import './Footer.scss';

export default function Footer(): JSX.Element {
	return (
		<div className="footer">
			<span className="footer-title">Get in touch!</span>
			<div className="footer-options">
				<div className="footer-option"><div className="icon-discord" /><span>Join our Discord server!</span></div>
				<div className="footer-option"><div className="icon-email" /><span>andromeda.dreams.tv@gmail.com</span></div>
				<div className="footer-option credits"><a href="https://linkedin.com/in/johnnybueti" target="_blank" rel="noreferrer">© 2022 - Johnny Bueti</a></div>
			</div>
		</div>
	);
};