import './BrandingButton.scss';
import twitchLogoPath from '../../assets/img/twitch.svg';

export default function BrandingButton(): JSX.Element {
	return (
		<a href='https://twitch.tv/andreams_tv' target='_blank' rel='noreferrer'>
			<div className="join-twitch-button">
				<img src={twitchLogoPath} alt="twitch_logo"/>
				<span>Visit our Twitch channel!</span>
			</div>
		</a>
	);
};