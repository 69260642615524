export type StatusResponse = {
	status: 'error' | 'okay',
	message: string
};

export class API {
	/**
	 * Makes an HTTP GET/POST request to the supplied endpoint with given params and JSON body.
	 */
	 private static async request<T extends unknown>(method: 'GET' | 'POST', endpoint: string, params?: URLSearchParams, body?: any): Promise<T> {
		const url: string = `${endpoint}${params ? `?${params.toString()}` : ''}`;

		const response: Response | Error = await fetch(url, {
			headers: {
				'Content-Type': 'application/json'
			},
			body: (body !== undefined && method === 'POST') ? JSON.stringify(body) : undefined,
			credentials: 'include'
		}).catch((ex: Error) => ex);

		if (response instanceof Error)
			throw response;

		const responseText: string | Error = await response.text().catch((ex: Error) => ex);

		if (responseText instanceof Error)
			throw responseText;
		
		let json: any = undefined;
		try { json = JSON.parse(responseText); } catch(_) {}

		if (!json)
			throw new Error(`Could not parse JSON string: ${responseText}`);

		return json;
	}

	/**
	 * Makes an HTTP GET request to the specified endpoint with given parameters.
	 */
	public static async get<T extends unknown>(endpoint: string, params?: URLSearchParams): Promise<T> {
		return await this.request<T>('GET', endpoint, params);
	}

	/**
	 * Makes an HTTP POST request to the specified endpoint with given parameters.
	 */
	public static async post<T extends unknown>(endpoint: string, params?: URLSearchParams, body?: any): Promise<T> {
		return await this.request<T>('POST', endpoint, params, body);
	}
};