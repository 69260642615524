import { MouseEvent } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ChosenPKMInputType, ChosenPKMWrapper } from '../services/ChosenPKMWrapper';
import PKMManager from '../services/PKMManager';
import QueueAPI from '../services/QueueAPI';
import { currentNavigationState, NavigationState } from '../state/NavigationState';
import { currentlyStreamingState, legendaryQueueInfoState, normalQueueInfoState } from '../state/NetworkState';
import { selectedPkmnNameState, validatedPkmnCustomizationDataState } from '../state/PkmnState';
import { pickQueueSectionDisplayState } from '../state/SectionVisibilityStates';
import { AuthStatus, userAuthStatusState } from '../state/UserState';
import './PickQueueSection.scss';

export default function PickQueueSection(): JSX.Element {
	const setCurrentNavigation 							= useSetRecoilState(currentNavigationState);
	const pickQueueSectionDisplay: string 				= useRecoilValue(pickQueueSectionDisplayState);
	const authStatus: AuthStatus 						= useRecoilValue(userAuthStatusState);
	const isChannelLive: boolean | undefined 			= useRecoilValue(currentlyStreamingState);
	const selectedPkmnName: string | undefined			= useRecoilValue(selectedPkmnNameState);
	const pkmnCustomizationData 						= useRecoilValue(validatedPkmnCustomizationDataState);

	const [normalQInfo, setNormQInfo] 			= useRecoilState(normalQueueInfoState);
	const normalQEstWait: number 				= Math.round(((normalQInfo?.queuedUsers?.length ?? 0) * 47) / 60 * 100) / 100;
	const normalQButtonClassModifier: string	= `${normalQInfo?.locked === true ? 'locked' : ''} ${!isChannelLive ? ' offline' : ''}`;

	const [legendaryQInfo, setLegendaryQInfo] 	= useRecoilState(legendaryQueueInfoState);
	const legendaryQEstWait: number 			= Math.round(((legendaryQInfo?.queuedUsers?.length ?? 0) * 41) / 60 * 100) / 100;
	const legendaryQButtonClassModifier: string = `${legendaryQInfo?.locked === true ? 'locked' : ''}`;

	const normalQueueClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		const channelLiveCheckEnabled = false;
		if (!isChannelLive && channelLiveCheckEnabled)
			return toast.error(<span>The stream is currently offline, and so is the Switch connected to this queue. We'll be back as per schedule!</span>, {
				position: toast.POSITION.TOP_CENTER
			});

		const clickHandlerResult: string | Error = await queueClickHandler('normal').catch((ex: Error) => ex);

		if (clickHandlerResult instanceof Error)
			return toast.error(<span>{clickHandlerResult.message}</span>, { position: toast.POSITION.TOP_CENTER });
		else
			toast(<span>{clickHandlerResult}</span>, { position: toast.POSITION.TOP_CENTER });

		// Give enough time for the toast to be seen & read.
		await new Promise(r => setTimeout(r, 1_500));

		// Invalidate normal queue data, such that position gets updated.
		setNormQInfo(undefined);
		
		setCurrentNavigation(NavigationState.enqueued);

		//REVIEW - Definitely needs to be improved.
		window.location.reload();
	};

	const legendaryQueueClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		if (!authStatus.isSub)
			return toast.error(<span>Legendary queue is available to <b>andreams_tv subscribers</b> exclusively. <a href="https://www.twitch.tv/subs/andreams_tv" target="_blank" rel="noreferrer">You can sub by clicking here!</a></span>, {
				position: toast.POSITION.TOP_CENTER
			});

		const clickHandlerResult: string | Error = await queueClickHandler('legendary').catch((ex: Error) => ex);

		if (clickHandlerResult instanceof Error)
			return toast.error(<span>{clickHandlerResult.message}</span>, { position: toast.POSITION.TOP_CENTER });
		else
			toast(<span>{clickHandlerResult}</span>, { position: toast.POSITION.TOP_CENTER });

		// Give enough time for the toast to be seen & read.
		await new Promise(r => setTimeout(r, 11_500));

		// Invalidate legendary queue data, such that position gets updated.
		setLegendaryQInfo(undefined);

		setCurrentNavigation(NavigationState.enqueued);

		//REVIEW - Definitely needs to be improved.
		window.location.reload();
	};

	const queueClickHandler = async (queueName: string): Promise<string> => {
		if (authStatus.isGuest === true)
			throw new Error('Login with your Twitch account before joining a queue!');

		if (!selectedPkmnName || !pkmnCustomizationData)
			throw new Error('You have not selected your Pokémon yet. Please select one first!');

		const res = await QueueAPI.join(
			queueName, 
			new ChosenPKMWrapper(ChosenPKMInputType.CUSTOMIZATION_FIELDS, JSON.stringify(pkmnCustomizationData))
		).catch((ex: Error) => ex);
	
		if (res instanceof Error)
			throw new Error(`There was an error while joining the normal queue: ${res.message}`);

		if (res.status === 'error')
			throw new Error(`There was an error while joining the normal queue: ${res.message}`);

		PKMManager.save(pkmnCustomizationData!);

		return `You have joined the ${queueName} queue!`;
	};

	return (
		<div className="section pick-queue-section" style={{ display: pickQueueSectionDisplay }}>
			<span className="section-title">2. Join a queue!</span>

			<div className="queue-button-wrapper">
				<div className={`queue-button legendary-queue-button button ${legendaryQButtonClassModifier}`} onClick={ legendaryQueueClickHandler }>
					<div className="top-left">
						<span className="queue-title">Legendary Queue</span>
						<span className="switches-available">Switches available: 1/2</span>
						<span className="est-wait">Est. wait: {legendaryQEstWait} minute{(legendaryQEstWait > 1 || legendaryQEstWait === 0) ? 's' : ''}</span>
					</div>
					<div className="top-right">
						<span className="sub-only">Sub. Benefit</span>
					</div>
					<div className="bottom-left">
						<span>{ legendaryQInfo?.locked ? 'LOCKED' : 'JOIN' }</span>
					</div>
					<div className="bottom-right">
						<span>{ legendaryQInfo?.queuedUsersLength ?? 4 }/{ legendaryQInfo?.maxQueueLength ?? 14 }</span>
					</div>
				</div>
			</div>

			<div className="legendary-spacer"></div>

			<div className={`queue-button normal-queue-button button${normalQButtonClassModifier}`} onClick={ normalQueueClickHandler }>
				<div className="top-left">
					<span className="queue-title">Normal Queue</span>
					<span className="switches-available">Switches available: 1/2</span>
					<span className="est-wait">Est. wait: {normalQEstWait} minute{(normalQEstWait > 1 || normalQEstWait === 0) ? 's' : ''}</span>
				</div>
				<div className="top-right"></div>
				<div className="bottom-left">
					<span>{ isChannelLive ? (normalQInfo?.locked ? 'LOCKED' : 'JOIN') : 'OFFLINE' }</span>
				</div>
				<div className="bottom-right">
					<span>{ normalQInfo?.queuedUsersLength ?? 4 }/{ normalQInfo?.maxQueueLength ?? 14 }</span>
				</div>
			</div>
		</div>
	);
};