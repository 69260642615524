class BilinearSearch {
	/**
	 * Searches through the whitelist of Dex Pokémon and returns a list of search results.
	 * @param pool The **ordered** array to search through.
	 * @param query The search input.
	 * @param resultsCount The number of results to return.
	 */
	 public search(pool: Array<string>, query: string, resultsCount: number = 3, caseSensitive: boolean = true): Array<string> {	
		if (query === '')
			return [];

		let a: number = 0;
		let b: number = pool.length - 1;
        
		const adjustCase = (s: string) => !caseSensitive ? s.toLowerCase() : s;
		const results: Array<string> = new Array<string>();

		for (let i = 0; i < query.length && a <= b; i++) {
			
			while (
				a <= b &&
				(pool[a].length <= i || adjustCase(pool[a][i]) !== adjustCase(query[i]))
			)
				a++;
			
			while (
				a <= b &&
				(pool[b].length <= i || adjustCase(pool[b][i]) !== adjustCase(query[i]))
			)
				b--;
		}

		for (let k = a; k <= b && k < a+resultsCount; k++)
			results.push(pool[k]);

		return results;
	}
};

export default new BilinearSearch();