/**
 * Determines which new Pokémon are available to be requested.
 */
export const pokemonWhitelist: string[] = [
	"sprigatito",
	"floragato",
	"meowscarada",
	"fuecoco",
	"crocalor",
	"skeledirge",
	"quaxly",
	"quaxwell",
	"quaquaval",
	"lechonk",
	"oinkologne",
	"tarountula",
	"spidops",
	"nymble",
	"lokix",
	"hoppip",
	"skiploom",
	"jumpluff",
	"fletchling",
	"fletchinder",
	"talonflame",
	"pawmi",
	"pawmo",
	"pawmot",
	"houndour",
	"houndoom",
	"yungoos",
	"gumshoos",
	"skwovet",
	"greedent",
	"sunkern",
	"sunflora",
	"kricketot",
	"kricketune",
	"scatterbug",
	"spewpa",
	"vivillon",
	"combee",
	"vespiquen",
	"rookidee",
	"corvisquire",
	"corviknight",
	"happiny",
	"chansey",
	"blissey",
	"azurill",
	"marill",
	"azumarill",
	"surskit",
	"masquerain",
	"buizel",
	"floatzel",
	"wooper",
	"clodsire",
	"psyduck",
	"golduck",
	"chewtle",
	"drednaw",
	"igglybuff",
	"jigglypuff",
	"wigglytuff",
	"ralts",
	"kirlia",
	"gardevoir",
	"gallade",
	"drowzee",
	"hypno",
	"gastly",
	"haunter",
	"gengar",
	"tandemaus",
	"maushold",
	"pichu",
	"pikachu",
	"raichu",
	"fidough",
	"dachsbun",
	"slakoth",
	"vigoroth",
	"slaking",
	"bounsweet",
	"steenee",
	"tsareena",
	"smoliv",
	"dolliv",
	"arboliva",
	"bonsly",
	"sudowoodo",
	"rockruff",
	"lycanroc",
	"rolycoly",
	"carkol",
	"coalossal",
	"shinx",
	"luxio",
	"luxray",
	"starly",
	"staravia",
	"staraptor",
	"oricorio",
	"mareep",
	"flaaffy",
	"ampharos",
	"petilil",
	"lilligant",
	"shroomish",
	"breloom",
	"applin",
	"flapple",
	"appletun",
	"spoink",
	"grumpig",
	"squawkabilly",
	"misdreavus",
	"mismagius",
	"makuhita",
	"hariyama",
	"crabrawler",
	"crabominable",
	"salandit",
	"salazzle",
	"phanpy",
	"donphan",
	"cufant",
	"copperajah",
	"gible",
	"gabite",
	"garchomp",
	"nacli",
	"naclstack",
	"garganacl",
	"wingull",
	"pelipper",
	"magikarp",
	"gyarados",
	"arrokuda",
	"barraskewda",
	"basculin",
	"gulpin",
	"swalot",
	"meowth",
	"persian",
	"drifloon",
	"drifblim",
	"undefined",
	"floette",
	"florges",
	"diglett",
	"dugtrio",
	"torkoal",
	"numel",
	"camerupt",
	"bronzor",
	"bronzong",
	"axew",
	"fraxure",
	"haxorus",
	"mankey",
	"primeape",
	"annihilape",
	"meditite",
	"medicham",
	"riolu",
	"lucario",
	"charcadet",
	"armarouge",
	"ceruledge",
	"barboach",
	"whiscash",
	"tadbulb",
	"bellibolt",
	"goomy",
	"sliggoo",
	"goodra",
	"croagunk",
	"toxicroak",
	"wattrel",
	"kilowattrel",
	"eevee",
	"vaporeon",
	"jolteon",
	"flareon",
	"espeon",
	"umbreon",
	"leafeon",
	"glaceon",
	"sylveon",
	"dunsparce",
	"dudunsparce",
	"deerling",
	"sawsbuck",
	"girafarig",
	"farigiraf",
	"grimer",
	"muk",
	"maschiff",
	"mabosstiff",
	"toxel",
	"toxtricity",
	"dedenne",
	"pachirisu",
	"shroodle",
	"grafaiai",
	"stantler",
	"foongus",
	"amoonguss",
	"voltorb",
	"electrode",
	"magnemite",
	"magneton",
	"magnezone",
	"ditto",
	"growlithe",
	"arcanine",
	"teddiursa",
	"ursaring",
	"zangoose",
	"seviper",
	"swablu",
	"altaria",
	"skiddo",
	"gogoat",
	"tauros",
	"litleo",
	"pyroar",
	"stunky",
	"skuntank",
	"zorua",
	"zoroark",
	"sneasel",
	"weavile",
	"murkrow",
	"honchkrow",
	"gothita",
	"gothorita",
	"gothitelle",
	"sinistea",
	"polteageist",
	"mimikyu",
	"klefki",
	"indeedee",
	"bramblin",
	"brambleghast",
	"toedscool",
	"toedscruel",
	"tropius",
	"fomantis",
	"lurantis",
	"klawf",
	"capsakid",
	"scovillain",
	"cacnea",
	"cacturne",
	"rellor",
	"rabsca",
	"venonat",
	"venomoth",
	"pineco",
	"forretress",
	"scyther",
	"scizor",
	"heracross",
	"flittle",
	"espathra",
	"hippopotas",
	"hippowdon",
	"sandile",
	"krokorok",
	"krookodile",
	"silicobra",
	"sandaconda",
	"mudbray",
	"mudsdale",
	"larvesta",
	"volcarona",
	"bagon",
	"shelgon",
	"salamence",
	"tinkatink",
	"tinkatuff",
	"tinkaton",
	"hatenna",
	"hattrem",
	"hatterene",
	"impidimp",
	"morgrem",
	"grimmsnarl",
	"wiglett",
	"wugtrio",
	"bombirdier",
	"finizen",
	"palafin",
	"varoom",
	"revavroom",
	"cyclizar",
	"orthworm",
	"sableye",
	"shuppet",
	"banette",
	"falinks",
	"hawlucha",
	"spiritomb",
	"noibat",
	"noivern",
	"dreepy",
	"drakloak",
	"dragapult",
	"glimmet",
	"glimmora",
	"rotom",
	"greavard",
	"houndstone",
	"oranguru",
	"passimian",
	"komala",
	"larvitar",
	"pupitar",
	"tyranitar",
	"stonjourner",
	"eiscue",
	"pincurchin",
	"sandygast",
	"palossand",
	"slowpoke",
	"slowbro",
	"slowking",
	"shellos",
	"gastrodon",
	"shellder",
	"cloyster",
	"qwilfish",
	"luvdisc",
	"finneon",
	"lumineon",
	"bruxish",
	"alomomola",
	"skrelp",
	"dragalge",
	"clauncher",
	"clawitzer",
	"tynamo",
	"eelektrik",
	"eelektross",
	"mareanie",
	"toxapex",
	"flamigo",
	"dratini",
	"dragonair",
	"dragonite",
	"snom",
	"frosmoth",
	"snover",
	"abomasnow",
	"delibird",
	"cubchoo",
	"beartic",
	"snorunt",
	"glalie",
	"froslass",
	"cryogonal",
	"cetoddle",
	"cetitan",
	"bergmite",
	"avalugg",
	"rufflet",
	"braviary",
	"pawniard",
	"bisharp",
	"kingambit",
	"deino",
	"zweilous",
	"hydreigon",
	"veluza",
	"dondozo",
	"tatsugiri",
	"greattusk",
	"screamtail",
	"brutebonnet",
	"fluttermane",
	"slitherwing",
	"sandyshocks",
	"irontreads",
	"ironbundle",
	"ironhands",
	"ironjugulis",
	"ironmoth",
	"ironthorns",
	"frigibax",
	"arctibax",
	"baxcalibur",
	"gimmighoul",
	"gholdengo",
	"wochien",
	"chienpao",
	"tinglu",
	"chiyu",
	"roaringmoon",
	"ironvaliant",
	"koraidon",
	"miraidon"
];