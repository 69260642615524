import { ChangeEvent, FocusEvent, MouseEvent, useLayoutEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentNavigationState, NavigationState } from '../../state/NavigationState';
import { choosePkmnSectionDisplayState, pkmnSearchBlackOverlayDisplayState } from '../../state/SectionVisibilityStates';

import masterBallIconPath from '../../assets/img/masterBall.png';
import searchIconPath from '../../assets/img/search.svg';
import BlackOverlay from '../../components/BlackOverlay/BlackOverlay';
import './ChoosePkmnSection.scss';
import CustomizePkmnPopup from './components/CustomizePkmn/CustomizePkmnPopup';
import SearchResultsPopup from './components/SearchResults/SearchResultsPopup';
import { toast } from 'react-toastify';
import { pkmnSearchStringState } from '../../state/SearchState';
import { selectedPkmnNameState } from '../../state/PkmnState';

export default function ChoosePkmnSection(): JSX.Element {
	const choosePkmnSectionRef 			= useRef<HTMLDivElement>(null);
	const [translateY, setTranslateY] 	= useState<string>();
	const choosePkmnSectionDisplay		= useRecoilValue(choosePkmnSectionDisplayState);

	const internalOverlayDisplay 				= useRecoilValue(pkmnSearchBlackOverlayDisplayState);
	const [pkmnSearchText, setPkmnSearchText] 	= useRecoilState<string>(pkmnSearchStringState);
	const setNavigationState 					= useSetRecoilState(currentNavigationState);

	const selectedPkmnName						= useRecoilValue(selectedPkmnNameState);

	const editPkmnEnabled: boolean = true;

	const pkmnTextChangedHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setPkmnSearchText(value);
		setNavigationState(NavigationState.searchingPkmn);
	};

	const pkmnTextFocusGainHandler = (_: FocusEvent<HTMLInputElement>) => {
		setNavigationState(NavigationState.searchingPkmn);
	};

	const editPkmnClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		if (!editPkmnEnabled)
			return toast.error(<span>Pokémon customization is currently disabled, but under active development and will be available in the future.</span>, {
				position: toast.POSITION.TOP_CENTER
			});

		if (!selectedPkmnName)
			return toast.error(<span>You cannot edit a Pokémon before choosing one.</span>, { position: toast.POSITION.TOP_CENTER });

		setNavigationState(NavigationState.editingPkmn);
	};

	useLayoutEffect(() => {
		if (choosePkmnSectionRef.current === undefined) return;

		const searchResultTranslate: string = `translateY(calc(${choosePkmnSectionRef.current?.clientHeight}px))`;
		setTranslateY(searchResultTranslate);
	}, []);
	
	return (
		<div className="section choose-pkmn-section" ref={choosePkmnSectionRef} style={{ display: choosePkmnSectionDisplay }}>
			<BlackOverlay style={{ display: internalOverlayDisplay }} />

			<span className="section-title">1. Choose your Pokémon!</span>
			
			<div className="search-bar">
				<div className="search-input-wrapper">
					<input name="pkmn-search" type="text" placeholder="Search..." autoComplete="off" onChange={ pkmnTextChangedHandler } onFocus={ pkmnTextFocusGainHandler } value={pkmnSearchText} />
					<img className="search-icon" src={searchIconPath} alt="searchIcon" />
				</div>
				<img className="selected-pkmn img-disabled" src={masterBallIconPath} alt="selectedPkmn" onClick={ editPkmnClickHandler } />
				<div className="edit-button" onClick={ editPkmnClickHandler }>
					<span>Edit</span>
				</div>
			</div>

			<SearchResultsPopup translateY={ translateY } />
			<CustomizePkmnPopup translateY={ translateY } />
		</div>
	);
};