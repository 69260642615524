import { MouseEvent } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { currentNavigationState, enqueuedModifierState, NavigationState, popupModifierState } from '../../state/NavigationState';
import { editFieldSearchValueState } from '../../state/SearchState';
import { blackOverlayDisplayState } from '../../state/SectionVisibilityStates';
import { blackOverlayZIndexState } from '../../state/SectionZIndexStates';
import './BlackOverlay.scss';

export default function BlackOverlay(props: { style: React.CSSProperties | undefined }): JSX.Element {
	const overlayDisplay: string 			= useRecoilValue(blackOverlayDisplayState);
	const overlayZIndex: number 			= useRecoilValue(blackOverlayZIndexState);
	const setCurrentNavigation 				= useSetRecoilState(currentNavigationState);
	const setSearchFieldVal 				= useSetRecoilState(editFieldSearchValueState);
	const enqueuedModifier: boolean 		= useRecoilValue(enqueuedModifierState);
	const [popupModifier, setPopupModifier] = useRecoilState(popupModifierState);

	const clickHandler = (_: MouseEvent<HTMLDivElement>) => {
		setSearchFieldVal('');

		if (popupModifier !== undefined) {
			setPopupModifier(undefined);
			return;
		}

		if (enqueuedModifier)
			setCurrentNavigation(NavigationState.enqueued);
		else
			setCurrentNavigation(NavigationState.mainPage);
	};

	return (
		<div 
			className="black-overlay" 
			style={props?.style ?? { display: overlayDisplay, zIndex: overlayZIndex }}
			onClick={ clickHandler }
		></div>
	);
};