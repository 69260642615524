import { atom, selector } from "recoil";
import { PKMCustomizationData } from "../services/model/PKMCustomizationData";

export const selectedPkmnNameState = atom<string | undefined>({
	key: 'selectedPkmnNameState',
	default: undefined
});

export const selectedPkmnCustomizationDataState = atom<PKMCustomizationData | undefined>({
	key: 'selectedPkmnCustomizationDataState',
	default: new PKMCustomizationData(
		'Abomasnow',
		100,
		'---',
		'None',
		false,
		[31, 31, 31, 31, 31, 31],
		[252, 0, 0, 0, 0, 0],
		['---', '---', '---', '---'],
		'Fighting',
		'M'
	)
});

export const editingPkmnCustomizationDataState = atom<PKMCustomizationData>({
	key: 'editingPkmnCustomizationDataState',
	default: new PKMCustomizationData(
		'Abomasnow',
		100,
		'---',
		'None',
		false,
		[31, 31, 31, 31, 31, 31],
		[252, 0, 0, 0, 0, 0],
		['---', '---', '---', '---'],
		'Fighting',
		'M'
	)
});

export const validatedPkmnCustomizationDataState = selector<PKMCustomizationData | undefined>({
	key: 'validatedPkmnCustomizationDataState',
	get: ({get}) => {
		const customizationData: PKMCustomizationData | undefined = get(selectedPkmnCustomizationDataState)?.clone();
		const selectedPkmnName: string | undefined = get(selectedPkmnNameState);

		if (!selectedPkmnName || !customizationData)
			return undefined;
		
		customizationData.species = selectedPkmnName[0].toUpperCase() + selectedPkmnName.slice(1);

		return customizationData;
	}
});