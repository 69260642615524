import { EditField } from "../state/CustomizationPopupState";
import { gen9Items } from "../static-data/gen9Items";
import { Learnsets } from "../static-data/learnsets";
import { Pokedex, Pokemon } from "../static-data/pokedex";
import { MovesText } from "../static-data/text/moves";

class EditEntriesFactory {
	public readonly heldItems: string[];
	private readonly possibleHeldItemsOrdered: string[];

	public constructor() {
		this.heldItems = new Array<string>(...gen9Items);
		this.possibleHeldItemsOrdered = new Array<string>(...gen9Items);
		this.possibleHeldItemsOrdered = this.possibleHeldItemsOrdered.sort().filter(entry => entry !== '');
	}

	/**
	 * Generate edit field entries depending on the given canonical dex PKM name.
	 * @param canonicalPkmnName The PKM's canonical name, valid for O(1) retrieval in static structures.
	 * @param editField The edit field to retrieve static data from.
	 * @returns 
	 */
	public generateEditEntries(canonicalPkmnName: string, editField: EditField): string[] {
		if (!canonicalPkmnName || !editField)
			return [];

		// Fetch the PKM's Pokedex entry now, in O(1) time.
		const pokedexEntry: Pokemon | undefined = Pokedex[canonicalPkmnName];

		if (!pokedexEntry)
			return [];

		const possibleEditEntries: Array<string> = new Array<string>();
		switch (editField) {
			case 'ability':
				possibleEditEntries.push(...Object.values(pokedexEntry.abilities));
				break;
			case 'gender':
				if (pokedexEntry.gender !== undefined) {
					switch(pokedexEntry.gender) {
						case 'F':
							possibleEditEntries.push('F');
							break;
						case 'M':
							possibleEditEntries.push('M');
							break;
						case 'N':
							possibleEditEntries.push('-');
							break;
					}
				} else
					possibleEditEntries.push(...['F', 'M']);
				break;
			case 'heldItem':
				return this.possibleHeldItemsOrdered;
			case 'move0':
			case 'move1': 
			case 'move2':
			case 'move3':
				const learnset: string[] = Object.keys(Learnsets[canonicalPkmnName]?.learnset ?? {});
				if (!learnset)
					break;

				possibleEditEntries.push(...learnset.map(move => MovesText[move].name))
				break;
		}
		
		return possibleEditEntries;
	}
};

export default new EditEntriesFactory();