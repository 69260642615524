import LoadingIcon from '../../../../components/LoadingIcon/LoadingIcon';
import masterBallIconPath from '../../../../assets/img/masterBall.png';
import './SearchResultsPopup.scss';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { searchResultsPopupDisplayState } from '../../../../state/SectionVisibilityStates';
import { pkmSearchLoadingGridDisplayState, pkmnSearchResultsState, pkmnSearchStringState } from '../../../../state/SearchState';
import { MouseEvent, useRef } from 'react';
import { toast } from 'react-toastify';
import { currentNavigationState, enqueuedModifierState, NavigationState } from '../../../../state/NavigationState';
import { selectedPkmnNameState } from '../../../../state/PkmnState';

function capitalize(str: string): string {
	return str[0].toUpperCase() + str.slice(1);
}

const SearchResult = (props: {id: string, name: string, imgPath: string}) => {
	const imgRef = useRef<HTMLImageElement>(null);
	const setCurrentNavigation = useSetRecoilState(currentNavigationState);
	const setPkmnSearchString = useSetRecoilState(pkmnSearchStringState);
	const setSelectedPkmnName = useSetRecoilState(selectedPkmnNameState);
	const enqueuedModifier = useRecoilValue(enqueuedModifierState);

	const searchResultClickHandler = (e: MouseEvent<HTMLDivElement>) => {
		const pkmnName: string | null = e.currentTarget.getAttribute('pkmn_name');

		if (!pkmnName)
			return toast.error(<span>An unexpected error occurred. Refresh your page. Error code: 0x1.</span>, {
				position: 'top-center'
			});

		setSelectedPkmnName(pkmnName);
		setPkmnSearchString(capitalize(pkmnName));

		if (enqueuedModifier)
			setCurrentNavigation(NavigationState.enqueued)
		else
			setCurrentNavigation(NavigationState.mainPage);
	};
	
	let errored: boolean = false;
	return (
		<div className="search-result" onClick={ searchResultClickHandler } {...{pkmn_name: props.name}}>
			<div className="pkmn-icon">
				<img src={props.imgPath} ref={imgRef} alt="" onError={() => {
					if (errored)
						return;

					errored = true;

					imgRef.current!.setAttribute('src', masterBallIconPath);
				}} />
			</div>
			<div className="pkmn-details">
				<span className="pokedex-id">{props.id}</span>
				<span className="pkmn-name">{capitalize(props.name)}</span>
			</div>
		</div>
	);
};

export default function SearchResultsPopup(props: { translateY: string | undefined }): JSX.Element {
	const searchResultsPopupDisplay: string = useRecoilValue(searchResultsPopupDisplayState);
	const searchResults: Array<string> 		= useRecoilValue(pkmnSearchResultsState);
	const loadingGridDisplay: string 		= useRecoilValue(pkmSearchLoadingGridDisplayState);

	const searchResultsDOM: JSX.Element[] = [];
	searchResults.forEach(result => {
		searchResultsDOM.push(<SearchResult key={result} id={'# ???'} name={result} imgPath={`https://img.pokemondb.net/sprites/home/normal/${result}.png`} />);
	});

	return (
		<div className="section search-results" style={{ transform: props.translateY, display: searchResultsPopupDisplay }}>
			<LoadingIcon style={{ display: loadingGridDisplay }}/>
			<div className="scroll-wrapper">
				{ searchResultsDOM }
			</div>
		</div>
	);
}