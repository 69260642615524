export class ChosenPKMWrapper {
	public readonly inputType: ChosenPKMInputType;
	public readonly payload: string;

	public constructor(inputType: ChosenPKMInputType, payload: string) {
		this.inputType = inputType;
		this.payload = payload;
	}

	public toString(): string {
		return JSON.stringify({ inputType: this.inputType, payload: this.payload });
	}
}; 

export enum ChosenPKMInputType {
	SHOWDOWN_SET = 0,
	CUSTOMIZATION_FIELDS = 1,
	PREDEFINED = 2		
};