import { BACK_END_URI } from "../config";
import { API, StatusResponse } from "./API";
import { ChosenPKMWrapper } from "./ChosenPKMWrapper";
import { Queue } from "./model/Queue";

class QueueAPI {
	private readonly endpoint: string = BACK_END_URI + '/';

	/**
	 * Fetches the serialized state of a queue given the queue name.
	 * @returns A `Queue` object or `undefined` if unsuccessful.
	 */
	public async getQueueInfo(queueName: string): Promise<Queue | undefined> {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<Queue | undefined>(
			this.endpoint + 'queue/getInfo', 
			new URLSearchParams({ queueName: queueName })
		).catch(_ => undefined);
	}

	/**
	 * Given the queue name, this returns the user's position in queue.
	 * @returns The position in queue of the queued user.
	 */
	async position(queueName: string): Promise<number> {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<number>(
			this.endpoint + 'queue/position', 
			new URLSearchParams({ queueName: queueName })
		);
	}

	/**
	 * Allows the logged in user to join the supplied queue.
	 */
	async join(queueName: string, chosenPkmnWrapper: ChosenPKMWrapper, skipChecks: boolean = false, testUID?: string, testUsername?: string, testSub?: boolean): Promise<StatusResponse> {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<StatusResponse>(
			this.endpoint + 'queue/join', 
			new URLSearchParams({ 
				queueName: queueName,
				isTest: skipChecks.toString(),
				testUID: testUID ?? '',
				testUsername: testUsername ?? '',
				testSub: testSub?.toString() ?? '',
				pkmnInputType: chosenPkmnWrapper.inputType.toString(),
				pkmnPayload: chosenPkmnWrapper.payload
			})
		);
	}

	/**
	 * Allows a user to leave the supplied queue.
	 */
	async leave(queueName: string) {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<StatusResponse>(
			this.endpoint + 'queue/leave', 
			new URLSearchParams({ queueName: queueName })
		);
	}

	/**
	 *  Kicks a player from a queue.
	 */
	async kick(queueName: string, kickUID: string) {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		if (kickUID === undefined)
			throw new Error('Parameter `kickUID` cannot be undefined.');

		return await API.get<StatusResponse>(
			this.endpoint + 'queue/kick',
			new URLSearchParams({ queueName: queueName, kickUID: kickUID })
		);
	}

	/**
	 * 	Toggles sub-only mode on a queue.
	 */
	async toggleSubOnly(queueName: string): Promise<StatusResponse> {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<StatusResponse>(
			this.endpoint + 'queue/toggleSubOnly',
			new URLSearchParams({ queueName: queueName })
		);
	}

	/**
	 * 	Toggles lock on a queue.
	 */
	async toggleLock(queueName: string): Promise<StatusResponse> {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<StatusResponse>(
			this.endpoint + 'queue/toggleLock',
			new URLSearchParams({ queueName: queueName })
		);
	}

	/**
	 * 	Toggles kick timeout of a queue.
	 */
	async toggleKickTimeout(queueName: string): Promise<StatusResponse> {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<StatusResponse>(
			this.endpoint + 'queue/toggleKickTimeout',
			new URLSearchParams({ queueName: queueName })
		);
	}

	/**
	 * 	Resets all kick timeouts of a queue.
	 */
	async resetAllKickTimeouts(queueName: string) {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		throw new Error('Not implemented.');
	}

	/**
	 * 	Sets kick timeout of a queue.
	 */
	async setTimeToKick(queueName: string, _timeToKick: number) {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<StatusResponse>(
			this.endpoint + 'queue/setTimeToKick',
			new URLSearchParams({ queueName: queueName, timeToKick: _timeToKick.toString() })
		);
	}

	/**
	 * 	Sets number of maximum concurrent visitors for a queue.
	 */
	async resize(queueName: string, _maxTreasuryLength?: number, _maxQueueLength?: number) {
		if (queueName === undefined)
			throw new Error('Parameter `queueName` cannot be undefined.');

		return await API.get<StatusResponse>(
			this.endpoint + 'queue/resize',
			new URLSearchParams({ 
				queueName: queueName,
				maxQueueLength: _maxQueueLength?.toString() ?? '',
				maxTreasuryLength: _maxTreasuryLength?.toString() ?? ''
			})
		);
	}
};

export default new QueueAPI();