import { selector } from "recoil";
import UserManager from "../services/UserManager";

/**
 * Describes the /userGroup EP response.
 */
export interface AuthStatus {
	isGuest: boolean;
	isMod: boolean;
	isSub: '1000' | '2000' | '3000' | boolean;
};

export const userAuthStatusState = selector<AuthStatus>({
	key: 'userAuthStatus',
	get: async (_) => {
		const userGroup: AuthStatus | undefined = await UserManager.getUserGroup();

		return userGroup ?? {
			isGuest: true,
			isMod: false,
			isSub: false
		};
	}
});