import { pokemonWhitelist } from "../static-data/pokemon-whitelist";
import BilinearSearch from "./BinarySearch";

class DexSearch {
	private readonly lexOrderedDex: string[];

	public constructor() {
		this.lexOrderedDex = [];

		pokemonWhitelist.forEach(pkmn => {
			this.lexOrderedDex.push(pkmn.toLowerCase());
		});

		this.lexOrderedDex.sort();
	}

	/**
	 * Searches through the whitelist of Dex Pokémon and returns a list of search results.
	 * @param pkmnSearch The search input.
	 * @param resultsCount The number of results to return.
	 */
	public search(pkmnSearch: string, resultsCount: number = 3): Array<string> {	
		if (pkmnSearch === '')
			return [];

		return BilinearSearch.search(this.lexOrderedDex, pkmnSearch, resultsCount);
	}
}

export default new DexSearch();