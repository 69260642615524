import { Suspense } from "react";
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import BlackOverlay from "../components/BlackOverlay/BlackOverlay";
import BrandingButton from "../components/BrandingButton/BrandingButton";
import Footer from "../components/Footer/Footer";
import InteractivePopup, { InteractivePopupType } from "../components/InteractivePopup/InteractivePopup";
import MenuBar from "../components/MenuBar/MenuBar";
import NavCustomRouter from "../components/NavCustomRouter";
import Sections from "../sections/Sections";

export default function MainPage(): JSX.Element {
	return (
		<Suspense>
			<NavCustomRouter />
			<BlackOverlay style={undefined} />
			<MenuBar />
			<BrandingButton />
			<Sections />
			<div className="spacer" />
			<Footer />
			<ToastContainer />
			<InteractivePopup name="leave-popup" popupType={InteractivePopupType.confirm} title="Are you sure?" message="Upon leaving the queue you lose your spot and will have to requeue again." />
			<InteractivePopup name="insert-code-popup" popupType={InteractivePopupType.input} title="Trade Link" message="Insert your Trade Link Code below and wait!" />
			<CookieConsent location="bottom" buttonText="Okay!">This website uses cookies exclusively to improve user experience, no targeted ads shenanigans.</CookieConsent>
	</Suspense>
  );
};