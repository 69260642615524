/* eslint-disable max-len */

export const Learnsets: {[speciesid: string]: any} = {
	missingno: {
		learnset: {
			blizzard: ["3L1"],
			bubblebeam: ["3L1"],
			cut: ["3L1"],
			doubleedge: ["3L1"],
			earthquake: ["3L1"],
			fissure: ["3L1"],
			fly: ["3L1"],
			icebeam: ["3L1"],
			megakick: ["3L1"],
			megapunch: ["3L1"],
			psychic: ["3L1"],
			rage: ["3L1"],
			razorwind: ["3L1"],
			rest: ["3L1"],
			seismictoss: ["3L1"],
			skyattack: ["3L1"],
			submission: ["3L1"],
			substitute: ["3L1"],
			swordsdance: ["3L1"],
			takedown: ["3L1"],
			teleport: ["3L1"],
			thunder: ["3L1"],
			thunderwave: ["3L1"],
			toxic: ["3L1"],
			triattack: ["3L1"],
			watergun: ["3L1"],
		},
	},
	bulbasaur: {
		learnset: {
			amnesia: ["8M", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			block: ["5S3"],
			bodyslam: ["8M", "7V", "3T"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			celebrate: ["6S5"],
			charm: ["8M", "7E", "6E", "5E", "4E", "3E"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			doubleedge: ["8L33", "8V", "7L27", "7V", "6L27", "5L27", "4L27", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "5S3"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frenzyplant: ["5S3"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigadrain: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasspledge: ["8T", "7T", "6T", "5T"],
			grasswhistle: ["7E", "6E", "5E", "4E", "3E"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7E", "6E"],
			growl: ["8L1", "8V", "7L3", "7V", "6L3", "6S4", "6S5", "5L3", "5S2", "4L3", "3L4", "3S1"],
			growth: ["8L6", "8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L32", "3S0"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			ingrain: ["8E", "7E", "6E", "5E", "4E"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leafstorm: ["8M", "7E", "6E", "5E", "4E"],
			leechseed: ["8L9", "8V", "7L7", "7V", "6L7", "6S4", "5L7", "5S2", "4L7", "3L7", "3S1"],
			lightscreen: ["8M", "8V", "7M", "7V", "6M", "5M", "4E", "3E"],
			magicalleaf: ["8M", "7E", "6E", "5E", "4E", "3E"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["8E", "7M", "7E", "6M", "6E", "5E", "4E"],
			outrage: ["8V"],
			petaldance: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			poisonpowder: ["8L15", "8V", "7L13", "7V", "6L13", "6S4", "5L13", "4L13", "3L15"],
			powerwhip: ["8M", "7E", "6E", "5E", "4E"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			razorleaf: ["8L12", "8V", "7L19", "7V", "6L19", "5L19", "4L19", "3L20"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "8L18", "7T", "7L37", "6T", "6L37", "5T", "5L37", "4T", "4L37"],
			skullbash: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			sleeppowder: ["8L15", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L15"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludge: ["7E", "6E", "5E", "4E"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8L36", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3L46", "3S0"],
			strength: ["6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["8L24", "7L21", "7V", "6L21", "5L21", "4L21", "3L25", "3S0"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["8L27", "7T", "7L33", "7V", "6T", "6L33", "5T", "5L33", "4T", "4L33", "3L39", "3S0"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "6S5", "5L1", "5S2", "4L1", "3L1", "3S1"],
			takedown: ["8L21", "8V", "7L15", "7V", "6L15", "5L15", "4L15"],
			toxic: ["8E", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			vinewhip: ["8L3", "8V", "7L7", "7V", "6L9", "6S4", "5L9", "5S2", "4L9", "3L10", "3S1"],
			weatherball: ["8M", "5S3"],
			workup: ["8M", "7M"],
			worryseed: ["8L30", "7T", "7L31", "6T", "6L31", "5T", "5L31", "4T", "4L31"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["sweetscent", "growth", "solarbeam", "synthesis"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "growl", "leechseed", "vinewhip"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tackle", "growl", "leechseed", "vinewhip"]},
			{generation: 5, level: 1, shiny: 1, ivs: {def: 31}, moves: ["falseswipe", "block", "frenzyplant", "weatherball"], pokeball: "pokeball"},
			{generation: 6, level: 5, moves: ["growl", "leechseed", "vinewhip", "poisonpowder"], pokeball: "cherishball"},
			{generation: 6, level: 5, isHidden: true, moves: ["tackle", "growl", "celebrate"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 5},
		],
	},
	ivysaur: {
		learnset: {
			amnesia: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			bodyslam: ["8M", "7V", "3T"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			doubleedge: ["8L45", "8V", "7L31", "7V", "6L31", "5L31", "4L31", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasspledge: ["8T", "7T", "6T", "5T"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			growth: ["8L1", "8V", "7L28", "7V", "6L28", "5L28", "4L28", "3L38"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leafstorm: ["8M"],
			leechseed: ["8L9", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M"],
			magicalleaf: ["8M"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			outrage: ["8V"],
			poisonpowder: ["8L15", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L15"],
			powerwhip: ["8M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			razorleaf: ["8L12", "8V", "7L20", "7V", "6L20", "5L20", "4L20", "3L22"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "8L20", "7T", "6T", "5T", "4T"],
			sleeppowder: ["8L15", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L15"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8L50", "8V", "7M", "7L44", "7V", "6M", "6L44", "5M", "5L44", "4M", "4L44", "3M", "3L56"],
			strength: ["6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["8L30", "7L23", "7V", "6L23", "5L23", "4L23", "3L29"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["8L35", "7T", "7L39", "7V", "6T", "6L39", "5T", "5L39", "4T", "4L39", "3L47"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L25", "8V", "7L15", "7V", "6L15", "5L15", "4L15"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			vinewhip: ["8L1", "8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L10"],
			weatherball: ["8M"],
			workup: ["8M", "7M"],
			worryseed: ["8L40", "7T", "7L36", "6T", "6L36", "5T", "5L36", "4T", "4L36"],
		},
	},
	venusaur: {
		learnset: {
			amnesia: ["8M", "8V"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["8M", "7V", "3T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			doubleedge: ["8L51", "8V", "7L31", "7V", "6L31", "5L31", "4L31", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M"],
			earthquake: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frenzyplant: ["8T", "7T", "6T", "6S0", "5T", "4T", "3T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasspledge: ["8T", "7T", "6T", "6S0", "5T"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			growth: ["8L1", "8V", "7L28", "7V", "6L28", "5L28", "4L28", "3L41"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leafstorm: ["8M"],
			leechseed: ["8L9", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M"],
			magicalleaf: ["8M"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			petalblizzard: ["8L0", "7L50", "6L50"],
			petaldance: ["8L1", "8V", "7L1", "6L32", "5L32", "4L32"],
			poisonpowder: ["8L15", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L15"],
			powerwhip: ["8M", "8V"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			razorleaf: ["8L12", "8V", "7L20", "7V", "6L20", "5L20", "4L20", "3L22"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "8L20", "7T", "6T", "5T", "4T"],
			sleeppowder: ["8L15", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L15"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8L58", "8V", "7M", "7L53", "7V", "6M", "6L53", "6S0", "5M", "5L53", "4M", "4L53", "3M", "3L65"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["8L30", "7L23", "7V", "6L23", "5L23", "4L23", "3L29"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["8L37", "7T", "7L45", "7V", "6T", "6L45", "6S0", "5T", "5L45", "4T", "4L45", "3L53"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L25", "8V", "7L15", "7V", "6L15", "5L15", "4L15"],
			terrainpulse: ["8T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			vinewhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			weatherball: ["8M"],
			workup: ["8M", "7M"],
			worryseed: ["8L44", "7T", "7L39", "6T", "6L39", "5T", "5L39", "4T", "4L39"],
		},
		eventData: [
			{generation: 6, level: 100, isHidden: true, moves: ["solarbeam", "frenzyplant", "synthesis", "grasspledge"], pokeball: "cherishball"},
		],
	},
	charmander: {
		learnset: {
			acrobatics: ["8M", "5S6"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["7E", "6E"],
			ancientpower: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			bellydrum: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			bite: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			blastburn: ["5S6"],
			block: ["5S6"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			celebrate: ["6S8"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3T"],
			crunch: ["9M", "8M", "7E", "6E", "5E", "4E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["9L12", "8L12", "7V"],
			dragonclaw: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "8M", "7E", "6E", "5E", "4E", "3E"],
			dragonpulse: ["9M", "8M", "8V", "7T", "7E", "6T", "6E", "5E"],
			dragonrage: ["8V", "7L16", "7V", "6L16", "6S7", "5L16", "4L16", "3L43"],
			dragonrush: ["9E", "8E", "7E", "6E", "5E", "4E"],
			dragontail: ["9M", "9E", "8E"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["9L4", "8L4", "8V", "7L7", "7V", "6L7", "6S7", "5L7", "5S4", "4L7", "3L7", "3S0"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M", "8M", "5S6"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "9L17", "8M", "8L17", "7L25", "6L25", "5L25", "4L25"],
			firepledge: ["9M", "8T", "7T", "6T", "5T"],
			firepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			firespin: ["9M", "9L32", "8M", "8L32", "8V", "7L43", "7V", "6L43", "5L43", "4L37", "3L49"],
			flameburst: ["7L28", "6L28", "5L28"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L24", "8M", "8L24", "8V", "7M", "7L37", "7V", "6M", "6L37", "5M", "5L37", "4M", "4L34", "3M", "3L31"],
			flareblitz: ["9M", "9L40", "8M", "8L40", "7E", "6E", "5E", "4E"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["8V"],
			growl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "6S7", "6S8", "5L1", "5S4", "4L1", "3L1", "3S0"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "4S1", "4S2", "4S3", "4S5", "3M"],
			honeclaws: ["6M", "5M"],
			howl: ["4S1", "4S2", "4S3", "4S5"],
			incinerate: ["6M", "5M"],
			inferno: ["9L36", "8L36", "7L46", "6L46", "5L46"],
			irontail: ["9E", "8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["7V"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metalclaw: ["9M", "9E", "8E", "7E", "6E", "5E", "4E", "3L13"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "8V", "7E", "7V", "6E", "5T", "5E", "4E", "3E"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["4S1", "4S2", "4S3", "4S5"],
			rage: ["7V", "3L19"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "4S1", "4S2", "4S3", "4S5", "3M"],
			rockslide: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L28", "8M", "8L28", "7L19", "7V", "6L19", "5L19", "4L19", "3L25"],
			scratch: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "6S8", "5L1", "5S4", "4L1", "3L1", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			skullbash: ["7V"],
			slash: ["9L20", "8L20", "8V", "7L34", "7V", "6L34", "5L34", "4L28", "3L37"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["9L8", "8L8", "8V", "7L10", "7V", "6L10", "6S7", "5L10", "5S4", "4L10", "3L13"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			swordsdance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			weatherball: ["8M"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			wingattack: ["8E"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["scratch", "growl", "ember"], pokeball: "pokeball"},
			{generation: 4, level: 40, gender: "M", nature: "Mild", moves: ["return", "hiddenpower", "quickattack", "howl"], pokeball: "cherishball"},
			{generation: 4, level: 40, gender: "M", nature: "Naive", moves: ["return", "hiddenpower", "quickattack", "howl"], pokeball: "cherishball"},
			{generation: 4, level: 40, gender: "M", nature: "Naughty", moves: ["return", "hiddenpower", "quickattack", "howl"], pokeball: "cherishball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["scratch", "growl", "ember", "smokescreen"]},
			{generation: 4, level: 40, gender: "M", nature: "Hardy", moves: ["return", "hiddenpower", "quickattack", "howl"], pokeball: "cherishball"},
			{generation: 5, level: 1, shiny: 1, ivs: {spe: 31}, moves: ["falseswipe", "block", "blastburn", "acrobatics"], pokeball: "pokeball"},
			{generation: 6, level: 5, moves: ["growl", "ember", "smokescreen", "dragonrage"], pokeball: "cherishball"},
			{generation: 6, level: 5, isHidden: true, moves: ["scratch", "growl", "celebrate"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 5},
		],
	},
	charmeleon: {
		learnset: {
			acrobatics: ["8M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M"],
			bide: ["7V"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			crunch: ["9M", "8M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["9L12", "8L12", "7V"],
			dragonclaw: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "8M"],
			dragonpulse: ["9M", "8M", "8V", "7T", "6T"],
			dragonrage: ["8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L48"],
			dragontail: ["9M"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M", "8M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "9L19", "8M", "8L19", "7L28", "6L28", "5L28", "4L28"],
			firepledge: ["9M", "8T", "7T", "6T", "5T"],
			firepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			firespin: ["9M", "8M", "8L42", "8V", "7L50", "7V", "6L50", "5L50", "4L43", "3L55"],
			flameburst: ["7L32", "6L32", "5L32"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L30", "8M", "8L30", "8V", "7M", "7L43", "7V", "6M", "6L43", "5M", "5L43", "4M", "4L39", "3M", "3L34"],
			flareblitz: ["9M", "9L54", "8M", "8L54"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["8V"],
			growl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			inferno: ["9L48", "8L48", "7L54", "6L54", "5L54"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["7V"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metalclaw: ["3L13"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "8V", "5T"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V", "3L20"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L37", "8M", "8L37", "7L21", "7V", "6L21", "5L21", "4L21", "3L27"],
			scratch: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			skullbash: ["7V"],
			slash: ["9L24", "8L24", "8V", "7L39", "7V", "6L39", "5L39", "4L32", "3L41"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["9L1", "8L1", "8V", "7L10", "7V", "6L10", "5L10", "4L10", "3L13"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			swordsdance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			weatherball: ["8M"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			workup: ["8M", "7M"],
		},
	},
	charizard: {
		learnset: {
			acrobatics: ["9M", "8M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			aircutter: ["9M", "4T"],
			airslash: ["9M", "9L0", "8M", "8L0", "8V", "7L1", "6L1", "6S1", "6S2", "5L1", "4L1"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M"],
			bide: ["7V"],
			blastburn: ["9M", "8T", "7T", "6T", "6S4", "5T", "4T", "3T"],
			blazekick: ["8M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["8V", "7V", "3T"],
			crunch: ["9M", "8M", "8V"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			defog: ["7T", "4M"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["9L12", "8L12", "7V"],
			dragonclaw: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "7S6", "7S7", "6M", "6L1", "6S2", "5M", "5L1", "4M", "4L1", "3M"],
			dragondance: ["9M", "8M", "7S9"],
			dragonpulse: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			dragonrage: ["8V", "7L17", "7V", "7S6", "7S7", "7S8", "6L17", "6S2", "5L17", "4L17", "3L54", "3S0"],
			dragontail: ["9M", "8V", "8S10", "7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "8M", "8V", "7M", "7V", "7S9", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "6S5", "5L1", "4L1", "3L1"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "9L19", "8M", "8L19", "7L28", "6L28", "6S1", "6S2", "5L28", "4L28"],
			firepledge: ["9M", "8T", "7T", "6T", "6S4", "5T"],
			firepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			firespin: ["9M", "9L46", "8M", "8L46", "8V", "7L56", "7V", "6L56", "6S5", "5L56", "4L49", "3L64", "3S0"],
			fissure: ["7V"],
			flameburst: ["7L32", "6L32", "6S1", "6S5", "5L32"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L30", "8M", "8L30", "8V", "8S10", "7M", "7L47", "7V", "7S8", "6M", "6L47", "6S5", "5M", "5L47", "4M", "4L42", "3M", "3L34"],
			flareblitz: ["9M", "9L62", "8M", "8L62", "8V", "7L1", "7S6", "7S7", "7S9", "6L1", "6S4", "5L77", "4L66"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fly: ["9M", "8M", "8V", "7M", "7V", "7S6", "7S7", "7S9", "6M", "5M", "4M", "3M"],
			focusblast: ["9M", "8M", "7M", "6M", "6S3", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["8V"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V", "7V", "4T"],
			heatcrash: ["8M"],
			heatwave: ["9M", "9L1", "8M", "8L1", "8V", "7T", "7L1", "6T", "6L1", "5T", "5L71", "4T", "4L59", "3L1"],
			helpinghand: ["9M", "8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			holdhands: ["6S3"],
			honeclaws: ["6M", "5M"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			inferno: ["9L54", "8L54", "7L62", "6L62", "6S1", "5L62"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["7V"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metalclaw: ["3L1"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			outrage: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			overheat: ["9M", "8M", "7M", "6M", "6S3", "5M", "4M", "3M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V", "3L20"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			roost: ["8V", "7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "7V"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L39", "8M", "8L39", "7L21", "7V", "6L21", "6S4", "5L21", "4L21", "3L27"],
			scorchingsands: ["8T"],
			scratch: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "8S10", "7V", "7S8", "3T"],
			shadowclaw: ["9M", "8M", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			skullbash: ["7V"],
			skydrop: ["7M", "6M", "5M"],
			slash: ["9L24", "8L24", "8V", "8S10", "7L41", "7V", "7S8", "6L41", "5L41", "4L32", "3L44", "3S0"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["9L1", "8L1", "8V", "7L10", "7V", "6L1", "5L1", "4L1", "3L1"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "8V", "7M", "6M", "6S3", "5M", "4M"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			swordsdance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			weatherball: ["8M"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			wingattack: ["8V", "7L1", "7V", "6L36", "5L36", "4L36", "3L36", "3S0"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["wingattack", "slash", "dragonrage", "firespin"], pokeball: "pokeball"},
			{generation: 6, level: 36, gender: "M", moves: ["firefang", "flameburst", "airslash", "inferno"], pokeball: "cherishball"},
			{generation: 6, level: 36, gender: "M", moves: ["firefang", "airslash", "dragonclaw", "dragonrage"], pokeball: "cherishball"},
			{generation: 6, level: 36, shiny: true, gender: "M", moves: ["overheat", "solarbeam", "focusblast", "holdhands"], pokeball: "cherishball"},
			{generation: 6, level: 100, isHidden: true, moves: ["flareblitz", "blastburn", "scaryface", "firepledge"], pokeball: "cherishball"},
			{generation: 6, level: 36, gender: "M", nature: "Serious", moves: ["flamethrower", "ember", "firespin", "flameburst"], pokeball: "cherishball"},
			{generation: 7, level: 40, nature: "Jolly", moves: ["dragonclaw", "dragonrage", "fly", "flareblitz"], pokeball: "cherishball"},
			{generation: 7, level: 40, gender: "M", nature: "Jolly", moves: ["flareblitz", "dragonclaw", "fly", "dragonrage"], pokeball: "cherishball"},
			{generation: 7, level: 40, gender: "M", nature: "Adamant", moves: ["flamethrower", "dragonrage", "slash", "seismictoss"], pokeball: "pokeball"},
			{generation: 7, level: 50, moves: ["dragondance", "flareblitz", "fly", "earthquake"], pokeball: "cherishball"},
			{generation: 8, level: 50, gender: "M", nature: "Adamant", moves: ["flamethrower", "dragontail", "slash", "seismictoss"], pokeball: "pokeball"},
		],
	},
	squirtle: {
		learnset: {
			aquajet: ["8E", "7E", "6E", "5E", "4E"],
			aquaring: ["8E", "7E", "6E", "5E", "4E"],
			aquatail: ["8L24", "7T", "7L28", "6T", "6L28", "5T", "5L28", "4T", "4L28"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurasphere: ["8M", "7E", "6E"],
			bide: ["7V"],
			bite: ["8L12", "8V", "7L16", "7V", "6L16", "5L16", "4L16", "3L18"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["5S2"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "7E", "6E", "5E", "4M"],
			bubble: ["8V", "7L13", "7V", "6L13", "6S3", "5L7", "5S1", "4L7", "3L7", "3S0"],
			bubblebeam: ["8V", "7V"],
			captivate: ["4M"],
			celebrate: ["6S4"],
			confide: ["7M", "6M"],
			confusion: ["7V"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonpulse: ["8M", "8V", "7T", "7E", "6T", "6E"],
			dynamicpunch: ["7V", "3T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8E", "7E", "6E", "5E", "4E"],
			falseswipe: ["8M", "5S2"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["5S2"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydrocannon: ["5S2"],
			hydropump: ["8M", "8L33", "8V", "7L40", "7V", "6L40", "5L40", "4L37", "3L47"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "8L30", "7T", "7L34", "6T", "6L34", "5T", "5L34", "4T", "4L34"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lifedew: ["8E"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mirrorcoat: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			mist: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			muddywater: ["8M", "7E", "6E", "5E", "4E"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			outrage: ["8V"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L18", "8V", "7M", "7L22", "7V", "6M", "6L22", "5M", "5L22", "4M", "4L22", "3M", "3L28"],
			rage: ["7V"],
			raindance: ["8M", "8L21", "7M", "7L37", "7V", "6M", "6L37", "5M", "5L37", "4M", "4L34", "3M", "3L33"],
			rapidspin: ["8L9", "7L19", "7V", "6L19", "5L19", "4L19", "3L23"],
			reflect: ["8V", "7V"],
			refresh: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8V"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shellsmash: ["8L27"],
			skullbash: ["8L36", "8V", "7L31", "7V", "6L31", "5L31", "4L31", "3L40"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "6S4", "5L1", "5S1", "4L1", "3L1", "3S0"],
			tailwhip: ["8L1", "8V", "7L4", "7V", "6L4", "6S3", "6S4", "5L4", "5S1", "4L4", "3L4", "3S0"],
			takedown: ["7V"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L3", "8V", "7L7", "7V", "6L7", "6S3", "5L13", "4L13", "3L13"],
			waterpledge: ["8T", "7T", "6T", "5T"],
			waterpulse: ["8L15", "7T", "7L25", "6T", "6L25", "5L25", "4M", "4L25", "3M"],
			waterspout: ["8E", "7E", "6E", "5E", "4E"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7V", "4M"],
			withdraw: ["8L6", "8V", "7L10", "7V", "6L10", "6S3", "5L10", "5S1", "4L10", "3L10", "3S0"],
			workup: ["8M", "7M"],
			yawn: ["8E", "7E", "6E", "5E", "4E", "3E"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "tailwhip", "bubble", "withdraw"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tackle", "tailwhip", "bubble", "withdraw"]},
			{generation: 5, level: 1, shiny: 1, ivs: {hp: 31}, moves: ["falseswipe", "block", "hydrocannon", "followme"], pokeball: "pokeball"},
			{generation: 6, level: 5, moves: ["tailwhip", "watergun", "withdraw", "bubble"], pokeball: "cherishball"},
			{generation: 6, level: 5, isHidden: true, moves: ["tackle", "tailwhip", "celebrate"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 5},
		],
	},
	wartortle: {
		learnset: {
			aquatail: ["8L30", "7T", "7L33", "6T", "6L32", "5T", "5L32", "4T", "4L32"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurasphere: ["8M"],
			bide: ["7V"],
			bite: ["8L12", "8V", "7L17", "7V", "6L16", "5L16", "4L16", "3L19"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "4M"],
			bubble: ["8V", "7L13", "7V", "6L13", "5L1", "4L1", "3L1"],
			bubblebeam: ["8V", "7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonpulse: ["8M", "8V", "7T", "6T"],
			dynamicpunch: ["7V", "3T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L45", "8V", "7L49", "7V", "6L48", "5L48", "4L44", "3L53"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "8L40", "7T", "7L41", "6T", "6L40", "5T", "5L40", "4T", "4L40"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["8V"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L20", "8V", "7M", "7L25", "7V", "6M", "6L24", "5M", "5L24", "4M", "4L24", "3M", "3L31"],
			rage: ["7V"],
			raindance: ["8M", "8L25", "7M", "7L45", "7V", "6M", "6L44", "5M", "5L44", "4M", "4L40", "3M", "3L37"],
			rapidspin: ["8L9", "7L21", "7V", "6L20", "5L20", "4L20", "3L25"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8V"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shellsmash: ["8L35"],
			skullbash: ["8L50", "8V", "7L37", "7V", "6L36", "5L36", "4L36", "3L45"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "8V", "7L1", "7V", "6L1", "5L13", "4L13", "3L13"],
			waterpledge: ["8T", "7T", "6T", "5T"],
			waterpulse: ["8L15", "7T", "7L29", "6T", "6L28", "5L28", "4M", "4L28", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7V", "4M"],
			withdraw: ["8L1", "8V", "7L10", "7V", "6L10", "5L10", "4L10", "3L10"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	blastoise: {
		learnset: {
			aquajet: ["8V"],
			aquatail: ["8L30", "7T", "7L33", "6T", "6L32", "5T", "5L32", "4T", "4L32"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurasphere: ["8M"],
			avalanche: ["8M", "4M"],
			bide: ["7V"],
			bite: ["8L12", "8V", "7L17", "7V", "6L16", "5L16", "4L16", "3L19"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodypress: ["8M"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "4M"],
			bubble: ["8V", "7L13", "7V", "6L13", "5L1", "4L1", "3L1"],
			bubblebeam: ["8V", "7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			crunch: ["8M"],
			curse: ["7V"],
			darkpulse: ["8M", "8V", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonpulse: ["8M", "8V", "7T", "6T"],
			dragontail: ["8V", "7M", "6M", "5M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8V"],
			falseswipe: ["8M"],
			fissure: ["7V"],
			flashcannon: ["8M", "8L0", "8V", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydrocannon: ["8T", "7T", "6T", "6S1", "5T", "4T", "3T"],
			hydropump: ["8M", "8L49", "8V", "7L60", "7V", "6L60", "6S1", "5L60", "4L53", "3L68", "3S0"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "8L42", "7T", "7L47", "6T", "6L46", "6S1", "5T", "5L46", "4T", "4L46"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			liquidation: ["8M", "7T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L20", "8V", "7M", "7L25", "7V", "6M", "6L24", "5M", "5L24", "4M", "4L24", "3M", "3L31", "3S0"],
			rage: ["7V"],
			raindance: ["8M", "8L25", "7M", "7L54", "7V", "6M", "6L53", "5M", "5L53", "4M", "4L46", "3M", "3L42", "3S0"],
			rapidspin: ["8L9", "7L21", "7V", "6L20", "5L20", "4L20", "3L25"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shellsmash: ["8L35"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skullbash: ["8L56", "8V", "7L40", "7V", "6L39", "5L39", "4L39", "3L55", "3S0"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			terrainpulse: ["8T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "8V", "7L1", "7V", "6L1", "5L13", "4L13", "3L13"],
			waterpledge: ["8T", "7T", "6T", "6S1", "5T"],
			waterpulse: ["8L15", "7T", "7L29", "6T", "6L28", "5L28", "4M", "4L28", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7V", "4M"],
			withdraw: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["protect", "raindance", "skullbash", "hydropump"], pokeball: "pokeball"},
			{generation: 6, level: 100, isHidden: true, moves: ["hydropump", "hydrocannon", "irondefense", "waterpledge"], pokeball: "cherishball"},
		],
	},
	caterpie: {
		learnset: {
			bugbite: ["8L9", "7T", "7L9", "6T", "6L15", "5T", "5L15", "4T", "4L15"],
			electroweb: ["8M", "7T", "6T", "5T"],
			snore: ["7T", "6T", "5T", "4T"],
			stringshot: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4T", "4L1", "3L1"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
		},
		encounters: [
			{generation: 1, level: 3},
			{generation: 2, level: 3},
			{generation: 3, level: 3},
		],
	},
	metapod: {
		learnset: {
			bugbite: ["7T", "6T", "5T", "4T"],
			electroweb: ["8M", "7T", "6T", "5T"],
			harden: ["8L0", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			stringshot: ["4T"],
		},
		encounters: [
			{generation: 1, level: 4},
			{generation: 2, level: 4},
			{generation: 3, level: 4},
			{generation: 4, level: 3},
			{generation: 6, level: 4},
			{generation: 7, level: 3},
		],
	},
	butterfree: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M", "3S0"],
			aircutter: ["5D", "4T"],
			airslash: ["8M", "8L24", "8V", "7L43"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M"],
			bide: ["7V"],
			bugbite: ["8L1", "7T", "6T", "5T", "4T"],
			bugbuzz: ["8M", "8L32", "8V", "7L31", "6L42", "5L42", "4L40"],
			captivate: ["7L37", "6L40", "5L40", "4M", "4L36"],
			confide: ["7M", "6M"],
			confusion: ["8L8", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M"],
			dreameater: ["8V", "7M", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gust: ["8L0", "8V", "7L1", "7V", "6L16", "5L16", "4L16", "3L28"],
			harden: ["8L1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			morningsun: ["3S0"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			ominouswind: ["4T"],
			poisonpowder: ["8L12", "8V", "7L13", "7V", "6L12", "5L12", "4L12", "3L13"],
			pollenpuff: ["8M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L16", "8V", "7L17", "7V", "6L24", "5L24", "4L24", "3L34"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			psychup: ["7M", "6M", "5M", "4M"],
			psywave: ["7V"],
			quiverdance: ["8L44", "8V", "7L47", "6L46", "5L46"],
			rage: ["7V"],
			ragepowder: ["8L40", "7L35", "6L34", "5L34"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "6M", "5T", "5D", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L28", "7M", "7L25", "7V", "6M", "6L36", "5M", "5L36", "4M", "4L34", "3M", "3L40"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["7L19", "6L28", "5L28", "4M", "4L28", "3L47"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			sleeppowder: ["8L12", "8V", "7L13", "7V", "6L12", "5L12", "4L12", "3L15", "3S0"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			stringshot: ["8L1", "4T"],
			strugglebug: ["6M", "5M"],
			stunspore: ["8L12", "8V", "7L13", "7V", "6L12", "5L12", "4L12", "3L14"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["8L4", "8V", "7L23", "7V", "6L18", "5L18", "4L18", "3L18"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8L1"],
			tailwind: ["8L36", "7T", "7L41", "6T", "6L30", "5T", "5L30", "4T", "4L30"],
			takedown: ["7V"],
			teleport: ["8V", "7V"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uturn: ["8M", "8V", "7M", "6M", "5M", "4M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			whirlwind: ["8L20", "8V", "7L29", "7V", "6L22", "5L22", "4L22", "3L23"],
		},
		eventData: [
			{generation: 3, level: 30, moves: ["morningsun", "psychic", "sleeppowder", "aerialace"]},
		],
		encounters: [
			{generation: 2, level: 7},
			{generation: 4, level: 6},
			{generation: 7, level: 9},
		],
	},
	weedle: {
		learnset: {
			bugbite: ["7T", "7L9", "6T", "6L15", "5T", "5L15", "4T", "4L15"],
			electroweb: ["7T", "6T", "5T"],
			poisonsting: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			stringshot: ["8V", "7L1", "7V", "6L1", "5L1", "4T", "4L1", "3L1"],
		},
		encounters: [
			{generation: 1, level: 3},
			{generation: 2, level: 3},
			{generation: 3, level: 3},
		],
	},
	kakuna: {
		learnset: {
			bugbite: ["7T", "6T", "5T", "4T"],
			electroweb: ["7T", "6T", "5T"],
			harden: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			irondefense: ["7T", "6T", "5T", "4T"],
			stringshot: ["4T"],
		},
		encounters: [
			{generation: 1, level: 4},
			{generation: 2, level: 4},
			{generation: 3, level: 4},
			{generation: 4, level: 3},
			{generation: 6, level: 4},
			{generation: 7, level: 3},
		],
	},
	beedrill: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8V", "7L38", "7V", "6L31", "5L31", "4L31", "3L40"],
			aircutter: ["5D", "4T"],
			assurance: ["7L26", "6L34", "5L34", "4L34"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["3S0"],
			bide: ["7V"],
			brickbreak: ["8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["7M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defog: ["7T", "4M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8V", "7T", "6T", "5T"],
			electroweb: ["7T", "6T", "5T"],
			endeavor: ["7T", "7L41", "6T", "6L40", "5T", "5L40", "4T", "4L40", "3L45"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["7M", "6M", "5M", "4M"],
			fellstinger: ["7L44", "6L45"],
			flash: ["6M", "5M", "4M"],
			focusenergy: ["8V", "7L20", "7V", "6L13", "5L13", "4L13", "3L15"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			furycutter: ["7V", "5D", "4T", "3T"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			outrage: ["8V"],
			payback: ["7M", "6M", "5M", "4M"],
			peck: ["8V"],
			pinmissile: ["8V", "7L32", "7V", "6L28", "5L28", "4L28", "3L35"],
			poisonjab: ["8V", "7M", "7L35", "6M", "6L37", "5M", "5L37", "4M", "4L37"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L17", "7V", "6L22", "5L22", "4L22", "3L30"],
			rage: ["8V", "7L14", "7V", "6L19", "5L19", "4L19", "3L25"],
			reflect: ["8V", "7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			silverwind: ["4M"],
			skullbash: ["7V"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8V", "7M", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["7V", "4T", "3T"],
			swordsdance: ["7M", "7V", "6M", "5M", "4M", "3T", "3S0"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["7L29", "6L25", "5L25", "4L25"],
			twineedle: ["8V", "7L1", "7V", "6L16", "5L16", "4L16", "3L20", "3S0"],
			uturn: ["8V", "7M", "6M", "5M", "4M"],
			venoshock: ["7M", "7L23", "6M", "5M"],
			xscissor: ["8V", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 30, moves: ["batonpass", "sludgebomb", "twineedle", "swordsdance"]},
		],
		encounters: [
			{generation: 2, level: 7},
			{generation: 4, level: 6},
		],
	},
	pidgey: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8V", "7L29", "7V", "6L29", "5L29", "4L29", "3L39"],
			aircutter: ["7E", "6E", "5E", "4T", "4E", "3E"],
			airslash: ["8V", "7L49", "7E", "6L49", "6E", "5L49", "5E", "4L49", "4E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bravebird: ["7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "7E", "6E", "5E", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["7L25", "6L25", "5L25", "4L25", "3L31"],
			feintattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			fly: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gust: ["8V", "7L9", "7V", "6L9", "5L9", "5D", "4L9", "3L9"],
			headbutt: ["8V"],
			heatwave: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["7L53", "6L53", "5L53"],
			mimic: ["7V", "3T"],
			mirrormove: ["8V", "7L45", "7V", "6L45", "5L45", "4L45", "3L47"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			pluck: ["5M", "4M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			quickattack: ["8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L13"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			razorwind: ["8V", "7V"],
			reflect: ["8V", "7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "7L37", "6M", "6L37", "5T", "5L37", "4M", "4L37"],
			round: ["7M", "6M", "5M"],
			sandattack: ["8V", "7L5", "7V", "6L5", "5L5", "4L5", "3L5"],
			secretpower: ["6M", "5D", "4M", "3M"],
			skyattack: ["7T", "7V", "6T", "3T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "7E", "7V", "6M", "6E", "5E", "5D", "4M", "4E", "3M", "3E"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tackle: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "7L41", "6T", "6L41", "5T", "5L41", "4T", "4L41"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["7L21", "6L21", "5L21", "4T", "4L21"],
			uproar: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			uturn: ["8V", "7M", "6M", "5M", "4M"],
			whirlwind: ["8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L19"],
			wingattack: ["8V", "7L33", "7V", "6L33", "5L33", "4L33", "3L25"],
			workup: ["7M", "5M"],
		},
		encounters: [
			{generation: 1, level: 2},
			{generation: 2, level: 2},
			{generation: 3, level: 2},
		],
	},
	pidgeotto: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8V", "7L32", "7V", "6L32", "5L32", "4L32", "3L43"],
			aircutter: ["4T"],
			airslash: ["8V", "7L57", "6L57", "5L57", "4L57"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["7L27", "6L27", "5L27", "4L27", "3L34", "3S0"],
			fly: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gust: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V"],
			heatwave: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["7L62", "6L62", "5L62"],
			mimic: ["7V", "3T"],
			mirrormove: ["8V", "7L52", "7V", "6L52", "5L52", "4L52", "3L52"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			pluck: ["5M", "4M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L13"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			razorwind: ["8V", "7V"],
			reflect: ["8V", "7V"],
			refresh: ["3S0"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "7L42", "6M", "6L42", "5T", "5L42", "4M", "4L42"],
			round: ["7M", "6M", "5M"],
			sandattack: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["7T", "7V", "6T", "3T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "7V", "6M", "4M", "3M", "3S0"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tackle: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "7L47", "6T", "6L47", "5T", "5L47", "4T", "4L47"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["7L22", "6L22", "5L22", "4T", "4L22"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["8V", "7M", "6M", "5M", "4M"],
			whirlwind: ["8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L20"],
			wingattack: ["8V", "7L37", "7V", "6L37", "5L37", "4L37", "3L27", "3S0"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 30, abilities: ["keeneye"], moves: ["refresh", "wingattack", "steelwing", "featherdance"]},
		],
		encounters: [
			{generation: 1, level: 9},
			{generation: 2, level: 7},
			{generation: 3, level: 7},
			{generation: 4, level: 7},
		],
	},
	pidgeot: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8V", "7L32", "7V", "6L32", "5L32", "4L32", "3L48"],
			aircutter: ["4T"],
			airslash: ["8V", "7L62", "6L62", "5L62", "4L62"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["7L27", "6L27", "5L27", "4L27", "3L34"],
			fly: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			gust: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V"],
			heatwave: ["8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["7L1", "6L1", "5L68"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			laserfocus: ["7T"],
			mimic: ["7V", "3T"],
			mirrormove: ["8V", "7L56", "7V", "6L56", "5L56", "5S0", "4L56", "3L62"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			pluck: ["5M", "4M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			razorwind: ["8V", "7V"],
			reflect: ["8V", "7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "7L44", "6M", "6L44", "5T", "5L44", "4M", "4L44"],
			round: ["7M", "6M", "5M"],
			sandattack: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["8V", "7T", "7V", "6T", "5T", "5S0", "4T", "3T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "7V", "6M", "4M", "3M"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tackle: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "7L50", "6T", "6L50", "5T", "5L50", "4T", "4L50"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["7L22", "6L22", "5L22", "4T", "4L22"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["8V", "7M", "6M", "5M", "4M"],
			whirlwind: ["8V", "7L17", "7V", "6L17", "5L17", "5S0", "4L17", "3L20"],
			wingattack: ["8V", "7L38", "7V", "6L38", "5L38", "5S0", "4L38", "3L27"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 5, level: 61, gender: "M", nature: "Naughty", ivs: {hp: 30, atk: 30, def: 30, spa: 30, spd: 30, spe: 30}, abilities: ["keeneye"], moves: ["whirlwind", "wingattack", "skyattack", "mirrormove"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 7, level: 29},
		],
	},
	rattata: {
		learnset: {
			assurance: ["7L19", "6L25", "5L25", "4L25"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["8V", "7L10", "7E", "7V", "6L10", "6E", "5L10", "5E", "4L10", "4E", "3E"],
			blizzard: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7V", "3T"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			counter: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			covet: ["7T", "6T", "5T"],
			crunch: ["8V", "7L22", "6L22", "5L22", "4L22"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8V", "7L31", "7V", "6L31", "5L31", "4L31", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endeavor: ["7T", "7L34", "6T", "6L34", "5T", "5L34", "4T", "4L34", "3L41"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			finalgambit: ["7E", "6E", "5E"],
			flamewheel: ["7E", "7V", "6E", "5E", "4E", "3E"],
			focusenergy: ["8V", "7L7", "7V", "6L7", "5L7", "4L7", "3L20"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["7E", "7V", "6E", "5E", "4E", "3E"],
			grassknot: ["7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperfang: ["8V", "7L16", "7V", "6L16", "5L16", "4L16", "3L13"],
			icebeam: ["8V", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8V", "7T", "7V", "6T", "5T", "5D", "4M", "3M"],
			lastresort: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			mefirst: ["7E", "6E", "5E", "5D", "4E"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			pluck: ["5M", "4M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L13", "7V", "6L13", "5L13", "4L13", "3L27"],
			quickattack: ["8V", "7L4", "7V", "6L4", "5L4", "5D", "4L4", "3L7"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["7E", "6E", "5E"],
			reversal: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			screech: ["7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8V", "7L25", "6L19", "5L19", "4T", "4L19"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["8V", "7T", "7L28", "7V", "6T", "6L28", "5T", "5L28", "4T", "4L28", "3L34"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			swift: ["7V", "4T", "3T"],
			tackle: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			taunt: ["8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["8V", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "7E", "6T", "6E", "5T", "5E", "4E", "3E"],
			uturn: ["8V", "7M", "6M", "5M", "4M"],
			watergun: ["7V"],
			wildcharge: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zenheadbutt: ["7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 1, level: 2},
			{generation: 2, level: 2},
			{generation: 3, level: 2},
		],
	},
	rattataalola: {
		learnset: {
			assurance: ["7L19"],
			attract: ["7M"],
			bite: ["8V", "7L10"],
			blizzard: ["8V", "7M"],
			confide: ["7M"],
			counter: ["7E"],
			covet: ["7T"],
			crunch: ["8V", "7L22"],
			darkpulse: ["8V", "7M"],
			dig: ["8V"],
			doubleedge: ["8V", "7L31"],
			doubleteam: ["7M"],
			embargo: ["7M"],
			endeavor: ["7T", "7L34"],
			facade: ["8V", "7M"],
			finalgambit: ["7E"],
			focusenergy: ["8V", "7L7"],
			frustration: ["7M"],
			furyswipes: ["7E"],
			grassknot: ["7M"],
			headbutt: ["8V"],
			hiddenpower: ["7M"],
			hyperfang: ["8V", "7L16"],
			icebeam: ["8V", "7M"],
			icywind: ["7T"],
			irontail: ["8V", "7T"],
			lastresort: ["7T"],
			mefirst: ["7E"],
			protect: ["8V", "7M"],
			pursuit: ["7L13"],
			quash: ["7M"],
			quickattack: ["8V", "7L4"],
			raindance: ["7M"],
			rest: ["8V", "7M"],
			return: ["7M"],
			revenge: ["7E"],
			reversal: ["7E"],
			round: ["7M"],
			shadowball: ["8V", "7M"],
			shadowclaw: ["7M"],
			shockwave: ["7T"],
			sleeptalk: ["7M"],
			sludgebomb: ["8V", "7M"],
			snarl: ["7M"],
			snatch: ["7T", "7E"],
			snore: ["7T"],
			stockpile: ["7E"],
			substitute: ["8V", "7M"],
			suckerpunch: ["8V", "7L25"],
			sunnyday: ["7M"],
			superfang: ["8V", "7T", "7L28"],
			swagger: ["7M"],
			swallow: ["7E"],
			switcheroo: ["7E"],
			tackle: ["8V", "7L1"],
			tailwhip: ["8V", "7L1"],
			taunt: ["8V", "7M"],
			thief: ["7M"],
			torment: ["7M"],
			toxic: ["8V", "7M"],
			uproar: ["7T", "7E"],
			uturn: ["8V", "7M"],
			zenheadbutt: ["7T"],
		},
	},
	raticate: {
		learnset: {
			assurance: ["7L19", "6L29", "5L29", "4L29"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["8V", "7L10", "6L10", "5L10", "4L10"],
			blizzard: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7V", "3T"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			counter: ["8V", "3T"],
			covet: ["7T", "6T", "5T"],
			crunch: ["8V", "7L24", "6L24", "5L24", "4L24"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8V", "7L39", "7V", "6L39", "5L39", "4L39", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endeavor: ["7T", "7L44", "6T", "6L44", "5T", "5L44", "4T", "4L44", "3L50"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["8V", "7L1", "7V", "6L1", "5L1", "4L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["8V"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hyperfang: ["8V", "7L16", "7V", "6L16", "5L16", "4L16", "3L13", "3S0"],
			icebeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			pluck: ["5M", "4M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L13", "7V", "6L13", "5L13", "4L13", "3L30"],
			quickattack: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["7L1", "7V", "6L20", "5L20", "4L20", "3L20", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8V", "7L29", "6L19", "5L19", "4T", "4L19"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["8V", "7T", "7L34", "7V", "6T", "6L34", "5T", "5L34", "4T", "4L34", "3L40", "3S0"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			swordsdance: ["8V", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			tackle: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			taunt: ["8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			thunder: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["8V", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T"],
			uturn: ["8V", "7M", "6M", "5M", "4M"],
			watergun: ["7V"],
			wildcharge: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zenheadbutt: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 34, moves: ["refresh", "superfang", "scaryface", "hyperfang"]},
		],
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 6},
			{generation: 4, level: 13},
		],
	},
	raticatealola: {
		learnset: {
			assurance: ["7L19"],
			attract: ["7M"],
			bite: ["8V", "7L10"],
			blizzard: ["8V", "7M"],
			bulkup: ["8V", "7M"],
			confide: ["7M"],
			counter: ["8V"],
			covet: ["7T"],
			crunch: ["8V", "7L24"],
			darkpulse: ["8V", "7M"],
			dig: ["8V"],
			doubleedge: ["8V", "7L39"],
			doubleteam: ["7M"],
			embargo: ["7M"],
			endeavor: ["7T", "7L44"],
			facade: ["8V", "7M"],
			focusenergy: ["8V", "7L1"],
			frustration: ["7M"],
			furyswipes: ["8V"],
			gigaimpact: ["7M"],
			grassknot: ["7M"],
			headbutt: ["8V"],
			hiddenpower: ["7M"],
			hyperbeam: ["8V", "7M"],
			hyperfang: ["8V", "7L16"],
			icebeam: ["8V", "7M"],
			icywind: ["7T"],
			irontail: ["8V", "7T"],
			knockoff: ["7T"],
			lastresort: ["7T"],
			protect: ["8V", "7M"],
			pursuit: ["7L13"],
			quash: ["7M"],
			quickattack: ["8V", "7L1"],
			raindance: ["7M"],
			rest: ["8V", "7M"],
			return: ["7M"],
			roar: ["7M"],
			round: ["7M"],
			scaryface: ["7L1"],
			shadowball: ["8V", "7M"],
			shadowclaw: ["7M"],
			shockwave: ["7T"],
			sleeptalk: ["7M"],
			sludgebomb: ["8V", "7M"],
			sludgewave: ["7M"],
			snarl: ["7M"],
			snatch: ["7T"],
			snore: ["7T"],
			stompingtantrum: ["7T"],
			substitute: ["8V", "7M"],
			suckerpunch: ["8V", "7L29"],
			sunnyday: ["7M"],
			superfang: ["8V", "7T", "7L34"],
			swagger: ["7M"],
			swordsdance: ["8V", "7M", "7L1"],
			tackle: ["8V", "7L1"],
			tailwhip: ["8V", "7L1"],
			taunt: ["8V", "7M"],
			thief: ["7M"],
			throatchop: ["7T"],
			torment: ["7M"],
			toxic: ["8V", "7M"],
			uproar: ["7T"],
			uturn: ["8V", "7M"],
			venoshock: ["7M"],
			zenheadbutt: ["7T"],
		},
		encounters: [
			{generation: 7, level: 17},
		],
	},
	raticatealolatotem: {
		learnset: {
			assurance: ["7L19", "7S0"],
			attract: ["7M"],
			bite: ["7L10", "7S0"],
			blizzard: ["7M"],
			bulkup: ["7M"],
			confide: ["7M"],
			covet: ["7T"],
			crunch: ["7L24"],
			darkpulse: ["7M"],
			doubleedge: ["7L39"],
			doubleteam: ["7M"],
			embargo: ["7M"],
			endeavor: ["7T", "7L44"],
			facade: ["7M"],
			focusenergy: ["7L1"],
			frustration: ["7M"],
			gigaimpact: ["7M"],
			grassknot: ["7M"],
			hiddenpower: ["7M"],
			hyperbeam: ["7M"],
			hyperfang: ["7L16", "7S0"],
			icebeam: ["7M"],
			icywind: ["7T"],
			irontail: ["7T"],
			knockoff: ["7T"],
			lastresort: ["7T"],
			protect: ["7M"],
			pursuit: ["7L13", "7S0"],
			quash: ["7M"],
			quickattack: ["7L1"],
			raindance: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			roar: ["7M"],
			round: ["7M"],
			scaryface: ["7L1"],
			shadowball: ["7M"],
			shadowclaw: ["7M"],
			shockwave: ["7T"],
			sleeptalk: ["7M"],
			sludgebomb: ["7M"],
			sludgewave: ["7M"],
			snarl: ["7M"],
			snatch: ["7T"],
			snore: ["7T"],
			stompingtantrum: ["7T"],
			substitute: ["7M"],
			suckerpunch: ["7L29"],
			sunnyday: ["7M"],
			superfang: ["7T", "7L34"],
			swagger: ["7M"],
			swordsdance: ["7M", "7L1"],
			tackle: ["7L1"],
			tailwhip: ["7L1"],
			taunt: ["7M"],
			thief: ["7M"],
			throatchop: ["7T"],
			torment: ["7M"],
			toxic: ["7M"],
			uproar: ["7T"],
			uturn: ["7M"],
			venoshock: ["7M"],
			zenheadbutt: ["7T"],
		},
		eventData: [
			{generation: 7, level: 20, perfectIVs: 3, moves: ["bite", "pursuit", "hyperfang", "assurance"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	spearow: {
		learnset: {
			aerialace: ["7M", "7L15", "6M", "6L17", "5M", "5L17", "4M", "4L17", "3M", "3L25", "3S0"],
			agility: ["8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L43"],
			aircutter: ["4T"],
			assurance: ["7L22", "6L29", "5L29", "4L29"],
			astonish: ["7E", "6E", "5E", "4E", "3E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["3S0"],
			bide: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillpeck: ["8V", "7L36", "7V", "6L37", "5L37", "4L37", "3L37"],
			drillrun: ["8V", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["7M", "7V", "6M", "5M", "4E", "3E", "3S0"],
			featherdance: ["7E", "6E", "5E", "4E"],
			feintattack: ["7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			fly: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			focusenergy: ["8V", "7L29"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L11", "7V", "6L9", "5L9", "4L9", "3L13"],
			growl: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V"],
			heatwave: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			leer: ["8V", "7L4", "7V", "6L5", "5L5", "4L5", "3L7", "3S0"],
			mimic: ["7V", "3T"],
			mirrormove: ["8V", "7L18", "7V", "6L21", "5L21", "4L21", "3L31"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			peck: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			pluck: ["5M", "4M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L8", "7V", "6L13", "5L13", "4L13", "3L19"],
			quickattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7E", "7V", "6E", "5E"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "7L32", "6M", "6L33", "5T", "5L33", "4M", "4L33"],
			round: ["7M", "6M", "5M"],
			scaryface: ["7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4E", "3T", "3E"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "7E", "7V", "6M", "6E", "5E", "5D", "4M", "3M"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			twister: ["4T"],
			uproar: ["7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			uturn: ["8V", "7M", "6M", "5M", "4M"],
			whirlwind: ["7E", "7V", "6E", "5E", "4E"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 22, moves: ["batonpass", "falseswipe", "leer", "aerialace"]},
		],
		encounters: [
			{generation: 1, level: 3},
			{generation: 2, level: 2},
			{generation: 3, level: 3},
		],
	},
	fearow: {
		learnset: {
			aerialace: ["7M", "7L15", "6M", "6L17", "5M", "5L17", "4M", "4L17", "3M"],
			agility: ["8V", "7L27", "7V", "6L29", "5L29", "4L29", "3L47"],
			aircutter: ["4T"],
			assurance: ["7L23", "6L35", "5L35", "4L35"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillpeck: ["8V", "7L41", "7V", "6L47", "5L47", "4L47", "3L40"],
			drillrun: ["8V", "7T", "7L1", "6T", "6L1", "5T", "5L53"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["7M", "6M", "5M"],
			fly: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			focusenergy: ["8V", "7L32"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L11", "7V", "6L1", "5L1", "4L1", "3L1"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			growl: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V"],
			heatwave: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			laserfocus: ["7T"],
			leer: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["7V", "3T"],
			mirrormove: ["8V", "7L18", "7V", "6L23", "5L23", "4L23", "3L32"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			peck: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			pluck: ["7L1", "6L1", "5M", "5L1", "4M", "4L1"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L1", "7V", "6L13", "5L13", "4L13", "3L26"],
			quickattack: ["8V"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "7L36", "6M", "6L41", "5T", "5L41", "4M", "4L41"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "7V", "6M", "4M", "3M"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8V"],
			twister: ["4T"],
			uproar: ["7T", "6T", "5T"],
			uturn: ["8V", "7M", "6M", "5M", "4M"],
			whirlwind: ["7V"],
			workup: ["7M", "5M"],
		},
		encounters: [
			{generation: 1, level: 19},
			{generation: 2, level: 7},
			{generation: 4, level: 7},
		],
	},
	ekans: {
		learnset: {
			acid: ["8V", "7L20", "7V", "6L20", "5L20", "4L20", "3L32"],
			acidspray: ["7L28", "6L28", "5L28"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			belch: ["7L38", "6L38"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			bite: ["8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L13", "3S0"],
			bodyslam: ["7V", "3T"],
			brutalswing: ["7M"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			coil: ["7L44", "6L44", "5L44"],
			confide: ["7M", "6M"],
			crunch: ["7V"],
			curse: ["7V"],
			darkpulse: ["8V", "7M", "6M", "5T", "5D", "4M"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			disable: ["7E", "6E", "5E", "4E"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthquake: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "7L36", "6T", "6L36", "5T", "5L36", "4T", "4L33"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			glare: ["8V", "7L12", "7V", "6L12", "5L12", "4L12", "3L20"],
			gunkshot: ["7T", "7L49", "6T", "6L49", "5T", "5L49", "4T", "4L41"],
			haze: ["8V", "7L41", "7V", "6L41", "5L41", "4L36", "3L44"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			irontail: ["8V", "7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			leer: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			mudbomb: ["7L33", "6L33", "5L33", "4L28"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			poisonfang: ["7E", "6E", "5E", "4E", "3E"],
			poisonjab: ["8V", "7M", "6M", "5M", "4M"],
			poisonsting: ["8V", "7L4", "7V", "6L4", "5L4", "5D", "4L4", "3L8", "3S0", "3S1"],
			poisontail: ["7E", "6E", "5E", "4E"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["7E", "6E", "5E", "4E"],
			screech: ["8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L25"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			slam: ["8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["7M", "6M", "5M"],
			snatch: ["7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			spitup: ["7L25", "6L25", "5L25", "4L25", "3L37"],
			stockpile: ["7L25", "6L25", "5L25", "4L25", "3L37"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["7E", "6E", "5E"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["7L25", "6L25", "5L25", "4L25", "3L37"],
			switcheroo: ["7E", "6E", "5E", "4E"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["7M", "6M", "5M"],
			wrap: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
		},
		eventData: [
			{generation: 3, level: 14, gender: "F", nature: "Docile", ivs: {hp: 26, atk: 28, def: 6, spa: 14, spd: 30, spe: 11}, abilities: ["shedskin"], moves: ["leer", "wrap", "poisonsting", "bite"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["wrap", "leer", "poisonsting"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 6},
			{generation: 2, level: 4},
		],
	},
	arbok: {
		learnset: {
			acid: ["8V", "7L20", "7V", "6L20", "5L20", "4L20", "3L38"],
			acidspray: ["7L32", "6L32", "5L32"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			belch: ["7L48", "6L48"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			bite: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			bodyslam: ["7V", "3T"],
			brutalswing: ["7M"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			coil: ["7L56", "6L56", "5L56"],
			confide: ["7M", "6M"],
			crunch: ["8V", "7L1", "6L22", "5L22", "4L22"],
			curse: ["7V"],
			darkpulse: ["8V", "7M", "6M", "5T", "4M"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragontail: ["8V", "7M", "6M", "5M"],
			earthquake: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["7L1", "6L1", "5L1", "4L1"],
			fissure: ["7V"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "7L44", "6T", "6L44", "5T", "5L44", "4T", "4L42"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			glare: ["8V", "7L12", "7V", "6L12", "5L12", "4L12", "3L20", "3S0"],
			gunkshot: ["7T", "7L63", "6T", "6L63", "5T", "5L63", "4T", "4L56"],
			haze: ["8V", "7L51", "7V", "6L51", "5L51", "4L48", "3L56"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icefang: ["7L1", "6L1", "5L1", "4L1"],
			infestation: ["7M", "6M"],
			irontail: ["8V", "7T", "6T", "5T", "4M", "3M"],
			leer: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			mudbomb: ["7L39", "6L39", "5L39", "4L34"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			poisonjab: ["8V", "7M", "6M", "5M", "4M"],
			poisonsting: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			screech: ["8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L28"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			slam: ["8V"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			sludgewave: ["7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			spitup: ["7L27", "6L27", "5L27", "4L28", "3L46"],
			stockpile: ["7L27", "6L27", "5L27", "4L28", "3L46"],
			stompingtantrum: ["7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8V"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["7L27", "6L27", "5L27", "4L28", "3L46"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			thunderfang: ["7L1", "6L1", "5L1", "4L1"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["7M", "6M", "5M"],
			wrap: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
		},
		eventData: [
			{generation: 3, level: 33, moves: ["refresh", "sludgebomb", "glare", "bite"]},
		],
		encounters: [
			{generation: 2, level: 10},
			{generation: 4, level: 10},
		],
	},
	pichu: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bestow: ["7E", "6E", "5E"],
			bide: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bodyslam: ["9M", "8M", "3T"],
			captivate: ["4M"],
			charge: ["9E", "8E", "7E", "6E", "5E", "4E", "4S5", "3E"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["9M", "9L20", "8M", "8L20", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1", "3S2", "3S3"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			disarmingvoice: ["9M", "9E", "8E", "7E", "6E"],
			doubleedge: ["3T"],
			doubleslap: ["7E", "7V", "6E", "5E", "4E", "3E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			electricterrain: ["9M", "8M", "7E"],
			electroball: ["9M"],
			electroweb: ["8M", "7T", "6T"],
			encore: ["9M", "8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			endeavor: ["4S5"],
			endure: ["9M", "8M", "7E", "7V", "6E", "5E", "4M", "4S5", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9E", "8E", "7E", "6E", "5E", "4E"],
			flail: ["9E", "8E", "7E", "6E", "5E", "4E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			followme: ["3S3"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M", "4S4"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7E", "6E", "5E"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "9L16", "8M", "8L16", "7L13", "6L13", "5L18", "4L18"],
			naturalgift: ["4M"],
			nuzzle: ["9L12", "8L12"],
			playnice: ["9L4", "8L4"],
			playrough: ["9M", "8M"],
			present: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "4S4", "3M"],
			reversal: ["9M", "8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["9M", "8M", "3S0"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["9L8", "8L8", "7L10", "7V", "6L10", "5L13", "4L13", "3L11"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwhip: ["9L1", "8L1", "7L5", "7V", "6L5", "5L5", "4L5", "3L6"],
			takedown: ["9M"],
			teeterdance: ["3S2"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "4S4", "3M"],
			thunderpunch: ["9M", "8M", "7T", "7E", "6T", "6E", "5E", "4E"],
			thundershock: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1", "3S2", "3S3"],
			thunderwave: ["9M", "8M", "7M", "7L18", "7V", "6M", "6L13", "5M", "5L10", "4M", "4L10", "3T", "3L8"],
			tickle: ["9E", "8E", "7E", "6E", "5E", "4E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M"],
			volttackle: ["9R", "8R", "7R", "6E", "5E", "4E", "4S4", "4S5", "3E"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			wish: ["9E", "8E", "7E", "6E", "5E", "4E", "3E", "3S1"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["thundershock", "charm", "surf"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, moves: ["thundershock", "charm", "wish"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, moves: ["thundershock", "charm", "teeterdance"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, moves: ["thundershock", "charm", "followme"], pokeball: "pokeball"},
			{generation: 4, level: 1, moves: ["volttackle", "thunderbolt", "grassknot", "return"], pokeball: "pokeball"},
			{generation: 4, level: 30, shiny: true, gender: "M", nature: "Jolly", moves: ["charge", "volttackle", "endeavor", "endure"], pokeball: "cherishball"},
		],
	},
	pichuspikyeared: {
		learnset: {
			attract: ["4M"],
			captivate: ["4M"],
			chargebeam: ["4M"],
			charm: ["4L1"],
			doubleteam: ["4M"],
			endure: ["4M"],
			facade: ["4M"],
			flash: ["4M"],
			fling: ["4M"],
			frustration: ["4M"],
			grassknot: ["4M"],
			headbutt: ["4T"],
			helpinghand: ["4T", "4S0"],
			hiddenpower: ["4M"],
			irontail: ["4M"],
			lightscreen: ["4M"],
			magnetrise: ["4T"],
			mudslap: ["4T"],
			nastyplot: ["4L18"],
			naturalgift: ["4M"],
			painsplit: ["4S0"],
			protect: ["4M"],
			raindance: ["4M"],
			rest: ["4M"],
			return: ["4M"],
			rollout: ["4T"],
			secretpower: ["4M"],
			shockwave: ["4M"],
			signalbeam: ["4T"],
			sleeptalk: ["4M"],
			snore: ["4T"],
			substitute: ["4M"],
			swagger: ["4M", "4S0"],
			sweetkiss: ["4L13"],
			swift: ["4T"],
			tailwhip: ["4L5"],
			thunder: ["4M"],
			thunderbolt: ["4M"],
			thundershock: ["4L1"],
			thunderwave: ["4M", "4L10"],
			toxic: ["4M"],
			uproar: ["4T"],
			volttackle: ["4S0"],
		},
		eventData: [
			{generation: 4, level: 30, gender: "F", nature: "Naughty", moves: ["helpinghand", "volttackle", "swagger", "painsplit"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachu: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24", "8V", "7L45", "7V", "6L37", "6S41", "5L37", "4L34", "3L33", "3S0", "3S8"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bestow: ["7S44", "6S42"],
			bide: ["7V"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "5S26", "4M", "3M"],
			calmmind: ["8V"],
			captivate: ["4M"],
			celebrate: ["8S50", "8S51", "8S52", "7S43", "7S48", "6S31", "6S41"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1", "6S36"],
			confide: ["7M", "6M"],
			counter: ["7S48", "3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			disarmingvoice: ["9M"],
			discharge: ["9L32", "8L32", "7L34", "7S47", "6L34", "5L42", "4L37"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8V"],
			doubleteam: ["9L8", "8L8", "8V", "7M", "7L23", "7V", "6M", "6L21", "6S32", "5M", "5L21", "4M", "4L18", "4S13", "3M", "3L15"],
			drainingkiss: ["9M", "8M"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12", "8S52", "7L13", "6L13", "6S32", "6S37", "5L18", "5S23", "5S24", "5S30"],
			electroweb: ["8M", "7T", "6T"],
			encore: ["9M", "8M", "8S52", "6S39", "5S23"],
			endeavor: ["6S39"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			extremespeed: ["5S26"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["6S39"],
			faketears: ["9M"],
			feint: ["9L16", "8L16", "7L21", "6L21", "5L34", "5S29", "4L29"],
			flash: ["7V", "6M", "6S40", "5M", "4M", "4S13", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fly: ["9S53", "7S49", "6S41", "5S24", "5S27", "3S2", "3S4", "3S6"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "5S25", "5S26", "5S27", "4M", "4S13"],
			growl: ["9L1", "8L1", "8V", "7L5", "7V", "7S43", "7S46", "6L5", "6S31", "5L1", "4L1", "3L1", "3S1", "3S2", "3S3", "3S4", "3S5", "3S10"],
			happyhour: ["7S45", "7S46", "6S40"],
			headbutt: ["8V", "7V", "5S28", "4T"],
			heartstamp: ["6S34"],
			helpinghand: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			holdhands: ["7S44", "7S45", "6S33", "6S34", "6S35", "6S40", "6S42"],
			irontail: ["9L28", "8M", "8V", "7T", "7V", "6T", "6S37", "5T", "5S24", "5S30", "4M", "4S21", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["4S18"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "8V", "7M", "7L53", "7V", "6M", "6L45", "5M", "5L45", "4M", "4L42", "4S11", "3M", "3L50", "3S0", "3S6", "3S7", "3S8"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "6S32", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			naturalgift: ["4M"],
			nuzzle: ["9L1", "8L1", "7L29", "7S47", "6L23", "6S36", "6S38"],
			payday: ["8M", "8V", "7V"],
			playnice: ["9L1", "8L1", "8S50", "7L7", "7S43", "7S44", "7S45", "6L7", "6S31", "6S35", "6S36", "6S38", "6S40", "6S42"],
			playrough: ["9M", "8M"],
			present: ["4S12", "4S15", "4S17", "4S18", "4S20", "4S22"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "5S27", "4M", "4S14", "4S16", "3M"],
			quickattack: ["9L1", "9S53", "8L1", "8V", "8S50", "7L10", "7V", "7S43", "7S46", "7S49", "6L10", "6S31", "6S32", "6S33", "6S34", "6S37", "5L13", "5S24", "5S25", "5S29", "5S30", "4L13", "4S11", "4S12", "4S15", "4S17", "4S18", "4S20", "4S21", "4S22", "3L11"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M", "8M", "8V", "7V"],
			refresh: ["7S48"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "4S19", "3M"],
			return: ["7M", "7V", "7S44", "6M", "6S42", "5M", "4M", "3M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["8S52", "5S23"],
			skullbash: ["7V"],
			slam: ["8L28", "8V", "7L37", "7V", "7S47", "6L26", "5L26", "4L21", "3L20"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "4S19", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "4S19", "3T"],
			spark: ["9L20", "8L20", "7L26", "6L26"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "6S35", "5M", "4M", "3T"],
			surf: ["9M", "8M", "7S47", "7S49", "6S33", "6S41", "4S9", "4S11", "4S14", "4S16", "3S3", "3S5", "3S7"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["9L1", "8L1", "6S36"],
			sweetscent: ["7S48"],
			swift: ["9M", "8M", "8S51", "7V", "4T", "3T"],
			tailwhip: ["9L1", "9S53", "8L1", "8V", "7L1", "7V", "6L1", "6S38", "5L5", "5S28", "4L5", "4S9", "4S12", "4S15", "4S17", "4S20", "4S22", "3L6", "3S1", "3S2", "3S3", "3S4", "3S10"],
			takedown: ["9M", "7V"],
			teeterdance: ["7S45", "6S38", "5S23"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44", "8V", "7M", "7L58", "7V", "6M", "6L50", "6S35", "5M", "5L50", "5S25", "4M", "4L45", "4S14", "4S16", "3M", "3L41", "3S0", "3S6", "3S7", "3S8"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8V", "8S51", "7M", "7L42", "7V", "7S49", "6M", "6L29", "6S33", "6S34", "6S37", "5M", "5L29", "5S26", "5S27", "5S30", "4M", "4L26", "4S11", "4S13", "4S18", "4S21", "3M", "3L26", "3S0", "3S6", "3S7", "3S8"],
			thunderpunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thundershock: ["9L1", "9S53", "8L1", "8V", "8S50", "7L1", "7V", "7S46", "6L1", "5L1", "5S28", "4L1", "4S12", "4S15", "4S20", "4S22", "3L1", "3S1", "3S5", "3S10"],
			thunderwave: ["9M", "9L4", "8M", "8L4", "8V", "7M", "7L18", "7V", "6M", "6L13", "5M", "5L10", "5S28", "4M", "4L10", "4S9", "4S17", "3T", "3L8", "3S1", "3S2", "3S3", "3S4", "3S5", "3S10"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M", "5S29"],
			volttackle: ["7T", "6S39", "5S25", "5S29", "4S9", "4S21"],
			wildcharge: ["9M", "8M", "7M", "7L50", "6M", "6L50", "5M"],
			wish: ["8S51"],
			yawn: ["4S19"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 50, moves: ["thunderbolt", "agility", "thunder", "lightscreen"], pokeball: "pokeball"},
			{generation: 3, level: 10, moves: ["thundershock", "growl", "tailwhip", "thunderwave"], pokeball: "pokeball"},
			{generation: 3, level: 10, moves: ["fly", "tailwhip", "growl", "thunderwave"], pokeball: "pokeball"},
			{generation: 3, level: 5, moves: ["surf", "growl", "tailwhip", "thunderwave"], pokeball: "pokeball"},
			{generation: 3, level: 10, moves: ["fly", "growl", "tailwhip", "thunderwave"], pokeball: "pokeball"},
			{generation: 3, level: 10, moves: ["thundershock", "growl", "thunderwave", "surf"], pokeball: "pokeball"},
			{generation: 3, level: 70, moves: ["thunderbolt", "thunder", "lightscreen", "fly"], pokeball: "pokeball"},
			{generation: 3, level: 70, moves: ["thunderbolt", "thunder", "lightscreen", "surf"], pokeball: "pokeball"},
			{generation: 3, level: 70, moves: ["thunderbolt", "thunder", "lightscreen", "agility"], pokeball: "pokeball"},
			{generation: 4, level: 10, gender: "F", nature: "Hardy", moves: ["surf", "volttackle", "tailwhip", "thunderwave"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["thundershock", "growl", "tailwhip", "thunderwave"], pokeball: "pokeball"},
			{generation: 4, level: 50, gender: "M", nature: "Hardy", moves: ["surf", "thunderbolt", "lightscreen", "quickattack"], pokeball: "cherishball"},
			{generation: 4, level: 20, gender: "F", nature: "Bashful", moves: ["present", "quickattack", "thundershock", "tailwhip"], pokeball: "cherishball"},
			{generation: 4, level: 20, gender: "M", nature: "Jolly", moves: ["grassknot", "thunderbolt", "flash", "doubleteam"], pokeball: "pokeball"},
			{generation: 4, level: 40, gender: "M", nature: "Modest", moves: ["surf", "thunder", "protect"], pokeball: "cherishball"},
			{generation: 4, level: 20, gender: "F", nature: "Bashful", moves: ["quickattack", "thundershock", "tailwhip", "present"], pokeball: "cherishball"},
			{generation: 4, level: 40, gender: "M", nature: "Mild", moves: ["surf", "thunder", "protect"], pokeball: "cherishball"},
			{generation: 4, level: 20, gender: "F", nature: "Bashful", moves: ["present", "quickattack", "thunderwave", "tailwhip"], pokeball: "cherishball"},
			{generation: 4, level: 30, gender: "M", nature: "Naughty", moves: ["lastresort", "present", "thunderbolt", "quickattack"], pokeball: "cherishball"},
			{generation: 4, level: 50, gender: "M", nature: "Relaxed", moves: ["rest", "sleeptalk", "yawn", "snore"], pokeball: "cherishball"},
			{generation: 4, level: 20, gender: "M", nature: "Docile", moves: ["present", "quickattack", "thundershock", "tailwhip"], pokeball: "cherishball"},
			{generation: 4, level: 50, gender: "M", nature: "Naughty", moves: ["volttackle", "irontail", "quickattack", "thunderbolt"], pokeball: "cherishball"},
			{generation: 4, level: 20, gender: "M", nature: "Bashful", moves: ["present", "quickattack", "thundershock", "tailwhip"], pokeball: "cherishball"},
			{generation: 5, level: 30, gender: "F", isHidden: true, moves: ["sing", "teeterdance", "encore", "electroball"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["fly", "irontail", "electroball", "quickattack"], pokeball: "cherishball"},
			{generation: 5, level: 100, shiny: 1, gender: "F", moves: ["thunder", "volttackle", "grassknot", "quickattack"], pokeball: "cherishball"},
			{generation: 5, level: 50, shiny: 1, gender: "F", moves: ["extremespeed", "thunderbolt", "grassknot", "brickbreak"], pokeball: "cherishball"},
			{generation: 5, level: 50, gender: "F", nature: "Timid", isHidden: true, moves: ["fly", "thunderbolt", "grassknot", "protect"], pokeball: "cherishball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["thundershock", "tailwhip", "thunderwave", "headbutt"]},
			{generation: 5, level: 100, gender: "M", isHidden: true, moves: ["volttackle", "quickattack", "feint", "voltswitch"], pokeball: "cherishball"},
			{generation: 5, level: 50, gender: "M", nature: "Brave", moves: ["thunderbolt", "quickattack", "irontail", "electroball"], pokeball: "cherishball"},
			{generation: 6, level: 10, moves: ["celebrate", "growl", "playnice", "quickattack"], pokeball: "cherishball"},
			{generation: 6, level: 22, moves: ["quickattack", "electroball", "doubleteam", "megakick"], pokeball: "cherishball"},
			{generation: 6, level: 10, moves: ["thunderbolt", "quickattack", "surf", "holdhands"], pokeball: "cherishball"},
			{generation: 6, level: 10, gender: "F", moves: ["thunderbolt", "quickattack", "heartstamp", "holdhands"], pokeball: "healball"},
			{generation: 6, level: 36, shiny: true, isHidden: true, moves: ["thunder", "substitute", "playnice", "holdhands"], pokeball: "cherishball"},
			{generation: 6, level: 10, gender: "F", moves: ["playnice", "charm", "nuzzle", "sweetkiss"], pokeball: "cherishball"},
			{generation: 6, level: 50, gender: "M", nature: "Naughty", moves: ["thunderbolt", "quickattack", "irontail", "electroball"], pokeball: "cherishball"},
			{generation: 6, level: 10, shiny: true, moves: ["teeterdance", "playnice", "tailwhip", "nuzzle"], pokeball: "cherishball"},
			{generation: 6, level: 10, perfectIVs: 2, isHidden: true, moves: ["fakeout", "encore", "volttackle", "endeavor"], pokeball: "cherishball"},
			{generation: 6, level: 99, moves: ["happyhour", "playnice", "holdhands", "flash"], pokeball: "cherishball"},
			{generation: 6, level: 10, moves: ["fly", "surf", "agility", "celebrate"], pokeball: "cherishball"},
			{generation: 6, level: 10, moves: ["bestow", "holdhands", "return", "playnice"], pokeball: "healball"},
			{generation: 7, level: 10, nature: "Jolly", moves: ["celebrate", "growl", "playnice", "quickattack"], pokeball: "cherishball"},
			{generation: 7, level: 10, moves: ["bestow", "holdhands", "return", "playnice"], pokeball: "cherishball"},
			{generation: 7, level: 10, moves: ["holdhands", "playnice", "teeterdance", "happyhour"], pokeball: "cherishball"},
			{generation: 7, level: 10, moves: ["growl", "quickattack", "thundershock", "happyhour"], pokeball: "cherishball"},
			{generation: 7, level: 40, shiny: 1, perfectIVs: 3, moves: ["nuzzle", "discharge", "slam", "surf"], pokeball: "pokeball"},
			{generation: 7, level: 5, moves: ["celebrate", "sweetscent", "counter", "refresh"], pokeball: "cherishball"},
			{generation: 7, level: 10, moves: ["fly", "surf", "thunderbolt", "quickattack"], pokeball: "cherishball"},
			{generation: 8, level: 5, gender: "M", nature: "Serious", moves: ["celebrate", "playnice", "thundershock", "quickattack"], pokeball: "cherishball"},
			{generation: 8, level: 21, gender: "M", nature: "Brave", moves: ["thunderbolt", "swift", "wish", "celebrate"], pokeball: "cherishball"},
			{generation: 8, level: 25, isHidden: true, moves: ["sing", "encore", "celebrate", "electroball"], pokeball: "cherishball"},
			{generation: 9, level: 5, gender: "M", moves: ["fly", "tailwhip", "thundershock", "quickattack"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 3},
			{generation: 2, level: 4},
			{generation: 3, level: 3},
		],
	},
	pikachucosplay: {
		learnset: {
			agility: ["6L45"],
			attract: ["6M"],
			brickbreak: ["6M"],
			chargebeam: ["6M"],
			confide: ["6M"],
			covet: ["6T"],
			dig: ["6M"],
			discharge: ["6L34"],
			doubleteam: ["6M", "6L23"],
			echoedvoice: ["6M"],
			electroball: ["6L13", "6S0"],
			electroweb: ["6T"],
			facade: ["6M"],
			feint: ["6L21"],
			flash: ["6M"],
			fling: ["6M"],
			focuspunch: ["6T"],
			frustration: ["6M"],
			grassknot: ["6M"],
			growl: ["6L5"],
			helpinghand: ["6T"],
			hiddenpower: ["6M"],
			irontail: ["6T"],
			knockoff: ["6T"],
			lightscreen: ["6M", "6L53"],
			magnetrise: ["6T"],
			nuzzle: ["6L29"],
			playnice: ["6L7"],
			protect: ["6M"],
			quickattack: ["6L10", "6S0"],
			raindance: ["6M"],
			rest: ["6M"],
			return: ["6M"],
			rocksmash: ["6M"],
			round: ["6M"],
			secretpower: ["6M"],
			shockwave: ["6T"],
			signalbeam: ["6T"],
			slam: ["6L37"],
			sleeptalk: ["6M"],
			snore: ["6T"],
			spark: ["6L26"],
			strength: ["6M"],
			substitute: ["6M"],
			swagger: ["6M"],
			tailwhip: ["6L1"],
			thunder: ["6M", "6L58"],
			thunderbolt: ["6M", "6L42"],
			thunderpunch: ["6T"],
			thundershock: ["6L1", "6S0"],
			thunderwave: ["6M", "6L18", "6S0"],
			toxic: ["6M"],
			voltswitch: ["6M"],
			wildcharge: ["6M", "6L50"],
		},
		eventData: [
			{generation: 6, level: 20, perfectIVs: 3, moves: ["quickattack", "electroball", "thunderwave", "thundershock"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachurockstar: {
		learnset: {
			meteormash: ["6R"],
		},
		eventOnly: true,
	},
	pikachubelle: {
		learnset: {
			iciclecrash: ["6R"],
		},
		eventOnly: true,
	},
	pikachupopstar: {
		learnset: {
			drainingkiss: ["6R"],
		},
		eventOnly: true,
	},
	pikachuphd: {
		learnset: {
			electricterrain: ["6R"],
		},
		eventOnly: true,
	},
	pikachulibre: {
		learnset: {
			flyingpress: ["6R"],
		},
		eventOnly: true,
	},
	pikachuoriginal: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24", "7L45", "7S0"],
			attract: ["8M", "7M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M"],
			charge: ["8E"],
			chargebeam: ["9M", "7M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M"],
			covet: ["7T"],
			dig: ["9M", "8M"],
			disarmingvoice: ["9M", "8E"],
			discharge: ["9L32", "8L32", "7L34"],
			doubleteam: ["9L8", "8L8", "7M", "7L23"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L13"],
			electroweb: ["8M", "8S1", "7T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["8E"],
			faketears: ["9M"],
			feint: ["9L16", "8L16", "7L21"],
			flail: ["8E"],
			fling: ["9M", "8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			grassknot: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "7L5"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			irontail: ["9L28", "8M", "8S1", "7T"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "7M", "7L53"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1", "7L29"],
			payday: ["8M"],
			playnice: ["9L1", "8L1", "7L7"],
			playrough: ["9M", "8M"],
			present: ["8E"],
			protect: ["9M", "8M", "7M"],
			quickattack: ["9L1", "8L1", "8S1", "7L10", "7S0"],
			raindance: ["9M", "8M", "7M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			slam: ["8L28", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["9L20", "8L20", "7L26"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M"],
			swagger: ["7M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44", "7M", "7L58", "7S0"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8S1", "7M", "7L42", "7S0"],
			thunderpunch: ["9M", "8M", "7T"],
			thundershock: ["9L1", "8L1", "7L1"],
			thunderwave: ["9M", "9L4", "8M", "8L4", "7M", "7L18"],
			tickle: ["8E"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M"],
			volttackle: ["8S1", "7T"],
			wildcharge: ["9M", "8M", "7M", "7L50"],
			wish: ["8E"],
		},
		eventData: [
			{generation: 7, level: 1, nature: "Hardy", moves: ["thunderbolt", "quickattack", "thunder", "agility"], pokeball: "pokeball"},
			{generation: 8, level: 25, nature: "Hardy", isHidden: false, moves: ["thunderbolt", "quickattack", "irontail", "electroweb", "volttackle"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachuhoenn: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24", "7L45"],
			attract: ["8M", "7M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M"],
			charge: ["8E"],
			chargebeam: ["9M", "7M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M"],
			covet: ["7T"],
			dig: ["9M", "8M"],
			disarmingvoice: ["9M", "8E"],
			discharge: ["9L32", "8L32", "7L34"],
			doubleteam: ["9L8", "8L8", "7M", "7L23"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L13"],
			electroweb: ["8M", "8S1", "7T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["8E"],
			faketears: ["9M"],
			feint: ["9L16", "8L16", "7L21"],
			flail: ["8E"],
			fling: ["9M", "8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			grassknot: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "7L5"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			irontail: ["9L28", "8M", "8S1", "7T", "7S0"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "7M", "7L53"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1", "7L29"],
			payday: ["8M"],
			playnice: ["9L1", "8L1", "7L7"],
			playrough: ["9M", "8M"],
			present: ["8E"],
			protect: ["9M", "8M", "7M"],
			quickattack: ["9L1", "8L1", "8S1", "7L10", "7S0"],
			raindance: ["9M", "8M", "7M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			slam: ["8L28", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["9L20", "8L20", "7L26"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M"],
			swagger: ["7M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44", "7M", "7L58", "7S0"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8S1", "7M", "7L42", "7S0"],
			thunderpunch: ["9M", "8M", "7T"],
			thundershock: ["9L1", "8L1", "7L1"],
			thunderwave: ["9M", "9L4", "8M", "8L4", "7M", "7L18"],
			tickle: ["8E"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M"],
			volttackle: ["8S1", "7T"],
			wildcharge: ["9M", "8M", "7M", "7L50"],
			wish: ["8E"],
		},
		eventData: [
			{generation: 7, level: 6, nature: "Hardy", moves: ["thunderbolt", "quickattack", "thunder", "irontail"], pokeball: "pokeball"},
			{generation: 8, level: 25, nature: "Hardy", isHidden: false, moves: ["thunderbolt", "quickattack", "irontail", "electroweb", "volttackle"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachusinnoh: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24", "7L45"],
			attract: ["8M", "7M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M"],
			charge: ["8E"],
			chargebeam: ["9M", "7M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M"],
			covet: ["7T"],
			dig: ["9M", "8M"],
			disarmingvoice: ["9M", "8E"],
			discharge: ["9L32", "8L32", "7L34"],
			doubleteam: ["9L8", "8L8", "7M", "7L23"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L13"],
			electroweb: ["8M", "8S1", "7T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["8E"],
			faketears: ["9M"],
			feint: ["9L16", "8L16", "7L21"],
			flail: ["8E"],
			fling: ["9M", "8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			grassknot: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "7L5"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			irontail: ["9L28", "8M", "8S1", "7T", "7S0"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "7M", "7L53"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1", "7L29"],
			payday: ["8M"],
			playnice: ["9L1", "8L1", "7L7"],
			playrough: ["9M", "8M"],
			present: ["8E"],
			protect: ["9M", "8M", "7M"],
			quickattack: ["9L1", "8L1", "8S1", "7L10", "7S0"],
			raindance: ["9M", "8M", "7M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			slam: ["8L28", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["9L20", "8L20", "7L26"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M"],
			swagger: ["7M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44", "7M", "7L58"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8S1", "7M", "7L42", "7S0"],
			thunderpunch: ["9M", "8M", "7T"],
			thundershock: ["9L1", "8L1", "7L1"],
			thunderwave: ["9M", "9L4", "8M", "8L4", "7M", "7L18"],
			tickle: ["8E"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M"],
			volttackle: ["8S1", "7T", "7S0"],
			wildcharge: ["9M", "8M", "7M", "7L50"],
			wish: ["8E"],
		},
		eventData: [
			{generation: 7, level: 10, nature: "Hardy", moves: ["thunderbolt", "quickattack", "irontail", "volttackle"], pokeball: "pokeball"},
			{generation: 8, level: 25, nature: "Hardy", isHidden: false, moves: ["thunderbolt", "quickattack", "irontail", "electroweb", "volttackle"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachuunova: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24", "7L45"],
			attract: ["8M", "7M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M"],
			charge: ["8E"],
			chargebeam: ["9M", "7M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M"],
			covet: ["7T"],
			dig: ["9M", "8M"],
			disarmingvoice: ["9M", "8E"],
			discharge: ["9L32", "8L32", "7L34"],
			doubleteam: ["9L8", "8L8", "7M", "7L23"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L13"],
			electroweb: ["8M", "8S1", "7T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["8E"],
			faketears: ["9M"],
			feint: ["9L16", "8L16", "7L21"],
			flail: ["8E"],
			fling: ["9M", "8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			grassknot: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "7L5"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			irontail: ["9L28", "8M", "8S1", "7T", "7S0"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "7M", "7L53"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1", "7L29"],
			payday: ["8M"],
			playnice: ["9L1", "8L1", "7L7"],
			playrough: ["9M", "8M"],
			present: ["8E"],
			protect: ["9M", "8M", "7M"],
			quickattack: ["9L1", "8L1", "8S1", "7L10", "7S0"],
			raindance: ["9M", "8M", "7M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			slam: ["8L28", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["9L20", "8L20", "7L26"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M"],
			swagger: ["7M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44", "7M", "7L58"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8S1", "7M", "7L42", "7S0"],
			thunderpunch: ["9M", "8M", "7T"],
			thundershock: ["9L1", "8L1", "7L1"],
			thunderwave: ["9M", "9L4", "8M", "8L4", "7M", "7L18"],
			tickle: ["8E"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M"],
			volttackle: ["8S1", "7T", "7S0"],
			wildcharge: ["9M", "8M", "7M", "7L50"],
			wish: ["8E"],
		},
		eventData: [
			{generation: 7, level: 14, nature: "Hardy", moves: ["thunderbolt", "quickattack", "irontail", "volttackle"], pokeball: "pokeball"},
			{generation: 8, level: 25, nature: "Hardy", isHidden: false, moves: ["thunderbolt", "quickattack", "irontail", "electroweb", "volttackle"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachukalos: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24", "7L45"],
			attract: ["8M", "7M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M"],
			charge: ["8E"],
			chargebeam: ["9M", "7M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M"],
			covet: ["7T"],
			dig: ["9M", "8M"],
			disarmingvoice: ["9M", "8E"],
			discharge: ["9L32", "8L32", "7L34"],
			doubleteam: ["9L8", "8L8", "7M", "7L23"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L13", "7S0"],
			electroweb: ["8M", "8S1", "7T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["8E"],
			faketears: ["9M"],
			feint: ["9L16", "8L16", "7L21"],
			flail: ["8E"],
			fling: ["9M", "8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			grassknot: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "7L5"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			irontail: ["9L28", "8M", "8S1", "7T", "7S0"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "7M", "7L53"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1", "7L29"],
			payday: ["8M"],
			playnice: ["9L1", "8L1", "7L7"],
			playrough: ["9M", "8M"],
			present: ["8E"],
			protect: ["9M", "8M", "7M"],
			quickattack: ["9L1", "8L1", "8S1", "7L10", "7S0"],
			raindance: ["9M", "8M", "7M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			slam: ["8L28", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["9L20", "8L20", "7L26"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M"],
			swagger: ["7M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44", "7M", "7L58"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8S1", "7M", "7L42", "7S0"],
			thunderpunch: ["9M", "8M", "7T"],
			thundershock: ["9L1", "8L1", "7L1"],
			thunderwave: ["9M", "9L4", "8M", "8L4", "7M", "7L18"],
			tickle: ["8E"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M"],
			volttackle: ["8S1", "7T"],
			wildcharge: ["9M", "8M", "7M", "7L50"],
			wish: ["8E"],
		},
		eventData: [
			{generation: 7, level: 17, nature: "Hardy", moves: ["thunderbolt", "quickattack", "irontail", "electroball"], pokeball: "pokeball"},
			{generation: 8, level: 25, nature: "Hardy", isHidden: false, moves: ["thunderbolt", "quickattack", "irontail", "electroweb", "volttackle"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachualola: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24", "7L45"],
			attract: ["8M", "7M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M"],
			charge: ["8E"],
			chargebeam: ["9M", "7M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M"],
			covet: ["7T"],
			dig: ["9M", "8M"],
			disarmingvoice: ["9M", "8E"],
			discharge: ["9L32", "8L32", "7L34"],
			doubleteam: ["9L8", "8L8", "7M", "7L23"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L13", "7S0"],
			electroweb: ["8M", "8S1", "7T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["8E"],
			faketears: ["9M"],
			feint: ["9L16", "8L16", "7L21"],
			flail: ["8E"],
			fling: ["9M", "8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			grassknot: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "7L5"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			irontail: ["9L28", "8M", "8S1", "7T", "7S0"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "7M", "7L53"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1", "7L29"],
			payday: ["8M"],
			playnice: ["9L1", "8L1", "7L7"],
			playrough: ["9M", "8M"],
			present: ["8E"],
			protect: ["9M", "8M", "7M"],
			quickattack: ["9L1", "8L1", "8S1", "7L10", "7S0"],
			raindance: ["9M", "8M", "7M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			slam: ["8L28", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["9L20", "8L20", "7L26"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M"],
			swagger: ["7M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44", "7M", "7L58"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8S1", "7M", "7L42", "7S0"],
			thunderpunch: ["9M", "8M", "7T"],
			thundershock: ["9L1", "8L1", "7L1"],
			thunderwave: ["9M", "9L4", "8M", "8L4", "7M", "7L18"],
			tickle: ["8E"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M"],
			volttackle: ["8S1", "7T"],
			wildcharge: ["9M", "8M", "7M", "7L50"],
			wish: ["8E"],
		},
		eventData: [
			{generation: 7, level: 20, nature: "Hardy", moves: ["thunderbolt", "quickattack", "irontail", "electroball"], pokeball: "pokeball"},
			{generation: 8, level: 25, nature: "Hardy", isHidden: false, moves: ["thunderbolt", "quickattack", "irontail", "electroweb", "volttackle"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachupartner: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24", "7L45"],
			attract: ["8M", "7M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M"],
			charge: ["8E"],
			chargebeam: ["9M", "7M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M"],
			covet: ["7T"],
			dig: ["9M", "8M"],
			disarmingvoice: ["9M", "8E"],
			discharge: ["9L32", "8L32", "7L34"],
			doubleteam: ["9L8", "8L8", "7M", "7L23"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L13"],
			electroweb: ["8M", "8S1", "7T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["8E"],
			faketears: ["9M"],
			feint: ["9L16", "8L16", "7L21"],
			flail: ["8E"],
			fling: ["9M", "8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			grassknot: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "7L5"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			irontail: ["9L28", "8M", "8S1", "7T", "7S0"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "7M", "7L53"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1", "7L29"],
			payday: ["8M"],
			playnice: ["9L1", "8L1", "7L7"],
			playrough: ["9M", "8M"],
			present: ["8E"],
			protect: ["9M", "8M", "7M"],
			quickattack: ["9L1", "8L1", "8S1", "7L10", "7S0"],
			raindance: ["9M", "8M", "7M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			slam: ["8L28", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["9L20", "8L20", "7L26"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M"],
			swagger: ["7M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44", "7M", "7L58", "7S0"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8S1", "7M", "7L42", "7S0"],
			thunderpunch: ["9M", "8M", "7T"],
			thundershock: ["9L1", "8L1", "7L1"],
			thunderwave: ["9M", "9L4", "8M", "8L4", "7M", "7L18"],
			tickle: ["8E"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M"],
			volttackle: ["8S1", "7T"],
			wildcharge: ["9M", "8M", "7M", "7L50"],
			wish: ["8E"],
		},
		eventData: [
			{generation: 7, level: 21, shiny: 1, nature: "Hardy", moves: ["thunderbolt", "quickattack", "thunder", "irontail"], pokeball: "pokeball"},
			{generation: 8, level: 25, nature: "Hardy", isHidden: false, moves: ["thunderbolt", "quickattack", "irontail", "electroweb", "volttackle"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachustarter: {
		learnset: {
			agility: ["8V", "7L27"],
			brickbreak: ["8V", "7M"],
			calmmind: ["8V", "7M"],
			dig: ["8V", "7M"],
			doublekick: ["8V", "7L9"],
			doubleteam: ["8V", "7L12"],
			facade: ["8V", "7M"],
			floatyfall: ["8V", "7T"],
			growl: ["8V", "7L1", "7S0"],
			headbutt: ["8V", "7M"],
			helpinghand: ["8V", "7M"],
			irontail: ["8V", "7M"],
			lightscreen: ["8V", "7M", "7L18"],
			payday: ["8V", "7M"],
			pikapapow: ["8V", "7T"],
			protect: ["8V", "7M"],
			quickattack: ["8V", "7L6"],
			reflect: ["8V", "7M"],
			rest: ["8V", "7M"],
			seismictoss: ["8V", "7M"],
			slam: ["8V", "7L24"],
			splishysplash: ["8V", "7T"],
			substitute: ["8V", "7M"],
			tailwhip: ["8V", "7L3", "7S0"],
			thunder: ["8V", "7M", "7L30"],
			thunderbolt: ["8V", "7M", "7L21"],
			thunderpunch: ["8V", "7M"],
			thundershock: ["8V", "7L1", "7S0"],
			thunderwave: ["8V", "7M", "7L15"],
			toxic: ["8V", "7M"],
			zippyzap: ["8V", "7T"],
		},
		eventData: [
			{generation: 7, level: 5, perfectIVs: 6, moves: ["thundershock", "tailwhip", "growl"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	pikachuworld: {
		learnset: {
			agility: ["9M", "9L24", "8M", "8L24"],
			attract: ["8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M"],
			charge: ["8E"],
			chargebeam: ["9M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			dig: ["9M", "8M"],
			disarmingvoice: ["9M", "8E"],
			discharge: ["9L32", "8L32"],
			doubleteam: ["9L8", "8L8"],
			drainingkiss: ["9M", "8M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L12", "8M", "8L12"],
			electroweb: ["8M", "8S1", "8S0"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fakeout: ["8E"],
			faketears: ["9M"],
			feint: ["9L16", "8L16"],
			flail: ["8E"],
			fling: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			helpinghand: ["9M", "8M"],
			irontail: ["9L28", "8M", "8S1", "8S0"],
			lightscreen: ["9M", "9L40", "8M", "8L40"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1"],
			payday: ["8M"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			present: ["8E"],
			protect: ["9M", "8M"],
			quickattack: ["9L1", "8L1", "8S1", "8S0"],
			raindance: ["9M", "8M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M"],
			slam: ["8L28"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			spark: ["9L20", "8L20"],
			substitute: ["9M", "8M"],
			surf: ["9M", "8M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "9L44", "8M", "8L44"],
			thunderbolt: ["9M", "9L36", "8M", "8L36", "8S1", "8S0"],
			thunderpunch: ["9M", "8M"],
			thundershock: ["9L1", "8L1"],
			thunderwave: ["9M", "9L4", "8M", "8L4"],
			tickle: ["8E"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M"],
			volttackle: ["8S0"],
			wildcharge: ["9M", "8M"],
			wish: ["8E"],
		},
		eventData: [
			{generation: 8, level: 25, nature: "Hardy", moves: ["thunderbolt", "quickattack", "irontail", "electroweb", "volttackle"], pokeball: "pokeball"},
			{generation: 8, level: 80, nature: "Hardy", ivs: {hp: 31, atk: 30, def: 30, spa: 31, spd: 30, spe: 31}, moves: ["thunderbolt", "quickattack", "irontail", "electroweb"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	raichu: {
		learnset: {
			agility: ["9M", "9L1", "8M", "8L1"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M"],
			calmmind: ["8V"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			disarmingvoice: ["9M"],
			discharge: ["9L1", "8L1"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["9L1", "8L1", "7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M", "8M"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			eerieimpulse: ["9M", "8M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L1", "8M", "8L1"],
			electroweb: ["8M", "7T", "6T"],
			encore: ["9M", "8M", "8V"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8V"],
			feint: ["9L1", "8L1"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "8V", "7V"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			irontail: ["9L1", "8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L1", "8M", "8L1", "8V", "7M", "6M", "5M", "4M", "3M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			naturalgift: ["4M"],
			nuzzle: ["9L1", "8L1"],
			payday: ["8M", "8V", "7V"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M", "8M", "8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			slam: ["8L1"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spark: ["9L1", "8L1"],
			speedswap: ["8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["9M", "8M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwhip: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "9L5", "8M", "8L1", "8V", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			thunderpunch: ["9M", "9L0", "8M", "8L0", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thundershock: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thunderwave: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
	},
	raichualola: {
		learnset: {
			agility: ["9M", "9L1", "8M", "8L1"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "8V", "7M"],
			calmmind: ["9M", "8M", "8V", "7M"],
			chargebeam: ["9M", "7M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M"],
			covet: ["7T"],
			dig: ["9M", "8M", "8V"],
			discharge: ["9L1", "8L1"],
			doubleteam: ["9L1", "8L1", "8V", "7M"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L1", "8M", "8L1"],
			electroweb: ["8M", "7T"],
			encore: ["9M", "8M", "8V"],
			endure: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "8V", "7M"],
			fakeout: ["8V"],
			feint: ["9L1", "8L1"],
			fling: ["9M", "8M", "7M"],
			focusblast: ["9M", "8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			futuresight: ["8M"],
			gigaimpact: ["9M", "8M", "7M"],
			grassknot: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "8V"],
			headbutt: ["8V"],
			helpinghand: ["9M", "8M", "8V", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M", "8M", "8V", "7M"],
			irontail: ["9L1", "8M", "8V", "7T"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L1", "8M", "8L1", "8V", "7M"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nuzzle: ["9L1", "8L1"],
			payday: ["8M", "8V"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "8V", "7M"],
			psychic: ["9M", "9L0", "8M", "8L0", "8V", "7M", "7L1"],
			psychicterrain: ["9M"],
			psyshock: ["9M", "8M", "7M"],
			quickattack: ["9L1", "8L1", "7L1"],
			raindance: ["9M", "8M", "7M"],
			recycle: ["7T"],
			reflect: ["9M", "8M", "8V", "7M"],
			rest: ["9M", "8M", "8V", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seismictoss: ["8V"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			skillswap: ["9M"],
			slam: ["8L1"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["9L1", "8L1"],
			speedswap: ["8M", "7L1"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "8V", "7M"],
			surf: ["9M", "8M"],
			swagger: ["7M"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tailwhip: ["9L1", "8L1", "8V", "7L1"],
			takedown: ["9M"],
			telekinesis: ["7T"],
			teleport: ["8V"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			thunder: ["9M", "9L1", "8M", "8L1", "8V", "7M"],
			thunderbolt: ["9M", "9L5", "8M", "8L1", "8V", "7M", "7L1"],
			thunderpunch: ["9M", "8M", "8V", "7T"],
			thundershock: ["9L1", "8L1", "8V", "7L1"],
			thunderwave: ["9M", "9L1", "8M", "8L1", "8V", "7M"],
			toxic: ["8V", "7M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			voltswitch: ["9M", "8M", "7M"],
			wildcharge: ["9M", "8M", "7M"],
		},
	},
	sandshrew: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L27"],
			amnesia: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "8L18", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3T", "3E"],
			covet: ["7T", "6T", "5T"],
			crushclaw: ["7E", "6E", "5E", "4E", "3E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["8L1", "8V", "7L1", "7V", "6L1", "5L3", "4L3", "3T", "3L6", "3S0"],
			detect: ["7V"],
			dig: ["8M", "8L33", "8V", "7L30", "7V", "6M", "6L30", "5M", "5L30", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L45", "8V", "7M", "7L46", "7V", "6M", "6L46", "5M", "5L46", "4M", "3M"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["8L12", "7L11", "7V", "6L11", "5L25", "4T", "4L25", "3T"],
			furyswipes: ["8L24", "8V", "7L20", "7V", "6L20", "5L19", "4L19", "3L37"],
			gyroball: ["8M", "8L36", "7M", "7L34", "6M", "6L34", "5M", "5L33", "4M", "4L33"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["8E", "7E", "6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leechlife: ["8M"],
			magnitude: ["7L14", "6L14", "5L17"],
			metalclaw: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			mimic: ["7V", "3T"],
			mudshot: ["8M", "7E", "6E", "5E", "4E"],
			mudslap: ["8E", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["8E", "7E", "6E", "5E", "4E"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L3", "8V", "7L5", "7V", "6L5", "5L9", "4L9", "3L17", "3S0"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			rapidspin: ["8L15", "7L9", "7E", "7V", "6L9", "6E", "5L13", "5E", "4L13", "4E", "3E"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["7E", "6E", "5E", "4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L9", "7L7", "7V", "6L7", "5L21", "4T", "4L21", "3T"],
			rototiller: ["7E", "6E"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			sandattack: ["8L6", "8V", "7L3", "7V", "6L3", "5L7", "5D", "4L7", "3L11", "3S0"],
			sandstorm: ["8M", "8L42", "7M", "7L42", "7V", "6M", "6L42", "5M", "5L37", "4M", "4L37", "3M", "3L53"],
			sandtomb: ["8M", "7L23", "6L23", "5L27", "4L27", "3L45"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			skullbash: ["7V"],
			slash: ["8L30", "8V", "7L26", "7V", "6L26", "5L31", "4L31", "3L23"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "5D", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8L21", "8V", "7L17", "7V", "6L11", "5L15", "4T", "4L15", "3T", "3L30"],
			swordsdance: ["8M", "8L39", "8V", "7M", "7L38", "7V", "6M", "6L38", "5M", "5L38", "4M", "4E", "3T", "3E"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			workup: ["8M"],
			xscissor: ["8M", "8V", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 12, gender: "M", nature: "Docile", ivs: {hp: 4, atk: 23, def: 8, spa: 31, spd: 1, spe: 25}, moves: ["scratch", "defensecurl", "sandattack", "poisonsting"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 6},
		],
	},
	sandshrewalola: {
		learnset: {
			aerialace: ["7M"],
			amnesia: ["8M", "7E"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			auroraveil: ["7M"],
			avalanche: ["8M"],
			bide: ["8V", "7L3", "7S0"],
			blizzard: ["8M", "8L45", "8V", "7M", "7L46"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "8V", "7M"],
			bulldoze: ["8M", "7M"],
			chipaway: ["7E"],
			confide: ["7M"],
			counter: ["8E", "7E"],
			covet: ["7T"],
			crushclaw: ["8E", "7E"],
			curse: ["8E", "7E"],
			defensecurl: ["8L1", "8V", "7L1"],
			dig: ["8M", "8V"],
			doubleteam: ["7M"],
			earthquake: ["8M", "8V", "7M"],
			endure: ["8M", "7E"],
			facade: ["8M", "8V", "7M"],
			flail: ["8E", "7E"],
			fling: ["8M", "7M"],
			focuspunch: ["7T"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			furycutter: ["8L12", "7L11"],
			furyswipes: ["8L24", "8V", "7L20"],
			gyroball: ["8M", "8L36", "7M", "7L34"],
			hail: ["8M", "8L42", "7M", "7L42"],
			headbutt: ["8V"],
			hiddenpower: ["7M"],
			honeclaws: ["8E", "7E"],
			iceball: ["7L7", "7S0"],
			icebeam: ["8V"],
			icepunch: ["8M", "8V", "7T"],
			iceshard: ["8V"],
			iciclecrash: ["7E"],
			iciclespear: ["8M", "7E"],
			icywind: ["8M", "7T"],
			irondefense: ["8M", "8L27", "7T", "7L23"],
			ironhead: ["8M", "8L33", "7T", "7L30"],
			irontail: ["8M", "8V", "7T"],
			knockoff: ["7T"],
			leechlife: ["8M", "7M"],
			metalclaw: ["8L18", "7L14", "7E"],
			mirrorcoat: ["8V"],
			mist: ["8L3"],
			nightslash: ["8E", "7E"],
			poisonjab: ["8M", "8V", "7M"],
			powdersnow: ["8L6", "7L5", "7S0"],
			protect: ["8M", "8V", "7M"],
			rapidspin: ["8L15", "7L9", "7S0"],
			rest: ["8M", "8V", "7M"],
			return: ["7M"],
			rockslide: ["8M", "8V", "7M"],
			rocktomb: ["8M"],
			rollout: ["8L9"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scratch: ["8L1", "8V", "7L1"],
			seismictoss: ["8V"],
			shadowclaw: ["8M", "7M"],
			slash: ["8L30", "8V", "7L26"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["8M", "8V", "7T"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "8V", "7M"],
			sunnyday: ["8M", "7M"],
			superfang: ["7T"],
			swagger: ["7M"],
			swift: ["8M", "8L21", "8V", "7L17"],
			swordsdance: ["8M", "8L39", "8V", "7M", "7L38"],
			thief: ["8M", "7M"],
			throatchop: ["8M", "7T"],
			toxic: ["8V", "7M"],
			tripleaxel: ["8T"],
			workup: ["8M", "7M"],
			xscissor: ["8M", "8V", "7M"],
		},
		eventData: [
			{generation: 7, level: 10, moves: ["rapidspin", "iceball", "powdersnow", "bide"], pokeball: "cherishball"},
		],
	},
	sandslash: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			amnesia: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "8L18", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["8V", "3T"],
			covet: ["7T", "6T", "5T"],
			crushclaw: ["8L1", "7L1", "6L22", "5L22", "4L22"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			detect: ["7V"],
			dig: ["8M", "8L41", "8V", "7L33", "7V", "6M", "6L30", "5M", "5L30", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8M", "8V"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L61", "8V", "7M", "7L53", "7V", "6M", "6L46", "5M", "5L46", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["8L12", "7L11", "7V", "6L11", "5L28", "4T", "4L28", "3T"],
			furyswipes: ["8L26", "8V", "7L20", "7V", "6L20", "5L19", "4L19", "3L42"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "8L46", "7M", "7L38", "6M", "6L34", "5M", "5L45", "4M", "4L45"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leechlife: ["8M"],
			magnitude: ["7L14", "6L14", "5L17"],
			mimic: ["7V", "3T"],
			mudshot: ["8M"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L1", "8V", "7L1", "7V", "6L1", "5L9", "4L9", "3L17"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			rapidspin: ["8L15", "7L9", "6L9", "5L13", "4L13"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L9", "7L7", "7V", "6L7", "5L21", "4T", "4L21", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			sandstorm: ["8M", "8L56", "7M", "7L48", "7V", "6M", "6L42", "5M", "5L52", "4M", "4L52", "3M", "3L62"],
			sandtomb: ["8M", "8L31", "7L24", "6L23", "5L33", "4L33", "3L52"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			skullbash: ["7V"],
			slash: ["8L36", "8V", "7L28", "7V", "6L26", "5L40", "4L40", "3L24"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spikes: ["8M"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8L21", "8V", "7L17", "7V", "6L11", "5L15", "4T", "4L15", "3T", "3L33"],
			swordsdance: ["8M", "8L51", "8V", "7M", "7L43", "7V", "6M", "6L38", "5M", "5L38", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			workup: ["8M"],
			xscissor: ["8M", "8V", "7M", "6M", "5M", "4M"],
		},
		encounters: [
			{generation: 2, level: 10},
			{generation: 4, level: 10},
		],
	},
	sandslashalola: {
		learnset: {
			aerialace: ["7M"],
			agility: ["8M"],
			amnesia: ["8M"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			auroraveil: ["7M"],
			avalanche: ["8M"],
			bide: ["8V"],
			blizzard: ["8M", "8L1", "8V", "7M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "8V", "7M"],
			bulldoze: ["8M", "7M"],
			confide: ["7M"],
			counter: ["8V"],
			covet: ["7T"],
			defensecurl: ["8L1", "8V", "7L1"],
			dig: ["8M", "8V"],
			doubleteam: ["7M"],
			drillrun: ["8M", "8V", "7T"],
			earthquake: ["8M", "8V", "7M"],
			endure: ["8M"],
			facade: ["8M", "8V", "7M"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["7T"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			furycutter: ["8L1"],
			furyswipes: ["8L1"],
			gigaimpact: ["8M", "7M"],
			gyroball: ["8M", "8L1", "7M"],
			hail: ["8M", "8L1", "7M"],
			headbutt: ["8V"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "8V", "7M"],
			iceball: ["7L1"],
			icebeam: ["8V"],
			icepunch: ["8M", "8V", "7T"],
			iceshard: ["8V"],
			iciclecrash: ["8L1", "7L1"],
			iciclespear: ["8M", "8L0", "7L1"],
			icywind: ["8M", "7T"],
			irondefense: ["8M", "8L1", "7T"],
			ironhead: ["8M", "8L1", "7T"],
			irontail: ["8M", "8V", "7T"],
			knockoff: ["7T"],
			leechlife: ["8M", "7M"],
			metalburst: ["8L1", "7L1"],
			metalclaw: ["8L1", "7L1"],
			mist: ["8L1"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "8V", "7M"],
			powdersnow: ["8L1"],
			protect: ["8M", "8V", "7M"],
			rapidspin: ["8L1"],
			rest: ["8M", "8V", "7M"],
			return: ["7M"],
			rockslide: ["8M", "8V", "7M"],
			rocktomb: ["8M"],
			rollout: ["8L1"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scratch: ["8L1", "8V"],
			seismictoss: ["8V"],
			shadowclaw: ["8M", "7M"],
			slash: ["8L1", "7L1"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spikes: ["8M"],
			stealthrock: ["8M", "8V", "7T"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "8V", "7M"],
			sunnyday: ["8M", "7M"],
			superfang: ["7T"],
			swagger: ["7M"],
			swift: ["8M", "8L1"],
			swordsdance: ["8M", "8L1", "7M"],
			thief: ["8M", "7M"],
			throatchop: ["8M", "7T"],
			toxic: ["8V", "7M"],
			tripleaxel: ["8T"],
			workup: ["8M", "7M"],
			xscissor: ["8M", "8V", "7M"],
		},
	},
	nidoranf: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			bite: ["8L30", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L20"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			captivate: ["7L43", "6L43", "5L43", "4M", "4L43"],
			charm: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			chipaway: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			crunch: ["8M", "8L50", "8V", "7L37", "6L37", "5L37", "4L37", "3L47"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			disable: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8L25", "8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L12"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "8L55"],
			echoedvoice: ["7M", "6M"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flatter: ["8L45", "7L33", "6L33", "5L33", "4L33", "3L38"],
			focusenergy: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["8L15", "8V", "7L19", "7V", "6L19", "5L19", "4L19", "3L30"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8L35", "8V", "7T", "7L25", "6T", "6L25", "5T", "5L25", "4T", "4L25", "3L23"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			icebeam: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			poisonfang: ["8E", "7L45", "6L45", "5L45", "4L45"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L1", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L17"],
			poisontail: ["8E", "7E", "6E", "5E"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "6E", "5E", "4E"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scratch: ["8L5", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["8E", "7E", "7V", "6E", "5E", "4E"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["5D"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["8V", "7T", "6T", "5T", "5D", "4T"],
			supersonic: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["7V"],
			tailwhip: ["8L10", "8V", "7L7", "7V", "6L7", "5L7", "4L7", "3L8"],
			takedown: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8L40", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "8L20", "7L31", "6L31", "5L31", "4L31"],
			venomdrench: ["8M", "7E", "6E"],
			venoshock: ["8M", "7M", "6M", "5M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
		},
		encounters: [
			{generation: 1, level: 2},
		],
	},
	nidorina: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M"],
			bide: ["7V"],
			bite: ["8L36", "8V", "7L23", "7V", "6L23", "5L23", "4L23", "3L22"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			bubblebeam: ["7V"],
			captivate: ["7L50", "6L50", "5L50", "4M", "4L50"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["8M", "8L64", "8V", "7L43", "6L43", "5L43", "4L43", "3L53"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8L29", "8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L12"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "8L71"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flatter: ["8L57", "7L38", "6L38", "5L38", "4L38", "3L43"],
			focusenergy: ["8M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["8L15", "8V", "7L20", "7V", "6L20", "5L20", "4L20", "3L34"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8L43", "8V", "7T", "7L28", "6T", "6L28", "5T", "5L28", "4T", "4L28", "3L26"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			horndrill: ["7V"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			poisonfang: ["7L58", "6L58", "5L58", "4L58"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L1", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L18"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scratch: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["7V"],
			tailwhip: ["8L1", "8V", "7L7", "7V", "6L7", "5L7", "4L7", "3L8"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8L50", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "8L22", "7L35", "6L35", "5L35", "4L35"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
		},
		encounters: [
			{generation: 4, level: 15, pokeball: "safariball"},
		],
	},
	nidoqueen: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			beatup: ["8M"],
			bide: ["7V"],
			bite: ["8L1"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8V", "7L35", "7V", "6L35", "6S0", "5L35", "4L23", "3T", "3L22"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["8M"],
			chipaway: ["7L23", "6L23", "5L23"],
			confide: ["7M", "6M"],
			counter: ["8V", "7V", "3T"],
			crunch: ["8M", "8L1"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8L1", "7L1", "7V", "6L1", "6S0", "5L1", "4L1", "3L1"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonpulse: ["8M", "8V", "7T", "6T", "5T", "4M"],
			dragontail: ["8V", "7M", "6M", "5M"],
			drillrun: ["8M", "8V", "7T", "6T"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["8M", "8L1", "7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L43"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flatter: ["8L1"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["8L1"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "8V"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8L1", "8V", "7T", "6T", "5T", "4T"],
			hex: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M", "5M"],
			horndrill: ["7V"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudshot: ["8M"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			payday: ["8M", "8V", "7V"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L1", "8V", "7L1", "7V", "6L1", "6S0", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quash: ["7M", "6M", "5M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			superpower: ["8M", "8L0", "8V", "7T", "7L1", "6T", "6L1", "5T", "5L58", "4T", "4L58", "3L43"],
			supersonic: ["8V"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["7V"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "6S0", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "8L1"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 6, level: 41, perfectIVs: 2, abilities: ["poisonpoint"], moves: ["tailwhip", "doublekick", "poisonsting", "bodyslam"], pokeball: "cherishball"},
		],
	},
	nidoranm: {
		learnset: {
			amnesia: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			captivate: ["7L43", "6L43", "5L43", "4M", "4L43"],
			chipaway: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			confusion: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			counter: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3T", "3E"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			disable: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8L25", "8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L12"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8M", "8V", "7T", "6T", "5T"],
			earthpower: ["8M", "8L55"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flatter: ["8L45", "7L33", "6L33", "5L33", "4L33", "3L38"],
			focusenergy: ["8M", "8L10", "8V", "7L7", "7V", "6L7", "5L7", "4L7", "3L8"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8L15", "8V", "7L19", "7V", "6L19", "5L19", "4L19", "3L30"],
			headbutt: ["8V", "7V", "4T"],
			headsmash: ["8E", "7E", "6E", "5E", "4E"],
			helpinghand: ["8M", "8L35", "8V", "7T", "7L25", "6T", "6L25", "5T", "5L25", "4T", "4L25", "3L23"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hornattack: ["8L30", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L20"],
			horndrill: ["8E", "8V", "7L45", "7V", "6L45", "5L45", "4L45", "3L47"],
			icebeam: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			peck: ["8L5", "8V", "7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			poisonjab: ["8M", "8L50", "8V", "7M", "7L37", "6M", "6L37", "5M", "5L37", "4M", "4L37"],
			poisonsting: ["8L1", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L17"],
			poisontail: ["8E", "7E", "6E", "5E"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8E", "7E", "6E", "5E", "4T", "4E"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "5D", "4T"],
			supersonic: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["7V"],
			takedown: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["8E"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8L40", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "8L20", "7L31", "6L31", "5L31", "4L31"],
			venomdrench: ["8M", "7E", "6E"],
			venoshock: ["8M", "7M", "6M", "5M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
		},
		encounters: [
			{generation: 1, level: 2},
		],
	},
	nidorino: {
		learnset: {
			amnesia: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			bubblebeam: ["7V"],
			captivate: ["7L50", "6L50", "5L50", "4M", "4L50"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8L29", "8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L12"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8M", "8V", "7T", "6T", "5T"],
			earthpower: ["8M", "8L71"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flatter: ["8L57", "7L38", "6L38", "5L38", "4L38", "3L43"],
			focusenergy: ["8M", "8L1", "8V", "7L7", "7V", "6L7", "5L7", "4L7", "3L8"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8L15", "8V", "7L20", "7V", "6L20", "5L20", "4L20", "3L34"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8L43", "8V", "7T", "7L28", "6T", "6L28", "5T", "5L28", "4T", "4L28", "3L26"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hornattack: ["8L36", "8V", "7L23", "7V", "6L23", "5L23", "4L23", "3L22"],
			horndrill: ["8V", "7L58", "7V", "6L58", "5L58", "4L58", "3L53"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			peck: ["8L1", "8V", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poisonjab: ["8M", "8L64", "8V", "7M", "7L43", "6M", "6L43", "5M", "5L43", "4M", "4L43"],
			poisonsting: ["8L1", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L18"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["7V"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8L50", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "8L22", "7L35", "6L35", "5L35", "4L35"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
		},
		encounters: [
			{generation: 4, level: 15, pokeball: "safariball"},
		],
	},
	nidoking: {
		learnset: {
			amnesia: ["8M"],
			aquatail: ["7T", "7S0", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			beatup: ["8M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodypress: ["8M"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L23", "6L23", "5L23"],
			confide: ["7M", "6M"],
			counter: ["8V", "7V", "3T"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonpulse: ["8M", "8V", "7T", "6T", "5T", "4M"],
			dragontail: ["8V", "7M", "6M", "5M"],
			drillrun: ["8M", "8V", "7T", "6T", "5T"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["8M", "8L1", "7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L43"],
			earthquake: ["8M", "8V", "7M", "7V", "7S0", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flatter: ["8L1"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L1", "8V", "7L1", "6L1", "5L1", "4L1", "3L1"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8L1"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8L1", "8V", "7T", "6T", "5T", "4T"],
			hex: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M", "5M"],
			hornattack: ["8L1", "7V"],
			horndrill: ["7V"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["8L1", "8V"],
			megahorn: ["8M", "8L0", "8V", "7L1", "6L1", "5L58", "4L58", "3L43"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudshot: ["8M"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			payday: ["8M", "8V", "7V"],
			peck: ["8L1", "8V", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poisonjab: ["8M", "8L1", "8V", "7M", "7S0", "6M", "5M", "4M"],
			poisonsting: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quash: ["7M", "6M", "5M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			supersonic: ["8V"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["7V"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["8V", "7L35", "7V", "6L35", "5L35", "4L23", "3L22"],
			throatchop: ["8M", "7T", "7S0"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "8L1"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 7, level: 68, abilities: ["poisonpoint"], moves: ["earthquake", "poisonjab", "throatchop", "aquatail"], pokeball: "cherishball"},
		],
	},
	cleffa: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			aromatherapy: ["8E", "7E", "6E", "5E", "5D", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bellydrum: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bodyslam: ["8M", "3T"],
			captivate: ["4M"],
			charm: ["8M", "8L20", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			confide: ["7M", "6M"],
			copycat: ["8L1", "7L13", "6L13", "5L13", "4L13"],
			counter: ["3T"],
			covet: ["7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			disarmingvoice: ["8L12"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "8L16", "7L4", "7V", "6L4", "5L4", "5D", "4L4", "3L4"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["8M", "7E", "6E", "5E", "4E"],
			fireblast: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			headbutt: ["7V", "4T"],
			healpulse: ["8E", "7E", "6E"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["8M", "7L16", "6L16", "5L16", "4L16", "3L17"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["8M", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			mimic: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			mistyterrain: ["8M", "7E", "6E"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			playrough: ["8M"],
			pound: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			present: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["8L4", "7L7", "7V", "6L7", "5L7", "4L7", "3L8"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "5D", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			softboiled: ["3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			splash: ["8L1", "7E", "7V", "6E", "5E", "4E", "3E"],
			storedpower: ["8M", "7E", "6E", "5E"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["8L8", "7L10", "7V", "6L10", "5L10", "4L10", "3L13"],
			telekinesis: ["7T", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			tickle: ["8E", "7E", "6E", "5E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wish: ["8E", "7E", "6E", "5E", "4E", "3E"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	clefairy: {
		learnset: {
			afteryou: ["8L12", "7T", "7L58", "6T", "6L1", "5T", "5L52"],
			allyswitch: ["8M"],
			amnesia: ["8M", "8V"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M"],
			bestow: ["7L19", "6L19", "5L25"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "8V", "7L40", "7V", "6L40", "5L40", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			calmmind: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M", "8L1"],
			confide: ["7M", "6M"],
			copycat: ["8L1"],
			cosmicpower: ["8M", "8L40", "7L34", "6L34", "5L28", "4L25", "3L33"],
			counter: ["7V", "3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			defensecurl: ["8L1", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3T", "3L25"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			disarmingvoice: ["8L1", "7L1", "6L1"],
			doubleedge: ["7V", "3T"],
			doubleslap: ["8V", "7L10", "7V", "6L10", "5L10", "4L10", "3L13"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "8L1", "8V", "7L1", "7V", "6L1", "5L4", "4L4", "3L5"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["8M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["8L36", "8S0", "7L16", "6L16", "5L16", "4L16", "3L17"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["8L28", "7T", "7L49", "6T", "6L49", "5T", "5L37", "4T", "4L34"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			healingwish: ["8L48", "7L55", "6L1", "5L49", "4L46"],
			helpinghand: ["8M", "8V", "8S0", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "8S0", "7T", "6T", "5T", "4T"],
			imprison: ["8M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lifedew: ["8L16"],
			lightscreen: ["8M", "8V", "7M", "7V", "6M", "5M", "5L46", "4M", "4L40", "3M", "3L41"],
			luckychant: ["7L37", "6L37", "5L31", "4L28"],
			magicalleaf: ["8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			meteorbeam: ["8T"],
			meteormash: ["8L32", "7L50", "6L50", "5L55", "4L43", "3L45"],
			metronome: ["8M", "8L20", "8V", "8S1", "7L31", "7V", "6L31", "5L34", "4L31", "3T", "3L29"],
			mimic: ["7V", "3T"],
			minimize: ["8L8", "8V", "7L25", "7V", "6L25", "5L19", "4L19", "3L21"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M"],
			moonblast: ["8L44", "8V", "8S1", "7L46", "6L46"],
			moonlight: ["8L24", "8S1", "7L43", "7V", "6L43", "5L40", "4L37", "3L37"],
			mudslap: ["7V", "4T", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			playrough: ["8M", "8V"],
			pound: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "8S0", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["8L1", "8V", "7L7", "7V", "6L7", "5L7", "4L7", "3L9"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			softboiled: ["3T"],
			solarbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			splash: ["8L1"],
			spotlight: ["7L1"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			storedpower: ["8M", "8L4", "7L28", "6L28", "5L43"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["8L1"],
			takedown: ["7V"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			uproar: ["8M"],
			wakeupslap: ["7L22", "6L22", "5L22", "4L22"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "8S1", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 8, level: 50, gender: "F", shiny: true, nature: "Bold", isHidden: true, ivs: {hp: 31, atk: 0, def: 31, spa: 31, spd: 31, spe: 31}, moves: ["followme", "icywind", "helpinghand", "protect"], pokeball: "cherishball"},
			{generation: 8, level: 15, gender: "M", nature: "Modest", abilities: ["cutecharm"], moves: ["metronome", "moonblast", "zenheadbutt", "moonlight"], pokeball: "moonball"},
		],
		encounters: [
			{generation: 1, level: 8},
		],
	},
	clefable: {
		learnset: {
			afteryou: ["8L1", "7T", "6T", "5T"],
			allyswitch: ["8M"],
			amnesia: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			calmmind: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M", "8L1"],
			confide: ["7M", "6M"],
			copycat: ["8L1"],
			cosmicpower: ["8M", "8L1"],
			counter: ["7V", "3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			defensecurl: ["8L1", "8V", "7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			disarmingvoice: ["8L1", "7L1", "6L1"],
			doubleedge: ["7V", "3T"],
			doubleslap: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "8L1"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["8M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["8L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["8L1", "7T", "6T", "5T", "4T"],
			growl: ["8L1", "8V"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			healingwish: ["8L1"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			imprison: ["8M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lifedew: ["8L1"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			meteorbeam: ["8T"],
			meteormash: ["8L1"],
			metronome: ["8M", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			mimic: ["7V", "3T"],
			minimize: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M"],
			moonblast: ["8L1"],
			moonlight: ["8L1", "7V"],
			mudslap: ["7V", "4T", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			playrough: ["8M", "8V"],
			pound: ["8L1", "8V"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			softboiled: ["3T"],
			solarbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			splash: ["8L1"],
			spotlight: ["7L1"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			storedpower: ["8M", "8L1"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["8L1"],
			takedown: ["7V"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			uproar: ["8M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	vulpix: {
		learnset: {
			agility: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			babydolleyes: ["8E", "7L9", "6L9"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			burningjealousy: ["8T"],
			captivate: ["7L47", "7E", "6L47", "6E", "5L41", "4M", "4L37"],
			charm: ["3S1"],
			confide: ["7M", "6M"],
			confuseray: ["8L20", "8V", "7L12", "7V", "6L12", "5L17", "4L17", "3L21"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			darkpulse: ["8M", "8V", "7M", "6M", "5T", "5D", "4M"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M", "3S1"],
			disable: ["8L4", "7E", "7V", "6E", "5E", "4E", "3E"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			ember: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			encore: ["8M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4E"],
			extrasensory: ["8L28", "7L31", "7E", "6L31", "6E", "5L51", "5E", "4L44", "4E"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L23", "7E", "7V", "6L20", "6E", "5L20", "5E", "4E", "3E"],
			fireblast: ["8M", "8L56", "8V", "7M", "7L42", "7V", "6M", "6L42", "5M", "5L54", "4M", "4L47", "3M"],
			firespin: ["8M", "8L40", "8V", "7L15", "7V", "6L12", "5L14", "4L34", "3L41"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			flameburst: ["7L28", "6L23", "5L24"],
			flamecharge: ["8E", "7M", "6M", "5M"],
			flamethrower: ["8M", "8L32", "8V", "7M", "7L36", "7V", "6M", "6L34", "5M", "5L37", "4M", "4L24", "3M", "3L29"],
			flareblitz: ["8M", "7E", "6E", "5E", "4E"],
			foulplay: ["8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grudge: ["8L52", "7L44", "6L44", "5L47", "4L41", "3L37"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E", "3S1"],
			hex: ["8M", "7L26", "7E", "6L26", "6E", "5L28", "5E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			howl: ["8E", "7E", "6E", "5E", "4E", "3E"],
			hypnosis: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			imprison: ["8M", "8L36", "7L39", "6L18", "5L21", "4L21", "3L25"],
			incinerate: ["8L16", "6M", "5M"],
			inferno: ["8L48", "7L50", "6L50", "5L44"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			memento: ["8E"],
			mimic: ["7V", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "7L18", "6M", "6L18", "5M", "5L34", "4M", "4L31"],
			powerswap: ["8M", "7E", "6E", "5E", "4E"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "4E", "3E"],
			quickattack: ["8L8", "8V", "7L10", "7V", "6L10", "5L11", "4L11", "3L13", "3S0"],
			rage: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["8E", "8V", "7M", "7L7", "7V", "6M", "6L7", "5M", "5L7", "5D", "4M", "4L7", "3M", "3L9", "3S0"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L44", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L27", "4M", "4L27", "3M", "3L33"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["8L12", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8V"],
			tailslap: ["8M", "7E", "6E", "5E"],
			tailwhip: ["8L1", "8V", "7L4", "7V", "6L4", "5L4", "4L4", "3L5", "3S0"],
			takedown: ["7V"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			weatherball: ["8M"],
			willowisp: ["8M", "8L24", "8V", "7M", "7L20", "6M", "6L20", "5M", "5L31", "4M", "4L14", "3L17", "3S0"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 18, gender: "F", nature: "Quirky", ivs: {hp: 15, atk: 6, def: 3, spa: 25, spd: 13, spe: 22}, moves: ["tailwhip", "roar", "quickattack", "willowisp"], pokeball: "pokeball"},
			{generation: 3, level: 18, moves: ["charm", "heatwave", "ember", "dig"]},
		],
		encounters: [
			{generation: 1, level: 18},
		],
	},
	vulpixalola: {
		learnset: {
			agility: ["8M", "7E"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			aurorabeam: ["8L24", "8V", "7L28"],
			auroraveil: ["8L44", "7M"],
			babydolleyes: ["8E", "7L9", "7S0"],
			blizzard: ["8M", "8L56", "8V", "7M", "7L42"],
			bodyslam: ["8M"],
			captivate: ["7L47"],
			celebrate: ["7S0"],
			charm: ["8M", "7E"],
			confide: ["7M"],
			confuseray: ["8L20", "8V", "7L12"],
			covet: ["7T"],
			darkpulse: ["8M", "8V", "7M"],
			dazzlinggleam: ["8V"],
			dig: ["8M", "8V"],
			disable: ["8L4", "7E"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			encore: ["8M", "7E"],
			endure: ["8M"],
			extrasensory: ["8L28", "7L31", "7E"],
			facade: ["8M", "8V", "7M"],
			feintattack: ["7L23"],
			flail: ["8E", "7E"],
			foulplay: ["8M", "8V", "7T"],
			freezedry: ["8E", "7E"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			grudge: ["8L52", "7L44"],
			hail: ["8M", "7M"],
			headbutt: ["8V"],
			healbell: ["7T"],
			hex: ["8M", "7L26"],
			hiddenpower: ["7M"],
			howl: ["8E", "7E"],
			hypnosis: ["8E", "7E"],
			icebeam: ["8M", "8L32", "8V", "7M", "7L36"],
			iceshard: ["8L8", "8V", "7L10", "7S0"],
			icywind: ["8M", "8L16", "7T", "7L15"],
			imprison: ["8M", "8L36", "7L39"],
			irontail: ["8M", "8V", "7T"],
			mist: ["8L40", "8V", "7L20"],
			moonblast: ["8E", "7E"],
			painsplit: ["7T"],
			payback: ["8M", "7M", "7L18"],
			powdersnow: ["8L1", "7L1", "7S1"],
			powerswap: ["8M", "7E"],
			protect: ["8M", "8V", "7M"],
			psychup: ["7M"],
			raindance: ["8M", "7M"],
			reflect: ["8V"],
			rest: ["8M", "8V", "7M"],
			return: ["7M"],
			roar: ["8E", "8V", "7M", "7L7"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "7L34"],
			secretpower: ["7E"],
			sheercold: ["8L48", "7L50"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spite: ["8L12", "7T", "7E"],
			substitute: ["8M", "8V", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			tackle: ["8V"],
			tailslap: ["8M", "7E"],
			tailwhip: ["8L1", "8V", "7L4", "7S0"],
			toxic: ["8V", "7M"],
			weatherball: ["8M"],
			zenheadbutt: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 10, moves: ["celebrate", "tailwhip", "babydolleyes", "iceshard"], pokeball: "cherishball"},
			{generation: 7, level: 10, gender: "F", nature: "Modest", moves: ["powdersnow"], pokeball: "cherishball"},
		],
	},
	ninetales: {
		learnset: {
			agility: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			burningjealousy: ["8T"],
			calmmind: ["8M", "8V", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			darkpulse: ["8M", "8V", "7M", "6M", "5T", "4M"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			disable: ["8L1"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8V", "7M", "6M", "5M", "4M"],
			ember: ["8L1", "8V", "7V", "5L1", "4L1", "3L1"],
			encore: ["8M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M"],
			extrasensory: ["8L1"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["8M"],
			fireblast: ["8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firespin: ["8M", "8L1", "7V", "3L45"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "8L1", "8V", "7M", "7L1", "6M", "6L1", "5M", "4M", "3M"],
			flareblitz: ["8M"],
			foulplay: ["8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grudge: ["8L1"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "5S0", "4T"],
			hex: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["8V"],
			imprison: ["8M", "8L1", "7L1", "6L1"],
			incinerate: ["8L1", "6M", "5M"],
			inferno: ["8L1"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			mimic: ["7V", "3T"],
			mysticalfire: ["8M"],
			nastyplot: ["8M", "8L1", "8V", "7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			powerswap: ["8M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["8M", "7M", "6M", "5M", "5S0"],
			quickattack: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			rage: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L1", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "6M", "5M", "5S0", "4M"],
			spite: ["8L1", "7T", "6T", "5T", "4T"],
			storedpower: ["8M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8V"],
			tailslap: ["8M"],
			tailwhip: ["8L1", "8V", "7V"],
			takedown: ["7V"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			weatherball: ["8M"],
			willowisp: ["8M", "8L1", "8V", "7M", "6M", "5M", "5S0", "4M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 5, level: 50, gender: "M", nature: "Bold", ivs: {def: 31}, isHidden: true, moves: ["heatwave", "solarbeam", "psyshock", "willowisp"], pokeball: "cherishball"},
		],
	},
	ninetalesalola: {
		learnset: {
			agility: ["8M"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			aurorabeam: ["8L1"],
			auroraveil: ["8L1", "7M"],
			avalanche: ["8M"],
			blizzard: ["8M", "8L1", "8V", "7M"],
			bodyslam: ["8M"],
			calmmind: ["8M", "8V", "7M"],
			charm: ["8M"],
			confide: ["7M"],
			confuseray: ["8L1", "7L1"],
			covet: ["7T"],
			darkpulse: ["8M", "8V", "7M"],
			dazzlinggleam: ["8M", "8L0", "8V", "7M", "7L1"],
			dig: ["8M", "8V"],
			disable: ["8L1"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			dreameater: ["8V", "7M"],
			encore: ["8M"],
			endure: ["8M"],
			extrasensory: ["8L1"],
			facade: ["8M", "8V", "7M"],
			faketears: ["8M"],
			foulplay: ["8M", "8V", "7T"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			grudge: ["8L1"],
			hail: ["8M", "7M"],
			headbutt: ["8V"],
			healbell: ["7T"],
			hex: ["8M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "8V", "7M"],
			hypnosis: ["8V"],
			icebeam: ["8M", "8L1", "8V", "7M", "7L1"],
			iceshard: ["8L1", "8V", "7L1"],
			icywind: ["8M", "8L1", "7T"],
			imprison: ["8M", "8L1", "7L1"],
			irontail: ["8M", "8V", "7T"],
			laserfocus: ["7T"],
			mist: ["8L1", "8V"],
			mistyterrain: ["8M"],
			nastyplot: ["8M", "8L1", "8V", "7L1"],
			painsplit: ["7T"],
			payback: ["8M", "7M"],
			powdersnow: ["8L1"],
			powerswap: ["8M"],
			protect: ["8M", "8V", "7M"],
			psychup: ["7M"],
			psyshock: ["8M", "7M"],
			raindance: ["8M", "7M"],
			reflect: ["8V"],
			rest: ["8M", "8V", "7M"],
			return: ["7M"],
			roar: ["7M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "7L1"],
			sheercold: ["8L1"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M"],
			spite: ["8L1", "7T"],
			storedpower: ["8M"],
			substitute: ["8M", "8V", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			tackle: ["8V"],
			tailslap: ["8M"],
			tailwhip: ["8L1", "8V"],
			toxic: ["8V", "7M"],
			tripleaxel: ["8T"],
			weatherball: ["8M"],
			wonderroom: ["8M", "7T"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	igglybuff: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			captivate: ["7E", "6E", "5E", "4M"],
			charm: ["9M", "9L20", "8M", "8L20", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1", "7L11", "6L11", "5L17", "4L17"],
			counter: ["3T"],
			covet: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			curse: ["7V"],
			dazzlinggleam: ["9M"],
			defensecurl: ["9L4", "8L4", "7L3", "7V", "6L3", "5L5", "4L5", "3T", "3L4", "3S0"],
			detect: ["7V"],
			dig: ["9M", "8M", "6M", "5M", "4M", "3M"],
			disable: ["9L16", "8L16"],
			disarmingvoice: ["9M", "9L12", "8L12"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M", "8M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M", "7E", "6E", "5E", "5D", "4E", "3E"],
			feintattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			fireblast: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			healpulse: ["9E", "8E", "7E", "6E"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "5D", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			lastresort: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mistyterrain: ["9M", "8M", "7E", "6E"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			perishsong: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			playrough: ["9M", "8M"],
			pound: ["9L1", "8L1", "7L5", "7V", "6L5", "5L9", "4L9", "3L9"],
			present: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			punishment: ["7E", "6E", "5E"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["9E", "8E", "7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sing: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			sleeptalk: ["9M", "8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["9L8", "8L8", "7L9", "7V", "6L9", "5L13", "4L13", "3L14"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			tickle: ["3S0"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			wish: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, abilities: ["cutecharm"], moves: ["sing", "charm", "defensecurl", "tickle"], pokeball: "pokeball"},
		],
	},
	jigglypuff: {
		learnset: {
			allyswitch: ["8M", "7T"],
			amnesia: ["9M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "8M"],
			bide: ["8V", "7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L24", "8M", "8L24", "8V", "7L32", "7V", "6L33", "5L33", "4L29", "3T", "3L34"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			calmmind: ["9M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			counter: ["7V", "3T"],
			covet: ["9L8", "8L8", "7T", "6T", "5T"],
			curse: ["7V"],
			darkpulse: ["9M"],
			dazzlinggleam: ["9M", "8M", "8V", "7M", "6M"],
			defensecurl: ["9L1", "8L1", "8V", "7L3", "7V", "6L3", "5L5", "4L5", "3T", "3L4"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			disable: ["9L1", "8L1", "8V", "7L14", "7V", "6L13", "5L13", "4L13", "3L14"],
			disarmingvoice: ["9M", "9L1", "8L1", "7L11", "6L11"],
			doubleedge: ["9L44", "8L44", "8V", "7L45", "7V", "6L49", "5L53", "4L49", "3T", "3L49"],
			doubleslap: ["8V", "7L17", "7V", "6L18", "5L25", "4L21", "3L24"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["9L4", "8L4", "7M", "6M", "5M"],
			encore: ["9M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			energyball: ["9M"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["9L32", "8M", "8L32", "7M", "7L35", "6M", "6L37", "5M", "5L37", "4M", "4L33"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "9L36", "8M", "8L36", "7T", "7L41", "6T", "6L44", "5T", "5L49", "4L45", "3L44"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icespinner: ["9M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["9M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["9M"],
			mimic: ["9L28", "8L28", "8V", "7L38", "7V", "6L37", "5L45", "4L41", "3T", "3L39"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M", "8M"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			playnice: ["7L9", "6L8"],
			playrough: ["9M", "8M", "8L40", "8V"],
			pound: ["9L1", "8L1", "8V", "7L5", "7V", "6L5", "5L9", "4L9", "3L9"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["9M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "9L20", "8M", "8L20", "8V", "7M", "7L30", "7V", "6M", "6L29", "5M", "5L29", "4M", "4L25", "3M", "3L29"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["7L20", "7V", "6L21", "5L21", "4T", "4L17", "3T", "3L19"],
			round: ["9L16", "8M", "8L16", "7M", "7L22", "6M", "6L17", "5M", "5L17"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["9M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8M"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sing: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			skillswap: ["9M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			spitup: ["9L12", "8L12", "7L25"],
			stealthrock: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			steelroller: ["8T"],
			stockpile: ["9L12", "8L12", "7L25"],
			storedpower: ["9M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["9L12", "8L12", "7L25"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M"],
			takedown: ["9M", "7V"],
			taunt: ["9M"],
			telekinesis: ["7T"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			triattack: ["8M", "8V", "7V"],
			uproar: ["8M"],
			wakeupslap: ["7L27", "6L28", "5L41", "4L37"],
			watergun: ["7V"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M"],
		},
		encounters: [
			{generation: 1, level: 3},
			{generation: 2, level: 3},
			{generation: 3, level: 3},
		],
	},
	wigglytuff: {
		learnset: {
			allyswitch: ["8M", "7T"],
			amnesia: ["9M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "8M"],
			bide: ["8V", "7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L1", "8M", "8L1", "7V", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			calmmind: ["9M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			counter: ["7V", "3T"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			curse: ["7V"],
			darkpulse: ["9M"],
			dazzlinggleam: ["9M", "8M", "8V", "7M", "6M"],
			defensecurl: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			disable: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			disarmingvoice: ["9M", "9L1", "8L1"],
			doubleedge: ["9L1", "8L1", "7L1", "7V", "6L1", "3T"],
			doubleslap: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["9L1", "8L1", "7M", "6M", "5M"],
			encore: ["9M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			energyball: ["9M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["9L1", "8M", "8L1", "7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "9L1", "8M", "8L1", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icespinner: ["9M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["9M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["9M"],
			mimic: ["9L1", "8L1", "7V", "3T"],
			minimize: ["8V"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M", "8M"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			playrough: ["9M", "9L5", "8M", "8L1", "8V", "7L1", "6L1"],
			pound: ["9L1", "8L1", "8V"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["9M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["7V", "4T", "3T"],
			round: ["9L1", "8M", "8L1", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["9M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8M"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sing: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			skillswap: ["9M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			spitup: ["9L1", "8L1"],
			stealthrock: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			steelroller: ["8T"],
			stockpile: ["9L1", "8L1"],
			storedpower: ["9M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["9L1", "8L1"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M"],
			takedown: ["9M", "7V"],
			taunt: ["9M"],
			telekinesis: ["7T"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			triattack: ["8M", "8V", "7V"],
			uproar: ["8M"],
			watergun: ["7V"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M"],
		},
		encounters: [
			{generation: 1, level: 22},
		],
	},
	zubat: {
		learnset: {
			absorb: ["8L1", "8V", "7L1"],
			acrobatics: ["8M", "7M", "6M", "6L30", "5M", "5L33"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			aircutter: ["8L25", "7L19", "6L19", "5L25", "4T", "4L25", "3L31"],
			airslash: ["8M", "8L50", "8V", "7L41", "6L41", "5L45", "4L41"],
			assurance: ["8M"],
			astonish: ["8L5", "7L7", "6L7", "5L9", "4L9", "3L6"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["8L30", "8V", "7L11", "7V", "6L11", "5L13", "4L13", "3L16"],
			bravebird: ["8M", "7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L45", "8V", "7L17", "7V", "6L17", "5L21", "4L21", "3L26"],
			crunch: ["8M"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			defog: ["8E", "7T", "7E", "6E", "5E", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			fly: ["8M", "8V", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			gust: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			haze: ["8L35", "8V", "7L35", "7V", "6L35", "5L41", "4L37", "3L46"],
			headbutt: ["8V"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["8E", "7E", "6E", "5E", "5D", "4E"],
			leechlife: ["8M", "8L55", "8V", "7M", "7L31", "7V", "6L1", "5L1", "4L1", "3L1"],
			meanlook: ["8L10", "7L29", "7V", "6L29", "5L29", "4L29", "3L36"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			nastyplot: ["8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pluck: ["5M", "4M"],
			poisonfang: ["8L15", "7L25", "6L25", "5L37", "4L33", "3L41"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			quickattack: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			quickguard: ["8L20", "7L43", "6L43"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["8M", "7M", "7E", "7V", "6M", "6E", "5E", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "5D", "4T"],
			supersonic: ["8L1", "8V", "7L5", "7V", "6L4", "5L5", "5D", "4L5", "3L6"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8V", "7L23", "7V", "6L23", "5L24", "4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["8M", "8V", "7M", "6M", "5M", "4M"],
			venomdrench: ["8M", "7E", "6E"],
			venoshock: ["8M", "8L40", "7M", "7L37", "6M", "6L37", "5M"],
			whirlwind: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			wingattack: ["8E", "8V", "7L13", "7V", "6L13", "5L17", "4L17", "3L21"],
			zenheadbutt: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
		},
		encounters: [
			{generation: 1, level: 6},
			{generation: 2, level: 2},
		],
	},
	golbat: {
		learnset: {
			absorb: ["8L1", "8V", "7L1"],
			acrobatics: ["8M", "7M", "6M", "6L33", "5M", "5L39"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			aircutter: ["8L27", "7L19", "6L19", "5L27", "4T", "4L27", "3L35"],
			airslash: ["8M", "8L62", "8V", "7L48", "6L48", "5L57", "4L51"],
			assurance: ["8M"],
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["8L34", "8V", "7L1", "7V", "6L1", "5L13", "4L13", "3L16"],
			bravebird: ["8M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L55", "8V", "7L17", "7V", "6L17", "5L21", "4L21", "3L28"],
			crunch: ["8M", "8V"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fly: ["8M", "8V", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			haze: ["8L41", "8V", "7L40", "7V", "6L40", "5L51", "4L45", "3L56"],
			headbutt: ["8V"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			leechlife: ["8M", "8L69", "8V", "7M", "7L35", "7V", "6L1", "5L1", "4L1", "3L1"],
			meanlook: ["8L1", "7L32", "7V", "6L32", "5L33", "4L33", "3L42"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pluck: ["5M", "4M"],
			poisonfang: ["8L15", "7L27", "6L27", "5L45", "4L39", "3L49"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["8V"],
			quickguard: ["8L20", "7L51", "6L51"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			supersonic: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8V", "7L24", "7V", "6L24", "5L24", "4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["8M", "8V", "7M", "6M", "5M", "4M"],
			venomdrench: ["8M"],
			venoshock: ["8M", "8L48", "7M", "7L43", "6M", "6L43", "5M"],
			whirlwind: ["8V", "7V"],
			wingattack: ["8V", "7L13", "7V", "6L13", "5L17", "4L17", "3L21"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 2, level: 13},
			{generation: 3, level: 5},
			{generation: 4, level: 10},
			{generation: 6, level: 19, maxEggMoves: 1},
			{generation: 7, level: 20},
		],
	},
	crobat: {
		learnset: {
			absorb: ["8L1", "7L1"],
			acrobatics: ["8M", "7M", "6M", "6L33", "5M", "5L39"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			aircutter: ["8L27", "7L19", "6L19", "5L27", "4T", "4L27", "3L35"],
			airslash: ["8M", "8L62", "7L48", "7S1", "6L48", "5L57", "4L51", "4S0"],
			assurance: ["8M"],
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bite: ["8L34", "7L1", "7V", "6L1", "5L13", "4L13", "3L16"],
			bravebird: ["8M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L55", "7L17", "7V", "6L17", "5L21", "4L21", "3L28"],
			crosspoison: ["8M", "8L0", "7L1", "6L1", "5L1", "4L1"],
			crunch: ["8M"],
			curse: ["7V"],
			darkpulse: ["8M", "7M", "7S1", "6M", "5T", "4M"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fly: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			haze: ["8L41", "7L40", "7V", "6L40", "5L51", "4L45", "3L56"],
			heatwave: ["8M", "7T", "6T", "5T", "4T", "4S0"],
			hex: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["8M"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			leechlife: ["8M", "8L69", "7M", "7L35", "7V", "6L1", "5L1", "4L1", "3L1"],
			meanlook: ["8L1", "7L32", "7V", "6L32", "5L33", "4L33", "3L42"],
			mimic: ["3T"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pluck: ["5M", "4M"],
			poisonfang: ["8L15", "7L27", "6L27", "5L45", "4L39", "3L49"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickguard: ["8L20", "7L51", "6L51"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			skyattack: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "7S1", "6M", "5M", "4M", "4S0", "3M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T", "4S0"],
			supersonic: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7L24", "7V", "6L24", "5L24", "4T", "3T"],
			tailwind: ["8L1", "7T", "6T", "5T", "4T"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8L1", "7M", "7V", "7S1", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["8M", "7M", "6M", "5M", "4M"],
			venomdrench: ["8M"],
			venoshock: ["8M", "8L48", "7M", "7L43", "6M", "6L43", "5M"],
			wingattack: ["7L13", "7V", "6L13", "5L17", "4L17", "3L21"],
			xscissor: ["8M", "7M", "6M", "5M", "4M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 30, gender: "M", nature: "Timid", moves: ["heatwave", "airslash", "sludgebomb", "superfang"], pokeball: "cherishball"},
			{generation: 7, level: 64, gender: "M", moves: ["airslash", "toxic", "darkpulse", "sludgebomb"], pokeball: "cherishball"},
		],
	},
	oddish: {
		learnset: {
			absorb: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			acid: ["8L4", "8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L23", "3S0"],
			afteryou: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["8M", "7E", "6E", "5E", "4E", "3E"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "6T", "5T", "4T"],
			gigadrain: ["8M", "8L20", "7T", "7L31", "7V", "6T", "6L31", "5T", "5L37", "5D", "4M", "4L37", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L32", "7L47", "6L45"],
			growth: ["8L1", "8V", "7L1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			ingrain: ["8E", "7E", "6E", "5E", "4E", "3E"],
			leechseed: ["8E", "3S1"],
			luckychant: ["7L23", "6L23", "5L25", "4L25"],
			megadrain: ["8L12", "8V", "7L19", "7V", "6L19", "5L21", "4L21"],
			mimic: ["7V", "3T"],
			moonblast: ["8L28", "8V", "7L43", "6L43"],
			moonlight: ["8L36", "7L27", "7V", "6L27", "5L33", "4L33", "3L32"],
			naturalgift: ["7L39", "6L29", "5L29", "4M", "4L29"],
			naturepower: ["8E", "7M", "7E", "6M", "6E", "5E"],
			petaldance: ["8L40", "7L51", "7V", "6L41", "5L41", "4L41", "3L39"],
			poisonpowder: ["8L14", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L14", "3S0"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			razorleaf: ["8E", "8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeppowder: ["8L18", "8V", "7L15", "7V", "6L15", "5L17", "4L17", "3L18", "3S0"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			strengthsap: ["8E", "7E"],
			stunspore: ["8L16", "8V", "7L14", "7V", "6L14", "5L15", "4L15", "3L16", "3S0"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["8L8", "7L5", "7V", "6L5", "5L5", "5D", "4L5", "3L7"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			synthesis: ["8E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			takedown: ["7V"],
			teeterdance: ["8E", "7E", "6E", "5E", "5D", "4E"],
			tickle: ["8E", "7E", "6E", "5E", "4E"],
			toxic: ["8L24", "8V", "7M", "7L35", "7V", "6M", "6L35", "5M", "4M", "3M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 26, gender: "M", nature: "Quirky", ivs: {hp: 23, atk: 24, def: 20, spa: 21, spd: 9, spe: 16}, moves: ["poisonpowder", "stunspore", "sleeppowder", "acid"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, moves: ["absorb", "leechseed"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 12},
		],
	},
	gloom: {
		learnset: {
			absorb: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			acid: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L24", "3S0"],
			afteryou: ["7T", "6T", "5T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "6T", "5T", "4T"],
			gigadrain: ["8M", "8L20", "7T", "7L34", "7V", "6T", "6L34", "5T", "5L47", "4M", "4L47", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L38", "7L54", "6L54"],
			growth: ["8L1", "8V", "7L1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			luckychant: ["7L24", "6L24", "5L29", "4L29"],
			megadrain: ["8L12", "8V", "7L19", "7V", "6L19", "5L23", "4L23"],
			mimic: ["7V", "3T"],
			moonblast: ["8L32", "8V"],
			moonlight: ["8L44", "7L29", "7V", "6L29", "5L41", "4L41", "3L35", "3S0"],
			naturalgift: ["7L44", "6L35", "5L35", "4M", "4L35"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["7L49", "6L49"],
			petaldance: ["8L50", "7L59", "7V", "6L53", "5L53", "4L53", "3L44", "3S0"],
			poisonpowder: ["8L14", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			razorleaf: ["8V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeppowder: ["8L18", "8V", "7L15", "7V", "6L15", "5L17", "4L17", "3L18", "3S0"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			stunspore: ["8L16", "8V", "7L14", "7V", "6L14", "5L15", "4L15", "3L16"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			toxic: ["8L26", "8V", "7M", "7L39", "7V", "6M", "6L39", "5M", "4M", "3M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 50, moves: ["sleeppowder", "acid", "moonlight", "petaldance"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 2, level: 14},
			{generation: 4, level: 14},
			{generation: 6, level: 18, maxEggMoves: 1},
		],
	},
	vileplume: {
		learnset: {
			absorb: ["8L1", "8V", "7V", "3L1"],
			acid: ["8L1", "8V", "7V"],
			afteryou: ["7T", "6T", "5T"],
			aromatherapy: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "6T", "5T", "4T"],
			gigadrain: ["8M", "8L1", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L1"],
			growth: ["8L1", "8V"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			megadrain: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["7V", "3T"],
			moonblast: ["8L1"],
			moonlight: ["8L1"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["8L0", "7L49", "6L49"],
			petaldance: ["8L1", "8V", "7L59", "7V", "6L53", "5L53", "4L53", "3L44"],
			poisonpowder: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1"],
			pollenpuff: ["8M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeppowder: ["8L1", "7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "7L69", "7V", "6M", "6L64", "5M", "5L65", "4M", "4L65", "3M"],
			stunspore: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["8L1", "7V"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			toxic: ["8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
	},
	bellossom: {
		learnset: {
			absorb: ["8L1", "7V", "3L1"],
			acid: ["8L1"],
			afteryou: ["7T", "6T", "5T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "6T", "5T", "4T"],
			gigadrain: ["8M", "8L1", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L1"],
			growth: ["8L1"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			laserfocus: ["7T"],
			leafblade: ["8M", "7L1", "6L1", "5L1", "4L1"],
			leafstorm: ["8M", "7L1", "6L1", "5L53", "4L53"],
			magicalleaf: ["8M", "7L1", "6L23", "5L23", "4L23", "3L1"],
			megadrain: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			mimic: ["3T"],
			moonblast: ["8L1"],
			moonlight: ["8L1"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["8L0", "7L49", "6L49"],
			petaldance: ["8L1", "7L59", "7V", "3L44"],
			playrough: ["8M"],
			poisonpowder: ["8L1"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			quiverdance: ["8L1", "7L39"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeppowder: ["8L1"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M", "3L55"],
			stunspore: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			toxic: ["8L1", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venoshock: ["8M", "7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
	},
	paras: {
		learnset: {
			absorb: ["8V", "7L11"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			afteryou: ["7T", "6T", "5T"],
			agility: ["7E", "6E", "5E", "4E"],
			aromatherapy: ["7L43", "6L43", "5L43", "4L38", "3L49"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["7V", "3T"],
			brickbreak: ["8V", "7M", "6M", "5M", "4M"],
			bugbite: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			bulletseed: ["4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			crosspoison: ["7E", "6E", "5E", "5D", "4E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7E", "7V", "6E", "5E", "4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["7M", "7V", "6M", "5M", "4M", "4E", "3E", "3S0"],
			fellstinger: ["7E", "6E"],
			flail: ["7E", "7V", "6E", "5E", "4E", "3E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7L17", "7V", "6L17", "5L17", "4T", "3T"],
			furyswipes: ["8V"],
			gigadrain: ["7T", "7L38", "7V", "6T", "6L38", "5T", "5L38", "4M", "4L33", "3M", "3L43"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grassyterrain: ["7E"],
			growth: ["8V", "7L33", "7V", "6L33", "5L33", "4L27", "3L37"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leechlife: ["8V", "7M", "7V", "6L11", "5L11", "4L11", "3L19"],
			leechseed: ["7E", "6E", "5E"],
			lightscreen: ["8V", "7M", "7V", "6M", "5M", "4E", "3E"],
			megadrain: ["8V", "7V"],
			metalclaw: ["7E", "6E", "5E", "4E"],
			mimic: ["7V", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			naturepower: ["7M", "6M"],
			poisonpowder: ["8V", "7L6", "7V", "6L6", "5L6", "4L6", "3L13"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["7E", "7V", "6E", "5E", "4E", "3E"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7V"],
			ragepowder: ["7L49", "6L49", "5L49"],
			reflect: ["8V", "7V"],
			refresh: ["3S0"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rototiller: ["7E", "6E"],
			round: ["7M", "6M", "5M"],
			scratch: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			slash: ["8V", "7L27", "7V", "6L27", "5L27", "4L22", "3L31", "3S0"],
			sleeppowder: ["8V"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			spore: ["8V", "7L22", "7V", "6L22", "5L22", "4L17", "3L25", "3S0"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			stunspore: ["8V", "7L6", "7V", "6L6", "5L6", "5D", "4L6", "3L7"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7E", "7V", "6E", "5E", "4E", "3E"],
			swordsdance: ["7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "5D", "4T"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["7M", "6M", "5M"],
			wideguard: ["7E", "6E"],
			worryseed: ["7T", "6T", "5T", "4T"],
			xscissor: ["8V", "7M", "7L54", "6M", "6L54", "5M", "5L54", "4M", "4L43"],
		},
		eventData: [
			{generation: 3, level: 28, abilities: ["effectspore"], moves: ["refresh", "spore", "slash", "falseswipe"]},
		],
		encounters: [
			{generation: 1, level: 8},
		],
	},
	parasect: {
		learnset: {
			absorb: ["8V", "7L1"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			afteryou: ["7T", "6T", "5T"],
			aromatherapy: ["7L51", "6L51", "5L51", "4L47", "3L59"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["7V", "3T"],
			brickbreak: ["8V", "7M", "6M", "5M", "4M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bulletseed: ["4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crosspoison: ["7L1", "6L1", "5L1", "4L1"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["7M", "6M", "5M", "4M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7L17", "7V", "6L17", "5L17", "4T", "3T"],
			furyswipes: ["8V"],
			gigadrain: ["7T", "7L44", "7V", "6T", "6L44", "5T", "5L44", "4M", "4L39", "3M", "3L51"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growth: ["8V", "7L37", "7V", "6L37", "5L37", "4L30", "3L43"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leechlife: ["8V", "7M", "7V", "6L1", "5L1", "4L1", "3L19"],
			leechseed: ["8V"],
			lightscreen: ["8V", "7M", "6M", "5M"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			poisonpowder: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			ragepowder: ["7L59", "6L59", "5L59"],
			reflect: ["8V", "7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scratch: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["8V"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			slash: ["8V", "7L29", "7V", "6L29", "5L29", "4L22", "3L35"],
			sleeppowder: ["8V"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			spore: ["8V", "7L22", "7V", "6L22", "5L22", "4L17", "3L27"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			stunspore: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swordsdance: ["7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			xscissor: ["8V", "7M", "7L66", "6M", "6L66", "5M", "5L66", "4M", "4L55"],
		},
		encounters: [
			{generation: 1, level: 13},
			{generation: 2, level: 5},
		],
	},
	venonat: {
		learnset: {
			acidspray: ["9M"],
			agility: ["9M", "9E", "7E", "6E", "5E", "4E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "9E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			bide: ["7V"],
			bugbite: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			bugbuzz: ["9M", "9L25"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L11", "8V", "7L11", "7V", "6L11", "5L11", "4L11", "3L17"],
			curse: ["7V"],
			disable: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["9M", "7V", "4M", "3T"],
			energyball: ["9M"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foresight: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "4E", "3M", "3E"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			leechlife: ["9M", "9L35", "8V", "7M", "7L35", "7V", "6L17", "5L17", "4L17", "3L25"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			morningsun: ["9E", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			poisonfang: ["9L41", "7L41", "6L41", "5L41", "4L41"],
			poisonpowder: ["9L13", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L20"],
			pounce: ["9M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L17", "8V", "7L17", "7V", "6L25", "5L25", "4L25", "3L33"],
			psychic: ["9M", "9L47", "8V", "7M", "7L47", "7V", "6M", "6L47", "5M", "5L47", "4M", "4L47", "3M", "3L41"],
			psywave: ["7V"],
			rage: ["7V"],
			ragepowder: ["9E", "7E", "6E", "5E"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			screech: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			signalbeam: ["7T", "7L25", "7E", "6T", "6L35", "6E", "5T", "5L35", "5E", "4T", "4L35", "4E", "3E"],
			skillswap: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4M", "3M"],
			sleeppowder: ["9L29", "8V", "7L29", "7V", "6L29", "5L29", "4L29", "3L36"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["9M", "6M", "5M"],
			stunspore: ["9L23", "8V", "7L23", "7V", "6L23", "5L23", "4L23", "3L28"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["9L5", "8V", "7L5", "7V", "6L5", "5L5", "4L5", "3L9"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["9M", "7V", "4T", "3T"],
			tackle: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M", "9E", "7E", "6E", "5E", "4E"],
			venoshock: ["9M", "9E", "7M", "6M", "5M"],
			zenheadbutt: ["9M", "9L37", "7T", "7L37", "6T", "6L37", "5T", "5L37", "4T", "4L37"],
		},
		encounters: [
			{generation: 1, level: 13},
		],
	},
	venomoth: {
		learnset: {
			acidspray: ["9M"],
			acrobatics: ["9M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "8V"],
			aircutter: ["9M", "4T"],
			airslash: ["9M", "9L0"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M"],
			bide: ["7V"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["9M", "9L25", "8V", "7L1", "6L1", "5L59", "4L59"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L11", "8V", "7L11", "7V", "6L11", "5L11", "4L11", "3L17"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			disable: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8V"],
			endure: ["9M", "7V", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foresight: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gust: ["8V", "7L1", "7V", "6L31", "5L31", "4L31", "3L31"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			leechlife: ["9M", "9L37", "8V", "7M", "7L37", "7V", "6L17", "5L17", "4L17", "3L25"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			ominouswind: ["4T"],
			poisonfang: ["9L47", "7L47", "6L47", "5L47", "4L47"],
			poisonpowder: ["9L13", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L20"],
			pounce: ["9M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L17", "8V", "7L17", "7V", "6L25", "5L25", "4L25", "3L36"],
			psychic: ["9M", "9L55", "8V", "7M", "7L55", "7V", "6M", "6L55", "5M", "5L55", "4M", "4L55", "3M", "3L52", "3S0"],
			psywave: ["7V"],
			quiverdance: ["9L1", "8V", "7L1", "6L1", "5L63"],
			rage: ["7V"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			refresh: ["3S0"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			screech: ["8V"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "7L25", "6T", "6L37", "5T", "5L37", "4T", "4L37"],
			silverwind: ["7L1", "6L1", "5L1", "4M", "4L1", "3L1", "3S0"],
			skillswap: ["9M", "7T", "6T", "5T", "4M", "3M"],
			sleeppowder: ["9L29", "8V", "7L29", "7V", "6L29", "5L29", "4L29", "3L42"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["9M", "6M", "5M"],
			stunspore: ["9L23", "8V", "7L23", "7V", "6L23", "5L23", "4L23", "3L28"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T", "3S0"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["9M", "7V", "4T", "3T"],
			tackle: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["9M", "7V"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M"],
			twister: ["4T"],
			uturn: ["9M", "8V", "7M", "6M", "5M", "4M"],
			venoshock: ["9M", "7M", "6M", "5M"],
			whirlwind: ["7V"],
			zenheadbutt: ["9M", "9L41", "7T", "7L41", "6T", "6L41", "5T", "5L41", "4T", "4L41"],
		},
		eventData: [
			{generation: 3, level: 32, abilities: ["shielddust"], moves: ["refresh", "silverwind", "substitute", "psychic"]},
		],
		encounters: [
			{generation: 1, level: 30},
			{generation: 2, level: 10},
			{generation: 4, level: 8},
			{generation: 6, level: 30},
		],
	},
	diglett: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "8M", "8V"],
			allyswitch: ["8M"],
			ancientpower: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			assurance: ["8M"],
			astonish: ["9L8", "8L8", "7L7", "7E", "6L7", "6E", "5L7", "5E", "4L7", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			bide: ["7V"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			bulldoze: ["9M", "9L16", "8M", "8L16", "7M", "7L18", "6M", "6L18", "5M", "5L18"],
			captivate: ["4M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dig: ["9M", "9L32", "8M", "8L32", "8V", "7L31", "7V", "6M", "6L34", "5M", "5L34", "4M", "4L18", "3M", "3L17"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "9L36", "8M", "8L36", "7T", "7L28", "6T", "6L29", "5T", "5L29", "4T", "4L26"],
			earthquake: ["9M", "9L40", "8M", "8L40", "8V", "7M", "7L39", "7V", "6M", "6L40", "5M", "5L40", "4M", "4L37", "3M", "3L41"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			finalgambit: ["9E", "8E", "7E", "6E", "5E"],
			fissure: ["9L44", "8L44", "8V", "7L43", "7V", "6L45", "5L45", "4L40", "3L49"],
			foulplay: ["9M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["8V", "3L21"],
			growl: ["9L4", "8L4", "8V", "7L4", "7V", "6L4", "5L4", "4L4", "3L5"],
			headbutt: ["9E", "8E", "8V", "7E", "6E", "5E"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["9E", "8E", "6M", "5M"],
			magnitude: ["7L14", "7V", "6L15", "5L15", "4L12", "3L9"],
			memento: ["9E", "8E", "7E", "6E", "5E"],
			mimic: ["7V", "3T"],
			mudbomb: ["7L25", "7E", "6L26", "6E", "5L26", "5E", "4L29", "4E"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L12", "8L12", "7L10", "7V", "6L12", "5L12", "4T", "4L15", "3T", "3L25"],
			naturalgift: ["4M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "8M", "7E", "6E", "5E", "4E"],
			rockblast: ["9M"],
			rockslide: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			sandstorm: ["9M", "9L28", "8M", "8L28", "7M", "6M", "5M", "4M"],
			scorchingsands: ["8T"],
			scratch: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["9L24", "8L24", "8V", "7L35", "7V", "6L37", "5L37", "4L34", "3L33"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["9M", "8M", "8V", "7T", "6T", "5T", "5D", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L20", "8L20", "8V", "7L22", "6L23", "5L23", "4T", "4L23"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["9M"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7E", "6E", "5T", "5E", "4E", "3E"],
			workup: ["8M"],
		},
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 2},
		],
	},
	diglettalola: {
		learnset: {
			aerialace: ["7M"],
			agility: ["9M", "8M", "8V"],
			allyswitch: ["8M"],
			ancientpower: ["9E", "8E", "7E"],
			assurance: ["8M"],
			astonish: ["9L8", "8L8", "7L7", "7S0"],
			attract: ["8M", "7M"],
			beatup: ["8M", "7E"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "9L16", "8M", "8L16", "7M", "7L18"],
			charm: ["9M"],
			confide: ["7M"],
			dig: ["9M", "9L32", "8M", "8L32", "8V", "7L31"],
			doubleteam: ["7M"],
			earthpower: ["9M", "9L36", "8M", "8L36", "7T", "7L28"],
			earthquake: ["9M", "9L40", "8M", "8L40", "8V", "7M", "7L39"],
			echoedvoice: ["7M"],
			endure: ["9M", "8M", "7E"],
			facade: ["9M", "8M", "8V", "7M"],
			feintattack: ["7E"],
			finalgambit: ["9E", "8E", "7E"],
			fissure: ["9L44", "8L44", "8V", "7L43"],
			flashcannon: ["9M", "8M", "8V", "7M"],
			foulplay: ["9M"],
			frustration: ["7M"],
			furyswipes: ["8V"],
			growl: ["9L4", "8L4", "8V", "7L4", "7S0"],
			headbutt: ["9E", "8E", "8V", "7E"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			honeclaws: ["9E", "8E"],
			irondefense: ["9M", "8M", "7T"],
			ironhead: ["9M", "9L24", "8M", "8L24", "7T", "7L35"],
			magnitude: ["7L14"],
			memento: ["9E", "8E", "7E"],
			metalclaw: ["9M", "9L1", "8L1", "7L1", "7S0"],
			metalsound: ["9E", "8E", "7E"],
			mudbomb: ["7L25"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L12", "8L12", "7L10", "7S0"],
			protect: ["9M", "8M", "8V", "7M"],
			pursuit: ["7E"],
			rest: ["9M", "8M", "8V", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M", "7E"],
			rockblast: ["9M"],
			rockslide: ["9M", "8M", "8V", "7M"],
			rocktomb: ["9M", "8M", "7M"],
			round: ["8M", "7M"],
			sandattack: ["9L1", "8L1", "8V", "7L1"],
			sandstorm: ["9M", "9L28", "8M", "8L28", "7M"],
			scaryface: ["9M"],
			scorchingsands: ["8T"],
			scratch: ["8V"],
			screech: ["8M"],
			shadowclaw: ["9M", "8M", "7M"],
			slash: ["8V"],
			sleeptalk: ["9M", "8M", "7M"],
			sludgebomb: ["9M", "8M", "8V", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "8V", "7T"],
			steelbeam: ["9M", "8T"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M"],
			substitute: ["9M", "8M", "8V", "7M"],
			suckerpunch: ["9L20", "8L20", "8V", "7L22"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			thrash: ["9E", "8E", "7E"],
			toxic: ["8V", "7M"],
			uproar: ["8M"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 10, abilities: ["tanglinghair"], moves: ["mudslap", "astonish", "growl", "metalclaw"], pokeball: "cherishball"},
		],
	},
	dugtrio: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "8M", "8V"],
			allyswitch: ["8M"],
			assurance: ["8M"],
			astonish: ["9L1", "8L1", "7L7", "6L7", "5L7", "4L7"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["8M"],
			bide: ["7V"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			bulldoze: ["9M", "9L16", "8M", "8L16", "7M", "7L18", "6M", "6L18", "5M", "5L18"],
			captivate: ["4M"],
			charm: ["9M", "3S0"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dig: ["9M", "9L36", "8M", "8L36", "8V", "7L35", "7V", "6M", "6L40", "5M", "5L40", "4M", "4L18", "3M", "3L17"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "9L42", "8M", "8L42", "7T", "7L30", "6T", "6L33", "5T", "5L33", "4T", "4L28"],
			earthquake: ["9M", "9L48", "8M", "8L48", "8V", "7M", "7L47", "7V", "6M", "6L50", "5M", "5L50", "4M", "4L45", "3M", "3L51", "3S0"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["9L54", "8L54", "8V", "7L53", "7V", "6L57", "5L57", "4L50", "3L64"],
			foulplay: ["9M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["8V", "3L21"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			magnitude: ["7L14", "7V", "6L15", "5L15", "4L12", "3L9"],
			mimic: ["7V", "3T"],
			mudbomb: ["7L25", "6L28", "5L28", "4L33"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L12", "8L12", "7L10", "7V", "6L12", "5L12", "4T", "4L15", "3T", "3L25"],
			naturalgift: ["4M"],
			nightslash: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "8M"],
			rockblast: ["9M"],
			rockslide: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rototiller: ["7L1", "6L1"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			sandstorm: ["9M", "9L30", "8M", "8L30", "7M", "6M", "5M", "4M", "3S0"],
			sandtomb: ["9L0", "8M", "8L0", "7L1", "6L26", "5L26", "4L26", "3L26"],
			scaryface: ["9M"],
			scorchingsands: ["8T"],
			scratch: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["8M", "8V"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["9L24", "8L24", "8V", "7L41", "7V", "6L45", "5L45", "4L40", "3L38"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L20", "8L20", "8V", "7L22", "6L23", "5L23", "4T", "4L23"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["9M"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["9L1", "8M", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			uproar: ["8M", "5T"],
			workup: ["8M"],
		},
		eventData: [
			{generation: 3, level: 40, moves: ["charm", "earthquake", "sandstorm", "triattack"]},
		],
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 5},
			{generation: 4, level: 19},
		],
	},
	dugtrioalola: {
		learnset: {
			aerialace: ["7M"],
			agility: ["9M", "8M", "8V"],
			allyswitch: ["8M"],
			assurance: ["8M"],
			astonish: ["9L1", "8L1", "7L7"],
			attract: ["8M", "7M"],
			beatup: ["8M"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "9L16", "8M", "8L16", "7M", "7L18"],
			charm: ["9M"],
			confide: ["7M"],
			dig: ["9M", "9L36", "8M", "8L36", "8V", "7L35"],
			doubleteam: ["7M"],
			earthpower: ["9M", "9L42", "8M", "8L42", "7T", "7L30"],
			earthquake: ["9M", "9L48", "8M", "8L48", "8V", "7M", "7L47"],
			echoedvoice: ["7M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "8V", "7M"],
			fissure: ["9L54", "8L54", "8V", "7L53"],
			flashcannon: ["9M", "8M", "8V", "7M"],
			foulplay: ["9M"],
			frustration: ["7M"],
			furyswipes: ["8V"],
			gigaimpact: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "8V", "7L1"],
			headbutt: ["8V"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			highhorsepower: ["8M"],
			hyperbeam: ["9M", "8M", "8V", "7M"],
			irondefense: ["9M", "8M", "7T"],
			ironhead: ["9M", "9L24", "8M", "8L24", "7T", "7L41"],
			magnitude: ["7L14"],
			metalclaw: ["9M", "9L1", "8L1", "7L1"],
			mudbomb: ["7L25"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L12", "8L12", "7L10"],
			nightslash: ["9L1", "8L1", "7L1"],
			protect: ["9M", "8M", "8V", "7M"],
			rest: ["9M", "8M", "8V", "7M"],
			return: ["7M"],
			reversal: ["9M", "8M"],
			rockblast: ["9M"],
			rockslide: ["9M", "8M", "8V", "7M"],
			rocktomb: ["9M", "8M", "7M"],
			rototiller: ["7L1"],
			round: ["8M", "7M"],
			sandattack: ["9L1", "8L1", "8V", "7L1"],
			sandstorm: ["9M", "9L30", "8M", "8L30", "7M"],
			sandtomb: ["9L0", "8M", "8L0", "7L1"],
			scorchingsands: ["8T"],
			scratch: ["8V"],
			screech: ["8M", "8V"],
			shadowclaw: ["9M", "8M", "7M"],
			slash: ["8V"],
			sleeptalk: ["9M", "8M", "7M"],
			sludgebomb: ["9M", "8M", "8V", "7M"],
			sludgewave: ["8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "8V", "7T"],
			steelbeam: ["9M", "8T"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M"],
			substitute: ["9M", "8M", "8V", "7M"],
			suckerpunch: ["9L20", "8L20", "8V", "7L22"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			toxic: ["8V", "7M"],
			triattack: ["9L1", "8M", "8L1", "8V", "7L1"],
			uproar: ["8M"],
			workup: ["8M", "7M"],
		},
	},
	meowth: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M"],
			amnesia: ["9M", "8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			assist: ["7E", "6E", "5E", "4E", "4S5", "3E"],
			assurance: ["9L24", "8M", "8L24", "7L41", "6L41", "5L41", "4L41"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["9L16", "8L16", "8V", "7L6", "7V", "6L6", "6S7", "5L6", "4L6", "4S4", "3L10", "3S2", "3S3"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			bubblebeam: ["7V"],
			captivate: ["7L46", "6L46", "5L46", "4M", "4L46"],
			charm: ["9M", "8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			covet: ["9E", "8E", "7T", "6T", "5T"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["9M", "8M", "8V", "7M", "6M", "5T", "4M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9L1", "8L1", "8V", "7L9", "6L9", "6S7", "5L9", "4L9", "4S4", "4S5", "3L43"],
			faketears: ["9M"],
			falseswipe: ["9M"],
			feint: ["9L4", "8L4", "8V", "7L50", "6L50", "5L54", "4L54"],
			feintattack: ["7L22", "7V", "6L22", "5L22", "4L22", "3L25"],
			flail: ["9E", "8E", "7E", "6E", "5E", "4E"],
			flash: ["6M", "5M", "4M", "3M"],
			foulplay: ["9M", "8M", "8V", "7T", "7E", "6T", "6E", "5T", "5E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["9L29", "8L29", "8V", "7L14", "7V", "6L14", "5L14", "5S6", "4L14", "4S4", "3L36"],
			growl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1", "3S2"],
			gunkshot: ["9M", "8M", "7T", "6T", "5T", "4T"],
			happyhour: ["6S7"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			hypnosis: ["9E", "8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lashout: ["8T"],
			lastresort: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			metalclaw: ["9M"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "9L40", "8M", "8L40", "8V", "7L38", "6L38", "5L38", "5S6", "4L38"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightslash: ["7L49", "6L49", "5L49", "4L49"],
			odorsleuth: ["7E", "6E", "5E", "4E"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			payday: ["9L12", "8M", "8L12", "8V", "7L30", "7V", "6L30", "5L30", "4L30", "4S5", "3L18", "3S3"],
			petaldance: ["3S0"],
			playrough: ["9M", "9L44", "8M", "8L44", "8V"],
			powergem: ["9M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			punishment: ["7E", "6E", "5E", "4E"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scratch: ["9L8", "8L8", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "4S5", "3L1", "3S0", "3S1", "3S2"],
			screech: ["9L32", "8M", "8L32", "8V", "7L17", "7V", "6L17", "6S7", "5L17", "4L17", "4S4", "3L31"],
			secretpower: ["6M", "5D", "4M", "3M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T", "4T"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sing: ["5S6", "3S3"],
			skullbash: ["7V"],
			slash: ["9L36", "8L36", "8V", "7L33", "7V", "6L33", "5L33", "4L33", "3L40", "3S3"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["9M"],
			snatch: ["7T", "7E", "6T", "6E", "5T", "5E", "5S6", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["9E", "8E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T", "3L45"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwhip: ["9E", "8E", "7E", "6E", "5E", "4E"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "9L20", "8M", "8L20", "8V", "7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["scratch", "growl", "petaldance"], pokeball: "pokeball"},
			{generation: 3, level: 5, moves: ["scratch", "growl"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["scratch", "growl", "bite"], pokeball: "pokeball"},
			{generation: 3, level: 22, moves: ["sing", "slash", "payday", "bite"]},
			{generation: 4, level: 21, gender: "F", nature: "Jolly", abilities: ["pickup"], moves: ["bite", "fakeout", "furyswipes", "screech"], pokeball: "cherishball"},
			{generation: 4, level: 10, gender: "M", nature: "Jolly", abilities: ["pickup"], moves: ["fakeout", "payday", "assist", "scratch"], pokeball: "cherishball"},
			{generation: 5, level: 15, gender: "M", abilities: ["pickup"], moves: ["furyswipes", "sing", "nastyplot", "snatch"], pokeball: "cherishball"},
			{generation: 6, level: 20, abilities: ["pickup"], moves: ["happyhour", "screech", "bite", "fakeout"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 10},
			{generation: 3, level: 3, gender: "M", nature: "Naive", ivs: {hp: 4, atk: 5, def: 4, spa: 5, spd: 4, spe: 4}, abilities: ["pickup"], pokeball: "pokeball"},
		],
	},
	meowthalola: {
		learnset: {
			aerialace: ["9M", "7M"],
			agility: ["9M"],
			amnesia: ["9M", "8M", "7E"],
			assist: ["7E"],
			assurance: ["9L24", "8M", "8L24", "7L41"],
			attract: ["8M", "7M"],
			bite: ["9L16", "8L16", "8V", "7L6"],
			bodyslam: ["9M", "8M"],
			captivate: ["7L46"],
			charm: ["9M", "8M", "7E"],
			chillingwater: ["9M"],
			confide: ["7M"],
			confuseray: ["9M"],
			covet: ["9E", "8E", "7T", "7E"],
			darkpulse: ["9M", "8M", "8V", "7M", "7L55"],
			dig: ["9M", "8M"],
			doubleteam: ["7M"],
			dreameater: ["8V", "7M"],
			echoedvoice: ["7M"],
			embargo: ["7M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "8V", "7M"],
			fakeout: ["9L1", "8L1", "8V", "7L9"],
			faketears: ["9M"],
			feint: ["9L4", "8L4", "8V", "7L50"],
			feintattack: ["7L22"],
			flail: ["9E", "8E", "7E"],
			flatter: ["9E", "8E", "7E"],
			foulplay: ["9M", "8M", "8V", "7T", "7E"],
			frustration: ["7M"],
			furyswipes: ["9L29", "8L29", "8V", "7L14"],
			growl: ["9L1", "8L1", "8V", "7L1"],
			gunkshot: ["9M", "8M", "7T"],
			headbutt: ["8V"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			hypervoice: ["9M", "8M", "7T"],
			hypnosis: ["9E", "8E", "7E"],
			icywind: ["9M", "8M", "7T"],
			irontail: ["8M", "8V", "7T"],
			knockoff: ["7T"],
			lashout: ["8T"],
			lastresort: ["7T"],
			metalclaw: ["9M"],
			nastyplot: ["9M", "9L40", "8M", "8L40", "8V", "7L38"],
			nightslash: ["9L36", "8L36", "7L49"],
			partingshot: ["9E", "8E", "7E"],
			payback: ["8M", "7M"],
			payday: ["9L12", "8M", "8L12", "8V", "7L30"],
			playrough: ["9M", "9L44", "8M", "8L44", "8V"],
			powergem: ["9M"],
			protect: ["9M", "8M", "8V", "7M"],
			psychup: ["7M"],
			punishment: ["7E"],
			quash: ["7M"],
			raindance: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "8V", "7M"],
			retaliate: ["8M"],
			return: ["7M"],
			round: ["8M", "7M"],
			scratch: ["9L8", "8L8", "8V", "7L1"],
			screech: ["9L32", "8M", "8L32", "8V", "7L17"],
			seedbomb: ["9M", "8M", "7T"],
			shadowball: ["9M", "8M", "8V", "7M"],
			shadowclaw: ["9M", "8M", "7M"],
			shockwave: ["7T"],
			slash: ["8V", "7L33"],
			sleeptalk: ["9M", "8M", "7M"],
			snarl: ["9M"],
			snatch: ["7T", "7E"],
			snore: ["8M", "7T"],
			spite: ["9E", "8E", "7T", "7E"],
			substitute: ["9M", "8M", "8V", "7M"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L20", "8M", "8L20", "8V", "7M", "7L25"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			throatchop: ["8M", "7T"],
			thunder: ["9M", "8M", "8V", "7M"],
			thunderbolt: ["9M", "8M", "8V", "7M"],
			thunderwave: ["9M"],
			torment: ["7M"],
			toxic: ["8V", "7M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T"],
			uturn: ["9M", "8M", "8V", "7M"],
			waterpulse: ["7T"],
			workup: ["8M", "7M"],
		},
	},
	meowthgalar: {
		learnset: {
			aerialace: ["9M"],
			amnesia: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M"],
			charm: ["9M"],
			covet: ["9E", "8E"],
			crunch: ["9M", "8M"],
			curse: ["9E", "8E"],
			darkpulse: ["9M", "8M"],
			dig: ["9M", "8M"],
			doubleedge: ["9E", "8E"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fakeout: ["9L1", "8L1", "8S0"],
			faketears: ["9M"],
			falseswipe: ["9M"],
			flail: ["9E", "8E"],
			flashcannon: ["9M"],
			fling: ["9M"],
			foulplay: ["9M", "8M"],
			furyswipes: ["9L29", "8L29"],
			growl: ["9L1", "8L1", "8S0"],
			gunkshot: ["9M", "8M"],
			gyroball: ["8M"],
			helpinghand: ["9M"],
			honeclaws: ["9L4", "8L4", "8S0"],
			hypervoice: ["9M", "8M"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M", "8M"],
			irontail: ["8M"],
			lashout: ["8T"],
			metalclaw: ["9M", "9L16", "8L16"],
			metalsound: ["9L40", "8L40"],
			metronome: ["9M"],
			nastyplot: ["9M", "8M"],
			nightslash: ["9E", "8E"],
			payback: ["8M"],
			payday: ["9L12", "8M", "8L12", "8S0"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M"],
			raindance: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			round: ["8M"],
			scratch: ["9L8", "8L8"],
			screech: ["9L32", "8M", "8L32"],
			seedbomb: ["9M", "8M"],
			shadowball: ["9M", "8M"],
			shadowclaw: ["9M", "8M"],
			slash: ["9L36", "8L36"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			spite: ["9E", "8E"],
			stealthrock: ["9M"],
			steelbeam: ["9M", "8T"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			swagger: ["9L24", "8L24"],
			swordsdance: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L20", "8M", "8L20"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thrash: ["9L44", "8L44"],
			throatchop: ["8M"],
			thunder: ["9M", "8M"],
			thunderbolt: ["9M", "8M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			uturn: ["9M", "8M"],
			workup: ["8M"],
			xscissor: ["9M"],
		},
		eventData: [
			{generation: 8, level: 15, isHidden: true, moves: ["fakeout", "growl", "honeclaws", "payday"], pokeball: "cherishball"},
		],
	},
	persian: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M"],
			amnesia: ["9M", "8M", "8V"],
			assurance: ["9L24", "8M", "8L24", "7L49", "6L49", "5L49", "4L49"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["9L16", "8L16", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			bubblebeam: ["7V"],
			captivate: ["7L56", "6L56", "5L56", "4M", "4L56"],
			charm: ["9M", "8M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["9M", "8M", "8V", "7M", "6M", "5T", "4M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9L1", "8L1", "8V", "7L1", "6L1", "5L1", "4L1", "3L55"],
			faketears: ["9M"],
			falseswipe: ["9M"],
			feint: ["9L1", "8L1", "8V", "7L65", "6L65", "5L68", "4L68"],
			feintattack: ["7L22", "7V", "6L22", "5L22", "4L22", "3L25"],
			flash: ["6M", "5M", "4M", "3M"],
			foulplay: ["9M", "8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["9L31", "8L31", "8V", "7L14", "7V", "6L14", "5L14", "4L14", "3L42"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			gunkshot: ["9M", "8M", "7T", "6T", "5T", "4T"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			hypnosis: ["8V"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lashout: ["8T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			metalclaw: ["9M"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "9L48", "8M", "8L48", "8V", "7L44", "6L44", "5L44", "4L44"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightslash: ["7L61", "6L61", "5L61", "4L61"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			payday: ["9L12", "8M", "8L12", "8V", "7V", "3L18"],
			playrough: ["9M", "9L54", "8M", "8L54", "8V", "7L1", "6L1"],
			powergem: ["9M", "9L0", "8M", "8L0", "7L32", "6L32", "5L32", "4L32"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scratch: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["9L36", "8M", "8L36", "8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L34"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T", "4T"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skittersmack: ["8T"],
			skullbash: ["7V"],
			slash: ["9L42", "8L42", "8V", "7L37", "7V", "6L37", "5L37", "4L37", "3L49"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["9M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T", "3L61"],
			swift: ["9M", "8M", "8V", "7L1", "7V", "6L28", "5L28", "4T", "3T"],
			switcheroo: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "9L20", "8M", "8L20", "8V", "7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 2, level: 18},
			{generation: 4, level: 19},
		],
	},
	persianalola: {
		learnset: {
			aerialace: ["9M", "7M"],
			agility: ["9M"],
			amnesia: ["9M", "8M", "8V"],
			assurance: ["9L24", "8M", "8L24", "7L49"],
			attract: ["8M", "7M"],
			beatup: ["8M"],
			bite: ["9L16", "8L16", "8V", "7L1"],
			bodyslam: ["9M", "8M"],
			burningjealousy: ["8T"],
			captivate: ["7L56"],
			charm: ["9M", "8M"],
			chillingwater: ["9M"],
			confide: ["7M"],
			confuseray: ["9M"],
			covet: ["7T"],
			darkpulse: ["9M", "8M", "8V", "7M", "7L69"],
			dig: ["9M", "8M"],
			doubleteam: ["7M"],
			dreameater: ["8V", "7M"],
			echoedvoice: ["7M"],
			embargo: ["7M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "8V", "7M"],
			fakeout: ["9L1", "8L1", "8V", "7L1"],
			faketears: ["9M", "8M"],
			feint: ["9L1", "8L1", "8V", "7L65"],
			feintattack: ["7L22"],
			foulplay: ["9M", "8M", "8V", "7T"],
			frustration: ["7M"],
			furyswipes: ["9L31", "8L31", "8V", "7L14"],
			gigaimpact: ["9M", "8M", "7M"],
			growl: ["9L1", "8L1", "8V", "7L1"],
			gunkshot: ["9M", "8M", "7T"],
			headbutt: ["8V"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M", "8M", "8V", "7M"],
			hypervoice: ["9M", "8M", "7T"],
			hypnosis: ["8V"],
			icywind: ["9M", "8M", "7T"],
			irontail: ["8M", "8V", "7T"],
			knockoff: ["7T"],
			lashout: ["8T"],
			lastresort: ["7T"],
			metalclaw: ["9M"],
			nastyplot: ["9M", "9L48", "8M", "8L48", "8V", "7L44"],
			nightslash: ["9L42", "8L42", "7L61"],
			payback: ["8M", "7M"],
			payday: ["9L12", "8M", "8L12", "8V"],
			playrough: ["9M", "9L54", "8M", "8L54", "8V", "7L1"],
			powergem: ["9M", "9L0", "8M", "8L0", "7L32"],
			protect: ["9M", "8M", "8V", "7M"],
			psychup: ["7M"],
			quash: ["9L1", "8L1", "7M", "7L1"],
			raindance: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "8V", "7M"],
			retaliate: ["8M"],
			return: ["7M"],
			roar: ["7M"],
			round: ["8M", "7M"],
			scratch: ["9L1", "8L1", "8V", "7L1"],
			screech: ["9L36", "8M", "8L36", "8V", "7L17"],
			seedbomb: ["9M", "8M", "7T"],
			shadowball: ["9M", "8M", "8V", "7M"],
			shadowclaw: ["9M", "8M", "7M"],
			shockwave: ["7T"],
			skittersmack: ["8T"],
			slash: ["8V", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snarl: ["9M", "8M", "7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			spite: ["7T"],
			substitute: ["9M", "8M", "8V", "7M"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swift: ["9M", "8M", "8V", "7L1"],
			switcheroo: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			taunt: ["9M", "9L20", "8M", "8L20", "8V", "7M", "7L25"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			throatchop: ["8M", "7T"],
			thunder: ["9M", "8M", "8V", "7M"],
			thunderbolt: ["9M", "8M", "8V", "7M"],
			thunderwave: ["9M"],
			torment: ["7M"],
			toxic: ["8V", "7M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T"],
			uturn: ["9M", "8M", "8V", "7M"],
			waterpulse: ["7T"],
			workup: ["8M", "7M"],
		},
	},
	perrserker: {
		learnset: {
			amnesia: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M"],
			charm: ["9M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "8M"],
			crunch: ["9M", "8M"],
			darkpulse: ["9M", "8M"],
			dig: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fakeout: ["9L1", "8L1"],
			faketears: ["9M"],
			falseswipe: ["9M"],
			fling: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			furyswipes: ["9L31", "8L31"],
			gigaimpact: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			gunkshot: ["9M", "8M"],
			gyroball: ["8M"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M"],
			honeclaws: ["9L1", "8L1"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			irondefense: ["9M", "9L1", "8M", "8L1"],
			ironhead: ["9M", "9L0", "8M", "8L0"],
			irontail: ["8M"],
			lashout: ["8T"],
			metalburst: ["9L1", "8L1"],
			metalclaw: ["9M", "9L16", "8L16"],
			metalsound: ["9L48", "8L48"],
			metronome: ["9M"],
			nastyplot: ["9M", "8M"],
			payback: ["8M"],
			payday: ["9L12", "8M", "8L12"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M"],
			raindance: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			round: ["8M"],
			scratch: ["9L1", "8L1"],
			screech: ["9L36", "8M", "8L36"],
			seedbomb: ["9M", "8M"],
			shadowball: ["9M", "8M"],
			shadowclaw: ["9M", "8M"],
			slash: ["9L42", "8L42"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			stealthrock: ["9M"],
			steelbeam: ["9M", "8T"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			swagger: ["9L24", "8L24"],
			swordsdance: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L20", "8M", "8L20"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thrash: ["9L54", "8L54"],
			throatchop: ["8M"],
			thunder: ["9M", "8M"],
			thunderbolt: ["9M", "8M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			uturn: ["9M", "8M"],
			workup: ["8M"],
			xscissor: ["9M"],
		},
	},
	psyduck: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			amnesia: ["9M", "9L34", "8M", "8L34", "8V", "7L37", "6L43", "5L48", "4L44"],
			aquatail: ["9L24", "8L24", "7T", "7L28", "6T", "6L29", "5T", "5L32", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "5D", "4M"],
			bubblebeam: ["7V"],
			calmmind: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			clearsmog: ["9E", "8E", "7E", "6E"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9E", "8E", "7E", "6E", "5E", "4E"],
			confusion: ["9L6", "8L6", "8V", "7L10", "7V", "6L11", "5L18", "4L18", "3L16", "3S0"],
			counter: ["7V", "3T"],
			crosschop: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			curse: ["7V"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			disable: ["9L15", "8L15", "8V", "7L19", "7V", "6L11", "5L14", "4L14", "3L10", "3S0"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			encore: ["9M", "8M", "7E", "6E", "5E", "5D", "4E"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["9L9", "8L9", "8V", "7L13", "7V", "6L15", "5L27", "4L27", "3L40"],
			futuresight: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["9M", "8M", "8L36", "8V", "7L40", "7V", "6L46", "5L53", "4L48", "3L50"],
			hypnosis: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lightscreen: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4E", "3E"],
			liquidation: ["9M"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["9M"],
			mimic: ["7V", "3T"],
			mudbomb: ["7E", "6E", "5E", "4E"],
			mudshot: ["9M"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["3S1"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			payday: ["8M", "8V", "7V"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9E", "8E", "8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			psychic: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3E"],
			psychup: ["9L30", "8L30", "7M", "7L34", "7V", "6M", "6L39", "5M", "5L40", "4M", "4L35", "3T", "3L31"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			refresh: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scratch: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			screech: ["9L21", "8M", "8L21", "8V", "7L22", "7V", "6L25", "5L31", "4L31", "3L23", "3S0"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			simplebeam: ["9E", "8E", "7E", "6E"],
			skillswap: ["9M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			soak: ["9L27", "8L27", "7L31", "6L36", "5L35"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			synchronoise: ["7E", "6E", "5E"],
			tailwhip: ["9L1", "8L1", "8V", "7L4", "7V", "6L4", "5L5", "4L5", "3L5", "3S0", "3S1"],
			takedown: ["9M", "7V"],
			taunt: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			waterfall: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["9L3", "8L3", "8V", "7L7", "7V", "6L8", "5L9", "4L9"],
			waterpulse: ["9M", "9L12", "8L12", "7T", "7L16", "6T", "6L18", "5L22", "4M", "4L22", "3M"],
			watersport: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1", "3S1"],
			whirlpool: ["8M", "7V", "4M"],
			wonderroom: ["9L39", "8M", "8L39", "7T", "7L43", "6T", "6L50", "5T", "5L57"],
			worryseed: ["7T", "6T", "5T", "4T"],
			yawn: ["9E", "8E", "7E", "6E", "5E", "4E"],
			zenheadbutt: ["9M", "9L18", "8M", "8L18", "7T", "7L25", "6T", "6L29", "5T", "5L44", "4T", "4L40"],
		},
		eventData: [
			{generation: 3, level: 27, gender: "M", nature: "Lax", ivs: {hp: 31, atk: 16, def: 12, spa: 29, spd: 31, spe: 14}, abilities: ["damp"], moves: ["tailwhip", "confusion", "disable", "screech"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, moves: ["watersport", "scratch", "tailwhip", "mudsport"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 15},
		],
	},
	golduck: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			amnesia: ["9M", "9L36", "8M", "8L36", "8V", "7L41", "6L49", "5L56", "4L50"],
			aquajet: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			aquatail: ["9L24", "8L24", "7T", "7L28", "6T", "6L32", "5T", "5L32", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M", "3S0"],
			brine: ["8M", "4M"],
			bubblebeam: ["7V"],
			calmmind: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["3S0"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L1", "8L1", "8V", "7L10", "7V", "6L11", "5L18", "4L18", "3L16"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			disable: ["9L15", "8L15", "8V", "7L19", "7V", "6L11", "5L14", "4L14", "3L1"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			encore: ["9M", "8M", "8V", "7S1"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["9L9", "8L9", "8V", "7L13", "7V", "6L15", "5L27", "4L27", "3L44"],
			futuresight: ["8M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["9M", "9L40", "8M", "8L40", "8V", "7L46", "7V", "7S1", "6L54", "5L63", "4L56", "3L58"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M"],
			liquidation: ["9M", "8M", "7T"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			mefirst: ["7L1"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["9M"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			mudshot: ["9M"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			payday: ["8M", "8V", "7V"],
			powergem: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "7S1", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "8V"],
			psychic: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			psychup: ["9L30", "8L30", "7M", "7L36", "7V", "6M", "6L43", "5M", "5L44", "4M", "4L37", "3T", "3L31", "3S0"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "7S1", "6M", "5M"],
			scratch: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["9L21", "8M", "8L21", "8V", "7L22", "7V", "6L25", "5L31", "4L31", "3L23"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			soak: ["9L27", "8L27", "7L31", "6L38", "5L37"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwhip: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "7V"],
			taunt: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			waterfall: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			watergun: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1"],
			waterpulse: ["9M", "9L12", "8L12", "7T", "7L16", "6T", "6L18", "5L22", "4M", "4L22", "3M"],
			watersport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			whirlpool: ["8M", "7V", "4M"],
			wonderroom: ["9L45", "8M", "8L45", "7T", "7L51", "6T", "6L60", "5T", "5L69"],
			worryseed: ["7T", "6T", "5T", "4T"],
			yawn: ["8V"],
			zenheadbutt: ["9M", "9L18", "8M", "8L18", "7T", "7L25", "6T", "6L25", "5T", "5L50", "4T", "4L44"],
		},
		eventData: [
			{generation: 3, level: 33, moves: ["charm", "waterfall", "psychup", "brickbreak"]},
			{generation: 7, level: 50, gender: "M", nature: "Timid", ivs: {hp: 31, atk: 30, def: 31, spa: 31, spd: 31, spe: 31}, isHidden: true, moves: ["hydropump", "scald", "encore", "protect"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 10},
			{generation: 3, level: 25, pokeball: "safariball"},
			{generation: 4, level: 10},
		],
	},
	mankey: {
		learnset: {
			acrobatics: ["9M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			assurance: ["9L26", "7L26", "6L25", "5L25", "4L25"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			bodyslam: ["9M", "7V", "3T"],
			brickbreak: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["9M", "9L33", "7L36", "7E", "6L49", "6E", "5L49", "5E", "4L49", "4E"],
			confide: ["7M", "6M"],
			counter: ["9E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			covet: ["9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4L1"],
			crosschop: ["9L22", "7L22", "7V", "6L37", "5L37", "4L37", "3L31"],
			curse: ["9E", "7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["9M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			encore: ["9M", "9E", "7E", "6E", "5E", "4E"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			finalgambit: ["9L48", "7L50", "6L53", "5L53"],
			firepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L21"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["9L5", "8V", "7L5", "7V", "6L9", "5L9", "4L9", "3L16"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			icepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			karatechop: ["8V", "7L8", "7V", "6L13", "5L13", "4L13", "3L11"],
			leer: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["9M", "9L8", "8V", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "5D", "4T", "4L1", "3L6"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			meditate: ["7E", "7V", "6E", "5E", "4E", "3E"],
			megakick: ["7V", "3T"],
			megapunch: ["7V", "3T"],
			metronome: ["9M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["9E", "7E", "6E"],
			outrage: ["9M", "9L44", "8V", "7T", "7L47", "6T", "5T", "4T"],
			overheat: ["9M", "7M", "6M", "5M", "4M", "3M"],
			payback: ["7M", "6M", "5M", "4M"],
			payday: ["8V", "7V"],
			poisonjab: ["9M", "8V", "7M", "6M", "5M", "4M"],
			powertrip: ["7E"],
			poweruppunch: ["6M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7V", "3T"],
			punishment: ["7L29", "6L45", "5L45", "4L45"],
			pursuit: ["7L12"],
			rage: ["7V"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["7E", "6E", "5E", "4E", "3E"],
			reversal: ["9M", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			scratch: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["9L36", "8V", "7L40", "7V", "6L21", "5L21", "4L21", "3L41"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			seismictoss: ["9L12", "8V", "7L15", "7V", "6L17", "5L17", "4L17", "3T", "3L26"],
			shadowclaw: ["9M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			smellingsalts: ["7E", "6E", "5E", "4E", "3E"],
			snore: ["7T", "7V", "6T", "3T"],
			spite: ["9E", "7T", "6T", "5T", "4T"],
			stompingtantrum: ["9M", "9L40", "7T", "7L43"],
			stoneedge: ["9M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["9L17", "7M", "7L19", "7V", "6M", "6L33", "5M", "5L33", "4M", "4L33", "3T", "3L36"],
			swift: ["9M", "7V", "4T", "3T"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["9L29", "8V", "7L33", "7V", "6L41", "5L41", "4L41", "3L46"],
			thunder: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8V", "7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["9M", "8V", "7M", "6M", "5M", "4M"],
			vacuumwave: ["4T"],
			workup: ["7M", "5M"],
		},
		encounters: [
			{generation: 1, level: 3},
			{generation: 3, level: 2},
		],
	},
	primeape: {
		learnset: {
			acrobatics: ["9M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			assurance: ["9L26", "7L26", "6L25", "5L25", "4L25"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["9M", "7V", "3T"],
			brickbreak: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["9M", "9L39", "7L39", "6L59", "5L59", "4L59"],
			confide: ["7M", "6M"],
			counter: ["8V", "7V", "3T"],
			covet: ["7T", "6T", "5T"],
			crosschop: ["9L22", "7L22", "7V", "6L41", "5L41", "4L41", "3L35", "3S0"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["9M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			encore: ["9M", "8V"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			finalgambit: ["9L57", "7L1", "6L1", "5L63"],
			firepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fling: ["9M", "9L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L21", "3S0"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["9L5", "8V", "7L5", "7V", "6L9", "5L9", "4L9", "3L16"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M", "8V", "7T", "6T", "5T", "4T", "3S0"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			karatechop: ["8V", "7L8", "7V", "6L13", "5L13", "4L13", "3L11"],
			leer: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["9M", "9L8", "8V", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			megakick: ["7V", "3T"],
			megapunch: ["7V", "3T"],
			metronome: ["9M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "9L53", "8V", "7T", "7L53", "6T", "5T", "4T"],
			overheat: ["9M", "7M", "6M", "5M", "4M", "3M"],
			payback: ["7M", "6M", "5M", "4M"],
			payday: ["8V", "7V"],
			poisonjab: ["9M", "8V", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7V", "3T"],
			punishment: ["7L30", "6L53", "5L53", "4L53"],
			pursuit: ["7L12"],
			rage: ["8V", "7L1", "7V", "6L28", "5L28", "4L28", "3L1"],
			ragefist: ["9L35"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "3S0"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			scratch: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["9L44", "8V", "7L44", "7V", "6L21", "5L21", "4L21", "3L53"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			seismictoss: ["9L15", "8V", "7L15", "7V", "6L17", "5L17", "4L17", "3T", "3L26"],
			shadowclaw: ["9M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["7T", "7V", "6T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M", "9L48", "7T", "7L48"],
			stoneedge: ["9M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["9L17", "7M", "7L19", "7V", "6M", "6L35", "5M", "5L35", "4M", "4L35", "3T", "3L44"],
			swift: ["9M", "7V", "4T", "3T"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["9L30", "8V", "7L35", "7V", "6L47", "5L47", "4L47", "3L62"],
			throatchop: ["7T"],
			thunder: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["9M", "8V", "7M", "6M", "5M", "4M"],
			vacuumwave: ["4T"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 34, abilities: ["vitalspirit"], moves: ["helpinghand", "crosschop", "focusenergy", "reversal"]},
		],
		encounters: [
			{generation: 2, level: 15},
			{generation: 4, level: 15},
		],
	},
	annihilape: {
		learnset: {
			acrobatics: ["9M"],
			assurance: ["9L26"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulkup: ["9M"],
			bulldoze: ["9M"],
			closecombat: ["9M", "9L39"],
			counter: ["9L1"],
			crosschop: ["9L22"],
			dig: ["9M"],
			drainpunch: ["9M"],
			earthquake: ["9M"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			finalgambit: ["9L57"],
			firepunch: ["9M"],
			fling: ["9M", "9L1"],
			focusblast: ["9M"],
			focusenergy: ["9L1"],
			furyswipes: ["9L5"],
			gigaimpact: ["9M"],
			gunkshot: ["9M"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			icepunch: ["9M"],
			leer: ["9L1"],
			lowkick: ["9M", "9L8"],
			lowsweep: ["9M"],
			metronome: ["9M"],
			nightshade: ["9M"],
			outrage: ["9M", "9L53"],
			overheat: ["9M"],
			phantomforce: ["9M"],
			poisonjab: ["9M"],
			protect: ["9M"],
			ragefist: ["9L35"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			scaryface: ["9M"],
			scratch: ["9L1"],
			screech: ["9L44"],
			seedbomb: ["9M"],
			seismictoss: ["9L12"],
			shadowball: ["9M"],
			shadowclaw: ["9M"],
			shadowpunch: ["9L0"],
			sleeptalk: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M", "9L48"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swagger: ["9L17"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L30"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderpunch: ["9M"],
			uturn: ["9M"],
		},
	},
	growlithe: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L20", "8M", "8L20", "8V", "7L30", "7V", "6L30", "5L42", "4L39", "3L43"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["9L8", "8L8", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S1", "3S2"],
			bodyslam: ["9M", "8M", "7E", "7V", "6E", "5E", "5D", "4E", "3T", "3E"],
			burnup: ["7E"],
			captivate: ["4M"],
			charm: ["9M", "3S2"],
			closecombat: ["9M", "8M", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			covet: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			crunch: ["9M", "9L32", "8M", "8L32", "8V", "7L39", "7E", "7V", "6L39", "6E", "5L45", "5E", "4L42", "4E", "3E"],
			curse: ["7V"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3T"],
			doublekick: ["9E", "8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["7V"],
			dragonrage: ["7V"],
			ember: ["9L1", "8L1", "8V", "7L6", "7V", "6L6", "5L6", "4L6", "3L7", "3S1"],
			endure: ["9M", "8M", "7V", "5D", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "9L24", "8M", "8L24", "7L21", "6L21", "5L28", "4L28"],
			firespin: ["9M", "8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			flameburst: ["7L28", "6L28", "5L31"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L40", "8M", "8L40", "8V", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L39", "4M", "4L34", "3M", "3L49", "3S2"],
			flamewheel: ["9L12", "8L12", "7L17", "7V", "6L17", "5L20", "4L20", "3L31", "3S0"],
			flareblitz: ["9M", "9L56", "8M", "8L56", "8V", "7L45", "7E", "6L45", "6E", "5L56", "5E", "4L48", "4E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["9M", "8M", "8V", "7T", "7L41", "7E", "6T", "6L41", "6E", "5T", "5L51", "5E", "4T", "4L45", "4E", "3E"],
			helpinghand: ["9M", "9L16", "8M", "8L16", "8V", "7T", "7L12", "6T", "6L12", "5T", "5L17", "4T", "4L17", "3L37"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			howl: ["9L4", "8L4", "7E", "6E", "5E", "4E", "3E"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			leer: ["9L1", "8L1", "8V", "7L8", "7V", "6L8", "5L9", "4L9", "3L13", "3S0"],
			mimic: ["7V", "3T"],
			morningsun: ["9E", "8E", "7E", "6E", "5E", "4E"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			odorsleuth: ["7L10", "6L10", "5L14", "4L14", "3L19", "3S0"],
			outrage: ["9M", "8M", "8V", "7T", "7L43", "6T", "6L43", "5T", "5L43"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			playrough: ["9M", "9L48", "8M", "8L48", "8V"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychicfangs: ["9M", "8M"],
			rage: ["7V"],
			ragingfury: ["9E"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["9L28", "8M", "8L28", "7L32", "6M", "6L32", "5M", "5L48"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "9L52", "8M", "8L52", "7L19", "6L19", "5L25", "4L25"],
			roar: ["9L44", "8L44", "8V", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "5D", "4M", "4L1", "3M", "3L1", "3S1"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			takedown: ["9M", "9L36", "8L36", "8V", "7L23", "7V", "6L23", "5L34", "4L31", "3L25", "3S0", "3S2"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thrash: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			thunderfang: ["9M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 32, gender: "F", nature: "Quiet", ivs: {hp: 11, atk: 24, def: 28, spa: 1, spd: 20, spe: 2}, abilities: ["intimidate"], moves: ["leer", "odorsleuth", "takedown", "flamewheel"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["bite", "roar", "ember"], pokeball: "pokeball"},
			{generation: 3, level: 28, moves: ["charm", "flamethrower", "bite", "takedown"]},
		],
		encounters: [
			{generation: 1, level: 15},
		],
	},
	growlithehisui: {
		learnset: {
			agility: ["9M"],
			bite: ["9L8"],
			bodyslam: ["9M"],
			closecombat: ["9M"],
			covet: ["9E"],
			crunch: ["9M", "9L32"],
			dig: ["9M"],
			doubleedge: ["9E"],
			doublekick: ["9E"],
			ember: ["9L1"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M", "9L24"],
			firespin: ["9M"],
			flamecharge: ["9M"],
			flamethrower: ["9M", "9L40"],
			flamewheel: ["9L12"],
			flareblitz: ["9M", "9L56"],
			headsmash: ["9E"],
			heatwave: ["9M"],
			helpinghand: ["9M", "9L16"],
			howl: ["9L4"],
			leer: ["9L1"],
			morningsun: ["9E"],
			outrage: ["9M"],
			overheat: ["9M"],
			powergem: ["9M"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			rest: ["9M"],
			retaliate: ["9L28"],
			reversal: ["9M", "9L52"],
			roar: ["9L44"],
			rockblast: ["9M"],
			rockslide: ["9M", "9L48"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			smartstrike: ["9M"],
			stealthrock: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			takedown: ["9M", "9L36"],
			terablast: ["9M"],
			thrash: ["9E"],
			thunderfang: ["9M"],
			willowisp: ["9M"],
		},
	},
	arcanine: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L1", "8M", "8L1"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["9L1", "8L1", "8V", "7L1", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			burnup: ["8L1"],
			captivate: ["4M"],
			charm: ["9M"],
			closecombat: ["9M", "8M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["9M", "9L1", "8M", "8L1", "4S0"],
			curse: ["7V"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8V", "7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["7V"],
			dragonpulse: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			dragonrage: ["7V"],
			ember: ["9L1", "8L1", "8V", "7V", "3L1"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			extremespeed: ["9L0", "8L0", "7L34", "7V", "7S1", "6L34", "5L39", "4L39", "4S0", "3L49"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			firespin: ["9M", "8M"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L5", "8M", "8L1", "8V", "7M", "6M", "5M", "4M", "3M"],
			flamewheel: ["9L1", "8L1", "7V"],
			flareblitz: ["9M", "9L1", "8M", "8L1", "7S1", "4S0"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			howl: ["9L1", "8L1"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			leer: ["9L1", "8L1", "8V", "7V"],
			mimic: ["7V", "3T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			odorsleuth: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			outrage: ["9M", "8M", "8V", "7T", "6T", "5T"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			playrough: ["9M", "9L1", "8M", "8L1", "8V"],
			protect: ["9M", "8M", "8V", "7M", "7V", "7S1", "6M", "5M", "4M", "3M"],
			psychicfangs: ["9M", "8M"],
			rage: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["9L1", "8M", "8L1", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "9L1", "8M", "8L1"],
			roar: ["9L1", "8L1", "8V", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8V"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			takedown: ["9M", "9L1", "8L1", "7V"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderfang: ["9M", "8M", "7L1", "6L1", "5L1", "4L1", "4S0"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "8V", "7M", "7S1", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 4, level: 50, abilities: ["intimidate"], moves: ["flareblitz", "thunderfang", "crunch", "extremespeed"], pokeball: "cherishball"},
			{generation: 7, level: 50, abilities: ["intimidate"], moves: ["flareblitz", "extremespeed", "willowisp", "protect"], pokeball: "cherishball"},
		],
	},
	arcaninehisui: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M", "9L1"],
			bite: ["9L1"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			closecombat: ["9M"],
			crunch: ["9M", "9L1"],
			dig: ["9M"],
			dragonpulse: ["9M"],
			ember: ["9L1"],
			endure: ["9M"],
			extremespeed: ["9L0"],
			facade: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M", "9L1"],
			firespin: ["9M"],
			flamecharge: ["9M"],
			flamethrower: ["9M", "9L5"],
			flamewheel: ["9L1"],
			flareblitz: ["9M", "9L1"],
			gigaimpact: ["9M"],
			heatwave: ["9M"],
			helpinghand: ["9M", "9L1"],
			howl: ["9L1"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			ironhead: ["9M"],
			leer: ["9L1"],
			outrage: ["9M"],
			overheat: ["9M"],
			powergem: ["9M"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			ragingfury: ["9L64"],
			rest: ["9M"],
			retaliate: ["9L1"],
			reversal: ["9M", "9L1"],
			roar: ["9L1"],
			rockblast: ["9M"],
			rockslide: ["9M", "9L1"],
			rockthrow: ["9L1"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			smartstrike: ["9M"],
			snarl: ["9M"],
			solarbeam: ["9M"],
			stealthrock: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			takedown: ["9M", "9L1"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderfang: ["9M"],
			wildcharge: ["9M"],
			willowisp: ["9M"],
		},
	},
	poliwag: {
		learnset: {
			amnesia: ["7V"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bellydrum: ["8L48", "7L31", "7V", "6L31", "5L31", "4L31", "3L37"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "8L30", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3T", "3L31"],
			bubble: ["8V", "7L11", "7V", "6L11", "5L5", "4L5", "3L1", "3S0"],
			bubblebeam: ["8L18", "8V", "7L25", "7E", "7V", "6L25", "6E", "5L25", "5E", "4L25", "4E", "3E"],
			bulldoze: ["8M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["8L54", "7V", "3T"],
			doubleslap: ["8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L19"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "8L36"],
			encore: ["8M", "7E", "6E", "5E", "4E"],
			endeavor: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L42", "8V", "7L38", "7V", "6L38", "5L38", "4L38", "3L43"],
			hypnosis: ["8L1", "8V", "7L8", "7V", "6L8", "5L8", "5D", "4L8", "3L7"],
			iceball: ["7E", "6E", "5E", "4E", "3E"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			lowkick: ["8V"],
			mimic: ["7V", "3T"],
			mindreader: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			mist: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			mudbomb: ["7L41", "6L41", "5L41", "4L41"],
			muddywater: ["8M"],
			mudshot: ["8M", "8L12", "7L28", "7E", "6L28", "6E", "5L28", "5E", "4L28", "4E"],
			naturalgift: ["4M"],
			pound: ["8L6", "8V"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "8L24", "7M", "7L18", "7V", "6M", "6L18", "5M", "5L18", "4M", "4L18", "3M", "3L25"],
			refresh: ["7E", "6E", "5E", "4E"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "5D", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			splash: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["3S0"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			wakeupslap: ["7L35", "6L35", "5L35", "4L35"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "8V", "7L5", "7V", "6L5", "5L11", "4L11", "3L13"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			watersport: ["7L1", "7E", "6L1", "6E", "5L1", "5E", "4L1", "4E", "3E"],
			whirlpool: ["8M", "7V", "4M"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["bubble", "sweetkiss"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 5},
			{generation: 2, level: 3},
		],
	},
	poliwhirl: {
		learnset: {
			amnesia: ["7V"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bellydrum: ["8L56", "7L37", "7V", "6L37", "5L37", "4L37", "3L43"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "8L32", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3T", "3L35"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubble: ["8V", "7L11", "7V", "6L11", "5L1", "4L1", "3L1"],
			bubblebeam: ["8L18", "8V", "7L27", "7V", "6L27", "5L27", "4L27"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["8L66", "7V", "3T"],
			doubleslap: ["8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L19"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "8L40"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["8M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L48", "8V", "7L48", "7V", "6L48", "5L48", "4L48", "3L51"],
			hypnosis: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			lowkick: ["8V"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudbomb: ["7L53", "6L53", "5L53", "4L53"],
			muddywater: ["8M"],
			mudshot: ["8M", "8L1", "7L32", "6L32", "5L32", "4L32"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			pound: ["8L1", "8V"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "8L24", "7M", "7L18", "7V", "6M", "6L18", "5M", "5L18", "4M", "4L18", "3M", "3L27"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			wakeupslap: ["7L43", "6L43", "5L43", "4L43"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "8V", "7L1", "7V", "6L1", "5L11", "4L11", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			watersport: ["7L1", "6L1", "5L1", "4L1"],
			whirlpool: ["8M", "7V", "4M"],
		},
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 10},
			{generation: 3, level: 20},
			{generation: 4, level: 10},
			{generation: 7, level: 24},
			{generation: 7, level: 22, gender: "F", nature: "Naughty", abilities: ["damp"], pokeball: "pokeball"},
		],
	},
	poliwrath: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bellydrum: ["8L1"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "8L1", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M", "3S0"],
			bubble: ["8V"],
			bubblebeam: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1"],
			bulkup: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			circlethrow: ["8L1", "7L1", "6L1", "5L53"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			darkestlariat: ["8M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["8L1", "7V", "3T"],
			doubleslap: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M"],
			dualchop: ["7T"],
			dynamicpunch: ["8L1", "7L32", "7V", "6L32", "5L32", "4L43", "3T"],
			earthpower: ["8M", "8L1"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["8M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8V"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T", "3S0"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hydropump: ["8M", "8L1", "3S0"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			liquidation: ["8M"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mindreader: ["8L1", "7L43", "7V", "6L43", "5L43", "4L53", "3L51"],
			mist: ["8V"],
			muddywater: ["8M"],
			mudshot: ["8M", "8L1"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			pound: ["8L1", "8V"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "8L1", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["8M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["8L0", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "8V"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			vacuumwave: ["4T"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "8V", "7V", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 42, moves: ["helpinghand", "hydropump", "raindance", "brickbreak"]},
		],
	},
	politoed: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bellydrum: ["8L1"],
			blizzard: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "8L1", "3T"],
			bounce: ["8M", "8L0", "7T", "7L37", "6T", "6L37", "5T", "5L37", "4T", "4L37"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["8L1", "3T"],
			doubleslap: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["8M", "8L1"],
			earthquake: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L1"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "8L1", "7T", "7L48", "6T", "6L48", "5T", "5L48", "4L48"],
			hypnosis: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			icebeam: ["8M", "7M", "6M", "5M", "5S0", "4M", "3M"],
			icepunch: ["8M", "7T", "7V", "6T", "5T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			liquidation: ["8M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["8M", "3T"],
			mimic: ["3T"],
			muddywater: ["8M"],
			mudshot: ["8M", "8L1"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			perishsong: ["8L1", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			pound: ["8L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "5S0", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "8L1", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M", "5S0"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["8L1", "7M", "7L27", "7V", "6M", "6L27", "5M", "5L27", "4M", "4L27", "3T", "3L51"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M"],
			waterfall: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7V", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7V", "4M"],
		},
		eventData: [
			{generation: 5, level: 50, gender: "M", nature: "Calm", ivs: {hp: 31, atk: 13, def: 31, spa: 5, spd: 31, spe: 5}, isHidden: true, moves: ["scald", "icebeam", "perishsong", "protect"], pokeball: "cherishball"},
		],
	},
	abra: {
		learnset: {
			allyswitch: ["8M", "7T", "7E", "6E", "5M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			calmmind: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8E"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "5D", "4T"],
			guardsplit: ["8E", "7E", "6E", "5E"],
			guardswap: ["8M", "7E", "6E", "5E", "4E"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			irontail: ["8M", "8V", "7T", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			lightscreen: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			magiccoat: ["8E", "7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			powerswap: ["8M"],
			powertrick: ["7E", "6E", "5E", "4E"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychicterrain: ["8M", "7E"],
			psychoshift: ["7E", "6E"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			speedswap: ["8M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 1, level: 6},
		],
	},
	kadabra: {
		learnset: {
			allyswitch: ["8M", "8L15", "7T", "7L36", "6L24", "5M", "5L24"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			calmmind: ["8M", "8L50", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8L0", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			dig: ["8V", "7V"],
			disable: ["8L1", "8V", "7L18", "7V", "6L18", "5L18", "4L18", "3L18"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["8M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flash: ["8V", "7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L45", "7L43", "7V", "6L43", "5L48", "4L42", "3L30"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["8M"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "8V", "7T", "6T", "5T", "4M", "3M"],
			kinesis: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			miracleeye: ["7L23", "6L22", "5L22", "4L22"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["8V"],
			powerswap: ["8M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L5", "8V", "7L21", "7V", "6L21", "5L28", "4L24", "3L21"],
			psychic: ["8M", "8L35", "8V", "7M", "7L38", "7V", "6M", "6L38", "5M", "5L46", "4M", "4L40", "3M", "3L36"],
			psychicterrain: ["8M"],
			psychocut: ["8M", "8L20", "7L28", "6L28", "5L40", "4L34"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "8L30", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["8L25", "8V", "7L31", "7V", "6L31", "5L36", "4L30", "3L25"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "8L10", "8V", "7M", "7L26", "7V", "6M", "6L26", "5M", "5L30", "4M", "4L28", "3M", "3L23"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["8L40", "7T", "7L41", "6T", "6L41", "5T", "5L42", "4T", "4L36", "3L33"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			speedswap: ["8M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "7L33", "6L33", "5M", "5L34"],
			teleport: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["8M", "7T", "7L46", "6T", "6L46", "5T", "5L52", "4T", "4L46", "3L43"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 2, level: 15},
			{generation: 4, level: 15},
			{generation: 7, level: 11, pokeball: "pokeball"},
		],
	},
	alakazam: {
		learnset: {
			allyswitch: ["8M", "8L15", "7T", "7L36", "6L24", "5M", "5L24"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["8V"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			calmmind: ["8M", "8L50", "8V", "7M", "7L41", "6M", "6L41", "5M", "5L42", "4M", "4L36", "3M", "3L33", "3S0"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			dig: ["8V", "7V"],
			disable: ["8L1", "8V", "7L18", "7V", "6L18", "5L18", "4L18", "3L18"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["8M", "8V"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flash: ["8V", "7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L45", "7L43", "7V", "6L43", "5L48", "4L42", "3L30", "3S0"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["8M"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["8M"],
			irontail: ["8M", "8V", "7T", "6T", "5T", "4M", "3M"],
			kinesis: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			miracleeye: ["7L23", "6L22", "5L22", "4L22"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["8V"],
			powerswap: ["8M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L5", "8V", "7L21", "7V", "6L21", "5L28", "4L24", "3L21"],
			psychic: ["8M", "8L35", "8V", "7M", "7L38", "7V", "6M", "6L38", "5M", "5L46", "4M", "4L40", "3M", "3L36", "3S0"],
			psychicterrain: ["8M"],
			psychocut: ["8M", "8L20", "7L28", "6L28", "5L40", "4L34"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "8L30", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["8L25", "8V", "7L31", "7V", "6L31", "5L36", "4L30", "3L25"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "8L10", "8V", "7M", "7L26", "7V", "6M", "6L26", "5M", "5L30", "4M", "4L28", "3M", "3L23"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["8L40", "7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			speedswap: ["8M"],
			storedpower: ["8M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "7L33", "6L33", "5M", "5L34"],
			teleport: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["8M", "7T", "7L46", "6T", "6L46", "5T", "5L52", "4T", "4L46", "3L43", "3S0"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["futuresight", "calmmind", "psychic", "trick"], pokeball: "pokeball"},
		],
	},
	machop: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["8V", "7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["8M", "8L36", "8V", "7M", "7L37", "6M", "6L37", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletpunch: ["8E", "7E", "6E", "5E", "5D", "4E"],
			captivate: ["4M"],
			closecombat: ["8M", "7E", "6E", "5E", "4E"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			crosschop: ["8L48", "7L39", "7V", "6L39", "5L43", "4L37", "3L40"],
			curse: ["7V"],
			detect: ["7V"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L52", "7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualchop: ["8L32", "7T", "7L31", "6T", "6L31", "5T"],
			dynamicpunch: ["8L44", "7L45", "7V", "6L45", "5L49", "4L46", "3T", "3L49"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L4", "8V", "7L3", "7V", "6L3", "5L7", "4L7", "3L7"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7L9", "7V", "6L9", "5L19", "4L13", "3L22"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			heavyslam: ["8M", "7E", "6E", "5E"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "5D", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T"],
			incinerate: ["6M", "5M"],
			karatechop: ["8V", "7L7", "7V", "6L7", "5L10", "4L10", "3L13"],
			knockoff: ["8L16", "7T", "7L21", "7E", "6T", "6L21", "6E", "5T", "5E"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "8V", "7M", "7V", "6M", "5M", "4E", "3E"],
			lowkick: ["8M", "8L1", "8V", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "5D", "4T", "4L1", "3L1"],
			lowsweep: ["8M", "8L12", "7M", "7L13", "6M", "6L13", "5M", "5L13"],
			meditate: ["7E", "7V", "6E", "5E", "4E", "3E"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			powertrick: ["7E", "6E", "5E", "4E"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickguard: ["8E", "7E", "6E"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "8L8", "7L19", "6L19", "5L25", "4L22", "3L25"],
			reversal: ["8M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollingkick: ["7E", "7V", "6E", "5E", "4E", "3E"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L20", "7L43", "7V", "6L43", "5L46", "4L43", "3L43"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8L40", "8V", "7L15", "7V", "6L15", "5L22", "4L19", "3T", "3L19"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			smellingsalts: ["7E", "6E", "5E", "4E", "3E"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["8L29", "7V", "6M", "5M", "4M", "3M"],
			submission: ["8E", "8V", "7L33", "7V", "6L33", "5L34", "4L31", "3L37"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T"],
			tickle: ["8E", "7E", "6E", "5E"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			vacuumwave: ["4T"],
			vitalthrow: ["8L24", "7L25", "7V", "6L25", "5L31", "4L25", "3L31"],
			wakeupslap: ["7L27", "6L27", "5L37", "4L34"],
			workup: ["8M", "7M", "5M"],
		},
		encounters: [
			{generation: 1, level: 15},
		],
	},
	machoke: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["8V", "7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["8M", "8L42", "8V", "7M", "7L43", "6M", "6L43", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			crosschop: ["8L60", "7L47", "7V", "6L44", "5L44", "4L40", "3L46"],
			curse: ["7V"],
			detect: ["7V"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L66", "7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualchop: ["8L36", "7T", "7L33", "6T", "6L33", "5T"],
			dynamicpunch: ["8L54", "7L57", "7V", "6L55", "5L55", "4L51", "3T", "3L59"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["8M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7L9", "7V", "6L9", "5L19", "5S0", "4L13", "3L22"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			heavyslam: ["8M"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			karatechop: ["8V", "7L1", "7V", "6L1", "5L1", "4L10", "3L13"],
			knockoff: ["8L16", "7T", "7L21", "6T", "6L21", "5T"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M"],
			lowkick: ["8M", "8L1", "8V", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			lowsweep: ["8M", "8L12", "7M", "7L13", "6M", "6L13", "5M", "5L13", "5S0"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "8L1", "7L19", "6L19", "5L25", "5S0", "4L22", "3L25"],
			reversal: ["8M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L20", "7L53", "7V", "6L51", "5L51", "4L44", "3L51"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8L48", "8V", "7L15", "7V", "6L15", "5L22", "5S0", "4L19", "3T", "3L19"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["8L31", "7V", "6M", "5M", "4M", "3M"],
			submission: ["8V", "7L37", "7V", "6L36", "5L36", "4L32", "3L41"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			vacuumwave: ["4T"],
			vitalthrow: ["8L24", "7L25", "7V", "6L25", "5L32", "4L25", "3L33"],
			wakeupslap: ["7L27", "6L27", "5L40", "4L36"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 5, level: 30, moves: ["lowsweep", "foresight", "seismictoss", "revenge"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 2, level: 14},
			{generation: 4, level: 14},
		],
	},
	machamp: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["8V", "7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["8M", "8L42", "8V", "7M", "7L43", "7S3", "6M", "6L43", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["8V", "7V", "3T"],
			crosschop: ["8L60", "7L47", "7V", "6L44", "5L44", "4L40", "3L46"],
			crosspoison: ["8M"],
			curse: ["7V"],
			darkestlariat: ["8M"],
			detect: ["7V"],
			dig: ["8M", "8V", "7V", "6M", "6S2", "5M", "4M", "3M"],
			doubleedge: ["8L66", "7V", "7S3", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualchop: ["8L36", "7T", "7L33", "6T", "6L33", "5T"],
			dynamicpunch: ["8L54", "7L57", "7V", "6L55", "6S1", "6S2", "5L55", "4L51", "3T", "3L59"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["8M", "8V"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L1", "8V", "7L1", "7V", "6L1", "6S2", "5L1", "4L1", "3L1"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7L9", "7V", "6L9", "5L19", "4L13", "3L22", "3S0"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			heavyslam: ["8M"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			karatechop: ["8V", "7L1", "7V", "6L1", "5L1", "4L10", "3L13"],
			knockoff: ["8L16", "7T", "7L21", "6T", "6L21", "6S1", "5T"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M"],
			lowkick: ["8M", "8L1", "8V", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			lowsweep: ["8M", "8L12", "7M", "7L13", "6M", "6L13", "5M", "5L13"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickguard: ["7S3"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "8L1", "7L19", "6L19", "5L25", "4L22", "3L25", "3S0"],
			reversal: ["8M"],
			rockblast: ["8M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L20", "7L53", "7V", "6L51", "5L51", "4L44", "3L51"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8L48", "8V", "7L15", "7V", "6L15", "6S2", "5L22", "4L19", "3T", "3L19", "3S0"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "6S1", "5M", "4M"],
			strength: ["8L31", "8V", "7L1", "7V", "7S3", "6M", "5M", "4M", "3M"],
			submission: ["8V", "7L37", "7V", "6L36", "5L36", "4L32", "3L41"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			vacuumwave: ["4T"],
			vitalthrow: ["8L24", "7L25", "7V", "6L25", "5L32", "4L25", "3L33", "3S0"],
			wakeupslap: ["7L27", "6L27", "5L40", "4L36"],
			wideguard: ["8L1", "7L1", "6L1", "6S1", "5L1"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 38, gender: "M", nature: "Quiet", ivs: {hp: 9, atk: 23, def: 25, spa: 20, spd: 15, spe: 10}, abilities: ["guts"], moves: ["seismictoss", "foresight", "revenge", "vitalthrow"], pokeball: "pokeball"},
			{generation: 6, level: 50, shiny: true, gender: "M", nature: "Adamant", ivs: {hp: 31, atk: 31, def: 31, spa: 31, spd: 31, spe: 31}, abilities: ["noguard"], moves: ["dynamicpunch", "stoneedge", "wideguard", "knockoff"], pokeball: "cherishball"},
			{generation: 6, level: 39, gender: "M", nature: "Hardy", abilities: ["noguard"], moves: ["seismictoss", "dynamicpunch", "dig", "focusenergy"], pokeball: "cherishball"},
			{generation: 7, level: 34, gender: "F", nature: "Brave", ivs: {atk: 31}, abilities: ["guts"], moves: ["strength", "bulkup", "quickguard", "doubleedge"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 16},
			{generation: 2, level: 5},
		],
	},
	bellsprout: {
		learnset: {
			acid: ["8V", "7L23", "7V", "6L23", "5L23", "4L23", "3L23"],
			acidspray: ["7E", "6E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			belch: ["7E", "6E"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			bulletseed: ["7E", "6E", "5E", "4M", "3M"],
			captivate: ["4M"],
			clearsmog: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["7E", "7V", "6E", "5E", "4E", "3E"],
			endure: ["7V", "4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "7L35", "6T", "6L35", "5T", "5L35", "4T", "4L35"],
			gigadrain: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growth: ["8V", "7L7", "7V", "6L7", "5L7", "4L7", "3L6", "3S1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			ingrain: ["7E", "6E", "5E", "4E", "3E"],
			knockoff: ["7T", "7L27", "6T", "6L27", "5T", "5L27", "4T", "4L27"],
			leechlife: ["7E", "7V", "6E", "5E", "4E", "3E"],
			magicalleaf: ["7E", "6E", "5E", "4E", "3E"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			naturepower: ["7M", "6M"],
			poisonjab: ["8V", "7M", "7L41"],
			poisonpowder: ["8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L17"],
			powerwhip: ["7E", "6E", "5E"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			razorleaf: ["8V", "7L39", "7V", "6L39", "5L39", "4L39", "3L37"],
			reflect: ["8V", "7M", "7V", "6M", "5M", "4E", "3E"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "5D", "4T"],
			slam: ["8V", "7L47", "7V", "6L41", "5L41", "4L41", "3L45"],
			sleeppowder: ["8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L15"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			strengthsap: ["7E"],
			stunspore: ["8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L19"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7L29", "7V", "6L29", "5L29", "4L29", "3L30"],
			swordsdance: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			synthesis: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			takedown: ["7V"],
			teeterdance: ["3S0"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			tickle: ["7E", "6E", "5E", "4E"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["7M", "6M", "5M"],
			vinewhip: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S0", "3S1"],
			weatherball: ["7E", "6E", "5E", "4E"],
			worryseed: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			wrap: ["8V", "7L11", "7V", "6L11", "5L11", "4L11", "3L11"],
			wringout: ["7L50", "6L47", "5L47", "4L47"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["vinewhip", "teeterdance"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["vinewhip", "growth"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 12},
			{generation: 2, level: 3},
		],
	},
	weepinbell: {
		learnset: {
			acid: ["8V", "7L24", "7V", "6L23", "5L23", "4L23", "3L24"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			bugbite: ["5T"],
			bulletseed: ["4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "7L39", "6T", "6L35", "5T", "5L35", "4T", "4L35"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growth: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "7L29", "6T", "6L27", "5T", "5L27", "4T", "4L27"],
			magicalleaf: ["3S0"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			morningsun: ["3S0"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			poisonjab: ["8V", "7M", "7L47"],
			poisonpowder: ["8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L17"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			razorleaf: ["8V", "7L44", "7V", "6L39", "5L39", "4L39", "3L42"],
			reflect: ["8V", "7M", "7V", "6M", "5M"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			slam: ["8V", "7L54", "7V", "6L41", "5L41", "4L41", "3L54"],
			sleeppowder: ["8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L15"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			stunspore: ["8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L19"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7L32", "7V", "6L29", "5L29", "4L29", "3L33", "3S0"],
			swordsdance: ["7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["7M", "6M", "5M"],
			vinewhip: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			worryseed: ["7T", "6T", "5T", "4T"],
			wrap: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			wringout: ["7L58", "6L47", "5L47", "4L47"],
		},
		eventData: [
			{generation: 3, level: 32, moves: ["morningsun", "magicalleaf", "sludgebomb", "sweetscent"]},
		],
		encounters: [
			{generation: 2, level: 12},
			{generation: 4, level: 10},
		],
	},
	victreebel: {
		learnset: {
			acid: ["8V", "7V"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			bodyslam: ["7V", "3T"],
			bugbite: ["5T"],
			bulletseed: ["4M", "3M"],
			captivate: ["4M"],
			clearsmog: ["8V"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["7T", "6T", "5T", "4T"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growth: ["8V"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leafblade: ["7L44", "6L47", "5L47", "4L47"],
			leafstorm: ["7L32", "6L47", "5L47", "4L47"],
			leaftornado: ["7L1", "6L27", "5L27"],
			leechlife: ["8V"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			poisonjab: ["8V", "7M"],
			poisonpowder: ["7V"],
			powerwhip: ["8V"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			razorleaf: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			reflect: ["8V", "7M", "7V", "6M", "5M"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			sleeppowder: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			spitup: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			stockpile: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			stunspore: ["7V"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8V", "4T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			sweetscent: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swordsdance: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["7M", "6M", "5M"],
			vinewhip: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			worryseed: ["7T", "6T", "5T", "4T"],
			wrap: ["8V", "7V"],
		},
	},
	tentacool: {
		learnset: {
			acid: ["8L4", "8V", "7L10", "7V", "6L10", "5L12", "4L12", "3L19"],
			acidarmor: ["8L32"],
			acidspray: ["7L22", "6L22", "5L26"],
			acupressure: ["8E", "7E", "6E", "5E", "5D", "4E"],
			aquaring: ["8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			barrier: ["8V", "7L28", "7V", "6L28", "5L29", "4L26", "3L36"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			brine: ["8M", "7L34", "6L34", "4M"],
			brutalswing: ["8M"],
			bubble: ["7E", "6E", "5E"],
			bubblebeam: ["8L24", "8V", "7L25", "7V", "6L19", "5L19", "4L19", "3L25"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E", "4E", "3E"],
			constrict: ["8V", "7L7", "7V", "6L7", "5L8", "4L8", "3L12"],
			crosspoison: ["8M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "5D", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V"],
			hex: ["8M", "8L28", "7L40", "6L40", "5L43"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L48", "8V", "7L46", "7V", "6L46", "5L47", "4L40", "3L49"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			mirrorcoat: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			muddywater: ["8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["8M", "8L36", "8V", "7M", "7L31", "6M", "6L31", "5M", "5L36", "4M", "4L33"],
			poisonsting: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rapidspin: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			screech: ["8M", "8L20", "8V", "7L37", "7V", "6L37", "5L40", "4L36", "3L43"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "8L44", "7M", "7L43", "6M", "6L43", "5M", "5L50"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			supersonic: ["8L12", "8V", "7L4", "7V", "6L4", "5L5", "5D", "4L5", "3L6"],
			surf: ["8M", "8L40", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			tickle: ["8E", "7E", "6E", "5E"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "7L13", "6L13", "5L15", "4L15"],
			venoshock: ["8M", "7M", "6M", "5M"],
			waterfall: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7V"],
			waterpulse: ["8L16", "7T", "7L16", "6T", "6L16", "5L33", "4M", "4L29", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			wrap: ["8L8", "8V", "7L19", "7V", "6L19", "5L22", "4L22", "3L30"],
			wringout: ["7L49", "6L49", "5L54", "4L43"],
		},
		encounters: [
			{generation: 1, level: 5},
		],
	},
	tentacruel: {
		learnset: {
			acid: ["8L1", "8V", "7L1", "7V", "6L1", "5L12", "4L12", "3L19"],
			acidarmor: ["8L34"],
			acidspray: ["7L22", "6L22", "5L26"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["8V", "7L28", "7V", "6L28", "5L29", "4L26", "3L38"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			brine: ["8M", "7L36", "6L36", "4M"],
			brutalswing: ["8M"],
			bubblebeam: ["8L24", "8V", "7L25", "7V", "6L19", "5L19", "4L19", "3L25"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			constrict: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			corrosivegas: ["8T"],
			crosspoison: ["8M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8V"],
			headbutt: ["8V"],
			hex: ["8M", "8L28", "7L44", "6L44", "5L47"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L58", "8V", "7L52", "7V", "6L52", "5L52", "4L49", "3L55"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			mirrorcoat: ["8V"],
			muddywater: ["8M"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["8M", "8L40", "8V", "7M", "7L32", "6M", "6L32", "5M", "5L38", "4M", "4L36"],
			poisonsting: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			reflecttype: ["8L1", "7L1", "6L1"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			screech: ["8M", "8L20", "8V", "7L40", "7V", "6L40", "5L43", "4L42", "3L47"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "8L52", "7M", "7L48", "6M", "6L48", "5M", "5L56"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			supersonic: ["8L12", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			surf: ["8M", "8L46", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "7L13", "6L13", "5L15", "4L15"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			waterfall: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7V"],
			waterpulse: ["8L16", "7T", "7L16", "6T", "6L16", "5L34", "4M", "4L29", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			wrap: ["8L1", "8V", "7L19", "7V", "6L19", "5L22", "4L22", "3L30"],
			wringout: ["7L1", "6L1", "5L61", "4L55"],
		},
		encounters: [
			{generation: 1, level: 20},
			{generation: 2, level: 20},
			{generation: 3, level: 20},
			{generation: 4, level: 15},
			{generation: 6, level: 21, maxEggMoves: 1},
		],
	},
	geodude: {
		learnset: {
			ancientpower: ["4T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			autotomize: ["7E", "6E", "5E"],
			bide: ["8V", "7V"],
			block: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			bodyslam: ["7V", "3T"],
			brickbreak: ["8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["7M", "7L22", "6M", "6L22", "5M", "5L32"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["7E", "7V", "6E", "5E", "4E"],
			defensecurl: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3T", "3L1"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8V", "7L40", "7V", "6L40", "5L46", "4L36", "3T", "3L46"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["8V", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L39", "4M", "4L29", "3M", "3L36"],
			endure: ["7E", "7V", "6E", "5E", "4M", "3T"],
			explosion: ["8V", "7M", "7L36", "7V", "6M", "6L36", "5M", "5L43", "4M", "4L32", "3T", "3L41"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flail: ["7E", "6E", "5E", "4E"],
			flamethrower: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gyroball: ["7M", "6M", "5M", "4M"],
			hammerarm: ["7E", "6E", "5E", "4E"],
			harden: ["7V"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			irondefense: ["7T", "6T", "5T"],
			magnitude: ["7L12", "7V", "6L12", "5L15", "4L15", "3L16"],
			megapunch: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			metronome: ["7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7L4", "6L4", "5L4", "4L4", "3L6"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["7L30", "6L22", "5L22", "4L25", "3L31"],
			rockclimb: ["7E", "6E", "5E", "5D", "4M"],
			rockpolish: ["7M", "7L6", "6M", "6L6", "5M", "5L8", "4M", "4L8"],
			rockslide: ["8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V", "7L16", "7V", "6L16", "5L11", "4L11", "3L11"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["7L10", "7V", "6L10", "5L18", "4T", "4L22", "3T", "3L26"],
			round: ["7M", "6M", "5M"],
			sandattack: ["8V"],
			sandstorm: ["7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8V", "7L24", "7V", "6L24", "5L29", "4L18", "3T", "3L21"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "7L18", "6M", "6L18", "5M", "5L25"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8V", "7T", "7L28", "6T", "6L28", "5T", "5L36", "5D", "4M"],
			stoneedge: ["7M", "7L42", "6M", "6L42", "5M", "5L50", "4M", "4L39"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8V", "7V"],
			thunderpunch: ["8V", "7T", "6T", "5T", "4T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			wideguard: ["7E", "6E"],
		},
		encounters: [
			{generation: 1, level: 7},
			{generation: 2, level: 2},
		],
	},
	geodudealola: {
		learnset: {
			attract: ["7M"],
			autotomize: ["7E"],
			bide: ["8V"],
			block: ["7T", "7E"],
			brickbreak: ["8V", "7M"],
			brutalswing: ["7M"],
			bulldoze: ["7M"],
			charge: ["7L4"],
			chargebeam: ["7M"],
			confide: ["7M"],
			counter: ["7E"],
			curse: ["7E"],
			defensecurl: ["8V", "7L1"],
			dig: ["8V"],
			discharge: ["7L34"],
			doubleedge: ["8V", "7L40"],
			doubleteam: ["7M"],
			earthpower: ["7T"],
			earthquake: ["8V", "7M"],
			electroweb: ["7T"],
			endure: ["7E"],
			explosion: ["8V", "7M", "7L36"],
			facade: ["8V", "7M"],
			fireblast: ["8V", "7M"],
			firepunch: ["8V", "7T"],
			flail: ["7E"],
			flamethrower: ["8V", "7M"],
			fling: ["7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gyroball: ["7M"],
			headbutt: ["8V"],
			hiddenpower: ["7M"],
			irondefense: ["7T"],
			magnetrise: ["7T", "7E"],
			naturepower: ["7M"],
			protect: ["8V", "7M"],
			rest: ["8V", "7M"],
			return: ["7M"],
			rockblast: ["7L30"],
			rockclimb: ["7E"],
			rockpolish: ["7M", "7L6"],
			rockslide: ["8V", "7M"],
			rockthrow: ["8V", "7L16"],
			rocktomb: ["7M"],
			rollout: ["7L10"],
			round: ["7M"],
			sandstorm: ["7M"],
			screech: ["7E"],
			seismictoss: ["8V"],
			selfdestruct: ["8V", "7L24"],
			sleeptalk: ["7M"],
			smackdown: ["7M", "7L18"],
			snore: ["7T"],
			spark: ["7L12"],
			stealthrock: ["8V", "7T", "7L28"],
			stoneedge: ["7M", "7L42"],
			substitute: ["8V", "7M"],
			sunnyday: ["7M"],
			superpower: ["8V", "7T"],
			swagger: ["7M"],
			tackle: ["8V", "7L1"],
			takedown: ["8V"],
			thunder: ["8V", "7M"],
			thunderbolt: ["8V", "7M"],
			thunderpunch: ["8V", "7T", "7L22"],
			thundershock: ["8V"],
			thunderwave: ["8V"],
			toxic: ["8V", "7M"],
			voltswitch: ["7M"],
			wideguard: ["7E"],
		},
	},
	graveler: {
		learnset: {
			ancientpower: ["4T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["8V", "7V"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["7V", "3T"],
			brickbreak: ["8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["7M", "7L22", "6M", "6L22", "5M", "5L36"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			defensecurl: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8V", "7L50", "7V", "6L50", "5L58", "4L44", "3T", "3L62"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["8V", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L47", "4M", "4L33", "3M", "3L45"],
			endure: ["7V", "4M", "3T"],
			explosion: ["8V", "7M", "7L44", "7V", "6M", "6L44", "5M", "5L53", "4M", "4L38", "3T", "3L53"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gyroball: ["7M", "6M", "5M", "4M"],
			harden: ["7V"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			irondefense: ["7T", "6T", "5T"],
			magnitude: ["7L12", "7V", "6L12", "5L15", "4L15", "3L16"],
			megapunch: ["7V", "3T"],
			metronome: ["7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["7L34", "6L22", "5L22", "4L27", "3L37"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			rockslide: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V", "7L16", "7V", "6L16", "5L11", "4L11", "3L1"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["7L10", "7V", "6L10", "5L18", "4T", "4L22", "3T", "3L29"],
			round: ["7M", "6M", "5M"],
			sandattack: ["8V"],
			sandstorm: ["7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8V", "7L24", "7V", "6L24", "5L31", "4L18", "3T", "3L21"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "7L18", "6M", "6L18", "5M", "5L27"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8V", "7T", "7L30", "6T", "6L30", "5T", "5L42", "4M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "7L54", "6M", "6L54", "5M", "5L64", "4M", "4L49"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8V", "7V"],
			thunderpunch: ["8V", "7T", "6T", "5T", "4T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
		},
		encounters: [
			{generation: 2, level: 23},
			{generation: 4, level: 16, pokeball: "safariball"},
			{generation: 6, level: 24},
		],
	},
	graveleralola: {
		learnset: {
			allyswitch: ["7T"],
			attract: ["7M"],
			bide: ["8V"],
			block: ["7T"],
			brickbreak: ["8V", "7M"],
			brutalswing: ["7M"],
			bulldoze: ["7M"],
			charge: ["7L1"],
			chargebeam: ["7M"],
			confide: ["7M"],
			defensecurl: ["8V", "7L1"],
			dig: ["8V"],
			discharge: ["7L40"],
			doubleedge: ["8V", "7L50"],
			doubleteam: ["7M"],
			earthpower: ["7T"],
			earthquake: ["8V", "7M"],
			electroweb: ["7T"],
			explosion: ["8V", "7M", "7L44"],
			facade: ["8V", "7M"],
			fireblast: ["8V", "7M"],
			firepunch: ["8V", "7T"],
			flamethrower: ["8V", "7M"],
			fling: ["7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gyroball: ["7M"],
			headbutt: ["8V"],
			hiddenpower: ["7M"],
			irondefense: ["7T"],
			magnetrise: ["7T"],
			naturepower: ["7M"],
			protect: ["8V", "7M"],
			rest: ["8V", "7M"],
			return: ["7M"],
			rockblast: ["7L34"],
			rockpolish: ["7M", "7L1"],
			rockslide: ["8V", "7M"],
			rockthrow: ["8V", "7L16"],
			rocktomb: ["7M"],
			rollout: ["7L10"],
			round: ["7M"],
			sandstorm: ["7M"],
			seismictoss: ["8V"],
			selfdestruct: ["8V", "7L24"],
			shockwave: ["7T"],
			sleeptalk: ["7M"],
			smackdown: ["7M", "7L18"],
			snore: ["7T"],
			spark: ["7L12"],
			stealthrock: ["8V", "7T", "7L30"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "7L54"],
			substitute: ["8V", "7M"],
			sunnyday: ["7M"],
			superpower: ["8V", "7T"],
			swagger: ["7M"],
			tackle: ["8V", "7L1"],
			takedown: ["8V"],
			thunder: ["8V", "7M"],
			thunderbolt: ["8V", "7M"],
			thunderpunch: ["8V", "7T", "7L22"],
			thundershock: ["8V"],
			thunderwave: ["8V"],
			toxic: ["8V", "7M"],
			voltswitch: ["7M"],
		},
	},
	golem: {
		learnset: {
			ancientpower: ["4T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["8V", "7V"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["7V", "3T"],
			brickbreak: ["8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["7M", "7L22", "6M", "6L22", "5M", "5L36"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			defensecurl: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			dig: ["8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8V", "7L50", "7V", "6L50", "5L58", "4L44", "3T", "3L62"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["8V", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L47", "4M", "4L33", "3M", "3L45"],
			endure: ["7V", "4M", "3T"],
			explosion: ["8V", "7M", "7L44", "7V", "6M", "6L44", "5M", "5L53", "4M", "4L38", "3T", "3L53"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			gyroball: ["7M", "6M", "5M", "4M"],
			harden: ["7V"],
			headbutt: ["8V", "7V", "4T"],
			heavyslam: ["7L1", "6L1", "5L69"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			irondefense: ["7T", "6T", "5T"],
			ironhead: ["7T", "6T", "5T", "4T"],
			magnitude: ["7L12", "7V", "6L12", "5L15", "4L15", "3L16"],
			megakick: ["7V", "3T"],
			megapunch: ["8V", "7V", "3T"],
			metronome: ["7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["7L34", "6L22", "5L22", "4L27", "3L37"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			rockslide: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V", "7L16", "7V", "6L16", "5L11", "4L11", "3L1"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "4L22", "3T", "3L29"],
			round: ["7M", "6M", "5M"],
			sandattack: ["8V"],
			sandstorm: ["7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8V", "7L24", "7V", "6L24", "5L31", "4L18", "3T", "3L21"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "7L18", "6M", "6L18", "5M", "5L27"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8V", "7T", "7L30", "6T", "6L30", "5T", "5L42", "4M"],
			steamroller: ["7L10", "6L10", "5L18"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "7L54", "6M", "6L54", "5M", "5L64", "4M", "4L49"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8V", "7V"],
			thunderpunch: ["8V", "7T", "6T", "5T", "4T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
		},
	},
	golemalola: {
		learnset: {
			allyswitch: ["7T"],
			attract: ["7M"],
			bide: ["8V"],
			block: ["7T"],
			brickbreak: ["8V", "7M"],
			brutalswing: ["7M"],
			bulldoze: ["7M"],
			charge: ["7L1"],
			chargebeam: ["7M"],
			confide: ["7M"],
			defensecurl: ["8V", "7L1"],
			dig: ["8V"],
			discharge: ["7L40"],
			doubleedge: ["8V", "7L50"],
			doubleteam: ["7M"],
			earthpower: ["7T"],
			earthquake: ["8V", "7M"],
			echoedvoice: ["7M"],
			electroweb: ["7T"],
			explosion: ["8V", "7M", "7L44"],
			facade: ["8V", "7M"],
			fireblast: ["8V", "7M"],
			firepunch: ["8V", "7T"],
			flamethrower: ["8V", "7M"],
			fling: ["7M"],
			focusblast: ["7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gigaimpact: ["7M"],
			gyroball: ["7M"],
			headbutt: ["8V"],
			heavyslam: ["7L1"],
			hiddenpower: ["7M"],
			hyperbeam: ["8V", "7M"],
			irondefense: ["7T"],
			ironhead: ["7T"],
			magnetrise: ["7T"],
			megapunch: ["8V"],
			naturepower: ["7M"],
			protect: ["8V", "7M"],
			rest: ["8V", "7M"],
			return: ["7M"],
			roar: ["7M"],
			rockblast: ["7L34"],
			rockpolish: ["7M", "7L1"],
			rockslide: ["8V", "7M"],
			rockthrow: ["8V", "7L16"],
			rocktomb: ["7M"],
			round: ["7M"],
			sandstorm: ["7M"],
			seismictoss: ["8V"],
			selfdestruct: ["8V", "7L24"],
			shockwave: ["7T"],
			sleeptalk: ["7M"],
			smackdown: ["7M", "7L18"],
			snore: ["7T"],
			spark: ["7L12"],
			stealthrock: ["8V", "7T", "7L30"],
			steamroller: ["7L10"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "7L54"],
			substitute: ["8V", "7M"],
			sunnyday: ["7M"],
			superpower: ["8V", "7T"],
			swagger: ["7M"],
			tackle: ["8V", "7L1"],
			takedown: ["8V"],
			thunder: ["8V", "7M"],
			thunderbolt: ["8V", "7M"],
			thunderpunch: ["8V", "7T", "7L22"],
			thundershock: ["8V"],
			thunderwave: ["8V"],
			toxic: ["8V", "7M"],
			voltswitch: ["7M"],
			wildcharge: ["7M"],
		},
	},
	ponyta: {
		learnset: {
			agility: ["8M", "8L20", "8V", "7L37", "7V", "6L37", "5L37", "4L33", "3L38"],
			allyswitch: ["8M", "7T", "7E", "6E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			bounce: ["8M", "7T", "7L45", "6T", "6L45", "5T", "5L45", "4T", "4L42", "3L45"],
			captivate: ["7E", "6E", "5E", "4M"],
			charm: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			doubleedge: ["8E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			doublekick: ["8E", "8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["8L10", "8V", "7L9", "7V", "6L9", "5L9", "4L10", "3L14"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8L50", "8V", "7M", "7L41", "7V", "6M", "6L41", "5M", "5L41", "4M", "4L37", "3M", "3L53"],
			firespin: ["8M", "8L35", "8V", "7L25", "7V", "6L25", "5L25", "4L24", "3L25"],
			flamecharge: ["8L15", "7M", "7L21", "6M", "6L21", "5M", "5L21"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flamewheel: ["8L25", "7L13", "7E", "7V", "6L13", "6E", "5L13", "5E", "4L15", "4E", "3E"],
			flareblitz: ["8M", "8L55", "8V", "7L49", "6L49", "5L49", "4L46"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L5"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "5D", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M", "7E"],
			horndrill: ["8E", "7E", "7V", "6E", "5E", "4E"],
			hypnosis: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			incinerate: ["6M", "5M"],
			inferno: ["8L45", "7L33", "6L33", "5L33"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lowkick: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			mimic: ["7V", "3T"],
			morningsun: ["8E", "7E", "6E", "5E", "4E"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			playrough: ["8M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["8V", "7V", "3L1"],
			rage: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			solarblade: ["8M"],
			stomp: ["8L30", "8V", "7L17", "7V", "6L17", "5L17", "4L19", "3L19"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			tailwhip: ["8L5", "8V", "7L4", "7V", "6L4", "5L4", "4L6", "3L9"],
			takedown: ["8L41", "8V", "7L29", "7V", "6L29", "5L29", "4L28", "3L31"],
			thrash: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8V", "7M", "6M", "5M", "4M"],
		},
		encounters: [
			{generation: 1, level: 28},
		],
	},
	ponytagalar: {
		learnset: {
			agility: ["8M", "8L20"],
			allyswitch: ["8M"],
			attract: ["8M"],
			bodyslam: ["8M"],
			bounce: ["8M"],
			calmmind: ["8M"],
			charm: ["8M"],
			confusion: ["8L10", "8S0"],
			dazzlinggleam: ["8M", "8L45"],
			doubleedge: ["8E"],
			doublekick: ["8E"],
			endure: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M"],
			fairywind: ["8L15", "8S0"],
			futuresight: ["8M"],
			growl: ["8L1", "8S0"],
			healingwish: ["8L55"],
			healpulse: ["8L35"],
			highhorsepower: ["8M"],
			horndrill: ["8E"],
			hypnosis: ["8E"],
			imprison: ["8M"],
			irontail: ["8M"],
			lowkick: ["8M"],
			morningsun: ["8E"],
			mysticalfire: ["8M"],
			playrough: ["8M"],
			protect: ["8M"],
			psybeam: ["8L25"],
			psychic: ["8M", "8L50"],
			rest: ["8M"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stomp: ["8L30"],
			storedpower: ["8M"],
			substitute: ["8M"],
			swift: ["8M"],
			tackle: ["8L1", "8S0"],
			tailwhip: ["8L5"],
			takedown: ["8L41"],
			thrash: ["8E"],
			wildcharge: ["8M"],
			zenheadbutt: ["8M"],
		},
		eventData: [
			{generation: 8, level: 15, isHidden: true, moves: ["tackle", "growl", "confusion", "fairywind"], pokeball: "cherishball"},
		],
	},
	rapidash: {
		learnset: {
			agility: ["8M", "8L20", "8V", "7L37", "7V", "6L37", "5L37", "4L33", "3L38"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "3S0"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			bounce: ["8M", "7T", "7L45", "6T", "6L45", "5T", "5L45", "4T", "4L47", "3L50"],
			captivate: ["4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8V"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8M", "8V", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8L56", "8V", "7M", "7L41", "7V", "6M", "6L41", "5M", "5L41", "4M", "4L37", "3M", "3L63"],
			firespin: ["8M", "8L35", "8V", "7L25", "7V", "6L25", "5L25", "4L24", "3L25"],
			flamecharge: ["8L15", "7M", "7L21", "6M", "6L21", "5M", "5L21"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M", "3S0"],
			flamewheel: ["8L25", "7L13", "6L13", "5L13", "4L15"],
			flareblitz: ["8M", "8L63", "8V", "7L49", "6L49", "5L49", "4L56"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L1", "7V", "6L40", "5L40", "4L40", "3L40"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			horndrill: ["8V", "7V"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["8V"],
			incinerate: ["6M", "5M"],
			inferno: ["8L49", "7L33", "6L33", "5L33"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lowkick: ["8M", "7T", "6T", "5T"],
			megahorn: ["8M", "8L1", "8V", "7L1", "6L1", "5L1", "4L1"],
			mimic: ["7V", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			payday: ["8M"],
			playrough: ["8M"],
			poisonjab: ["8M", "8L1", "8V", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["8L1", "8V", "7L1", "6L1", "5L1", "4L1", "3L1"],
			rage: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smartstrike: ["8M", "8L0", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "6M", "5M", "4M", "3M", "3S0"],
			solarblade: ["8M"],
			stomp: ["8L30", "8V", "7L17", "7V", "6L17", "5L17", "4L19", "3L19"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			swordsdance: ["8M"],
			tackle: ["8L1", "8V", "7V", "3L1"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L43", "8V", "7L29", "7V", "6L29", "5L29", "4L28", "3L31"],
			throatchop: ["8M", "7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8V", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 40, moves: ["batonpass", "solarbeam", "sunnyday", "flamethrower"]},
		],
		encounters: [
			{generation: 2, level: 14, gender: "M"},
			{generation: 3, level: 37},
		],
	},
	rapidashgalar: {
		learnset: {
			agility: ["8M", "8L20"],
			allyswitch: ["8M"],
			attract: ["8M"],
			batonpass: ["8M"],
			bodyslam: ["8M"],
			bounce: ["8M"],
			calmmind: ["8M"],
			charm: ["8M"],
			confusion: ["8L1"],
			dazzlinggleam: ["8M", "8L49"],
			drillrun: ["8M"],
			endure: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M"],
			fairywind: ["8L15"],
			futuresight: ["8M"],
			gigaimpact: ["8M"],
			growl: ["8L1"],
			healingwish: ["8L63"],
			healpulse: ["8L35"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M"],
			imprison: ["8M"],
			irontail: ["8M"],
			lowkick: ["8M"],
			magicroom: ["8M"],
			megahorn: ["8M", "8L1"],
			mistyterrain: ["8M"],
			mysticalfire: ["8M"],
			payday: ["8M"],
			playrough: ["8M"],
			protect: ["8M"],
			psybeam: ["8L25"],
			psychic: ["8M", "8L56"],
			psychicterrain: ["8M"],
			psychocut: ["8M", "8L0"],
			quickattack: ["8L1"],
			rest: ["8M"],
			round: ["8M"],
			sleeptalk: ["8M"],
			smartstrike: ["8M"],
			snore: ["8M"],
			stomp: ["8L30"],
			storedpower: ["8M"],
			substitute: ["8M"],
			swift: ["8M"],
			swordsdance: ["8M"],
			tackle: ["8L1"],
			tailwhip: ["8L1"],
			takedown: ["8L43"],
			throatchop: ["8M"],
			trickroom: ["8M"],
			wildcharge: ["8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["8M"],
		},
	},
	slowpoke: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["9M", "9L27", "8M", "8L27", "8V", "7L41", "7V", "6L41", "5L41", "4L43", "3L36"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M"],
			belch: ["9E", "8E", "7E", "6E"],
			bellydrum: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brine: ["8M", "5D", "4M"],
			bubblebeam: ["7V"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confusion: ["9L12", "8L12", "8V", "7L14", "7V", "6L14", "5L14", "5S2", "4L15", "3L17", "3S0"],
			curse: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			disable: ["9L15", "8L15", "8V", "7L19", "7V", "6L19", "5L19", "5S2", "4L20", "3L24", "3S0"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			earthquake: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foulplay: ["9M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L3", "8L3", "8V", "7L5", "7V", "6L5", "5L5", "4L6", "3L6", "3S1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["9L21", "8L21", "8V", "7L23", "7V", "6L23", "5L23", "5S2", "4T", "4L25", "3L29", "3S0"],
			healpulse: ["9L45", "8L45", "7L58", "6L58", "5L58"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "8M"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "8M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			liquidation: ["9M", "8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mefirst: ["7E", "6E", "5E", "4E"],
			mimic: ["7V", "3T"],
			mudshot: ["9M", "8M"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payday: ["8M", "8V", "7V"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L36", "8M", "8L36", "8V", "7M", "7L45", "7V", "6M", "6L45", "5M", "5L45", "4M", "4L48", "3M", "3L40"],
			psychicterrain: ["9M", "8M"],
			psychup: ["9L39", "8L39", "7M", "7L54", "7V", "6M", "6L54", "5M", "5L54", "4M", "4L57", "3T", "3L47"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "9L42", "8M", "8L42", "7M", "7L49", "7V", "6M", "6L49", "5M", "5L49", "4M", "4L53", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4M", "4E", "3M", "3E"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			skullbash: ["7V"],
			slackoff: ["9L33", "8L33", "7L36", "6L36", "5L36", "4L39"],
			sleeptalk: ["9M", "8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "4E", "3T", "3E"],
			snore: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			snowscape: ["9M"],
			stomp: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			storedpower: ["9M", "8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["9M", "9L30", "8M", "8L30", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			takedown: ["9M", "7V"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			waterfall: ["9M"],
			watergun: ["9L6", "8L6", "8V", "7L9", "7V", "6L9", "5L9", "4L11", "3L13", "3S0"],
			waterpulse: ["9M", "9L18", "8L18", "7T", "7L28", "6T", "6L28", "5L28", "5S2", "4M", "4L29", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "4M"],
			wonderroom: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			yawn: ["9L9", "8L9", "8V", "7L1", "6L1", "5L1", "5D", "4L1", "3L1", "3S1"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "9L24", "8M", "8L24", "7T", "7L32", "7E", "6T", "6L32", "6E", "5T", "5L32", "5E", "4T", "4L34", "4E"],
		},
		eventData: [
			{generation: 3, level: 31, gender: "F", nature: "Naive", ivs: {hp: 17, atk: 11, def: 19, spa: 20, spd: 5, spe: 10}, abilities: ["oblivious"], moves: ["watergun", "confusion", "disable", "headbutt"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["curse", "yawn", "tackle", "growl"], pokeball: "pokeball"},
			{generation: 5, level: 30, moves: ["confusion", "disable", "headbutt", "waterpulse"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 15},
		],
	},
	slowpokegalar: {
		learnset: {
			acid: ["9L6", "8L6"],
			amnesia: ["9M", "9L27", "8M", "8L27"],
			attract: ["8M"],
			avalanche: ["9M"],
			belch: ["9E", "8E"],
			bellydrum: ["9E", "8E"],
			blizzard: ["9M", "8M"],
			block: ["9E", "8E"],
			bodyslam: ["9M", "8M"],
			brine: ["8M"],
			bulldoze: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			chillingwater: ["9M"],
			confusion: ["9L12", "8L12"],
			curse: ["9L1", "8L1"],
			dig: ["9M", "8M"],
			disable: ["9L15", "8L15"],
			dive: ["8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			flamethrower: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			futuresight: ["8M"],
			grassknot: ["9M", "8M"],
			growl: ["9L3", "8L3"],
			hail: ["8M"],
			headbutt: ["9L21", "8L21"],
			healpulse: ["9L45", "8L45"],
			helpinghand: ["9M"],
			hydropump: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			icywind: ["9M", "8M"],
			imprison: ["9M", "8M"],
			irontail: ["8M"],
			lightscreen: ["9M", "8M"],
			liquidation: ["9M", "8M"],
			mudshot: ["9M", "8M"],
			payday: ["8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L36", "8M", "8L36"],
			psychicterrain: ["9M", "8M"],
			psychup: ["9L39", "8L39"],
			psyshock: ["9M", "8M"],
			raindance: ["9M", "9L42", "8M", "8L42"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			scald: ["8M"],
			shadowball: ["9M", "8M"],
			skillswap: ["9M", "8M"],
			slackoff: ["9L33", "8L33"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			snowscape: ["9M"],
			stomp: ["9E", "8E"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			surf: ["9M", "9L30", "8M", "8L30"],
			swift: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M"],
			triattack: ["8M"],
			trick: ["9M", "8M"],
			trickroom: ["9M", "8M"],
			waterfall: ["9M"],
			waterpulse: ["9M", "9L18", "8L18"],
			weatherball: ["8M"],
			whirlpool: ["8M"],
			wonderroom: ["8M"],
			yawn: ["9L9", "8L9"],
			zenheadbutt: ["9M", "9L24", "8M", "8L24"],
		},
	},
	slowbro: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["9M", "9L27", "8M", "8L27", "8V", "7L43", "7V", "6L43", "5L43", "4L47", "3L36"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "8M", "4M"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "4M"],
			bubblebeam: ["7V"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confusion: ["9L12", "8L12", "8V", "7L14", "7V", "6L14", "5L14", "4L15", "3L17"],
			counter: ["7V", "3T"],
			curse: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			disable: ["9L15", "8L15", "8V", "7L19", "7V", "6L19", "5L19", "4L20", "3L24"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			futuresight: ["9L1", "8M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["9L21", "8L21", "8V", "7L23", "7V", "6L23", "5L23", "4T", "4L25", "3L29"],
			healpulse: ["9L51", "8L51", "7L1", "6L1", "5L68"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "8M"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "6S0", "5T", "4M", "3M"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			liquidation: ["9M", "8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["9M"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "8M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payday: ["8M", "8V", "7V"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L36", "8M", "8L36", "8V", "7M", "7L49", "7V", "6M", "6L49", "5M", "5L49", "4M", "4L54", "3M", "3L44"],
			psychicterrain: ["9M", "8M"],
			psychup: ["9L41", "8L41", "7M", "7L62", "7V", "6M", "6L62", "5M", "5L62", "4M", "4L67", "3T", "3L55"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "9L46", "8M", "8L46", "7M", "7L55", "7V", "6M", "6L55", "5M", "5L55", "4M", "4L61", "3M"],
			razorshell: ["8M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scald: ["8M", "8V", "7M", "6M", "6S0", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			skullbash: ["7V"],
			slackoff: ["9L33", "8L33", "7L36", "6L36", "6S0", "5L36", "4L41"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			stomp: ["8V"],
			storedpower: ["9M", "8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["9M", "9L30", "8M", "8L30", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "7V"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "6S0", "5M", "4M"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1", "8V", "7L9", "7V", "6L9", "5L9", "4L11", "3L13"],
			waterpulse: ["9M", "9L18", "8L18", "7T", "7L28", "6T", "6L28", "5L28", "4M", "4L29", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "4M"],
			withdraw: ["9L1", "8L1", "8V", "7L1", "7V", "6L37", "5L37", "4L37", "3L37"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			yawn: ["9L9", "8L9", "8V", "7L1", "6L1", "5L1", "4L1", "3L1"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "9L24", "8M", "8L24", "7T", "7L32", "6T", "6L32", "5T", "5L32", "4T", "4L34"],
		},
		eventData: [
			{generation: 6, level: 100, nature: "Quiet", abilities: ["oblivious"], moves: ["scald", "trickroom", "slackoff", "irontail"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 15},
			{generation: 1, level: 23},
			{generation: 2, level: 20},
			{generation: 3, level: 32},
			{generation: 4, level: 15},
			{generation: 5, level: 35},
			{generation: 7, level: 15},
		],
	},
	slowbrogalar: {
		learnset: {
			acid: ["9L1", "8L1"],
			acidspray: ["9M"],
			amnesia: ["9M", "9L27", "8M", "8L27"],
			attract: ["8M"],
			avalanche: ["9M", "8M"],
			blizzard: ["9M", "8M"],
			bodypress: ["9M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M"],
			brine: ["8M"],
			brutalswing: ["8M"],
			bulldoze: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			chillingwater: ["9M"],
			confusion: ["9L12", "8L12"],
			curse: ["9L1", "8L1"],
			dig: ["9M", "8M"],
			disable: ["9L15", "8L15"],
			dive: ["8M"],
			drainpunch: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			flamethrower: ["9M", "8M"],
			fling: ["9M", "8M"],
			focusblast: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			futuresight: ["8M"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			gunkshot: ["9M"],
			hail: ["8M"],
			headbutt: ["9L21", "8L21"],
			healpulse: ["9L45", "8L45"],
			helpinghand: ["9M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			icefang: ["9M"],
			icepunch: ["9M", "8M"],
			icywind: ["9M", "8M"],
			imprison: ["9M", "8M"],
			irondefense: ["9M", "8M"],
			irontail: ["8M"],
			lightscreen: ["9M", "8M"],
			liquidation: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			nastyplot: ["9M", "8M"],
			payday: ["8M"],
			poisonjab: ["9M", "8M"],
			powergem: ["9M"],
			protect: ["9M", "8M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L36", "8M", "8L36"],
			psychicterrain: ["9M", "8M"],
			psychup: ["9L39", "8L39"],
			psyshock: ["9M", "8M"],
			raindance: ["9M", "9L42", "8M", "8L42"],
			razorshell: ["8M"],
			rest: ["9M", "8M"],
			rockblast: ["9M"],
			round: ["8M"],
			safeguard: ["8M"],
			sandstorm: ["9M"],
			scald: ["8M"],
			scaryface: ["9M"],
			shadowball: ["9M", "8M"],
			shellsidearm: ["9L0", "8L0"],
			skillswap: ["9M", "8M"],
			slackoff: ["9L33", "8L33"],
			sleeptalk: ["9M", "8M"],
			sludgebomb: ["9M", "8M"],
			sludgewave: ["8M"],
			snore: ["8M"],
			snowscape: ["9M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			surf: ["9M", "9L30", "8M", "8L30"],
			swift: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M"],
			triattack: ["8M"],
			trick: ["9M", "8M"],
			trickroom: ["9M", "8M"],
			venoshock: ["9M", "8M"],
			waterfall: ["9M"],
			watergun: ["8L1"],
			waterpulse: ["9M", "9L18", "8L18"],
			weatherball: ["8M"],
			whirlpool: ["8M"],
			withdraw: ["9L1", "8L1"],
			wonderroom: ["8M"],
			yawn: ["9L9", "8L9"],
			zenheadbutt: ["9M", "9L24", "8M", "8L24"],
		},
	},
	slowking: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			allyswitch: ["7T"],
			amnesia: ["9M", "9L27", "8M", "8L27"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "8M", "4M"],
			blizzard: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			chillyreception: ["9L1"],
			confide: ["7M", "6M"],
			confusion: ["9L12", "8L12", "7L14", "7V", "6L14", "5L14", "4L15", "3L17"],
			counter: ["3T"],
			curse: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			dig: ["9M", "8M", "7V", "6M", "5M", "4M", "3M"],
			disable: ["9L15", "8L15", "7L19", "7V", "6L19", "5L19", "4L20", "3L24"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragontail: ["7M", "6M", "5M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["9M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			futuresight: ["9L1", "8M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "7L5", "7V", "6L5", "5L5", "4L6", "3L6"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["9L21", "8L21", "7L23", "7V", "6L23", "5L23", "4T", "4L25", "3L29"],
			healpulse: ["9L45", "8L45", "7L1", "6L1", "5L58"],
			hiddenpower: ["7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "8M"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			liquidation: ["9M", "8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["9M"],
			mimic: ["3T"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "9L1", "8M", "8L1", "7L36", "6L36", "5L36", "4L39"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payday: ["8M"],
			powergem: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L36", "8M", "8L36", "7M", "7L45", "7V", "6M", "6L45", "5M", "5L45", "4M", "4L48", "3M", "3L40"],
			psychicterrain: ["9M", "8M"],
			psychup: ["9L39", "8L39", "7M", "7L54", "7V", "6M", "6L54", "5M", "5L54", "4M", "4L57", "3T", "3L47"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			quash: ["7M", "6M", "5M"],
			raindance: ["9M", "9L42", "8M", "8L42", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorshell: ["8M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			slackoff: ["9L33", "8L33"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			storedpower: ["9M", "8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["9M", "9L30", "8M", "8L30", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["9L1", "8L1", "7M", "7L41", "7V", "6M", "6L41", "5M", "5L41", "4M", "4L43", "3T", "3L36"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunderpunch: ["9M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			trumpcard: ["7L49", "6L49", "5L49", "4L53"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1", "7L9", "7V", "6L9", "5L9", "4L11", "3L13"],
			waterpulse: ["9M", "9L18", "8L18", "7T", "7L28", "6T", "6L28", "5L28", "4M", "4L29", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7V", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			yawn: ["9L9", "8L9", "7L1", "6L1", "5L1", "4L1", "3L1"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "8M", "8L24", "7T", "7L32", "6T", "6L32", "5T", "5L32", "4T", "4L34"],
		},
	},
	slowkinggalar: {
		learnset: {
			acid: ["9L1", "8L1"],
			acidspray: ["9M"],
			amnesia: ["9M", "9L27", "8M", "8L27"],
			attract: ["8M"],
			avalanche: ["9M", "8M"],
			blizzard: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M"],
			brine: ["8M"],
			bulldoze: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			chillingwater: ["9M"],
			chillyreception: ["9L1"],
			confusion: ["9L12", "8L12"],
			curse: ["9L1", "8L1"],
			dig: ["9M", "8M"],
			disable: ["9L15", "8L15"],
			dive: ["8M"],
			drainpunch: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			eeriespell: ["9L0", "8L0"],
			endure: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			firepunch: ["9M"],
			flamethrower: ["9M", "8M"],
			fling: ["9M", "8M"],
			focusblast: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			futuresight: ["9L1", "8M"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			gunkshot: ["9M"],
			hail: ["8M"],
			headbutt: ["9L21", "8L21"],
			healpulse: ["9L45", "8L45"],
			hex: ["9M", "8M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			icepunch: ["8M"],
			icywind: ["9M", "8M"],
			imprison: ["9M", "8M"],
			irondefense: ["9M", "8M"],
			irontail: ["8M"],
			lightscreen: ["9M", "8M"],
			liquidation: ["9M", "8M"],
			lowsweep: ["9M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			payday: ["8M"],
			poisonjab: ["9M"],
			powergem: ["9M", "9L1", "8M", "8L1"],
			protect: ["9M", "8M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L36", "8M", "8L36"],
			psychicterrain: ["9M", "8M"],
			psychup: ["9L39", "8L39"],
			psyshock: ["9M", "8M"],
			raindance: ["9M", "9L42", "8M", "8L42"],
			razorshell: ["8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			scald: ["8M"],
			scaryface: ["9M"],
			shadowball: ["9M", "8M"],
			skillswap: ["9M", "8M"],
			slackoff: ["9L33", "8L33"],
			sleeptalk: ["9M", "8M"],
			sludgebomb: ["9M", "8M"],
			sludgewave: ["8M"],
			snarl: ["9M"],
			snore: ["8M"],
			stompingtantrum: ["9M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			surf: ["9M", "9L30", "8M", "8L30"],
			swagger: ["9L1", "8L1"],
			swift: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thunderpunch: ["9M"],
			thunderwave: ["9M", "8M"],
			toxic: ["9L1"],
			toxicspikes: ["9M"],
			triattack: ["8M"],
			trick: ["9M", "8M"],
			trickroom: ["9M", "8M"],
			venomdrench: ["8M"],
			venoshock: ["9M", "8M"],
			waterfall: ["9L1"],
			waterpulse: ["9M", "9L18", "8L18"],
			weatherball: ["8M"],
			whirlpool: ["8M"],
			wonderroom: ["8M"],
			yawn: ["9L9", "8L9"],
			zenheadbutt: ["9M", "9L24", "8M", "8L24"],
		},
	},
	magnemite: {
		learnset: {
			bide: ["7V"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			curse: ["7V"],
			discharge: ["9L36", "8L36", "7L37", "6L37", "5L43", "4L38"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L29", "6L29", "5L27"],
			electroweb: ["9E", "8M", "7T", "6T", "5T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			explosion: ["9E", "7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			flashcannon: ["9M", "9L32", "8M", "8L32", "8V", "7M", "7L31", "6M", "6L31", "5M", "5L35", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gravity: ["7T", "6T", "5T", "5D", "4T"],
			gyroball: ["9L16", "8M", "8L16", "7M", "7L47", "6M", "6L47", "5M", "5L54", "4M", "4L49"],
			headbutt: ["8V"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M"],
			lightscreen: ["9M", "9L44", "8M", "8L44", "8V", "7M", "7L13", "6M", "5M", "4M"],
			lockon: ["9L48", "8L48", "7L41", "7V", "6L41", "5L30", "4L27", "3L32"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magnetbomb: ["7L11", "6L17", "5L18", "4L30"],
			magnetrise: ["9L28", "8L28", "7T", "7L43", "6T", "6L43", "5T", "5L49", "4T", "4L46"],
			metalsound: ["9L40", "8L40", "7L25", "6L25", "5L1", "5D", "4L1", "3L1"],
			mimic: ["7V", "3T"],
			mirrorshot: ["7L23", "6L23", "5L46", "4L43"],
			naturalgift: ["4M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "5D", "4M"],
			reflect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M"],
			screech: ["9L24", "8M", "8L24", "8V", "7L35", "7V", "6L35", "5L38", "4L33", "3L44"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			sonicboom: ["8V", "7L17", "7V", "6L11", "5L14", "4L14", "3L16"],
			spark: ["9L20", "8L20", "7L19", "6L19", "5L22", "4L22", "3L26"],
			steelbeam: ["9M", "8T"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			supersonic: ["9L4", "8L4", "8V", "7L1", "7V", "6L4", "5L11", "4L11", "3L11"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T", "3L38"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "7V"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thundershock: ["9L1", "8L1", "8V", "7L5", "7V", "6L7", "5L6", "5D", "4L6", "3L6"],
			thunderwave: ["9M", "9L8", "8M", "8L8", "8V", "7M", "7L11", "7V", "6M", "6L13", "5M", "5L17", "4M", "4L17", "3T", "3L21"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			zapcannon: ["9L52", "8L52", "7L49", "7V", "6L49", "5L59", "4L54", "3L50"],
		},
		encounters: [
			{generation: 1, level: 16},
		],
	},
	magneton: {
		learnset: {
			bide: ["7V"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			curse: ["7V"],
			discharge: ["9L40", "8L40", "7L43", "6L43", "5L46", "4L40"],
			doubleedge: ["7V", "3T", "3S0"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "9L1", "8M", "8L1", "7L1", "6L1"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L29", "6L29", "5L27"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			flashcannon: ["9M", "9L34", "8M", "8L34", "8V", "7M", "7L33", "6M", "6L33", "5M", "5L39", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["9L16", "8M", "8L16", "7M", "7L59", "6M", "6L59", "5M", "5L60", "4M", "4L54"],
			headbutt: ["8V"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M"],
			lightscreen: ["9M", "9L52", "8M", "8L52", "8V", "7M", "7L13", "6M", "5M", "4M"],
			lockon: ["9L58", "8L58", "7L49", "7V", "6L49", "5L30", "4L27", "3L35"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magnetbomb: ["7L1", "6L17", "5L34", "4L30"],
			magnetrise: ["9L28", "8L28", "7T", "7L53", "6T", "6L53", "5T", "5L54", "4T", "4L50"],
			metalsound: ["9L46", "8L46", "7L25", "6L25", "5L1", "4L1", "3L1"],
			mimic: ["7V", "3T"],
			mirrorshot: ["7L23", "6L23", "5L50", "4L46"],
			naturalgift: ["4M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M"],
			screech: ["9L24", "8M", "8L24", "8V", "7L39", "7V", "6L39", "5L40", "4L34", "3L53"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			sonicboom: ["8V", "7L17", "7V", "6L1", "5L14", "4L14", "3L16"],
			spark: ["9L20", "8L20", "7L19", "6L19", "5L22", "4L22", "3L26"],
			steelbeam: ["9M", "8T"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			supersonic: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "7V"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thundershock: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thunderwave: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7L1", "7V", "6M", "6L13", "5M", "5L17", "4M", "4L17", "3T", "3L21"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["9L0", "8M", "8L0", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L44"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			zapcannon: ["9L64", "8L64", "7L1", "7V", "6L1", "5L66", "4L60", "3L62"],
		},
		eventData: [
			{generation: 3, level: 30, moves: ["refresh", "doubleedge", "raindance", "thunder"]},
		],
		encounters: [
			{generation: 2, level: 5},
			{generation: 3, level: 26},
			{generation: 4, level: 17, pokeball: "safariball"},
		],
	},
	magnezone: {
		learnset: {
			allyswitch: ["8M", "7T"],
			barrier: ["7L1", "6L1", "5L1", "4L1"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			discharge: ["9L40", "8L40", "7L43", "6L43", "5L46", "4L40"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "9L1", "8M", "8L1", "7L1", "6L1"],
			electroball: ["9M", "9L12", "8M", "8L12", "7L29", "6L29", "5L27"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["9M", "8M", "4M"],
			explosion: ["7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			flashcannon: ["9M", "9L34", "8M", "8L34", "7M", "7L33", "6M", "6L33", "5M", "5L39", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["9L16", "8M", "8L16", "7M", "7L59", "6M", "6L59", "5M", "5L60", "4M", "4L54"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "9L52", "8M", "8L52", "7M", "7L13", "6M", "5M", "4M"],
			lockon: ["9L58", "8L58", "7L49", "6L49", "5L30", "4L27"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magnetbomb: ["7L1", "6L17", "5L34", "4L30"],
			magneticflux: ["9L1", "8L1", "7L1", "6L1"],
			magnetrise: ["9L28", "8L28", "7T", "7L53", "6T", "6L53", "5T", "5L54", "4T", "4L50"],
			metalsound: ["9L46", "8L46", "7L25", "6L25", "5L1", "4L1"],
			mirrorcoat: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			mirrorshot: ["7L23", "6L23", "5L50", "4L46"],
			naturalgift: ["4M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			risingvoltage: ["8T"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M"],
			screech: ["9L24", "8M", "8L24", "7L39", "6L39", "5L40", "4L34"],
			secretpower: ["6M", "4M"],
			selfdestruct: ["8M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			sonicboom: ["7L17", "6L1", "5L14", "4L14"],
			spark: ["9L20", "8L20", "7L19", "6L19", "5L22", "4L22"],
			steelbeam: ["9M", "8T"],
			steelroller: ["8T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			supersonic: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thundershock: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			thunderwave: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L13", "5M", "5L17", "4M", "4L17"],
			toxic: ["7M", "6M", "5M", "4M"],
			triattack: ["9L1", "8M", "8L1", "7L1"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			zapcannon: ["9L64", "8L64", "7L1", "6L1", "5L66", "4L60"],
		},
	},
	farfetchd: {
		learnset: {
			acrobatics: ["8M", "7M", "7L37", "6M", "6L37", "5M", "5L37"],
			aerialace: ["8L20", "7M", "7L9", "6M", "6L9", "5M", "5L13", "4M", "4L13", "3M", "3S1"],
			agility: ["8M", "8L60", "8V", "7L31", "7V", "6L31", "5L31", "4L31", "3L36"],
			aircutter: ["8L25", "7L21", "6L21", "5L21", "4T", "4L21"],
			airslash: ["8M", "8L50", "8V", "7L49", "6L49", "5L49", "4L37"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "3S1"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			bravebird: ["8M", "8L65", "7L1", "6L1", "5L55"],
			brutalswing: ["8M", "7M"],
			captivate: ["4M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			covet: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			cut: ["8L15", "8V", "7V", "6M", "5M", "4M", "3M"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "8L35", "7M", "7L45", "7V", "6M", "6L45", "5M", "5L45", "4M", "4L43", "3L46"],
			featherdance: ["8E", "7E", "6E", "5E", "4E", "3E"],
			feint: ["8E", "8V", "7L43", "6L43", "5L43", "4L43"],
			finalgambit: ["8E", "7E"],
			firstimpression: ["8E", "7E"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			fly: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			focusenergy: ["8M", "8V"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L7", "7V", "6L7", "5L7", "4L7", "3L16"],
			furycutter: ["8L10", "7L1", "6L1", "5L1", "5D", "4T", "4L1", "3L26"],
			gust: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["8L30", "7T", "7L13", "6T", "6L13", "5T", "5L9", "4T", "4L9", "3L21"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leafblade: ["8M", "8L55", "7E", "6E", "5E", "5D", "4E"],
			leer: ["8L5", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L11"],
			mimic: ["7V", "3T"],
			mirrormove: ["8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			mudslap: ["7E", "7V", "6E", "5E", "4T", "4E", "3T"],
			naturalgift: ["4M"],
			nightslash: ["8E", "7L33", "7E", "6L33", "6E", "5L33", "5E", "4L33", "4E"],
			ominouswind: ["4T"],
			peck: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			pluck: ["5M", "4M"],
			poisonjab: ["8M", "8V", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			quickattack: ["8E", "8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7V"],
			razorleaf: ["8V"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "7E", "6E", "5E"],
			roost: ["8E", "8V", "7M", "7E", "6M", "6E", "5T", "5E", "5D", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L6"],
			secretpower: ["6M", "4M", "3M"],
			simplebeam: ["8E", "7E", "6E"],
			skullbash: ["7V"],
			skyattack: ["8E", "8V", "7T", "6T", "5T"],
			slash: ["8L40", "8V", "7L19", "7V", "6L19", "5L19", "4L19", "3L41", "3S1"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarblade: ["8M"],
			steelwing: ["8M", "7M", "7E", "7V", "6M", "6E", "5E", "4M", "4E", "3M", "3E"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			swordsdance: ["8M", "8L45", "8V", "7M", "7L25", "7V", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3T", "3L31", "3S1"],
			tailwind: ["7T", "6T", "5T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trumpcard: ["7E", "6E", "5E"],
			twister: ["4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["8M", "8V", "7M", "6M", "5M", "4M"],
			whirlwind: ["7V"],
			wish: ["3S0"],
			workup: ["8M", "7M", "5M"],
			yawn: ["3S0"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["yawn", "wish"], pokeball: "pokeball"},
			{generation: 3, level: 36, moves: ["batonpass", "slash", "swordsdance", "aerialace"]},
		],
		encounters: [
			{generation: 1, level: 3},
			{generation: 3, level: 3, gender: "M", nature: "Adamant", ivs: {hp: 20, atk: 25, def: 21, spa: 24, spd: 15, spe: 20}, abilities: ["keeneye"], pokeball: "pokeball"},
		],
	},
	farfetchdgalar: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M"],
			bodyslam: ["8M"],
			bravebird: ["8M", "8L65"],
			brickbreak: ["8M", "8L40"],
			brutalswing: ["8M", "8L20"],
			closecombat: ["8M"],
			counter: ["8E"],
			covet: ["8E"],
			curse: ["8E"],
			defog: ["8L35"],
			detect: ["8L25"],
			doubleedge: ["8E"],
			dualwingbeat: ["8T"],
			endure: ["8M"],
			facade: ["8M"],
			feint: ["8E"],
			finalgambit: ["8L60"],
			flail: ["8E"],
			focusenergy: ["8M"],
			furycutter: ["8L10"],
			helpinghand: ["8M"],
			knockoff: ["8L30"],
			leafblade: ["8M", "8L55"],
			leer: ["8L5"],
			nightslash: ["8E"],
			peck: ["8L1"],
			poisonjab: ["8M"],
			protect: ["8M"],
			quickattack: ["8E"],
			quickguard: ["8E"],
			rest: ["8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			rocksmash: ["8L15"],
			round: ["8M"],
			sandattack: ["8L1"],
			simplebeam: ["8E"],
			skyattack: ["8E"],
			slam: ["8L50"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarblade: ["8M"],
			steelwing: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			superpower: ["8M"],
			swordsdance: ["8M", "8L45"],
			throatchop: ["8M"],
			workup: ["8M"],
		},
	},
	sirfetchd: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M"],
			bodyslam: ["8M"],
			bravebird: ["8M", "8L65"],
			brickbreak: ["8M", "8L40"],
			brutalswing: ["8M", "8L20", "8S0"],
			closecombat: ["8M"],
			coaching: ["8T"],
			defog: ["8L35"],
			detect: ["8L25", "8S0"],
			dualwingbeat: ["8T"],
			endure: ["8M"],
			facade: ["8M"],
			finalgambit: ["8L60"],
			firstimpression: ["8L1"],
			focusenergy: ["8M"],
			furycutter: ["8L1", "8S0"],
			grassyglide: ["8T"],
			helpinghand: ["8M"],
			irondefense: ["8M", "8L0"],
			knockoff: ["8L30"],
			leafblade: ["8M", "8L55"],
			leer: ["8L1"],
			meteorassault: ["8L70", "8S0"],
			peck: ["8L1"],
			poisonjab: ["8M"],
			protect: ["8M"],
			rest: ["8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			rocksmash: ["8L15"],
			round: ["8M"],
			sandattack: ["8L1"],
			slam: ["8L50"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarblade: ["8M"],
			steelwing: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			superpower: ["8M"],
			swordsdance: ["8M", "8L45"],
			throatchop: ["8M"],
			workup: ["8M"],
		},
		eventData: [
			{generation: 8, level: 80, gender: "M", nature: "Brave", abilities: ["steadfast"], ivs: {hp: 30, atk: 31, def: 31, spa: 30, spd: 30, spe: 31}, moves: ["meteorassault", "brutalswing", "furycutter", "detect"], pokeball: "pokeball"},
		],
	},
	doduo: {
		learnset: {
			acupressure: ["7L33", "6L28", "5L28", "4L28"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8V", "7L26", "7V", "6L33", "5L37", "4L37", "3L45"],
			aircutter: ["4T"],
			assurance: ["7E", "6E", "5E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["7V", "3T"],
			bravebird: ["7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			doubleedge: ["7V", "3T"],
			doublehit: ["7L22", "6L25", "5L32", "4L32"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillpeck: ["8V", "7L43", "7V", "6L37", "5L41", "4L41", "3L37"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["7T", "7L47", "7E", "6T", "6L45", "6E", "5T", "5L46", "5E", "4T", "4L46", "4E", "3E"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			flail: ["7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			fly: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L12", "7V", "6L13", "5L14", "4L14", "3L13"],
			growl: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			haze: ["7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			jumpkick: ["8V", "7L40"],
			knockoff: ["7T", "6T", "5T", "4T"],
			mimic: ["7V", "3T"],
			mirrormove: ["7E", "6E", "5E", "4E"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			peck: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			pluck: ["7L19", "6L21", "5M", "4M"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L15", "7V", "6L17", "5L19", "4L19", "3L9"],
			quickattack: ["8V", "7L5", "7E", "7V", "6L5", "6E", "5L5", "5E", "4L5", "4E", "3E"],
			rage: ["8V", "7L8", "7V", "6L9", "5L10", "4L10", "3L25"],
			reflect: ["8V", "7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "6M", "5T", "5D", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			skyattack: ["7V", "3T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "7V", "6M", "4M", "3M"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["7E", "7V", "6E", "5E", "4E", "3E"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			swordsdance: ["8V", "7M", "7L36"],
			takedown: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["8V", "7L50", "6L49", "5L50"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["7V", "3L21"],
			uproar: ["7T", "7L29", "6T", "6L23", "5T", "5L23", "4T", "4L23", "3L33"],
			whirlwind: ["7V"],
			workup: ["7M", "5M"],
		},
		encounters: [
			{generation: 1, level: 18},
			{generation: 2, level: 4},
		],
	},
	dodrio: {
		learnset: {
			acupressure: ["7L34", "6L28", "5L28", "4L28"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8V", "7L26", "7V", "6L35", "5L41", "4L41", "3L60", "3S0"],
			aircutter: ["4T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["3S0"],
			bide: ["7V"],
			bodyslam: ["7V", "3T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			doubleedge: ["7V", "3T"],
			doublehit: ["7L22"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillpeck: ["8V", "7L47", "7V", "6L41", "5L47", "4L47", "3L47", "3S0"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["7T", "7L52", "6T", "6L53", "5T", "5L54", "4T", "4L54"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fly: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L12", "7V", "6L13", "5L14", "4L14", "3L1"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			growl: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			jumpkick: ["8V", "7L43"],
			knockoff: ["7T", "6T", "5T", "4T"],
			mimic: ["7V", "3T"],
			mirrormove: ["8V"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			peck: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			pluck: ["7L19", "6L1", "5M", "5L1", "4M", "4L1"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L15", "7V", "6L17", "5L19", "4L19", "3L1"],
			quickattack: ["8V", "7L1", "6L1", "5L1", "4L1"],
			rage: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L25"],
			reflect: ["8V", "7V"],
			rest: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			skyattack: ["7T", "7V", "6T", "5T", "4T", "3T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "7V", "6M", "4M", "3M"],
			stompingtantrum: ["7T"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["8V"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			swordsdance: ["8V", "7M", "7L38"],
			takedown: ["7V"],
			taunt: ["8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["8V", "7L56", "6L59", "5L60"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8V", "7L1", "7V", "6L25", "5L34", "4L34", "3L21", "3S0"],
			uproar: ["7T", "7L29", "6T", "6L23", "5T", "5L23", "4T", "4L23", "3L38"],
			whirlwind: ["7V"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 34, moves: ["batonpass", "drillpeck", "agility", "triattack"]},
		],
		encounters: [
			{generation: 1, level: 29},
			{generation: 2, level: 10, gender: "F"},
			{generation: 2, level: 30},
			{generation: 3, level: 29, pokeball: "safariball"},
			{generation: 4, level: 15, gender: "F", nature: "Impish", ivs: {hp: 20, atk: 20, def: 20, spa: 15, spd: 15, spe: 15}, abilities: ["runaway"], pokeball: "pokeball"},
		],
	},
	seel: {
		learnset: {
			aquajet: ["8V", "7L31", "6L31", "5L31", "4L31"],
			aquaring: ["7L23", "6L23", "5L23", "4L23"],
			aquatail: ["7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L43"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8V", "7L27", "7V", "6L27", "5L27", "4L27", "3L21"],
			belch: ["7E", "6E"],
			bide: ["7V"],
			blizzard: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7V", "3T"],
			brine: ["7L33", "6L33", "5L33", "4M", "4L33"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			disable: ["7E", "7V", "6E", "5E", "4E", "3E"],
			dive: ["7L41", "6M", "6L41", "5M", "5L41", "4T", "4L41", "3M"],
			doubleedge: ["8V", "7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8V", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8V", "7L13", "7E", "7V", "6L13", "6E", "5L13", "5E", "4L13", "4E", "3E"],
			endure: ["7V", "4M", "3T"],
			entrainment: ["7E", "6E"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["7E", "6E", "5E", "4E", "3E"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			growl: ["8V", "7L3", "7V", "6L3", "5L3", "4L3", "3L9"],
			hail: ["7M", "7L53", "6M", "6L53", "5M", "5L53", "4M", "3M"],
			headbutt: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4T", "4L1", "3L1"],
			helpinghand: ["8V", "3S0"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			horndrill: ["7E", "7V", "6E", "5E", "4E", "3E"],
			icebeam: ["8V", "7M", "7L47", "7V", "6M", "6L47", "5M", "5L47", "4M", "4L47", "3M", "3L41", "3S0"],
			iceshard: ["8V", "7L17", "6L17", "5L17", "4L17"],
			iciclespear: ["7E", "6E", "5E", "5D", "4E", "3E"],
			icywind: ["7T", "7L11", "7V", "6T", "6L11", "5T", "5L11", "4T", "4L11", "3T", "3L17"],
			irontail: ["8V", "7T", "7E", "6T", "6E", "5T", "5E"],
			lick: ["7E", "7V", "6E", "5E", "4E", "3E"],
			megahorn: ["8V"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			payday: ["8V", "7V"],
			peck: ["7V"],
			perishsong: ["7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8V", "7M", "7L21", "7V", "6M", "6L21", "5M", "5L21", "4M", "4L21", "3M", "3L29"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7L51", "7V", "6M", "6L51", "5M", "5L51", "4M", "4L51", "3M", "3L49", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			skullbash: ["7V"],
			slam: ["7E", "7V", "6E", "5E", "4E", "3E"],
			sleeptalk: ["7M", "7E", "7V", "6M", "6E", "5T", "5E", "5D", "4M", "3T"],
			smartstrike: ["7M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			spitup: ["7E", "6E", "5E", "4E"],
			stockpile: ["7E", "6E", "5E", "4E"],
			strength: ["7V"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["7E", "6E", "5E", "4E"],
			takedown: ["8V", "7L37", "7V", "6L37", "5L37", "4L37", "3L37"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			watersport: ["7L7", "6L7", "5L7", "4L7"],
			whirlpool: ["7V", "4M"],
		},
		eventData: [
			{generation: 3, level: 23, abilities: ["thickfat"], moves: ["helpinghand", "surf", "safeguard", "icebeam"]},
		],
		encounters: [
			{generation: 1, level: 22},
		],
	},
	dewgong: {
		learnset: {
			aquajet: ["8V", "7L31", "6L31", "5L31", "4L31"],
			aquaring: ["7L23", "6L23", "5L23", "4L23"],
			aquatail: ["7T", "7L49", "6T", "6L49", "5T", "5L49", "4T", "4L43"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8V", "7L27", "7V", "6L27", "5L27", "4L27", "3L1"],
			avalanche: ["4M"],
			bide: ["7V"],
			blizzard: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7V", "3T"],
			brine: ["7L33", "6L33", "5L33", "4M", "4L33"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			dive: ["7L45", "6M", "6L45", "5M", "5L45", "4T", "4L41", "3M"],
			doubleedge: ["8V", "7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8V", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8V", "7L13", "6L13", "5L13", "4L13"],
			endure: ["7V", "4M", "3T"],
			facade: ["8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8V"],
			fling: ["7M", "6M", "5M", "4M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			growl: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			hail: ["7M", "7L65", "6M", "6L65", "5M", "5L65", "4M", "3M"],
			headbutt: ["8V", "7L1", "7V", "6L1", "5L1", "4T", "4L1", "3L1"],
			helpinghand: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			horndrill: ["8V", "7V"],
			hyperbeam: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8V", "7M", "7L55", "7V", "6M", "6L55", "5M", "5L55", "4M", "4L47", "3M", "3L51"],
			iceshard: ["8V", "7L17", "6L17", "5L17", "4L17"],
			icywind: ["7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T", "3L1"],
			irontail: ["8V", "7T", "6T", "5T"],
			liquidation: ["7T"],
			megahorn: ["8V"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			payday: ["8V", "7V"],
			protect: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8V", "7M", "7L21", "7V", "6M", "6L21", "5M", "5L21", "4M", "4L21", "3M", "3L29"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7L61", "7V", "6M", "6L61", "5M", "5L61", "4M", "4L51", "3M", "3L64"],
			secretpower: ["6M", "4M", "3M"],
			sheercold: ["7L1", "6L34", "5L34", "4L34", "3L34"],
			signalbeam: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			skullbash: ["7V"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			smartstrike: ["7M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V"],
			substitute: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["8V", "7L39", "7V", "6L39", "5L39", "4L37", "3L42"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["7V", "4M"],
		},
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 5},
			{generation: 3, level: 32},
			{generation: 5, level: 30},
			{generation: 6, level: 30, maxEggMoves: 1},
		],
	},
	grimer: {
		learnset: {
			acidarmor: ["9L43", "8V", "7L43", "7V", "6L40", "5L39", "4L39", "3L34"],
			acidspray: ["9M", "9E", "7E", "6E", "5E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			belch: ["9L46", "7L46", "6L46"],
			bide: ["7V"],
			bodyslam: ["9M", "7V", "3T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			curse: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			dig: ["9M", "8V", "6M", "5M", "4M", "3M"],
			disable: ["9L12", "8V", "7L12", "7V", "6L12", "5L12", "4L12", "3L8"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dynamicpunch: ["7V", "3T"],
			endure: ["9M", "7V", "4M", "3T"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "7L26", "6M", "6L26", "5M", "5L28", "4M", "4L28"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gunkshot: ["9M", "9L40", "7T", "7L40", "6T", "6L40", "5T", "5L49", "4T", "4L44"],
			harden: ["9L4", "8V", "7L4", "7V", "6L4", "5L4", "4L4", "3L4"],
			haze: ["9E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			headbutt: ["8V"],
			helpinghand: ["9M", "8V", "3S0"],
			hex: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "7E", "6E", "5E", "4E", "3E"],
			incinerate: ["6M", "5M"],
			infestation: ["7M", "6M"],
			lick: ["7E", "7V", "6E", "5E", "4E", "3E"],
			meanlook: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			megadrain: ["8V", "7V"],
			memento: ["9L48", "7L48", "6L48", "5L52", "4L49", "3L53"],
			metronome: ["9M"],
			mimic: ["7V", "3T"],
			minimize: ["9L21", "8V", "7L21", "7V", "6L18", "5L17", "4L17", "3L19", "3S0"],
			mudbomb: ["7L18", "6L18", "5L23", "4L23"],
			mudshot: ["9M", "9L18"],
			mudslap: ["9M", "9L7", "7L7", "7V", "6L7", "5L7", "4T", "4L7", "3T"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["7M", "6M", "5M", "4M"],
			poisongas: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			poisonjab: ["9M", "8V", "7M", "6M", "5M", "4M"],
			pound: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["7E", "6M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8V", "7M", "6M", "5M", "4M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "7E", "6E", "5E"],
			screech: ["9L37", "8V", "7L37", "7V", "6L32", "5L33", "4L33", "3L26"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8V", "7V", "3T"],
			shadowball: ["9M", "8V", "7M", "6M", "5M", "4M"],
			shadowpunch: ["9E", "7E", "6E", "5E", "4E", "3E", "3S0"],
			shadowsneak: ["9E", "7E", "6E", "5E", "5D", "4E"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludge: ["9L15", "8V", "7L15", "7V", "6L15", "5L20", "4L20", "3L13"],
			sludgebomb: ["9M", "9L29", "8V", "7M", "7L29", "7V", "6M", "6L26", "5M", "5L36", "4M", "4L36", "3M", "3L43", "3S0"],
			sludgewave: ["9L32", "7M", "7L32", "6M", "6L32", "5M", "5L44"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			spitup: ["9E", "7E", "6E", "5E", "4E"],
			stockpile: ["9E", "7E", "6E", "5E", "4E"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["9E", "7E", "6E", "5E", "4E"],
			takedown: ["9M"],
			taunt: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["9L26", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			venoshock: ["9M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 23, moves: ["helpinghand", "sludgebomb", "shadowpunch", "minimize"]},
		],
		encounters: [
			{generation: 1, level: 23},
		],
	},
	grimeralola: {
		learnset: {
			acidarmor: ["9L43", "8V", "7L43"],
			acidspray: ["9M", "9L15", "7L15"],
			assurance: ["7E"],
			attract: ["7M"],
			belch: ["9L46", "7L46"],
			bite: ["9L7", "8V", "7L7", "7S0"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			brutalswing: ["7M"],
			clearsmog: ["7E"],
			confide: ["7M"],
			crunch: ["9M", "9L32", "8V", "7L32"],
			curse: ["7E"],
			darkpulse: ["9M"],
			dig: ["9M", "8V"],
			disable: ["9L12", "8V", "7L12"],
			doubleteam: ["7M"],
			drainpunch: ["9M"],
			embargo: ["7M"],
			endure: ["9M"],
			explosion: ["7M"],
			facade: ["9M", "8V", "7M"],
			fireblast: ["9M", "8V", "7M"],
			firepunch: ["9M", "8V", "7T"],
			flamethrower: ["9M", "8V", "7M"],
			fling: ["9M", "7M", "7L26"],
			frustration: ["7M"],
			gastroacid: ["7T"],
			gigadrain: ["9M", "7T"],
			gigaimpact: ["9M"],
			gunkshot: ["9M", "9L40", "7T", "7L40"],
			harden: ["9L4", "8V", "7L4", "7S0"],
			headbutt: ["8V"],
			helpinghand: ["9M", "8V"],
			hex: ["9M"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M"],
			icepunch: ["9M", "8V", "7T"],
			imprison: ["9M", "7E"],
			infestation: ["7M"],
			knockoff: ["9L29", "7T", "7L29"],
			meanlook: ["7E"],
			megadrain: ["8V"],
			memento: ["9L48", "7L48"],
			metronome: ["9M"],
			minimize: ["9L21", "8V", "7L21"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			painsplit: ["7T"],
			payback: ["7M"],
			poisonfang: ["9L18", "7L18"],
			poisongas: ["9L1", "8V", "7L1", "7S0"],
			poisonjab: ["9M", "8V", "7M"],
			pound: ["9L1", "8V", "7L1", "7S0"],
			poweruppunch: ["7E"],
			protect: ["9M", "8V", "7M"],
			pursuit: ["7E"],
			quash: ["7M"],
			raindance: ["9M", "7M"],
			rest: ["9M", "8V", "7M"],
			return: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["9M", "8V", "7M"],
			rocktomb: ["9M", "7M"],
			round: ["7M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "7E"],
			screech: ["9L37", "8V", "7L37"],
			selfdestruct: ["8V"],
			shadowball: ["9M", "8V", "7M"],
			shadowsneak: ["7E"],
			shockwave: ["7T"],
			sleeptalk: ["9M", "7M"],
			sludgebomb: ["9M", "8V", "7M"],
			sludgewave: ["7M"],
			snarl: ["9M", "7M"],
			snore: ["7T"],
			spite: ["7T", "7E"],
			spitup: ["7E"],
			stockpile: ["7E"],
			stoneedge: ["9M", "7M"],
			substitute: ["9M", "8V", "7M"],
			sunnyday: ["9M", "7M"],
			swagger: ["7M"],
			swallow: ["7E"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "8V", "7M"],
			terablast: ["9M"],
			thief: ["9M", "7M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderpunch: ["9M", "8V", "7T"],
			torment: ["7M"],
			toxic: ["9L26", "8V", "7M"],
			venoshock: ["9M", "7M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 7, level: 10, abilities: ["poisontouch"], moves: ["bite", "harden", "poisongas", "pound"], pokeball: "cherishball"},
		],
	},
	muk: {
		learnset: {
			acidarmor: ["9L46", "8V", "7L46", "7V", "6L43", "5L42", "4L44", "3L34"],
			acidspray: ["9M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			belch: ["9L52", "7L52", "6L52"],
			bide: ["7V"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["9M", "7V", "3T"],
			brickbreak: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			curse: ["7V"],
			darkpulse: ["9M", "8V", "7M", "6M", "5T", "4M"],
			dig: ["9M", "8V", "6M", "5M", "4M", "3M"],
			disable: ["9L12", "8V", "7L12", "7V", "6L12", "5L12", "4L12", "3L8"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dynamicpunch: ["7V", "3T"],
			endure: ["9M", "7V", "4M", "3T"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "7L26", "6M", "6L26", "5M", "5L28", "4M", "4L28"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gunkshot: ["9M", "9L40", "7T", "7L40", "6T", "6L40", "5T", "5L58", "4T", "4L54"],
			harden: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			haze: ["8V"],
			headbutt: ["8V"],
			helpinghand: ["9M", "8V"],
			hex: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["9M"],
			incinerate: ["6M", "5M"],
			infestation: ["7M", "6M"],
			megadrain: ["8V", "7V"],
			memento: ["9L57", "7L57", "6L57", "5L64", "4L65", "3L61"],
			metronome: ["9M"],
			mimic: ["7V", "3T"],
			minimize: ["9L21", "8V", "7L21", "7V", "6L18", "5L17", "4L17", "3L19"],
			moonblast: ["8V"],
			mudbomb: ["7L18", "6L18", "5L23", "4L23"],
			mudshot: ["9M", "9L18"],
			mudslap: ["9M", "9L1", "7L1", "7V", "6L1", "5L1", "4T", "4L1", "3T"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["7M", "6M", "5M", "4M"],
			poisongas: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			poisonjab: ["9M", "8V", "7M", "6M", "5M", "4M"],
			pound: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			screech: ["9L37", "8V", "7L37", "7V", "6L32", "5L33", "4L33", "3L26"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8V", "7V", "3T"],
			shadowball: ["9M", "8V", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludge: ["9L15", "8V", "7L15", "7V", "6L15", "5L20", "4L20", "3L13"],
			sludgebomb: ["9M", "9L29", "8V", "7M", "7L29", "7V", "6M", "6L26", "5M", "5L36", "4M", "4L36", "3M", "3L47"],
			sludgewave: ["9L32", "7M", "7L32", "6M", "6L32", "5M", "5L50"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["9L26", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M"],
			venomdrench: ["7L1", "6L38"],
			venoshock: ["9M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M"],
		},
		encounters: [
			{generation: 1, level: 25},
			{generation: 2, level: 5},
			{generation: 3, level: 32},
			{generation: 4, level: 15},
			{generation: 5, level: 5},
			{generation: 5, level: 35, isHidden: true},
			{generation: 6, level: 30},
		],
	},
	mukalola: {
		learnset: {
			acidarmor: ["9L46", "8V", "7L46"],
			acidspray: ["9M", "9L15", "7L15"],
			attract: ["7M"],
			belch: ["9L52", "7L52"],
			bite: ["9L1", "8V", "7L1"],
			block: ["7T"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8V", "7M"],
			brutalswing: ["7M"],
			confide: ["7M"],
			crunch: ["9M", "9L32", "8V", "7L32"],
			darkpulse: ["9M", "8V", "7M"],
			dig: ["9M", "8V"],
			disable: ["9L12", "8V", "7L12"],
			doubleteam: ["7M"],
			drainpunch: ["9M"],
			embargo: ["7M"],
			endure: ["9M"],
			explosion: ["7M"],
			facade: ["9M", "8V", "7M"],
			fireblast: ["9M", "8V", "7M"],
			firepunch: ["9M", "8V", "7T"],
			flamethrower: ["9M", "8V", "7M"],
			fling: ["9M", "7M", "7L26"],
			focusblast: ["9M", "7M"],
			focuspunch: ["7T"],
			foulplay: ["8V"],
			frustration: ["7M"],
			gastroacid: ["7T"],
			gigadrain: ["9M", "7T"],
			gigaimpact: ["9M", "7M"],
			gunkshot: ["9M", "9L40", "7T", "7L40"],
			harden: ["9L1", "8V", "7L1"],
			haze: ["8V"],
			headbutt: ["8V"],
			helpinghand: ["9M", "8V"],
			hex: ["9M"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M", "8V", "7M"],
			icepunch: ["9M", "8V", "7T"],
			imprison: ["9M"],
			infestation: ["7M"],
			knockoff: ["9L29", "7T", "7L29"],
			megadrain: ["8V"],
			memento: ["9L57", "7L57"],
			metronome: ["9M"],
			minimize: ["9L21", "8V", "7L21"],
			moonblast: ["8V"],
			mudshot: ["9M"],
			painsplit: ["7T"],
			payback: ["7M"],
			poisonfang: ["9L18", "7L18"],
			poisongas: ["9L1", "8V", "7L1"],
			poisonjab: ["9M", "8V", "7M"],
			pound: ["9L1", "8V", "7L1"],
			protect: ["9M", "8V", "7M"],
			quash: ["7M"],
			raindance: ["9M", "7M"],
			recycle: ["7T"],
			rest: ["9M", "8V", "7M"],
			return: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["9M", "8V", "7M"],
			rocktomb: ["9M", "7M"],
			round: ["7M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			screech: ["9L37", "8V", "7L37"],
			selfdestruct: ["8V"],
			shadowball: ["9M", "8V", "7M"],
			shockwave: ["7T"],
			sleeptalk: ["9M", "7M"],
			sludgebomb: ["9M", "8V", "7M"],
			sludgewave: ["7M"],
			snarl: ["9M", "7M"],
			snore: ["7T"],
			spite: ["7T"],
			stoneedge: ["9M", "7M"],
			substitute: ["9M", "8V", "7M"],
			sunnyday: ["9M", "7M"],
			swagger: ["7M"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "8V", "7M"],
			terablast: ["9M"],
			thief: ["9M", "7M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderpunch: ["9M", "8V", "7T"],
			torment: ["7M"],
			toxic: ["9L26", "8V", "7M"],
			venomdrench: ["7L1"],
			venoshock: ["9M", "7M"],
			zenheadbutt: ["9M"],
		},
	},
	shellder: {
		learnset: {
			aquaring: ["9E", "8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["9L24", "8L24", "8V", "7L37", "7V", "6L37", "5L37", "4L32", "3L17", "3S0", "3S2"],
			avalanche: ["9M", "8M", "7E", "6E", "5E"],
			barrier: ["7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			brine: ["8M", "7L44", "6L44", "5L44", "4M", "4L44"],
			bubblebeam: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			clamp: ["8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L41"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "9L48", "8M", "8L48", "8V", "7L61", "6L61", "5L61"],
			icebeam: ["9M", "9L40", "8M", "8L40", "8V", "7M", "7L52", "7V", "6M", "6L52", "5M", "5L52", "4M", "4L49", "3M", "3L49"],
			iceshard: ["9L8", "8L8", "8V", "7L28", "6L28", "5L28", "4L28"],
			icespinner: ["9M"],
			iciclespear: ["8M", "7L13", "7E", "6L13", "6E", "5L13", "5E", "4L13", "4E", "3L8", "3E", "3S0", "3S1"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			irondefense: ["9M", "9L36", "8M", "8L36", "7T", "7L49", "6T", "6L49", "5T", "5L49", "4T", "4L40"],
			leer: ["9L12", "8L12", "8V", "7L20", "7V", "6L20", "5L20", "4L20", "3L33"],
			lifedew: ["9E", "8E"],
			liquidation: ["9M", "8M", "7T"],
			mimic: ["7V", "3T"],
			mudshot: ["9M", "8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["9M", "9L28", "8M", "8L28", "8V", "7M", "7L16", "7V", "6M", "6L16", "5M", "5L16", "4M", "4L16", "3M", "3L25"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rapidspin: ["7E", "7V", "6E", "5E", "4E", "3E"],
			razorshell: ["9L32", "8M", "8L32", "7L32", "6L32", "5L32"],
			reflect: ["8V", "7V"],
			refresh: ["3S2"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["9M", "8M", "7E", "6E", "5E", "4E"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			shellsmash: ["9L44", "8L44", "8V", "7L56", "6L56", "5L56"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			spikes: ["9M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			supersonic: ["9L20", "8L20", "8V", "7L8", "7V", "6L8", "5L8", "4L8", "3L9", "3S0"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S2"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			takedown: ["9M", "7E", "7V", "6E", "5E", "4E", "3E", "3S2"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M"],
			triattack: ["8M", "8V", "7V"],
			twineedle: ["7E", "6E", "5E"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1", "8V", "7L1", "7V"],
			waterpulse: ["9M", "9E", "8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			whirlpool: ["9L16", "8M", "8L16", "7L40", "7V", "6L40", "5L40", "4M", "4L37"],
			withdraw: ["9L4", "8L4", "8V", "7L4", "7V", "6L4", "5L4", "5D", "4L4", "3L1", "3S0", "3S1"],
		},
		eventData: [
			{generation: 3, level: 24, gender: "F", nature: "Brave", ivs: {hp: 5, atk: 19, def: 18, spa: 5, spd: 11, spe: 13}, abilities: ["shellarmor"], moves: ["withdraw", "iciclespear", "supersonic", "aurorabeam"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", abilities: ["shellarmor"], moves: ["tackle", "withdraw", "iciclespear"], pokeball: "pokeball"},
			{generation: 3, level: 29, abilities: ["shellarmor"], moves: ["refresh", "takedown", "surf", "aurorabeam"]},
		],
		encounters: [
			{generation: 1, level: 10},
		],
	},
	cloyster: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			avalanche: ["9M", "8M", "4M"],
			barrier: ["8V"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M"],
			brine: ["8M", "4M"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			clamp: ["7V"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["9M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "5S0", "4M", "3M"],
			hydropump: ["9M", "9L1", "8M", "8L1", "7L1", "6L1"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			iceshard: ["9L1", "8L1"],
			icespinner: ["9M"],
			iciclecrash: ["9L1", "8L1", "7L50", "6L50", "5L52"],
			iciclespear: ["9L0", "8M", "8L0", "5S0"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["9M", "9L1", "8M", "8L1", "7T", "6T", "5T", "4T"],
			leer: ["9L1", "8L1", "8V"],
			lightscreen: ["9M", "8M"],
			liquidation: ["9M", "8M", "7T"],
			mimic: ["7V", "3T"],
			mudshot: ["9M", "8M"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pinmissile: ["8M"],
			poisonjab: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			protect: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorshell: ["9L5", "8M", "8L1", "5S0"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["9M", "8M", "5S0"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			shellsmash: ["9L1", "8L1", "7L1", "6L1"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smartstrike: ["9M", "8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			spikecannon: ["8V", "7L13", "7V", "6L13", "5L13", "4L40", "3L41"],
			spikes: ["9M", "9L1", "8M", "8L1", "7L28", "7V", "6L28", "5L28", "4L28", "3L33"],
			steelroller: ["8T"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			supersonic: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "8V"],
			takedown: ["9M", "7V"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			triattack: ["8M", "8V", "7V"],
			twineedle: ["8V"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1", "8V", "7V"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			weatherball: ["8M"],
			whirlpool: ["9L1", "8M", "8L1", "7V", "4M"],
			withdraw: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
		},
		eventData: [
			{generation: 5, level: 30, gender: "M", nature: "Naughty", abilities: ["skilllink"], moves: ["iciclespear", "rockblast", "hiddenpower", "razorshell"], pokeball: "pokeball"},
		],
	},
	gastly: {
		learnset: {
			acidspray: ["9M"],
			allyswitch: ["8M", "7T"],
			astonish: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			captivate: ["4M"],
			clearsmog: ["9E", "8E", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L1", "8L1", "8V", "7L19", "7V", "6L19", "5L19", "4L19", "3L21"],
			corrosivegas: ["8T"],
			curse: ["9L20", "8L20", "7L12", "7V", "6L12", "5L12", "4L12", "3L13"],
			darkpulse: ["9M", "9L36", "8M", "8L36", "8V", "7M", "7L36", "6M", "6L36", "5T", "5L36", "4M", "4L36"],
			dazzlinggleam: ["9M", "8M", "8V", "7M", "6M"],
			destinybond: ["9L44", "8L44", "7L40", "7V", "6L40", "5L40", "4L40", "3L33"],
			disable: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["9L48", "8L48", "8V", "7M", "7L33", "7V", "6M", "6L33", "5M", "5L33", "4M", "4L33", "3T", "3L28"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "8V", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			foulplay: ["9M", "8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			grudge: ["8E", "7E", "6E", "5E", "4E", "3E"],
			gunkshot: ["9M"],
			haze: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V"],
			hex: ["9M", "9L24", "8M", "8L24", "7L43", "6L43", "5L43"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["9L4", "8L4", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			icepunch: ["9M", "8M", "8V", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lick: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			meanlook: ["9L8", "8L8", "7L8", "7V", "6L8", "5L8", "4L8", "3L13"],
			megadrain: ["8V", "7V"],
			mimic: ["7V", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightmare: ["7L47", "7V", "6L47", "5L47", "4L43", "3T", "3L41"],
			nightshade: ["9M", "9L28", "8L28", "8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L16"],
			ominouswind: ["4T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["9L12", "8M", "8L12", "7M", "7L26", "6M", "6L26", "5M", "5L26", "4M", "4L26"],
			perishsong: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			poisongas: ["8V"],
			poisonjab: ["9M", "8M"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psywave: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflecttype: ["9E", "8E", "7E", "6E"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M", "7E", "6E", "5E"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			shadowball: ["9M", "9L40", "8M", "8L40", "8V", "7M", "7L29", "7V", "6M", "6L29", "5M", "5L29", "4M", "4L29", "3M", "3L36"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "5D"],
			smog: ["9E", "8E", "8V", "7E", "6E", "5E"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["9L16", "8L16", "7T", "7L5", "7V", "6T", "6L5", "5T", "5L5", "4T", "4L5", "3L8"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L32", "8L32", "8V", "7L22", "6L22", "5L22", "4T", "4L22"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			taunt: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "8V", "7V"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["9E", "8E", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venoshock: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "4E", "3E"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 1, level: 18},
		],
	},
	haunter: {
		learnset: {
			acidspray: ["9M"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L1", "8L1", "8V", "7L19", "7V", "6L19", "5L19", "5S0", "4L19", "3L21"],
			corrosivegas: ["8T"],
			curse: ["9L20", "8L20", "7L12", "7V", "6L12", "5L12", "4L12", "3L13"],
			darkpulse: ["9M", "9L42", "8M", "8L42", "8V", "7M", "7L44", "6M", "6L44", "5T", "5L44", "4M", "4L44"],
			dazzlinggleam: ["9M", "8M", "8V", "7M", "6M"],
			destinybond: ["9L54", "8L54", "7L50", "7V", "6L50", "5L50", "4L50", "3L39"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["9L60", "8L60", "8V", "7M", "7L39", "7V", "6M", "6L39", "5M", "5L39", "4M", "4L39", "3T", "3L31"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M"],
			foulplay: ["9M", "8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gunkshot: ["9M"],
			headbutt: ["8V"],
			hex: ["9M", "9L24", "8M", "8L24", "7L55", "6L55", "5L55"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			icepunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lick: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			meanlook: ["9L1", "8L1", "7L8", "7V", "6L8", "5L8", "4L8", "3L13"],
			megadrain: ["8V", "7V"],
			metronome: ["9M"],
			mimic: ["7V", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightmare: ["7L61", "7V", "6L61", "5L61", "4L55", "3T", "3L53"],
			nightshade: ["9M", "9L30", "8L30", "8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L16"],
			ominouswind: ["4T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["9L12", "8M", "8L12", "7M", "7L28", "6M", "6L28", "5M", "5L28", "5S0", "4M", "4L28"],
			phantomforce: ["9M"],
			poisongas: ["8V"],
			poisonjab: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			shadowball: ["9M", "9L48", "8M", "8L48", "8V", "7M", "7L33", "7V", "6M", "6L33", "5M", "5L33", "4M", "4L33", "3M", "3L45"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowpunch: ["9L0", "8L0", "7L1", "6L25", "5L25", "5S0", "4L25", "3L25"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M"],
			smog: ["8V"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["9L16", "8L16", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L36", "8L36", "8V", "7L22", "6L22", "5L22", "5S0", "4T", "4L22"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			taunt: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "8V", "7V"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venoshock: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 5, level: 30, moves: ["confuseray", "suckerpunch", "shadowpunch", "payback"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 20},
			{generation: 2, level: 15},
			{generation: 3, level: 20},
			{generation: 4, level: 16},
		],
	},
	gengar: {
		learnset: {
			acidspray: ["9M"],
			allyswitch: ["8M", "7T"],
			astonish: ["6S4"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L1", "8L1", "8V", "7L19", "7V", "6L19", "6S1", "6S2", "6S4", "5L19", "4L19", "3L21", "3S0"],
			corrosivegas: ["8T"],
			counter: ["7V", "3T"],
			curse: ["9L20", "8L20", "7L12", "7V", "6L12", "5L12", "4L12", "3L13", "3S0"],
			darkpulse: ["9M", "9L42", "8M", "8L42", "8V", "7M", "7L44", "6M", "6L44", "5T", "5L44", "4M", "4L44"],
			dazzlinggleam: ["9M", "8M", "8V", "8S7", "7M", "6M"],
			destinybond: ["9L54", "8L54", "7L50", "7V", "6L50", "6S3", "5L50", "4L50", "3L39"],
			disable: ["8V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["9L60", "8L60", "8V", "7M", "7L39", "7V", "6M", "6L39", "5M", "5L39", "4M", "4L39", "3T", "3L31"],
			dynamicpunch: ["7V", "3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gunkshot: ["9M"],
			haze: ["8V"],
			headbutt: ["8V", "7V", "4T"],
			hex: ["9M", "9L24", "8M", "8L24", "7L55", "6L55", "5L55"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "6S5", "6S6", "5M", "4M", "3M"],
			hypnosis: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "6S5", "6S6", "5L1", "4L1", "3L1"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M", "8M"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lick: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			meanlook: ["9L1", "8L1", "7L8", "7V", "6L8", "6S5", "6S6", "5L8", "4L8", "3L13"],
			megadrain: ["8V", "7V"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["9M", "8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			nastyplot: ["9M", "8M"],
			naturalgift: ["4M"],
			nightmare: ["7L61", "7V", "6L61", "5L61", "4L55", "3T", "3L53"],
			nightshade: ["9M", "9L30", "8L30", "8V", "7L15", "7V", "6L15", "6S2", "5L15", "4L15", "3L16", "3S0"],
			ominouswind: ["4T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["9L12", "8M", "8L12", "7M", "7L28", "6M", "6L28", "5M", "5L28", "4M", "4L28"],
			perishsong: ["9L1", "8L1"],
			phantomforce: ["9M", "8M"],
			poisongas: ["8V"],
			poisonjab: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			poltergeist: ["8T"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "8V", "7M", "7V", "6M", "6S1", "6S5", "6S6", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflecttype: ["9L1", "8L1"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			shadowball: ["9M", "9L48", "8M", "8L48", "8V", "8S7", "7M", "7L33", "7V", "6M", "6L33", "6S3", "6S4", "5M", "5L33", "4M", "4L33", "3M", "3L45"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowpunch: ["9L1", "8L1", "7L1", "6L25", "6S1", "6S2", "5L25", "4L25", "3L25"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			skittersmack: ["8T"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8M", "8V", "8S7", "7M", "6M", "6S3", "5M", "4M", "3M"],
			sludgewave: ["8M", "6S4"],
			smog: ["8V"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["9L16", "8L16", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1", "3S0"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L36", "8L36", "8V", "7L22", "6L22", "6S1", "6S2", "5L22", "4T", "4L22"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			taunt: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venoshock: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "8V", "8S7", "7M", "6M", "6S3", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 23, gender: "F", nature: "Hardy", ivs: {hp: 19, atk: 14, def: 0, spa: 14, spd: 17, spe: 27}, moves: ["spite", "curse", "nightshade", "confuseray"], pokeball: "pokeball"},
			{generation: 6, level: 25, nature: "Timid", moves: ["psychic", "confuseray", "suckerpunch", "shadowpunch"], pokeball: "cherishball"},
			{generation: 6, level: 25, moves: ["nightshade", "confuseray", "suckerpunch", "shadowpunch"], pokeball: "cherishball"},
			{generation: 6, level: 50, moves: ["shadowball", "sludgebomb", "willowisp", "destinybond"], pokeball: "cherishball"},
			{generation: 6, level: 25, shiny: true, moves: ["shadowball", "sludgewave", "confuseray", "astonish"], pokeball: "duskball"},
			{generation: 6, level: 50, shiny: true, gender: "M", moves: ["meanlook", "hypnosis", "psychic", "hyperbeam"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["meanlook", "hypnosis", "psychic", "hyperbeam"], pokeball: "cherishball"},
			{generation: 8, level: 80, gender: "M", nature: "Naughty", abilities: ["cursedbody"], ivs: {hp: 30, atk: 30, def: 30, spa: 31, spd: 31, spe: 31}, moves: ["shadowball", "sludgebomb", "dazzlinggleam", "willowisp"], pokeball: "pokeball"},
		],
	},
	onix: {
		learnset: {
			ancientpower: ["4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["8L1", "8V", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "5D", "4L1", "3L8"],
			block: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			bodypress: ["8M"],
			bodyslam: ["8M", "7V", "3T"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["8L16", "7L4", "7V", "6L4", "5L46", "4L38"],
			defensecurl: ["8E", "7E", "6E", "5E", "4E"],
			dig: ["8M", "8L44", "8V", "7L43", "7V", "6M", "6L43", "5M", "5L43", "4M", "3M"],
			doubleedge: ["8L56", "8V", "7L49", "7V", "6L49", "5L57", "4L46", "3T", "3L56"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["8L12", "7L25", "6L25", "5L41", "4L33", "3L30"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8V", "7T", "6T", "5T", "4M"],
			dragontail: ["8E", "8V", "7M", "6M", "5M"],
			drillrun: ["8M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			flashcannon: ["8M", "8V", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gyroball: ["8M", "7M", "7L20", "6M", "6L20", "5M", "4M"],
			harden: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L19"],
			headbutt: ["8V", "7V", "4T"],
			headsmash: ["8E"],
			heavyslam: ["8M", "7E", "6E", "5E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "8L48", "8V", "7T", "7L40", "7V", "6T", "6L40", "5T", "5L49", "4M", "4L38", "3M", "3L45"],
			meteorbeam: ["8T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			rage: ["8V", "7L13", "7V", "6L13", "5L14", "4L14", "3L23"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "7E", "6E", "5E", "4E"],
			rockclimb: ["7E", "6E", "5E", "5D", "4M"],
			rockpolish: ["8L8", "7M", "7L19", "6M", "6L19", "5M", "5L30", "4M", "4L30"],
			rockslide: ["8M", "8L20", "8V", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L34", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8L1", "8V", "7L7", "7V", "6L7", "5L9", "4L9", "3L12"],
			rocktomb: ["8M", "7M", "7L10", "6M", "6L10", "5M", "5L17", "4M", "4L17", "3M"],
			rollout: ["8E", "7E", "6E", "5E", "4T", "4E"],
			rototiller: ["7E", "6E"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "8L40", "7M", "7L52", "7V", "6M", "6L52", "5M", "5L25", "4M", "4L22", "3M", "3L33"],
			sandtomb: ["8M", "8L28", "7L37", "6L37", "5L54", "4L41", "3L49"],
			scaryface: ["8M"],
			scorchingsands: ["8T"],
			screech: ["8M", "8L24", "8V", "7L31", "7V", "6L31", "5L6", "4L6", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			skullbash: ["7V"],
			slam: ["8L36", "8V", "7L28", "7V", "6L28", "5L33", "4L25", "3L37"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["8L4", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8L32", "8V", "7T", "7L16", "7E", "6T", "6L16", "6E", "5T", "5L38", "5E", "5D", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L52", "7M", "7L46", "6M", "6L46", "5M", "5L62", "4M", "4L49"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			wideguard: ["8E", "7E"],
		},
		encounters: [
			{generation: 1, level: 13},
		],
	},
	steelix: {
		learnset: {
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			autotomize: ["8L8", "7L19", "6L19", "5L30"],
			bind: ["8L1", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4L1", "3L8"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L1", "7L37", "7V", "6L37", "5L54", "4L41", "3L49"],
			curse: ["8L16", "7L4", "7V", "6L4", "5L46", "4L38"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			defensecurl: ["7V", "3T"],
			dig: ["8M", "8L44", "7L43", "7V", "6M", "6L43", "5M", "5L43", "4M", "3M"],
			doubleedge: ["8L56", "7L49", "6L49", "5L57", "4L46", "3T", "3L56"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["8L12", "7L25", "7V", "6L25", "5L41", "4L33", "3L30"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T", "6T", "5T", "4M"],
			dragontail: ["7M", "6M", "5M"],
			drillrun: ["8M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "7M", "7L20", "6M", "6L20", "5M", "4M"],
			harden: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L19"],
			headbutt: ["7V", "4T"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icefang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			irondefense: ["8M"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "8L48", "7T", "7L40", "7V", "6T", "6L40", "5T", "5L49", "4M", "4L38", "3M", "3L45"],
			magnetrise: ["8L60", "7T", "6T", "5T", "4T"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychicfangs: ["8M"],
			psychup: ["7M", "6M", "5M", "4M"],
			rage: ["7L13", "7V", "6L13", "5L14", "4L14", "3L23"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockclimb: ["4M"],
			rockpolish: ["8L1", "7M", "6M", "5M", "4M", "4L30"],
			rockslide: ["8M", "8L20", "7M", "7L34", "6M", "6L34", "5M", "5L34", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8L1", "7L7", "7V", "6L7", "5L9", "4L9", "3L12"],
			rocktomb: ["8M", "7M", "7L10", "6M", "6L10", "5M", "5L17", "4M", "4L17", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "8L40", "7M", "7L52", "7V", "6M", "6L52", "5M", "5L25", "4M", "4L22", "3M", "3L33"],
			sandtomb: ["8M", "8L28"],
			scaryface: ["8M"],
			scorchingsands: ["8T"],
			screech: ["8M", "8L24", "7L31", "7V", "6L31", "5L6", "4L6", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			slam: ["8L36", "7L28", "7V", "6L28", "5L33", "4L25", "3L37"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["8L4", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8L32", "7T", "7L16", "6T", "6L16", "5T", "5L38", "4M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L52", "7M", "7L46", "6M", "6L46", "5M", "5L62", "4M", "4L49"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderfang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
		},
	},
	drowzee: {
		learnset: {
			allyswitch: ["7T"],
			assist: ["7E", "6E", "5E", "4E", "3E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bellydrum: ["3S0"],
			bide: ["7V"],
			bodyslam: ["9M", "7V", "3T"],
			brickbreak: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			calmmind: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confusion: ["9L9", "8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L11"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "8V", "7M", "6M"],
			disable: ["9L5", "8V", "7L5", "7V", "6L5", "5L5", "4L7", "3L7"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M"],
			drainpunch: ["9M", "7T", "6T", "5T", "5D", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			encore: ["9M"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "9E", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			flatter: ["9E", "7E", "6E", "5E", "4E"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["9L49", "7L61", "7V", "6L61", "5L61", "4L53", "3L45"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			guardswap: ["9E", "7E", "6E", "5E", "4E"],
			headbutt: ["9L13", "8V", "7L13", "7V", "6L13", "5L13", "4T", "4L15", "3L17"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			icepunch: ["9M", "9E", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			imprison: ["9M"],
			lightscreen: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["7T", "6T", "5T"],
			meditate: ["8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L27"],
			megakick: ["7V", "3T"],
			megapunch: ["7V", "3T"],
			metronome: ["9M", "7V", "3T"],
			mimic: ["7V", "3T"],
			nastyplot: ["9M", "9L41", "8V", "7L53", "7E", "6L53", "6E", "5L53", "5E", "4L43", "4E"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["9M"],
			poisongas: ["9L17", "8V", "7L17", "7V", "6L17", "5L17", "4L18", "3L21"],
			pound: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			powersplit: ["9E", "7E"],
			poweruppunch: ["6M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L21", "8V", "7L25", "6L25", "5L25", "4L26"],
			psychic: ["9M", "9L37", "8V", "7M", "7L49", "7V", "6M", "6L49", "5M", "5L49", "4M", "4L40", "3M", "3L31"],
			psychicterrain: ["9M", "7E"],
			psychocut: ["9E", "7E", "6E", "5E", "5D", "4E"],
			psychup: ["9L25", "7M", "7L33", "7V", "6M", "6L33", "5M", "5L33", "4M", "4L29", "3T", "3L37"],
			psyshock: ["9M", "9L45", "7M", "7L57", "6M", "6L57", "5M", "5L57"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "7V", "6T", "3T"],
			storedpower: ["9M"],
			submission: ["7V"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["9L33", "7M", "7L45", "7V", "6M", "6L45", "5M", "5L45", "4M", "4L37", "3T", "3L41"],
			swift: ["9M"],
			synchronoise: ["7L37", "6L37", "5L37"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "9E", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			thunderwave: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			triattack: ["8V", "7V"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			wakeupslap: ["7L29"],
			wish: ["3S0"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "9L29", "7T", "7L41", "6T", "6L41", "5T", "5L41", "4T", "4L50"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, abilities: ["insomnia"], moves: ["bellydrum", "wish"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 9},
		],
	},
	hypno: {
		learnset: {
			allyswitch: ["7T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["8V"],
			batonpass: ["9M", "3S0"],
			bide: ["7V"],
			bodypress: ["9M"],
			bodyslam: ["9M", "7V", "3T"],
			brickbreak: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			calmmind: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "8V", "7M", "6M"],
			disable: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M"],
			drainpunch: ["9M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			encore: ["9M"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["9L56", "7L1", "7V", "6L1", "5L61", "4L69", "3L57"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			headbutt: ["9L13", "8V", "7L13", "7V", "6L13", "5L13", "4T", "4L15", "3L17"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			icepunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["9M"],
			lightscreen: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["7T", "6T", "5T"],
			meditate: ["8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L29", "3S0"],
			megakick: ["7V", "3T"],
			megapunch: ["7V", "3T"],
			metronome: ["9M", "7V", "3T"],
			mimic: ["7V", "3T"],
			nastyplot: ["9M", "9L47", "8V", "7L1", "6L1", "5L53", "4L55"],
			naturalgift: ["4M"],
			nightmare: ["7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			nightshade: ["9M"],
			poisongas: ["9L17", "8V", "7L17", "7V", "6L17", "5L17", "4L18", "3L21"],
			pound: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L21", "8V", "7L25", "6L25", "5L25", "4L28"],
			psychic: ["9M", "9L42", "8V", "7M", "7L49", "7V", "6M", "6L49", "5M", "5L49", "4M", "4L50", "3M", "3L35", "3S0"],
			psychicterrain: ["9M"],
			psychup: ["9L25", "7M", "7L33", "7V", "6M", "6L33", "5M", "5L33", "4M", "4L33", "3T", "3L43"],
			psyshock: ["9M", "9L51", "7M", "7L57", "6M", "6L57", "5M", "5L57"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "7T", "6T", "5T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "7V", "6T", "3T"],
			storedpower: ["9M"],
			submission: ["7V"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["9L37", "7M", "7L45", "7V", "6M", "6L45", "5M", "5L45", "4M", "4L45", "3T", "3L49"],
			swift: ["9M"],
			switcheroo: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			synchronoise: ["7L37", "6L37", "5L37"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			triattack: ["8V", "7V"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			wakeupslap: ["7L29"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "9L32", "7T", "7L41", "6T", "6L41", "5T", "5L41", "4T", "4L64"],
		},
		eventData: [
			{generation: 3, level: 34, abilities: ["insomnia"], moves: ["batonpass", "psychic", "meditate", "shadowball"]},
		],
		encounters: [
			{generation: 2, level: 16},
			{generation: 4, level: 16},
		],
	},
	krabby: {
		learnset: {
			agility: ["8M", "7E", "6E", "5E", "4E"],
			allyswitch: ["8M", "7T", "7E", "6E"],
			amnesia: ["8M", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			ancientpower: ["8E", "7E", "6E", "5E", "4T", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7E", "7V", "6E", "5E"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M"],
			brine: ["8M", "7L39", "6L39", "5L39", "4M", "4L39"],
			bubble: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bubblebeam: ["8L20", "8V", "7L15", "7V", "6L15", "5L15", "4L15"],
			captivate: ["4M"],
			chipaway: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			crabhammer: ["8L44", "8V", "7L41", "7V", "6L41", "5L41", "4L41", "3L45"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "4E", "3M", "3E"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			flail: ["8L29", "7L45", "7E", "7V", "6L45", "6E", "5L45", "5E", "4L45", "4E", "3L49", "3E"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			guillotine: ["8L48", "8V", "7L31", "7V", "6L31", "5L31", "4L31", "3L34"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hammerarm: ["8E"],
			harden: ["8L4", "8V", "7L11", "7V", "6L11", "5L11", "4L11", "3L16"],
			haze: ["7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			leer: ["8L1", "8V", "7L9", "7V", "6L9", "5L9", "4L9", "3L5"],
			liquidation: ["8M", "7T"],
			metalclaw: ["8L8", "7L21", "6L21", "5L21", "4L21"],
			mimic: ["7V", "3T"],
			mudshot: ["8M", "8L12", "7L19", "6L19", "5L19", "4L19", "3L23"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			nightslash: ["8E"],
			protect: ["8M", "8L16", "8V", "7M", "7L29", "7V", "6M", "6L29", "5M", "5L29", "4M", "4L29", "3M", "3L38"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorshell: ["8M", "8L32"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "5D", "4M", "3M"],
			slam: ["8L36", "8V", "7L35", "7E", "7V", "6L35", "6E", "5L35", "5E", "4L35", "4E", "3E"],
			slash: ["8E"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stomp: ["8L24", "8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L27"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "8L40", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			tickle: ["7E", "6E", "5E", "4E"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			visegrip: ["8V", "7L5", "7V", "6L5", "5L5", "5D", "4L5", "3L12"],
			watergun: ["8L1", "7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			xscissor: ["8M", "8V", "7M", "6M", "5M", "4M"],
		},
		encounters: [
			{generation: 1, level: 10},
		],
	},
	kingler: {
		learnset: {
			agility: ["8M", "8V"],
			allyswitch: ["8M", "7T"],
			amnesia: ["8M", "8V"],
			ancientpower: ["4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M"],
			brine: ["8M", "7L51", "6L51", "5L51", "4M", "4L51"],
			brutalswing: ["8M"],
			bubble: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bubblebeam: ["8L20", "8V", "7L15", "7V", "6L15", "5L15", "4L15"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crabhammer: ["8L54", "8V", "7L56", "7V", "6L56", "5L56", "4L56", "3L57"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			flail: ["8L31", "7L63", "6L63", "5L63", "4L63", "3L65"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			guillotine: ["8L60", "8V", "7L37", "7V", "6L37", "5L37", "4L37", "3L38"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hammerarm: ["8L1"],
			harden: ["8L1", "8V", "7L11", "7V", "6L11", "5L11", "4L11", "3L1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L9", "3L1"],
			liquidation: ["8M", "7T"],
			metalclaw: ["8L1", "7L21", "6L21", "5L21", "4L21", "3L1"],
			mimic: ["7V", "3T"],
			mudshot: ["8M", "8L12", "7L19", "6L19", "5L19", "4L19", "3L23"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			protect: ["8M", "8L16", "8V", "7M", "7L32", "7V", "6M", "6L32", "5M", "5L32", "4M", "4L32", "3M", "3L42"],
			quash: ["7M", "6M", "5M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorshell: ["8M", "8L36"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			slam: ["8L42", "8V", "7L44", "6L44", "5L44", "4L44"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stomp: ["8L24", "8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L27"],
			stompingtantrum: ["8M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "8L48", "7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			visegrip: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			watergun: ["8L1", "7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			wideguard: ["8L1", "7L1", "6L1", "5L1"],
			xscissor: ["8M", "8V", "7M", "6M", "5M", "4M"],
		},
		encounters: [
			{generation: 1, level: 15},
			{generation: 3, level: 25},
			{generation: 4, level: 22},
		],
	},
	voltorb: {
		learnset: {
			agility: ["9M"],
			bide: ["7V"],
			charge: ["9L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			chargebeam: ["9M", "9L16", "7M", "7L16", "6M", "6L16", "5M", "5L22", "4M", "4L26"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			discharge: ["9L37", "7L37", "6L37"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["9M", "9L6", "7L6", "6L6"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L22", "7L22", "6L22", "5L29"],
			endure: ["9M", "7V", "4M", "3T"],
			explosion: ["9L41", "8V", "7M", "7L41", "7V", "6M", "6L41", "5M", "5L47", "4M", "4L43", "3T", "3L46"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foulplay: ["9M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gyroball: ["9L46", "7M", "7L46", "6M", "6L43", "5M", "5L43", "4M", "4L40"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			lightscreen: ["9M", "9L29", "8V", "7M", "7L29", "7V", "6M", "6L26", "5M", "5L26", "4M", "4L22", "3M", "3L37"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magnetrise: ["9L34", "7T", "7L34", "6T", "6L34", "5T", "5L40", "4T", "4L36"],
			metalsound: ["9E"],
			mimic: ["7V", "3T"],
			mirrorcoat: ["9L50", "8V", "7L48", "7V", "6L48", "5L50", "4L47", "3L49", "3S0"],
			naturalgift: ["5D", "4M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["9E"],
			reflect: ["8V", "7V"],
			refresh: ["3S0"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["9L11", "7L11", "7V", "6L11", "5L15", "4T", "4L15", "3T", "3L32"],
			round: ["7M", "6M", "5M"],
			screech: ["9L13", "8V", "7L13", "7V", "6L13", "5L19", "4L19", "3L8"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["9L26", "8V", "7L26", "7V", "6L26", "5L33", "4L29", "3T", "3L27"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "5D", "4T"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			sonicboom: ["8V", "7L4", "7V", "6L4", "5L8", "4L8", "3L15"],
			spark: ["9L9", "7L9", "6L9", "5L12", "4L12", "3L21", "3S0"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L20", "8V", "7L20", "7V", "6L20", "5L36", "4T", "4L33", "3T", "3L42", "3S0"],
			tackle: ["9L1", "8V", "7L1", "7V", "6L1", "5L5", "4L5", "3L1"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thundershock: ["9L4", "8V"],
			thunderwave: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			voltswitch: ["9M", "7M", "6M", "5M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 19, moves: ["refresh", "mirrorcoat", "spark", "swift"]},
		],
		encounters: [
			{generation: 1, level: 14},
			{generation: 1, level: 40},
		],
	},
	voltorbhisui: {
		learnset: {
			agility: ["9M"],
			bulletseed: ["9M", "9L9"],
			charge: ["9L1"],
			chargebeam: ["9M", "9L16"],
			discharge: ["9L34"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L22"],
			endure: ["9M"],
			energyball: ["9M", "9L29"],
			explosion: ["9L41"],
			facade: ["9M"],
			foulplay: ["9M"],
			gigadrain: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M", "9L50"],
			gyroball: ["9L46"],
			leafstorm: ["9M"],
			leechseed: ["9E"],
			protect: ["9M"],
			raindance: ["9M"],
			recycle: ["9E"],
			reflect: ["9M"],
			rest: ["9M"],
			rollout: ["9L11"],
			screech: ["9L13"],
			seedbomb: ["9M", "9L34"],
			selfdestruct: ["9L26"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			stunspore: ["9L6"],
			substitute: ["9M"],
			swift: ["9M", "9L20"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thundershock: ["9L4"],
			thunderwave: ["9M"],
			voltswitch: ["9M"],
			wildcharge: ["9M"],
			worryseed: ["9E"],
		},
	},
	electrode: {
		learnset: {
			agility: ["9M"],
			bide: ["7V"],
			charge: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			chargebeam: ["9M", "9L16", "7M", "7L16", "6M", "6L16", "5M", "5L22", "4M", "4L26"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			discharge: ["9L41", "7L41", "6L41"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["9M", "9L1", "7L1", "6L6"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L22", "7L22", "6L22", "5L29"],
			endure: ["9M", "7V", "4M", "3T"],
			explosion: ["9L47", "8V", "7M", "7L47", "7V", "6M", "6L47", "5M", "5L57", "4M", "4L51", "3T", "3L54"],
			facade: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foulplay: ["9M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gyroball: ["9L54", "7M", "7L54", "6M", "6L51", "5M", "5L51", "4M", "4L46"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			lightscreen: ["9M", "9L29", "8V", "7M", "7L29", "7V", "6M", "6L26", "5M", "5L26", "4M", "4L22", "3M", "3L41"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magneticflux: ["9L1", "7L1", "6L1"],
			magnetrise: ["9L36", "7T", "7L36", "6T", "6L36", "5T", "5L46", "4T", "4L40"],
			mimic: ["7V", "3T"],
			mirrorcoat: ["9L58", "8V", "7L58", "7V", "6L58", "5L62", "4L57", "3L59"],
			naturalgift: ["4M"],
			protect: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["9L11", "7L11", "7V", "6L11", "5L15", "4T", "4L15", "3T", "3L34"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["9L13", "8V", "7L13", "7V", "6L13", "5L19", "4L19", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["9L26", "8V", "7L26", "7V", "6L26", "5L35", "4L29", "3T", "3L27"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			sonicboom: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			spark: ["9L9", "7L9", "6L1", "5L1", "4L1", "3L21"],
			substitute: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L20", "8V", "7L20", "7V", "6L20", "5L40", "4T", "4L35", "3T", "3L48"],
			tackle: ["9L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thundershock: ["9L1", "8V"],
			thunderwave: ["9M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			voltswitch: ["9M", "7M", "6M", "5M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 1, level: 3},
			{generation: 2, level: 23},
			{generation: 3, level: 3, nature: "Hasty", ivs: {hp: 19, atk: 16, def: 18, spa: 25, spd: 25, spe: 19}, abilities: ["static"], pokeball: "pokeball"},
			{generation: 4, level: 23},
		],
	},
	electrodehisui: {
		learnset: {
			agility: ["9M"],
			bulletseed: ["9M", "9L9"],
			charge: ["9L1"],
			chargebeam: ["9M", "9L16"],
			chloroblast: ["9L0"],
			discharge: ["9L34"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L22"],
			endure: ["9M"],
			energyball: ["9M", "9L29"],
			explosion: ["9L41"],
			facade: ["9M"],
			foulplay: ["9M"],
			gigadrain: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M", "9L50"],
			gyroball: ["9L46"],
			hyperbeam: ["9M"],
			leafstorm: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			rollout: ["9L11"],
			scaryface: ["9M"],
			screech: ["9L13"],
			seedbomb: ["9M", "9L34"],
			selfdestruct: ["9L26"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			stunspore: ["9L6"],
			substitute: ["9M"],
			swift: ["9M", "9L20"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thundershock: ["9L4"],
			thunderwave: ["9M"],
			voltswitch: ["9M"],
			wildcharge: ["9M"],
		},
	},
	exeggcute: {
		learnset: {
			absorb: ["8L1"],
			ancientpower: ["8E", "7E", "7V", "6E", "5E", "4T", "4E", "3E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrage: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			bestow: ["7L50", "6L50", "5L53"],
			bide: ["7V"],
			block: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			bulletseed: ["8M", "8L30", "7L17", "6L17", "5L17", "4M", "4L17", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confusion: ["8L20", "8V", "7L27", "7V", "6L27", "5L27", "4L27", "3L19"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			eggbomb: ["7V"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "3T"],
			extrasensory: ["8L40", "7L47", "6L47", "5L47"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "8L35", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "5D", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7E", "6E"],
			gravity: ["7T", "6T", "5T", "4T"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			infestation: ["7M", "6M"],
			ingrain: ["8E", "7E", "6E", "5E", "4E", "3E"],
			leafstorm: ["8M", "7E", "6E", "5E", "4E"],
			leechseed: ["8L10", "8V", "7L11", "7V", "6L11", "5L11", "4L11", "3L13"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7E", "6E", "5E", "4E"],
			megadrain: ["8L15", "8V", "7V"],
			mimic: ["7V", "3T"],
			moonlight: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			naturalgift: ["7L37", "7E", "6L37", "6E", "5L37", "5E", "4M", "4L37"],
			naturepower: ["7M", "7E", "6M", "6E", "5E", "4E"],
			nightmare: ["7V", "3T"],
			poisonpowder: ["8E", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L31"],
			powerswap: ["8M", "7E", "6E", "5E", "4E"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8V"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "5L47", "4M", "4L47", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			psywave: ["7V"],
			rage: ["7V"],
			reflect: ["8M", "8L5", "8V", "7M", "7L7", "7V", "6M", "6L7", "5M", "5L7", "4M", "4L7", "4E", "3M", "3L7", "3E"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			skillswap: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			sleeppowder: ["8E", "8V", "7L23", "7V", "6L23", "5L23", "4L23", "3L37"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8L55", "8V", "7M", "7L43", "7V", "6M", "6L43", "5M", "5L43", "4M", "4L43", "3M", "3L43"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			stunspore: ["8E", "8V", "7L19", "7V", "6L19", "5L19", "4L19", "3L25"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["3S0"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["8L25", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			takedown: ["7V"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "8L45", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			wish: ["3S0"],
			worryseed: ["8L50", "7T", "7L33", "6T", "6L33", "5T", "5L33", "4T", "4L33"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["sweetscent", "wish"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 20},
		],
	},
	exeggutor: {
		learnset: {
			absorb: ["8L1"],
			ancientpower: ["4T", "3S0"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrage: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bide: ["7V"],
			block: ["7T", "6T", "5T"],
			bulldoze: ["8M"],
			bulletseed: ["8M", "8L1", "4M", "3M"],
			calmmind: ["8M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			curse: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			eggbomb: ["8V", "7L27", "7V", "6L27", "5L27", "4L27", "3L31"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "3T"],
			extrasensory: ["8L1"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M"],
			gigadrain: ["8M", "8L1", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			gravity: ["7T", "6T", "5T", "4T"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			infestation: ["7M", "6M"],
			leafstorm: ["8M", "8L1", "7L47", "6L47", "5L47", "4L47"],
			leechseed: ["8L1"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			magicalleaf: ["8M"],
			megadrain: ["8L1", "8V", "7V"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			nightmare: ["7V", "3T"],
			powerswap: ["8M"],
			powerwhip: ["8V"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			psychocut: ["8M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "8L1", "7M", "7L17", "6M", "6L17", "5M", "5L17"],
			psywave: ["7V"],
			rage: ["7V"],
			reflect: ["8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			stomp: ["8L0", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L19"],
			stompingtantrum: ["8M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			stunspore: ["8V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["8L1", "7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			terrainpulse: ["8T"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "8L1"],
			woodhammer: ["8L1", "7L37", "6L37", "5L37", "4L37"],
			worryseed: ["8L1", "7T", "6T", "5T", "4T"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 46, moves: ["refresh", "psychic", "hypnosis", "ancientpower"]},
		],
	},
	exeggutoralola: {
		learnset: {
			absorb: ["8L1"],
			attract: ["8M", "7M"],
			barrage: ["8V", "7L1"],
			block: ["7T"],
			breakingswipe: ["8M"],
			brickbreak: ["8M", "8V", "7M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			bulletseed: ["8M", "8L1"],
			celebrate: ["7S0"],
			confide: ["7M"],
			confusion: ["8L1", "8V", "7L1"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T", "7S0"],
			dragonhammer: ["8L0", "7L1"],
			dragonpulse: ["8M", "8V", "7T"],
			dragontail: ["8V", "7M"],
			dreameater: ["8V", "7M"],
			earthquake: ["8M", "8V", "7M"],
			eggbomb: ["8V", "7L27"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			explosion: ["7M"],
			extrasensory: ["8L1"],
			facade: ["8M", "8V", "7M"],
			flamethrower: ["8M", "8V", "7M"],
			frustration: ["7M"],
			gigadrain: ["8M", "8L1", "7T"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			gravity: ["7T"],
			headbutt: ["8V"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "8V", "7M"],
			hypnosis: ["8L1", "8V", "7L1"],
			infestation: ["7M"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "8V", "7T"],
			knockoff: ["7T"],
			leafstorm: ["8M", "8L1", "7L47", "7S0"],
			leechseed: ["8L1"],
			lightscreen: ["8M", "8V", "7M"],
			lowkick: ["8M", "7T"],
			magicalleaf: ["8M"],
			megadrain: ["8L1", "8V"],
			naturepower: ["7M"],
			outrage: ["8M", "8V", "7T"],
			powerswap: ["8M", "7S0"],
			powerwhip: ["8M", "8V"],
			protect: ["8M", "8V", "7M"],
			psychic: ["8M", "8V", "7M"],
			psychup: ["7M"],
			psyshock: ["8M", "8L1", "7M", "7L17"],
			reflect: ["8M", "8L1", "8V", "7M"],
			rest: ["8M", "8V", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			seedbomb: ["8M", "8L1", "7T", "7L1"],
			selfdestruct: ["8M", "8V"],
			skillswap: ["8M", "7T"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "8V", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "8L1", "8V", "7M"],
			stompingtantrum: ["8M", "7T"],
			stunspore: ["8V"],
			substitute: ["8M", "8V", "7M"],
			sunnyday: ["8M", "7M"],
			superpower: ["8M", "8V", "7T"],
			swagger: ["7M"],
			swordsdance: ["8M", "7M"],
			synthesis: ["8L1", "7T"],
			telekinesis: ["7T"],
			teleport: ["8V"],
			terrainpulse: ["8T"],
			thief: ["8M", "7M"],
			toxic: ["8V", "7M"],
			trickroom: ["8M", "7M"],
			uproar: ["8M", "8L1"],
			woodhammer: ["8L1", "7L37"],
			worryseed: ["8L1", "7T"],
			zenheadbutt: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 50, gender: "M", nature: "Modest", isHidden: true, moves: ["powerswap", "celebrate", "leafstorm", "dracometeor"], pokeball: "cherishball"},
		],
	},
	cubone: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bellydrum: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			boneclub: ["8V", "7L7", "7V", "6L7", "5L7", "5D", "4L7", "3L9"],
			bonemerang: ["8L40", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L25"],
			bonerush: ["8L29", "7L51", "7V", "6L37", "5L37", "4L37", "3L41"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["8E", "7E", "7V"],
			detect: ["8E", "7E", "7V", "6E", "5E", "4E"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L48", "8V", "7L43", "7V", "6L43", "5L43", "4L43", "3T", "3L45"],
			doublekick: ["8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["8L36", "7T", "7L41", "6T", "6L41", "5T", "5L41", "4T", "4L41"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "8L8", "7M", "7L27", "7V", "6M", "6L27", "5M", "5L27", "4M", "4L27", "3L33"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "8L20", "7M", "7L33", "6M", "6L33", "5M", "5L33", "4M", "4L33"],
			focusenergy: ["8M", "8L32", "8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L21"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["4T"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8L12", "8V", "7L11", "7V", "6L11", "5L11", "4T", "4L11", "3L13"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			ironhead: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leer: ["8E", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L17"],
			lowkick: ["8M", "7T", "6T", "5T", "5D", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["8L1", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			perishsong: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["8V", "7L23", "7V", "6L23", "5L23", "4L23", "3L29"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "8L16", "7L47", "6M", "6L47", "5M", "5L47"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scorchingsands: ["8T"],
			screech: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			skullbash: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "8L24", "7L37"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			tailwhip: ["8L4", "8V", "7L3", "7V", "6L3", "5L3", "4L3", "3L5"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["8L44", "8V", "7L31", "7V", "6L31", "5L31", "4L31", "3L37"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			watergun: ["7V"],
		},
		encounters: [
			{generation: 1, level: 16},
		],
	},
	marowak: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			boneclub: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bonemerang: ["8L48", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L25"],
			bonerush: ["8L31", "7L65", "7V", "6L43", "5L43", "4L43", "3L53"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			detect: ["7V"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L60", "8V", "7L53", "7V", "6L53", "5L53", "4L53", "3T", "3L61"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["8L42", "7T", "7L49", "6T", "6L49", "5T", "5L49", "4T", "4L49"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "8L1", "7M", "7L27", "7V", "6M", "6L27", "5M", "5L27", "4M", "4L27", "3L39"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "8L20", "7M", "7L37", "6M", "6L37", "5M", "5L37", "4M", "4L37"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L36", "8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L21"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8L12", "8V", "7L1", "7V", "6L1", "5L1", "4T", "4L1", "3L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			ironhead: ["8M", "7T", "6T", "5T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			leer: ["8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L17"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["8L1", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["8V", "7L23", "7V", "6L23", "5L23", "4L23", "3L32"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "8L16", "7L59", "6M", "6L59", "5M", "5L59"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "3T", "3S0"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scorchingsands: ["8T"],
			screech: ["8M", "8V"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			sing: ["3S0"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "8L24", "7T", "7L43"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M"],
			swordsdance: ["8M", "8V", "7M", "6M", "5M", "4M", "3T", "3S0"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["8L54", "8V", "7L33", "7V", "6L33", "5L33", "4L33", "3L46"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			watergun: ["7V"],
		},
		eventData: [
			{generation: 3, level: 44, moves: ["sing", "earthquake", "swordsdance", "rockslide"]},
		],
		encounters: [
			{generation: 1, level: 24},
			{generation: 2, level: 12},
			{generation: 4, level: 14},
		],
	},
	marowakalola: {
		learnset: {
			aerialace: ["7M"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M"],
			blizzard: ["8M", "8V", "7M"],
			bodyslam: ["8M"],
			boneclub: ["8V", "7L1"],
			bonemerang: ["8L48", "8V", "7L21"],
			bonerush: ["8L31", "7L65"],
			brickbreak: ["8M", "8V", "7M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			burningjealousy: ["8T"],
			confide: ["7M"],
			darkpulse: ["8M", "8V", "7M"],
			dig: ["8M", "8V"],
			doubleedge: ["8L1"],
			doubleteam: ["7M"],
			dreameater: ["8V", "7M"],
			earthpower: ["8M", "7T"],
			earthquake: ["8M", "8V", "7M"],
			echoedvoice: ["7M"],
			endeavor: ["8L42", "7T", "7L49"],
			endure: ["8M"],
			facade: ["8M", "8V", "7M"],
			falseswipe: ["8M", "8L1", "7M"],
			fireblast: ["8M", "8V", "7M"],
			firepunch: ["8M", "8V", "7T"],
			firespin: ["8M", "8L1", "8V"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "8V", "7M"],
			flamewheel: ["8L12", "7L1"],
			flareblitz: ["8M", "8L60", "8V", "7L53"],
			fling: ["8M", "8L20", "7M", "7L37"],
			focusblast: ["8M", "7M"],
			focusenergy: ["8M", "8L1"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			growl: ["8L1", "8V", "7L1"],
			headbutt: ["8L1", "8V"],
			heatwave: ["8M", "7T"],
			hex: ["8M", "8L16", "7L17"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "8V", "7M"],
			icebeam: ["8M", "8V", "7M"],
			icywind: ["8M", "7T"],
			imprison: ["8M"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "8V", "7T"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			leer: ["8V", "7L13"],
			lowkick: ["8M", "7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudslap: ["8L1"],
			outrage: ["8M", "8V", "7T"],
			painsplit: ["7T"],
			poltergeist: ["8T"],
			protect: ["8M", "8V", "7M"],
			rage: ["8V"],
			raindance: ["8M", "7M"],
			rest: ["8M", "8V", "7M"],
			retaliate: ["8M", "8L1", "7L59"],
			return: ["7M"],
			rockslide: ["8M", "8V", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			scorchingsands: ["8T"],
			screech: ["8M", "8V"],
			seismictoss: ["8V"],
			shadowball: ["8M", "8V", "7M"],
			shadowbone: ["8L0", "7L27"],
			sleeptalk: ["8M", "7M"],
			smackdown: ["7M"],
			snore: ["8M", "7T"],
			spite: ["7T"],
			stealthrock: ["8M", "8V", "7T"],
			stompingtantrum: ["8M", "8L24", "7T", "7L43"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "8V", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "8V", "7M"],
			tailwhip: ["8L1", "8V", "7L1"],
			thief: ["8M", "7M"],
			thrash: ["8L54", "8V", "7L33"],
			throatchop: ["8M", "7T"],
			thunder: ["8M", "8V", "7M"],
			thunderbolt: ["8M", "8V", "7M"],
			thunderpunch: ["8M", "8V", "7T"],
			toxic: ["8V", "7M"],
			uproar: ["8M", "7T"],
			willowisp: ["8M", "8L36", "8V", "7M", "7L23"],
		},
	},
	marowakalolatotem: {
		learnset: {
			aerialace: ["7M"],
			allyswitch: ["7T"],
			attract: ["7M"],
			blizzard: ["7M"],
			boneclub: ["7L1"],
			bonemerang: ["7L21", "7S0"],
			bonerush: ["7L65"],
			brickbreak: ["7M"],
			brutalswing: ["7M"],
			bulldoze: ["7M"],
			confide: ["7M"],
			darkpulse: ["7M"],
			doubleteam: ["7M"],
			dreameater: ["7M"],
			earthpower: ["7T"],
			earthquake: ["7M"],
			echoedvoice: ["7M"],
			endeavor: ["7T", "7L49"],
			facade: ["7M"],
			falseswipe: ["7M"],
			fireblast: ["7M"],
			firepunch: ["7T"],
			flamecharge: ["7M"],
			flamethrower: ["7M"],
			flamewheel: ["7L1"],
			flareblitz: ["7L53"],
			fling: ["7M", "7L37"],
			focusblast: ["7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gigaimpact: ["7M"],
			growl: ["7L1"],
			heatwave: ["7T"],
			hex: ["7L17", "7S0"],
			hiddenpower: ["7M"],
			hyperbeam: ["7M"],
			icebeam: ["7M"],
			icywind: ["7T"],
			irondefense: ["7T"],
			ironhead: ["7T"],
			irontail: ["7T"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			leer: ["7L13", "7S0"],
			lowkick: ["7T"],
			outrage: ["7T"],
			painsplit: ["7T"],
			protect: ["7M"],
			raindance: ["7M"],
			rest: ["7M"],
			retaliate: ["7L59"],
			return: ["7M"],
			rockslide: ["7M"],
			rocktomb: ["7M"],
			round: ["7M"],
			sandstorm: ["7M"],
			shadowball: ["7M"],
			shadowbone: ["7L27"],
			sleeptalk: ["7M"],
			smackdown: ["7M"],
			snore: ["7T"],
			spite: ["7T"],
			stealthrock: ["7T"],
			stompingtantrum: ["7T", "7L43"],
			stoneedge: ["7M"],
			substitute: ["7M"],
			sunnyday: ["7M"],
			swagger: ["7M"],
			swordsdance: ["7M"],
			tailwhip: ["7L1"],
			thief: ["7M"],
			thrash: ["7L33"],
			throatchop: ["7T"],
			thunder: ["7M"],
			thunderbolt: ["7M"],
			thunderpunch: ["7T"],
			toxic: ["7M"],
			uproar: ["7T"],
			willowisp: ["7M", "7L23", "7S0"],
		},
		eventData: [
			{generation: 7, level: 25, perfectIVs: 3, moves: ["leer", "hex", "bonemerang", "willowisp"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	tyrogue: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletpunch: ["8E", "7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E", "4E", "3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L1", "7L1", "6L1", "5L1", "5D", "4L1"],
			feint: ["8E", "7E", "6E", "5E", "5D"],
			focusenergy: ["8M", "8L1"],
			foresight: ["7L1", "6L1", "5L1", "4L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["8M", "8L1", "7T", "7L1", "7E", "6T", "6L1", "6E", "5T", "5L1", "5E", "4T", "4L1", "4E", "3E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highjumpkick: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			laserfocus: ["7T"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			machpunch: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			megakick: ["8M", "3T"],
			megapunch: ["8M"],
			mimic: ["3T"],
			mindreader: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "6E", "5E"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rapidspin: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			vacuumwave: ["8E", "7E", "6E", "5E", "4T", "4E"],
			workup: ["8M", "7M", "5M"],
		},
	},
	hitmonlee: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurasphere: ["8M"],
			bide: ["7V"],
			blazekick: ["8M", "8L24", "7L45", "6L45", "5L45", "4L41"],
			bodyslam: ["8M", "7V", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brickbreak: ["8M", "8L0", "8V", "7M", "7L17", "6M", "6L17", "5M", "5L17", "4M", "4L17", "3M", "3L20"],
			bulkup: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["8M", "8L36", "7L1", "6L1", "5L57", "4L53"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doublekick: ["8L4", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "8L12", "7L49", "7V", "6L49", "5L49", "4M", "4L45", "3T", "3L41"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L1"],
			feint: ["8L1", "8V", "7L25", "6L25", "5L25", "4L25"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L1", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L21"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7L37", "7V", "6L37", "5L37", "4L37", "3L36"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8L1", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highjumpkick: ["8L44", "8V", "7L29", "7V", "6L29", "5L29", "4L29", "3L26", "3S0"],
			jumpkick: ["8V", "7L1", "7V", "6L13", "5L13", "4L13", "3L16"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lowkick: ["8M", "8L8", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "8L1", "7M", "6M", "5M"],
			meditate: ["8V", "7L1", "7V", "6L5", "5L5", "4L5", "3L6"],
			megakick: ["8M", "8L32", "8V", "7L1", "7V", "6L1", "5L53", "4L49", "3T", "3L46", "3S0"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mindreader: ["8L28", "7L33", "7V", "6L33", "5L33", "4L33", "3L31", "3S0"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "8L16", "7L1", "6L1", "5L1", "4L1", "3L1"],
			reversal: ["8M", "8L40", "7L1", "7V", "6L1", "5L61", "4L57", "3L51"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollingkick: ["8V", "7L1", "7V", "6L9", "5L9", "4L9", "3L11"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8L1"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M"],
			vacuumwave: ["4T"],
			wideguard: ["8L21", "7L41", "6L41", "5L41"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 38, abilities: ["limber"], moves: ["refresh", "highjumpkick", "mindreader", "megakick"]},
		],
		encounters: [
			{generation: 1, level: 30},
		],
	},
	hitmonchan: {
		learnset: {
			agility: ["8M", "8L28", "8V", "7L1", "7V", "6L6", "5L6", "4L6", "3L7"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurasphere: ["8M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletpunch: ["8L1", "7L16", "6L16", "5L16", "4L16"],
			captivate: ["4M"],
			closecombat: ["8M", "8L36", "7L1", "6L1", "5L66", "4L56"],
			coaching: ["8T"],
			cometpunch: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			confide: ["7M", "6M"],
			counter: ["8L40", "8V", "7L1", "7V", "6L1", "5L61", "4L51", "3T", "3L50"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			detect: ["8L12", "7L50", "7V", "6L50", "5L51", "4L46", "3L44"],
			dizzypunch: ["8V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "8L0", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L1"],
			feint: ["8L1", "8V", "7L21", "6L21", "5L21", "4L21"],
			firepunch: ["8M", "8L24", "8V", "7T", "7L36", "7V", "6T", "6L36", "5T", "5L36", "4T", "4L31", "3T", "3L26"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L1", "8V"],
			focuspunch: ["8L44", "7T", "7L1", "6T", "6L1", "5L56", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8L1", "8V", "7T", "6T", "5T", "4T", "3S0"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8L24", "8V", "7T", "7L36", "7V", "6T", "6L36", "5T", "5L36", "4T", "4L31", "3T", "3L26"],
			laserfocus: ["7T"],
			leer: ["8V"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			machpunch: ["8L4", "7L1", "7V", "6L16", "5L16", "4L16", "3L20"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "8L32", "8V", "7L46", "7V", "6L46", "5L46", "4L41", "3T", "3L38", "3S0"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mindreader: ["3S0"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["8L8", "6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L1", "7V", "6L11", "5L11", "4L11", "3L13"],
			quickguard: ["8L21", "7L31", "6L31", "5L31"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "8L16", "7L1", "6L1", "5L1", "4L1", "3L1"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			skullbash: ["7V"],
			skyuppercut: ["7L41", "6L41", "5L41", "4L36", "3L32", "3S0"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8V", "7V", "4T", "3T"],
			tackle: ["8L1"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "8L24", "8V", "7T", "7L36", "7V", "6T", "6L36", "5T", "5L36", "4T", "4L31", "3T", "3L26"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M"],
			vacuumwave: ["8L1", "7L26", "6L26", "5L26", "4T", "4L26"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 38, abilities: ["keeneye"], moves: ["helpinghand", "skyuppercut", "mindreader", "megapunch"]},
		],
		encounters: [
			{generation: 1, level: 30},
		],
	},
	hitmontop: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["8M", "8L28", "7L37", "7V", "6L37", "5L37", "4L37", "3L37"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M"],
			bulkup: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["8M", "8L36", "7L1", "6L1", "5L55", "5S0", "4L51"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["8L40", "7L28", "7V", "6L28", "5L28", "4L28", "3T", "3L31"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			detect: ["8L12", "7L1", "7V", "6L50", "5L51", "4L46", "3L43"],
			dig: ["8M", "8L32", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8M", "5T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endeavor: ["8L44", "7T", "7L1", "6T", "6L1", "5T", "5L60", "4T", "4L55", "3L49"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L1", "5S0"],
			feint: ["8L1", "7L24", "6L33", "5L33", "4L33"],
			focusblast: ["8M"],
			focusenergy: ["8M", "8L1", "7L1", "7V", "6L6", "5L6", "4L6", "3L7"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gyroball: ["8M", "8L8", "7M", "7L42", "6M", "6L42", "5M", "5L42", "4M", "4L42"],
			headbutt: ["7V", "4T"],
			helpinghand: ["8M", "8L1", "7T", "6T", "5T", "5S0", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			laserfocus: ["7T"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L1", "7V", "6L10", "5L10", "4L10", "3L13"],
			quickattack: ["8L4", "7L1", "7V", "6L15", "5L15", "4L15", "3L19"],
			quickguard: ["8L21", "7L46", "6L46", "5L46"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rapidspin: ["8L1", "7L19", "7V", "6L24", "5L24", "4L24", "3L25"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "8L16", "7L1", "6L1", "5L1", "4L1", "3L1"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollingkick: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8L24", "5S0", "4T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8L1"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			tripleaxel: ["8T"],
			triplekick: ["8L0", "7L33", "7V", "6L19", "5L19", "4L19", "3L20"],
			twister: ["4T"],
			uproar: ["8M"],
			vacuumwave: ["4T"],
			wideguard: ["8L21", "7L46", "6L46", "5L46"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 5, level: 55, gender: "M", nature: "Adamant", abilities: ["intimidate"], moves: ["fakeout", "closecombat", "suckerpunch", "helpinghand"]},
		],
	},
	lickitung: {
		learnset: {
			acid: ["8V"],
			amnesia: ["8M", "7E", "6E", "5E", "4E"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			belch: ["8E", "7E", "6E"],
			bellydrum: ["8L60", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			bind: ["8V", "7T", "6T", "5T"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodypress: ["8M"],
			bodyslam: ["8M", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L37", "6L37", "5L37"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["8L1", "7L9", "7V", "6L9", "5L9", "4L9", "3T", "3L12", "3S1"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			disable: ["8L24", "8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L34"],
			doubleedge: ["7V", "3T", "3S1"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragontail: ["8V", "7M", "6M", "5M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hammerarm: ["8E", "7E", "6E", "5E", "4E"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["3S0"],
			helpinghand: ["8M", "8V", "3S1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["8L36", "7T", "7L13", "6T", "6L13", "5T", "5L13", "4T", "4L13", "3L18"],
			lick: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			magnitude: ["7E", "7V", "6E", "5E", "4E", "3E"],
			mefirst: ["7L41", "6L41", "5L41", "4L37"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			muddywater: ["8M", "7E", "6E", "5E", "4E"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			poweruppunch: ["6M"],
			powerwhip: ["8M", "8L54", "8V", "7L53", "6L53", "5L53", "4L49"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			refresh: ["7L45", "6L45", "5L45", "4L41", "3L51"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["5D", "4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L6", "7L33", "7V", "6L33", "5L33", "4T", "4L33", "3T", "3S1"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			screech: ["8M", "8L42", "8V", "7L49", "7V", "6L49", "5L49", "4L45", "3L45"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			slam: ["8L48", "8V", "7L29", "7V", "6L29", "5L29", "4L29", "3L40"],
			sleeptalk: ["8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "5D", "4M", "4E", "3T", "3E"],
			smellingsalts: ["7E", "6E", "5E", "4E", "3E"],
			snore: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			solarbeam: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			steelroller: ["8T"],
			stomp: ["8L30", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L23"],
			stompingtantrum: ["8M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["8L12", "7L5", "7V", "6L5", "5L5", "4L5", "3L7"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			terrainpulse: ["8T"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["8E", "8V", "7E"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			wish: ["3S0"],
			workup: ["8M", "7M", "5M"],
			wrap: ["8L18", "8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L29"],
			wringout: ["7L57", "6L57", "5L57", "4L53"],
			zenheadbutt: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["healbell", "wish"], pokeball: "pokeball"},
			{generation: 3, level: 38, moves: ["helpinghand", "doubleedge", "defensecurl", "rollout"]},
		],
		encounters: [
			{generation: 1, level: 15},
		],
	},
	lickilicky: {
		learnset: {
			amnesia: ["8M"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bellydrum: ["8L60"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["8M", "7M", "6M", "5M", "4M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L37", "6L37", "5L37"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			defensecurl: ["8L1", "7L9", "6L9", "5L9", "4L9"],
			dig: ["8M", "6M", "5M", "4M"],
			disable: ["8L24", "7L25", "6L25", "5L25", "4L25"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dragontail: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M"],
			endure: ["8M", "4M"],
			explosion: ["7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "7M", "7L61", "6M", "6L61", "5M", "5L61", "4M", "4L57"],
			headbutt: ["4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			knockoff: ["8L36", "7T", "7L13", "6T", "6L13", "5T", "5L13", "4T", "4L13"],
			lick: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			mefirst: ["7L41", "6L41", "5L41", "4L37"],
			megakick: ["8M"],
			megapunch: ["8M"],
			muddywater: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			powerwhip: ["8M", "8L54", "7L1", "6L1", "5L53", "4L49"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			refresh: ["7L45", "6L45", "5L45", "4L41"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rollout: ["8L1", "7L33", "6L33", "5L33", "4T", "4L33"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M"],
			screech: ["8M", "8L42", "7L49", "6L49", "5L49", "4L45"],
			secretpower: ["6M", "4M"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			slam: ["8L48", "7L29", "6L29", "5L29", "4L29"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			steelroller: ["8T"],
			stomp: ["8L30", "7L21", "6L21", "5L21", "4L21"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			supersonic: ["8L1", "7L5", "6L5", "5L5", "4L5"],
			surf: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			terrainpulse: ["8T"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			thunder: ["8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M"],
			whirlpool: ["8M", "4M"],
			workup: ["8M", "7M", "5M"],
			wrap: ["8L18", "7L17", "6L17", "5L17", "4L17"],
			wringout: ["7L1", "6L1", "5L57", "4L53"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	koffing: {
		learnset: {
			assurance: ["8M", "8L16", "7L12", "6L12", "5L15", "4L15"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			belch: ["8L40", "7L42", "6L42"],
			bide: ["7V"],
			captivate: ["4M"],
			clearsmog: ["8L12", "8V", "7L15", "6L15", "5L19"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E"],
			darkpulse: ["8M", "8V", "7M", "6M", "5T", "5D", "4M"],
			destinybond: ["8L52", "7L40", "7E", "7V", "6L40", "6E", "5L51", "5E", "4L46", "4E", "3L45", "3E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			explosion: ["8L44", "8V", "7M", "7L37", "7V", "6M", "6L37", "5M", "5L42", "4M", "4L37", "3T", "3L41"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grudge: ["8E", "7E", "6E", "5E", "4E"],
			gyroball: ["8M", "7M", "7L29", "6M", "6L29", "5M", "5L37", "4M", "4L33"],
			haze: ["8L24", "8V", "7L26", "7V", "6L26", "5L33", "4L28", "3L33"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			infestation: ["7M", "6M"],
			memento: ["8L48", "7L45", "6L45", "5L55", "4L51", "3L49"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			painsplit: ["8E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisongas: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			psywave: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8L28", "8V", "7L23", "7V", "6L23", "5L24", "4L19", "3T", "3L17"],
			shadowball: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludge: ["8L20", "8V", "7L18", "7V", "6L18", "5L28", "4L24", "3L21"],
			sludgebomb: ["8M", "8L32", "8V", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L46", "4M", "4L42", "3M"],
			sludgewave: ["8M", "5D"],
			smog: ["8L4", "8V", "7L4", "7V", "6L4", "5L6", "5D", "4L6", "3L9"],
			smokescreen: ["8L8", "7L7", "7V", "6L7", "5L10", "4L10", "3L25"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			spitup: ["8E", "7E", "6E", "5E"],
			stockpile: ["8E", "7E", "6E", "5E"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["8E", "7E", "6E", "5E"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8L36", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "7E", "6E"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venomdrench: ["8M", "7E"],
			venoshock: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8V", "7M", "6M", "5M", "4M", "4E", "3E"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 1, level: 30},
		],
	},
	weezing: {
		learnset: {
			assurance: ["8M", "8L16", "7L12", "6L12", "5L15", "4L15"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			belch: ["8L44", "7L51", "6L50"],
			bide: ["7V"],
			captivate: ["4M"],
			clearsmog: ["8L12", "8V", "7L15", "6L15", "5L19"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			curse: ["7V"],
			darkpulse: ["8M", "8V", "7M", "6M", "5T", "4M"],
			destinybond: ["8L62", "7L46", "7V", "6L46", "5L59", "4L55", "3L51"],
			doublehit: ["8L0", "7L1", "6L29", "5L39", "4L33"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			explosion: ["8L50", "8V", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L46", "4M", "4L40", "3T", "3L44"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "7M", "7L29", "6M", "5M", "4M"],
			haze: ["8L24", "8V", "7L26", "7V", "6L26", "5L33", "4L28", "3L33"],
			headbutt: ["8V"],
			heatwave: ["8M", "8L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			infestation: ["7M", "6M"],
			memento: ["8L56", "7L57", "6L54", "5L65", "4L63", "3L58"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisongas: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8V"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8L28", "8V", "7L23", "7V", "6L23", "5L24", "4L19", "3T", "3L1"],
			shadowball: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludge: ["8L20", "8V", "7L18", "7V", "6L18", "5L28", "4L24", "3L21"],
			sludgebomb: ["8M", "8L32", "8V", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L52", "4M", "4L48", "3M"],
			sludgewave: ["8M"],
			smog: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			smokescreen: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L25"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8L38", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8V", "7M", "6M", "5M", "4M"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 2, level: 16},
			{generation: 3, level: 32},
			{generation: 4, level: 15, pokeball: "safariball"},
		],
	},
	weezinggalar: {
		learnset: {
			aromatherapy: ["8L24"],
			aromaticmist: ["8L1"],
			assurance: ["8M", "8L16"],
			attract: ["8M"],
			belch: ["8L44"],
			brutalswing: ["8M"],
			clearsmog: ["8L12"],
			corrosivegas: ["8T"],
			darkpulse: ["8M"],
			dazzlinggleam: ["8M"],
			defog: ["8L1"],
			destinybond: ["8L62"],
			doublehit: ["8L0"],
			endure: ["8M"],
			explosion: ["8L50"],
			facade: ["8M"],
			fairywind: ["8L1"],
			fireblast: ["8M"],
			flamethrower: ["8M"],
			gigaimpact: ["8M"],
			gyroball: ["8M"],
			haze: ["8L1"],
			heatwave: ["8M", "8L1"],
			hyperbeam: ["8M"],
			memento: ["8L56"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M", "8L68"],
			overheat: ["8M"],
			payback: ["8M"],
			playrough: ["8M"],
			poisongas: ["8L1"],
			protect: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			round: ["8M"],
			screech: ["8M"],
			selfdestruct: ["8M", "8L28"],
			shadowball: ["8M"],
			sleeptalk: ["8M"],
			sludge: ["8L20"],
			sludgebomb: ["8M", "8L32"],
			sludgewave: ["8M"],
			smog: ["8L1"],
			smokescreen: ["8L1"],
			snore: ["8M"],
			strangesteam: ["8L1"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			tackle: ["8L1"],
			taunt: ["8M"],
			thief: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			toxic: ["8L38"],
			toxicspikes: ["8M"],
			uproar: ["8M"],
			venomdrench: ["8M"],
			venoshock: ["8M"],
			willowisp: ["8M"],
			wonderroom: ["8M"],
		},
	},
	rhyhorn: {
		learnset: {
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			bulldoze: ["8M", "8L10", "7M", "7L21", "6M", "6L21", "5M", "5L30"],
			captivate: ["4M"],
			chipaway: ["7L25", "6L25", "5L34"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3T", "3E"],
			crunch: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			crushclaw: ["7E", "6E", "5E", "4E", "3E"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "5D", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonpulse: ["8M", "8V", "7T", "6T", "5T", "4M"],
			dragonrush: ["8E", "7E", "6E", "5E", "4E"],
			drillrun: ["8M", "8L35", "8V", "7T", "7L33", "6T", "6L33", "5T", "5L45"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L45", "8V", "7M", "7L45", "7V", "6M", "6L45", "5M", "5L56", "4M", "4L49", "3M", "3L52"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["8M", "7E", "6E", "5E", "4E"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L5", "7V", "6L5", "5L12", "4L13", "3L15"],
			guardsplit: ["8E", "7E", "6E"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hornattack: ["8L15", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			horndrill: ["8L60", "8V", "7L53", "7V", "6L53", "5L63", "4L37", "3L38"],
			icebeam: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			icefang: ["8M", "7E", "6E", "5E", "4E"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			leer: ["7V"],
			magnitude: ["7E", "7V", "6E", "5E", "4E", "3E"],
			megahorn: ["8M", "8L55", "8V", "7L49", "6L49", "5L67", "4L57", "3L57"],
			metalburst: ["8E", "7E", "6E"],
			mimic: ["7V", "3T"],
			mudshot: ["8M"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "8L30", "7L29", "6L23", "5L23", "4L25", "3L29"],
			rockclimb: ["7E", "6E", "5E", "4M"],
			rockpolish: ["8E", "7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			rototiller: ["7E", "6E"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8V"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scaryface: ["8M", "8L20", "7L9", "7V", "6L9", "5L19", "4L21", "3L24"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["8E", "7E", "7V", "6E", "5E", "4E"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["8L5", "7M", "7L13", "6M", "6L13"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stomp: ["8L25", "8V", "7L17", "7V", "6L8", "5L8", "4L9", "3L10"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L50", "7M", "7L41", "6M", "6L41", "5M", "5L52", "4M", "4L45"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			tackle: ["8L1"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L40", "8V", "7L37", "7V", "6L37", "5L41", "4L33", "3L43"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thrash: ["7V"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderfang: ["8M", "7E", "6E", "5E", "4E"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 1, level: 20},
		],
	},
	rhydon: {
		learnset: {
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "7V", "3T"],
			breakingswipe: ["8M"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "8L1", "7M", "7L21", "6M", "6L21", "5M", "5L30"],
			captivate: ["4M"],
			chipaway: ["7L25", "6L25", "5L34"],
			confide: ["7M", "6M"],
			counter: ["8V", "7V", "3T"],
			crunch: ["8M", "8V"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonpulse: ["8M", "8V", "7T", "6T", "5T", "4M"],
			dragontail: ["8V", "7M", "6M", "5M"],
			drillrun: ["8M", "8L35", "8V", "7T", "7L33", "6T", "6L33", "5T", "5L47"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L47", "8V", "7M", "7L48", "7V", "6M", "6L48", "5M", "5L62", "4M", "4L49", "3M", "3L58", "3S0"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["8M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hammerarm: ["8L0", "7L1", "6L42", "5L42", "4L42"],
			headbutt: ["8V", "7V", "4T"],
			heatcrash: ["8M"],
			heavyslam: ["8M"],
			helpinghand: ["8M", "8V", "3S0"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hornattack: ["8L15", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			horndrill: ["8L68", "8V", "7L1", "7V", "6L1", "5L71", "4L37", "3L38"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icefang: ["8M"],
			icepunch: ["8M", "8V", "7T", "6T", "5T", "4T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["7V"],
			megahorn: ["8M", "8L61", "8V", "7L55", "6L1", "5L77", "4L57", "3L66", "3S0"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			meteorbeam: ["8T"],
			mimic: ["7V", "3T"],
			mudshot: ["8M"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			payday: ["8M", "8V", "7V"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["8M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "8L30", "7L29", "6L23", "5L23", "4L25", "3L29"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8V"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scaryface: ["8M", "8L20", "7L1", "7V", "6L1", "5L19", "4L21", "3L24", "3S0"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["8L1", "7M", "7L13", "6M", "6L13", "5M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stomp: ["8L25", "8V", "7L17", "7V", "6L1", "5L1", "4L1", "3L1"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L54", "7M", "7L41", "6M", "6L41", "5M", "5L56", "4M", "4L45"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			tackle: ["8L1"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L40", "8V", "7L37", "7V", "6L37", "5L41", "4L33", "3L46"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderfang: ["8M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			watergun: ["7V"],
			whirlpool: ["8M", "4M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 46, moves: ["helpinghand", "megahorn", "scaryface", "earthquake"]},
		],
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 10},
			{generation: 4, level: 41},
			{generation: 6, level: 30},
		],
	},
	rhyperior: {
		learnset: {
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			breakingswipe: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "8L1", "7M", "7L21", "6M", "6L21", "5M"],
			captivate: ["4M"],
			chipaway: ["7L25", "6L25", "5L30"],
			confide: ["7M", "6M"],
			crunch: ["8M"],
			cut: ["6M", "5M", "4M"],
			dig: ["8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dragonpulse: ["8M", "7T", "6T", "5T", "4M"],
			dragontail: ["7M", "6M", "5M"],
			drillrun: ["8M", "8L35", "7T", "7L33", "6T", "6L33", "5T", "5L47"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L47", "7M", "7L48", "6M", "6L48", "5M", "5L62", "4M", "4L49"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M"],
			firefang: ["8M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyattack: ["7L1", "6L1", "5L1", "4L1"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hammerarm: ["8L1", "7L1", "6L42", "5L42", "4L42"],
			headbutt: ["4T"],
			heatcrash: ["8M"],
			heavyslam: ["8M"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			highhorsepower: ["8M"],
			hornattack: ["8L15", "7L1", "6L1", "5L1", "4L1"],
			horndrill: ["8L68", "7L1", "6L1", "5L71", "4L37"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icefang: ["8M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			megahorn: ["8M", "8L61", "7L55", "6L1", "5L77", "4L57"],
			megakick: ["8M"],
			megapunch: ["8M"],
			meteorbeam: ["8T"],
			mudshot: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			payday: ["8M"],
			poisonjab: ["8M", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			reversal: ["8M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockblast: ["8M", "8L30", "7L29", "6L23", "5L23", "4L25"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rockwrecker: ["8L75", "7L1", "6L1", "5L86", "4L61"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M"],
			scaryface: ["8M", "8L20", "7L1", "6L1", "5L19", "4L21"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			smackdown: ["8L1", "7M", "7L13", "6M", "6L13", "5M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stomp: ["8L25", "7L17", "6L1", "5L1", "4L1"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L54", "7M", "7L41", "6M", "6L41", "5M", "5L56", "4M", "4L45"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			surf: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			tackle: ["8L1"],
			tailwhip: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["8L40", "7L37", "6L37", "5L41", "4L33"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			thunder: ["8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thunderfang: ["8M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			whirlpool: ["8M", "4M"],
		},
	},
	happiny: {
		learnset: {
			aromatherapy: ["8E", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			charm: ["9M", "9L20", "8M", "8L20", "7L1", "6L1", "5L1", "4L1"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1", "7L5", "6L5", "5L5", "4L5"],
			counter: ["7E", "6E", "5E", "4E"],
			covet: ["9L16", "8L16", "7T", "6T", "5T"],
			defensecurl: ["9L4", "8L4"],
			disarmingvoice: ["9M", "9L12", "8L12"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "7E", "6E", "5E", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			hail: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			healbell: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			helpinghand: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			lastresort: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			metronome: ["9M", "8M", "7E", "6E", "5E", "4E"],
			minimize: ["8L1"],
			mudbomb: ["7E", "6E", "5E", "4E"],
			mudslap: ["4T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			pound: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			present: ["9E", "8E", "7E", "6E", "5E", "4E"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			refresh: ["7L9", "6L9", "5L9", "4L9"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			seismictoss: ["9E", "8E"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "4E"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			sweetkiss: ["9L8", "8L8", "7L12", "6L12", "5L12", "4L12"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
	},
	chansey: {
		learnset: {
			allyswitch: ["8M", "7T"],
			aromatherapy: ["8E", "7E", "6E", "5E", "4E", "3E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bestow: ["7L20", "6L20", "5L20"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			celebrate: ["8V"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1", "8S3"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			counter: ["7E", "7V", "6E", "5E", "5D", "4E", "3T"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "8M", "8V", "7M", "6M"],
			defensecurl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L31", "3T", "3L41"],
			disarmingvoice: ["9M", "9L1", "8L1"],
			doubleedge: ["9L40", "8L40", "8V", "7L1", "7V", "6L1", "5L54", "4L46", "3T", "3L57"],
			doubleslap: ["8V", "7L12", "7V", "6L12", "5L12", "4L16", "3L17"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["9L8", "8L8", "7M", "6M", "5M"],
			eggbomb: ["8V", "7L42", "7V", "6L42", "5L42", "4L38", "3L35"],
			electricterrain: ["9M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "9L20", "8M", "8L20", "7M", "7L34", "6M", "6L34", "5M", "5L34", "4M", "4L27"],
			focusblast: ["9M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			growl: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			healingwish: ["9L52", "8L52", "7L50", "6L50", "5L50", "4L42"],
			healpulse: ["9L28", "8L28", "7L38", "6L38", "5L38"],
			helpinghand: ["9M", "9L32", "8M", "8L32", "8V", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lastresort: ["9L48", "8L48", "7T", "6T", "5T", "4T"],
			lifedew: ["9L12", "8L12"],
			lightscreen: ["9M", "9L36", "8M", "8L36", "8V", "7M", "7L46", "7V", "6M", "6L46", "5M", "5L46", "4M", "4L34", "3M", "3L49"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["9M", "8M", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			mimic: ["7V", "3T"],
			minimize: ["8L1", "8V", "7L23", "7V", "6L23", "5L23", "4L20", "3L23"],
			mudbomb: ["7E", "6E", "5E", "4E"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			pound: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			poweruppunch: ["6M"],
			present: ["8E", "8S3", "7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8V", "7V"],
			refresh: ["7L9", "6L9", "5L9", "4L9", "3L9", "3S1"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8E", "8V", "7E", "7V", "6E", "3T"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sing: ["9L16", "8L16", "8V", "7L31", "7V", "6L31", "5L31", "4L23", "3L29"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M", "3S2"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			softboiled: ["9L44", "8L44", "8V", "8S3", "7L16", "7V", "6L16", "5L16", "4L12", "3T", "3L13", "3S2"],
			solarbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			stealthrock: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			storedpower: ["9M", "8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["9L1", "8L1", "8S3", "3S2"],
			sweetscent: ["3S0"],
			swift: ["9M"],
			tailwhip: ["9L4", "8L4", "8V", "7L5", "7V", "6L5", "5L5", "4L5", "3L5", "3S1"],
			takedown: ["9M", "9L24", "8L24", "8V", "7L27", "7V", "6L27", "5L27"],
			telekinesis: ["7T"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S2"],
			thunderpunch: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			thunderwave: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			triattack: ["8M", "8V", "7V"],
			uproar: ["8M"],
			watergun: ["7V"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			wish: ["3S0"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["sweetscent", "wish"], pokeball: "pokeball"},
			{generation: 3, level: 10, moves: ["pound", "growl", "tailwhip", "refresh"], pokeball: "pokeball"},
			{generation: 3, level: 39, moves: ["sweetkiss", "thunderbolt", "softboiled", "skillswap"]},
			{generation: 8, level: 7, moves: ["present", "sweetkiss", "charm", "softboiled"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 7},
		],
	},
	blissey: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "8M", "4M"],
			bestow: ["7L20", "6L20", "5L20"],
			blizzard: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			counter: ["3T"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			defensecurl: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L31", "3T", "3L33"],
			disarmingvoice: ["9M", "9L1", "8L1"],
			doubleedge: ["9L40", "8L40", "7L1", "7V", "6L1", "5L54", "4L46", "3T", "3L47"],
			doubleslap: ["7L12", "7V", "6L12", "5L12", "4L16", "3L13"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["9L8", "8L8", "7M", "6M", "5M"],
			eggbomb: ["7L42", "7V", "6L42", "5L42", "4L38", "3L28"],
			electricterrain: ["9M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "9L20", "8M", "8L20", "7M", "7L34", "6M", "6L34", "5M", "5L34", "4M", "4L27"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			growl: ["7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			healingwish: ["9L52", "8L52", "7L50", "6L50", "5L50", "4L42"],
			healpulse: ["9L28", "8L28", "7L38", "6L38", "5L38"],
			helpinghand: ["9M", "9L32", "8M", "8L32", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lastresort: ["9L48", "8L48", "7T", "6T", "5T", "4T"],
			lifedew: ["9L12", "8L12"],
			lightscreen: ["9M", "9L36", "8M", "8L36", "7M", "7L46", "7V", "6M", "6L46", "5M", "5L46", "4M", "4L34", "3M", "3L40"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["9M", "8M", "3T"],
			mimic: ["3T"],
			minimize: ["8L1", "7L23", "7V", "6L23", "5L23", "4L20", "3L18"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			pound: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			refresh: ["7L9", "6L9", "5L9", "5S0", "4L9", "3L7"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sing: ["9L16", "8L16", "7L31", "7V", "6L31", "5L31", "4L23", "3L23"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			softboiled: ["9L44", "8L44", "7L16", "7V", "6L16", "5L16", "4L12", "3T", "3L10"],
			solarbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			storedpower: ["9M", "8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["9L1", "8L1"],
			swift: ["9M"],
			tailwhip: ["9L4", "8L4", "7L5", "7V", "6L5", "5L5", "5S0", "4L5", "3L4"],
			takedown: ["9M", "9L24", "8L24", "7L27", "6L27", "5L27"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			triattack: ["8M"],
			trick: ["9M"],
			uproar: ["8M"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 5, level: 10, isHidden: true, moves: ["pound", "growl", "tailwhip", "refresh"]},
		],
	},
	tangela: {
		learnset: {
			absorb: ["8L1", "8V", "7L10", "7V", "6L10", "5L8", "4L8", "3L10"],
			amnesia: ["8M", "8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			ancientpower: ["8L24", "7L38", "6L38", "5L36", "4T", "4L33"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["8L1", "8V", "7T", "7L17", "7V", "6T", "6L17", "5T", "5L22", "4L22", "3L28"],
			bodyslam: ["8M", "7V", "3T"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confusion: ["8E", "8V", "7E", "7V", "6E", "5E", "4E", "3E"],
			constrict: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endeavor: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "8L32", "7T", "7L36", "7E", "7V", "6T", "6L36", "6E", "5T", "5L36", "5E", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L56", "7L48", "6L48"],
			growth: ["8L8", "8V", "7L20", "7V", "6L20", "5L12", "4L12", "3L13"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			ingrain: ["8L52", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			knockoff: ["8L28", "7T", "7L27", "6T", "6L27", "5T", "5L33", "4T", "4L36"],
			leafstorm: ["8M", "7E", "6E", "5E", "4E"],
			leechseed: ["8E", "8V", "7E", "6E", "5E", "5D", "4E", "3E"],
			megadrain: ["8L12", "8V", "7L23", "7E", "7V", "6L23", "6E", "5L26", "5E", "4L26", "4E", "3L31", "3E"],
			mimic: ["7V", "3T"],
			morningsun: ["3S0"],
			naturalgift: ["7L33", "7E", "6L33", "6E", "5L40", "5E", "4M", "4L40"],
			naturepower: ["8E", "7M", "7E", "6E", "5E", "4E", "3E"],
			painsplit: ["7T", "6T", "5T", "4T"],
			poisonpowder: ["8L20", "8V", "7L14", "7V", "6L14", "5L15", "4L15", "3L19"],
			powerswap: ["8M", "7E", "6E", "5E", "4E"],
			powerwhip: ["8M", "8L48", "8V", "7L50", "6L50", "5L54", "4L54"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			rage: ["7V"],
			ragepowder: ["8E", "7E", "6E", "5E"],
			reflect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3E"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "5D", "4T"],
			shockwave: ["7T", "6T", "4M"],
			skullbash: ["7V"],
			slam: ["8L40", "8V", "7L41", "7V", "6L41", "5L43", "4L43", "3L40"],
			sleeppowder: ["8L36", "8V", "7L4", "7V", "6L4", "5L5", "5D", "4L5", "3L4"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			stunspore: ["8L4", "8V", "7L30", "7V", "6L30", "5L29", "4L29", "3L37"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swordsdance: ["8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			tickle: ["8L44", "7L44", "6L44", "5L47", "4L47", "3L46"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			vinewhip: ["8L16", "8V", "7L7", "7V", "6L7", "5L19", "4L19", "3L22"],
			wakeupslap: ["7E"],
			worryseed: ["7T", "6T", "5T", "4T"],
			wringout: ["7L46", "6L46", "5L50", "4L50"],
		},
		eventData: [
			{generation: 3, level: 30, abilities: ["chlorophyll"], moves: ["morningsun", "solarbeam", "sunnyday", "ingrain"]},
		],
		encounters: [
			{generation: 1, level: 13},
		],
	},
	tangrowth: {
		learnset: {
			absorb: ["8L1", "7L10", "6L10", "5L8", "4L8"],
			aerialace: ["7M", "6M", "5M", "4M"],
			amnesia: ["8M"],
			ancientpower: ["8L24", "7L40", "6L40", "5L36", "4T", "4L33", "4S0"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bind: ["8L1", "7T", "7L17", "6T", "6L17", "5T", "5L22", "4L22"],
			block: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L57", "4T", "4L57"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			brutalswing: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletseed: ["8M", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "6L1", "5L1", "4L1"],
			cut: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "4M"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["8M", "8L32", "7T", "7L36", "6T", "6L36", "5T", "5L36", "4M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L56", "7L50", "6L50"],
			growth: ["8L1", "7L20", "6L20", "5L12", "4L12"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			infestation: ["7M", "6M"],
			ingrain: ["8L52", "7L1", "6L1", "5L1", "4L1"],
			knockoff: ["8L28", "7T", "7L27", "6T", "6L27", "5T", "5L33", "4T", "4L36"],
			leafstorm: ["8M"],
			megadrain: ["8L12", "7L23", "6L23", "5L26", "4L26"],
			morningsun: ["4S0"],
			mudslap: ["4T"],
			naturalgift: ["7L33", "6L33", "5L40", "4M", "4L40", "4S0"],
			naturepower: ["7M", "6M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["8M", "7M", "6M", "5M", "4M"],
			poisonpowder: ["8L20", "7L14", "6L14", "5L15", "4L15"],
			powerswap: ["8M"],
			powerwhip: ["8M", "8L48", "7L53", "6L53", "5L54", "4L54"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			shockwave: ["7T", "6T", "4M"],
			slam: ["8L40", "7L43", "6L43", "5L43", "4L43"],
			sleeppowder: ["8L36", "7L4", "6L4", "5L5", "4L5"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			solarblade: ["8M"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M"],
			stunspore: ["8L1", "7L30", "6L30", "5L29", "4L29"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "4S0"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["7T", "6T", "5T", "4T"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			tickle: ["8L44", "7L46", "6L46", "5L47", "4L47"],
			toxic: ["7M", "6M", "5M", "4M"],
			vinewhip: ["8L16", "7L7", "6L7", "5L19", "4L19"],
			worryseed: ["7T", "6T", "5T", "4T"],
			wringout: ["7L49", "6L49", "5L50", "4L50"],
		},
		eventData: [
			{generation: 4, level: 50, gender: "M", nature: "Brave", moves: ["sunnyday", "morningsun", "ancientpower", "naturalgift"], pokeball: "cherishball"},
		],
	},
	kangaskhan: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aquatail: ["7T", "6T", "5T", "4T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			beatup: ["8M"],
			bide: ["7V"],
			bite: ["8L12", "8V", "7L13", "7V", "6L13", "5L13", "4L13", "3L7", "3S1"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L31", "6L31", "5L31"],
			circlethrow: ["8E", "7E", "6E", "5E"],
			cometpunch: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3T", "3E"],
			covet: ["7T", "6T", "5T"],
			crunch: ["8M", "8L36", "8V", "7L37", "6L37", "5L37", "4L31"],
			crushclaw: ["7E", "6E", "5E", "4E", "3E"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			disable: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			dizzypunch: ["8V", "7L34", "7V", "6L34", "5L34", "4L25", "3L43", "3S2"],
			doubleedge: ["8E", "7E", "7V", "6E", "5E", "4E", "3T"],
			doublehit: ["8L32", "7L19", "6L19", "5L19", "4L43"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "5D", "4M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "6S3", "5M", "4M", "3M", "3S2"],
			endeavor: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			endure: ["8M", "8L40", "7L43", "7V", "6L43", "5L43", "4M", "4L34", "3T", "3L37"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L8", "8V", "7L7", "6L7", "6S3", "5L7", "5D", "4L7", "3L19"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			fissure: ["7V"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L20", "7E", "7V", "6E", "5E", "4E", "3E"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L4"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hammerarm: ["8E", "7E", "6E", "5E", "4E"],
			headbutt: ["8L24", "8V", "7V", "4T"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lastresort: ["8L52"],
			leer: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "8V", "7L25", "7V", "6L25", "5L25", "4L19", "3T", "3L25"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["8M", "8L48", "8V", "7T", "7L46", "6T", "6L46", "5T", "5L46", "4T", "4L37"],
			pound: ["8L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["8V", "7L22", "7V", "6L22", "5L22", "4L22", "3L31"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "6S3", "5M", "4M", "3M"],
			reversal: ["8M", "8L44", "7L50", "7V", "6L50", "5L55", "4L49", "3L49"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sing: ["3S2"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			spite: ["7T", "6T", "5T", "4T"],
			stomp: ["8L16", "7E", "7V", "6E", "5E", "4E", "3E"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			suckerpunch: ["8L28", "8V", "7L49", "6L49", "6S3", "5L49", "4T", "4L46"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tailwhip: ["8L1", "8V", "7L10", "7V", "6L10", "5L10", "4L10", "3L13", "3S2"],
			takedown: ["7V"],
			terrainpulse: ["8T"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trumpcard: ["7E", "6E", "5E"],
			uproar: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			wish: ["3S0"],
			workup: ["8M", "7M", "5M"],
			yawn: ["3S0"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, abilities: ["earlybird"], moves: ["yawn", "wish"], pokeball: "pokeball"},
			{generation: 3, level: 10, abilities: ["earlybird"], moves: ["cometpunch", "leer", "bite"], pokeball: "pokeball"},
			{generation: 3, level: 35, abilities: ["earlybird"], moves: ["sing", "earthquake", "tailwhip", "dizzypunch"]},
			{generation: 6, level: 50, abilities: ["scrappy"], moves: ["fakeout", "return", "earthquake", "suckerpunch"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 25},
		],
	},
	horsea: {
		learnset: {
			agility: ["8M", "8L30", "8V", "7L36", "7V", "6L23", "5L23", "4L23", "3L36"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "7L31", "6L30", "5L30", "5D", "4M", "4L30"],
			bubble: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "5S0", "4L1", "3L1"],
			bubblebeam: ["8L25", "8V", "7L21", "7V", "6L18", "5L18", "4L18"],
			captivate: ["4M"],
			clearsmog: ["8E", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			disable: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["8L20", "7E", "7V", "6E", "5E", "4E", "3E"],
			dragondance: ["8M", "8L50", "7L46", "6L38", "5L38", "4L38", "3L50"],
			dragonpulse: ["8M", "8L40", "8V", "7T", "7L41", "6T", "6L41", "5T", "5L42", "4M", "4L42"],
			dragonrage: ["7E", "7V", "6E", "5E", "4E", "3E"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			flashcannon: ["8M", "8V", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			focusenergy: ["8M", "8L15", "8V", "7L26", "6L14", "5L14", "4L14"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L45", "8V", "7L52", "7V", "6L35", "5L35", "4L35", "3L43"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			laserfocus: ["8L35"],
			leer: ["8L1", "8V", "7L9", "7V", "6L8", "5L8", "4L8", "3L15"],
			liquidation: ["8M"],
			mimic: ["7V", "3T"],
			muddywater: ["8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			octazooka: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			outrage: ["8M", "8V", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "8L55", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorwind: ["7E", "6E", "5E", "4E"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["8L5", "8V", "7L5", "7V", "6L4", "5L4", "4L4", "3L8"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			splash: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			takedown: ["7V"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["8L10", "7L17", "7V", "6L17", "5L26", "4T", "4L26", "3L29"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "8V", "7L13", "7V", "6L1", "5L11", "4L11", "3L22"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
		},
		eventData: [
			{generation: 5, level: 1, shiny: true, moves: ["bubble"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 5},
		],
	},
	seadra: {
		learnset: {
			agility: ["8M", "8L30", "8V", "7L38", "7V", "6L23", "5L23", "4L23", "3L40", "3S0"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "7L31", "6L30", "5L30", "4M", "4L30"],
			bubble: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bubblebeam: ["8L25", "8V", "7L21", "7V", "6L18", "5L18", "4L18"],
			captivate: ["4M"],
			clearsmog: ["8V"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			disable: ["8V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["8L20", "7V"],
			dragondance: ["8M", "8L58", "7L52", "6L48", "5L48", "4L48", "3L62"],
			dragonpulse: ["8M", "8L44", "8V", "7T", "7L45", "6T", "6L45", "5T", "5L57", "4M", "4L57"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "8V", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			focusenergy: ["8M", "8L15", "8V", "7L26", "6L14", "5L14", "4L14"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L51", "8V", "7L1", "7V", "6L1", "5L40", "4L40", "3L51"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			laserfocus: ["8L37", "7T"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			liquidation: ["8M"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			naturalgift: ["4M"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "8L65", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			takedown: ["7V"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["8L1", "7L17", "7V", "6L17", "5L26", "4T", "4L26", "3L29", "3S0"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
		},
		eventData: [
			{generation: 3, level: 45, abilities: ["poisonpoint"], moves: ["leer", "watergun", "twister", "agility"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 20},
			{generation: 2, level: 20},
			{generation: 3, level: 25},
			{generation: 4, level: 15},
		],
	},
	kingdra: {
		learnset: {
			agility: ["8M", "8L30", "7L38", "7V", "6L23", "5L23", "4L23", "3L40", "3S0"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			breakingswipe: ["8M"],
			brine: ["8M", "7L31", "6L30", "5L30", "4M", "4L30"],
			bubble: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bubblebeam: ["8L25", "7L21", "6L18", "5L18", "4L18"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dracometeor: ["8T", "7T", "6T", "5T", "5S1", "4T"],
			dragonbreath: ["8L20", "7V"],
			dragondance: ["8M", "8L58", "7L52", "6L48", "5L48", "4L48", "3L62"],
			dragonpulse: ["8M", "8L44", "7T", "7L45", "6T", "6L1", "5T", "5L57", "5S1", "4M", "4L57"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			focusenergy: ["8M", "8L15", "7L26", "6L14", "5L14", "4L14"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["8M"],
			hydropump: ["8M", "8L51", "7L1", "7V", "6L1", "5L40", "4L40", "3L51"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			laserfocus: ["8L37", "7T"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			liquidation: ["8M"],
			mimic: ["3T"],
			muddywater: ["8M", "5S1"],
			naturalgift: ["4M"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			protect: ["8M", "7M", "7V", "6M", "5M", "5S1", "4M", "3M"],
			quash: ["7M", "6M", "5M"],
			raindance: ["8M", "8L65", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["8L1", "7L17", "7V", "6L17", "5L26", "4T", "4L26", "3L29", "3S0"],
			waterfall: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "8L1", "7V", "4M"],
			yawn: ["8L1", "7L1", "6L1", "5L1", "4L1"],
		},
		eventData: [
			{generation: 3, level: 50, abilities: ["swiftswim"], moves: ["leer", "watergun", "twister", "agility"], pokeball: "pokeball"},
			{generation: 5, level: 50, gender: "M", nature: "Timid", ivs: {hp: 31, atk: 17, def: 8, spa: 31, spd: 11, spe: 31}, abilities: ["swiftswim"], moves: ["dracometeor", "muddywater", "dragonpulse", "protect"], pokeball: "cherishball"},
		],
	},
	goldeen: {
		learnset: {
			acupressure: ["8E"],
			agility: ["8M", "8L20", "8V", "7L29", "7V", "6L29", "5L47", "4L47", "3L52"],
			aquaring: ["8L25", "7L21", "6L21", "5L27", "4L27"],
			aquatail: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7E", "6E", "5E", "4E"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8M", "8V", "7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L30", "7L13", "7V", "6L13", "5L21", "4L21", "3L24"],
			flipturn: ["8T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L24", "7V", "6L24", "5L31", "4L31", "3L29"],
			furycutter: ["4T"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hornattack: ["8L15", "8V", "7L8", "7V", "6L8", "5L11", "4L11", "3L15"],
			horndrill: ["8L50", "8V", "7L37", "7V", "6L37", "5L41", "4L41", "3L43"],
			hydropump: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			megahorn: ["8M", "8L45", "8V", "7L45", "6L45", "5L57", "4L51", "3L57"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			mudshot: ["8M", "7E", "6E", "5E"],
			mudslap: ["8E", "7E", "6E", "5E", "4T", "4E"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			peck: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			poisonjab: ["8M", "8V", "7M", "6M", "5M", "4M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			quickattack: ["8V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E"],
			skullbash: ["7E", "7V", "6E", "5E"],
			sleeptalk: ["8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "4E", "3T", "3E"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			soak: ["8L40", "7L40", "6L40", "5L51"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			supersonic: ["8L5", "8V", "7L5", "7V", "6L5", "5L7", "4L7", "3L10"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			swordsdance: ["8M"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			throatchop: ["8M", "7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "8L35", "8V", "7M", "7L32", "7V", "6M", "6L32", "5M", "5L37", "4M", "4L37", "3M", "3L38"],
			watergun: ["7V"],
			waterpulse: ["8L10", "7T", "7L16", "6T", "6L16", "5L17", "5D", "4M", "4L17", "3M"],
			watersport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			whirlpool: ["8M", "4M"],
		},
		encounters: [
			{generation: 1, level: 5},
		],
	},
	seaking: {
		learnset: {
			agility: ["8M", "8L20", "8V", "7L29", "7V", "6L29", "5L56", "4L56", "3L61"],
			aquaring: ["8L25", "7L21", "6L21", "5L27", "4L27"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["8M", "8V", "7T", "6T", "5T"],
			endure: ["8M", "7V", "5D", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L30", "7L13", "7V", "6L13", "5L21", "4L21", "3L24"],
			flipturn: ["8T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8V", "7L24", "7V", "6L24", "5L31", "4L31", "3L29"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hornattack: ["8L15", "8V", "7L8", "7V", "6L8", "5L11", "4L11", "3L15"],
			horndrill: ["8L58", "8V", "7L40", "7V", "6L40", "5L47", "4L47", "3L49"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			megahorn: ["8M", "8L51", "8V", "7L1", "6L1", "5L72", "4L63", "3L69"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			peck: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			poisonjab: ["8M", "8V", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8V"],
			quickattack: ["8V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T"],
			skullbash: ["8V", "7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "5D", "4M", "3T"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			soak: ["8L44", "7L46", "6L46", "5L63"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			supersonic: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			swordsdance: ["8M"],
			tailwhip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			throatchop: ["8M", "7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "8L37", "8V", "7M", "7L32", "7V", "6M", "6L32", "5M", "5L40", "4M", "4L40", "3M", "3L41"],
			watergun: ["7V"],
			waterpulse: ["8L1", "7T", "7L16", "6T", "6L16", "5L17", "5D", "4M", "4L17", "3M"],
			watersport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			whirlpool: ["8M", "4M"],
		},
		encounters: [
			{generation: 1, level: 23},
			{generation: 2, level: 10},
			{generation: 3, level: 20},
			{generation: 4, level: 10},
			{generation: 6, level: 26, maxEggMoves: 1},
			{generation: 7, level: 10},
		],
	},
	staryu: {
		learnset: {
			attract: ["7V"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			brine: ["8M", "8L28", "7L28", "6L28", "5L36", "4M"],
			bubblebeam: ["8V", "7L18", "7V", "6L18", "5L28", "4L28", "3L28"],
			camouflage: ["7L22", "6L15", "5L19", "4L19", "3L19"],
			confide: ["7M", "6M"],
			confuseray: ["8L8", "8V", "7L40", "6L40"],
			cosmicpower: ["8M", "8L52", "7L49", "6L48", "5L55", "4L51", "3L42", "3S0"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "8V", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["8M", "7M", "7L24", "6M", "6L24", "5M", "5L37", "4M", "4L37"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L56", "8V", "7L53", "7V", "6L52", "5L60", "4L55", "3L46", "3S0"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			lightscreen: ["8M", "8L32", "8V", "7M", "7L46", "7V", "6M", "6L33", "5M", "5L42", "4M", "4L42", "3M", "3L37", "3S0"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["7V", "3T"],
			minimize: ["8L16", "8V", "7L31", "7V", "6L25", "5L33", "4L33", "3L33", "3S0"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powergem: ["8M", "8L36", "7L37", "6L37", "5L51", "4L46"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L24"],
			psychic: ["8M", "8L40", "8V", "7M", "7L42", "7V", "6M", "6L42", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psywave: ["8V", "7L13", "7V", "6L13"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rapidspin: ["8L12", "7L7", "7V", "6L7", "5L10", "4L10", "3L10", "3S1"],
			recover: ["8L48", "8V", "7L10", "7V", "6L10", "5L15", "4L15", "3L15", "3S1"],
			recycle: ["7T", "6T", "5T", "5D", "4M"],
			reflect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflecttype: ["7L35", "6L35", "5L46"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8L44", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8L20", "8V", "7L16", "7V", "6L16", "5L24", "4T", "4L24", "3T", "3L24"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			teleport: ["8V", "7V"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			twister: ["4T"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L4", "8V", "7L4", "7V", "6L4", "5L6", "5D", "4L6", "3L6", "3S1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 50, moves: ["minimize", "lightscreen", "cosmicpower", "hydropump"], pokeball: "pokeball"},
			{generation: 3, level: 18, nature: "Timid", ivs: {hp: 10, atk: 3, def: 22, spa: 24, spd: 3, spe: 18}, abilities: ["illuminate"], moves: ["harden", "watergun", "rapidspin", "recover"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 5},
		],
	},
	starmie: {
		learnset: {
			agility: ["8M"],
			allyswitch: ["8M", "7T"],
			attract: ["7V"],
			avalanche: ["8M", "4M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			brine: ["8M", "8L1", "4M"],
			bubblebeam: ["7V"],
			confide: ["7M", "6M"],
			confuseray: ["8L1", "7L40", "7V", "6L22", "5L28", "4L28", "3L33"],
			cosmicpower: ["8M", "8L1"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "8V", "7M", "6M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			endure: ["8M", "7V", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "8V", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["8L1", "8V", "7V"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L1", "7L1", "6L1"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			lightscreen: ["8M", "8L1", "8V", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			meteorbeam: ["8T"],
			mimic: ["7V", "3T"],
			minimize: ["8L1"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powergem: ["8M", "8L1"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L1"],
			psychic: ["8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychocut: ["8M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["8V", "7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rapidspin: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			recover: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spotlight: ["7L1"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8L1", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8L1", "7L1", "7V", "6L1", "5L1", "4T", "4L1", "3T", "3L1"],
			tackle: ["8L1", "8V", "7V"],
			takedown: ["7V"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			twister: ["4T"],
			waterfall: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			watergun: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 41, moves: ["refresh", "waterfall", "icebeam", "recover"]},
		],
	},
	mimejr: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			barrier: ["7L1", "6L1", "5L1", "4L1"],
			batonpass: ["8M", "8L4", "7L46", "6L46", "5L46", "4L46"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M", "7E", "6E", "5E", "4E"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E", "4E"],
			confusion: ["8L12", "7L1", "6L1", "5L1", "4L1"],
			copycat: ["8L1", "7L4", "6L4", "5L4", "4L4"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["8M", "8L44"],
			doubleslap: ["7L11", "6L11", "5L15", "4L15"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			encore: ["8M", "8L8", "7L18", "6L18", "5L11", "4L11"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			fakeout: ["8E", "7E", "6E", "5E", "4E"],
			flash: ["6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["8M", "7E", "6E", "5E", "4E"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			healingwish: ["7E", "6E", "5E", "4E"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hypnosis: ["8E", "7E", "6E", "5E", "4E"],
			icywind: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			infestation: ["7M", "6M"],
			lightscreen: ["8M", "8L36", "7M", "7L22", "6M", "6L22", "5M", "5L22", "4M", "4L22"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			meditate: ["7L8", "6L8", "5L8", "4L8"],
			mimic: ["8L32", "7L15", "7E", "6L15", "6E", "5L18", "5E", "4L18", "4E"],
			mistyterrain: ["8M"],
			mudslap: ["4T"],
			nastyplot: ["8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			pound: ["8L1", "7L1"],
			powersplit: ["8E", "7E", "6E", "5E"],
			protect: ["8M", "8L20", "7M", "6M", "5M", "4M"],
			psybeam: ["8L28", "7L25", "6L25", "5L25", "4L25"],
			psychic: ["8M", "8L48", "7M", "7L39", "6M", "6L39", "5M", "5L39", "4M", "4L39"],
			psychicterrain: ["8M", "7E"],
			psychup: ["7M", "6M", "5M", "4M", "4E"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			recycle: ["8L24", "7T", "7L32", "6T", "6L32", "5T", "5L32", "4M", "4L32"],
			reflect: ["8M", "8L36", "7M", "7L22", "6M", "6L22", "5M", "5L22", "4M", "4L22"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roleplay: ["8L16", "7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L43"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L36", "7M", "7L50", "6M", "6L50", "5M", "5L50", "4M", "4L50"],
			secretpower: ["6M", "4M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "7L29", "6M", "6L29", "5M", "5L29", "4M", "4L29"],
			suckerpunch: ["8L40"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			taunt: ["8M", "7M", "6M", "5M", "4M"],
			teeterdance: ["8L52", "7E", "6E", "5E", "4E"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			thunder: ["8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M"],
			tickle: ["8E", "7L1", "6L1", "5L1", "4L1"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["8M", "7T", "7L36", "7E", "6T", "6L36", "6E", "5T", "5L36", "5E", "4T", "4L36", "4E"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			wakeupslap: ["7E", "6E", "5E", "4E"],
			wonderroom: ["8M", "7T", "6T", "5T"],
		},
	},
	mrmime: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			batonpass: ["8M", "8L1", "7L46", "7V", "6L46", "5L46", "4L46", "3L47"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			calmmind: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E", "4E"],
			confusion: ["8L12", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L5"],
			copycat: ["8L1", "7L4", "6L4", "5L4", "4L4"],
			counter: ["7V", "3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "8L44", "8V", "7M", "6M"],
			doubleedge: ["7V", "3T"],
			doubleslap: ["8V", "7L11", "7V", "6L11", "5L15", "4L15", "3L15"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V"],
			encore: ["8M", "8L1", "8V", "7L18", "7V", "6L18", "5L11", "4L11", "3L25", "3S0"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8E", "7E", "6E", "5E", "4E", "3E"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["3S0"],
			foulplay: ["8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			guardswap: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			lightscreen: ["8M", "8L36", "8V", "7M", "7L22", "7V", "6M", "6L22", "5M", "5L22", "4M", "4L22", "3M", "3L19"],
			magicalleaf: ["8M", "7L1", "6L1", "5L1", "4L1", "3L22"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			meditate: ["8V", "7L8", "7V", "6L8", "5L8", "4L8", "3L12"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["8L32", "8V", "7L15", "7E", "7V", "6L15", "6E", "5L18", "5E", "4L18", "4E", "3T", "3E"],
			mistyterrain: ["8M", "7L1", "6L1"],
			mudslap: ["7V", "4T", "3T"],
			mysticalfire: ["8M"],
			nastyplot: ["8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pound: ["8L1", "8V", "7L1"],
			powersplit: ["8E", "7E", "6E", "5E"],
			powerswap: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L20", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L28", "8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L29"],
			psychic: ["8M", "8L48", "8V", "7M", "7L39", "7V", "6M", "6L39", "5M", "5L39", "4M", "4L39", "3M", "3L43", "3S0"],
			psychicterrain: ["8M", "7E"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["8V", "7L15", "7V", "6L15", "5L15"],
			quickguard: ["8L1", "7L1", "6L1", "5L1"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["8L24", "7T", "7L32", "6T", "6L32", "5T", "5L32", "4M", "4L32", "3L33"],
			reflect: ["8M", "8L36", "8V", "7M", "7L22", "7V", "6M", "6L22", "5M", "5L22", "4M", "4L22", "3M", "3L19"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["8L16", "7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L43", "3L40"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L36", "7M", "7L50", "7V", "6M", "6L50", "5M", "5L50", "4M", "4L50", "3M", "3L50"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "5D", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			storedpower: ["8M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7L29", "7V", "6M", "6L29", "5M", "5L29", "4M", "4L29", "3T", "3L8"],
			suckerpunch: ["8L40"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			teeterdance: ["8L52", "7E", "6E", "5E", "5D", "4E"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T", "3S0"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			tickle: ["8E"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "7L36", "7E", "6T", "6L36", "6E", "5T", "5L36", "5E", "4T", "4L36", "4E", "3L36", "3E"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M"],
			wakeupslap: ["7E", "6E", "5E", "4E"],
			wideguard: ["8L1", "7L1", "6L1", "5L1"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 42, abilities: ["soundproof"], moves: ["followme", "psychic", "encore", "thunderpunch"]},
		],
		encounters: [
			{generation: 1, level: 6},
		],
	},
	mrmimegalar: {
		learnset: {
			allyswitch: ["8M", "8L16"],
			attract: ["8M"],
			avalanche: ["8M"],
			batonpass: ["8M", "8L1"],
			blizzard: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M"],
			calmmind: ["8M"],
			charm: ["8M"],
			confuseray: ["8E"],
			confusion: ["8L12", "8S0"],
			copycat: ["8L1", "8S0"],
			dazzlinggleam: ["8M", "8L1"],
			doublekick: ["8L24"],
			drainpunch: ["8M"],
			encore: ["8M", "8L1", "8S0"],
			endure: ["8M"],
			energyball: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M"],
			fakeout: ["8E"],
			fling: ["8M"],
			focusblast: ["8M"],
			foulplay: ["8M"],
			freezedry: ["8L44"],
			futuresight: ["8M"],
			gigaimpact: ["8M"],
			grassknot: ["8M"],
			guardswap: ["8M"],
			hail: ["8M"],
			helpinghand: ["8M"],
			hyperbeam: ["8M"],
			hypnosis: ["8L32"],
			icebeam: ["8M"],
			icepunch: ["8M"],
			iceshard: ["8L1", "8S0"],
			iciclespear: ["8M"],
			icywind: ["8M", "8L20"],
			irondefense: ["8M"],
			lightscreen: ["8M", "8L1"],
			magicroom: ["8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["8M"],
			mimic: ["8L1"],
			mirrorcoat: ["8L36"],
			mistyterrain: ["8M", "8L1"],
			nastyplot: ["8M"],
			payback: ["8M"],
			pound: ["8L1"],
			powersplit: ["8E"],
			powerswap: ["8M"],
			protect: ["8M", "8L1"],
			psybeam: ["8L28"],
			psychic: ["8M", "8L48"],
			psychicterrain: ["8M"],
			psyshock: ["8M"],
			raindance: ["8M"],
			rapidspin: ["8L1"],
			recycle: ["8L1"],
			reflect: ["8M", "8L1"],
			rest: ["8M"],
			roleplay: ["8L1"],
			round: ["8M"],
			safeguard: ["8M", "8L1"],
			screech: ["8M"],
			shadowball: ["8M"],
			skillswap: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			stompingtantrum: ["8M"],
			storedpower: ["8M"],
			substitute: ["8M"],
			suckerpunch: ["8L40"],
			sunnyday: ["8M"],
			taunt: ["8M"],
			teeterdance: ["8L52"],
			thief: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderwave: ["8M"],
			tickle: ["8E"],
			trick: ["8M"],
			trickroom: ["8M"],
			tripleaxel: ["8T"],
			uproar: ["8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["8M"],
		},
		eventData: [
			{generation: 8, level: 15, isHidden: true, moves: ["copycat", "encore", "iceshard", "confusion"], pokeball: "cherishball"},
		],
	},
	mrrime: {
		learnset: {
			afteryou: ["8L1"],
			allyswitch: ["8M", "8L16"],
			attract: ["8M"],
			avalanche: ["8M"],
			batonpass: ["8M", "8L1"],
			blizzard: ["8M"],
			block: ["8L1"],
			bodyslam: ["8M"],
			brickbreak: ["8M"],
			calmmind: ["8M"],
			charm: ["8M"],
			confusion: ["8L12"],
			copycat: ["8L1"],
			dazzlinggleam: ["8M", "8L1"],
			doublekick: ["8L24"],
			drainpunch: ["8M"],
			encore: ["8M", "8L1"],
			endure: ["8M"],
			energyball: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M"],
			faketears: ["8M", "8L1"],
			fling: ["8M"],
			focusblast: ["8M"],
			foulplay: ["8M"],
			freezedry: ["8L44"],
			futuresight: ["8M"],
			gigaimpact: ["8M"],
			grassknot: ["8M"],
			guardswap: ["8M"],
			hail: ["8M"],
			helpinghand: ["8M"],
			hyperbeam: ["8M"],
			hypnosis: ["8L32"],
			icebeam: ["8M"],
			icepunch: ["8M"],
			iceshard: ["8L1"],
			iciclespear: ["8M"],
			icywind: ["8M", "8L20"],
			irondefense: ["8M"],
			lightscreen: ["8M", "8L1"],
			magicroom: ["8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["8M"],
			mimic: ["8L1"],
			mirrorcoat: ["8L36"],
			mistyterrain: ["8M", "8L1"],
			nastyplot: ["8M"],
			payback: ["8M"],
			pound: ["8L1"],
			powerswap: ["8M"],
			protect: ["8M", "8L1"],
			psybeam: ["8L28"],
			psychic: ["8M", "8L48"],
			psychicterrain: ["8M"],
			psyshock: ["8M"],
			raindance: ["8M"],
			rapidspin: ["8L1"],
			recycle: ["8L1"],
			reflect: ["8M", "8L1"],
			rest: ["8M"],
			roleplay: ["8L1"],
			round: ["8M"],
			safeguard: ["8M", "8L1"],
			screech: ["8M"],
			shadowball: ["8M"],
			skillswap: ["8M"],
			slackoff: ["8L1"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			stompingtantrum: ["8M"],
			storedpower: ["8M"],
			substitute: ["8M"],
			suckerpunch: ["8L40"],
			sunnyday: ["8M"],
			taunt: ["8M"],
			teeterdance: ["8L52"],
			thief: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderwave: ["8M"],
			trick: ["8M"],
			trickroom: ["8M"],
			tripleaxel: ["8T"],
			uproar: ["8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["8M"],
		},
	},
	scyther: {
		learnset: {
			acrobatics: ["9M", "8M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L32", "8M", "8L32", "8V", "7L17", "7V", "6L17", "5L17", "5S2", "4L17", "3L21"],
			aircutter: ["9M"],
			airslash: ["9M", "9L36", "8M", "8L36", "8V", "7L50", "6L50", "5L53", "4L53"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "8M", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			bide: ["7V"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			brutalswing: ["8M", "7M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["9M", "8M", "7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			closecombat: ["9M"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			crosspoison: ["8M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defog: ["9E", "8E", "7T", "7E", "6E", "5E", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doublehit: ["9L20", "8L20", "7L49", "6L49", "5L49", "4L49"],
			doubleteam: ["9L16", "8L16", "8V", "7M", "7L37", "7V", "6M", "6L37", "5M", "5L37", "4M", "4L37", "3M", "3L41"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M", "7E", "7V", "6E", "5E", "4M", "4E", "3T", "3E"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M", "9L8", "8M", "8L8", "7M", "7L13", "7V", "6M", "6L13", "5M", "5L13", "4M", "4L13", "3L16"],
			feint: ["9E", "8E", "8V", "7L61", "6L61", "5L61", "4L61"],
			focusenergy: ["9L28", "8M", "8L28", "8V", "7L5", "7V", "6L5", "5L5", "4L5", "3L6", "3S0"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["9L4", "8L4", "7L25", "7V", "6L25", "5L25", "5S2", "4T", "4L25", "3T", "3L46"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["8L44", "7T"],
			leer: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			lightscreen: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4E", "3E"],
			mimic: ["7V", "3T"],
			morningsun: ["3S1"],
			naturalgift: ["4M"],
			nightslash: ["9E", "8E", "7L45", "7E", "6L45", "6E", "5L45", "5E", "4L45", "4E"],
			ominouswind: ["4T"],
			pounce: ["9M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychocut: ["8M"],
			pursuit: ["7L9", "7V", "6L9", "5L9", "4L9", "3L11"],
			quickattack: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			quickguard: ["9E", "8E", "7E", "6E"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			razorwind: ["8V", "7L33", "7E", "7V", "6L33", "6E", "5L33", "5E", "4L33", "4E", "3E", "3S1"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roost: ["8V", "7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			silverwind: ["7E", "6E", "5E", "4M", "4E", "3E", "3S1"],
			skullbash: ["7V"],
			slash: ["9L24", "8L24", "8V", "7L29", "7V", "6L29", "5L29", "5S2", "4L29", "3L31", "3S1"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["8M", "7M", "7E", "7V", "6M", "6E", "5E", "4M", "3M"],
			strugglebug: ["9M", "6M", "5M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			swordsdance: ["9M", "9L44", "8M", "8L48", "8V", "7M", "7L57", "7V", "6M", "6L57", "5M", "5L57", "4M", "4L57", "3T", "3L36"],
			tailwind: ["9M", "7T", "6T", "5T", "5D", "4T"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			vacuumwave: ["7L1", "6L1", "5L1", "4L1"],
			wingattack: ["9L12", "8L12", "8V", "7L21", "7V", "6L21", "5L21", "5S2", "4L21", "3L26"],
			xscissor: ["9M", "9L40", "8M", "8L40", "8V", "7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L41"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", abilities: ["swarm"], moves: ["quickattack", "leer", "focusenergy"], pokeball: "pokeball"},
			{generation: 3, level: 40, abilities: ["swarm"], moves: ["morningsun", "razorwind", "silverwind", "slash"]},
			{generation: 5, level: 30, moves: ["agility", "wingattack", "furycutter", "slash"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 15},
			{generation: 1, level: 25},
		],
	},
	scizor: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			aerialace: ["9M", "7M", "6M", "6S5", "5M", "4M", "3M"],
			agility: ["9M", "9L1", "8M", "8L1", "7L17", "7V", "6L17", "6S5", "6S6", "5L17", "4L17", "4S1", "3L21"],
			aircutter: ["9M"],
			airslash: ["9M", "9L1", "8M", "8L1"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			brutalswing: ["8M", "7M"],
			bugbite: ["7T", "6T", "5T", "5S2", "4T"],
			bugbuzz: ["9M", "8M"],
			bulletpunch: ["9L0", "8L0", "7L1", "6L1", "6S7", "5L1", "5S2", "4L1"],
			captivate: ["4M"],
			closecombat: ["9M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crosspoison: ["8M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doublehit: ["9L20", "8L20", "7L49", "6L49", "6S4", "5L49", "4L49"],
			doubleteam: ["9L16", "8L16", "7M", "7V", "6M", "5M", "4M", "3M", "3L41"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M", "9L1", "8M", "8L1", "7M", "7L13", "7V", "6M", "6L13", "6S5", "6S6", "5M", "5L13", "4M", "4L13", "3L16"],
			feint: ["7L1", "6L1", "5L61", "4L61"],
			flashcannon: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L28", "8M", "8L28", "7L5", "7V", "6L5", "5L5", "5S3", "4L5", "3L6"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["9L1", "8L1", "7L25", "7V", "6L25", "6S5", "6S6", "5L25", "4T", "4L25", "3T", "3L46", "3S0"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			irondefense: ["9M", "9L32", "8M", "8L32", "7T", "7L37", "6T", "6L37", "5T", "5L37", "4T", "4L37", "4S1", "3L41"],
			ironhead: ["9M", "9L36", "8M", "8L36", "7T", "7L50", "6T", "6L50", "6S4", "5T", "5L53", "4T", "4L53"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["8L44", "7T"],
			leer: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5S3", "4L1", "3L1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			metalclaw: ["9M", "9L12", "8L12", "7L21", "7V", "6L21", "6S6", "5L21", "4L21", "3L26", "3S0"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			nightslash: ["7L45", "6L45", "6S4", "5L45", "4L45"],
			ominouswind: ["4T"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychocut: ["8M"],
			pursuit: ["7L9", "7V", "6L9", "5L9", "5S3", "4L9", "3L11"],
			quickattack: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7L33", "6L33", "5L33", "4L33"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "8M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "6S7", "5T", "5S2", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			silverwind: ["4M"],
			slash: ["9L24", "8L24", "7L29", "7V", "6L29", "5L29", "4L29", "3L31", "3S0"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			steelbeam: ["9M", "8T"],
			steelwing: ["8M", "7M", "7V", "6M", "5S3", "4M", "3M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			strugglebug: ["9M", "6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			swordsdance: ["9M", "9L44", "8M", "8L48", "7M", "7L57", "7V", "6M", "6L57", "6S7", "5M", "5L57", "5S2", "4M", "4L57", "4S1", "3T", "3L36", "3S0"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M", "7M", "6M", "6S7", "5M", "4M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			wingattack: ["9L1", "8L1"],
			xscissor: ["9M", "9L40", "8M", "8L40", "7M", "7L41", "6M", "6L41", "6S4", "5M", "5L41", "4M", "4L41", "4S1"],
		},
		eventData: [
			{generation: 3, level: 50, gender: "M", abilities: ["swarm"], moves: ["furycutter", "metalclaw", "swordsdance", "slash"], pokeball: "pokeball"},
			{generation: 4, level: 50, gender: "M", nature: "Adamant", abilities: ["swarm"], moves: ["xscissor", "swordsdance", "irondefense", "agility"], pokeball: "cherishball"},
			{generation: 5, level: 100, gender: "M", abilities: ["technician"], moves: ["bulletpunch", "bugbite", "roost", "swordsdance"], pokeball: "cherishball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["leer", "focusenergy", "pursuit", "steelwing"]},
			{generation: 6, level: 50, gender: "M", moves: ["xscissor", "nightslash", "doublehit", "ironhead"], pokeball: "cherishball"},
			{generation: 6, level: 25, nature: "Adamant", abilities: ["technician"], moves: ["aerialace", "falseswipe", "agility", "furycutter"], pokeball: "cherishball"},
			{generation: 6, level: 25, moves: ["metalclaw", "falseswipe", "agility", "furycutter"], pokeball: "cherishball"},
			{generation: 6, level: 50, abilities: ["technician"], moves: ["bulletpunch", "swordsdance", "roost", "uturn"], pokeball: "cherishball"},
		],
	},
	kleavor: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			agility: ["9M", "9L32"],
			aircutter: ["9M"],
			airslash: ["9M"],
			batonpass: ["9M"],
			brickbreak: ["9M"],
			bugbuzz: ["9M"],
			closecombat: ["9M"],
			doublehit: ["9L20"],
			doubleteam: ["9L16"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M", "9L8"],
			focusenergy: ["9L28"],
			furycutter: ["9L4"],
			gigaimpact: ["9M"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			leer: ["9L1"],
			lightscreen: ["9M"],
			pounce: ["9M"],
			protect: ["9M"],
			quickattack: ["9L1"],
			rest: ["9M"],
			reversal: ["9M"],
			rockblast: ["9M"],
			rockslide: ["9M", "9L36"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			slash: ["9L24"],
			sleeptalk: ["9M"],
			smackdown: ["9L12"],
			stealthrock: ["9M"],
			stoneaxe: ["9L0"],
			stoneedge: ["9M"],
			strugglebug: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			swordsdance: ["9M", "9L44"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M"],
			xscissor: ["9M", "9L40"],
		},
	},
	smoochum: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			auroraveil: ["7M"],
			avalanche: ["8M", "7L35", "6L35", "5L35", "4M", "4L31"],
			blizzard: ["8M", "8L48", "7M", "7L48", "7V", "6M", "6L48", "5M", "5L48", "4M", "4L45", "3M", "3L57"],
			bodyslam: ["8M", "3T"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["7E", "6E", "5E", "5D", "4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			confusion: ["8L12", "7L15", "7V", "6L15", "5L15", "4L15", "3L21"],
			copycat: ["8L8", "7L41", "6L41", "5L41", "4L38"],
			counter: ["3T"],
			covet: ["8L16", "7T", "6T", "5T"],
			curse: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8E", "7E", "6E", "5E", "4E", "3E"],
			faketears: ["8M", "8L24", "7L28", "6L28", "5L28", "4L25", "3L37"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			healbell: ["7T", "6T", "5T", "4T"],
			heartstamp: ["7L21", "6L21", "5L21"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8L28", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			lick: ["8L1", "7L5", "7V", "6L5", "5L5", "4L5", "3L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7L31", "6L31", "5L31", "4L28"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			meanlook: ["8L40", "7L25", "7V", "6L25", "5L25", "4L21", "3L33"],
			meditate: ["7E", "7V", "6E", "5E", "4E", "3E"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["8M", "3T"],
			mimic: ["3T"],
			miracleeye: ["7E", "6E", "5E", "4E"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			perishsong: ["8L44", "7L45", "7V", "6L45", "5L45", "4L41", "3L49"],
			pound: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			powdersnow: ["8L4", "7L11", "7V", "6L11", "5L11", "4L11", "3L13"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L32", "7M", "7L38", "7V", "6M", "6L38", "5M", "5L38", "4M", "4L35", "3M", "3L45"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["8E", "7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["8L20", "7L18", "7V", "6L18", "5L18", "4L18", "3L25"],
			skillswap: ["8M", "7T", "6T", "5T", "5D", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["8L36", "7L8", "7V", "6L8", "5L8", "5D", "4L8", "3L9"],
			sweetscent: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			wakeupslap: ["7E", "6E", "5E"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wish: ["8E", "7E", "6E", "5E", "4E", "3E"],
			wonderroom: ["8M", "7T"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	jynx: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			auroraveil: ["7M"],
			avalanche: ["8M", "7L39", "6L39", "5L39", "4M", "4L33"],
			bide: ["7V"],
			blizzard: ["8M", "8L58", "8V", "7M", "7L60", "7V", "6M", "6L60", "5M", "5L60", "4M", "4L55", "3M", "3L67"],
			bodyslam: ["8M", "8V", "7L44", "7V", "6L44", "5L44", "4L39", "3T", "3L51"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			calmmind: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			confusion: ["8L12", "8V"],
			copycat: ["8L1"],
			counter: ["7V", "3T"],
			covet: ["8L16", "7T", "6T", "5T"],
			curse: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleslap: ["8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L21"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M", "7L1", "6L1"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["8M", "8L24", "7L28", "6L28", "5L28", "4L25", "3L41"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			heartstamp: ["7L21", "6L21", "5L21"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8L28", "8V", "7T", "7L18", "7V", "6T", "6L18", "5T", "5L18", "4T", "4L18", "3T", "3L25"],
			iciclespear: ["8M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			lick: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			lovelykiss: ["8L40", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			meanlook: ["8L46", "7L25", "7V", "6L25", "5L25", "4L21", "3L35"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			perishsong: ["8L52", "7L1", "7V", "6L1", "5L55", "4L49", "3L57"],
			pound: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			powdersnow: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L34", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychicterrain: ["8M"],
			psychocut: ["8M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8V"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["8L20"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			storedpower: ["8M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["8L1"],
			sweetscent: ["7V"],
			takedown: ["7V"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["7V"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			tripleaxel: ["8T"],
			uproar: ["8M"],
			wakeupslap: ["7L33", "6L33", "5L33", "4L28"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wonderroom: ["8M", "7T"],
			wringout: ["7L49", "6L49", "5L49", "4L44"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 10},
			{generation: 3, level: 20, nature: "Mild", ivs: {hp: 18, atk: 17, def: 18, spa: 22, spd: 25, spe: 21}, abilities: ["oblivious"], pokeball: "pokeball"},
			{generation: 4, level: 22},
			{generation: 7, level: 9},
		],
	},
	elekid: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			charge: ["8L8"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			crosschop: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E", "3S0"],
			curse: ["7V"],
			detect: ["7V"],
			discharge: ["8L32", "7L33", "6L33", "5L41", "4L34"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["8E", "7E", "7V", "6E", "5E", "4E", "3T"],
			electroball: ["8M", "7L22", "6L22", "5L31"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			feint: ["8E", "7E", "6E", "5E", "4E"],
			firepunch: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E", "3S0"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hammerarm: ["8E", "7E", "6E", "5E"],
			headbutt: ["7V", "4T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E", "3S0"],
			karatechop: ["7E", "7V", "6E", "5E", "4E", "3E"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "8L44", "7M", "7L26", "7V", "6M", "6L26", "5M", "5L26", "4M", "4L25", "3M", "3L17"],
			lowkick: ["8M", "8L36", "7T", "7L8", "6T", "6L8", "5T", "5L11", "4T", "4L10"],
			magnetrise: ["7T", "6T", "5T", "5D", "4T"],
			meditate: ["7E", "7V", "6E", "5E", "4E", "3E"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollingkick: ["7E", "7V", "6E", "5E", "4E", "3E"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L24", "7L36", "7V", "6L36", "5L51", "4L43", "3L33"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shockwave: ["8L16", "7T", "7L15", "6T", "6L15", "5L21", "4M", "4L19", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8L12", "7L12", "7V", "6L12", "5L16", "4T", "4L16", "3T", "3L25"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8L48", "7M", "7L43", "7V", "6M", "6L43", "5M", "5L56", "4M", "4L46", "3M", "3L49"],
			thunderbolt: ["8M", "8L40", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L46", "4M", "4L37", "3M", "3L41"],
			thunderpunch: ["8M", "8L28", "7T", "7L29", "7V", "6T", "6L29", "5T", "5L36", "4T", "4L28", "3T", "3L9", "3S0"],
			thundershock: ["8L4", "7L5", "6L5", "5L6", "5D", "4L7"],
			thunderwave: ["8M", "8L20", "7M", "7L19", "6M", "6L19", "5M", "5L19", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 20, moves: ["icepunch", "firepunch", "thunderpunch", "crosschop"], pokeball: "pokeball"},
		],
	},
	electabuzz: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			charge: ["8L1"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			counter: ["7V", "3T"],
			covet: ["7T", "6T", "5T"],
			crosschop: ["3S1"],
			curse: ["7V"],
			detect: ["7V"],
			discharge: ["8L34", "7L36", "6L36", "5L44", "4L37"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["7V", "3T"],
			electroball: ["8M", "7L22", "6L22", "5L32"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["3S1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "8L64", "7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			lightscreen: ["8M", "8L52", "8V", "7M", "7L26", "7V", "6M", "6L26", "6S4", "5M", "5L26", "5S3", "4M", "4L25", "4S2", "3M", "3L17"],
			lowkick: ["8M", "8L40", "8V", "7T", "7L8", "6T", "6L8", "6S4", "5T", "5L11", "5S3", "4T", "4L10", "4S2"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["7V"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psywave: ["7V"],
			quickattack: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L24", "8V", "7L42", "7V", "6L42", "5L56", "4L52", "3L36"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shockwave: ["8L16", "7T", "7L15", "6T", "6L15", "6S4", "5L21", "5S3", "4M", "4L19", "4S2", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8L12", "8V", "7L12", "7V", "6L12", "5L16", "5S3", "4T", "4L16", "3T", "3L25"],
			takedown: ["7V"],
			taunt: ["8V"],
			teleport: ["8V", "7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8L58", "8V", "7M", "7L55", "7V", "6M", "6L55", "5M", "5L62", "4M", "4L58", "3M", "3L58"],
			thunderbolt: ["8M", "8L46", "8V", "7M", "7L49", "7V", "6M", "6L49", "5M", "5L50", "4M", "4L43", "3M", "3L47", "3S1"],
			thunderpunch: ["8M", "8L28", "8V", "7T", "7L29", "7V", "6T", "6L29", "6S4", "5T", "5L38", "4T", "4L28", "4S2", "3T", "3L1", "3S0"],
			thundershock: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1"],
			thunderwave: ["8M", "8L20", "8V", "7M", "7L19", "7V", "6M", "6L19", "5M", "5L19", "4M", "3T", "3S1"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["quickattack", "leer", "thunderpunch"], pokeball: "pokeball"},
			{generation: 3, level: 43, moves: ["followme", "crosschop", "thunderwave", "thunderbolt"]},
			{generation: 4, level: 30, gender: "M", nature: "Naughty", moves: ["lowkick", "shockwave", "lightscreen", "thunderpunch"], pokeball: "pokeball"},
			{generation: 5, level: 30, moves: ["lowkick", "swift", "shockwave", "lightscreen"], pokeball: "cherishball"},
			{generation: 6, level: 30, gender: "M", isHidden: true, moves: ["lowkick", "shockwave", "lightscreen", "thunderpunch"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 33},
			{generation: 2, level: 15},
			{generation: 4, level: 15},
			{generation: 7, level: 25},
		],
	},
	electivire: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charge: ["8L1"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crosschop: ["4S0"],
			darkestlariat: ["8M"],
			dig: ["8M", "6M", "5M", "4M"],
			discharge: ["8L34", "7L36", "6L36", "5L44", "4L37", "4S1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dualchop: ["7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "4S0"],
			electricterrain: ["8M", "7L1", "6L1"],
			electroball: ["8M", "7L22", "6L22", "5L32"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			firepunch: ["8M", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["8M", "8L64", "7M", "7L62", "6M", "6L62", "5M", "5L68", "4M", "4L67"],
			headbutt: ["4T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "4S0"],
			iondeluge: ["7L1", "6L1"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			lightscreen: ["8M", "8L52", "7M", "7L26", "6M", "6L26", "5M", "5L26", "4M", "4L25", "4S1"],
			lowkick: ["8M", "8L40", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychic: ["8M", "7M", "6M", "5M", "4M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			risingvoltage: ["8T"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L24", "7L42", "6L42", "5L56", "4L52"],
			secretpower: ["6M", "4M"],
			shockwave: ["8L16", "7T", "7L15", "6T", "6L15", "5L21", "4M", "4L19"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["8M", "8L12", "7L12", "6L12", "5L16", "4T", "4L16"],
			taunt: ["8M", "7M", "6M", "5M", "4M"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			thunder: ["8M", "8L58", "7M", "7L55", "6M", "6L55", "5M", "5L62", "4M", "4L58"],
			thunderbolt: ["8M", "8L46", "7M", "7L49", "6M", "6L49", "5M", "5L50", "4M", "4L43", "4S1"],
			thunderpunch: ["8M", "8L28", "7T", "7L29", "6T", "6L29", "5T", "5L38", "4T", "4L28", "4S0", "4S1"],
			thundershock: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			thunderwave: ["8M", "8L20", "7M", "7L19", "6M", "6L19", "5M", "5L19", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["8M"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			weatherball: ["8M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
		},
		eventData: [
			{generation: 4, level: 50, gender: "M", nature: "Adamant", moves: ["thunderpunch", "icepunch", "crosschop", "earthquake"], pokeball: "pokeball"},
			{generation: 4, level: 50, gender: "M", nature: "Serious", moves: ["lightscreen", "thunderpunch", "discharge", "thunderbolt"], pokeball: "cherishball"},
		],
	},
	magby: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["7E", "7V", "6E", "5E", "4E", "3E"],
			belch: ["8E", "7E", "6E"],
			bellydrum: ["7E", "6E", "5E", "4E"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			clearsmog: ["8L12", "7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			confuseray: ["8L20", "7L26", "7V", "6L26", "5L25", "4L25", "3L43"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			crosschop: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			curse: ["7V"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["8E", "7E", "7V", "6E", "5E", "4E", "3T"],
			ember: ["8L4", "7L5", "7V", "6L5", "5L7", "5D", "4L7", "3L1"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L12", "6L12", "5L16", "4L16"],
			fireblast: ["8M", "8L48", "7M", "7L43", "7V", "6M", "6L43", "5M", "5L49", "4M", "4L46", "3M", "3L49"],
			firepunch: ["8M", "8L28", "7T", "7L29", "7V", "6T", "6L29", "5T", "5L34", "4T", "4L28", "3T", "3L19"],
			firespin: ["8M", "7L15", "6L15", "5L19", "4L19"],
			flameburst: ["7L22", "6L22", "5L28"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "8L40", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L43", "4M", "4L37", "3M", "3L37"],
			flamewheel: ["8L16"],
			flareblitz: ["8M", "7E", "6E", "5E", "4E"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "7E", "6E", "5E"],
			focuspunch: ["8E", "7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "5D", "4T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			karatechop: ["7E", "7V", "6E", "5E", "4E", "3E"],
			lavaplume: ["8L32", "7L33", "6L33", "5L37", "4L34"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L7"],
			lowkick: ["8M", "8L36"],
			machpunch: ["8E", "7E", "6E", "5E", "4E"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M"],
			powerswap: ["8M", "7E", "6E"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L24"],
			screech: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smog: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L13"],
			smokescreen: ["8L8", "7L8", "7V", "6L8", "5L10", "4L10", "3L25"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "8L44", "7M", "7L36", "7V", "6M", "6L36", "5M", "5L46", "4M", "4L43", "3M", "3L31"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3T", "3E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
		},
	},
	magmar: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			burningjealousy: ["8T"],
			captivate: ["4M"],
			clearsmog: ["8L12", "8V", "7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			confuseray: ["8L20", "8V", "7L26", "7V", "6L26", "6S4", "5L26", "5S3", "4L25", "4S2", "3L49"],
			counter: ["7V", "3T"],
			covet: ["7T", "6T", "5T"],
			crosschop: ["3S1"],
			curse: ["7V"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["7V", "3T"],
			ember: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L12", "6L12", "5L16", "5S3", "4L16"],
			fireblast: ["8M", "8L58", "8V", "7M", "7L55", "7V", "6M", "6L55", "5M", "5L62", "4M", "4L54", "3M", "3L57", "3S1"],
			firepunch: ["8M", "8L28", "8V", "7T", "7L29", "7V", "6T", "6L29", "6S4", "5T", "5L38", "4T", "4L28", "4S2", "3T", "3L1", "3S0"],
			firespin: ["8M", "8V", "7L15", "6L15", "6S4", "5L21", "5S3", "4L19", "4S2"],
			flameburst: ["7L22", "6L22", "5L32"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "8L46", "8V", "7M", "7L49", "7V", "6M", "6L49", "5M", "5L50", "4M", "4L41", "3M", "3L41"],
			flamewheel: ["8L16"],
			flareblitz: ["8M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["3S1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8L64", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lavaplume: ["8L34", "7L36", "6L36", "5L44", "4L36"],
			leer: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			lowkick: ["8M", "8L40", "8V", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["7V"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M"],
			powerswap: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psywave: ["7V"],
			rage: ["7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L24"],
			scorchingsands: ["8T"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smog: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			smokescreen: ["8L1", "8V", "7L8", "7V", "6L8", "6S4", "5L11", "5S3", "4L10", "4S2", "3L25"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "8L52", "7M", "7L42", "7V", "6M", "6L42", "5M", "5L56", "4M", "4L49", "3M", "3L33"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			taunt: ["8V"],
			teleport: ["8V", "7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T", "3S1"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M"],
			willowisp: ["8M", "8V", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["leer", "smog", "firepunch", "ember"], pokeball: "pokeball"},
			{generation: 3, level: 36, moves: ["followme", "fireblast", "crosschop", "thunderpunch"]},
			{generation: 4, level: 30, gender: "M", nature: "Quiet", moves: ["smokescreen", "firespin", "confuseray", "firepunch"], pokeball: "pokeball"},
			{generation: 5, level: 30, moves: ["smokescreen", "feintattack", "firespin", "confuseray"], pokeball: "cherishball"},
			{generation: 6, level: 30, gender: "M", isHidden: true, moves: ["smokescreen", "firespin", "confuseray", "firepunch"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 34},
			{generation: 2, level: 14},
			{generation: 4, level: 14},
			{generation: 7, level: 16},
		],
	},
	magmortar: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			burningjealousy: ["8T"],
			captivate: ["4M"],
			clearsmog: ["8L12", "7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			confuseray: ["8L20", "7L26", "6L26", "5L26", "4L25", "4S1"],
			covet: ["7T", "6T", "5T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dualchop: ["7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M"],
			ember: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			feintattack: ["7L12", "6L12", "5L16", "4L16"],
			fireblast: ["8M", "8L58", "7M", "7L55", "6M", "6L55", "5M", "5L62", "4M", "4L58"],
			firepunch: ["8M", "8L28", "7T", "7L29", "6T", "6L29", "5T", "5L38", "4T", "4L28", "4S1"],
			firespin: ["8M", "7L15", "6L15", "5L21", "4L19"],
			flameburst: ["7L22", "6L22", "5L32"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "8L46", "7M", "7L49", "6M", "6L49", "5M", "5L50", "4M", "4L43", "4S0", "4S1"],
			flamewheel: ["8L16"],
			flareblitz: ["8M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["8M", "8L64", "7M", "7L62", "6M", "6L62", "5M", "5L68", "4M", "4L67", "4S0"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			lavaplume: ["8L34", "7L36", "6L36", "5L44", "4L37", "4S1"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			lowkick: ["8M", "8L40", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudslap: ["4T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M"],
			powerswap: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "4S0"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L24"],
			scorchingsands: ["8T"],
			screech: ["8M"],
			secretpower: ["6M", "4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			smog: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			smokescreen: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "4S0"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "8L52", "7M", "7L42", "6M", "6L42", "5M", "5L56", "4M", "4L52"],
			swagger: ["7M", "6M", "5M", "4M"],
			taunt: ["8M", "7M", "6M", "5M", "4M"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["8M", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["8M"],
			weatherball: ["8M"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 4, level: 50, gender: "F", nature: "Modest", moves: ["flamethrower", "psychic", "hyperbeam", "solarbeam"], pokeball: "pokeball"},
			{generation: 4, level: 50, gender: "M", nature: "Hardy", moves: ["confuseray", "firepunch", "lavaplume", "flamethrower"], pokeball: "cherishball"},
		],
	},
	pinsir: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["8L8", "8V", "7T", "7L4", "7V", "6T", "6L4", "5T", "5L4", "4L4", "3L7"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "7L26", "6M", "6L18", "5M", "5L21", "4M", "4L21", "3M", "3L31"],
			brutalswing: ["8M", "7M"],
			bugbite: ["8L16", "7T", "7E", "6T", "6E", "5T", "5E"],
			bulkup: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["8M", "7E", "6E", "5E", "5D", "4E"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7V", "3T"],
			doublehit: ["8L24", "7L22", "6L22"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthquake: ["8M", "8V", "7M", "6M", "6S1", "6S2", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M", "4E", "3E", "3S0"],
			feint: ["8E", "7E", "6E", "6S2", "5E", "4E"],
			feintattack: ["7E", "6E", "5E", "4E", "3E"],
			flail: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L4", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			guillotine: ["8L48", "8V", "7L50", "7V", "6L47", "5L47", "4L47", "3L37", "3S0"],
			harden: ["8L1", "8V", "7L11", "7V", "6L11", "5L13", "4L13", "3L19"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["8M", "8V", "3S0"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			mefirst: ["7E", "6E", "5E", "5D"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			outrage: ["8V"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["8E", "7E", "6E", "6S2", "5E", "4E"],
			rage: ["7V"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "6S1", "5M", "4M", "3M"],
			revenge: ["8M", "7L15", "6L15", "5L18", "4L18", "3L25"],
			reversal: ["8M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8L12", "8V", "7L8", "7V", "6L8", "5L8", "4L8", "3T", "3L13"],
			slash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stoneedge: ["8M", "7M", "6M", "6S1", "5M", "4M"],
			stormthrow: ["8L20", "7L36", "6L33", "5L33"],
			strength: ["8L36", "7V", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			submission: ["8L44", "8V", "7L33", "7V", "6L26", "5L42", "4L42", "3L43", "3S0"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8L52", "8V", "7T", "7L47", "7E", "6T", "6L43", "6E", "5T", "5L52", "5E", "4T", "4L52"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "8L40", "8V", "7M", "7L40", "7V", "6M", "6L40", "6S2", "5M", "5L38", "4M", "4L38", "3T", "3L49"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["8E", "8V", "7L43", "6L36", "5L35", "4L35"],
			throatchop: ["8M", "7T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			visegrip: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			vitalthrow: ["8L28", "7L18", "6L18", "5L25", "4L25"],
			xscissor: ["8M", "8L32", "8V", "7M", "7L29", "6M", "6L29", "6S1", "5M", "5L30", "4M", "4L30"],
		},
		eventData: [
			{generation: 3, level: 35, abilities: ["hypercutter"], moves: ["helpinghand", "guillotine", "falseswipe", "submission"]},
			{generation: 6, level: 50, gender: "F", nature: "Adamant", moves: ["xscissor", "earthquake", "stoneedge", "return"], pokeball: "cherishball"},
			{generation: 6, level: 50, nature: "Jolly", isHidden: true, moves: ["earthquake", "swordsdance", "feint", "quickattack"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 15},
			{generation: 1, level: 20},
		],
	},
	tauros: {
		learnset: {
			assurance: ["9L15", "8M", "8L15"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T", "3S2"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["9M", "8M"],
			confide: ["7M", "6M"],
			curse: ["9E", "7V"],
			dig: ["9M"],
			doubleedge: ["9L55", "8L55", "8V", "7L63", "7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthquake: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S2"],
			endeavor: ["9E", "7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			fissure: ["7V"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["8V"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "9L60", "8M", "8L60", "7M", "7L63", "6M", "6L63", "5M", "5L63", "4M", "4L55"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hornattack: ["9L20", "8L20", "8V", "7L8", "7V", "6L8", "5L8", "4L8", "3L8", "3S0", "3S1"],
			horndrill: ["7V"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "5D", "4M", "3M"],
			lashout: ["8T"],
			leer: ["8V", "7V"],
			megahorn: ["8M"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			payback: ["9L10", "8M", "8L10", "7M", "7L24", "6M", "6L24", "5M", "5L24", "4M", "4L24"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7L15", "7V", "6L15", "5L15", "4L15", "3L19", "3S0"],
			rage: ["8V", "7L5", "7V", "6L5", "5L5", "5D", "4L5", "3L4", "3S0", "3S1"],
			ragingbull: ["9L35"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S2"],
			rest: ["9M", "9L40", "8M", "8L40", "8V", "7M", "7L19", "7V", "6M", "6L19", "5M", "5L19", "4M", "4L19", "3M", "3L34"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			rockclimb: ["5D", "4M"],
			rockslide: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			scaryface: ["9M", "9L25", "8M", "8L25", "7L11", "7V", "6L11", "5L11", "4L11", "3L13", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smartstrike: ["9M", "8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			spite: ["7T", "6T", "5T", "4T"],
			stomp: ["7V"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["9L45", "8L45", "7M", "7L48", "7V", "6M", "6L48", "5M", "5L48", "4M", "4L41", "3T", "3L26"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S1"],
			tailwhip: ["9L1", "8L1", "8V", "7L3", "7V", "6L3", "5L3", "4L3", "3L1", "3S1", "3S2"],
			takedown: ["9M", "8L35", "8V", "7L35", "7V", "6L41", "5L41", "4L35", "3L53"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L50", "8L50", "8V", "7L50", "7V", "6L50", "5L55", "4L48", "3L43"],
			throatchop: ["8M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			workup: ["9L5", "8M", "8L5", "7M", "7L29", "6L29", "5M", "5L29"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "9L30", "8M", "8L30", "7T", "7L35", "6T", "6L35", "5T", "5L35", "4T", "4L29"],
		},
		eventData: [
			{generation: 3, level: 25, nature: "Docile", ivs: {hp: 14, atk: 19, def: 12, spa: 17, spd: 5, spe: 26}, abilities: ["intimidate"], moves: ["rage", "hornattack", "scaryface", "pursuit"], pokeball: "safariball"},
			{generation: 3, level: 10, abilities: ["intimidate"], moves: ["tackle", "tailwhip", "rage", "hornattack"], pokeball: "pokeball"},
			{generation: 3, level: 46, abilities: ["intimidate"], moves: ["refresh", "earthquake", "tailwhip", "bodyslam"]},
		],
		encounters: [
			{generation: 1, level: 21},
		],
	},
	taurospaldea: {
		learnset: {
			assurance: ["9L15"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulkup: ["9M"],
			bulldoze: ["9M"],
			closecombat: ["9M", "9L60"],
			curse: ["9E"],
			dig: ["9M"],
			doubleedge: ["9L55"],
			doublekick: ["9L10"],
			drillrun: ["9M"],
			earthquake: ["9M"],
			endeavor: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			gigaimpact: ["9M"],
			headbutt: ["9L20"],
			hyperbeam: ["9M"],
			ironhead: ["9M"],
			outrage: ["9M"],
			protect: ["9M"],
			ragingbull: ["9L35"],
			raindance: ["9M"],
			rest: ["9M", "9L40"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "9L25"],
			sleeptalk: ["9M"],
			smartstrike: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			surf: ["9M"],
			swagger: ["9L45"],
			tackle: ["9L1"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L50"],
			trailblaze: ["9M"],
			wildcharge: ["9M"],
			workup: ["9L5"],
			zenheadbutt: ["9M", "9L30"],
		},
	},
	taurospaldeafire: {
		learnset: {
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulkup: ["9M"],
			bulldoze: ["9M"],
			closecombat: ["9M", "9L60"],
			curse: ["9E"],
			dig: ["9M"],
			doublekick: ["9L10"],
			drillrun: ["9M"],
			earthquake: ["9M"],
			endeavor: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			firespin: ["9M"],
			flamecharge: ["9M", "9L15"],
			flamethrower: ["9M"],
			flareblitz: ["9M", "9L55"],
			gigaimpact: ["9M"],
			headbutt: ["9L20"],
			hyperbeam: ["9M"],
			ironhead: ["9M"],
			outrage: ["9M"],
			overheat: ["9M"],
			protect: ["9M"],
			ragingbull: ["9L35"],
			raindance: ["9M"],
			rest: ["9M", "9L40"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "9L25"],
			sleeptalk: ["9M"],
			smartstrike: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swagger: ["9L45"],
			tackle: ["9L1"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L50"],
			trailblaze: ["9M"],
			wildcharge: ["9M"],
			willowisp: ["9M"],
			workup: ["9L5"],
			zenheadbutt: ["9M", "9L30"],
		},
	},
	taurospaldeawater: {
		learnset: {
			aquajet: ["9L15"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulkup: ["9M"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "9L60"],
			curse: ["9E"],
			dig: ["9M"],
			doublekick: ["9L10"],
			drillrun: ["9M"],
			earthquake: ["9M"],
			endeavor: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			gigaimpact: ["9M"],
			headbutt: ["9L20"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			ironhead: ["9M"],
			liquidation: ["9M"],
			outrage: ["9M"],
			protect: ["9M"],
			ragingbull: ["9L35"],
			raindance: ["9M"],
			rest: ["9M", "9L40"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "9L25"],
			sleeptalk: ["9M"],
			smartstrike: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			surf: ["9M"],
			swagger: ["9L45"],
			tackle: ["9L1"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L50"],
			trailblaze: ["9M"],
			waterpulse: ["9M"],
			wavecrash: ["9L55"],
			wildcharge: ["9M"],
			workup: ["9L5"],
			zenheadbutt: ["9M", "9L30"],
		},
	},
	magikarp: {
		learnset: {
			bounce: ["8M", "7T", "7S7", "6T", "5T", "5D", "5S5", "4T"],
			celebrate: ["6S6"],
			flail: ["9L25", "8L25", "7L30", "7V", "6L30", "5L30", "5S5", "4L30", "3L30"],
			happyhour: ["6S6"],
			hydropump: ["8M", "5S5"],
			splash: ["9L1", "8L1", "8V", "7L1", "7V", "7S7", "6L1", "6S6", "5L1", "5D", "5S5", "4L1", "4S0", "4S1", "4S2", "4S3", "4S4", "3L1"],
			tackle: ["9L15", "8L15", "8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L15"],
		},
		eventData: [
			{generation: 4, level: 5, gender: "M", nature: "Relaxed", moves: ["splash"], pokeball: "pokeball"},
			{generation: 4, level: 6, gender: "F", nature: "Rash", moves: ["splash"], pokeball: "pokeball"},
			{generation: 4, level: 7, gender: "F", nature: "Hardy", moves: ["splash"], pokeball: "pokeball"},
			{generation: 4, level: 5, gender: "F", nature: "Lonely", moves: ["splash"], pokeball: "pokeball"},
			{generation: 4, level: 4, gender: "M", nature: "Modest", moves: ["splash"], pokeball: "pokeball"},
			{generation: 5, level: 99, shiny: true, gender: "M", moves: ["flail", "hydropump", "bounce", "splash"], pokeball: "cherishball"},
			{generation: 6, level: 1, shiny: 1, moves: ["splash", "celebrate", "happyhour"], pokeball: "cherishball"},
			{generation: 7, level: 19, shiny: true, moves: ["splash", "bounce"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 5},
		],
	},
	gyarados: {
		learnset: {
			aquatail: ["9L32", "8L32", "7T", "7L30", "6T", "6L35", "5T", "5L35", "4T", "4L35"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "8M", "4M"],
			bide: ["7V"],
			bind: ["8V"],
			bite: ["9L0", "8L0", "8V", "7L1", "7V", "6L20", "6S1", "5L20", "4L20", "3L20"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["9L12", "8M", "8L12", "4M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L24", "8M", "8L24", "8V", "7L39", "6L41"],
			curse: ["7V"],
			darkpulse: ["9M", "8M", "8V", "7M", "6M", "5T", "4M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["7V"],
			dragondance: ["9M", "9L36", "8M", "8L36", "7L45", "6L44", "6S0", "5L44", "4L44", "3L50"],
			dragonpulse: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			dragonrage: ["8V", "7L36", "7V", "6L23", "5L23", "4L23", "3L25"],
			dragontail: ["9M", "8V", "7M", "6M", "5M"],
			earthquake: ["9M", "8M", "8V", "7M", "6M", "6S0", "5M", "4M", "3M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			flail: ["9L1", "8L1"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["9M", "9L44", "8M", "8L44", "7L48"],
			hydropump: ["9M", "9L40", "8M", "8L40", "8V", "7L42", "7V", "6L41", "5L41", "4L41", "3L40"],
			hyperbeam: ["9M", "9L52", "8M", "8L52", "8V", "7M", "7L54", "7V", "6M", "6L47", "5M", "5L47", "4M", "4L47", "3M", "3L55"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icefang: ["9M", "9L8", "8M", "8L8", "7L27", "6L32", "6S0", "6S1", "5L32", "4L32"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "6T", "6S1", "5T", "4T"],
			irontail: ["8M", "8V", "7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "8V", "7L21", "7V", "6L26", "5L26", "4L26", "3L30"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			powerwhip: ["8M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["8V", "7V"],
			raindance: ["9M", "9L28", "8M", "8L28", "7M", "7L51", "7V", "6M", "6L38", "5M", "5L38", "4M", "4L38", "3M", "3L45"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L16", "8M", "8L16", "7L33"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			splash: ["9L1", "8L1"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["9L1", "8L1", "7V"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thrash: ["9L48", "8L48", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["9L1", "8L1", "7L24", "7V", "6L29", "5L29", "4T", "4L29", "3L35"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterfall: ["9M", "9L21", "8M", "8L21", "8V", "7M", "7V", "6M", "6S0", "6S1", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			whirlpool: ["9L4", "8M", "8L4", "7V", "4M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 6, level: 50, moves: ["waterfall", "earthquake", "icefang", "dragondance"], pokeball: "cherishball"},
			{generation: 6, level: 20, shiny: true, moves: ["waterfall", "bite", "icefang", "ironhead"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 15},
			{generation: 3, level: 5},
			{generation: 4, level: 10},
			{generation: 5, level: 1},
			{generation: 7, level: 10},
		],
	},
	lapras: {
		learnset: {
			ancientpower: ["8E", "7E", "6E", "5E", "4T", "4E"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["7V"],
			avalanche: ["8M", "7E", "6E", "5E", "4M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L40", "8V", "7L18", "7V", "6L18", "5L18", "4L18", "3T", "3L13"],
			brine: ["8M", "8L35", "7L37", "6L37", "5L37", "4M", "4L37"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			confuseray: ["8L25", "8V", "7L7", "7V", "6L7", "5L7", "5D", "4L7", "3L19"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["7V"],
			dragondance: ["8M", "7E", "6E", "5E", "4E", "3E"],
			dragonpulse: ["8M", "8V", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			dragonrage: ["7V"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			drillrun: ["8M", "8V", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["8E", "7E", "6E", "5E", "4E"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			freezedry: ["8E", "7E", "6E"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "7E", "6E", "5E"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T", "3S0"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			horndrill: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			hydropump: ["8M", "8L55", "8V", "7L47", "7V", "6L47", "5L49", "4L49", "3L49", "3S0"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "8L45", "8V", "7M", "7L32", "7V", "6M", "6L32", "5M", "5L32", "4M", "4L32", "3M", "3L31"],
			iceshard: ["8L20", "8V", "7L10", "6L10", "5L10", "4L10"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lifedew: ["8L15"],
			liquidation: ["8M"],
			megahorn: ["8M", "8V"],
			mimic: ["7V", "3T"],
			mist: ["8L10", "8V", "7L4", "7V", "6L4", "5L4", "4L4", "3L7"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			perishsong: ["8L60", "7L27", "7V", "6L27", "5L27", "4L27", "3L25"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "8L50", "7M", "7L22", "7V", "6M", "6L22", "5M", "5L22", "4M", "4L22", "3M", "3L37", "3S0"],
			reflect: ["8V", "7V"],
			refresh: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7L43", "7V", "6M", "6L43", "5M", "5L43", "4M", "4L43", "3M", "3L43"],
			secretpower: ["6M", "4M", "3M"],
			sheercold: ["8L65", "7L50", "6L50", "5L55", "4L55", "3L55"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["8L5", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "4E", "3T", "3E"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8V", "7V"],
			sparklingaria: ["8E"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			tickle: ["8E", "7E", "6E", "5E", "4E", "3E"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["8L30", "7T", "7L14", "6T", "6L14", "5L14", "4M", "4L14", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7E", "7V", "6E", "5E", "4M", "4E"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 44, moves: ["hydropump", "raindance", "blizzard", "healbell"]},
		],
		encounters: [
			{generation: 1, level: 15},
		],
	},
	ditto: {
		learnset: {
			transform: ["9L1", "8L1", "8V", "7L1", "7V", "7S0", "6L1", "5L1", "4L1", "3L1"],
		},
		eventData: [
			{generation: 7, level: 10, moves: ["transform"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 12},
			{generation: 2, level: 10},
			{generation: 3, level: 23},
			{generation: 4, level: 10},
			{generation: 5, level: 45},
			{generation: 6, level: 30},
			{generation: 7, level: 25},
		],
	},
	eevee: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "5S2", "4M", "4S0", "3M"],
			babydolleyes: ["9L15", "8L15", "7L9", "7S5", "6L9", "6S3", "6S4"],
			batonpass: ["9M", "9L35", "8M", "8L35", "7L33", "7V", "6L33", "5L36", "4L36", "3L36"],
			bide: ["7V"],
			bite: ["9L25", "8L25", "8V", "7L17", "7V", "6L17", "5L29", "4L29", "4S0", "3L30"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			calmmind: ["9M"],
			captivate: ["7E", "6E", "4M"],
			celebrate: ["8S6", "7S5", "6S3"],
			charm: ["9M", "9L45", "8M", "8L45", "7L29", "7E", "7V", "6L29", "6E", "5L29", "5E", "5D", "4E", "3E"],
			confide: ["7M", "6M"],
			copycat: ["9L30", "8L30"],
			covet: ["9L1", "8L1", "8S6", "7T", "7L1", "7E", "6T", "6L23", "6E", "5T", "5L21", "5E", "4E", "4S0"],
			curse: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			detect: ["9E", "8E", "7E", "7V", "6E", "5E", "4E"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L50", "8L50", "8V", "7L37", "7V", "6L37", "5L37", "3T"],
			doublekick: ["9E", "8E", "8V"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M", "5S2"],
			endure: ["9M", "8M", "7E", "7V", "6E", "5E", "4M", "4E", "3T", "3E"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M", "7E", "6E", "5E", "4E"],
			flail: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "4S1", "3E"],
			focusenergy: ["8M", "7V"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			growl: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L15", "4L15", "3L16"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "8V", "8S6", "7T", "7L1", "6T", "6L1", "6S4", "5T", "5L1", "4T", "4L1", "4S0", "3L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "4S1", "3M"],
			laserfocus: ["7T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41", "5T", "5L50", "4T", "4L50"],
			mimic: ["7V", "3T"],
			mudslap: ["9M", "9E", "8E", "7V", "4T", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			payday: ["8M", "8V"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9L10", "8L10", "8V", "7L13", "7V", "6L13", "6S4", "5L22", "4L22", "4S1", "3L23"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			refresh: ["7L20", "6L20"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "5S2", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L5", "8L5", "8V", "7L5", "7V", "7S5", "6L5", "6S3", "5L8", "5D", "4L8", "3L8"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			sing: ["5S2"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			storedpower: ["9M", "8M", "7E", "6E", "5E"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L20", "8M", "8L20", "8V", "7L17", "7V", "6L10", "6S3", "6S4", "5D", "4T", "3T"],
			synchronoise: ["7E", "6E", "5E"],
			tackle: ["9L1", "8L1", "8V", "8S6", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9L40", "8L40", "8V", "7L25", "7V", "6L25", "5L43", "4L43", "3L42"],
			terablast: ["9M"],
			tickle: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trumpcard: ["7L45", "6L45", "5L57", "4L57", "4S1"],
			weatherball: ["8M"],
			wish: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			workup: ["8M", "7M", "5M"],
			yawn: ["9E", "8E", "7E", "6E", "5E", "4E"],
		},
		eventData: [
			{generation: 4, level: 10, gender: "F", nature: "Lonely", abilities: ["adaptability"], moves: ["covet", "bite", "helpinghand", "attract"], pokeball: "cherishball"},
			{generation: 4, level: 50, shiny: true, gender: "M", nature: "Hardy", abilities: ["adaptability"], moves: ["irontail", "trumpcard", "flail", "quickattack"], pokeball: "cherishball"},
			{generation: 5, level: 50, gender: "F", nature: "Hardy", abilities: ["adaptability"], moves: ["sing", "return", "echoedvoice", "attract"], pokeball: "cherishball"},
			{generation: 6, level: 10, moves: ["celebrate", "sandattack", "babydolleyes", "swift"], pokeball: "cherishball"},
			{generation: 6, level: 15, shiny: true, isHidden: true, moves: ["swift", "quickattack", "babydolleyes", "helpinghand"], pokeball: "cherishball"},
			{generation: 7, level: 10, nature: "Jolly", moves: ["celebrate", "sandattack", "babydolleyes"], pokeball: "cherishball"},
			{generation: 8, level: 5, gender: "M", nature: "Docile", abilities: ["runaway"], moves: ["celebrate", "covet", "helpinghand", "tackle"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 25},
		],
	},
	eeveestarter: {
		learnset: {
			baddybad: ["8V", "7T"],
			bite: ["8V", "7L17"],
			bouncybubble: ["8V", "7T"],
			buzzybuzz: ["8V", "7T"],
			dig: ["8V", "7M"],
			doubleedge: ["8V", "7L28"],
			doublekick: ["8V", "7L10"],
			facade: ["8V", "7M"],
			freezyfrost: ["8V", "7T"],
			glitzyglow: ["8V", "7T"],
			growl: ["8V", "7L1", "7S0"],
			headbutt: ["8V", "7M"],
			helpinghand: ["8V", "7M", "7L31"],
			irontail: ["8V", "7M"],
			payday: ["8V", "7M"],
			protect: ["8V", "7M"],
			quickattack: ["8V", "7L6"],
			reflect: ["8V", "7M"],
			rest: ["8V", "7M"],
			sandattack: ["8V", "7L14"],
			sappyseed: ["8V", "7T"],
			shadowball: ["8V", "7M"],
			sizzlyslide: ["8V", "7T"],
			sparklyswirl: ["8V", "7T"],
			substitute: ["8V", "7M"],
			swift: ["8V", "7L21"],
			tackle: ["8V", "7L1", "7S0"],
			tailwhip: ["8V", "7L3", "7S0"],
			takedown: ["8V", "7L24"],
			toxic: ["8V", "7M"],
			veeveevolley: ["8V", "7T"],
		},
		eventData: [
			{generation: 7, level: 5, perfectIVs: 6, moves: ["tackle", "tailwhip", "growl"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	vaporeon: {
		learnset: {
			acidarmor: ["9L45", "8L45", "8V", "7L29", "7V", "6L29", "5L64", "4L64", "3L47"],
			aquaring: ["9L35", "8L35", "7L25", "6L25", "5L43", "4L43"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["9L30", "8L30", "8V", "7L20", "7V", "6L20", "5L36", "4L36", "3L36"],
			babydolleyes: ["9L15", "8L15", "7L9"],
			batonpass: ["9M", "9L1", "8M", "8L1"],
			bide: ["7V"],
			bite: ["9L1", "8L1", "7V", "5L29", "4L29", "3L30"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brine: ["8M", "4M"],
			bubblebeam: ["7V"],
			calmmind: ["9M"],
			captivate: ["4M"],
			celebrate: ["6S1"],
			charm: ["9M", "9L1", "8M", "8L1"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			curse: ["7V"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["9L1", "8L1", "7V", "3T"],
			doublekick: ["8V"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			flipturn: ["8T"],
			focusenergy: ["8M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "8V"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["9L20", "8L20", "8V", "7L33", "7V", "6L33", "5L57", "4L57", "3L42"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "8V", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S0", "4T", "4L1", "3L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "9L50", "8M", "8L50", "8V", "7L45", "7V", "6L45", "5L71", "4L71", "3L52"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "7S2", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41", "5T", "5L50", "4T", "4L50"],
			liquidation: ["9M", "8M"],
			mimic: ["7V", "3T"],
			mist: ["7V"],
			muddywater: ["9L40", "8M", "8L40", "7L37", "6L37", "5L78", "4L78"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			payday: ["8M", "8V"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9L10", "8L10", "8V", "7L13", "7V", "6L13", "5L22", "4L22", "3L23"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "7S2", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "7S2", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L5", "8L5", "8V", "7L5", "7V", "6L5", "6S1", "5L8", "5S0", "4L8", "3L8"],
			scald: ["8M", "8V", "7M", "7S2", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			storedpower: ["9M", "8M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L1", "8M", "8L1", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			tailwhip: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "6S1", "5L1", "5S0", "4L1", "3L1"],
			takedown: ["9M", "9L1", "8L1", "7V"],
			terablast: ["9M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			waterfall: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["9L0", "8L0", "8V", "7L1", "7V", "6L9", "6S1", "5L15", "4L15", "3L16"],
			waterpulse: ["9M", "9L25", "8L25", "7T", "7L17", "6T", "6L17", "4M", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7V", "4M"],
			workup: ["8M", "7M", "5M"],
			yawn: ["8V"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tailwhip", "tackle", "helpinghand", "sandattack"]},
			{generation: 6, level: 10, moves: ["celebrate", "tailwhip", "sandattack", "watergun"], pokeball: "cherishball"},
			{generation: 7, level: 50, gender: "F", isHidden: true, moves: ["scald", "icebeam", "raindance", "rest"], pokeball: "cherishball"},
		],
	},
	jolteon: {
		learnset: {
			agility: ["9M", "9L45", "8M", "8L45", "8V", "7L29", "7V", "6L29", "5L64", "4L64", "3L47"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			babydolleyes: ["9L15", "8L15", "7L9"],
			batonpass: ["9M", "9L1", "8M", "8L1"],
			bide: ["7V"],
			bite: ["9L1", "8L1"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			calmmind: ["9M"],
			captivate: ["4M"],
			celebrate: ["6S1"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			curse: ["7V"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			discharge: ["9L40", "8L40", "7L37", "6L37", "5L78", "4L78"],
			doubleedge: ["9L1", "8L1", "7V", "3T"],
			doublekick: ["9L25", "8L25", "8V", "7L17", "7V", "6L17", "5L29", "4L29", "3L30"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M", "8M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			falseswipe: ["9M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			focusenergy: ["8M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "8V"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "8V", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S0", "4T", "4L1", "3L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41", "5T", "5L50", "4T", "4L50"],
			lightscreen: ["9M", "8M", "8V", "7M", "7S2", "6M", "5M", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mimic: ["7V", "3T"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			payday: ["8M", "8V"],
			pinmissile: ["9L35", "8M", "8L35", "8V", "7L25", "7V", "6L25", "5L36", "4L36", "3L36"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9L10", "8L10", "8V", "7L13", "7V", "6L13", "5L22", "4L22", "3L23"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L5", "8L5", "8V", "7L5", "7V", "6L5", "6S1", "5L8", "5S0", "4L8", "3L8"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "7S2", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			storedpower: ["9M", "8M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L1", "8M", "8L1", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			tailwhip: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "6S1", "5L1", "5S0", "4L1", "3L1"],
			takedown: ["9M", "9L1", "8L1", "7V"],
			terablast: ["9M"],
			thunder: ["9M", "9L50", "8M", "8L50", "8V", "7M", "7L45", "7V", "6M", "6L45", "5M", "5L71", "4M", "4L71", "3M", "3L52"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "7S2", "6M", "5M", "4M", "3M"],
			thunderfang: ["9M", "9L30", "8M", "8L30", "7L20", "6L20", "5L43", "4L43"],
			thundershock: ["9L0", "8L0", "8V", "7L1", "7V", "6L9", "6S1", "5L15", "4L15", "3L16"],
			thunderwave: ["9M", "9L20", "8M", "8L20", "8V", "7M", "7L33", "7V", "6M", "6L33", "5M", "5L57", "4M", "4L57", "3T", "3L42"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			voltswitch: ["9M", "8M", "7M", "7S2", "6M", "5M"],
			weatherball: ["8M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			yawn: ["8V"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tailwhip", "tackle", "helpinghand", "sandattack"]},
			{generation: 6, level: 10, moves: ["celebrate", "tailwhip", "sandattack", "thundershock"], pokeball: "cherishball"},
			{generation: 7, level: 50, gender: "F", moves: ["thunderbolt", "shadowball", "lightscreen", "voltswitch"], pokeball: "cherishball"},
		],
	},
	flareon: {
		learnset: {
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			babydolleyes: ["9L15", "8L15", "7L9"],
			batonpass: ["9M", "9L1", "8M", "8L1"],
			bide: ["7V"],
			bite: ["9L25", "8L25", "7L17", "7V", "6L17", "5L29", "4L29", "3L30"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			burningjealousy: ["8T"],
			calmmind: ["9M"],
			captivate: ["4M"],
			celebrate: ["6S1"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			curse: ["7V"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L1", "8L1", "7V", "3T"],
			doublekick: ["8V"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["9L0", "8L0", "8V", "7L1", "7V", "6L9", "6S1", "5L15", "4L15", "3L16"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "7S2", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "5L71", "4M", "4L71", "3M"],
			firefang: ["9M", "9L30", "8M", "8L30", "7L20", "6L20", "5L43", "4L43"],
			firespin: ["9M", "9L35", "8M", "8L35", "8V", "7L25", "7V", "6L25", "5L36", "4L36", "3L36"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3L52"],
			flareblitz: ["9M", "9L50", "8M", "8L50", "8V", "7L45", "7S2", "6L45"],
			focusenergy: ["8M", "8V"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "8V"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "8V", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S0", "4T", "4L1", "3L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41", "5T", "5L50", "4T", "4L50"],
			lavaplume: ["9L40", "8L40", "7L37", "6L37", "5L78", "4L78"],
			leer: ["7V", "3L47"],
			mimic: ["7V", "3T"],
			mudslap: ["9M", "7V", "4T", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			payday: ["8M", "8V"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9L10", "8L10", "8V", "7L13", "7V", "7S2", "6L13", "5L22", "4L22", "3L23"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L5", "8L5", "8V", "7L5", "7V", "6L5", "6S1", "5L8", "5S0", "4L8", "3L8"],
			scaryface: ["9M", "9L45", "8M", "8L45", "7L29", "6L29", "5L64", "4L64"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smog: ["9L20", "8L20", "8V", "7L33", "7V", "6L33", "5L57", "4L57", "3L42"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			storedpower: ["9M", "8M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L1", "8M", "8L1", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			tailwhip: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "6S1", "5L1", "5S0", "4L1", "3L1"],
			takedown: ["9M", "9L1", "8L1", "7V"],
			terablast: ["9M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			weatherball: ["8M"],
			willowisp: ["9M", "8M", "8V", "7M", "7S2", "6M", "5M", "4M"],
			workup: ["8M", "7M", "5M"],
			yawn: ["8V"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tailwhip", "tackle", "helpinghand", "sandattack"]},
			{generation: 6, level: 10, moves: ["celebrate", "tailwhip", "sandattack", "ember"], pokeball: "cherishball"},
			{generation: 7, level: 50, gender: "F", isHidden: true, moves: ["flareblitz", "facade", "willowisp", "quickattack"], pokeball: "cherishball"},
		],
	},
	espeon: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			babydolleyes: ["9L15", "8L15", "7L9"],
			batonpass: ["9M", "9L1", "8M", "8L1"],
			bite: ["9L1", "8L1"],
			bodyslam: ["9M", "8M", "3T"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			celebrate: ["6S2"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L0", "8L0", "7L1", "7V", "6L9", "6S2", "5L15", "4L15", "3L16"],
			copycat: ["9L1", "8L1"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dazzlinggleam: ["9M", "8M", "7M", "7S3", "6M"],
			detect: ["7V"],
			dig: ["9M", "8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L1", "8L1", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			focusenergy: ["8M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["9L50", "8M", "8L50", "7L25", "6L25", "5L43", "4L43"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S1", "4T", "4L1", "3L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			imprison: ["9M"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41", "5T", "5L50", "4T", "4L50"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["9M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			mimic: ["3T"],
			morningsun: ["9L30", "8L30", "7L33", "7V", "6L33", "5L71", "4L71", "3L52", "3S0"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payday: ["8M"],
			powergem: ["9M"],
			powerswap: ["9L35", "8M", "8L35", "7L45", "6L45", "5L78", "4L78"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L25", "8L25", "7L20", "7V", "6L20", "5L36", "4L36", "3L36", "3S0"],
			psychic: ["9M", "9L40", "8M", "8L40", "7M", "7L37", "7V", "7S3", "6M", "6L37", "5M", "5L64", "4M", "4L64", "3M", "3L47", "3S0"],
			psychicfangs: ["9M", "8M"],
			psychicterrain: ["9M"],
			psychup: ["9L45", "8L45", "7M", "7L29", "7V", "6M", "6L29", "5M", "5L57", "4M", "4L57", "3T", "3L42", "3S0"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			quickattack: ["9L10", "8L10", "7L13", "7V", "6L13", "5L22", "4L22", "3L23"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M", "8M", "7M", "7S3", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L5", "8L5", "7L5", "7V", "6L5", "6S2", "5L8", "5S1", "4L8", "3L8"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "7M", "7V", "7S3", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L20", "8M", "8L20", "7L17", "7V", "6L17", "5L29", "4T", "4L29", "3T", "3L30"],
			tackle: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5S1", "4L1", "3L1"],
			tailwhip: ["9L1", "8L1", "7L1", "7V", "6L1", "6S2", "5L1", "5S1", "4L1", "3L1"],
			takedown: ["9M", "9L1", "8L1"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunderwave: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			weatherball: ["8M"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["psybeam", "psychup", "psychic", "morningsun"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tailwhip", "tackle", "helpinghand", "sandattack"]},
			{generation: 6, level: 10, moves: ["celebrate", "tailwhip", "sandattack", "confusion"], pokeball: "cherishball"},
			{generation: 7, level: 50, gender: "F", isHidden: true, moves: ["psychic", "dazzlinggleam", "shadowball", "reflect"], pokeball: "cherishball"},
		],
	},
	umbreon: {
		learnset: {
			assurance: ["9L25", "8M", "8L25", "7L25", "6L25", "5L43", "4L43"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			babydolleyes: ["9L15", "8L15", "7L9"],
			batonpass: ["9M", "9L1", "8M", "8L1"],
			bite: ["9L1", "8L1"],
			bodyslam: ["9M", "8M", "3T"],
			calmmind: ["9M"],
			captivate: ["4M"],
			celebrate: ["6S2"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L20", "8L20", "7L17", "7V", "6L17", "5L29", "4L29", "3L30"],
			copycat: ["9L1", "8L1"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			crunch: ["9M", "8M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			darkpulse: ["9M", "9L40", "8M", "8L40", "7M", "6M", "5T", "4M"],
			detect: ["7V"],
			dig: ["9M", "8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L1", "8L1", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			feintattack: ["7L20", "7V", "6L20", "5L36", "4L36", "3L36", "3S0"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			focusenergy: ["8M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1"],
			guardswap: ["9L35", "8M", "8L35", "7L45", "6L45", "5L78", "4L78"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S1", "4T", "4L1", "3L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41", "5T", "5L50", "4T", "4L50"],
			lightscreen: ["9M"],
			meanlook: ["9L50", "8L50", "7L37", "7V", "6L37", "5L57", "4L57", "3L42", "3S0"],
			mimic: ["3T"],
			moonlight: ["9L30", "8L30", "7L33", "7V", "7S3", "6L33", "5L71", "4L71", "3L52", "3S0"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			payday: ["8M"],
			protect: ["9M", "8M", "7M", "7V", "7S3", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			pursuit: ["7L1", "7V", "6L9", "6S2", "5L15", "4L15", "3L16"],
			quickattack: ["9L10", "8L10", "7L13", "7V", "6L13", "5L22", "4L22", "3L23"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L5", "8L5", "7L5", "7V", "6L5", "6S2", "5L8", "5S1", "4L8", "3L8"],
			scaryface: ["9M"],
			screech: ["9L45", "8M", "8L45", "7L29", "7V", "6L29", "5L64", "4L64", "3L47", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["9M", "9L0", "8M", "8L0", "7M", "7S3", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L1", "8M", "8L1", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5S1", "4L1", "3L1"],
			tailwhip: ["9L1", "8L1", "7L1", "7V", "6L1", "6S2", "5L1", "5S1", "4L1", "3L1"],
			takedown: ["9M", "9L1", "8L1"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			throatchop: ["8M", "7T"],
			thunderwave: ["9M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "7S3", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			weatherball: ["8M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["feintattack", "meanlook", "screech", "moonlight"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tailwhip", "tackle", "helpinghand", "sandattack"]},
			{generation: 6, level: 10, moves: ["celebrate", "tailwhip", "sandattack", "pursuit"], pokeball: "cherishball"},
			{generation: 7, level: 50, gender: "F", moves: ["snarl", "toxic", "protect", "moonlight"], pokeball: "cherishball"},
		],
	},
	leafeon: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			babydolleyes: ["9L15", "8L15", "7L9"],
			batonpass: ["9M", "9L1", "8M", "8L1"],
			bite: ["9L1", "8L1"],
			bodyslam: ["9M", "8M"],
			bulletseed: ["9M", "8M", "4M"],
			calmmind: ["9M"],
			captivate: ["4M"],
			celebrate: ["6S1"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleedge: ["9L1", "8L1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			faketears: ["9M", "8M"],
			flash: ["6M", "5M", "4M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigadrain: ["9M", "9L40", "8M", "8L40", "7T", "7L25", "6T", "6L25", "5T", "5L43", "4M", "4L43"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7L17", "6L17", "5L57", "4L57"],
			grassyglide: ["8T"],
			growl: ["9L1", "8L1"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S0", "4T", "4L1"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41", "5T", "5L50", "4T", "4L50"],
			leafblade: ["9L50", "8M", "8L50", "7L45", "7S2", "6L45", "5L71", "4L71"],
			leafstorm: ["9M", "8M"],
			leechseed: ["9L20", "8L20"],
			magicalleaf: ["9M", "9L25", "8M", "8L25", "7L20", "6L20", "5L36", "4L36"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			payday: ["8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			quickattack: ["9L10", "8L10", "7L13", "6L13", "5L22", "4L22"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			razorleaf: ["9L0", "8L0", "7L1", "6L9", "6S1", "5L15", "4L15"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L5", "8L5", "7L5", "6L5", "6S1", "5L8", "5S0", "4L8"],
			secretpower: ["6M", "4M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T", "4T"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			solarblade: ["8M"],
			storedpower: ["9M", "8M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "9L35", "8M", "8L35", "7M", "7L37", "7S2", "6M", "6L37", "5M", "5L64", "4M", "4L64"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "9L1", "8M", "8L1", "4T"],
			swordsdance: ["9M", "9L45", "8M", "8L45", "7M", "7L29", "7S2", "6M", "6L29", "5M", "5L78", "4M", "4L78"],
			synthesis: ["9L30", "8L30", "7T", "7L33", "7S2", "6T", "6L33", "5T", "5L29", "4T", "4L29"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S0", "4L1"],
			tailwhip: ["9L1", "8L1", "7L1", "6L1", "6S1", "5L1", "5S0", "4L1"],
			takedown: ["9M", "9L1", "8L1"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			weatherball: ["8M"],
			workup: ["8M", "7M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			xscissor: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tailwhip", "tackle", "helpinghand", "sandattack"]},
			{generation: 6, level: 10, moves: ["celebrate", "tailwhip", "sandattack", "razorleaf"], pokeball: "cherishball"},
			{generation: 7, level: 50, gender: "F", isHidden: true, moves: ["leafblade", "swordsdance", "sunnyday", "synthesis"], pokeball: "cherishball"},
		],
	},
	glaceon: {
		learnset: {
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			auroraveil: ["7M", "7S2"],
			avalanche: ["9M", "8M", "4M"],
			babydolleyes: ["9L15", "8L15", "7L9"],
			barrier: ["7L29", "6L29", "5L78", "4L78"],
			batonpass: ["9M", "9L1", "8M", "8L1"],
			bite: ["9L25", "8L25", "7L17", "6L17", "5L29", "4L29"],
			blizzard: ["9M", "9L50", "8M", "8L50", "7M", "7L45", "7S2", "6M", "6L45", "5M", "5L71", "4M", "4L71"],
			bodyslam: ["9M", "8M"],
			calmmind: ["9M"],
			captivate: ["4M"],
			celebrate: ["6S1"],
			charm: ["9M", "9L1", "8M", "8L1"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			covet: ["9L1", "8L1", "7T", "6T", "5T"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleedge: ["9L1", "8L1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			faketears: ["9M", "8M"],
			focusenergy: ["8M"],
			freezedry: ["9L40", "8L40"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1"],
			hail: ["8M", "8L35", "7M", "7L37", "7S2", "6M", "6L37", "5M", "5L64", "4M", "4L64"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S0", "4T", "4L1"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icefang: ["9M", "9L30", "8M", "8L30", "7L20", "6L20", "5L43", "4L43"],
			iceshard: ["9L20", "8L20", "7L25", "6L25", "5L36", "4L36"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L0", "8M", "8L0", "7T", "7L1", "6T", "6L9", "6S1", "5T", "5L15", "4T", "4L15"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			laserfocus: ["7T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41", "5T", "5L50", "4T", "4L50"],
			mirrorcoat: ["9L45", "8L45", "7L33", "6L33", "5L57", "4L57"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			payday: ["8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			quickattack: ["9L10", "8L10", "7L13", "6L13", "5L22", "4L22"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L5", "8L5", "7L5", "6L5", "6S1", "5L8", "5S0", "4L8"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "7S2", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M", "9L35"],
			storedpower: ["9M", "8M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "9L1", "8M", "8L1", "4T"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S0", "4L1"],
			tailwhip: ["9L1", "8L1", "7L1", "6L1", "6S1", "5L1", "5S0", "4L1"],
			takedown: ["9M", "9L1", "8L1"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			tripleaxel: ["8T"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			weatherball: ["8M"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tailwhip", "tackle", "helpinghand", "sandattack"]},
			{generation: 6, level: 10, moves: ["celebrate", "tailwhip", "sandattack", "icywind"], pokeball: "cherishball"},
			{generation: 7, level: 50, gender: "F", moves: ["blizzard", "shadowball", "hail", "auroraveil"], pokeball: "cherishball"},
		],
	},
	porygon: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L30", "8V", "7L12", "7V", "6L12", "5L12", "4L12", "3L9"],
			allyswitch: ["8M", "7T"],
			barrier: ["8V"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			conversion2: ["8L25", "8S1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			conversion: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			curse: ["7V"],
			defensecurl: ["7V"],
			discharge: ["8L40", "7L40", "6L40", "5L40", "4L40"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			eerieimpulse: ["8M"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foulplay: ["8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["8M"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lockon: ["8L55", "7L45", "7V", "6L45", "5L45", "4L45", "3L32"],
			magiccoat: ["8L50", "7T", "7L56", "6T", "6L56", "5T", "5L56", "4T", "4L56"],
			magnetrise: ["8L10", "8S1", "7T", "7L23", "6T", "6L23", "5T", "5L23", "4L23"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powerswap: ["8M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L20", "8V", "8S1", "7L7", "7V", "6L7", "5L7", "5S0", "4L7", "3L12"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7V"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["8L35", "8V", "7L18", "7V", "6L18", "5L18", "4L18", "3L20"],
			recycle: ["8L5", "7T", "7L34", "6T", "6L34", "5T", "5L34", "4M", "4L34", "3L44"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			sharpen: ["8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L24"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "7L29", "6T", "6L29", "5T", "5L29", "4T", "4L29"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			speedswap: ["8M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			takedown: ["7V"],
			telekinesis: ["7T"],
			teleport: ["8V", "7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thundershock: ["8L15", "8S1"],
			thunderwave: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8L45", "8V", "7L50", "7V", "6L50", "5L51", "4L51", "3L36"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["8L60", "7L62", "7V", "6L62", "5L62", "4L62", "3L48"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 5, level: 10, isHidden: true, moves: ["tackle", "conversion", "sharpen", "psybeam"]},
			{generation: 8, level: 25, isHidden: true, moves: ["magnetrise", "thundershock", "psybeam", "conversion2"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 1, level: 18},
		],
	},
	porygon2: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L30", "7L12", "7V", "6L12", "5L12", "4L12", "3L9"],
			allyswitch: ["8M", "7T"],
			blizzard: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			conversion2: ["8L25", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			conversion: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			curse: ["7V"],
			defensecurl: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L24"],
			discharge: ["8L40", "7L40", "6L40", "5L40", "4L40"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			eerieimpulse: ["8M"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8L65", "7M", "7L67", "7V", "6M", "6L67", "5M", "5L67", "4M", "4L67", "3M"],
			icebeam: ["8M", "8S0", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lockon: ["8L55", "7L45", "7V", "6L45", "5L45", "4L45", "3L32"],
			magiccoat: ["8L50", "7T", "7L1", "6T", "6L1", "5T", "5L56", "4T", "4L56"],
			magnetrise: ["8L1", "7T", "7L23", "6T", "6L23", "5T", "5L23", "4L23"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powerswap: ["8M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L20", "7L7", "7V", "6L7", "5L7", "4L7", "3L12"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["8L35", "8S0", "7L18", "7V", "6L18", "5L18", "4L18", "3L20"],
			recycle: ["8L1", "7T", "7L34", "6T", "6L34", "5T", "5L34", "4M", "4L34", "3L44"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "7L29", "6T", "6L29", "5T", "5L29", "4T", "4L29"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			speedswap: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			telekinesis: ["7T"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8S0", "7M", "6M", "5M", "4M", "3M"],
			thundershock: ["8L15"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "8L45", "7L50", "7V", "6L50", "5L51", "4L51", "3L36"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "8S0", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["8L60", "7L1", "7V", "6L1", "5L62", "4L62", "3L48"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 8, level: 50, nature: "Sassy", abilities: ["download"], ivs: {hp: 31, atk: 0, spe: 0}, moves: ["recover", "trickroom", "icebeam", "thunderbolt"], pokeball: "cherishball"},
		],
	},
	porygonz: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["8M", "8L30", "7L12", "6L12", "5L12", "4L12"],
			allyswitch: ["8M", "7T"],
			blizzard: ["8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			conversion2: ["8L25", "7L1", "6L1", "5L1", "4L1"],
			conversion: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			defensecurl: ["8L1"],
			discharge: ["8L40", "7L40", "6L40", "5L40", "4L40"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			eerieimpulse: ["8M"],
			electroweb: ["8M", "7T", "6T", "5T"],
			embargo: ["7M", "7L34", "6M", "6L34", "5M", "5L34", "4M", "4L34"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["8M", "8L65", "7M", "7L67", "6M", "6L67", "5M", "5L67", "4M", "4L67"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lockon: ["8L55", "7L45", "6L45", "5L45", "4L45"],
			magiccoat: ["8L50", "7T", "7L1", "6T", "6L1", "5T", "5L56", "4T", "4L56"],
			magnetrise: ["8L1", "7T", "7L23", "6T", "6L23", "5T", "5L23", "4L23"],
			nastyplot: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powerswap: ["8M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psybeam: ["8L20", "7L7", "6L7", "5L7", "4L7"],
			psychic: ["8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			recover: ["8L35", "7L18", "6L18", "5L18", "4L18"],
			recycle: ["8L1", "7T", "6T", "5T", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "7L29", "6T", "6L29", "5T", "5L29", "4T", "4L29"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			speedswap: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["8M", "4T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			telekinesis: ["7T"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			thunder: ["8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thundershock: ["8L15"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			triattack: ["8M", "8L45", "7L50", "6L50", "5L51", "4L51"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			wonderroom: ["8M", "7T", "5T"],
			zapcannon: ["8L60", "7L1", "6L1", "5L62", "4L62"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	omanyte: {
		learnset: {
			ancientpower: ["8L30", "7L37", "7V", "6L37", "5L37", "4T", "4L37", "3L49"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7E", "7V", "6E", "5E"],
			bind: ["8L1", "7T", "6T", "5T"],
			bite: ["8E", "8V", "7L7", "7V", "6L7", "5L7", "5D", "5S0", "4L7", "3L13"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			brine: ["8M", "8L35", "7L28", "6L28", "5L28", "4M", "4L28"],
			bubblebeam: ["8E", "7E", "7V", "6E", "5E", "5S0", "4E", "3E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			constrict: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			curse: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hornattack: ["7V"],
			hydropump: ["8M", "8L60", "8V", "7L55", "7V", "6L55", "5L55", "4L52", "3L55"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			leer: ["8L20", "8V", "7L19", "7V", "6L19", "5L19", "4L19", "3L31"],
			meteorbeam: ["8T"],
			mimic: ["7V", "3T"],
			muddywater: ["8M", "7E", "6E", "5E", "5D", "4E"],
			mudshot: ["8M", "8L25", "7L25", "6L25", "5L25", "4L25", "3L25"],
			naturalgift: ["4M"],
			protect: ["8M", "8L41", "8V", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L34", "4M", "4L34", "3M", "3L37"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			reflecttype: ["8E", "7E", "6E"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "8L45", "7L46", "6L46", "5L46", "4L46"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L5", "7L16", "7V", "6L16", "5L16", "4T", "4L16", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L10"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shellsmash: ["8L55", "8V", "7L50", "6L50", "5L52"],
			slam: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spikecannon: ["7V"],
			spikes: ["8M", "7E", "6E", "5E", "4E", "3E"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			supersonic: ["8E", "7E", "7V", "6E", "5E", "5S0", "4E", "3E"],
			surf: ["8M", "8L50", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			tickle: ["8E", "7L43", "6L43", "5L43", "4L43", "3L43"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M", "7E", "6E", "5E", "4E"],
			waterfall: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L15", "8V", "7L10", "7V", "6L10", "5L10", "4L10", "3L19"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			whirlpool: ["8M", "7E", "7V", "6E", "5E", "4M"],
			withdraw: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			wringout: ["7E", "6E", "5E", "4E"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", abilities: ["swiftswim"], moves: ["bubblebeam", "supersonic", "withdraw", "bite"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 30},
		],
	},
	omastar: {
		learnset: {
			ancientpower: ["8L30", "7L37", "7V", "6L37", "5L37", "4T", "4L37", "3L55"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["8V", "7V"],
			bind: ["8L1", "7T", "6T", "5T"],
			bite: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			brine: ["8M", "8L35", "7L28", "6L28", "5L28", "4M", "4L28"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			constrict: ["8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			crunch: ["8M", "8L0"],
			curse: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hornattack: ["7V"],
			horndrill: ["7V"],
			hydropump: ["8M", "8L70", "8V", "7L1", "7V", "6L1", "5L75", "4L67", "3L65"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leer: ["8L20", "8V", "7L19", "7V", "6L19", "5L19", "4L19", "3L31"],
			liquidation: ["8M"],
			meteorbeam: ["8T"],
			mimic: ["7V", "3T"],
			muddywater: ["8M"],
			mudshot: ["8M", "8L25", "7L25", "6L25", "5L25", "4L25", "3L25"],
			naturalgift: ["4M"],
			pinmissile: ["8M"],
			protect: ["8M", "8L43", "8V", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L34", "4M", "4L34", "3M", "3L37"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "8L49", "7L56", "6L56", "5L56", "4L56"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L1", "7L16", "7V", "6L16", "5L16", "4T", "4L16", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			shellsmash: ["8L63", "8V", "7L67", "6L67", "5L67"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spikecannon: ["8V", "7L1", "7V", "6L40", "5L40", "4L40", "3L40"],
			spikes: ["8M"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			supersonic: ["8V"],
			surf: ["8M", "8L56", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			tickle: ["7L48", "6L48", "5L48", "4L48", "3L46"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["8M"],
			waterfall: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L15", "8V", "7L10", "7V", "6L10", "5L10", "4L10", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			withdraw: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
		},
	},
	kabuto: {
		learnset: {
			absorb: ["8L1", "8V", "7L6", "7V", "6L6", "5L6", "5D", "4L6", "3L13"],
			aerialace: ["7M", "3M"],
			ancientpower: ["8L30", "7L46", "7V", "6L46", "5L46", "4T", "4L46", "3L55"],
			aquajet: ["8L15", "8V", "7L31", "6L31", "5L31", "4L31"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			brine: ["8M", "8L35", "4M"],
			bubblebeam: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E", "5S0", "4E", "3E"],
			curse: ["7V"],
			dig: ["8M", "8V", "7V", "6M", "5M", "5S0", "4M", "4E", "3M", "3E"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			endure: ["8M", "7L26", "7V", "6L26", "5L26", "4M", "4L26", "3T", "3L37"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			foresight: ["7E", "6E", "5E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			headbutt: ["8V"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["8M", "7V"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			leechlife: ["8M", "8L45", "8V"],
			leer: ["8L20", "8V", "7L11", "7V", "6L11", "5L11", "4L11", "3L19"],
			liquidation: ["8M", "8L50"],
			megadrain: ["8E", "8V", "7L36", "7V", "6L36", "5L36", "4L36", "3L49"],
			metalsound: ["8L55", "7L41", "6L41", "5L41", "4L41", "3L43"],
			meteorbeam: ["8T"],
			mimic: ["7V", "3T"],
			mudshot: ["8M", "8L25", "7L16", "7E", "6L16", "6E", "5L16", "5E", "4L16", "4E", "3L25"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			protect: ["8M", "8L41", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rapidspin: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L10", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L31"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scratch: ["8L5", "8V", "7L1", "7V", "6L1", "5L1", "5S0", "4L1", "3L1"],
			screech: ["8M", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M", "3M"],
			slash: ["7V"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "5D", "4M"],
			stoneedge: ["8M", "8L60"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["8E", "7E", "7V", "6E"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			wringout: ["7L50", "6L50", "5L51", "4L51"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", abilities: ["battlearmor"], moves: ["confuseray", "dig", "scratch", "harden"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 30},
		],
	},
	kabutops: {
		learnset: {
			absorb: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["8L30", "7L54", "7V", "6L54", "5L54", "4T", "4L54", "3L65"],
			aquajet: ["8L15", "8V", "7L31", "6L31", "5L31", "4L31"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7V", "3T"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "8L35", "4M"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8V"],
			crosspoison: ["8M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dig: ["8M", "8V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			endure: ["8M", "7L26", "7V", "6L26", "5L26", "4M", "4L26", "3T", "3L37"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			feint: ["8L1", "8V", "7L1", "6L1", "5L1", "4L1"],
			flipturn: ["8T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T", "3L1"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8V", "7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["8M", "7V"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leechlife: ["8M", "8L49", "8V"],
			leer: ["8L20", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			liquidation: ["8M", "8L56", "7T"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			megadrain: ["8V", "7L36", "7V", "6L36", "5L36", "4L36", "3L55"],
			megakick: ["8M", "7V", "3T"],
			metalsound: ["8L63", "7L45", "6L45", "5L45", "4L45", "3L46"],
			meteorbeam: ["8T"],
			mimic: ["7V", "3T"],
			mudshot: ["8M", "8L25", "7L16", "6L16", "5L16", "4L16", "3L25"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			nightslash: ["8L1", "7L1", "6L1", "5L72", "4L72"],
			protect: ["8M", "8L43", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychocut: ["8M"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorshell: ["8M"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8V", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L31"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scratch: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["8M", "8V"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			skullbash: ["7V"],
			slash: ["8L0", "8V", "7L1", "7V", "6L40", "5L40", "4L40", "3L40"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "4M"],
			stoneedge: ["8M", "8L70", "7M", "6M", "5M", "4M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["7V"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			wringout: ["7L63", "6L63", "5L63", "4L63"],
			xscissor: ["8M", "8V", "7M", "6M", "5M", "4M"],
		},
	},
	aerodactyl: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L50", "8V", "7L17", "7V", "6L17", "5L17", "4L17", "3L8"],
			aircutter: ["4T"],
			ancientpower: ["8L1", "7L25", "7V", "7S1", "6L25", "5L25", "4T", "4L25", "3L29"],
			aquatail: ["7T", "6T", "5T", "4T"],
			assurance: ["8M", "7E", "6E", "5E", "5D", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bite: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L15"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			celebrate: ["7S1"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L30", "8V", "7L33", "6L33", "5L33", "4L33"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			dragonclaw: ["8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8V", "7T", "6T", "5T", "4M"],
			dragonrage: ["7V"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["8M", "7L1", "6L1", "5L1", "5S0", "4L1"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fly: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "8L60", "7M", "7L81", "6M", "6L81", "5M", "5L81", "4M", "4L73"],
			headbutt: ["8V", "7V", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hurricane: ["8M"],
			hyperbeam: ["8M", "8L55", "8V", "7M", "7L65", "7V", "6M", "6L65", "5M", "5L65", "4M", "4L57", "3M", "3L50"],
			icefang: ["8M", "7L1", "6L1", "5L1", "5S0", "4L1"],
			incinerate: ["6M", "5M"],
			ironhead: ["8M", "8L35", "7T", "7L1", "6T", "6L1", "5T", "5L57", "4T", "4L49"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			meteorbeam: ["8T"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychicfangs: ["8M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["8L25", "8V", "7M", "7L9", "7V", "6M", "6L9", "5M", "5L9", "4M", "4L9", "3M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "7S1", "6M", "5M", "4M"],
			rockslide: ["8M", "8L20", "8V", "7M", "7L73", "6M", "6L73", "5M", "5L73", "4M", "4L65", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8V"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roost: ["8E", "8V", "7M", "7E", "6M", "6E", "5T", "5E", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scaryface: ["8M", "8L15", "7L1", "7V", "6L1", "5L1", "4L1", "3L36"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["7T", "7V", "6T", "5T", "4T", "3T"],
			skydrop: ["7M", "7L49", "6M", "6L49", "5M", "5L49"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "8V", "7T", "6T", "5T", "5D", "4M"],
			steelwing: ["8M", "7M", "7E", "7V", "6M", "6E", "5E", "5S0", "4M", "4E", "3M", "3E"],
			stoneedge: ["8M", "8L45", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			supersonic: ["8L5", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L22"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tailwind: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			takedown: ["8L40", "8V", "7L41", "7V", "6L41", "5L41", "4L41", "3L43"],
			taunt: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderfang: ["8M", "7L1", "6L1", "5L1", "5S0", "4L1"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			whirlwind: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			wideguard: ["8E", "7E", "7S1", "6E"],
			wingattack: ["8L10", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", abilities: ["pressure"], moves: ["steelwing", "icefang", "firefang", "thunderfang"], pokeball: "cherishball"},
			{generation: 7, level: 50, isHidden: true, moves: ["ancientpower", "rockpolish", "wideguard", "celebrate"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 30},
		],
	},
	munchlax: {
		learnset: {
			afteryou: ["7T", "7E", "6T", "6E", "5T", "5E"],
			amnesia: ["8M", "8L36", "7L9", "6L9", "5L9", "4L9"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			belch: ["8E", "7E", "6E"],
			bellydrum: ["8L48", "7L44", "6L44"],
			bite: ["8L16"],
			blizzard: ["8M", "7M", "6M", "5M", "4M"],
			bodyslam: ["8M", "8L28", "7L25", "6L25", "5L36", "4L33"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["8M", "7E", "6E", "5E", "4E"],
			chipaway: ["7L17", "6L17", "5L25"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E", "4E"],
			covet: ["8L12", "7T", "6T", "5T"],
			curse: ["8E", "7E", "6E", "5E", "4E", "4S1"],
			defensecurl: ["8L4", "7L4", "6L4", "5L4", "4L4", "4S0"],
			doubleedge: ["8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M"],
			encore: ["8M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T"],
			fissure: ["8E"],
			flail: ["8L44"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "8L32", "7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L36"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gunkshot: ["8M", "7T", "6T", "5T", "4T"],
			happyhour: ["7S2"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			holdback: ["7S2"],
			hydropump: ["8M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			lastresort: ["8L52", "7T", "7L1", "6T", "6L1", "5T", "5L57", "4T", "4L49"],
			lick: ["8L1", "7L1", "7E", "6L1", "6E", "5L12", "5E", "4L12", "4E"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["8M", "8L40", "7L1", "7S2", "6L1", "5L1", "4L1", "4S0", "4S1"],
			mudslap: ["4T"],
			naturalgift: ["7L49", "7E", "6L49", "6E", "5L49", "5E", "4M", "4L44"],
			odorsleuth: ["7L1", "6L1", "5L1", "4L1", "4S1"],
			payday: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychic: ["8M", "7M", "6M", "5M", "4M"],
			pursuit: ["7E", "6E", "5E", "4E"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			recycle: ["8L8", "7T", "7L1", "6T", "6L1", "5T", "5L17", "4M", "4L17"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rollout: ["7L36", "6L36", "5L44", "4T", "4L41"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M"],
			screech: ["8M", "8L24", "7L20", "6L20", "5L20", "4L20"],
			secretpower: ["6M", "4M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			selfdestruct: ["8M", "7E", "6E", "5E", "4S0"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snatch: ["7T", "7L50", "6T", "6L1", "5T", "5L52"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			stockpile: ["8L20", "7L28", "6L28", "5L28", "4L25"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "4E"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			surf: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swallow: ["8L20", "7L33", "6L33", "5L33", "4L28"],
			tackle: ["8L1", "7L1", "7S2", "6L1", "5L1", "4L1", "4S0", "4S1"],
			thunder: ["8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M"],
			whirlpool: ["8M", "4M"],
			whirlwind: ["7E", "6E", "5E", "4E"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
		},
		eventData: [
			{generation: 4, level: 5, moves: ["metronome", "tackle", "defensecurl", "selfdestruct"]},
			{generation: 4, level: 5, gender: "F", nature: "Relaxed", abilities: ["thickfat"], moves: ["metronome", "odorsleuth", "tackle", "curse"], pokeball: "cherishball"},
			{generation: 7, level: 5, abilities: ["thickfat"], moves: ["tackle", "metronome", "holdback", "happyhour"], pokeball: "cherishball"},
		],
	},
	snorlax: {
		learnset: {
			afteryou: ["7T", "7E", "6T", "6E", "5T", "5E"],
			amnesia: ["8M", "8L36", "8V", "7L9", "7V", "6L9", "5L9", "5D", "4L9", "3L5"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			belch: ["8L52", "7E", "6E"],
			bellydrum: ["8L48", "7L44", "7V", "6L44", "5L17", "4L17", "3L13"],
			bide: ["7V"],
			bite: ["8L16"],
			blizzard: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["8L1", "7T", "7L41", "7S1", "6T", "6L41", "5T", "5L41", "4T", "4L36", "3L37"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L28", "8V", "7L25", "7V", "7S1", "6L25", "5L36", "4L33", "3T", "3L33", "3S0"],
			brickbreak: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["7V"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			celebrate: ["7S1"],
			charm: ["8M", "7E", "6E", "5E", "4E", "3E"],
			chipaway: ["7L17", "6L17", "5L25"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "7V", "6E", "5E", "4E", "3T"],
			covet: ["8L1", "7T", "6T", "5T", "3L42"],
			crunch: ["8M", "8L24", "8V", "7L49", "6L49", "5L49", "4L44"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E", "3S0"],
			darkestlariat: ["8M"],
			defensecurl: ["8L1", "8V", "7L4", "7V", "6L4", "5L4", "4L4", "3T", "3L9"],
			doubleedge: ["8E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["8M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			fissure: ["8E", "7E", "7V", "6E", "5E", "4E", "3E", "3S0"],
			flail: ["8L1"],
			flamethrower: ["8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["8E"],
			gigaimpact: ["8M", "8L56", "7M", "7L35", "6M", "6L57", "5M", "5L57", "4M", "4L49"],
			gunkshot: ["8M", "7T", "6T", "5T", "4T"],
			hammerarm: ["8L44"],
			harden: ["7V"],
			headbutt: ["8V", "7V", "4T", "3L17"],
			heatcrash: ["8M"],
			heavyslam: ["8M", "8L32", "7L50", "6L50", "5L52"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M", "8L40", "7L57"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3L51"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			lastresort: ["8L1", "7T", "6T", "5T", "4T"],
			lick: ["8L1", "8V", "7L12", "7E", "7V", "6L12", "6E", "5L12", "5E", "4L12", "4E", "3E"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["8M", "8L1", "7V", "3T"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			outrage: ["8M", "8V", "7T", "6T", "5T", "4T"],
			payday: ["8M", "8V", "7V"],
			poweruppunch: ["7E", "6M"],
			protect: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7V", "3T"],
			psywave: ["7V"],
			pursuit: ["7E", "6E", "5E", "4E"],
			rage: ["7V"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recycle: ["8L1", "7T", "6T", "5T", "5D", "4M"],
			reflect: ["8V", "7V"],
			refresh: ["3S0"],
			rest: ["8M", "8L20", "8V", "7M", "7L28", "7V", "6M", "6L28", "5M", "5L28", "4M", "4L25", "3M", "3L25"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7L36", "7V", "6L36", "5L44", "4T", "4L41", "3T", "3L46"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			screech: ["8M", "8L1", "8V"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			shadowball: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["8M", "8L20", "7M", "7L33", "7V", "6M", "6L33", "5T", "5L33", "4M", "4L28", "3T", "3L37"],
			smackdown: ["7M", "6M", "5M"],
			snatch: ["7T"],
			snore: ["8M", "8L20", "7T", "7L28", "7V", "6T", "6L28", "5T", "5L28", "4T", "4L28", "3T", "3L28"],
			solarbeam: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			steelroller: ["8T"],
			stockpile: ["8L1"],
			stompingtantrum: ["8M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			sunnyday: ["8M", "7M", "7V", "7S1", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			surf: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["8L1"],
			tackle: ["8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7V"],
			terrainpulse: ["8T"],
			thunder: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			whirlwind: ["7E", "6E", "5E", "4E"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			yawn: ["8L12", "8V", "7L20", "6L20", "5L20", "4L20", "3L21"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 43, moves: ["refresh", "fissure", "curse", "bodyslam"]},
			{generation: 7, level: 30, abilities: ["thickfat"], moves: ["sunnyday", "block", "bodyslam", "celebrate"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 1, level: 30},
		],
	},
	articuno: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L20", "8M", "8L20", "8V", "7L36", "7V", "6L36", "5L36", "4L36", "4S3", "4S4", "3L25", "3S0", "3S1"],
			aircutter: ["9M", "4T"],
			airslash: ["9M", "8M"],
			ancientpower: ["9L25", "8L25", "7L29", "7S7", "6L29", "5L29", "4T", "4L29"],
			auroraveil: ["7M"],
			avalanche: ["9M", "8M", "4M"],
			bide: ["7V"],
			blizzard: ["9M", "9L65", "8M", "8L65", "8V", "7M", "7L78", "7V", "6M", "6L71", "5M", "5L71", "4M", "4L71", "3M", "3L73"],
			bravebird: ["9M", "8M"],
			bubblebeam: ["7V"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			extrasensory: ["3S2"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fly: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			freezedry: ["9L35", "8L35", "8S8", "7L43", "7S7", "6L1", "6S6"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gust: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "8L50", "7M", "7L57", "7S7", "6M", "6L57", "6S5", "6S6", "5M", "5L85", "4M", "4L85", "3M"],
			haze: ["9L60", "3S2"],
			headbutt: ["8V"],
			healbell: ["3S2"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["9M", "9L55", "8M", "8L55", "8S8", "7L92", "6L1", "5L92"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "9L45", "8M", "8L45", "8V", "8S8", "7M", "7L71", "7V", "6M", "6L43", "6S5", "6S6", "5M", "5L43", "4M", "4L43", "4S3", "4S4", "3M", "3L49", "3S0", "3S1", "3S2"],
			iceshard: ["9L15", "8L15", "8V", "7L15", "6L15", "5L15", "4L15"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			laserfocus: ["7T"],
			leer: ["8V"],
			lightscreen: ["9M"],
			mimic: ["7V", "3T"],
			mindreader: ["8L60", "7L22", "7V", "6L22", "5L22", "4L22", "4S4", "3L37", "3S0", "3S1"],
			mirrorcoat: ["8V"],
			mist: ["9L1", "8L1", "8V", "8S8", "7L8", "7V", "6L8", "5L8", "4L8", "4S4", "3L13", "3S0"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			peck: ["7V"],
			pluck: ["5M", "4M"],
			powdersnow: ["9L5", "8L5", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			reflect: ["9M", "9L10", "8M", "8L10", "8V", "7M", "7L50", "7V", "7S7", "6M", "6L50", "6S5", "6S6", "5M", "5L50", "4M", "4L50", "4S3", "3M", "3L61", "3S1"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roost: ["9L40", "8L40", "8V", "7M", "7L85", "6M", "6L1", "5T", "5L57", "4M", "4L57", "4S3"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			sheercold: ["9L70", "8L70", "7L99", "6L1", "5L78", "4L78", "3L85"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skyattack: ["8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M", "9L50"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwind: ["9M", "9L30", "8L30", "7T", "7L64", "6T", "6L1", "6S5", "5T", "5L64", "4T", "4L64"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			tripleaxel: ["8T"],
			twister: ["4T"],
			uturn: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			watergun: ["7V"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			weatherball: ["8M"],
			whirlwind: ["7V"],
		},
		eventData: [
			{generation: 3, level: 50, shiny: 1, moves: ["mist", "agility", "mindreader", "icebeam"]},
			{generation: 3, level: 70, moves: ["agility", "mindreader", "icebeam", "reflect"], pokeball: "pokeball"},
			{generation: 3, level: 50, moves: ["icebeam", "healbell", "extrasensory", "haze"]},
			{generation: 4, level: 60, shiny: 1, moves: ["agility", "icebeam", "reflect", "roost"]},
			{generation: 4, level: 50, shiny: 1, moves: ["mist", "agility", "mindreader", "icebeam"]},
			{generation: 6, level: 70, moves: ["icebeam", "reflect", "hail", "tailwind"]},
			{generation: 6, level: 70, isHidden: true, moves: ["freezedry", "icebeam", "hail", "reflect"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["ancientpower", "freezedry", "reflect", "hail"]},
			{generation: 8, level: 70, shiny: 1, moves: ["icebeam", "freezedry", "hurricane", "mist"]},
		],
		encounters: [
			{generation: 1, level: 50},
		],
		eventOnly: true,
	},
	articunogalar: {
		learnset: {
			agility: ["9M", "9L20", "8M", "8L20"],
			aircutter: ["9M"],
			airslash: ["9M", "8M"],
			allyswitch: ["8M"],
			ancientpower: ["9L25", "8L25"],
			bravebird: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			confusion: ["9L5", "8L5"],
			doubleteam: ["9L60"],
			dreameater: ["9L50", "8L50"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			fly: ["9M", "8M"],
			freezingglare: ["9L45", "8L45", "8S0", "8S1"],
			futuresight: ["9L65", "8M", "8L65"],
			gigaimpact: ["9M", "8M"],
			guardswap: ["8M"],
			gust: ["9L1", "8L1"],
			helpinghand: ["9M"],
			hurricane: ["9M", "9L55", "8M", "8L55", "8S0", "8S1"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			hypnosis: ["9L15", "8L15"],
			imprison: ["9M", "8M"],
			lightscreen: ["9M", "8M"],
			mindreader: ["8L60"],
			powerswap: ["8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M"],
			psychic: ["9M", "8M"],
			psychocut: ["9L35", "8M", "8L35", "8S0", "8S1"],
			psychoshift: ["8L1", "8S0", "8S1"],
			psyshock: ["9M", "8M"],
			raindance: ["9M"],
			recover: ["9L40", "8L40"],
			reflect: ["9M", "9L10", "8M", "8L10"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "8M"],
			shadowball: ["9M", "8M"],
			skillswap: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			snowscape: ["9M"],
			steelwing: ["8M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			swift: ["9M", "8M"],
			tailwind: ["9M", "9L30", "8L30"],
			takedown: ["9M"],
			terablast: ["9M"],
			trick: ["9M"],
			trickroom: ["9M", "9L70", "8M", "8L70"],
			uturn: ["9M", "8M"],
		},
		eventData: [
			{generation: 8, level: 70, moves: ["freezingglare", "hurricane", "psychocut", "psychoshift"]},
			{generation: 8, level: 70, shiny: true, moves: ["freezingglare", "hurricane", "psychocut", "psychoshift"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	zapdos: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L20", "8M", "8L20", "8V", "8S8", "7L43", "7V", "6L43", "6S5", "6S6", "5L43", "4L43", "4S3", "4S4", "3L25", "3S0", "3S1"],
			aircutter: ["9M", "4T"],
			ancientpower: ["9L25", "8L25", "7L29", "7S7", "6L29", "5L29", "4T", "4L29"],
			batonpass: ["9M", "8M", "3S2"],
			bide: ["7V"],
			bravebird: ["9M", "8M", "8S8"],
			charge: ["9L30", "8L30", "7L36", "6L36", "5L36", "4L36", "4S3", "3L61", "3S1"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["9L60", "8L60", "7L15", "7V", "6L15", "5L15", "4L15", "4S4", "3L37", "3S0", "3S1"],
			discharge: ["9L45", "8L45", "7L50", "7S7", "6L50", "6S5", "6S6", "5L50", "4L50", "4S3"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillpeck: ["9L35", "8L35", "8V", "8S8", "7L71", "7V", "6L1", "5L71", "4L71", "4S4", "3L49", "3S0", "3S1"],
			dualwingbeat: ["8T"],
			eerieimpulse: ["9M", "8M"],
			electricterrain: ["9M"],
			electroball: ["9M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			extrasensory: ["3S2"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fly: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M"],
			headbutt: ["8V"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			laserfocus: ["7T"],
			leer: ["8V"],
			lightscreen: ["9M", "9L10", "8M", "8L10", "8V", "7M", "7L64", "7V", "6M", "6L64", "6S5", "5M", "5L64", "4M", "4L64", "3M", "3L73"],
			magneticflux: ["9L65", "8L65", "7L92"],
			metalsound: ["3S2"],
			mimic: ["7V", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			peck: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			pluck: ["9L15", "8L15", "7L22", "7S7", "6L22", "5M", "5L22", "4M", "4L22"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "9L50", "8M", "8L50", "7M", "7L57", "7V", "7S7", "6M", "6L57", "6S5", "6S6", "5M", "5L85", "4M", "4L85", "3M"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roost: ["9L40", "8L40", "8V", "7M", "7L85", "6M", "6L1", "5T", "5L57", "4M", "4L57", "4S3"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skyattack: ["8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thunder: ["9M", "9L55", "8M", "8L55", "8V", "8S8", "7M", "7L78", "7V", "6M", "6L78", "5M", "5L78", "4M", "4L78", "3M", "3L85"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M", "3S2"],
			thundershock: ["9L5", "8L5", "8V", "7L1", "7V", "6L1", "6S6", "5L1", "4L1", "3L1"],
			thunderwave: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7L8", "7V", "6M", "6L8", "5M", "5L8", "4M", "4L8", "4S4", "3T", "3L13", "3S0"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uturn: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M"],
			weatherball: ["8M"],
			whirlwind: ["7V"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			zapcannon: ["9L70", "8L70", "7L99", "7V", "6L1", "5L92"],
		},
		eventData: [
			{generation: 3, level: 50, shiny: 1, moves: ["thunderwave", "agility", "detect", "drillpeck"]},
			{generation: 3, level: 70, moves: ["agility", "detect", "drillpeck", "charge"], pokeball: "pokeball"},
			{generation: 3, level: 50, moves: ["thunderbolt", "extrasensory", "batonpass", "metalsound"]},
			{generation: 4, level: 60, shiny: 1, moves: ["charge", "agility", "discharge", "roost"]},
			{generation: 4, level: 50, shiny: 1, moves: ["thunderwave", "agility", "detect", "drillpeck"]},
			{generation: 6, level: 70, moves: ["agility", "discharge", "raindance", "lightscreen"]},
			{generation: 6, level: 70, isHidden: true, moves: ["discharge", "thundershock", "raindance", "agility"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["ancientpower", "discharge", "pluck", "raindance"]},
			{generation: 8, level: 70, shiny: 1, moves: ["thunder", "drillpeck", "bravebird", "agility"]},
		],
		encounters: [
			{generation: 1, level: 50},
		],
		eventOnly: true,
	},
	zapdosgalar: {
		learnset: {
			acrobatics: ["9M", "8M"],
			aerialace: ["9M"],
			agility: ["9M", "9L20", "8M", "8L20"],
			ancientpower: ["9L25", "8L25"],
			assurance: ["8M"],
			blazekick: ["8M"],
			bounce: ["8M"],
			bravebird: ["9M", "8M"],
			brickbreak: ["9M", "9L30", "8M", "8L30"],
			bulkup: ["9M", "9L50", "8M", "8L50"],
			closecombat: ["9M", "9L65", "8M", "8L65"],
			coaching: ["8T"],
			counter: ["9L55", "8L55"],
			detect: ["9L60", "8L60"],
			drillpeck: ["9L35", "8L35", "8S0", "8S1"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fly: ["9M", "8M"],
			focusenergy: ["9L1", "8M", "8L1", "8S0", "8S1"],
			gigaimpact: ["9M", "8M"],
			helpinghand: ["9M"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			lightscreen: ["9M", "9L10", "8M", "8L10"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			payback: ["8M"],
			peck: ["9L1", "8L1"],
			pluck: ["9L15", "8L15"],
			protect: ["9M", "8M"],
			quickguard: ["9L40", "8L40"],
			raindance: ["9M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "9L70", "8M", "8L70", "8S0", "8S1"],
			rocksmash: ["9L5", "8L5"],
			round: ["8M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "8M"],
			screech: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			steelwing: ["8M"],
			stompingtantrum: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			superpower: ["8M"],
			swift: ["9M", "8M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			throatchop: ["8M"],
			thunderouskick: ["9L45", "8L45", "8S0", "8S1"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M"],
		},
		eventData: [
			{generation: 8, level: 70, moves: ["thunderouskick", "drillpeck", "reversal", "focusenergy"]},
			{generation: 8, level: 70, shiny: true, moves: ["thunderouskick", "drillpeck", "reversal", "focusenergy"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	moltres: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L20", "8M", "8L20", "8V", "7L15", "7V", "6L15", "5L15", "4L15", "4S4", "3L25", "3S0", "3S1"],
			aircutter: ["9M", "4T"],
			airslash: ["9M", "9L35", "8M", "8L35", "8V", "7L50", "7S7", "6L50", "6S5", "5L50", "4L50", "4S3"],
			ancientpower: ["9L25", "8L25", "7L29", "7S7", "6L29", "5L29", "4T", "4L29"],
			bide: ["7V"],
			bravebird: ["9M", "8M"],
			burningjealousy: ["8T"],
			burnup: ["8L65", "7L99"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			ember: ["9L5", "8L5", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			endure: ["9M", "9L60", "8M", "8L60", "7L22", "7V", "6L22", "5L22", "4M", "4L22", "4S4", "3T", "3L37", "3S0", "3S1"],
			extrasensory: ["3S2"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firespin: ["9M", "8M", "8V", "8S8", "7L8", "7V", "6L8", "5L8", "4L8", "4S4", "3L13", "3S0"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "8M", "8V", "7M", "7L36", "7V", "7S7", "6M", "6L36", "5M", "5L36", "4M", "4L36", "4S3", "4S4", "3M", "3L49", "3S0", "3S1", "3S2"],
			flareblitz: ["9M", "8M"],
			fly: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gust: ["9L1", "8L1"],
			headbutt: ["8V"],
			heatwave: ["9M", "9L45", "8M", "8L45", "8V", "8S8", "7T", "7L64", "6T", "6L1", "6S5", "6S6", "5T", "5L64", "4T", "4L64", "3L73"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["9M", "9L55", "8M", "8L55", "7L92", "6L1", "5L92"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["9L30", "8L30", "6M", "5M"],
			laserfocus: ["7T"],
			leer: ["9L1", "8L1", "8V", "8S8", "7V"],
			mimic: ["7V", "3T"],
			morningsun: ["3S2"],
			mudslap: ["7V", "4T", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			overheat: ["9M", "9L65", "8M", "7M", "6M", "5M", "4M", "3M"],
			peck: ["7V"],
			pluck: ["5M", "4M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roost: ["9L40", "8L40", "8V", "7M", "7L85", "6M", "6L1", "5T", "5L57", "4M", "4L57", "4S3"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["9L10", "8M", "8L10", "7M", "7L43", "7V", "6M", "6L43", "6S5", "6S6", "5M", "5L43", "4M", "4L43", "4S3", "3M", "3L61", "3S1"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["9L70", "8L70", "8V", "7T", "7L78", "7V", "6T", "6L1", "6S6", "5T", "5L78", "4T", "4L78", "3T", "3L85"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "8V", "7M", "7L71", "6M", "6L71", "5M", "5L71", "4M", "4L71"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "9L50", "8M", "8L50", "7M", "7L57", "7V", "7S7", "6M", "6L57", "6S5", "6S6", "5M", "5L85", "4M", "4L85", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uturn: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			weatherball: ["8M"],
			whirlwind: ["7V"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3S2"],
			wingattack: ["9L15", "8L15", "8V", "8S8", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
		},
		eventData: [
			{generation: 3, level: 50, shiny: 1, moves: ["firespin", "agility", "endure", "flamethrower"]},
			{generation: 3, level: 70, moves: ["agility", "endure", "flamethrower", "safeguard"], pokeball: "pokeball"},
			{generation: 3, level: 50, moves: ["extrasensory", "morningsun", "willowisp", "flamethrower"]},
			{generation: 4, level: 60, shiny: 1, moves: ["flamethrower", "safeguard", "airslash", "roost"]},
			{generation: 4, level: 50, shiny: 1, moves: ["firespin", "agility", "endure", "flamethrower"]},
			{generation: 6, level: 70, moves: ["safeguard", "airslash", "sunnyday", "heatwave"]},
			{generation: 6, level: 70, isHidden: true, moves: ["skyattack", "heatwave", "sunnyday", "safeguard"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["ancientpower", "flamethrower", "airslash", "sunnyday"]},
			{generation: 8, level: 70, shiny: 1, moves: ["heatwave", "wingattack", "leer", "firespin"]},
		],
		encounters: [
			{generation: 1, level: 50},
		],
		eventOnly: true,
	},
	moltresgalar: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			afteryou: ["9L40", "8L40"],
			agility: ["9M", "9L20", "8M", "8L20"],
			airslash: ["9M", "9L35", "8M", "8L35"],
			ancientpower: ["9L25", "8L25"],
			assurance: ["8M"],
			bravebird: ["9M", "8M"],
			darkpulse: ["9M", "8M"],
			dualwingbeat: ["8T"],
			endure: ["9M", "9L60", "8M", "8L60"],
			facade: ["9M", "8M"],
			fierywrath: ["9L45", "8L45", "8S0", "8S1"],
			fly: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			gust: ["9L1", "8L1"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hurricane: ["9M", "9L55", "8M", "8L55", "8S0", "8S1"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			imprison: ["9M", "8M"],
			lashout: ["8T"],
			leer: ["9L1", "8L1"],
			memento: ["9L65", "8L65"],
			nastyplot: ["9M", "9L50", "8M", "8L50", "8S0", "8S1"],
			payback: ["9L5", "8M", "8L5"],
			protect: ["9M", "8M"],
			raindance: ["9M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["9L10", "8M", "8L10"],
			sandstorm: ["9M"],
			scaryface: ["9M", "8M"],
			shadowball: ["9M", "8M"],
			skyattack: ["9L70", "8L70"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			steelwing: ["8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L30", "8L30", "8S0", "8S1"],
			sunnyday: ["9M"],
			swift: ["9M", "8M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thief: ["9M"],
			uturn: ["9M", "8M"],
			wingattack: ["9L15", "8L15"],
		},
		eventData: [
			{generation: 8, level: 70, moves: ["fierywrath", "hurricane", "suckerpunch", "nastyplot"]},
			{generation: 8, level: 70, shiny: true, moves: ["fierywrath", "hurricane", "suckerpunch", "nastyplot"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	dratini: {
		learnset: {
			agility: ["9M", "9L20", "8M", "8L20", "8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L36"],
			aquajet: ["9E", "8E", "7E", "6E", "5E"],
			aquatail: ["9L31", "8L31", "7T", "7L35", "6T", "6L35", "5T", "5L35", "4T", "4L31"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["9E", "8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			dragondance: ["9M", "9L50", "8M", "8L50", "7L51", "7E", "6L51", "6E", "5L51", "5E", "4L45", "4E", "3E"],
			dragonpulse: ["9M", "8M", "8V", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			dragonrage: ["8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L22"],
			dragonrush: ["9L35", "8L35", "7L41", "7E", "6L41", "6E", "5L41", "5E", "4L35", "4E"],
			dragontail: ["9M", "9L15", "8L15", "8V", "7M", "7L31", "6M", "6L31", "5M", "5L31"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			extremespeed: ["9E", "8E", "7E", "7V", "6E", "5E", "4E"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firespin: ["9M", "8M"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "9L60", "8M", "8L60", "8V", "7M", "7L61", "7V", "6M", "6L61", "5M", "5L61", "4M", "4L55", "3M", "3L57"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M"],
			irontail: ["8M", "8V", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			leer: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4E", "3E"],
			mimic: ["7V", "3T"],
			mist: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			outrage: ["9M", "9L55", "8M", "8L55", "8V", "7T", "7L55", "7V", "6T", "6L55", "5T", "5L55", "4T", "4L51", "3L50"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "9L45", "8M", "8L45", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["9L40", "8M", "8L40", "7M", "7L45", "7V", "6M", "6L45", "5M", "5L45", "4M", "4L41", "3M", "3L43"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			slam: ["9L25", "8L25", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L29"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			supersonic: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "9L10", "8M", "8L10", "8V", "7M", "7L5", "7V", "6M", "6L5", "5M", "5L5", "5D", "4M", "4L5", "3T", "3L8"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["9L5", "8L5", "7L11", "7V", "6L11", "5L11", "4T", "4L11", "3L15"],
			waterfall: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["9M", "9E", "8E", "7T", "7E", "6T", "6E", "5E", "5D", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			wrap: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 1, level: 10},
		],
	},
	dragonair: {
		learnset: {
			agility: ["9M", "9L20", "8M", "8L20", "8V", "7L25", "7V", "6L25", "5L25", "4L25", "3L38"],
			aquatail: ["9L33", "8L33", "7T", "7L39", "6T", "6L39", "5T", "5L39", "4T", "4L33"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			detect: ["7V"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["7V"],
			dragondance: ["9M", "9L60", "8M", "8L60", "7L61", "6L61", "5L61", "4L53"],
			dragonpulse: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			dragonrage: ["8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L22"],
			dragonrush: ["9L39", "8L39", "7L47", "6L47", "5L47", "4L39"],
			dragontail: ["9M", "9L15", "8L15", "8V", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firespin: ["9M", "8M"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			horndrill: ["7V"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "9L74", "8M", "8L74", "8V", "7M", "7L75", "7V", "6M", "6L75", "5M", "5L75", "4M", "4L67", "3M", "3L65"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M"],
			mimic: ["7V", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "9L67", "8M", "8L67", "8V", "7T", "7L67", "7V", "6T", "6L67", "5T", "5L67", "4T", "4L61", "3L56"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "9L53", "8M", "8L53", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["9L46", "8M", "8L46", "7M", "7L53", "7V", "6M", "6L53", "5M", "5L53", "4M", "4L47", "3M", "3L47"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			slam: ["9L25", "8L25", "8V", "7L21", "7V", "6L21", "5L21", "4L21", "3L29"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3T", "3L1"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4T", "4L1", "3L1"],
			waterfall: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			wrap: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 1, level: 15},
			{generation: 2, level: 10},
			{generation: 3, level: 25, pokeball: "safariball"},
			{generation: 4, level: 15},
			{generation: 7, level: 10},
		],
	},
	dragonite: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L20", "8M", "8L20", "8V", "7L25", "7V", "6L25", "6S8", "5L25", "4L25", "3L38", "3S0"],
			aircutter: ["9M", "4T"],
			airslash: ["9M", "8M"],
			aquajet: ["8V"],
			aquatail: ["9L33", "8L33", "7T", "7L39", "6T", "6L39", "5T", "5L39", "4T", "4L33"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["6S8"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M", "8T", "8S9", "7T", "6T", "5T", "4T", "4S2"],
			dragonbreath: ["7V"],
			dragonclaw: ["9M", "8M", "8S9", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "9L62", "8M", "8L62", "8S9", "7L61", "6L61", "6S7", "5L61", "5S3", "4L53", "4S2", "3S1"],
			dragonpulse: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			dragonrage: ["8V", "7L15", "7V", "6L15", "5L15", "4L15", "3L22"],
			dragonrush: ["9L39", "8L39", "7L47", "6L47", "5L47", "5S4", "5S5", "4L39"],
			dragontail: ["9M", "9L15", "8L15", "8V", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			dualwingbeat: ["8T"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M", "3S1"],
			encore: ["9M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			extremespeed: ["9L1", "8L1", "6S7", "5S3", "5S5"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "5S6", "4M", "3M"],
			firepunch: ["9M", "9L1", "8M", "8L1", "8V", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "5S3", "4T", "4L1", "3T"],
			firespin: ["9M", "8M"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fly: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["3S1"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			horndrill: ["7V"],
			hurricane: ["9M", "9L0", "8M", "8L0", "8S9", "7L1", "6L1", "6S7", "5L81"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "9L80", "8M", "8L80", "8V", "7M", "7L75", "7V", "6M", "6L75", "6S8", "5M", "5L75", "5S6", "4M", "4L73", "3M", "3L75", "3S1"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icespinner: ["9M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M"],
			lowkick: ["9M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M"],
			mimic: ["7V", "3T"],
			mist: ["8V"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			outrage: ["9M", "9L41", "8M", "8L41", "8V", "7T", "7L67", "7V", "6T", "6L67", "6S7", "5T", "5L67", "5S3", "5S6", "4T", "4L64", "4S2", "3L61", "3S0"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7V"],
			raindance: ["9M", "9L53", "8M", "8L53", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			reflect: ["8V", "7V"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			roost: ["9L1", "8L1", "8V", "7M", "7L1", "6M", "6L1", "5T", "5L1", "4M", "4L1"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["9L46", "8M", "8L46", "7M", "7L53", "7V", "6M", "6L53", "5M", "5L53", "5S4", "5S5", "5S6", "4M", "4L47", "3M", "3L47", "3S0"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skullbash: ["7V"],
			skydrop: ["7M", "6M", "5M"],
			slam: ["9L25", "8L25", "8V", "7L21", "7V", "6L21", "6S8", "5L21", "4L21", "3L29"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M", "7V"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "4S2", "3M"],
			thunderpunch: ["9M", "9L1", "8M", "8L1", "8V", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "5S4", "4T", "4L1", "3T"],
			thunderwave: ["9M", "9L1", "8M", "8L1", "8V", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3T", "3L1"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4T", "4L1", "3L1"],
			waterfall: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			wingattack: ["9L1", "8L1", "8V", "7L1", "7V", "6L55", "5L55", "5S4", "5S5", "4L55", "3L55", "3S0"],
			wrap: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["agility", "safeguard", "wingattack", "outrage"], pokeball: "pokeball"},
			{generation: 3, level: 55, moves: ["healbell", "hyperbeam", "dragondance", "earthquake"]},
			{generation: 4, level: 50, gender: "M", nature: "Mild", moves: ["dracometeor", "thunderbolt", "outrage", "dragondance"], pokeball: "cherishball"},
			{generation: 5, level: 100, gender: "M", isHidden: true, moves: ["extremespeed", "firepunch", "dragondance", "outrage"], pokeball: "cherishball"},
			{generation: 5, level: 55, gender: "M", isHidden: true, moves: ["dragonrush", "safeguard", "wingattack", "thunderpunch"]},
			{generation: 5, level: 55, gender: "M", isHidden: true, moves: ["dragonrush", "safeguard", "wingattack", "extremespeed"]},
			{generation: 5, level: 50, gender: "M", nature: "Brave", ivs: {hp: 30, atk: 30, def: 30, spa: 30, spd: 30, spe: 30}, moves: ["fireblast", "safeguard", "outrage", "hyperbeam"], pokeball: "cherishball"},
			{generation: 6, level: 55, gender: "M", isHidden: true, moves: ["dragondance", "outrage", "hurricane", "extremespeed"], pokeball: "cherishball"},
			{generation: 6, level: 62, gender: "M", ivs: {hp: 31, def: 31, spa: 31, spd: 31}, moves: ["agility", "slam", "barrier", "hyperbeam"], pokeball: "cherishball"},
			{generation: 8, level: 80, gender: "F", nature: "Jolly", abilities: ["innerfocus"], ivs: {hp: 30, atk: 31, def: 30, spa: 30, spd: 31, spe: 31}, moves: ["dragonclaw", "dracometeor", "hurricane", "dragondance"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 5, level: 50},
			{generation: 7, level: 10},
		],
	},
	mewtwo: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "8M", "8V"],
			allyswitch: ["8M"],
			amnesia: ["9M", "9L32", "8M", "8L32", "8V", "7L79", "7V", "6L79", "5L50", "4L57", "4S1", "3L77"],
			ancientpower: ["9L8", "8L8"],
			aquatail: ["7T", "6T", "5T", "4T"],
			aurasphere: ["9M", "9L40", "8M", "8L40", "7L70", "6L70", "6S4", "6S5", "5L93", "5S2", "4L100"],
			avalanche: ["9M", "8M", "4M"],
			barrier: ["8V", "7L64", "7V", "6L64", "6S4", "5L1", "4L8", "3L11"],
			bide: ["7V"],
			blizzard: ["9M", "8M", "8V", "8S7", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			bulkup: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "8V"],
			confusion: ["9L1", "8L1", "8V", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			counter: ["7V", "3T"],
			curse: ["7V"],
			darkpulse: ["9M"],
			detect: ["7V"],
			disable: ["9L1", "8L1", "8V", "8S7", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			dive: ["8M", "6M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["9M"],
			earthquake: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			electroball: ["9M", "8M", "5S2"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8M", "8V", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["9L88", "8M", "8L88", "7L15", "7V", "6L15", "5L15", "4L22", "3L44"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["9L56", "8M", "8L56", "7L43", "6L43", "5L57", "4L64", "4S1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			healpulse: ["5S3"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["9M", "8M", "5S3"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "5S3", "4M", "3M"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["9M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["8L1", "7T", "7L1"],
			lifedew: ["9L1", "8L1"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			mefirst: ["7L93", "6L93", "5L71", "4L79"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "7V", "3T"],
			metronome: ["9M", "8M", "7V", "3T"],
			mimic: ["7V", "3T"],
			miracleeye: ["7L29", "6L29", "5L29", "4L36"],
			mist: ["9L64", "8L64", "8V", "7L86", "7V", "6L86", "5L36", "4L43", "3L22"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "8M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["9M"],
			payday: ["8M", "8V", "7V"],
			poisonjab: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			powergem: ["9M"],
			powerswap: ["9L56", "8M", "8L56", "7L43", "6L43", "5L57", "4L64", "4S1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "8V"],
			psychic: ["9M", "9L48", "8M", "8L48", "8V", "8S7", "7M", "7L57", "7V", "7S6", "6M", "6L57", "6S4", "6S5", "5M", "5L64", "4M", "4L71", "3M", "3L66", "3S0"],
			psychicterrain: ["9M", "8M"],
			psychocut: ["9L16", "8M", "8L16", "7L36", "7S6", "6L36", "5L43", "4L50", "4S1"],
			psychup: ["7M", "7L22", "7V", "6M", "6L22", "5M", "5L22", "4M", "4L29", "3T", "3L33"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			psystrike: ["9L72", "8L72", "7L100", "6L100", "6S5", "5L100", "5S2", "5S3"],
			psywave: ["8V", "7L1", "7V"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["9L80", "8L80", "8V", "8S7", "7L50", "7V", "7S6", "6L50", "6S4", "6S5", "5L79", "4L86", "3L44", "3S0"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["9L24", "8M", "8L24", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L86", "4M", "4L93", "3M", "3L55", "3S0"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "5S2", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			skullbash: ["7V"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			speedswap: ["8M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			storedpower: ["9M", "8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L1", "8M", "8L1", "8V", "7L8", "7V", "7S6", "6L8", "5L8", "4T", "4L15", "3T", "3L22", "3S0"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			triattack: ["8M", "8V", "7V"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			watergun: ["7V"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 70, shiny: 1, moves: ["swift", "recover", "safeguard", "psychic"]},
			{generation: 4, level: 70, shiny: 1, moves: ["psychocut", "amnesia", "powerswap", "guardswap"]},
			{generation: 5, level: 70, moves: ["psystrike", "shadowball", "aurasphere", "electroball"], pokeball: "cherishball"},
			{generation: 5, level: 100, nature: "Timid", ivs: {spa: 31, spe: 31}, isHidden: true, moves: ["psystrike", "icebeam", "healpulse", "hurricane"], pokeball: "cherishball"},
			{generation: 6, level: 70, moves: ["recover", "psychic", "barrier", "aurasphere"]},
			{generation: 6, level: 100, shiny: true, isHidden: true, moves: ["psystrike", "psychic", "recover", "aurasphere"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["psychic", "recover", "swift", "psychocut"]},
			{generation: 8, level: 70, shiny: 1, moves: ["psychic", "disable", "recover", "blizzard"]},
		],
		encounters: [
			{generation: 1, level: 70},
		],
		eventOnly: true,
	},
	mew: {
		learnset: {
			acidspray: ["9M"],
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			afteryou: ["7T", "6T", "5T"],
			agility: ["9M", "8M"],
			aircutter: ["9M", "4T"],
			airslash: ["9M", "8M"],
			allyswitch: ["8M", "7T", "5M"],
			amnesia: ["9M", "9L10", "8M", "8L10", "8V", "7L60", "6L60", "5L60", "4L60", "4S17"],
			ancientpower: ["9L30", "8L30", "7L50", "7V", "6L50", "5L50", "4T", "4L50", "4S14", "3L50"],
			aquatail: ["7T", "6T", "5T", "4T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurasphere: ["9M", "9L90", "8M", "8L90", "7L100", "6L100", "5L100", "4L100", "4S14", "4S15", "4S16", "4S17", "4S18", "4S19"],
			auroraveil: ["7M"],
			avalanche: ["9M", "8M", "4M"],
			barrier: ["8V", "7L40", "7S24", "6L40", "5L40", "4L40", "4S15"],
			batonpass: ["9M", "9L20", "8M", "8L20", "7L80", "6L80", "5L80", "4L80"],
			beatup: ["8M"],
			bide: ["7V"],
			bind: ["7T", "6T", "5T"],
			blastburn: ["9M"],
			blazekick: ["8M"],
			blizzard: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M", "7V", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			bravebird: ["9M", "8M"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "4M"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["7V"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["9M", "8M"],
			bulkup: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			bulletseed: ["9M", "8M", "4M", "3M"],
			burningjealousy: ["8T"],
			calmmind: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "8M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["8V"],
			corrosivegas: ["8T"],
			cosmicpower: ["8M"],
			counter: ["7V", "3T"],
			covet: ["7T", "6T", "5T"],
			crosspoison: ["8M"],
			crunch: ["9M", "8M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			darkestlariat: ["8M"],
			darkpulse: ["9M", "8M", "8V", "7M", "6M", "5T", "4M"],
			dazzlinggleam: ["9M", "8M", "8V", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			dig: ["9M", "8M", "8V", "7V", "6M", "5M", "4M", "3M"],
			disarmingvoice: ["9M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["7V", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M"],
			dragonbreath: ["7V"],
			dragonclaw: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "8M"],
			dragonpulse: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			dragonrage: ["7V"],
			dragontail: ["9M", "8V", "7M", "6M", "5M"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			drillrun: ["9M", "8M", "8V", "7T", "6T", "5T"],
			dualchop: ["7T", "6T", "5T"],
			dualwingbeat: ["8T"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			eerieimpulse: ["9M", "8M"],
			eggbomb: ["7V"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "8M"],
			electroweb: ["8M", "7T", "6T", "5T"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			explosion: ["7M", "7V", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["3S2", "3S3"],
			faketears: ["9M", "8M"],
			falseswipe: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feintattack: ["3S4", "3S5"],
			fireblast: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "8M"],
			firepledge: ["9M"],
			firepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			firespin: ["9M", "8M"],
			fissure: ["7V"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			flareblitz: ["9M", "8M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			flashcannon: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			fly: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8M", "8V", "7T", "6T", "5T"],
			frenzyplant: ["9M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			futuresight: ["8M"],
			gastroacid: ["7T", "6T", "5T", "4T"],
			gigadrain: ["9M", "8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grasspledge: ["9M"],
			grassyglide: ["8T"],
			grassyterrain: ["9M", "8M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["8M"],
			gunkshot: ["9M", "8M", "7T", "6T", "5T", "4T"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8V", "7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			heatcrash: ["8M"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M", "5M"],
			horndrill: ["7V"],
			hurricane: ["9M", "8M"],
			hydrocannon: ["9M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			hypnosis: ["4S20", "3S6", "3S7"],
			icebeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			icefang: ["9M", "8M"],
			icepunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "9L70", "8M", "8L70"],
			incinerate: ["6M", "5M"],
			infestation: ["7M", "6M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "8V", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leafblade: ["8M"],
			leafstorm: ["9M", "8M"],
			leechlife: ["9M", "8M", "7M"],
			lifedew: ["9L40", "8L40"],
			lightscreen: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			liquidation: ["9M", "8M", "7T"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magicalleaf: ["9M", "8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mefirst: ["7L70", "6L70", "5L70", "4L70"],
			megadrain: ["8V", "7V"],
			megahorn: ["8M", "8V"],
			megakick: ["8M", "7V", "3T"],
			megapunch: ["8M", "8V", "7L10", "7V", "6L10", "5L10", "4L10", "4S16", "3T", "3L20", "3S0"],
			metalclaw: ["9M"],
			meteorbeam: ["8T"],
			metronome: ["9M", "9L60", "8M", "8L60", "8V", "7L20", "7V", "7S24", "6L20", "5L20", "4L20", "4S14", "4S15", "4S16", "4S17", "4S18", "4S19", "3T", "3L30", "3S0"],
			mimic: ["8V", "7V", "3T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M", "8M"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			mysticalfire: ["8M"],
			nastyplot: ["9M", "9L50", "8M", "8L50", "8V", "7L90", "6L90", "5L90", "4L90"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			nightmare: ["7V", "3T"],
			nightshade: ["9M", "3S8", "3S9"],
			ominouswind: ["4T"],
			outrage: ["9M", "8M", "8V", "7T", "6T", "5T", "4T"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			payday: ["8M", "8V", "7V"],
			phantomforce: ["9M", "8M"],
			pinmissile: ["8M"],
			playrough: ["9M", "8M", "8V"],
			pluck: ["5M", "4M"],
			poisonjab: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			poisontail: ["9M"],
			pollenpuff: ["9M", "8M"],
			poltergeist: ["8T"],
			pounce: ["9M"],
			pound: ["9L1", "8L1", "8V", "8S25", "7L1", "7V", "7S23", "6L1", "6S22", "5L1", "4L1", "4S21", "3L1", "3S0", "3S1"],
			powergem: ["9M", "8M"],
			powerswap: ["8M"],
			poweruppunch: ["6M"],
			powerwhip: ["8M"],
			protect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L100", "8M", "8L100", "8V", "7M", "7L30", "7V", "7S24", "6M", "6L30", "5M", "5L30", "4M", "4L30", "4S19", "3M", "3L40"],
			psychicfangs: ["9M", "8M"],
			psychicterrain: ["9M", "8M"],
			psychocut: ["8M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			psywave: ["8V", "7V"],
			quash: ["7M", "6M", "5M"],
			rage: ["7V"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			razorshell: ["8M"],
			razorwind: ["7V"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflecttype: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			rest: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "4S20", "3M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["9M", "8M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T", "3S10", "3S11"],
			rollout: ["7V", "4T", "3T"],
			roost: ["8V", "7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			scald: ["8M", "8V", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "8M"],
			scorchingsands: ["8T"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T", "4T"],
			seismictoss: ["8V", "7V", "3T"],
			selfdestruct: ["8M", "8V", "7V", "3T"],
			shadowball: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			skittersmack: ["8T"],
			skullbash: ["7V"],
			skyattack: ["7T", "7V", "6T", "5T", "4T", "3T"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			smartstrike: ["9M", "8M", "7M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			softboiled: ["7V", "3T"],
			solarbeam: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			solarblade: ["8M"],
			speedswap: ["8M"],
			spikes: ["9M", "8M"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "8V", "7T", "6T", "5T", "4M"],
			steelbeam: ["9M"],
			steelroller: ["8T"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			storedpower: ["9M", "8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["9M", "6M", "5M"],
			submission: ["7V"],
			substitute: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			superpower: ["8M", "8V", "7T", "6T", "5T", "4T"],
			surf: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["9M", "8M", "8V", "7V", "4T", "3T"],
			swordsdance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T", "4S20"],
			tailslap: ["8M"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M", "7V"],
			taunt: ["9M", "8M", "8V", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["8V", "7V", "4S14", "4S15", "4S16", "4S17", "4S18", "4S19", "4S20"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			thunder: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderfang: ["9M", "8M"],
			thunderpunch: ["9M", "8M", "8V", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M", "8M"],
			trailblaze: ["9M"],
			transform: ["9L80", "8L80", "8V", "7L1", "7V", "7S24", "6L1", "5L1", "4L1", "4S18", "3L10", "3S0", "3S1"],
			triattack: ["8M", "8V", "7V"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			tripleaxel: ["8T"],
			twister: ["4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			vacuumwave: ["4T"],
			venomdrench: ["8M"],
			venoshock: ["9M", "8M", "7M", "6M", "5M"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M"],
			waterfall: ["9M", "8M", "8V", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["7V"],
			waterpledge: ["9M"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7V", "4M"],
			whirlwind: ["7V"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			workup: ["8M", "7M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			xscissor: ["9M", "8M", "8V", "7M", "6M", "5M", "4M"],
			zapcannon: ["7V", "3S12", "3S13"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 30, shiny: 1, moves: ["pound", "transform", "megapunch", "metronome"]},
			{generation: 3, level: 10, moves: ["pound", "transform"], pokeball: "pokeball"},
			{generation: 3, level: 30, shiny: 1, moves: ["fakeout"]},
			{generation: 3, level: 10, moves: ["fakeout"], pokeball: "pokeball"},
			{generation: 3, level: 30, shiny: 1, moves: ["feintattack"]},
			{generation: 3, level: 10, moves: ["feintattack"], pokeball: "pokeball"},
			{generation: 3, level: 30, shiny: 1, moves: ["hypnosis"]},
			{generation: 3, level: 10, moves: ["hypnosis"], pokeball: "pokeball"},
			{generation: 3, level: 30, shiny: 1, moves: ["nightshade"]},
			{generation: 3, level: 10, moves: ["nightshade"], pokeball: "pokeball"},
			{generation: 3, level: 30, shiny: 1, moves: ["roleplay"]},
			{generation: 3, level: 10, moves: ["roleplay"], pokeball: "pokeball"},
			{generation: 3, level: 30, shiny: 1, moves: ["zapcannon"]},
			{generation: 3, level: 10, moves: ["zapcannon"], pokeball: "pokeball"},
			{generation: 4, level: 50, moves: ["ancientpower", "metronome", "teleport", "aurasphere"], pokeball: "cherishball"},
			{generation: 4, level: 50, moves: ["barrier", "metronome", "teleport", "aurasphere"], pokeball: "cherishball"},
			{generation: 4, level: 50, moves: ["megapunch", "metronome", "teleport", "aurasphere"], pokeball: "cherishball"},
			{generation: 4, level: 50, moves: ["amnesia", "metronome", "teleport", "aurasphere"], pokeball: "cherishball"},
			{generation: 4, level: 50, moves: ["transform", "metronome", "teleport", "aurasphere"], pokeball: "cherishball"},
			{generation: 4, level: 50, moves: ["psychic", "metronome", "teleport", "aurasphere"], pokeball: "cherishball"},
			{generation: 4, level: 50, moves: ["synthesis", "return", "hypnosis", "teleport"], pokeball: "cherishball"},
			{generation: 4, level: 5, moves: ["pound"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["pound"], pokeball: "cherishball"},
			{generation: 7, level: 5, perfectIVs: 5, moves: ["pound"], pokeball: "pokeball"},
			{generation: 7, level: 50, moves: ["psychic", "barrier", "metronome", "transform"], pokeball: "cherishball"},
			{generation: 8, level: 1, moves: ["pound"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	chikorita: {
		learnset: {
			ancientpower: ["7E", "7V", "6E", "5E", "4T", "4E", "3E", "3S1"],
			aromatherapy: ["7L42", "7E", "6L42", "6E", "5L42", "5E", "4L42", "4E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7L34", "7E", "7V", "6L34", "6E", "5L34", "5E", "4L34", "4E", "3T", "3L29"],
			bulletseed: ["4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["7V", "4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flail: ["7E", "7V", "6E", "5E", "4E", "3E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frenzyplant: ["3S1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["4T"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grasspledge: ["7T", "6T", "5T"],
			grasswhistle: ["7E", "6E", "5E", "4E", "3E"],
			grassyterrain: ["7E", "6E"],
			growl: ["7L1", "7V", "6L1", "6S2", "5L1", "4L1", "3L1", "3S0", "3S1"],
			headbutt: ["7V", "4T"],
			healpulse: ["7E", "6E", "5E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			ingrain: ["7E", "6E", "5E", "4E", "3E"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			leafstorm: ["7E", "6E", "5E", "4E"],
			leechseed: ["7E", "7V", "6E", "5E", "4E", "3E"],
			lightscreen: ["7M", "7L31", "7V", "6M", "6L31", "5M", "5L31", "4M", "4L31", "3M", "3L36"],
			magicalleaf: ["7L20", "6L20", "5L20", "4L20"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7L23", "6L23", "5L23", "4M", "4L23"],
			naturepower: ["7M", "7E", "6M", "6E", "5E", "4E", "3E"],
			poisonpowder: ["7L9", "7V", "6L9", "5L9", "4L9", "3L15"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			razorleaf: ["7L6", "7V", "6L6", "5L6", "4L6", "3L8", "3S0"],
			reflect: ["7M", "7L17", "7V", "6M", "6L17", "5M", "5L17", "4M", "4L17", "3M", "3L12"],
			refresh: ["7E", "6E", "5E"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7L39", "7V", "6M", "6L39", "5M", "5L39", "4M", "4L39", "3M", "3L43"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "7L45", "7V", "6M", "6L45", "5M", "5L45", "4M", "4L45", "3M", "3L50"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7L28", "7V", "6L28", "5L28", "4L28"],
			swordsdance: ["7M", "7V", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "7L12", "7V", "6T", "6L12", "5T", "5L12", "4T", "4L12", "3L22"],
			tackle: ["7L1", "7V", "6L1", "6S2", "5L1", "4L1", "3L1", "3S0", "3S1"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			vinewhip: ["7E", "7V", "6E", "5E", "4E", "3E"],
			workup: ["7M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			wringout: ["7E", "6E", "5E", "4E"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "growl", "razorleaf"], pokeball: "pokeball"},
			{generation: 3, level: 5, moves: ["tackle", "growl", "ancientpower", "frenzyplant"], pokeball: "pokeball"},
			{generation: 6, level: 5, moves: ["tackle", "growl"], pokeball: "cherishball"},
		],
	},
	bayleef: {
		learnset: {
			ancientpower: ["4T"],
			aromatherapy: ["7L50", "6L50", "5L50", "4L50"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7L40", "7V", "6L40", "5L40", "4L40", "3T", "3L31"],
			bulletseed: ["4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["7V", "4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grasspledge: ["7T", "6T", "5T"],
			growl: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lightscreen: ["7M", "7L36", "7V", "6M", "6L36", "5M", "5L36", "4M", "4L36", "3M", "3L39"],
			magicalleaf: ["7L22", "6L22", "5L22", "4L22"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7L26", "6L26", "5L26", "4M", "4L26"],
			naturepower: ["7M", "6M"],
			poisonpowder: ["7L1", "7V", "6L1", "5L1", "4L1", "3L15"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			razorleaf: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			reflect: ["7M", "7L18", "7V", "6M", "6L18", "5M", "5L18", "4M", "4L18", "3M", "3L1"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7L46", "7V", "6M", "6L46", "5M", "5L46", "4M", "4L46", "3M", "3L47"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "7L54", "7V", "6M", "6L54", "5M", "5L54", "4M", "4L54", "3M", "3L55"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7L32", "7V", "6L32", "5L32", "4L32"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "7L12", "7V", "6T", "6L12", "5T", "5L12", "4T", "4L12", "3L23"],
			tackle: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			workup: ["7M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
	},
	meganium: {
		learnset: {
			ancientpower: ["4T"],
			aromatherapy: ["7L60", "6L60", "5L60", "4L60"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7L46", "7V", "6L46", "6S0", "5L46", "4L46", "3T", "3L31"],
			bulldoze: ["7M", "6M", "5M"],
			bulletseed: ["4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragontail: ["7M", "6M", "5M"],
			earthquake: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["7V", "4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frenzyplant: ["7T", "6T", "5T", "4T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grasspledge: ["7T", "6T", "5T"],
			growl: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lightscreen: ["7M", "7L40", "7V", "6M", "6L40", "5M", "5L40", "4M", "4L40", "3M", "3L41"],
			magicalleaf: ["7L22", "6L22", "5L22", "4L22"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7L26", "6L26", "5L26", "4M", "4L26"],
			naturepower: ["7M", "6M"],
			outrage: ["7T", "6T", "5T", "4T"],
			petalblizzard: ["7L1", "6L1"],
			petaldance: ["7L1", "6L32", "5L32", "4L32"],
			poisonpowder: ["7L1", "7V", "6L1", "5L1", "4L1", "3L15"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			razorleaf: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			reflect: ["7M", "7L18", "7V", "6M", "6L18", "5M", "5L18", "4M", "4L18", "3M", "3L1"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7L54", "7V", "6M", "6L54", "5M", "5L54", "4M", "4L54", "3M", "3L51"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "7L66", "7V", "6M", "6L66", "6S0", "5M", "5L66", "4M", "4L66", "3M", "3L61"],
			stompingtantrum: ["7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "6S0", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7L34", "7V", "6L34", "5L34", "4L34"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "7L12", "7V", "6T", "6L12", "6S0", "5T", "5L12", "4T", "4L12", "3L23"],
			tackle: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			workup: ["7M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 6, level: 50, isHidden: true, moves: ["solarbeam", "sunnyday", "synthesis", "bodyslam"], pokeball: "pokeball"},
		],
	},
	cyndaquil: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			blastburn: ["3S1"],
			bodyslam: ["9M", "3T"],
			burnup: ["7L58"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			covet: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "4E", "3E"],
			crushclaw: ["7E", "6E", "5E", "4E", "3E"],
			curse: ["9E", "7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["9L22", "7L22", "7V", "6L22", "5L22", "4L22", "3T"],
			detect: ["7V"],
			dig: ["9M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L55", "7L55", "7E", "6L55", "6E", "5L55", "5E", "4L46", "4E", "3T"],
			doublekick: ["9E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			ember: ["9L10", "7L10", "7V", "6L10", "5L10", "4L10", "3L12"],
			endure: ["9M", "7V", "4M", "3T"],
			eruption: ["9L64", "7L64", "6L58", "5L58", "4L49"],
			extrasensory: ["9E", "7E", "6E", "5E", "4E"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M"],
			firepledge: ["9M", "7T", "6T", "5T"],
			firespin: ["9M"],
			flameburst: ["7E", "6E", "5E"],
			flamecharge: ["9M", "9L28", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			flamethrower: ["9M", "9L40", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L40", "4M", "4L37", "3M", "3L46"],
			flamewheel: ["9L19", "7L19", "7V", "6L19", "5L19", "4L19", "3L27"],
			flareblitz: ["9M", "7E", "6E", "5E", "4E"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["7V", "4T"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			howl: ["9E", "7E", "6E", "5E", "4E", "3E"],
			incinerate: ["6M", "5M"],
			inferno: ["9L46", "7L46", "6L46", "5L46"],
			ironhead: ["9M"],
			irontail: ["7V"],
			lavaplume: ["9L37", "7L37", "6L37", "5L37", "4L31"],
			leer: ["9L1", "7L1", "7V", "6L1", "6S2", "5L1", "4L1", "3L1", "3S0", "3S1"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "7E", "6M", "6E", "5E"],
			overheat: ["9M", "9L58", "7M", "6M", "5M", "4M", "3M"],
			playrough: ["9M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9L13", "7L13", "7E", "7V", "6L13", "6E", "5L13", "5E", "4L13", "4E", "3L19", "3E"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "9E", "7E", "7V", "6E", "5E", "4E", "3E", "3S1"],
			rollout: ["9L49", "7L49", "7V", "6L49", "5L49", "4T", "4L40", "3T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["9L6", "7L6", "7V", "6L6", "5L6", "4L4", "3L6", "3S0"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			submission: ["7V"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L31", "7L31", "7V", "6L31", "5L31", "4T", "4L28", "3T", "3L36"],
			tackle: ["9L1", "7L1", "7V", "6L1", "6S2", "5L1", "4L1", "3L1", "3S0", "3S1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["7E", "7V", "6E", "5E", "4E", "3E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
			workup: ["7M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "leer", "smokescreen"], pokeball: "pokeball"},
			{generation: 3, level: 5, moves: ["tackle", "leer", "reversal", "blastburn"], pokeball: "pokeball"},
			{generation: 6, level: 5, moves: ["tackle", "leer"], pokeball: "cherishball"},
		],
	},
	quilava: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			burnup: ["7L68"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["9L24", "7L24", "7V", "6L24", "5L24", "4L24", "3T"],
			detect: ["7V"],
			dig: ["9M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L64", "7L64", "6L64", "5L64", "4L53", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			ember: ["9L10", "7L10", "7V", "6L10", "5L10", "4L10", "3L12"],
			endure: ["9M", "7V", "4M", "3T"],
			eruption: ["9L75", "7L75", "6L68", "5L68", "4L57"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M"],
			firepledge: ["9M", "7T", "6T", "5T"],
			firespin: ["9M"],
			flamecharge: ["9M", "9L35", "7M", "7L35", "6M", "6L35", "5M", "5L35"],
			flamethrower: ["9M", "9L46", "7M", "7L46", "7V", "6M", "6L46", "5M", "5L46", "4M", "4L42", "3M", "3L54"],
			flamewheel: ["9L20", "7L20", "7V", "6L20", "5L20", "4L20", "3L31"],
			flareblitz: ["9M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			headbutt: ["7V", "4T"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			inferno: ["9L53", "7L53", "6L53", "5L53"],
			ironhead: ["9M"],
			irontail: ["7V"],
			lavaplume: ["9L42", "7L42", "6L42", "5L42", "4L35"],
			leer: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			overheat: ["9M", "9L68", "7M", "6M", "5M", "4M", "3M"],
			playrough: ["9M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9L13", "7L13", "7V", "6L13", "5L13", "4L13", "3L21"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rollout: ["9L57", "7L57", "7V", "6L57", "5L57", "4T", "4L46", "3T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L31", "7L31", "7V", "6L31", "5L31", "4T", "4L31", "3T", "3L42"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
			workup: ["7M"],
			zenheadbutt: ["9M"],
		},
	},
	typhlosion: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			blastburn: ["9M", "7T", "6T", "5T", "4T"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			burnup: ["7L74"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["9L24", "7L24", "7V", "6L24", "5L24", "4L24", "3T"],
			detect: ["7V"],
			dig: ["9M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L1", "7L1", "6L1", "5L69", "4L53", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			ember: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			endure: ["9M", "7V", "4M", "3T"],
			eruption: ["9L1", "7L1", "6L1", "5L74", "4L57"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M"],
			firepledge: ["9M", "7T", "6T", "5T"],
			firepunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			firespin: ["9M"],
			flamecharge: ["9M", "9L35", "7M", "7L35", "6M", "6L35", "6S1", "5M", "5L35"],
			flamethrower: ["9M", "9L48", "7M", "7L48", "7V", "6M", "6L48", "5M", "5L48", "4M", "4L42", "3M", "3L60", "3S0"],
			flamewheel: ["9L20", "7L20", "7V", "6L20", "6S1", "5L20", "4L20", "3L31", "3S0"],
			flareblitz: ["9M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gyroball: ["9L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			headbutt: ["7V", "4T"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			inferno: ["9L56", "7L56", "6L56", "5L56"],
			ironhead: ["9M"],
			irontail: ["7V"],
			laserfocus: ["7T"],
			lavaplume: ["9L43", "7L43", "6L43", "5L43", "4L35"],
			leer: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			overheat: ["9M", "9L74", "7M", "6M", "6S1", "5M", "4M", "3M"],
			playrough: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9L13", "7L13", "7V", "6L13", "5L13", "4L13", "3L21", "3S0"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			rollout: ["9L61", "7L61", "7V", "6L61", "5L61", "4T", "4L46", "3T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["9M"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M"],
			stompingtantrum: ["9M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "9L31", "7L31", "7V", "6L31", "6S1", "5L31", "4T", "4L31", "3T", "3L45", "3S0"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			throatchop: ["7T"],
			thunderpunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
			workup: ["7M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["quickattack", "flamewheel", "swift", "flamethrower"], pokeball: "pokeball"},
			{generation: 6, level: 50, isHidden: true, moves: ["overheat", "flamewheel", "flamecharge", "swift"], pokeball: "pokeball"},
		],
	},
	typhlosionhisui: {
		learnset: {
			aerialace: ["9M"],
			blastburn: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			calmmind: ["9M"],
			confuseray: ["9M"],
			defensecurl: ["9L24"],
			dig: ["9M"],
			doubleedge: ["9L1"],
			earthquake: ["9M"],
			ember: ["9L1"],
			endure: ["9M"],
			eruption: ["9L1"],
			facade: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M"],
			firepledge: ["9M"],
			firepunch: ["9M"],
			firespin: ["9M"],
			flamecharge: ["9M", "9L35"],
			flamethrower: ["9M", "9L48"],
			flamewheel: ["9L20"],
			flareblitz: ["9M"],
			focusblast: ["9M"],
			gigaimpact: ["9M"],
			gyroball: ["9L1"],
			heatwave: ["9M"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			infernalparade: ["9L0"],
			inferno: ["9L56"],
			ironhead: ["9M"],
			lavaplume: ["9L43"],
			leer: ["9L1"],
			lowkick: ["9M"],
			nightshade: ["9M"],
			overheat: ["9M", "9L74"],
			playrough: ["9M"],
			protect: ["9M"],
			quickattack: ["9L13"],
			rest: ["9M"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rollout: ["9L61"],
			shadowball: ["9M"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M"],
			smokescreen: ["9L1"],
			solarbeam: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M", "9L31"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderpunch: ["9M"],
			wildcharge: ["9M"],
			willowisp: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	totodile: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["7E", "7V", "6E", "5E", "4T", "4E", "3E"],
			aquajet: ["7E", "6E", "5E", "4E"],
			aquatail: ["7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L36"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bite: ["7L13", "7V", "6L13", "5L13", "4L13", "3L20"],
			blizzard: ["7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "7E", "6T", "6E", "5T", "5E"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chipaway: ["7L29", "6L29", "5L29"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["7L27", "7E", "7V", "6L27", "6E", "5L27", "5E", "4L27", "4E", "3E", "3S1"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			dig: ["7V", "6M", "5M", "4M", "3M"],
			dive: ["6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonclaw: ["7M", "6M", "5M", "4E", "3E"],
			dragondance: ["7E", "6E", "5E", "4E"],
			dynamicpunch: ["7V", "3T"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			faketears: ["7E", "6E", "5E"],
			flail: ["7L22", "6L22", "5L22", "4L22"],
			flatter: ["7E", "6E"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hail: ["7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydrocannon: ["3S1"],
			hydropump: ["7L50", "7E", "7V", "6L50", "6E", "5L50", "5E", "4L43", "4E", "3L52", "3E"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icefang: ["7L20", "6L20", "5L20", "4L20"],
			icepunch: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T"],
			icywind: ["7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["7L1", "7V", "6L1", "6S2", "5L1", "4L1", "3L1", "3S0", "3S1"],
			lowkick: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metalclaw: ["7E", "6E", "5E", "4E"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7L8", "7V", "6L8", "5L8", "4L8", "3L7", "3S0"],
			raindance: ["7M", "7V", "6M", "5M", "4M", "3M"],
			razorwind: ["7V"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			scaryface: ["7L15", "7V", "6L15", "5L15", "4L15", "3L27"],
			scratch: ["7L1", "7V", "6L1", "6S2", "5L1", "4L1", "3L1", "3S0", "3S1"],
			screech: ["7L36", "7V", "6L36", "5L36", "4L34", "3L43"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			slash: ["7L34", "7V", "6L34", "5L34", "4L29", "3L35"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			superpower: ["7T", "7L48", "6T", "6L48", "5T", "5L48", "4T", "4L41"],
			surf: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			thrash: ["7L41", "7E", "7V", "6L41", "6E", "5L41", "5E", "4L22", "4E", "3E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			waterfall: ["7M", "6M", "5M", "4M", "3M"],
			watergun: ["7L6", "7V", "6L6", "5L6", "4L6", "3L13"],
			waterpledge: ["7T", "6T", "5T"],
			waterpulse: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			watersport: ["7E", "6E", "5E", "4E", "3E"],
			whirlpool: ["7V", "4M"],
			workup: ["7M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["scratch", "leer", "rage"], pokeball: "pokeball"},
			{generation: 3, level: 5, moves: ["scratch", "leer", "crunch", "hydrocannon"], pokeball: "pokeball"},
			{generation: 6, level: 5, moves: ["scratch", "leer"], pokeball: "cherishball"},
		],
	},
	croconaw: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["4T"],
			aquatail: ["7T", "7L51", "6T", "6L51", "5T", "5L51", "4L42"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bite: ["7L13", "7V", "6L13", "5L13", "4L13", "3L21"],
			blizzard: ["7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chipaway: ["7L33", "6L33", "5L33"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["7L30", "6L30", "5L30", "4L30"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			dig: ["7V", "6M", "5M", "4M", "3M"],
			dive: ["6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonclaw: ["7M", "6M", "5M"],
			dynamicpunch: ["7V", "3T"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flail: ["7L24", "6L24", "5L24", "4L24"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			hail: ["7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["7L60", "7V", "6L60", "5L60", "4L51", "3L55"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icefang: ["7L21", "6L21", "5L21", "4L21"],
			icepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7L8", "7V", "6L8", "5L8", "4L8", "3L1"],
			raindance: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			scaryface: ["7L15", "7V", "6L15", "5L15", "4L15", "3L28"],
			scratch: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["7L42", "7V", "6L42", "5L42", "4L39", "3L45"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			slash: ["7L39", "7V", "6L39", "5L39", "4L33", "3L37"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			superpower: ["7T", "7L57", "6T", "6L57", "5T", "5L57", "4T", "4L48"],
			surf: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			thrash: ["7L48", "6L48", "5L48", "4L24"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			waterfall: ["7M", "6M", "5M", "4M", "3M"],
			watergun: ["7L1", "7V", "6L1", "5L1", "4L1", "3L13"],
			waterpledge: ["7T", "6T", "5T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["7V", "4M"],
			workup: ["7M"],
		},
	},
	feraligatr: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["7L1", "6L30", "5L30", "4L30"],
			ancientpower: ["4T"],
			aquatail: ["7T", "7L63", "6T", "6L63", "5T", "5L63", "4T", "4L50"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["4M"],
			bite: ["7L13", "7V", "6L13", "5L13", "4L13", "3L21"],
			blizzard: ["7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L37", "6L37", "5L37"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["7L32", "6L32", "6S0", "5L32", "4L32"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			dig: ["7V", "6M", "5M", "4M", "3M"],
			dive: ["6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonclaw: ["7M", "6M", "5M", "4M", "3M"],
			dragonpulse: ["7T", "6T", "5T", "4M"],
			dragontail: ["7M", "6M", "5M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flail: ["7L24", "6L24", "5L24", "4L24"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			hail: ["7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydrocannon: ["7T", "6T", "5T", "4T"],
			hydropump: ["7L76", "7V", "6L76", "5L76", "4L63", "3L58"],
			hyperbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icefang: ["7L21", "6L21", "5L21", "4L21"],
			icepunch: ["7T", "7V", "6T", "6S0", "5T", "4T", "3T"],
			icywind: ["7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			liquidation: ["7T"],
			lowkick: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["7T", "6T", "5T", "4T"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			scaryface: ["7L15", "7V", "6L15", "5L15", "4L15", "3L28"],
			scratch: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["7L50", "7V", "6L50", "6S0", "5L50", "4L45", "3L47"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			slash: ["7L45", "7V", "6L45", "5L45", "4L37", "3L38"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			superpower: ["7T", "7L71", "6T", "6L71", "5T", "5L71", "4T", "4L58"],
			surf: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			thrash: ["7L58", "6L58", "5L58", "4L24"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			waterfall: ["7M", "6M", "6S0", "5M", "4M", "3M"],
			watergun: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			waterpledge: ["7T", "6T", "5T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["7V", "4M"],
			workup: ["7M"],
		},
		eventData: [
			{generation: 6, level: 50, isHidden: true, moves: ["icepunch", "crunch", "waterfall", "screech"], pokeball: "pokeball"},
		],
	},
	sentret: {
		learnset: {
			amnesia: ["7L36", "7V", "6L36", "5L36", "4L36", "3L49"],
			aquatail: ["7T", "6T", "5T", "4T"],
			assist: ["7E", "6E", "5E", "4E", "3E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			babydolleyes: ["7E"],
			batonpass: ["7L39", "6L39", "5L39", "4L39"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["7M"],
			captivate: ["7E", "6E", "4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["7E", "6E", "5E", "4E"],
			confide: ["7M", "6M"],
			covet: ["7T", "7E", "6T", "6E", "5T", "5E", "5D", "4E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7L4", "7V", "6L4", "5L4", "4L4", "3T", "3L4"],
			detect: ["7V"],
			dig: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["7V", "5D", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			firepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["7M", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusenergy: ["7E", "7V", "6E", "5E", "4E", "3E"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["7L19", "6L19", "5L19", "4L19", "3L31"],
			foresight: ["7L1", "6L1", "5L1", "4L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["7L13", "7V", "6L13", "5L13", "4L13", "3L12"],
			grassknot: ["7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["7T", "7L16", "6T", "6L16", "5T", "5L16", "4T", "4L16", "3L17"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hypervoice: ["7T", "7L47", "6T", "6L47", "5T", "5L47", "4L47"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			mefirst: ["7L42", "6L42", "5L42", "4L42"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			quickattack: ["7L7", "7V", "6L7", "5L7", "4L7", "3L7"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "7L28", "7V", "6M", "6L28", "5M", "5L28", "4M", "4L28", "3M", "3L40"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rollout: ["7V", "4T", "3T"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slam: ["7L25", "7V", "6L25", "5L25", "4L25", "3L24"],
			slash: ["7E", "7V", "6E", "5E", "4E", "3E"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			suckerpunch: ["7L31", "6L31", "5L31", "4T", "4L31"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tackle: ["7V"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["7T", "7E", "6T", "6E", "5T", "5E", "4E", "3E"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["4M"],
			workup: ["7M", "5M"],
		},
		encounters: [
			{generation: 2, level: 2},
		],
	},
	furret: {
		learnset: {
			agility: ["7L1"],
			amnesia: ["7L42", "7V", "6L42", "5L42", "4L42", "3L59"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["7L46", "6L46", "5L46", "4L46"],
			blizzard: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["7M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			coil: ["7L1"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			detect: ["7V"],
			dig: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			firepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["7M", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["7L21", "6L21", "5L21", "4L21", "3L37"],
			foresight: ["7L1", "6L1", "5L1", "4L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["7L13", "7V", "6L13", "5L13", "4L13", "3L12"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["7T", "7L17", "6T", "6L17", "5T", "5L17", "4T", "4L17", "3L19"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["7T", "7L56", "6T", "6L56", "5T", "5L56", "4L56"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			mefirst: ["7L50", "6L50", "5L50", "4L50"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "7L32", "7V", "6M", "6L32", "5M", "5L32", "4M", "4L32", "3M", "3L48"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slam: ["7L28", "7V", "6L28", "5L28", "4L28", "3L28"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["7L36", "6L36", "5L36", "4T", "4L36"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["7T", "6T", "5T"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["4M"],
			workup: ["7M", "5M"],
		},
		encounters: [
			{generation: 2, level: 6},
			{generation: 4, level: 6},
		],
	},
	hoothoot: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "7E", "6E", "5E", "4E"],
			aircutter: ["4T"],
			airslash: ["8M", "8L18", "7L31", "6L33", "5L33", "4L29"],
			amnesia: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			calmmind: ["8M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confusion: ["8L9", "7L10", "7V", "6L21", "5L21", "4L21", "3L34"],
			curse: ["7V"],
			defog: ["8E", "7T", "7E", "6E", "5E", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8L39", "7M", "7L46", "7V", "6M", "6L57", "5M", "5L57", "4M", "4L49", "3T", "3L48"],
			dualwingbeat: ["8T"],
			echoedvoice: ["8L6", "7M", "7L13", "6M", "6L25", "5M", "5L25"],
			endure: ["8M", "7V", "4M", "3T"],
			extrasensory: ["8L21", "7L22", "6L45", "5L45", "4L37"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["8E", "7E", "6E", "5E", "4E", "3E"],
			feintattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			flash: ["7V", "3M"],
			fly: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			foresight: ["7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L6", "3S0"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			growl: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["8M", "7E"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			hypnosis: ["8L36", "7L4", "7V", "6L5", "5L5", "4L5", "3L16"],
			imprison: ["8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			meanlook: ["7E"],
			mimic: ["3T"],
			mirrormove: ["7E", "7V", "6E", "5E", "4E", "3E"],
			moonblast: ["8L33", "7L40"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["8E", "7E", "6E", "5E", "5D", "4E"],
			ominouswind: ["4T"],
			peck: ["8L1", "7L7", "7V", "6L9", "5L9", "4L9", "3L11"],
			pluck: ["5M", "4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychoshift: ["8L15", "7L19", "6L49", "5L49", "4L41"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "5D", "4M"],
			reflect: ["8M", "8L12", "7M", "7L28", "7V", "6M", "6L17", "5M", "5L17", "4M", "4L17", "3M", "3L22"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8L30", "7M", "7L37", "6M", "6L53", "5T", "5L53", "4M", "4L45"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			silverwind: ["4M"],
			skyattack: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4E", "3T", "3E"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "3T"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			synchronoise: ["7L43", "6L41", "5L41"],
			tackle: ["8L3", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["8L24", "7L25", "7V", "6L29", "5L29", "4L25", "3L28"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["8M", "8L27", "7T", "7L34", "6T", "6L13", "5T", "5L13", "4T", "4L13"],
			whirlwind: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			wingattack: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "7L16", "6T", "6L37", "5T", "5L37", "4T", "4L33"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "growl", "foresight"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 2, level: 2},
		],
	},
	noctowl: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			aircutter: ["4T"],
			airslash: ["8M", "8L18", "7L35", "6L37", "5L37", "4L32"],
			amnesia: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			calmmind: ["8M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confusion: ["8L9", "7L10", "7V", "6L22", "5L22", "4L22", "3L41"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["8L53", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L67", "4M", "4L57", "3T", "3L57"],
			dualwingbeat: ["8T"],
			echoedvoice: ["8L1", "7M", "7L13", "6M", "6L27", "5M", "5L27"],
			endure: ["8M", "7V", "4M", "3T"],
			extrasensory: ["8L23", "7L23", "6L52", "5L52", "4L42"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "3M"],
			fly: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			foresight: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["8M"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			hypnosis: ["8L48", "7L1", "7V", "6L1", "5L1", "4L1", "3L16"],
			imprison: ["8M"],
			laserfocus: ["7T"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			moonblast: ["8L43", "7L47"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			ominouswind: ["4T"],
			peck: ["8L1", "7L7", "7V", "6L9", "5L9", "4L9", "3L1"],
			pluck: ["5M", "4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychoshift: ["8L15", "7L19", "6L57", "5L57", "4L47"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "8L12", "7M", "7L31", "7V", "6M", "6L17", "5M", "5L17", "4M", "4L17", "3M", "3L25"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8L38", "7M", "7L43", "6M", "6L62", "5T", "5L62", "4M", "4L52"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			silverwind: ["4M"],
			skyattack: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "3T"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			synchronoise: ["7L51", "6L47", "5L47"],
			tackle: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["8L28", "7L27", "7V", "6L32", "5L32", "4L27", "3L33"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["8M", "8L33", "7T", "7L39", "6T", "6L13", "5T", "5L13", "4T", "4L13"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "7L16", "6T", "6L42", "5T", "5L42", "4T", "4L37"],
		},
		encounters: [
			{generation: 2, level: 7},
			{generation: 4, level: 5},
			{generation: 7, level: 19},
		],
	},
	ledyba: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M", "3S0"],
			agility: ["7L29", "7V", "6L30", "5L30", "4L30", "3L43"],
			aircutter: ["4T"],
			airslash: ["7L36"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["7L26", "7V", "6L22", "5L22", "4L22", "3L29"],
			bide: ["7E", "7V", "6E", "5E", "4E", "3E"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			bugbite: ["7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			bugbuzz: ["7L33", "7E", "6L41", "6E", "5L41", "5E", "4L41", "4E"],
			captivate: ["4M"],
			cometpunch: ["7L22", "7V", "6L9", "5L9", "5D", "4L9", "3L15"],
			confide: ["7M", "6M"],
			counter: ["7E"],
			curse: ["7V"],
			dig: ["7V", "6M", "5M", "4M", "3M"],
			dizzypunch: ["7E", "6E", "5E"],
			doubleedge: ["7L40", "7V", "6L38", "5L38", "4L38", "3T", "3L50"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			dynamicpunch: ["7V", "3T"],
			encore: ["7E", "6E", "5E", "4E"],
			endure: ["7E", "7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			lightscreen: ["7M", "7L12", "7V", "6M", "6L14", "5M", "5L14", "4M", "4L14", "3M", "3L22"],
			machpunch: ["7L15", "6L17", "5L17", "4L17"],
			megapunch: ["3T"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["7E", "7V", "6E", "5E", "4E", "3E", "3S0"],
			reflect: ["7M", "7L12", "7V", "6M", "6L14", "5M", "5L14", "4M", "4L14", "3M", "3L22"],
			refresh: ["3S0"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7L12", "7V", "6M", "6L14", "5M", "5L14", "4M", "4L14", "3M", "3L22"],
			screech: ["7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M", "3M"],
			silverwind: ["7L19", "7E", "6L25", "6E", "5L25", "5E", "4M", "4L25", "4E", "3E"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["7L5", "7V", "6L6", "5L6", "4L6", "3L8", "3S0"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["7L8", "7V", "6L33", "5L33", "4T", "4L33", "3T", "3L36"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "7E", "6T", "6E", "5T", "4T"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 10, moves: ["refresh", "psybeam", "aerialace", "supersonic"]},
		],
		encounters: [
			{generation: 2, level: 3},
		],
	},
	ledian: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["7L33", "7V", "6L36", "5L36", "4L36", "3L51"],
			aircutter: ["4T"],
			airslash: ["7L42"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["7L29", "7V", "6L24", "5L24", "4L24", "3L33"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["7L38", "6L53", "5L53", "4L53"],
			captivate: ["4M"],
			cometpunch: ["7L24", "7V", "6L1", "5L1", "4L1", "3L15"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T"],
			dig: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["7L47", "7V", "6L48", "5L48", "4L48", "3T", "3L60"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["7T", "6T", "5T", "4M"],
			dynamicpunch: ["7V", "3T"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lightscreen: ["7M", "7L12", "7V", "6M", "6L14", "5M", "5L14", "4M", "4L14", "3M", "3L24"],
			machpunch: ["7L15", "6L17", "5L17", "4L17"],
			megapunch: ["3T"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["7M", "7L12", "7V", "6M", "6L14", "5M", "5L14", "4M", "4L14", "3M", "3L24"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M"],
			rollout: ["7V", "4T", "3T"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7L12", "7V", "6M", "6L14", "5M", "5L14", "4M", "4L14", "3M", "3L24"],
			secretpower: ["6M", "4M", "3M"],
			silverwind: ["7L20", "6L29", "5L29", "4M", "4L29"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			strength: ["6M", "5M", "4M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["7L1", "7V", "6L41", "5L41", "4T", "4L41", "3T", "3L42"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
		},
		encounters: [
			{generation: 2, level: 7},
			{generation: 4, level: 5},
		],
	},
	spinarak: {
		learnset: {
			absorb: ["7L5"],
			agility: ["7L33", "7V", "6L33", "5L33", "4L33", "3L45"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bodyslam: ["3T"],
			bounce: ["7T", "6T", "5T", "4T"],
			bugbite: ["7T", "6T", "5T", "5D", "4T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "7V", "6L8", "5L8", "4L8", "3L11"],
			crosspoison: ["7L47", "6L47", "5L47"],
			curse: ["7V"],
			dig: ["7V", "6M", "5M", "4M", "3M", "3S0"],
			disable: ["7E", "7V", "6E", "5E", "4E", "3E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			electroweb: ["7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["7L22", "7V", "6L22", "5L22", "4L22", "3L30"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			infestation: ["7M", "7L8", "6M"],
			leechlife: ["7M", "7V", "6L12", "5L12", "4L12", "3L23"],
			lunge: ["7E"],
			megahorn: ["7E", "6E"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			nightshade: ["7L15", "7V", "6L15", "5L15", "4L15", "3L17", "3S0"],
			nightslash: ["7E", "6E", "5E"],
			pinmissile: ["7L36", "6L36", "5L36", "4L36"],
			poisonjab: ["7M", "7L43", "6M", "6L43", "5M", "5L43", "4M", "4L43", "4E"],
			poisonsting: ["7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["7E", "7V", "6E", "5E", "4E", "3E"],
			psychic: ["7M", "7L40", "7V", "6M", "6L40", "5M", "5L40", "4M", "4L40", "3M", "3L53"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			ragepowder: ["7E", "6E", "5E"],
			refresh: ["3S0"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["7L12", "7V", "6L5", "5L5", "4L5", "3L6"],
			screech: ["7V"],
			secretpower: ["6M", "4M", "3M"],
			shadowsneak: ["7L19", "6L19", "5L19", "4L19"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E", "3S0"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "3T"],
			solarbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			sonicboom: ["7E", "7V", "6E", "5E", "4E", "3E"],
			spiderweb: ["7L29", "7V", "6L29", "5L29", "4L29", "3L37"],
			stickyweb: ["7L50", "6L50"],
			stringshot: ["7L1", "7V", "6L1", "5L1", "4T", "4L1", "3L1"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["7L26", "6L26", "5L26", "4T", "4L26"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["7E", "6E", "5E", "4E"],
			toxicthread: ["7L54"],
			twineedle: ["7E", "6E", "5E"],
			venoshock: ["7M", "6M", "5M"],
			xscissor: ["7M", "6M", "5M"],
		},
		eventData: [
			{generation: 3, level: 14, moves: ["refresh", "dig", "signalbeam", "nightshade"]},
		],
		encounters: [
			{generation: 2, level: 3},
		],
	},
	ariados: {
		learnset: {
			absorb: ["7L1"],
			agility: ["7L37", "7V", "6L37", "5L37", "4L37", "3L53"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			bounce: ["7T", "6T", "5T", "4T"],
			bugbite: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			crosspoison: ["7L55", "6L55", "5L55"],
			curse: ["7V"],
			dig: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			electroweb: ["7T", "6T", "5T"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fellstinger: ["7L1", "6L1"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			focusenergy: ["7L1"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyswipes: ["7L23", "7V", "6L23", "5L23", "4L23", "3L34"],
			gigadrain: ["7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "7L8", "6M"],
			leechlife: ["7M", "7V", "6L12", "5L12", "4L12", "3L25"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			nightshade: ["7L15", "7V", "6L15", "5L15", "4L15", "3L17"],
			pinmissile: ["7L41", "6L41", "5L41", "4L41"],
			poisonjab: ["7M", "7L50", "6M", "6L50", "5M", "5L50", "4M", "4L50"],
			poisonsting: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["7M", "7L46", "7V", "6M", "6L46", "5M", "5L46", "4M", "4L46", "3M", "3L63"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["7L12", "7V", "6L1", "5L1", "4L1", "3L1"],
			screech: ["7V"],
			secretpower: ["6M", "4M", "3M"],
			shadowsneak: ["7L19", "6L19", "5L19", "4L19"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["7M", "7V", "6M", "5M", "4M", "3M"],
			smartstrike: ["7M"],
			snore: ["7T", "7V", "6T", "3T"],
			solarbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			spiderweb: ["7L32", "7V", "6L32", "5L32", "4L32", "3L43"],
			stickyweb: ["7L58", "6L58"],
			stompingtantrum: ["7T"],
			stringshot: ["7L1", "7V", "6L1", "5L1", "4T", "4L1", "3L1"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["7L28", "6L28", "5L28", "4T", "4L28"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["7M", "7L1"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxicthread: ["7L63"],
			venomdrench: ["7L1", "6L1"],
			venoshock: ["7M", "6M", "5M"],
			xscissor: ["7M", "6M", "5M"],
		},
		encounters: [
			{generation: 2, level: 7},
			{generation: 4, level: 5},
			{generation: 6, level: 19, maxEggMoves: 1},
		],
	},
	chinchou: {
		learnset: {
			agility: ["8M", "7E", "6E", "5E", "4E"],
			amnesia: ["8M", "7E", "6E", "5E", "5D", "4E", "3E"],
			aquaring: ["8L32", "7L42", "6L42", "5L42", "4L39"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "7E", "6E", "5E", "4M"],
			bubble: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bubblebeam: ["8L12", "7L20", "6L20", "5L31", "4L28"],
			captivate: ["4M"],
			charge: ["8L24", "7L50", "6L50", "5L50", "4L45", "3L49"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L16", "7L17", "7V", "6L17", "5L12", "4L17", "3L29"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "7M", "6M"],
			discharge: ["8L28", "7L34", "6L34", "5L39", "4L34"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			electroball: ["8M", "8L4", "7L9", "6L9", "5L28"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L36", "7L31", "7E", "7V", "6L9", "6E", "5L9", "5E", "4L9", "4E", "3L13", "3E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			healbell: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L44", "7L45", "7V", "6L45", "5L45", "4L42", "3L41"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			iondeluge: ["7L47", "6L47"],
			mimic: ["3T"],
			mist: ["8E", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8E", "7E", "6E", "5E", "4E"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "7E", "6T", "6E", "5E", "5D", "4M", "3M"],
			signalbeam: ["7T", "7L28", "6T", "6L28", "5T", "5L34", "4T", "4L31"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			soak: ["8E", "7E", "6E"],
			spark: ["8L20", "7L23", "7V", "6L20", "5L20", "4L20", "3L25"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			supersonic: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L5"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["8L40", "7L39", "7V", "6L23", "5L23", "4L23", "3L37"],
			thunder: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "8L8", "7M", "7L6", "7V", "6M", "6L6", "5M", "5L6", "5D", "4M", "4L6", "3T", "3L1"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			waterfall: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L12", "7V", "6L1", "5L17", "4L12", "3L17"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			whirlpool: ["8M", "7E", "7V", "6E", "5E", "4M", "4E"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
	},
	lanturn: {
		learnset: {
			agility: ["8M"],
			amnesia: ["8M"],
			aquaring: ["8L36", "7L47", "6L47", "5L52", "4L47"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "4M"],
			bubble: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bubblebeam: ["8L12", "7L20", "6L20", "5L35", "4L30"],
			captivate: ["4M"],
			charge: ["8L24", "7L58", "6L58", "5L64", "4L57", "3L61"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L16", "7L17", "7V", "6L17", "5L17", "4L17", "3L32"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "7M", "6M"],
			discharge: ["8L30", "7L37", "6L37", "5L47", "4L40"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["8M", "8L1", "7L1", "6L1"],
			electroball: ["8M", "8L1", "7L1", "6L1", "5L30"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L42", "7L33", "7V", "6L9", "5L9", "4L9", "3L13"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			healbell: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L54", "7L51", "7V", "6L51", "5L57", "4L52", "3L50"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			iondeluge: ["7L54", "6L54"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "7L29", "6T", "6L29", "5T", "5L40", "4T", "4L35"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spark: ["8L20", "7L23", "7V", "6L20", "5L20", "4L20", "3L25"],
			spitup: ["8L0", "7L1", "6L27", "5L27", "4L27"],
			spotlight: ["7L1"],
			stockpile: ["8L0", "7L1", "6L27", "5L27", "4L27"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			supersonic: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["8L0", "7L1", "6L27", "5L27", "4L27"],
			takedown: ["8L48", "7L43", "7V", "6L23", "5L23", "4L23", "3L43"],
			thunder: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "8L1", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3T", "3L1"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			waterfall: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L12", "7V", "6L1", "5L12", "4L12", "3L17"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 4, level: 20},
			{generation: 6, level: 26, maxEggMoves: 1},
			{generation: 7, level: 10},
		],
	},
	togepi: {
		learnset: {
			aerialace: ["8E"],
			afteryou: ["8L28", "7T", "7L53", "6T", "6L53", "5T", "5L53"],
			ancientpower: ["8L16", "7L33", "6L33", "5L33", "4T", "4L33", "3L21", "3S1"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "8L44", "7L41", "6L41", "5L41", "4L42", "3L41"],
			bestow: ["7L25", "6L25", "5L25"],
			bodyslam: ["8M", "3T"],
			captivate: ["4M"],
			charm: ["8M", "8L12", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			doubleedge: ["8L32", "7L45", "7V", "6L45", "5L45", "4L46", "3T", "3L37"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "7L17", "7V", "6L17", "5L17", "4L19", "3L17"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			extrasensory: ["8E", "7E", "6E", "5E", "4E"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			followme: ["8L40", "7L21", "6L21", "5L21", "4L24", "3L25", "3S1"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["8M", "3S1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			incinerate: ["6M", "5M"],
			lastresort: ["8L48", "7T", "7L49", "6T", "6L49", "5T", "5L49", "4T", "4L51"],
			lifedew: ["8L8"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7E", "6E", "5E", "5D", "4E"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["8M", "8L24", "7L5", "7V", "6L5", "5L5", "5D", "4L6", "3T", "3L4", "3S0"],
			mimic: ["3T"],
			mirrormove: ["7E", "7V", "6E", "5E", "4E", "3E"],
			morningsun: ["8E", "7E", "6E", "5E"],
			mudslap: ["7V", "4T", "3T"],
			mysticalfire: ["8M"],
			nastyplot: ["8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			peck: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			playrough: ["8M"],
			pound: ["8L1"],
			present: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychoshift: ["8E", "7E", "6E", "5E", "4E"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L36", "7M", "7L37", "7V", "6M", "6L37", "5M", "5L37", "4M", "4L37", "3M", "3L33"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			softboiled: ["3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			storedpower: ["8M", "7E", "6E", "5E"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["8L4", "7L9", "7V", "6L9", "5L9", "4L10", "3L9", "3S0"],
			swift: ["8M", "7V", "4T", "3T"],
			telekinesis: ["7T", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M", "3S1"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			uproar: ["8M", "7T", "6T", "5T", "5D", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wish: ["8L52", "7L29", "6L29", "5L29", "4L28", "3L29"],
			workup: ["8M", "7M", "5M"],
			yawn: ["8L20", "7L13", "6L13", "5L13", "4L15", "3L13", "3S0"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 20, gender: "F", abilities: ["serenegrace"], moves: ["metronome", "charm", "sweetkiss", "yawn"], pokeball: "pokeball"},
			{generation: 3, level: 25, moves: ["triattack", "followme", "ancientpower", "helpinghand"]},
		],
	},
	togetic: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			afteryou: ["8L28", "7T", "7L53", "6T", "6L53", "5T", "5L53"],
			aircutter: ["4T"],
			ancientpower: ["8L16", "7L33", "6L33", "5L33", "4T", "4L33", "3L21"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "8L44", "7L41", "6L41", "5L41", "4L42", "3L41"],
			bestow: ["7L25", "6L25", "5L25"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["8M", "8L12", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["8L32", "7L45", "7V", "6L45", "5L45", "4L46", "3T", "3L37"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "7L17", "7V", "6L17", "5L17", "4L19", "3L17"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fairywind: ["8L0", "7L14", "6L14"],
			fireblast: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			fly: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			followme: ["8L40", "7L21", "6L21", "5L21", "4L24", "3L25"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			incinerate: ["6M", "5M"],
			lastresort: ["8L48", "7T", "7L49", "6T", "6L49", "5T", "5L49", "4T", "4L51"],
			lifedew: ["8L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["8M", "7L1", "6L1", "5L1", "4L1", "3L1"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["8M", "8L24", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			mysticalfire: ["8M"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			playrough: ["8M"],
			pound: ["8L1"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L36", "7M", "7L37", "7V", "6M", "6L37", "5M", "5L37", "4M", "4L37", "3M", "3L33"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			skyattack: ["3T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			softboiled: ["3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetkiss: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			swift: ["8M", "7V", "4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			telekinesis: ["7T", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			triattack: ["8M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			twister: ["4T"],
			uproar: ["8M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wish: ["8L52", "7L29", "6L29", "5L29", "4L28", "3L29"],
			workup: ["8M", "7M", "5M"],
			yawn: ["8L20", "7L13", "6L13", "5L13", "4L15", "3L13"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	togekiss: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			afteryou: ["8L1", "7T", "7L1", "6T", "6L1"],
			aircutter: ["4T"],
			airslash: ["8M", "8L0", "7L1", "6L1", "5L1", "5S0", "4L1"],
			allyswitch: ["8M"],
			amnesia: ["8M"],
			ancientpower: ["8L1", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			aurasphere: ["8M", "8L1", "7L1", "6L1", "5L1", "5S0", "4L1"],
			batonpass: ["8M", "8L1"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			charm: ["8M", "8L1"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defog: ["7T", "4M"],
			doubleedge: ["8L1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainingkiss: ["8M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "4M"],
			extremespeed: ["8L1", "7L1", "6L1", "5L1", "5S0", "4L1"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			fairywind: ["8L1"],
			fireblast: ["8M", "7M", "6M", "5M", "4M"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			fly: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			followme: ["8L1"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			incinerate: ["6M", "5M"],
			laserfocus: ["7T"],
			lastresort: ["8L1", "7T", "6T", "5T", "4T"],
			lifedew: ["8L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M"],
			magicalleaf: ["8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["8M", "8L1"],
			mudslap: ["4T"],
			mysticalfire: ["8M"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			playrough: ["8M"],
			pluck: ["5M", "4M"],
			pound: ["8L1"],
			present: ["5S0"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychic: ["8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rollout: ["4T"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			skyattack: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			smartstrike: ["8M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			steelwing: ["8M", "7M", "6M", "4M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			sweetkiss: ["8L1"],
			swift: ["8M", "4T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			telekinesis: ["7T", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			triattack: ["8M", "8L1"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			twister: ["4T"],
			uproar: ["8M"],
			waterpulse: ["7T", "6T", "4M"],
			wish: ["8L1"],
			workup: ["8M", "7M", "5M"],
			yawn: ["8L1"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["extremespeed", "aurasphere", "airslash", "present"]},
		],
	},
	natu: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M", "3S0"],
			aircutter: ["4T"],
			airslash: ["8M"],
			allyswitch: ["8M", "7T", "7E", "6E", "5M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "3S0"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L15", "7L23", "7V", "6L23", "5L23", "4L23", "3L40"],
			cosmicpower: ["8M"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "7M", "6M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			drillpeck: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			dualwingbeat: ["8T"],
			endure: ["8M", "7V", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["8E", "7E", "6E", "5E", "5D", "4E", "3E"],
			feintattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L45", "7L44", "7V", "6L36", "5L36", "4L36", "3L30", "3S0"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "5D", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			guardswap: ["8M", "8L35", "7L47", "6L47", "5L47", "4L44"],
			haze: ["7E", "7V", "6E", "5E", "4E", "3E"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			imprison: ["8M"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7L12", "6L12", "5L12", "4L12"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			mefirst: ["7L50", "6L20", "5L20", "4L20"],
			mimic: ["3T"],
			miracleeye: ["7L36", "6L17", "5L17", "4L17"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["8L20", "7L6", "7V", "6L6", "5L6", "5D", "4L6", "3L10", "3S0"],
			ominouswind: ["7L20", "6L20", "5L44", "4T", "4L39"],
			painsplit: ["7T", "6T", "5T", "4T"],
			peck: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			pluck: ["5M", "4M"],
			powerswap: ["8M", "8L30", "7L47", "6L47", "5L47", "4L44"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L35", "7M", "7L33", "7V", "6M", "6L33", "5M", "5L50", "4M", "4L47", "3M", "3L50"],
			psychoshift: ["8L26", "7L39", "6L33", "5L33", "4L33"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			psyshock: ["8M", "7M", "6M", "5M"],
			quickattack: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["8E", "7M", "7E", "6M", "6E", "5T", "5E", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			simplebeam: ["8E", "7E", "6E"],
			skillswap: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			skyattack: ["7T", "6T", "3T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			steelwing: ["8M", "7M", "7E", "7V", "6M", "6E", "5E", "4M", "4E", "3M", "3E"],
			storedpower: ["8M", "8L5", "7L17", "6L17", "5L39"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8E", "7E", "6E", "5E", "4T", "4E"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			synchronoise: ["7E", "6E", "5E"],
			tailwind: ["7T", "6T", "5T", "4T"],
			telekinesis: ["7T", "5M"],
			teleport: ["8L10", "7L9", "7V", "6L9", "5L9", "4L9", "3L20"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uturn: ["8M", "7M", "6M", "5M", "4M"],
			wish: ["8L40", "7L28", "6L28", "5L28", "4L28", "3L30"],
			zenheadbutt: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
		},
		eventData: [
			{generation: 3, level: 22, moves: ["batonpass", "futuresight", "nightshade", "aerialace"]},
		],
	},
	xatu: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["4T"],
			airslash: ["8M", "8L0", "7L1", "6L25"],
			allyswitch: ["8M", "7T", "5M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["8M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L15", "7L23", "7V", "6L23", "5L23", "4L23", "3L50"],
			cosmicpower: ["8M"],
			curse: ["7V"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			endure: ["8M", "7V", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fly: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L55", "7L49", "7V", "6L42", "5L42", "4L42", "3L35"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			guardswap: ["8M", "8L34", "7L53", "6L53", "5L59", "4L54"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			imprison: ["8M"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7L12", "6L12", "5L12", "4L12"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			mefirst: ["7L57", "6L20", "5L20", "4L20"],
			mimic: ["3T"],
			miracleeye: ["7L39", "6L17", "5L17", "4L17"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["8L20", "7L1", "7V", "6L1", "5L6", "4L6", "3L10"],
			ominouswind: ["7L20", "6L20", "5L54", "4T", "4L47"],
			painsplit: ["7T", "6T", "5T", "4T"],
			peck: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			pluck: ["5M", "4M"],
			powerswap: ["8M", "8L34", "7L53", "6L53", "5L54", "4L54"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L41", "7M", "7L35", "7V", "6M", "6L35", "5M", "5L66", "4M", "4L59", "3M", "3L65"],
			psychoshift: ["8L28", "7L43", "6L37", "5L37", "4L37"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			skyattack: ["7T", "6T", "5T", "4T", "3T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			storedpower: ["8M", "8L1", "7L17", "6L17", "5L47"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tailwind: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L27", "4T", "4L27"],
			telekinesis: ["7T", "5M"],
			teleport: ["8L1", "7L1", "7V", "6L1", "5L9", "4L9", "3L20"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uturn: ["8M", "7M", "6M", "5M", "4M"],
			wish: ["8L48", "7L29", "6L29", "5L30", "4L30", "3L35"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 2, level: 15},
			{generation: 4, level: 16, gender: "M", nature: "Modest", ivs: {hp: 15, atk: 20, def: 15, spa: 20, spd: 20, spe: 20}, abilities: ["synchronize"], pokeball: "pokeball"},
			{generation: 6, level: 24, maxEggMoves: 1},
			{generation: 7, level: 21},
		],
	},
	mareep: {
		learnset: {
			afteryou: ["9E", "7T", "7E", "6T", "6E", "5T", "5E"],
			agility: ["9M", "9E", "7E", "6E", "5E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "7E", "7V", "6E", "5E", "4E", "3T", "3E", "3S2"],
			captivate: ["4M"],
			charge: ["9L15", "7L15", "7E", "6L15", "6E", "5L23", "5E", "4L23", "4E", "3E"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L25", "7L25", "6L25", "5L25"],
			cottonguard: ["9L36", "7L36", "6L36", "5L32"],
			cottonspore: ["9L11", "7L11", "7V", "6L11", "5L19", "4L19", "3L23", "3S0"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "9L39"],
			defensecurl: ["7V", "3T"],
			dig: ["9M"],
			discharge: ["9L32", "7L32", "6L32", "5L37", "4L28"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			eerieimpulse: ["9M", "9E", "7E", "6E"],
			electricterrain: ["9M", "9E", "7E", "6E"],
			electroball: ["9M", "9L22", "7L22", "6L22", "5L28"],
			electroweb: ["9E", "7T", "6T"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			flatter: ["9E", "7E", "6E", "5E", "4E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			growl: ["9L1", "7L1", "7V", "6L1", "5L5", "4L5", "3L1", "3S1"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T", "3S2"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			holdback: ["6S3"],
			irontail: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4M", "3M"],
			lightscreen: ["9M", "9L43", "7M", "7L43", "7V", "6M", "6L43", "5M", "5L46", "4M", "4L37", "3M", "3L30"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			odorsleuth: ["7E", "6E", "5E", "4E", "3E"],
			powergem: ["9M", "9L29", "7L29", "6L29", "5L50", "4L41"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M", "7V", "5D", "4E", "3E"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7V", "6M", "5M", "4E", "3E"],
			sandattack: ["7E", "6E", "5E", "4E"],
			screech: ["7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "5D", "4M", "3M"],
			signalbeam: ["7T", "7L39", "6T", "6L39", "5T", "5L41", "4T", "4L32"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tackle: ["9L1", "7L1", "7V", "6L1", "6S3", "5L1", "4L1", "3L1", "3S1"],
			takedown: ["9M", "9L18", "7L18", "7E", "7V", "6L18", "6E", "5L18", "5E", "4E", "3E"],
			terablast: ["9M"],
			thunder: ["9M", "9L46", "7M", "7L46", "7V", "6M", "6L46", "5M", "5L55", "4M", "4L46", "3M", "3L37", "3S0"],
			thunderbolt: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thundershock: ["9L8", "7L8", "7V", "6L8", "6S3", "5L10", "5D", "4L10", "3L9", "3S0", "3S1", "3S2"],
			thunderwave: ["9M", "9L4", "7M", "7L4", "7V", "6M", "6L4", "6S3", "5M", "5L14", "4M", "4L14", "3T", "3L16", "3S0", "3S2"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			voltswitch: ["9M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 37, gender: "F", moves: ["thunder", "thundershock", "thunderwave", "cottonspore"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "growl", "thundershock"], pokeball: "pokeball"},
			{generation: 3, level: 17, moves: ["healbell", "thundershock", "thunderwave", "bodyslam"]},
			{generation: 6, level: 10, moves: ["holdback", "tackle", "thunderwave", "thundershock"], pokeball: "cherishball"},
		],
	},
	flaaffy: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			agility: ["9M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			charge: ["9L16", "7L16", "6L16", "5L25", "4L25"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L29", "7L29", "6L29", "5L29"],
			cottonguard: ["9L43", "7L43", "6L43", "5L36"],
			cottonspore: ["9L11", "7L11", "7V", "6L11", "5L20", "4L20", "3L27"],
			counter: ["3T"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "9L47"],
			defensecurl: ["7V", "3T"],
			dig: ["9M"],
			discharge: ["9L38", "7L38", "6L38", "5L42", "4L31"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L25", "7L25", "6L25", "5L31"],
			electroweb: ["7T", "6T"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			growl: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			lightscreen: ["9M", "9L52", "7M", "7L52", "7V", "6M", "6L52", "5M", "5L53", "4M", "4L42", "3M", "3L36"],
			lowkick: ["9M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			powergem: ["9M", "9L34", "7L34", "6L34", "5L59", "4L47"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "7L47", "6T", "6L47", "5T", "5L47", "4T", "4L36"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "7V", "4T", "3T"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9L20", "7L20", "6L20", "5L20"],
			terablast: ["9M"],
			thunder: ["9M", "9L56", "7M", "7L56", "7V", "6M", "6L56", "5M", "5L65", "4M", "4L53", "3M", "3L45"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			thundershock: ["9L6", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thunderwave: ["9M", "9L9", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L14", "4M", "4L14", "3T", "3L18"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			voltswitch: ["9M", "7M", "6M", "5M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 7, level: 11, pokeball: "pokeball"},
		],
	},
	ampharos: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			agility: ["9M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["7M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charge: ["9L16", "7L16", "6L16", "5L25", "4L25"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L29", "7L29", "6L29", "5L29"],
			cottonguard: ["9L46", "7L46", "6L46", "5L40"],
			cottonspore: ["9L11", "7L11", "7V", "6L11", "5L20", "4L20", "3L27"],
			counter: ["3T"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "9L51"],
			defensecurl: ["7V", "3T"],
			dig: ["9M"],
			discharge: ["9L40", "7L40", "6L40", "5L48", "4L34"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonpulse: ["9M", "9L1", "7T", "7L1", "6T", "6L1"],
			dragontail: ["9M"],
			dynamicpunch: ["7V", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L25", "7L25", "6L25", "5L33"],
			electroweb: ["7T", "6T"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "9L1", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icepunch: ["9M"],
			iondeluge: ["7L1", "6L1"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L57", "7M", "7L57", "7V", "6M", "6L57", "5M", "5L63", "4M", "4L51", "3M", "3L42"],
			lowkick: ["9M"],
			magneticflux: ["9L1", "7L1", "6L1"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "7T", "6T", "5T", "4T"],
			powergem: ["9M", "9L35", "7L35", "6L35", "5L71", "4L59"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "7L51", "6T", "6L51", "5T", "5L55", "4T", "4L42"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["9M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "7V", "4T", "3T"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9L20", "7L20", "6L20", "5L20"],
			terablast: ["9M"],
			thunder: ["9M", "9L62", "7M", "7L62", "7V", "6M", "6L62", "5M", "5L79", "4M", "4L68", "3M", "3L57"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "9L0", "7T", "7L1", "7V", "6T", "6L30", "5T", "5L30", "4T", "4L30", "3T", "3L30"],
			thundershock: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thunderwave: ["9M", "9L1", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3T", "3L1"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			voltswitch: ["9M", "7M", "6M", "5M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			zapcannon: ["9L1", "7L1", "7V", "6L1"],
		},
	},
	azurill: {
		learnset: {
			aquajet: ["9E", "8E"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bellydrum: ["9E", "8E"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7E", "6E", "5E", "4E", "3T"],
			bounce: ["9L15", "8M", "8L15", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			brutalswing: ["8M"],
			bubble: ["7L7", "6L7", "5L10", "4L10", "3L10"],
			bubblebeam: ["9L6", "8L6", "7L13", "6L13", "5L13"],
			camouflage: ["7E", "6E"],
			captivate: ["4M"],
			charm: ["9M", "9L9", "8M", "8L9", "7L10", "6L10", "5L2", "4L2", "3L3"],
			confide: ["7M", "6M"],
			copycat: ["9E", "8E", "7E", "6E"],
			covet: ["7T", "6T", "5T"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M", "8M"],
			encore: ["9M", "8M", "7E", "6E", "5E", "4E", "3E"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M", "7E", "6E", "5E", "4E"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			helpinghand: ["9M", "9L3", "8M", "8L3", "7T", "7L16", "6T", "6L16", "5T", "5L16", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			mimic: ["3T"],
			muddywater: ["8M", "7E", "6E", "5E"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			perishsong: ["9E", "8E"],
			present: ["9E", "8E"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sing: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			slam: ["9L12", "8L12", "7L20", "7E", "6L20", "6E", "5L15", "5E", "4L15", "4E", "3L15", "3E"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			soak: ["9E", "8E", "7E", "6E", "5E"],
			splash: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			supersonic: ["9E", "8E"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tailwhip: ["9L1", "8L1", "7L2", "6L2", "5L7", "4L7", "3L6"],
			takedown: ["9M"],
			terablast: ["9M"],
			tickle: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterfall: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["9L1", "8L1", "7L1", "6L1", "5L18", "4L18", "3L21"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			watersport: ["7L5", "7E", "6L5", "6E", "5L5", "5E"],
			whirlpool: ["8M", "4M"],
			workup: ["8M", "7M", "5M"],
		},
	},
	marill: {
		learnset: {
			amnesia: ["9M", "8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			aquajet: ["8E", "7E", "6E", "5E", "5D", "4E"],
			aquaring: ["9L24", "8L24", "7L28", "6L28", "5L23", "4L23"],
			aquatail: ["9L19", "8L19", "7T", "7L20", "6T", "6L20", "5T", "5L37", "4T", "4L37"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bellydrum: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			blizzard: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7E", "6E", "5E", "4E", "3T"],
			bounce: ["9L15", "8M", "8L15", "7T", "6T", "5T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M"],
			bubble: ["7L7", "6L7", "5L1"],
			bubblebeam: ["9L6", "8L6", "7L13", "7V", "6L13", "5L18", "4L18", "3L21"],
			bulldoze: ["9M"],
			camouflage: ["7E", "6E"],
			captivate: ["4M"],
			charm: ["9M", "9L9", "8M", "8L9"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["8E"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			defensecurl: ["9L1", "8L1", "7L10", "7V", "6L10", "5L2", "5D", "4L2", "3T", "3L3"],
			dig: ["9M", "8M", "6M", "5M", "4M", "3M"],
			disarmingvoice: ["9M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["9L33", "8L33", "7L37", "7V", "6L23", "5L27", "4L27", "3T", "3L28"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M", "8M"],
			dynamicpunch: ["7V", "3T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7V"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "7L16", "6T", "6L16", "5T", "5L16", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "9L30", "8M", "8L30", "7L47", "6L40", "5L42", "4L42", "3L45"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			icespinner: ["9M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			liquidation: ["9M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["9M"],
			mimic: ["3T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M", "8M"],
			muddywater: ["8M", "7E", "6E", "5E"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			perishsong: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			playrough: ["9M", "9L21", "8M", "8L21", "7L23", "6L23"],
			poweruppunch: ["6M"],
			present: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "9L27", "8M", "8L27", "7M", "7L31", "7V", "6M", "6L31", "5M", "5L32", "4M", "4L32", "3M", "3L36"],
			refresh: ["7E", "6E", "5E", "4E"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["9L1", "8L1", "7L10", "7V", "6L10", "5L15", "4T", "4L15", "3T", "3L15"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			sing: ["8E"],
			slam: ["9L12", "8L12"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			soak: ["8E"],
			steelroller: ["8T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			superpower: ["9L36", "8M", "8L36", "7T", "7L40", "7E", "6T", "6L37", "6E", "5T", "5L37", "5E", "4T", "4E"],
			supersonic: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			surf: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["9L1", "8L1", "7L2", "7V", "6L2", "5L7", "4L7", "3L6"],
			takedown: ["9M"],
			terablast: ["9M"],
			tickle: ["8E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			waterfall: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["9L1", "8L1", "7L1", "7V", "6L1", "5L10", "4L10", "3L10"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			watersport: ["7L5", "7E", "6L5", "6E", "5L5", "5E"],
			whirlpool: ["8M", "7V", "4M"],
			workup: ["8M", "7M", "5M"],
		},
	},
	azumarill: {
		learnset: {
			amnesia: ["9M", "8M"],
			aquaring: ["9L30", "8L30", "7L31", "6L31", "5L27", "4L27"],
			aquatail: ["9L21", "8L21", "7T", "7L21", "6T", "6L21", "5T", "5L47", "4T", "4L47"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			blizzard: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			bounce: ["9L15", "8M", "8L15", "7T", "6T", "5T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M"],
			bubble: ["7L7", "6L7", "5L1"],
			bubblebeam: ["9L6", "8L6", "7L13", "7V", "6L13", "5L20", "4L20", "3L24"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["9M", "9L9", "8M", "8L9"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			curse: ["7V"],
			defensecurl: ["9L1", "8L1", "7L10", "7V", "6L10", "5L1", "4L1", "3T", "3L1"],
			dig: ["9M", "8M", "6M", "5M", "4M", "3M"],
			disarmingvoice: ["9M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["9L45", "8L45", "7L42", "7V", "6L25", "5L33", "4L33", "3T", "3L34"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M", "8M"],
			dynamicpunch: ["7V", "3T"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "7L16", "6T", "6L16", "5T", "5L16", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "9L40", "8M", "8L40", "7L55", "6L46", "5L54", "4L54", "3L57"],
			hyperbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			icespinner: ["9M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			liquidation: ["9M", "8M", "7T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["9M"],
			mimic: ["3T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M", "8M"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			playrough: ["9M", "9L25", "8M", "8L25", "7L25", "6L25"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "9L35", "8M", "8L35", "7M", "7L35", "7V", "6M", "6L35", "5M", "5L40", "4M", "4L40", "3M", "3L45"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rollout: ["9L1", "8L1", "7L10", "7V", "6L10", "5L15", "4T", "4L15", "3T", "3L15"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			slam: ["9L12", "8L12"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			steelroller: ["8T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["9L50", "8M", "8L50", "7T", "7L46", "6T", "6L42", "5T", "5L42", "4T"],
			surf: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tackle: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			waterfall: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			watersport: ["7L1", "6L1", "5L1"],
			whirlpool: ["8M", "7V", "4M"],
			workup: ["8M", "7M", "5M"],
		},
		encounters: [
			{generation: 5, level: 5},
			{generation: 6, level: 16, maxEggMoves: 1},
		],
	},
	bonsly: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			block: ["9L12", "8L12", "7T", "7L29", "6T", "6L26", "5T", "5L22", "4T", "4L22"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			counter: ["9L40", "8L40", "7L36", "6L33"],
			covet: ["7T", "6T", "5T"],
			curse: ["9E", "8E", "7E", "6E", "5E"],
			defensecurl: ["9E", "8E", "7E", "6E", "5E", "4E"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleedge: ["9L44", "8L44", "7L43", "6L40", "5L46", "4L46"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M"],
			endure: ["9M", "8M", "7E", "6E", "5E", "4M"],
			explosion: ["7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			faketears: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			feintattack: ["7L19", "6L19", "5L25", "4L25"],
			flail: ["9L4", "8L4", "7L5", "6L5", "5L6", "4L6"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			grassknot: ["9M"],
			harden: ["9E", "8E", "7E", "6E", "5E", "4E"],
			headbutt: ["9E", "8E", "7E", "6E", "5E", "4T", "4E"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			lowkick: ["9M", "9L36", "8M", "8L36", "7T", "7L8", "6T", "6L8", "5T", "5L9", "4T", "4L9"],
			mimic: ["9L16", "8L16", "7L15", "6L15", "5L17", "4L17"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			powergem: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockblast: ["9M"],
			rockpolish: ["9E", "8E", "7M", "6M", "5M", "4M"],
			rockslide: ["9M", "9L32", "8M", "8L32", "7M", "7L33", "6M", "6L29", "5M", "5L33", "4M", "4L33"],
			rockthrow: ["9L8", "8L8", "7L12", "6L12", "5L14", "4L14"],
			rocktomb: ["9M", "9L20", "8M", "8L20", "7M", "7L26", "6M", "6L22", "5M", "5L30", "4M", "4L30"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["9E", "8E", "7E", "6E", "5E", "4T", "4E"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sandtomb: ["8M", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M"],
			selfdestruct: ["8M", "7E", "6E", "5E", "4E"],
			slam: ["5L38", "4L38"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spikes: ["9M"],
			stealthrock: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["9L28", "8L28", "7L40", "6L36", "5L41", "4T", "4L41"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			takedown: ["9M"],
			tearfullook: ["9L24", "8L24", "7L22"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	sudowoodo: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["9L12", "8L12", "7T", "7L29", "6T", "6L26", "5T", "5L22", "4T", "4L22", "3L33"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			counter: ["9L40", "8L40", "7L36", "6L33", "5L33", "3T"],
			covet: ["7T", "6T", "5T"],
			curse: ["8E", "7E", "7V", "6E", "5E"],
			defensecurl: ["8E", "7E", "7V", "6E", "5E", "4E", "3T"],
			dig: ["9M", "8M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L44", "8L44", "7L43", "6L40", "5L46", "4L46", "3T", "3L57"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["9M", "8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "9L1", "8M", "8L1"],
			feintattack: ["7L19", "7V", "6L19", "5L25", "4L25", "3L41"],
			firepunch: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			flail: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L9"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			hammerarm: ["9L1", "8L1", "7L50", "6L47", "5L49", "4L49"],
			harden: ["8E", "7E", "6E", "5E", "4E"],
			headbutt: ["8E", "7E", "7V", "6E", "5E", "4T", "4E"],
			headsmash: ["9L48", "8L48", "7L54"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M"],
			icepunch: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			irondefense: ["9M", "8M"],
			lowkick: ["9M", "9L36", "8M", "8L36", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L17"],
			lowsweep: ["9M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			meteorbeam: ["8T"],
			mimic: ["9L16", "8L16", "7L15", "7V", "6L15", "5L17", "4L17", "3T", "3L1"],
			mudshot: ["9M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			powergem: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["9M", "8M"],
			rockpolish: ["8E", "7M", "6M", "5M", "4M"],
			rockslide: ["9M", "9L32", "8M", "8L32", "7M", "7L33", "7V", "6M", "6L29", "5M", "5L33", "4M", "4L33", "3T", "3L25"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			rocktomb: ["9M", "9L20", "8M", "8L20", "7M", "7L26", "6M", "6L22", "5M", "5L30", "4M", "4L30", "3M"],
			roleplay: ["7T", "6T", "5T", "5D", "4T"],
			rollout: ["8E", "7E", "7V", "6E", "5E", "5D", "4T", "4E", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			selfdestruct: ["8M", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			slam: ["9L0", "8L0", "7L1", "7V", "6L15", "5L38", "4L38", "3L49"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spikes: ["9M"],
			stealthrock: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "9L1", "8M", "8L1", "7M", "7L47", "6M", "6L43", "5M", "5L43", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L28", "8L28", "7L40", "6L36", "5L41", "4T", "4L41"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			tearfullook: ["9L24", "8L24", "7L22"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			woodhammer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
		},
	},
	hoppip: {
		learnset: {
			absorb: ["9L6", "7L1"],
			acrobatics: ["9M", "9L24", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			amnesia: ["7E", "7V", "6E", "5E", "4E", "3E"],
			aromatherapy: ["7E", "6E", "5E", "4E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M"],
			bounce: ["9L35", "7T", "7L46", "6T", "6L46", "5T", "5L46", "5D", "4T", "4L40"],
			bulletseed: ["9M", "9L12", "7L19", "6L19", "5L19", "5D", "4M", "4L19", "3M"],
			captivate: ["4M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			confusion: ["7E", "7V", "6E", "5E", "4E", "3E"],
			cottonguard: ["9E", "7E", "6E", "5E"],
			cottonspore: ["9L27", "7L34", "7V", "6L34", "5L34", "4L28", "3L25"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			doubleedge: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["9M", "9E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			endure: ["9M", "7E", "7V", "6E", "5E", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fairywind: ["9L8", "7L10", "6L10"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "9L32", "7T", "7L43", "7V", "6T", "6L43", "5T", "5L43", "4M", "4L37", "3M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["9M", "9E", "7E", "6E"],
			growl: ["7V"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			leafstorm: ["9M"],
			leechseed: ["9L19", "7L22", "7V", "6L22", "5L22", "4L22", "3L20"],
			lightscreen: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L22", "7L25", "7V", "6L25", "5L25", "4L25", "3L30"],
			memento: ["9L38", "7L49", "6L49", "5L49", "4L43"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			payday: ["7V"],
			poisonpowder: ["9L10", "7L12", "7V", "6L12", "5L12", "4L12", "3L13"],
			pollenpuff: ["9M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4E", "3E"],
			ragepowder: ["9E", "7L31", "6L31", "5L31"],
			raindance: ["9M"],
			reflect: ["9M", "7M", "7V", "6M", "5M", "4E", "3E"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			silverwind: ["4M"],
			sleeppowder: ["9L10", "7L16", "7V", "6L16", "5L16", "4L16", "3L17"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			splash: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			strengthsap: ["9E", "7E"],
			stunspore: ["9L10", "7L14", "7V", "6L14", "5L14", "4L14", "3L15"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			switcheroo: ["9E"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["9L15", "7T", "7L4", "7V", "6T", "6L4", "5T", "5L4", "5D", "4T", "4L4", "3L5"],
			tackle: ["9L1", "7L8", "7V", "6L8", "5L10", "4L10", "3L10"],
			tailwhip: ["9L4", "7L6", "7V", "6L6", "5L7", "4L7", "3L5"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L29", "7M", "7L37", "6M", "6L37", "5M", "5L37", "4M", "4L31"],
			worryseed: ["9E", "7T", "7L40", "7E", "6T", "6L40", "6E", "5T", "5L40", "5E", "4T", "4L34", "4E"],
		},
		encounters: [
			{generation: 2, level: 3},
		],
	},
	skiploom: {
		learnset: {
			absorb: ["9L1", "7L1"],
			acrobatics: ["9M", "9L28", "7M", "7L32", "6M", "6L32", "5M", "5L32"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M"],
			bounce: ["9L41", "7T", "7L56", "6T", "6L56", "5T", "5L56", "4T", "4L48"],
			bulletseed: ["9M", "9L15", "7L20", "6L20", "5L20", "4M", "4L20", "3M"],
			captivate: ["4M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			cottonspore: ["9L31", "7L40", "7V", "6L40", "5L40", "4L32", "3L29"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["9M"],
			endure: ["9M", "7V", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fairywind: ["9L10", "7L10", "6L10"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "9L37", "7T", "7L52", "7V", "6T", "6L52", "5T", "5L52", "4M", "4L44", "3M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["9M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			leafstorm: ["9M"],
			leechseed: ["9L20", "7L24", "7V", "6L24", "5L24", "4L24", "3L22"],
			lightscreen: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L24", "7L28", "7V", "6L28", "5L28", "4L28", "3L36"],
			memento: ["9L44", "7L60", "6L60", "5L60", "4L52"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			poisonpowder: ["9L12", "7L12", "7V", "6L12", "5L12", "4L12", "3L13"],
			pollenpuff: ["9M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M"],
			ragepowder: ["7L36", "6L36", "5L36"],
			reflect: ["9M", "7M", "6M", "5M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			sleeppowder: ["9L12", "7L16", "7V", "6L16", "5L16", "4L16", "3L17"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			splash: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			stunspore: ["9L12", "7L14", "7V", "6L14", "5L14", "4L14", "3L15"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["9L1", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			tackle: ["9L8", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L34", "7M", "7L44", "6M", "6L44", "5M", "5L44", "4M", "4L36"],
			worryseed: ["7T", "7L48", "6T", "6L48", "5T", "5L48", "4T", "4L40"],
		},
		encounters: [
			{generation: 4, level: 12},
		],
	},
	jumpluff: {
		learnset: {
			absorb: ["9L1", "7L1"],
			acrobatics: ["9M", "9L30", "7M", "7L34", "6M", "6L34", "5M", "5L34"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M"],
			bounce: ["9L49", "7T", "7L64", "6T", "6L64", "5T", "5L64", "4T", "4L48"],
			bulletseed: ["9M", "9L15", "7L20", "6L20", "5L20", "5S0", "4M", "4L20", "3M"],
			captivate: ["4M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			cottonspore: ["9L35", "7L44", "7V", "6L44", "5L44", "4L32", "3L33"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["9M"],
			endure: ["9M", "7V", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fairywind: ["9L10", "7L10", "6L10"],
			falseswipe: ["5S0"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "9L43", "7T", "7L59", "7V", "6T", "6L59", "5T", "5L59", "4M", "4L44", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["9M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			leafstorm: ["9M"],
			leechseed: ["9L20", "7L24", "7V", "6L24", "5L24", "5S0", "4L24", "3L22"],
			lightscreen: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L24", "7L29", "7V", "6L29", "5L29", "4L28", "3L44"],
			memento: ["9L55", "7L69", "6L69", "5L69", "4L52"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			poisonpowder: ["9L12", "7L12", "7V", "6L12", "5L12", "4L12", "3L13"],
			pollenpuff: ["9M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M"],
			ragepowder: ["7L39", "6L39", "5L39"],
			raindance: ["9M"],
			reflect: ["9M", "7M", "6M", "5M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			sleeppowder: ["9L12", "7L16", "7V", "6L16", "5L16", "5S0", "4L16", "3L17"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			splash: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			stunspore: ["9L12", "7L14", "7V", "6L14", "5L14", "4L14", "3L15"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["9L1", "7T", "7L1", "7V", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			tackle: ["9L8", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L39", "7M", "7L49", "6M", "6L49", "5M", "5L49", "4M", "4L36"],
			worryseed: ["7T", "7L54", "6T", "6L54", "5T", "5L54", "4T", "4L40"],
		},
		eventData: [
			{generation: 5, level: 27, gender: "M", isHidden: true, moves: ["falseswipe", "sleeppowder", "bulletseed", "leechseed"]},
		],
	},
	aipom: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["7L29", "7E", "7V", "6L29", "6E", "5L29", "5E", "4L29", "4E", "3L50", "3E"],
			astonish: ["7L8", "6L8", "5L8", "4L8", "3L13"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["7L11", "7V", "6L11", "5L11", "4L11", "3L18"],
			beatup: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bodyslam: ["3T"],
			bounce: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			covet: ["7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doublehit: ["7L32", "6L32", "5L32", "4L32"],
			doubleslap: ["7E", "7V", "6E", "5E", "4E", "3E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fakeout: ["7E", "6E", "5E", "5D", "4E"],
			firepunch: ["7T", "7V", "6T", "5T", "5D", "4T", "3T"],
			fling: ["7M", "7L36", "6M", "6L36", "5M", "5L36", "4M", "4L36"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["7L18", "7V", "6L18", "5L18", "4L18", "3L31"],
			grassknot: ["7M", "6M", "5M", "4M"],
			gunkshot: ["7T", "6T", "5T", "4T"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			icepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L43"],
			lowkick: ["7T", "6T", "5T", "4T"],
			lowsweep: ["7M", "6M", "5M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["7L39", "6L39", "5L39", "4L39"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payback: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			quickguard: ["7E", "6E"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["7E", "6E", "5E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7L4", "7V", "6L4", "5L4", "4L4", "3L6", "3S0"],
			scratch: ["7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			screech: ["7L25", "7E", "7V", "6L25", "6E", "5L25", "5E", "4L25", "4E", "3L43", "3E"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			shadowball: ["7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slam: ["7E", "7V", "6E", "5E", "4E", "3E"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			spite: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7L22", "7V", "6L22", "5L22", "4T", "4L22", "3T", "3L38"],
			switcheroo: ["7E", "6E", "5E"],
			tailslap: ["7E"],
			tailwhip: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			taunt: ["7M", "6M", "5M", "4M", "3M"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			tickle: ["7L15", "6L15", "5L15", "4L15", "3L25"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			workup: ["7M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["scratch", "tailwhip", "sandattack"], pokeball: "pokeball"},
		],
	},
	ambipom: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["7L29", "6L29", "5L29", "4L29"],
			astonish: ["7L1", "6L1", "5L1", "4L1"],
			attract: ["7M", "6M", "5M", "4M"],
			batonpass: ["7L11", "6L11", "5L11", "4L11"],
			bounce: ["7T", "6T", "5T", "4T"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			dig: ["6M", "5M", "4M"],
			doublehit: ["7L32", "6L32", "5L32", "4L32"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			dualchop: ["7L1"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			firepunch: ["7T", "6T", "5T", "4T"],
			fling: ["7M", "7L36", "6M", "6L36", "5M", "5L36", "4M", "4L36"],
			focuspunch: ["7T", "6T", "4M"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			furyswipes: ["7L18", "6L18", "5L18", "4L18"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			gunkshot: ["7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			icepunch: ["7T", "6T", "5T", "4T"],
			irontail: ["7T", "6T", "5T", "4M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L43"],
			lowkick: ["7T", "6T", "5T", "4T"],
			lowsweep: ["7M", "6M", "5M"],
			mudslap: ["4T"],
			nastyplot: ["7L39", "6L39", "5L39", "4L39"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7L1", "6L1", "5L1", "4L1"],
			scratch: ["7L1", "6L1", "5L1", "4L1"],
			screech: ["7L25", "6L25", "5L25", "4L25"],
			secretpower: ["6M", "4M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			shadowball: ["7M", "6M", "5M", "4M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["7L22", "6L22", "5L22", "4T", "4L22"],
			tailwhip: ["7L1", "6L1", "5L1", "4L1"],
			taunt: ["7M", "6M", "5M", "4M"],
			thief: ["7M", "6M", "5M", "4M"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			thunderpunch: ["7T", "6T", "5T", "4T"],
			thunderwave: ["7M", "6M", "5M", "4M"],
			tickle: ["7L15", "6L15", "5L15", "4L15"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M"],
			workup: ["7M", "5M"],
		},
	},
	sunkern: {
		learnset: {
			absorb: ["9L7", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			afteryou: ["7T", "6T", "5T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7E", "6E", "5E"],
			bulletseed: ["9M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L34", "7L37", "6L37", "5L37", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "7T", "6T", "5T", "5D", "4T"],
			encore: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			endeavor: ["9L25", "7T", "7L25", "6T", "6L25", "5T", "5L21", "4T", "4L21", "3L25"],
			endure: ["9M", "7E", "7V", "6E", "5E", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "9L22", "7T", "7L22", "7V", "6T", "6L22", "5T", "5L41", "4M", "4L41", "3M", "3L42"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7L7", "7E", "6L7", "6E", "5L13", "5E", "4L13", "4E", "3E"],
			grassyterrain: ["9M", "9E", "7E", "6E"],
			growth: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L6", "3S0"],
			helpinghand: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			ingrain: ["9E", "7L4", "7E", "6L4", "6E", "5L9", "5E", "4L9", "4E", "3L18"],
			leafstorm: ["9M"],
			leechseed: ["9E", "7L13", "7E", "6L13", "6E", "5L17", "5E", "4L17", "4E", "3E"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["9M"],
			megadrain: ["9L10", "7L10", "7V", "6L10", "5L5", "5D", "4L5", "3L13"],
			mimic: ["3T"],
			morningsun: ["9E", "7E", "6E", "5E"],
			naturalgift: ["7L31", "7E", "6L31", "6E", "5L31", "5E", "4M"],
			naturepower: ["7M", "7E", "6M", "6E", "5E", "4E", "3E"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			razorleaf: ["9L16", "7L16", "6L16", "5L29", "4L29"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "9L39", "7T", "7L43", "6T", "6L43", "5T", "5L45", "4T", "4L45"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "9L31", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L34", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "9L36", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L37", "4M", "4L37", "3M", "3L30"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7E", "7V", "6E", "5E", "5D", "4E"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			synthesis: ["9L28", "7T", "7L28", "7V", "6T", "6L28", "5T", "5L33", "4T", "4L33", "3L37"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["7T", "6T", "5T", "4T"],
			worryseed: ["9L19", "7T", "7L19", "6T", "6L19", "5T", "5L25", "4T", "4L25"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", abilities: ["chlorophyll"], moves: ["absorb", "growth"], pokeball: "pokeball"},
		],
	},
	sunflora: {
		learnset: {
			absorb: ["9L7", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			afteryou: ["7T", "6T", "5T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bulletseed: ["9M", "9L25", "7L25", "6L25", "5L21", "4M", "4L21", "3M", "3L25"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			dazzlinggleam: ["9M"],
			doubleedge: ["9L34", "7L37", "6L37", "5L37", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "7T", "6T", "5T", "4T"],
			encore: ["9M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "7V", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			flowershield: ["7L1", "6L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "9L22", "7T", "7L22", "7V", "6T", "6L22", "5T", "5L22", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7L7", "6L7", "5L13", "4L13"],
			grassyterrain: ["9M"],
			growth: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L6"],
			helpinghand: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			ingrain: ["9L4", "7L4", "6L4", "5L9", "4L9", "3L18"],
			leafstorm: ["9M", "9L43", "7L43", "6L43", "5L45", "4L43"],
			leechseed: ["9L13", "7L13", "6L13", "5L17", "4L17"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["9M"],
			megadrain: ["9L10", "7L10", "6L10", "5L5", "4L5"],
			mimic: ["3T"],
			naturalgift: ["7L31", "6L31", "5L31", "4M"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["9L50", "7L50", "6L50"],
			petaldance: ["9L28", "7L28", "7V", "6L28", "5L33", "4L33", "3L37"],
			pound: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			razorleaf: ["9L16", "7L16", "7V", "6L16", "5L29", "4L29", "3L13"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "9L31", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L41", "4M", "4L41", "3M", "3L42"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "9L39", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L37", "4M", "4L37", "3M", "3L30"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["7T", "6T", "5T", "4T"],
			worryseed: ["9L19", "7T", "7L19", "6T", "6L19", "5T", "5L25", "4T", "4L25"],
		},
	},
	yanma: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["4T"],
			airslash: ["7L54", "6L54", "5L54", "4L54"],
			ancientpower: ["7L33", "6L33", "5L33", "4T", "4L33"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["7L57", "6L57", "5L57", "4L57"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7L17", "7V", "6L17", "5L17", "4L17", "3L25"],
			doubleedge: ["7E", "6E", "5E", "3T"],
			doubleteam: ["7M", "7L11", "7V", "6M", "6L11", "5M", "5L11", "4M", "4L11", "3M", "3L12"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			feint: ["7E", "6E", "5E", "5D", "4E"],
			feintattack: ["7E", "6E", "5E", "4E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foresight: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["7T", "7V", "6T", "5T", "5D", "4M", "3M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypnosis: ["7L38", "6L38", "5L38", "4L38", "3L23"],
			leechlife: ["7E", "7V", "6E", "5E", "4E", "3E"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7L30", "7E", "6L30", "6E", "5L30", "5E", "4L30", "4E"],
			quickattack: ["7L6", "7V", "6L6", "5L6", "5D", "4L6", "3L6"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["7E", "7V", "6E", "5E", "4E", "3E"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			screech: ["7L46", "7V", "6L46", "5L46", "4L46", "3L49"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			silverwind: ["7E", "6E", "5E", "4M", "4E", "3E"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			sonicboom: ["7L14", "7V", "6L14", "5L14", "4L14", "3L17"],
			steelwing: ["7M", "6M", "4M", "3M"],
			stringshot: ["4T"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			supersonic: ["7L22", "7V", "6L22", "5L22", "4L22", "3L31"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tackle: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "7L27", "6T", "6L27", "5T", "5L27", "4T", "4L27", "3L34"],
			uturn: ["7M", "7L49", "6M", "6L49", "5M", "5L49", "4M", "4L49"],
			whirlwind: ["7E", "7V", "6E", "5E", "4E", "3E"],
			wingattack: ["7L43", "7V", "6L43", "5L43", "4L43", "3L39"],
		},
	},
	yanmega: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			aircutter: ["4T"],
			airslash: ["7L1", "6L1", "5L54", "4L49"],
			ancientpower: ["7L33", "6L33", "5L33", "4T", "4L33"],
			attract: ["7M", "6M", "5M", "4M"],
			bugbite: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			bugbuzz: ["7L1", "6L1", "5L57", "4L54"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			defog: ["7T", "4M"],
			detect: ["7L17", "6L17", "5L17", "4L17"],
			doubleteam: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			dreameater: ["7M", "6M", "5M", "4M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			feint: ["7L38", "6L38", "5L38", "4L38"],
			flash: ["6M", "5M", "4M"],
			foresight: ["7L1", "6L1", "5L1", "4L1"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["7T", "6T", "5T", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			laserfocus: ["7T"],
			leechlife: ["7M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightslash: ["7L1", "6L1", "5L1", "4L1"],
			ominouswind: ["4T"],
			protect: ["7M", "6M", "5M", "4M"],
			psychic: ["7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			pursuit: ["7L30", "6L30", "5L30", "4L30"],
			quickattack: ["7L1", "6L1", "5L1", "4L1"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			screech: ["7L46", "6L46", "5L46", "4L43"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			slash: ["7L43", "6L43", "5L43", "4L38"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			sonicboom: ["7L14", "6L14", "5L14", "4L14"],
			steelwing: ["7M", "6M", "4M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			supersonic: ["7L22", "6L22", "5L22", "4L22"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "7L27", "6T", "6L27", "5T", "5L27", "4T", "4L27"],
			uturn: ["7M", "7L49", "6M", "6L49", "5M", "5L49", "4M", "4L46"],
		},
	},
	wooper: {
		learnset: {
			acidspray: ["9M", "9E", "8E", "7E", "6E", "5E"],
			afteryou: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			amnesia: ["9M", "9L32", "8M", "8L32", "7L23", "7V", "6L23", "5L23", "4L23", "3L21"],
			ancientpower: ["9E", "8E", "7E", "7V", "6E", "5E", "4T", "4E", "3E"],
			aquatail: ["9L24", "8L24", "7T", "6T", "5T", "5D", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "7E", "7V", "6E", "5E", "5D", "4E", "3T", "3E"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "6E", "5E", "4E"],
			curse: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			defensecurl: ["7V", "3T"],
			dig: ["9M", "8M", "7V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doublekick: ["9E", "8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L40", "8M", "8L40", "7M", "7L33", "7V", "6M", "6L33", "5M", "5L33", "4M", "4L33", "3M", "3L36"],
			eerieimpulse: ["8M", "7E", "6E"],
			encore: ["9M", "8M", "7E", "6E", "5E", "4E"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			guardswap: ["8M", "7E", "6E"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["9L12", "8L12", "7L43", "7V", "6L43", "5L43", "4L43", "3L51"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "8M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			infestation: ["7M", "6M"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			liquidation: ["9M"],
			mimic: ["3T"],
			mist: ["9L12", "8L12", "7L43", "7V", "6L43", "5L43", "4L43", "3L51"],
			mudbomb: ["7L19", "6L19", "5L19", "4L19"],
			muddywater: ["9L28", "8M", "8L28", "7L47", "6L47", "5L47", "4L47"],
			mudshot: ["9M", "9L8", "8M", "8L8", "7L9", "6L9", "5L9", "4L9", "3L16"],
			mudslap: ["9M", "7V", "4T", "3T"],
			mudsport: ["7L5", "7E", "6L5", "6E", "5L5", "5E", "4L5", "4E", "3E"],
			naturalgift: ["4M"],
			poweruppunch: ["8E", "7E"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "9L4", "8M", "8L4", "7M", "7L37", "7V", "6M", "6L37", "5M", "5L37", "4M", "4L37", "3M", "3L41"],
			recover: ["9E", "8E", "7E", "6E", "5E", "4E"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			slam: ["9L16", "8L16", "7L15", "7V", "6L15", "5L15", "4L15", "3L11"],
			sleeptalk: ["9M", "8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "3T"],
			sludgebomb: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			spikes: ["9M"],
			spitup: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			stealthrock: ["9M"],
			stockpile: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			tailwhip: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["9L36", "8L36", "7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			waterfall: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			yawn: ["9L21", "8L21", "7L29", "6L29", "5L29", "4L29", "3L31"],
		},
		encounters: [
			{generation: 2, level: 4},
		],
	},
	wooperpaldea: {
		learnset: {
			acidspray: ["9M", "9E"],
			afteryou: ["9E"],
			amnesia: ["9M", "9L32"],
			ancientpower: ["9E"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			counter: ["9E"],
			curse: ["9E"],
			dig: ["9M"],
			doublekick: ["9E"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L40"],
			endure: ["9M"],
			facade: ["9M"],
			gunkshot: ["9M"],
			haze: ["9E"],
			helpinghand: ["9M"],
			hydropump: ["9M"],
			liquidation: ["9M"],
			lowkick: ["9M"],
			mist: ["9E"],
			mudshot: ["9M", "9L1"],
			mudslap: ["9M"],
			poisonjab: ["9M", "9L24"],
			poisontail: ["9M", "9L8"],
			protect: ["9M"],
			raindance: ["9M"],
			recover: ["9E"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			slam: ["9L16"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			sludgewave: ["9L28"],
			spikes: ["9M"],
			spitup: ["9E"],
			stealthrock: ["9M"],
			stockpile: ["9E"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			surf: ["9M"],
			swallow: ["9E"],
			tackle: ["9L4"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["9L36"],
			toxicspikes: ["9M", "9L12"],
			trailblaze: ["9M"],
			venoshock: ["9M"],
			waterfall: ["9M"],
			waterpulse: ["9M"],
			yawn: ["9L21"],
		},
	},
	quagsire: {
		learnset: {
			acidspray: ["9M"],
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["9M", "9L40", "8M", "8L40", "7L24", "7V", "6L24", "5L24", "4L24", "3L23"],
			ancientpower: ["4T"],
			aquatail: ["9L28", "8L28", "7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bodypress: ["9M"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			dig: ["9M", "8M", "7V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L52", "8M", "8L52", "7M", "7L36", "7V", "6M", "6L36", "5M", "5L36", "4M", "4L36", "3M", "3L42"],
			eerieimpulse: ["9M", "8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			guardswap: ["8M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["9L12", "8L12", "7L48", "7V", "6L48", "5L48", "4L48", "3L61"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			infestation: ["7M", "6M"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			liquidation: ["9M", "8M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mist: ["9L12", "8L12", "7L48", "7V", "6L48", "5L48", "4L48", "3L61"],
			mudbomb: ["7L19", "6L19", "5L19", "4L19"],
			muddywater: ["9L34", "8M", "8L34", "7L53", "6L53", "5L53", "4L53"],
			mudshot: ["9M", "9L1", "8M", "8L1", "7L9", "6L9", "5L9", "4L9", "3L16"],
			mudslap: ["9M", "7V", "4T", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "9L1", "8M", "8L1", "7M", "7L41", "7V", "6M", "6L41", "5M", "5L41", "4M", "4L41", "3M", "3L49"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			slam: ["9L16", "8L16", "7L15", "7V", "6L15", "5L15", "4L15", "3L11"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			spikes: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tailwhip: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			toxic: ["9L46", "8L46", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			waterfall: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			yawn: ["9L23", "8L23", "7L31", "6L31", "5L31", "4L31", "3L35"],
		},
		encounters: [
			{generation: 2, level: 15},
			{generation: 4, level: 10},
		],
	},
	clodsire: {
		learnset: {
			acidspray: ["9M"],
			amnesia: ["9M", "9L0"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			dig: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L48"],
			endure: ["9M"],
			facade: ["9M"],
			gigaimpact: ["9M"],
			gunkshot: ["9M"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			ironhead: ["9M"],
			liquidation: ["9M"],
			lowkick: ["9M"],
			megahorn: ["9L36"],
			mudshot: ["9M", "9L8"],
			mudslap: ["9M"],
			poisonjab: ["9M", "9L24"],
			poisonsting: ["9L1"],
			poisontail: ["9M", "9L12"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			slam: ["9L16"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			sludgewave: ["9L30"],
			spikes: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			surf: ["9M"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["9L40"],
			toxicspikes: ["9M", "9L4"],
			trailblaze: ["9M"],
			venoshock: ["9M"],
			waterfall: ["9M"],
			waterpulse: ["9M"],
			yawn: ["9L21"],
			zenheadbutt: ["9M"],
		},
	},
	murkrow: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			aircutter: ["9M", "4T"],
			airslash: ["9M"],
			assurance: ["9L25", "7L25", "7E", "6L25", "6E", "5L25", "5E", "4L25"],
			astonish: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L9", "3S0"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bravebird: ["9M", "9E", "7E", "6E", "5E", "4E"],
			calmmind: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9E", "7E", "6E", "5E", "5D", "4E", "3E"],
			curse: ["7V"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			drillpeck: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["9E", "7E", "6E", "5E", "4E", "3E"],
			feintattack: ["7L35", "7E", "7V", "6L35", "6E", "5L35", "5E", "4L35", "4E", "3L35"],
			flatter: ["9E", "7E", "6E"],
			fly: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			foulplay: ["9M", "9L40", "7T", "7L45", "6T", "6L45", "5T", "5L45"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M"],
			gust: ["9L5"],
			haze: ["9L11", "7L11", "7V", "6L11", "5L11", "4L11", "3L22"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			hex: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["9M"],
			hyperbeam: ["9M"],
			icywind: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			meanlook: ["9L35", "7L41", "7V", "6L41", "5L41", "4L41", "3L48"],
			mimic: ["3T"],
			mirrormove: ["7E", "7V", "6E", "5E", "4E", "3E"],
			mudslap: ["9M", "7V", "4T", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["9M", "9L21", "7L21", "7V", "6L21", "5L21", "4L21", "3L27"],
			ominouswind: ["4T"],
			payback: ["7M", "6M", "5M", "4M"],
			peck: ["9L1", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			perishsong: ["9E", "7E", "6E", "5E", "4E", "3E"],
			pluck: ["5M", "4M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["9M", "7M", "6M", "5M", "4M"],
			psychoshift: ["7E", "6E", "5E", "4E"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			pursuit: ["7L5", "7V", "6L5", "5L5", "4L5", "3L14"],
			quash: ["9L60", "7M", "7L65", "6M", "6L65", "5M", "5L65"],
			quickattack: ["7V"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roost: ["7M", "7E", "6M", "6E", "5T", "5E", "5D", "4M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["9E", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			skyattack: ["9E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4E", "3T", "3E"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["9M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "7V", "6T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			steelwing: ["7M", "7V", "6M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L50", "7L55", "6L55", "5L55", "4T", "4L45"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			tailwind: ["9M", "7T", "7L50", "6T", "6L50", "5T", "5L51", "4T"],
			takedown: ["9M"],
			taunt: ["9M", "9L31", "7M", "7L31", "6M", "6L31", "5M", "5L31", "4M", "4L31", "3M", "3L40"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["9L55", "7M", "7L61", "6M", "6L61", "5M", "5L61", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["9M"],
			whirlwind: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			wingattack: ["9L15", "7L15", "7E", "7V", "6L15", "6E", "5L15", "5E", "4L15", "4E", "3E"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", abilities: ["insomnia"], moves: ["peck", "astonish"], pokeball: "pokeball"},
		],
	},
	honchkrow: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M"],
			aircutter: ["9M", "4T"],
			airslash: ["9M"],
			astonish: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			attract: ["7M", "6M", "5M", "4M"],
			bravebird: ["9M"],
			calmmind: ["9M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			comeuppance: ["9L65"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			darkpulse: ["9M", "9L55", "7M", "7L75", "6M", "6L75", "5T", "5L75", "4M", "4L55"],
			defog: ["7T", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			fly: ["9M", "7M", "6M", "5M", "4M"],
			foulplay: ["9M", "9L45", "7T", "7L45", "6T", "6L45", "5T", "5L45"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			haze: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			heatwave: ["9M", "7T", "7S0", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hurricane: ["9M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M"],
			icywind: ["9M", "7T", "7S0", "6T", "5T"],
			incinerate: ["6M", "5M"],
			mudslap: ["9M", "4T"],
			nastyplot: ["9M", "9L35", "7L35", "6L35", "5L35", "4L35"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			nightslash: ["9L1", "7L1", "7S0", "6L1", "5L55", "4L45"],
			ominouswind: ["4T"],
			payback: ["7M", "6M", "5M", "4M"],
			pluck: ["5M", "4M"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			psychic: ["9M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			pursuit: ["7L1", "6L1", "5L1", "4L1"],
			quash: ["9L1", "7M", "7L65", "6M", "6L65", "5M", "5L65"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "7M", "6M", "5M", "4M"],
			skyattack: ["7T", "7S0", "6T", "5T", "4T"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snarl: ["9M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["7T", "6T"],
			spite: ["7T", "6T", "5T", "4T"],
			steelwing: ["7M", "6M", "4M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["9L1", "7L1", "6L1", "4T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["9L25", "7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L25"],
			swift: ["4T"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["9M"],
			wingattack: ["9L1", "7L1", "6L1", "5L1", "4L1"],
		},
		eventData: [
			{generation: 7, level: 65, gender: "M", abilities: ["superluck"], moves: ["nightslash", "skyattack", "heatwave", "icywind"], pokeball: "cherishball"},
		],
	},
	misdreavus: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			allyswitch: ["7T"],
			astonish: ["9L10", "7L10", "6L10", "5L10", "4L10", "3L11"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			calmmind: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L14", "7L14", "7V", "6L14", "5L14", "4L14", "3L17"],
			confusion: ["9L1"],
			curse: ["9E", "7E", "7V", "6E", "5E", "4E"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			dazzlinggleam: ["9M", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			destinybond: ["9E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "7V", "4M", "3T"],
			energyball: ["9M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			foulplay: ["9M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			growl: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			grudge: ["7L50", "6L50", "5L50", "4L46", "3L53"],
			headbutt: ["7V", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hex: ["9M", "9L23", "7L23", "6L23", "5L23"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			icywind: ["9M", "7T", "6T", "5T", "4T"],
			imprison: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			inferno: ["5D"],
			magicalleaf: ["9M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["7T", "6T", "5T"],
			meanlook: ["9L19", "7L19", "7V", "6L19", "5L19", "4L19", "3L23"],
			mefirst: ["7E", "6E"],
			memento: ["9E", "7E", "6E", "5E", "4E"],
			mimic: ["3T"],
			nastyplot: ["9M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			nightshade: ["9M"],
			ominouswind: ["7E", "6E", "5E", "4T", "4E"],
			painsplit: ["9L32", "7T", "7L32", "7V", "6T", "6L32", "5T", "5L32", "4T", "4L28", "3L37"],
			payback: ["9L37", "7M", "7L37", "6M", "6L37", "5M", "5L37", "4M", "4L32"],
			perishsong: ["9L46", "7L46", "7V", "6L46", "5L46", "4L41", "3L45"],
			phantomforce: ["9M"],
			powergem: ["9M", "9L50", "7L55", "6L55", "5L55", "4L50"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L28", "7L28", "7V", "6L28", "5L28", "4L23", "3L30"],
			psychic: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			psyshock: ["9M"],
			psywave: ["7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "9L41", "7M", "7L41", "7V", "6M", "6L41", "5M", "5L41", "4M", "4L37", "3M"],
			shadowsneak: ["9E", "7E", "6E", "5E", "4E"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skillswap: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			spite: ["9E", "7T", "7L5", "7E", "7V", "6T", "6L5", "6E", "5T", "5L5", "5E", "4T", "4L5", "4E", "3L6", "3S0"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9E", "7E", "6E", "5E", "4T", "4E"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "7V", "4T", "3T"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
			wonderroom: ["9E", "7T", "7E", "6T", "6E", "5T", "5E"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["growl", "psywave", "spite"], pokeball: "pokeball"},
		],
	},
	mismagius: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			allyswitch: ["7T"],
			astonish: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			attract: ["7M", "6M", "5M", "4M"],
			calmmind: ["9M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			dazzlinggleam: ["9M", "7M", "6M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainingkiss: ["9M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "4M"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			faketears: ["9M"],
			flash: ["6M", "5M", "4M"],
			foulplay: ["9M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			icywind: ["9M", "7T", "6T", "5T", "4T"],
			imprison: ["9M"],
			laserfocus: ["7T"],
			luckychant: ["7L1", "6L1", "5L1", "4L1"],
			magicalleaf: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["7T", "6T", "5T"],
			mysticalfire: ["9L1", "7L1", "6L1"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			ominouswind: ["4T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["7M", "6M", "5M", "4M"],
			phantomforce: ["9M", "9L1", "7L1", "6L1"],
			powergem: ["9M", "9L1", "7L1", "6L1"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M"],
			psychic: ["9M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M"],
			psywave: ["7L1", "6L1", "5L1", "4L1"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			skillswap: ["9M", "7T", "6T", "5T", "4M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			snowscape: ["9M"],
			spite: ["9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "4T"],
			taunt: ["9M", "7M", "6M", "5M", "4M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M"],
			thunder: ["9M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
			wonderroom: ["7T", "6T", "5T"],
		},
	},
	unown: {
		learnset: {
			hiddenpower: ["7M", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
		},
		encounters: [
			{generation: 2, level: 5},
			{generation: 3, level: 25},
			{generation: 4, level: 5},
			{generation: 6, level: 32},
		],
	},
	wynaut: {
		learnset: {
			amnesia: ["8M", "8L1"],
			charm: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			counter: ["8L1", "7L15", "6L15", "5L15", "4L15", "3L15"],
			destinybond: ["8L1", "7L15", "6L15", "5L15", "4L15", "3L15"],
			encore: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			mirrorcoat: ["8L1", "7L15", "6L15", "5L15", "4L15", "3L15"],
			safeguard: ["8M", "8L1", "7M", "7L15", "6M", "6L15", "5L15", "4L15", "3L15"],
			splash: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			tickle: ["3S0"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["splash", "charm", "encore", "tickle"], pokeball: "pokeball"},
		],
	},
	wobbuffet: {
		learnset: {
			amnesia: ["8M", "8L1"],
			charm: ["8M", "8L1", "5D"],
			counter: ["8L0", "7L1", "7V", "6L1", "6S2", "6S3", "5L1", "4L1", "3L1", "3S0", "3S1"],
			destinybond: ["8L0", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
			encore: ["8M", "8L1", "5D"],
			mirrorcoat: ["8L0", "7L1", "7V", "6L1", "6S3", "5L1", "5D", "4L1", "3L1", "3S0", "3S1"],
			safeguard: ["8M", "8L0", "7M", "7L1", "7V", "6M", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
			splash: ["8L1"],
		},
		eventData: [
			{generation: 3, level: 5, moves: ["counter", "mirrorcoat", "safeguard", "destinybond"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["counter", "mirrorcoat", "safeguard", "destinybond"], pokeball: "pokeball"},
			{generation: 6, level: 10, gender: "M", moves: ["counter"], pokeball: "cherishball"},
			{generation: 6, level: 15, gender: "M", moves: ["counter", "mirrorcoat"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 2, level: 5},
			{generation: 4, level: 3},
		],
	},
	girafarig: {
		learnset: {
			agility: ["9M", "9L23", "7L23", "7V", "6L14", "5L14", "4L14", "3L31"],
			allyswitch: ["9E", "7T"],
			amnesia: ["9M", "7E", "7V", "6E", "5E", "4E", "3E"],
			assurance: ["9L10", "7L10", "6L10", "5L28", "4L28"],
			astonish: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L7"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "9L41", "7L41", "7V", "6L23", "5L23", "4L23", "3L37"],
			beatup: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bodyslam: ["9M", "3T"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			calmmind: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L5", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L13"],
			crunch: ["9M", "9L37", "7L37", "7V", "6L37", "5L46", "4L46", "3L49"],
			curse: ["7V"],
			dazzlinggleam: ["9M", "7M", "6M"],
			doubleedge: ["3T"],
			doublehit: ["9L28", "7L28", "6L28", "5L32", "4L32"],
			doublekick: ["9E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			earthquake: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "7V", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			foulplay: ["9M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			gigaimpact: ["9M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			growl: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			guardswap: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			imprison: ["9M"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			lowkick: ["9M"],
			magiccoat: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			meanlook: ["9E", "7E", "6E", "5E"],
			mimic: ["3T"],
			mirrorcoat: ["9E", "7E", "6E", "5E", "5D", "4E"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M", "9L46", "7L46", "6L46"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			odorsleuth: ["7L5", "6L5", "5L5", "4L5", "3L25"],
			powerswap: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L19", "7L19", "7V", "6L19", "5L19", "4L19", "3L43"],
			psychic: ["9M", "9L50", "7M", "7L50", "7V", "6M", "6L37", "5M", "5L37", "4M", "4L37", "3M"],
			psychicfangs: ["9M", "7E"],
			psychicterrain: ["9M", "7E"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			psyshock: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7E", "6E", "5E", "4E"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			shadowball: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			stomp: ["9L14", "7L14", "7V", "6L10", "5L10", "4L10", "3L19"],
			stompingtantrum: ["9M", "7T"],
			storedpower: ["9M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "7V", "4T", "3T"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			twinbeam: ["9L32"],
			uproar: ["9E", "7T", "6T", "5T", "4T"],
			wish: ["9E", "7E", "6E", "5E", "4E", "3E"],
			workup: ["7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "7T", "7L32", "6T", "6L32", "5T", "5L41", "4T", "4L41"],
		},
	},
	pineco: {
		learnset: {
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7L20", "7V", "6L20", "5L20", "4L17", "3L29"],
			bodyslam: ["9M", "3T"],
			bugbite: ["9L9", "7T", "7L9", "6T", "6L9", "5T", "5L9", "4T", "4L9"],
			bugbuzz: ["9M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["9E", "7E", "6E", "5E", "4E", "3T", "3E", "3S1"],
			curse: ["9L23", "7V"],
			defensecurl: ["7V", "3T"],
			dig: ["9M", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L45", "7L45", "7E", "7V", "6L45", "6E", "5L45", "5E", "4L42", "4E", "3T", "3L50"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["9M", "7T", "6T", "5T"],
			earthquake: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "7E", "7V", "6E", "5E", "4M", "3T"],
			explosion: ["9L34", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L34", "4M", "4L31", "3T", "3L36"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gravity: ["7T", "6T", "5T", "5D", "4T"],
			gyroball: ["9L42", "7M", "7L42", "6M", "6L42", "5M", "5L42", "4M", "4L39"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icespinner: ["9M"],
			irondefense: ["9M", "9L39", "7T", "7L39", "6T", "6L39", "5T", "5L39", "4T", "4L34"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			naturalgift: ["7L23", "6L23", "5L23", "4M", "4L20"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["9L31", "7M", "7L31", "6M", "6L31", "5M", "5L31", "4M", "4L28"],
			pinmissile: ["9E", "7E", "7V", "6E", "5E", "4E", "3E", "3S1"],
			poisonjab: ["9M"],
			pounce: ["9M"],
			powertrick: ["9E", "7E", "6E", "5E", "4E"],
			protect: ["9M", "9L1", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1", "3S0"],
			raindance: ["9M"],
			rapidspin: ["9L17", "7L17", "7V", "6L17", "5L17", "4L12", "3L22"],
			reflect: ["9M", "7M", "7V", "6M", "5M", "4M", "4E", "3M", "3E"],
			refresh: ["3S1"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["7E", "6E", "5E", "4E"],
			reversal: ["9M"],
			rockblast: ["9M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			rollout: ["9L20", "7V", "4T", "3T"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["9M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["9E", "7E", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M"],
			selfdestruct: ["9L6", "7L6", "7V", "6L6", "5L6", "5D", "4L6", "3T", "3L8", "3S0"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			spikes: ["9M", "9L28", "7L28", "7V", "6L28", "5L28", "4L23", "3L43", "3S1"],
			stealthrock: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["9M", "6M", "5M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["9M", "9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			takedown: ["9M", "9L12", "7L12", "7V", "6L12", "5L12", "4L9", "3L15"],
			terablast: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M", "9E", "7E", "6E", "5E", "5D", "4E"],
			venoshock: ["9M", "7M", "6M", "5M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "protect", "selfdestruct"], pokeball: "pokeball"},
			{generation: 3, level: 20, moves: ["refresh", "pinmissile", "spikes", "counter"]},
		],
	},
	forretress: {
		learnset: {
			allyswitch: ["7T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			autotomize: ["7L1", "6L32", "5L32"],
			bide: ["7L20", "7V", "6L20", "5L20", "4L17", "3L29"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M"],
			bodyslam: ["9M", "3T"],
			bugbite: ["9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			bugbuzz: ["9M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["9L23", "7V"],
			defensecurl: ["7V", "3T"],
			dig: ["9M", "6M", "5M", "4M", "3M"],
			doubleedge: ["9L50", "7L50", "7V", "6L56", "5L56", "4L50", "3T", "3L59"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillrun: ["9M", "7T", "6T", "5T"],
			earthpower: ["9M"],
			earthquake: ["9M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "7V", "4M", "3T"],
			explosion: ["9L36", "7M", "7L36", "7V", "6M", "6L42", "5M", "5L42", "4M", "4L33", "3T", "3L39"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flashcannon: ["9M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["9L46", "7M", "7L46", "6M", "6L50", "5M", "5L50", "4M", "4L45"],
			headbutt: ["7V", "4T"],
			heavyslam: ["9M", "9L0", "7L1", "6L1", "5L70"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icespinner: ["9M"],
			irondefense: ["9M", "9L42", "7T", "7L42", "6T", "6L46", "5T", "5L46", "4T", "4L38"],
			ironhead: ["9M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			magnetrise: ["9L1", "7T", "7L1", "6T", "6L1", "5T", "5L60", "4T", "4L57"],
			mimic: ["3T"],
			mirrorshot: ["7L1", "6L31", "5L31", "4L31"],
			naturalgift: ["7L23", "6L23", "5L23", "4M", "4L20"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["9L32", "7M", "7L32", "6M", "6L36", "5M", "5L36", "4M", "4L28"],
			poisonjab: ["9M"],
			pounce: ["9M"],
			protect: ["9M", "9L1", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			raindance: ["9M"],
			rapidspin: ["9L17", "7L17", "7V", "6L17", "5L17", "4L12", "3L22"],
			reflect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			rockblast: ["9M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			rollout: ["9L20", "7V", "4T", "3T"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M"],
			selfdestruct: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L1"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smartstrike: ["9M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			spikes: ["9M", "9L28", "7L28", "7V", "6L28", "5L28", "4L23", "3L49"],
			stealthrock: ["9M", "7T", "6T", "5T", "4M"],
			steelbeam: ["9M"],
			stoneedge: ["9M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["9M", "6M", "5M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["9M"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9L12", "7L12", "7V", "6L12", "5L12", "4L1", "3L15"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			thunderwave: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1"],
			venoshock: ["9M", "7M", "6M", "5M"],
			voltswitch: ["9M", "7M", "6M", "5M"],
			zapcannon: ["9L1", "7L1", "6L1", "5L64", "4L62", "3L31"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	dunsparce: {
		learnset: {
			agility: ["9M", "8M", "7E", "6E", "5E", "4E"],
			airslash: ["9M", "8M", "7L41"],
			amnesia: ["9M", "8M"],
			ancientpower: ["9L20", "8L20", "7L16", "7E", "7V", "6L19", "6E", "5L48", "5E", "4T", "4L41", "4E", "3E"],
			aquatail: ["9E", "8E", "7T", "6T", "5T", "4T"],
			astonish: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "8M"],
			bide: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bind: ["7T", "6T", "5T"],
			bite: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "8L32", "7L18", "3T"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			chillingwater: ["9M"],
			coil: ["9L44", "8L48", "7L28", "6L37", "5L43"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			defensecurl: ["9L1", "8L1", "7L1", "7V", "6L1", "5L4", "5D", "4L5", "3T", "3L4"],
			dig: ["9M", "8M", "7L31", "7V", "6M", "6L31", "5M", "5L53", "4M", "4L45", "3M"],
			doubleedge: ["9L48", "8L52", "7L36", "6L34", "5L34", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonrush: ["9L40", "8L44", "7L43"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			drillrun: ["9M", "9L24", "8M", "8L24", "7T", "7L21", "6T", "6L43", "5T", "5L43"],
			dualwingbeat: ["8T"],
			earthpower: ["9M"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			endeavor: ["9L52", "8L56", "7T", "7L38", "6T", "6L46", "5T", "5L58", "4T", "4L49", "3L41"],
			endure: ["9M", "8M", "7L46", "7V", "6L40", "5L40", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["9L1", "8L1", "7L48", "6L49", "5L63", "4L53", "3L44"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M"],
			glare: ["9L12", "8L12", "7L33", "7V", "6L28", "5L12", "4L13", "3L14"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["9E", "8E", "7E", "7V", "6E", "5E", "4T", "4E", "3E"],
			helpinghand: ["9M", "8M"],
			hex: ["9M", "8M", "7E", "6E", "5E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M"],
			hyperdrill: ["9L32"],
			hypervoice: ["9M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icespinner: ["9M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			lastresort: ["9E", "8E", "7T", "6T", "5T", "4T"],
			magiccoat: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L4", "8L4", "7L13", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			poisontail: ["9M"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			pursuit: ["7L8", "7V", "6L10", "5L24", "4L25", "3L24"],
			rage: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["9L8", "8L8", "7L3", "7V", "6L4", "5L16", "4T", "4L17", "3T", "3L21"],
			roost: ["9L36", "8L40", "7M", "7L23", "6M", "6L25", "5T", "5L33", "4M", "4L33"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			screech: ["9L16", "8M", "8L16", "7L11", "7V", "6L13", "5L28", "4L29", "3L31"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "3T"],
			smartstrike: ["9M"],
			snore: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T"],
			solarbeam: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			spite: ["7T", "7L6", "7V", "6T", "6L7", "5T", "5L20", "4T", "4L21", "3L21"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "5D", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M"],
			storedpower: ["9M", "8M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["9M", "8L36", "7L26", "7V", "6L22", "5L38", "4L37", "3L34"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trumpcard: ["7E", "6E", "5E", "4E"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			yawn: ["9L28", "8L28", "7L13", "6L16", "5L8", "4L9", "3L11"],
			zapcannon: ["7V"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
	},
	dudunsparce: {
		learnset: {
			agility: ["9M"],
			airslash: ["9M"],
			amnesia: ["9M"],
			ancientpower: ["9L20"],
			batonpass: ["9M"],
			blizzard: ["9M"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			boomburst: ["9L62"],
			bulldoze: ["9M"],
			calmmind: ["9M"],
			chillingwater: ["9M"],
			coil: ["9L44"],
			defensecurl: ["9L1"],
			dig: ["9M"],
			doubleedge: ["9L48"],
			dragonrush: ["9L40"],
			dragontail: ["9M"],
			drillrun: ["9M", "9L24"],
			earthpower: ["9M"],
			earthquake: ["9M"],
			endeavor: ["9L52"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			flail: ["9L1"],
			flamethrower: ["9M"],
			gigaimpact: ["9M"],
			glare: ["9L12"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hurricane: ["9M", "9L56"],
			hyperbeam: ["9M"],
			hyperdrill: ["9L32"],
			hypervoice: ["9M"],
			icebeam: ["9M"],
			icespinner: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L4"],
			outrage: ["9M"],
			poisonjab: ["9M"],
			poisontail: ["9M"],
			pounce: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			rollout: ["9L8"],
			roost: ["9L36"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			screech: ["9L16"],
			shadowball: ["9M"],
			sleeptalk: ["9M"],
			smartstrike: ["9M"],
			solarbeam: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			wildcharge: ["9M"],
			yawn: ["9L28"],
			zenheadbutt: ["9M"],
		},
	},
	gligar: {
		learnset: {
			acrobatics: ["7M", "7L22", "6M", "6L22", "5M", "5L27"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["7E", "6E", "5E", "4E"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			batonpass: ["7E", "6E", "5E", "4E"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			bugbite: ["7T", "6T", "5T"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			crosspoison: ["7E", "6E", "5E", "4E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			darkpulse: ["7M", "6M", "5T", "4M"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			dig: ["6M", "5M", "4M", "3M"],
			doubleedge: ["7E", "6E", "5E", "4E", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["3T"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["7M", "6M", "5M", "4M"],
			feint: ["7E", "6E", "5E", "5D", "4E"],
			feintattack: ["7L19", "7V", "6L19", "5L23", "4L23", "3L28"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7L16", "7V", "6L16", "5L20", "4T", "4L20", "3T"],
			guillotine: ["7L55", "7V", "6L55", "5L49", "4L45", "3L52"],
			harden: ["7L7", "7V", "6L7", "5L9", "4L9", "3L13"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "7L10", "6T", "6L10", "5T", "5L12", "4T", "4L12"],
			metalclaw: ["7E", "7V", "6E", "5E", "4E", "3E"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			nightslash: ["7E", "6E", "5E", "4E"],
			payback: ["7M", "6M", "5M", "4M"],
			poisonjab: ["7M", "6M", "5M", "4M"],
			poisonsting: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			poisontail: ["7E", "6E", "5E"],
			powertrick: ["7E", "6E", "5E", "4E"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["7L13", "7V", "6L13", "5L16", "4L16", "3L20"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["7E", "6E", "5E"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7L4", "7V", "6L4", "5L5", "5D", "4L5", "3L6", "3S0"],
			sandstorm: ["7M", "7V", "6M", "5M", "4M", "3M"],
			sandtomb: ["7E", "6E", "5E", "4E", "3E"],
			screech: ["7L35", "7V", "6L35", "5L31", "4L27", "3L44"],
			secretpower: ["6M", "4M", "3M"],
			skyuppercut: ["7L45", "6L45", "5L45"],
			slash: ["7L27", "7V", "6L27", "5L34", "4L31", "3L36"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["7M", "7V", "6M", "5M", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			steelwing: ["7M", "6M", "4M", "3M"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			swordsdance: ["7M", "7L50", "6M", "6L50", "5M", "5L38", "4M", "4L34", "3T"],
			tailwind: ["7T", "6T", "5T", "5D", "4T"],
			taunt: ["7M", "6M", "5M", "4M"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uturn: ["7M", "7L30", "6M", "6L30", "5M", "5L42", "4M", "4L38"],
			venoshock: ["7M", "6M", "5M"],
			wingattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			xscissor: ["7M", "7L40", "6M", "6L40", "5M", "5L45", "4M", "4L42"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["poisonsting", "sandattack"], pokeball: "pokeball"},
		],
	},
	gliscor: {
		learnset: {
			acrobatics: ["7M", "7L22", "6M", "6L22", "5M", "5L27"],
			aerialace: ["7M", "6M", "5M", "4M"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["7M", "6M", "5M", "4M"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			brutalswing: ["7M"],
			bugbite: ["7T", "6T", "5T"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["7M", "6M", "5T", "4M"],
			defog: ["7T", "4M"],
			dig: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["7M", "6M", "5M", "4M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			falseswipe: ["7M", "6M", "5M", "4M"],
			feintattack: ["7L19", "6L19", "5L23", "4L23"],
			firefang: ["7L1", "6L1", "5L1", "4L1"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["7L16", "6L16", "5L20", "4T", "4L20"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			guillotine: ["7L1", "6L1", "5L49", "4L45"],
			harden: ["7L1", "6L1", "5L1", "4L1"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			icefang: ["7L1", "6L1", "5L1", "4L1"],
			irontail: ["7T", "6T", "5T", "4M"],
			knockoff: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightslash: ["7L27", "6L27", "5L34", "4L31"],
			payback: ["7M", "6M", "5M", "4M"],
			poisonjab: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			protect: ["7M", "6M", "5M", "4M"],
			quickattack: ["7L13", "6L13", "5L16", "4L16"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7L1", "6L1", "5L1", "4L1"],
			sandstorm: ["7M", "6M", "5M", "4M"],
			screech: ["7L35", "6L35", "5L31", "4L27"],
			secretpower: ["6M", "4M"],
			skyattack: ["7T", "6T", "5T", "4T"],
			skyuppercut: ["7L45", "6L45", "5L45"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			sludgebomb: ["7M", "6M", "5M", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			steelwing: ["7M", "6M", "4M"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			swordsdance: ["7M", "7L50", "6M", "6L50", "5M", "5L38", "4M", "4L34"],
			tailwind: ["7T", "6T", "5T", "4T"],
			taunt: ["7M", "6M", "5M", "4M"],
			thief: ["7M", "6M", "5M", "4M"],
			throatchop: ["7T"],
			thunderfang: ["7L1", "6L1", "5L1", "4L1"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uturn: ["7M", "7L30", "6M", "6L30", "5M", "5L42", "4M", "4L38"],
			venoshock: ["7M", "6M", "5M"],
			xscissor: ["7M", "7L40", "6M", "6L40", "5M", "5L45", "4M", "4L42"],
		},
	},
	snubbull: {
		learnset: {
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bite: ["7L7", "7V", "6L7", "5L7", "4L7", "3L13"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			bulkup: ["7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L8", "3S0"],
			closecombat: ["7E", "6E", "5E", "5D", "4E"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			crunch: ["7L49", "7E", "7V", "6L49", "6E", "5L49", "5E", "4L49", "4E", "3L53", "3E"],
			curse: ["7V"],
			dazzlinggleam: ["7M", "6M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["6M", "5M", "4M", "3M"],
			doubleedge: ["7E", "6E", "5E", "5D", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			faketears: ["7E", "6E"],
			feintattack: ["7E", "7V", "6E", "5E", "4E", "3E"],
			fireblast: ["7M", "6M", "5M", "4M", "3M"],
			firefang: ["7L1", "7E", "6L1", "6E", "5L1", "5E", "4L1", "4E"],
			firepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["7M", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["7L19", "7V", "6L19", "5L19", "4T", "4L19"],
			healbell: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["7T", "6T", "5T"],
			icefang: ["7L1", "7E", "6L1", "6E", "5L1", "5E", "4L1", "4E"],
			icepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leer: ["7V"],
			lick: ["7L13", "7V", "6L13", "5L13", "4L13", "3L19"],
			lowkick: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			mimic: ["7E", "6E", "5E", "3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			overheat: ["7M", "6M", "5M", "4M", "3M"],
			payback: ["7M", "7L43", "6M", "6L43", "5M", "5L43", "4M", "4L43"],
			playrough: ["7L37", "6L37"],
			poweruppunch: ["6M"],
			present: ["7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7L31", "7V", "6L31", "5L31", "4L31", "3L34"],
			raindance: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["7M", "7V", "6M", "5M", "4E", "3E"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7L25", "7V", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3M", "3L26"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["7M", "7V", "6M", "5M", "4M", "3M"],
			smellingsalts: ["7E", "6E", "5E", "4E", "3E"],
			snarl: ["7M", "6M", "5M"],
			snore: ["7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			tailwhip: ["7L1", "7V", "6L1", "5L1", "4L1", "3L4", "3S0"],
			takedown: ["7V", "5L37", "4L37", "3L43"],
			taunt: ["7M", "6M", "5M", "4M", "3M"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderfang: ["7L1", "7E", "6L1", "6E", "5L1", "5E", "4L1", "4E"],
			thunderpunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wildcharge: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "scaryface", "tailwhip", "charm"], pokeball: "pokeball"},
		],
	},
	granbull: {
		learnset: {
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bite: ["7L7", "7V", "6L7", "5L7", "4L7", "3L13"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			bulkup: ["7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["7L1", "7V", "6L1", "5L1", "4L1", "3L8"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			crunch: ["7L59", "6L59", "5L59", "4L59", "3L61"],
			curse: ["7V"],
			dazzlinggleam: ["7M", "6M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fireblast: ["7M", "6M", "5M", "4M", "3M"],
			firefang: ["7L1", "6L1", "5L1", "4L1"],
			firepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["7M", "6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			headbutt: ["7L19", "7V", "6L19", "5L19", "4T", "4L19"],
			healbell: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["7T", "6T", "5T"],
			icefang: ["7L1", "6L1", "5L1", "4L1"],
			icepunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lick: ["7L13", "7V", "6L13", "5L13", "4L13", "3L19"],
			lowkick: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["7T", "7L1", "6T", "6L1", "5T", "5L67"],
			overheat: ["7M", "6M", "5M", "4M", "3M"],
			payback: ["7M", "7L51", "6M", "6L51", "5M", "5L51", "4M", "4L51"],
			playrough: ["7L43", "6L43"],
			poweruppunch: ["6M"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rage: ["7L35", "7V", "6L35", "5L35", "4L35", "3L38"],
			raindance: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["7M", "6M", "5M"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7L27", "7V", "6M", "6L27", "5M", "5L27", "4M", "4L27", "3M", "3L28"],
			rockclimb: ["4M"],
			rockslide: ["7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["7M", "7V", "6M", "5M", "4M", "3M"],
			snarl: ["7M", "6M", "5M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			tailwhip: ["7L1", "7V", "6L1", "5L1", "4L1", "3L4"],
			takedown: ["7V", "5L43", "4L43", "3L49"],
			taunt: ["7M", "6M", "5M", "4M", "3M"],
			thief: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunder: ["7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderfang: ["7L1", "6L1", "5L1", "4L1"],
			thunderpunch: ["7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wildcharge: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 2, level: 15},
		],
	},
	qwilfish: {
		learnset: {
			acidspray: ["9M", "9E", "8E", "7E", "6E", "5E"],
			acupressure: ["9L52", "8L60"],
			agility: ["9M"],
			aquajet: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
			aquatail: ["9L48", "8L56", "7T", "7L45", "6T", "6L45", "5T", "5L45", "4T", "4L45"],
			assurance: ["8M"],
			astonish: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barbbarrage: ["9E"],
			blizzard: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["9L24", "8M", "8L24", "7L33", "7E", "6L33", "6E", "5L33", "5E", "4M", "4L33"],
			bubble: ["7L13", "6L13"],
			bubblebeam: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			destinybond: ["9L56", "8L66", "7L1", "6L1", "5L53", "4L53", "3L45"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fellstinger: ["9L12", "8L12", "7L1", "6L1"],
			flail: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M"],
			gunkshot: ["9M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["9L4", "8L4", "7L9", "7V", "6L9", "5L9", "4L9", "3L9", "3S0"],
			haze: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["7V", "4T"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "8M", "7L1", "7V", "6L1", "5L57", "4L57", "3L37"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			liquidation: ["9M", "8M", "7T"],
			mimic: ["3T"],
			minimize: ["9L16", "8L16", "7L9", "7V", "6L9", "5L9", "4L9", "3L9", "3S0"],
			mudshot: ["9M"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pinmissile: ["9L32", "8M", "8L32", "7L37", "7V", "6L37", "5L37", "4L37", "3L21"],
			poisonjab: ["9M", "9L28", "8M", "8L40", "7M", "7L49", "6M", "6L49", "5M", "5L49", "4M", "4L49", "4E"],
			poisonsting: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			poisontail: ["9M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "8L28", "7L29", "6L29", "5L29", "4L29", "3L25"],
			reversal: ["9M", "8M"],
			rollout: ["7L17", "7V", "6L17", "5L17", "4T", "4L17", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "8M"],
			secretpower: ["6M", "5D", "4M", "3M"],
			selfdestruct: ["9E", "8M", "3T"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spikes: ["9M", "9L20", "8M", "8L20", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			spitup: ["9L40", "8L44", "7L25", "6L25", "5L25", "4L25"],
			steelroller: ["8T"],
			stockpile: ["9L40", "8L44", "7L25", "6L25", "5L25", "4L25"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			supersonic: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			surf: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			swordsdance: ["9M", "8M", "3T"],
			tackle: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			takedown: ["9M", "8L48", "7L41", "7V", "6L41", "5L41", "4L41", "3L33"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			terablast: ["9M"],
			throatchop: ["8M", "7T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["9L44", "8L52", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M", "9L36", "8M", "8L36", "7L21", "6L21", "5L21", "4L21"],
			venomdrench: ["8M"],
			venoshock: ["9M", "8M", "7M", "6M", "5M"],
			waterfall: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["9L8", "8L8", "7L1", "7V", "6L1", "5L13", "4L13", "3L13"],
			waterpulse: ["9M", "9E", "8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "poisonsting", "harden", "minimize"], pokeball: "pokeball"},
		],
	},
	qwilfishhisui: {
		learnset: {
			acidspray: ["9M", "9E"],
			acupressure: ["9L52"],
			agility: ["9M"],
			aquajet: ["9E"],
			aquatail: ["9E"],
			astonish: ["9E"],
			barbbarrage: ["9L28"],
			bite: ["9L8"],
			blizzard: ["9M"],
			brine: ["9L24"],
			bubblebeam: ["9E"],
			chillingwater: ["9M"],
			crunch: ["9M", "9L48"],
			darkpulse: ["9M"],
			destinybond: ["9L56"],
			endure: ["9M"],
			facade: ["9M"],
			fellstinger: ["9L12"],
			flail: ["9E"],
			gigaimpact: ["9M"],
			gunkshot: ["9M"],
			harden: ["9L4"],
			haze: ["9E"],
			hex: ["9M"],
			hydropump: ["9M"],
			icebeam: ["9M"],
			icywind: ["9M"],
			liquidation: ["9M"],
			minimize: ["9L16"],
			mudshot: ["9M"],
			pinmissile: ["9L32"],
			poisonjab: ["9M"],
			poisonsting: ["9L1"],
			poisontail: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			scaryface: ["9M"],
			selfdestruct: ["9E"],
			shadowball: ["9M"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			spikes: ["9M", "9L20"],
			spitup: ["9L40"],
			stockpile: ["9L40"],
			substitute: ["9M"],
			supersonic: ["9E"],
			surf: ["9M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			toxic: ["9L44"],
			toxicspikes: ["9M", "9L36"],
			venoshock: ["9M"],
			waterfall: ["9M"],
			waterpulse: ["9M", "9E"],
		},
	},
	overqwil: {
		learnset: {
			acupressure: ["9L52"],
			agility: ["9M"],
			barbbarrage: ["9L28"],
			bite: ["9L8"],
			blizzard: ["9M"],
			brine: ["9L24"],
			chillingwater: ["9M"],
			crunch: ["9M", "9L48"],
			darkpulse: ["9M"],
			destinybond: ["9L56"],
			endure: ["9M"],
			facade: ["9M"],
			fellstinger: ["9L12"],
			gigaimpact: ["9M"],
			gunkshot: ["9M"],
			harden: ["9L4"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M"],
			icywind: ["9M"],
			liquidation: ["9M"],
			minimize: ["9L16"],
			mudshot: ["9M"],
			pinmissile: ["9L32"],
			poisonjab: ["9M"],
			poisonsting: ["9L1"],
			poisontail: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			scaryface: ["9M"],
			shadowball: ["9M"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			smartstrike: ["9M"],
			spikes: ["9M", "9L20"],
			spitup: ["9L40"],
			stockpile: ["9L40"],
			substitute: ["9M"],
			surf: ["9M"],
			swordsdance: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			toxic: ["9L44"],
			toxicspikes: ["9M", "9L36"],
			venoshock: ["9M"],
			waterfall: ["9M"],
		},
	},
	shuckle: {
		learnset: {
			acid: ["8E", "7E", "6E", "5E"],
			acupressure: ["8E", "7E", "6E", "5E", "4E"],
			afteryou: ["7T", "6T", "5T"],
			ancientpower: ["4T"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7L1", "7V", "6L1", "5L1", "4L1", "3L28"],
			bind: ["7T", "6T", "5T"],
			bodyslam: ["8M", "3T"],
			bugbite: ["8L30", "7T", "7L42", "6T", "6L42", "5T", "5L49", "4T", "4L40"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			covet: ["8E"],
			curse: ["7V"],
			defensecurl: ["8E", "7V", "3T"],
			dig: ["8M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			encore: ["8M", "7L5", "7V", "6L5", "5L7", "5D", "4L9", "3L14", "3S1"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			finalgambit: ["8E", "7E", "6E", "5E"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gastroacid: ["8L45", "7T", "7L27", "6T", "6L27", "5T", "5L31", "4T", "4L35"],
			guardsplit: ["8L35", "7L45", "6L45", "5L55"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			infestation: ["8E", "7M", "6M"],
			irondefense: ["8M"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["7E", "7V", "6E", "5E", "4T", "4E", "3T"],
			naturalgift: ["4M"],
			powersplit: ["8L35", "7L45", "6L45", "5L55"],
			powertrick: ["8L55", "7L31", "6L31", "5L43", "4L48"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L25", "7M", "7L20", "7V", "6M", "6L20", "5M", "5L25", "4M", "4L27", "3M", "3L37"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["8M"],
			rockblast: ["8M", "7E", "6E", "5E"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8L40", "7M", "7L38", "6M", "6L38", "5M", "5L38", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["8L15", "7L23", "6L23", "5L23"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L5", "7L1", "7V", "6L1", "5L37", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L20", "7M", "7L16", "7V", "6M", "6L16", "5M", "5L19", "4M", "4L14", "3M", "3L23"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M", "3M"],
			shellsmash: ["8L65", "7L34", "6L34", "5L34", "5D"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "7V", "6M", "5M", "4M", "3M", "3S1"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			steelroller: ["8T"],
			stickyweb: ["8L50", "7L1", "6L1"],
			stoneedge: ["8M", "8L60", "7M", "7L49", "6M", "6L49", "5M", "5L49", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["8L10", "7L12", "6M", "6L12", "5M", "5L1"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T", "3S1"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			toxic: ["8E", "7M", "7V", "6M", "5M", "4M", "3M", "3S1"],
			venoshock: ["8M", "7M", "6M", "5M"],
			withdraw: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			wrap: ["8L1", "7L9", "7V", "6L9", "5L13", "4L22", "3L9", "3S0"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", abilities: ["sturdy"], moves: ["constrict", "withdraw", "wrap"], pokeball: "pokeball"},
			{generation: 3, level: 20, abilities: ["sturdy"], moves: ["substitute", "toxic", "sludgebomb", "encore"], pokeball: "pokeball"},
		],
	},
	heracross: {
		learnset: {
			aerialace: ["9M", "9L15", "8L15", "7M", "7L10", "6M", "6L10", "5M", "5L13", "4M", "4L13"],
			armthrust: ["9L1", "7L1", "6L1"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bide: ["7E", "7V", "6E", "5E", "4E", "3E"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "9L30", "8M", "8L30", "7M", "7L28", "6M", "6L25", "5M", "5L19", "4M", "4L19", "3M", "3L23"],
			brutalswing: ["8M", "7M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["9M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			bulletseed: ["9M", "8M", "7L1", "6L1", "6S0", "6S1"],
			captivate: ["4M"],
			chipaway: ["7L16", "6L16", "5L16"],
			closecombat: ["9M", "9L60", "8M", "8L60", "7L43", "6L34", "6S0", "5L37", "4L37"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["9L25", "8L25", "7L19", "7V", "6L19", "5L25", "4L25", "3T", "3L30"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			dig: ["9M", "8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["9E", "8E", "7E", "6E", "5E", "4E", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthquake: ["9M", "8M", "7M", "7V", "6M", "6S1", "5M", "4M", "3M"],
			endure: ["9M", "9L10", "8M", "8L10", "7L1", "7V", "6L1", "5L1", "4M", "4L1", "3T", "3L11"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M", "8M", "7M", "6M", "5M", "4E", "3E"],
			feint: ["9E", "8E", "7L7", "6L7", "5L49", "4L49"],
			flail: ["9E", "8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "5D", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["9L5", "8L5", "7L25", "7V", "6L7", "5L7", "4L7", "3L17"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			harden: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hornattack: ["9L20", "8L20", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L6"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leer: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			megahorn: ["9L55", "8M", "8L55", "7L37", "7E", "7V", "6L37", "6E", "6S0", "5L55", "5E", "4L55", "3L53"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			nightslash: ["9E", "8E", "7L1", "6L1", "5L1", "4L1"],
			pinmissile: ["9L35", "8M", "8L35", "7L31", "6L31", "6S0", "6S1"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "6E", "5E", "4E"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M", "7E", "6E", "5E", "4E"],
			reversal: ["9M", "8M", "7L46", "7V", "6L43", "5L43", "4L43", "3L45"],
			rockblast: ["9M", "8M", "7E", "6E", "6S1"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["9E", "8E", "7E", "6E", "5E", "3T"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			smartstrike: ["9M", "8M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spikes: ["9M", "8M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			strugglebug: ["9M", "6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swordsdance: ["9M", "9L50", "8M", "8L50", "7M", "6M", "5M", "4M", "3T"],
			tackle: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "8E", "7L34", "7V", "6L28", "5L31", "4L31", "3L37"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["9L45", "8L45"],
			throatchop: ["9L40", "8M", "8L40", "7T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			vacuumwave: ["4T"],
			venoshock: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 6, level: 50, gender: "F", nature: "Adamant", moves: ["bulletseed", "pinmissile", "closecombat", "megahorn"], pokeball: "cherishball"},
			{generation: 6, level: 50, nature: "Adamant", abilities: ["guts"], moves: ["pinmissile", "bulletseed", "earthquake", "rockblast"], pokeball: "cherishball"},
		],
	},
	sneasel: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L48", "8M", "8L48", "7L20", "7V", "6L20", "5L24", "4L24", "3L36"],
			assist: ["7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "8M", "7E", "6E", "5E", "4M"],
			beatup: ["9L42", "8M", "8L42", "7L28", "7V", "6L28", "5L42", "4L38", "3L57"],
			bite: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			blizzard: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			crushclaw: ["7E", "6E", "5E", "4E", "3E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["9M", "8M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doublehit: ["9E", "8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["7V", "3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			faketears: ["9M", "8M"],
			falseswipe: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feint: ["9E", "8E", "7E", "6E", "5E"],
			feintattack: ["7L10", "7V", "6L10", "5L14", "4L14", "3L22"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7E", "7V", "6E", "5E", "4E", "3E"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["9L30", "8L30", "7L16", "7V", "6L16", "5L21", "4L21", "3L29"],
			gigaimpact: ["9M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["9L36", "8L36", "7L25", "6M", "6L25", "5M", "5L35"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3T"],
			iceshard: ["9E", "8E", "7L47", "7E", "6L47", "6E", "5L51", "5E", "4L49", "4E"],
			iciclecrash: ["9E", "8E", "7E", "6E"],
			icywind: ["9M", "9L24", "8M", "8L24", "7T", "7L14", "7V", "6T", "6L14", "5T", "5L28", "4T", "4L28", "3T", "3L43"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "5D", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metalclaw: ["9M", "9L18", "8L18", "7L22", "7V", "6L22", "5L49", "4L42", "3L64"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychocut: ["8M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			punishment: ["7L44", "7E", "6L44", "6E", "5L44", "5E", "4E"],
			pursuit: ["7E", "6E", "5E", "4E"],
			quickattack: ["9L12", "8L12", "7L8", "7V", "6L8", "5L8", "4L8", "3L8", "3S0"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "7V", "6M", "5M", "4E", "3E"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			scratch: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			screech: ["9L54", "8M", "8L54", "7L32", "7V", "6L32", "5L10", "4L10", "3L15"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["9L60", "8L60", "7L35", "7V", "6L35", "5L38", "4L35", "3L50"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "7L40", "6T", "6L40", "5T", "5L40", "4M", "3M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			spite: ["9E", "8E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "9L6", "8M", "8L6", "7M", "7L1", "6M", "6L1", "5M", "5L1", "5D", "4M", "4L1", "3M", "3L1", "3S0"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T", "7E"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			tripleaxel: ["8T"],
			waterpulse: ["9M"],
			whirlpool: ["8M", "4M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["scratch", "leer", "taunt", "quickattack"], pokeball: "pokeball"},
		],
	},
	sneaselhisui: {
		learnset: {
			acidspray: ["9M"],
			aerialace: ["9M"],
			agility: ["9M", "9L48"],
			brickbreak: ["9M", "9L30"],
			bulkup: ["9M"],
			calmmind: ["9M"],
			closecombat: ["9M", "9L60"],
			counter: ["9E"],
			dig: ["9M"],
			doublehit: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			fakeout: ["9E"],
			falseswipe: ["9M"],
			feint: ["9E"],
			fling: ["9M"],
			focusblast: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			gunkshot: ["9M"],
			honeclaws: ["9L36"],
			leer: ["9L1"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			metalclaw: ["9M", "9L18"],
			nastyplot: ["9M"],
			nightslash: ["9E"],
			poisonjab: ["9M", "9L24"],
			poisontail: ["9M"],
			protect: ["9M"],
			quickattack: ["9L12"],
			quickguard: ["9E"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			rocksmash: ["9L1"],
			scratch: ["9L1"],
			screech: ["9L54"],
			shadowball: ["9M"],
			shadowclaw: ["9M"],
			slash: ["9L42"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			switcheroo: ["9E"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L6"],
			terablast: ["9M"],
			thief: ["9M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			venoshock: ["9M"],
			xscissor: ["9M"],
		},
	},
	weavile: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M"],
			agility: ["9M", "9L1", "8M", "8L1"],
			assurance: ["9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			avalanche: ["9M", "8M", "4M"],
			batonpass: ["9M"],
			beatup: ["9L1", "8M", "8L1"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M"],
			brickbreak: ["9M", "8M", "7M", "6M", "6S1", "5M", "4M", "4S0"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["9M", "9L66", "8M", "8L66", "7M", "7L47", "6M", "6L47", "5T", "5L51", "4M", "4L49"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			embargo: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fakeout: ["4S0"],
			faketears: ["9M", "8M"],
			falseswipe: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feintattack: ["7L10", "6L10", "5L14", "4L14"],
			fling: ["9M", "9L42", "8M", "8L42", "7M", "7L28", "6M", "6L28", "5M", "5L42", "4M", "4L38"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			furyswipes: ["9L30", "8L30", "7L16", "6L16", "5L21", "4L21"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["9L36", "8L36", "7L25", "6M", "6L25", "5M", "5L35"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icepunch: ["9M", "8M", "7T", "6T", "6S1", "5T", "4T"],
			iceshard: ["9L1", "8L1", "4S0"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L24", "8M", "8L24", "7T", "7L14", "6T", "6L14", "5T", "5L28", "4T", "4L28"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metalclaw: ["9M", "9L18", "8L18", "7L22", "6L22", "5L49", "4L42"],
			metronome: ["9M"],
			mudslap: ["4T"],
			nastyplot: ["9M", "9L48", "8M", "8L48", "7L20", "6L20", "5L24", "4L24"],
			naturalgift: ["4M"],
			nightslash: ["9L60", "8L60", "7L35", "6L35", "6S1", "5L35", "4L35", "4S0"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychocut: ["8M"],
			psychup: ["7M", "6M", "5M", "4M"],
			punishment: ["7L44", "6L44"],
			quickattack: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			screech: ["9L54", "8M", "8L54", "7L32", "6L32", "5L10", "4L10"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["9L1", "8L1"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "7L40", "6T", "6L40", "5T", "5L40", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			takedown: ["9M"],
			taunt: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			throatchop: ["8M", "7T"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			tripleaxel: ["8T"],
			waterpulse: ["9M"],
			whirlpool: ["8M", "4M"],
			xscissor: ["9M", "8M", "7M", "6M", "6S1", "5M", "4M"],
		},
		eventData: [
			{generation: 4, level: 30, gender: "M", nature: "Jolly", moves: ["fakeout", "iceshard", "nightslash", "brickbreak"], pokeball: "cherishball"},
			{generation: 6, level: 48, gender: "M", perfectIVs: 2, moves: ["nightslash", "icepunch", "brickbreak", "xscissor"], pokeball: "cherishball"},
		],
	},
	sneasler: {
		learnset: {
			acidspray: ["9M"],
			acrobatics: ["9M"],
			aerialace: ["9M"],
			agility: ["9M", "9L48"],
			brickbreak: ["9M", "9L30"],
			bulkup: ["9M"],
			calmmind: ["9M"],
			closecombat: ["9M", "9L60"],
			dig: ["9M"],
			direclaw: ["9L0"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			firepunch: ["9M"],
			fling: ["9M", "9L1"],
			focusblast: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			gunkshot: ["9M"],
			honeclaws: ["9L36"],
			hyperbeam: ["9M"],
			leer: ["9L1"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			metalclaw: ["9M", "9L18"],
			nastyplot: ["9M"],
			poisonjab: ["9M", "9L24"],
			poisontail: ["9M"],
			protect: ["9M"],
			quickattack: ["9L12"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rocksmash: ["9L1"],
			rocktomb: ["9M"],
			scratch: ["9L1"],
			screech: ["9L54"],
			shadowball: ["9M"],
			shadowclaw: ["9M"],
			slash: ["9L42"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L6"],
			terablast: ["9M"],
			thief: ["9M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M"],
			venoshock: ["9M"],
			xscissor: ["9M"],
		},
	},
	teddiursa: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M"],
			babydolleyes: ["9L1", "7L1", "6L1"],
			bellydrum: ["9E", "7E", "6E", "5E", "4E"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["9M", "9L33", "7L36", "6L36", "5L36", "4L36"],
			chipaway: ["7E", "6E", "5E"],
			closecombat: ["9M", "9E", "7E", "6E", "5E", "4E"],
			confide: ["7M", "6M"],
			counter: ["9E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			covet: ["9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4L1"],
			crosschop: ["9E", "7E", "6E", "5E", "4E"],
			crunch: ["9M", "9E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			dig: ["9M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["9E", "7E", "6E", "5E", "4E", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "9E", "7L1", "7E", "6L1", "6E", "5L1", "5E", "5D", "4L1", "4E", "3L19", "3E"],
			feintattack: ["7L15", "7V", "6L15", "5L15", "4L15", "3L25"],
			firepunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			fling: ["9M", "9L1", "7M", "7L1", "6M", "6L1", "5M", "5L57", "4M", "4L57"],
			focusenergy: ["7V"],
			focuspunch: ["7T", "6T", "5D", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["9E", "7V", "4T", "3T"],
			furyswipes: ["9L8", "7L8", "7V", "6L8", "5L8", "4L8", "3L13"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			icepunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leer: ["7V", "5L1", "4L1", "3L1", "3S0"],
			lick: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L7", "3S0", "3S1"],
			lowkick: ["9M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metalclaw: ["9M", "9E", "7E", "7V", "6E", "5E", "4E", "3E", "3S1"],
			metronome: ["9M", "3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["9E", "7E", "6E", "5E", "4E"],
			payback: ["9L13", "7M", "6M", "5M", "4M"],
			playnice: ["9L25", "7L25", "6L25"],
			playrough: ["9M", "9L29", "7E", "6E"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S1"],
			rest: ["9M", "9L37", "7M", "7L43", "7V", "6M", "6L43", "5M", "5L43", "4M", "4L43", "3M", "3L31"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M", "3S1"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			rollout: ["7V", "4T", "3T"],
			round: ["7M", "6M", "5M"],
			scratch: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			seismictoss: ["9E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M"],
			slash: ["9L22", "7L29", "7V", "6L29", "5L29", "4L29", "3L37"],
			sleeptalk: ["9M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "4E", "3T", "3E"],
			snore: ["9L37", "7T", "7L43", "7V", "6T", "6L43", "5T", "5L43", "4T", "4L43", "3T", "3L43"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["9L17", "7L22", "6L22", "5L22", "4L22"],
			swift: ["9M", "7V", "4T", "3T"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M", "7E", "7V", "6E", "5E", "4E", "3E"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["9L41", "7L50", "7V", "6L50", "5L50", "4L50", "3L49"],
			thunderpunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			workup: ["7M", "5M"],
			yawn: ["9E", "7E", "6E", "5E", "4E", "3E"],
			zapcannon: ["7V"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", abilities: ["pickup"], moves: ["scratch", "leer", "lick"], pokeball: "pokeball"},
			{generation: 3, level: 11, abilities: ["pickup"], moves: ["refresh", "metalclaw", "lick", "return"]},
		],
		encounters: [
			{generation: 2, level: 2},
		],
	},
	ursaring: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "4M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["9M"],
			closecombat: ["9M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4L1"],
			crunch: ["9M"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			defensecurl: ["7V", "3T"],
			dig: ["9M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1", "3L19"],
			feintattack: ["7L15", "7V", "6L15", "5L15", "4L15", "3L25"],
			firepunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			furyswipes: ["9L8", "7L8", "7V", "6L8", "5L8", "4L8", "3L1"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			hammerarm: ["9L64", "7L1", "6L1", "5L67", "4L67"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["9L48"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			icepunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leer: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lick: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metalclaw: ["9M"],
			metronome: ["9M", "3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["9L13", "7M", "6M", "5M", "4M"],
			playnice: ["9L25", "7L25", "6L25"],
			playrough: ["9M", "9L29"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "9L41", "7M", "7L47", "7V", "6M", "6L47", "5M", "5L47", "4M", "4L47", "3M", "3L31"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M", "9L35", "7L38", "6L38", "5L38", "4L38"],
			scratch: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M"],
			slash: ["9L22", "7L29", "7V", "6L29", "5L29", "4L29", "3L37"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["9L41", "7T", "7L49", "7V", "6T", "6L49", "5T", "5L49", "4T", "4L49", "3T", "3L43"],
			stompingtantrum: ["9M", "7T"],
			stoneedge: ["9M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["9L17", "7L22", "6L22", "5L22", "4L22"],
			swift: ["9M", "7V", "4T", "3T"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["9L56", "7L58", "7V", "6L58", "5L58", "4L58", "3L49"],
			throatchop: ["7T"],
			thunderpunch: ["9M", "7T", "7V", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["7T", "6T", "5T", "4T"],
			workup: ["7M", "5M"],
			zapcannon: ["7V"],
		},
		encounters: [
			{generation: 2, level: 25},
		],
	},
	ursaluna: {
		learnset: {
			aerialace: ["9M"],
			avalanche: ["9M"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulkup: ["9M"],
			bulldoze: ["9M"],
			charm: ["9M"],
			closecombat: ["9M"],
			covet: ["9L1"],
			crunch: ["9M"],
			dig: ["9M"],
			drainpunch: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			faketears: ["9M", "9L1"],
			firepunch: ["9M"],
			fling: ["9M"],
			furyswipes: ["9L8"],
			gigaimpact: ["9M"],
			gunkshot: ["9M"],
			hammerarm: ["9L64"],
			headlongrush: ["9L0"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			highhorsepower: ["9L48"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icepunch: ["9M"],
			leer: ["9L1"],
			lick: ["9L1"],
			lowkick: ["9M"],
			metalclaw: ["9M"],
			metronome: ["9M"],
			payback: ["9L13"],
			playnice: ["9L25"],
			playrough: ["9M", "9L29"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M", "9L41"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			scaryface: ["9M", "9L35"],
			scratch: ["9L1"],
			seedbomb: ["9M"],
			shadowclaw: ["9M"],
			slash: ["9L22"],
			sleeptalk: ["9M"],
			snore: ["9L41"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			sweetscent: ["9L17"],
			swift: ["9M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L56"],
			thunderpunch: ["9M"],
			trailblaze: ["9M"],
		},
	},
	slugma: {
		learnset: {
			acidarmor: ["7E", "7V", "6E", "5E", "4E", "3E"],
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["7L36", "7V", "6L32", "5L32", "4L31", "3L29"],
			ancientpower: ["7L22", "6L22", "5L28", "4T", "4L26"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7L41", "7V", "6L41", "5L46", "4L46", "3T", "3L50"],
			captivate: ["4M"],
			clearsmog: ["7L20", "6L20"],
			confide: ["7M", "6M"],
			curse: ["7E", "7V", "6E", "5E", "4E"],
			defensecurl: ["7V", "3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["7T", "7L50", "7E", "6T", "6L50", "6E", "5T", "5L55", "5E", "4T", "4L56"],
			ember: ["7L6", "7V", "6L5", "5L5", "5D", "4L8", "3L8"],
			endure: ["7V", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fireblast: ["7M", "7V", "6M", "5M", "4M", "3M"],
			flameburst: ["7L27", "6L23", "5L23"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["7M", "7L48", "7V", "6M", "6L48", "5M", "5L50", "4M", "4L53", "3M", "3L36"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			guardswap: ["7E", "6E"],
			harden: ["7L13", "7V", "6L13", "5L14", "4L16", "3L22"],
			heatwave: ["7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["7L15", "6M", "6L15", "5M"],
			inferno: ["7E", "6E", "5E", "5D"],
			infestation: ["7M", "6M"],
			irondefense: ["7T", "6T", "5T", "4T"],
			lavaplume: ["7L34", "6L34", "5L37", "4L38"],
			lightscreen: ["7M", "6M", "5M", "4M", "3M"],
			memento: ["7E", "6E", "5E", "4E"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			overheat: ["7M", "6M", "5M", "4M", "3M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["7L43", "6L19", "5L19", "4L23"],
			reflect: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["7M", "7L29", "7V", "6M", "6L29", "5M", "5L41", "4M", "4L41", "3T", "3L43"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["7L8", "7V", "6L8", "5L10", "4L11", "3L15"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			rollout: ["7E", "7V", "6E", "5E", "4T", "3T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["3T"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			smog: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			smokescreen: ["7E", "6E", "5E", "4E"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			spitup: ["7E", "6E", "5E", "4E"],
			stockpile: ["7E", "6E", "5E", "4E"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swallow: ["7E", "6E", "5E", "4E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			willowisp: ["7M", "6M", "5M", "4M"],
			yawn: ["7L1", "6L1", "5L1", "4L1", "3L1"],
		},
	},
	magcargo: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["7L36", "7V", "6L32", "5L32", "4L31", "3L29"],
			ancientpower: ["7L22", "6L22", "5L28", "4T", "4L26"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["7L43", "7V", "6L43", "5L52", "4L52", "3T", "3L60"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			clearsmog: ["7L20", "6L20"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["7T", "7L1", "6T", "6L1", "5T", "5L67", "4T", "4L66"],
			earthquake: ["7M", "7V", "6M", "5M", "4M", "3M", "3S0"],
			ember: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			endure: ["7V", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fireblast: ["7M", "7V", "6M", "5M", "4M", "3M"],
			flameburst: ["7L27", "6L23", "5L23"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["7M", "7L54", "7V", "6M", "6L54", "5M", "5L59", "4M", "4L61", "3M", "3L36", "3S0"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			gyroball: ["7M", "6M", "5M", "4M"],
			harden: ["7L13", "7V", "6L13", "5L14", "4L16", "3L22"],
			heatwave: ["7T", "6T", "5T", "4T", "3S0"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["7L15", "6M", "6L15", "5M"],
			infestation: ["7M", "6M"],
			irondefense: ["7T", "6T", "5T", "4T"],
			lavaplume: ["7L34", "6L34", "5L37", "4L40"],
			lightscreen: ["7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			overheat: ["7M", "6M", "5M", "4M", "3M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			protect: ["7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["7L47", "6L19", "5L19", "4L23"],
			reflect: ["7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "7L29", "7V", "6M", "6L29", "5M", "5L44", "4M", "4L45", "3T", "3L48"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["3T"],
			shellsmash: ["7L1", "6L38", "5L38"],
			sleeptalk: ["7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			smog: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			willowisp: ["7M", "6M", "5M", "4M"],
			yawn: ["7L1", "6L1", "5L1", "4L1", "3L1"],
		},
		eventData: [
			{generation: 3, level: 38, moves: ["refresh", "heatwave", "earthquake", "flamethrower"]},
		],
		encounters: [
			{generation: 3, level: 25},
			{generation: 6, level: 30},
		],
	},
	swinub: {
		learnset: {
			amnesia: ["8M", "8L35", "7L48", "7V", "6L48", "5L49", "4L49", "3L55"],
			ancientpower: ["8E", "7E", "7V", "6E", "5E", "5D", "4T", "4E", "3E", "3S0"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "7E", "6E", "5E"],
			bite: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			blizzard: ["8M", "8L50", "7M", "7L44", "7V", "6M", "6L44", "5M", "5L44", "4M", "4L44", "3M", "3L46"],
			bodyslam: ["8M", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["3S0"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["8E", "7E", "6E", "5E", "4E", "3T", "3E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L45", "7M", "7L37", "7V", "6M", "6L37", "5M", "5L37", "4M", "4L37", "3M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "8L25", "7L14", "7V", "6L14", "5L16", "4M", "4L16", "3T", "3L19"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["8E", "7E", "6E", "5E", "4E"],
			flail: ["8L10", "7L40", "6L40", "5L40"],
			freezedry: ["8E", "7E", "6E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			iceshard: ["8L15", "7L24", "6L24", "5L28", "4L28"],
			iciclecrash: ["8E", "7E", "6E", "5E"],
			iciclespear: ["8M", "7E", "6E", "5E", "5D", "4E", "3E"],
			icywind: ["8M", "8L30", "7T", "7L21", "7V", "6T", "6L21", "5T", "5L25", "4T", "4L25", "3T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			mist: ["8L20", "7L35", "7V", "6L35", "5L40", "4L40", "3L37", "3S0"],
			mudbomb: ["7L18", "6L18", "5L20", "4L20"],
			mudshot: ["8M", "7E", "6E", "5E", "4E", "3E", "3S0"],
			mudslap: ["8L1", "7L11", "7V", "6L11", "5L13", "4T", "4L13", "3T"],
			mudsport: ["7L5", "6L5", "5L4", "4L4"],
			naturalgift: ["4M"],
			odorsleuth: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			powdersnow: ["8L5", "7L8", "7V", "6L8", "5L8", "4L8", "3L10"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			scaryface: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L40", "7L28", "7E", "7V", "6L28", "6E", "5L32", "5E", "4L32", "4E", "3L28", "3E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
		},
		eventData: [
			{generation: 3, level: 22, abilities: ["oblivious"], moves: ["charm", "ancientpower", "mist", "mudshot"]},
		],
	},
	piloswine: {
		learnset: {
			amnesia: ["8M", "8L37", "7L58", "7V", "6L58", "5L65", "4L65", "3L70"],
			ancientpower: ["8L1", "7L1", "6L1", "5L1", "4T", "4L1"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "8L58", "7M", "7L52", "7V", "6M", "6L52", "5M", "5L56", "4M", "4L56", "3M", "3L56"],
			bodyslam: ["8M", "3T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L51", "7M", "7L46", "7V", "6M", "6L46", "5M", "5L40", "4M", "4L40", "3M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "8L25", "7L14", "7V", "6L14", "5L16", "4M", "4L16", "3T", "3L1"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L1"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["7L1", "7V", "6L33", "5L33", "4L33", "3L33"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hornattack: ["3L1"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icefang: ["8M", "8L0", "7L24", "6L24", "5L28", "4L28"],
			iceshard: ["8L15"],
			iciclespear: ["8M"],
			icywind: ["8M", "8L30", "7T", "7L21", "7V", "6T", "6L21", "5T", "5L25", "4T", "4L25", "3T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			mist: ["8L20", "7L37", "7V", "6L37", "5L48", "4L48", "3L42"],
			mudbomb: ["7L18", "6L18", "5L20", "4L20"],
			mudshot: ["8M"],
			mudslap: ["8L1", "7L11", "7V", "6L11", "5L13", "4T", "4L13", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			odorsleuth: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			peck: ["7L1", "6L1", "5L1", "4L1"],
			powdersnow: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			scaryface: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1"],
			takedown: ["8L44", "7L28", "7V", "6L28", "5L32", "4L32", "3L28"],
			thrash: ["8L65", "7L41", "6L41", "5L41"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	mamoswine: {
		learnset: {
			amnesia: ["8M", "8L37"],
			ancientpower: ["8L1", "7L1", "6L1", "5L1", "4T", "4L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "8L58", "7M", "7L52", "6M", "6L52", "5M", "5L56", "4M", "4L56"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			dig: ["8M", "6M", "5M", "4M"],
			doublehit: ["8L0", "7L33", "6L33", "5L33", "5S0", "4L33"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L51", "7M", "7L46", "6M", "6L46", "6S1", "5M", "5L40", "4M", "4L40"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "8L25", "7L14", "6L14", "5L16", "4M", "4L16"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			flail: ["8L1"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyattack: ["7L1"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "7L21", "6M", "6L21", "5M", "5L25", "5S0", "4M", "4L25"],
			headbutt: ["4T"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icefang: ["8M", "8L1", "7L24", "6L24", "5L28", "5S0", "4L28"],
			iceshard: ["8L15"],
			iciclecrash: ["6S1"],
			iciclespear: ["8M", "6S1"],
			icywind: ["8M", "8L30", "7T", "6T", "5T", "4T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M"],
			mist: ["8L20", "7L37", "6L37", "5L48", "4L48"],
			mudbomb: ["7L18", "6L18", "5L20", "4L20"],
			mudshot: ["8M"],
			mudslap: ["8L1", "7L11", "6L11", "5L13", "4T", "4L13"],
			mudsport: ["7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			odorsleuth: ["7L1", "6L1", "5L1", "4L1"],
			peck: ["7L1", "6L1", "5L1", "4L1"],
			powdersnow: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockblast: ["8M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "6S1", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M"],
			sandtomb: ["8M"],
			scaryface: ["8M", "7L1", "6L1", "5L65", "4L65"],
			secretpower: ["6M", "4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M"],
			tackle: ["8L1"],
			takedown: ["8L44", "7L28", "6L28", "5L32", "5S0", "4L32"],
			thrash: ["8L65", "7L41", "6L41", "5L41"],
			toxic: ["7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 5, level: 34, gender: "M", isHidden: true, moves: ["hail", "icefang", "takedown", "doublehit"]},
			{generation: 6, level: 50, shiny: true, gender: "M", nature: "Adamant", isHidden: true, moves: ["iciclespear", "earthquake", "iciclecrash", "rockslide"], pokeball: "pokeball"},
		],
	},
	corsola: {
		learnset: {
			amnesia: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			ancientpower: ["8L20", "7L17", "7V", "6L17", "5L32", "4T", "4L32", "3L45"],
			aquaring: ["8L10", "7L38", "7E", "6L38", "6E", "5L37", "5E", "4L37", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			barrier: ["7E", "6E", "5E", "4E", "3E"],
			bide: ["7E", "6E", "5E"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brine: ["8M", "7L27", "6L27", "4M"],
			bubble: ["7L4", "7V", "6L4", "5L8", "5D", "4L8", "3L12"],
			bubblebeam: ["8L25", "7L10", "7V", "6L10", "5L25", "4L25", "3L23"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			camouflage: ["7E", "6E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E", "5D", "4E", "3E"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E"],
			defensecurl: ["7V", "3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "8L45", "7T", "7L47", "6T", "6L47", "5T", "5L53", "4T", "4L53"],
			earthquake: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "8L15", "7L35", "7V", "6L35", "5L35", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L30", "7L50", "6L50", "5L52"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["8L1", "7L1", "7V", "6L1", "5L4", "4L4", "3L6"],
			headbutt: ["7V", "4T"],
			headsmash: ["8E", "7E", "6E", "5E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			iciclespear: ["8M", "7E", "6E", "5E", "4E", "3E"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			ingrain: ["7E", "6E", "5E", "4E", "3E"],
			irondefense: ["8M", "7T", "7L29", "6T", "6L29", "5T", "5L29"],
			lifedew: ["8L35"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			liquidation: ["8M", "7T", "7E"],
			luckychant: ["7L23", "6L23", "5L28", "4L28"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mirrorcoat: ["8L55", "7L45", "7V", "6L45", "5L48", "4L48", "3L39"],
			mist: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["3S0"],
			naturalgift: ["4M"],
			naturepower: ["8E", "7M", "7E", "6M", "6E", "5E", "4E"],
			powergem: ["8M", "8L40", "7L41", "7S1", "6L41", "5L44", "4L44"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["8L50", "7L8", "7V", "6L8", "5L13", "4L13", "3L17"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["7L13", "6L13", "5L16", "4L16", "3L17"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "7L31", "6L31", "5L20", "4L20", "3L34"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7V", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spikecannon: ["7L20", "7V", "6L20", "5L40", "4L40", "3L28"],
			stealthrock: ["8M", "7T", "6T", "5T", "5D", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "7V", "7S1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			throatchop: ["8M", "7T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L5"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["tackle", "mudsport"], pokeball: "pokeball"},
			{generation: 7, level: 50, gender: "F", nature: "Serious", abilities: ["hustle"], moves: ["tackle", "powergem"], pokeball: "ultraball"},
		],
	},
	corsolagalar: {
		learnset: {
			amnesia: ["8M"],
			ancientpower: ["8L20"],
			astonish: ["8L5", "8S0"],
			attract: ["8M"],
			blizzard: ["8M"],
			bodyslam: ["8M"],
			brine: ["8M"],
			bulldoze: ["8M"],
			calmmind: ["8M"],
			confuseray: ["8E"],
			curse: ["8L30"],
			destinybond: ["8E"],
			dig: ["8M"],
			disable: ["8L10", "8S0"],
			earthpower: ["8M"],
			earthquake: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			gigadrain: ["8M"],
			grudge: ["8L50"],
			hail: ["8M"],
			harden: ["8L1"],
			haze: ["8E"],
			headsmash: ["8E"],
			hex: ["8M", "8L25"],
			hydropump: ["8M"],
			icebeam: ["8M"],
			iciclespear: ["8M"],
			icywind: ["8M"],
			irondefense: ["8M"],
			lightscreen: ["8M"],
			liquidation: ["8M"],
			meteorbeam: ["8T"],
			mirrorcoat: ["8L55"],
			naturepower: ["8E"],
			nightshade: ["8L45"],
			powergem: ["8M", "8L40"],
			protect: ["8M"],
			psychic: ["8M"],
			raindance: ["8M"],
			reflect: ["8M"],
			rest: ["8M"],
			rockblast: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sandstorm: ["8M"],
			scald: ["8M"],
			screech: ["8M"],
			selfdestruct: ["8M"],
			shadowball: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			spite: ["8L15", "8S0"],
			stealthrock: ["8M"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M"],
			strengthsap: ["8L35"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			surf: ["8M"],
			tackle: ["8L1", "8S0"],
			throatchop: ["8M"],
			waterpulse: ["8E"],
			whirlpool: ["8M"],
			willowisp: ["8M"],
		},
		eventData: [
			{generation: 8, level: 15, isHidden: true, moves: ["tackle", "astonish", "disable", "spite"], pokeball: "cherishball"},
		],
	},
	cursola: {
		learnset: {
			amnesia: ["8M"],
			ancientpower: ["8L20"],
			astonish: ["8L1"],
			attract: ["8M"],
			blizzard: ["8M"],
			bodyslam: ["8M"],
			brine: ["8M"],
			bulldoze: ["8M"],
			burningjealousy: ["8T"],
			calmmind: ["8M"],
			curse: ["8L30"],
			dig: ["8M"],
			disable: ["8L1"],
			earthpower: ["8M"],
			earthquake: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			gigadrain: ["8M"],
			gigaimpact: ["8M"],
			grudge: ["8L50"],
			hail: ["8M"],
			harden: ["8L1"],
			hex: ["8M", "8L25"],
			hydropump: ["8M"],
			hyperbeam: ["8M"],
			icebeam: ["8M"],
			iciclespear: ["8M"],
			icywind: ["8M"],
			irondefense: ["8M"],
			leechlife: ["8M"],
			lightscreen: ["8M"],
			liquidation: ["8M"],
			meteorbeam: ["8T"],
			mirrorcoat: ["8L55"],
			nightshade: ["8L45"],
			perishsong: ["8L1"],
			pinmissile: ["8M"],
			poltergeist: ["8T"],
			powergem: ["8M", "8L40"],
			protect: ["8M"],
			psychic: ["8M"],
			raindance: ["8M"],
			reflect: ["8M"],
			rest: ["8M"],
			revenge: ["8M"],
			rockblast: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sandstorm: ["8M"],
			scald: ["8M"],
			screech: ["8M"],
			selfdestruct: ["8M"],
			shadowball: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			spite: ["8L15"],
			stealthrock: ["8M"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M"],
			strengthsap: ["8L35"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			surf: ["8M"],
			tackle: ["8L1"],
			throatchop: ["8M"],
			whirlpool: ["8M"],
			willowisp: ["8M"],
		},
	},
	remoraid: {
		learnset: {
			acidspray: ["8E", "7E", "6E", "5E"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8L16", "7L14", "7E", "7V", "6L14", "6E", "5L14", "5E", "4L14", "4E", "3L22", "3E"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "5D", "4M"],
			bubblebeam: ["8L20", "7L18", "7V", "6L18", "5L19", "4L19", "3L22"],
			bulletseed: ["8M", "8L28", "7L38", "6L38", "5L27", "4M", "4L27"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			entrainment: ["7E", "6E"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8E", "7E", "6E", "5E", "4E"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["8M", "8L8", "7L22", "7V", "6L22", "5L23", "4L23", "3L33"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gunkshot: ["8M", "7T", "6T", "5T", "4T"],
			haze: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			helpinghand: ["8M", "8L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L36", "7L42", "6L42", "5L42"],
			hyperbeam: ["8M", "8L44", "7M", "7L46", "7V", "6M", "6L46", "5M", "5L45", "4M", "4L45", "3M", "3L55"],
			icebeam: ["8M", "8L32", "7M", "7L34", "7V", "6M", "6L34", "5M", "5L40", "4M", "4L40", "3M", "3L44"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			lockon: ["8L24", "7L6", "7V", "6L6", "5L6", "5D", "4L6", "3L11"],
			mimic: ["3T"],
			mudshot: ["8M", "7E", "6E", "5E"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			octazooka: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L12", "7L10", "7V", "6L10", "5L10", "4L10", "3L22"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "7E", "6E", "5E", "5D", "4E", "3E"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			signalbeam: ["7T", "7L30", "6T", "6L30", "5T", "5L36", "4T", "4L36"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T"],
			soak: ["8L40", "7L50", "6L50", "5L49"],
			stringshot: ["4T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			supersonic: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7E", "7V", "6E", "5E", "4T", "3T"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["8L4", "7T", "7L26", "7E", "6T", "6L26", "6E", "5L32", "5E", "4M", "4L32", "3M"],
			waterspout: ["8E", "7E", "6E", "5E", "4E"],
			whirlpool: ["8M", "7V", "4M"],
		},
	},
	octillery: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8L16", "7L1", "7V", "6L1", "5L1", "4L1", "3L22"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "4M"],
			bubblebeam: ["8L20", "7L18", "7V", "6L18", "5L19", "4L19", "3L22"],
			bulletseed: ["8M", "8L30", "7L46", "6L46", "5L29", "4M", "4L29", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "7V", "6L1", "5L1", "4L1", "3L11"],
			curse: ["7V"],
			defensecurl: ["7V", "3T"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L1", "7L22", "7V", "6L22", "5L23", "4L23", "3L38"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gunkshot: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			helpinghand: ["8M", "8L1"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L42", "7L52", "6L52", "5L52"],
			hyperbeam: ["8M", "8L54", "7M", "7L58", "7V", "6M", "6L58", "5M", "5L55", "4M", "4L55", "4S0", "3M", "3L70"],
			icebeam: ["8M", "8L36", "7M", "7L40", "7V", "6M", "6L40", "5M", "5L48", "4M", "4L48", "4S0", "3M", "3L54"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			liquidation: ["8M"],
			lockon: ["8L24"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			octazooka: ["8L0", "7L1", "7V", "6L25", "5L25", "4L25", "4S0", "3L25"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L12", "7L1", "7V", "6L1", "5L1", "4L1", "3L22"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			signalbeam: ["7T", "7L34", "6T", "6L34", "5T", "5L42", "4T", "4L42", "4S0"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			soak: ["8L48", "7L64", "6L64", "5L61"],
			stringshot: ["4T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["8L1", "7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7V", "4M"],
			wrap: ["8L1"],
			wringout: ["7L28", "6L28", "5L36", "4L36"],
		},
		eventData: [
			{generation: 4, level: 50, gender: "F", nature: "Serious", abilities: ["suctioncups"], moves: ["octazooka", "icebeam", "signalbeam", "hyperbeam"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 4, level: 19},
			{generation: 7, level: 10},
		],
	},
	delibird: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "8M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			aurorabeam: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			auroraveil: ["9E", "8E", "7M"],
			avalanche: ["9M", "8M", "4M"],
			batonpass: ["9M", "8M"],
			bestow: ["7E", "6E", "5E"],
			blizzard: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			bravebird: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			brutalswing: ["8M", "7M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "3T"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			destinybond: ["9E", "8E", "7E", "6E"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillpeck: ["9L25", "8L25", "7L25"],
			drillrun: ["9M", "8M"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9E", "8E", "7E", "6E", "5E", "4E"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fly: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			focuspunch: ["7T", "6T", "5D", "4M", "3M"],
			foulplay: ["9M"],
			freezedry: ["9E", "8E", "7E", "6E"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			gigaimpact: ["9M"],
			gunkshot: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			happyhour: ["6S1"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M", "8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M"],
			iceball: ["7E", "6E", "5E", "4E", "3E"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			iceshard: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "3T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			memento: ["9E", "8E"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			pluck: ["5M", "4M"],
			poweruppunch: ["6M"],
			present: ["9L1", "8L1", "7L1", "7V", "6L1", "6S1", "5L1", "5D", "4L1", "3L1", "3S0"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			quickattack: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rapidspin: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			recycle: ["7T", "6T", "5T", "4M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skyattack: ["7T", "6T", "5T", "4T", "3T"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "3T"],
			snowscape: ["9M"],
			spikes: ["9M", "8M", "7E", "6E"],
			splash: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			steelwing: ["8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "7V", "4T", "3T"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			tripleaxel: ["8T"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			weatherball: ["8M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["present"], pokeball: "pokeball"},
			{generation: 6, level: 10, abilities: ["vitalspirit"], moves: ["present", "happyhour"], pokeball: "cherishball"},
		],
	},
	mantyke: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["8M", "8L20", "7L32", "6L32", "5L19", "4L19"],
			aircutter: ["4T"],
			airslash: ["8M", "8L32", "7L36", "6L36", "5L36"],
			amnesia: ["8M", "7E", "6E", "5E"],
			aquaring: ["8L36", "7L39", "6L39", "5L46", "4L46"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M"],
			bounce: ["8M", "8L40", "7T", "7L46", "6T", "6L46", "5T", "5L40", "4T", "4L40"],
			bubble: ["7L1", "6L1", "5L1", "4L1"],
			bubblebeam: ["8L24", "7L7", "6L7", "5L10", "4L10"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7L11", "6L11", "5L37", "4L37"],
			dive: ["8M", "6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M"],
			haze: ["8E", "7E", "6E", "5E", "4E"],
			headbutt: ["8L28", "7L16", "6L16", "5L13", "4T", "4L13"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hydropump: ["8M", "8L48", "7L49", "7E", "6L49", "6E", "5L49", "5E", "4L49", "4E"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			mirrorcoat: ["8E", "7E", "6E", "5E", "4E"],
			mudslap: ["4T"],
			mudsport: ["7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4E"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			slam: ["8E", "7E", "6E", "5E", "4E"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			splash: ["8E", "7E", "6E", "5E", "4E"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			supersonic: ["8L4", "7L3", "6L3", "5L4", "4L4"],
			surf: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["8M", "4T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			tailwind: ["8E", "7T", "7E", "6E"],
			takedown: ["8L44", "7L27", "6L27", "5L31", "4L31"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["8E", "7E", "6E", "5E", "4E"],
			waterfall: ["8M", "7M", "6M", "5M", "4M"],
			watergun: ["8L1"],
			waterpulse: ["8L12", "7T", "7L19", "6T", "6L19", "5L28", "4M", "4L28"],
			watersport: ["7E", "6E", "5E", "4E"],
			whirlpool: ["8M", "4M"],
			wideguard: ["8L16", "7L23", "7E", "6L23", "6E", "5L23", "5E"],
			wingattack: ["8L8", "7L14", "6L14", "5L22", "4L22"],
		},
	},
	mantine: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L20", "7L32", "7V", "6L32", "5L19", "4L19", "3L29"],
			aircutter: ["5D", "4T"],
			airslash: ["8M", "8L32", "7L36", "6L36", "5L36"],
			amnesia: ["8M", "7E", "6E", "5E"],
			aquaring: ["8L36", "7L39", "6L39", "5L46", "4L46"],
			aquatail: ["7T", "6T", "5T", "4T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			bounce: ["8M", "8L40", "7T", "7L46", "6T", "6L46", "5T", "5L40", "4T", "4L40"],
			brine: ["8M", "4M"],
			bubble: ["7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			bubblebeam: ["8L24", "7L1", "7V", "6L1", "5L1", "4L1", "3L15"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletseed: ["8M", "8L1", "7L1", "6L1", "5L1", "4M", "4L1"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7L11", "7V", "6L11", "5L37", "4L37", "3L50"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gunkshot: ["8M", "7T", "6T", "5T", "4T"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			headbutt: ["8L28", "7L16", "7V", "6L16", "5L13", "4T", "4L13"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["8M"],
			hydropump: ["8M", "8L48", "7L49", "7E", "7V", "6L49", "6E", "5L49", "5E", "4L49", "4E", "3E"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			liquidation: ["8M", "7T"],
			mimic: ["3T"],
			mirrorcoat: ["8E", "7E", "6E", "5E", "5D", "4E"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "4E", "3E"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			roost: ["8L1", "7M", "7L1"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			signalbeam: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			slam: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			splash: ["8E", "7E", "6E", "5E", "4E"],
			stringshot: ["4T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			supersonic: ["8L1", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L8", "3S0"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tackle: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			tailwind: ["8E", "7T", "6T", "5T", "4T"],
			takedown: ["8L44", "7L27", "7V", "6L27", "5L31", "4L31", "3L22"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			waterfall: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1"],
			waterpulse: ["8L12", "7T", "7L19", "6T", "6L19", "5L28", "4M", "4L28", "3M", "3L43"],
			watersport: ["7E", "6E", "5E", "4E"],
			whirlpool: ["8M", "7V", "4M"],
			wideguard: ["8L16", "7L23", "7E", "6L23", "6E", "5L23", "5E"],
			wingattack: ["8L1", "7L14", "7V", "6L14", "5L22", "4L22", "3L36"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "bubble", "supersonic"], pokeball: "pokeball"},
		],
	},
	skarmory: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L16", "7L31", "7V", "6L12", "5L12", "4L12", "3L16"],
			aircutter: ["8E", "7L12", "6L12", "5L23", "4T", "4L23", "3L29"],
			airslash: ["8M", "7L45", "6L42", "5L42", "4L39"],
			assurance: ["8M", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			autotomize: ["8L32", "7L50", "6L39", "5L39"],
			bodypress: ["8M"],
			bravebird: ["8M", "8L52", "7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			drillpeck: ["8L36", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			dualwingbeat: ["8T"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			feint: ["8E", "7L20", "6L20", "5L20", "4L20"],
			flash: ["6M", "5M", "4M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			fly: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["8L8", "7L17", "7V", "6L17", "5L17", "4L17", "3L26"],
			furycutter: ["4T"],
			guardswap: ["8M", "7E", "6E", "5E", "4E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			irondefense: ["8M", "8L48", "7T", "6T", "5T", "4T"],
			ironhead: ["8M", "7T", "6T", "5T"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			metalclaw: ["8L12", "7L9", "6L9"],
			metalsound: ["8L40", "7L42", "6L31", "5L31", "4L31", "3L45"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["8E", "7L53", "6L50", "5L50", "4L45"],
			ominouswind: ["4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			peck: ["8L1", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1"],
			pluck: ["5M", "4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			roost: ["8E", "7M", "6M", "5T", "5D", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L4", "7L6", "7V", "6L6", "5L6", "4L6", "3L10"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["8E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			skydrop: ["7M", "6M", "5M"],
			slash: ["8L24", "7L39", "6L39", "5L45", "4L42"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spikes: ["8M", "8L44", "7L28", "6L28", "5L28", "4L27", "3L42"],
			stealthrock: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			steelbeam: ["8T"],
			steelwing: ["8M", "8L28", "7M", "7L34", "7V", "6M", "6L34", "5L34", "4M", "4L34", "3M", "3L32"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7L23", "7V", "6L9", "5L9", "4T", "4L9", "3T", "3L13"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			tailwind: ["7T", "6T", "5T", "4T"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			whirlwind: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			wingattack: ["8L20"],
			xscissor: ["8M", "7M", "6M", "5M", "4M"],
		},
	},
	houndour: {
		learnset: {
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["9L25", "7L25", "7E", "7V", "6L25", "6E", "5L25", "5E", "4L27", "4E", "3E"],
			bite: ["9L16", "7L16", "7V", "6L16", "5L16", "4L17", "3L25"],
			bodyslam: ["9M", "3T"],
			captivate: ["4M"],
			charm: ["3S1"],
			comeuppance: ["9L37"],
			confide: ["7M", "6M"],
			counter: ["9E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			crunch: ["9M", "9L49", "7L49", "7V", "6L49", "5L49", "4L48", "3L49"],
			curse: ["7V"],
			darkpulse: ["9M", "7M", "6M", "5T", "5D", "4M"],
			destinybond: ["9E", "7E", "6E"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			embargo: ["7M", "7L37", "6M", "6L37", "5M", "5L37", "4M", "4L40"],
			ember: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			feint: ["9E", "7E", "6E", "5E", "5D", "4E"],
			feintattack: ["7L32", "7V", "6L32", "5L32", "4L35", "3L37", "3S1"],
			fireblast: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "9L28", "7L28", "7E", "6L28", "6E", "5L28", "5E", "4L30", "4E"],
			firespin: ["9M", "9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L44", "7M", "7L44", "7V", "6M", "6L44", "5M", "5L44", "4M", "4L43", "3M", "3L43"],
			flareblitz: ["9M"],
			foulplay: ["9M", "9L40", "7T", "7L40", "6T", "6L40", "5T", "5L40"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			howl: ["9L4", "7L4", "6L4", "5L4", "5D", "4L4", "3L7", "3S0"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			incinerate: ["9L20", "6M", "5M"],
			inferno: ["9L56", "7L56", "6L56", "5L56"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			leer: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S0"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			nastyplot: ["9M", "9L52", "7L52", "7E", "6L52", "6E", "5L52", "5E", "4L53", "4E"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			odorsleuth: ["7L20", "6L20", "5L20", "4L22", "3L31"],
			overheat: ["9M", "7M", "6M", "5M", "4M", "3M"],
			payback: ["7M", "6M", "5M", "4M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychicfangs: ["9M"],
			punishment: ["7E", "6E", "5E", "4E"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			rage: ["7E", "7V", "6E", "5E", "4E", "3E"],
			raindance: ["9M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			roar: ["9L13", "7M", "7L13", "7V", "6M", "6L13", "5M", "5L13", "4M", "4L14", "3M", "3L19", "3S1"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			smog: ["9L8", "7L8", "7V", "6L8", "5L8", "4L9", "3L13"],
			snarl: ["9M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			spite: ["9E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9E", "7E", "6E", "5E", "4T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderfang: ["9M", "9E", "7E", "6E", "5E", "4E"],
			torment: ["9L32", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["7T", "6T", "5T", "4T"],
			willowisp: ["9M", "7M", "6M", "5M", "4M", "4E", "3E"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["leer", "ember", "howl"], pokeball: "pokeball"},
			{generation: 3, level: 17, moves: ["charm", "feintattack", "ember", "roar"]},
		],
	},
	houndoom: {
		learnset: {
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			beatup: ["9L26", "7L26", "6L26", "5L26", "4L28"],
			bite: ["9L16", "7L16", "7V", "6L16", "5L16", "4L17", "3L27"],
			bodyslam: ["9M", "3T"],
			captivate: ["4M"],
			comeuppance: ["9L41"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["9M", "9L56", "7L56", "7V", "6L56", "5L56", "4L54", "3L59"],
			curse: ["7V"],
			darkpulse: ["9M", "7M", "6M", "6S0", "5T", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			embargo: ["7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L44"],
			ember: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L35", "7V", "6L35", "5L35", "4L38", "3L43"],
			fireblast: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "9L30", "7L30", "6L30", "5L30", "4L32"],
			firespin: ["9M"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L50", "7M", "7L50", "7V", "6M", "6L50", "6S0", "5M", "5L50", "4M", "4L48", "3M", "3L51"],
			flareblitz: ["9M"],
			foulplay: ["9M", "9L45", "7T", "7L45", "6T", "6L45", "5T", "5L45"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			howl: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hyperbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			incinerate: ["9L20", "6M", "5M"],
			inferno: ["9L62", "7L1", "6L1", "5L65"],
			irontail: ["7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			leer: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			nastyplot: ["9M", "9L1", "7L1", "6L1", "5L60", "4L60"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			odorsleuth: ["7L20", "6L20", "5L20", "4L22", "3L35"],
			overheat: ["9M", "7M", "6M", "5M", "4M", "3M"],
			payback: ["7M", "6M", "5M", "4M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			roar: ["9L13", "7M", "7L13", "7V", "6M", "6L13", "5M", "5L13", "4M", "4L14", "3M", "3L19"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "7M", "7V", "6M", "6S0", "5M", "4M", "3M"],
			smog: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L13"],
			snarl: ["9M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "7V", "6M", "6S0", "5M", "4M", "3M"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["7V", "4T", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			thunderfang: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1"],
			torment: ["9L35", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["7T", "6T", "5T", "4T"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 6, level: 50, nature: "Timid", abilities: ["flashfire"], moves: ["flamethrower", "darkpulse", "solarbeam", "sludgebomb"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 4, level: 20},
		],
	},
	phanpy: {
		learnset: {
			ancientpower: ["9E", "7E", "7V", "6E", "5E", "4T", "4E", "3E"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "9E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			bulldoze: ["9M", "9L15", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["9M", "9L33", "7L33", "6L33", "5L33", "4L33"],
			confide: ["7M", "6M"],
			counter: ["9E", "7E", "6E", "5E", "4E", "3T", "3E"],
			curse: ["7V"],
			defensecurl: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L9"],
			dig: ["9M"],
			doubleedge: ["9L42", "7L42", "7V", "6L42", "5L42", "4L42", "3T", "3L49"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M"],
			endeavor: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			endure: ["9M", "9L19", "7L19", "7V", "6L19", "5L28", "4M", "4L28", "3T", "3L41"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["9E", "7E", "6E", "5E", "4E", "3E"],
			flail: ["9L6", "7L6", "7V", "6L6", "5L6", "5D", "4L6", "3L17"],
			focusenergy: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			growl: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			headbutt: ["7V", "4T"],
			headsmash: ["9E", "7E", "6E", "5E", "4E"],
			heavyslam: ["9M", "9E", "7E", "6E", "5E", "5D"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["9E", "7E"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			iceshard: ["9E", "7E", "6E", "5E", "4E"],
			ironhead: ["9M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["9L37", "7T", "7L37", "6T", "6L37", "5T", "5L37", "4L37"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "7E", "7V", "6E", "5E", "4T", "3T"],
			naturalgift: ["7L15", "6L15", "5L19", "4M", "4L19"],
			odorsleuth: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			playrough: ["9M", "9E", "7E", "6E"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["9L10", "7L10", "7V", "6L10", "5L15", "4T", "4L15", "3T", "3L33"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "5D", "4T"],
			slam: ["9L24", "7L24", "6L24", "5L24", "4L24"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["9E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			stealthrock: ["9M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9L28", "7L28", "7V", "6L10", "5L10", "4L10", "3L25"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			watergun: ["7V"],
		},
		encounters: [
			{generation: 2, level: 2},
		],
	},
	donphan: {
		learnset: {
			ancientpower: ["4T"],
			assurance: ["9L15", "7L15", "6L15", "5L31", "4L31"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M"],
			bodyslam: ["9M", "3T"],
			bounce: ["7T", "6T", "5T", "4T"],
			brutalswing: ["7M"],
			bulldoze: ["9M", "9L1", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			captivate: ["4M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["7V"],
			defensecurl: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3T", "3L9"],
			dig: ["9M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L43", "7M", "7L43", "7V", "6M", "6L43", "5M", "5L46", "4M", "4L46", "3M", "3L49"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "7V", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1"],
			flail: ["7V", "4L1", "3L17"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furyattack: ["9L0", "7L1", "7V", "6L25", "5L25", "4L25", "3L25"],
			gigaimpact: ["9M", "9L50", "7M", "7L50", "6M", "6L50", "5M", "5L54", "4M", "4L54"],
			growl: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			gyroball: ["7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hornattack: ["9L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			hyperbeam: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			icefang: ["9M"],
			icespinner: ["9M"],
			irondefense: ["9M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			knockoff: ["9L19", "7T", "7L19", "6T", "6L10", "5T", "5L10", "4T", "4L10"],
			lastresort: ["7T", "6T", "5T"],
			magnitude: ["7L30", "6L19", "5L19", "4L19"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			odorsleuth: ["3L1"],
			playrough: ["9M"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			rapidspin: ["9L6", "7L6", "7V", "6L6", "5L6", "4L6", "3L41"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["9L10", "7L10", "7V", "6L10", "5L15", "4T", "4L15", "3T", "3L33"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scaryface: ["9M", "9L37", "7L37", "6L37", "5L39", "4L39"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			slam: ["9L24", "7L24", "6L24", "5L24", "4L24"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smartstrike: ["9M"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			stealthrock: ["9M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "9L30", "7T"],
			stoneedge: ["9M", "7M", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderfang: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
		},
		encounters: [
			{generation: 6, level: 24, maxEggMoves: 1},
		],
	},
	stantler: {
		learnset: {
			agility: ["9M"],
			astonish: ["9L7", "7L7", "6L7", "5L7", "4L7", "3L11"],
			attract: ["7M", "7V", "6M", "5M", "4M", "3M"],
			bite: ["9E", "7E", "7V", "6E", "5E", "4E", "3E"],
			bodyslam: ["9M", "3T"],
			bounce: ["7T", "6T", "5T", "4T"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			calmmind: ["9M", "9L27", "7M", "7L27", "6M", "6L27", "5M", "5L27", "4M", "4L27", "3M", "3L47"],
			captivate: ["7L50", "6L50", "5L53", "4M", "4L49"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L23", "7L23", "7V", "6L23", "5L23", "4L23", "3L41"],
			curse: ["7V"],
			detect: ["7V"],
			dig: ["9M"],
			disable: ["9E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			doubleedge: ["9L55", "3T"],
			doublekick: ["9E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			earthpower: ["9M"],
			earthquake: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["9M", "7V", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			extrasensory: ["9E", "7E", "6E", "5E", "4E", "3E"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M"],
			hypnosis: ["9L10", "7L10", "7V", "6L10", "5L10", "4L10", "3L17"],
			imprison: ["9M", "9L49", "7L49", "6L49", "5L49", "4L43"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			jumpkick: ["7L43", "6L43", "5L43"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leer: ["9L3", "7L3", "7V", "6L3", "5L3", "4L3", "3L7", "3S0"],
			lightscreen: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			magicroom: ["7T", "6T", "5T"],
			mefirst: ["7L1", "7E", "6L1", "6E", "5L55", "5E", "4L53"],
			megahorn: ["9E", "7E", "6E", "5E", "4E"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			mudsport: ["7E", "6E", "5E"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			protect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychic: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			psyshieldbash: ["9E"],
			psyshock: ["9M", "7M", "6M", "5M"],
			rage: ["7E", "6E", "5E"],
			raindance: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roleplay: ["9L32", "7T", "7L33", "6T", "6L33", "5T", "5L33", "4T", "4L33", "3L31"],
			round: ["7M", "6M", "5M"],
			sandattack: ["9L16", "7L16", "7V", "6L16", "5L16", "4L16", "3L27"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "7T", "6T", "5T", "5D", "4M", "3M"],
			sleeptalk: ["9M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			spite: ["9E", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			stomp: ["9L13", "7L13", "7V", "6L13", "5L13", "4L13", "3L21"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			swift: ["9M", "7V", "4T", "3T"],
			tackle: ["9L1", "7L1", "7V", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			takedown: ["9M", "9L21", "7L21", "7V", "6L21", "5L21", "4L21", "3L37"],
			terablast: ["9M"],
			thief: ["9M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thrash: ["9E", "7E", "6E", "5E", "4E"],
			throatchop: ["7T"],
			thunder: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trick: ["9M"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zenheadbutt: ["9M", "9L37", "7T", "7L38", "7E", "6T", "6L38", "6E", "5T", "5L38", "5E", "4T", "4L38", "4E"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", abilities: ["intimidate"], moves: ["tackle", "leer"], pokeball: "pokeball"},
		],
	},
	wyrdeer: {
		learnset: {
			agility: ["9M"],
			astonish: ["9L7"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			calmmind: ["9M", "9L27"],
			chargebeam: ["9M"],
			confuseray: ["9M", "9L23"],
			dig: ["9M"],
			doubleedge: ["9L55"],
			earthpower: ["9M"],
			earthquake: ["9M"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			gigaimpact: ["9M"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			hypnosis: ["9L10"],
			imprison: ["9M", "9L49"],
			leer: ["9L3"],
			lightscreen: ["9M"],
			megahorn: ["9L62"],
			protect: ["9M"],
			psybeam: ["9M"],
			psychic: ["9M"],
			psyshieldbash: ["9L0"],
			psyshock: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			roleplay: ["9L32"],
			sandattack: ["9L16"],
			scaryface: ["9M"],
			shadowball: ["9M"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			stomp: ["9L13"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M", "9L21"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderwave: ["9M"],
			trailblaze: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			wildcharge: ["9M"],
			zenheadbutt: ["9M", "9L37"],
		},
	},
	smeargle: {
		learnset: {
			captivate: ["5D"],
			falseswipe: ["5S1"],
			flamethrower: ["6S2"],
			furyswipes: ["6S2"],
			meanlook: ["5S1"],
			odorsleuth: ["5S1"],
			seismictoss: ["6S2"],
			sketch: ["7L1", "7V", "6L1", "6S2", "5L1", "5D", "4L1", "3L1", "3S0"],
			sleeptalk: ["5D"],
			spore: ["5S1"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", abilities: ["owntempo"], moves: ["sketch"], pokeball: "pokeball"},
			{generation: 5, level: 50, gender: "F", nature: "Jolly", ivs: {atk: 31, spe: 31}, abilities: ["technician"], moves: ["falseswipe", "spore", "odorsleuth", "meanlook"], pokeball: "cherishball"},
			{generation: 6, level: 40, gender: "M", nature: "Jolly", abilities: ["owntempo"], moves: ["sketch", "furyswipes", "seismictoss", "flamethrower"], pokeball: "cherishball"},
		],
	},
	miltank: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			attract: ["8M", "7M", "7V", "6M", "6S0", "5M", "4M", "3M"],
			belch: ["8E", "7E", "6E"],
			bide: ["7L15", "7V", "6L15", "5L15", "4L15", "3L26"],
			blizzard: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L40", "7L24", "7V", "6L24", "5L24", "4L24", "3T", "3L43"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["7L35", "6L35", "5L35", "4M", "4L35"],
			charm: ["8M", "8L50"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["8E", "7E", "7V", "6E", "5E", "5D", "4E", "3E"],
			defensecurl: ["8L10", "7L5", "7V", "6L5", "5L5", "5D", "4L5", "3T", "3L8"],
			dizzypunch: ["7E", "6E", "5E", "4E"],
			doubleedge: ["8E", "7E", "6E", "5E", "4E", "3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["7V", "3T"],
			earthquake: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7E", "7V", "6E", "5E", "4M", "4E", "3T", "3E"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["8E", "7T", "6T", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L3", "7V", "6L3", "5L3", "4L3", "3L4"],
			gyroball: ["8M", "7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L41"],
			hammerarm: ["8E", "7E", "6E", "5E", "4E"],
			headbutt: ["8L25", "7V", "4T"],
			healbell: ["8L20", "7T", "7L48", "7V", "6T", "6L48", "5T", "5L48", "4T", "4L48", "3L53"],
			heartstamp: ["7E", "6E", "5E"],
			heavyslam: ["8M"],
			helpinghand: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M", "8L55"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "5D", "4M", "3M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["8M", "3T"],
			milkdrink: ["8L35", "7L11", "7V", "6L11", "6S0", "5L11", "4L11", "3L19"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			playrough: ["8M", "8L45"],
			poweruppunch: ["6M"],
			present: ["8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "4E", "3T", "3E"],
			punishment: ["7E", "6E", "5E", "4E"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L5", "7L19", "7V", "6L19", "6S0", "5L19", "4T", "4L19", "3T", "3L34"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8E", "7E", "7V", "6E", "5E", "4E", "3T", "3E"],
			shadowball: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7E", "7V", "6M", "6E", "5T", "5E", "4M", "4E", "3T", "3E"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			steelroller: ["8T"],
			stomp: ["8L15", "7L8", "7V", "6L8", "6S0", "5L8", "4L8", "3L13"],
			stompingtantrum: ["8M", "7T"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			tackle: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			thunder: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			wakeupslap: ["7L50", "6L50", "5L55", "4L55"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			workup: ["8M", "7M", "5M"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "8L30", "7T", "7L29", "6T", "6L29", "5T", "5L29", "4T", "4L29"],
		},
		eventData: [
			{generation: 6, level: 20, perfectIVs: 3, abilities: ["scrappy"], moves: ["rollout", "attract", "stomp", "milkdrink"], pokeball: "cherishball"},
		],
	},
	raikou: {
		learnset: {
			agility: ["8M"],
			aurasphere: ["8M", "4S3"],
			bite: ["8L12", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["8M", "3T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "8L18", "7M", "7L78", "7S7", "6M", "6L78", "5M", "5L78", "4M", "4L78", "3M", "3L81"],
			charge: ["8L1"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L42", "7L43", "7V", "7S5", "7S6", "6L43", "6S4", "5L43", "4L43", "3L61", "3S1"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			dig: ["8M", "7V", "6M", "5M", "4M", "3M"],
			discharge: ["8L54", "7L1", "7S5", "7S6", "6L1", "5L57", "4L57"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M"],
			endure: ["8M", "7V", "4M", "3T"],
			extrasensory: ["8L48", "7L1", "7S7", "6L1", "5L64", "4L64"],
			extremespeed: ["8L1", "8S8", "4S3"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			howl: ["8L36", "8S8"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			quash: ["7M", "6M", "5M"],
			quickattack: ["8L1", "7L22", "7V", "6L22", "5L22", "4L22", "4S2", "3L31", "3S0", "3S1"],
			raindance: ["8M", "8L66", "7M", "7L71", "7V", "6M", "6L71", "5M", "5L71", "4M", "4L71", "3M"],
			reflect: ["8M", "8L60", "7M", "7L36", "7V", "7S5", "7S6", "6M", "6L36", "6S4", "5M", "5L36", "4M", "4L36", "4S2", "3M", "3L51", "3S1"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			roar: ["8L24", "7M", "7L15", "7V", "6M", "6L15", "5M", "5L15", "4M", "4L15", "4S2", "3M", "3L21", "3S0"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spark: ["8L6", "7L29", "7V", "6L29", "6S4", "5L29", "4L29", "4S2", "3L41", "3S0", "3S1"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			throatchop: ["8M", "7T"],
			thunder: ["8M", "8L72", "7M", "7L85", "7V", "6M", "6L85", "5M", "5L85", "4M", "4L71", "3M", "3L71"],
			thunderbolt: ["8M", "8S8", "7M", "7S7", "6M", "5M", "4M", "3M"],
			thunderfang: ["8M", "8L30", "7L50", "7S5", "7S6", "6L50", "6S4", "5L50", "4L50"],
			thundershock: ["8L1", "7L8", "7V", "6L8", "5L8", "4L8", "3L11", "3S0"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			voltswitch: ["8M", "7M", "7S7", "6M", "5M"],
			weatherball: ["8M", "8S8", "4S3"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zapcannon: ["8L78", "7V", "4S3"],
		},
		eventData: [
			{generation: 3, level: 50, shiny: 1, moves: ["thundershock", "roar", "quickattack", "spark"]},
			{generation: 3, level: 70, moves: ["quickattack", "spark", "reflect", "crunch"], pokeball: "pokeball"},
			{generation: 4, level: 40, shiny: 1, moves: ["roar", "quickattack", "spark", "reflect"]},
			{generation: 4, level: 30, shiny: true, nature: "Rash", moves: ["zapcannon", "aurasphere", "extremespeed", "weatherball"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["spark", "reflect", "crunch", "thunderfang"]},
			{generation: 7, level: 60, shiny: 1, moves: ["reflect", "crunch", "thunderfang", "discharge"]},
			{generation: 7, level: 60, moves: ["reflect", "crunch", "thunderfang", "discharge"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["thunderbolt", "voltswitch", "extrasensory", "calmmind"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["thunderbolt", "howl", "extremespeed", "weatherball"]},
		],
		encounters: [
			{generation: 2, level: 40},
			{generation: 3, level: 40},
		],
		eventOnly: true,
	},
	entei: {
		learnset: {
			agility: ["8M"],
			bite: ["8L12", "7L1", "7V", "7S5", "7S6", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["8M", "3T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "8L18", "7M", "7L78", "6M", "6L78", "5M", "5L78", "4M", "4L78", "3M", "3L81"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L42", "8S8"],
			crushclaw: ["4S3"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			dig: ["8M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			ember: ["8L1", "7L8", "7V", "6L8", "5L8", "4L8", "3L11", "3S0"],
			endure: ["8M", "7V", "4M", "3T"],
			eruption: ["8L78", "7L1", "6L1", "5L85", "4L85"],
			extrasensory: ["8L48", "7L1", "6L1", "5L64", "4L64"],
			extremespeed: ["8L1", "8S8", "4S3"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8L72", "7M", "7L71", "7V", "6M", "6L71", "5M", "5L71", "4M", "4L71", "3M", "3L71"],
			firefang: ["8M", "8L30", "7L50", "6L50", "6S4", "5L50", "4L50"],
			firespin: ["8M", "7L22", "7V", "6L22", "5L22", "4L22", "4S2", "3L31", "3S0", "3S1"],
			flamecharge: ["7M", "7S7", "6M", "5M"],
			flamethrower: ["8M", "8S8", "7M", "7L36", "7V", "6M", "6L36", "6S4", "5M", "5L36", "4M", "4L36", "4S2", "3M", "3L51", "3S1"],
			flamewheel: ["8L6"],
			flareblitz: ["8M", "4S3"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["7V", "4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			howl: ["4S3"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			ironhead: ["8M", "7T", "7S7", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lavaplume: ["8L54", "7L1", "7S5", "7S6", "6L1", "5L57", "4L57"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			quash: ["7M", "6M", "5M"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			reversal: ["8M"],
			roar: ["8L24", "7M", "7L15", "7V", "6M", "6L15", "5M", "5L15", "4M", "4L15", "4S2", "3M", "3L21", "3S0"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sacredfire: ["8L1", "7L1", "7S7", "6L1"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scaryface: ["8M", "8L36", "8S8"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smokescreen: ["8L1"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			stomp: ["8L1", "7L29", "7V", "7S5", "7S6", "6L29", "6S4", "5L29", "4L29", "4S2", "3L41", "3S0", "3S1"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "7S7", "6M", "5M", "4M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "8L66", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["8L60", "7M", "7L43", "7V", "7S5", "7S6", "6M", "6L43", "6S4", "5M", "5L43", "4M", "4L43", "3T", "3L61", "3S1"],
			swift: ["8M", "7V", "4T", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			weatherball: ["8M"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 50, shiny: 1, moves: ["ember", "roar", "firespin", "stomp"]},
			{generation: 3, level: 70, moves: ["firespin", "stomp", "flamethrower", "swagger"], pokeball: "pokeball"},
			{generation: 4, level: 40, shiny: 1, moves: ["roar", "firespin", "stomp", "flamethrower"]},
			{generation: 4, level: 30, shiny: true, nature: "Adamant", moves: ["flareblitz", "howl", "extremespeed", "crushclaw"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["stomp", "flamethrower", "swagger", "firefang"]},
			{generation: 7, level: 60, shiny: 1, moves: ["stomp", "bite", "swagger", "lavaplume"]},
			{generation: 7, level: 60, moves: ["stomp", "bite", "swagger", "lavaplume"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["sacredfire", "stoneedge", "ironhead", "flamecharge"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["flamethrower", "scaryface", "extremespeed", "crunch"]},
		],
		encounters: [
			{generation: 2, level: 40},
			{generation: 3, level: 40},
		],
		eventOnly: true,
	},
	suicune: {
		learnset: {
			agility: ["8M"],
			airslash: ["8M", "4S3"],
			aquaring: ["4S3"],
			aurorabeam: ["7L29", "7V", "7S5", "6L29", "6S4", "5L29", "4L29", "4S2", "3L41", "3S0", "3S1"],
			avalanche: ["8M", "4M"],
			bite: ["8L12", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			blizzard: ["8M", "8L78", "7M", "7L85", "7V", "6M", "6L85", "5M", "5L85", "4M", "4L85", "3M"],
			bodyslam: ["8M", "3T"],
			brine: ["8M", "4M"],
			bubblebeam: ["7L1", "7V", "7S5", "6L8", "5L8", "4L8", "3L11", "3S0"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "8L18", "8S6", "7M", "7L78", "6M", "6L78", "5M", "5L78", "4M", "4L78", "3M", "3L81"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L42"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			detect: ["7V"],
			dig: ["8M", "7V", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7V", "4M", "3T"],
			extrasensory: ["8L48", "8S6", "7L64", "6L1", "5L64", "4L64"],
			extremespeed: ["8L1", "8S6", "4S3"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gust: ["8L1", "7L22", "7V", "6L22", "5L22", "4L22", "4S2", "3L31", "3S0", "3S1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L72", "7L71", "7V", "6L1", "5L71", "4L71", "3L71"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icefang: ["8M", "8L30", "7L50", "6L50", "6S4", "5L50", "4L50"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			liquidation: ["8M", "8S6"],
			mimic: ["3T"],
			mirrorcoat: ["8L60", "7L43", "7V", "6L43", "6S4", "5L43", "4L43", "3L61", "3S1"],
			mist: ["8L1", "7L36", "7V", "7S5", "6L36", "6S4", "5L36", "4L36", "4S2", "3L51", "3S1"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			quash: ["7M", "6M", "5M"],
			raindance: ["8M", "8L66", "7M", "7L1", "7V", "7S5", "6M", "6L15", "5M", "5L15", "4M", "4L15", "4S2", "3M", "3L21", "3S0"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["8L24", "7M", "7V", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			sheercold: ["8L1", "7L1", "4S3"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["8M", "8L54", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "3T"],
			tailwind: ["8L36", "7T", "7L57", "6T", "6L1", "5T", "5L57", "4T", "4L57"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7V"],
			waterpulse: ["8L6", "7T", "6T", "4M", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "7V", "4M"],
		},
		eventData: [
			{generation: 3, level: 50, shiny: 1, moves: ["bubblebeam", "raindance", "gust", "aurorabeam"]},
			{generation: 3, level: 70, moves: ["gust", "aurorabeam", "mist", "mirrorcoat"], pokeball: "pokeball"},
			{generation: 4, level: 40, shiny: 1, moves: ["raindance", "gust", "aurorabeam", "mist"]},
			{generation: 4, level: 30, shiny: true, nature: "Relaxed", moves: ["sheercold", "airslash", "extremespeed", "aquaring"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["aurorabeam", "mist", "mirrorcoat", "icefang"]},
			{generation: 7, level: 60, shiny: 1, moves: ["bubblebeam", "aurorabeam", "mist", "raindance"]},
			{generation: 8, level: 70, shiny: 1, moves: ["liquidation", "extrasensory", "extremespeed", "calmmind"]},
		],
		encounters: [
			{generation: 2, level: 40},
			{generation: 3, level: 40},
		],
		eventOnly: true,
	},
	larvitar: {
		learnset: {
			ancientpower: ["9E", "8E", "7E", "7V", "6E", "5E", "4T", "4E", "3E"],
			assurance: ["9E", "8M", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bite: ["9L9", "8L9", "7L1", "7V", "6L1", "5L1", "5D", "5S1", "4L1", "3L1", "3S0"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L14", "6L14", "5L14"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L27", "8M", "8L27", "7L41", "7V", "6L41", "5L41", "4L37", "3L43"],
			curse: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			darkpulse: ["9M", "8M", "8L24", "7M", "7L32", "6M", "6L32", "5T", "5L32", "5D", "4M", "4L28"],
			detect: ["7V"],
			dig: ["9M", "8M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "9E", "8M", "7E", "6E", "5E", "4E", "3E", "3S0"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L31", "8M", "8L31", "7M", "7L46", "7V", "6M", "6L46", "5M", "5L46", "4M", "4L41", "3M", "3L50"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["9E", "8M", "7E", "7V", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "9L42", "8M", "8L42", "7M", "7L55", "7V", "6M", "6L55", "5M", "5L55", "4M", "4L50", "3M", "3L57"],
			irondefense: ["9M", "9E", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			ironhead: ["9M", "9E", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			irontail: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			leer: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "5S1", "4L1", "3L1"],
			mimic: ["3T"],
			muddywater: ["8M"],
			mudshot: ["9M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "9E", "8M", "7T", "7E", "7V", "6T", "6E", "5T", "5E", "4E", "3E", "3S0"],
			payback: ["9L6", "8M", "8L6", "7M", "7L37", "6M", "6L37", "5M", "5L37", "4M", "4L32"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "7V", "6E", "5E", "4E", "3E"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["9M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "9L15", "8M", "8L15", "7M", "7L19", "7V", "6M", "6L19", "5M", "5L19", "4M", "4L14", "3T", "3L22"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rockthrow: ["9L3", "8L3"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "9L39", "8M", "8L39", "7M", "7L5", "7V", "6M", "6L5", "5M", "5L5", "5S1", "4M", "4L5", "3M", "3L8", "3S0"],
			sandtomb: ["8M"],
			scaryface: ["9M", "9L12", "8M", "8L12", "7L23", "7V", "6L23", "5L23", "4L19", "3L36"],
			screech: ["9L21", "8M", "8L21", "7L10", "7V", "6L10", "5L10", "4L10", "3L15"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["9L24", "7M", "6M", "5M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4M"],
			stomp: ["9E", "8E", "7E", "7V", "6E", "5E", "4E", "3E"],
			stompingtantrum: ["9M", "9L18", "8M", "8L18"],
			stoneedge: ["9M", "9L33", "8M", "8L33", "7M", "7L50", "6M", "6L50", "5M", "5L50", "4M", "4L46"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "5S1", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thrash: ["9L36", "8L36", "7L28", "7V", "6L28", "5L28", "4L23", "3L29"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 20, moves: ["sandstorm", "dragondance", "bite", "outrage"], pokeball: "pokeball"},
			{generation: 5, level: 5, shiny: true, gender: "M", moves: ["bite", "leer", "sandstorm", "superpower"], pokeball: "cherishball"},
		],
	},
	pupitar: {
		learnset: {
			aerialace: ["9M"],
			ancientpower: ["4T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bite: ["9L9", "8L9", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L14", "6L14", "5L14"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L27", "8M", "8L27", "7L47", "7V", "6L47", "5L47", "4L41", "3L47"],
			curse: ["7V"],
			darkpulse: ["9M", "8M", "8L24", "7M", "7L34", "6M", "6L34", "5T", "5L34", "4M", "4L28"],
			detect: ["7V"],
			dig: ["9M", "8M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "8M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L33", "8M", "8L33", "7M", "7L54", "7V", "6M", "6L54", "5M", "5L54", "4M", "4L47", "3M", "3L56"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["8M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "9L52", "8M", "8L52", "7M", "7L67", "7V", "6M", "6L67", "5M", "5L67", "4M", "4L60", "3M", "3L65"],
			irondefense: ["9M", "9L0", "8M", "8L0", "7T", "6T", "5T", "4T"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T"],
			leer: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["3T"],
			muddywater: ["8M"],
			mudshot: ["9M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "7T", "6T", "5T"],
			payback: ["9L1", "8M", "8L1", "7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L34"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["9M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "9L15", "8M", "8L15", "7M", "7L19", "7V", "6M", "6L19", "5M", "5L19", "4M", "4L14", "3T", "3L22"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rockthrow: ["9L1", "8L1"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "9L47", "8M", "8L47", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			sandtomb: ["8M"],
			scaryface: ["9M", "9L12", "8M", "8L12", "7L23", "7V", "6L23", "5L23", "4L19", "3L38"],
			screech: ["9L21", "8M", "8L21", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["9L24", "7M", "6M", "5M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "9L18", "8M", "8L18"],
			stoneedge: ["9M", "9L37", "8M", "8L37", "7M", "7L60", "6M", "6L60", "5M", "5L60", "4M", "4L54"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thrash: ["9L42", "8L42", "7L28", "7V", "6L28", "5L28", "4L23", "3L29"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	tyranitar: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "8M", "4M"],
			bite: ["9L9", "8L9", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M", "3T"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L14", "6L14", "5L14"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["9M", "9L27", "8M", "8L27", "7L47", "7V", "6L47", "6S3", "6S4", "6S5", "6S6", "5L47", "5S1", "5S2", "4L41", "3L47", "3S0"],
			curse: ["7V"],
			cut: ["7V", "6M", "5M", "4M", "3M"],
			darkpulse: ["9M", "9L1", "8M", "8L24", "7M", "7L34", "6M", "6L34", "5T", "5L34", "4M", "4L28"],
			detect: ["7V"],
			dig: ["9M", "8M", "7V", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["7V"],
			dragonclaw: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "8M"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dragontail: ["9M", "7M", "6M", "5M"],
			dynamicpunch: ["7V", "3T"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L33", "8M", "8L33", "7M", "7L54", "7V", "6M", "6L54", "6S3", "6S4", "5M", "5L54", "5S2", "4M", "4L47", "3M", "3L61", "3S0"],
			endure: ["9M", "8M", "7V", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "7V", "6M", "5M", "5S1", "4M", "3M"],
			firefang: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			firepunch: ["9M", "8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			furycutter: ["7V", "4T", "3T"],
			gigaimpact: ["9M", "9L59", "8M", "8L59", "7M", "7L82", "6M", "6L82", "5M", "5L82", "4M"],
			headbutt: ["7V", "4T"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "9L52", "8M", "8L52", "7M", "7L73", "7V", "6M", "6L73", "5M", "5L73", "4M", "4L70", "3M", "3L75"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "5S1", "4M", "3M"],
			icefang: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			icepunch: ["9M", "8M", "7T", "6T", "6S3", "6S6", "5T", "4T"],
			icywind: ["9M"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "9L1", "8M", "8L1", "7T", "6T", "5T"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["9M", "8M", "7T", "6T", "6S5", "6S6", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			muddywater: ["8M"],
			mudshot: ["9M"],
			mudslap: ["9M", "7V", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["7V", "3T"],
			outrage: ["9M", "8M", "7T", "6T", "5T", "4T"],
			payback: ["9L1", "8M", "8L1", "7M", "7L41", "6M", "6L41", "5M", "5L41", "5S2", "4M", "4L34"],
			powergem: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "7V", "6M", "6S5", "5M", "4M", "3M"],
			raindance: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			revenge: ["8M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rockblast: ["9M", "8M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "9L15", "8M", "8L15", "7M", "7L19", "7V", "6M", "6L19", "6S4", "6S5", "6S6", "5M", "5L19", "4M", "4L14", "3T", "3L22"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			rockthrow: ["9L1", "8L1"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "9L47", "8M", "8L47", "7M", "7L1", "7V", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			sandtomb: ["8M"],
			scaryface: ["9M", "9L12", "8M", "8L12", "7L23", "7V", "6L23", "5L23", "4L19", "3L38", "3S0"],
			screech: ["9L21", "8M", "8L21", "7L1", "7V", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["5S2", "3T"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			smackdown: ["9L24", "7M", "6M", "5M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "9L18", "8M", "8L18", "7T"],
			stoneedge: ["9M", "9L37", "8M", "8L37", "7M", "7L63", "6M", "6L63", "6S3", "6S4", "5M", "5L63", "5S1", "4M", "4L54"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			surf: ["9M", "8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thrash: ["9L42", "8L42", "7L28", "7V", "6L28", "5L28", "4L23", "3L29", "3S0"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderfang: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["thrash", "scaryface", "crunch", "earthquake"], pokeball: "pokeball"},
			{generation: 5, level: 100, gender: "M", moves: ["fireblast", "icebeam", "stoneedge", "crunch"], pokeball: "cherishball"},
			{generation: 5, level: 55, gender: "M", isHidden: true, moves: ["payback", "crunch", "earthquake", "seismictoss"]},
			{generation: 6, level: 50, moves: ["stoneedge", "crunch", "earthquake", "icepunch"], pokeball: "cherishball"},
			{generation: 6, level: 50, nature: "Jolly", moves: ["rockslide", "earthquake", "crunch", "stoneedge"], pokeball: "cherishball"},
			{generation: 6, level: 55, shiny: true, nature: "Adamant", ivs: {hp: 31, atk: 31, def: 31, spa: 14, spd: 31, spe: 0}, moves: ["crunch", "rockslide", "lowkick", "protect"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["rockslide", "crunch", "icepunch", "lowkick"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 5, level: 50},
		],
	},
	lugia: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aeroblast: ["8L54", "7L43", "7V", "7S7", "7S8", "7S9", "7S10", "6L43", "6S5", "6S6", "5L43", "4L43", "4S2", "4S3", "3L77"],
			aircutter: ["4T"],
			airslash: ["8M"],
			ancientpower: ["8L1", "8S11", "7L57", "7V", "7S7", "7S9", "6L57", "5L57", "4T", "4L57", "4S3", "3L88"],
			aquatail: ["7T", "6T", "5T", "4T"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brine: ["8M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "8L27", "7M", "7L93", "6M", "6L93", "5M", "5L93", "4M", "4L93", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "7S8", "4M"],
			detect: ["7V"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["7V"],
			dragonpulse: ["8M", "8S11", "7T", "6T", "5T", "4M"],
			dragonrush: ["8L1", "7L15", "6L15", "6S6", "5L15", "4L15"],
			dragontail: ["7M", "6M", "5M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "7S10", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "7V", "6M", "5M", "4M", "3M", "3S1"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			extrasensory: ["8L36", "8S11", "7L23", "7S7", "7S9", "6L23", "5L23", "4L23", "4S2"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["3S1"],
			flash: ["6M", "5M", "4M"],
			fly: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L81", "7L79", "7V", "6L79", "5L79", "4L79", "3L99"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gust: ["8L1", "7L9", "7V", "6L9", "5L9", "4L9", "3L22"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["7V", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hurricane: ["8M", "7S8"],
			hydropump: ["8M", "8L72", "7L37", "7V", "6L37", "6S5", "6S6", "5L37", "4L29", "4S2", "3L44", "3S0", "3S1"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "6S6", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			imprison: ["8M"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			mist: ["8L9"],
			mudslap: ["7V", "4T", "3T"],
			naturalgift: ["7L85", "6L85", "5L85", "4M", "4L51"],
			nightmare: ["7V", "3T"],
			ominouswind: ["4T"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "7S10", "6M", "5M", "4M", "3M"],
			psychoboost: ["3S1"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			punishment: ["7L50", "6L50", "6S5", "5L50", "4L50", "4S3"],
			raindance: ["8M", "8L63", "7M", "7L29", "7V", "6M", "6L29", "6S5", "5M", "5L29", "4M", "4L29", "4S2", "3M", "3L55", "3S0"],
			recover: ["8L45", "7L71", "7V", "6L71", "5L71", "4L23", "3L33", "3S0"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L18", "7M", "7L65", "7V", "6M", "6L65", "5M", "5L65", "4M", "4L9", "4S3", "3M", "3L11"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "7S7", "7S9", "6T", "5T", "4M", "3M"],
			skyattack: ["8L90", "7T", "7L99", "6T", "6L99", "5T", "5L99", "4T", "4L99"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			surf: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "4L43", "3T", "3L66", "3S0"],
			tailwind: ["7T", "7S8", "7S10", "6T", "5T", "4T"],
			telekinesis: ["7T", "5M"],
			thunder: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			twister: ["4T"],
			waterfall: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			weatherball: ["8M", "8L1", "7L1", "6L1", "5L1", "5S4", "4L1"],
			whirlpool: ["8M", "8S11", "7V", "4M"],
			whirlwind: ["8L1", "7L1", "7V", "6L1", "5L1", "5S4", "4L1", "3L1"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 70, shiny: 1, moves: ["recover", "hydropump", "raindance", "swift"]},
			{generation: 3, level: 50, moves: ["psychoboost", "earthquake", "hydropump", "featherdance"]},
			{generation: 4, level: 45, shiny: 1, moves: ["extrasensory", "raindance", "hydropump", "aeroblast"]},
			{generation: 4, level: 70, shiny: 1, moves: ["aeroblast", "punishment", "ancientpower", "safeguard"]},
			{generation: 5, level: 5, isHidden: true, moves: ["whirlwind", "weatherball"], pokeball: "dreamball"},
			{generation: 6, level: 50, shiny: 1, moves: ["raindance", "hydropump", "aeroblast", "punishment"]},
			{generation: 6, level: 50, nature: "Timid", moves: ["aeroblast", "hydropump", "dragonrush", "icebeam"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["skillswap", "aeroblast", "extrasensory", "ancientpower"]},
			{generation: 7, level: 100, isHidden: true, moves: ["aeroblast", "hurricane", "defog", "tailwind"], pokeball: "cherishball"},
			{generation: 7, level: 60, moves: ["skillswap", "aeroblast", "extrasensory", "ancientpower"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["aeroblast", "earthpower", "psychic", "tailwind"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["dragonpulse", "extrasensory", "whirlpool", "ancientpower"]},
		],
		encounters: [
			{generation: 2, level: 40},
		],
		eventOnly: true,
	},
	hooh: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["4T"],
			airslash: ["8M"],
			ancientpower: ["8L1", "8S10", "7L57", "7V", "7S7", "7S8", "6L57", "5L57", "4T", "4L57", "4S2", "3L88"],
			bravebird: ["8M", "7L15", "7S6", "7S9", "6L15", "6S5", "5L15", "4L15"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			burnup: ["8L99", "7S7", "7S8"],
			calmmind: ["8M", "8L27", "7M", "7L93", "6M", "6L93", "5M", "5L93", "4M", "4L93", "3M"],
			celebrate: ["6S5"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			curse: ["7V"],
			defog: ["7T", "4M"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dragonbreath: ["7V"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "7V", "7S9", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			extrasensory: ["8L36", "8S10", "7L23", "7S7", "7S8", "6L23", "5L23", "4L23", "4S1"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "8L72", "7M", "7L37", "7V", "6M", "6L37", "6S4", "5M", "5L37", "4M", "4L29", "4S1", "3M", "3L44", "3S0"],
			firespin: ["8M"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flareblitz: ["8M", "8S10"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fly: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L81", "7L79", "7V", "6L79", "5L79", "4L79", "3L99"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gust: ["8L1", "7L9", "7V", "6L9", "5L9", "4L9", "3L22"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			incinerate: ["6M", "5M"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lifedew: ["8L9"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["7L85", "6L85", "5L85", "4M", "4L51"],
			nightmare: ["7V", "3T"],
			ominouswind: ["4T"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			pluck: ["5M", "4M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			punishment: ["7L50", "6L50", "6S4", "5L50", "4L50", "4S2"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["8L45", "7L71", "7V", "7S6", "6L71", "6S5", "5L71", "4L23", "3L33", "3S0"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7V", "6M", "5M", "4M", "3M"],
			rocksmash: ["7V", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sacredfire: ["8L54", "7L43", "7V", "7S6", "7S7", "7S8", "7S9", "6L43", "6S4", "6S5", "5L43", "4L43", "4S1", "4S2", "3L77"],
			safeguard: ["8M", "8L18", "7M", "7L65", "7V", "7S6", "6M", "6L65", "5M", "5L65", "4M", "4L9", "4S2", "3M", "3L11"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skyattack: ["8L90", "7T", "7L99", "6T", "6L99", "5T", "5L99", "4T", "4L99", "3T"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			steelwing: ["8M", "7M", "7V", "6M", "4M", "3M"],
			strength: ["7V", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "8L63", "8S10", "7M", "7L29", "7V", "6M", "6L29", "6S4", "5M", "5L29", "4M", "4L29", "4S1", "3M", "3L55", "3S0"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7V", "4T", "4L43", "3T", "3L66", "3S0"],
			tailwind: ["7T", "7S9", "6T", "5T", "4T"],
			thunder: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			weatherball: ["8M", "8L1", "7L1", "6L1", "5L1", "5S3", "4L1"],
			whirlwind: ["8L1", "7L1", "7V", "6L1", "5L1", "5S3", "4L1", "3L1"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
			zapcannon: ["7V"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 70, shiny: 1, moves: ["recover", "fireblast", "sunnyday", "swift"]},
			{generation: 4, level: 45, shiny: 1, moves: ["extrasensory", "sunnyday", "fireblast", "sacredfire"]},
			{generation: 4, level: 70, shiny: 1, moves: ["sacredfire", "punishment", "ancientpower", "safeguard"]},
			{generation: 5, level: 5, isHidden: true, moves: ["whirlwind", "weatherball"], pokeball: "dreamball"},
			{generation: 6, level: 50, shiny: 1, moves: ["sunnyday", "fireblast", "sacredfire", "punishment"]},
			{generation: 6, level: 50, shiny: true, moves: ["sacredfire", "bravebird", "recover", "celebrate"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["sacredfire", "bravebird", "recover", "safeguard"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["burnup", "sacredfire", "extrasensory", "ancientpower"]},
			{generation: 7, level: 60, moves: ["burnup", "sacredfire", "extrasensory", "ancientpower"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["sacredfire", "bravebird", "earthquake", "tailwind"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["flareblitz", "extrasensory", "sunnyday", "ancientpower"]},
		],
		encounters: [
			{generation: 2, level: 40},
		],
		eventOnly: true,
	},
	celebi: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			allyswitch: ["8M", "7T"],
			ancientpower: ["8L30", "7L28", "7V", "7S7", "6L28", "5L28", "4T", "4L28", "3L20", "3S1", "3S3"],
			aurasphere: ["8M"],
			batonpass: ["8M", "8L20", "7L37", "7V", "6L37", "5L37", "4L37", "3L40", "3S1"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "7V", "6L1", "6S6", "5L1", "4L1", "3L1", "3S0"],
			curse: ["7V"],
			cut: ["6M", "5M", "4M", "3M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defensecurl: ["7V", "3T"],
			detect: ["7V"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7V", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "7V", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "7V", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["7V", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "7V", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L70", "8S8", "7L64", "7V", "7S7", "6L64", "5L64", "4L64", "3L30", "3S1", "3S3"],
			gigadrain: ["8M", "7T", "7V", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			healbell: ["8L1", "8S8", "7T", "7L1", "7V", "7S7", "6T", "6L1", "6S5", "6S6", "5T", "5L1", "4T", "4L1", "3L1", "3S0", "3S2", "3S3"],
			healblock: ["7L55", "6L55", "5L55", "4L55"],
			healingwish: ["8L80", "7L73", "6L73", "5L73", "4L73", "4S4"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7V", "6M", "5M", "4M", "3M"],
			holdback: ["6S5"],
			hyperbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			imprison: ["8M"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leafblade: ["8M"],
			leafstorm: ["8M", "8L90", "7L82", "6L82", "5L82", "4L82", "4S4"],
			leechseed: ["8L50", "7L1", "7V", "6L1", "5L1", "4L1", "3L1", "3S2"],
			lifedew: ["8L40", "8S8"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["8M", "8L10", "8S8", "7L19", "6L19", "5L19", "4L19"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			metronome: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["7V", "4T", "3T"],
			nastyplot: ["8M", "4S4"],
			naturalgift: ["7L46", "6L46", "5L46", "4M", "4L46"],
			naturepower: ["7M", "6M"],
			nightmare: ["7V", "3T"],
			perishsong: ["8L100", "7L91", "7V", "6L91", "5L91", "4L91", "3L50", "3S1"],
			pollenpuff: ["8M"],
			protect: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			psychicterrain: ["8M"],
			psychocut: ["8M"],
			psychup: ["7M", "7V", "6M", "5M", "4M", "3T"],
			raindance: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			recover: ["8L60", "7L1", "7V", "6L1", "6S5", "6S6", "5L1", "4L1", "4S4", "3L1", "3S0", "3S2"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			return: ["7M", "7V", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7L10", "7V", "7S7", "6M", "6L10", "6S5", "6S6", "5M", "5L10", "4M", "4L10", "3M", "3L10", "3S0", "3S2", "3S3"],
			sandstorm: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			shadowball: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "7V", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "7V", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			solarblade: ["8M"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "7V", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "7V", "6M", "5M", "4M", "3T"],
			sweetscent: ["7V"],
			swift: ["8M", "7V", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			telekinesis: ["7T"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "7V", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["8M", "7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			weatherball: ["8M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			worryseed: ["7T", "6T", "5T", "4T"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 10, moves: ["confusion", "recover", "healbell", "safeguard"], pokeball: "pokeball"},
			{generation: 3, level: 70, moves: ["ancientpower", "futuresight", "batonpass", "perishsong"], pokeball: "pokeball"},
			{generation: 3, level: 10, moves: ["leechseed", "recover", "healbell", "safeguard"], pokeball: "pokeball"},
			{generation: 3, level: 30, moves: ["healbell", "safeguard", "ancientpower", "futuresight"], pokeball: "pokeball"},
			{generation: 4, level: 50, moves: ["leafstorm", "recover", "nastyplot", "healingwish"], pokeball: "cherishball"},
			{generation: 6, level: 10, moves: ["recover", "healbell", "safeguard", "holdback"], pokeball: "luxuryball"},
			{generation: 6, level: 100, moves: ["confusion", "recover", "healbell", "safeguard"], pokeball: "cherishball"},
			{generation: 7, level: 30, moves: ["healbell", "safeguard", "ancientpower", "futuresight"], pokeball: "cherishball"},
			{generation: 8, level: 60, shiny: true, nature: "Quirky", moves: ["magicalleaf", "futuresight", "lifedew", "healbell"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 2, level: 30},
		],
		eventOnly: true,
	},
	treecko: {
		learnset: {
			absorb: ["8E", "7L5", "6L5", "5L6", "5S1", "4L6", "3L6", "3S0"],
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "7L25", "6L25", "5L31", "4L31", "3L31"],
			assurance: ["8M", "8L18"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulletseed: ["8M", "7E", "6E", "5E", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["8M", "7E", "6E", "5E", "4E", "3E"],
			crushclaw: ["7E", "6E", "5E", "4E", "3E"],
			cut: ["6M", "5M", "4M", "3M"],
			detect: ["8L12", "7L33", "6L33", "5L41", "4L41", "3L41"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doublekick: ["8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["8L27", "7M", "6M", "5M", "4M", "3M"],
			dragonbreath: ["8E", "7E", "6E", "5E", "4E", "3E"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["3T"],
			endeavor: ["8L36", "7T", "7L45", "7E", "6T", "6L45", "6E", "5T", "5E", "4T", "4E", "3E"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "8L30", "7M", "7L37", "6M", "6L37", "5M", "5L51", "4M", "4L51"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["8M", "8L21", "7T", "7L21", "6T", "6L21", "5T", "5L46", "4M", "4L46", "3M", "3L46"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasspledge: ["8T", "7T", "6T", "5T"],
			grasswhistle: ["7E", "6E", "5E", "4E"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7E", "6E"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			leafage: ["8L3"],
			leafstorm: ["8M", "8L39", "7E", "6E", "5E", "4E"],
			leechseed: ["8E", "7E", "6E", "5E", "4E", "3E"],
			leer: ["8L1", "7L1", "6L1", "5L1", "5S1", "4L1", "3L1", "3S0"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			magicalleaf: ["8M", "7E", "6E", "5E", "4E"],
			megadrain: ["8L9", "7L13", "6L13", "5L26", "4L26", "3L26"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			naturepower: ["7M", "6M"],
			nightslash: ["8E"],
			pound: ["8L1", "7L1", "6L1", "5L1", "5S1", "4L1", "3L1", "3S0"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7L17", "6L16", "5L16", "4L16", "3L16"],
			quickattack: ["8L6", "7L9", "6L9", "5L11", "4L11", "3L11"],
			quickguard: ["8L15", "7L41", "6L41"],
			razorwind: ["7E", "6E", "5E", "4E"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			screech: ["8M", "8L33", "7L49", "6L21", "5L21", "4L21", "3L21"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			slam: ["8L24", "7L29", "6L29", "5L36", "4L36", "3L36"],
			slash: ["8E"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			workup: ["8M", "7M"],
			worryseed: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["pound", "leer", "absorb"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["pound", "leer", "absorb"]},
		],
	},
	grovyle: {
		learnset: {
			absorb: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "7L28", "6L28", "5L35", "4L35", "3L35"],
			assurance: ["8M", "8L20"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["8M"],
			cut: ["6M", "5M", "4M", "3M"],
			detect: ["8L12", "7L38", "6L38", "5L47", "4L47", "3L47"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["8L35", "7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["3T"],
			endeavor: ["8L50", "7T", "6T", "5T", "4T"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "8L1", "7M", "7L48", "6M", "6L48", "5M", "5L53", "4M", "4L53", "3L53"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["8L1", "7L1", "6L16", "5L16", "4T", "4L16", "3T", "3L16"],
			gigadrain: ["8M", "8L25", "7T", "6T", "5T", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasspledge: ["8T", "7T", "6T", "5T"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			leafage: ["8L1"],
			leafblade: ["8M", "8L40", "7L23", "6L23", "5L29", "4L29", "3L29"],
			leafstorm: ["8M", "8L55", "7L58", "6L58", "5L59", "4L59"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			magicalleaf: ["8M"],
			megadrain: ["8L9", "7L13", "6L13"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			pound: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7L18", "6L17", "5L17", "4L17", "3L17"],
			quickattack: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			quickguard: ["8L15", "7L53", "6L53"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			screech: ["8M", "8L45", "7L63", "6L23", "5L23", "4L23", "3L23"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			slam: ["8L30", "7L33", "6L33", "5L41", "4L41", "3L41"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			solarblade: ["8M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			workup: ["8M", "7M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			xscissor: ["8M", "8L1", "7M", "7L43", "6M", "6L43", "5M", "4M"],
		},
	},
	sceptile: {
		learnset: {
			absorb: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "7L28", "6L28", "5L35", "4L35", "3L35"],
			assurance: ["8M", "8L20"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			breakingswipe: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crosspoison: ["8M"],
			crunch: ["8M"],
			cut: ["6M", "5M", "4M", "3M"],
			detect: ["8L12", "7L39", "6L39", "5L51", "4L51", "3L51"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["8L35", "7M", "6M", "5M", "4M", "3M"],
			dragonclaw: ["8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T", "6T", "5T", "5S0", "4M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dualchop: ["8L0", "7T", "7L1", "6T", "6L36"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endeavor: ["8L56", "7T", "6T", "5T", "4T"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "8L1", "7M", "7L51", "6M", "6L51", "5M", "5L59", "4M", "4L59", "3L59"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "5S0", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frenzyplant: ["8T", "7T", "6T", "5T", "4T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["8L1", "7L1", "6L16", "4T", "3T", "3L16"],
			gigadrain: ["8M", "8L25", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasspledge: ["8T", "7T", "6T", "5T"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			leafage: ["8L1"],
			leafblade: ["8M", "8L42", "7L23", "6L23", "5L29", "4L29", "3L29"],
			leafstorm: ["8M", "8L63", "7L1", "6L1", "5L67", "5S0", "4L67"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			magicalleaf: ["8M"],
			megadrain: ["8L5", "7L13", "6L13"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			nightslash: ["7L1", "6L1", "5L1", "4L1"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			pound: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7L18", "6L17", "5L17", "4L17", "3L17"],
			quickattack: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			quickguard: ["8L15", "7L57", "6L57"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "5M", "5S0", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scaleshot: ["8T"],
			screech: ["8M", "8L49", "7L69", "6L23", "5L23", "4L23", "3L23"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			slam: ["8L30", "7L33", "6L33", "5L43", "4L43", "3L43"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			solarblade: ["8M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			workup: ["8M", "7M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			xscissor: ["8M", "8L1", "7M", "7L45", "6M", "6L16", "5M", "5L16", "4M", "4L16"],
		},
		eventData: [
			{generation: 5, level: 50, shiny: 1, moves: ["leafstorm", "dragonpulse", "focusblast", "rockslide"], pokeball: "cherishball"},
		],
	},
	torchic: {
		learnset: {
			aerialace: ["8L18", "7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "7E", "6E", "5E", "4E"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "7E", "6E", "5E", "4E"],
			bodyslam: ["8M", "3T"],
			bounce: ["8M", "8L24", "7T", "6T", "5T", "4T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E", "4E", "3T", "3E"],
			crushclaw: ["8E", "7E", "6E", "5E", "4E"],
			curse: ["8E", "7E", "6E", "5E"],
			cut: ["6M", "5M", "4M", "3M"],
			defog: ["7T"],
			detect: ["8L12"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["8L3", "7L5", "6L5", "6S2", "5L10", "5S1", "5S2", "4L10", "3L10", "3S0"],
			endure: ["8M", "7E", "6E", "5E", "4M", "4E", "3T", "3E"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["8L33", "7E", "6E", "5E", "4E"],
			feint: ["8E", "7E", "6E", "5E", "4E"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepledge: ["8T", "7T", "6T", "5T"],
			firespin: ["8M", "7L19", "6L19", "5L25", "4L25", "3L25"],
			flameburst: ["7L28", "7E", "6L28", "6E", "5E"],
			flamecharge: ["8L9", "7M", "6M", "5M"],
			flamethrower: ["8M", "8L30", "7M", "7L46", "6M", "6L43", "5M", "5L43", "4M", "4L43", "3M", "3L43"],
			flareblitz: ["8M", "8L39"],
			focusenergy: ["8M", "8L27", "7L32", "6L7", "6S2", "5L7", "5S1", "5S2", "4L7", "3L7", "3S0"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["8L1", "7L1", "6L1", "6S2", "5L1", "5S1", "5S2", "4L1", "3L1", "3S0"],
			headbutt: ["4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			lastresort: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			lowkick: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mirrormove: ["7L41", "6L37", "5L37", "4L37", "3L37"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["8E", "7E", "6E", "5E", "4E"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			peck: ["8E", "7L14", "6L14", "5L16", "4L16", "3L16"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			quickattack: ["8L6", "7L23", "6L23", "5L28", "4L28", "3L28"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["8M", "8L36", "7E", "6E", "5E", "4E", "3E"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L15", "7L10", "6L10", "5L19", "4L19", "3L19"],
			scratch: ["8L1", "7L1", "6L1", "6S2", "5L1", "5S1", "5S2", "4L1", "3L1", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			slash: ["8L21", "7L37", "6L34", "5L34", "4L34", "3L34"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smellingsalts: ["7E", "6E", "5E", "4E", "3E"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			swift: ["4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["scratch", "growl", "focusenergy", "ember"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["scratch", "growl", "focusenergy", "ember"]},
			{generation: 6, level: 10, gender: "M", isHidden: true, moves: ["scratch", "growl", "focusenergy", "ember"], pokeball: "cherishball"},
		],
	},
	combusken: {
		learnset: {
			aerialace: ["8L20", "7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			batonpass: ["8M"],
			blazekick: ["8M", "8L40"],
			bodyslam: ["8M", "3T"],
			bounce: ["8M", "8L30", "7T", "6T", "5T", "4T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["8M", "8L45", "7M", "7L31", "6M", "6L28", "5M", "5L28", "4M", "4L28", "3M", "3L28"],
			captivate: ["4M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			cut: ["6M", "5M", "4M", "3M"],
			defog: ["7T"],
			detect: ["8L12"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doublekick: ["8L0", "7L1", "6L16", "5L16", "4L16", "3L16"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["3T"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["8L1"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepledge: ["8T", "7T", "6T", "5T"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			firespin: ["8M"],
			flamecharge: ["8L9", "7M", "7L20", "6M", "6L20", "5M"],
			flamethrower: ["8M", "8L1", "7M", "6M", "5M", "4M", "3M"],
			flareblitz: ["8M", "8L55", "7L58", "6L54", "5L54", "4L54"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L35", "7L36", "6L1", "5L1", "4L1", "3L1"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mirrormove: ["7L47", "6L43", "5L43", "4L43", "3L43"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			peck: ["7L14", "6L14", "5L17", "4L17", "3L17"],
			poisonjab: ["8M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			quickattack: ["8L1", "7L25", "6L25", "5L32", "4L32", "3L32"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["8M"],
			reversal: ["8M", "8L50"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L15", "7L1", "6L1", "5L21", "4L21", "3L21"],
			scratch: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			skyuppercut: ["7L53", "6L50", "5L50", "4L50", "3L50"],
			slash: ["8L25", "7L42", "6L39", "5L39", "4L39", "3L39"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			vacuumwave: ["4T"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
			workup: ["8M", "7M", "5M"],
		},
	},
	blaziken: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["8L20", "7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			aurasphere: ["8M"],
			batonpass: ["8M"],
			blastburn: ["8T", "7T", "6T", "5T", "4T"],
			blazekick: ["8M", "8L42", "7L1", "6L36", "5L36", "4L36", "3L36", "3S0"],
			bodyslam: ["8M", "3T"],
			bounce: ["8M", "8L30", "7T", "6T", "5T", "4T"],
			bravebird: ["8M", "8L1", "7L50", "6L49", "5L49", "4L49"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["8M", "8L49", "7M", "7L31", "6M", "6L28", "5M", "5L28", "4M", "4L28", "3M", "3L28"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			cut: ["6M", "5M", "4M", "3M"],
			defog: ["7T"],
			detect: ["8L12"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doublekick: ["8L1", "7L1", "6L16", "5L16", "4L16", "3L16"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			featherdance: ["8L1"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepledge: ["8T", "7T", "6T", "5T"],
			firepunch: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T", "3L1"],
			firespin: ["8M"],
			flamecharge: ["8L9", "7M", "7L20", "6M", "6L20", "5M"],
			flamethrower: ["8M", "8L1", "7M", "6M", "5M", "4M", "3M"],
			flareblitz: ["8M", "8L63", "7L1", "6L1", "5L66", "5S1", "4L66"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M", "8L35", "7L37", "6L1", "5L1", "4L1", "3L1"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["4T"],
			heatcrash: ["8M"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			highjumpkick: ["7L1", "6L1", "5L1", "5S1"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mirrormove: ["3L49", "3S0"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			peck: ["7L14", "6L14", "5L17", "4L17", "3L17"],
			poisonjab: ["8M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			quickattack: ["8L1", "7L25", "6L25", "5L32", "4L32", "3L32"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["8M"],
			reversal: ["8M", "8L56"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L15", "7L1", "6L1", "5L21", "4L21", "3L21"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			skyuppercut: ["7L57", "6L57", "5L59", "4L59", "3L59", "3S0"],
			slash: ["8L25", "7L44", "6L42", "5L42", "4L42", "3L42", "3S0"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			stoneedge: ["8M", "7M", "6M", "5M", "5S1", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			thunderpunch: ["8M", "7T", "6T", "5T", "5S1", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uturn: ["8M"],
			vacuumwave: ["4T"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 70, moves: ["blazekick", "slash", "mirrormove", "skyuppercut"], pokeball: "pokeball"},
			{generation: 5, level: 50, shiny: 1, moves: ["flareblitz", "highjumpkick", "thunderpunch", "stoneedge"], pokeball: "cherishball"},
		],
	},
	mudkip: {
		learnset: {
			amnesia: ["8M", "8L27"],
			ancientpower: ["8E", "7E", "6E", "5E", "4T", "4E"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "7E", "6E", "5E"],
			barrier: ["7E", "6E"],
			bide: ["7L17", "6L15", "5L15", "4L15", "3L15"],
			bite: ["8E", "7E", "6E", "5E", "4E"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E", "4E"],
			curse: ["8E", "7E", "6E", "5E", "4E", "3E"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["8E", "7E", "6E", "5E", "4E", "3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["8L36", "7T", "7L44", "6T", "6L44", "5T", "5L46", "4T", "4L46", "3L46"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			foresight: ["7L12", "6L12", "5L19", "4L19", "3L19"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "5S1", "4L1", "3L1", "3S0"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L39", "7L41", "6L41", "5L42", "4L42", "3L42"],
			iceball: ["7E", "6E", "5E", "4E", "3E"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mirrorcoat: ["8E", "7E", "6E", "5E", "4E", "3E"],
			mudbomb: ["7E", "6E", "5E", "4E"],
			mudslap: ["8E", "7L9", "6L6", "5L6", "5S1", "4T", "4L6", "3T", "3L6", "3S0"],
			mudsport: ["7L20", "6L20", "5L24", "4L24", "3L24"],
			naturalgift: ["4M"],
			protect: ["8M", "8L12", "7M", "7L28", "6M", "6L28", "5M", "5L37", "4M", "4L37", "3M", "3L37"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "8L21", "7M", "6M", "5M", "4M"],
			rocksmash: ["8L6", "6M", "5M", "4M", "3M"],
			rockthrow: ["8L9", "7L25", "6L25"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L33"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludge: ["8E", "7E", "6E", "5E", "4E"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stomp: ["8E", "7E", "6E", "5E", "4E", "3E"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			supersonic: ["8L15"],
			surf: ["8M", "8L30", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "5S1", "4L1", "3L1", "3S0"],
			takedown: ["8L24", "7L36", "6L28", "5L28", "4L28", "3L28"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "7E", "6T", "6E", "5E", "4E", "3E"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L3", "7L4", "6L4", "5L10", "5S1", "4L10", "3L10", "3S0"],
			waterpledge: ["8T", "7T", "6T", "5T"],
			waterpulse: ["8L18", "7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "7L33", "7E", "6L33", "6E", "5L33", "5E", "4M", "4L33", "4E", "3L33"],
			wideguard: ["8E", "7E", "6E", "5E"],
			workup: ["8M", "7M"],
			yawn: ["8E", "7E", "6E", "5E", "4E"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["tackle", "growl", "mudslap", "watergun"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tackle", "growl", "mudslap", "watergun"]},
		],
	},
	marshtomp: {
		learnset: {
			amnesia: ["8M", "8L35"],
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["8M"],
			bide: ["7L18", "6L15", "5L15", "4L15", "3L15"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L1", "7M", "7L48", "6M", "6L46", "5M", "5L46", "4M", "4L46", "3M", "3L46"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["8L50", "7T", "7L52", "6T", "6L52", "5T", "5L53", "4T", "4L53", "3L53"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			foresight: ["7L12", "6L12", "5L20", "4L20", "3L20"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L55"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudbomb: ["7L22", "6L22", "5L25", "4L25"],
			muddywater: ["8M", "8L40", "7L38", "6L37", "5L37", "4L37", "3L37"],
			mudshot: ["8M", "8L0", "7L1", "6L16", "5L16", "4L16", "3L16"],
			mudslap: ["7L1", "6L1", "5L1", "4T", "4L1", "3T", "3L1"],
			mudsport: ["3L25"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L12", "7M", "7L32", "6M", "6L32", "5M", "5L42", "4M", "4L42", "3M", "3L42"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "8L25", "7M", "7L28", "6M", "6L28", "5M", "4M", "3T"],
			rocksmash: ["8L1", "6M", "5M", "4M", "3M"],
			rockthrow: ["8L9"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandtomb: ["8M"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L45"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			supersonic: ["8L15"],
			surf: ["8M", "8L1", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L30", "7L42", "6L31", "5L31", "4L31", "3L31"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpledge: ["8T", "7T", "6T", "5T"],
			waterpulse: ["8L20", "7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			workup: ["8M", "7M"],
		},
	},
	swampert: {
		learnset: {
			amnesia: ["8M", "8L35"],
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			bide: ["7L18", "6L15", "5L15", "4L15", "3L15"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			darkestlariat: ["8M"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "8L1", "7M", "7L51", "6M", "6L51", "5M", "5L52", "5S0", "4M", "4L52", "3M", "3L52"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["8L56", "7T", "7L56", "6T", "6L56", "5T", "5L61", "4T", "4L61", "3L61"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			flipturn: ["8T"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7L12", "6L12", "5L20", "4L20", "3L20"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hammerarm: ["8L1", "7L1", "6L1", "5L69", "5S0", "4L69"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			hydrocannon: ["8T", "7T", "6T", "5T", "4T"],
			hydropump: ["8M", "8L63", "5S0"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "5S0", "4M", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			liquidation: ["8M"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudbomb: ["7L22", "6L22", "5L25", "4L25"],
			muddywater: ["8M", "8L42", "7L39", "6L39", "5L39", "4L39", "3L39"],
			mudshot: ["8M", "8L1", "7L1", "6L16", "5L16", "4L16", "3L16"],
			mudslap: ["7L1", "6L1", "5L1", "4T", "4L1", "3T", "3L1"],
			mudsport: ["3L25"],
			naturalgift: ["4M"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L12", "7M", "7L32", "6M", "6L32", "5M", "5L46", "4M", "4L46", "3M", "3L46"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "8L25", "7M", "7L28", "6M", "6L28", "5M", "4M", "3T"],
			rocksmash: ["8L1", "6M", "5M", "4M", "3M"],
			rockthrow: ["8L9"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandtomb: ["8M"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L49"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			supersonic: ["8L15"],
			surf: ["8M", "8L1", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L30", "7L44", "6L31", "5L31", "4L31", "3L31"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpledge: ["8T", "7T", "6T", "5T"],
			waterpulse: ["8L20", "7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 5, level: 50, shiny: 1, moves: ["earthquake", "icebeam", "hydropump", "hammerarm"], pokeball: "cherishball"},
		],
	},
	poochyena: {
		learnset: {
			assurance: ["7L22", "6L22", "5L29", "4L29"],
			astonish: ["7E", "6E", "5E", "4E", "3E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bite: ["7L10", "6L10", "5L13", "4L13", "3L13"],
			bodyslam: ["3T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "7E", "6T", "6E", "5T", "5E", "4E", "3E"],
			crunch: ["7L34", "6L37", "5L53", "4L53", "3L41"],
			darkpulse: ["7M", "6M", "5T", "5D", "4M"],
			dig: ["6M", "5M", "4M", "3M", "3S0"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			embargo: ["7M", "7L28", "6M", "6L28", "5M", "5L41", "4M", "4L41"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			firefang: ["7E", "6E", "5E", "4E"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			healbell: ["3S0"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			howl: ["7L4", "6L4", "5L5", "5D", "4L5", "3L5", "3S0"],
			hypervoice: ["7T", "6T", "5T"],
			icefang: ["7E", "6E", "5E", "4E"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			leer: ["7E", "6E", "5E", "4E", "3E"],
			mefirst: ["7E", "6E", "5E", "4E"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			odorsleuth: ["7L13", "6L13", "5L17", "4L17", "3L17"],
			payback: ["7M", "6M", "5M", "4M"],
			playrough: ["7L46", "7E", "6E"],
			poisonfang: ["7E", "6E", "5E", "5D", "4E", "3E", "3S0"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychup: ["3T"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7L16", "6M", "6L16", "5M", "5L21", "4M", "4L21", "3M", "3L21"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7L7", "6L7", "5L9", "4L9", "3L9"],
			scaryface: ["7L25", "6L25", "5L33", "4L33", "3L29"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			sleeptalk: ["7M", "7E", "6M", "6E", "5T", "5E", "4M", "3T"],
			snarl: ["7M", "6M", "5M"],
			snatch: ["7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["7L43", "7E", "6L40", "6E", "5L49", "5E", "4T", "4L49", "4E"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7L19", "6M", "6L19", "5M", "5L25", "4M", "4L25", "3T", "3L25"],
			tackle: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7L40", "6L34", "5L45", "4L45", "3L33"],
			taunt: ["7M", "7L31", "6M", "6L31", "5M", "5L37", "4M", "4L37", "3M", "3L37"],
			thief: ["7M", "6M", "5M", "4M", "3M", "3L45"],
			thunderfang: ["7E", "6E", "5E", "4E"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			yawn: ["7L37", "7E", "6E", "5E", "4E", "3E"],
		},
		eventData: [
			{generation: 3, level: 10, abilities: ["runaway"], moves: ["healbell", "dig", "poisonfang", "howl"]},
		],
		encounters: [
			{generation: 3, level: 2},
		],
	},
	mightyena: {
		learnset: {
			assurance: ["7L24", "6L24", "5L32", "4L32"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bite: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["3T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			crunch: ["7L1", "7S0", "6L1", "3L47"],
			darkpulse: ["7M", "6M", "5T", "4M"],
			dig: ["6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			embargo: ["7M", "7L32", "6M", "6L32", "5M", "5L47", "4M", "4L47"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			firefang: ["7L1", "7S0"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			howl: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			hyperbeam: ["7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["7T", "6T", "5T"],
			icefang: ["7L1", "7S0"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			odorsleuth: ["7L13", "6L13", "5L17", "4L17", "3L17"],
			payback: ["7M", "6M", "5M", "4M"],
			playrough: ["7L56"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychup: ["3T"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "7L16", "6M", "6L16", "5M", "5L22", "4M", "4L22", "3M", "3L22"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			scaryface: ["7L28", "6L28", "5L37", "4L37", "3L32"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snarl: ["7M", "7L1", "6M", "6L18", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["7L52", "6L48", "5L62", "4T", "4L62"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7L20", "6M", "6L20", "5M", "5L27", "4M", "4L27", "3T", "3L27"],
			tackle: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["7L48", "6L40", "5L52", "4L52", "3L37"],
			taunt: ["7M", "7L36", "6M", "6L36", "5M", "5L42", "4M", "4L42", "3M", "3L42"],
			thief: ["7M", "7L1", "6M", "6L1", "5M", "5L57", "4M", "4L57", "3M", "3L52"],
			throatchop: ["7T"],
			thunderfang: ["7L1", "7S0"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			yawn: ["7L44"],
		},
		eventData: [
			{generation: 7, level: 64, gender: "M", abilities: ["intimidate"], moves: ["crunch", "firefang", "icefang", "thunderfang"], pokeball: "cherishball"},
		],
	},
	zigzagoon: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			babydolleyes: ["8L15", "7L12", "6L11"],
			bellydrum: ["8L33", "7L37", "6L37", "5L45", "4L41", "3L41"],
			bestow: ["7L25", "6L25", "5L33"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M", "7E", "6E", "5E", "4E", "3E"],
			confide: ["7M", "6M"],
			covet: ["8L9", "7T", "7L23", "6T", "6L23", "5T", "5L29", "4L29", "3L29"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L36", "3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "4M", "3T"],
			extremespeed: ["8E", "7E", "3S1"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L30", "7L29", "6L29", "5L37", "4L33", "3L33"],
			fling: ["8M", "8L27", "7M", "7L41", "6M", "6L41", "5M", "5L49", "4M", "4L45"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
			gunkshot: ["8M", "7T", "6T", "5T", "4T"],
			headbutt: ["8L12", "7L11", "6L9", "5L9", "4T", "4L9", "3L9"],
			helpinghand: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "6T", "5T", "5D", "4T"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["7E", "6E", "5E", "4T", "4E", "3T"],
			mudsport: ["7L17", "6L17", "5L21", "4L21", "3L21"],
			naturalgift: ["4M"],
			odorsleuth: ["7L13", "6L13", "5L17", "4L17", "3L17"],
			pinmissile: ["8M", "8L18", "7L19", "6L19", "5L25", "4L25", "3L25"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "6E", "5E", "4E", "3E"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L21", "7M", "7L35", "6M", "6L35", "5M", "5L41", "4M", "4L37", "3M", "3L37"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["7E", "6E", "5E"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L3", "7L7", "6L7", "5L13", "4L13", "3L13"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			simplebeam: ["8E", "7E", "6E", "5E"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L1", "3S0", "3S1"],
			tailslap: ["8M"],
			tailwhip: ["8L6", "7L5", "6L5", "5L5", "4L5", "3L5", "3S0", "3S1"],
			takedown: ["8L24", "7L31", "6L31"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			tickle: ["8E", "7E", "6E", "5E", "4E", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: true, abilities: ["pickup"], moves: ["tackle", "growl", "tailwhip"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, abilities: ["pickup"], moves: ["tackle", "growl", "tailwhip", "extremespeed"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 3, level: 2},
		],
	},
	zigzagoongalar: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M"],
			babydolleyes: ["8L15"],
			blizzard: ["8M"],
			bodyslam: ["8M"],
			counter: ["8L30"],
			dig: ["8M"],
			doubleedge: ["8L36"],
			endure: ["8M"],
			facade: ["8M"],
			faketears: ["8M"],
			fling: ["8M"],
			grassknot: ["8M"],
			gunkshot: ["8M"],
			headbutt: ["8L12"],
			helpinghand: ["8M"],
			hypervoice: ["8M"],
			icebeam: ["8M"],
			icywind: ["8M"],
			irontail: ["8M"],
			knockoff: ["8E"],
			lashout: ["8T"],
			leer: ["8L1"],
			lick: ["8L6"],
			mudshot: ["8M"],
			partingshot: ["8E"],
			payback: ["8M"],
			pinmissile: ["8M", "8L18"],
			protect: ["8M"],
			quickguard: ["8E"],
			raindance: ["8M"],
			rest: ["8M", "8L21"],
			retaliate: ["8M"],
			round: ["8M"],
			sandattack: ["8L3"],
			scaryface: ["8M", "8L27"],
			screech: ["8M"],
			seedbomb: ["8M"],
			shadowball: ["8M"],
			sleeptalk: ["8M"],
			snarl: ["8M", "8L9"],
			snore: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			surf: ["8M"],
			swift: ["8M"],
			tackle: ["8L1"],
			takedown: ["8L24"],
			taunt: ["8M", "8L33"],
			thief: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderwave: ["8M"],
			trick: ["8M"],
			whirlpool: ["8M"],
			workup: ["8M"],
		},
	},
	linoone: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			babydolleyes: ["8L1", "6S0"],
			bellydrum: ["8L43", "7L43", "6L43", "5L59", "4L53", "3L53"],
			bestow: ["7L27", "6L27", "5L41"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			covet: ["8L9", "7T", "7L24", "6T", "6L24", "5T", "5L35", "4L35", "3L35"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L48", "7L35", "6L35", "3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "4M", "3T"],
			extremespeed: ["6S0"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L38"],
			fling: ["8M", "8L33", "7M", "7L48", "6M", "6L48", "5M", "5L65", "4M", "4L59"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			furyswipes: ["8L18", "7L19", "6L19", "5L29", "4L29", "3L29"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			gunkshot: ["8M", "7T", "6T", "5T", "4T"],
			headbutt: ["8L12", "7L11", "6L1", "5L1", "4T", "4L1", "3L1"],
			helpinghand: ["8M", "7T", "6T", "6S0", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["8L15", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			mudsport: ["7L17", "6L17", "5L23", "4L23", "3L23"],
			naturalgift: ["4M"],
			odorsleuth: ["7L13", "6L13", "5L17", "4L17", "3L17"],
			pinmissile: ["8M", "8L1"],
			playrough: ["8M", "7L1", "6L1"],
			protect: ["8M", "7M", "6M", "6S0", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L23", "7M", "7L40", "6M", "6L40", "5M", "5L53", "4M", "4L47", "3M", "3L47"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			rototiller: ["7L1", "6L1"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L13", "4L13", "3L13"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slash: ["8L0", "7L32", "6L32", "5L47", "4L41", "3L41"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			switcheroo: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			tailslap: ["8M"],
			tailwhip: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L28"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 6, level: 50, moves: ["extremespeed", "helpinghand", "babydolleyes", "protect"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 4, level: 3},
			{generation: 6, level: 17, maxEggMoves: 1},
		],
	},
	linoonegalar: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M"],
			babydolleyes: ["8L1"],
			blizzard: ["8M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			counter: ["8L38"],
			dig: ["8M"],
			doubleedge: ["8L48"],
			endure: ["8M"],
			facade: ["8M"],
			faketears: ["8M"],
			fling: ["8M"],
			furyswipes: ["8L18"],
			gigaimpact: ["8M"],
			grassknot: ["8M"],
			gunkshot: ["8M"],
			headbutt: ["8L12"],
			helpinghand: ["8M"],
			honeclaws: ["8L15"],
			hyperbeam: ["8M"],
			hypervoice: ["8M"],
			icebeam: ["8M"],
			icywind: ["8M"],
			irontail: ["8M"],
			lashout: ["8T"],
			leer: ["8L1"],
			lick: ["8L1"],
			mudshot: ["8M"],
			nightslash: ["8L0"],
			payback: ["8M"],
			pinmissile: ["8M", "8L1"],
			protect: ["8M"],
			raindance: ["8M"],
			rest: ["8M", "8L23"],
			retaliate: ["8M"],
			round: ["8M"],
			sandattack: ["8L1"],
			scaryface: ["8M", "8L33"],
			screech: ["8M"],
			seedbomb: ["8M"],
			shadowball: ["8M"],
			shadowclaw: ["8M"],
			sleeptalk: ["8M"],
			snarl: ["8M", "8L9"],
			snore: ["8M"],
			stompingtantrum: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			surf: ["8M"],
			swift: ["8M"],
			switcheroo: ["8L1"],
			tackle: ["8L1"],
			takedown: ["8L28"],
			taunt: ["8M", "8L43"],
			thief: ["8M"],
			throatchop: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderwave: ["8M"],
			trick: ["8M"],
			whirlpool: ["8M"],
			workup: ["8M"],
		},
	},
	obstagoon: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M"],
			babydolleyes: ["8L1"],
			blizzard: ["8M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M"],
			bulkup: ["8M"],
			closecombat: ["8M"],
			counter: ["8L42"],
			crosschop: ["8L1"],
			crosspoison: ["8M"],
			dig: ["8M"],
			doubleedge: ["8L56"],
			endure: ["8M"],
			facade: ["8M"],
			faketears: ["8M"],
			firepunch: ["8M"],
			fling: ["8M"],
			focusenergy: ["8M"],
			furyswipes: ["8L18"],
			gigaimpact: ["8M"],
			grassknot: ["8M"],
			gunkshot: ["8M"],
			headbutt: ["8L12"],
			helpinghand: ["8M"],
			honeclaws: ["8L15"],
			hyperbeam: ["8M"],
			hypervoice: ["8M"],
			icebeam: ["8M"],
			icepunch: ["8M"],
			icywind: ["8M"],
			irondefense: ["8M"],
			irontail: ["8M"],
			lashout: ["8T"],
			leer: ["8L1"],
			lick: ["8L1"],
			lowkick: ["8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudshot: ["8M"],
			nightslash: ["8L1"],
			obstruct: ["8L0"],
			payback: ["8M"],
			pinmissile: ["8M", "8L1"],
			protect: ["8M"],
			raindance: ["8M"],
			rest: ["8M", "8L23"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["8M"],
			round: ["8M"],
			sandattack: ["8L1"],
			scaryface: ["8M", "8L35"],
			screech: ["8M"],
			seedbomb: ["8M"],
			shadowball: ["8M"],
			shadowclaw: ["8M"],
			sleeptalk: ["8M"],
			snarl: ["8M", "8L9"],
			snore: ["8M"],
			stompingtantrum: ["8M"],
			submission: ["8L1"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			surf: ["8M"],
			swift: ["8M"],
			switcheroo: ["8L1"],
			tackle: ["8L1"],
			takedown: ["8L28"],
			taunt: ["8M", "8L49"],
			thief: ["8M"],
			throatchop: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderpunch: ["8M"],
			thunderwave: ["8M"],
			trick: ["8M"],
			whirlpool: ["8M"],
			workup: ["8M"],
			xscissor: ["8M"],
		},
	},
	wurmple: {
		learnset: {
			bugbite: ["7T", "7L15", "6T", "6L15", "5T", "5L15", "5D", "4T", "4L15"],
			electroweb: ["7T", "6T", "5T"],
			poisonsting: ["7L5", "6L5", "5L5", "5D", "4L5", "3L5"],
			snore: ["7T", "6T", "5T", "5D", "4T"],
			stringshot: ["7L1", "6L1", "5L1", "4T", "4L1", "3L1"],
			tackle: ["7L1", "6L1", "5L1", "4L1", "3L1"],
		},
		encounters: [
			{generation: 3, level: 2},
		],
	},
	silcoon: {
		learnset: {
			bugbite: ["7T", "6T", "5T", "4T"],
			electroweb: ["7T", "6T", "5T"],
			harden: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			irondefense: ["7T", "6T", "5T", "4T"],
			stringshot: ["4T"],
		},
		encounters: [
			{generation: 3, level: 5},
			{generation: 4, level: 5},
			{generation: 6, level: 2, maxEggMoves: 1},
		],
	},
	beautifly: {
		learnset: {
			absorb: ["7L12", "6L1", "5L1", "4L1", "3L1"],
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["7L20", "6L20", "4T"],
			attract: ["7M", "7L27", "6M", "6L27", "5M", "5L31", "4M", "4L31", "3M", "3L31"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["7L35", "6L35", "5L41", "4L41"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			defog: ["7T", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			electroweb: ["7T", "6T", "5T"],
			endure: ["4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["7T", "7L32", "6T", "6L32", "5T", "5L38", "4M", "4L38", "3M", "3L38"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			gust: ["7L1", "6L1", "5L13", "4L13", "3L13"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			laserfocus: ["7T"],
			megadrain: ["7L22", "6L22", "5L24", "4L24", "3L24"],
			mimic: ["3T"],
			morningsun: ["7L17", "6L17", "5L20", "4L20", "3L20"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychic: ["7M", "6M", "5M", "4M", "3M"],
			quiverdance: ["7L40", "6L40", "5L45"],
			rage: ["7L37", "6L37"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["7L25", "6L25", "5L34", "4M", "4L34", "3L34"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			stunspore: ["7L15", "6L15", "5L17", "4L17", "3L17"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			venoshock: ["7M", "6M", "5M"],
			whirlwind: ["7L30", "6L27", "5L27", "4L27", "3L27"],
		},
	},
	cascoon: {
		learnset: {
			bugbite: ["7T", "6T", "5T", "4T"],
			electroweb: ["7T", "6T", "5T"],
			harden: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			irondefense: ["7T", "6T", "5T", "4T"],
			stringshot: ["4T"],
		},
		encounters: [
			{generation: 3, level: 5},
			{generation: 4, level: 5},
			{generation: 6, level: 2, maxEggMoves: 1},
		],
	},
	dustox: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["4T"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["7L35", "6L35", "5L41", "4L41"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confusion: ["7L12", "6L1", "5L1", "4L1", "3L1"],
			defog: ["7T", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			electroweb: ["7T", "6T", "5T"],
			endure: ["4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			gust: ["7L1", "6L1", "5L13", "4L13", "3L13"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			laserfocus: ["7T"],
			lightscreen: ["7M", "7L27", "6M", "6L27", "5M", "5L31", "4M", "4L31", "3M", "3L31"],
			mimic: ["3T"],
			moonlight: ["7L17", "6L17", "5L20", "4L20", "3L20"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			poisonpowder: ["7L15", "6L15"],
			protect: ["7M", "7L37", "6M", "6L17", "5M", "5L17", "4M", "4L17", "3M", "3L17"],
			psybeam: ["7L22", "6L22", "5L24", "4L24", "3L24"],
			psychic: ["7M", "6M", "5M", "4M", "3M"],
			quiverdance: ["7L40", "6L40", "5L45"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["7L25", "6L25", "5L34", "4M", "4L34", "3L34"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["7M", "6M", "5M", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "7L32", "6M", "6L32", "5M", "5L38", "4M", "4L38", "3M", "3L38"],
			twister: ["4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			venoshock: ["7M", "7L20", "6M", "6L20", "5M"],
			whirlwind: ["7L30", "6L27", "5L27", "4L27", "3L27"],
		},
	},
	lotad: {
		learnset: {
			absorb: ["8L3", "7L6", "6L5", "5L5", "5D", "4L5", "3L7", "3S0"],
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			bubble: ["7L9", "6L9"],
			bubblebeam: ["8L20", "7L21", "6L21", "5L25", "4L25"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "8L43", "7M", "7L36", "6M", "6L36", "5M", "5L45", "4M", "4L43"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L16", "7E", "6E", "5E", "4E", "3E"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "8L28", "7T", "7L30", "7E", "6T", "6L30", "6E", "5T", "5E", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			growl: ["8L1", "7L3", "6L3", "5L3", "4L3", "3L3", "3S0"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			leechseed: ["8E", "7E", "6E", "5E", "5D", "4E", "3E"],
			megadrain: ["8L12", "7L18", "6L18", "5L19", "4L19", "3L43"],
			mimic: ["3T"],
			mist: ["8L9", "7L15", "6L11", "5L11", "4L11", "3L21"],
			naturalgift: ["7L12", "6L12", "5L15", "4M", "4L15"],
			naturepower: ["8L24", "7M", "7L24", "6M", "6L7", "5L7", "4L7", "3L13"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "8L33", "7M", "7L27", "6M", "6L27", "5M", "5L37", "4M", "4L35", "3M", "3L31"],
			razorleaf: ["8E", "7E", "6E", "5E", "4E", "3E"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetscent: ["8E", "7E", "6E", "5E", "4E", "3E"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			teeterdance: ["8E", "7E", "6E", "5E"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			tickle: ["8E", "7E", "6E", "5E", "4E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			watergun: ["8L6", "7E", "6E", "5E", "4E", "3E"],
			waterpulse: ["7T", "6T", "5D", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			zenheadbutt: ["8M", "8L38", "7T", "7L33", "6T", "6L31", "5T", "5L31", "4T", "4L27"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["astonish", "growl", "absorb"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 3, level: 3},
		],
	},
	lombre: {
		learnset: {
			absorb: ["8L1", "7L6", "6L5", "5L5", "4L5", "3L7"],
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bubble: ["7L9", "6L9"],
			bubblebeam: ["8L24", "7L24", "6L24", "5L25", "4L25"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "8L57", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L1", "7L16", "6L11", "5L11", "4L11", "3L19"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			flail: ["8L1"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furyswipes: ["8L18", "7L12", "6L12", "5L15", "4L15", "3L25"],
			gigadrain: ["8M", "8L36", "7T", "6T", "5T", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			growl: ["8L1", "7L3", "6L3", "5L3", "4L3", "3L3"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["8M", "8L64", "7L44", "6L44", "5L45", "4L43", "3L49"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			knockoff: ["8L1", "7T", "7L36", "6T", "6L36"],
			megadrain: ["8L12"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mimic: ["3T"],
			mist: ["8L9"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["8L30", "7M", "7L28", "6M", "6L7", "5L7", "4L7", "3L13"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "8L43", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			teeterdance: ["8L1"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M", "3L37"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "7L32", "6T", "6L32", "5T", "5L37", "4T", "4L35", "3L43"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			watersport: ["7L20", "6L19", "5L19", "4L19", "3L31"],
			whirlpool: ["8M", "4M"],
			zenheadbutt: ["8M", "8L50", "7T", "7L40", "6T", "6L31", "5T", "5L31", "4T", "4L27"],
		},
		encounters: [
			{generation: 6, level: 13, maxEggMoves: 1},
		],
	},
	ludicolo: {
		learnset: {
			absorb: ["8L1", "3L1"],
			amnesia: ["8M"],
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bubblebeam: ["8L1"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["3T"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L1", "5S0"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			flail: ["8L1"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furyswipes: ["8L1"],
			gigadrain: ["8M", "8L1", "7T", "6T", "5T", "5S0", "5S1", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["8M", "8L1", "5S0"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "5S0", "5S1", "4M", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			knockoff: ["8L1", "7T", "6T"],
			leafstorm: ["8M"],
			megadrain: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["8M", "3T"],
			mimic: ["3T"],
			mist: ["8L1"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["8L1", "7M", "7L1", "6M", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "8L1", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M", "5S1"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "5S1", "4M", "3M"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			teeterdance: ["8L1"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			weatherball: ["8M"],
			whirlpool: ["8M", "4M"],
			zenheadbutt: ["8M", "8L1", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 5, level: 50, shiny: 1, abilities: ["swiftswim"], moves: ["fakeout", "hydropump", "icebeam", "gigadrain"], pokeball: "cherishball"},
			{generation: 5, level: 30, gender: "M", nature: "Calm", abilities: ["swiftswim"], moves: ["scald", "gigadrain", "icebeam", "sunnyday"], pokeball: "pokeball"},
		],
	},
	seedot: {
		learnset: {
			absorb: ["8L3"],
			amnesia: ["8M", "7E", "6E", "5E", "4E", "3E"],
			astonish: ["8L6"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			beatup: ["8M", "7E", "6E", "5E"],
			bide: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			bodyslam: ["8M", "3T"],
			bulletseed: ["8M", "7E", "6E", "5E", "5D", "4M", "3M", "3S1"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			defensecurl: ["3T"],
			defog: ["8E", "7T", "7E", "6E", "5E"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			explosion: ["8L33", "7M", "7L33", "6M", "6L33", "5M", "5L43", "4M", "4L43", "3T", "3L43"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4E", "3E"],
			flash: ["6M", "5M", "4M", "3M"],
			foulplay: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M", "3M", "3S1"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7E", "6E"],
			growth: ["8L9", "7L9", "6L7", "5L7", "5D", "4L7", "3L7", "3S0"],
			harden: ["8L1", "7L3", "6L3", "5L3", "4L3", "3L3", "3S0"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			leechseed: ["8E", "7E", "6E", "5E", "4E", "3E"],
			megadrain: ["8L15"],
			mimic: ["3T"],
			nastyplot: ["8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			naturepower: ["8L21", "7M", "7L15", "6M", "6L13", "5L13", "4L13", "3L13"],
			nightslash: ["8E"],
			payback: ["8M", "8L18"],
			powerswap: ["8M", "7E", "6E", "5E", "4E"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			quickattack: ["8E", "7E", "6E", "5E", "4E", "3E"],
			razorwind: ["7E", "6E", "5E", "4E", "3E"],
			refresh: ["3S1"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["8L12", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M", "3S1"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8L30"],
			sunnyday: ["8M", "8L24", "7M", "7L27", "6M", "6L27", "5M", "5L31", "4M", "4L31", "3M", "3L31"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["8L27", "7T", "7L21", "6T", "6L21", "5T", "5L21", "4T", "4L21", "3L21"],
			tackle: ["8L1"],
			takedown: ["8E", "7E", "6E", "5E", "4E", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			worryseed: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["bide", "harden", "growth"], pokeball: "pokeball"},
			{generation: 3, level: 17, moves: ["refresh", "gigadrain", "bulletseed", "secretpower"]},
		],
		encounters: [
			{generation: 3, level: 3},
		],
	},
	nuzleaf: {
		learnset: {
			absorb: ["8L1"],
			aircutter: ["8L1"],
			amnesia: ["8M"],
			assurance: ["8M"],
			astonish: ["8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			beatup: ["8M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			defensecurl: ["3T"],
			defog: ["7T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			explosion: ["8L1", "7M", "6M", "5M", "4M", "3T"],
			extrasensory: ["8L43", "7L36", "6L36", "5L49", "4L49", "3L49"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L1", "7L12", "6L12", "5L19", "4L19", "3L19"],
			falseswipe: ["8M", "7M", "6M", "5M"],
			feintattack: ["7L24", "6L24", "5L31", "4L31", "3L31"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growth: ["8L9", "7L6", "6L6", "5L7", "4L7", "3L7"],
			harden: ["8L1", "7L3", "6L3", "5L3", "4L3", "3L3"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			lashout: ["8T"],
			leafblade: ["8M", "8L57", "7L28", "6L28"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megadrain: ["8L18"],
			megakick: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			naturepower: ["8L30", "7M", "7L16", "6M", "6L9", "5L13", "4L13", "3L13"],
			payback: ["8M", "8L24", "7M", "6M", "5M", "4M"],
			pound: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			powerswap: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			razorleaf: ["8L0", "7L1", "6L1", "5L1", "4L1"],
			razorwind: ["7L20", "6L20", "5L37", "4L37", "3L37"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L12", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			solarblade: ["8M"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8L50"],
			sunnyday: ["8M", "8L36", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["8L1", "7M", "7L32", "6M", "6L32", "5M", "5L43", "4M", "4L43", "3T", "3L43"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["8L1", "7T", "6T", "5T", "4T"],
			tackle: ["8L1"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			torment: ["8L1", "7M", "7L9", "6M", "6L16", "5M", "5L25", "4M", "4L25", "3M", "3L25"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 6, level: 13, maxEggMoves: 1},
		],
	},
	shiftry: {
		learnset: {
			absorb: ["8L1"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["8L1", "4T"],
			airslash: ["8M"],
			amnesia: ["8M"],
			assurance: ["8M"],
			astonish: ["8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			beatup: ["8M"],
			bodyslam: ["8M", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			defensecurl: ["3T"],
			defog: ["7T", "4M"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			explosion: ["8L1", "7M", "6M", "5M", "4M", "3T"],
			extrasensory: ["8L1"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["8L1"],
			falseswipe: ["8M", "7M", "6M", "5M"],
			feintattack: ["7L1", "6L1", "5L1", "4L1"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growth: ["8L1", "3L1"],
			harden: ["8L1", "3L1"],
			headbutt: ["4T"],
			heatwave: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hurricane: ["8M", "8L1", "7L32", "6L32"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lashout: ["8T"],
			leafblade: ["8M", "8L1"],
			leafstorm: ["8M", "7L44", "6L44", "5L49", "4L49"],
			leaftornado: ["8L0", "7L20", "6L19", "5L19"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megadrain: ["8L1"],
			megakick: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			nastyplot: ["8M", "7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			naturepower: ["8L1", "7M", "6M", "3L1"],
			ominouswind: ["4T"],
			payback: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			pound: ["3L1"],
			powerswap: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			razorleaf: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["8M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L1", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			silverwind: ["4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			solarblade: ["8M"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8L1", "4T"],
			sunnyday: ["8M", "8L1", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["8L1", "7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["8L1", "7T", "6T", "5T", "4T"],
			tackle: ["8L1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			torment: ["8L1", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			whirlwind: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			worryseed: ["7T", "6T", "5T", "4T"],
			xscissor: ["8M", "7M", "6M", "5M", "4M"],
		},
	},
	taillow: {
		learnset: {
			aerialace: ["7M", "7L21", "6M", "6L21", "5M", "5L34", "4M", "4L34", "3M", "3L34"],
			agility: ["7L29", "6L29", "5L43", "4L43", "3L43"],
			aircutter: ["4T"],
			airslash: ["7L33", "6L33", "5L53", "4L53"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			boomburst: ["7E", "6E"],
			bravebird: ["7L41", "7E", "6L41", "6E", "5E", "5D", "4E"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			defog: ["7T", "7E", "6E", "5E", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7L17", "6M", "6L17", "5M", "5L19", "4M", "4L19", "3M", "3L19"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["7T", "7L37", "6T", "6L26", "5T", "5L26", "4T", "4L26", "3L26"],
			endure: ["5D", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			featherdance: ["3S0"],
			fly: ["7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["7L5", "6L4", "5L4", "4L4", "3L4", "3S0"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			heatwave: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hurricane: ["7E"],
			mimic: ["3T"],
			mirrormove: ["7E", "6E", "5E", "5D", "4E", "3E"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			peck: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			pluck: ["5M", "4M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "6E", "5E", "4E", "3E"],
			quickattack: ["7L9", "6L7", "5L8", "4L8", "3L8"],
			quickguard: ["7L25", "6L25"],
			rage: ["7E", "6E", "5E", "4E", "3E"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			refresh: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["7L45"],
			roost: ["7M", "7E", "6M", "6E", "5T", "5E", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["7T", "7E", "6T", "6E", "5E", "4E", "3T", "3E"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "7E", "6M", "6E", "5E", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			supersonic: ["7E", "6E", "5E", "4E", "3E"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tailwind: ["7T", "6T", "5T", "5D", "4T"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			whirlwind: ["7E", "6E", "5E", "4E"],
			wingattack: ["7L13", "6L13", "5L13", "4L13", "3L13"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["peck", "growl", "focusenergy", "featherdance"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 3, level: 4},
		],
	},
	swellow: {
		learnset: {
			aerialace: ["7M", "7L21", "6M", "6L21", "5M", "5L38", "4M", "4L38", "3M", "3L38"],
			agility: ["7L33", "6L33", "5L49", "4L49", "3L49", "3S0"],
			aircutter: ["4T"],
			airslash: ["7L1", "6L1", "5L61", "4L61"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["3S0"],
			bravebird: ["7L1", "6L1"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			defog: ["7T", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7L17", "6M", "6L17", "5M", "5L19", "4M", "4L19", "3M", "3L19"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["7T", "7L45", "6T", "6L28", "5T", "5L28", "4T", "4L28", "3L28"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M", "3S0"],
			fly: ["7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			growl: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			heatwave: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "6M", "5M", "4M", "3M"],
			laserfocus: ["7T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			peck: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			pluck: ["7L1", "6L1", "5M", "5L1", "4M", "4L1"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			quickattack: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			quickguard: ["7L27", "6L27"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["7L57"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			skyattack: ["7T", "6T", "5T", "4T", "3T", "3S0"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			steelwing: ["7M", "6M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			wingattack: ["7L13", "6L13", "5L13", "4L13", "3L13"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 3, level: 43, moves: ["batonpass", "skyattack", "agility", "facade"]},
		],
		encounters: [
			{generation: 4, level: 20},
		],
	},
	wingull: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "9E", "8E", "7M", "7L29", "6M", "6L29", "5M", "5L42", "4M", "4L42", "3M"],
			agility: ["9M", "9L26", "8M", "8L26", "7L36", "7E", "6L36", "6E", "5L37", "5E", "4L37", "4E", "3L55", "3E"],
			aircutter: ["9M", "9E", "8E", "7L22", "6L22", "5L33", "4T"],
			airslash: ["9M", "9L30", "8M", "8L30", "7L40", "6L40", "5L47", "4L47"],
			aquaring: ["9E", "8E", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bravebird: ["9M"],
			brine: ["8M", "7E", "6E", "5E", "4M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			defog: ["7T", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fly: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			gust: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hurricane: ["9M", "9L45", "8M", "8L45", "7L43", "6L43", "5L50"],
			hydropump: ["9M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			knockoff: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			liquidation: ["9M", "8M", "7T"],
			mimic: ["3T"],
			mist: ["9L35", "8L35", "7L12", "7E", "6L12", "6E", "5L16", "5E", "4L16", "4E", "3L21", "3E"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			pluck: ["5M", "4M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7L26", "6L26", "5L34", "4L34", "3L43"],
			quickattack: ["9L5", "8L5", "7L19", "6L19", "5L24", "4L24", "3L31"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["9L40", "8L40", "7M", "7L33", "7E", "6M", "6L26", "6E", "5T", "5L29", "5E", "4M", "4L29"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["7T", "6T", "5D", "4M", "3M"],
			skyattack: ["7T", "6T", "3T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			soak: ["9E", "8E", "7E", "6E"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			supersonic: ["9L10", "8L10", "7L5", "6L5", "5L6", "4L6", "3L7"],
			surf: ["9M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "4T", "3T"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["9E", "8E", "7E", "6E", "5E", "5D", "4T", "4E", "3E"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["9M", "8M", "7M", "6M", "5M", "4M"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			waterpulse: ["9M", "9L20", "8L20", "7T", "7L15", "6T", "6L15", "5L19", "4M", "4L19", "3M"],
			watersport: ["7E", "6E", "5E", "4E", "3E"],
			wideguard: ["9E", "8E", "7E", "6E"],
			wingattack: ["9L15", "8L15", "7L8", "6L8", "5L11", "4L11", "3L13"],
		},
		encounters: [
			{generation: 3, level: 2},
		],
	},
	pelipper: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "9L1", "8M", "8L1"],
			aircutter: ["9M", "4T"],
			airslash: ["9M", "9L1", "8M", "8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M"],
			bravebird: ["9M", "8M"],
			brine: ["8M", "7L22", "6L28", "5L34", "4M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			defog: ["7T", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "9L34", "8M", "8L34", "7M", "7L28", "6M", "6L39", "5M", "5L43", "4M", "4L43"],
			fly: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			gunkshot: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hurricane: ["9M", "9L55", "8M", "8L55", "7L1", "6L1", "5L63"],
			hydropump: ["9M", "9L62", "8M", "8L62", "7L1", "6L1", "5L57", "4L57", "3L61"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			liquidation: ["9M", "8M", "7T"],
			mimic: ["3T"],
			mist: ["9L41", "8L41", "7L12", "6L12", "5L16", "4L16", "3L21"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			payback: ["8M", "7M", "7L19", "6M", "6L19", "5M", "5L24", "4M", "4L24"],
			pluck: ["5M", "4M"],
			protect: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3M", "3L25"],
			quickattack: ["9L1", "8L1"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["9L48", "8L48", "7M", "7L39", "6M", "6L22", "5T", "5L31", "4M", "4L31"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T", "4T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skyattack: ["7T", "6T", "5T", "4T", "3T"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			soak: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			spitup: ["9L28", "8L28", "7L33", "6L33", "5L38", "4L38", "3L47"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			stockpile: ["9L28", "8L28", "7L33", "6L33", "5L38", "4L38", "3L33"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			supersonic: ["9L1", "8L1", "7L5", "6L5", "5L6", "4L6", "3L7"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swallow: ["9L28", "8L28", "7L33", "6L33", "5L38", "4L38", "3L33"],
			swift: ["9M", "8M", "4T", "3T"],
			tailwind: ["9M", "9L1", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L50", "4T", "4L50"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["9M", "8M", "7M", "6M", "5M", "4M"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["9M", "9L20", "8L20", "7T", "7L15", "6T", "6L15", "5L19", "4M", "4L19", "3M"],
			watersport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			weatherball: ["8M"],
			whirlpool: ["8M", "4M"],
			wingattack: ["9L15", "8L15", "7L1", "6L1", "5L1", "4L1", "3L1"],
		},
		encounters: [
			{generation: 4, level: 15},
			{generation: 6, level: 18, maxEggMoves: 1},
		],
	},
	ralts: {
		learnset: {
			allyswitch: ["8M", "7T", "7E", "6E", "5M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			calmmind: ["9M", "9L27", "8M", "8L27", "7M", "7L24", "6M", "6L24", "5M", "5L28", "4M", "4L23", "3M", "3L21"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "9L24", "8M", "8L24", "7L34", "6L34", "5L43", "4L39", "3S1"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9E", "8E", "7E", "6E", "5E", "4E"],
			confusion: ["9L6", "8L6", "7L4", "6L4", "5L6", "5D", "4L6", "3L6", "3S2"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			defensecurl: ["3T"],
			destinybond: ["9E", "8E", "7E", "6E", "5E", "5D", "4E", "3E"],
			disable: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			disarmingvoice: ["9M", "9L1", "8L1", "7L11", "6L11"],
			doubleedge: ["3T"],
			doubleteam: ["9L3", "8L3", "7M", "7L6", "6M", "6L6", "5M", "5L10", "4M", "4L10", "3M", "3L11"],
			drainingkiss: ["9M", "9L12", "8M", "8L12", "7L22", "6L22"],
			dreameater: ["9L36", "8L36", "7M", "7L39", "6M", "6L39", "5M", "5L50", "4M", "4L45", "3T", "3L46"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M", "8M", "7E", "6E", "6S3", "5E", "4E"],
			endure: ["9M", "8M", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["9L39", "8M", "8L39", "7L32", "6L32", "5L39", "4L34", "3L36"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "7L1", "6L1", "6S3", "5L1", "4L1", "3L1", "3S0", "3S1"],
			grudge: ["8E", "7E", "6E", "5E", "4E"],
			headbutt: ["4T"],
			healpulse: ["9L33", "8L33", "7L19", "6L19", "5L23"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "5D", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			hypnosis: ["9L9", "8L9", "7L37", "6L37", "5L45", "4L43", "3L41"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "8M", "7L29", "6L29", "5L34", "4L32", "3L31"],
			knockoff: ["9E", "8E"],
			lifedew: ["9L21", "8L21"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7L14", "6L14", "5L17", "4L17"],
			magicalleaf: ["9M", "8M", "7L17", "6L17", "5L21", "4L21"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			meanlook: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			megakick: ["8M"],
			megapunch: ["8M"],
			memento: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			metronome: ["9M"],
			mimic: ["3T"],
			mistyterrain: ["9M", "8M", "7E", "6E"],
			mudslap: ["4T", "3T"],
			mysticalfire: ["9E"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L18", "8L18"],
			psychic: ["9M", "9L30", "8M", "8L30", "7M", "7L27", "6M", "6L27", "5M", "5L32", "4M", "4L28", "3M", "3L26"],
			psychicterrain: ["9M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M", "3S2"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			shadowsneak: ["9E", "8E", "7E", "6E", "5E", "4E"],
			shockwave: ["7T", "6T", "4M", "3M", "3S2"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["3S2"],
			skillswap: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			storedpower: ["9M", "8M", "7L42", "6L42", "5L54"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "4T"],
			synchronoise: ["7E", "6E", "5E"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["9L15", "8L15", "7L9", "6L9", "5L12", "4L12", "3L16"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			willowisp: ["9M", "8M", "7M", "6M", "5M", "4E", "3E"],
			wish: ["3S0"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["growl", "wish"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, moves: ["growl", "charm"], pokeball: "pokeball"},
			{generation: 3, level: 20, moves: ["sing", "shockwave", "reflect", "confusion"]},
			{generation: 6, level: 1, isHidden: true, moves: ["growl", "encore"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 3, level: 4},
		],
	},
	kirlia: {
		learnset: {
			allyswitch: ["8M", "7T", "5M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			calmmind: ["9M", "9L33", "8M", "8L33", "7M", "7L26", "6M", "6L26", "5M", "5L31", "4M", "4L25", "3M", "3L21"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "9L28", "8M", "8L28", "7L40", "6L40", "5L50", "4L45"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			defensecurl: ["3T"],
			disarmingvoice: ["9M", "9L1", "8L1", "7L11", "6L11"],
			doubleedge: ["3T"],
			doubleteam: ["9L1", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			drainingkiss: ["9M", "9L12", "8M", "8L12", "7L23", "6L23"],
			dreameater: ["9L48", "8L48", "7M", "7L47", "6M", "6L47", "5M", "5L59", "4M", "4L53", "3T", "3L54"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "4M", "3T"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["9L53", "8M", "8L53", "7L37", "6L37", "5L45", "4L39", "3L40"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["4T"],
			healpulse: ["9L43", "8L43", "7L19", "6L19", "5L25"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			hypnosis: ["9L9", "8L9", "7L44", "6L44", "5L53", "4L50", "3L47"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "8M", "7L33", "6L33", "5L39", "4L36", "3L33"],
			lifedew: ["9L23", "8L23"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7L14", "6L14", "5L17", "4L17"],
			magicalleaf: ["9M", "8M", "7L17", "6L17", "5L22", "4L22", "3L1"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M"],
			mimic: ["3T"],
			mistyterrain: ["9M", "8M"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L18", "8L18"],
			psychic: ["9M", "9L38", "8M", "8L38", "7M", "7L30", "6M", "6L30", "5M", "5L36", "4M", "4L31", "3M", "3L26"],
			psychicterrain: ["9M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			storedpower: ["9M", "8M", "7L51", "6L51", "5L64"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "4T"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["9L15", "8L15", "7L1", "6L1", "5L1", "4L1", "3L1"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			tripleaxel: ["8T"],
			willowisp: ["9M", "8M", "7M", "6M", "5M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 4, level: 6},
		],
	},
	gardevoir: {
		learnset: {
			allyswitch: ["8M", "7T", "5M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			aurasphere: ["9M"],
			bodyslam: ["9M", "8M", "3T"],
			calmmind: ["9M", "9L35", "8M", "8L35", "7M", "7L26", "6M", "6L26", "6S1", "5M", "5L33", "4M", "4L25", "3M", "3L21"],
			captivate: ["7L44", "6L44", "5L60", "4M", "4L53"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			dazzlinggleam: ["9M", "9L0", "8M", "8L0", "7M", "6M", "6S1"],
			defensecurl: ["3T"],
			disarmingvoice: ["9M", "9L1", "8L1", "7L11", "6L11"],
			doubleedge: ["3T"],
			doubleteam: ["9L1", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			drainingkiss: ["9M", "9L12", "8M", "8L12", "7L23", "6L23"],
			dreameater: ["9L56", "8L56", "7M", "7L53", "6M", "6L53", "5M", "5L73", "4M", "4L65", "3T", "3L60"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "4M", "3T"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "5S0", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["9L63", "8M", "8L63", "7L40", "6L40", "5L53", "4L45", "3L42"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			guardswap: ["8M"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			healingwish: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			healpulse: ["9L1", "8L49", "7L19", "6L19", "5L25"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			hypnosis: ["9L9", "8L9", "7L49", "6L49", "5L65", "5S0", "4L60", "3L51"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "8M", "7L35", "6L35", "5L45", "4L40", "3L33"],
			laserfocus: ["7T"],
			lifedew: ["9L23", "8L23"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			magicalleaf: ["9M", "8M", "7L17", "6L17", "5L22", "4L22"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M"],
			mimic: ["3T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M", "9L1", "8M", "8L1", "7L1", "6L1"],
			moonblast: ["9L49", "8L1", "7L1", "6L1", "6S1"],
			mudslap: ["4T", "3T"],
			mysticalfire: ["9L1", "8M"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			nightshade: ["9M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powerswap: ["8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L18", "8L18"],
			psychic: ["9M", "9L42", "8M", "8L42", "7M", "7L31", "6M", "6L31", "5M", "5L40", "5S0", "4M", "4L33", "3M", "3L26"],
			psychicterrain: ["9M", "8M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			storedpower: ["9M", "8M", "7L1", "6L1", "6S1", "5L80"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "4T"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			teleport: ["9L15", "8L15", "7L1", "6L1", "5L1", "4L1", "3L1"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "5S0", "4M", "3M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			tripleaxel: ["8T"],
			willowisp: ["9M", "8M", "7M", "6M", "5M"],
			wish: ["9L28", "8L28", "7L14", "6L14", "5L17", "4L17"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 5, level: 50, shiny: 1, abilities: ["trace"], moves: ["hypnosis", "thunderbolt", "focusblast", "psychic"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: true, gender: "F", abilities: ["synchronize"], moves: ["dazzlinggleam", "moonblast", "storedpower", "calmmind"], pokeball: "cherishball"},
		],
	},
	gallade: {
		learnset: {
			aerialace: ["9M", "9L18", "8L18", "7M", "7L17", "6M", "5M", "4M"],
			agility: ["9M"],
			airslash: ["9M", "8M"],
			allyswitch: ["8M", "7T", "5M"],
			aquacutter: ["9L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			aurasphere: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "9L1", "8M", "8L1", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			closecombat: ["9M", "9L63", "8M", "8L63", "7L1", "6L1", "5L59", "4L53"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			cut: ["6M", "5M", "4M"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			disarmingvoice: ["9M", "9L1", "8L1"],
			doubleteam: ["9L1", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			drainingkiss: ["9M", "9L1", "8M", "8L1"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dreameater: ["9L1", "8L1", "7M", "6M", "5M", "4M"],
			dualchop: ["7T", "6T", "5T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "4M"],
			energyball: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			falseswipe: ["9M", "9L23", "8M", "8L23", "7M", "7L44", "6M", "6L44", "5M", "5L50", "4M", "4L45"],
			feint: ["9L12", "8L12", "7L40", "6L40", "5L45", "4L39"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			flash: ["6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["9L1", "8L1", "7L14", "6L14", "5L17", "4T", "4L17"],
			futuresight: ["9L1", "8M", "8L1"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1"],
			headbutt: ["4T"],
			healpulse: ["9L49", "8L49", "7L19", "6L19", "5L25"],
			helpinghand: ["9M", "9L9", "8M", "8L9", "7T", "7L35", "6T", "6L35", "5T", "5L39", "4T", "4L36"],
			hex: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			hypnosis: ["9L1", "8L1"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			icywind: ["9M", "8M"],
			imprison: ["9M", "9L1", "8M", "8L1"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			leafblade: ["9L1", "8M", "7L1", "6L1", "5L1", "4L1"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			lifedew: ["9L1", "8L1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magicalleaf: ["9M", "8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mistyterrain: ["9M", "8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			nightslash: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			painsplit: ["7T", "6T", "5T", "4T"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "9L28", "8M", "8L28", "7M", "7L49", "6M", "6L49", "5M", "5L53", "4M", "4L50"],
			psybeam: ["9M", "9L1", "8L1"],
			psychic: ["9M", "9L1", "8M", "8L1", "7M", "6M", "5M", "4M"],
			psychicterrain: ["9M"],
			psychocut: ["9L42", "8M", "8L42", "7L31", "6L31", "5L36", "4L31"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			quickguard: ["9L56", "8L56", "7L11", "6L11"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sacredsword: ["9L1"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M"],
			slash: ["9L0", "8L0", "7L1", "6L17", "5L22", "4L22"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarblade: ["8M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			storedpower: ["9M", "8M", "7L1", "6L1", "5L64"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			swordsdance: ["9M", "9L35", "8M", "8L35", "7M", "7L26", "6M", "6L26", "5M", "5L31", "4M", "4L25"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			telekinesis: ["7T", "5M"],
			teleport: ["9L15", "8L15", "7L1", "6L1", "5L1", "4L1"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			throatchop: ["8M", "7T"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			tripleaxel: ["8T"],
			vacuumwave: ["4T"],
			wideguard: ["9L56", "8L56", "7L23", "6L23"],
			willowisp: ["9M", "8M", "7M", "6M", "5M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			workup: ["8M", "7M", "5M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M", "4M"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
	},
	surskit: {
		learnset: {
			acrobatics: ["9M"],
			agility: ["9M", "9L22", "7L22", "6L22", "5L31", "4L31", "3L31"],
			aquajet: ["9E", "7L30", "7E", "6L30", "6E", "5E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "9L35", "7L35", "6L35", "5L43", "4L43"],
			blizzard: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bubble: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1", "3S0", "3S1"],
			bubblebeam: ["9L17", "7L17", "6L17", "5L25", "4L25", "3L25"],
			bugbite: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			bugbuzz: ["9M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "7E", "6E", "5E", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fellstinger: ["9E", "7E", "6E"],
			flash: ["6M", "5M", "4M", "3M"],
			foresight: ["7E", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "7T", "6T", "5T", "5D", "4M", "3M"],
			haze: ["9L25", "7L25", "6L25", "5L37", "4L37", "3L37"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "9E", "7E", "6E", "5E", "5D", "4E", "3E"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			leechlife: ["9M"],
			liquidation: ["9M", "7T"],
			lunge: ["9E", "7E"],
			mimic: ["3T"],
			mindreader: ["7E", "6E", "5E", "4E", "3E"],
			mist: ["9L25", "7L25", "6L25", "5L37", "4L37", "3L37"],
			mudshot: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			mudslap: ["9M", "4T"],
			mudsport: ["3S0"],
			naturalgift: ["4M"],
			pounce: ["9M"],
			powersplit: ["9E", "7E", "6E"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			quickattack: ["9L6", "7L6", "6L6", "5L7", "4L7", "3L7", "3S1"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			soak: ["9L14"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			stickyweb: ["9L38", "7L38", "6L38"],
			stringshot: ["4T"],
			strugglebug: ["9M", "6M", "5M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["9M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetscent: ["9L9", "7L9", "6L9", "5L13", "4L13", "3L13"],
			swift: ["4T", "3T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			watersport: ["7L14", "6L14", "5L19", "4L19", "3L19"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["bubble", "mudsport"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["bubble", "quickattack"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 3, level: 3},
		],
	},
	masquerain: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M"],
			aircutter: ["9M", "9L22", "7L22", "6L22", "4T"],
			airslash: ["9M", "9L32", "7L38", "6L38", "5L47", "4L47"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["9M"],
			blizzard: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bubble: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["9M", "9L44", "7L1", "6L1", "5L61", "4L61"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			defog: ["7T", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			foulplay: ["9M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "9L38", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gust: ["9L17", "7L17", "6L17", "5L22", "4L22", "3L26"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hurricane: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			leechlife: ["9M"],
			liquidation: ["9M", "7T"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			ominouswind: ["7L1", "6L1", "5L1", "4T", "4L1"],
			pounce: ["9M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			quickattack: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			quiverdance: ["9L52", "7L1", "6L1", "5L68"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			scaryface: ["9M", "9L22", "7L22", "6L22", "5L26", "4L26", "3L33"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["7L32", "6L32", "5L40", "4M", "4L40", "3L47"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			soak: ["9L1"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["9M", "6M", "5M"],
			stunspore: ["9L26", "7L26", "6L26", "5L33", "4L33", "3L40"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["9M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetscent: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			swift: ["9M", "4T", "3T"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uturn: ["9M", "7M", "6M", "5M", "4M"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			watersport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			whirlwind: ["9L1", "7L1", "6L1", "5L54", "4L54", "3L53"],
		},
		encounters: [
			{generation: 6, level: 21, maxEggMoves: 1},
		],
	},
	shroomish: {
		learnset: {
			absorb: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "3T"],
			bulletseed: ["9M", "7E", "6E", "5E", "5D", "4M", "3M"],
			captivate: ["4M"],
			charm: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			confide: ["7M", "6M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "7T", "7E", "6T", "6E", "5T", "5E"],
			endure: ["9M", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			falseswipe: ["9M", "7M", "6M", "5M", "4E", "3E", "3S0"],
			flash: ["6M", "5M", "4M", "3M"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "5D"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["9M", "9L26", "7T", "7L26", "6T", "6L26", "5T", "5L37", "4M", "4L37", "3M", "3L45"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["9M"],
			growth: ["9L29", "7L29", "6L29", "5L33", "4L33", "3L36"],
			gunkshot: ["9M"],
			headbutt: ["9L15", "7L15", "6L15", "5L21", "4T", "4L21", "3L22"],
			helpinghand: ["9M", "9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			leechseed: ["9L8", "7L8", "6L8", "5L13", "4L13", "3L10"],
			magicalleaf: ["9M"],
			megadrain: ["9L12", "7L12", "6L12", "5L17", "4L17", "3L16", "3S0"],
			mimic: ["3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			poisonpowder: ["9L19", "7L19", "6L19", "5L25", "4L25", "3L28"],
			pounce: ["9M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			refresh: ["3S0"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "9L36", "7T", "7L36", "7E", "6T", "6L36", "6E", "5T", "5L41", "5E", "4T", "4L41", "4E"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			spore: ["9L40", "7L40", "6L40", "5L45", "4L45", "3L54"],
			stunspore: ["9L5", "7L5", "6L5", "5L9", "5D", "4L9", "3L7", "3S0"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			swift: ["9M"],
			swordsdance: ["7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			tackle: ["9L1", "7L1", "6L1", "5L5", "4L5", "3L4"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["9L33", "7M", "7L33", "6M", "6L33", "5M", "4M", "3M"],
			venoshock: ["9M", "7M", "6M", "5M"],
			wakeupslap: ["7E", "6E", "5E", "4E"],
			worryseed: ["9E", "7T", "7L22", "7E", "6T", "6L22", "6E", "5T", "5L29", "5E", "4T", "4L29", "4E"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 15, abilities: ["effectspore"], moves: ["refresh", "falseswipe", "megadrain", "stunspore"]},
		],
	},
	breloom: {
		learnset: {
			absorb: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			aerialace: ["9M"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "9L39", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M"],
			bulletseed: ["9M", "4M", "3M"],
			captivate: ["4M"],
			charm: ["9M"],
			closecombat: ["9M"],
			confide: ["7M", "6M"],
			counter: ["9L22", "7L22", "6L22", "5L25", "4L25", "3T", "3L28"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["9M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["9L50", "7L50", "6L45", "5L45", "4L45", "3T", "3L54"],
			endure: ["9M", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M"],
			falseswipe: ["9M", "7M", "6M", "5M"],
			feint: ["9L19", "7L19", "6L19"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["9L55", "7T", "6T", "4M", "3M"],
			forcepalm: ["9L28", "7L28", "6L28", "5L29", "4L29"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["9M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["9M"],
			growth: ["9L1"],
			gunkshot: ["9M"],
			headbutt: ["9L15", "7L15", "6L15", "5L21", "4T", "4L21", "3L22"],
			helpinghand: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			leafstorm: ["9M"],
			leechseed: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["9M"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			machpunch: ["9L0", "7L1", "6L23", "5L23", "4L23", "3L23"],
			magicalleaf: ["9M"],
			megadrain: ["9L12", "7L12", "6L12", "5L17", "4L17", "3L16"],
			megakick: ["3T"],
			megapunch: ["3T"],
			mimic: ["3T"],
			mindreader: ["7L33", "6L33", "5L37", "4L37", "3L45"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			poisonjab: ["9M"],
			poisonpowder: ["9L1"],
			pounce: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "9L44", "7T", "7L44", "6T", "6L41", "5T", "5L41", "4T", "4L41"],
			seismictoss: ["3T"],
			skyuppercut: ["7L39", "6L33", "5L33", "4L33", "3L36"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			stoneedge: ["9M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			stunspore: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			tackle: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["9L1", "7M", "6M", "5M", "4M", "3M"],
			vacuumwave: ["4T"],
			venoshock: ["9M", "7M", "6M", "5M"],
			workup: ["7M", "5M"],
			worryseed: ["9L33", "7T", "6T", "5T", "4T"],
			zenheadbutt: ["9M"],
		},
	},
	slakoth: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			afteryou: ["9E", "7T", "7E", "6T", "6E", "5T", "5E"],
			amnesia: ["9M", "9L17", "7L17", "6L17", "5L25", "4L25", "3L25"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			blizzard: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "9E", "7E", "6E", "5E", "4E", "3T", "3E"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			chipaway: ["7L25", "6L25", "5L37"],
			confide: ["7M", "6M"],
			counter: ["9L30", "7L30", "6L30", "5L43", "4L37", "3T", "3L37"],
			covet: ["9L22", "7T", "7L22", "6T", "6L22", "5T", "5L31", "4L31", "3L31"],
			crushclaw: ["9E", "7E", "6E", "5E", "4E", "3E"],
			curse: ["9E", "7E", "6E", "5E", "4E", "3E"],
			cut: ["6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			encore: ["9M", "9L6", "7L6", "6L6", "5L7", "4L7", "3L7"],
			endure: ["9M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M"],
			feintattack: ["7L14", "6L14", "5L19", "4L19", "3L19"],
			fireblast: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			flail: ["9L33", "7L33", "6L33", "5L49", "4L43", "3L43"],
			flamethrower: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			hammerarm: ["9E", "7E", "6E", "5E", "4E"],
			headbutt: ["9L14", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metalclaw: ["9M"],
			metronome: ["9M"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["9E", "7E", "6E", "5E", "5D", "4E"],
			playrough: ["9M", "9L38", "7L38", "6L38"],
			poisonjab: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7E", "6E", "5E", "4E", "3E"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scratch: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slackoff: ["9L9", "7L9", "6L9", "5L13", "4L13", "3L13"],
			slash: ["9E", "7E", "6E", "5E", "4E", "3E"],
			sleeptalk: ["9M", "7M", "7E", "6M", "6E", "5T", "5E", "4M", "4E", "3T", "3E"],
			snore: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["5D", "4T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["9L25"],
			thunder: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			tickle: ["9E", "7E", "6E", "5E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			workup: ["7M", "5M"],
			xscissor: ["9M"],
			yawn: ["9L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			zenheadbutt: ["9M"],
		},
	},
	vigoroth: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["9M"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			blizzard: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			chipaway: ["7L27", "6L27", "5L43"],
			confide: ["7M", "6M"],
			counter: ["9L33", "7L33", "6L33", "5L37", "4L37", "3T", "3L37"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["9M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dynamicpunch: ["3T"],
			earthquake: ["9M", "7M", "6M", "5M", "4M", "3M"],
			encore: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			endure: ["9M", "9L17", "7L17", "6L17", "5L25", "4M", "4L25", "3T", "3L25"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M"],
			fireblast: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			flamethrower: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			focuspunch: ["9L37", "7T", "7L37", "6T", "6L37", "5L49", "4M", "4L43", "3M", "3L43"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			furyswipes: ["9L14", "7L14", "6L14", "5L19", "4L19", "3L19"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hypervoice: ["9M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metalclaw: ["9M"],
			metronome: ["9M"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M"],
			playrough: ["9M"],
			poisonjab: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "9L43", "7L1", "6L1", "5L55", "4L49", "3L49"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			scratch: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slash: ["9L23", "7L23", "6L23", "5L31", "4L31", "3L31"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			stompingtantrum: ["9M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["9L27"],
			thunder: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			uproar: ["9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			workup: ["7M", "5M"],
			xscissor: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	slaking: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "4S0", "3M"],
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["9M", "9L17", "7L17", "6L17", "5L25", "4L25", "3L25"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			blizzard: ["9M", "7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			chipaway: ["7L27", "6L27", "5L37"],
			confide: ["7M", "6M"],
			counter: ["9L33", "7L33", "6L33", "5L43", "4L37", "3T", "3L37"],
			covet: ["9L23", "7T", "7L23", "6T", "6L23", "5T", "5L31", "4L31", "3L31"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["9M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dynamicpunch: ["3T"],
			earthquake: ["9M", "7M", "6M", "5M", "4M", "3M"],
			encore: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			endure: ["9M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M"],
			feintattack: ["7L14", "6L14", "5L19", "4L19", "3L19"],
			fireblast: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			flail: ["9L39", "7L39", "6L39", "5L49", "4L43", "3L43"],
			flamethrower: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "9L45", "7M", "7L1", "6M", "6L1", "5M", "5L55", "4M", "4L49"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M", "4S0"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			hammerarm: ["9L63", "7L1", "6L1", "5L67", "4L61"],
			headbutt: ["4T"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			megakick: ["9L52", "3T"],
			megapunch: ["3T"],
			metalclaw: ["9M"],
			metronome: ["9M"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M"],
			playrough: ["9M"],
			poisonjab: ["9M"],
			pounce: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			punishment: ["7L1", "6L1", "5L61", "4L55"],
			quash: ["7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "4S0", "3M"],
			reversal: ["9M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			scratch: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M", "4S0"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slackoff: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			stompingtantrum: ["9M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L1", "4T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["9L0", "7M", "7L1", "6M", "6L36", "5M", "5L36", "4M", "4L36", "3T", "3L36"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["9L27"],
			thunder: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wildcharge: ["9M"],
			workup: ["7M", "5M"],
			xscissor: ["9M"],
			yawn: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 4, level: 50, gender: "M", nature: "Adamant", moves: ["gigaimpact", "return", "shadowclaw", "aerialace"], pokeball: "cherishball"},
		],
	},
	nincada: {
		learnset: {
			absorb: ["8L21", "7L5"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			bide: ["7L29", "6L29"],
			bugbite: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			bugbuzz: ["8M", "7E", "6E", "5E", "4E"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["8M", "8L40", "7L37", "6M", "6L37", "5M", "5L45", "4M", "4L45", "3M", "3L45"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "7E", "6E", "5E", "5D", "4M", "4E", "3T", "3E"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "8L10", "7M", "7L33", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3L25"],
			feintattack: ["7E", "6E", "5E", "4E", "3E"],
			finalgambit: ["8E", "7E", "6E", "5E"],
			flail: ["8E"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			furyswipes: ["8L30", "7L13", "6L13", "5L14", "4L14", "3L14"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M", "3M"],
			gust: ["8E", "7E", "6E", "5E", "4E", "3E"],
			harden: ["8L5", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			leechlife: ["8M", "7M", "6L5", "5L5", "5D", "4L5", "3L5"],
			metalclaw: ["8L25", "7L21", "6L21", "5L38", "4L38", "3L38"],
			mimic: ["3T"],
			mindreader: ["8L35", "7L25", "6L19", "5L19", "4L19", "3L19"],
			mudslap: ["8L15", "7L17", "6L17", "5L31", "4T", "4L31", "3T", "3L31"],
			naturalgift: ["4M"],
			nightslash: ["8E", "7E", "6E", "5E", "5D", "4E"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L9", "6L9", "5L9", "4L9", "3L9"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scratch: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			silverwind: ["7E", "6E", "5E", "4E", "3E"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spite: ["7T", "6T", "5T", "4T"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			xscissor: ["8M", "7M", "6M", "5M", "4M"],
		},
	},
	ninjask: {
		learnset: {
			absorb: ["8L23", "7L1"],
			acrobatics: ["8M"],
			aerialace: ["8L1", "7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L15", "7L17", "6L17", "5L38", "4L38", "3L38"],
			aircutter: ["4T"],
			airslash: ["8M"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "8L1", "7L35", "6L35", "5L45", "4L45", "3L45"],
			bugbite: ["8L29", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			bugbuzz: ["8M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M", "3M"],
			defog: ["7T", "4M"],
			dig: ["8M", "8L1", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["8L0", "7M", "7L1", "6M", "6L20", "5M", "5L20", "4M", "4L20", "3M", "3L20"],
			dualwingbeat: ["8T"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["8L0", "7L1", "6L20", "5L20", "4T", "4L20", "3T", "3L20"],
			furyswipes: ["8L36", "7L13", "6L13", "5L14", "4L14", "3L14"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			laserfocus: ["7T"],
			leechlife: ["8M", "7M", "6L1", "5L1", "4L1", "3L1"],
			metalclaw: ["8L1"],
			mimic: ["3T"],
			mindreader: ["8L43", "7L29", "6L19", "5L19", "4L19", "3L19"],
			mudslap: ["8L1", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scratch: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			screech: ["8M", "8L0", "7L1", "6L20", "5L20", "4L20", "3L20"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			silverwind: ["4M"],
			skittersmack: ["8T"],
			slash: ["8L50", "7L23", "6L23", "5L31", "4L31", "3L31"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spite: ["7T", "6T", "5T", "4T"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "8L57", "7M", "7L41", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3T", "3L25"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["8M", "7M", "6M", "5M", "4M"],
			xscissor: ["8M", "8L64", "7M", "7L47", "6M", "6L47", "5M", "5L52", "4M", "4L52"],
		},
	},
	shedinja: {
		learnset: {
			absorb: ["8L23", "7L1"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "4R", "3R"],
			allyswitch: ["8M", "7T"],
			batonpass: ["4R", "3R"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["8M"],
			confide: ["7M", "6M"],
			confuseray: ["8L15", "7L29", "6L29", "5L31", "4L31", "3L31", "3S0"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["8M", "8L1", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			furyswipes: ["8L36", "7L13", "6L13", "5L14", "4L14", "3L14"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grudge: ["8L1", "7L37", "6L37", "5L45", "4L45", "3L45", "3S0"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			healblock: ["7L41", "6L41", "5L52", "4L52"],
			hex: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			leechlife: ["8M", "7M", "6L5", "5L5", "4L5", "3L5"],
			metalclaw: ["8L1"],
			mimic: ["3T"],
			mindreader: ["8L43", "7L25", "6L19", "5L19", "4L19", "3L19"],
			mudslap: ["8L1", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			phantomforce: ["8M", "8L64", "7L45", "6L45"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L9", "5L9", "4L9", "3L9"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scratch: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			screech: ["4R", "3R"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "8L50", "7M", "7L33", "6M", "6L33", "5M", "5L59", "4M", "4L59", "3M", "3L38", "3S0"],
			shadowclaw: ["8M", "8L1", "7M", "6M", "5M", "4M"],
			shadowsneak: ["8L29", "7L21", "6L21", "5L38", "4L38"],
			skittersmack: ["8T"],
			slash: ["4R", "3R"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spite: ["8L57", "7T", "7L17", "6T", "6L17", "5T", "5L25", "4T", "4L25", "3L25", "3S0"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swordsdance: ["4R", "3R"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
			xscissor: ["8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 50, moves: ["spite", "confuseray", "shadowball", "grudge"], pokeball: "pokeball"},
		],
	},
	whismur: {
		learnset: {
			astonish: ["8L1", "7L8", "6L8", "5L11", "4L11", "3L11"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			captivate: ["4M"],
			circlethrow: ["8E", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			defensecurl: ["3T"],
			disarmingvoice: ["8E", "7E", "6E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			echoedvoice: ["8L5", "7M", "7L4", "6M", "6L4", "5M"],
			endeavor: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4E"],
			endure: ["8M", "4M", "3T"],
			extrasensory: ["8E", "7E", "6E", "5E", "4E", "3E"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["8M", "7E", "6E", "5E"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			hammerarm: ["8E", "7E", "6E", "5E", "4E"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			howl: ["8L10", "7L11", "6L11", "5L15", "4L15", "3L15"],
			hypervoice: ["8M", "8L45", "7T", "7L39", "6T", "6L39", "5T", "5L51", "4L45", "3L45"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			pound: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["3T"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L15", "7M", "7L32", "6M", "6L32", "5M", "5L45", "4M", "4L41", "3M", "3L41"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["8L25", "7M", "7L29", "6M", "6L29", "5M", "5L35", "4M", "4L35", "3M", "3L35"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L40", "7L15", "6L15", "5L31", "4L31", "3L31"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "8L15", "7M", "7L36", "6M", "6L36", "5T", "5L45", "4M", "4L41", "3T", "3L41"],
			smellingsalts: ["7E", "6E", "5E", "4E", "3E"],
			smokescreen: ["8E", "7E", "6E", "5E", "4E"],
			snore: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			stomp: ["8L21", "7L22", "6L22", "5L25", "4L25", "3L25"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			supersonic: ["8L30", "7L18", "6L18", "5L21", "4L21", "3L21"],
			swagger: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			synchronoise: ["7L43", "6L41", "5L41"],
			takedown: ["8E", "7E", "6E", "5E", "4E", "3E"],
			teeterdance: ["3S0"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "8L35", "7T", "7L25", "6T", "6L5", "5T", "5L5", "5D", "4T", "4L5", "3L5", "3S0"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlwind: ["8E", "7E"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "5D", "4T"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["pound", "uproar", "teeterdance"], pokeball: "pokeball"},
		],
	},
	loudred: {
		learnset: {
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bite: ["8L0", "7L1", "6L20", "5L20", "4L20"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["8L1", "7M", "7L1", "6M", "6L1", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["8M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			howl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hypervoice: ["8M", "8L57", "7T", "7L45", "6T", "6L45", "5T", "5L65", "4L57", "3L57"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			pound: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["3T"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L15", "7M", "7L36", "6M", "6L36", "5M", "5L57", "4M", "4L51", "3M", "3L51"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["8L29", "7M", "7L32", "6M", "6L32", "5M", "5L43", "4M", "4L43", "3M", "3L43"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L50", "7L15", "6L15", "5L37", "4L37", "3L37"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "8L15", "7M", "7L41", "6M", "6L41", "5T", "5L57", "4M", "4L51", "3T", "3L51"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			stomp: ["8L23", "7L23", "6L23", "5L29", "4L29", "3L29"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			supersonic: ["8L36", "7L18", "6L18", "5L23", "4L23", "3L23"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			synchronoise: ["7L50", "6L50", "5L51"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "8L43", "7T", "7L27", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 6, level: 16, maxEggMoves: 1},
		],
	},
	exploud: {
		learnset: {
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			bite: ["8L1", "7L1", "6L20", "5L20", "4L20"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			boomburst: ["8L72", "7L1", "6L1"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["8M", "8L0", "7L1", "6L40", "5L40", "4L40"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["8L1", "7M", "7L1", "6M", "6L1", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["8M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			howl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "8L81", "7M", "7L64", "6M", "6L64", "5M", "5L79", "4M", "4L71", "3M", "3L40", "3S1"],
			hypervoice: ["8M", "8L63", "7T", "7L47", "6T", "6L47", "5T", "5L71", "4L63", "3L63", "3S0"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icefang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			pound: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["3T"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L15", "7M", "7L36", "6M", "6L36", "5M", "5L55", "4M", "4L55", "3M", "3L55", "3S0"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["8L29", "7M", "7L32", "6M", "6L32", "5M", "5L45", "4M", "4L45", "3M", "3L45", "3S0", "3S1"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L54", "7L15", "6L15", "5L37", "4L37", "3L37", "3S1"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "8L15", "7M", "7L42", "6M", "6L42", "5T", "5L63", "4M", "4L55", "3T", "3L55", "3S0"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			stomp: ["8L23", "7L23", "6L23", "5L29", "4L29", "3L29", "3S1"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			supersonic: ["8L36", "7L18", "6L18", "5L23", "4L23", "3L23"],
			surf: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			synchronoise: ["7L53", "6L53", "5L55"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			terrainpulse: ["8T"],
			thunderfang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "8L45", "7T", "7L27", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 100, moves: ["roar", "rest", "sleeptalk", "hypervoice"], pokeball: "pokeball"},
			{generation: 3, level: 50, moves: ["stomp", "screech", "hyperbeam", "roar"], pokeball: "pokeball"},
		],
	},
	makuhita: {
		learnset: {
			armthrust: ["9L7", "7L7", "6L7", "5L7", "5D", "4L7", "3L10", "3S0"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bellydrum: ["9L25", "7L25", "6L25", "5L25", "4L25", "3L37"],
			bodypress: ["9M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M", "3S0"],
			bulkup: ["9M", "9L22", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			bulletpunch: ["9E", "7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			chipaway: ["7E", "6E", "5E"],
			closecombat: ["9M", "9L40", "7L40", "6L40", "5L40", "4L40"],
			confide: ["7M", "6M"],
			counter: ["9E", "7E", "6E", "5E", "4E", "3T", "3E"],
			crosschop: ["9E", "7E", "6E", "5E", "4E", "3E"],
			detect: ["9L28", "7E", "6E", "5E", "4E", "3E"],
			dig: ["9M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dynamicpunch: ["9E", "7E", "6E", "5E", "4E", "3T", "3E"],
			earthquake: ["9M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "9L37", "7L37", "6L37", "5L37", "4M", "4L37", "3T", "3L40"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9L10", "7L10", "6L10", "5L13", "4L13", "3L19"],
			feint: ["9E", "7E", "6E", "5E", "4E"],
			feintattack: ["7E", "6E", "5E", "4E", "3E"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			focuspunch: ["9L34", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			forcepalm: ["9L13", "7L13", "6L13", "5L28", "4L28"],
			foresight: ["7E", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			heavyslam: ["9M", "9L46", "7L46", "6L46", "5L46"],
			helpinghand: ["9M", "9E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "7T", "6T", "5T", "5D", "4T", "3T"],
			knockoff: ["9L19", "7T", "7L19", "6T", "6L19", "5T", "5L19", "4T", "4L19", "3L28"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["9M", "3T"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["7E", "6E", "5E", "4E", "3E"],
			reversal: ["9M", "9L43", "7L43", "6L43", "5L43", "4L43", "3L49"],
			rockclimb: ["4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M", "3S0"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			sandattack: ["9L4", "7L4", "6L4", "5L4", "4L4", "3L4"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["9L31", "7L31", "6L31", "5L31", "4L31", "3T", "3L46"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			smellingsalts: ["7L28", "6L22", "5L22", "4L22", "3L31"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["7T", "6T", "5T", "4T"],
			surf: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M"],
			tackle: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			vacuumwave: ["4T"],
			vitalthrow: ["7L22", "6L10", "5L10", "4L10", "3L13"],
			wakeupslap: ["7L34", "7E", "6L34", "6E", "5L34", "5E", "4L34", "4E"],
			whirlpool: ["4M"],
			whirlwind: ["9L16", "7L16", "6L16", "5L16", "4L16", "3L22"],
			wideguard: ["9E", "7E", "6E", "5E"],
			workup: ["7M", "5M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 18, moves: ["refresh", "brickbreak", "armthrust", "rocktomb"]},
		],
	},
	hariyama: {
		learnset: {
			armthrust: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bellydrum: ["9L26", "7L26", "6L26", "5L27", "4L27", "3L40"],
			bodypress: ["9M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			brine: ["9L1", "7L1", "6L1", "5L1", "4M", "4L1"],
			bulkup: ["9M", "9L22", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "9L46", "7L46", "6L46", "5L52", "4L52"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			detect: ["9L30"],
			dig: ["9M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M"],
			dynamicpunch: ["3T"],
			earthquake: ["9M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "9L42", "7L42", "6L42", "5L47", "4M", "4L47", "3T", "3L44"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9L10", "7L10", "6L10", "5L13", "4L13", "3L19"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			focuspunch: ["9L38", "7T", "6T", "4M", "3M"],
			forcepalm: ["9L13", "7L13", "6L13", "5L32", "4L32"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			headlongrush: ["9L60"],
			heavyslam: ["9M", "9L54", "7L54", "6L54", "5L62"],
			helpinghand: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			ironhead: ["9M", "7T", "6T", "5T", "4T"],
			knockoff: ["9L19", "7T", "7L19", "6T", "6L19", "5T", "5L19", "4T", "4L19", "3L29"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["9M", "3T"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "9L50", "7L50", "6L50", "5L57", "4L57", "3L55"],
			rockclimb: ["4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			sandattack: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["9L34", "7L34", "6L34", "5L37", "4L37", "3T", "3L51"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			smellingsalts: ["7L30", "6L22", "5L22", "4L22", "3L33"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			stompingtantrum: ["9M", "7T"],
			stoneedge: ["9M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["7T", "6T", "5T", "4T"],
			surf: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M"],
			tackle: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["7T"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			vacuumwave: ["4T"],
			vitalthrow: ["7L22", "6L10", "5L10", "4L10", "3L13"],
			wakeupslap: ["7L38", "6L38", "5L42", "4L42"],
			whirlpool: ["4M"],
			whirlwind: ["9L16", "7L16", "6L16", "5L16", "4L16", "3L22"],
			workup: ["7M", "5M"],
			zenheadbutt: ["9M"],
		},
		encounters: [
			{generation: 6, level: 22},
		],
	},
	nosepass: {
		learnset: {
			ancientpower: ["5D", "4T"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "7L7", "7E", "6T", "6L7", "6E", "5T", "5L19", "5E", "4T", "4L19", "4E", "3L16"],
			bodyslam: ["3T"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			dazzlinggleam: ["7M", "6M"],
			defensecurl: ["3T"],
			discharge: ["7L31", "6L31", "5L55", "4L49"],
			doubleedge: ["7E", "6E", "5E", "4E", "3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthpower: ["7T", "7L37", "6T", "6L37", "5T", "5L79", "4T", "4L73"],
			earthquake: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["7E", "6E", "5E", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			firepunch: ["7T", "6T", "5T", "4T", "3T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gravity: ["7T", "6T", "5T", "4T"],
			harden: ["7L4", "6L4", "5L7", "4L7", "3L7"],
			headbutt: ["4T"],
			helpinghand: ["3S0"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "6T", "5T", "4T", "3T"],
			irondefense: ["7T", "6T", "5T", "4T"],
			lockon: ["7L43", "6L43", "5L73", "4L67", "3L46"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			magnitude: ["7E", "6E", "5E", "4E", "3E"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powergem: ["7L25", "6L25", "5L49", "4L49"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "7L16", "6M", "6L16", "5M", "5L43", "4M", "4L43", "3M", "3L37"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["7L28", "6L18", "5L18"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "7L22", "6M", "6L22", "5M", "5L31", "4M", "4L31", "3T", "3L28", "3S0"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rockthrow: ["7L10", "6L10", "5L13", "4L13", "3L13"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["7E", "6E", "5E", "4T", "4E", "3T", "3E"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["7M", "7L34", "6M", "6L34", "5M", "5L37", "4M", "4L37", "3M", "3L31"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			spark: ["7L19", "6L19", "5L25"],
			stealthrock: ["7T", "7E", "6T", "6E", "5T", "5E", "5D", "4M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "7L40", "6M", "6L40", "5M", "5L61", "4M", "4L55"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			taunt: ["7M", "6M", "5M", "4M", "3M"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M", "3S0"],
			thunderpunch: ["7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "7L13", "6M", "6L13", "5M", "5L25", "4M", "4L25", "3T", "3L22", "3S0"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			voltswitch: ["7M", "6M", "5M"],
			wideguard: ["7E", "6E"],
			zapcannon: ["7L43", "6L43", "5L67", "4L61", "3L43"],
		},
		eventData: [
			{generation: 3, level: 26, moves: ["helpinghand", "thunderbolt", "thunderwave", "rockslide"]},
		],
	},
	probopass: {
		learnset: {
			allyswitch: ["7T"],
			ancientpower: ["4T"],
			attract: ["7M", "6M", "5M", "4M"],
			block: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			dazzlinggleam: ["7M", "6M"],
			discharge: ["7L31", "6L31", "5L55", "4L49"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "7L37", "6T", "6L37", "5T", "5L79", "4T", "4L73"],
			earthquake: ["7M", "6M", "5M", "4M"],
			endure: ["4M"],
			explosion: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			firepunch: ["7T", "6T", "5T", "4T"],
			flashcannon: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			gravity: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			icepunch: ["7T", "6T", "5T", "4T"],
			irondefense: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			ironhead: ["7T", "6T", "5T", "4T"],
			lockon: ["7L43", "6L43", "5L73", "4L67"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magnetbomb: ["7L1", "6L1", "5L1", "4L1"],
			magneticflux: ["7L1"],
			magnetrise: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powergem: ["7L25", "6L25", "5L49", "4L49"],
			protect: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "7L16", "6M", "6L16", "5M", "5L43", "4M", "4L43"],
			return: ["7M", "6M", "5M", "4M"],
			rockblast: ["7L28", "6L18", "5L18"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "7L22", "6M", "6L22", "5M", "5L31", "4M", "4L31"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["7M", "7L34", "6M", "6L34", "5M", "5L37", "4M", "4L37"],
			secretpower: ["6M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["7T", "6T", "5T", "4T"],
			spark: ["7L19", "6L19", "5L25"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "7L40", "6M", "6L40", "5M", "5L61", "4M", "4L55"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			taunt: ["7M", "6M", "5M", "4M"],
			telekinesis: ["7T"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			thunderpunch: ["7T", "6T", "5T", "4T"],
			thunderwave: ["7M", "7L13", "6M", "6L13", "5M", "5L25", "4M", "4L25"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			triattack: ["7L1"],
			voltswitch: ["7M", "6M", "5M"],
			wideguard: ["7L1", "6L1"],
			zapcannon: ["7L43", "6L43", "5L67", "4L61"],
		},
	},
	skitty: {
		learnset: {
			assist: ["7L31", "6L22", "5L22", "4L18", "3L19"],
			attract: ["7M", "7L10", "6M", "6L8", "5M", "5L8", "4M", "4L4", "3M", "3L7", "3S2"],
			batonpass: ["7E", "6E", "5E", "4E", "3E"],
			blizzard: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			calmmind: ["7M", "6M", "5M", "4M", "3M"],
			captivate: ["7L43", "7E", "6L43", "6E", "5L46", "5E", "5D", "4M", "4L42"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["7L25", "6L25", "5L25", "4L22", "3L25"],
			confide: ["7M", "6M"],
			copycat: ["7L19", "6L18", "5L18", "4L11"],
			cosmicpower: ["7E", "6E"],
			covet: ["7T", "7L34", "6T", "6L34", "5T", "5L36", "4L32", "3L31"],
			defensecurl: ["3T"],
			dig: ["6M", "5M", "4M", "3M"],
			disarmingvoice: ["7L13", "6L13"],
			doubleedge: ["7L40", "6L40", "5L42", "4L39", "3T", "3L39"],
			doubleslap: ["7L16", "6L15", "5L15", "4L15", "3L15"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fakeout: ["7L1", "7E", "6L1", "6E", "5L1", "5E", "4L1", "4E"],
			faketears: ["7E", "6E", "5E", "4E", "3E"],
			feintattack: ["7L22", "6L22", "5L29", "4L25", "3L27"],
			flash: ["6M", "5M", "4M", "3M"],
			foresight: ["7L4", "6L4", "5L4", "5D", "4L4"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growl: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1", "3S2"],
			headbutt: ["4T"],
			healbell: ["7T", "7L37", "6T", "6L37", "5T", "5L39", "4T", "4L36", "3L37"],
			helpinghand: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["7T", "6T", "5T"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			mimic: ["3T"],
			mudbomb: ["7E", "6E", "5E", "4E"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			payday: ["3S0"],
			playrough: ["7L46", "6L46"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T", "3S1"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			simplebeam: ["7E", "6E", "5E"],
			sing: ["7L7", "6L7", "5L11", "4L8", "3L13"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			suckerpunch: ["7E", "6E", "5E", "4T", "4E"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tackle: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1", "3S2"],
			tailwhip: ["7L1", "6L1", "5L1", "4L1", "3L3", "3S0", "3S1", "3S2"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			tickle: ["7E", "6E", "5E", "5D", "4E", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "7E", "6T", "6E", "5T", "5E", "4E", "3E"],
			wakeupslap: ["7L28", "6L28", "5L32", "4L29"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wildcharge: ["7M", "6M", "5M"],
			wish: ["7E", "6E", "5E", "4E", "3E"],
			workup: ["7M", "5M"],
			zenheadbutt: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, abilities: ["cutecharm"], moves: ["tackle", "growl", "tailwhip", "payday"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, abilities: ["cutecharm"], moves: ["growl", "tackle", "tailwhip", "rollout"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", abilities: ["cutecharm"], moves: ["growl", "tackle", "tailwhip", "attract"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 3, level: 3, gender: "F", ivs: {hp: 5, atk: 4, def: 4, spa: 5, spd: 4, spe: 4}, abilities: ["cutecharm"], pokeball: "pokeball"},
		],
	},
	delcatty: {
		learnset: {
			attract: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1", "3S0"],
			blizzard: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			calmmind: ["7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			defensecurl: ["3T"],
			dig: ["6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleslap: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fakeout: ["7L1", "6L1", "5L1", "4L1"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growl: ["3L1"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["7T", "6T", "5T"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M", "3S0"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M", "3S0"],
			sing: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			stompingtantrum: ["7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetkiss: ["3S0"],
			swift: ["4T", "3T"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wildcharge: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zenheadbutt: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 18, abilities: ["cutecharm"], moves: ["sweetkiss", "secretpower", "attract", "shockwave"]},
		],
	},
	sableye: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			allyswitch: ["8M", "7T"],
			astonish: ["9L3", "8L3", "7L9", "6L9", "5L11", "4L11", "3L13"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M"],
			calmmind: ["9M", "8M", "7M", "6M", "6S3", "5M", "4M", "3M"],
			captivate: ["7E", "6E", "5E", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "8L6", "7L31", "6L31", "5L46", "4L46", "3L37"],
			counter: ["3T"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			detect: ["9L18", "8L18", "7L14", "6L14", "5L22", "4L22", "3L25"],
			dig: ["9M", "8M", "6M", "5M", "4M", "3M"],
			disable: ["9L15", "8L15"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "8M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "4M", "3T"],
			energyball: ["9M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9L12", "8L12", "7L21", "6L18", "5L18", "4L18", "3L21"],
			feint: ["9E", "8E", "7E", "6E", "5E", "4E"],
			feintattack: ["7L19", "6L19", "5L32", "4L32", "3L29", "3S1"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			flash: ["6M", "5M", "4M", "3M"],
			flatter: ["9E", "8E", "7E", "6E", "5E", "4E"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7L4", "6L4", "5L4", "5D", "4L4", "3L5", "3S0"],
			foulplay: ["9M", "9L48", "8M", "8L48", "7T", "7L41", "6T", "6L41", "5T", "5L50", "5S2"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			furyswipes: ["9L24", "8L24", "7L11", "6L11", "5L15", "4L15", "3L17"],
			gigadrain: ["9M"],
			gigaimpact: ["9M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["8M"],
			headbutt: ["4T"],
			helpinghand: ["9M", "8M", "3S1"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M", "8M", "7E", "6E"],
			incinerate: ["6M", "5M"],
			knockoff: ["9L27", "8L27", "7T", "7L26", "6T", "6L26", "5T", "5L29", "4T", "4L29", "3L33"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			lightscreen: ["9M"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			meanlook: ["9L36", "8L36", "7L46", "7E", "6L1", "6E", "5L60", "5E", "4L57", "3L45"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metalburst: ["9E", "8E", "7E", "6E", "5E"],
			metalclaw: ["9M"],
			metronome: ["9M", "8M", "3T"],
			mimic: ["3T"],
			moonlight: ["7E", "6E", "5E", "4E", "3E"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			nastyplot: ["9M", "8M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			nightshade: ["9M", "9L21", "8L21", "7L6", "6L6", "5L8", "4L8", "3L9", "3S0"],
			octazooka: ["5S2"],
			ominouswind: ["4T"],
			painsplit: ["7T", "6T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			phantomforce: ["9M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			poltergeist: ["8T"],
			powergem: ["9M", "9L39", "8M", "8L39", "7L36", "6L36", "5L43", "4L43"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			punishment: ["7L24", "6L24", "5L36", "4L36"],
			quash: ["9L30", "8L30", "7M", "7L44", "6M", "6L44"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			recover: ["9E", "8E", "7E", "6E", "6S3", "6S4", "5E", "4E", "3E", "3S1"],
			reflect: ["9M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "9L45", "8M", "8L45", "7M", "7L39", "6M", "6L39", "6S3", "5M", "5L57", "4M", "4L53", "3M", "3L41", "3S1"],
			shadowclaw: ["9M", "9L33", "8M", "8L33", "7M", "7L29", "6M", "6L29", "5M", "5L39", "4M", "4L39"],
			shadowsneak: ["9L9", "8L9", "7L16", "6L16", "5L25", "4L25"],
			shockwave: ["7T", "6T", "6S4", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "5D", "4T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9E", "8E", "7E", "6E", "5E", "5D", "4T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "6S4", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M"],
			tickle: ["5S2"],
			torment: ["9E", "8E", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "5S2", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			willowisp: ["9M", "8M", "7M", "6M", "6S3", "6S4", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			xscissor: ["9M"],
			zenheadbutt: ["9M", "9L42", "8M", "8L42", "7T", "7L34", "6T", "6L1", "5T", "5L53", "4T", "4L50"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", abilities: ["keeneye"], moves: ["leer", "scratch", "foresight", "nightshade"], pokeball: "pokeball"},
			{generation: 3, level: 33, abilities: ["keeneye"], moves: ["helpinghand", "shadowball", "feintattack", "recover"]},
			{generation: 5, level: 50, gender: "M", isHidden: true, moves: ["foulplay", "octazooka", "tickle", "trick"], pokeball: "cherishball"},
			{generation: 6, level: 50, nature: "Relaxed", ivs: {hp: 31, spa: 31}, isHidden: true, moves: ["calmmind", "willowisp", "recover", "shadowball"], pokeball: "cherishball"},
			{generation: 6, level: 100, nature: "Bold", isHidden: true, moves: ["willowisp", "recover", "taunt", "shockwave"], pokeball: "cherishball"},
		],
	},
	mawile: {
		learnset: {
			ancientpower: ["8E", "7E", "6E", "5E", "4T", "4E", "3E"],
			assurance: ["8M"],
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "8L8", "7L25", "6L25", "5L31", "4L31", "3L31"],
			bite: ["8L12", "7L9", "6L9", "5L11", "4L11", "3L11"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M"],
			captivate: ["7E", "6E", "5E", "4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["8M", "8L28", "7L29", "6L29", "5L36", "4L36", "3L36"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainingkiss: ["8M"],
			dynamicpunch: ["3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fairywind: ["8L4", "7L1", "6L1"],
			faketears: ["8M", "8L44", "7L5", "6L5", "5L6", "5D", "4L6", "3L6", "3S0"],
			falseswipe: ["8M", "7M", "6M", "5M", "4E", "3E", "3S1"],
			feintattack: ["7L21", "6L21", "5L26", "4L26", "3L26"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["8M", "7E", "6E", "6S2", "5E", "5D", "4E"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "6L1"],
			guardswap: ["8M", "7E", "6E", "5E", "4E"],
			headbutt: ["4T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icefang: ["8M", "7E", "6E", "5E", "4E"],
			icepunch: ["8M", "7T", "6T", "5T", "5D", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "8L24", "7T", "7L33", "6T", "6L33", "5T", "5L41", "4T", "4L41", "3L41", "3S1"],
			ironhead: ["8M", "8L36", "7T", "7L1", "6T", "6L1", "6S2", "6S3", "5T", "5L56", "4T", "4L56"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metalburst: ["7E", "6E", "5E"],
			mimic: ["3T"],
			mistyterrain: ["8M", "7E", "6E"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			playrough: ["8M", "8L48", "7L1", "6L1", "6S2", "6S3"],
			poisonfang: ["7E", "6E", "5E", "4E", "3E"],
			poweruppunch: ["8E", "7E", "6M"],
			protect: ["8M", "7M", "6M", "6S3", "5M", "4M", "3M"],
			psychicfangs: ["8M"],
			psychup: ["7M", "6M", "5M", "4E", "3T", "3E"],
			punishment: ["7E", "6E", "5E", "4E"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["8E", "7E", "6E", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			sing: ["3S1"],
			slam: ["8E", "7E", "6E", "5E"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spitup: ["8L16", "7L41", "6L41", "5L51", "4L51", "3L46"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			stockpile: ["8L16", "7L41", "6L41", "5L51", "4L51", "3L46"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["8L20", "7L37", "7E", "6L37", "6E", "6S2", "6S3", "5L46", "5E", "4T", "4L46", "4E"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swallow: ["8L16", "7L41", "6L41", "5L51", "4L51", "3L46"],
			sweetscent: ["8L32", "7L13", "6L13", "5L16", "4L16", "3L16"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			taunt: ["8M", "8L40", "7M", "7L1", "6M", "6L1", "5M", "4M", "3M"],
			thunderfang: ["8M", "7E", "6E", "5E", "4E"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			tickle: ["8E", "7E", "6E", "5E", "4E", "3E"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			visegrip: ["7L17", "6L17", "5L21", "4L21", "3L21", "3S1"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["astonish", "faketears"], pokeball: "pokeball"},
			{generation: 3, level: 22, moves: ["sing", "falseswipe", "visegrip", "irondefense"]},
			{generation: 6, level: 50, abilities: ["intimidate"], moves: ["ironhead", "playrough", "firefang", "suckerpunch"], pokeball: "cherishball"},
			{generation: 6, level: 100, abilities: ["intimidate"], moves: ["suckerpunch", "protect", "playrough", "ironhead"], pokeball: "cherishball"},
		],
	},
	aron: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			autotomize: ["8L40", "7L43", "6L39", "5L43"],
			bodypress: ["8M"],
			bodyslam: ["8M", "7E", "6E", "5E", "4E", "3T", "3E"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "6E", "5E", "4E"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L56", "7L40", "6L40", "5L50", "4L43", "3T", "3L44"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragonrush: ["8E", "7E", "6E", "5E", "4E"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endeavor: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			harden: ["8L1", "7L1", "6L1", "5L4", "5D", "4L4", "3L4"],
			headbutt: ["8L16", "7L7", "6L7", "5L11", "4T", "4L11", "3L10"],
			headsmash: ["8E", "7E", "6E", "5E", "5D", "4E"],
			heavyslam: ["8M", "8L52", "7L46", "6L43", "5L46"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			irondefense: ["8M", "8L48", "7T", "7L37", "6T", "6L15", "5T", "5L18", "4T", "4L18", "3L17"],
			ironhead: ["8M", "8L28", "7T", "7L22", "7E", "6T", "6L22", "6E", "5T", "5L29", "5E", "4T", "4L29", "4E"],
			irontail: ["8M", "8L44", "7T", "7L34", "6T", "6L34", "5T", "5L39", "4M", "4L39", "3M", "3L29"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			metalburst: ["8L60", "7L49", "6L49", "5L53", "4L46"],
			metalclaw: ["8L4", "7L10", "6L10", "5L15", "4L15", "3L13"],
			metalsound: ["8L33", "7L31", "6L31", "5L36", "4L36", "3L39"],
			mimic: ["3T"],
			mudslap: ["8E", "7L4", "6L4", "5L8", "4T", "4L8", "3T", "3L7"],
			naturalgift: ["4M"],
			protect: ["8M", "8L20", "7M", "7L16", "6M", "6L16", "5M", "5L32", "4M", "4L32", "3M", "3L34"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["8M", "7E", "6E"],
			roar: ["8L12", "7M", "7L19", "6M", "6L18", "5M", "5L22", "4M", "4L22", "3M", "3L21"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8L24", "7M", "7L25", "6M", "6L25", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "8L8", "7M", "7L13", "6M", "6L13", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			screech: ["8M", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smellingsalts: ["7E", "6E", "5E", "4E", "3E"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stomp: ["8E", "7E", "6E", "5E", "4E", "3E"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L36", "7L28", "6L22", "5L25", "4L25", "3L25"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
		},
	},
	lairon: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			autotomize: ["8L46", "7L47", "6L45", "5L51"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L70", "7L43", "6L43", "5L62", "4L51", "3T", "3L53"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8L16", "7L1", "6L1", "5L1", "4T", "4L1", "3L1"],
			heavyslam: ["8M", "8L64", "7L51", "6L51", "5L56"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			irondefense: ["8M", "8L58", "7T", "7L39", "6T", "6L15", "5T", "5L18", "4T", "4L18", "3L17"],
			ironhead: ["8M", "8L28", "7T", "7L22", "6T", "6L22", "5T", "5L29", "4T", "4L29"],
			irontail: ["8M", "8L52", "7T", "7L35", "6T", "6L35", "5T", "5L45", "4M", "4L45", "3M", "3L29"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			metalburst: ["8L76", "7L55", "6L55", "5L67", "4L56"],
			metalclaw: ["8L1", "7L10", "6L10", "5L15", "4L15", "3L13"],
			metalsound: ["8L35", "7L31", "6L31", "5L40", "4L40", "3L45"],
			mimic: ["3T"],
			mudslap: ["7L1", "6L1", "5L1", "4T", "4L1", "3T", "3L1"],
			naturalgift: ["4M"],
			protect: ["8M", "8L20", "7M", "7L16", "6M", "6L16", "5M", "5L34", "4M", "4L34", "3M", "3L37"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["8M"],
			roar: ["8L12", "7M", "7L19", "6M", "6L18", "5M", "5L22", "4M", "4L22", "3M", "3L21"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8L24", "7M", "7L25", "6M", "6L25", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "8L1", "7M", "7L13", "6M", "6L13", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L40", "7L28", "6L22", "5L25", "4L25", "3L25"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
		},
	},
	aggron: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			autotomize: ["8L48", "7L51", "6L48", "5L57"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["8M"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["8L80", "7L45", "6L45", "5L74", "4L57", "3T", "3L63", "3S0"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragonclaw: ["8M", "7M", "6M", "5M", "4M", "3M"],
			dragonpulse: ["8M", "7T", "6T", "5T", "4M"],
			dragontail: ["7M", "6M", "5M"],
			dynamicpunch: ["3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "6M", "6S2", "5M", "4M", "3M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["8L16", "7L1", "6L1", "5L1", "4T", "4L1", "3L1"],
			headsmash: ["6S2"],
			heavyslam: ["8M", "8L72", "7L57", "6L57", "5L65"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "8L64", "7T", "7L39", "6T", "6L15", "5T", "5L18", "4T", "4L18", "3L17"],
			ironhead: ["8M", "8L28", "7T", "7L22", "6T", "6L22", "6S2", "5T", "5L29", "4T", "4L29"],
			irontail: ["8M", "8L56", "7T", "7L35", "6T", "6L35", "5T", "5L48", "4M", "4L48", "3M", "3L29", "3S0", "3S1"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metalburst: ["8L88", "7L63", "6L63", "5L82", "4L65"],
			metalclaw: ["8L1", "7L10", "6L10", "5L15", "4L15", "3L13"],
			metalsound: ["8L35", "7L31", "6L31", "5L40", "4L40", "3L50", "3S0", "3S1"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudslap: ["7L1", "6L1", "5L1", "4T", "4L1", "3T", "3L1"],
			naturalgift: ["4M"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L20", "7M", "7L16", "6M", "6L16", "5M", "5L34", "4M", "4L34", "3M", "3L37", "3S0", "3S1"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["8M"],
			roar: ["8L12", "7M", "7L19", "6M", "6L18", "5M", "5L22", "4M", "4L22", "3M", "3L21"],
			rockblast: ["8M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8L24", "7M", "7L25", "6M", "6L25", "6S2", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "8L1", "7M", "7L13", "6M", "6L13", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			scaryface: ["8M"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L40", "7L28", "6L22", "5L25", "4L25", "3L25", "3S1"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 3, level: 100, moves: ["irontail", "protect", "metalsound", "doubleedge"], pokeball: "pokeball"},
			{generation: 3, level: 50, moves: ["takedown", "irontail", "protect", "metalsound"], pokeball: "pokeball"},
			{generation: 6, level: 50, nature: "Brave", abilities: ["rockhead"], moves: ["ironhead", "earthquake", "headsmash", "rockslide"], pokeball: "cherishball"},
		],
	},
	meditite: {
		learnset: {
			acupressure: ["9L33", "7L33", "6L33", "5L39"],
			aerialace: ["9M"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			bide: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulletpunch: ["9E", "7E", "6E", "5E", "4E"],
			calmmind: ["9M", "9L23", "7M", "7L23", "6M", "6L23", "5M", "5L25", "4M", "4L25", "3M", "3L28"],
			captivate: ["4M"],
			closecombat: ["9M"],
			confide: ["7M", "6M"],
			confusion: ["9L1", "7L7", "6L7", "5L8", "4L8", "3L9", "3S0", "3S1"],
			counter: ["9L44", "7L44", "6L44", "3T"],
			detect: ["9L9", "7L9", "6L9", "5L11", "4L11", "3L12", "3S1"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["9E", "7E", "6E", "5E", "4E", "3T", "3E", "3S1"],
			endure: ["9M", "9L12", "7L12", "6L12", "5D", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fakeout: ["9E", "7E", "6E", "5E", "4E", "3E"],
			feint: ["9L15", "7L15", "6L15", "5L22", "4L22"],
			firepunch: ["9M", "9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			forcepalm: ["9L17", "7L17", "6L17", "5L29", "4L29"],
			foresight: ["7E", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["9E", "7E", "6E", "5E", "4E"],
			headbutt: ["4T"],
			helpinghand: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7L20", "6M", "6L15", "5M", "5L15", "4M", "4L15", "3M", "3L17"],
			highjumpkick: ["9L28", "7L28", "6L28", "5L32", "4L32", "3L32"],
			icepunch: ["9M", "9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			imprison: ["9M"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			meditate: ["7L4", "6L4", "5L4", "5D", "4L4", "3L4", "3S0"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["9M", "3T"],
			mimic: ["3T"],
			mindreader: ["7L25", "6L18", "5L18", "4L18", "3L22"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			powerswap: ["9E", "7E", "6E", "5E", "4E"],
			powertrick: ["9L36", "7L36", "6L36", "5L43", "4L39"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L20"],
			psychic: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psychicterrain: ["9M"],
			psychocut: ["9E", "7E", "6E", "5E", "4E"],
			psychup: ["9L31", "7M", "7L31", "6M", "6L31", "5M", "5L36", "4M", "4L36", "3T", "3L36"],
			psyshock: ["9M", "7M", "6M", "5M"],
			quickguard: ["9E", "7E", "6E"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			recover: ["9L41", "7L41", "6L41", "5L50", "4L46", "3L44"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "9L39", "7L39", "6L39", "5L46", "4L43", "3L41"],
			rockslide: ["9M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["7E", "6M", "6E", "5E", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M", "3S1"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			storedpower: ["9M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T", "3L20"],
			swift: ["9M", "4T", "3T"],
			takedown: ["9M"],
			taunt: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderpunch: ["9M", "9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M"],
			vacuumwave: ["4T"],
			workup: ["9L1", "7M", "5M"],
			zenheadbutt: ["9M", "9L25", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["bide", "meditate", "confusion"], pokeball: "pokeball"},
			{generation: 3, level: 20, moves: ["dynamicpunch", "confusion", "shadowball", "detect"], pokeball: "pokeball"},
		],
	},
	medicham: {
		learnset: {
			acupressure: ["9L33", "7L33", "6L33", "5L42"],
			aerialace: ["9M"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			aurasphere: ["9M"],
			axekick: ["9L53"],
			batonpass: ["9M"],
			bide: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M", "3M"],
			calmmind: ["9M", "9L23", "7M", "7L23", "6M", "6L23", "5M", "5L25", "4M", "4L25", "3M", "3L28"],
			captivate: ["4M"],
			closecombat: ["9M"],
			confide: ["7M", "6M"],
			confusion: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			counter: ["9L53", "7L53", "6L53", "3T"],
			detect: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["3T"],
			endure: ["9M", "9L12", "7L12", "6L12", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			feint: ["9L15", "7L15", "6L15", "5L22", "4L22"],
			firepunch: ["9M", "9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T", "3L1"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			forcepalm: ["9L17", "7L17", "6L17", "5L29", "4L29"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			helpinghand: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7L20", "6M", "6L15", "5M", "5L15", "4M", "4L15", "3M", "3L17"],
			highjumpkick: ["9L28", "7L28", "6L28", "5L32", "4L32", "3L32"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T", "3L1"],
			imprison: ["9M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			meditate: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["9M", "3T"],
			mimic: ["3T"],
			mindreader: ["7L25", "6L18", "5L18", "4L18", "3L22"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			powertrick: ["9L36", "7L36", "6L36", "5L49", "4L42"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L20"],
			psychic: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psychicterrain: ["9M"],
			psychup: ["9L31", "7M", "7L31", "6M", "6L31", "5M", "5L36", "4M", "4L36", "3T", "3L36"],
			psyshock: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			recover: ["9L47", "7L47", "6L47", "5L62", "4L55", "3L54"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["9M", "7L42", "6L42", "5L55", "4L49", "3L46"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			storedpower: ["9M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T", "3L20"],
			swift: ["9M", "4T", "3T"],
			takedown: ["9M"],
			taunt: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderpunch: ["9M", "9L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T", "3L1"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M"],
			vacuumwave: ["4T"],
			workup: ["9L1", "7M", "5M"],
			zenheadbutt: ["9M", "9L25", "7T", "7L1", "6T", "6L1", "5T", "4T"],
		},
		encounters: [
			{generation: 4, level: 35},
			{generation: 6, level: 34, maxEggMoves: 1},
		],
	},
	electrike: {
		learnset: {
			agility: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bite: ["8L20", "7L24", "6L24", "5L28", "4L28", "3L33"],
			bodyslam: ["8M", "3T"],
			captivate: ["4M"],
			charge: ["8L36", "7L44", "6L44", "5L44", "4L44", "3L41"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "7E", "6E", "5E", "4E", "3E"],
			curse: ["8E", "7E", "6E", "5E", "4E", "3E"],
			discharge: ["8L32", "7L29", "7E", "6L29", "6E", "5L41", "5E", "4L41", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["8M", "7E", "6E"],
			electroball: ["8M", "7E", "6E", "5E"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["8M", "7E", "6E", "5E", "4E"],
			flameburst: ["7E", "6E", "5E"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			headbutt: ["8E", "7E", "6E", "5E", "4T", "4E", "3E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			howl: ["8L8", "7L7", "6L7", "5L12", "4L12", "3L12"],
			icefang: ["8M", "7E", "6E", "5E", "5D", "4E"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			leer: ["8L4", "7L4", "6L4", "5L9", "4L9", "3L9"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			odorsleuth: ["7L16", "6L16", "5L25", "4L25", "3L25"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychicfangs: ["8M"],
			quickattack: ["8L12", "7L10", "6L10", "5L17", "4L17", "3L17"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			roar: ["8L28", "7M", "7L34", "6M", "6L34", "5M", "5L36", "4M", "4L36", "3M", "3L28"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["8L16", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "5D", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spark: ["8E", "7L13", "6L13", "5L20", "4L20", "3L20"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "7E", "6E", "5E", "4T", "4E", "3T", "3E"],
			switcheroo: ["8E", "7E", "6E", "5E", "4E"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8L44", "7M", "7L49", "6M", "6L49", "5M", "5L52", "4M", "4L49", "3M", "3L36"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderfang: ["8M", "8L24", "7L19", "7E", "6L19", "6E", "5L33", "5E", "4L33", "4E"],
			thunderwave: ["8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L4", "5D", "4M", "4L4", "3T", "3L4"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4E", "3E"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			wildcharge: ["8M", "8L40", "7M", "7L39", "6M", "6L39", "5M", "5L49"],
		},
	},
	manectric: {
		learnset: {
			agility: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bite: ["8L20", "7L24", "6L24", "5L30", "4L30", "3L39", "3S0"],
			bodyslam: ["8M", "3T"],
			captivate: ["4M"],
			charge: ["8L42", "7L48", "6L48", "5L54", "4L54", "3L53"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			crunch: ["8M"],
			discharge: ["8L36", "7L30", "6L30", "5L49", "4L49"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M", "8L60", "7L1", "6L1"],
			electroball: ["8M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			howl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M"],
			icefang: ["8M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			odorsleuth: ["7L16", "6L16", "5L25", "4L25", "3L25"],
			overheat: ["8M", "7M", "6M", "6S1", "5M", "4M"],
			protect: ["8M", "7M", "6M", "6S1", "5M", "4M", "3M"],
			psychicfangs: ["8M"],
			quickattack: ["8L12", "7L10", "6L10", "5L17", "4L17", "3L17"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M", "3S0"],
			refresh: ["3S0"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			risingvoltage: ["8T"],
			roar: ["8L30", "7M", "7L36", "6M", "6L36", "5M", "5L42", "4M", "4L42", "3M", "3L31"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			shockwave: ["8L16", "7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spark: ["7L13", "6L13", "5L20", "4L20", "3L20"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["8M", "8L54", "7M", "7L54", "6M", "6L54", "5M", "5L66", "4M", "4L61", "3M", "3L45", "3S0"],
			thunderbolt: ["8M", "7M", "6M", "6S1", "5M", "4M", "3M"],
			thunderfang: ["8M", "8L24", "7L19", "6L19", "5L37", "4L37"],
			thunderwave: ["8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3T", "3L1"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T"],
			voltswitch: ["8M", "7M", "6M", "6S1", "5M"],
			wildcharge: ["8M", "8L48", "7M", "7L42", "6M", "6L42", "5M", "5L61"],
		},
		eventData: [
			{generation: 3, level: 44, moves: ["refresh", "thunder", "raindance", "bite"]},
			{generation: 6, level: 50, nature: "Timid", abilities: ["lightningrod"], moves: ["overheat", "thunderbolt", "voltswitch", "protect"], pokeball: "cherishball"},
		],
	},
	plusle: {
		learnset: {
			agility: ["7L37", "6L37", "5L48", "4L44", "3L47"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["7L34", "6L34", "5L44", "4L42", "3L40"],
			bestow: ["7L13", "6L13"],
			bodyslam: ["3T"],
			captivate: ["4M"],
			charge: ["7L28", "6L28", "5L38", "4L35", "3L31"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["7L25", "7E", "6L25"],
			confide: ["7M", "6M"],
			copycat: ["7L22", "6L22", "5L24", "4L24"],
			counter: ["3T"],
			covet: ["7T"],
			defensecurl: ["3T"],
			discharge: ["7L31", "7E", "6L31", "6E", "5E", "5D", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			echoedvoice: ["7M", "6M", "5M"],
			electroball: ["7L19", "6L19", "5L29"],
			electroweb: ["7T", "6T"],
			encore: ["7L10", "6L10", "5L17", "4L17", "3L22"],
			endure: ["4M", "3T"],
			entrainment: ["7L49", "6L1", "5L63"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			faketears: ["7E", "6L35", "5L21", "4L21", "3L28"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growl: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
			headbutt: ["4T"],
			helpinghand: ["7T", "7L4", "6T", "6L4", "5T", "5L10", "4T", "4L10", "3L13"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "7L40", "6T", "6L40", "5T", "5L51", "4T", "4L48"],
			lightscreen: ["7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7E", "6E", "5E"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			nastyplot: ["7L46", "6L1", "5L56", "4L51"],
			naturalgift: ["4M"],
			nuzzle: ["7L1", "6L1"],
			playnice: ["7L1", "6L1"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			quickattack: ["7L1", "6L1", "5L7", "4L7", "3L10", "3S1"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "5D", "4T"],
			sing: ["7E", "6E", "5E", "4E"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			spark: ["7L7", "6L7", "5L15", "4L15", "3L19"],
			substitute: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetkiss: ["7E", "6E", "5E", "4E"],
			swift: ["7L16", "6L16", "5L31", "4T", "4L29", "3T"],
			tearfullook: ["7E"],
			thunder: ["7M", "7L43", "6M", "6L42", "5M", "5L42", "4M", "4L38", "3M", "3L37"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "7L1", "6M", "6L1", "5M", "5L3", "5D", "4M", "4L3", "3T", "3L4", "3S0", "3S1"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["7T", "6T", "5T", "4T"],
			voltswitch: ["7M", "6M", "5M"],
			watersport: ["3S0"],
			wildcharge: ["7M", "6M", "5M"],
			wish: ["7E", "6E", "5E", "4E", "3E"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["growl", "thunderwave", "watersport"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["growl", "thunderwave", "quickattack"], pokeball: "pokeball"},
		],
	},
	minun: {
		learnset: {
			agility: ["7L37", "6L37", "5L48", "4L44", "3L47"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["7L34", "6L34", "5L44", "4L42", "3L40"],
			bodyslam: ["3T"],
			captivate: ["4M"],
			charge: ["7L28", "6L28", "5L38", "4L35", "3L31"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["7E", "6L21", "5L21", "4L21", "3L28"],
			confide: ["7M", "6M"],
			copycat: ["7L22", "6L22", "5L24", "4L24"],
			counter: ["3T"],
			covet: ["7T"],
			defensecurl: ["3T"],
			discharge: ["7L31", "7E", "6L31", "6E", "5E", "5D", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			echoedvoice: ["7M", "6M", "5M"],
			electroball: ["7L19", "6L19", "5L29"],
			electroweb: ["7T", "6T"],
			encore: ["7L10", "6L10", "5L17", "4L17", "3L22"],
			endure: ["4M", "3T"],
			entrainment: ["7L49", "6L1", "5L63"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			faketears: ["7L25", "7E", "6L25", "5L35", "4L31"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growl: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
			headbutt: ["4T"],
			helpinghand: ["7T", "7L4", "6T", "6L4", "5T", "5L10", "4T", "4L10", "3L13"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7E", "6E", "5E"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			mudsport: ["3S0"],
			nastyplot: ["7L46", "6L1", "5L56", "4L51"],
			naturalgift: ["4M"],
			nuzzle: ["7L1", "6L1"],
			playnice: ["7L1", "6L1"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			quickattack: ["7L1", "6L1", "5L7", "4L7", "3L10", "3S1"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "5D", "4T"],
			sing: ["7E", "6E", "5E", "4E"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			spark: ["7L7", "6L7", "5L15", "4L15", "3L19"],
			substitute: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetkiss: ["7E", "6E", "5E", "4E"],
			swift: ["7L16", "6L16", "5L31", "4T", "4L29", "3T"],
			switcheroo: ["7L13", "6L13"],
			tearfullook: ["7E"],
			thunder: ["7M", "7L43", "6M", "6L42", "5M", "5L42", "4M", "4L38", "3M", "3L37"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "7L1", "6M", "6L1", "5M", "5L3", "5D", "4M", "4L3", "3T", "3L4", "3S0", "3S1"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trumpcard: ["7L40", "6L40", "5L51", "4L48"],
			uproar: ["7T", "6T", "5T", "4T"],
			voltswitch: ["7M", "6M", "5M"],
			wildcharge: ["7M", "6M", "5M"],
			wish: ["7E", "6E", "5E", "4E", "3E"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["growl", "thunderwave", "mudsport"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["growl", "thunderwave", "quickattack"], pokeball: "pokeball"},
		],
	},
	volbeat: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["4T"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["7E", "6E", "5E", "4E", "3E"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["7L40", "7E", "6L40", "6E", "5L41", "5E", "4L41", "4E"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["7L8", "6L8", "5L9", "4L9", "3L5"],
			counter: ["3T"],
			dazzlinggleam: ["7M", "6M"],
			defog: ["7T"],
			dizzypunch: ["7E", "6E", "5E"],
			doubleedge: ["7L47", "6L45", "5L45", "4L45", "3T", "3L37"],
			doubleteam: ["7M", "7L5", "6M", "6L5", "5M", "5L5", "4M", "4L5", "3M", "3L9"],
			dynamicpunch: ["3T"],
			encore: ["7E", "6E", "5E", "4E"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["7L1", "6M", "6L1", "5M", "5L1", "5D", "4M", "4L1", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["7T", "6T", "5T", "4M", "3M"],
			helpinghand: ["7T", "7L36", "6T", "6L33", "5T", "5L33", "4T", "4L33", "3L33"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "7L50"],
			lightscreen: ["7M", "6M", "5M", "4M", "3M"],
			lunge: ["7E"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["3T"],
			mimic: ["3T"],
			moonlight: ["7L19", "6L13", "5L13", "4L13", "3L13"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			playrough: ["7L43", "6L43"],
			poweruppunch: ["6M"],
			protect: ["7M", "7L29", "6M", "6L29", "5M", "5L29", "4M", "4L29", "3M", "3L29"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			quickattack: ["7L12", "6L12", "5L17", "4L17", "3L17"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["7E", "6E", "5E", "3T"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "7L26", "6T", "6L25", "5T", "5L25", "4T", "4L25", "3L25"],
			silverwind: ["7E", "6E", "5E", "4M", "4E", "3E"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["7L15", "6M", "6L15", "5M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tackle: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			tailglow: ["7L22", "6L21", "5L21", "4L21", "3L21"],
			tailwind: ["7T", "6T", "5T", "5D", "4T"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["7T", "7E", "6T", "6E", "5T", "5E", "5D", "4E", "3E"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			zenheadbutt: ["7T", "7L33", "6T", "6L33", "5T", "5L37", "4T", "4L37"],
		},
	},
	illumise: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			aircutter: ["4T"],
			aromatherapy: ["7E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["7E", "6E", "5E", "4E", "3E"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["7L40", "7E", "6L40", "6E", "5L41", "5E", "4L41", "4E"],
			captivate: ["7E", "6E", "5E", "4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["7L9", "6L9", "5L9", "5D", "4L9", "3L9"],
			confide: ["7M", "6M"],
			confuseray: ["7E", "6E", "5E"],
			counter: ["3T"],
			covet: ["7T", "7L47", "6T", "6L45", "5T", "5L45", "4L45", "3L37"],
			dazzlinggleam: ["7M", "6M"],
			defog: ["7T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			encore: ["7L26", "7E", "6L25", "6E", "5L25", "5E", "4L25", "4E", "3L25"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			faketears: ["7E", "6E", "5E", "5D"],
			flash: ["6M", "5M", "4M", "3M"],
			flatter: ["7L29", "6L29", "5L29", "4L29", "3L29"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigadrain: ["7T", "6T", "5T", "4M", "3M"],
			growth: ["7E", "6E", "5E", "4E", "3E"],
			helpinghand: ["7T", "7L36", "6T", "6L33", "5T", "5L33", "4T", "4L33", "3L33"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "7L50"],
			lightscreen: ["7M", "6M", "5M", "4M", "3M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["3T"],
			mimic: ["3T"],
			moonlight: ["7L19", "6L13", "5L13", "4L13", "3L13"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			playnice: ["7L1", "6L1"],
			playrough: ["7L43", "6L43"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			quickattack: ["7L12", "6L12", "5L17", "4L17", "3L17"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			silverwind: ["7E", "6E", "5E", "4M", "4E", "3E"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["7L15", "6M", "6L15", "5M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetscent: ["7L5", "6L5", "5L5", "4L5", "3L5"],
			swift: ["4T", "3T"],
			tackle: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "6T", "5T", "5D", "4T"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wish: ["7L22", "6L21", "5L21", "4L21", "3L21"],
			zenheadbutt: ["7T", "7L33", "6T", "6L33", "5T", "5L37", "4T", "4L37"],
		},
	},
	budew: {
		learnset: {
			absorb: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bulletseed: ["8M", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cottonspore: ["8E", "7E", "6E", "5E", "4E"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			extrasensory: ["8E", "7E", "6E", "5E", "4E"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7E", "6E", "5E"],
			grassyglide: ["8T"],
			growth: ["8L1", "7L4", "6L4", "5L4", "4L4"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			leafstorm: ["8M", "7E", "6E", "5E", "4E"],
			lifedew: ["8E"],
			megadrain: ["7L13", "6L13", "5L13", "4L13"],
			mindreader: ["7E", "6E", "5E", "4E"],
			mudslap: ["4T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			naturepower: ["7M", "6M"],
			pinmissile: ["8M", "7E", "6E", "5E", "4E"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			razorleaf: ["8E", "7E", "6E", "5E", "4E"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			sleeppowder: ["8E", "7E", "6E", "5E", "4E"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			spikes: ["8M", "7E", "6E", "5E", "4E"],
			stunspore: ["8L1", "7L10", "6L10", "5L10", "4L10"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["8M", "4T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			venoshock: ["8M", "7M", "6M", "5M"],
			watersport: ["7L7", "6L7", "5L7", "4L7"],
			weatherball: ["8M"],
			worryseed: ["8L1", "7T", "7L16", "6T", "6L16", "5T", "5L16", "4T", "4L16"],
		},
	},
	roselia: {
		learnset: {
			absorb: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			aromatherapy: ["8L50", "7L43", "6L43", "5L43", "4L43", "3L53"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			bulletseed: ["8M", "7E", "6E", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cottonspore: ["8E", "7E", "6E", "5E", "4E", "3E"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M", "3M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			extrasensory: ["8E"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["8M", "8L30", "7T", "7L25", "7E", "6T", "6L25", "6E", "5T", "5L25", "5E", "4M", "4L25", "3M", "3L33"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7L22", "7E", "6L22", "6E", "5L22", "5E", "4L22", "3L29", "3S1"],
			grassyglide: ["8T"],
			growth: ["8L1", "7L4", "6L4", "5L4", "5D", "4L4", "3L5", "3S0"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			ingrain: ["8L55", "7L34", "6L34", "5L34", "4L34", "3L41"],
			leafstorm: ["8M", "7E", "6E", "5E", "4E"],
			leechseed: ["8L10", "7L16", "6L16", "5L16", "4L16", "3L21", "3S1"],
			lifedew: ["8E"],
			magicalleaf: ["8M", "8L15", "7L19", "6L19", "5L19", "4L19", "3L25", "3S1"],
			megadrain: ["8L5", "7L13", "6L13", "5L13", "4L13", "3L17"],
			mimic: ["3T"],
			mindreader: ["7E", "6E", "5E", "4E"],
			mudslap: ["4T", "3T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			naturepower: ["7M", "6M"],
			nightmare: ["3T"],
			petalblizzard: ["8L45", "7L37", "6L37"],
			petaldance: ["8L60", "7L50", "6L37", "5L40", "4L40", "3L49"],
			pinmissile: ["8M", "7E", "6E", "5E", "4E", "3E"],
			poisonjab: ["8M", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L0", "7L7", "6L7", "5L7", "4L7", "3L9", "3S0"],
			powerwhip: ["8M", "7E"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			razorleaf: ["8E", "7E", "6E", "5E", "4E"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sleeppowder: ["8E", "7E", "6E", "5E", "5D", "4E"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spikes: ["8M", "7E", "6E", "5E", "4E", "3E"],
			stunspore: ["8L1", "7L10", "6L10", "5L10", "4L10", "3L13"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetkiss: ["3S1"],
			sweetscent: ["8L25", "7L31", "6L31", "5L31", "4L31", "3L37"],
			swift: ["8M", "5D", "4T", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["8L35", "7T", "7L46", "7E", "6T", "6L46", "6E", "5T", "5L46", "5E", "4T", "4L46", "4E", "3L57", "3E"],
			toxic: ["8L40", "7M", "7L40", "6M", "6L40", "5M", "5L37", "4M", "4L37", "3M", "3L45"],
			toxicspikes: ["8M", "8L20", "7L28", "6L28", "5L28", "4L28"],
			uproar: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			weatherball: ["8M"],
			worryseed: ["8L1", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["absorb", "growth", "poisonsting"], pokeball: "pokeball"},
			{generation: 3, level: 22, moves: ["sweetkiss", "magicalleaf", "leechseed", "grasswhistle"]},
		],
	},
	roserade: {
		learnset: {
			absorb: ["8L1"],
			aromatherapy: ["8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bodyslam: ["8M"],
			bulletseed: ["8M", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigadrain: ["8M", "8L1", "7T", "6T", "5T", "4M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L1", "7L1", "6L1"],
			growth: ["8L1"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			ingrain: ["8L1"],
			laserfocus: ["7T"],
			leafstorm: ["8M"],
			leechseed: ["8L1"],
			magicalleaf: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			megadrain: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["8L1"],
			petaldance: ["8L1"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			powerwhip: ["8M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			spikes: ["8M"],
			stunspore: ["8L1"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			sweetscent: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			swift: ["8M", "4T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["8L1", "7T", "6T", "5T", "4T"],
			toxic: ["8L1", "7M", "6M", "5M", "4M"],
			toxicspikes: ["8M", "8L1"],
			uproar: ["8M"],
			venomdrench: ["8M", "8L1", "7L1", "6L1"],
			venoshock: ["8M", "7M", "6M", "5M"],
			weatherball: ["8M", "7L1", "6L1", "5L1", "4L1"],
			worryseed: ["8L1", "7T", "6T", "5T", "4T"],
		},
	},
	gulpin: {
		learnset: {
			acidarmor: ["9E", "7E", "6E", "5E", "5D", "4E", "3E"],
			acidspray: ["9M", "9L17", "7L17", "6L17", "5L34"],
			amnesia: ["9M", "9L12", "7L12", "6L12", "5L17", "4L17", "3L17"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			belch: ["9L41", "7L41", "6L40"],
			bodyslam: ["9M", "3T"],
			bulletseed: ["9M", "4M", "3M"],
			captivate: ["4M"],
			clearsmog: ["9E"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["9E", "7E", "6E", "5E", "4E"],
			defensecurl: ["3T"],
			destinybond: ["9E", "7E", "6E", "5E", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			dynamicpunch: ["3T"],
			encore: ["9M", "9L20", "7L20", "6L20", "5L23", "4L23", "3L23"],
			endure: ["9M", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			fling: ["9M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gastroacid: ["9L36", "7T", "7L36", "6T", "6L36", "5T", "5L49", "4T", "4L44"],
			gigadrain: ["9M", "7T", "6T", "5T", "5D", "4M", "3M"],
			gunkshot: ["9M", "9L49", "7T", "7L49", "7E", "6T", "6L49", "6E", "5T", "5L59", "5E", "4T", "4L54"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "9E", "7E", "6E", "5E", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			painsplit: ["9L44", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			poisongas: ["9L8", "7L8", "6L8", "5L9", "5D", "4L9", "3L9"],
			poisonjab: ["9M"],
			pound: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			selfdestruct: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M", "3S0"],
			sing: ["3S0"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			sludge: ["9L10", "7L10", "6L10", "5L14", "4L14", "3L14", "3S0"],
			sludgebomb: ["9M", "9L33", "7M", "7L33", "6M", "6L33", "5M", "5L44", "4M", "4L39", "3M", "3L39"],
			sludgewave: ["7M", "6M", "5M"],
			smog: ["9E", "7E", "6E", "5E", "4E", "3E"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			spitup: ["9L28", "7L28", "6L28", "5L39", "4L34", "3L34"],
			stockpile: ["9L28", "7L28", "6L28", "5L39", "4L34", "3L34"],
			strength: ["6M", "5M", "4M", "3M"],
			stuffcheeks: ["9E"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swallow: ["9L28", "7L28", "6L28", "5L39", "4L34", "3L34"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M"],
			toxic: ["9L25", "7M", "7L25", "6M", "6L25", "5M", "5L28", "4M", "4L28", "3M", "3L28", "3S0"],
			toxicspikes: ["9M"],
			venomdrench: ["7E", "6E"],
			venoshock: ["9M", "7M", "6M", "5M"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			wringout: ["7L44", "6L44", "5L54", "4L49"],
			yawn: ["9L5", "7L5", "6L5", "5L6", "4L6", "3L6"],
		},
		eventData: [
			{generation: 3, level: 17, moves: ["sing", "shockwave", "sludge", "toxic"]},
		],
	},
	swalot: {
		learnset: {
			acidspray: ["9M", "9L17", "7L17", "6L17", "5L38"],
			amnesia: ["9M", "9L12", "7L12", "6L12", "5L17", "4L17", "3L17"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			belch: ["9L49", "7L49", "6L46"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L0", "7L1", "6L26", "5L26", "4L26", "3T", "3L26"],
			brickbreak: ["9M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			bulletseed: ["9M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["3T"],
			earthquake: ["9M", "7M", "6M", "5M", "4M"],
			encore: ["9M", "9L20", "7L20", "6L20", "5L23", "4L23", "3L23"],
			endure: ["9M", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			fling: ["9M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gastroacid: ["9L42", "7T", "7L42", "6T", "6L42", "5T", "5L59", "4T", "4L52"],
			gigadrain: ["9M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gunkshot: ["9M", "9L1", "7T", "7L1", "6T", "6L1", "5T", "5L73", "4T", "4L66"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			metronome: ["9M"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			poisongas: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poisonjab: ["9M"],
			pound: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			selfdestruct: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			sludge: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			sludgebomb: ["9M", "9L37", "7M", "7L37", "6M", "6L37", "5M", "5L52", "4M", "4L45", "3M", "3L48"],
			sludgewave: ["7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			spitup: ["9L30", "7L30", "6L30", "5L45", "4L38", "3L40"],
			stockpile: ["9L30", "7L30", "6L30", "5L45", "4L38", "3L40"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swallow: ["9L30", "7L30", "6L30", "5L45", "4L38", "3L40"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M"],
			toxic: ["9L25", "7M", "7L25", "6M", "6L25", "5M", "5L30", "4M", "4L30", "3M", "3L31"],
			toxicspikes: ["9M"],
			venomdrench: ["7L1"],
			venoshock: ["9M", "7M", "6M", "5M"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			wringout: ["7L1", "6L1", "5L66", "4L59"],
			yawn: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			zenheadbutt: ["9M"],
		},
	},
	carvanha: {
		learnset: {
			agility: ["8M", "8L36", "7L39", "6L36", "5L36", "4L36", "3L43"],
			ancientpower: ["8E", "7E", "6E", "5E", "4T", "4E"],
			aquajet: ["8L1", "7L11", "6L11", "5L31", "4L31"],
			assurance: ["8M", "7L15", "6L15", "5L26", "4L26"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bite: ["8L16", "7L1", "6L1", "6S1", "5L1", "5D", "4L1", "3L1", "3S0"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "7E", "6E", "5E", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L32", "7L36", "6L28", "5L28", "4L28", "3L22"],
			darkpulse: ["8M", "7M", "6M", "5T", "5D", "4M"],
			destinybond: ["8E", "7E", "6E"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["8E", "7E", "6E", "5E", "4E", "3T", "3E"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flipturn: ["8T"],
			focusenergy: ["8M", "8L8", "7L8", "6L8", "5L8", "4L8", "3L13"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "7E", "6E", "6S1", "5E", "4E", "3E"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icefang: ["8M", "8L20", "7L25", "6L16", "5L16", "4L16"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			leer: ["8L1", "7L1", "6L1", "6S1", "5L1", "4L1", "3L1"],
			liquidation: ["8M", "8L40"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonfang: ["8L4", "7L32", "6L32"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychicfangs: ["8M", "7E"],
			rage: ["7L4", "6L4", "5L6", "4L6", "3L7"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L12", "7L29", "6L11", "5L11", "4L11", "3L16", "3S0"],
			screech: ["8M", "8L24", "7L18", "6L18", "5L18", "4L18", "3L28"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["8L28", "7M", "7L22", "6M", "6L21", "5M", "5L21", "4M", "4L21", "3T", "3L37"],
			swift: ["8M", "7E", "6E", "5E", "4T", "3T"],
			takedown: ["8L44", "7L43", "6L38", "5L38", "4L38", "3L31"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thrash: ["8E", "7E", "6E", "5E", "5D", "4E", "3E"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["8E", "7T", "6T", "4M", "3M", "3S0"],
			whirlpool: ["8M", "4M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 15, moves: ["refresh", "waterpulse", "bite", "scaryface"]},
			{generation: 6, level: 1, isHidden: true, moves: ["leer", "bite", "hydropump"], pokeball: "pokeball"},
		],
	},
	sharpedo: {
		learnset: {
			agility: ["8M", "8L40", "7L45", "6L45", "5L45", "4L45", "3L53"],
			ancientpower: ["4T"],
			aquajet: ["8L1", "7L11", "6L11", "6S0", "5L34", "4L34"],
			assurance: ["8M", "7L15", "6L15", "5L26", "4L26"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			bite: ["8L16", "7L1", "6L1", "5L1", "4L1", "3L1"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L34", "7L40", "6L28", "6S0", "6S1", "5L28", "4L28", "3L22"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			destinybond: ["6S0"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			feint: ["7L1", "6L1", "5L1", "4L1"],
			flipturn: ["8T"],
			focusenergy: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icefang: ["8M", "8L20", "7L25", "6L16", "6S0", "5L16", "4L16"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			liquidation: ["8M", "8L46", "7T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["8L1", "7L1", "6L1", "5L56", "4L56"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonfang: ["8L1", "7L34", "6L34", "6S1"],
			poisonjab: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychicfangs: ["8M"],
			rage: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L12", "7L29", "6L11", "6S1", "5L11", "4L11", "3L16"],
			screech: ["8M", "8L24", "7L18", "6L18", "5L18", "4L18", "3L28"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["7L51", "6L50", "5L50", "4L50", "3L48"],
			slash: ["8L0", "7L1", "6L30", "6S1", "5L30", "4L30", "3L33"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["8L28", "7M", "7L22", "6M", "6L21", "5M", "5L21", "4M", "4L21", "3T", "3L43"],
			swift: ["8M", "4T", "3T"],
			takedown: ["8L52"],
			taunt: ["8M", "7M", "7L56", "6M", "6L40", "5M", "5L40", "4M", "4L40", "3M", "3L38"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 6, level: 50, nature: "Adamant", isHidden: true, moves: ["aquajet", "crunch", "icefang", "destinybond"], pokeball: "cherishball"},
			{generation: 6, level: 43, gender: "M", perfectIVs: 2, moves: ["scaryface", "slash", "poisonfang", "crunch"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 7, level: 10},
		],
	},
	wailmer: {
		learnset: {
			amnesia: ["8M", "8L42", "7L37", "6L37", "5L37", "4L37", "3L46"],
			aquaring: ["8E", "7E", "6E", "5E", "4E"],
			astonish: ["8L6", "7L16", "6L16", "5L17", "4L17", "3L23"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L36", "7E", "6E", "5E", "4E", "3T"],
			bounce: ["8M", "8L33", "7T", "7L45", "6T", "6L44", "5T", "5L44", "5D", "4T", "4L44"],
			brine: ["8M", "8L24", "7L25", "6L25", "5L31", "4M", "4L31"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			clearsmog: ["7E", "6E"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "6E", "5E", "4E", "3E"],
			defensecurl: ["8E", "7E", "6E", "5E", "4E", "3T"],
			dive: ["8M", "8L30", "7L41", "6M", "6L33", "5M", "5L41", "4T", "4L41", "3M"],
			doubleedge: ["8E", "7E", "6E", "5E", "4E", "3T", "3E"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["8E", "7E", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["8L3", "7L4", "6L4", "5L4", "4L4", "3L5"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			heavyslam: ["8M", "8L21", "7L53", "6L50", "5L50"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L45", "7L49", "6L47", "5L47", "4L47", "3L50"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			mimic: ["3T"],
			mist: ["8L15", "7L22", "6L22", "5L24", "4L24", "3L32"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L39", "7M", "7L29", "6M", "6L27", "5M", "5L27", "4M", "4L27", "3M", "3L37"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8E", "7L10", "6L10", "5L11", "4T", "4L11", "3T", "3L14"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E", "5D", "4M", "4E", "3T", "3E"],
			snore: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3T", "3E"],
			soak: ["8E", "7E", "6E", "5E"],
			splash: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			steelroller: ["8T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			thrash: ["8E", "7E", "6E", "5E", "4E", "3E"],
			tickle: ["8E", "7E", "6E", "5E", "4E", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L12", "7L7", "6L7", "5L7", "5D", "4L7", "3L10"],
			waterpulse: ["8L18", "7T", "7L19", "6T", "6L19", "5L21", "4M", "4L21", "3M", "3L28"],
			waterspout: ["8L48", "7L33", "6L34", "5L34", "4L34", "3L41"],
			weatherball: ["8M"],
			whirlpool: ["8M", "8L27", "7L13", "6L13", "5L14", "4M", "4L14", "3L19"],
			zenheadbutt: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
		},
	},
	wailord: {
		learnset: {
			amnesia: ["8M", "8L44", "7L37", "6L37", "5L37", "4L37", "3L52", "3S0"],
			astonish: ["8L1", "7L16", "6L16", "5L17", "4L17", "3L23"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L36", "3T"],
			bounce: ["8M", "8L33", "7T", "7L51", "6T", "6L51", "5T", "5L54", "4T", "4L54"],
			brine: ["8M", "8L24", "7L25", "6L29", "5L31", "4M", "4L31"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			defensecurl: ["3T"],
			dive: ["8M", "8L30", "7L44", "6M", "6L44", "5M", "5L46", "4T", "4L46", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			heavyslam: ["8M", "8L21", "7L1", "6L1", "5L70"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L49", "7L58", "6L58", "5L62", "4L62", "3L59", "3S0"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			liquidation: ["8M", "7T"],
			mimic: ["3T"],
			mist: ["8L15", "7L22", "6L22", "5L24", "4L24", "3L32", "3S1"],
			naturalgift: ["4M"],
			nobleroar: ["8L1", "7L1"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L39", "7M", "7L29", "6M", "6L25", "5M", "5L27", "4M", "4L27", "3M", "3L37", "3S0", "3S1"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["7L1", "6L1", "5L1", "4T", "4L1", "3T", "3L1"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			soak: ["8L1", "7L1"],
			splash: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			steelroller: ["8T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["8L18", "7T", "7L19", "6T", "6L19", "5L21", "4M", "4L21", "3M", "3L28", "3S1"],
			waterspout: ["8L54", "7L33", "6L33", "5L34", "4L34", "3L44", "3S0", "3S1"],
			weatherball: ["8M"],
			whirlpool: ["8M", "8L27", "7L13", "6L13", "5L14", "4M", "4L14", "3L19"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 3, level: 100, moves: ["rest", "waterspout", "amnesia", "hydropump"], pokeball: "pokeball"},
			{generation: 3, level: 50, moves: ["waterpulse", "mist", "rest", "waterspout"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 3, level: 25},
			{generation: 4, level: 35},
			{generation: 5, level: 30},
			{generation: 7, level: 10},
		],
	},
	numel: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["9M", "9L19", "7L19", "6L19", "5L31", "4L25", "3L31"],
			ancientpower: ["9E", "7E", "6E", "5E", "4E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9E", "7E", "6E", "5E", "5D", "4E", "3T", "3E"],
			bulldoze: ["9M", "9L12", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["9M", "3S0"],
			confide: ["7M", "6M"],
			curse: ["9L29", "7L29", "6L29", "5L29"],
			defensecurl: ["9E", "7E", "6E", "5E", "4E", "3T", "3E"],
			dig: ["9M", "6M", "5M", "4M", "3M", "3S0"],
			doubleedge: ["9L47", "7L47", "6L47", "5L55", "4L51", "3T", "3L49"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "9L26", "7T", "7L26", "6T", "6L26", "5T", "5L41", "4T", "4L35"],
			earthquake: ["9M", "9L40", "7M", "7L40", "6M", "6L40", "5M", "5L45", "4M", "4L41", "3M", "3L35"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["9L5", "7L5", "6L5", "5L5", "5D", "4L5", "3L11", "3S0"],
			endure: ["9M", "7E", "6E", "5E", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firespin: ["9M"],
			flameburst: ["7L15", "6L15", "5L21"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L43", "7M", "7L43", "6M", "6L43", "5M", "5L51", "4M", "4L45", "3M", "3L41"],
			flareblitz: ["9M"],
			flashcannon: ["9M"],
			focusenergy: ["9L8", "7L8", "6L8", "5L15", "4L15", "3L25"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["9L1", "7L1", "6L1", "6S1", "5L1", "4L1", "3L1"],
			growth: ["9E", "7E", "6E"],
			headbutt: ["4T"],
			heatwave: ["9M", "9E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			heavyslam: ["9M", "9E", "7E"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			howl: ["9E", "7E", "6E", "5E", "4E", "3E"],
			incinerate: ["9L15", "6M", "5M"],
			ironhead: ["9M", "9E", "7T", "7E", "6T", "6E", "6S1", "5T", "5E"],
			lavaplume: ["9L22", "7L22", "6L22", "5L35", "4L31"],
			magnitude: ["7L12", "6L8", "5L11", "4L11", "3L19"],
			mimic: ["3T"],
			mudbomb: ["7E", "6E", "5E", "4E"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			overheat: ["9M", "7M", "6M", "5M", "4M", "3M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["9E", "7E", "6E", "5E", "4T", "4E", "3T", "3E"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["9M", "7M", "6M", "5M", "4M", "3M"],
			scaryface: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			spitup: ["9E", "7E", "6E", "5E", "4E"],
			stealthrock: ["9M", "7T", "6T", "5T", "4M"],
			stockpile: ["9E", "7E", "6E", "5E", "4E"],
			stomp: ["9E", "7E", "6E", "5E", "4E", "3E"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swallow: ["9E", "7E", "6E", "5E", "4E"],
			tackle: ["9L1", "7L1", "6L1", "6S1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9L31", "7L31", "6L31", "5L25", "4L21", "3L29", "3S0"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
			yawn: ["9E", "7L36", "7E", "6L36", "6E", "5L36", "5E", "4E"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 14, abilities: ["oblivious"], moves: ["charm", "takedown", "dig", "ember"]},
			{generation: 6, level: 1, moves: ["growl", "tackle", "ironhead"], pokeball: "pokeball"},
		],
	},
	camerupt: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["9M", "9L19", "7L19", "6L19", "5L31", "4L25", "3L31"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bodypress: ["9M"],
			bodyslam: ["9M", "3T"],
			bulldoze: ["9M", "9L12", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			curse: ["9L29", "7L29", "6L29", "6S0", "5L29"],
			defensecurl: ["3T"],
			dig: ["9M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "9L26", "7T", "7L26", "6T", "6L26", "5T", "5L49", "4T", "4L39"],
			earthquake: ["9M", "9L46", "7M", "7L46", "6M", "6L46", "5M", "5L57", "4M", "4L49", "3M", "3L37"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			endure: ["9M", "4M", "3T"],
			eruption: ["9L1", "7L1", "6L1", "5L67", "4L57", "3L45"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firespin: ["9M"],
			fissure: ["9L1", "7L1", "6L1", "5L75", "4L67", "3L55"],
			flameburst: ["7L15", "6L15", "5L21"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flareblitz: ["9M"],
			flashcannon: ["9M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L1", "7L1", "6L1", "5L15", "4L15", "3L25"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["4T"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			incinerate: ["9L15", "6M", "5M"],
			ironhead: ["9M", "7T", "6T", "5T", "4T"],
			lavaplume: ["9L22", "7L22", "6L22", "5L33", "4L31"],
			magnitude: ["7L12", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			overheat: ["9M", "7M", "6M", "5M", "4M", "3M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "9L0", "7M", "7L1", "6M", "6L33", "6S0", "5M", "5L39", "4M", "4L33", "3T", "3L33"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["9M", "7M", "6M", "5M", "4M", "3M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["3T"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M"],
			stealthrock: ["9M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "7T"],
			stoneedge: ["9M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9L31", "7L31", "6L31", "6S0", "5L25", "4L21", "3L29"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
			yawn: ["9L39", "7L39", "6L39", "6S0", "5L39"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 6, level: 43, gender: "M", perfectIVs: 2, abilities: ["solidrock"], moves: ["curse", "takedown", "rockslide", "yawn"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 6, level: 30},
		],
	},
	torkoal: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			amnesia: ["9M", "9L52", "8M", "8L52", "7L40", "6L40", "5L49", "4L49", "3L40"],
			ancientpower: ["9E", "8E"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodypress: ["9M", "8M", "8S0"],
			bodyslam: ["9M", "9L32", "8M", "8L32", "7L27", "6L27", "5L33", "4L33", "3T", "3L20"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			burningjealousy: ["8T", "8S0"],
			captivate: ["4M"],
			clearsmog: ["9L16", "8L16", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			curse: ["9L44", "8L44", "7L22", "6L12", "5L12", "4L12", "3L7"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M", "4E"],
			ember: ["9L1", "8L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			endure: ["9M", "8M", "7E", "6E", "5E", "4M", "4E", "3T", "3E"],
			eruption: ["9L64", "8L64", "7E", "6E", "5E", "4E", "3E"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			firespin: ["9M", "8M", "7L13", "6L13", "5L17", "4L17", "3L17"],
			fissure: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
			flail: ["9E", "8E", "7L42", "6L1", "5L52", "4L52", "3L43"],
			flameburst: ["7E", "6E", "5E"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "9L40", "8M", "8L40", "7M", "7L34", "6M", "6L28", "5M", "5L28", "4M", "4L28", "3M", "3L30"],
			flamewheel: ["9L20", "8L20", "7L18", "6L18"],
			flareblitz: ["9M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			heatcrash: ["8M"],
			heatwave: ["9M", "9L48", "8M", "8L48", "7T", "7L45", "6T", "6L1", "5T", "5L55", "4T", "4L55", "3L46"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			inferno: ["9L56", "8L56", "7L50", "6L1", "5L60"],
			irondefense: ["9M", "9L36", "8M", "8L36", "7T", "7L38", "6T", "6L38", "5T", "5L44", "4T", "4L44", "3L33"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			lavaplume: ["9L28", "8L28", "7L25", "6L25", "5L39", "4L39"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			protect: ["9M", "9L24", "8M", "8L24", "8S0", "7M", "7L30", "6M", "6L1", "5M", "5L36", "4M", "4L36", "3M", "3L27"],
			rapidspin: ["9L8", "8L8", "7L10", "6L10", "5L23", "4L23"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			shellsmash: ["9L60", "8L60", "7L47", "6L1", "5L65"],
			skullbash: ["8E", "7E", "6E", "5E", "4E"],
			sleeptalk: ["9M", "8M", "7M", "7E", "6M", "6E", "5T", "5E", "4M", "4E", "3T", "3E"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			smog: ["9L1", "8L1", "7L4", "6L4", "5L4", "4L4", "3L4"],
			smokescreen: ["9L12", "8L12", "7L15", "6L15", "5L20", "4L20", "3L14"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "5D", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "7E", "6T", "6E"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			weatherball: ["8M"],
			willowisp: ["9M", "8M", "7M", "6M", "5M", "4M"],
			withdraw: ["9L4", "8L4", "7L7", "6L7", "5L7", "4L7"],
			yawn: ["9E", "8E", "8S0", "7E", "6E", "5E", "4E", "3E"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 8, level: 50, gender: "M", nature: "Bold", abilities: ["drought"], ivs: {hp: 31, atk: 12, def: 31, spa: 31, spd: 31, spe: 0}, moves: ["burningjealousy", "bodypress", "yawn", "protect"], pokeball: "cherishball"},
		],
	},
	spoink: {
		learnset: {
			allyswitch: ["7T"],
			amnesia: ["9M", "9E", "7E", "6E", "5E", "4E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "3T"],
			bounce: ["9L50", "7T", "7L50", "6T", "6L50", "5T", "5L53", "4T", "4L48", "3L43"],
			calmmind: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L22", "7L18", "6L18", "5L18", "4L18", "3L25"],
			confusion: ["9L7"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["9M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			encore: ["9M"],
			endure: ["9M", "7E", "6E", "5E", "4M", "3T"],
			extrasensory: ["9E", "7E", "6E", "5E", "4E", "3E"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			flashcannon: ["9M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["9E", "7E", "6E", "5E", "4E", "3E"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			growl: ["9L10"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["9M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7E", "6E", "5E"],
			magiccoat: ["7T", "7L21", "6T", "6L21", "5T", "5L21", "4T", "4L21", "3L28"],
			mimic: ["3T"],
			mirrorcoat: ["9E", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			odorsleuth: ["7L10", "6L10", "5L10", "4L10", "3L10"],
			payback: ["9L40", "7M", "7L40", "6M", "6L40", "5M", "5L41", "4M", "4L34"],
			powergem: ["9M", "9L29", "7L29", "6L29", "5L48", "4L46"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L14", "7L14", "6L14", "5L14", "4L14", "3L16"],
			psychic: ["9M", "9L44", "7M", "7L44", "6M", "6L44", "5M", "5L46", "4M", "4L41", "3M", "3L34"],
			psychicterrain: ["9M"],
			psychup: ["9L18", "7M", "7L15", "6M", "6L15", "5M", "5L15", "4M", "4L15", "3T", "3L19"],
			psyshock: ["9M", "9L38", "7M", "7L38", "6M", "6L38", "5M", "5L34"],
			psywave: ["7L7", "6L7", "5L7", "5D", "4L7", "3L7"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "5D", "4M"],
			reflect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "9L29", "7M", "7L29", "6M", "6L29", "5M", "5L29", "4M", "4L29", "3M", "3L37"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			simplebeam: ["9E", "7E", "6E"],
			skillswap: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4M", "3M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["9M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["9L33", "7T", "7L33", "6T", "6L29", "5T", "5L29", "4T", "4L29", "3T", "3L37"],
			snowscape: ["9M"],
			splash: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "4T", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trick: ["9M", "9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			uproar: ["3S0"],
			whirlwind: ["9E", "7E", "6E", "5E"],
			zenheadbutt: ["9M", "9E", "7T", "7L26", "7E", "6T", "6L26", "6E", "5T", "5L26", "5E", "4T", "4L26", "4E"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, abilities: ["owntempo"], moves: ["splash", "uproar"], pokeball: "pokeball"},
		],
	},
	grumpig: {
		learnset: {
			allyswitch: ["7T"],
			amnesia: ["9M"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			belch: ["9L1", "7L1"],
			bodypress: ["9M"],
			bodyslam: ["9M", "3T"],
			bounce: ["9L60", "7T", "7L60", "6T", "6L60", "5T", "5L68", "4T", "4L60", "3L55"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			calmmind: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L22", "7L18", "6L18", "5L18", "4L18", "3L25"],
			confusion: ["9L1"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["9M"],
			dig: ["9M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["3T"],
			earthpower: ["9M"],
			encore: ["9M"],
			endure: ["9M", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			flash: ["6M", "5M", "4M", "3M"],
			flashcannon: ["9M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["9M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M", "3M"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			magiccoat: ["7T", "7L21", "6T", "6L21", "5T", "5L21", "4T", "4L21", "3L28"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["9M"],
			mimic: ["3T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			odorsleuth: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			payback: ["9L46", "7M", "7L46", "6M", "6L46", "5M", "5L47", "4M", "4L37"],
			powergem: ["9M", "9L29", "7L29", "6L29", "5L60", "4L55"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			psychic: ["9M", "9L52", "7M", "7L52", "6M", "6L52", "5M", "5L55", "4M", "4L47", "3M", "3L37"],
			psychicterrain: ["9M"],
			psychup: ["9L18", "7M", "7L15", "6M", "6L15", "5M", "5L15", "4M", "4L15", "3T", "3L19"],
			psyshock: ["9M", "9L42", "7M", "7L42", "6M", "6L42", "5M", "5L37"],
			psywave: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "9L35", "7M", "7L35", "6M", "6L29", "5M", "5L29", "4M", "4L29", "3M", "3L43"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["9M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["9L35", "7T", "7L35", "6T", "6L29", "5T", "5L29", "4T", "4L29", "3T", "3L43"],
			snowscape: ["9M"],
			splash: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			stompingtantrum: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "4T", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			teeterdance: ["9L0", "7L1", "6L32"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			zenheadbutt: ["9M", "9L26", "7T", "7L26", "6T", "6L26", "5T", "5L26", "4T", "4L26"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	spinda: {
		learnset: {
			assist: ["7E", "6E", "5E", "4E", "3E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["7E", "6E", "5E", "4E", "3E"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			calmmind: ["7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			copycat: ["7L5", "6L5", "5L10", "5D", "4L10"],
			counter: ["3T"],
			covet: ["7T", "6T", "5T"],
			defensecurl: ["3T"],
			dig: ["6M", "5M", "4M", "3M"],
			disable: ["7E", "6E", "5E", "4E", "3E"],
			dizzypunch: ["7L23", "6L23", "5L28", "4L28", "3L27"],
			doubleedge: ["7L46", "6L46", "5L46", "4L46", "3T", "3L45"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["7T", "6T", "5T", "4M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["3T"],
			encore: ["7E", "6E", "5E", "4E", "3E"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fakeout: ["7E", "6E", "5E", "5D", "4E"],
			faketears: ["7E", "6E", "5E"],
			feintattack: ["7L10", "6L10", "5L14", "4L14", "3L12"],
			firepunch: ["7T", "6T", "5T", "4T", "3T"],
			flail: ["7L50", "6L50", "5L50", "4L50", "3L49"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			guardsplit: ["7E", "6E"],
			headbutt: ["4T"],
			helpinghand: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["7T", "6T", "5T"],
			hypnosis: ["7L19", "6L19", "5L23", "4L23", "3L23"],
			icepunch: ["7T", "6T", "5T", "4T", "3T"],
			icywind: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "3T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lowkick: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psybeam: ["7L14", "6L14", "5L19", "4L19", "3L16"],
			psychic: ["7M", "6M", "5M", "4M", "3M"],
			psychocut: ["7E", "6E", "5E", "4E"],
			psychoshift: ["7E", "6E"],
			psychup: ["7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L41", "3T", "3L38"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rapidspin: ["7E", "6E", "5E"],
			recycle: ["7T", "6T", "5T", "4M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sing: ["3S0"],
			skillswap: ["7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			smellingsalts: ["7E", "6E", "5E", "4E", "3E"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			spotlight: ["7E"],
			stompingtantrum: ["7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["7L28", "6L28", "5L32", "4T", "4L32"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			superpower: ["5D"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tackle: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			teeterdance: ["7L32", "6L32", "5L37", "4L37", "3L34"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			thrash: ["7L55", "6L50", "5L55", "4L55", "3L56"],
			thunderpunch: ["7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["7T", "7E", "6T", "6E", "5T", "5E", "4E", "3E"],
			trickroom: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "7L37", "6T", "6L5", "5T", "5L5", "4T", "4L5", "3L5", "3S0"],
			waterpulse: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			wildcharge: ["7M", "6M", "5M"],
			wish: ["7E", "6E", "5E", "4E", "3E"],
			workup: ["7M", "5M"],
			zenheadbutt: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["tackle", "uproar", "sing"], pokeball: "pokeball"},
		],
	},
	trapinch: {
		learnset: {
			astonish: ["8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bide: ["7L1", "6L1", "5L17"],
			bite: ["8L8", "7L1", "6L1", "5L1", "5D", "5S0", "4L1", "3L1"],
			bodyslam: ["8M", "3T"],
			bugbite: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			bulldoze: ["8M", "8L20", "7M", "7L8", "6M", "6L8", "5M", "5L21"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L28", "7L22", "6L22", "5L33", "4L33", "3L33"],
			dig: ["8M", "8L24", "7L19", "6M", "6L19", "5M", "5L41", "4M", "4L41", "3M", "3L41"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "8L36", "7T", "7L26", "7E", "6T", "6L26", "6E", "5T", "5L65", "5E", "4T", "4L65"],
			earthquake: ["8M", "8L40", "7M", "7L33", "6M", "6L33", "5M", "5L73", "4M", "4L73", "3M"],
			endure: ["8M", "7E", "6E", "5E", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			feint: ["8E", "7L29", "6L1", "5L81", "4L81"],
			feintattack: ["7L1", "6L1", "5L17", "4L17", "3L17"],
			firstimpression: ["8E"],
			fissure: ["8L48", "7L47", "6L1", "5L89", "4L89"],
			flail: ["8E", "7E", "6E", "5E", "4E"],
			focusenergy: ["8M", "7E", "6E", "5E", "4E", "3E"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["8E", "7E", "6E", "5E", "4T", "4E"],
			gigadrain: ["8M", "7T", "6T", "5T", "5D", "4M", "3M"],
			gust: ["8E", "7E", "6E", "5E", "4E", "3E"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "7L43", "6M", "6L43", "5M", "5L57", "4M", "4L57", "3M", "3L57"],
			laserfocus: ["8L4"],
			mimic: ["3T"],
			mudshot: ["8M", "7E", "6E", "5E", "4E"],
			mudslap: ["8L12", "7L5", "6L5", "5L13", "4T", "3T"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			quickattack: ["8E", "7E", "6E", "5E", "4E", "3E"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "7L15", "6M", "6L15", "5M", "5L25", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L9", "4L9", "3L9"],
			sandstorm: ["8M", "8L32", "7M", "7L36", "6M", "6L36", "5M", "5L49", "4M", "4L49", "3M", "3L49"],
			sandtomb: ["8M", "8L16", "7L12", "6L10", "5L25", "4L25", "3L25"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			strength: ["6M", "5M", "4M", "3M"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8L44", "7T", "7L40", "6T", "6L1", "5T", "5L67"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
		},
		eventData: [
			{generation: 5, level: 1, shiny: true, moves: ["bite"], pokeball: "pokeball"},
		],
	},
	vibrava: {
		learnset: {
			aircutter: ["4T"],
			airslash: ["8M"],
			astonish: ["8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bide: ["7L1", "6L1", "5L17"],
			bite: ["8L1", "3L1"],
			bodyslam: ["8M", "3T"],
			boomburst: ["8L62", "7L47", "6L47"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["8M", "8L28", "7L29", "6L29"],
			bulldoze: ["8M", "8L1", "7M", "7L8", "6M", "6L8", "5M", "5L21"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L1", "3L33"],
			defog: ["7T", "4M"],
			dig: ["8M", "8L1", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["8L0", "7L1", "6L35", "5L35", "4L35", "3L35"],
			dragonpulse: ["8M", "7T", "6T", "5T", "4M"],
			dragonrush: ["8L56"],
			dragontail: ["8L20"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "8L38", "7T", "7L26", "6T", "6L26", "5T", "5L39", "4T"],
			earthquake: ["8M", "8L44", "7M", "7L33", "6M", "6L33", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			fissure: ["8L1"],
			fly: ["8M", "7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M", "3M"],
			headbutt: ["4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "7L43", "6M", "6L43", "5M", "5L57", "4M", "4L57", "3M", "3L57"],
			laserfocus: ["8L1"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["8L12", "7L5", "6L5", "5L13", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "7L15", "6M", "6L15", "5M", "5L25", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			sandstorm: ["8M", "8L32", "7M", "7L36", "6M", "6L36", "5M", "5L49", "4M", "4L49", "3M", "3L49"],
			sandtomb: ["8M", "8L16", "7L12", "6L1", "5L1", "4L1", "3L1"],
			scorchingsands: ["8T"],
			screech: ["8M", "8L24", "7L22", "6L22", "5L41", "4L41", "3L41"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T"],
			silverwind: ["4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sonicboom: ["7L1", "6L1", "5L1", "4L1"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			strength: ["6M", "5M", "4M", "3M"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8L1", "7T", "6T", "5T"],
			supersonic: ["8L1", "7L19", "6L19", "5L33", "4L33"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			throatchop: ["8M", "7T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["8M", "8L50", "7T", "7L40", "6T", "6L40"],
			uturn: ["8M", "7M", "6M", "5M", "4M"],
		},
	},
	flygon: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			aircutter: ["4T"],
			airslash: ["8M"],
			astonish: ["8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bide: ["7L1", "6L1", "5L17"],
			bite: ["8L1", "3L1"],
			bodyslam: ["8M", "3T"],
			boomburst: ["8L68"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["8M", "8L28"],
			bulldoze: ["8M", "8L1", "7M", "7L8", "6M", "6L8", "5M", "5L21"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L1", "3L33", "3S0"],
			defog: ["7T", "4M"],
			dig: ["8M", "8L1", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["8T", "7T", "6T", "5T", "4T", "4S1"],
			dragonbreath: ["8L1", "7L1", "6L35", "5L35", "4L35", "3L35", "3S0"],
			dragonclaw: ["8M", "8L0", "7M", "7L1", "6M", "6L45", "5M", "5L45", "4M", "4L45", "4S1", "3M"],
			dragondance: ["8M", "8L1", "7L1"],
			dragonpulse: ["8M", "7T", "6T", "5T", "4M"],
			dragonrush: ["8L60", "7L47", "6L47"],
			dragontail: ["8L20", "7M", "7L29", "6M", "6L29", "5M", "5L65"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "8L38", "7T", "7L26", "6T", "6L26", "5T", "5L39", "4T"],
			earthquake: ["8M", "8L44", "7M", "7L33", "6M", "6L33", "5M", "4M", "4S1", "3M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			feint: ["8L1"],
			feintattack: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			firespin: ["8M"],
			fissure: ["8L1"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fly: ["8M", "7M", "6M", "5M", "4M", "3M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			heatwave: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "7L43", "6M", "6L43", "5M", "5L57", "4M", "4L57", "3M", "3L65"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["8L1", "7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["8L12", "7L5", "6L5", "5L13", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "7L15", "6M", "6L15", "5M", "5L25", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			sandstorm: ["8M", "8L32", "7M", "7L36", "6M", "6L36", "5M", "5L49", "4M", "4L49", "3M", "3L53"],
			sandtomb: ["8M", "8L16", "7L12", "6L1", "5L1", "4L1", "3L1", "3S0"],
			scaleshot: ["8T"],
			scorchingsands: ["8T"],
			screech: ["8M", "8L24", "7L22", "6L22", "5L41", "4L41", "3L41", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T"],
			silverwind: ["4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sonicboom: ["7L1", "6L1", "5L1", "4L1"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8L1", "7T", "6T", "5T"],
			supersonic: ["8L1", "7L19", "6L19", "5L33", "4L33"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			uproar: ["8M", "8L52", "7T", "7L40", "6T", "6L40"],
			uturn: ["8M", "7M", "6M", "5M", "4M", "4S1"],
		},
		eventData: [
			{generation: 3, level: 45, moves: ["sandtomb", "crunch", "dragonbreath", "screech"], pokeball: "pokeball"},
			{generation: 4, level: 50, gender: "M", nature: "Naive", moves: ["dracometeor", "uturn", "earthquake", "dragonclaw"], pokeball: "cherishball"},
		],
	},
	cacnea: {
		learnset: {
			absorb: ["9L4", "7L4", "6L4", "5L5", "5D", "4L5", "3L5", "3S0"],
			acid: ["7E", "6E", "5E", "4E", "3E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			belch: ["7E", "6E"],
			block: ["7T", "7E", "6T", "6E", "5T", "5E"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M"],
			bulletseed: ["9M", "9L16", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cottonspore: ["9L46", "7L46", "6L46", "5L49", "4L49", "3L41"],
			counter: ["7E", "6E", "5E", "4E", "3T", "3E"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			destinybond: ["9L54", "7L54", "6L54", "5L57", "4L57", "3L49"],
			dig: ["9M"],
			disable: ["7E", "6E", "5E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["7E", "6E", "5E", "4E", "3T", "3E"],
			encore: ["9M", "3S0"],
			endure: ["9M", "4M", "3T"],
			energyball: ["9M", "9L42", "7M", "7L42", "6M", "6L42", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L19", "6L19", "5L29", "4L29", "3L29"],
			fellstinger: ["7E", "6E"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["9M", "7T", "6T", "5T", "4M", "3M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7E", "6E", "5E", "4E", "3E"],
			grassyterrain: ["9M"],
			growth: ["9L7", "7L7", "6L7", "5L9", "4L9", "3L9"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			ingrain: ["9L22", "7L22", "6L22", "5L25", "4L25", "3L25"],
			leafstorm: ["9M"],
			leechseed: ["9L10", "7L10", "6L10", "5L13", "4L13", "3L13"],
			leer: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			lowkick: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			magicalleaf: ["9M", "7E", "6E", "5E", "4E"],
			megapunch: ["3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			nastyplot: ["9M", "7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			needlearm: ["7L16", "6L16", "5L45", "4L45", "3L37"],
			payback: ["9L26", "7M", "7L26", "6M", "6L26", "5M", "5L41", "4M", "4L41"],
			pinmissile: ["9L38", "7L38", "6L21", "5L21", "4L21", "3L21"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			poisonsting: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			powertrip: ["9L19"],
			poweruppunch: ["7E", "6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rototiller: ["7E", "6E"],
			round: ["7M", "6M", "5M"],
			sandattack: ["9L13", "7L13", "6L13", "5L17", "4L17", "3L17"],
			sandstorm: ["9M", "9L50", "7M", "7L50", "6M", "6L50", "5M", "5L53", "4M", "4L53", "3M", "3L45"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			seismictoss: ["3T"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			smellingsalts: ["7E", "6E", "5E", "4E"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			spikes: ["9M", "9L30", "7L30", "6L30", "5L33", "4L33", "3L33"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L34", "7L34", "6L34", "5L37", "4T", "4L37"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M"],
			switcheroo: ["7E", "6E", "5E"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			teeterdance: ["7E", "6E", "5E", "5D", "4E", "3E"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "5D", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			venoshock: ["9M", "7M", "6M", "5M"],
			worryseed: ["7T", "7E", "6T", "6E", "5T", "5E", "4T"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["poisonsting", "leer", "absorb", "encore"], pokeball: "pokeball"},
		],
	},
	cacturne: {
		learnset: {
			absorb: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M"],
			bulletseed: ["9M", "9L16", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cottonspore: ["9L49", "7L49", "6L49", "5L59", "4L59", "3L47"],
			counter: ["3T"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			destinybond: ["9L1", "7L1", "6L1", "5L71", "4L71", "3L59"],
			dig: ["9M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["9M", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["9M"],
			endure: ["9M", "4M", "3T"],
			energyball: ["9M", "9L44", "7M", "7L44", "6M", "6L44", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L19", "6L19", "5L29", "4L29", "3L29", "3S0"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["9M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["9M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["9M"],
			growth: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			ingrain: ["9L22", "7L22", "6L22", "5L25", "4L25", "3L25", "3S0"],
			leafstorm: ["9M"],
			leechseed: ["9L10", "7L10", "6L10", "5L13", "4L13", "3L13"],
			leer: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			magicalleaf: ["9M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			needlearm: ["7L16", "6L16", "5L53", "4L53", "3L41", "3S0"],
			payback: ["9L26", "7M", "7L26", "6M", "6L26", "5M", "5L47", "4M", "4L47"],
			pinmissile: ["9L38", "7L38", "6L21", "5L21", "4L21", "3L21"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			poisonsting: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			powertrip: ["9L19"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			sandattack: ["9L13", "7L13", "6L13", "5L17", "4L17", "3L17"],
			sandstorm: ["9M", "9L54", "7M", "7L54", "6M", "6L54", "5M", "5L65", "4M", "4L65", "3M", "3L53"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			seismictoss: ["3T"],
			shadowball: ["9M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			spikes: ["9M", "9L30", "7L30", "6L30", "5L35", "4L35", "3L35", "3S0"],
			spikyshield: ["9L0", "7L1", "6L32"],
			spite: ["7T", "6T", "5T", "4T"],
			stompingtantrum: ["9M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L35", "7L35", "6L35", "5L41", "4T", "4L41"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			venoshock: ["9M", "7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 45, moves: ["ingrain", "feintattack", "spikes", "needlearm"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 6, level: 30},
		],
	},
	swablu: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M", "8M", "7E", "6E", "5E", "4E", "3E"],
			aircutter: ["4T"],
			astonish: ["9E", "8E", "7L3", "6L3", "5L5", "4L5", "3L8"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			bravebird: ["9M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cottonguard: ["9L32", "8L32", "7L34", "6L34", "5L40"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			defog: ["9E", "8E", "7T"],
			disarmingvoice: ["9M", "9L4", "8L4", "7L11", "6L11"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragonbreath: ["9L20", "8L20"],
			dragonpulse: ["9M", "8M", "7T", "7L38", "6T", "6L38", "5T", "5L50", "4M", "4L45"],
			dragonrush: ["9E", "8E", "7E", "6E", "5E", "4E"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "3S0"],
			featherdance: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
			fly: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furyattack: ["9L12", "8L12", "7L7", "6L7", "5L13", "4L13", "3L18"],
			growl: ["9L1", "8L1", "7L1", "6L1", "6S2", "5L1", "5S1", "4L1", "3L1", "3S0"],
			haze: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			healbell: ["7T", "6T", "5T", "4T"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hurricane: ["9M"],
			hypervoice: ["9M", "8M", "7T", "7E", "6T", "6E", "6S2", "5T", "5E"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			mirrormove: ["7L30", "6L30", "5L36", "4L36", "3L38"],
			mist: ["9L8", "8L8", "7L14", "6L14", "5L23", "4L23", "3L28"],
			moonblast: ["9L40", "8L40", "7L46", "6L46"],
			mudslap: ["4T", "3T"],
			naturalgift: ["7L20", "6L20", "5L32", "4M", "4L32"],
			ominouswind: ["4T"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			peck: ["9L1", "8L1", "7L1", "6L1", "6S2", "5L1", "5D", "5S1", "4L1", "3L1", "3S0"],
			perishsong: ["9L44", "8L44", "7L42", "6L42", "5L55", "4L50", "3L48"],
			playrough: ["9M", "8M", "7E"],
			pluck: ["5M", "4M"],
			powerswap: ["8M", "7E", "6E", "5E", "4E"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			pursuit: ["7E", "6E", "5E", "4E", "3E"],
			rage: ["7E", "6E", "5E", "4E", "3E"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["7L26", "6L26", "5L45", "4L40", "3L41"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["9E", "8E", "7M", "7E", "6M", "6E", "5T", "5E", "5D", "4M"],
			round: ["9L16", "8M", "8L16", "7M", "7L17", "6M", "6L17", "5M", "5L18"],
			safeguard: ["9L24", "8M", "8L24", "7M", "7L9", "6M", "6L9", "5M", "5L18", "4M", "4L18", "3M", "3L21"],
			secretpower: ["6M", "4M", "3M"],
			sing: ["9L28", "8L28", "7L5", "6L5", "5L9", "4L9", "3L11"],
			skyattack: ["7T", "6T", "3T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			steelwing: ["8M", "7M", "7E", "6M", "6E", "5E", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "4T", "3T"],
			tailwind: ["9M", "9E", "8E", "7T", "6T", "5T", "4T"],
			takedown: ["9M", "9L36", "8L36", "7L23", "6L23", "5L28", "4L28", "3L31"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			twister: ["4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["peck", "growl", "falseswipe"], pokeball: "pokeball"},
			{generation: 5, level: 1, shiny: true, moves: ["peck", "growl"], pokeball: "pokeball"},
			{generation: 6, level: 1, isHidden: true, moves: ["peck", "growl", "hypervoice"], pokeball: "pokeball"},
		],
	},
	altaria: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M", "3S1"],
			agility: ["9M", "8M", "6S3"],
			aircutter: ["4T"],
			astonish: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			bravebird: ["9M"],
			breakingswipe: ["8M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cottonguard: ["9L32", "8L32", "7L34", "6L34", "5L46"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			defog: ["7T"],
			disarmingvoice: ["9M", "9L1", "8L1", "7L11", "6L11"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["9L20", "8L20", "7L1", "6L35", "5L35", "5S2", "4L35", "3L35", "3S0", "3S1"],
			dragonclaw: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "8M", "7L30", "6L30", "5L39", "4L39", "3L40", "3S0"],
			dragonpulse: ["9M", "9L0", "8M", "8L0", "7T", "7L40", "6T", "6L40", "5T", "5L62", "4M", "4L54"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "5S2"],
			fireblast: ["9M", "8M", "7M", "6M", "6S3", "5M", "4M", "3M"],
			firespin: ["9M", "8M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fly: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furyattack: ["9L12", "8L12", "7L7", "6L7", "5L13", "4L13", "3L18"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			healbell: ["7T", "6T", "5T", "4T", "3S1"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "6S3", "5T"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			mimic: ["3T"],
			mist: ["9L1", "8L1", "7L14", "6L14", "5L23", "4L23", "3L28"],
			moonblast: ["9L44", "8L44", "7L52", "6L52"],
			mudslap: ["4T", "3T"],
			naturalgift: ["7L20", "6L20", "5L32", "5S2", "4M", "4L32"],
			ominouswind: ["4T"],
			outrage: ["9M", "8M", "7T", "6T", "5T", "4T"],
			peck: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			perishsong: ["9L50", "8L50", "7L46", "6L46", "5L70", "4L62", "3L54"],
			playrough: ["9M", "8M"],
			pluck: ["9L1", "8L1", "7L1", "6L1", "5M", "5L1", "4M", "4L1"],
			powerswap: ["8M"],
			protect: ["9M", "8M", "7M", "6M", "6S3", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["7L26", "6L26", "5L54", "4L46", "3L45", "3S0"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["9L16", "8M", "8L16", "7M", "7L17", "6M", "6L17", "5M", "5L18"],
			safeguard: ["9L24", "8M", "8L24", "7M", "7L9", "6M", "6L9", "5M", "5L18", "4M", "4L18", "3M", "3L21"],
			secretpower: ["6M", "4M", "3M"],
			sing: ["9L28", "8L28", "7L1", "6L1", "5L1", "4L1", "3L1"],
			skyattack: ["9L56", "8L56", "7T", "7L1", "6T", "6L1", "5T", "5L77", "4T", "4L70", "3T", "3L59"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M", "3S1"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "4T", "3T"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M", "9L38", "8L38", "7L23", "6L23", "5L28", "5S2", "4L28", "3L31", "3S0"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			twister: ["4T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			willowisp: ["9M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 3, level: 45, moves: ["takedown", "dragonbreath", "dragondance", "refresh"], pokeball: "pokeball"},
			{generation: 3, level: 36, moves: ["healbell", "dragonbreath", "solarbeam", "aerialace"]},
			{generation: 5, level: 35, gender: "M", isHidden: true, moves: ["takedown", "naturalgift", "dragonbreath", "falseswipe"]},
			{generation: 6, level: 100, nature: "Modest", isHidden: true, moves: ["hypervoice", "fireblast", "protect", "agility"], pokeball: "cherishball"},
		],
	},
	zangoose: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			agility: ["9M"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			aurasphere: ["9M"],
			batonpass: ["9M"],
			bellydrum: ["9L1", "7E"],
			blizzard: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "3T"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M", "3M", "3S2"],
			captivate: ["4M"],
			closecombat: ["9M", "9L50", "7L50", "6L47", "5L53", "4L53"],
			confide: ["7M", "6M"],
			counter: ["9L1", "7E", "6E", "5E", "4E", "3T", "3E", "3S2"],
			crushclaw: ["9L26", "7L26", "6L22", "5L31", "4L31", "3L31", "3S2"],
			curse: ["9L1", "7E", "6E", "5E", "4E", "3E"],
			defensecurl: ["3T"],
			detect: ["9L36", "7L36", "6L33", "5L40", "4L40", "3L46"],
			dig: ["9M", "6M", "5M", "4M", "3M"],
			disable: ["9L1", "7E", "6E", "5E", "4E"],
			doubleedge: ["3T"],
			doublehit: ["9L1", "7E", "6E", "5E", "5D", "4E"],
			doublekick: ["9L1", "7E", "6E", "5E", "4E", "3E"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			embargo: ["7M", "7L33", "6M", "6L19", "5M", "5L27", "4M", "4L27"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["9M", "9L29", "7M", "7L29", "6M", "6L29", "5M", "5L44", "4M", "4L44", "3L55"],
			feint: ["9L1", "7E", "6E", "5E"],
			finalgambit: ["9L1", "7E", "6E", "5E"],
			fireblast: ["9M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			flail: ["9L1", "7E", "6E", "5E", "4E", "3E"],
			flamethrower: ["9M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["9L8", "7L8", "6L8", "5L14", "4T", "4L14", "3T", "3L13", "3S0"],
			furyswipes: ["9L1", "7E", "6E", "5E", "4E"],
			gigadrain: ["7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			gunkshot: ["9M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["9L15", "7L15", "6M", "6L15", "5M"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leer: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L4", "3S0", "3S1"],
			lowkick: ["9M", "7T", "6T", "5T", "5D", "4T"],
			lowsweep: ["9M"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metalclaw: ["9M", "9L12", "7E", "6E", "5E", "4E"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["9L1", "7E", "6E", "5E", "4E"],
			payback: ["7M", "6M", "5M", "4M"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			powertrip: ["9L22"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			pursuit: ["7L12", "6L12", "5L22", "4L22", "3L25"],
			quickattack: ["9L5", "7L5", "6L5", "5L5", "5D", "4L5", "3L7", "3S0", "3S1"],
			quickguard: ["9L1", "7E", "6E"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7E", "6E", "5E", "4E", "3E"],
			refresh: ["3S2"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["7L22", "6L22", "5L26"],
			reversal: ["9M"],
			roar: ["7M", "6M", "5M", "4M", "4E", "3M", "3E"],
			rockclimb: ["4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			scratch: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S1"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M"],
			seismictoss: ["3T"],
			shadowball: ["9M", "7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slash: ["9L19", "7L19", "6L15", "5L18", "4L18", "3L19"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["9M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "4T", "3T"],
			switcheroo: ["9L33"],
			swordsdance: ["9M", "9L47", "7M", "7L47", "6M", "6L43", "5M", "5L9", "4M", "4L9", "3T", "3L10", "3S0", "3S1"],
			takedown: ["9M"],
			taunt: ["9M", "9L43", "7M", "7L43", "6M", "6L40", "5M", "5L35", "4M", "4L35", "3M", "3L37"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			thunder: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			workup: ["7M", "5M"],
			xscissor: ["9M", "9L40", "7M", "7L40", "6M", "6L36", "5M", "5L48", "4M", "4L48"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 18, moves: ["leer", "quickattack", "swordsdance", "furycutter"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["scratch", "leer", "quickattack", "swordsdance"], pokeball: "pokeball"},
			{generation: 3, level: 28, moves: ["refresh", "brickbreak", "counter", "crushclaw"]},
		],
	},
	seviper: {
		learnset: {
			acidspray: ["9M"],
			aquatail: ["7T", "6T", "5T", "5D", "4T"],
			assurance: ["9E", "7E", "6E", "5E", "4E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			belch: ["9L41", "7L41", "6L43"],
			bind: ["7T", "6T", "5T"],
			bite: ["9L4", "7L4", "6L4", "5L10", "5D", "4L10", "3L10", "3S0", "3S2"],
			bodyslam: ["9M", "9E", "7E", "6E", "5E", "5D", "4E", "3T", "3E"],
			brickbreak: ["9M"],
			brutalswing: ["7M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			captivate: ["4M"],
			coil: ["9L44", "7L44", "6L46", "5L64"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L39", "7L39", "6L40", "5L28", "4L28", "3L28", "3S1"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			dig: ["9M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragontail: ["7M", "6M", "5M"],
			earthquake: ["9M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			feint: ["9L11", "7L11"],
			finalgambit: ["9E", "7E", "6E", "5E"],
			firefang: ["9M"],
			flamethrower: ["9M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gastroacid: ["9L29", "7T", "7L29", "6T", "6L31", "5T", "5L34"],
			gigadrain: ["9M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M"],
			glare: ["9L19", "7L19", "6L19", "5L25", "4L25", "3L25", "3S1"],
			gunkshot: ["9M"],
			haze: ["9L34", "7L34", "6L37", "5L43", "4L43", "3L43"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M"],
			icefang: ["9M"],
			infestation: ["7M", "6M"],
			ironhead: ["9M"],
			irontail: ["7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lick: ["9L6", "7L6", "6L7", "5L7", "4L7", "3L7", "3S0", "3S2"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightslash: ["9E", "7L26", "7E", "6L28", "6E", "5L46", "5E", "4L46", "4E"],
			payback: ["7M", "6M", "5M", "4M"],
			poisonfang: ["9L21", "7L21", "6L22", "5L34", "4L34", "3L34"],
			poisonjab: ["9M", "9L31", "7M", "7L31", "6M", "6L34", "5M", "5L52", "4M", "4L52"],
			poisontail: ["9M", "9L9", "7L9", "6L10", "5L16", "4L16", "3L16", "3S0", "3S1"],
			pounce: ["9M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psychicfangs: ["9M"],
			punishment: ["7E", "6E", "5E"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			reversal: ["9M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M", "9E", "7E", "6E", "5E", "4E"],
			screech: ["9L14", "7L14", "6L13", "5L19", "4L19", "3L19", "3S1"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["9M", "9L46", "7M", "6M", "5M", "4M", "3M"],
			sludgewave: ["7M", "6M", "5M"],
			snarl: ["9M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			spitup: ["9E", "7E", "6E", "5E", "4E", "3E"],
			stockpile: ["9E", "7E", "6E", "5E", "4E", "3E"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["9L1", "7M", "7L1", "6M", "6L1", "5M", "5L37", "4M", "4L37", "3T", "3L37"],
			swallow: ["9E", "7E", "6E", "5E", "4E", "3E"],
			swift: ["4T", "3T"],
			switcheroo: ["9E", "7E", "6E", "5E", "4E"],
			swordsdance: ["9M", "7M", "7L36"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			venomdrench: ["7L24", "6L25"],
			venoshock: ["9M", "9L24", "7M", "7L16", "6M", "6L16", "5M", "5L55"],
			wrap: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0", "3S2"],
			wringout: ["7L46", "7E", "6L49", "6E", "5L61", "5E", "4L55"],
			xscissor: ["9M", "7M", "6M", "5M", "4M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 18, moves: ["wrap", "lick", "bite", "poisontail"], pokeball: "pokeball"},
			{generation: 3, level: 30, moves: ["poisontail", "screech", "glare", "crunch"], pokeball: "pokeball"},
			{generation: 3, level: 10, gender: "M", moves: ["wrap", "lick", "bite"], pokeball: "pokeball"},
		],
	},
	lunatone: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			allyswitch: ["8M", "7T"],
			ancientpower: ["4T"],
			batonpass: ["8M", "3S1"],
			blizzard: ["8M", "7M", "6M", "5M", "4M"],
			bodyslam: ["8M", "3T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L7", "3S0"],
			cosmicpower: ["8M", "8L25", "7L25", "7S2", "6L25", "5L34", "4L34", "3L31"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			earthpower: ["8M", "7T", "6T", "5T", "5D", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			embargo: ["7M", "7L17", "6M", "6L17", "5M", "5L31", "4M", "4L31"],
			endure: ["8M", "4M", "3T"],
			explosion: ["8L50", "7M", "7L45", "6M", "6L45", "5M", "5L56", "4M", "4L56", "3T", "3L49"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L40", "7L41", "6L41", "5L53", "4L53", "3L43"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			healblock: ["7L33", "6L33", "5L42", "4L42"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7S2", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hypnosis: ["8L5", "7L5", "6L5", "5L12", "4L12", "3L19"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "8L45", "7T", "7L49", "6T", "6L1", "5T", "5L64"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			moonblast: ["8L1", "7L1", "7S2", "6L1"],
			moonlight: ["8L1", "5D"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powergem: ["8M", "7L1", "7S2"],
			powerswap: ["8M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L30", "7M", "7L29", "6M", "6L29", "5M", "5L45", "4M", "4L45", "3M", "3L37", "3S1"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "8L20", "7M", "7L1", "6M", "5M"],
			psywave: ["7L13", "6L13", "5L23", "4L23", "3L25"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M", "3S1"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockpolish: ["8L10", "7M", "7L9", "6M", "6L9", "5M", "5L20", "4M", "4L20"],
			rockslide: ["8M", "8L15", "7M", "7L21", "6M", "6L21", "5M", "5L25", "4M", "3T"],
			rockthrow: ["8L1", "7L1", "6L1", "5L9", "4L9", "3L13"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M", "3S1"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L35", "7M", "7L37", "6M", "6L37", "5M", "5L41", "4M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			telekinesis: ["7T", "5M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			weatherball: ["8M"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 10, moves: ["tackle", "harden", "confusion"], pokeball: "pokeball"},
			{generation: 3, level: 25, moves: ["batonpass", "psychic", "raindance", "rocktomb"]},
			{generation: 7, level: 30, moves: ["cosmicpower", "hiddenpower", "moonblast", "powergem"], pokeball: "cherishball"},
		],
	},
	solrock: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			allyswitch: ["8M", "7T"],
			ancientpower: ["4T"],
			batonpass: ["8M", "3S1"],
			bodyslam: ["8M", "3T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L7", "3S0"],
			cosmicpower: ["8M", "8L25", "7L25", "7S2", "6L25", "5L34", "4L34", "3L31", "3S1"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			embargo: ["7M", "7L17", "6M", "6L17", "5M", "5L31", "4M", "4L31"],
			endure: ["8M", "4M", "3T"],
			explosion: ["8L50", "7M", "7L45", "6M", "6L45", "5M", "5L56", "4M", "4L56", "3T", "3L49"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firespin: ["8M", "7L5", "6L5", "5L12", "4L12", "3L19"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flareblitz: ["8M", "8L1", "7L1"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			healblock: ["7L33", "6L33", "5L42", "4L42"],
			heatwave: ["8M", "7T", "6T", "5T"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "7S2", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			hypnosis: ["8L5"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			morningsun: ["8L1", "5D"],
			naturalgift: ["4M"],
			overheat: ["8M", "7M", "6M", "5M", "4M", "3M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powerswap: ["8M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L30", "7M", "7L29", "6M", "6L29", "5M", "5L33", "4M", "3M", "3S1"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7L13", "6L13", "5L23", "4L23", "3L25"],
			raindance: ["8M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockpolish: ["8L10", "7M", "7L9", "6M", "6L9", "5M", "5L20", "4M", "4L20"],
			rockslide: ["8M", "8L15", "7M", "7L21", "6M", "6L21", "5M", "5L45", "4M", "4L45", "3T", "3L37"],
			rockthrow: ["8L1", "7L1", "6L1", "5L9", "4L9", "3L13"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "8L40", "7M", "7L41", "7S2", "6M", "6L41", "5M", "5L53", "4M", "4L53", "3M", "3L43"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L35", "7M", "7L37", "7S2", "6M", "6L37", "5M", "5L41", "4M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M", "3S1"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			telekinesis: ["7T", "5M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			weatherball: ["8M"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "8L45", "7T", "7L49", "6T", "6L1", "5T", "5L64"],
			zenheadbutt: ["8M", "8L20", "7T", "6T", "5T", "5D", "4T"],
		},
		eventData: [
			{generation: 3, level: 10, moves: ["tackle", "harden", "confusion"], pokeball: "pokeball"},
			{generation: 3, level: 41, moves: ["batonpass", "psychic", "sunnyday", "cosmicpower"]},
			{generation: 7, level: 30, moves: ["cosmicpower", "hiddenpower", "solarbeam", "stoneedge"], pokeball: "cherishball"},
		],
	},
	barboach: {
		learnset: {
			amnesia: ["9M", "9L18", "8M", "8L18", "7L15", "6L15", "5L18", "4L18", "3L21"],
			aquatail: ["9L24", "8L24", "7T", "7L28", "6T", "6L28", "5T", "5L35", "4T", "4L35"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "8M", "7E", "6E", "5E", "4E"],
			earthpower: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			earthquake: ["9M", "9L36", "8M", "8L36", "7M", "7L32", "6M", "6L32", "5M", "5L39", "4M", "4L39", "3M", "3L31"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["9L48", "8L48", "7L44", "6L44", "5L47", "4L47", "3L41"],
			flail: ["9E", "8E", "7E", "6E", "5E", "4E"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["9L42", "8M", "8L42", "7L39", "6L39", "5L43", "4L43", "3L36"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "8M", "7E", "6E", "5E", "4E"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			liquidation: ["9M"],
			magnitude: ["7L20", "6L20", "5L26", "4L26", "3L16"],
			mimic: ["3T"],
			mudbomb: ["7L13", "6L13", "5L14", "4L14"],
			muddywater: ["9L31", "8M", "8L31", "7L35", "7E", "6L35", "6E", "5E"],
			mudshot: ["9M", "8M", "7E", "6E", "5E"],
			mudslap: ["9M", "9L1", "8L1", "7L1", "6L1", "5L1", "5D", "4T", "4L1", "3T", "3L1"],
			mudsport: ["7L6", "6L6", "5L6", "4L6", "3L6"],
			naturalgift: ["4M"],
			outrage: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "9L6", "8M", "8L6", "7M", "7L25", "6M", "6L25", "5M", "5L31", "4M", "4L31", "3M", "3L26"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "5D", "4M", "3T"],
			snore: ["9L6", "8M", "8L6", "7T", "7L25", "6T", "6L25", "5T", "5L31", "4T", "4L31", "3T", "3L26"],
			spark: ["9E", "8E", "7E", "6E", "5E", "5D", "4E", "3E"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M"],
			takedown: ["9M", "9E", "8E", "7E", "6E", "5E", "4E"],
			terablast: ["9M"],
			thrash: ["9E", "8E", "7E", "6E", "5E", "4E", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["9L1", "8L1", "7L9", "6L9", "5L10", "4L10", "3L11"],
			waterpulse: ["9M", "9L12", "8L12", "7T", "7L17", "6T", "6L17", "5L22", "4M", "4L22", "3M"],
			watersport: ["7L6", "6L6", "5L6", "4L6", "3L6"],
			whirlpool: ["8M", "7E", "6E", "5E", "4M", "4E", "3E"],
			zenheadbutt: ["9M"],
		},
	},
	whiscash: {
		learnset: {
			amnesia: ["9M", "9L18", "8M", "8L18", "7L15", "6L15", "5L18", "4L18", "3L21"],
			aquatail: ["9L24", "8L24", "7T", "7L28", "6T", "6L28", "5T", "5L39", "4T", "4L39", "4S0"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			belch: ["9L1", "8L1", "7L1"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "8M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L40", "8M", "8L40", "7M", "7L34", "6M", "6L34", "5M", "5L45", "4M", "4L45", "4S0", "3M", "3L36"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["9L56", "8L56", "7L52", "6L52", "5L57", "4L57", "3L56"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["9L48", "8M", "8L48", "7L45", "6L45", "5L51", "4L51", "3L46"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M", "4S0"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			liquidation: ["9M", "8M"],
			magnitude: ["7L20", "6L20", "5L26", "4L26", "3L16"],
			mimic: ["3T"],
			mudbomb: ["7L13", "6L13", "5L14", "4L14"],
			muddywater: ["9L33", "8M", "8L33", "7L39", "6L39"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "9L1", "8L1", "7L1", "6L1", "5L1", "4T", "4L1", "3T", "3L1"],
			mudsport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			naturalgift: ["4M"],
			outrage: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "9L1", "8M", "8L1", "7M", "7L25", "6M", "6L25", "5M", "5L33", "4M", "4L33", "3M", "3L26"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["9L1", "8M", "8L1", "7T", "7L25", "6T", "6L25", "5T", "5L33", "4T", "4L33", "3T", "3L26"],
			spikes: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L0", "8L0", "7L1"],
			tickle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M"],
			waterfall: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["9L1", "8L1", "7L1", "6L1", "5L10", "4L10", "3L11"],
			waterpulse: ["9M", "9L12", "8L12", "7T", "7L17", "6T", "6L17", "5L22", "4M", "4L22", "3M"],
			watersport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			weatherball: ["8M"],
			whirlpool: ["8M", "4M"],
			zenheadbutt: ["9M", "9L1", "8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "4S0"],
		},
		eventData: [
			{generation: 4, level: 51, gender: "F", nature: "Gentle", abilities: ["oblivious"], moves: ["earthquake", "aquatail", "zenheadbutt", "gigaimpact"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 4, level: 10},
			{generation: 7, level: 10},
		],
	},
	corphish: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["8E", "7E", "6E", "5E", "4T", "4E", "3E"],
			aquajet: ["8E", "7E", "6E"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "7E", "6E", "5E", "4E", "3T", "3E"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bubble: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			bubblebeam: ["8L12", "7L14", "6L14", "5L20", "4L20", "3L19"],
			captivate: ["4M"],
			chipaway: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crabhammer: ["8L44", "7L43", "6L38", "5L38", "4L38", "3L34"],
			crunch: ["8M", "8L40", "7L39", "6L39", "5L47", "4L47", "3L43"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["8E", "7E", "6E", "5E", "3T"],
			doublehit: ["8L20", "7L20", "6L20"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragondance: ["8M", "7E", "6E", "5E", "4E"],
			endeavor: ["8L48", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			guillotine: ["8L52", "7L48", "6L48", "5L53", "4L53", "3L44"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["8L1", "7L5", "6L5", "5L7", "5D", "4L7", "3L7"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["8M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["8L16", "7T", "7L23", "7E", "6T", "6L23", "6E", "5T", "5L26", "5E", "4T", "4L26", "4E", "3L25"],
			leer: ["8L4", "7L10", "6L10", "5L13", "4L13", "3L13"],
			metalclaw: ["8E", "7E", "6E", "5E", "5D", "4E"],
			mimic: ["3T"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			nightslash: ["8L28", "7L26", "6L26", "5L35", "4L35"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["8M", "8L24", "7M", "7L17", "6M", "6L17", "5M", "5L23", "4M", "4L23", "3M", "3L22"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			razorshell: ["8M", "8L32", "7L31", "6L31"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			slash: ["8E"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			switcheroo: ["8E", "7E", "6E"],
			swordsdance: ["8M", "8L36", "7M", "7L37", "6M", "6L37", "5M", "5L44", "4M", "4L44", "3T", "3L37"],
			taunt: ["8M", "8L8", "7M", "7L34", "6M", "6L32", "5M", "5L32", "4M", "4L32", "3M", "3L31"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trumpcard: ["7E", "6E", "5E"],
			visegrip: ["7L7", "6L7", "5L10", "4L10", "3L10"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			watersport: ["3S0"],
			whirlpool: ["8M", "4M"],
			xscissor: ["8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["bubble", "watersport"], pokeball: "pokeball"},
		],
	},
	crawdaunt: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bubble: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			bubblebeam: ["8L12", "7L14", "6L14", "5L20", "4L20", "3L19"],
			captivate: ["4M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crabhammer: ["8L52", "7L48", "6L44", "5L44", "4L44", "3L38", "3S0", "3S1"],
			crunch: ["8M", "8L46", "7L43", "6L43", "5L57", "4L57", "3L51"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doublehit: ["8L20", "7L20", "6L20"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragondance: ["8M"],
			endeavor: ["8L58", "7T", "6T", "5T", "4T"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			guillotine: ["8L64", "7L54", "6L1", "5L65", "4L65", "3L52", "3S0"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			knockoff: ["8L16", "7T", "7L23", "6T", "6L23", "5T", "5L26", "4T", "4L26", "3L25", "3S1"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			liquidation: ["8M", "7T"],
			mimic: ["3T"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			nightslash: ["8L28", "7L26", "6L26", "5L39", "4L39"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			protect: ["8M", "8L24", "7M", "7L17", "6M", "6L17", "5M", "5L23", "4M", "4L23", "3M", "3L22"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			razorshell: ["8M", "8L34", "7L32", "6L32"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["8M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8L0", "7L1", "6L30", "5L30", "4T", "4L30", "3T"],
			swordsdance: ["8M", "8L40", "7M", "7L40", "6M", "6L40", "5M", "5L52", "4M", "4L52", "3T", "3L43", "3S0", "3S1"],
			taunt: ["8M", "8L1", "7M", "7L36", "6M", "6L34", "5M", "5L34", "4M", "4L34", "3M", "3L33", "3S0", "3S1"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			visegrip: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			xscissor: ["8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 3, level: 100, moves: ["taunt", "crabhammer", "swordsdance", "guillotine"], pokeball: "pokeball"},
			{generation: 3, level: 50, moves: ["knockoff", "taunt", "crabhammer", "swordsdance"], pokeball: "pokeball"},
		],
		encounters: [
			{generation: 7, level: 10},
		],
	},
	baltoy: {
		learnset: {
			allyswitch: ["8M", "7T", "5M"],
			ancientpower: ["8L18", "7L19", "6L19", "5L26", "4T", "4L25", "3L25"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8L6", "7L1", "6L1", "5L1", "4L1", "3L1"],
			cosmicpower: ["8M", "8L24", "7L22", "6L22", "5L37", "4L45", "3L37"],
			dazzlinggleam: ["8M", "7M", "6M"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			drillrun: ["8M", "7T", "6T", "5T"],
			earthpower: ["8M", "8L30", "7T", "7L37", "6T", "6L37", "5T", "5L51", "4T", "4L53"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["8M"],
			endure: ["8M", "4M", "3T"],
			expandingforce: ["8T"],
			explosion: ["8L42", "7M", "7L46", "6M", "6L46", "5M", "5L60", "4M", "4L71", "3T", "3L45"],
			extrasensory: ["8L27", "7L31", "6L28", "5L43"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "5D", "4T"],
			guardsplit: ["8L36", "7L34", "6L34", "5L48"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			harden: ["8L1", "7L1", "6L1", "5L4", "4L3", "3L3"],
			headbutt: ["4T"],
			healblock: ["7L10", "6L10", "5L54", "4L61"],
			hex: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			imprison: ["8M", "8L21", "7L43", "6L43"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["8L1", "7L7", "6L7", "5L11", "4T", "4L7", "3T", "3L7", "3S0"],
			naturalgift: ["4M"],
			powersplit: ["8L36", "7L34", "6L34", "5L48"],
			powerswap: ["8M"],
			powertrick: ["8L12", "7L25", "6L17", "5L31", "4L31"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["8L15", "7L16", "6L13", "5L15", "4L11", "3L11", "3S0"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rapidspin: ["8L3", "7L4", "6L4", "5L7", "5D", "4L5", "3L5"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocktomb: ["8M", "8L9", "7M", "7L13", "6M", "6L10", "5M", "5L18", "4M", "4L15", "3M", "3L15", "3S0"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			sandstorm: ["8M", "8L39", "7M", "7L40", "6M", "6L40", "5M", "5L34", "4M", "4L37", "3M", "3L31"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8L33", "7L28", "6L25", "5L21", "4L19", "3T", "3L19"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			telekinesis: ["7T", "5M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "5D", "4T"],
		},
		eventData: [
			{generation: 3, level: 17, moves: ["refresh", "rocktomb", "mudslap", "psybeam"]},
		],
	},
	claydol: {
		learnset: {
			allyswitch: ["8M", "7T", "5M"],
			ancientpower: ["8L18", "7L19", "6L19", "5L26", "4T", "4L25", "3L25"],
			bodypress: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			cosmicpower: ["8M", "8L24", "7L22", "6L22", "5L47", "4L51", "3L42"],
			dazzlinggleam: ["8M", "7M", "6M"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			drillrun: ["8M", "7T", "6T", "5T"],
			earthpower: ["8M", "8L30", "7T", "7L40", "6T", "6L40", "5T", "5L59", "4T", "4L62"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			eerieimpulse: ["8M"],
			endure: ["8M", "4M", "3T"],
			expandingforce: ["8T"],
			explosion: ["8L48", "7M", "7L58", "6M", "6L58", "5M", "5L72", "4M", "4L86", "3T", "3L55"],
			extrasensory: ["8L27", "7L31", "6L28", "5L39"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardsplit: ["8L38", "7L34", "6L34", "5L54"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["4T"],
			healblock: ["7L10", "6L10", "5L64", "4L73"],
			hex: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8L0", "7M", "7L1", "6M", "6L36", "5M", "5L36", "4M", "4L36", "3M", "3L36"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			imprison: ["8M", "8L21", "7L52", "6L52"],
			irondefense: ["8M"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mudslap: ["8L1", "7L7", "6L7", "5L11", "4T", "4L7", "3T", "3L7"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			powersplit: ["8L38", "7L34", "6L34", "5L54"],
			powerswap: ["8M"],
			powertrick: ["8L12", "7L25", "6L17", "5L31", "4L31"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["8L15", "7L16", "6L13", "5L15", "4L11", "3L11"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rapidspin: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "8L9", "7M", "7L13", "6M", "6L10", "5M", "5L18", "4M", "4L15", "3M", "3L15"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			sandstorm: ["8M", "8L43", "7M", "7L46", "6M", "6L46", "5M", "5L34", "4M", "4L40", "3M", "3L31"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "8L33", "7L28", "6L25", "5L21", "4L19", "3T", "3L19"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			storedpower: ["8M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			telekinesis: ["7T", "5M"],
			teleport: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["8M", "7T", "6T", "5T", "4T"],
		},
	},
	lileep: {
		learnset: {
			acid: ["8L4", "7L5", "6L5", "5L8", "5D", "5S0", "4L8", "3L15"],
			amnesia: ["8M", "8L28", "7L36", "6L29", "5L29", "4L29", "3L36"],
			ancientpower: ["8L16", "7L17", "6L17", "5L43", "4T", "4L43", "3L43"],
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			barrier: ["7E", "6E", "5E", "4E", "3E"],
			bind: ["8E", "7T", "6T", "5T"],
			bodyslam: ["8M", "3T"],
			brine: ["8M", "8L24", "7L21", "6L21"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L8", "7L13", "6L13", "5L22", "4L22", "3L29"],
			constrict: ["7L1", "6L1", "5L1", "5S0", "4L1", "3L8"],
			curse: ["8E", "7E", "6E", "5E", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			endure: ["8M", "7E", "6E", "5E", "4M", "3T"],
			energyball: ["8M", "8L44", "7M", "7L41", "6M", "6L41", "5M", "5L50", "4M", "4L50"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gastroacid: ["8L32", "7T", "7L31", "6T", "6L31", "5T", "5L36", "4T", "4L36"],
			gigadrain: ["8M", "8L36", "7T", "7L26", "6T", "6L26", "5T", "5D", "4M", "3M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			ingrain: ["8L12", "7L9", "6L9", "5L15", "4L15", "3L22"],
			megadrain: ["8L20", "7E", "6E", "5E"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mirrorcoat: ["8E", "7E", "6E", "5E", "5D", "4E", "3E"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["3T"],
			recover: ["8E", "7E", "6E", "5E", "5S0", "4E", "3E"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "5S0", "4M", "4E", "3T", "3E"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spitup: ["8L41", "7L46", "6L46", "5L57", "4L57", "3L50"],
			stealthrock: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			stockpile: ["8L41", "7L46", "6L46", "5L57", "4L57", "3L50"],
			stringshot: ["4T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swallow: ["8L41", "7L46", "6L46", "5L57", "4L57", "3L50"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["7T", "6T", "5T", "4T"],
			tickle: ["8E", "7E", "6E", "5E", "4E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			wrap: ["8L1"],
			wringout: ["7L52", "7E", "6L52", "6E", "5L64", "5E", "4L64", "4E"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", moves: ["recover", "rockslide", "constrict", "acid"], pokeball: "cherishball"},
		],
	},
	cradily: {
		learnset: {
			acid: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			amnesia: ["8M", "8L28", "7L36", "6L29", "5L29", "4L29", "3L36"],
			ancientpower: ["8L16", "7L17", "6L17", "5L36", "4T", "4L36", "3L48"],
			astonish: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bind: ["7T", "6T", "5T"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["8M", "3T"],
			brine: ["8M", "8L24", "7L21", "6L21"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletseed: ["8M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L1", "7L13", "6L13", "5L22", "4L22", "3L29"],
			constrict: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			dig: ["8M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "8L48", "7M", "7L44", "6M", "6L44", "5M", "5L56", "4M", "4L56"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gastroacid: ["8L32", "7T", "7L31", "6T", "6L31", "5T", "5L46", "4T", "4L46"],
			gigadrain: ["8M", "8L36", "7T", "7L26", "6T", "6L26", "5T", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["8M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			infestation: ["7M", "6M"],
			ingrain: ["8L12", "7L1", "6L1", "5L1", "4L1", "3L1"],
			leechseed: ["8L1"],
			megadrain: ["8L20"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			powerwhip: ["8M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["3T"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			spitup: ["8L43", "7L52", "6L1", "5L66", "4L66", "3L60"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stockpile: ["8L43", "7L52", "6L1", "5L66", "4L66", "3L60"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swallow: ["8L43", "7L52", "6L1", "5L66", "4L66", "3L60"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			worryseed: ["7T", "6T", "5T", "4T"],
			wrap: ["8L1"],
			wringout: ["7L1", "6L1", "5L76", "4L76"],
		},
	},
	anorith: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["8L16", "7L21", "6L21", "5L31", "4T", "4L31", "3L37"],
			aquajet: ["8E", "7E", "6E"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "8L24", "7L29", "6L29"],
			bugbite: ["8L20", "7T", "7L25", "6T", "6L25", "5T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crosspoison: ["8M", "7E", "6E", "5E", "5D", "5S0", "4E"],
			crushclaw: ["8L32", "7L39", "6L39", "5L55", "4L55"],
			curse: ["8E", "7E", "6E", "5E", "4E"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["8L1", "7L10", "6L10", "5L37", "4T", "4L37", "3T", "3L43"],
			harden: ["8L1", "7L1", "6L1", "5L1", "5S0", "4L1", "3L7"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			irondefense: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			metalclaw: ["8L12", "7L17", "6L17", "5L19", "4L19", "3L25"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			mudsport: ["7L4", "6L4", "5L7", "5S0", "4L7", "3L13"],
			naturalgift: ["4M"],
			protect: ["8M", "8L41", "7M", "7L49", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3M", "3L31"],
			rapidspin: ["8E", "7E", "6E", "5E", "4E", "3E"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "8L36", "7L55", "6L49", "5L49", "4L49", "3L55"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8E", "7E", "6E", "5E", "4E"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scratch: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			screech: ["8M", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M", "3M"],
			slash: ["8L28", "7L34", "6L34", "5L43", "4L43", "3L49"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["8L8", "7M", "7L13", "6M", "6L13", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "5D", "4M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L4", "7L7", "6L7", "5L13", "5S0", "4L13", "3L19"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			xscissor: ["8M", "8L44", "7M", "7L44", "6M", "6L44", "5M", "5L61", "4M", "4L61"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", moves: ["harden", "mudsport", "watergun", "crosspoison"], pokeball: "cherishball"},
		],
	},
	armaldo: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["8L16", "7L21", "6L21", "5L31", "4T", "4L31", "3L37"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "8L24", "7L29", "6L29"],
			brutalswing: ["8M", "7M"],
			bugbite: ["8L20", "7T", "7L25", "6T", "5T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crosspoison: ["8M"],
			crushclaw: ["8L32", "7L39", "6L1", "5L67", "4L67"],
			cut: ["6M", "5M", "4M", "3M"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["8L1", "7L10", "6L10", "5L37", "4T", "4L37", "3T", "3L46"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			liquidation: ["8M", "7T"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			metalclaw: ["8L12", "7L17", "6L17", "5L19", "4L19", "3L25"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			mudsport: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			naturalgift: ["4M"],
			protect: ["8M", "8L43", "7M", "7L53", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3M", "3L31"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["8M", "8L36", "7L61", "6L55", "5L55", "4L55", "3L64"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scratch: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			screech: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["8M"],
			slash: ["8L28", "7L25", "6L25", "5L46", "4L46", "3L55"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["8L1", "7M", "7L13", "6M", "6L13", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			xscissor: ["8M", "8L48", "7M", "7L46", "6M", "6L46", "5M", "5L73", "4M", "4L73"],
		},
	},
	feebas: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "7E", "6E", "5E"],
			captivate: ["7E", "6E", "5E", "5D", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E", "4E", "3E"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragonbreath: ["8E", "7E", "6E", "5E", "4E", "3E"],
			dragonpulse: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L25", "7L30", "6L30", "5L30", "4L30", "3L30"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			haze: ["8E", "7E", "6E", "5E", "4E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hypnosis: ["8E", "7E", "6E", "5E", "4E", "3E"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			lightscreen: ["8M", "7M", "6M", "5M", "4E", "3E"],
			mimic: ["3T"],
			mirrorcoat: ["8E", "7E", "6E", "5E", "5D", "4E", "4S0", "3E"],
			mist: ["8E", "7E", "6E", "5E", "4E"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			splash: ["8L1", "7L1", "6L1", "5L1", "5D", "4L1", "4S0", "3L1"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tackle: ["8L15", "7L15", "6L15", "5L15", "4L15", "3L15"],
			tickle: ["8E", "7E", "6E", "5E", "4E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 4, level: 5, gender: "F", nature: "Calm", moves: ["splash", "mirrorcoat"], pokeball: "cherishball"},
		],
	},
	milotic: {
		learnset: {
			aquaring: ["8L12", "7L17", "6L21", "5L49", "4L49"],
			aquatail: ["8L32", "7T", "7L31", "6T", "6L29", "5T", "5L29", "4T", "4L29"],
			attract: ["8M", "8L16", "7M", "7L34", "6M", "6L37", "5M", "5L41", "4M", "4L41", "3M", "3L45"],
			avalanche: ["8M", "4M"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			breakingswipe: ["8M"],
			brine: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["7L21", "6L24", "5L25", "4M", "4L25"],
			coil: ["8L48", "7L41", "6L44"],
			confide: ["7M", "6M"],
			disarmingvoice: ["8L4", "7L11", "6L11"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T", "6T", "5T", "4M"],
			dragontail: ["8L24", "7M", "7L24", "6M", "6L27", "5M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L1"],
			flipturn: ["8T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L52", "7L44", "6L37", "5L37", "5S3", "4L37", "4S1", "4S2", "3L40"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "5S3", "5S4", "4M", "4S1", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "4S2", "3T"],
			imprison: ["8M"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			lifedew: ["8L20"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			mirrorcoat: ["5S3"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["8M", "8L44", "7M", "7L47", "6M", "6L33", "5M", "5L33", "4M", "4L33", "4S1", "4S2", "3M", "3L35", "3S0"],
			recover: ["8L28", "7L27", "6L21", "5L21", "5S3", "5S4", "4L21", "4S1", "4S2", "3L30", "3S0"],
			refresh: ["7L1", "6L7", "5L9", "4L9", "3L15"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "8L36", "7M", "7L37", "6M", "6L41", "5M", "5L45", "4M", "4L45", "3M", "3L50"],
			scald: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			splash: ["8L1"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["8M", "8L40", "7M", "6M", "5M", "5S4", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tackle: ["8L1"],
			toxic: ["7M", "6M", "5M", "5S4", "4M", "3M"],
			tripleaxel: ["8T"],
			twister: ["8L8", "7L14", "6L14", "5L17", "4T", "4L17", "3L25", "3S0"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["8L0", "7T", "7L1", "6T", "6L13", "5L13", "4M", "4L13", "3M", "3L20", "3S0"],
			watersport: ["7L1", "6L4", "5L5", "4L5", "3L10"],
			weatherball: ["8M"],
			whirlpool: ["8M", "4M"],
			wrap: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L5"],
		},
		eventData: [
			{generation: 3, level: 35, moves: ["waterpulse", "twister", "recover", "raindance"], pokeball: "pokeball"},
			{generation: 4, level: 50, gender: "F", nature: "Bold", moves: ["recover", "raindance", "icebeam", "hydropump"], pokeball: "cherishball"},
			{generation: 4, level: 50, shiny: true, gender: "M", nature: "Timid", moves: ["raindance", "recover", "hydropump", "icywind"], pokeball: "cherishball"},
			{generation: 5, level: 50, shiny: 1, moves: ["recover", "hydropump", "icebeam", "mirrorcoat"], pokeball: "cherishball"},
			{generation: 5, level: 58, gender: "M", nature: "Lax", ivs: {hp: 30, atk: 30, def: 30, spa: 30, spd: 30, spe: 30}, moves: ["recover", "surf", "icebeam", "toxic"], pokeball: "cherishball"},
		],
	},
	castform: {
		learnset: {
			amnesia: ["7E", "6E", "5E", "4E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			avalanche: ["4M"],
			blizzard: ["7M", "7L35", "6M", "6L35", "5M", "5L50", "4M", "3M"],
			bodyslam: ["3T"],
			captivate: ["4M"],
			clearsmog: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			cosmicpower: ["7E", "6E"],
			defensecurl: ["3T"],
			defog: ["7T"],
			disable: ["7E", "6E", "5E", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			ember: ["7L10", "6L10", "5L10", "5D", "4L10", "3L10"],
			endure: ["4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fireblast: ["7M", "7L35", "6M", "6L35", "5M", "5L50", "4M", "3M"],
			flamethrower: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["7E", "6E", "5E", "4E", "3E"],
			guardswap: ["7E", "6E"],
			hail: ["7M", "7L20", "6M", "6L20", "5M", "5L30", "4M", "4L20", "3M", "3L20"],
			headbutt: ["7L15", "6L15", "5L20"],
			hex: ["7E", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hurricane: ["7L45", "6L45"],
			hydropump: ["7L35", "6L35", "5L50"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			luckychant: ["7E", "6E", "5E", "4E"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			ominouswind: ["7E", "6E", "5E", "5D", "4T", "4E"],
			powdersnow: ["7L10", "6L10", "5L10", "4L10", "3L10"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			raindance: ["7M", "7L20", "6M", "6L20", "5M", "5L30", "4M", "4L20", "3M", "3L20"],
			reflecttype: ["7E", "6E"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["7M", "6M", "5M", "4M", "3M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "7L20", "6M", "6L20", "5M", "5L30", "4M", "4L20", "3M", "3L20"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			tackle: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["7M", "6M", "5M", "4M", "3M"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			watergun: ["7L10", "6L10", "5L10", "4L10", "3L10"],
			waterpulse: ["7T", "6T", "5D", "4M", "3M"],
			weatherball: ["7L25", "6L25", "5L40", "4L30", "3L30"],
			workup: ["7M", "5M"],
		},
	},
	kecleon: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			afteryou: ["7T", "6T", "5T"],
			ancientpower: ["7L21", "6L1", "5L55", "4T", "4L55", "3L49"],
			aquatail: ["7T", "6T", "5T", "4T"],
			astonish: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bind: ["7T", "7L4", "6T", "6L4", "5T", "5L4", "4L4", "3L4"],
			blizzard: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			camouflage: ["7L30", "7E", "6L30", "6E"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			dig: ["6M", "5M", "4M", "3M"],
			disable: ["7E", "6E", "5E", "4E", "3E"],
			dizzypunch: ["7E", "6E", "5E", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["7T", "6T", "5T", "4M"],
			dynamicpunch: ["3T"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			fakeout: ["7E", "6E", "5E", "4E"],
			feint: ["7L10", "6L10", "5L14", "4L14"],
			feintattack: ["7L16", "6L7", "5L7", "5D", "4L7", "3L7"],
			fireblast: ["7M", "6M", "5M", "4M", "3M"],
			firepunch: ["7T", "6T", "5T", "4T", "3T"],
			flamethrower: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foulplay: ["7T", "7E", "6T", "6E", "5T", "5E"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			furyswipes: ["7L13", "6L10", "5L10", "4L10", "3L12"],
			grassknot: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "6T", "5T", "4T", "3T"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T", "4M", "3M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lick: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			lowkick: ["7T", "6T", "5T", "4T"],
			magiccoat: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			megakick: ["3T"],
			megapunch: ["3T"],
			metronome: ["3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			nastyplot: ["7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			poweruppunch: ["7E", "6M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psybeam: ["7L18", "6L18", "5L18", "4L15", "3L17"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			recover: ["7E", "6E", "5E", "4E"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflecttype: ["5D"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			screech: ["7L38", "6L32", "5L32", "4L32", "3L24"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["7M", "7L33", "6M", "6L33", "5M", "5L49", "4M", "4L49"],
			shadowsneak: ["7L7", "6L7", "5L22", "4L20"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skillswap: ["7T", "7E", "6T", "6E", "5T", "5E", "5D", "4M", "3M"],
			slash: ["7L25", "6L25", "5L27", "4L25", "3L31"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["7M", "7L42", "6M", "6L37", "5M", "5L37", "4M", "4L37", "3T", "3L40"],
			suckerpunch: ["7L46", "6L43", "5L43", "4T", "4L43"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			synchronoise: ["7L50", "6L1", "5L58"],
			tailwhip: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			thief: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			trickroom: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wonderroom: ["7T", "6T", "5T"],
			workup: ["7M", "5M"],
		},
	},
	shuppet: {
		learnset: {
			allyswitch: ["7T"],
			astonish: ["9L1", "7E", "6E", "5E", "4E", "3E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			calmmind: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9E", "7E", "6E", "5E", "4E"],
			curse: ["9L26", "7L26", "6L19", "5L13", "4L13", "3L20"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			dazzlinggleam: ["9M", "7M", "6M"],
			destinybond: ["9E", "7E", "6E", "5E", "5D", "4E", "3E"],
			disable: ["9E", "7E", "6E", "5E", "4E", "3E"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			embargo: ["7M", "7L34", "6M", "6L34", "5M", "5L43", "4M", "4L38"],
			encore: ["9M"],
			endure: ["9M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L19", "6L19", "5L28", "4L28", "3L37", "3S0"],
			flash: ["6M", "5M", "4M", "3M"],
			foresight: ["7E", "6E", "5E", "4E", "3E"],
			foulplay: ["9M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			grudge: ["7L46", "6L46", "5L50", "4L46", "3L56"],
			gunkshot: ["9M", "9E", "7T", "7E", "6E", "5E"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hex: ["9M", "9L22", "7L22", "6L22", "5L31"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["9M", "9E", "7E", "6E", "5E", "4E", "3E"],
			knockoff: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["7T", "6T", "5T"],
			metronome: ["9M"],
			mimic: ["3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			nightshade: ["9M", "9L7", "7L7", "6L7", "5L8", "5D", "4L8", "3L13"],
			ominouswind: ["7E", "6E", "5E", "4T"],
			painsplit: ["7T", "6T", "5T", "5D", "4T"],
			payback: ["7M", "6M", "5M", "4M", "4E"],
			phantomforce: ["9M", "9L48", "7L54", "7E", "6L54", "6E"],
			pounce: ["9M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychic: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			pursuit: ["7E", "6E", "5E", "4E"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["9L34", "7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["9L4", "7L4", "6L4", "5L5", "4L5", "3L8"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "9L30", "7M", "7L30", "6M", "6L30", "5M", "5L35", "4M", "4L31", "3M", "3L44", "3S0"],
			shadowsneak: ["9L19", "7L13", "7E", "6L13", "6E", "5L20", "5E", "4L20", "4E"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skillswap: ["9M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "7L42", "6T", "6L42", "5T", "5L46", "4M", "4L43", "3M", "3L49"],
			snore: ["7T", "6T", "3T"],
			spite: ["9L10", "7T", "7L10", "6T", "6L10", "5T", "5L16", "4T", "4L16", "3L25", "3S0"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L38", "7L38", "6L34", "5L38", "4T", "4L35"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunder: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["9M", "9L42", "7T", "7L50", "6T", "6L50", "5T", "5L55", "4T", "4L50"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			willowisp: ["9M", "9L16", "7M", "7L16", "6M", "6L13", "5M", "5L23", "4M", "4L23", "3L32", "3S0"],
		},
		eventData: [
			{generation: 3, level: 45, abilities: ["insomnia"], moves: ["spite", "willowisp", "feintattack", "shadowball"], pokeball: "pokeball"},
		],
	},
	banette: {
		learnset: {
			allyswitch: ["7T"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			calmmind: ["9M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			cottonguard: ["5S1"],
			curse: ["9L26", "7L26", "6L1", "5L1", "4L1", "3L1", "3S0"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			dazzlinggleam: ["9M", "7M", "6M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			embargo: ["7M", "7L34", "6M", "6L34", "5M", "5L51", "4M", "4L42"],
			encore: ["9M"],
			endure: ["9M", "4M", "3T"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L19", "6L19", "5L28", "5S1", "4L28", "3L39", "3S0"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			foulplay: ["9M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grudge: ["7L52", "6L52", "5L66", "4L58", "3L64"],
			gunkshot: ["9M", "7T"],
			headbutt: ["4T"],
			helpinghand: ["9M", "3S0"],
			hex: ["9M", "9L22", "7L22", "6L22", "5L31", "5S1"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["9M"],
			infestation: ["7M", "6M"],
			knockoff: ["9L0", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["7T", "6T", "5T"],
			metronome: ["9M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			nastyplot: ["9M"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			nightshade: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			ominouswind: ["4T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["7M", "6M", "5M", "4M"],
			phantomforce: ["9M", "9L53", "7L1", "6L1"],
			pounce: ["9M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psybeam: ["9M"],
			psychic: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["9L34", "7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "9L30", "7M", "7L30", "6M", "6L30", "5M", "5L35", "5S1", "4M", "4L31", "3M", "3L48", "3S0"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M"],
			shadowsneak: ["9L19", "7L13", "6L13", "5L20", "4L20"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skillswap: ["9M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "7L46", "6T", "6L46", "5T", "5L58", "4M", "4L51", "3M", "3L55"],
			snore: ["7T", "6T", "3T"],
			spite: ["9L1", "7T", "7L1", "6T", "6L1", "5T", "5L16", "4T", "4L16", "3L25"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["9L40", "7L40", "6L34", "5L42", "4T", "4L35"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swordsdance: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M", "3M"],
			throatchop: ["7T"],
			thunder: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			trick: ["9M", "9L46", "7T", "7L58", "6T", "6L58", "5T", "5L75", "4T", "4L66"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			willowisp: ["9M", "9L16", "7M", "7L16", "6M", "6L13", "5M", "5L23", "4M", "4L23", "3L32"],
		},
		eventData: [
			{generation: 3, level: 37, abilities: ["insomnia"], moves: ["helpinghand", "feintattack", "shadowball", "curse"]},
			{generation: 5, level: 37, gender: "F", isHidden: true, moves: ["feintattack", "hex", "shadowball", "cottonguard"]},
		],
		encounters: [
			{generation: 5, level: 32},
		],
	},
	duskull: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L9", "6L9", "5L14", "4L14", "3L16", "3S1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L12", "7L30", "6L17", "5L17", "4L17", "3L23", "3S1"],
			curse: ["8L36", "7L33", "6L30", "5L30", "4L30", "3L34", "3S0"],
			darkpulse: ["8M", "7M", "7E", "6M", "6E", "5T", "5E", "4M"],
			destinybond: ["7E", "6E", "5E", "4E", "3E"],
			disable: ["8L4", "7L6", "6L6", "5L6", "5D", "4L6", "3L5"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7E", "6E", "5E", "4E", "3E"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			foresight: ["7L14", "6L9", "5L9", "4L9", "3L12"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L44", "7L54", "6L49", "5L49", "4L46", "3L49"],
			gravity: ["7T", "6T", "5T", "4T"],
			grudge: ["8E", "7E", "6E", "5E", "4E", "3E"],
			haze: ["8E", "7E", "6E"],
			headbutt: ["4T"],
			helpinghand: ["8M", "3S1"],
			hex: ["8M", "8L32", "7L38", "6L38", "5L38"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["8M", "7E", "6E", "5E", "4E", "3E"],
			infestation: ["7M", "6M"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			meanlook: ["8L28", "7L46", "6L41", "5L41", "4L38", "3L45", "3S0"],
			memento: ["8E", "7E", "6E", "5E", "4E", "3E"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			nightshade: ["8L16", "7L1", "6L1", "5L1", "4L1", "3L1"],
			ominouswind: ["7E", "6E", "5E", "4T", "4E"],
			painsplit: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E", "3E"],
			payback: ["8M", "8L20", "7M", "7L49", "6M", "6L46", "5M", "5L46", "4M", "4L41"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			pursuit: ["7L22", "6L22", "5L25", "4L25", "3L27", "3S0"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["8M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "8L40", "7M", "7L41", "6M", "6L41", "5M", "4M", "3M", "3S1"],
			shadowsneak: ["8L8", "7L17", "6L17", "5L22", "4L22"],
			skillswap: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "5D", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			willowisp: ["8M", "8L24", "7M", "7L25", "6M", "6L25", "5M", "5L33", "4M", "4L33", "3L38", "3S0"],
			wonderroom: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 3, level: 45, moves: ["pursuit", "curse", "willowisp", "meanlook"], pokeball: "pokeball"},
			{generation: 3, level: 19, moves: ["helpinghand", "shadowball", "astonish", "confuseray"]},
		],
	},
	dusclops: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L14", "4L14", "3L16"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bind: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4L1", "3L1"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L12", "7L30", "6L17", "5L17", "4L17", "3L23"],
			counter: ["3T"],
			curse: ["8L36", "7L33", "6L30", "5L30", "4L30", "3L34"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			disable: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T"],
			flash: ["6M", "5M", "4M", "3M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			foresight: ["7L14", "6L9", "5L9", "4L9", "3L12"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L48", "7L1", "6L1", "5L61", "4L61", "3L58"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			headbutt: ["4T"],
			helpinghand: ["8M"],
			hex: ["8M", "8L32", "7L40", "6L40", "5L42"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["8M"],
			infestation: ["7M", "6M"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			meanlook: ["8L28", "7L52", "6L49", "5L49", "4L43", "3L51"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metronome: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			nightshade: ["8L16", "7L1", "6L1", "5L1", "4L1", "3L1"],
			ominouswind: ["4T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "8L20", "7M", "7L57", "6M", "6L57", "5M", "5L58", "4M", "4L51"],
			poltergeist: ["8T"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			pursuit: ["7L22", "6L22", "5L25", "4L25", "3L27"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			revenge: ["8M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["8M", "8L42", "7M", "7L45", "6M", "6L45", "5M", "4M", "3M"],
			shadowpunch: ["8L0", "7L1", "6L37", "5L37", "4L37", "3L37"],
			shadowsneak: ["8L1", "7L17", "6L17", "5L22", "4L22"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			taunt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			willowisp: ["8M", "8L24", "7M", "7L25", "6M", "6L25", "5M", "5L33", "4M", "4L33", "3L41"],
			wonderroom: ["8M", "7T", "6T", "5T"],
		},
		encounters: [
			{generation: 4, level: 16},
			{generation: 6, level: 30},
		],
	},
	dusknoir: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L14", "4L14"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bind: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4L1"],
			blizzard: ["8M", "7M", "6M", "5M", "4M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["8L12", "7L30", "6L17", "5L17", "4L17"],
			curse: ["8L36", "7L33", "6L30", "5L30", "4L30"],
			darkestlariat: ["8M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			destinybond: ["8L54"],
			disable: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			firepunch: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			flash: ["6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			foresight: ["7L14", "6L9", "5L9", "4L9"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["8M", "8L48", "7L1", "6L1", "5L61", "4L61"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			headbutt: ["4T"],
			helpinghand: ["8M"],
			hex: ["8M", "8L32", "7L40", "6L40", "5L42"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icepunch: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			icywind: ["8M", "7T", "6T", "5T", "4T"],
			imprison: ["8M"],
			infestation: ["7M", "6M"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			meanlook: ["8L28", "7L52", "6L49", "5L49", "4L43"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightshade: ["8L16", "7L1", "6L1", "5L1", "4L1"],
			ominouswind: ["4T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "8L20", "7M", "7L57", "6M", "6L57", "5M", "5L58", "4M", "4L51"],
			poltergeist: ["8T"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			psychic: ["8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			pursuit: ["7L22", "6L22", "5L25", "4L25"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowball: ["8M", "8L42", "7M", "7L45", "6M", "6L45", "5M", "4M"],
			shadowpunch: ["8L1", "7L1", "6L37", "5L37", "4L37"],
			shadowsneak: ["8L1", "7L17", "6L17", "5L22", "4L22"],
			skillswap: ["8M", "7T", "6T", "5T", "4M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			taunt: ["8M", "7M", "6M", "5M", "4M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			willowisp: ["8M", "8L24", "7M", "7L25", "6M", "6L25", "5M", "5L33", "4M", "4L33"],
			wonderroom: ["8M", "7T", "6T"],
		},
	},
	tropius: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			aircutter: ["9M", "4T"],
			airslash: ["9M", "9L36", "7L36", "6L36", "5L51", "4L47", "4S0"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bestow: ["7L46", "6L1", "5L57"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L41", "7L41", "6L37", "5L37", "4L37", "3T", "3L37"],
			brutalswing: ["7M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			bulletseed: ["9M", "7E", "6E", "5E", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			curse: ["9E", "7E", "6E", "5E", "4E"],
			cut: ["6M", "5M", "4M", "3M"],
			defog: ["7T", "4M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragondance: ["9M", "9E", "7E", "6E", "5E", "4E"],
			dragonhammer: ["7E"],
			dragonpulse: ["9M", "7T", "6T", "5T"],
			dragontail: ["9M"],
			earthquake: ["9M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "4M", "3T"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			fly: ["9M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigadrain: ["9M", "7T", "6T", "5T", "4M", "3M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["9M"],
			growth: ["9L1", "7L1", "6L1", "5L7", "4L7", "3L7"],
			gust: ["9L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			headbutt: ["9E", "7E", "6E", "5E", "4T", "4E", "3E"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hurricane: ["9M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			leafblade: ["9E", "7E", "6E", "5E", "4E"],
			leafstorm: ["9M", "9L1", "7L1", "7E", "6L1", "6E", "5L71", "5E", "4L61", "4E"],
			leaftornado: ["7L26", "6L26", "5L47"],
			leechseed: ["9E", "7E", "6E", "5E", "5D", "4E", "3E"],
			leer: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			magicalleaf: ["9M", "9L16", "7L16", "6L16", "5L31", "4L31", "3L31"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["7L30", "7E", "6L1", "6E", "5L67", "5E", "4M", "4L57"],
			naturepower: ["7M", "7E", "6M", "6E", "5E", "4E", "3E"],
			ominouswind: ["4T"],
			outrage: ["9M", "9L46", "7T", "6T", "5T", "4T"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["9M"],
			razorleaf: ["9L1", "7L1", "6L1", "5L11", "4L11", "3L11"],
			razorwind: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seedbomb: ["9M", "7T", "6T", "5T"],
			silverwind: ["5D", "4M"],
			slam: ["9E", "7E", "6E", "5E", "4E", "3E"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "9L56", "7M", "7L56", "6M", "6L56", "5M", "5L61", "4M", "4L51", "4S0", "3M", "3L41"],
			steelwing: ["7M", "6M", "4M", "3M"],
			stomp: ["9L10", "7L10", "6L10", "5L17", "4L17", "3L17"],
			stompingtantrum: ["9M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M", "4S0", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetscent: ["9L6", "7L6", "6L6", "5L21", "4L21", "3L21"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M", "3T"],
			synthesis: ["9L50", "7T", "7L50", "7E", "6T", "6L41", "6E", "5T", "5L41", "5E", "4T", "4L41", "4E", "4S0", "3L47"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			twister: ["4T"],
			uturn: ["9M"],
			whirlwind: ["9L21", "7L21", "6L21", "5L27", "4L27", "3L27"],
			wideguard: ["9L30"],
			worryseed: ["7T", "6T", "5T", "4T"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 4, level: 53, gender: "F", nature: "Jolly", abilities: ["chlorophyll"], moves: ["airslash", "synthesis", "sunnyday", "solarbeam"], pokeball: "cherishball"},
		],
	},
	chingling: {
		learnset: {
			allyswitch: ["7T"],
			astonish: ["7L7", "6L7", "5L9", "4L9"],
			attract: ["7M", "6M", "5M", "4M"],
			bind: ["7T", "6T", "5T"],
			calmmind: ["7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["7L10", "6L10", "5L14", "4L14"],
			cosmicpower: ["7E", "6E"],
			curse: ["7E", "6E", "5E", "4E"],
			dazzlinggleam: ["7M", "6M"],
			disable: ["7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M", "4E"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M"],
			entrainment: ["7L19", "6L19", "5L25"],
			facade: ["7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["7E", "6E", "5E", "4E"],
			grassknot: ["7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			growl: ["7L4", "6L4", "5L6", "4L6"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hypervoice: ["7T", "6T", "5T"],
			hypnosis: ["7E", "6E", "5E", "4E"],
			icywind: ["7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "7L16", "6T", "6L16", "5T", "5L22", "4T", "4L22"],
			lightscreen: ["7M", "6M", "5M", "4M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			naturalgift: ["4M"],
			protect: ["7M", "6M", "5M", "4M"],
			psychic: ["7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["7M", "6M", "5M"],
			raindance: ["7M", "6M", "5M", "4M"],
			recover: ["7E", "6E", "5E", "4E"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			storedpower: ["7E", "6E", "5E"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			taunt: ["7M", "6M", "5M", "4M"],
			telekinesis: ["7T", "5M"],
			thunderwave: ["7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["7T", "6T", "5T", "4T"],
			trickroom: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "7L32", "6T", "6L17", "5T", "5L17", "4T", "4L17"],
			wish: ["7E", "6E", "5E", "4E"],
			wrap: ["7L1", "6L1", "5L1", "4L1"],
			yawn: ["7L13", "6L13"],
			zenheadbutt: ["7T", "6T", "5T", "4T"],
		},
	},
	chimecho: {
		learnset: {
			allyswitch: ["7T"],
			astonish: ["7L1", "6L1", "5L9", "4L9", "3L9", "3S0"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			bind: ["7T", "6T", "5T"],
			calmmind: ["7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["7L1", "6L1", "5L14", "4L14", "3L14"],
			cosmicpower: ["7E", "6E"],
			craftyshield: ["7E"],
			curse: ["7E", "6E", "5E", "4E", "3E"],
			dazzlinggleam: ["7M", "6M"],
			defensecurl: ["3T"],
			defog: ["7T"],
			disable: ["7E", "6E", "5E", "4E", "3E"],
			doubleedge: ["7L42", "6L33", "5L33", "4L33", "3T", "3L33"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dreameater: ["7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			extrasensory: ["7L22", "6L22", "5L46", "4L46"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["7E", "6E", "5E", "4E"],
			grassknot: ["7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			growl: ["7L1", "6L1", "5L6", "4L6", "3L6", "3S0"],
			healbell: ["7T", "7L27", "6T", "6L27", "5T", "5L38", "4T", "4L38", "3L38"],
			healingwish: ["7L1", "6L1", "5L57", "4L49"],
			healpulse: ["7L47", "6L47", "5L49"],
			helpinghand: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["7T", "6T", "5T", "5D"],
			hypnosis: ["7E", "6E", "5E", "5D", "4E", "3E"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			perishsong: ["7E"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychic: ["7M", "6M", "5M", "4M", "3M", "3L46"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["7M", "6M", "5M"],
			psywave: ["7L16", "6L16", "5L30", "4L30", "3L30"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			recover: ["7E", "6E"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "7L37", "6M", "6L37", "5M", "5L41", "4M", "4L41", "3M", "3L41"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["7T", "7E", "6T", "6E", "5T", "5E", "4M", "3M"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			storedpower: ["7E", "6E", "5E"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			synchronoise: ["7L1", "6L1", "5L54"],
			takedown: ["7L19", "6L19", "5L22", "4L22", "3L17"],
			taunt: ["7M", "6M", "5M", "4M", "3M"],
			telekinesis: ["7T", "5M"],
			thunderwave: ["7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["7T", "6T", "5T", "4T"],
			trickroom: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "7L32", "6T", "6L17", "5T", "5L17", "4T", "4L17", "3L22"],
			wish: ["7E", "6E", "5E", "4E"],
			wrap: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1", "3S0"],
			yawn: ["7L13", "6L13", "5L25", "4L25", "3L25"],
			zenheadbutt: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 3, level: 10, gender: "M", moves: ["wrap", "growl", "astonish"], pokeball: "pokeball"},
		],
	},
	absol: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			airslash: ["8M"],
			assurance: ["8M", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			batonpass: ["8M", "7E", "6E", "5E", "4E", "3E"],
			bite: ["8E", "7L16", "6L16", "5L28", "4L28", "3L21", "3S2"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["8M", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brutalswing: ["8M", "7M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["8E", "7E", "6E", "5E", "4E", "3E"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			detect: ["8L15", "7L33", "6L1", "5L49", "4L49"],
			doubleedge: ["8E", "7E", "6E", "5E", "4E", "3T", "3E"],
			doubleteam: ["8L5", "7M", "7L19", "6M", "6L19", "5M", "5L33", "4M", "4L33", "3M", "3L31", "3S3"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			feint: ["8E", "7L1", "6L1", "5L1", "5D", "4L1"],
			feintattack: ["7E", "6E", "5E", "4E", "3E"],
			fireblast: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flamethrower: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			focusenergy: ["8M", "8L35"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			futuresight: ["8M", "8L50", "7L1", "6L1", "5L41", "4L41", "3L41", "3S3"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			hex: ["8M", "7E", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			knockoff: ["8L10", "7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "6L1", "5L4", "4L4", "3L5", "3S0", "3S1"],
			magiccoat: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			meanlook: ["8E", "7E", "6E", "5E", "4E"],
			mefirst: ["7L41", "7E", "6L1", "6E", "5L57", "5E", "4L57", "4E"],
			megahorn: ["8M", "7E", "6E", "5E", "5D", "4E"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			nightslash: ["8L30", "7L29", "6L29", "5L52", "4L52"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			perishsong: ["8L55", "7L1", "7E", "6L1", "6E", "5L65", "5E", "4L65", "3L46", "3S3"],
			playrough: ["8M", "7E", "6E"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychocut: ["8M", "7L37", "6L37", "5L60", "4L60"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			punishment: ["7E", "6E", "5E", "4E"],
			pursuit: ["7L10", "6L10", "5L20", "4L20"],
			quickattack: ["8L1", "7L1", "6L1", "5L12", "4L12", "3L13"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			razorwind: ["7L49", "6L1", "5L17", "4L17", "3L17", "3S2"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scratch: ["7L1", "6L1", "5L1", "4L1", "3L1", "3S0", "3S1"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slash: ["8L25", "7L22", "6L22", "5L36", "4L36", "3L36", "3S3"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			spite: ["7T", "6T", "5T", "4T", "3S1", "3S2"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			suckerpunch: ["8L40", "7L45", "7E", "6L45", "6E", "5L44", "5E", "4T", "4L44", "4E"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "7T", "6T", "5T", "5D", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			swordsdance: ["8M", "8L45", "7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L25", "3T", "3L26", "3S2"],
			taunt: ["8M", "8L20", "7M", "7L13", "6M", "6L1", "5M", "5L9", "4M", "4L9", "3M", "3L9"],
			thief: ["8M", "7M", "6M", "5M", "4M", "3M"],
			throatchop: ["8M", "7T"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			willowisp: ["8M", "7M", "6M", "5M", "4M"],
			wish: ["3S0"],
			xscissor: ["8M", "7M", "6M", "5M", "4M"],
			zenheadbutt: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, abilities: ["pressure"], moves: ["scratch", "leer", "wish"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, abilities: ["pressure"], moves: ["scratch", "leer", "spite"], pokeball: "pokeball"},
			{generation: 3, level: 35, abilities: ["pressure"], moves: ["razorwind", "bite", "swordsdance", "spite"], pokeball: "pokeball"},
			{generation: 3, level: 70, abilities: ["pressure"], moves: ["doubleteam", "slash", "futuresight", "perishsong"], pokeball: "pokeball"},
		],
	},
	snorunt: {
		learnset: {
			astonish: ["9L1", "8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "8M", "7E", "6E", "5E", "4M"],
			bide: ["7E", "6E", "5E", "4E"],
			bite: ["9L35", "8L35", "7L19", "6L10", "5L10", "4L10", "3L10", "3S0"],
			blizzard: ["9M", "9L60", "8M", "8L60", "7M", "7L46", "6M", "6L46", "5M", "5L46", "4M", "4L46", "3M", "3L43"],
			block: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3E"],
			bodyslam: ["9M", "8M", "3T"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L55", "8M", "8L55", "7L41", "6L31", "5L31", "4L31", "3L28"],
			disable: ["9E", "8E", "7E", "6E", "5E", "4E"],
			doubleedge: ["3T"],
			doubleteam: ["9L10", "8L10", "7M", "7L5", "6M", "6L4", "5M", "5L4", "4M", "4L4", "3M", "3L7"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M", "7E", "6E", "5E"],
			flash: ["6M", "5M", "4M", "3M"],
			frostbreath: ["9L30", "8L30", "7M", "7L37", "6M", "6L37", "5M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			hail: ["8M", "8L45", "7M", "7L50", "6M", "6L40", "5M", "5L40", "4M", "4L40", "3M", "3L37"],
			headbutt: ["9L1", "8L50", "7L28", "6L19", "5L19", "4T", "4L19", "3L19"],
			helpinghand: ["9M"],
			hex: ["9M", "8M", "7E", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M", "3L34"],
			icefang: ["9M", "9L40", "8M", "8L40", "7L23", "6L23", "5L28", "4L28"],
			iceshard: ["9L15", "8L15", "7L10", "6L10", "5L37", "4L37"],
			icespinner: ["9M"],
			iciclecrash: ["9E", "8E"],
			icywind: ["9M", "9L25", "8M", "8L25", "7T", "7L14", "6T", "6L13", "5T", "5L13", "4T", "4L13", "3T", "3L16", "3S0"],
			leer: ["9L5", "8L5", "7L1", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			powdersnow: ["9L1", "8L1", "7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			protect: ["9M", "9L20", "8M", "8L20", "7M", "7L32", "6M", "6L22", "5M", "5L22", "4M", "4L22", "3M", "3L25"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["9E", "8E", "7E", "6E", "5E", "4T", "4E"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			sing: ["3S0"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			snowscape: ["9M", "9L45"],
			spikes: ["9M", "8M", "7E", "6E", "5E", "4E", "3E"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			switcheroo: ["9E", "8E", "7E", "6E"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			waterpulse: ["9M", "7T", "6T", "5D", "4M", "3M", "3S0"],
			weatherball: ["9L50", "8M", "7E", "6E", "5E", "5D", "4E"],
		},
		eventData: [
			{generation: 3, level: 20, abilities: ["innerfocus"], moves: ["sing", "waterpulse", "bite", "icywind"]},
		],
	},
	glalie: {
		learnset: {
			astonish: ["9L1", "8L1"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			avalanche: ["9M", "8M", "4M"],
			bite: ["9L35", "8L35", "7L19", "6L1", "5L1", "4L1", "3L1"],
			blizzard: ["9M", "9L68", "8M", "8L68", "7M", "7L48", "6M", "6L48", "5M", "5L51", "4M", "4L51", "3M", "3L53"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["9M", "8M", "3T"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L61", "8M", "8L61", "7L41", "6L31", "5L31", "4L31", "3L28"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["9L1", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3M", "3L1"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "8M", "4M", "3T"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			faketears: ["9M", "8M"],
			flash: ["6M", "5M", "4M", "3M"],
			foulplay: ["9M"],
			freezedry: ["9L0", "8L0", "7L1", "6L42"],
			frostbreath: ["9L30", "8L30", "7M", "7L37", "6M", "6L37", "5M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "8L47", "7M", "7L54", "6M", "6L40", "5M", "5L40", "4M", "4L40", "3M", "3L42"],
			headbutt: ["9L1", "8L54", "7L28", "6L19", "5L19", "4T", "4L19", "3L19"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "8M", "7M", "6M", "6L37", "5M", "5L37", "4M", "4L37", "3M", "3L34"],
			icefang: ["9M", "9L40", "8M", "8L40", "7L23", "6L23", "5L28", "4L28"],
			iceshard: ["9L15", "8L15", "7L1", "6L1"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L25", "8M", "8L25", "7T", "7L14", "6T", "6L13", "5T", "5L13", "4T", "4L13", "3T", "3L16"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			mimic: ["3T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			powdersnow: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			protect: ["9M", "9L20", "8M", "8L20", "7M", "7L32", "6M", "6L22", "5M", "5L22", "4M", "4L22", "3M", "3L25"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scaryface: ["9M", "8M"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			sheercold: ["9L1", "8L1", "7L1", "6L1", "5L59", "4L59", "3L61"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			snowscape: ["9M", "9L47"],
			spikes: ["9M", "8M"],
			spite: ["7T", "6T", "5T", "4T"],
			steelroller: ["8T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			terablast: ["9M"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trailblaze: ["9M"],
			waterpulse: ["9M", "7T", "6T", "4M", "3M"],
			weatherball: ["9L54", "8M"],
		},
	},
	froslass: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["9L1", "8L1", "7L19", "6L1", "5L1", "4L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			auroraveil: ["9L54", "8L54", "7M"],
			avalanche: ["9M", "8M", "4M"],
			bite: ["9L1", "8L1"],
			blizzard: ["9M", "9L68", "8M", "8L68", "7M", "7L48", "6M", "6L48", "5M", "5L51", "4M", "4L51"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["9M", "8M"],
			captivate: ["7L41", "6L31", "5L31", "4M", "4L31"],
			charm: ["9M", "8M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L35", "8L35", "7L32", "6L19", "5L19", "4L19"],
			crunch: ["9M", "9L1", "8M", "8L1"],
			destinybond: ["9L1", "8L1", "7L1", "6L1", "5L59", "4L59"],
			doubleteam: ["9L1", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			drainingkiss: ["9M", "9L20", "8M", "8L20", "7L23", "6L23"],
			dreameater: ["7M", "6M", "5M", "4M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			faketears: ["9M", "8M"],
			flash: ["6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frostbreath: ["9L30", "8L30", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "8L40", "7M", "7L54", "6M", "6L40", "5M", "5L40", "4M", "4L40"],
			headbutt: ["9L1", "8L1", "4T"],
			helpinghand: ["9M"],
			hex: ["9M", "9L0", "8M", "8L0"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icefang: ["9M", "9L1", "8M", "8L1"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			iceshard: ["9L15", "8L15", "7L1", "6L1", "5L37", "4L37"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L25", "8M", "8L25", "7T", "7L14", "6T", "6L13", "5T", "5L13", "4T", "4L13"],
			imprison: ["9M", "8M"],
			laserfocus: ["7T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			ominouswind: ["7L1", "6L22", "5L22", "4T", "4L22"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poltergeist: ["8T"],
			powdersnow: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["9M", "9L1", "8M", "8L1", "7M", "6M", "5M", "4M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "9L61", "8M", "8L61", "7M", "7L42", "6M", "6L42", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M", "9L40"],
			spikes: ["9M", "8M"],
			spite: ["7T", "6T", "5T", "4T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["4T"],
			swagger: ["7M", "6M", "5M", "4M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			tripleaxel: ["8T"],
			wakeupslap: ["7L37", "6L28", "5L28", "4L28"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			weatherball: ["8M"],
			willowisp: ["9M", "9L47", "8M", "8L47", "7M", "7L28"],
		},
	},
	spheal: {
		learnset: {
			aquaring: ["8E", "7E", "6E", "5E", "4E"],
			aquatail: ["7T", "6T", "5T", "5D", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8L28", "7L21", "6L21", "5L25", "4L25", "3L25", "3S0"],
			bellydrum: ["8E", "7E", "6E"],
			blizzard: ["8M", "8L44", "7M", "7L41", "6M", "6L41", "5M", "5L43", "4M", "4L43", "3M", "3L43"],
			bodyslam: ["8M", "8L36", "7L26", "6L19", "5L19", "4L19", "3T", "3L19"],
			brine: ["8M", "8L24", "7L17", "6L17", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			charm: ["3S0"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "6E", "5E", "4E", "3E"],
			defensecurl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3T", "3L1"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "8L33", "7L9", "6L7", "5L7", "4L7", "3L7"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			fissure: ["8E", "7E", "6E", "5E", "5D", "4E", "3E"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["8L4", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "8L48", "7M", "7L36", "6M", "6L31", "5M", "5L31", "4M", "4L31", "3M", "3L31"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			iceball: ["7L13", "6L13", "5L13", "4L13", "3L13"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			mimic: ["3T"],
			mudslap: ["4T", "3T", "3S0"],
			naturalgift: ["4M"],
			powdersnow: ["8L12", "7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L16", "7M", "7L31", "6M", "6L31", "5M", "5L37", "4M", "4L37", "3M", "3L37"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L1", "7L5", "7E", "6L5", "6E", "5E", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sheercold: ["8L52", "7L46", "6L46", "5L49", "4L49", "3L49"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E", "4M", "3T"],
			snore: ["8M", "8L20", "7T", "7L31", "6T", "6L31", "5T", "5L37", "4T", "4L37", "3T", "3L37"],
			spitup: ["8E", "7E", "6E", "5E", "4E", "3E"],
			steelroller: ["8T"],
			stockpile: ["8E", "7E", "6E", "5E", "4E", "3E"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["8M", "8L40", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swallow: ["8E", "7E", "6E", "5E", "4E", "3E"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L8", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			watersport: ["7E", "6E", "5E", "4E", "3E"],
			whirlpool: ["8M", "4M"],
			yawn: ["8E", "7E", "6E", "5E", "4E", "3E"],
		},
		eventData: [
			{generation: 3, level: 17, abilities: ["thickfat"], moves: ["charm", "aurorabeam", "watergun", "mudslap"]},
		],
	},
	sealeo: {
		learnset: {
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8L28", "7L21", "6L21", "5L25", "4L25", "3L25"],
			blizzard: ["8M", "8L52", "7M", "7L45", "6M", "6L45", "5M", "5L47", "4M", "4L47", "3M", "3L47"],
			bodyslam: ["8M", "8L40", "7L26", "6L19", "5L19", "4L19", "3T", "3L19"],
			brine: ["8M", "8L24", "7L17", "6L17", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			defensecurl: ["8L1", "7L1", "6L1", "3T", "3L1"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "8L35", "7L9", "6L1", "5L1", "4L1", "3L1"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "8L58", "7M", "7L38", "6M", "6L31", "5M", "5L31", "4M", "4L31", "3M", "3L31"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			iceball: ["7L13", "6L13", "5L13", "4L13", "3L13"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			iciclespear: ["8M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			powdersnow: ["8L12", "7L1", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L16", "7M", "7L31", "6M", "6L31", "5M", "5L39", "4M", "4L39", "3M", "3L39"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L1", "7L5", "6L5", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sheercold: ["8L64", "7L52", "6L52", "5L55", "4L55", "3L55"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "8L20", "7T", "7L31", "6T", "6L31", "5T", "5L39", "4T", "4L39", "3T", "3L39"],
			steelroller: ["8T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["8M", "8L46", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["8L0", "7M", "7L1", "6M", "6L32", "5M", "5L32", "4M", "4L32", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
		},
		encounters: [
			{generation: 4, level: 25},
			{generation: 6, level: 28, maxEggMoves: 1},
		],
	},
	walrein: {
		learnset: {
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			aurorabeam: ["8L28", "7L19", "6L19", "5L25", "4L25", "3L25"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "8L56", "7M", "7L49", "6M", "6L49", "5M", "5L52", "4M", "4L52", "3M", "3L50"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L40", "7L25", "6L19", "5L19", "4L19", "3T", "3L19"],
			brine: ["8M", "8L24", "7L19", "6L19", "5S0", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			defensecurl: ["8L1", "7L1", "6L1", "3T", "3L1"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "8L35", "7L7", "6L1", "5L1", "4L1", "3L1"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			growl: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			hail: ["8M", "8L64", "7M", "7L38", "6M", "6L31", "5M", "5L31", "5S0", "4M", "4L31", "3M", "3L31"],
			headbutt: ["4T"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			iceball: ["7L13", "6L13", "5L13", "4L13", "3L13"],
			icebeam: ["8M", "7M", "6M", "5M", "5S0", "4M", "3M"],
			icefang: ["8M", "8L1", "7L1", "6L44", "5L44", "4L44"],
			iciclespear: ["8M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			liquidation: ["8M"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			powdersnow: ["8L12", "7L1", "6L1", "5L1", "4L1", "3L1"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L16", "7M", "7L31", "6M", "6L31", "5M", "5L39", "4M", "4L39", "3M", "3L39"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["8L1", "7L7", "6L7", "4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sheercold: ["8L72", "7L60", "6L60", "5L65", "5S0", "4L65", "3L61"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "8L20", "7T", "7L31", "6T", "6L31", "5T", "5L39", "4T", "4L39", "3T", "3L39"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["8M", "8L48", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["8L1", "7M", "7L1", "6M", "6L32", "5M", "5L32", "4M", "4L32", "3T"],
			swordsdance: ["8M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 5, level: 50, abilities: ["thickfat"], moves: ["icebeam", "brine", "hail", "sheercold"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 5, level: 30},
		],
	},
	clamperl: {
		learnset: {
			aquaring: ["7E", "6E", "5E", "5D", "4E"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			barrier: ["7E", "6E", "5E", "4E", "3E"],
			blizzard: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["7E", "6E", "5E", "4E", "3T", "3E"],
			brine: ["7E", "6E", "5E", "4M"],
			captivate: ["5D", "4M"],
			clamp: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			confide: ["7M", "6M"],
			confuseray: ["7E", "6E", "5E", "4E", "3E"],
			dive: ["6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["7E", "6E", "5E", "4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			hail: ["7M", "6M", "5M", "4M", "3M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			irondefense: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1", "3L1"],
			mimic: ["3T"],
			muddywater: ["7E", "6E", "5E", "4E"],
			mudsport: ["7E", "6E", "5E", "4E", "3E"],
			naturalgift: ["4M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			refresh: ["7E", "6E", "5E", "4E", "3E"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shellsmash: ["7L50", "6L50", "5L51"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			supersonic: ["7E", "6E", "5E", "4E", "3E"],
			surf: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["7M", "6M", "5M", "4M", "3M"],
			watergun: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			waterpulse: ["7T", "7E", "6T", "6E", "5E", "4M", "3M"],
			whirlpool: ["7L1", "6L1", "5L1", "5D", "4M", "4L1", "3L1"],
		},
	},
	huntail: {
		learnset: {
			aquatail: ["7T", "7L39", "6T", "6L39", "5T", "5L46", "4T", "4L46"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["7L29", "6L29", "5L33", "4L33", "3L43"],
			bind: ["7T", "6T", "5T"],
			bite: ["7L1", "6L1", "5L6", "4L6", "3L8"],
			blizzard: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			bounce: ["7T", "6T", "5T", "4T"],
			brine: ["7L19", "6L19", "5L28", "4M", "4L28"],
			captivate: ["4M"],
			coil: ["7L45", "6L45"],
			confide: ["7M", "6M"],
			crunch: ["7L34", "6L34", "5L42", "4L42", "3L36"],
			dive: ["7L26", "6M", "6L26", "5M", "5L37", "4T", "4L37", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			feintattack: ["7L11", "6L11"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			hail: ["7M", "6M", "5M", "4M", "3M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["7L50", "6L50", "5L51", "4L51", "3L50"],
			hyperbeam: ["7M", "6M", "5M", "4M", "3M"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icefang: ["7L16", "6L16", "5L24", "4L24"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			scaryface: ["7L9", "6L9", "5L19", "4L19", "3L29"],
			screech: ["7L5", "6L5", "5L10", "4L10", "3L15"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "6T", "5T", "4M", "3M"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["7L23", "6L23", "4T"],
			superfang: ["7T", "6T", "5T", "4T"],
			surf: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "7L14", "6T", "6L14", "5L15", "4M", "4L15", "3M", "3L22"],
			whirlpool: ["7L1", "6L1", "5L1", "4M", "4L1", "3L1"],
		},
	},
	gorebyss: {
		learnset: {
			agility: ["7L9", "6L9", "5L10", "4L10", "3L15"],
			amnesia: ["7L16", "6L16", "5L19", "4L19", "3L29"],
			aquaring: ["7L19", "6L19", "5L24", "4L24"],
			aquatail: ["7T", "7L39", "6T", "6L39", "5T", "5L46", "4T", "4L46"],
			attract: ["7M", "6M", "5M", "4M", "3M"],
			batonpass: ["7L29", "6L29", "5L33", "4L33", "3L43"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["3T"],
			bounce: ["7T", "6T", "5T", "4T"],
			brine: ["4M"],
			captivate: ["7L23", "6L23", "5L28", "4M", "4L28"],
			coil: ["7L45", "6L45"],
			confide: ["7M", "6M"],
			confusion: ["7L1", "6L1", "5L6", "4L6", "3L8"],
			dive: ["7L26", "6M", "6L26", "5M", "5L37", "4T", "4L37", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainingkiss: ["7L11", "6L11"],
			endure: ["4M", "3T"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			hail: ["7M", "6M", "5M", "4M", "3M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["7L50", "6L50", "5L51", "4L51", "3L50"],
			hyperbeam: ["7M", "6M", "5M", "4M", "3M"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			infestation: ["7M", "6M"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychic: ["7M", "7L34", "6M", "6L34", "5M", "5L42", "4M", "4L42", "3M", "3L36"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			surf: ["7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "7L14", "6T", "6L14", "5L15", "4M", "4L15", "3M", "3L22"],
			watersport: ["7L5", "6L5"],
			whirlpool: ["7L1", "6L1", "5L1", "4M", "4L1", "3L1"],
		},
	},
	relicanth: {
		learnset: {
			amnesia: ["8M", "7E", "6E", "5E", "4E", "3E"],
			ancientpower: ["8L10", "7L21", "6L1", "5L43", "4T", "4L43", "3L43"],
			aquatail: ["8L30", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			blizzard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brine: ["8M", "7E", "6E", "5E", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			dive: ["8M", "8L20", "7L26", "6M", "6L26", "5M", "5L57", "4T", "4L57", "3M"],
			doubleedge: ["8L50", "7L50", "6L50", "5L50", "4L50", "3T", "3L57"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthpower: ["8M", "7T", "6T", "5T", "5D", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["8L40", "7L1"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			harden: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			headbutt: ["4T"],
			headsmash: ["8L55", "7L1", "6L1", "5L78", "4L78"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["8M", "8L45", "7L46", "6L1", "5L71", "4L71", "3L64"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irondefense: ["8M"],
			liquidation: ["8M"],
			magnitude: ["7E", "6E", "5E", "4E", "3E"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			muddywater: ["8M", "7E", "6E", "5E", "4E"],
			mudshot: ["8M", "7E", "6E", "5E"],
			mudslap: ["8E", "7E", "6E", "5E", "4T", "4E", "3T"],
			mudsport: ["7L1", "6L1", "5L36", "4L36", "3L36"],
			naturalgift: ["4M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "8L35", "7M", "7L41", "6M", "6L41", "5M", "5L64", "4M", "4L64", "3M", "3L50"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "4E", "3T", "3E"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "7L15", "6M", "6L15", "5M", "5L15", "4M", "4L15", "3M", "3L15"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			skullbash: ["8E", "7E", "6E", "5E", "4E", "3E"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E", "5D", "4M", "4E", "3T", "3E"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["8L25", "7L31", "6L29", "5L29", "4L29", "3L29"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["8L5", "7L1", "6L1", "5L8", "5D", "4L8", "3L8"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			watersport: ["7E", "6E", "5E", "4E", "3E"],
			whirlpool: ["8M", "4M"],
			yawn: ["8L15", "7L35", "6L22", "5L22", "4L22", "3L22"],
			zenheadbutt: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
		},
	},
	luvdisc: {
		learnset: {
			agility: ["9M", "9L7", "7L7", "6L7", "5L9", "4L9", "3L16"],
			aquajet: ["7E", "6E", "5E", "4E"],
			aquaring: ["9L40", "7L40", "7E", "6L40", "6E", "5L46", "5E", "4L37", "4E"],
			attract: ["9L20", "7M", "7L20", "6M", "6L22", "5M", "5L27", "4M", "4L22", "3M", "3L28"],
			babydolleyes: ["9L37"],
			blizzard: ["9M", "7M", "6M", "5M", "4M", "3M"],
			bounce: ["7T", "6T", "5T", "4T"],
			brine: ["7E", "6E", "5E", "4M"],
			captivate: ["7L37", "7E", "6L46", "6E", "5L51", "5E", "4M", "4L40", "4E"],
			charm: ["9M", "9L1", "7L1", "6L1", "5L4", "5D", "4L4", "3L4"],
			confide: ["7M", "6M"],
			dive: ["6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainingkiss: ["9M", "9L22", "7L9", "6L9"],
			endure: ["9M", "4M", "3T"],
			entrainment: ["7E", "6E"],
			facade: ["9M", "7M", "6M", "5M", "4M", "3M"],
			flail: ["9L26", "7L26", "6L27", "5L31", "4L46", "3L40"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			hail: ["7M", "6M", "5M", "4M", "3M"],
			healpulse: ["7E", "6E", "5E"],
			heartstamp: ["7L22"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "9L46", "7L46", "6L40", "5L40"],
			icebeam: ["9M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "7T", "6T", "5T", "5D", "4T", "3T"],
			liquidation: ["9M", "7T"],
			luckychant: ["7L13", "6L14", "5L17", "4L17"],
			mimic: ["3T"],
			mudsport: ["7E", "6E", "5E", "5D", "4E", "3E"],
			naturalgift: ["4M"],
			protect: ["9M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["9M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["9L49", "7M", "7L49", "6M", "6L55", "5M", "5L55", "4M", "4L51", "3M", "3L48"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M", "3M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			snowscape: ["9M"],
			soak: ["9L42", "7L42"],
			splash: ["7E", "6E", "5E", "4E", "3E"],
			substitute: ["9M", "7M", "6M", "5M", "4M", "3T"],
			supersonic: ["7E", "6E", "5E", "4E", "3E"],
			surf: ["9M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetkiss: ["9L31", "7L31", "6L31", "5L37", "4L27", "3L36"],
			swift: ["4T", "3T"],
			tackle: ["9L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			takedown: ["9M", "9L34", "7L34", "6L14", "5L14", "4L14", "3L24"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			waterfall: ["9M", "7M", "6M", "5M", "4M", "3M"],
			watergun: ["9L4", "7L4", "6L4", "5L7", "4L7", "3L12"],
			waterpulse: ["9M", "9L17", "7T", "7L17", "6T", "6L17", "5L22", "4M", "4L31", "3M"],
			watersport: ["7E", "6E", "5E", "4E", "3E"],
			whirlpool: ["4M"],
			wish: ["9L13"],
		},
	},
	bagon: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bite: ["9L5", "8L5", "7L10", "6L5", "5L5", "5D", "4L5", "3L5", "3S0", "3S1"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L25", "8M", "8L25", "7L25", "6L25", "5L46", "4L46", "3L41"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["9E", "8E", "7E", "6E", "5E"],
			doubleedge: ["9L55", "8L55", "7L49", "6L49", "5L55", "4L55", "3T", "3L53"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["9L10", "8L10", "7L13", "6L13", "5L31", "4L31", "3L33"],
			dragonclaw: ["9M", "9L31", "8M", "8L31", "7M", "7L29", "6M", "6L29", "5M", "5L50", "4M", "4L50", "3M", "3L49"],
			dragondance: ["9M", "8M", "7E", "6E", "5E", "5D", "4E", "3E"],
			dragonpulse: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			dragonrage: ["7E", "6E", "5E", "4E", "3E"],
			dragonrush: ["9E", "8E", "7E", "6E", "5E", "4E"],
			dragontail: ["9M"],
			ember: ["9L1", "8L1", "7L4", "6L4", "5L25", "4L25", "3L25"],
			endure: ["9M", "8M", "7E", "6E", "5E", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "8M", "7E", "6E", "5E", "4E"],
			firespin: ["9M"],
			flamethrower: ["9M", "9L45", "8M", "8L45", "7M", "7L44", "6M", "6L44", "5M", "4M", "3M"],
			focusenergy: ["9L40", "8M", "8L40", "7L21", "6L20", "5L20", "4L20", "3L21"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			headbutt: ["9L15", "8L15", "7L17", "6L16", "5L16", "4T", "4L16", "3L17"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["9M", "8M", "7E", "6E", "5E", "4E", "3E"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "8M", "3S1"],
			ironhead: ["9M"],
			leer: ["9L1", "8L1", "7L7", "6L7", "5L10", "4L10", "3L9"],
			mimic: ["3T"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "9L50", "8M", "8L50", "7T", "6T", "5T", "5D", "4T"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rage: ["7L1", "6L1", "6S3", "5L1", "5S2", "4L1", "3L1", "3S0", "3S1"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L20", "8M", "8L20", "7L39", "6L39", "5L40", "4L40", "3L37"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M", "4E"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9E", "8E", "7E", "6E", "6S3", "5E", "4E", "3E"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["9E", "8E", "7E", "6E", "5E", "4T", "4E", "3E"],
			wish: ["3S0"],
			zenheadbutt: ["9M", "9L35", "8M", "8L35", "7T", "7L34", "6T", "6L34", "5T", "5L35", "4T", "4L35"],
		},
		eventData: [
			{generation: 3, level: 5, shiny: 1, moves: ["rage", "bite", "wish"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: 1, moves: ["rage", "bite", "irondefense"], pokeball: "pokeball"},
			{generation: 5, level: 1, shiny: true, moves: ["rage"], pokeball: "pokeball"},
			{generation: 6, level: 1, moves: ["rage", "thrash"], pokeball: "pokeball"},
		],
	},
	shelgon: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bite: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L25", "8M", "8L25", "7L25", "6L25", "5L50", "4L50", "3L56"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			doubleedge: ["9L67", "8L67", "7L56", "6L56", "5L61", "4L61", "3T", "3L78"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["9L1", "8L1", "7L13", "6L13", "5L32", "4L32", "3L38"],
			dragonclaw: ["9M", "9L33", "8M", "8L33", "7M", "7L29", "6M", "6L29", "5M", "5L55", "4M", "4L55", "3M", "3L69"],
			dragondance: ["9M", "8M"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dragontail: ["9M"],
			ember: ["9L1", "8L1", "7L1", "6L1", "5L25", "4L25", "3L25"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			firefang: ["9M", "8M"],
			firespin: ["9M"],
			flamethrower: ["9M", "9L53", "8M", "8L53", "7M", "7L49", "6M", "6L49", "5M", "4M", "3M"],
			focusenergy: ["9L46", "8M", "8L46", "7L21", "6L20", "5L20", "4L20", "3L21"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			headbutt: ["9L15", "8L15", "7L17", "6L1", "5L1", "4T", "4L1", "3L1"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["9M", "8M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["3T"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "9L60", "8M", "8L60", "7T", "6T", "5T", "4T"],
			protect: ["9M", "9L0", "8M", "8L0", "7M", "7L1", "6M", "6L30", "5M", "5L30", "4M", "4L30", "3M", "3L30"],
			rage: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L20", "8M", "8L20", "7L42", "6L42", "5L43", "4L43", "3L47"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			zenheadbutt: ["9M", "9L39", "8M", "8L39", "7T", "7L35", "6T", "6L35", "5T", "5L37", "4T", "4L37"],
		},
		encounters: [
			{generation: 7, level: 15},
		],
	},
	salamence: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "5S3", "4M", "3M", "3S1"],
			aircutter: ["4T"],
			airslash: ["9M", "8M"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bite: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			bodyslam: ["9M", "8M", "3T"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L25", "8M", "8L25", "7L25", "6L25", "5L53", "4L53", "3L61"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			defog: ["7T", "4M"],
			doubleedge: ["9L73", "8L73", "7L63", "6L1", "5L70", "4L70", "3T", "3L93"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["9L1", "8L1", "7L13", "6L13", "5L32", "4L32", "3L38", "3S0"],
			dragonclaw: ["9M", "9L33", "8M", "8L33", "7M", "7L29", "6M", "6L29", "5M", "5L61", "5S3", "4M", "4L61", "4S2", "3M", "3L79", "3S1"],
			dragondance: ["9M", "8M", "5S3", "3S1"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dragontail: ["9M", "9L1", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L80"],
			dualwingbeat: ["9L1", "8T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			ember: ["9L1", "8L1", "7L1", "6L1", "5L25", "4L25", "3L25"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M", "4S2", "3M"],
			firefang: ["9M", "8M", "7L1", "6L1", "5L1", "4L1"],
			firespin: ["9M"],
			flamethrower: ["9M", "9L55", "8M", "8L55", "7M", "7L49", "6M", "6L49", "5M", "4M", "3M"],
			fly: ["9M", "9L0", "8M", "8L0", "7M", "7L1", "6M", "6L50", "5M", "5L50", "4M", "4L50", "3M", "3L50", "3S0"],
			focusenergy: ["9L46", "8M", "8L46", "7L21", "6L20", "5L20", "4L20", "3L21"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["9L15", "8L15", "7L17", "6L1", "5L1", "4T", "4L1", "3L1"],
			heatwave: ["9M", "8M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hurricane: ["9M", "8M"],
			hydropump: ["9M", "8M", "4S2"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			laserfocus: ["7T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			mimic: ["3T"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			outrage: ["9M", "8M", "8L64", "7T", "6T", "5T", "5S3", "4T"],
			protect: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L30", "5M", "5L30", "4M", "4L30", "3M", "3L30", "3S0"],
			psychicfangs: ["9M"],
			rage: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S1"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			roost: ["9L1", "7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L20", "8M", "8L20", "7L42", "6L42", "5L43", "4L43", "3L47", "3S0"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M", "4S2"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "4T", "3T"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M", "8M", "7L1", "6L1", "5L1", "4L1"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["4T"],
			zenheadbutt: ["9M", "9L39", "8M", "8L39", "7T", "7L35", "6T", "6L35", "5T", "5L37", "4T", "4L37"],
		},
		eventData: [
			{generation: 3, level: 50, moves: ["protect", "dragonbreath", "scaryface", "fly"], pokeball: "pokeball"},
			{generation: 3, level: 50, moves: ["refresh", "dragonclaw", "dragondance", "aerialace"]},
			{generation: 4, level: 50, gender: "M", nature: "Naughty", moves: ["hydropump", "stoneedge", "fireblast", "dragonclaw"], pokeball: "cherishball"},
			{generation: 5, level: 50, shiny: 1, moves: ["dragondance", "dragonclaw", "outrage", "aerialace"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 7, level: 9},
		],
	},
	beldum: {
		learnset: {
			headbutt: ["4T"],
			holdback: ["6S0"],
			irondefense: ["8M", "7T", "6T", "6S0", "5T", "4T"],
			ironhead: ["8M", "7T", "6T", "6S0", "5T", "5D", "4T"],
			steelbeam: ["8T"],
			tackle: ["8L1"],
			takedown: ["7L1", "6L1", "5L1", "5D", "4L1", "3L1"],
			zenheadbutt: ["8M", "7T", "6T", "6S0", "5T", "5D", "4T"],
		},
		eventData: [
			{generation: 6, level: 5, shiny: true, moves: ["holdback", "ironhead", "zenheadbutt", "irondefense"], pokeball: "cherishball"},
		],
	},
	metang: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L66", "7L41", "6L38", "5L44", "4L44", "3L56"],
			allyswitch: ["8M", "7T"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletpunch: ["8L1", "7L26", "6L26", "5L32", "4L32"],
			confide: ["7M", "6M"],
			confusion: ["8L0", "7L1", "6L1", "5L1", "4L1", "3L20", "3S0"],
			cosmicpower: ["8M"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			expandingforce: ["8T"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "8L18", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "8L74", "7M", "7L50", "6M", "6L50", "5M", "5L56", "4M", "4L56", "3M", "3L62"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "8L58", "7T", "7L47", "6T", "6L47", "5T", "5L40", "4T", "4L40", "3L44"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magnetrise: ["8L12", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			metalclaw: ["8L0", "7L1", "6L1", "5L1", "4L1", "3L20", "3S0"],
			meteorbeam: ["8T"],
			meteormash: ["8L50", "7L44", "6L44", "5L48", "4L48", "3L50"],
			mimic: ["3T"],
			miracleeye: ["7L29", "6L26", "5L26"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L34", "7M", "7L38", "6M", "6L38", "5M", "5L36", "4M", "4L36", "3M", "3L38"],
			psychocut: ["8M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			pursuit: ["7L23", "6L23", "5L28", "4L28", "3L32"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["3S0"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scaryface: ["8M", "8L42", "7L35", "6L35", "5L24", "4L24", "3L26"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tackle: ["8L1"],
			takedown: ["8L26", "7L1", "6L1", "5L1", "4L1", "3L1", "3S0"],
			telekinesis: ["7T", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			zenheadbutt: ["8M", "8L6", "7T", "7L32", "6T", "6L29", "5T", "5L52", "4T", "4L52"],
		},
		eventData: [
			{generation: 3, level: 30, moves: ["takedown", "confusion", "metalclaw", "refresh"], pokeball: "pokeball"},
		],
	},
	metagross: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M", "8L72", "7L41", "6L38", "5L44", "5S4", "4L44", "3L66"],
			allyswitch: ["8M", "7T"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletpunch: ["8L1", "7L26", "7S7", "6L26", "5L32", "5S1", "5S2", "4L32", "4S0"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			cosmicpower: ["8M"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			doubleedge: ["5S4", "5S5", "3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "5S1", "5S3", "5S6", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			expandingforce: ["8T"],
			explosion: ["7M", "6M", "5M", "4M", "3T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "8L16", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			hammerarm: ["8L0", "7L1", "6L45", "5L45", "5S1", "5S2", "5S4", "5S5", "4L45", "4S0"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "8L82", "7M", "7L60", "6M", "6L60", "5M", "5L71", "5S6", "4M", "4L71", "3M", "3L77"],
			icepunch: ["8M", "7T", "7S7", "6T", "5T", "5S2", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "8L62", "7T", "7L52", "6T", "6L52", "5T", "5L40", "5S4", "4T", "4L40", "3L44"],
			ironhead: ["8M", "7T", "7S7", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magnetrise: ["8L12", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			metalclaw: ["8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			meteorbeam: ["8T"],
			meteormash: ["8L52", "7L44", "6L44", "5L53", "5S1", "5S3", "5S5", "5S6", "4L53", "4S0", "3L55"],
			mimic: ["3T"],
			miracleeye: ["7L29", "6L26", "5L26"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "5S3", "4M", "3M"],
			psychic: ["8M", "8L34", "7M", "7L38", "6M", "6L38", "5M", "5L36", "5S5", "5S6", "4M", "4L36", "3M", "3L38"],
			psychocut: ["8M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			pursuit: ["7L23", "6L23", "5L28", "4L28", "3L32"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scaryface: ["8M", "8L42", "7L35", "6L35", "5L24", "4L24", "3L1"],
			secretpower: ["6M", "4M", "3M"],
			selfdestruct: ["8M", "3T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T", "7S7"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tackle: ["8L1"],
			takedown: ["8L26", "7L1", "6L1", "5L1", "4L1", "3L1"],
			telekinesis: ["7T", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			zenheadbutt: ["8M", "8L6", "7T", "7L32", "6T", "6L29", "5T", "5L62", "5S2", "5S3", "4T", "4L62", "4S0"],
		},
		eventData: [
			{generation: 4, level: 62, nature: "Brave", moves: ["bulletpunch", "meteormash", "hammerarm", "zenheadbutt"], pokeball: "cherishball"},
			{generation: 5, level: 50, shiny: 1, moves: ["meteormash", "earthquake", "bulletpunch", "hammerarm"], pokeball: "cherishball"},
			{generation: 5, level: 100, moves: ["bulletpunch", "zenheadbutt", "hammerarm", "icepunch"], pokeball: "cherishball"},
			{generation: 5, level: 45, shiny: true, moves: ["meteormash", "zenheadbutt", "earthquake", "protect"], pokeball: "pokeball"},
			{generation: 5, level: 45, isHidden: true, moves: ["irondefense", "agility", "hammerarm", "doubleedge"]},
			{generation: 5, level: 45, isHidden: true, moves: ["psychic", "meteormash", "hammerarm", "doubleedge"]},
			{generation: 5, level: 58, nature: "Serious", ivs: {hp: 30, atk: 30, def: 30, spa: 30, spd: 30, spe: 30}, moves: ["earthquake", "hyperbeam", "psychic", "meteormash"], pokeball: "cherishball"},
			{generation: 7, level: 50, nature: "Jolly", ivs: {hp: 31, atk: 31, def: 31, spa: 31, spd: 31, spe: 31}, moves: ["ironhead", "icepunch", "bulletpunch", "stompingtantrum"], pokeball: "cherishball"},
		],
	},
	regirock: {
		learnset: {
			ancientpower: ["8L12", "7L31", "6L31", "6S4", "5L33", "4T", "4L33", "3L33", "3S0", "3S1"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "8L6", "7M", "7L1", "6M", "6L1", "6S4", "5M"],
			chargebeam: ["8L1", "7M", "7L1", "6M", "6L1", "5M", "5L49", "5S3", "4M", "4L49"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["8L30", "8S7", "7L25", "6L17", "6S4", "5L17", "4L17", "4S2", "3L17", "3S0", "3S1"],
			defensecurl: ["3T"],
			dig: ["8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dynamicpunch: ["3T"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			explosion: ["8L78", "7M", "7L1", "6M", "6L1", "6S5", "5M", "5L1", "4M", "4L1", "3T", "3L1"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			flashcannon: ["8M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			hammerarm: ["8L42", "8S7", "7L49", "7S6", "6L1", "6S5", "5L81", "4L81"],
			headbutt: ["4T"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8L72", "7M", "7L67", "6M", "6L67", "5M", "5L89", "4M", "4L89", "3M", "3L65", "3S1"],
			icepunch: ["8M", "7T", "6T", "6S5", "5T", "4T", "3T"],
			irondefense: ["8M", "8L36", "7T", "7L37", "6T", "6L37", "6S4", "5T", "5L41", "5S3", "4L41", "3L41"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			lockon: ["8L60", "7L55", "7S6", "6L1", "5L57", "5S3", "4L57", "3L57"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["8M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "8L24", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rockthrow: ["8L1", "7L1", "6L1", "5L9", "4L9", "4S2", "3L9", "3S0"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			selfdestruct: ["8M", "3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			stomp: ["8L18", "7L1", "6L1", "5L1", "4L1", "4S2"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L48", "8S7", "7M", "7L43", "7S6", "6M", "6L43", "6S5", "5M", "5L73", "4M", "4L73"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8L54", "8S7", "7T", "7L61", "6T", "6L25", "5T", "5L25", "4T", "4L25", "4S2", "3L25", "3S0", "3S1"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			zapcannon: ["8L66", "7L55", "7S6", "6L1", "5L65", "5S3", "4L65", "3L49"],
		},
		eventData: [
			{generation: 3, level: 40, shiny: 1, moves: ["rockthrow", "curse", "superpower", "ancientpower"]},
			{generation: 3, level: 40, moves: ["curse", "superpower", "ancientpower", "hyperbeam"], pokeball: "pokeball"},
			{generation: 4, level: 30, shiny: 1, moves: ["stomp", "rockthrow", "curse", "superpower"]},
			{generation: 5, level: 65, shiny: 1, moves: ["irondefense", "chargebeam", "lockon", "zapcannon"]},
			{generation: 6, level: 40, shiny: 1, moves: ["bulldoze", "curse", "ancientpower", "irondefense"]},
			{generation: 6, level: 50, isHidden: true, moves: ["explosion", "icepunch", "stoneedge", "hammerarm"], pokeball: "pokeball"},
			{generation: 7, level: 60, shiny: 1, moves: ["stoneedge", "hammerarm", "lockon", "zapcannon"]},
			{generation: 8, level: 70, shiny: 1, moves: ["superpower", "stoneedge", "hammerarm", "curse"]},
		],
		eventOnly: true,
	},
	regice: {
		learnset: {
			amnesia: ["8M", "8L36", "8S7", "7L37", "6L37", "6S4", "6S5", "5L41", "5S3", "4L41", "3L41"],
			ancientpower: ["8L12", "7L31", "6L31", "6S4", "5L33", "4T", "4L33", "3L33", "3S0", "3S1"],
			auroraveil: ["7M"],
			avalanche: ["8M", "4M"],
			blizzard: ["8M", "8L48", "7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "8L6", "7M", "7L1", "6M", "6L1", "6S4", "5M"],
			chargebeam: ["8L1", "7M", "7L1", "6M", "6L1", "5M", "5L49", "5S3", "4M", "4L49"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["8L30", "7L25", "6L17", "6S4", "5L17", "4L17", "4S2", "3L17", "3S0", "3S1"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			explosion: ["8L78", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3T", "3L1"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			hail: ["8M", "7M", "6M", "6S5", "5M", "4M", "3M"],
			hammerarm: ["8L42", "7L49", "7S6", "6L1", "5L81", "4L81"],
			headbutt: ["4T"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "8L72", "7M", "7L67", "6M", "6L67", "5M", "5L89", "4M", "4L89", "3M", "3L65", "3S1"],
			icebeam: ["8M", "8L24", "8S7", "7M", "7L43", "7S6", "6M", "6L43", "6S5", "5M", "5L73", "4M", "4L73", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			iciclespear: ["8M"],
			icywind: ["8M", "8L1", "8S7", "7T", "7L1", "6T", "6L1", "5T", "5L9", "4T", "4L9", "4S2", "3T", "3L9", "3S0"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			lockon: ["8L60", "7L55", "7S6", "6L1", "5L57", "5S3", "4L57", "3L57"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			selfdestruct: ["8M", "3T"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stomp: ["8L18", "7L1", "6L1", "5L1", "4L1", "4S2"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			superpower: ["8M", "8L54", "7T", "7L61", "6T", "6L25", "5T", "5L25", "4T", "4L25", "4S2", "3L25", "3S0", "3S1"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "6S5", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			zapcannon: ["8L66", "8S7", "7L55", "7S6", "6L1", "5L65", "5S3", "4L65", "3L49"],
		},
		eventData: [
			{generation: 3, level: 40, shiny: 1, moves: ["icywind", "curse", "superpower", "ancientpower"]},
			{generation: 3, level: 40, moves: ["curse", "superpower", "ancientpower", "hyperbeam"], pokeball: "pokeball"},
			{generation: 4, level: 30, shiny: 1, moves: ["stomp", "icywind", "curse", "superpower"]},
			{generation: 5, level: 65, shiny: 1, moves: ["amnesia", "chargebeam", "lockon", "zapcannon"]},
			{generation: 6, level: 40, shiny: 1, moves: ["bulldoze", "curse", "ancientpower", "amnesia"]},
			{generation: 6, level: 50, isHidden: true, moves: ["thunderbolt", "amnesia", "icebeam", "hail"], pokeball: "pokeball"},
			{generation: 7, level: 60, shiny: 1, moves: ["icebeam", "hammerarm", "lockon", "zapcannon"]},
			{generation: 8, level: 70, shiny: 1, moves: ["icebeam", "zapcannon", "amnesia", "icywind"]},
		],
		eventOnly: true,
	},
	registeel: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			amnesia: ["8M", "8L36", "7L37", "6L37", "6S4", "5L41", "5S3", "4L41", "3L41"],
			ancientpower: ["8L12", "7L31", "6L31", "6S4", "5L33", "4T", "4L33", "3L33", "3S0", "3S1"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "3T"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["8M", "8L6", "7M", "7L1", "6M", "6L1", "5M"],
			chargebeam: ["8L1", "8S7", "7M", "7L1", "6M", "6L1", "5M", "5L49", "5S3", "4M", "4L49"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			curse: ["8L30", "7L25", "6L17", "6S4", "5L17", "4L17", "4S2", "3L17", "3S0", "3S1"],
			defensecurl: ["3T"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dynamicpunch: ["3T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			explosion: ["8L78", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1", "3T", "3L1"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "8L24", "8S7", "7M", "7L43", "7S6", "6M", "6L43", "5M", "5L73", "4M", "4L73"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "6S5", "5T", "4T"],
			hammerarm: ["8L42", "7L49", "7S6", "6L1", "5L81", "4L81"],
			headbutt: ["4T"],
			heavyslam: ["8M", "8L48", "8S7"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "8L72", "7M", "7L67", "6M", "6L67", "5M", "5L89", "4M", "4L89", "3M", "3L65", "3S1"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			irondefense: ["8M", "8L36", "8S7", "7T", "7L37", "6T", "6L37", "6S4", "6S5", "5T", "5L41", "4T", "4L41", "3L41"],
			ironhead: ["8M", "8L24", "7T", "7L43", "6T", "6L1", "6S5", "5T", "5L73", "4T", "4L73"],
			lockon: ["8L60", "7L55", "7S6", "6L1", "5L57", "5S3", "4L57", "3L57"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metalclaw: ["8L1", "7L1", "6L1", "5L9", "4L9", "4S2", "3L9", "3S0"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "6S5", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandtomb: ["8M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			selfdestruct: ["8M", "3T"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stomp: ["8L18", "7L1", "6L1", "5L1", "4L1", "4S2"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			superpower: ["8M", "8L54", "7T", "7L61", "6T", "6L25", "5T", "5L25", "4T", "4L25", "4S2", "3L25", "3S0", "3S1"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			zapcannon: ["8L66", "7L55", "7S6", "6L1", "5L65", "5S3", "4L65", "3L49"],
		},
		eventData: [
			{generation: 3, level: 40, shiny: 1, moves: ["metalclaw", "curse", "superpower", "ancientpower"]},
			{generation: 3, level: 40, moves: ["curse", "superpower", "ancientpower", "hyperbeam"], pokeball: "pokeball"},
			{generation: 4, level: 30, shiny: 1, moves: ["stomp", "metalclaw", "curse", "superpower"]},
			{generation: 5, level: 65, shiny: 1, moves: ["amnesia", "chargebeam", "lockon", "zapcannon"]},
			{generation: 6, level: 40, shiny: 1, moves: ["curse", "ancientpower", "irondefense", "amnesia"]},
			{generation: 6, level: 50, isHidden: true, moves: ["ironhead", "rockslide", "gravity", "irondefense"], pokeball: "pokeball"},
			{generation: 7, level: 60, shiny: 1, moves: ["flashcannon", "hammerarm", "lockon", "zapcannon"]},
			{generation: 8, level: 70, shiny: 1, moves: ["heavyslam", "flashcannon", "irondefense", "chargebeam"]},
		],
		eventOnly: true,
	},
	latias: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			airslash: ["8M"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			aurasphere: ["8M"],
			batonpass: ["8M"],
			bodyslam: ["8M", "3T"],
			breakingswipe: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M", "8L1", "7L7", "6L1", "5L55", "5S5", "4L55", "3L50", "3S1", "3S2"],
			confide: ["7M", "6M"],
			confusion: ["8L15"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M", "3M"],
			defog: ["7T", "4M"],
			dive: ["8M", "8S11", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["8T", "7T", "7S9", "6T", "5T", "4T"],
			dragonbreath: ["8L25", "8S10", "7L20", "6L20", "6S6", "5L20", "4L20", "4S3", "3L20"],
			dragonclaw: ["8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8L45", "8S11", "7T", "7L56", "7S7", "7S8", "6T", "6L1", "5T", "5L80", "4M", "4L70"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			fly: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			guardsplit: ["8L65", "7L46", "6L1", "5L75"],
			healingwish: ["8L70", "7L1", "6L1", "5L85", "4L60"],
			healpulse: ["8L50", "7L16", "6L1", "6S6", "5L65", "5S5"],
			helpinghand: ["8M", "8L5", "7T", "7L1", "6T", "6L1", "5T", "5L10", "4T", "4L10", "3L10"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			mimic: ["3T"],
			mistball: ["8L35", "8S11", "7L24", "7S7", "7S8", "7S9", "6L24", "6S6", "5L35", "4L35", "4S3", "4S4", "3L35", "3S0", "3S1", "3S2"],
			mudslap: ["4T", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L60", "7M", "7L51", "7S9", "6M", "6L51", "5M", "5L60", "5S5", "4M", "4L65", "3M", "3L40", "3S0", "3S1", "3S2"],
			psychocut: ["8M"],
			psychoshift: ["8L75", "7L28", "7S7", "7S8", "6L28", "6S6", "5L50", "5S5", "4L50"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			recover: ["8L10", "7L32", "6L32", "5L45", "4L45", "3L45", "3S1", "3S2"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			reflecttype: ["8L55", "8S10", "7L36", "6L1", "5L70"],
			refresh: ["7L13", "6L13", "5L30", "4L30", "4S3", "4S4", "3L30", "3S0"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7L1", "6M", "6L1", "5M", "5L15", "4M", "4L15", "3M", "3L15"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			storedpower: ["8M", "8L1", "7L10", "6L10"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			suckerpunch: ["4T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["8M", "8S10", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			sweetkiss: ["8S11"],
			swift: ["8M", "4T", "3T"],
			tailwind: ["8L20", "7T", "7S9", "6T", "5T", "4T"],
			telekinesis: ["7T", "5M"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			triattack: ["8M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			twister: ["4T"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			watersport: ["7L4", "6L4", "5L25", "4L25", "4S3", "4S4", "3L25", "3S0"],
			whirlpool: ["8M", "4M"],
			wish: ["8L30", "7L1", "7S7", "7S8", "6L1", "5L5", "4L5", "3L5"],
			zenheadbutt: ["8M", "8L40", "8S10", "7T", "7L41", "6T", "6L40", "5T", "5L40", "4T", "4L40", "4S4"],
		},
		eventData: [
			{generation: 3, level: 40, shiny: 1, moves: ["watersport", "refresh", "mistball", "psychic"]},
			{generation: 3, level: 50, shiny: 1, moves: ["mistball", "psychic", "recover", "charm"]},
			{generation: 3, level: 70, moves: ["mistball", "psychic", "recover", "charm"], pokeball: "pokeball"},
			{generation: 4, level: 35, shiny: 1, moves: ["dragonbreath", "watersport", "refresh", "mistball"]},
			{generation: 4, level: 40, shiny: 1, moves: ["watersport", "refresh", "mistball", "zenheadbutt"]},
			{generation: 5, level: 68, shiny: 1, moves: ["psychoshift", "charm", "psychic", "healpulse"]},
			{generation: 6, level: 30, shiny: 1, moves: ["healpulse", "dragonbreath", "mistball", "psychoshift"]},
			{generation: 7, level: 60, shiny: 1, moves: ["mistball", "dragonpulse", "psychoshift", "wish"]},
			{generation: 7, level: 60, moves: ["mistball", "dragonpulse", "psychoshift", "wish"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["mistball", "psychic", "dracometeor", "tailwind"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["reflecttype", "dragonbreath", "zenheadbutt", "surf"]},
			{generation: 8, level: 70, nature: "Bashful", moves: ["mistball", "dragonpulse", "dive", "sweetkiss"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	latios: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["8M"],
			airslash: ["8M"],
			allyswitch: ["8M", "8L30", "7T"],
			attract: ["8M", "7M", "6M", "5M", "4M", "3M"],
			aurasphere: ["8M", "8S11"],
			batonpass: ["8M"],
			bodyslam: ["8M", "3T"],
			breakingswipe: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["8L15"],
			cut: ["6M", "5M", "4M", "3M"],
			defog: ["7T", "4M"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["8T", "7T", "7S10", "6T", "5T", "4T"],
			dragonbreath: ["8L25", "7L20", "7S8", "7S9", "6L20", "6S6", "5L20", "4L20", "4S3", "3L20"],
			dragonclaw: ["8M", "7M", "6M", "5M", "4M", "3M"],
			dragondance: ["8M", "8L1", "8S11", "7L7", "6L1", "5L55", "5S5", "4L55", "3L50", "3S1", "3S2"],
			dragonpulse: ["8M", "8L45", "8S11", "7T", "7L56", "7S8", "7S9", "6T", "6L1", "6S7", "5T", "5L80", "4M", "4L70"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dualwingbeat: ["8T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			flash: ["6M", "5M", "4M", "3M"],
			fly: ["8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			healblock: ["7L1", "6L1", "5L5", "4L5"],
			healpulse: ["8L50", "7L16", "6L1", "6S6", "6S7", "5L65", "5S5"],
			helpinghand: ["8M", "8L5", "7T", "7L1", "6T", "6L1", "5T", "5L10", "4T", "4L10", "3L10"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			lusterpurge: ["8L35", "7L24", "7S8", "7S9", "7S10", "6L24", "6S6", "6S7", "5L35", "4L35", "4S3", "4S4", "3L35", "3S0", "3S1", "3S2"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			memento: ["8L70", "7L1", "6L1", "5L85", "4L60", "3L5"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			mysticalfire: ["8M"],
			naturalgift: ["4M"],
			outrage: ["8M", "7T", "6T", "5T", "4T"],
			powersplit: ["8L65", "7L46", "6L1", "5L75"],
			protect: ["8M", "7M", "7L4", "6M", "6L4", "5M", "5L25", "4M", "4L25", "4S3", "4S4", "3M", "3L25", "3S0"],
			psychic: ["8M", "8L60", "7M", "7L51", "7S10", "6M", "6L51", "6S7", "5M", "5L60", "5S5", "4M", "4L65", "3M", "3L40", "3S0", "3S1", "3S2"],
			psychocut: ["8M"],
			psychoshift: ["8L75", "7L28", "7S8", "7S9", "6L28", "6S6", "5L50", "5S5", "4L50"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7L1", "6L1", "5L1", "4L1", "3L1"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			recover: ["8L10", "7L32", "6L32", "5L45", "4L45", "3L45", "3S1", "3S2"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["7L13", "6L13", "5L30", "4L30", "4S3", "4S4", "3L30", "3S0"],
			rest: ["8M", "7M", "6M", "5M", "4M", "3M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "7L1", "6M", "6L1", "5M", "5L15", "4M", "4L15", "3M", "3L15"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scaleshot: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shadowclaw: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			simplebeam: ["8L55"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			steelwing: ["8M", "7M", "6M", "4M", "3M"],
			storedpower: ["8M", "8L1", "7L10", "6L10"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "4T", "3T"],
			tailwind: ["8L20", "7T", "7S10", "6T", "5T", "4T"],
			telekinesis: ["7T", "7L36", "6L1", "5M", "5L70"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			triattack: ["8M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			twister: ["4T"],
			waterfall: ["8M", "7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["8M", "8L40", "8S11", "7T", "7L41", "6T", "6L40", "5T", "5L40", "4T", "4L40", "4S4"],
		},
		eventData: [
			{generation: 3, level: 40, shiny: 1, moves: ["protect", "refresh", "lusterpurge", "psychic"]},
			{generation: 3, level: 50, shiny: 1, moves: ["lusterpurge", "psychic", "recover", "dragondance"]},
			{generation: 3, level: 70, moves: ["lusterpurge", "psychic", "recover", "dragondance"], pokeball: "pokeball"},
			{generation: 4, level: 35, shiny: 1, moves: ["dragonbreath", "protect", "refresh", "lusterpurge"]},
			{generation: 4, level: 40, shiny: 1, moves: ["protect", "refresh", "lusterpurge", "zenheadbutt"]},
			{generation: 5, level: 68, shiny: 1, moves: ["psychoshift", "dragondance", "psychic", "healpulse"]},
			{generation: 6, level: 30, shiny: 1, moves: ["healpulse", "dragonbreath", "lusterpurge", "psychoshift"]},
			{generation: 6, level: 50, nature: "Modest", moves: ["dragonpulse", "lusterpurge", "psychic", "healpulse"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["lusterpurge", "dragonpulse", "psychoshift", "dragonbreath"]},
			{generation: 7, level: 60, moves: ["lusterpurge", "dragonpulse", "psychoshift", "dragonbreath"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["lusterpurge", "psychic", "dracometeor", "tailwind"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["dragondance", "dragonpulse", "zenheadbutt", "aurasphere"]},
		],
		eventOnly: true,
	},
	kyogre: {
		learnset: {
			ancientpower: ["9L1", "8L1", "7L1", "6L1", "5L45", "5S3", "4T", "4L15", "4S2", "3L15"],
			aquaring: ["9L54", "8L54", "8S11", "7L30", "6L30", "6S5", "5L30", "4L30", "4S2"],
			aquatail: ["9L9", "8L9", "7T", "7L15", "6T", "6L15", "5T", "5L65", "4T", "4L65"],
			avalanche: ["9M", "8M", "4M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["9M", "9L1", "8M", "8L1", "8S11", "7L20", "6L15", "6S5", "5L15", "4L15", "3T", "3L20", "3S0"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			brine: ["8M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "9L18", "8M", "8L18", "7M", "7L50", "7S7", "7S8", "7S9", "7S10", "6M", "6L50", "5M", "5L60", "4M", "4L30", "3M", "3L30", "3S0"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			defensecurl: ["3T"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["9L81", "8L81", "7L80", "6L80", "5L80", "4L65", "3T", "3L65", "3S1"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			endure: ["9M", "8M", "4M", "3T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M", "3M"],
			headbutt: ["4T"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hydropump: ["9M", "9L72", "8M", "8L72", "7L75", "6L75", "5L90", "4L45", "3L45", "3S0", "3S1"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icebeam: ["9M", "9L36", "8M", "8L36", "7M", "7L35", "7S7", "7S8", "7S9", "7S10", "6M", "6L35", "6S5", "6S6", "5M", "5L35", "5S3", "5S4", "4M", "4L35", "4S2", "3M", "3L35", "3S0"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			liquidation: ["9M", "8M", "7T"],
			mimic: ["3T"],
			muddywater: ["9L27", "8M", "8L27", "7L60", "7S7", "7S8", "7S9", "6L20", "5L20", "4L20"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			originpulse: ["9L1", "8L63", "7L45", "7S7", "7S8", "7S9", "7S10", "6L45", "6S5"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M", "4L50", "3M", "3L50", "3S1"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L1", "8M", "8L1", "7L5", "6L5", "5L5", "4L5", "3L5"],
			secretpower: ["6M", "4M", "3M"],
			sheercold: ["9L45", "8L45", "7L65", "6L65", "6S6", "5L75", "5S4", "4L60", "3L60", "3S1"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			surf: ["9M", "8M", "8S11", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "8S11", "7M", "6M", "6S6", "5M", "5S3", "5S4", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterfall: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["9M", "9L1", "8L1", "7T", "7L1", "6T", "6L1", "5L1", "4M", "4L1", "3M", "3L1"],
			waterspout: ["9L90", "8L90", "7L90", "7S10", "6L50", "6S6", "5L50", "5S3", "5S4", "4L50", "4S2", "3L75"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 3, level: 45, shiny: 1, moves: ["bodyslam", "calmmind", "icebeam", "hydropump"]},
			{generation: 3, level: 70, shiny: 1, moves: ["hydropump", "rest", "sheercold", "doubleedge"]},
			{generation: 4, level: 50, shiny: 1, moves: ["aquaring", "icebeam", "ancientpower", "waterspout"]},
			{generation: 5, level: 80, shiny: 1, moves: ["icebeam", "ancientpower", "waterspout", "thunder"], pokeball: "cherishball"},
			{generation: 5, level: 100, moves: ["waterspout", "thunder", "icebeam", "sheercold"], pokeball: "cherishball"},
			{generation: 6, level: 45, moves: ["bodyslam", "aquaring", "icebeam", "originpulse"]},
			{generation: 6, level: 100, nature: "Timid", moves: ["waterspout", "thunder", "sheercold", "icebeam"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["icebeam", "originpulse", "calmmind", "muddywater"]},
			{generation: 7, level: 60, shiny: true, moves: ["icebeam", "originpulse", "calmmind", "muddywater"], pokeball: "cherishball"},
			{generation: 7, level: 60, moves: ["icebeam", "originpulse", "calmmind", "muddywater"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["originpulse", "icebeam", "waterspout", "calmmind"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["surf", "bodyslam", "aquaring", "thunder"]},
		],
		eventOnly: true,
	},
	groudon: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			ancientpower: ["9L1", "8L1", "7L1", "6L1", "5L45", "5S3", "4T", "4L15", "4S2", "3L15"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M"],
			bodyslam: ["9M", "8M", "3T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["9M", "9L18", "8M", "8L18", "7M", "7L50", "7S7", "7S8", "7S9", "6M", "6L50", "5M", "5L60", "4M", "4L30", "3M", "3L30", "3S0"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			counter: ["3T"],
			crunch: ["9M"],
			cut: ["6M", "5M", "4M", "3M"],
			defensecurl: ["3T"],
			dig: ["9M", "8M", "6M", "5M", "4M", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dragonclaw: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dragontail: ["7M", "6M", "5M"],
			dynamicpunch: ["3T"],
			earthpower: ["9M", "9L9", "8M", "8L9", "7T", "7L15", "7S10", "6T", "6L15", "5T", "5L65", "5S4", "4T", "4L65"],
			earthquake: ["9M", "9L27", "8M", "8L27", "8S11", "7M", "7L35", "7S7", "7S8", "7S9", "6M", "6L35", "6S5", "5M", "5L35", "5S3", "4M", "4L35", "4S2", "3M", "3L35", "3S0"],
			endure: ["9M", "8M", "4M", "3T"],
			eruption: ["9L90", "8L90", "7L90", "6L50", "5L50", "5S3", "5S4", "4L50", "4S2", "3L75"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "9L72", "8M", "8L72", "7M", "7L75", "6M", "6L75", "5M", "5L90", "4M", "4L45", "3M", "3L45", "3S0", "3S1"],
			firefang: ["9M"],
			firepunch: ["9M", "8M", "7T", "7S10", "6T", "6S6", "5T", "4T", "3T"],
			fissure: ["9L45", "8L45", "7L65", "6L65", "5L75", "4L60", "3L60", "3S1"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hammerarm: ["9L36", "8L36", "8S11", "7L80", "6L20", "6S6", "5L20", "5S4", "4L20"],
			headbutt: ["4T"],
			heatcrash: ["8M"],
			heatwave: ["9M"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			lavaplume: ["9L1", "8L1", "8S11", "7L20", "6L15", "6S5", "5L15", "4L15"],
			megakick: ["8M", "3T"],
			megapunch: ["8M", "3T"],
			metalclaw: ["9M"],
			mimic: ["3T"],
			mudshot: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1", "3L1"],
			mudslap: ["9M", "4T", "3T"],
			naturalgift: ["4M"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			poweruppunch: ["6M"],
			precipiceblades: ["9L1", "8L63", "7L45", "7S7", "7S8", "7S9", "7S10", "6L45", "6S5"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			rest: ["9M", "9L54", "8M", "8L54", "7M", "7L30", "6M", "6L30", "6S5", "5M", "5L30", "4M", "4L30", "4S2", "3M", "3L50", "3S1"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockblast: ["9M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "8M", "7M", "6M", "6S6", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rollout: ["4T", "3T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			scaryface: ["9M", "9L1", "8M", "8L1", "8S11", "7L5", "6L5", "5L5", "4L5", "3L5"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			slash: ["4L20", "3L20", "3S0"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "9L81", "8M", "8L81", "7M", "7L60", "7S7", "7S8", "7S9", "6M", "6L60", "6S6", "5M", "5L80", "5S3", "5S4", "4M", "4L65", "3M", "3L65", "3S1"],
			spikes: ["9M"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["4T", "3T"],
			swordsdance: ["9M", "8M", "7M", "7S10", "6M", "5M", "4M", "3T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			willowisp: ["9M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 3, level: 45, shiny: 1, moves: ["slash", "bulkup", "earthquake", "fireblast"]},
			{generation: 3, level: 70, shiny: 1, moves: ["fireblast", "rest", "fissure", "solarbeam"]},
			{generation: 4, level: 50, shiny: 1, moves: ["rest", "earthquake", "ancientpower", "eruption"]},
			{generation: 5, level: 80, shiny: 1, moves: ["earthquake", "ancientpower", "eruption", "solarbeam"], pokeball: "cherishball"},
			{generation: 5, level: 100, moves: ["eruption", "hammerarm", "earthpower", "solarbeam"], pokeball: "cherishball"},
			{generation: 6, level: 45, moves: ["lavaplume", "rest", "earthquake", "precipiceblades"]},
			{generation: 6, level: 100, nature: "Adamant", moves: ["firepunch", "solarbeam", "hammerarm", "rockslide"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["earthquake", "precipiceblades", "bulkup", "solarbeam"]},
			{generation: 7, level: 60, shiny: true, moves: ["earthquake", "precipiceblades", "bulkup", "solarbeam"], pokeball: "cherishball"},
			{generation: 7, level: 60, moves: ["earthquake", "precipiceblades", "bulkup", "solarbeam"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["precipiceblades", "earthpower", "firepunch", "swordsdance"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["earthquake", "scaryface", "lavaplume", "hammerarm"]},
		],
		eventOnly: true,
	},
	rayquaza: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M", "3M"],
			airslash: ["9M", "9L1", "8M", "8L1", "7L30", "6L30", "5L35", "4L35", "4S1"],
			ancientpower: ["9L1", "8L1", "7L15", "6L15", "5L45", "5S2", "4T", "4L15", "4S1", "3L15"],
			aquatail: ["7T", "6T", "5T", "4T"],
			avalanche: ["9M", "8M", "4M"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bodyslam: ["9M", "8M", "3T"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["8M", "8S9", "7M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			celebrate: ["6S7"],
			confide: ["7M", "6M"],
			cosmicpower: ["8M"],
			crunch: ["9M", "9L9", "8M", "8L9", "7L20", "6L15", "5L15", "4L15", "3L35"],
			defog: ["7T"],
			dive: ["8M", "6M", "5M", "4T", "3M"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["9M", "8T", "7T", "6T", "6S7", "5T", "4T"],
			dragonascent: ["9L1", "8L1", "8S9", "7T", "6T", "6S4", "6S6", "6S7"],
			dragonclaw: ["9M", "8M", "7M", "6M", "6S6", "5M", "4M", "4L20", "3M", "3L20"],
			dragondance: ["9M", "9L18", "8M", "8L18", "7L60", "7S8", "6L60", "6S4", "6S6", "5L60", "5S2", "4L30", "3L30"],
			dragonpulse: ["9M", "9L36", "8M", "8L36", "7T", "7L50", "7S8", "6T", "6L50", "6S4", "6S5", "5T", "5L90", "5S2", "5S3", "4M", "4L75"],
			dragontail: ["9M", "7M", "6M", "5M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M", "3T"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			extremespeed: ["9L27", "8L27", "8S9", "7L45", "7S8", "6L45", "6S4", "6S5", "6S6", "5L75", "5S3", "4L60", "3L60", "3S0"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fly: ["9M", "9L63", "8M", "8L63", "7M", "7L65", "6M", "6L65", "6S7", "5M", "5L65", "4M", "4L45", "3M", "3L45", "3S0"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			furycutter: ["4T", "3T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			honeclaws: ["6M", "5M"],
			hurricane: ["9M", "9L72", "8M", "8L72"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "9L90", "8M", "8L90", "7M", "7L90", "6M", "6L80", "5M", "5L80", "5S3", "4M", "4L65", "3M", "3L75"],
			hypervoice: ["9M", "9L45", "8M", "8L45", "7T", "7L75", "6T", "6L20", "5T", "5L20", "4L20"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T", "3T"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M", "3M"],
			meteorbeam: ["8T"],
			mimic: ["3T"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			outrage: ["9M", "9L81", "8M", "8L81", "7T", "7L80", "6T", "6L50", "5T", "5L50", "5S2", "4T", "4L50", "4S1", "3L65", "3S0"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			rest: ["9M", "9L54", "8M", "8L54", "7M", "7L35", "7S8", "6M", "6L30", "5M", "5L30", "4M", "4L30", "4S1", "3M", "3L50", "3S0"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			roar: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L1", "8M", "8L1", "7L5", "6L5", "5L5", "4L5", "3L5"],
			secretpower: ["6M", "4M", "3M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			stealthrock: ["9M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["9M", "8M", "4T", "3T"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "6S5", "5M", "4M", "3M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			twister: ["9L1", "8L1", "8S9", "7L1", "6L1", "6S5", "5L1", "4T", "4L1", "3L1"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["9M"],
			vcreate: ["5S3"],
			waterfall: ["9M", "8M", "7M", "6M", "5M", "4M", "3M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			whirlpool: ["8M", "4M"],
			wildcharge: ["9M"],
		},
		eventData: [
			{generation: 3, level: 70, shiny: 1, moves: ["fly", "rest", "extremespeed", "outrage"]},
			{generation: 4, level: 50, shiny: 1, moves: ["rest", "airslash", "ancientpower", "outrage"]},
			{generation: 5, level: 70, shiny: true, moves: ["dragonpulse", "ancientpower", "outrage", "dragondance"], pokeball: "cherishball"},
			{generation: 5, level: 100, moves: ["extremespeed", "hyperbeam", "dragonpulse", "vcreate"], pokeball: "cherishball"},
			{generation: 6, level: 70, moves: ["extremespeed", "dragonpulse", "dragondance", "dragonascent"]},
			{generation: 6, level: 70, shiny: true, moves: ["dragonpulse", "thunder", "twister", "extremespeed"], pokeball: "cherishball"},
			{generation: 6, level: 70, shiny: true, moves: ["dragonascent", "dragonclaw", "extremespeed", "dragondance"], pokeball: "cherishball"},
			{generation: 6, level: 100, shiny: true, moves: ["dragonascent", "dracometeor", "fly", "celebrate"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["rest", "extremespeed", "dragonpulse", "dragondance"]},
			{generation: 8, level: 70, shiny: 1, moves: ["dragonascent", "brutalswing", "extremespeed", "twister"]},
		],
		eventOnly: true,
	},
	jirachi: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			allyswitch: ["8M"],
			amnesia: ["8M"],
			ancientpower: ["4T"],
			aurasphere: ["8M"],
			batonpass: ["8M"],
			bodyslam: ["8M", "3T"],
			calmmind: ["8M", "7M", "6M", "5M", "4M", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "6S18", "6S20", "6S21", "5L1", "4L1", "4S11", "4S12", "3L1", "3S0", "3S1", "3S2", "3S3", "3S4", "3S5", "3S6", "3S7", "3S8", "3S9"],
			cosmicpower: ["8M", "8L84", "7L60", "6L60", "6S19", "5L60", "5S15", "4L60", "3L45"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defensecurl: ["3T"],
			doomdesire: ["8L98", "7L70", "6L70", "5L70", "4L70", "3L50"],
			doubleedge: ["8L77", "7L40", "6L40", "5L40", "4L40", "3T", "3L35"],
			doubleteam: ["7M", "6M", "5M", "4M", "3M"],
			dracometeor: ["5S14", "4S12"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["3T"],
			encore: ["8M"],
			endure: ["8M", "4M", "3T"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M", "4M", "3M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			flash: ["6M", "5M", "4M", "3M"],
			flashcannon: ["8M", "7M", "6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			followme: ["5S14"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			futuresight: ["8M", "8L70", "7L55", "6L55", "5L55", "4L55", "3L40"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			gravity: ["8L35", "7T", "7L45", "6T", "6L45", "5T", "5L45", "4T", "4L45"],
			happyhour: ["6S20"],
			headbutt: ["4T"],
			healingwish: ["8L56", "7L50", "7S22", "6L50", "6S17", "5L50", "5S13", "5S15", "5S16", "4L50"],
			heartstamp: ["6S19"],
			helpinghand: ["8M", "8L14", "7T", "7L15", "6T", "6L15", "6S18", "5T", "5L15", "4T", "4L15", "3L15", "3S10"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M", "3M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "3T"],
			imprison: ["8M"],
			irondefense: ["8M", "7T", "6T", "5T", "4T"],
			ironhead: ["8M", "7T", "6T", "5T", "4T"],
			lastresort: ["8L91", "7T", "7L65", "6T", "6L65", "5T", "5L65", "4T", "4L65"],
			lifedew: ["8L21"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M", "3M"],
			luckychant: ["7L30"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			meteorbeam: ["8T"],
			meteormash: ["8L49", "8S23", "5S13", "5S14", "5S15"],
			metronome: ["8M", "3T"],
			mimic: ["3T"],
			moonblast: ["6S17"],
			mudslap: ["4T", "3T"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			playrough: ["8M", "6S19"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			psychic: ["8M", "8L42", "8S23", "7M", "7L20", "6M", "6L20", "5M", "5L20", "5S13", "4M", "4L20", "3M", "3L20", "3S10"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M", "4M", "3M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["8M", "7M", "6M", "5M", "4M", "3M"],
			refresh: ["7L25", "6L25", "5L25", "4L25", "3L25", "3S10"],
			rest: ["8M", "8L63", "8S23", "7M", "7L30", "7S22", "6M", "6L5", "6S21", "5M", "5L5", "4M", "4L5", "4S11", "4S12", "3M", "3L5", "3S0", "3S1", "3S2", "3S3", "3S4", "3S5", "3S6", "3S7", "3S8", "3S9", "3S10"],
			return: ["7M", "6M", "6S18", "5M", "5S16", "4M", "3M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M", "3M"],
			sandstorm: ["8M", "7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["8M", "7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M", "3T"],
			snore: ["8M", "7T", "6T", "5T", "4T", "3T"],
			stealthrock: ["8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["8T"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M", "3M"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["8M", "8L7", "7L10", "7S22", "6L10", "6S17", "6S20", "5L10", "5S13", "5S16", "4T", "4L10", "3T", "3L10"],
			telekinesis: ["7T", "5M"],
			thunder: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M", "3T"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["8M", "7T", "6T", "5T", "4T"],
			trickroom: ["8M", "7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["8M", "7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wish: ["8L1", "8S23", "7L1", "7S22", "6L1", "6S17", "6S18", "6S19", "6S20", "6S21", "5L1", "5S14", "5S15", "5S16", "4L1", "4S11", "4S12", "3L1", "3S0", "3S1", "3S2", "3S3", "3S4", "3S5", "3S6", "3S7", "3S8", "3S9"],
			zenheadbutt: ["8M", "8L28", "7T", "7L35", "6T", "6L35", "5T", "5L35", "4T", "4L35"],
		},
		eventData: [
			{generation: 3, level: 5, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Bashful", ivs: {hp: 24, atk: 3, def: 30, spa: 12, spd: 16, spe: 11}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Careful", ivs: {hp: 10, atk: 0, def: 10, spa: 10, spd: 26, spe: 12}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Docile", ivs: {hp: 19, atk: 7, def: 10, spa: 19, spd: 10, spe: 16}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Hasty", ivs: {hp: 3, atk: 12, def: 12, spa: 7, spd: 11, spe: 9}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Jolly", ivs: {hp: 11, atk: 8, def: 6, spa: 14, spd: 5, spe: 20}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Lonely", ivs: {hp: 31, atk: 23, def: 26, spa: 29, spd: 18, spe: 5}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Naughty", ivs: {hp: 21, atk: 31, def: 31, spa: 18, spd: 24, spe: 19}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Serious", ivs: {hp: 29, atk: 10, def: 31, spa: 25, spd: 23, spe: 21}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 5, shiny: true, nature: "Timid", ivs: {hp: 15, atk: 28, def: 29, spa: 3, spd: 0, spe: 7}, moves: ["wish", "confusion", "rest"], pokeball: "pokeball"},
			{generation: 3, level: 30, moves: ["helpinghand", "psychic", "refresh", "rest"], pokeball: "pokeball"},
			{generation: 4, level: 5, moves: ["wish", "confusion", "rest"], pokeball: "cherishball"},
			{generation: 4, level: 5, moves: ["wish", "confusion", "rest", "dracometeor"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["healingwish", "psychic", "swift", "meteormash"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["dracometeor", "meteormash", "wish", "followme"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["wish", "healingwish", "cosmicpower", "meteormash"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["wish", "healingwish", "swift", "return"], pokeball: "cherishball"},
			{generation: 6, level: 10, shiny: true, moves: ["wish", "swift", "healingwish", "moonblast"], pokeball: "cherishball"},
			{generation: 6, level: 15, shiny: true, moves: ["wish", "confusion", "helpinghand", "return"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["heartstamp", "playrough", "wish", "cosmicpower"], pokeball: "cherishball"},
			{generation: 6, level: 25, shiny: true, moves: ["wish", "confusion", "swift", "happyhour"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["wish", "confusion", "rest"], pokeball: "cherishball"},
			{generation: 7, level: 15, moves: ["swift", "wish", "healingwish", "rest"], pokeball: "cherishball"},
			{generation: 8, level: 70, nature: "Timid", moves: ["meteormash", "psychic", "rest", "wish"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	deoxys: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M", "3M"],
			agility: ["7L55", "6L55", "5L73", "4L73", "3L35"],
			allyswitch: ["7T", "5M"],
			amnesia: ["7L55", "6L55", "5L73", "4L73", "3L35"],
			avalanche: ["4M"],
			bind: ["7T", "6T", "5T"],
			bodyslam: ["3T"],
			brickbreak: ["7M", "6M", "5M", "4M", "3M"],
			brutalswing: ["7M"],
			calmmind: ["7M", "6M", "5M", "4M", "3M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			cosmicpower: ["7L55", "6L55", "6S10", "5L73", "4L73", "3L35", "3S3"],
			counter: ["7L73", "6L73", "5L97", "4L97", "4S6", "3T", "3L50"],
			cut: ["6M", "5M", "4M", "3M"],
			darkpulse: ["7M", "6M", "5S9"],
			detect: ["4S6"],
			doubleedge: ["3T"],
			doubleteam: ["7M", "7L13", "6M", "6L13", "5M", "5L17", "4M", "4L17", "4S5", "3M", "3L10"],
			drainpunch: ["7T", "6T", "5T", "4M"],
			dreameater: ["7M", "6M", "5M", "4M", "3T"],
			dynamicpunch: ["3T"],
			endure: ["4M", "3T"],
			energyball: ["7M", "6M", "5M", "4M"],
			extremespeed: ["7L73", "6L73", "5L97", "4L97", "4S4", "4S5", "3L50"],
			facade: ["7M", "6M", "5M", "4M", "3M"],
			firepunch: ["7T", "6T", "5T", "4T", "3T"],
			flash: ["6M", "5M", "4M", "3M"],
			flashcannon: ["7M", "6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M", "3M"],
			frustration: ["7M", "6M", "5M", "4M", "3M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M", "3M"],
			hyperbeam: ["7M", "7L73", "6M", "6L73", "6S10", "5M", "5L97", "4M", "4L97", "4S7", "3M", "3L50", "3S3"],
			icebeam: ["7M", "6M", "5M", "4M", "3M"],
			icepunch: ["7T", "6T", "5T", "4T", "3T"],
			icywind: ["7T", "6T", "5T", "4T", "3T"],
			irondefense: ["7L55", "6T", "6L55", "5T", "5L73", "4T", "4L73", "4S4", "3L35"],
			knockoff: ["7T", "7L19", "6T", "6L19", "5T", "5L25", "4T", "4L25", "3L15", "3S1", "3S2"],
			laserfocus: ["7T"],
			leer: ["7L1", "6L1", "5L1", "4L1", "4S8", "3L1"],
			lightscreen: ["7M", "6M", "5M", "4M", "3M"],
			lowkick: ["7T", "6T", "5T", "4T"],
			lowsweep: ["7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["3T"],
			megapunch: ["3T"],
			meteormash: ["4S7"],
			mimic: ["3T"],
			mirrorcoat: ["7L73", "6L73", "5L97", "4L97", "4S6", "3L50"],
			mudslap: ["4T", "3T"],
			nastyplot: ["5S9"],
			naturalgift: ["4M"],
			nightmare: ["3T"],
			nightshade: ["7L7", "6L7", "5L9", "4L9", "4S8", "3L5"],
			poisonjab: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M", "3M"],
			psychic: ["7M", "7L31", "6M", "6L31", "5M", "5L41", "4M", "4L41", "3M", "3L25", "3S0", "3S1", "3S2"],
			psychoboost: ["7L67", "6L67", "6S10", "5L89", "5S9", "4L89", "4S4", "4S5", "4S6", "4S7", "4S8", "3L45", "3S3"],
			psychoshift: ["7L43", "6L43", "5L57", "4L57"],
			psychup: ["7M", "6M", "5M", "4M", "3T"],
			psyshock: ["7M", "6M", "5M"],
			pursuit: ["7L25", "6L25", "5L33", "4L33", "3L20", "3S0", "3S2"],
			raindance: ["7M", "6M", "5M", "4M", "3M"],
			recover: ["7L61", "6L61", "6S10", "5L81", "5S9", "4L81", "3L40", "3S3"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["7M", "6M", "5M", "4M", "3M"],
			rest: ["7M", "6M", "5M", "4M", "3M"],
			return: ["7M", "6M", "5M", "4M", "3M"],
			rockslide: ["7M", "6M", "5M", "4M", "3T"],
			rocksmash: ["6M", "5M", "4M", "3M"],
			rocktomb: ["7M", "6M", "5M", "4M", "3M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M", "3M"],
			secretpower: ["6M", "4M", "3M"],
			seismictoss: ["3T"],
			shadowball: ["7M", "6M", "5M", "4M", "3M"],
			shockwave: ["7T", "6T", "4M", "3M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["7T", "6T", "5T", "4M", "3M"],
			sleeptalk: ["7M", "6M", "5T", "4M", "3T"],
			snatch: ["7T", "7L37", "6T", "6L37", "5T", "5L49", "4M", "4L49", "3M", "3L30", "3S1"],
			snore: ["7T", "6T", "5T", "4T", "3T"],
			solarbeam: ["7M", "6M", "5M", "4M", "3M"],
			spikes: ["7L25", "6L25", "5L33", "4L33", "3L20", "3S1"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stompingtantrum: ["7T"],
			strength: ["6M", "5M", "4M", "3M"],
			substitute: ["7M", "6M", "5M", "4M", "3T"],
			sunnyday: ["7M", "6M", "5M", "4M", "3M"],
			superpower: ["7L37", "6T", "6L37", "5L49", "4T", "4L49", "4S7", "3S0"],
			swagger: ["7M", "6M", "5M", "4M", "3T"],
			swift: ["7L37", "6L37", "5L49", "4T", "4L49", "4S5", "3T", "3L30", "3S2"],
			taunt: ["7M", "7L19", "6M", "6L19", "5M", "5L25", "4M", "4L25", "3M", "3L15", "3S0"],
			telekinesis: ["7T", "5M"],
			teleport: ["7L13", "6L13", "5L17", "4L17", "3L10"],
			throatchop: ["7T"],
			thunder: ["7M", "6M", "5M", "4M", "3M"],
			thunderbolt: ["7M", "6M", "5M", "4M", "3M"],
			thunderpunch: ["7T", "6T", "5T", "4T", "3T"],
			thunderwave: ["7M", "6M", "5M", "4M", "3T"],
			torment: ["7M", "6M", "5M", "4M", "3M"],
			toxic: ["7M", "6M", "5M", "4M", "3M"],
			trick: ["7T", "6T", "5T", "4T"],
			trickroom: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M", "3M"],
			wonderroom: ["7T", "6T", "5T"],
			wrap: ["7L1", "6L1", "5L1", "4L1", "4S8", "3L1"],
			zapcannon: ["7L61", "6L61", "5L81", "4L81", "4S4", "3L40"],
			zenheadbutt: ["7T", "7L49", "6T", "6L49", "5T", "5L65", "4T", "4L65"],
		},
		eventData: [
			{generation: 3, level: 30, shiny: 1, moves: ["taunt", "pursuit", "psychic", "superpower"]},
			{generation: 3, level: 30, shiny: 1, moves: ["knockoff", "spikes", "psychic", "snatch"]},
			{generation: 3, level: 30, shiny: 1, moves: ["knockoff", "pursuit", "psychic", "swift"]},
			{generation: 3, level: 70, moves: ["cosmicpower", "recover", "psychoboost", "hyperbeam"], pokeball: "pokeball"},
			{generation: 4, level: 50, moves: ["psychoboost", "zapcannon", "irondefense", "extremespeed"], pokeball: "cherishball"},
			{generation: 4, level: 50, moves: ["psychoboost", "swift", "doubleteam", "extremespeed"], pokeball: "pokeball"},
			{generation: 4, level: 50, moves: ["psychoboost", "detect", "counter", "mirrorcoat"], pokeball: "pokeball"},
			{generation: 4, level: 50, moves: ["psychoboost", "meteormash", "superpower", "hyperbeam"], pokeball: "pokeball"},
			{generation: 4, level: 50, moves: ["psychoboost", "leer", "wrap", "nightshade"], pokeball: "pokeball"},
			{generation: 5, level: 100, moves: ["nastyplot", "darkpulse", "recover", "psychoboost"], pokeball: "duskball"},
			{generation: 6, level: 80, moves: ["cosmicpower", "recover", "psychoboost", "hyperbeam"]},
		],
		eventOnly: true,
	},
	deoxysattack: {
		eventOnly: true,
	},
	deoxysdefense: {
		eventOnly: true,
	},
	deoxysspeed: {
		eventOnly: true,
	},
	turtwig: {
		learnset: {
			absorb: ["7L9", "6L9", "5L9", "5S0", "5S1", "4L9"],
			amnesia: ["7E", "6E", "5E", "4E"],
			attract: ["7M", "6M", "5M", "4M"],
			bite: ["7L21", "6L21", "5L21", "4L21"],
			bodyslam: ["7E", "6E", "5E", "4E"],
			bulletseed: ["4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["7L37", "6L37", "5L37", "4L37"],
			curse: ["7L17", "6L17", "5L17", "4L17"],
			cut: ["6M", "5M", "4M"],
			doubleedge: ["7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			endure: ["4M"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["7T", "7L41", "6T", "6L41", "5T", "5L41", "4M", "4L41"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grasspledge: ["7T", "6T", "5T"],
			grassyterrain: ["7E", "6E"],
			growth: ["7E", "6E", "5E", "4E"],
			headbutt: ["4T"],
			heavyslam: ["7E"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			irontail: ["7T", "6T", "5T", "4M"],
			leafstorm: ["7L45", "6L45", "5L45", "4L45"],
			leechseed: ["7L29", "6L29", "5L29", "4L29"],
			lightscreen: ["7M", "6M", "5M", "4M"],
			megadrain: ["7L25", "6L25", "5L25", "4L25"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			protect: ["7M", "6M", "5M", "4M"],
			razorleaf: ["7L13", "6L13", "5L13", "4L13"],
			reflect: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			sandtomb: ["7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M"],
			seedbomb: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			spitup: ["7E", "6E", "5E", "4E"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stockpile: ["7E", "6E", "5E", "5S1", "4E"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superpower: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			swagger: ["7M", "6M", "5M", "4M"],
			swallow: ["7E", "6E", "5E", "4E"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			synthesis: ["7T", "7L33", "6T", "6L33", "5T", "5L33", "4T", "4L33"],
			tackle: ["7L1", "6L1", "5L1", "5S0", "5S1", "4L1"],
			thrash: ["7E", "6E", "5E", "4E"],
			tickle: ["7E", "6E", "5E", "4E"],
			toxic: ["7M", "6M", "5M", "4M"],
			wideguard: ["7E", "6E", "5E"],
			withdraw: ["7L5", "6L5", "5L5", "5S0", "5S1", "4L5"],
			workup: ["7M"],
			worryseed: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tackle", "withdraw", "absorb"]},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["tackle", "withdraw", "absorb", "stockpile"]},
		],
	},
	grotle: {
		learnset: {
			absorb: ["7L1", "6L9", "5L9", "4L9"],
			attract: ["7M", "6M", "5M", "4M"],
			bite: ["7L22", "6L22", "5L22", "4L22"],
			bulletseed: ["4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["7L42", "6L42", "5L42", "4L42"],
			curse: ["7L17", "6L17", "5L17", "4L17"],
			cut: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["7T", "7L47", "6T", "6L47", "5T", "5L47", "4M", "4L47"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grasspledge: ["7T", "6T", "5T"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			irontail: ["7T", "6T", "5T", "4M"],
			leafstorm: ["7L52", "6L52", "5L52", "4L52"],
			leechseed: ["7L32", "6L32", "5L32", "4L32"],
			lightscreen: ["7M", "6M", "5M", "4M"],
			megadrain: ["7L27", "6L27", "5L27", "4L27"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			protect: ["7M", "6M", "5M", "4M"],
			razorleaf: ["7L13", "6L13", "5L13", "4L13"],
			reflect: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			synthesis: ["7T", "7L37", "6T", "6L37", "5T", "5L37", "4T", "4L37"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			toxic: ["7M", "6M", "5M", "4M"],
			withdraw: ["7L1", "6L1", "5L1", "4L1"],
			workup: ["7M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
	},
	torterra: {
		learnset: {
			absorb: ["7L1", "6L1", "5L1", "4L1"],
			attract: ["7M", "6M", "5M", "4M"],
			bite: ["7L22", "6L22", "5L22", "4L22"],
			block: ["7T", "6T", "5T", "4T"],
			bulldoze: ["7M", "6M", "5M"],
			bulletseed: ["4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["7L45", "6L45", "5L45", "4L45"],
			curse: ["7L17", "6L17", "5L17", "4L17"],
			cut: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["7M", "7L1", "6M", "6L32", "5M", "5L32", "5S0", "4M", "4L32"],
			endure: ["4M"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frenzyplant: ["7T", "6T", "5T", "4T"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["7T", "7L51", "6T", "6L51", "5T", "5L51", "4M", "4L51"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grasspledge: ["7T", "6T", "5T"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			ironhead: ["7T", "6T", "5T", "4T"],
			irontail: ["7T", "6T", "5T", "4M"],
			leafstorm: ["7L57", "6L57", "5L57", "4L57"],
			leechseed: ["7L33", "6L33", "5L33", "4L33"],
			lightscreen: ["7M", "6M", "5M", "4M"],
			megadrain: ["7L27", "6L27", "5L27", "4L27"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			outrage: ["7T", "6T", "5T", "5S0", "4T"],
			protect: ["7M", "6M", "5M", "4M"],
			razorleaf: ["7L1", "6L1", "5L1", "4L1"],
			reflect: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			sandstorm: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "6M", "5M", "5S0", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			synthesis: ["7T", "7L39", "6T", "6L39", "5T", "5L39", "4T", "4L39"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			toxic: ["7M", "6M", "5M", "4M"],
			withdraw: ["7L1", "6L1", "5L1", "4L1"],
			woodhammer: ["7L1", "6L1", "5L1", "5S0", "4L1"],
			workup: ["7M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 5, level: 100, gender: "M", moves: ["woodhammer", "earthquake", "outrage", "stoneedge"], pokeball: "cherishball"},
		],
	},
	chimchar: {
		learnset: {
			acrobatics: ["7M", "7L39", "6M", "6L39", "5M", "5L39"],
			aerialace: ["7M", "6M", "5M", "4M"],
			assist: ["7E", "6E", "5E", "4E"],
			attract: ["7M", "6M", "5M", "4M"],
			blazekick: ["7E", "6E", "5E", "4E"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			bulkup: ["7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7E", "6E", "5E", "4E"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			dig: ["6M", "5M", "4M"],
			doublekick: ["7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			ember: ["7L7", "6L7", "5L7", "5S1", "5S3", "4L7"],
			encore: ["7E", "6E", "5E", "4E"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			facade: ["7M", "7L31", "6M", "6L31", "5M", "5L31", "4M", "4L31"],
			fakeout: ["7E", "6E", "5E", "5S3", "4E"],
			fireblast: ["7M", "6M", "5M", "4M"],
			firepledge: ["7T", "6T", "5T"],
			firepunch: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			firespin: ["7L33", "6L33", "5L33", "4L33"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["7M", "7L47", "6M", "6L47", "5M", "5L47", "4M", "4L41", "4S0", "4S2"],
			flamewheel: ["7L17", "6L17", "5L17", "4L17"],
			fling: ["7M", "6M", "5M", "4M"],
			focusenergy: ["7E", "6E", "5E", "4E"],
			focuspunch: ["7T", "7E", "6T", "6E", "5E", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyswipes: ["7L15", "6L15", "5L15", "4L15"],
			grassknot: ["7M", "6M", "5M", "4M", "4S0", "4S2"],
			gunkshot: ["7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			heatwave: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			helpinghand: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "4S0", "4S2"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T", "4M"],
			leer: ["7L1", "6L1", "5L1", "5S1", "5S3", "4L1"],
			lowkick: ["7T", "6T", "5T", "4T"],
			lowsweep: ["7M", "6M", "5M"],
			mudslap: ["4T"],
			nastyplot: ["7L23", "6L23", "5L23", "4L23"],
			naturalgift: ["4M"],
			overheat: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["7E", "6M"],
			protect: ["7M", "6M", "5M", "4M"],
			quickguard: ["7E", "6E", "5E"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rocksmash: ["6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["4T"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "6L1", "5L1", "5S1", "4L1"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			slackoff: ["7L41", "6L41", "5L41", "4L39"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			strength: ["6M", "5M", "4M"],
			submission: ["7E", "6E", "5E"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			taunt: ["7M", "7L9", "6M", "6L9", "5M", "5L9", "5S1", "5S3", "4M", "4L9"],
			thunderpunch: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E", "4S0", "4S2"],
			torment: ["7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L25"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			vacuumwave: ["4T"],
			willowisp: ["7M", "6M", "5M", "4M"],
			workup: ["7M"],
		},
		eventData: [
			{generation: 4, level: 40, gender: "M", nature: "Mild", moves: ["flamethrower", "thunderpunch", "grassknot", "helpinghand"], pokeball: "cherishball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["scratch", "leer", "ember", "taunt"]},
			{generation: 4, level: 40, gender: "M", nature: "Hardy", moves: ["flamethrower", "thunderpunch", "grassknot", "helpinghand"], pokeball: "cherishball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["leer", "ember", "taunt", "fakeout"]},
		],
	},
	monferno: {
		learnset: {
			acrobatics: ["7M", "7L46", "6M", "6L46", "5M", "5L46"],
			aerialace: ["7M", "6M", "5M", "4M"],
			attract: ["7M", "6M", "5M", "4M"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			bulkup: ["7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			closecombat: ["7L36", "6L36", "5L36", "4L36"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			dig: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dualchop: ["7T", "6T", "5T"],
			ember: ["7L1", "6L1", "5L1", "4L1"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			feint: ["7L26", "6L26", "5L26", "4L26"],
			fireblast: ["7M", "6M", "5M", "4M"],
			firepledge: ["7T", "6T", "5T"],
			firepunch: ["7T", "6T", "5T", "4T"],
			firespin: ["7L39", "6L39", "5L39", "4L39"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["7M", "6M", "5M", "4M"],
			flamewheel: ["7L19", "6L19", "5L19", "4L19"],
			flareblitz: ["7L56", "6L56", "5L56", "4L49"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyswipes: ["7L16", "6L16", "5L16", "4L16"],
			grassknot: ["7M", "6M", "5M", "4M"],
			gunkshot: ["7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			heatwave: ["7T", "6T", "5T", "4T"],
			helpinghand: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T", "4M"],
			leer: ["7L1", "6L1", "5L1", "4L1"],
			lowkick: ["7T", "6T", "5T", "4T"],
			lowsweep: ["7M", "6M", "5M"],
			machpunch: ["7L1", "6L14", "5L14", "4L14"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			overheat: ["7M", "6M", "5M", "4M"],
			poisonjab: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["4T"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "6L1", "5L1", "4L1"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			slackoff: ["7L49", "6L49", "5L49", "4L46"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			taunt: ["7M", "7L9", "6M", "6L9", "5M", "5L9", "4M", "4L9"],
			thunderpunch: ["7T", "6T", "5T", "4T"],
			torment: ["7M", "7L29", "6M", "6L29", "5M", "5L29", "4M", "4L29"],
			toxic: ["7M", "6M", "5M", "4M"],
			uturn: ["7M", "6M", "5M", "4M"],
			vacuumwave: ["4T"],
			willowisp: ["7M", "6M", "5M", "4M"],
			workup: ["7M", "5M"],
		},
	},
	infernape: {
		learnset: {
			acrobatics: ["7M", "7L52", "6M", "6L52", "5M", "5L52"],
			aerialace: ["7M", "6M", "5M", "4M"],
			attract: ["7M", "6M", "5M", "4M"],
			blastburn: ["7T", "6T", "5T", "4T"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			bulkup: ["7M", "6M", "5M", "4M"],
			bulldoze: ["7M", "6M", "5M"],
			calmmind: ["7M", "7L58", "6M", "6L58", "5M", "5L58", "4M", "4L53"],
			captivate: ["4M"],
			closecombat: ["7L1", "6L36", "6S1", "5L36", "5S0", "4L41"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			dig: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dualchop: ["7T", "6T", "5T"],
			earthquake: ["7M", "6M", "5M", "4M"],
			ember: ["7L1", "6L1", "5L1", "4L1"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			feint: ["7L26", "6L26", "5L26", "4L29"],
			fireblast: ["7M", "6M", "6S1", "5M", "5S0", "4M"],
			firepledge: ["7T", "6T", "5T"],
			firepunch: ["7T", "6T", "6S1", "5T", "4T"],
			firespin: ["7L42", "6L42", "5L42", "4L45"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["7M", "6M", "5M", "4M"],
			flamewheel: ["7L19", "6L19", "5L19", "4L21"],
			flareblitz: ["7L1", "6L1", "5L68", "4L57"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "6S1", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyswipes: ["7L16", "6L16", "5L16", "4L17"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "5S0", "4M"],
			gunkshot: ["7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			heatwave: ["7T", "6T", "5T", "4T"],
			helpinghand: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T", "4M"],
			laserfocus: ["7T"],
			leer: ["7L1", "6L1", "5L1", "4L1"],
			lowkick: ["7T", "6T", "5T", "4T"],
			lowsweep: ["7M", "6M", "5M"],
			machpunch: ["7L1", "6L14", "5L14", "4L14"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			overheat: ["7M", "6M", "5M", "4M"],
			poisonjab: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M"],
			punishment: ["7L29", "6L29", "5L29", "4L33"],
			rest: ["7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			rollout: ["4T"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "6L1", "5L1", "4L1"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			taunt: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			thunderpunch: ["7T", "6T", "5T", "4T"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uturn: ["7M", "6M", "5M", "5S0", "4M"],
			vacuumwave: ["4T"],
			willowisp: ["7M", "6M", "5M", "4M"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 5, level: 100, gender: "M", moves: ["fireblast", "closecombat", "uturn", "grassknot"], pokeball: "cherishball"},
			{generation: 6, level: 88, isHidden: true, moves: ["fireblast", "closecombat", "firepunch", "focuspunch"], pokeball: "cherishball"},
		],
	},
	piplup: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["7E", "6E", "5E", "4E"],
			aquaring: ["7E", "6E", "5E", "4E"],
			attract: ["7M", "6M", "5M", "4M"],
			bide: ["7L22", "7E", "6L22", "6E", "5L22", "5E", "4L18"],
			blizzard: ["7M", "6M", "5M", "4M"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			brine: ["7L29", "6L29", "5L29", "4M", "4L29"],
			bubble: ["7L8", "6L8", "5L8", "5S0", "5S3", "4L8"],
			bubblebeam: ["7L18", "7S5", "6L18", "5L18", "4L18"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			defog: ["7T", "4M"],
			dig: ["6M", "5M", "4M"],
			dive: ["6M", "5M", "4T"],
			doublehit: ["7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drillpeck: ["7L39", "7S5", "6L39", "5L39", "4L39"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			featherdance: ["7E", "6E", "5E", "5S1", "5S2", "5S3", "4E"],
			flail: ["7E", "6E", "5E", "4E"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyattack: ["7L25", "6L25", "5L25", "4L25"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growl: ["7L4", "6L4", "6S4", "5L4", "5S0", "5S3", "4L4"],
			hail: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hydropump: ["7L43", "7E", "7S5", "6L43", "6E", "5L43", "5E", "5S1", "4L43", "4E"],
			icebeam: ["7M", "6M", "5M", "4M"],
			icywind: ["7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			mist: ["7L36", "6L36", "5L36", "4L36"],
			mudslap: ["7E", "6E", "5E", "4T", "4E"],
			mudsport: ["7E", "6E", "5E", "4E"],
			naturalgift: ["4M"],
			peck: ["7L15", "6L15", "5L15", "5S1", "5S2", "4L15"],
			pluck: ["5M", "4M"],
			pound: ["7L1", "6L1", "6S4", "5L1", "5S0", "5S3", "4L1"],
			powertrip: ["7E"],
			protect: ["7M", "6M", "5M", "4M"],
			quash: ["7M", "6M", "5M"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "6S4", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M", "5S2"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sing: ["5S2"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			supersonic: ["7E", "6E", "5E", "4E"],
			surf: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterfall: ["7M", "6M", "5M", "4M"],
			waterpledge: ["7T", "6T", "5T"],
			waterpulse: ["7T", "6T", "4M"],
			watersport: ["7L11", "6L11", "5L11", "5S1", "4L11"],
			whirlpool: ["7L32", "7S5", "6L32", "5L32", "4M", "4L32"],
			workup: ["7M"],
			yawn: ["7E", "6E", "5E", "4E"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["pound", "growl", "bubble"]},
			{generation: 5, level: 15, shiny: 1, moves: ["hydropump", "featherdance", "watersport", "peck"], pokeball: "cherishball"},
			{generation: 5, level: 15, gender: "M", moves: ["sing", "round", "featherdance", "peck"], pokeball: "cherishball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["pound", "growl", "bubble", "featherdance"]},
			{generation: 6, level: 7, moves: ["pound", "growl", "return"], pokeball: "cherishball"},
			{generation: 7, level: 30, gender: "M", nature: "Hardy", moves: ["hydropump", "bubblebeam", "whirlpool", "drillpeck"], pokeball: "pokeball"},
		],
	},
	prinplup: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			attract: ["7M", "6M", "5M", "4M"],
			bide: ["7L24", "6L24", "5L24", "4L19"],
			blizzard: ["7M", "6M", "5M", "4M"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			brine: ["7L33", "6L33", "5L33", "4M", "4L33"],
			bubble: ["7L1", "6L8", "5L8", "4L8"],
			bubblebeam: ["7L19", "6L19", "5L19", "4L19"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			defog: ["7T", "4M"],
			dig: ["6M", "5M", "4M"],
			dive: ["6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drillpeck: ["7L46", "6L46", "5L46", "4L46"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyattack: ["7L28", "6L28", "5L28", "4L28"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growl: ["7L1", "6L1", "5L1", "4L1"],
			hail: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["7L50", "6L50", "5L51", "4L51"],
			icebeam: ["7M", "6M", "5M", "4M"],
			icywind: ["7T", "6T", "5T", "4T"],
			metalclaw: ["7L1", "6L16", "5L16", "4L16"],
			mist: ["7L42", "6L42", "5L42", "4L42"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			peck: ["7L15", "6L15", "5L15", "4L15"],
			pluck: ["5M", "4M"],
			protect: ["7M", "6M", "5M", "4M"],
			quash: ["7M", "6M", "5M"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			surf: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterfall: ["7M", "6M", "5M", "4M"],
			waterpledge: ["7T", "6T", "5T"],
			waterpulse: ["7T", "6T", "4M"],
			watersport: ["7L11", "6L11", "5L11", "4L11"],
			whirlpool: ["7L37", "6L37", "5L37", "4M", "4L37"],
			workup: ["7M"],
		},
	},
	empoleon: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			aquajet: ["7L1", "6L36", "5L36", "5S0", "4L36"],
			attract: ["7M", "6M", "5M", "4M"],
			avalanche: ["4M"],
			blizzard: ["7M", "6M", "5M", "4M"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			brine: ["7L33", "6L33", "5L33", "4M", "4L33"],
			bubble: ["7L1", "6L1", "5L1", "4L1"],
			bubblebeam: ["7L19", "6L19", "5L19", "4L19"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			defog: ["7T", "4M"],
			dig: ["6M", "5M", "4M"],
			dive: ["6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drillpeck: ["7L52", "6L52", "5L52", "4L52"],
			earthquake: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			flashcannon: ["7M", "6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyattack: ["7L28", "6L28", "5L28", "4L28"],
			furycutter: ["4T"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "5S0", "4M"],
			growl: ["7L1", "6L1", "5L1", "4L1"],
			hail: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hydrocannon: ["7T", "6T", "5T", "4T"],
			hydropump: ["7L59", "6L59", "5L59", "5S0", "4L59"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			icebeam: ["7M", "6M", "5M", "5S0", "4M"],
			icywind: ["7T", "6T", "5T", "4T"],
			irondefense: ["7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			liquidation: ["7T"],
			metalclaw: ["7L1", "6L16", "5L16", "4L16"],
			mist: ["7L46", "6L46", "5L46", "4L46"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			peck: ["7L15", "6L15", "5L15", "4L15"],
			pluck: ["5M", "4M"],
			protect: ["7M", "6M", "5M", "4M"],
			quash: ["7M", "6M", "5M"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			steelwing: ["7M", "6M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			surf: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "7L24", "6M", "6L24", "5M", "5L24", "4M", "4L19"],
			swordsdance: ["7M", "7L11", "6M", "6L11", "5M", "5L11", "4M", "4L11"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			throatchop: ["7T"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterfall: ["7M", "6M", "5M", "4M"],
			waterpledge: ["7T", "6T", "5T"],
			waterpulse: ["7T", "6T", "4M"],
			whirlpool: ["7L39", "6L39", "5L39", "4M", "4L39"],
			workup: ["7M"],
		},
		eventData: [
			{generation: 5, level: 100, gender: "M", moves: ["hydropump", "icebeam", "aquajet", "grassknot"], pokeball: "cherishball"},
		],
	},
	starly: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "9L25", "7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L25"],
			agility: ["9M", "9L33", "7L33", "6L33", "5L33", "4L33"],
			aircutter: ["9M", "4T"],
			airslash: ["9M"],
			astonish: ["9E", "7E", "6E", "5E", "4E"],
			attract: ["7M", "6M", "5M", "4M"],
			bravebird: ["9M", "9L37", "7L37", "6L37", "5L37", "4L37"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			defog: ["7T", "4M"],
			detect: ["7E", "6E", "5E"],
			doubleedge: ["9E", "7E", "6E", "5E", "4E"],
			doubleteam: ["9L13", "7M", "7L13", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["9L17", "7T", "7L17", "6T", "6L17", "5T", "5L17", "4T", "4L17"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			featherdance: ["9E", "7E", "6E", "5E", "4E"],
			finalgambit: ["9L41", "7L41", "6L41", "5L41"],
			fly: ["9M", "7M", "6M", "5M", "4M"],
			foresight: ["7E", "6E", "5E", "4E"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyattack: ["9E", "7E", "6E", "5E", "4E"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1", "4S0"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hurricane: ["9M"],
			mirrormove: ["7E", "6E"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			pluck: ["5M", "4M"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			pursuit: ["7E", "6E", "5E", "4E"],
			quickattack: ["9L5", "7L5", "6L5", "5L5", "4L5"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["7E", "6E", "5E"],
			roost: ["7M", "7E", "6M", "6E", "5T", "5E", "4M"],
			round: ["7M", "6M", "5M"],
			sandattack: ["9E", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			steelwing: ["7M", "7E", "6M", "6E", "5E", "4M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "4T"],
			tackle: ["9L1", "7L1", "6L1", "5L1", "4L1", "4S0"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M", "9L29", "7L29", "6L29", "5L29", "4L29"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uproar: ["9E", "7T", "7E", "6T", "6E", "5E"],
			uturn: ["9M", "7M", "6M", "5M", "4M"],
			whirlwind: ["9L21", "7L21", "6L21", "5L21", "4L21"],
			wingattack: ["9L9", "7L9", "6L9", "5L9", "4L9"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 4, level: 1, gender: "M", nature: "Mild", moves: ["tackle", "growl"], pokeball: "pokeball"},
		],
	},
	staravia: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "9L28", "7M", "7L28", "6M", "6L28", "5M", "5L28", "4M", "4L28"],
			agility: ["9M", "9L38", "7L38", "6L38", "5L38", "4L38"],
			aircutter: ["9M", "4T"],
			airslash: ["9M"],
			attract: ["7M", "6M", "5M", "4M"],
			bravebird: ["9M", "9L43", "7L43", "6L43", "5L43", "4L43"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			defog: ["7T", "4M"],
			doubleteam: ["9L13", "7M", "7L13", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["9L18", "7T", "7L18", "6T", "6L18", "5T", "5L18", "4T", "4L18"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			featherdance: ["5D"],
			finalgambit: ["9L48", "7L48", "6L48", "5L48"],
			fly: ["9M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hurricane: ["9M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			pluck: ["5M", "4M"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			quickattack: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			steelwing: ["7M", "6M", "4M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "4T"],
			tackle: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			tailwind: ["9M", "7T", "6T", "5T", "5D", "4T"],
			takedown: ["9M", "9L33", "7L33", "6L33", "5L33", "4L33"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uproar: ["7T", "6T"],
			uturn: ["9M", "7M", "6M", "5M", "4M"],
			whirlwind: ["9L23", "7L23", "6L23", "5L23", "4L23"],
			wingattack: ["9L9", "7L9", "6L9", "5L9", "5D", "4L9"],
			workup: ["7M", "5M"],
		},
		encounters: [
			{generation: 4, level: 4},
		],
	},
	staraptor: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "9L28", "7M", "7L28", "6M", "6L28", "5M", "5L28", "4M", "4L28"],
			agility: ["9M", "9L41", "7L41", "6L41", "5L41", "4L41"],
			aircutter: ["9M", "4T"],
			airslash: ["9M"],
			attract: ["7M", "6M", "5M", "4M"],
			bravebird: ["9M", "9L49", "7L49", "6L49", "5L49", "4L49"],
			captivate: ["4M"],
			closecombat: ["9M", "9L0", "7L1", "6L34", "5L34", "4L34"],
			confide: ["7M", "6M"],
			defog: ["7T", "4M"],
			doubleteam: ["9L13", "7M", "7L13", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["9L18", "7T", "7L18", "6T", "6L18", "5T", "5L18", "4T", "4L18"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			finalgambit: ["9L57", "7L57", "6L57", "5L57"],
			fly: ["9M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hurricane: ["9M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M"],
			laserfocus: ["7T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			pluck: ["5M", "4M"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			quickattack: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			skyattack: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			steelwing: ["7M", "6M", "4M"],
			strugglebug: ["9M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "4T"],
			tackle: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M", "9L33", "7L33", "6L33", "5L33", "4L33"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uproar: ["7T", "6T"],
			uturn: ["9M", "7M", "6M", "5M", "4M"],
			whirlwind: ["9L23", "7L23", "6L23", "5L23", "4L23"],
			wingattack: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			workup: ["7M", "5M"],
		},
	},
	bidoof: {
		learnset: {
			amnesia: ["7L41", "6L29", "5L29", "4L29"],
			aquatail: ["7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			attract: ["7M", "6M", "5M", "4M"],
			blizzard: ["7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["7L25"],
			curse: ["7L49", "6L45", "5L45", "4L45"],
			cut: ["6M", "5M", "4M"],
			defensecurl: ["7L5", "7E", "6L9", "6E", "5L9", "5E", "4L9", "4E"],
			dig: ["6M", "5M", "4M"],
			doubleedge: ["7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["7E", "6E", "5E", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			furyswipes: ["7E", "6E", "5E", "4E"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growl: ["7L1", "6L5", "5L5", "4L5"],
			headbutt: ["7L13", "6L17", "5L17", "4T", "4L17"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperfang: ["7L17", "6L21", "5L21", "4L21"],
			icebeam: ["7M", "6M", "5M", "4M"],
			icywind: ["7T", "6T", "5T", "4T"],
			irontail: ["7T", "6T", "5T", "4M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			mudslap: ["4T"],
			mudsport: ["7E"],
			naturalgift: ["4M"],
			odorsleuth: ["7E", "6E", "5E", "4E"],
			pluck: ["5M", "4M"],
			protect: ["7M", "6M", "5M", "4M"],
			quickattack: ["7E", "6E", "5E", "4E"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["7E", "6E", "5E"],
			rocksmash: ["6M", "5M", "4M"],
			rollout: ["7L9", "7E", "6L13", "6E", "5L13", "5E", "4T", "4L13", "4E"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "5D", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			skullbash: ["7E", "6E", "5E"],
			sleeptalk: ["7M", "7E", "6M", "6E", "5T", "5E", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superfang: ["7T", "7L33", "6T", "6L37", "5T", "5L37", "4T", "4L37"],
			superpower: ["7T", "7L45", "6T", "6L41", "5T", "5L41", "4T", "4L41"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			swordsdance: ["7M", "7L37"],
			tackle: ["7L1", "6L1", "5L1", "5D", "4L1", "4S0"],
			takedown: ["7L29", "6L33", "5L33", "4L33"],
			taunt: ["7M", "6M", "5M", "4M"],
			thief: ["7M", "6M", "5M", "4M"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			thunderwave: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			watersport: ["7E", "6E", "5E", "4E"],
			workup: ["7M", "5M"],
			yawn: ["7L21", "6L25", "5L25", "4L25"],
		},
		eventData: [
			{generation: 4, level: 1, gender: "M", nature: "Lonely", abilities: ["simple"], moves: ["tackle"], pokeball: "pokeball"},
		],
	},
	bibarel: {
		learnset: {
			amnesia: ["7L48", "6L33", "5L33", "4L33"],
			aquajet: ["7L1"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["7M", "6M", "5M", "4M"],
			blizzard: ["7M", "6M", "5M", "4M"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["7L28"],
			curse: ["7L58", "6L53", "5L53", "4L53"],
			cut: ["6M", "5M", "4M"],
			defensecurl: ["7L5", "6L9", "5L9", "4L9"],
			dig: ["6M", "5M", "4M"],
			dive: ["6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growl: ["7L1", "6L1", "5L1", "4L1"],
			headbutt: ["7L13", "6L18", "5L18", "4T", "4L18"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			hyperfang: ["7L18", "6L23", "5L23", "4L23"],
			icebeam: ["7M", "6M", "5M", "4M"],
			icywind: ["7T", "6T", "5T", "4T"],
			irontail: ["7T", "6T", "5T", "4M"],
			lastresort: ["7T", "6T", "5T", "4T"],
			liquidation: ["7T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			pluck: ["5M", "4M"],
			protect: ["7M", "6M", "5M", "4M"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rocksmash: ["6M", "5M", "4M"],
			rollout: ["7L9", "6L13", "5L13", "4T", "4L13"],
			rototiller: ["7L1", "6L1"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stompingtantrum: ["7T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superfang: ["7T", "7L38", "6T", "6L43", "5T", "5L43", "4T", "4L43"],
			superpower: ["7T", "7L53", "6T", "6L48", "5T", "5L48", "4T", "4L48"],
			surf: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			swordsdance: ["7M", "7L43"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			takedown: ["7L33", "6L38", "5L38", "4L38"],
			taunt: ["7M", "6M", "5M", "4M"],
			thief: ["7M", "6M", "5M", "4M"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			thunderwave: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterfall: ["7M", "6M", "5M", "4M"],
			watergun: ["7L1", "6L15", "5L15", "4L15"],
			waterpulse: ["7T", "6T", "4M"],
			whirlpool: ["4M"],
			workup: ["7M", "5M"],
			yawn: ["7L23", "6L28", "5L28", "4L28"],
		},
		encounters: [
			{generation: 4, level: 4},
		],
	},
	kricketot: {
		learnset: {
			bide: ["7L1", "6L1", "5L1", "4L1"],
			bugbite: ["9L16", "7T", "7L16", "6T", "6L16", "5T", "5L16", "4T", "4L16"],
			endeavor: ["7T", "6T", "5T", "5D", "4T"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			mudslap: ["4T"],
			snore: ["7T", "6T", "5T", "4T"],
			stringshot: ["4T"],
			strugglebug: ["9M", "9L6", "7L6", "6M", "6L6", "5L6", "5D"],
			tackle: ["9L1"],
			terablast: ["9M"],
			uproar: ["7T", "6T", "5T", "5D", "4T"],
		},
	},
	kricketune: {
		learnset: {
			absorb: ["9L14", "7L14"],
			aerialace: ["9M", "7M", "6M", "5M", "4M"],
			attract: ["7M", "6M", "5M", "4M"],
			batonpass: ["9M"],
			bide: ["7L1", "6L1", "5L1", "4L1"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bugbuzz: ["9M", "9L46", "7L46", "6L46", "5L46", "4L34"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			falseswipe: ["9M", "7M", "6M", "5M", "4M"],
			fellstinger: ["9L36", "7L36", "6L36"],
			flash: ["6M", "5M", "4M"],
			focusenergy: ["9L22", "7L22", "6L22", "5L22", "4L22"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["9L0", "7L1", "6L10", "5L10", "4T", "4L10"],
			gigadrain: ["9M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			healbell: ["7T", "6T", "5T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "7T", "6T", "5T"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			leechlife: ["9M", "7M", "6L14", "5L14", "4L14"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightslash: ["9L42", "7L42", "6L42", "5L42", "4L42"],
			perishsong: ["9L50", "7L50", "6L50", "5L50", "4L38"],
			pounce: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			screech: ["9L34", "7L34", "6L34", "5L34", "4L30"],
			secretpower: ["6M", "4M"],
			silverwind: ["4M"],
			sing: ["9L18", "7L18", "6L18", "5L18", "4L18"],
			slash: ["9L26", "7L26", "6L26", "5L26", "4L26"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stickyweb: ["9L44", "7L44", "6L44"],
			strength: ["6M", "5M", "4M"],
			stringshot: ["4T"],
			strugglebug: ["9M", "6M", "5M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M", "9L38", "7M", "7L38", "6M", "6L38", "5M", "5L38", "4M", "4L38"],
			terablast: ["9M"],
			throatchop: ["7T"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			uproar: ["7T", "6T", "5T", "4T"],
			xscissor: ["9M", "9L30", "7M", "7L30", "6M", "6L30", "5M", "5L30", "4M", "4L26"],
		},
	},
	shinx: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M"],
			babydolleyes: ["9E", "8E", "7L11", "6L11"],
			bite: ["9L12", "8L12", "7L17", "6L17", "5L17", "4L13"],
			captivate: ["4M"],
			charge: ["9L8", "8L8", "7L9", "6L9", "5L9", "5D", "4L9"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			crunch: ["9M", "9L36", "8M", "8L36", "7L33", "6L33", "5L33", "4L29"],
			discharge: ["9L40", "8L40", "7L41", "6L41", "5L41", "4L41"],
			doublekick: ["9E", "8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			eerieimpulse: ["9M", "8M", "7E", "6E"],
			electricterrain: ["9M"],
			electroball: ["9M", "8M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			faketears: ["9M", "8M", "7E", "6E"],
			firefang: ["9M", "8M", "7E", "6E", "5E", "4E"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			headbutt: ["4T"],
			helpinghand: ["9M", "8M", "7T", "7E", "6T", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			howl: ["9E", "8E", "7E", "6E", "5E", "4E"],
			icefang: ["9M", "8M", "7E", "6E", "5E", "4E"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			leer: ["9L1", "8L1", "7L5", "6L5", "5L5", "4L5"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			magnetrise: ["7T", "6T", "5T", "5D", "4T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightslash: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychicfangs: ["9M", "8M"],
			quickattack: ["9E", "8E", "7E", "6E", "5E", "4E"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			risingvoltage: ["8T"],
			roar: ["9L20", "8L20", "7M", "7L21", "6M", "6L21", "5M", "5L21", "4M", "4L21"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L28", "8M", "8L28", "7L37", "6L37", "5L37", "4L37"],
			secretpower: ["6M", "4M"],
			shockwave: ["9E", "8E", "7T", "7E", "6T", "6E", "5E", "4M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spark: ["9L16", "8L16", "7L13", "6L13", "5L13", "4L13"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M"],
			swagger: ["9L44", "8L44", "7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L25"],
			swift: ["9M", "8M", "7E", "6E", "5E", "4T"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M", "9E", "8E", "7E", "6E", "5E", "4E"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderfang: ["9M", "8M", "7L29", "7E", "6L29", "6E", "5L29", "5E", "4L29", "4E"],
			thundershock: ["9L4", "8L4"],
			thunderwave: ["9M", "9L32", "8M", "8L32", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			voltswitch: ["9M", "9L24", "8M", "8L24", "7M", "6M", "5M"],
			wildcharge: ["9M", "9L48", "8M", "8L48", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
		},
	},
	luxio: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["9L12", "8L12", "7L18", "6L18", "5L18", "4L13"],
			captivate: ["4M"],
			charge: ["9L1", "8L1", "7L9", "6L9", "5L9", "4L9"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			crunch: ["9M", "9L48", "8M", "8L48", "7L38", "6L38", "5L38", "4L33"],
			discharge: ["9L54", "8L54", "7L48", "6L48", "5L48", "4L48"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			eerieimpulse: ["9M", "8M"],
			electricterrain: ["9M"],
			electroball: ["9M", "8M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			faketears: ["9M", "8M"],
			firefang: ["9M", "8M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			headbutt: ["4T"],
			helpinghand: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			icefang: ["9M", "8M"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychicfangs: ["9M", "8M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			risingvoltage: ["8T"],
			roar: ["9L24", "8L24", "7M", "7L23", "6M", "6L23", "5M", "5L23", "4M", "4L23"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L36", "8M", "8L36", "7L43", "6L43", "5L43", "4L43"],
			secretpower: ["6M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spark: ["9L18", "8L18", "7L13", "6L13", "5L13", "4L13"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M"],
			swagger: ["9L60", "8L60", "7M", "7L28", "6M", "6L28", "5M", "5L28", "4M", "4L28"],
			swift: ["9M", "8M", "4T"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderfang: ["9M", "8M", "7L33", "6L33", "5L33", "4L33"],
			thundershock: ["9L1", "8L1"],
			thunderwave: ["9M", "9L42", "8M", "8L42", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			voltswitch: ["9M", "9L31", "8M", "8L31", "7M", "6M", "5M"],
			wildcharge: ["9M", "9L68", "8M", "8L68", "7M", "7L53", "6M", "6L53", "5M", "5L53"],
		},
	},
	luxray: {
		learnset: {
			agility: ["9M", "8M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["9L12", "8L12", "7L18", "6L18", "5L18", "4L13"],
			bodyslam: ["9M"],
			captivate: ["4M"],
			charge: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			crunch: ["9M", "9L56", "8M", "8L56", "7L42", "6L42", "5L42", "4L35"],
			discharge: ["9L64", "8L64", "7L56", "6L56", "5L56", "4L56"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			eerieimpulse: ["9M", "8M"],
			electricterrain: ["9M", "9L1", "8M", "8L1", "7L1", "6L1"],
			electroball: ["9M", "8M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			faketears: ["9M", "8M"],
			firefang: ["9M", "8M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icefang: ["9M", "8M"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			laserfocus: ["7T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychicfangs: ["9M", "8M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			risingvoltage: ["8T"],
			roar: ["9L24", "8L24", "7M", "7L23", "6M", "6L23", "5M", "5L23", "4M", "4L23"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L40", "8M", "8L40", "7L49", "6L49", "5L49", "4L49"],
			secretpower: ["6M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spark: ["9L18", "8L18", "7L13", "6L13", "5L13", "4L13"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["9L72", "8L72", "7M", "7L28", "6M", "6L28", "5M", "5L28", "4M", "4L28"],
			swift: ["9M", "8M", "4T"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			throatchop: ["8M", "7T"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderfang: ["9M", "8M", "7L35", "6L35", "5L35", "4L35"],
			thundershock: ["9L1", "8L1"],
			thunderwave: ["9M", "9L48", "8M", "8L48", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			voltswitch: ["9M", "9L33", "8M", "8L33", "7M", "6M", "5M"],
			wildcharge: ["9M", "9L80", "8M", "8L80", "7M", "7L63", "6M", "6L63", "5M", "5L63"],
		},
	},
	cranidos: {
		learnset: {
			ancientpower: ["7L33", "6L33", "5L33", "4T", "4L28"],
			assurance: ["7L24", "6L24", "5L24", "4L24"],
			attract: ["7M", "6M", "5M", "4M"],
			blizzard: ["7M", "6M", "5M", "4M"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L28", "6L28", "5L28"],
			confide: ["7M", "6M"],
			crunch: ["7E", "6E", "5E", "5S0", "4E"],
			curse: ["7E", "6E", "5E", "4E"],
			dig: ["6M", "5M", "4M"],
			doubleedge: ["7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dragonpulse: ["7T", "6T", "5T", "4M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["7M", "6M", "5M", "4M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			fireblast: ["7M", "6M", "5M", "4M"],
			firepunch: ["7T", "6T", "5T", "5D", "4T"],
			flamethrower: ["7M", "6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusenergy: ["7L6", "6L6", "5L6", "4L6"],
			frustration: ["7M", "6M", "5M", "4M"],
			hammerarm: ["7E", "6E", "5E", "4E"],
			headbutt: ["7L1", "6L1", "5L1", "5D", "5S0", "4T", "4L1"],
			headsmash: ["7L46", "6L46", "5L46", "4L43"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			icebeam: ["7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			ironhead: ["7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T"],
			irontail: ["7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			leer: ["7L1", "7E", "6L1", "6E", "5L1", "5E", "4L1", "4E"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M"],
			pursuit: ["7L10", "6L10", "5L10", "5S0", "4L10"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["7M", "6M", "5M", "4M"],
			scaryface: ["7L19", "6L19", "5L19", "4L19"],
			screech: ["7L42", "6L42", "5L42", "4L37"],
			secretpower: ["6M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			slam: ["7E", "6E", "5E", "4E"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stomp: ["7E", "6E", "5E", "4E"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superpower: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			takedown: ["7L15", "6L15", "5L15", "5S0", "4L15"],
			thief: ["7M", "6M", "5M", "4M"],
			thrash: ["7E", "6E", "5E", "4E"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			thunderpunch: ["7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			whirlwind: ["7E", "6E", "5E", "4E"],
			zenheadbutt: ["7T", "7L37", "6T", "6L37", "5T", "5L37", "4T", "4L33"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", moves: ["pursuit", "takedown", "crunch", "headbutt"], pokeball: "cherishball"},
		],
	},
	rampardos: {
		learnset: {
			ancientpower: ["7L36", "6L36", "5L36", "4T", "4L28"],
			assurance: ["7L24", "6L24", "5L24", "4L24"],
			attract: ["7M", "6M", "5M", "4M"],
			avalanche: ["4M"],
			blizzard: ["7M", "6M", "5M", "4M"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			chipaway: ["7L28", "6L28", "5L28"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			dig: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dragonpulse: ["7T", "6T", "5T", "4M"],
			dragontail: ["7M", "6M", "5M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["7M", "6M", "5M", "4M"],
			endeavor: ["7T", "7L1", "6T", "6L30", "5T", "5L30", "4T", "4L30"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			fireblast: ["7M", "6M", "5M", "4M"],
			firepunch: ["7T", "6T", "5T", "4T"],
			flamethrower: ["7M", "6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focusenergy: ["7L1", "6L6", "5L6", "4L6"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			headbutt: ["7L1", "6L1", "5L1", "4T", "4L1"],
			headsmash: ["7L58", "6L58", "5L58", "4L52"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			icebeam: ["7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			ironhead: ["7T", "6T", "5T", "4T"],
			irontail: ["7T", "6T", "5T", "4M"],
			laserfocus: ["7T"],
			leer: ["7L1", "6L1", "5L1", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			outrage: ["7T", "6T", "5T", "4T"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M"],
			pursuit: ["7L1", "6L10", "5L10", "4L10"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["7M", "6M", "5M", "4M"],
			scaryface: ["7L19", "6L19", "5L19", "4L19"],
			screech: ["7L51", "6L51", "5L51", "4L43"],
			secretpower: ["6M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superpower: ["7T", "6T", "5T", "4T"],
			surf: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			takedown: ["7L15", "6L15", "5L15", "4L15"],
			thief: ["7M", "6M", "5M", "4M"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			thunderpunch: ["7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			whirlpool: ["4M"],
			zenheadbutt: ["7T", "7L43", "6T", "6L43", "5T", "5L43", "4T", "4L36"],
		},
	},
	shieldon: {
		learnset: {
			ancientpower: ["7L28", "6L28", "5L28", "4T", "4L28"],
			attract: ["7M", "6M", "5M", "4M"],
			blizzard: ["7M", "6M", "5M", "4M"],
			bodyslam: ["7E", "6E", "5E", "5S0", "4E"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			counter: ["7E", "6E", "5E", "5D", "4E"],
			curse: ["7E", "6E", "5E", "4E"],
			dig: ["6M", "5M", "4M"],
			doubleedge: ["7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["7M", "6M", "5M", "4M"],
			endure: ["7L33", "6L33", "5L33", "4M", "4L33"],
			facade: ["7M", "6M", "5M", "4M"],
			fireblast: ["7M", "6M", "5M", "4M"],
			fissure: ["7E", "6E", "5E", "5D", "4E"],
			flamethrower: ["7M", "6M", "5M", "4M"],
			flashcannon: ["7M", "6M", "5M", "4M"],
			focusenergy: ["7E", "6E", "5E", "4E"],
			frustration: ["7M", "6M", "5M", "4M"],
			guardsplit: ["7E", "6E"],
			headbutt: ["7E", "6E", "5E", "4T", "4E"],
			heavyslam: ["7L46", "6L46", "5L46"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			icebeam: ["7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			irondefense: ["7T", "7L19", "6T", "6L19", "5T", "5L19", "4T", "4L19"],
			ironhead: ["7T", "7L42", "6T", "6L42", "5T", "5L42", "4T", "4L43"],
			irontail: ["7T", "6T", "5T", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			metalburst: ["7L37", "6L37", "5L37", "4L37"],
			metalsound: ["7L10", "6L10", "5L10", "5S0", "4L10"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			protect: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "5D", "5S0", "4M", "4L1"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockblast: ["7E", "6E", "5E", "4E"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["7M", "6M", "5M", "4M"],
			scaryface: ["7E", "6E", "5E", "4E"],
			screech: ["7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "7L24", "6M", "6L24", "5M", "5L24", "4M", "4L24"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			takedown: ["7L15", "6L15", "5L15", "5S0", "4L15"],
			taunt: ["7M", "7L6", "6M", "6L6", "5M", "5L6", "4M", "4L6"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			wideguard: ["7E", "6E", "5E"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", moves: ["metalsound", "takedown", "bodyslam", "protect"], pokeball: "cherishball"},
		],
	},
	bastiodon: {
		learnset: {
			ancientpower: ["7L28", "6L28", "5L28", "4T", "4L28"],
			attract: ["7M", "6M", "5M", "4M"],
			avalanche: ["4M"],
			blizzard: ["7M", "6M", "5M", "4M"],
			block: ["7T", "7L1", "6T", "6L30", "5T", "5L30", "4T", "4L30"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			dig: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["7M", "6M", "5M", "4M"],
			endure: ["7L36", "6L36", "5L36", "4M", "4L36"],
			facade: ["7M", "6M", "5M", "4M"],
			fireblast: ["7M", "6M", "5M", "4M"],
			flamethrower: ["7M", "6M", "5M", "4M"],
			flashcannon: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			heavyslam: ["7L58", "6L58", "5L58"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			icebeam: ["7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			irondefense: ["7T", "7L19", "6T", "6L19", "5T", "5L19", "4T", "4L19"],
			ironhead: ["7T", "7L51", "6T", "6L51", "5T", "5L51", "4T", "4L52"],
			irontail: ["7T", "6T", "5T", "4M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			metalburst: ["7L43", "6L43", "5L43", "4L43"],
			metalsound: ["7L1", "6L1", "5L1", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			outrage: ["7T", "6T", "5T", "4T"],
			protect: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			sandstorm: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "7L24", "6M", "6L24", "5M", "5L24", "4M", "4L24"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			takedown: ["7L15", "6L15", "5L15", "4L15"],
			taunt: ["7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
		},
	},
	burmy: {
		learnset: {
			bugbite: ["7T", "7L15", "6T", "6L15", "5T", "5L15", "5D", "4T", "4L15"],
			electroweb: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "7L20", "6M", "6L20", "5L20", "4L20"],
			protect: ["7M", "7L1", "6M", "6L1", "5L1", "5D", "4L1"],
			snore: ["7T", "6T", "5T", "5D", "4T"],
			stringshot: ["4T"],
			tackle: ["7L10", "6L10", "5L10", "4L10"],
		},
	},
	wormadam: {
		learnset: {
			allyswitch: ["7T"],
			attract: ["7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L41"],
			bugbite: ["7T", "7L1", "6T", "6L15", "5T", "5L15", "4T", "4L15"],
			bugbuzz: ["7L50"],
			bulletseed: ["4M"],
			captivate: ["7L35", "6L35", "5L35", "4M", "4L35"],
			confide: ["7M", "6M"],
			confusion: ["7L23", "6L23", "5L23", "4L23"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			electroweb: ["7T", "6T", "5T"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			flail: ["7L38", "6L38", "5L38", "4L38"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["7T", "6T", "5T", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			growth: ["7L29", "6L29", "5L29", "4L29"],
			hiddenpower: ["7M", "7L20", "6M", "6L20", "5M", "5L20", "4M", "4L20"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			infestation: ["7M", "6M"],
			leafstorm: ["7L47", "6L47", "5L47", "4L47"],
			naturalgift: ["4M"],
			protect: ["7M", "7L1", "6M", "6L10", "5M", "5L10", "4M", "4L10"],
			psybeam: ["7L32", "6L32", "5L32", "4L32"],
			psychic: ["7M", "7L44", "6M", "6L44", "5M", "5L44", "4M", "4L44"],
			psychup: ["7M", "6M", "5M", "4M"],
			quiverdance: ["7L1"],
			raindance: ["7M", "6M", "5M", "4M"],
			razorleaf: ["7L26", "6L26", "5L26", "4L26"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			shadowball: ["7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["7T", "6T", "5T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M"],
			suckerpunch: ["7L1", "4T"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			synthesis: ["7T", "6T", "5T", "4T"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			telekinesis: ["7T"],
			thief: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			venoshock: ["7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
	},
	wormadamsandy: {
		learnset: {
			allyswitch: ["7T"],
			attract: ["7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L41"],
			bugbite: ["7T", "7L1", "6T", "6L15", "5T", "5L15", "4T", "4L15"],
			bugbuzz: ["7L50"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["7L35", "6L35", "5L35", "4M", "4L35"],
			confide: ["7M", "6M"],
			confusion: ["7L23", "6L23", "5L23", "4L23"],
			dig: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			earthquake: ["7M", "6M", "5M", "4M"],
			electroweb: ["7T", "6T", "5T"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			fissure: ["7L47", "6L47", "5L47", "4L47"],
			flail: ["7L38", "6L38", "5L38", "4L38"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			harden: ["7L29", "6L29", "5L29", "4L29"],
			hiddenpower: ["7M", "7L20", "6M", "6L20", "5M", "5L20", "4M", "4L20"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			infestation: ["7M", "6M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			protect: ["7M", "7L1", "6M", "6L10", "5M", "5L10", "4M", "4L10"],
			psybeam: ["7L32", "6L32", "5L32", "4L32"],
			psychic: ["7M", "7L44", "6M", "6L44", "5M", "5L44", "4M", "4L44"],
			psychup: ["7M", "6M", "5M", "4M"],
			quiverdance: ["7L1"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockblast: ["7L26", "6L26", "5L26", "4L26"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			sandstorm: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["7T", "6T", "5T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M"],
			suckerpunch: ["7L1", "4T"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			telekinesis: ["7T"],
			thief: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			venoshock: ["7M", "6M", "5M"],
		},
	},
	wormadamtrash: {
		learnset: {
			allyswitch: ["7T"],
			attract: ["7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L41"],
			bugbite: ["7T", "7L1", "6T", "6L15", "5T", "5L15", "4T", "4L15"],
			bugbuzz: ["7L50"],
			captivate: ["7L35", "6L35", "5L35", "4M", "4L35"],
			confide: ["7M", "6M"],
			confusion: ["7L23", "6L23", "5L23", "4L23"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			electroweb: ["7T", "6T", "5T"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			flail: ["7L38", "6L38", "5L38", "4L38"],
			flash: ["6M", "5M", "4M"],
			flashcannon: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			gunkshot: ["7T", "6T", "5T", "4T"],
			gyroball: ["7M", "6M", "5M", "4M"],
			hiddenpower: ["7M", "7L20", "6M", "6L20", "5M", "5L20", "4M", "4L20"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			infestation: ["7M", "6M"],
			irondefense: ["7T", "6T", "5T", "4T"],
			ironhead: ["7T", "7L47", "6T", "6L47", "5T", "5L47", "4T", "4L47"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			metalburst: ["7L1"],
			metalsound: ["7L29", "6L29", "5L29", "4L29"],
			mirrorshot: ["7L26", "6L26", "5L26", "4L26"],
			naturalgift: ["4M"],
			protect: ["7M", "7L1", "6M", "6L10", "5M", "5L10", "4M", "4L10"],
			psybeam: ["7L32", "6L32", "5L32", "4L32"],
			psychic: ["7M", "7L44", "6M", "6L44", "5M", "5L44", "4M", "4L44"],
			psychup: ["7M", "6M", "5M", "4M"],
			quiverdance: ["7L1"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["7T", "6T", "5T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stealthrock: ["7T", "6T", "5T", "4M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M"],
			suckerpunch: ["7L1", "4T"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			telekinesis: ["7T"],
			thief: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			venoshock: ["7M", "6M", "5M"],
		},
	},
	mothim: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M", "4M"],
			aircutter: ["4T"],
			airslash: ["7L41", "6L41", "5L41", "4L41"],
			attract: ["7M", "6M", "5M", "4M"],
			bugbite: ["7T", "7L1", "6T", "6L15", "5T", "5L15", "4T", "4L15"],
			bugbuzz: ["7L50", "6L47", "5L47", "4L47"],
			camouflage: ["7L35", "6L35", "5L35", "4L35"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confusion: ["7L23", "6L23", "5L23", "4L23"],
			defog: ["7T", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			electroweb: ["7T", "6T", "5T"],
			endure: ["4M"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["7T", "6T", "5T", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			gust: ["7L26", "6L26", "5L26", "4L26"],
			hiddenpower: ["7M", "7L20", "6M", "6L20", "5M", "5L20", "4M", "4L20"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			infestation: ["7M", "6M"],
			lunge: ["7L47"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			poisonpowder: ["7L29", "6L29", "5L29", "4L29"],
			protect: ["7M", "7L1", "6M", "6L10", "5M", "5L10", "4M", "4L10"],
			psybeam: ["7L32", "6L32", "5L32", "4L32"],
			psychic: ["7M", "7L44", "6M", "6L44", "5M", "5L44", "4M", "4L44"],
			psychup: ["7M", "6M", "5M", "4M"],
			quiverdance: ["7L1", "6L50", "5L50"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roost: ["7M", "6M", "5T", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["7L38", "6L38", "5L38", "4M", "4L38"],
			skillswap: ["7T", "6T", "5T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			stringshot: ["4T"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			tackle: ["7L1", "6L1", "5L1", "4L1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			thief: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			venoshock: ["7M", "6M", "5M"],
		},
	},
	combee: {
		learnset: {
			aircutter: ["5D", "4T"],
			bugbite: ["9L1", "8L1", "7T", "7L13", "6T", "6L13", "5T", "5L13", "4T", "4L13"],
			bugbuzz: ["9M", "8M", "7L29", "6L29", "5L29"],
			dualwingbeat: ["8T"],
			endeavor: ["7T", "6T", "5T", "4T"],
			gust: ["9L1", "8L1", "7L1", "6L1", "5L1", "5D", "4L1"],
			mudslap: ["4T"],
			ominouswind: ["4T"],
			sleeptalk: ["9M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stringshot: ["4T"],
			strugglebug: ["9M", "9L1", "8L1"],
			sweetscent: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			swift: ["4T"],
			tailwind: ["7T", "6T", "5T", "5D", "4T"],
			terablast: ["9M"],
		},
	},
	vespiquen: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			aerialace: ["9M", "7M", "6M", "5M", "4M"],
			agility: ["9M"],
			aircutter: ["4T"],
			airslash: ["9M", "9L28", "8M", "8L28", "7L37", "6L37", "5L37"],
			aromatherapy: ["8L24"],
			aromaticmist: ["9L8", "8L8"],
			assurance: ["8M"],
			attackorder: ["9L40", "8L40", "7L45", "6L45", "5L37", "4L37"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			beatup: ["8M"],
			bugbite: ["9L1", "8L1", "7T", "6T", "5T", "4T"],
			bugbuzz: ["9M", "8M"],
			captivate: ["7L41", "6L41", "5L33", "4M", "4L33"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L1", "8L1", "7L1", "6L1", "5L7", "4L7"],
			crosspoison: ["8M"],
			cut: ["6M", "5M", "4M"],
			defendorder: ["9L40", "8L40", "7L17", "6L17", "5L13", "4L13"],
			defog: ["7T", "4M"],
			destinybond: ["9L44", "8L44", "7L1", "6L1", "5L43", "4L43"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dualwingbeat: ["8T"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fellstinger: ["9L12", "8L12", "7L1", "6L1"],
			flash: ["6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["9L4", "8L4", "7L5", "6L5", "5L9", "4T", "4L9"],
			furyswipes: ["9L16", "8L16", "7L13", "6L13", "5L19", "4L19"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gust: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			healorder: ["7L29", "6L29", "5L25", "4L25"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hurricane: ["9M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			infestation: ["7M", "6M"],
			laserfocus: ["7T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			pinmissile: ["8M"],
			poisonsting: ["9L1", "8L1", "7L1", "6L1", "5L3", "4L3"],
			pollenpuff: ["9M"],
			pounce: ["9M"],
			powergem: ["9M", "9L32", "8M", "8L32", "7L25", "6L25", "5L21", "4L21"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			pursuit: ["7L9", "6L9", "5L15", "4L15"],
			quash: ["7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			roost: ["9L24", "7M", "6M", "5T", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["8M"],
			secretpower: ["6M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			slash: ["9L0", "8L0", "7L1", "6L21", "5L31", "4L31"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spikes: ["9M"],
			stringshot: ["4T"],
			strugglebug: ["9M", "9L1", "8L1", "6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["9L20", "8L20", "7M", "7L49", "6M", "6L49", "5M", "5L39", "4M", "4L39"],
			sweetscent: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			swift: ["9M", "8M", "4T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["9L36", "8L36", "7M", "7L33", "6M", "6L33", "5M", "5L27", "4M", "4L27"],
			toxicspikes: ["9M", "8M"],
			uproar: ["8M"],
			uturn: ["9M", "8M", "7M", "6M", "5M", "4M"],
			venoshock: ["9M", "8M", "7M", "6M", "5M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
	},
	pachirisu: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M"],
			attract: ["7M", "6M", "5M", "4M"],
			babydolleyes: ["9E", "7E"],
			bestow: ["7E", "6E", "5E"],
			bide: ["7L1", "6L1", "5L1", "4L1"],
			bite: ["9E", "7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			charge: ["9E", "7E", "6E", "5E"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "9L9", "7L9", "6L9", "5L9", "4L9"],
			confide: ["7M", "6M"],
			covet: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4E"],
			cut: ["6M", "5M", "4M"],
			defensecurl: ["9E", "7E", "6E", "5E", "4E"],
			dig: ["9M", "6M", "5M", "4M"],
			discharge: ["9L41", "7L41", "6L41", "5L41", "4L29"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L25", "7L25", "6L25", "5L25"],
			electroweb: ["7T", "6T"],
			encore: ["9M"],
			endure: ["9M", "9L17", "7L17", "6L17", "5L17", "4M", "4L17"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			faketears: ["9M", "9E", "7E", "6E", "5E", "4E"],
			flail: ["9E", "7E", "6E", "5E", "4E"],
			flash: ["6M", "5M", "4M"],
			flatter: ["9E", "7E", "6E", "5E", "4E"],
			fling: ["9M", "7M", "6M", "5M", "4M"],
			followme: ["9E", "7E", "6E", "6S0", "5E"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			gunkshot: ["9M", "7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			helpinghand: ["9M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M"],
			hyperfang: ["7L49", "6L49", "5L49"],
			iondeluge: ["7E", "6E"],
			irontail: ["7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			laserfocus: ["7T"],
			lastresort: ["9L45", "7T", "7L45", "6T", "6L45", "5T", "5L45", "4T", "4L37"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mudshot: ["9M"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			nuzzle: ["9L19", "7L19", "6L19", "6S0"],
			playrough: ["9M"],
			protect: ["9M", "7M", "6M", "6S0", "5M", "4M"],
			quickattack: ["9L5", "7L5", "6L5", "5L5", "5D", "4L5"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rollout: ["9E", "7E", "6E", "5E", "4T", "4E"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["9M", "7T", "6T", "5T", "4T"],
			shockwave: ["7T", "6T", "5D", "4M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			spark: ["9L13", "7L13", "6L13", "5L13", "4L13"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M"],
			superfang: ["9L37", "7T", "7L37", "6T", "6L37", "6S0", "5T", "5L37", "4T", "4L33"],
			swagger: ["7M", "6M", "5M", "4M"],
			sweetkiss: ["9L29", "7L29", "6L29", "5L29", "4L25"],
			swift: ["9M", "9L21", "7L21", "6L21", "5L21", "4T", "4L21"],
			tailwhip: ["9E", "7E", "6E", "5E"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M", "9L49", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M"],
			thunderfang: ["9M"],
			thunderpunch: ["9M", "7T", "6T", "5T", "4T"],
			thundershock: ["9L1"],
			thunderwave: ["9M", "9L33", "7M", "7L33", "6M", "6L33", "5M", "5L33", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["9M", "7M", "6M", "5M", "4M"],
			voltswitch: ["9M", "7M", "6M", "5M"],
			wildcharge: ["9M"],
		},
		eventData: [
			{generation: 6, level: 50, nature: "Impish", ivs: {hp: 31, atk: 31, def: 31, spa: 14, spd: 31, spe: 31}, isHidden: true, moves: ["nuzzle", "superfang", "followme", "protect"], pokeball: "cherishball"},
		],
	},
	buizel: {
		learnset: {
			agility: ["9M", "9L41", "7L41", "6L41", "5L28", "4L28"],
			aquajet: ["9L24", "7L24", "6L24", "5L21", "4L21"],
			aquaring: ["9E", "7E", "6E", "5E"],
			aquatail: ["9L38", "7T", "7L38", "7E", "6T", "6L38", "6E", "5T", "5L55", "5E"],
			attract: ["7M", "6M", "5M", "4M"],
			batonpass: ["9M", "9E", "7E", "6E", "5E", "4E"],
			bite: ["9L18"],
			blizzard: ["9M", "7M", "6M", "5M", "4M"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M"],
			brine: ["4M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M"],
			dig: ["9M", "6M", "5M", "4M"],
			dive: ["6M", "5M", "4T"],
			doublehit: ["9L27", "7L27", "6L27", "5L27"],
			doubleslap: ["7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			fling: ["9M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["9E", "7E", "6E", "5E", "4E"],
			furyswipes: ["9E", "7E", "6E", "5E", "4E"],
			growl: ["9L4", "7L4", "6L4", "5L1", "4L1"],
			hail: ["7M", "6M", "5M", "4M"],
			headbutt: ["9E", "7E", "6E", "5E", "4T", "4E"],
			helpinghand: ["9M", "9E", "7T", "7E"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hydropump: ["9M", "9L45", "7L45", "6L45", "5L45"],
			icebeam: ["9M", "7M", "6M", "5M", "4M"],
			icefang: ["9M"],
			icepunch: ["9M", "7T", "6T", "5T", "4T"],
			icespinner: ["9M"],
			icywind: ["9M", "7T", "6T", "5T", "4T"],
			irontail: ["7T", "6T", "5T", "4M"],
			liquidation: ["9M", "9L35"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			mefirst: ["7E", "6E", "5E"],
			mudslap: ["9M", "9E", "7E", "6E", "5E", "4T", "4E"],
			naturalgift: ["4M"],
			odorsleuth: ["7E", "6E", "5E", "4E"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			pursuit: ["7L18", "6L18", "5L10", "4L10"],
			quickattack: ["9L11", "7L11", "6L11", "5L3", "4L3"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			razorwind: ["7L35", "6L35", "5L45", "4L45"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			slash: ["9E", "7E", "6E", "5E", "5D", "4E"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			soak: ["9L7", "7E", "6E"],
			sonicboom: ["7L1", "6L1", "5L1", "4L1"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "9L21", "7L21", "6L21", "5L15", "4T", "4L15"],
			switcheroo: ["7E", "6E", "5E"],
			tackle: ["9L1"],
			tailslap: ["7E", "6E", "5E"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterfall: ["9M", "7M", "6M", "5M", "4M"],
			watergun: ["9L15", "7L15", "6L15", "5L6", "5D", "4L6"],
			waterpulse: ["9M", "7T", "6T", "5D", "4M"],
			watersport: ["7L7", "6L7", "5L1", "5D", "4L1"],
			wavecrash: ["9L49"],
			whirlpool: ["9L31", "7L31", "6L31", "5L36", "4M", "4L36"],
		},
	},
	floatzel: {
		learnset: {
			agility: ["9M", "9L51", "7L51", "6L51", "5L29", "4L29"],
			aquajet: ["9L24", "7L24", "6L24", "5L21", "4L21"],
			aquatail: ["9L46", "7T", "7L46", "6T", "6L46", "5T", "5L62", "4T"],
			attract: ["7M", "6M", "5M", "4M"],
			batonpass: ["9M"],
			bite: ["9L18"],
			blizzard: ["9M", "7M", "6M", "5M", "4M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M"],
			brine: ["4M"],
			bulkup: ["9M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L1", "7L1", "6L1", "5L26", "4L26"],
			dig: ["9M", "6M", "5M", "4M"],
			dive: ["6M", "5M", "4T"],
			doublehit: ["9L29", "7L29", "6L29", "5L29"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			fling: ["9M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			growl: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			hail: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M", "7T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hydropump: ["9M", "9L57", "7L57", "6L57", "5L57"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M"],
			icefang: ["9M", "9L1", "7L1", "6L1", "5L1", "4L1"],
			icepunch: ["9M", "7T", "6T", "5T", "4T"],
			icespinner: ["9M"],
			icywind: ["9M", "7T", "6T", "5T", "4T"],
			irontail: ["7T", "6T", "5T", "4M"],
			liquidation: ["9M", "9L41", "7T"],
			lowkick: ["9M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M"],
			metronome: ["9M"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			pursuit: ["7L18", "6L18", "5L10", "4L10"],
			quickattack: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			razorwind: ["7L41", "6L41", "5L50", "4L50"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snarl: ["9M"],
			snore: ["7T", "6T", "5T", "4T"],
			soak: ["9L1"],
			sonicboom: ["7L1", "6L1", "5L1", "4L1"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "9L21", "7L21", "6L21", "5L15", "4T", "4L15"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M", "4M"],
			terablast: ["9M"],
			thief: ["9M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterfall: ["9M", "7M", "6M", "5M", "4M"],
			watergun: ["9L15", "7L15", "6L15", "5L6", "4L6"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			watersport: ["7L1", "6L1", "5L1", "4L1"],
			wavecrash: ["9L62"],
			whirlpool: ["9L35", "7L35", "6L35", "5L39", "4M", "4L39"],
		},
		encounters: [
			{generation: 4, level: 22},
			{generation: 5, level: 10},
		],
	},
	cherubi: {
		learnset: {
			aromatherapy: ["8E", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bulletseed: ["8M", "5D", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defensecurl: ["8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainingkiss: ["8M"],
			endure: ["8M", "4M"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			flowershield: ["8E", "7E", "6E"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7E", "6E", "5E", "4E"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7E"],
			growth: ["8L10", "7L7", "6L7", "5L7", "4L7"],
			healingwish: ["8E", "7E", "6E", "5E"],
			healpulse: ["8E", "7E", "6E", "5E", "5D"],
			helpinghand: ["8M", "8L15", "7T", "7L13", "6T", "6L13", "5T", "5L13", "4T", "4L13"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			leafage: ["8L5"],
			leechseed: ["8L26", "7L10", "6L10", "5L10", "5D", "4L10"],
			luckychant: ["7L40", "6L40", "5L40", "4L40"],
			magicalleaf: ["8M", "8L20", "7L19", "6L19", "5L19", "4L19"],
			morningsun: ["8L1", "7L1", "6L1", "5L1"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			naturepower: ["8E", "7M", "7E", "6M", "6E", "5E", "4E"],
			petalblizzard: ["8L35", "7L47", "6L47"],
			pollenpuff: ["8M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			razorleaf: ["8E", "7E", "6E", "5E", "4E"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rollout: ["8E", "7E", "6E", "5E", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "8L45", "7M", "7L37", "6M", "6L37", "5M", "5L37", "4M", "4L37"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "7L22", "6M", "6L22", "5M", "5L22", "4M", "4L22"],
			swagger: ["7M", "6M", "5M", "4M"],
			sweetscent: ["8E", "7E", "6E", "5E", "4E"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["7T", "6T", "5T", "4T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["8L30", "7L31", "6L31", "5L31", "4L31"],
			tickle: ["8E", "7E", "6E", "5E", "4E"],
			toxic: ["7M", "6M", "5M", "4M"],
			weatherball: ["8M", "7E", "6E", "5E", "4E"],
			worryseed: ["8L40", "7T", "7L28", "6T", "6L28", "5T", "5L28", "4T", "4L28"],
		},
	},
	cherrim: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bulletseed: ["8M", "4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainingkiss: ["8M"],
			endure: ["8M", "4M"],
			energyball: ["8M", "7M", "6M", "5M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			flowershield: ["8L1"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["8M", "7T", "6T", "5T", "4M"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growth: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			helpinghand: ["8M", "8L15", "7T", "7L13", "6T", "6L13", "5T", "5L13", "4T", "4L13"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			laserfocus: ["7T"],
			leafage: ["8L1"],
			leechseed: ["8L28", "7L1", "6L10", "5L10", "4L10"],
			luckychant: ["7L48", "6L48", "5L48", "4L48"],
			magicalleaf: ["8M", "8L20", "7L19", "6L19", "5L19", "4L19"],
			morningsun: ["8L1", "7L1", "6L1", "5L1"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["8L41", "7L50", "6L50"],
			petaldance: ["8L62", "7L1", "6L25", "5L25", "4L25"],
			playrough: ["8M"],
			pollenpuff: ["8M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["8M", "7T", "6T", "5T", "4T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "8L55", "7M", "7L43", "6M", "6L43", "5M", "5L43", "4M", "4L43"],
			solarblade: ["8M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "8L0", "7M", "7L22", "6M", "6L22", "5M", "5L22", "4M", "4L22"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			synthesis: ["7T", "6T", "5T", "4T"],
			tackle: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["8L34", "7L35", "6L35", "5L35", "4L35"],
			toxic: ["7M", "6M", "5M", "4M"],
			weatherball: ["8M"],
			worryseed: ["8L48", "7T", "7L30", "6T", "6L30", "5T", "5L30", "4T", "4L30"],
		},
	},
	shellos: {
		learnset: {
			acidarmor: ["9E", "8E", "7E", "6E"],
			amnesia: ["9M", "8M", "7E", "6E", "5E", "4E"],
			ancientpower: ["9L20", "8L20", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bodyslam: ["9M", "9L25", "8M", "8L25", "7L29", "6L29", "5L29", "4L29"],
			brine: ["8M", "7E", "6E", "5E", "4M"],
			bulldoze: ["9M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			clearsmog: ["9E", "8E", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "6E", "5E", "4E"],
			curse: ["9E", "8E", "7E", "6E", "5E", "4E"],
			dive: ["8M", "6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["9M", "9L35", "8M", "8L35", "7T", "6T", "5T", "4T"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fissure: ["7E", "6E", "5E", "4E"],
			frustration: ["7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M"],
			harden: ["9L5", "8L5", "7L4", "6L4", "5L4", "4L4"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7L16", "6M", "6L16", "5M", "5L16", "4M", "4L16"],
			hydropump: ["9M", "8M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			infestation: ["7M", "6M"],
			liquidation: ["9M"],
			memento: ["9L45", "8L45", "7E", "6E", "5E", "4E"],
			mirrorcoat: ["9E", "8E", "7E", "6E", "5E", "4E"],
			mist: ["9E", "8E", "7E", "6E", "5E"],
			mudbomb: ["7L11", "6L11", "5L11", "4L11"],
			muddywater: ["9L31", "8M", "8L31", "7L37", "6L37", "5L37", "4L37"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "9L1", "8L1", "7L1", "6L1", "5L1", "5D", "4T", "4L1"],
			mudsport: ["7L2", "6L2", "5L2", "4L2"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			raindance: ["9M", "9L40", "8M", "8L40", "7M", "7L22", "6M", "6L22", "5M", "5L22", "4M", "4L22"],
			recover: ["9L10", "8L10", "7L46", "6L46", "5L46", "4L46"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "5D", "4M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			sludge: ["9E", "8E", "7E", "6E", "5E", "4E"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M"],
			spitup: ["9E", "8E", "7E", "6E", "5E", "4E"],
			stealthrock: ["9M"],
			stockpile: ["9E", "8E", "7E", "6E", "5E", "4E"],
			stoneedge: ["9M"],
			stringshot: ["4T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swallow: ["9E", "8E", "7E", "6E", "5E", "4E"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trumpcard: ["7E", "6E", "5E", "4E"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1"],
			waterpulse: ["9M", "9L15", "8L15", "7T", "7L7", "6T", "6L7", "5L7", "4M", "4L7"],
			whirlpool: ["8M", "4M"],
			yawn: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
		},
	},
	gastrodon: {
		learnset: {
			amnesia: ["9M", "8M"],
			ancientpower: ["9L20", "8L20", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M"],
			block: ["7T", "6T", "5T", "4T"],
			bodyslam: ["9M", "9L25", "8M", "8L25", "7L29", "6L29", "5L29", "4L29"],
			brine: ["8M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			dive: ["8M", "6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["9M", "9L39", "8M", "8L39", "7T", "7S0", "6T", "5T", "4T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hail: ["8M", "7M", "6M", "5M", "4M"],
			harden: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "7L16", "6M", "6L16", "5M", "5L16", "4M", "4L16"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icebeam: ["9M", "8M", "7M", "7S0", "6M", "5M", "4M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			infestation: ["7M", "6M"],
			liquidation: ["9M"],
			memento: ["9L53", "8L53"],
			mudbomb: ["7L11", "6L11", "5L11", "4L11"],
			muddywater: ["9L33", "8M", "8L33", "7L41", "6L41", "5L41", "4L41"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "9L1", "8L1", "7L1", "6L1", "5L1", "4T", "4L1"],
			mudsport: ["7L1", "6L1", "5L1", "4L1"],
			naturalgift: ["4M"],
			painsplit: ["7T", "6T", "5T", "4T"],
			protect: ["9M", "8M", "7M", "7S0", "6M", "5M", "4M"],
			raindance: ["9M", "9L46", "8M", "8L46", "7M", "7L22", "6M", "6L22", "5M", "5L22", "4M", "4L22"],
			recover: ["9L1", "8L1", "7L54", "7S0", "6L54", "5L54", "4L54"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockblast: ["9M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sandtomb: ["8M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M"],
			spikes: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			stringshot: ["4T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterfall: ["9M", "8M", "7M", "6M", "5M", "4M"],
			watergun: ["9L1", "8L1"],
			waterpulse: ["9M", "9L15", "8L15", "7T", "7L1", "6T", "6L1", "5L1", "4M", "4L1"],
			weatherball: ["8M"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 7, level: 50, gender: "F", nature: "Modest", abilities: ["stormdrain"], ivs: {hp: 31, atk: 0, def: 31, spa: 31, spd: 31, spe: 31}, moves: ["earthpower", "icebeam", "recover", "protect"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 4, level: 20},
		],
	},
	gastrodoneast: {
		learnset: {
			earthpower: ["8S1", "8S0"],
			icebeam: ["8S1", "8S0"],
			protect: ["8S1", "8S0"],
			surf: ["8S0"],
			yawn: ["8S1"],
		},
		eventData: [
			{generation: 8, level: 50, gender: "F", nature: "Quiet", abilities: ["stormdrain"], ivs: {hp: 31, atk: 2, def: 31, spa: 31, spd: 31, spe: 0}, moves: ["protect", "surf", "icebeam", "earthpower"], pokeball: "cherishball"},
			{generation: 8, level: 50, gender: "F", nature: "Sassy", abilities: ["stormdrain"], ivs: {hp: 31, atk: 0, def: 31, spa: 31, spd: 31, spe: 0}, moves: ["protect", "yawn", "icebeam", "earthpower"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 4, level: 20},
		],
	},
	drifloon: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			aerialace: ["9M"],
			aircutter: ["9M", "4T"],
			allyswitch: ["8M", "7T"],
			amnesia: ["9M", "8M", "7L40", "6L40", "5L40"],
			astonish: ["9L1", "8L1", "7L4", "6L4", "5L6", "4L6"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			batonpass: ["9M", "9L36", "8M", "8L36", "7L44", "6L44", "5L38", "4L33"],
			bind: ["7T", "6T", "5T"],
			bodyslam: ["8M", "7E", "6E", "5E", "4E"],
			brutalswing: ["8M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			clearsmog: ["9E", "8E", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "6L1", "5L1", "4L1"],
			cut: ["6M", "5M", "4M"],
			defog: ["9E", "8E", "7T", "7E", "6E", "5E", "4M"],
			destinybond: ["9L32", "8L32", "7E", "6E", "5E", "4E"],
			disable: ["9E", "8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			explosion: ["9L44", "8L44", "7M", "7L50", "6M", "6L50", "5M", "5L46", "4M", "4L43"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fly: ["9M"],
			focusenergy: ["9L8", "8M", "8L8", "7L13", "6L13", "5L14", "4L14"],
			frustration: ["7M", "6M", "5M", "4M"],
			gust: ["9L4", "8L4", "7L8", "6L8", "5L11", "4L11"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			haze: ["9E", "8E", "7E", "6E", "5E", "4E"],
			helpinghand: ["9M"],
			hex: ["9M", "9L16", "8M", "8L16", "7L27", "6L27", "5L22"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hypnosis: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			memento: ["9E", "8E", "7E", "6E", "5E", "4E"],
			minimize: ["9L1", "8L1", "7L1", "6L1", "5L1", "5D", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			ominouswind: ["7L20", "6L20", "5L33", "4T", "4L30"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["9L12", "8M", "8L12", "7M", "7L16", "6M", "6L16", "5M", "5L17", "4M", "4L17"],
			phantomforce: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			selfdestruct: ["9L29", "8M", "8L29"],
			shadowball: ["9M", "9L20", "8M", "8L20", "7M", "7L36", "6M", "6L36", "5M", "5L43", "4M", "4L38"],
			shockwave: ["7T", "6T", "4M"],
			silverwind: ["4M"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "5D", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			spitup: ["9L24", "8L24", "7L32", "6L32", "5L30", "4L27"],
			stockpile: ["9L24", "8L24", "7L25", "6L25", "5L27", "4L22"],
			storedpower: ["9M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swallow: ["9L24", "8L24", "7L32", "6L32", "5L30", "4L27"],
			swift: ["9M", "8M", "4T"],
			tailwind: ["9M", "9L40", "8L40", "7T", "7E", "6T", "6E", "5T", "4T"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M"],
			weatherball: ["8M", "7E", "6E", "5E", "4E"],
			willowisp: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
	},
	drifblim: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			aerialace: ["9M"],
			aircutter: ["9M", "4T"],
			airslash: ["9M"],
			allyswitch: ["8M", "7T"],
			amnesia: ["9M", "8M", "7L46", "6L46", "5L46"],
			astonish: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			batonpass: ["9M", "9L42", "8M", "8L42", "7L52", "6L52", "5L44", "4L37"],
			bind: ["7T", "6T", "5T"],
			bodyslam: ["9M", "8M"],
			brutalswing: ["8M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "6L1", "5L1", "4L1"],
			cut: ["6M", "5M", "4M"],
			defog: ["7T", "4M"],
			destinybond: ["9L36", "8L36"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			embargo: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			explosion: ["9L54", "8L54", "7M", "7L60", "6M", "6L60", "5M", "5L56", "4M", "4L51"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fling: ["9M"],
			fly: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L1", "8M", "8L1", "7L13", "6L13", "5L14", "4L14"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gust: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			gyroball: ["8M", "7M", "6M", "5M", "4M"],
			helpinghand: ["9M"],
			hex: ["9M", "9L16", "8M", "8L16", "7L27", "6L27", "5L22"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M", "8M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			minimize: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			ominouswind: ["7L20", "6L20", "5L37", "4T", "4L32"],
			painsplit: ["7T", "6T", "5T", "4T"],
			payback: ["9L12", "8M", "8L12", "7M", "7L16", "6M", "6L16", "5M", "5L17", "4M", "4L17"],
			phantomforce: ["9M", "9L0", "8M", "8L0", "7L1", "6L1"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			selfdestruct: ["9L31", "8M", "8L31"],
			shadowball: ["9M", "9L20", "8M", "8L20", "7M", "7L40", "6M", "6L40", "5M", "5L51", "4M", "4L44"],
			shockwave: ["7T", "6T", "4M"],
			silverwind: ["4M"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			spitup: ["9L24", "8L24", "7L34", "6L34", "5L32", "4L27"],
			stockpile: ["9L24", "8L24", "7L25", "6L25", "5L27", "4L22"],
			storedpower: ["9M"],
			strengthsap: ["9L1", "8L1"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swallow: ["9L24", "8L24", "7L34", "6L34", "5L32", "4L27"],
			swift: ["9M", "8M", "4T"],
			tailwind: ["9M", "9L48", "8L48", "7T", "6T", "5T", "4T"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M"],
			weatherball: ["8M"],
			willowisp: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
		encounters: [
			{generation: 7, level: 11, pokeball: "pokeball"},
		],
	},
	buneary: {
		learnset: {
			afteryou: ["8L12", "7T", "7L43", "6T", "6L43", "5T", "5L43"],
			agility: ["8M", "8L36", "7L33", "6L33", "5L33", "4L33"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M", "4E"],
			babydolleyes: ["8L8", "7L13", "6L10"],
			batonpass: ["8M", "8L28", "7L26", "6L26", "5L26", "4L26"],
			bounce: ["8M", "8L48", "7T", "7L56", "6T", "6L56", "5T", "5L56", "4T", "4L46"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M", "8L24", "7L46", "6L46", "5L46", "4L43"],
			circlethrow: ["8E", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			copycat: ["8E", "7E", "6E", "5E"],
			cosmicpower: ["8M", "7E", "6E"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			defensecurl: ["8L4", "7L1", "6L1", "5L1", "4L1"],
			dig: ["8M", "6M", "5M", "4M"],
			dizzypunch: ["7L36", "6L36", "5L36", "4L36"],
			doublehit: ["8E", "7E", "6E", "5E", "4E"],
			doublekick: ["8L20"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainpunch: ["8M", "7T", "6T", "5T", "5D", "4M"],
			encore: ["8M", "7E", "6E", "5E", "4E"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7L6", "6L6", "5L6", "4M", "4L6"],
			entrainment: ["8L40", "7L50", "6L50", "5L53"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			fakeout: ["8E", "7E", "6E", "5E", "5D", "4E"],
			faketears: ["8M", "7E", "6E", "5E", "4E"],
			firepunch: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			flail: ["8E", "7E", "6E", "5E", "4E"],
			flatter: ["8L44"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["8E", "7T", "7E", "6T", "6E", "5E", "4M"],
			foresight: ["7L1", "6L1", "5L1", "5D", "4L1"],
			frustration: ["7M", "7L1", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["8L32", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			healingwish: ["8L52", "7L63", "6L63", "5L63", "4L53"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icepunch: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			jumpkick: ["7L23", "6L23", "5L23", "4L23"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lowkick: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			lowsweep: ["8M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudslap: ["4T"],
			mudsport: ["7E", "6E"],
			naturalgift: ["4M"],
			payback: ["8M"],
			playrough: ["8M"],
			pound: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			poweruppunch: ["7E", "6M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			quickattack: ["8L16", "7L16", "6L16", "5L16", "4L16"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			skyuppercut: ["7E", "6E", "5E", "4E"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			splash: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			sweetkiss: ["8E", "7E", "6E", "5E", "4E"],
			swift: ["8M", "4T"],
			switcheroo: ["8E", "7E", "6E", "5E", "4E"],
			teeterdance: ["8E", "7E", "6E"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4E"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			tripleaxel: ["8T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M"],
			workup: ["8M", "7M", "5M"],
		},
	},
	lopunny: {
		learnset: {
			acrobatics: ["8M"],
			afteryou: ["8L12", "7T", "7L43", "6T", "6L43", "5T", "5L43"],
			agility: ["8M", "8L36", "7L33", "6L33", "5L33", "4L33"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			aurasphere: ["8M"],
			babydolleyes: ["8L1", "7L13"],
			batonpass: ["8M", "8L28", "7L26", "6L26", "5L26", "4L26"],
			blizzard: ["8M", "7M", "6M", "5M", "4M"],
			bounce: ["8M", "8L48", "7T", "7L1", "6T", "6L1", "5T", "5L56", "4T", "4L46"],
			brutalswing: ["8M", "7M"],
			captivate: ["4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			charm: ["8M", "8L24", "7L46", "6L46", "5L46", "4L43"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			cosmicpower: ["8M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			defensecurl: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			dig: ["8M", "6M", "5M", "4M"],
			dizzypunch: ["7L36", "6L36", "5L36", "4L36"],
			doublekick: ["8L20"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainpunch: ["8M", "7T", "6T", "5T", "4M"],
			encore: ["8M"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["8M", "7L6", "6L6", "5L6", "4M", "4L6"],
			entrainment: ["8L40", "7L53", "6L53", "5L53"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			faketears: ["8M"],
			firepunch: ["8M", "7T", "6T", "5T", "4T"],
			flatter: ["8L44"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			foresight: ["7L1", "6L1", "5L1", "4L1"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			grassknot: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["8L32", "4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			healingwish: ["8L52", "7L1", "6L1", "5L63", "4L53"],
			helpinghand: ["8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			highjumpkick: ["8L56", "7L66", "6L66"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M", "4M"],
			icepunch: ["8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			jumpkick: ["7L23", "6L23", "5L23", "4L23"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lowkick: ["8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			magiccoat: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mirrorcoat: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			payback: ["8M"],
			playrough: ["8M"],
			pound: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			quickattack: ["8L16", "7L16", "6L16", "5L16", "4L16"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "7L1", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			reversal: ["8M"],
			rocksmash: ["6M", "5M", "4M"],
			rototiller: ["7L1", "6L1"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["8M", "7M", "6M", "5M", "4M"],
			splash: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["8M", "4T"],
			thunder: ["8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "4T"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			tripleaxel: ["8T"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			uturn: ["8M"],
			waterpulse: ["7T", "6T", "4M"],
			workup: ["8M", "7M", "5M"],
		},
	},
	glameow: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			assist: ["7L29", "6L29", "5L29", "4L29"],
			assurance: ["7E", "6E", "5E", "5D", "4E"],
			attract: ["7M", "7L44", "6M", "6L44", "5M", "5L44", "4M", "4L45"],
			bite: ["7E", "6E", "5E", "4E"],
			captivate: ["7L32", "6L32", "5L32", "4M", "4L32"],
			charm: ["7L25", "6L25", "5L25", "4L25"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			dig: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			fakeout: ["7L1", "6L1", "5L1", "5D", "4L1"],
			faketears: ["7E", "6E", "5E", "4E"],
			feintattack: ["7L17", "6L17", "5L17", "4L17"],
			flail: ["7E", "6E", "5E", "4E"],
			flash: ["6M", "5M", "4M"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			furyswipes: ["7L20", "6L20", "5L20", "4L20"],
			growl: ["7L8", "6L8", "5L8", "4L8"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["7L48", "6M", "6L48", "5M", "5L48"],
			hypervoice: ["7T", "6T", "5T"],
			hypnosis: ["7L13", "6L13", "5L13", "4L13"],
			irontail: ["7T", "6T", "5T", "4M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "7E", "6T", "6E", "5T", "5E", "4T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			playrough: ["7L50", "6L50"],
			protect: ["7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			quickattack: ["7E", "6E", "5E", "4E"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7E", "6E", "5E", "4E"],
			scratch: ["7L5", "6L5", "5L5", "4L5"],
			secretpower: ["6M", "5D", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			slash: ["7L37", "6L37", "5L37", "4L37"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snatch: ["7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			substitute: ["7M", "6M", "5M", "4M"],
			suckerpunch: ["7L41", "6L41", "5L41", "4T", "4L41"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			tailwhip: ["7E", "6E", "5E", "4E"],
			taunt: ["7M", "6M", "5M", "4M"],
			thief: ["7M", "6M", "5M", "4M"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uturn: ["7M", "6M", "5M", "4M"],
			wakeupslap: ["7E", "6E", "5E"],
			waterpulse: ["7T", "6T", "4M"],
			workup: ["7M", "5M"],
		},
	},
	purugly: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			assist: ["7L29", "6L29", "5L29", "4L29"],
			attract: ["7M", "7L52", "6M", "6L52", "5M", "5L52", "4M", "4L53"],
			bodyslam: ["7L45", "6L45", "5L45", "4L45"],
			bulldoze: ["7M", "6M", "5M"],
			captivate: ["7L32", "6L32", "5L32", "4M", "4L32"],
			charm: ["7L25", "6L25", "5L25", "4L25"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M", "4M"],
			dig: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			fakeout: ["7L1", "6L1", "5L1", "4L1"],
			feintattack: ["7L17", "6L17", "5L17", "4L17"],
			flash: ["6M", "5M", "4M"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			furyswipes: ["7L20", "6L20", "5L20", "4L20"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			growl: ["7L1", "6L1", "5L1", "4L1"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["7L60", "6M", "6L60", "5M", "5L60"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			hypervoice: ["7T", "6T", "5T"],
			hypnosis: ["7L13", "6L13", "5L13", "4L13"],
			irontail: ["7T", "6T", "5T", "4M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			payback: ["7M", "6M", "5M", "4M"],
			protect: ["7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "6L1", "5L1", "4L1"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			slash: ["7L37", "6L37", "5L37", "4L37"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			stompingtantrum: ["7T"],
			substitute: ["7M", "6M", "5M", "4M"],
			suckerpunch: ["4T"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["7M", "7L1", "6M", "6L38", "5M", "5L38", "4M", "4L38"],
			swift: ["4T"],
			taunt: ["7M", "6M", "5M", "4M"],
			thief: ["7M", "6M", "5M", "4M"],
			throatchop: ["7T"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M"],
			workup: ["7M", "5M"],
		},
		encounters: [
			{generation: 6, level: 32, maxEggMoves: 1},
		],
	},
	stunky: {
		learnset: {
			acidspray: ["9M", "9L9", "8L9", "7L19", "6L32", "5L32"],
			assurance: ["8M"],
			astonish: ["9E", "8E", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			belch: ["9L39", "8L39", "7L43", "6L46"],
			bite: ["9L18", "8L18", "7L21"],
			bodyslam: ["9M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			crunch: ["9M", "8M", "7E", "6E", "5E", "4E"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			defog: ["7T", "4M"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleedge: ["9E", "8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			explosion: ["9L42", "8L45", "7M", "7L45", "6M", "6L49", "5M", "5L49", "4M", "4L44"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feint: ["9L3", "8L3", "7L15", "6L18", "5L18", "4L18"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flameburst: ["7E", "6E", "5E"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["9L15", "8M", "8L15", "7L1", "6L1", "5L1", "4L1"],
			foulplay: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			furyswipes: ["9L12", "8L12", "7L9", "6L10", "5L10", "4L10"],
			gunkshot: ["9M"],
			haze: ["9E", "8E", "7E", "6E", "5E", "4E"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			lashout: ["8T"],
			leer: ["9E", "8E", "7E", "6E", "5E", "4E"],
			memento: ["9L33", "8L33", "7L33", "6L43", "5L43", "4L37"],
			mudslap: ["4T"],
			nastyplot: ["9M", "8M"],
			naturalgift: ["4M"],
			nightslash: ["9L36", "8L36", "7L31", "6L37", "5L37", "4L31"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			playrough: ["9M", "8M", "7E", "6E"],
			poisongas: ["9L1", "8L1", "7L3", "6L4", "5L4", "4L4"],
			poisonjab: ["9M"],
			poisontail: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			punishment: ["7E", "6E", "5E", "4E"],
			pursuit: ["7E", "6E", "5E", "4E"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M", "7E", "6E", "5E", "4E"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			screech: ["9L24", "8M", "8L24", "7L7", "6L7", "5L7", "5D", "4L7"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["9E", "8E", "7L25", "6L22", "5L22", "4L22"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			smog: ["9E", "8E", "7E", "6E", "5E", "4E"],
			smokescreen: ["9L6", "8L6", "7L13", "6L14", "5L14", "4L14"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["9L30", "8L30", "7L39", "5D", "4T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			tailslap: ["8M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			throatchop: ["8M", "7T"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["9L27", "8L27", "7M", "7L27", "6M", "6L27", "5M", "5L27", "4M", "4L20"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			venomdrench: ["8M", "8L42", "7L37"],
			venoshock: ["9M", "9L21", "8M", "8L21", "7M", "6M", "5M"],
		},
	},
	skuntank: {
		learnset: {
			acidspray: ["9M", "8L9", "7L19", "6L32", "5L32"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			belch: ["9L43", "8L43", "7L43", "6L56"],
			bite: ["9L18", "8L18", "7L21"],
			bodyslam: ["9M"],
			burningjealousy: ["8T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			crunch: ["9M", "8M"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			defog: ["7T", "4M"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			explosion: ["9L48", "8L53", "7M", "7L45", "6M", "6L61", "5M", "5L61", "4M", "4L52"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feint: ["9L1", "8L1", "7L15", "6L18", "5L18", "4L18"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firespin: ["9M", "8M"],
			flamethrower: ["9M", "9L0", "8M", "8L0", "7M", "7L1", "6M", "6L34", "5M", "5L34", "4M", "4L34"],
			focusenergy: ["9L15", "8M", "8L15", "7L1", "6L1", "5L1", "4L1"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			furyswipes: ["9L12", "8L12", "7L9", "6L10", "5L10", "4L10"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gunkshot: ["9M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			lashout: ["8T"],
			memento: ["9L33", "8L33", "7L33", "6L51", "5L51", "4L41"],
			mudslap: ["4T"],
			nastyplot: ["9M", "8M"],
			naturalgift: ["4M"],
			nightslash: ["9L38", "8L38", "7L31", "6L41", "5L41", "4L31"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			playrough: ["9M", "8M"],
			poisongas: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			poisontail: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			screech: ["9L24", "8M", "8L24", "7L1", "6L7", "5L7", "4L7"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["7L25", "6L22", "5L22", "4L22"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			smokescreen: ["9L1", "8L1", "7L13", "6L14", "5L14", "4L14"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["9L30", "8L30", "7L39", "4T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			tailslap: ["8M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			throatchop: ["8M", "7T"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["9L27", "8L27", "7M", "7L27", "6M", "6L27", "5M", "5L27", "4M", "4L20"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			venomdrench: ["8M", "8L48", "7L37"],
			venoshock: ["9M", "9L21", "8M", "8L21", "7M", "6M", "5M"],
		},
		encounters: [
			{generation: 4, level: 29},
		],
	},
	bronzor: {
		learnset: {
			allyswitch: ["8M", "7T"],
			ancientpower: ["4T"],
			bodyslam: ["9M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L4", "8L4", "7L11", "6L11", "5L11", "4L14"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			expandingforce: ["8T"],
			extrasensory: ["9L28", "8L28", "7L39", "6L39", "5L19", "4L19"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feintattack: ["7L21", "6L21", "5L21", "4L41"],
			flash: ["6M", "5M", "4M"],
			flashcannon: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["9L44", "8M", "8L44", "7L29", "6L29", "5L29", "4L37"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["9E", "7T", "6T", "5T", "5D", "4T"],
			guardswap: ["8M"],
			gyroball: ["9L16", "8M", "8L16", "7M", "7L35", "6M", "6L35", "5M", "5L35", "4M", "4L35"],
			healblock: ["7L45", "6L45", "5L45", "4L52"],
			heavyslam: ["9M", "9L32", "8M", "8L32", "7L49", "6L49", "5L49"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hypnosis: ["9L20", "8L20", "7L5", "6L5", "5L5", "5D", "4L7"],
			icespinner: ["9M"],
			imprison: ["9M", "9L12", "8M", "8L12", "7L9", "6L9", "5L9", "4L12"],
			irondefense: ["9M", "9L36", "8M", "8L36", "7T", "7L19", "6T", "6L19", "5T", "5L19", "4L26"],
			ironhead: ["9M"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			metalsound: ["9L40", "8L40", "7L31", "6L31", "5L31"],
			naturalgift: ["4M"],
			payback: ["9L8", "8M", "8L8", "7M", "7L41", "6M", "6L41", "5M", "5L49", "4M", "4L49"],
			powergem: ["9M"],
			powerswap: ["8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychicterrain: ["9M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			psywave: ["7L15", "6L15", "5L15"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["9E", "7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockblast: ["9M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["9L24", "8M", "8L24", "7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L30"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "5D", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			speedswap: ["8M"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["9M", "8T"],
			steelroller: ["8T"],
			stompingtantrum: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M"],
		},
	},
	bronzong: {
		learnset: {
			allyswitch: ["8M", "7T"],
			ancientpower: ["4T"],
			block: ["9L0", "8L0", "7T", "7L1", "6T", "6L33", "5T", "5L33", "4T", "4L33"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L1", "8L1", "7L11", "6L11", "5L11", "4L14"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			expandingforce: ["8T"],
			explosion: ["7M", "6M", "5M", "4M"],
			extrasensory: ["9L28", "8L28", "7L42", "6L42", "5L19", "4L19"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feintattack: ["7L21", "6L21", "5L21", "4L50"],
			flash: ["6M", "5M", "4M"],
			flashcannon: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["9L50", "8M", "8L50", "7L29", "6L29", "5L29", "4L43"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["8M"],
			gyroball: ["9L16", "8M", "8L16", "7M", "7L36", "6M", "6L36", "5M", "5L36", "4M", "4L38"],
			healblock: ["7L52", "6L52", "5L52", "4L67"],
			heavyslam: ["9M", "9L32", "8M", "8L32", "7L58", "6L58", "5L58"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypnosis: ["9L20", "8L20", "7L1", "6L1", "5L1", "4L1"],
			icespinner: ["9M"],
			imprison: ["9M", "9L12", "8M", "8L12", "7L1", "6L1", "5L1", "4L1"],
			irondefense: ["9M", "9L38", "8M", "8L38", "7T", "7L19", "6T", "6L19", "5T", "5L19", "4L26"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			metalsound: ["9L44", "8L44", "7L31", "6L31", "5L31"],
			meteorbeam: ["8T"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			payback: ["9L1", "8M", "8L1", "7M", "7L46", "6M", "6L46", "5M", "5L46", "4M", "4L61"],
			powergem: ["9M"],
			powerswap: ["8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychicterrain: ["9M", "8M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			psywave: ["7L15", "6L15", "5L15"],
			raindance: ["9M", "9L56", "8M", "8L56", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockblast: ["9M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rollout: ["4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["9L24", "8M", "8L24", "7M", "7L25", "6M", "6L25", "5M", "5L25", "4M", "4L30"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			speedswap: ["8M"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["9M", "8T"],
			steelroller: ["8T"],
			stompingtantrum: ["9M"],
			storedpower: ["9M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			swagger: ["7M", "6M", "5M", "4M"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			weatherball: ["9L1", "8M", "8L1"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	chatot: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["7E", "6E", "5E", "4E"],
			aircutter: ["7E", "6E", "5E", "4T"],
			attract: ["7M", "6M", "5M", "4M"],
			boomburst: ["7E", "6E"],
			captivate: ["4M"],
			chatter: ["7L1", "6L1", "5L21", "4L21", "4S0"],
			confide: ["7M", "7L1", "6M", "6L1"],
			defog: ["7T", "7E", "6E", "5E", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "7L37", "6M", "6L37", "5M", "5L37"],
			encore: ["7E", "6E", "5E", "4E"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			featherdance: ["7L50", "6L50", "5L53", "4L41"],
			fly: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furyattack: ["7L17", "6L17", "5L17", "4L17", "4S0"],
			growl: ["7L5", "6L5", "5L5", "4L5"],
			heatwave: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hypervoice: ["7T", "7L1", "6T", "6L1", "5T", "5L57", "4L45"],
			mimic: ["7L33", "6L33", "5L33", "4L29"],
			mirrormove: ["7L9", "6L9", "5L9", "5D", "4L9", "4S0"],
			mudslap: ["4T"],
			nastyplot: ["7E", "6E", "5E", "5D", "4E"],
			naturalgift: ["4M"],
			nightshade: ["7E", "6E", "5E", "4E"],
			ominouswind: ["4T"],
			peck: ["7L1", "6L1", "5L1", "4L1"],
			pluck: ["5M", "4M"],
			protect: ["7M", "6M", "5M", "4M"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "5D", "4T"],
			roost: ["7M", "7L41", "6M", "6L41", "5T", "5L41", "4M", "4L33"],
			round: ["7M", "7L29", "6M", "6L29", "5M", "5L29"],
			secretpower: ["6M", "4M"],
			sing: ["7L13", "6L13", "5L13", "4L13"],
			skyattack: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "7E", "6M", "6E", "5T", "5E", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			steelwing: ["7M", "7E", "6M", "6E", "5E", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			supersonic: ["7E", "6E", "5E", "4E"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			synchronoise: ["7L49", "6L49", "5L49"],
			tailwind: ["7T", "6T", "5T", "4T"],
			taunt: ["7M", "7L1", "6M", "6L1", "5M", "5L25", "4M", "4L25", "4S0"],
			thief: ["7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uproar: ["7T", "7L45", "6T", "6L45", "5T", "5L45", "4T", "4L37"],
			uturn: ["7M", "6M", "5M", "4M"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 4, level: 25, gender: "M", nature: "Jolly", abilities: ["keeneye"], moves: ["mirrormove", "furyattack", "chatter", "taunt"]},
		],
	},
	spiritomb: {
		learnset: {
			allyswitch: ["9E", "8M", "7T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bodyslam: ["9M"],
			burningjealousy: ["8T"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			captivate: ["7E", "6E", "5E", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			curse: ["9L40", "8L40", "7L1", "6L1", "5L1", "4L1"],
			darkpulse: ["9M", "9L50", "8M", "8L50", "7M", "7L49", "6M", "6L49", "5T", "5L49", "5S0", "4M", "4L49"],
			destinybond: ["9E", "8E", "7E", "6E", "5E", "4E"],
			disable: ["9E", "8E", "7E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dreameater: ["9L60", "8L60", "7M", "7L19", "6M", "6L19", "5M", "5L19", "4M", "4L19"],
			embargo: ["7M", "6M", "5M", "5S0", "4M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feintattack: ["7L7", "6L7", "5L7", "4L7"],
			flash: ["6M", "5M", "4M"],
			foulplay: ["9M", "8M", "7T", "7E", "6T", "6E", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grudge: ["8E", "7E", "6E", "5E", "4E"],
			helpinghand: ["9M"],
			hex: ["9M", "9L25", "8M", "8L25"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypnosis: ["9L55", "8L55", "7L13", "6L13", "5L13", "4L13"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "5D", "4T"],
			imprison: ["9M", "8M", "7E", "6E", "5E", "4E"],
			infestation: ["7M", "6M"],
			lashout: ["8T"],
			memento: ["9L30", "8L30", "7L43", "6L43", "5L43", "4L43"],
			nastyplot: ["9M", "9L20", "8M", "8L20", "7L37", "6L37", "5L37", "4L37"],
			naturalgift: ["4M"],
			nightmare: ["7E", "6E", "5E"],
			nightshade: ["9M", "9L1", "8L1"],
			ominouswind: ["7L25", "6L25", "5L25", "4T", "4L25"],
			painsplit: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			payback: ["9L15", "8M", "8L15"],
			phantomforce: ["9M", "8M"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "5S0", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M"],
			pursuit: ["7L1", "6L1", "5L1", "4L1"],
			quash: ["7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "9L45", "8M", "8L45", "7M", "6M", "5M", "4M"],
			shadowsneak: ["9L5", "8L5", "7L1", "7E", "6L1", "6E", "5L1", "5E", "4L1", "4E"],
			shockwave: ["7T", "6T", "4M"],
			silverwind: ["5S0", "4M"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			smokescreen: ["9E", "8E", "7E", "6E", "5E", "4E"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["9L10", "8L10", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5D", "4T", "4L1"],
			storedpower: ["9M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["9L35", "8L35", "7L31", "6L31", "5L31", "4T", "4L31"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			waterpulse: ["7T", "6T", "4M"],
			willowisp: ["9M", "8M", "7M", "6M", "5M", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 61, gender: "F", nature: "Quiet", ivs: {hp: 30, atk: 30, def: 30, spa: 30, spd: 30, spe: 30}, moves: ["darkpulse", "psychic", "silverwind", "embargo"], pokeball: "cherishball"},
		],
	},
	gible: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["9L25", "8L25"],
			bodyslam: ["9M", "8M", "7E", "6E", "5E", "4E"],
			bulldoze: ["9M", "9L18", "8M", "8L18", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			dig: ["9M", "9L42", "8M", "8L42", "7L31", "6M", "6L31", "5M", "5L31", "4M", "4L31"],
			doubleedge: ["9E", "8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["9L12", "8L12", "7E", "6E", "5E", "4E"],
			dragonclaw: ["9M", "9L36", "8M", "8L36", "7M", "7L27", "6M", "6L27", "5M", "5L27", "4M", "4L27"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dragonrage: ["7L7", "6L7", "5L7", "5D", "4L7"],
			dragonrush: ["9L60", "8L60", "7L37", "6L37", "5L37", "4L37"],
			dragontail: ["9M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "5D", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			falseswipe: ["9M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firefang: ["9M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			irontail: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			metalclaw: ["9M", "9E", "8E", "7E", "6E", "5E", "4E"],
			mudshot: ["9M", "8M", "7E", "6E", "5E", "4E"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D", "4T", "4E"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["7E", "6E", "5E", "4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L6", "8L6", "7L3", "6L3", "5L3", "4L3"],
			sandstorm: ["9M", "9L48", "8M", "8L48", "7M", "7L13", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			sandtomb: ["9L1", "8M", "8L1", "7L19", "7E", "6L19", "6E", "5L19", "5E", "4L19", "4E"],
			scaleshot: ["8T"],
			scaryface: ["9M", "8M", "7E", "6E", "5E", "4E"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["9L30", "8L30", "7L25", "6L25", "5L25", "4L25"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			swordsdance: ["9M"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M", "9L54", "8L54", "7L15", "6L15", "5L15", "4L15"],
			terablast: ["9M"],
			thrash: ["9E", "8E", "7E", "6E", "5E", "4E"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["9E", "8E", "7E", "6E", "5E", "4T", "4E"],
		},
	},
	gabite: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["9L27", "8L27"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "9L18", "8M", "8L18", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["9M"],
			cut: ["6M", "5M", "4M"],
			dig: ["9M", "9L50", "8M", "8L50", "7L40", "6M", "6L40", "5M", "5L40", "4M", "4L40"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T", "4T"],
			dragonbreath: ["9L1", "8L1"],
			dragonclaw: ["9M", "9L42", "8M", "8L42", "7M", "7L33", "6M", "6L33", "5M", "5L33", "4M", "4L33"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dragonrage: ["7L1", "6L7", "5L7", "4L7"],
			dragonrush: ["9L74", "8L74", "7L49", "6L49", "5L49", "4L49"],
			dragontail: ["9M"],
			dualchop: ["8L1", "7T", "7L1", "6T", "6L24", "5T", "5L24"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			falseswipe: ["9M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firefang: ["9M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			laserfocus: ["7T"],
			metalclaw: ["9M"],
			mudshot: ["9M", "8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "7T", "6T", "5T", "4T"],
			powergem: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			sandstorm: ["9M", "9L58", "8M", "8L58", "7M", "7L13", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			sandtomb: ["9L1", "8M", "8L1", "7L19", "6L19", "5L19", "4L19"],
			scaleshot: ["8T"],
			scaryface: ["9M", "8M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["9L34", "8L34", "7L28", "6L28", "5L28", "4L28"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			swordsdance: ["9M"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M", "9L66", "8L66", "7L15", "6L15", "5L15", "4L15"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
		},
	},
	garchomp: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["9L27", "8L27"],
			bodyslam: ["9M", "8M"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "6S4", "5M", "4M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "9L18", "8M", "8L18", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L0", "8M", "8L0", "7L1", "6L48", "6S2", "6S3", "5L48", "5S1", "4L48"],
			cut: ["6M", "5M", "4M"],
			dig: ["9M", "9L52", "8M", "8L52", "7L40", "6M", "6L40", "6S2", "6S3", "5M", "5L40", "5S1", "4M", "4L40"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dracometeor: ["9M", "8T", "7T", "6T", "6S2", "5T", "4T"],
			dragonbreath: ["9L1", "8L1"],
			dragonclaw: ["9M", "9L42", "8M", "8L42", "7M", "7L33", "6M", "6L33", "6S2", "6S3", "5M", "5L33", "5S1", "4M", "4L33"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dragonrage: ["7L1", "6L1", "5L1", "4L1"],
			dragonrush: ["9L82", "8L82", "7L55", "6L55", "6S4", "5L55", "4L55"],
			dragontail: ["9M", "7M", "6M", "5M"],
			dualchop: ["8L1", "7T", "7L1", "6T", "6L24", "5T", "5L24"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "8M", "7M", "6M", "6S4", "5M", "5S0", "4M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			falseswipe: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firefang: ["9M", "8M", "7L1", "6L1", "5L1", "4L1"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "6S4", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			laserfocus: ["7T"],
			liquidation: ["9M"],
			metalclaw: ["9M"],
			mudshot: ["9M", "8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "7T", "6T", "5T", "5S0", "5S1", "4T"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			powergem: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			sandstorm: ["9M", "9L62", "8M", "8L62", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			sandtomb: ["9L1", "8M", "8L1", "7L19", "6L19", "5L19", "4L19"],
			scaleshot: ["8T"],
			scaryface: ["9M", "8M"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			slash: ["9L34", "8L34", "7L28", "6L28", "6S3", "5L28", "4L28"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spikes: ["9M"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "5S0", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M", "5S0", "4M"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M", "9L72", "8L72", "7L15", "6L15", "5L15", "4L15"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 5, level: 100, gender: "M", moves: ["outrage", "earthquake", "swordsdance", "stoneedge"], pokeball: "cherishball"},
			{generation: 5, level: 48, gender: "M", isHidden: true, moves: ["dragonclaw", "dig", "crunch", "outrage"]},
			{generation: 6, level: 48, gender: "M", moves: ["dracometeor", "dragonclaw", "dig", "crunch"], pokeball: "cherishball"},
			{generation: 6, level: 50, gender: "M", moves: ["slash", "dragonclaw", "dig", "crunch"], pokeball: "cherishball"},
			{generation: 6, level: 66, gender: "F", perfectIVs: 3, moves: ["dragonrush", "earthquake", "brickbreak", "gigaimpact"], pokeball: "cherishball"},
		],
	},
	riolu: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M", "8M", "7E", "6E", "5E", "4E"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			aurasphere: ["4S0"],
			bite: ["9E", "8E", "7E", "6E", "5E", "4E"],
			blazekick: ["8M", "7E", "6E", "5E", "4E"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			bulletpunch: ["9E", "8E", "7E", "6E", "5E", "5D", "4E", "4S0"],
			captivate: ["4M"],
			circlethrow: ["9E", "8E", "7E", "6E", "5E"],
			closecombat: ["9M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			copycat: ["9L48", "8L48", "7L19", "6L19", "5L29", "4L29"],
			counter: ["9L12", "8L12", "7L6", "6L6", "5L6", "4L6"],
			crosschop: ["9E", "8E", "7E", "6E", "5E", "4E"],
			crunch: ["9M", "8M", "7E", "6E", "5E", "4E"],
			detect: ["9E", "8E", "7E", "6E", "5E", "4E"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M", "4S0"],
			dualchop: ["7T", "6T", "5T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "5D", "4M", "4L1"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feint: ["9L4", "8L4", "7L11", "6L11", "5L15", "4L15"],
			finalgambit: ["9L52", "8L52", "7L50", "6L50", "5L55"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "5D", "4M"],
			followme: ["7E", "6E", "5E", "4E"],
			forcepalm: ["9L36", "8L36", "7L15", "6L15", "5L11", "4L11"],
			foresight: ["7L1", "6L1", "5L1", "4L1"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			headbutt: ["4T"],
			helpinghand: ["9M", "9L44", "8M", "8L44", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			highjumpkick: ["9E", "8E", "7E", "6E", "5E", "4E"],
			howl: ["9E", "8E"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irondefense: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			laserfocus: ["7T"],
			lowkick: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metalclaw: ["9M", "9L8", "8L8"],
			meteormash: ["7E"],
			mindreader: ["8E", "7E", "6E", "5E", "4E"],
			mudslap: ["4T"],
			nastyplot: ["9M", "8M", "8L24", "7L47", "6L47", "5L47"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			quickattack: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			quickguard: ["9L32", "8L32"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M"],
			reversal: ["9M", "9L56", "8M", "8L56", "7L29", "6L29", "5L19", "4L19"],
			roar: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["9L20", "8L20", "6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["9L28", "8M", "8L28", "7L24", "6L24", "5L24", "4L24"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M", "4S0"],
			skyuppercut: ["7E", "6E", "5E", "4E"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			swordsdance: ["9M", "9L40", "8M", "8L40", "7M", "6M", "5M", "4M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			vacuumwave: ["9L24", "8E", "7E", "6E", "5E", "4T", "4E"],
			workup: ["9L16", "8M", "8L16", "7M", "5M"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 30, gender: "M", nature: "Serious", abilities: ["steadfast"], moves: ["aurasphere", "shadowclaw", "bulletpunch", "drainpunch"], pokeball: "pokeball"},
		],
	},
	lucario: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M", "8M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			aurasphere: ["9M", "9L0", "8M", "8L0", "8S6", "7L1", "7S5", "6L1", "6S4", "5L51", "4L37", "4S0"],
			blazekick: ["8M", "4S1"],
			bodyslam: ["9M"],
			bonerush: ["9L36", "8L36", "7L29", "6L29", "5L19", "4L19", "4S1"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			bulletpunch: ["8S6", "5S2", "5S3"],
			calmmind: ["9M", "9L24", "8M", "8L24", "7M", "7L47", "6M", "6L47", "5M", "5L47", "4M"],
			captivate: ["4M"],
			closecombat: ["9M", "9L60", "8M", "8L60", "7L55", "6L1", "6S4", "5L55", "5S3", "4L42"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			counter: ["9L12", "8L12", "7L6", "6L6", "5L6", "5S2", "4L6"],
			crunch: ["9M", "8M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "5L1", "4M", "4L1", "4S0"],
			detect: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S2", "4L1"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dragonpulse: ["9M", "9L52", "8M", "8L52", "7T", "7L60", "7S5", "6T", "6L1", "5T", "5L60", "4M", "4L47", "4S0"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dualchop: ["7T", "6T", "5T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			extremespeed: ["9L56", "8L56", "7L65", "7S5", "6L1", "5L65", "4L51"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feint: ["9L1", "8L1", "7L11", "6L11", "5L15", "4L15"],
			finalgambit: ["9L1", "8L1"],
			flashcannon: ["9M", "8M", "7M", "6M", "6S4", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T", "4M"],
			forcepalm: ["9L20", "8L1", "5L11", "4L11", "4S1"],
			foresight: ["7L1", "6L1", "5L1", "4L1"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			healpulse: ["9L44", "8L44", "7L51", "6L51", "5L42"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			highjumpkick: ["7S5"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			laserfocus: ["8L16", "7T", "7L1"],
			lifedew: ["9L1", "8L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			mefirst: ["7L37", "6L37", "5L29", "4L29"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metalclaw: ["9M", "9L1", "8L1", "7L1", "6L1", "5L1", "5S2", "4L1"],
			metalsound: ["9L28", "8L28", "7L24", "6L24", "5L24", "4L24"],
			meteormash: ["9L48", "8L48"],
			metronome: ["9M"],
			mudslap: ["4T"],
			nastyplot: ["9M", "8M", "8L1"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M", "4M"],
			poweruppunch: ["8L20", "7L15", "6M", "6L15"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M"],
			quickattack: ["9L1", "8L1", "7L1", "6L1", "6S4", "5L1", "4L1"],
			quickguard: ["9L32", "8L32", "7L33", "6L33", "5L33"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M"],
			reversal: ["9M", "9L1", "8M", "8L1", "8S6"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["9L1", "8L1", "6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M"],
			screech: ["9L1", "8M", "8L1"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "5S3", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			steelbeam: ["9M", "8T", "8S6"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "5S3", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M", "4S1"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			swordsdance: ["9M", "9L40", "8M", "8L40", "7M", "7L19", "6M", "6L19", "5M", "5L37", "4M", "4L33"],
			takedown: ["9M"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			vacuumwave: ["9L1", "4T"],
			waterpulse: ["9M", "7T", "6T", "4M", "4S0"],
			workup: ["9L16", "8M", "8L1", "7M", "7L42", "5M"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 50, gender: "M", nature: "Modest", abilities: ["steadfast"], moves: ["aurasphere", "darkpulse", "dragonpulse", "waterpulse"], pokeball: "cherishball"},
			{generation: 4, level: 30, gender: "M", nature: "Adamant", abilities: ["innerfocus"], moves: ["forcepalm", "bonerush", "sunnyday", "blazekick"], pokeball: "cherishball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["detect", "metalclaw", "counter", "bulletpunch"]},
			{generation: 5, level: 50, gender: "M", nature: "Naughty", ivs: {atk: 31}, isHidden: true, moves: ["bulletpunch", "closecombat", "stoneedge", "shadowclaw"], pokeball: "cherishball"},
			{generation: 6, level: 100, nature: "Jolly", abilities: ["innerfocus"], moves: ["closecombat", "aurasphere", "flashcannon", "quickattack"], pokeball: "cherishball"},
			{generation: 7, level: 40, gender: "M", nature: "Serious", abilities: ["steadfast"], moves: ["aurasphere", "highjumpkick", "dragonpulse", "extremespeed"], pokeball: "pokeball"},
			{generation: 8, level: 80, gender: "M", nature: "Serious", abilities: ["innerfocus"], ivs: {hp: 31, atk: 30, def: 30, spa: 31, spd: 30, spe: 31}, moves: ["aurasphere", "bulletpunch", "reversal", "steelbeam"], pokeball: "pokeball"},
		],
	},
	hippopotas: {
		learnset: {
			amnesia: ["9M", "8M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["9L4", "8L4", "7L7", "6L7", "5L7", "5D", "4L7"],
			bodypress: ["9M"],
			bodyslam: ["9M", "8M", "7E", "6E", "5E", "4E"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L20", "8M", "8L20", "7L31", "6L31", "5L31", "4L31"],
			curse: ["9E", "8E", "7E", "6E", "5E", "4E"],
			dig: ["9M", "9L16", "8M", "8L16", "7L19", "6M", "6L19", "5M", "5L19", "4M", "4L19"],
			doubleedge: ["9L44", "8L44", "7L44", "6L44", "5L44", "4L44"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L40", "8M", "8L40", "7M", "7L37", "6M", "6L37", "5M", "5L37", "4M", "4L37"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firefang: ["9M"],
			fissure: ["9L48", "8L48", "7L50", "6L50", "5L50", "4L50"],
			frustration: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			highhorsepower: ["8M"],
			icefang: ["9M"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "9L36", "8M", "8L36", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M", "7E", "6E", "5E", "4E"],
			roar: ["9L32", "8L32", "7M", "6M", "5M", "4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			sandstorm: ["9M", "9L24", "8M", "8L24", "7M", "6M", "5M", "4M"],
			sandtomb: ["9L12", "8M", "8L12", "7L25", "7E", "6L25", "6E", "5L25", "5E", "4L25", "4E"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M"],
			slackoff: ["9L52", "8L52", "7E", "6E", "5E", "4E"],
			sleeptalk: ["9M", "8M", "7M", "7E", "6M", "6E", "5T", "5E", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spitup: ["9E", "8E", "7E", "6E", "5E", "4E"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stockpile: ["9E", "8E", "7E", "6E", "5E", "5D", "4E"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			superpower: ["8M", "7T", "6T", "5T", "5D", "4T"],
			swagger: ["7M", "6M", "5M", "4M"],
			swallow: ["9E", "8E", "7E", "6E", "5E", "4E"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M", "9L28", "8L28", "7L19", "6L19", "5L19", "4L19"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M"],
			weatherball: ["8M"],
			whirlwind: ["9E", "8E", "7E", "6E", "5E"],
			yawn: ["9L8", "8L8", "7L13", "6L13", "5L13", "4L13"],
		},
	},
	hippowdon: {
		learnset: {
			amnesia: ["9M", "8M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L20", "8M", "8L20", "7L31", "6L31", "5L31", "4L31"],
			dig: ["9M", "9L16", "8M", "8L16", "7L19", "6M", "6L19", "5M", "5L19", "4M", "4L19"],
			doubleedge: ["9L50", "8L50", "7L50", "6L50", "5L50", "4L50"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T", "4T"],
			earthquake: ["9M", "9L44", "8M", "8L44", "7M", "7L40", "6M", "6L40", "5M", "5L40", "4M", "4L40"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firefang: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			fissure: ["9L56", "8L56", "7L60", "6L60", "5L60", "4L60"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			highhorsepower: ["8M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M"],
			icefang: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "9L38", "8M", "8L38", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M"],
			roar: ["9L32", "8L32", "7M", "6M", "5M", "4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			sandstorm: ["9M", "9L24", "8M", "8L24", "7M", "6M", "5M", "4M"],
			sandtomb: ["9L12", "8M", "8L12", "7L25", "6L25", "5L25", "4L25"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M"],
			slackoff: ["9L62", "8L62"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			superpower: ["8M", "7T", "6T", "5T", "4T"],
			swagger: ["7M", "6M", "5M", "4M"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			takedown: ["9M", "9L28", "8L28", "7L19", "6L19", "5L19", "4L19"],
			terablast: ["9M"],
			thunderfang: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			toxic: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "6T", "4M"],
			weatherball: ["8M"],
			yawn: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
		},
	},
	skorupi: {
		learnset: {
			acupressure: ["8L45", "7L13", "6L13", "5L17", "4L17"],
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["8M", "7E", "6E", "5E", "5D", "4E"],
			aquatail: ["7T", "6T", "5T", "5D", "4T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["8L12", "7L1", "6L1", "5L1", "5D", "4L1"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			bugbite: ["8L18", "7T", "7L20", "6T", "6L20", "5T", "5L34", "4T", "4L34"],
			bugbuzz: ["8M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E", "4E"],
			crosspoison: ["8M", "8L39", "7L49", "6L49", "5L61", "4L50"],
			crunch: ["8M", "8L48", "7L45", "6L45", "5L56", "4L45"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			dig: ["8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			feintattack: ["7E", "6E", "5E", "4E"],
			fellstinger: ["8L6", "7L47", "6L47"],
			flash: ["6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["8L3", "7L30", "6M", "6L30", "5M", "5L45"],
			infestation: ["7M", "6M"],
			irontail: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			knockoff: ["8L24", "7T", "7L5", "6T", "6L5", "5T", "5L6", "4T", "4L6"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightslash: ["8L36", "7L38", "7E", "6L38", "6E", "5L38", "5E", "4E"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pinmissile: ["8M", "8L30", "7L9", "6L9", "5L12", "4L12"],
			poisonfang: ["8L9", "7L23", "6L23", "5L39", "4L39"],
			poisonjab: ["8M", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			poisontail: ["7E", "6E", "5E"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			pursuit: ["7L16", "7E", "6L16", "6E", "5L16", "5E", "4E"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8E", "7E", "6E", "5E", "4E"],
			scaryface: ["8M", "8L27", "7L41", "6L41", "5L23", "4L23"],
			screech: ["8M", "7E", "6E", "5E", "4E"],
			secretpower: ["6M", "4M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			skittersmack: ["8T"],
			slash: ["8E", "7E", "6E", "5E", "4E"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			taunt: ["8M", "7M", "6M", "5M", "4M"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["8L33", "7M", "6M", "5M", "4M"],
			toxicspikes: ["8M", "8L15", "7L34", "6L34", "5L28", "4L28"],
			twineedle: ["7E", "6E", "5E"],
			venoshock: ["8M", "8L21", "7M", "7L27", "6M", "6L27", "5M", "5L50"],
			whirlwind: ["8E", "7E", "6E", "5E", "4E"],
			xscissor: ["8M", "8L42", "7M", "6M", "5M", "4M"],
		},
	},
	drapion: {
		learnset: {
			acupressure: ["8L49", "7L13", "6L13", "5L17", "4L17"],
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["8M"],
			aquatail: ["7T", "6T", "5T", "4T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bite: ["8L12", "7L1", "6L1", "5L1", "4L1"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			brutalswing: ["8M", "7M"],
			bugbite: ["8L18", "7T", "7L20", "6T", "6L20", "5T", "5L34", "4T", "4L34"],
			bugbuzz: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crosspoison: ["8M", "8L39", "7L57", "6L57", "5L73", "4L58"],
			crunch: ["8M", "8L54", "7L49", "6L49", "5L65", "4L49"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			dig: ["8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthquake: ["8M", "7M", "6M", "5M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			falseswipe: ["8M", "7M", "6M", "5M", "4M"],
			fellstinger: ["8L1", "7L53", "6L53"],
			firefang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			flash: ["6M", "5M", "4M"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["8L1", "7L30", "6M", "6L30", "5M", "5L48"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			icefang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			infestation: ["7M", "6M"],
			irondefense: ["8M"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			knockoff: ["8L24", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			lashout: ["8T"],
			leechlife: ["8M"],
			leer: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			nightslash: ["8L36", "7L38", "6L38", "5L38"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pinmissile: ["8M", "8L30", "7L9", "6L9", "5L12", "4L1"],
			poisonfang: ["8L9", "7L23", "6L23", "5L39", "4L39"],
			poisonjab: ["8M", "7M", "6M", "5M", "4M"],
			poisonsting: ["8L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["8M", "7M", "6M", "5M", "4M"],
			pursuit: ["7L16", "6L16", "5L16"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandtomb: ["8M"],
			scaryface: ["8M", "8L27", "7L43", "6L43", "5L23", "4L23"],
			screech: ["8M"],
			secretpower: ["6M", "4M"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["8M", "7M", "6M", "5M", "4M"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M", "4M"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swordsdance: ["8M", "7M", "6M", "5M", "4M"],
			taunt: ["8M", "7M", "6M", "5M", "4M"],
			thief: ["8M", "7M", "6M", "5M", "4M"],
			throatchop: ["8M", "7T"],
			thunderfang: ["8M", "8L1", "7L1", "6L1", "5L1", "4L1"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["8L33", "7M", "6M", "5M", "4M"],
			toxicspikes: ["8M", "8L15", "7L34", "6L34", "5L28", "4L28"],
			venomdrench: ["8M"],
			venoshock: ["8M", "8L21", "7M", "7L27", "6M", "6L27", "5M", "5L56"],
			xscissor: ["8M", "8L44", "7M", "6M", "5M", "4M"],
		},
		encounters: [
			{generation: 4, level: 22, pokeball: "safariball"},
			{generation: 6, level: 30},
		],
	},
	croagunk: {
		learnset: {
			acidspray: ["9M"],
			acupressure: ["7E", "6E", "5E"],
			aerialace: ["9M"],
			assurance: ["8M"],
			astonish: ["9L4", "8L4", "7L1", "6L1", "5L1", "5S0", "4L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			batonpass: ["9M", "8M"],
			belch: ["9L48", "8L48", "7L47", "6L47"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			bulletpunch: ["9E", "8E", "7E", "6E", "5E", "4E"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "6E", "5E", "4E"],
			crosschop: ["9E", "8E", "7E", "6E", "5E", "4E"],
			darkpulse: ["8M", "7M", "6M", "5T", "4M"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainpunch: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			dualchop: ["7T", "6T", "5T"],
			dynamicpunch: ["9E", "8E", "7E", "6E", "5E", "4E"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fakeout: ["9E", "8E", "7E", "6E", "5E", "4E"],
			feint: ["9E", "8E", "7E", "6E", "5E", "4E"],
			feintattack: ["7L17", "6L17", "5L17", "4L17"],
			flatter: ["9L12", "8L12", "7L50", "6L50", "5L50", "4L45"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gunkshot: ["9M", "8M", "7T", "6T", "5T", "5D", "4T"],
			headbutt: ["9E", "8E", "7E", "6E", "5E", "4T", "4E"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lashout: ["8T"],
			lowkick: ["9M", "9L16", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			meditate: ["7E", "6E", "5E", "4E"],
			mefirst: ["7E", "6E", "5E", "4E"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudbomb: ["7L29", "6L29", "5L29", "4L29"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L1", "8L1", "7L3", "6L3", "5L3", "5S0", "5S1", "4T", "4L3"],
			nastyplot: ["9M", "9L40", "8M", "8L40", "7L38", "6L38", "5L38", "4L36"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["9M", "9L32", "8M", "8L32", "7M", "7L43", "6M", "6L43", "5M", "5L43", "5S1", "4M", "4L38"],
			poisonsting: ["9L1", "8L1", "7L8", "6L8", "5L8", "5D", "5S0", "5S1", "4L8"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			pursuit: ["7L15", "6L15", "5L15", "4L15"],
			quickguard: ["9E", "8E", "7E", "6E"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M", "8L16", "7L22", "6L22", "5L22", "4L22"],
			reversal: ["9M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["8M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["9M", "9L44", "8M", "8L44", "7M", "7L45", "6M", "6L45", "5M", "5L45", "4M", "4L43"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smellingsalts: ["7E", "6E", "5E", "4E"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["9L24", "8L24", "7L31", "6L31", "5L31", "4T", "4L31"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["9L28", "8L28", "7M", "7L24", "6M", "6L24", "5M", "5L24", "4M", "4L24"],
			takedown: ["9M"],
			taunt: ["9M", "9L8", "8M", "8L8", "7M", "7L10", "6M", "6L10", "5M", "5L10", "5S0", "5S1", "4M", "4L10"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["9L36", "8L36", "7M", "6M", "5M", "4M"],
			vacuumwave: ["9E", "8E", "7E", "6E", "5E", "4T", "4E"],
			venomdrench: ["8M"],
			venoshock: ["9M", "9L20", "8M", "8L20", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			wakeupslap: ["7E", "6E", "5E", "4E"],
			workup: ["8M", "7M", "5M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["astonish", "mudslap", "poisonsting", "taunt"]},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["mudslap", "poisonsting", "taunt", "poisonjab"]},
		],
	},
	toxicroak: {
		learnset: {
			acidspray: ["9M"],
			aerialace: ["9M"],
			assurance: ["8M"],
			astonish: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			batonpass: ["9M", "8M"],
			belch: ["9L54", "8L54", "7L58", "6L58"],
			bounce: ["8M", "7T", "6T", "5T", "4T"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			closecombat: ["9M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			crosspoison: ["8M"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainpunch: ["9M", "8M", "7T", "6T", "5T"],
			dualchop: ["7T", "6T", "5T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			embargo: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			feintattack: ["7L17", "6L17", "5L17", "4L17"],
			flatter: ["9L12", "8L12", "7L62", "6L62", "5L62", "4L54"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gunkshot: ["9M", "8M", "7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lashout: ["8T"],
			lowkick: ["9M", "9L16", "8M", "7T", "6T", "5T", "4T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudbomb: ["7L29", "6L29", "5L29", "4L29"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L1", "8L1", "7L1", "6L1", "5L1", "4T", "4L1"],
			nastyplot: ["9M", "9L42", "8M", "8L42", "7L41", "6L41", "5L41", "4L36"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			poisonjab: ["9M", "9L32", "8M", "8L32", "7M", "7L49", "6M", "6L49", "5M", "5L49", "4M", "4L41"],
			poisonsting: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			pursuit: ["7L15", "6L15", "5L15", "4L15"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M", "8L16", "7L22", "6L22", "5L22", "4L22"],
			reversal: ["9M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			screech: ["8M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["9M", "9L48", "8M", "8L48", "7M", "7L54", "6M", "6L54", "5M", "5L54", "4M", "4L49"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			suckerpunch: ["9L24", "8L24", "7L31", "6L31", "5L31", "4T", "4L31"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			superfang: ["7T", "6T", "5T", "4T"],
			swagger: ["9L28", "8L28", "7M", "7L24", "6M", "6L24", "5M", "5L24", "4M", "4L24"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			takedown: ["9M"],
			taunt: ["9M", "9L1", "8M", "8L1", "7M", "7L10", "6M", "6L10", "5M", "5L10", "4M", "4L10"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["9L36", "8L36", "7M", "6M", "5M", "4M"],
			vacuumwave: ["4T"],
			venomdrench: ["8M"],
			venoshock: ["9M", "9L20", "8M", "8L20", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			workup: ["8M", "7M", "5M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
		encounters: [
			{generation: 4, level: 22, pokeball: "safariball"},
			{generation: 6, level: 30},
		],
	},
	carnivine: {
		learnset: {
			acidspray: ["7E"],
			attract: ["7M", "6M", "5M", "4M"],
			bind: ["7T", "7L1", "6T", "6L1", "5T", "5L1", "4L1"],
			bite: ["7L7", "6L7", "5L7", "5D", "4L7"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bulletseed: ["4M"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["7L41", "6L41", "5L41", "4L37"],
			cut: ["6M", "5M", "4M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["4M"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			feintattack: ["7L27", "6L27", "5L27", "4L27"],
			flash: ["6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gastroacid: ["7T", "6T", "5T", "5D", "4T"],
			gigadrain: ["7T", "7E", "6T", "6E", "5T", "5E", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grasswhistle: ["7E", "6E", "5E"],
			growth: ["7L1", "6L1", "5L1", "4L1"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			infestation: ["7M", "6M"],
			ingrain: ["7L21", "6L21", "5L21", "4L21"],
			knockoff: ["7T", "6T", "5T", "4T"],
			leaftornado: ["7L31", "6L31", "5L31"],
			leechseed: ["7E", "6E", "5E", "4E"],
			magicalleaf: ["7E", "6E", "5E", "4E"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			payback: ["7M", "6M", "5M", "4M"],
			powerwhip: ["7L50", "6L50", "5L51", "4L47"],
			protect: ["7M", "6M", "5M", "4M"],
			ragepowder: ["7E", "6E", "5E", "5D"],
			razorleaf: ["7E", "6E", "5E", "4E"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			slam: ["7E", "6E", "5E", "4E"],
			sleeppowder: ["7E", "6E", "5E", "4E"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			sludgebomb: ["7M", "6M", "5M", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			spitup: ["7L37", "6L37", "5L37", "4L31"],
			stockpile: ["7L37", "6L37", "5L37", "4L31"],
			stunspore: ["7E", "6E", "5E", "4E"],
			substitute: ["7M", "6M", "5M", "4M"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swallow: ["7L37", "6L37", "5L37", "4L31"],
			sweetscent: ["7L17", "6L17", "5L17", "4L17"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			synthesis: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			thief: ["7M", "6M", "5M", "4M"],
			throatchop: ["7T"],
			toxic: ["7M", "6M", "5M", "4M"],
			vinewhip: ["7L11", "6L11", "5L11", "4L11"],
			worryseed: ["7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			wringout: ["7L47", "6L47", "5L47", "4L41"],
		},
	},
	finneon: {
		learnset: {
			acrobatics: ["9M"],
			agility: ["9M", "9E", "7E", "6E", "5E", "4E"],
			aircutter: ["4T"],
			aquaring: ["9L33", "7L33", "6L33", "5L33", "4L33"],
			aquatail: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			attract: ["9L26", "7M", "7L10", "6M", "6L10", "5M", "5L10", "5D", "4M", "4L10"],
			aurorabeam: ["9E", "7E", "6E", "5E"],
			blizzard: ["9M", "7M", "6M", "5M", "4M"],
			bounce: ["9L45", "7T", "7L45", "6T", "6L45", "5T", "5L45", "4T", "4L45"],
			brine: ["7E", "6E", "5E", "4M"],
			captivate: ["7L26", "6L26", "5L26", "4M", "4L26"],
			charm: ["9M", "9E", "7E", "6E", "5E", "4E"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9E", "7E"],
			dazzlinggleam: ["9M"],
			defog: ["7T", "4M"],
			dive: ["6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			flail: ["9E", "7E", "6E", "5E", "4E"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gust: ["9L17", "7L17", "6L17", "5L17", "4L17"],
			hail: ["7M", "6M", "5M", "4M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hydropump: ["9M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M"],
			icywind: ["9M", "7T", "6T", "5T", "4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			ominouswind: ["4T"],
			payback: ["7M", "6M", "5M", "4M"],
			pound: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M", "9E", "7E", "6E", "5E", "4E"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "9L13", "7M", "7L13", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["9L29", "7M", "7L29", "6M", "6L29", "5M", "5L29", "4M", "4L29"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			signalbeam: ["7T", "7E", "6T", "6E", "5T", "5E"],
			silverwind: ["7L49", "6L49", "5L49", "4M", "4L49"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			soak: ["9L54", "7L54", "6L54", "5L54"],
			splash: ["7E", "6E", "5E", "4E"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			sweetkiss: ["9E", "7E", "6E", "5E", "5D", "4E"],
			swift: ["9M", "4T"],
			tailwind: ["9M", "9L49", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			tickle: ["9E", "7E", "6E", "5E", "4E"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uturn: ["9M", "9L42", "7M", "7L42", "6M", "6L42", "5M", "5L42", "4M", "4L42"],
			waterfall: ["9M", "7M", "6M", "5M", "4M"],
			watergun: ["9L6", "7L6", "6L6", "5L6", "4L6"],
			waterpulse: ["9M", "9L22", "7T", "7L22", "6T", "6L22", "5L22", "5D", "4M", "4L22"],
			whirlpool: ["9L38", "7L38", "6L38", "5L38", "4M", "4L38"],
		},
	},
	lumineon: {
		learnset: {
			acrobatics: ["9M"],
			agility: ["9M"],
			aircutter: ["9M", "4T"],
			airslash: ["9M"],
			aquaring: ["9L35", "7L35", "6L35", "5L35", "4L35"],
			aquatail: ["7T", "6T", "5T", "4T"],
			attract: ["9L26", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			blizzard: ["9M", "7M", "6M", "5M", "4M"],
			bounce: ["9L53", "7T", "7L53", "6T", "6L53", "5T", "5L53", "4T", "4L53"],
			brine: ["4M"],
			captivate: ["7L26", "6L26", "5L26", "4M", "4L26"],
			charm: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			dazzlinggleam: ["9M"],
			defog: ["7T", "4M"],
			dive: ["6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			encore: ["9M"],
			endure: ["9M", "4M"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			gust: ["9L1", "7L1", "6L1", "5L17", "4L17"],
			hail: ["7M", "6M", "5M", "4M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hydropump: ["9M"],
			hyperbeam: ["9M", "7M", "6M", "5M", "4M"],
			icebeam: ["9M", "7M", "6M", "5M", "4M"],
			icywind: ["9M", "7T", "6T", "5T", "4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			payback: ["7M", "6M", "5M", "4M"],
			pound: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "9L13", "7M", "7L13", "6M", "6L13", "5M", "5L13", "4M", "4L13"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["9L29", "7M", "7L29", "6M", "6L29", "5M", "5L29", "4M", "4L29"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			signalbeam: ["7T", "6T", "5T"],
			silverwind: ["7L59", "6L59", "5L59", "4M", "4L59"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			soak: ["9L1", "7L1", "6L1", "5L66"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "4T"],
			tailwind: ["9M", "9L59", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			uturn: ["9M", "9L48", "7M", "7L48", "6M", "6L48", "5M", "5L48", "4M", "4L48"],
			waterfall: ["9M", "7M", "6M", "5M", "4M"],
			watergun: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			waterpulse: ["9M", "9L22", "7T", "7L22", "6T", "6L22", "5L22", "4M", "4L22"],
			whirlpool: ["9L42", "7L42", "6L42", "5L42", "4M", "4L42"],
		},
		encounters: [
			{generation: 4, level: 20},
		],
	},
	snover: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M"],
			avalanche: ["9M", "8M", "7E", "6E", "5E", "5D", "4M"],
			blizzard: ["9M", "9L45", "8M", "8L45", "7M", "7L41", "6M", "6L41", "5M", "5L41", "4M", "4L41"],
			bodyslam: ["9M"],
			bulletseed: ["9M", "8M", "7E", "6E", "5E", "4M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			doubleedge: ["9E", "8E", "7E", "6E", "5E", "4E"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["9M", "8M", "7T", "6T", "5T", "5D", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7L13", "6L13", "5L13", "4L13"],
			grassyglide: ["8T"],
			growth: ["9E", "8E", "7E", "6E", "5E", "4E"],
			hail: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			iceshard: ["9L15", "8L15", "7L26", "6L26", "5L26", "4L26"],
			icespinner: ["9M"],
			icywind: ["9M", "9L25", "8M", "8L25", "7T", "7L9", "6T", "6L9", "5T", "5L9", "4T", "4L9"],
			ingrain: ["9L35", "8L35", "7L31", "6L31", "5L31", "4L31"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			leafage: ["9L5", "8L5"],
			leafstorm: ["9M"],
			leechseed: ["9E", "8E", "7E", "6E", "5E", "4E"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M"],
			magicalleaf: ["9M", "8M", "7E", "6E", "5E", "4E"],
			megapunch: ["8M"],
			mist: ["9L10", "8L10", "7L21", "7E", "6L21", "6E", "5L21", "5E", "4L21", "4E"],
			mudslap: ["9M", "4T"],
			naturalgift: ["7E", "6E", "5E", "4M"],
			powdersnow: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			razorleaf: ["9L20", "8L20", "7L5", "6L5", "5L5", "5D", "4L5"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E", "4T", "4E"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			sheercold: ["9L50", "8L50", "7L46", "6L46", "5L46", "4L46"],
			skullbash: ["8E", "7E", "6E", "5E", "4E"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			stomp: ["9E", "8E", "7E", "6E", "5E", "4E"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["9L30", "8L30", "7M", "7L17", "6M", "6L17", "5M", "5L17", "4M", "4L17"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			weatherball: ["9E", "8M"],
			woodhammer: ["9L41", "8L41", "7L36", "6L36", "5L36", "4L36"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
	},
	abomasnow: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M", "4M"],
			auroraveil: ["9L1", "8L1"],
			avalanche: ["9M", "8M", "4M"],
			blizzard: ["9M", "9L49", "8M", "8L49", "7M", "7L47", "6M", "6L47", "5M", "5L47", "4M", "4L47"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			bulletseed: ["9M", "8M", "4M"],
			captivate: ["4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["9M", "8M", "7T", "6T", "5T", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grasswhistle: ["7L13", "6L13", "5L13", "4L13"],
			grassyglide: ["8T"],
			hail: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icepunch: ["9M", "9L0", "8M", "8L0", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			iceshard: ["9L15", "8L15", "7L26", "6L26", "5L26", "4L26"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L25", "8M", "8L25", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			ingrain: ["9L35", "8L35", "7L31", "6L31", "5L31", "4L31"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			leafage: ["9L1", "8L1"],
			leafstorm: ["9M", "8M"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			lightscreen: ["8M", "7M", "6M", "5M", "4M"],
			lowkick: ["9M"],
			magicalleaf: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mist: ["9L1", "8L1", "7L21", "6L21", "5L21", "4L21"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "7T", "6T", "5T", "4T"],
			powdersnow: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			razorleaf: ["9L20", "8L20", "7L1", "6L1", "5L1", "4L1"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T", "4T"],
			shadowball: ["8M", "7M", "6M", "5M", "4M"],
			sheercold: ["9L56", "8L56", "7L58", "6L58", "5L58", "4L58"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			stompingtantrum: ["9M", "8M", "7T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["9L30", "8L30", "7M", "7L17", "6M", "6L17", "5M", "5L17", "4M", "4L17"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			synthesis: ["7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			weatherball: ["8M"],
			woodhammer: ["9L43", "8L43", "7L36", "6L36", "5L36", "4L36"],
			worryseed: ["7T", "6T", "5T", "4T"],
		},
		encounters: [
			{generation: 4, level: 38},
		],
	},
	rotom: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["9L1", "8L1", "7L1", "6L1", "6S1", "5L1", "5S0", "4L1"],
			charge: ["9L15", "8L15", "7L1", "6L1", "5L57", "4L43"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "7S2", "6M"],
			confuseray: ["9M", "9L10", "8L10", "7L1", "6L1", "5L1", "4L1"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			defog: ["7T"],
			disarmingvoice: ["7S2"],
			discharge: ["9L50", "8L50", "7L1", "6L1", "5L64", "4L50"],
			doubleteam: ["9L1", "8L1", "7M", "7L15", "6M", "6L15", "5M", "5L15", "4M", "4L15"],
			dreameater: ["7M", "6M", "5M", "4M"],
			eerieimpulse: ["9M", "8M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "9L20", "8M", "8L20", "7L43", "6L43", "5L43"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			foulplay: ["9M", "8M", "7T", "6T"],
			frustration: ["7M", "6M", "5M", "4M"],
			helpinghand: ["9M", "8M"],
			hex: ["9M", "9L35", "8M", "8L35", "7L50", "6L50", "5L50"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "8M"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			mudslap: ["4T"],
			nastyplot: ["9M", "8M"],
			naturalgift: ["4M"],
			nightshade: ["9M"],
			ominouswind: ["7L29", "6L29", "5L29", "4T", "4L29"],
			painsplit: ["7T", "6T", "5T", "4T"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["9L30", "8L30", "7T", "7L22", "6T", "6L22", "6S1", "5L22", "5D", "4M", "4L22"],
			signalbeam: ["7T", "6T", "5T", "5D", "4T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "9L40", "8M", "8L40", "7M", "7L36", "6M", "6L36", "5M", "5L36", "4M", "4L36"],
			suckerpunch: ["4T"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thundershock: ["9L5", "8L5", "7L1", "6L1", "5T", "5L1", "5S0", "4T", "4L1"],
			thunderwave: ["9M", "9L25", "8M", "8L25", "7M", "7L1", "6M", "6L1", "6S1", "5M", "5L1", "5D", "4M", "4L1"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "9L45", "8M", "8L45", "7T", "7L1", "6T", "6L1", "6S1", "5T", "5L1", "5S0", "4T", "4L1"],
			uproar: ["9L55", "8M", "8L55", "7T", "7L8", "7S2", "6T", "6L8", "5T", "5L8", "5S0", "4T", "4L8"],
			voltswitch: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 5, level: 10, nature: "Naughty", moves: ["uproar", "astonish", "trick", "thundershock"], pokeball: "cherishball"},
			{generation: 6, level: 10, nature: "Quirky", moves: ["shockwave", "astonish", "trick", "thunderwave"], pokeball: "cherishball"},
			{generation: 7, level: 10, moves: ["uproar", "confide", "disarmingvoice"], pokeball: "cherishball"},
		],
	},
	rotomheat: {
		learnset: {
			overheat: ["9L1", "8L1", "7R", "6R", "5R", "4R"],
		},
	},
	rotomwash: {
		learnset: {
			hydropump: ["9L1", "8L1", "7R", "6R", "5R", "4R"],
		},
	},
	rotomfrost: {
		learnset: {
			blizzard: ["9L1", "8L1", "7R", "6R", "5R", "4R"],
		},
	},
	rotomfan: {
		learnset: {
			airslash: ["9L1", "8L1", "7R", "6R", "5R", "4R"],
		},
	},
	rotommow: {
		learnset: {
			leafstorm: ["9L1", "8L1", "7R", "6R", "5R", "4R"],
		},
	},
	uxie: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			allyswitch: ["8M", "7T"],
			amnesia: ["9M", "9L42", "8M", "8L42", "7L46", "7S4", "6L46", "6S3", "5L46", "5S2", "4L46", "4S0", "4S1"],
			batonpass: ["9M", "8M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "4S0"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endure: ["9M", "9L14", "8M", "8L14", "7L16", "6L16", "5L16", "4M", "4L16"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			extrasensory: ["9L35", "8L35", "7L50", "7S4", "6L50", "6S3", "5L51", "5S2", "4L51"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			flail: ["9L70", "8L70", "7L1", "6L1", "5L61", "5S2", "4L61"],
			flash: ["6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["9L63", "8M", "8L63", "8S5", "7L36", "6L36", "6S3", "5L36", "5S2", "4L36", "4S0", "4S1"],
			gigadrain: ["9M", "8M", "7T", "6T", "5T", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M", "9L28", "8M", "8L28", "7L6", "6L6", "5L6", "4L6"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "8S5", "7T", "6T", "5T"],
			memento: ["9L77", "8L77", "7L1", "6L1", "5L76", "4L76"],
			metronome: ["9M", "8M"],
			mudslap: ["9M", "4T"],
			mysticalpower: ["9L84"],
			nastyplot: ["9M", "8M"],
			naturalgift: ["7L1", "6L1", "5L66", "4M", "4L66"],
			playrough: ["9M", "8M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M", "9L21", "8L21"],
			psychic: ["9M", "9L49", "8M", "8L49", "8S5", "7M", "6M", "5M", "4M"],
			psychocut: ["8M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			return: ["7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "8S5", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "9L7", "8M", "8L7", "7L21", "7S4", "6L21", "5L21", "4T", "4L21", "4S1"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			triattack: ["8M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			uturn: ["9M", "8M", "7M", "6M", "5M", "4M"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			yawn: ["9L56", "8L56", "7L31", "7S4", "6L31", "6S3", "5L31", "4L31", "4S0", "4S1"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 50, shiny: 1, moves: ["confusion", "yawn", "futuresight", "amnesia"]},
			{generation: 4, level: 50, shiny: 1, moves: ["swift", "yawn", "futuresight", "amnesia"]},
			{generation: 5, level: 65, shiny: 1, moves: ["futuresight", "amnesia", "extrasensory", "flail"]},
			{generation: 6, level: 50, shiny: 1, moves: ["yawn", "futuresight", "amnesia", "extrasensory"]},
			{generation: 7, level: 60, shiny: 1, moves: ["extrasensory", "yawn", "amnesia", "swift"]},
			{generation: 8, level: 70, shiny: 1, moves: ["psychic", "futuresight", "magicroom", "shadowball"]},
		],
		eventOnly: true,
	},
	mesprit: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			allyswitch: ["8M", "7T"],
			batonpass: ["9M", "8M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			charm: ["9M", "9L42", "8M", "8L42", "8S5", "7L46", "7S4", "6L46", "6S3", "5L46", "5S2", "4L46", "4S0", "4S1"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "4S0"],
			copycat: ["9L70", "8L70", "7L1", "6L1", "5L61", "5S2", "4L61"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainingkiss: ["9M", "8M", "8S5"],
			drainpunch: ["9M", "8M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "4M"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			extrasensory: ["9L35", "8L35", "7L50", "7S4", "6L50", "6S3", "5L51", "5S2", "4L51"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			flash: ["6M", "5M", "4M"],
			flatter: ["9L56", "8L56"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["9L63", "8M", "8L63", "7L36", "7S4", "6L36", "6S3", "5L36", "5S2", "4L36", "4S0", "4S1"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			healingwish: ["9L77", "8L77", "7L1", "6L1", "5L76", "4L76"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M", "9L28", "8M", "8L28", "7L6", "6L6", "5L6", "4L6"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			luckychant: ["7L31", "6L31", "6S3", "5L31", "4L31", "4S0", "4S1"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			metronome: ["9M", "8M"],
			mudslap: ["4T"],
			mysticalpower: ["9L84"],
			nastyplot: ["9M", "8M"],
			naturalgift: ["7L1", "6L1", "5L66", "4M", "4L66"],
			playrough: ["9M", "8M"],
			poweruppunch: ["6M"],
			protect: ["9M", "9L14", "8M", "8L14", "7M", "7L16", "6M", "6L16", "5M", "5L16", "4M", "4L16"],
			psybeam: ["9M", "9L21", "8L21"],
			psychic: ["9M", "9L49", "8M", "8L49", "8S5", "7M", "6M", "5M", "4M"],
			psychocut: ["8M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			return: ["7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "9L7", "8M", "8L7", "7L21", "7S4", "6L21", "5L21", "4T", "4L21", "4S1"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			triattack: ["8M", "8S5"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			uturn: ["9M", "8M", "7M", "6M", "5M", "4M"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 50, shiny: 1, moves: ["confusion", "luckychant", "futuresight", "charm"]},
			{generation: 4, level: 50, shiny: 1, moves: ["swift", "luckychant", "futuresight", "charm"]},
			{generation: 5, level: 50, shiny: 1, moves: ["futuresight", "charm", "extrasensory", "copycat"]},
			{generation: 6, level: 50, shiny: 1, moves: ["luckychant", "futuresight", "charm", "extrasensory"]},
			{generation: 7, level: 60, shiny: 1, moves: ["extrasensory", "charm", "futuresight", "swift"]},
			{generation: 8, level: 70, shiny: 1, moves: ["psychic", "charm", "drainingkiss", "triattack"]},
		],
		eventOnly: true,
	},
	azelf: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			allyswitch: ["8M", "7T"],
			assurance: ["8M"],
			batonpass: ["9M", "8M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1", "4S0"],
			dazzlinggleam: ["9M", "8M", "8S5", "7M", "6M"],
			detect: ["9L14", "8L14", "7L16", "6L16", "5L16", "4L16"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M", "4M"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			expandingforce: ["8T"],
			explosion: ["9L77", "8L77", "7M", "7L76", "6M", "6L76", "5M", "5L76", "4M", "4L76"],
			extrasensory: ["9L35", "8L35", "7L50", "7S4", "6L50", "6S3", "5L51", "5S2", "4L51"],
			facade: ["9M", "8M", "8S5", "7M", "6M", "5M", "4M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			futuresight: ["9L63", "8M", "8L63", "7L36", "6L36", "6S3", "5L36", "5S2", "4L36", "4S0", "4S1"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			imprison: ["9M", "9L28", "8M", "8L28", "7L6", "6L6", "5L6", "4L6"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			laserfocus: ["7T"],
			lastresort: ["9L70", "8L70", "7T", "7L1", "6T", "6L1", "5T", "5L61", "5S2", "4T", "4L61"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			metronome: ["9M", "8M"],
			mudslap: ["9M", "4T"],
			mysticalpower: ["9L84"],
			nastyplot: ["9M", "9L42", "8M", "8L42", "8S5", "7L46", "7S4", "6L46", "6S3", "5L46", "5S2", "4L46", "4S0", "4S1"],
			naturalgift: ["7L1", "6L1", "5L66", "4M", "4L66"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			playrough: ["9M", "8M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M", "9L21", "8L21"],
			psychic: ["9M", "9L49", "8M", "8L49", "8S5", "7M", "6M", "5M", "4M"],
			psychocut: ["8M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			return: ["7M", "6M", "5M", "4M"],
			roleplay: ["7T", "6T", "5T", "4T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			selfdestruct: ["8M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "9L7", "8M", "8L7", "7L21", "7S4", "6L21", "5L21", "4T", "4L21", "4S1"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T", "4T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			triattack: ["8M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			uproar: ["9L56", "8M", "8L56", "7T", "7L31", "7S4", "6T", "6L31", "6S3", "5T", "5L31", "4T", "4L31", "4S0", "4S1"],
			uturn: ["9M", "8M", "7M", "6M", "5M", "4M"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 50, shiny: 1, moves: ["confusion", "uproar", "futuresight", "nastyplot"]},
			{generation: 4, level: 50, shiny: 1, moves: ["swift", "uproar", "futuresight", "nastyplot"]},
			{generation: 5, level: 50, shiny: 1, moves: ["futuresight", "nastyplot", "extrasensory", "lastresort"]},
			{generation: 6, level: 50, shiny: 1, moves: ["uproar", "futuresight", "nastyplot", "extrasensory"]},
			{generation: 7, level: 60, shiny: 1, moves: ["extrasensory", "nastyplot", "uproar", "swift"]},
			{generation: 8, level: 70, shiny: 1, moves: ["psychic", "dazzlinggleam", "nastyplot", "facade"]},
		],
		eventOnly: true,
	},
	dialga: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			ancientpower: ["9L16", "8L16", "8S11", "7L10", "6L10", "5L10", "4T", "4L10", "4S0"],
			aurasphere: ["9M", "9L48", "8M", "8L48", "7L37", "7S7", "7S8", "7S9", "7S10", "6L37", "6S5", "5L37", "5S4", "4L37"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dracometeor: ["9M", "8T", "7T", "7S9", "7S10", "6T", "5T", "5S4", "4T"],
			dragonbreath: ["9L8", "8L8", "7L1", "6L1", "5L1", "5S3", "4L1", "4S2"],
			dragonclaw: ["9M", "9L40", "8M", "8L40", "8S11", "7M", "7L28", "6M", "6L28", "5M", "5L28", "4M", "4L28", "4S0"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "5S4", "4M"],
			dragontail: ["9M", "7M", "6M", "5M"],
			earthpower: ["9M", "9L72", "8M", "8L72", "7T", "7L33", "6T", "6L33", "5T", "5L33", "4T", "4L33", "4S1"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			flashcannon: ["9M", "9L32", "8M", "8L32", "8S12", "8S11", "7M", "7L50", "7S7", "7S8", "7S9", "7S10", "6M", "6L50", "6S5", "6S6", "5M", "5L50", "4M", "4L42"],
			focusblast: ["9M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			healblock: ["4L50", "4S1"],
			heavyslam: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M", "8M", "7T", "6T", "5T", "4T"],
			irontail: ["9L80", "8M", "8L80", "7T", "7L42", "7S7", "7S8", "6T", "6L42", "6S5", "5T", "5L42", "4M"],
			magnetrise: ["7T", "6T", "5T", "4T"],
			metalburst: ["9L64", "8L64", "8S12", "7L24", "6L24", "6S6", "5L24", "4L24"],
			metalclaw: ["9M", "9L1", "8L1", "7L6", "6L6", "5L6", "4L6", "4S0"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "7T", "6T", "5T", "4T"],
			overheat: ["9M", "8M", "8S12", "7M", "6M", "6S6", "5M", "4M"],
			powergem: ["9M", "9L56", "8M", "8L56", "7L19", "6L19", "5L19", "4L19"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			roaroftime: ["9L88", "8L88", "8S12", "7L46", "7S7", "7S8", "7S9", "7S10", "6L46", "6S5", "6S6", "5L46", "5S4", "4L40", "4S0", "4S1"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "5S3", "4L1", "4S2"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			slash: ["9L24", "8L24", "8S11", "7L15", "6L15", "5L15", "4L15", "4S1"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["9M", "8T"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			twister: ["4T"],
		},
		eventData: [
			{generation: 4, level: 47, shiny: 1, moves: ["metalclaw", "ancientpower", "dragonclaw", "roaroftime"]},
			{generation: 4, level: 70, shiny: 1, moves: ["roaroftime", "healblock", "earthpower", "slash"]},
			{generation: 4, level: 1, shiny: 1, moves: ["dragonbreath", "scaryface"]},
			{generation: 5, level: 5, isHidden: true, moves: ["dragonbreath", "scaryface"], pokeball: "dreamball"},
			{generation: 5, level: 100, shiny: true, moves: ["dragonpulse", "dracometeor", "aurasphere", "roaroftime"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["aurasphere", "irontail", "roaroftime", "flashcannon"]},
			{generation: 6, level: 100, nature: "Modest", isHidden: true, moves: ["metalburst", "overheat", "roaroftime", "flashcannon"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["aurasphere", "irontail", "roaroftime", "flashcannon"]},
			{generation: 7, level: 60, moves: ["aurasphere", "irontail", "roaroftime", "flashcannon"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["roaroftime", "aurasphere", "dracometeor", "flashcannon"], pokeball: "cherishball"},
			{generation: 7, level: 50, moves: ["flashcannon", "dracometeor", "roaroftime", "aurasphere"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["slash", "ancientpower", "flashcannon", "dragonclaw"]},
			{generation: 8, level: 70, nature: "Bold", isHidden: true, moves: ["roaroftime", "flashcannon", "metalburst", "overheat"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	dialgaorigin: {
		eventOnly: true,
	},
	palkia: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M", "4M"],
			ancientpower: ["9L16", "8L16", "8S11", "7L10", "6L10", "5L10", "4T", "4L10", "4S0"],
			aquaring: ["9L32", "8L32", "7L24"],
			aquatail: ["9L64", "8L64", "7T", "7L24", "7S7", "7S8", "6T", "6L24", "5T", "5L24", "4T", "4L24"],
			aurasphere: ["9M", "9L48", "8M", "8L48", "8S12", "7L37", "7S7", "7S8", "7S9", "7S10", "6L37", "6S5", "6S6", "5L37", "5S4", "4L37"],
			avalanche: ["9M", "8M", "4M"],
			blizzard: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			breakingswipe: ["8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M", "4M"],
			brine: ["8M", "4M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			dive: ["8M", "6M", "5M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dracometeor: ["9M", "8T", "7T", "7S9", "7S10", "6T", "5T", "5S4", "4T"],
			dragonbreath: ["9L8", "8L8", "7L1", "6L1", "5L1", "5S3", "4L1", "4S2"],
			dragonclaw: ["9M", "8M", "8L40", "8S11", "7M", "7L28", "6M", "6L28", "5M", "5L28", "4M", "4L28", "4S0"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			dragontail: ["9M", "7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			earthpower: ["9M", "9L72", "8M", "8L72", "8S12", "7T", "7L33", "6T", "6L33", "6S5", "6S6", "5T", "5L33", "4T", "4L33", "4S1"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fling: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			hail: ["8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			healblock: ["4L50", "4S1"],
			heavyslam: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["9M", "9L88", "8M", "8L88", "8S12", "7L50", "7S7", "7S8", "7S9", "7S10", "6L50", "6S5", "6S6", "5L50", "5S4", "4L42"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icywind: ["9M"],
			incinerate: ["6M", "5M"],
			liquidation: ["9M", "8M", "7T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			outrage: ["9M", "8M", "7T", "6T", "5T", "4T"],
			powergem: ["9M", "9L56", "8M", "8L56", "7L19", "6L19", "5L19", "4L19"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M", "4M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L1", "8M", "8L1", "7L1", "6L1", "5L1", "5S3", "4L1", "4S2"],
			secretpower: ["6M", "4M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			slash: ["9L24", "8L24", "8S11", "7L15", "6L15", "5L15", "4L15", "4S1"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			snowscape: ["9M"],
			spacialrend: ["9L80", "8L80", "8S12", "7L46", "7S7", "7S8", "7S9", "7S10", "6L46", "6S5", "6S6", "5L46", "5S4", "4L40", "4S0", "4S1"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "8M", "8S11", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			twister: ["4T"],
			waterfall: ["9M"],
			waterpulse: ["9M", "9L1", "8L1", "7T", "7L6", "6T", "6L6", "5L6", "4M", "4L6", "4S0"],
			whirlpool: ["8M", "4M"],
		},
		eventData: [
			{generation: 4, level: 47, shiny: 1, moves: ["waterpulse", "ancientpower", "dragonclaw", "spacialrend"]},
			{generation: 4, level: 70, shiny: 1, moves: ["spacialrend", "healblock", "earthpower", "slash"]},
			{generation: 4, level: 1, shiny: 1, moves: ["dragonbreath", "scaryface"]},
			{generation: 5, level: 5, isHidden: true, moves: ["dragonbreath", "scaryface"], pokeball: "dreamball"},
			{generation: 5, level: 100, shiny: true, moves: ["hydropump", "dracometeor", "spacialrend", "aurasphere"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["earthpower", "aurasphere", "spacialrend", "hydropump"]},
			{generation: 6, level: 100, nature: "Timid", isHidden: true, moves: ["earthpower", "aurasphere", "spacialrend", "hydropump"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["aurasphere", "aquatail", "spacialrend", "hydropump"]},
			{generation: 7, level: 60, moves: ["aurasphere", "aquatail", "spacialrend", "hydropump"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["spacialrend", "aurasphere", "dracometeor", "hydropump"], pokeball: "cherishball"},
			{generation: 7, level: 50, moves: ["hydropump", "dracometeor", "spacialrend", "aurasphere"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["slash", "surf", "ancientpower", "dragonclaw"]},
			{generation: 8, level: 70, nature: "Hasty", isHidden: true, moves: ["spacialrend", "hydropump", "aurasphere", "earthpower"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	palkiaorigin: {
		eventOnly: true,
	},
	heatran: {
		learnset: {
			ancientpower: ["9L12", "8L12", "7L1", "6L1", "5L1", "4T", "4L1", "4S2"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			bugbite: ["7T", "6T", "5T", "4T"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			burningjealousy: ["8T"],
			captivate: ["4M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L36", "8M", "8L36", "8S8", "7L33", "7S5", "7S6", "6L33", "6S4", "5L33", "4L33", "4S1"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			dig: ["9M", "8M", "6M", "5M", "4M"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "4M"],
			earthpower: ["9M", "9L54", "8M", "8L54", "7T", "7L1", "7S7", "6T", "6L1", "5T", "5L73", "4T", "4L73", "4S2"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			eruption: ["4S2"],
			explosion: ["7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M", "4M"],
			firefang: ["9M", "9L18", "8M", "8L18", "7L17", "6L17", "5L17", "4L17"],
			firespin: ["9M", "9L1", "8M", "8L1", "7L1", "7S5", "7S6", "6L1", "5L57", "5S3", "4L57", "4S0"],
			flamecharge: ["9M", "7M", "6M", "5M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flareblitz: ["9M"],
			flashcannon: ["9M", "8M", "7M", "7S7", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			heatcrash: ["8M"],
			heatwave: ["9M", "9L60", "8M", "8L60", "7T", "7L1", "7S7", "6T", "6L1", "5T", "5L81", "4T", "4L81"],
			heavyslam: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "8M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M", "9L30", "8M", "8L30", "8S8", "7T", "7L1", "6T", "6L1", "5T", "5L65", "5S3", "4T", "4L65", "4S0"],
			lavaplume: ["9L42", "8L42", "8S8", "7L49", "7S5", "7S6", "6L49", "6S4", "5L49", "5S3", "4L49", "4S0", "4S1"],
			leer: ["9L1", "8L1", "7L9", "6L9", "5L9", "4L9"],
			magmastorm: ["9L72", "8L72", "7L1", "7S7", "6L1", "5L96", "4L96", "4S2"],
			metalclaw: ["9M", "9L6", "8L6"],
			metalsound: ["9L48", "8L48", "8S8", "7L25", "6L25", "6S4", "5L25", "4L25", "4S1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "4M"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			pounce: ["9M"],
			powergem: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockblast: ["9M"],
			rockclimb: ["4M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "9L24", "8M", "8L24", "7L41", "7S5", "7S6", "6L41", "6S4", "5L41", "5S3", "4L41", "4S0", "4S1"],
			scorchingsands: ["8T"],
			secretpower: ["6M", "4M"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T", "4M"],
			steelbeam: ["9M", "8T"],
			steelroller: ["8T"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "9L66", "8M", "8L66", "7M", "7L88", "6M", "6L88", "5M", "5L88", "4M", "4L88"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			terablast: ["9M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["8M", "7T", "6T", "5T", "4T"],
			willowisp: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 4, level: 70, shiny: 1, moves: ["scaryface", "lavaplume", "firespin", "ironhead"]},
			{generation: 4, level: 50, shiny: 1, moves: ["metalsound", "crunch", "scaryface", "lavaplume"]},
			{generation: 4, level: 50, gender: "M", nature: "Quiet", moves: ["eruption", "magmastorm", "earthpower", "ancientpower"], pokeball: "pokeball"},
			{generation: 5, level: 68, shiny: 1, moves: ["scaryface", "lavaplume", "firespin", "ironhead"]},
			{generation: 6, level: 50, shiny: 1, moves: ["metalsound", "crunch", "scaryface", "lavaplume"]},
			{generation: 7, level: 60, shiny: 1, moves: ["crunch", "scaryface", "lavaplume", "firespin"]},
			{generation: 7, level: 60, moves: ["crunch", "scaryface", "lavaplume", "firespin"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["magmastorm", "heatwave", "earthpower", "flashcannon"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["metalsound", "lavaplume", "crunch", "ironhead"]},
		],
		eventOnly: true,
	},
	regigigas: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			ancientpower: ["4T"],
			avalanche: ["8M", "4M"],
			block: ["7T", "6T", "5T", "4T"],
			bodypress: ["8M", "8L42"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M", "4M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			confuseray: ["8L1", "7L1", "7S5", "7S6", "6L1", "5L1", "4L1", "4S0", "4S1"],
			crushgrip: ["8L78", "8S8", "7L1", "7S7", "6L1", "5L75", "4L75", "4S2"],
			darkestlariat: ["8M"],
			dizzypunch: ["7L1", "7S5", "7S6", "6L1", "5L1", "4L1", "4S1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			drainpunch: ["8M", "7T", "7S7", "6T", "5T", "4M"],
			earthpower: ["8M", "7T", "6T", "5T", "4T"],
			earthquake: ["8M", "7M", "6M", "5M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "6M", "5M", "4M"],
			firepunch: ["8M", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			fling: ["8M", "7M", "6M", "5M", "4M"],
			focusblast: ["8M", "7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			foresight: ["7L1", "6L1", "6S4", "5L1", "4L1", "4S1"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["8M", "8L72", "8S8", "7M", "7L100", "6M", "6L100", "5M", "5L100", "4M", "4L100"],
			gravity: ["7T", "6T", "5T", "4T"],
			hammerarm: ["8L66", "8S8"],
			headbutt: ["4T"],
			heatcrash: ["8M"],
			heavyslam: ["8M", "8L60", "7L1", "7S7", "6L1", "5L90"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M", "4M"],
			icepunch: ["8M", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			icywind: ["8M", "7T", "6T", "5T", "4T", "4S2"],
			ironhead: ["8M", "7T", "6T", "5T", "4T", "4S2"],
			knockoff: ["8L30", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4L1", "4S1"],
			megakick: ["8M"],
			megapunch: ["8M", "8L36", "4L1"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M", "6M"],
			payback: ["8M", "8L6", "7M", "7L65", "6M", "6L65", "5M", "5L65", "5S3"],
			pound: ["8L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L24"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["8M", "7M", "6M", "5M", "4M"],
			rest: ["8M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			revenge: ["8M", "8L12", "7L25", "7S5", "7S6", "6L25", "6S4", "5L25", "5S3", "4L25"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "6M", "5M", "4M"],
			rockslide: ["8M", "7M", "6M", "5M", "4M", "4S2"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["8M", "7M", "6M", "5T", "4M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			stomp: ["8L18", "4L1", "4S0"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["8M", "7M", "6M", "5M", "4M"],
			superpower: ["8M", "7T", "6T", "5T", "4T", "4L25", "4S0"],
			swagger: ["7M", "6M", "5M", "4M"],
			terrainpulse: ["8T"],
			thunder: ["8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["8M", "7M", "6M", "5M", "4M"],
			thunderpunch: ["8M", "7T", "7L1", "6T", "6L1", "5T", "5L1", "4T", "4L1"],
			thunderwave: ["8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			wideguard: ["8L48", "7L40", "6L40", "6S4", "5L40", "5S3"],
			zenheadbutt: ["8M", "8L54", "8S8", "7T", "7L50", "7S5", "7S6", "7S7", "6T", "6L50", "6S4", "5T", "5L50", "5S3", "4T", "4L50", "4S0"],
		},
		eventData: [
			{generation: 4, level: 70, shiny: 1, moves: ["confuseray", "stomp", "superpower", "zenheadbutt"]},
			{generation: 4, level: 1, shiny: 1, moves: ["dizzypunch", "knockoff", "foresight", "confuseray"]},
			{generation: 4, level: 100, moves: ["ironhead", "rockslide", "icywind", "crushgrip"], pokeball: "cherishball"},
			{generation: 5, level: 68, shiny: 1, moves: ["revenge", "wideguard", "zenheadbutt", "payback"]},
			{generation: 6, level: 50, shiny: 1, moves: ["foresight", "revenge", "wideguard", "zenheadbutt"]},
			{generation: 7, level: 60, shiny: 1, moves: ["zenheadbutt", "revenge", "dizzypunch", "confuseray"]},
			{generation: 7, level: 60, moves: ["zenheadbutt", "revenge", "dizzypunch", "confuseray"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["crushgrip", "drainpunch", "zenheadbutt", "heavyslam"], pokeball: "cherishball"},
			{generation: 8, level: 100, shiny: 1, moves: ["gigaimpact", "zenheadbutt", "hammerarm", "crushgrip"]},
		],
		eventOnly: true,
	},
	giratina: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			aircutter: ["4T"],
			ancientpower: ["9L14", "8L14", "8S8", "7L10", "6L10", "5L10", "4T", "4L10", "4S1"],
			aquatail: ["7T", "6T", "5T", "4T"],
			aurasphere: ["9M", "9L56", "8M", "8L56", "7L37", "7S7", "6L37", "6S5", "6S6", "5L37", "5S4", "4L37"],
			bodyslam: ["9M", "8M"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T", "4M"],
			defog: ["9L1", "8L1", "7T", "4M"],
			destinybond: ["9L84", "8L84", "7L24", "6L24", "5L24", "4L24"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dracometeor: ["9M", "8T", "7T", "6T", "6S6", "5T", "4T"],
			dragonbreath: ["9L7", "8L7", "7L1", "6L1", "5L1", "5S3", "4L1", "4S2"],
			dragonclaw: ["9M", "9L63", "8M", "8L63", "8S8", "7M", "7L28", "7S7", "6M", "6L28", "5M", "5L28", "5S4", "4M", "4L28", "4S1"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T", "5S4", "4M"],
			dragontail: ["9M", "7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			dualwingbeat: ["8T"],
			earthpower: ["9M", "9L70", "8M", "8L70", "7T", "7L33", "7S7", "6T", "6L33", "5T", "5L33", "4T", "4L33", "4S0"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "4M"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			fly: ["9M", "8M", "7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			headbutt: ["4T"],
			healblock: ["4L50", "4S0"],
			hex: ["9M", "9L21", "8M", "8L21", "7L50", "6L50", "6S5", "5L50"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			icywind: ["9M", "8M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M", "8M", "7T", "6T", "6S6", "5T", "4T"],
			irontail: ["8M", "7T", "6T", "5T", "4M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			ominouswind: ["7L6", "6L6", "5L6", "4T", "4L6", "4S1"],
			outrage: ["9M", "8M", "7T", "6T", "5T", "4T"],
			painsplit: ["9L49", "8L49", "7T", "6T", "5T", "4T"],
			payback: ["8M", "7M", "6M", "5M", "4M"],
			phantomforce: ["9M", "8M"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychic: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			rockclimb: ["4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M", "4M"],
			scaryface: ["9M", "9L35", "8M", "8L35", "8S8", "7L1", "6L1", "5L1", "5S3", "4L1", "4S2"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "8S8", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M", "9L42", "8M", "8L42", "7M", "7L42", "6M", "6L42", "6S5", "5M", "5L42", "4M", "4L42"],
			shadowforce: ["9L77", "8L77", "7L46", "7S7", "6L46", "6S5", "6S6", "5L46", "5S4", "4L40", "4S0", "4S1"],
			shadowsneak: ["9L1", "8L1", "7L19", "6L19", "5L19", "4L19"],
			shockwave: ["7T", "6T", "4M"],
			silverwind: ["4M"],
			slash: ["9L28", "8L28", "7L15", "6L15", "5L15", "4L15", "4S0"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			spite: ["7T", "6T", "5T", "4T"],
			steelwing: ["8M", "7M", "6M", "4M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			tailwind: ["7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			twister: ["4T"],
			willowisp: ["9M", "8M", "7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 4, level: 70, shiny: 1, moves: ["shadowforce", "healblock", "earthpower", "slash"]},
			{generation: 4, level: 47, shiny: 1, moves: ["ominouswind", "ancientpower", "dragonclaw", "shadowforce"]},
			{generation: 4, level: 1, shiny: 1, moves: ["dragonbreath", "scaryface"]},
			{generation: 5, level: 5, isHidden: true, moves: ["dragonbreath", "scaryface"], pokeball: "dreamball"},
			{generation: 5, level: 100, shiny: true, moves: ["dragonpulse", "dragonclaw", "aurasphere", "shadowforce"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["aurasphere", "shadowclaw", "shadowforce", "hex"]},
			{generation: 6, level: 100, nature: "Brave", isHidden: true, moves: ["aurasphere", "dracometeor", "shadowforce", "ironhead"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["shadowforce", "aurasphere", "earthpower", "dragonclaw"]},
			{generation: 8, level: 70, shiny: 1, moves: ["dragonclaw", "scaryface", "shadowball", "ancientpower"]},
		],
		eventOnly: true,
	},
	giratinaorigin: {
		eventOnly: true,
	},
	cresselia: {
		learnset: {
			allyswitch: ["9L24", "8M", "7T"],
			attract: ["8M", "7M", "6M", "5M", "4M"],
			aurorabeam: ["9L12", "8L12", "7L29", "7S4", "6L29", "6S3", "5L29", "4L29", "4S0"],
			bodyslam: ["9M", "8M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M", "4M"],
			captivate: ["4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1", "4L1"],
			dazzlinggleam: ["9M"],
			doubleteam: ["9L1", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1", "4M", "4L1"],
			dreameater: ["7M", "6M", "5M", "4M"],
			endure: ["9M", "8M", "4M"],
			energyball: ["9M", "8M", "7M", "6M", "5M", "5S2", "4M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			futuresight: ["9L66", "8M", "8L66", "7L38", "7S4", "6L38", "6S3", "5L38", "5S1", "4L38", "4S0"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M", "4M"],
			gravity: ["7T", "6T", "5T", "4T"],
			guardswap: ["8M"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "5S2", "4M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M", "5S2", "4M"],
			icywind: ["9M", "8M", "8S5", "7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M", "4M"],
			lunarblessing: ["9L72"],
			lunardance: ["9L72", "8L72", "7L1", "6L1", "5L84", "4L84"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			mist: ["9L6", "8L6", "7L20", "6L20", "6S3", "5L20", "4L20", "4S0"],
			moonblast: ["9L60", "8L60", "8S5", "7L99", "6L99"],
			moonlight: ["9L42", "8L42", "7L1", "7S4", "6L1", "5L57", "5S1", "4L57"],
			mudslap: ["9M", "4T"],
			naturalgift: ["4M"],
			powergem: ["9M"],
			powerswap: ["8M"],
			protect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			psybeam: ["9M", "9L18", "8L18"],
			psychic: ["9M", "9L54", "8M", "8L54", "7M", "7L93", "6M", "6L93", "5M", "5L93", "4M", "4L93"],
			psychicterrain: ["9M"],
			psychocut: ["9L36", "8M", "8L36", "8S5", "7L1", "6L1", "5L66", "5S1", "4L66"],
			psychoshift: ["8L24", "7L1", "6L1", "5L75", "4L75"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M", "8M", "8S5", "7M", "6M", "5M", "5S2"],
			raindance: ["9M", "8M", "7M", "6M", "5M", "4M"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "8M", "7M", "6M", "5M", "4M"],
			rest: ["9M", "8M", "7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["9L48", "8M", "8L48", "7M", "7L11", "6M", "6L11", "5M", "5L11", "4M", "4L11"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T", "4M"],
			slash: ["9L30", "8L30", "7L47", "7S4", "6L47", "6S3", "5L47", "5S1", "4L47", "4S0"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T", "4M"],
			snore: ["8M", "7T", "6T", "5T", "4T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M", "4M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "8M", "4T"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["9M", "8M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M", "4M"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 50, shiny: 1, moves: ["mist", "aurorabeam", "futuresight", "slash"]},
			{generation: 5, level: 68, shiny: 1, moves: ["futuresight", "slash", "moonlight", "psychocut"]},
			{generation: 5, level: 68, nature: "Modest", moves: ["icebeam", "psyshock", "energyball", "hiddenpower"]},
			{generation: 6, level: 50, shiny: 1, moves: ["mist", "aurorabeam", "futuresight", "slash"]},
			{generation: 7, level: 60, shiny: 1, moves: ["aurorabeam", "futuresight", "slash", "moonlight"]},
			{generation: 8, level: 70, shiny: 1, moves: ["icywind", "moonblast", "psychocut", "psyshock"]},
		],
		eventOnly: true,
	},
	phione: {
		learnset: {
			acidarmor: ["7L31", "6L31", "5L31", "4L31"],
			ancientpower: ["4T"],
			aquaring: ["7L54", "6L54", "5L54", "4L54"],
			blizzard: ["7M", "6M", "5M", "4M"],
			bounce: ["7T", "6T", "5T", "4T"],
			brine: ["4M"],
			bubble: ["7L1", "6L1", "5L1", "4L1"],
			bubblebeam: ["7L24", "6L24", "5L24", "4L24"],
			charm: ["7L9", "6L9", "5L9", "4L9"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["7M", "6M"],
			dive: ["7L61", "6M", "6L61", "5M", "5L61", "4T", "4L61"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M", "4S0"],
			hail: ["7M", "6M", "5M", "4M"],
			healbell: ["7T", "6T", "5T", "4T"],
			helpinghand: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			icebeam: ["7M", "6M", "5M", "4M"],
			icywind: ["7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			liquidation: ["7T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			protect: ["7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["7M", "7L69", "6M", "6L69", "5M", "5L69", "4M", "4L69", "4S0"],
			rest: ["7M", "6M", "5M", "4M", "4S0"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			substitute: ["7M", "6M", "5M", "4M"],
			supersonic: ["7L16", "6L16", "5L16", "4L16"],
			surf: ["7M", "6M", "5M", "4M", "4S0"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterfall: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "7L46", "6T", "6L46", "5L46", "4M", "4L46"],
			watersport: ["7L1", "6L1", "5L1", "4L1"],
			whirlpool: ["7L39", "6L39", "5L39", "4M", "4L39"],
		},
		eventData: [
			{generation: 4, level: 50, moves: ["grassknot", "raindance", "rest", "surf"], pokeball: "cherishball"},
		],
	},
	manaphy: {
		learnset: {
			acidarmor: ["7L31", "6L31", "5L31", "4L31", "4S2"],
			ancientpower: ["4T"],
			aquaring: ["7L54", "7S6", "6L54", "5L54", "4L54", "4S3"],
			blizzard: ["7M", "6M", "5M", "4M"],
			bounce: ["7T", "6T", "5T", "4T"],
			brine: ["4M"],
			bubble: ["7L1", "6L1", "6S4", "6S5", "5L1", "4L1", "4S0", "4S1"],
			bubblebeam: ["7L24", "6L24", "5L24", "4L24"],
			calmmind: ["7M", "6M", "5M", "4M"],
			charm: ["7L9", "6L9", "5L9", "4L9"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["7M", "6M"],
			dive: ["7L61", "6M", "6L61", "5M", "5L61", "4T", "4L61"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			endure: ["4M"],
			energyball: ["7M", "6M", "5M", "4M"],
			facade: ["7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			hail: ["7M", "6M", "5M", "4M"],
			healbell: ["7T", "6T", "5T", "4T"],
			heartswap: ["7L76", "7S6", "6L76", "6S4", "5L76", "4L76", "4S2", "4S3"],
			helpinghand: ["7T", "6T", "5T", "4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			icebeam: ["7M", "6M", "5M", "4M"],
			icywind: ["7T", "6T", "5T", "4T"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["7M", "6M", "5M", "4M"],
			liquidation: ["7T"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			protect: ["7M", "6M", "5M", "4M"],
			psychic: ["7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			raindance: ["7M", "7L69", "6M", "6L69", "5M", "5L69", "4M", "4L69"],
			reflect: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			skillswap: ["7T", "6T", "5T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			substitute: ["7M", "6M", "5M", "4M"],
			supersonic: ["7L16", "6L16", "5L16", "4L16"],
			surf: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			tailglow: ["7L1", "7S6", "6L1", "6S4", "6S5", "5L1", "4L1", "4S0", "4S1"],
			toxic: ["7M", "6M", "5M", "4M"],
			uproar: ["7T", "6T", "5T", "4T"],
			uturn: ["7M", "6M", "5M", "4M"],
			waterfall: ["7M", "6M", "5M", "4M"],
			waterpulse: ["7T", "7L46", "7S6", "6T", "6L46", "5L46", "4M", "4L46", "4S2", "4S3"],
			watersport: ["7L1", "6L1", "6S4", "6S5", "5L1", "4L1", "4S0", "4S1", "4S3"],
			whirlpool: ["7L39", "6L39", "5L39", "4M", "4L39", "4S2"],
		},
		eventData: [
			{generation: 4, level: 5, moves: ["tailglow", "bubble", "watersport"]},
			{generation: 4, level: 1, shiny: 1, moves: ["tailglow", "bubble", "watersport"], pokeball: "pokeball"},
			{generation: 4, level: 50, moves: ["heartswap", "waterpulse", "whirlpool", "acidarmor"], pokeball: "cherishball"},
			{generation: 4, level: 50, nature: "Impish", moves: ["aquaring", "waterpulse", "watersport", "heartswap"], pokeball: "cherishball"},
			{generation: 6, level: 1, moves: ["tailglow", "bubble", "watersport", "heartswap"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["tailglow", "bubble", "watersport"], pokeball: "cherishball"},
			{generation: 7, level: 15, moves: ["tailglow", "waterpulse", "aquaring", "heartswap"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	darkrai: {
		learnset: {
			aerialace: ["7M", "6M", "5M", "4M"],
			blizzard: ["7M", "6M", "5M", "4M"],
			brickbreak: ["7M", "6M", "5M", "4M"],
			calmmind: ["7M", "6M", "5M", "4M"],
			chargebeam: ["7M", "6M", "5M", "4M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["7M", "7L93", "6M", "6L93", "6S5", "5T", "5L93", "4M", "4L93", "4S2"],
			darkvoid: ["7L66", "7S7", "6L66", "6S5", "6S6", "5L66", "5S4", "4L66", "4S2"],
			disable: ["7L1", "6L1", "5L1", "4L1"],
			doubleteam: ["7M", "7L47", "6M", "6L47", "5M", "5L47", "4M", "4L47", "4S2", "4S3"],
			drainpunch: ["7T", "6T", "5T", "4M"],
			dreameater: ["7M", "7L84", "6M", "6L84", "6S5", "5M", "5L84", "4M", "4L84"],
			embargo: ["7M", "6M", "5M", "4M", "4L75"],
			endure: ["4M"],
			facade: ["7M", "6M", "5M", "4M"],
			feintattack: ["7L29", "7S7", "6L29", "6S6", "5L29", "5S4", "4L29", "4S3"],
			flash: ["6M", "5M", "4M"],
			fling: ["7M", "6M", "5M", "4M"],
			focusblast: ["7M", "6M", "5M", "4M"],
			focuspunch: ["7T", "6T", "4M"],
			foulplay: ["7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			haze: ["7L57", "6L57", "5L57", "4L57"],
			headbutt: ["4T"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			hypnosis: ["7L20", "6L20", "5L20", "4L20", "4S0", "4S1", "4S3"],
			icebeam: ["7M", "6M", "5M", "4M"],
			icywind: ["7T", "6T", "5T", "4T"],
			incinerate: ["6M", "5M"],
			knockoff: ["7T", "6T", "5T", "4T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			mudslap: ["4T"],
			nastyplot: ["7L75", "6L75", "5L75", "4L75"],
			naturalgift: ["4M"],
			nightmare: ["7L38", "7S7", "6L38", "6S6", "5L38", "5S4", "4L38", "4S0", "4S1", "4S3"],
			nightshade: ["4L1"],
			ominouswind: ["7L1", "7S7", "6L1", "6S6", "5L1", "5S4", "4T", "4L1"],
			payback: ["7M", "6M", "5M", "4M"],
			phantomforce: ["6S5"],
			poisonjab: ["7M", "6M", "5M", "4M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M", "4M"],
			psychic: ["7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			pursuit: ["4L29", "4S0"],
			quickattack: ["7L11", "6L11", "5L11", "4L11", "4S0"],
			raindance: ["7M", "6M", "5M", "4M"],
			rest: ["7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roaroftime: ["4S1"],
			rockclimb: ["4M"],
			rockslide: ["7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M", "4M"],
			shadowball: ["7M", "6M", "5M", "4M", "4S2"],
			shadowclaw: ["7M", "6M", "5M", "4M"],
			shockwave: ["7T", "6T", "4M"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			sludgebomb: ["7M", "6M", "5M", "4M"],
			snarl: ["7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			spacialrend: ["4S1"],
			spite: ["7T", "6T", "5T", "4T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["7M", "6M", "5M", "4M"],
			suckerpunch: ["4T"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["4T"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			taunt: ["7M", "6M", "5M", "4M"],
			thief: ["7M", "6M", "5M", "4M"],
			throatchop: ["7T"],
			thunder: ["7M", "6M", "5M", "4M"],
			thunderbolt: ["7M", "6M", "5M", "4M"],
			thunderwave: ["7M", "6M", "5M", "4M"],
			torment: ["7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trick: ["7T", "6T", "5T", "4T"],
			willowisp: ["7M", "6M", "5M", "4M"],
			wonderroom: ["7T", "6T", "5T"],
			xscissor: ["7M", "6M", "5M", "4M"],
		},
		eventData: [
			{generation: 4, level: 40, shiny: 1, moves: ["quickattack", "hypnosis", "pursuit", "nightmare"]},
			{generation: 4, level: 50, moves: ["roaroftime", "spacialrend", "nightmare", "hypnosis"], pokeball: "cherishball"},
			{generation: 4, level: 50, moves: ["darkvoid", "darkpulse", "shadowball", "doubleteam"], pokeball: "pokeball"},
			{generation: 4, level: 50, shiny: 1, moves: ["hypnosis", "feintattack", "nightmare", "doubleteam"]},
			{generation: 5, level: 50, moves: ["darkvoid", "ominouswind", "feintattack", "nightmare"], pokeball: "cherishball"},
			{generation: 6, level: 50, moves: ["darkvoid", "darkpulse", "phantomforce", "dreameater"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["darkvoid", "ominouswind", "nightmare", "feintattack"], pokeball: "cherishball"},
			{generation: 7, level: 50, moves: ["darkvoid", "feintattack", "nightmare", "ominouswind"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	shaymin: {
		learnset: {
			aircutter: ["4T"],
			airslash: ["7L64", "6L64", "6S3", "5L64", "4L64"],
			aromatherapy: ["7L64", "6L64", "6S4", "5L64", "4L64", "4S0"],
			bulletseed: ["4M"],
			celebrate: ["7S5"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["7M", "6M"],
			defensecurl: ["4L1"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			earthpower: ["7T", "6T", "5T", "4T"],
			endeavor: ["7T", "6T", "5T", "4T"],
			endure: ["4M"],
			energyball: ["7M", "7L73", "6M", "6L73", "6S4", "5M", "5L73", "4M", "4L73", "4S0"],
			facade: ["7M", "6M", "5M", "4M"],
			flash: ["6M", "5M", "4M"],
			frustration: ["7M", "6M", "5M", "4M"],
			gigadrain: ["7T", "6T", "5T", "4M"],
			gigaimpact: ["7M", "6M", "5M", "4M"],
			grassknot: ["7M", "6M", "5M", "4M"],
			grasswhistle: ["4L82"],
			growth: ["7L1", "7S5", "6L1", "6S3", "5L1", "4L1", "4S1"],
			headbutt: ["4T"],
			healingwish: ["7L91", "6L91", "5L91", "4L91"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			hyperbeam: ["7M", "6M", "5M", "4M"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			leafstorm: ["7L91", "6L91", "5L91", "4L91"],
			leechseed: ["7L19", "6L19", "5L19", "5S2", "4L19", "4S1"],
			luckychant: ["4L91"],
			magicalleaf: ["7L10", "6L10", "6S3", "5L10", "4L10", "4S1"],
			mudslap: ["4T"],
			naturalgift: ["7L46", "6L46", "5L46", "4M", "4L46"],
			naturepower: ["7M", "6M"],
			ominouswind: ["4T"],
			protect: ["7M", "6M", "5M", "4M"],
			psychic: ["7M", "6M", "5M", "4M"],
			psychup: ["7M", "6M", "5M", "4M"],
			quickattack: ["7L28", "6L28", "5L28", "4L28"],
			rest: ["7M", "6M", "5M", "4M"],
			return: ["7M", "7S5", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			secretpower: ["6M", "4M"],
			seedbomb: ["7T", "6T", "5T", "4T"],
			seedflare: ["7L100", "7S5", "6L100", "6S3", "6S4", "5L100", "5S2", "4L100", "4S0"],
			sleeptalk: ["7M", "6M", "5T", "4M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["7M", "6M", "5M", "4M"],
			substitute: ["7M", "6M", "6S4", "5M", "4M", "4S0"],
			sunnyday: ["7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			sweetkiss: ["7L82", "6L82", "5L82", "4L82"],
			sweetscent: ["7L37", "6L37", "5L37", "5S2", "4L37"],
			swift: ["4T"],
			swordsdance: ["7M", "6M", "5M", "4M"],
			synthesis: ["7T", "7L28", "6T", "6L28", "5T", "5L28", "5S2", "4T", "4L28", "4S1"],
			tailwind: ["7T", "6T", "5T", "4T"],
			toxic: ["7M", "6M", "5M", "4M"],
			worryseed: ["7T", "7L55", "6T", "6L55", "5T", "5L55", "4T", "4L55"],
			zenheadbutt: ["7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 50, moves: ["seedflare", "aromatherapy", "substitute", "energyball"], pokeball: "cherishball"},
			{generation: 4, level: 30, shiny: 1, moves: ["growth", "magicalleaf", "leechseed", "synthesis"], pokeball: "pokeball"},
			{generation: 5, level: 50, moves: ["seedflare", "leechseed", "synthesis", "sweetscent"], pokeball: "cherishball"},
			{generation: 6, level: 15, moves: ["growth", "magicalleaf", "seedflare", "airslash"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["seedflare", "aromatherapy", "substitute", "energyball"], pokeball: "cherishball"},
			{generation: 7, level: 20, moves: ["return", "growth", "seedflare", "celebrate"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	shayminsky: {
		eventOnly: true,
	},
	arceus: {
		learnset: {
			acidspray: ["9M"],
			aerialace: ["7M", "6M", "5M", "4M"],
			agility: ["9M"],
			airslash: ["9M"],
			ancientpower: ["4T"],
			aquatail: ["7T", "6T", "5T", "4T"],
			aurasphere: ["9M"],
			avalanche: ["9M", "4M"],
			blastburn: ["6S2"],
			blizzard: ["9M", "7M", "6M", "5M", "4M"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "7M", "6M", "5M", "4M"],
			brine: ["4M"],
			bugbuzz: ["9M"],
			bulkup: ["9M"],
			bulldoze: ["9M", "7M", "6M", "5M"],
			bulletseed: ["9M", "4M"],
			calmmind: ["9M", "7M", "6M", "5M", "4M"],
			chargebeam: ["9M", "7M", "6M", "5M", "4M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			cosmicpower: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			cut: ["6M", "5M", "4M"],
			darkpulse: ["9M", "7M", "6M", "5T", "4M"],
			dazzlinggleam: ["9M"],
			defog: ["7T", "4M"],
			dive: ["6M", "4T"],
			doubleteam: ["7M", "6M", "5M", "4M"],
			dracometeor: ["9M", "7T", "6T", "5T", "4T"],
			dragonclaw: ["9M", "7M", "6M", "5M", "4M"],
			dragondance: ["9M"],
			dragonpulse: ["9M", "7T", "6T", "5T", "4M"],
			dragontail: ["9M"],
			dreameater: ["7M", "6M", "5M", "4M"],
			earthpower: ["9M", "9L20", "7T", "7L20", "6T", "6L20", "6S2", "5T", "5L20", "4T", "4L20"],
			earthquake: ["9M", "7M", "6M", "5M", "4M"],
			echoedvoice: ["7M", "6M", "5M"],
			electricterrain: ["9M"],
			endure: ["9M", "4M"],
			energyball: ["9M", "7M", "6M", "5M", "4M"],
			extremespeed: ["9L40", "7L40", "7S4", "6L40", "5L40", "4L40"],
			facade: ["9M", "7M", "6M", "5M", "4M"],
			fireblast: ["9M", "7M", "6M", "5M", "4M"],
			flamethrower: ["9M", "7M", "6M", "5M", "4M"],
			flareblitz: ["9M"],
			flash: ["6M", "5M", "4M"],
			flashcannon: ["9M", "7M", "6M", "5M", "4M"],
			fly: ["9M", "7M", "6M", "5M", "4M"],
			focusblast: ["9M", "7M", "6M", "5M", "4M"],
			foulplay: ["9M"],
			frustration: ["7M", "6M", "5M", "4M"],
			furycutter: ["4T"],
			futuresight: ["9L60", "7L60", "6L60", "5L60", "4L60"],
			gigadrain: ["9M", "7T", "6T", "5T", "4M"],
			gigaimpact: ["9M", "7M", "6M", "5M", "4M"],
			grassknot: ["9M", "7M", "6M", "5M", "4M"],
			grassyterrain: ["9M"],
			gravity: ["9L10", "7T", "7L10", "6T", "6L10", "5T", "5L10", "4T", "4L10"],
			gunkshot: ["9M"],
			hail: ["7M", "6M", "5M", "4M"],
			headbutt: ["4T"],
			healingwish: ["9L50"],
			heatwave: ["9M", "7T", "6T", "5T", "4T"],
			heavyslam: ["9M"],
			hex: ["9M"],
			hiddenpower: ["7M", "6M", "5M", "4M"],
			honeclaws: ["6M", "5M"],
			hurricane: ["9M"],
			hydrocannon: ["6S2"],
			hydropump: ["9M"],
			hyperbeam: ["9M", "9L80", "7M", "7L80", "7S4", "6M", "6L80", "6S3", "5M", "5L80", "5S1", "4M", "4L80"],
			hypervoice: ["9M", "9L30", "7T", "7L30", "6T", "6L30", "5T", "5L30", "4L30"],
			icebeam: ["9M", "7M", "6M", "5M", "4M"],
			icywind: ["9M", "7T", "6T", "5T", "4T"],
			imprison: ["9M"],
			incinerate: ["6M", "5M"],
			irondefense: ["9M", "7T", "6T", "5T", "4T"],
			ironhead: ["9M", "7T", "6T", "5T", "4T"],
			irontail: ["7T", "6T", "5T", "4M"],
			judgment: ["9L100", "7L100", "7S4", "6L100", "6S2", "6S3", "5L100", "5S1", "4L100", "4S0"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T", "4T"],
			lightscreen: ["9M", "7M", "6M", "5M", "4M"],
			liquidation: ["9M", "7T"],
			magicalleaf: ["9M"],
			magiccoat: ["7T", "6T", "5T", "4T"],
			mistyterrain: ["9M"],
			mudslap: ["4T"],
			naturalgift: ["7L1", "6L1", "5L1", "4M", "4L1"],
			ominouswind: ["4T"],
			outrage: ["9M", "7T", "6T", "5T", "4T"],
			overheat: ["9M", "7M", "6M", "5M", "4M"],
			payback: ["7M", "6M", "5M", "4M"],
			perishsong: ["9L90", "7L90", "6L90", "6S3", "5L90", "5S1", "4L90"],
			phantomforce: ["9M"],
			poisonjab: ["9M", "7M", "6M", "5M", "4M"],
			powergem: ["9M"],
			protect: ["9M", "7M", "6M", "5M", "4M"],
			psychic: ["9M", "7M", "6M", "5M", "4M"],
			psychicterrain: ["9M"],
			psychup: ["7M", "6M", "5M", "4M"],
			psyshock: ["9M", "7M", "6M", "5M"],
			punishment: ["7L1", "6L1", "5L1", "4L1"],
			quash: ["7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M", "4M"],
			recover: ["9L70", "7L70", "7S4", "6L70", "6S3", "5L70", "5S1", "4L70"],
			recycle: ["7T", "6T", "5T", "4M"],
			reflect: ["9M", "7M", "6M", "5M", "4M"],
			refresh: ["7L50", "6L50", "5L50", "4L50"],
			rest: ["9M", "7M", "6M", "5M", "4M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M", "4M"],
			roar: ["7M", "6M", "5M", "4M"],
			roaroftime: ["4S0"],
			rockclimb: ["4M"],
			rockslide: ["9M", "7M", "6M", "5M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["9M", "7M", "6M", "5M", "4M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M", "4M"],
			sandstorm: ["9M", "7M", "6M", "5M", "4M"],
			scaryface: ["9M"],
			secretpower: ["6M", "4M"],
			seismictoss: ["9L1", "7L1", "6L1", "5L1", "4L1"],
			shadowball: ["9M", "7M", "6M", "5M", "4M"],
			shadowclaw: ["9M", "7M", "6M", "5M", "4M"],
			shadowforce: ["4S0"],
			shockwave: ["7T", "6T", "4M"],
			signalbeam: ["7T", "6T", "5T", "4T"],
			silverwind: ["4M"],
			sleeptalk: ["9M", "7M", "6M", "5T", "4M"],
			sludgebomb: ["9M", "7M", "6M", "5M", "4M"],
			snarl: ["9M", "7M", "6M", "5M"],
			snore: ["7T", "6T", "5T", "4T"],
			solarbeam: ["9M", "7M", "6M", "5M", "4M"],
			spacialrend: ["4S0"],
			stealthrock: ["9M", "7T", "6T", "5T", "4M"],
			steelbeam: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "7M", "6M", "5M", "4M"],
			storedpower: ["9M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["9M", "7M", "6M", "5M", "4M"],
			sunnyday: ["9M", "7M", "6M", "5M", "4M"],
			surf: ["9M", "7M", "6M", "5M", "4M"],
			swagger: ["7M", "6M", "5M", "4M"],
			swift: ["9M", "4T"],
			swordsdance: ["9M", "7M", "6M", "5M", "4M"],
			tailwind: ["9M", "7T", "6T", "5T", "4T"],
			takedown: ["9M"],
			taunt: ["9M"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunder: ["9M", "7M", "6M", "5M", "4M"],
			thunderbolt: ["9M", "7M", "6M", "5M", "4M"],
			thunderwave: ["9M", "7M", "6M", "5M", "4M"],
			toxic: ["7M", "6M", "5M", "4M"],
			trailblaze: ["9M"],
			trick: ["9M", "7T", "6T", "5T", "4T"],
			trickroom: ["9M", "7M", "6M", "5M", "4M"],
			twister: ["4T"],
			waterfall: ["9M", "7M", "6M", "5M", "4M"],
			waterpulse: ["9M", "7T", "6T", "4M"],
			whirlpool: ["4M"],
			wildcharge: ["9M"],
			willowisp: ["9M", "7M", "6M", "5M", "4M"],
			workup: ["7M", "5M"],
			xscissor: ["9M", "7M", "6M", "5M", "4M"],
			zenheadbutt: ["9M", "7T", "6T", "5T", "4T"],
		},
		eventData: [
			{generation: 4, level: 100, moves: ["judgment", "roaroftime", "spacialrend", "shadowforce"], pokeball: "cherishball"},
			{generation: 5, level: 100, moves: ["recover", "hyperbeam", "perishsong", "judgment"]},
			{generation: 6, level: 100, shiny: 1, moves: ["judgment", "blastburn", "hydrocannon", "earthpower"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["judgment", "perishsong", "hyperbeam", "recover"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["judgment", "extremespeed", "recover", "hyperbeam"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	arceusbug: {
		eventOnly: true,
	},
	arceusdark: {
		eventOnly: true,
	},
	arceusdragon: {
		eventOnly: true,
	},
	arceuselectric: {
		eventOnly: true,
	},
	arceusfairy: {
		eventOnly: true,
	},
	arceusfighting: {
		eventOnly: true,
	},
	arceusfire: {
		eventOnly: true,
	},
	arceusflying: {
		eventOnly: true,
	},
	arceusghost: {
		eventOnly: true,
	},
	arceusgrass: {
		eventOnly: true,
	},
	arceusground: {
		eventOnly: true,
	},
	arceusice: {
		eventOnly: true,
	},
	arceuspoison: {
		eventOnly: true,
	},
	arceuspsychic: {
		eventOnly: true,
	},
	arceusrock: {
		eventOnly: true,
	},
	arceussteel: {
		eventOnly: true,
	},
	arceuswater: {
		eventOnly: true,
	},
	victini: {
		learnset: {
			batonpass: ["8M"],
			blazekick: ["8M"],
			blueflare: ["5S2"],
			boltstrike: ["5S2"],
			bounce: ["8M", "7T", "6T", "5T"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			celebrate: ["7S6"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "6S3", "6S4", "5L1", "5S0"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleedge: ["8L70", "7L65", "6L65", "5L65"],
			doubleteam: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			encore: ["8M"],
			endure: ["8M", "8L35", "7L9", "6L9", "6S4", "5L9", "5S0"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M"],
			finalgambit: ["8L91", "7L81", "6L81", "5L81"],
			fireblast: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			firespin: ["8M"],
			flameburst: ["7L41", "6L41", "5L41"],
			flamecharge: ["8L1", "8S7", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flareblitz: ["8M", "8L77", "7L73", "6L73", "5L73"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M", "8L1", "7L1", "6L1", "5L1"],
			frustration: ["7M", "6M", "5M"],
			fusionbolt: ["5S1"],
			fusionflare: ["5S1"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			glaciate: ["5S2"],
			grassknot: ["8M", "7M", "6M", "5M"],
			guardswap: ["8M"],
			headbutt: ["8L28", "7L17", "6L17", "5L17"],
			heatwave: ["8M", "7T", "6T", "5T"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			incinerate: ["8L14", "7L1", "6M", "6L1", "6S4", "5M", "5L1", "5S0"],
			inferno: ["8L49", "7L57", "6L57", "5L57"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mysticalfire: ["8M"],
			overheat: ["8M", "8L84", "7M", "7L97", "6M", "6L97", "5M", "5L97"],
			powerswap: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "6S3", "6S4", "6S5", "5L1", "5S0"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			reversal: ["8M", "8L56", "7L33", "7S6", "6L33", "5L33"],
			rocksmash: ["6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scorchingsands: ["8T"],
			searingshot: ["8L63", "7L1", "6L1", "6S3", "5L1", "5S1"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			speedswap: ["8M"],
			storedpower: ["8M", "8L21", "7L89", "7S6", "6L89", "5L89"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "6S5", "5M"],
			swift: ["8M"],
			taunt: ["8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			vcreate: ["8L1", "8S7", "7S6", "6S3", "6S5", "5S1", "5S2"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "8L7", "8S7", "7M", "5M"],
			zenheadbutt: ["8M", "8L42", "8S7", "7T", "7L49", "6T", "6L49", "5T", "5L49"],
		},
		eventData: [
			{generation: 5, level: 15, moves: ["quickattack", "incinerate", "confusion", "endure"]},
			{generation: 5, level: 50, moves: ["vcreate", "fusionflare", "fusionbolt", "searingshot"], pokeball: "cherishball"},
			{generation: 5, level: 100, moves: ["vcreate", "blueflare", "boltstrike", "glaciate"], pokeball: "cherishball"},
			{generation: 6, level: 15, moves: ["confusion", "quickattack", "vcreate", "searingshot"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["incinerate", "quickattack", "endure", "confusion"], pokeball: "cherishball"},
			{generation: 6, level: 15, moves: ["quickattack", "swagger", "vcreate"], pokeball: "cherishball"},
			{generation: 7, level: 15, moves: ["vcreate", "reversal", "storedpower", "celebrate"], pokeball: "cherishball"},
			{generation: 8, level: 50, nature: "Brave", perfectIVs: 6, moves: ["vcreate", "zenheadbutt", "workup", "flamecharge"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	snivy: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			aromatherapy: ["5S0"],
			attract: ["7M", "6M", "5M"],
			bind: ["7T", "6T", "5T"],
			calmmind: ["7M", "6M", "5M"],
			captivate: ["7E", "6E", "5E"],
			coil: ["7L31", "6L31", "5L31"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			energyball: ["7M", "6M", "5M", "5S0"],
			facade: ["7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["7T", "7L40", "6T", "6L40", "5T", "5L40"],
			gigadrain: ["7T", "7L34", "6T", "6L34", "5T", "5L34"],
			glare: ["7E", "6E", "5E"],
			grassknot: ["7M", "6M", "5M"],
			grasspledge: ["7T", "6T", "5T"],
			grassyterrain: ["7E", "6E"],
			growth: ["7L13", "6L13", "5L13", "5S0"],
			hiddenpower: ["7M", "6M", "5M"],
			irontail: ["7T", "7E", "6T", "6E", "5T", "5E"],
			knockoff: ["7T", "6T", "5T"],
			leafblade: ["7L28", "6L28", "5L28"],
			leafstorm: ["7L43", "6L43", "5L43"],
			leaftornado: ["7L16", "6L16", "5L16"],
			leechseed: ["7L19", "6L19", "5L19"],
			leer: ["7L4", "6L4", "5L4"],
			lightscreen: ["7M", "6M", "5M"],
			magicalleaf: ["7E", "6E", "5E"],
			meanlook: ["7E", "6E", "5E"],
			megadrain: ["7L22", "6L22", "5L22"],
			mirrorcoat: ["7E", "6E", "5E"],
			naturalgift: ["7E", "6E", "5E"],
			naturepower: ["7M", "6M"],
			protect: ["7M", "6M", "5M"],
			pursuit: ["7E", "6E", "5E"],
			reflect: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["7T", "6T", "5T"],
			slam: ["7L25", "6L25", "5L25"],
			sleeptalk: ["7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			sweetscent: ["7E", "6E", "5E"],
			swordsdance: ["7M", "6M", "5M"],
			synthesis: ["7T", "6T", "5T", "5S0"],
			tackle: ["7L1", "6L1", "5L1"],
			taunt: ["7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			twister: ["7E", "6E", "5E"],
			vinewhip: ["7L7", "6L7", "5L7"],
			workup: ["7M"],
			worryseed: ["7T", "6T", "5T"],
			wrap: ["7L10", "6L10", "5L10"],
			wringout: ["7L37", "6L37", "5L37"],
		},
		eventData: [
			{generation: 5, level: 5, gender: "M", nature: "Hardy", moves: ["growth", "synthesis", "energyball", "aromatherapy"], pokeball: "cherishball"},
		],
	},
	servine: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			attract: ["7M", "6M", "5M"],
			bind: ["7T", "6T", "5T"],
			calmmind: ["7M", "6M", "5M"],
			coil: ["7L36", "6L36", "5L36"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			energyball: ["7M", "6M", "5M"],
			facade: ["7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["7T", "7L48", "6T", "6L48", "5T", "5L48"],
			gigadrain: ["7T", "7L40", "6T", "6L40", "5T", "5L40"],
			grassknot: ["7M", "6M", "5M"],
			grasspledge: ["7T", "6T", "5T"],
			growth: ["7L13", "6L13", "5L13"],
			hiddenpower: ["7M", "6M", "5M"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leafblade: ["7L32", "6L32", "5L32"],
			leafstorm: ["7L52", "6L52", "5L52"],
			leaftornado: ["7L16", "6L16", "5L16"],
			leechseed: ["7L20", "6L20", "5L20"],
			leer: ["7L1", "6L1", "5L1"],
			lightscreen: ["7M", "6M", "5M"],
			megadrain: ["7L24", "6L24", "5L24"],
			naturepower: ["7M", "6M"],
			protect: ["7M", "6M", "5M"],
			reflect: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["7T", "6T", "5T"],
			slam: ["7L28", "6L28", "5L28"],
			sleeptalk: ["7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["7M", "6M", "5M"],
			synthesis: ["7T", "6T", "5T"],
			tackle: ["7L1", "6L1", "5L1"],
			taunt: ["7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			vinewhip: ["7L1", "6L1", "5L1"],
			workup: ["7M"],
			worryseed: ["7T", "6T", "5T"],
			wrap: ["7L1", "6L1", "5L1"],
			wringout: ["7L44", "6L44", "5L44"],
		},
	},
	serperior: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			attract: ["7M", "6M", "5M"],
			bind: ["7T", "6T", "5T"],
			brutalswing: ["7M"],
			calmmind: ["7M", "6M", "5M"],
			coil: ["7L38", "6L38", "5L38"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			dragonpulse: ["7T", "6T", "5T"],
			dragontail: ["7M", "6M", "5M"],
			energyball: ["7M", "6M", "5M"],
			facade: ["7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frenzyplant: ["7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["7T", "7L56", "6T", "6L56", "5T", "5L56"],
			gigadrain: ["7T", "7L44", "6T", "6L44", "6S1", "5T", "5L44", "5S0"],
			gigaimpact: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			grasspledge: ["7T", "6T", "5T"],
			growth: ["7L13", "6L13", "5L13"],
			hiddenpower: ["7M", "6M", "5M"],
			holdback: ["6S1"],
			hyperbeam: ["7M", "6M", "5M"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leafblade: ["7L32", "6L32", "5L32"],
			leafstorm: ["7L62", "6L62", "6S1", "5L62", "5S0"],
			leaftornado: ["7L16", "6L16", "5L16"],
			leechseed: ["7L20", "6L20", "5L20", "5S0"],
			leer: ["7L1", "6L1", "5L1"],
			lightscreen: ["7M", "6M", "5M"],
			megadrain: ["7L24", "6L24", "5L24"],
			naturepower: ["7M", "6M"],
			outrage: ["7T", "6T", "5T"],
			protect: ["7M", "6M", "5M"],
			reflect: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["7T", "6T", "5T"],
			slam: ["7L28", "6L28", "5L28"],
			sleeptalk: ["7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["7M", "6M", "5M", "5S0"],
			sunnyday: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["7M", "6M", "5M"],
			synthesis: ["7T", "6T", "5T"],
			tackle: ["7L1", "6L1", "5L1"],
			taunt: ["7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			vinewhip: ["7L1", "6L1", "5L1"],
			workup: ["7M"],
			worryseed: ["7T", "6T", "5T"],
			wrap: ["7L1", "6L1", "5L1"],
			wringout: ["7L50", "6L50", "6S1", "5L50"],
		},
		eventData: [
			{generation: 5, level: 100, gender: "M", moves: ["leafstorm", "substitute", "gigadrain", "leechseed"], pokeball: "cherishball"},
			{generation: 6, level: 50, isHidden: true, moves: ["leafstorm", "holdback", "wringout", "gigadrain"], pokeball: "cherishball"},
		],
	},
	tepig: {
		learnset: {
			assurance: ["7L31", "6L31", "5L31"],
			attract: ["7M", "6M", "5M"],
			bodyslam: ["7E", "6E", "5E"],
			burnup: ["7E"],
			confide: ["7M", "6M"],
			covet: ["7T", "7E", "6T", "6E", "5T", "5E"],
			curse: ["7E", "6E", "5E"],
			defensecurl: ["7L13", "6L13", "5L13"],
			doubleteam: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["7L7", "6L7", "5L7"],
			endeavor: ["7T", "7E", "6T", "6E", "5T", "5E"],
			facade: ["7M", "6M", "5M"],
			fireblast: ["7M", "6M", "5M"],
			firepledge: ["7T", "6T", "5T"],
			flamecharge: ["7M", "7L15", "6M", "6L15", "5M", "5L15"],
			flamethrower: ["7M", "7L33", "6M", "6L33", "5M", "5L33"],
			flareblitz: ["7L43", "6L43", "5L43"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			gyroball: ["7M", "6M", "5M"],
			headsmash: ["7L37", "6L37", "5L37"],
			heatcrash: ["7L27", "6L27", "5L27"],
			heatwave: ["7T", "6T", "5T"],
			heavyslam: ["7E", "6E", "5E"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T"],
			magnitude: ["7E", "6E", "5E"],
			odorsleuth: ["7L9", "6L9", "5L9"],
			overheat: ["7M", "6M", "5M"],
			protect: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["7M", "7L39", "6M", "6L39", "5M", "5L39"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M"],
			rollout: ["7L21", "6L21", "5L21"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["7M", "7E", "6M", "6E", "5T", "5E"],
			smog: ["7L19", "6L19", "5L19"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			stompingtantrum: ["7T"],
			strength: ["6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			suckerpunch: ["7E", "6E"],
			sunnyday: ["7M", "6M", "5M"],
			superpower: ["7T", "7E", "6T", "6E", "5T", "5E"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["7L1", "6L1", "5L1"],
			tailwhip: ["7L3", "6L3", "5L3"],
			takedown: ["7L25", "6L25", "5L25"],
			taunt: ["7M", "6M", "5M"],
			thrash: ["7E", "6E", "5E"],
			toxic: ["7M", "6M", "5M"],
			wildcharge: ["7M", "6M", "5M"],
			willowisp: ["7M", "6M", "5M"],
			workup: ["7M"],
			yawn: ["7E", "6E", "5E"],
			zenheadbutt: ["7T", "6T"],
		},
	},
	pignite: {
		learnset: {
			armthrust: ["7L1", "6L17", "5L17"],
			assurance: ["7L36", "6L36", "5L36"],
			attract: ["7M", "6M", "5M"],
			brickbreak: ["7M", "6M", "5M"],
			bulldoze: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			defensecurl: ["7L13", "6L13", "5L13"],
			doubleteam: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["7L1", "6L1", "5L1"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			fireblast: ["7M", "6M", "5M"],
			firepledge: ["7T", "6T", "5T"],
			firepunch: ["7T", "6T", "5T"],
			flamecharge: ["7M", "7L15", "6M", "6L15", "5M", "5L15"],
			flamethrower: ["7M", "7L39", "6M", "6L39", "5M", "5L39"],
			flareblitz: ["7L52", "6L52", "5L52"],
			fling: ["7M", "6M", "5M"],
			focusblast: ["7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			gyroball: ["7M", "6M", "5M"],
			headsmash: ["7L44", "6L44", "5L44"],
			heatcrash: ["7L31", "6L31", "5L31"],
			heatwave: ["7T", "6T", "5T"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T"],
			lowkick: ["7T", "6T", "5T"],
			lowsweep: ["7M", "6M", "5M"],
			odorsleuth: ["7L1", "6L1", "5L1"],
			overheat: ["7M", "6M", "5M"],
			poisonjab: ["7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["7M", "7L47", "6M", "6L47", "5M", "5L47"],
			rockslide: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M"],
			rollout: ["7L23", "6L23", "5L23"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["7M", "6M", "5T"],
			smog: ["7L20", "6L20", "5L20"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			superpower: ["7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["7L1", "6L1", "5L1"],
			tailwhip: ["7L1", "6L1", "5L1"],
			takedown: ["7L28", "6L28", "5L28"],
			taunt: ["7M", "6M", "5M"],
			thunderpunch: ["7T", "6T", "5T"],
			toxic: ["7M", "6M", "5M"],
			wildcharge: ["7M", "6M", "5M"],
			willowisp: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zenheadbutt: ["7T", "6T"],
		},
	},
	emboar: {
		learnset: {
			armthrust: ["7L1", "6L17", "5L17"],
			assurance: ["7L38", "6L38", "5L38"],
			attract: ["7M", "6M", "5M"],
			blastburn: ["7T", "6T", "5T"],
			block: ["7T", "6T", "5T"],
			brickbreak: ["7M", "6M", "5M"],
			bulkup: ["7M", "6M", "5M"],
			bulldoze: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			defensecurl: ["7L13", "6L13", "5L13"],
			doubleteam: ["7M", "6M", "5M"],
			earthquake: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			ember: ["7L1", "6L1", "5L1"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			fireblast: ["7M", "6M", "5M"],
			firepledge: ["7T", "6T", "5T"],
			firepunch: ["7T", "6T", "5T"],
			flamecharge: ["7M", "7L15", "6M", "6L15", "5M", "5L15"],
			flamethrower: ["7M", "7L43", "6M", "6L43", "5M", "5L43"],
			flareblitz: ["7L62", "6L62", "6S1", "5L62", "5S0"],
			fling: ["7M", "6M", "5M"],
			focusblast: ["7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			gyroball: ["7M", "6M", "5M"],
			hammerarm: ["7L1", "6L1", "5L1", "5S0"],
			headsmash: ["7L50", "6L50", "6S1", "5L50", "5S0"],
			heatcrash: ["7L31", "6L31", "5L31"],
			heatwave: ["7T", "6T", "5T"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			holdback: ["6S1"],
			hyperbeam: ["7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			ironhead: ["7T", "6T", "5T"],
			irontail: ["7T", "6T", "5T"],
			lowkick: ["7T", "6T", "5T"],
			lowsweep: ["7M", "6M", "5M"],
			odorsleuth: ["7L1", "6L1", "5L1"],
			overheat: ["7M", "6M", "5M"],
			poisonjab: ["7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["7M", "7L55", "6M", "6L55", "5M", "5L55"],
			rockslide: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M"],
			rollout: ["7L23", "6L23", "5L23"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["7M", "6M", "5T"],
			smackdown: ["7M", "6M", "5M"],
			smog: ["7L20", "6L20", "5L20"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			stompingtantrum: ["7T"],
			stoneedge: ["7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			superpower: ["7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["7L1", "6L1", "5L1"],
			tailwhip: ["7L1", "6L1", "5L1"],
			takedown: ["7L28", "6L28", "6S1", "5L28"],
			taunt: ["7M", "6M", "5M"],
			thunderpunch: ["7T", "6T", "5T"],
			toxic: ["7M", "6M", "5M"],
			wildcharge: ["7M", "6M", "5M", "5S0"],
			willowisp: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zenheadbutt: ["7T", "6T"],
		},
		eventData: [
			{generation: 5, level: 100, gender: "M", moves: ["flareblitz", "hammerarm", "wildcharge", "headsmash"], pokeball: "cherishball"},
			{generation: 6, level: 50, isHidden: true, moves: ["flareblitz", "holdback", "headsmash", "takedown"], pokeball: "cherishball"},
		],
	},
	oshawott: {
		learnset: {
			aerialace: ["9M", "9L25", "7M", "6M", "5M"],
			airslash: ["9M", "9E", "7E", "6E", "5E"],
			aquacutter: ["9E"],
			aquajet: ["9L29", "7L29", "6L29", "5L29"],
			aquatail: ["9L35", "7T", "7L35", "6T", "6L35", "5T", "5L35"],
			assurance: ["7E", "6E", "5E"],
			attract: ["7M", "6M", "5M"],
			avalanche: ["9M"],
			blizzard: ["9M", "7M", "6M", "5M"],
			brine: ["7E", "6E", "5E"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9E", "7E", "6E", "5E"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			detect: ["9E", "7E", "6E", "5E"],
			dig: ["9M", "6M", "5M"],
			dive: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			encore: ["9M", "9L31", "7L31", "6L31", "5L31"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M", "5M"],
			falseswipe: ["9M", "7M", "6M", "5M"],
			fling: ["9M", "7M", "6M", "5M"],
			focusenergy: ["9L13", "7L13", "6L13", "5L13"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["9L19", "7L19", "6L19", "5L19"],
			grassknot: ["9M", "7M", "6M", "5M"],
			hail: ["7M", "6M", "5M"],
			helpinghand: ["9M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["9M", "9L43", "7L43", "6L43", "5L43"],
			icebeam: ["9M", "7M", "6M", "5M"],
			icywind: ["9M", "7T", "6T", "5T"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["9E"],
			liquidation: ["9M"],
			nightslash: ["9E", "7E", "6E", "5E"],
			protect: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M"],
			razorshell: ["9L17", "7L17", "6L17", "5L17"],
			rest: ["9M", "7M", "6M", "5M"],
			retaliate: ["9L37", "7L37", "6M", "6L37", "5M", "5L37"],
			return: ["7M", "6M", "5M"],
			revenge: ["7L25", "6L25", "5L25"],
			rocksmash: ["6M", "5M"],
			round: ["7M", "6M", "5M"],
			sacredsword: ["9E", "7E"],
			scald: ["7M", "6M", "5M"],
			screech: ["9E", "7E", "6E", "5E"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			snowscape: ["9M"],
			soak: ["9L11"],
			substitute: ["9M", "7M", "6M", "5M"],
			surf: ["9M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M"],
			swordsdance: ["9M", "9L41", "7M", "7L41", "6M", "6L41", "5M", "5L41"],
			tackle: ["9L1", "7L1", "6L1", "5L1"],
			tailwhip: ["9L5", "7L5", "6L5", "5L5"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "6M", "5M"],
			trumpcard: ["7E", "6E", "5E"],
			waterfall: ["9M", "7M", "6M", "5M"],
			watergun: ["9L7", "7L7", "6L7", "5L7"],
			waterpledge: ["9M", "7T", "6T", "5T"],
			waterpulse: ["9M", "9L23", "7T", "7L23", "6T", "6L23", "5L23"],
			watersport: ["7L11", "6L11", "5L11"],
			workup: ["7M"],
			xscissor: ["9M", "7M", "6M", "5M"],
		},
	},
	dewott: {
		learnset: {
			aerialace: ["9M", "9L29", "7M", "6M", "5M"],
			airslash: ["9M"],
			aquajet: ["9L34", "7L34", "6L33", "5L33"],
			aquatail: ["9L42", "7T", "7L42", "6T", "6L41", "5T", "5L41"],
			attract: ["7M", "6M", "5M"],
			avalanche: ["9M"],
			blizzard: ["9M", "7M", "6M", "5M"],
			brickbreak: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			dig: ["9M", "6M", "5M"],
			dive: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			encore: ["9M", "9L37", "7L37", "6L36", "5L36"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M", "5M"],
			falseswipe: ["9M", "7M", "6M", "5M"],
			fling: ["9M", "7M", "6M", "5M"],
			focusenergy: ["9L13", "7L13", "6L13", "5L13"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["9L21", "7L21", "6L20", "5L20"],
			grassknot: ["9M", "7M", "6M", "5M"],
			hail: ["7M", "6M", "5M"],
			helpinghand: ["9M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["9M", "9L53", "7L53", "6L52", "5L52"],
			icebeam: ["9M", "7M", "6M", "5M"],
			icywind: ["9M", "7T", "6T", "5T"],
			irontail: ["7T", "6T", "5T"],
			liquidation: ["9M"],
			protect: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M"],
			razorshell: ["9L18", "7L18", "6L17", "5L17"],
			rest: ["9M", "7M", "6M", "5M"],
			retaliate: ["9L45", "7L45", "6M", "6L44", "5M", "5L44"],
			return: ["7M", "6M", "5M"],
			revenge: ["7L29", "6L28", "5L28"],
			rocksmash: ["6M", "5M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			snowscape: ["9M"],
			soak: ["9L1"],
			substitute: ["9M", "7M", "6M", "5M"],
			surf: ["9M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M"],
			swordsdance: ["9M", "9L50", "7M", "7L50", "6M", "6L49", "5M", "5L49"],
			tackle: ["9L1", "7L1", "6L1", "5L1"],
			tailwhip: ["9L1", "7L1", "6L1", "5L1"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "6M", "5M"],
			waterfall: ["9M", "7M", "6M", "5M"],
			watergun: ["9L1", "7L1", "6L1", "5L1"],
			waterpledge: ["9M", "7T", "6T", "5T"],
			waterpulse: ["9M", "9L26", "7T", "7L26", "6T", "6L25", "5L25"],
			watersport: ["7L1", "6L1", "5L1"],
			workup: ["7M"],
			xscissor: ["9M", "7M", "6M", "5M"],
		},
	},
	samurott: {
		learnset: {
			aerialace: ["9M", "9L29", "7M", "6M", "5M"],
			airslash: ["9M"],
			aquajet: ["9L34", "7L34", "6L33", "5L33"],
			aquatail: ["9L46", "7T", "7L46", "6T", "6L45", "5T", "5L45"],
			attract: ["7M", "6M", "5M"],
			avalanche: ["9M"],
			blizzard: ["9M", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M", "6S1"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			dig: ["9M", "6M", "5M"],
			dive: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dragontail: ["7M", "6M", "5M"],
			drillrun: ["9M"],
			encore: ["9M", "9L39", "7L39", "6L38", "5L38"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M", "5M"],
			falseswipe: ["9M", "7M", "6M", "5M"],
			fling: ["9M", "7M", "6M", "5M"],
			focusenergy: ["9L13", "7L13", "6L13", "5L13"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["9L21", "7L21", "6L20", "5L20"],
			gigaimpact: ["9M", "7M", "6M", "5M"],
			grassknot: ["9M", "7M", "6M", "5M"],
			hail: ["7M", "6M", "5M"],
			helpinghand: ["9M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			holdback: ["6S1"],
			hydrocannon: ["9M", "7T", "6T", "5T"],
			hydropump: ["9M", "9L63", "7L63", "6L62", "6S1", "5L62", "5S0"],
			hyperbeam: ["9M", "7M", "6M", "5M"],
			icebeam: ["9M", "7M", "6M", "5M", "5S0"],
			icywind: ["9M", "7T", "6T", "5T"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			liquidation: ["9M", "7T"],
			megahorn: ["9L1", "7L1", "6L1", "5L1", "5S0"],
			protect: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M"],
			razorshell: ["9L18", "7L18", "6L17", "6S1", "5L17"],
			rest: ["9M", "7M", "6M", "5M"],
			retaliate: ["9L51", "7L51", "6M", "6L50", "5M", "5L50"],
			return: ["7M", "6M", "5M"],
			revenge: ["7L29", "6L28", "5L28"],
			rocksmash: ["6M", "5M"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			slash: ["9L0", "7L1", "6L36", "5L36"],
			sleeptalk: ["9M", "7M", "6M", "5T"],
			smartstrike: ["9M", "7M"],
			snore: ["7T", "6T", "5T"],
			snowscape: ["9M"],
			soak: ["9L1"],
			strength: ["6M", "5M"],
			substitute: ["9M", "7M", "6M", "5M"],
			superpower: ["7T", "6T", "5T", "5S0"],
			surf: ["9M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M"],
			swordsdance: ["9M", "9L58", "7M", "7L58", "6M", "6L57", "5M", "5L57"],
			tackle: ["9L1", "7L1", "6L1", "5L1"],
			tailwhip: ["9L1", "7L1", "6L1", "5L1"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["7M", "6M", "5M"],
			waterfall: ["9M", "7M", "6M", "5M"],
			watergun: ["9L1", "7L1", "6L1", "5L1"],
			waterpledge: ["9M", "7T", "6T", "5T"],
			waterpulse: ["9M", "9L25", "7T", "7L25", "6T", "6L25", "5L25"],
			watersport: ["7L1", "6L1", "5L1"],
			workup: ["7M"],
			xscissor: ["9M", "7M", "6M", "5M"],
		},
		eventData: [
			{generation: 5, level: 100, gender: "M", moves: ["hydropump", "icebeam", "megahorn", "superpower"], pokeball: "cherishball"},
			{generation: 6, level: 50, isHidden: true, moves: ["razorshell", "holdback", "confide", "hydropump"], pokeball: "cherishball"},
		],
	},
	samurotthisui: {
		learnset: {
			aerialace: ["9M", "9L29"],
			airslash: ["9M"],
			aquajet: ["9L34"],
			aquatail: ["9L46"],
			avalanche: ["9M"],
			blizzard: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			ceaselessedge: ["9L0"],
			chillingwater: ["9M"],
			darkpulse: ["9M"],
			dig: ["9M"],
			drillrun: ["9M"],
			encore: ["9M", "9L39"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			fling: ["9M"],
			focusenergy: ["9L13"],
			furycutter: ["9L21"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			helpinghand: ["9M"],
			hydrocannon: ["9M"],
			hydropump: ["9M", "9L63"],
			hyperbeam: ["9M"],
			icebeam: ["9M"],
			icywind: ["9M"],
			liquidation: ["9M"],
			megahorn: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			razorshell: ["9L18"],
			rest: ["9M"],
			retaliate: ["9L51"],
			scaryface: ["9M"],
			slash: ["9L1"],
			sleeptalk: ["9M"],
			smartstrike: ["9M"],
			snarl: ["9M"],
			snowscape: ["9M"],
			substitute: ["9M"],
			suckerpunch: ["9L1"],
			surf: ["9M"],
			swift: ["9M"],
			swordsdance: ["9M", "9L58"],
			tackle: ["9L1"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpledge: ["9M"],
			waterpulse: ["9M", "9L25"],
			xscissor: ["9M"],
		},
	},
	patrat: {
		learnset: {
			afteryou: ["7T", "7L23", "6T", "6L23", "5T", "5L23"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["7E", "6E", "5E"],
			attract: ["7M", "6M", "5M"],
			batonpass: ["7L38", "6L33", "5L33"],
			bide: ["7L8", "6L8", "5L8"],
			bite: ["7L6", "6L6", "5L6"],
			bulletseed: ["7E"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["7L16", "6L16", "5L16"],
			cut: ["6M", "5M"],
			detect: ["7L11", "6L11", "5L11"],
			dig: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			flail: ["7E", "6E", "5E"],
			fling: ["7M", "6M", "5M"],
			focusenergy: ["7L26"],
			foresight: ["7E", "6E", "5E"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			gunkshot: ["7T", "6T", "5T"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperfang: ["7L31", "6L28", "5L28"],
			hypnosis: ["7L18", "6L18", "5L18"],
			irontail: ["7T", "7E", "6T", "6E", "5T", "5E"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T"],
			leer: ["7L3", "6L3", "5L3"],
			lowkick: ["7T", "6T", "5T"],
			meanlook: ["7L36", "6L31", "5L31"],
			nastyplot: ["7L33"],
			protect: ["7M", "6M", "5M"],
			pursuit: ["7E", "6E", "5E"],
			raindance: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["7E", "6E", "5E"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7L13", "6L13", "5L13"],
			screech: ["7E", "6E", "5E"],
			secretpower: ["6M"],
			seedbomb: ["7T", "6T", "5T"],
			shadowball: ["7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			slam: ["7L41", "6L36", "5L36"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			superfang: ["7T", "7L21", "6T", "6L21", "5T", "5L21"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["7M", "6M", "5M"],
			tackle: ["7L1", "6L1", "5L1"],
			tearfullook: ["7E"],
			thunderbolt: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			workup: ["7M", "7L28", "6L26", "5M", "5L26"],
			zenheadbutt: ["7T", "6T", "5T"],
		},
	},
	watchog: {
		learnset: {
			afteryou: ["7T", "7L25", "6T", "6L25", "5T", "5L25"],
			aquatail: ["7T", "6T", "5T"],
			attract: ["7M", "6M", "5M"],
			batonpass: ["7L46", "6L39", "5L39"],
			bide: ["7L8", "6L8", "5L8"],
			bite: ["7L1", "6L1", "5L1"],
			confide: ["7M", "6M"],
			confuseray: ["7L1", "6L20", "5L20"],
			covet: ["7T", "6T", "5T"],
			crunch: ["7L16", "6L16", "5L16"],
			cut: ["6M", "5M"],
			detect: ["7L11", "6L11", "5L11"],
			dig: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			firepunch: ["7T", "6T", "5T"],
			flamethrower: ["7M", "6M", "5M"],
			flash: ["6M", "5M"],
			fling: ["7M", "6M", "5M"],
			focusblast: ["7M", "6M", "5M"],
			focusenergy: ["7L29"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			gunkshot: ["7T", "6T", "5T"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["7M", "6M", "5M"],
			hyperfang: ["7L36", "6L32", "5L32"],
			hypnosis: ["7L18", "6L18", "5L18"],
			icepunch: ["7T", "6T", "5T"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T"],
			leer: ["7L1", "6L1", "5L1"],
			lightscreen: ["7M", "6M", "5M"],
			lowkick: ["7T", "7L1", "6T", "6L1", "5T", "5L1"],
			meanlook: ["7L43", "6L36", "5L36"],
			nastyplot: ["7L39"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M"],
			psychup: ["7M", "7L32", "6M", "6L29", "5M", "5L29"],
			raindance: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rototiller: ["7L1", "6L1"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7L13", "6L13", "5L13"],
			secretpower: ["6M"],
			seedbomb: ["7T", "6T", "5T"],
			shadowball: ["7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			slam: ["7L50", "6L43", "5L43"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			stompingtantrum: ["7T"],
			strength: ["6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			superfang: ["7T", "7L22", "6T", "6L22", "5T", "5L22"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["7M", "6M", "5M"],
			tackle: ["7L1", "6L1", "5L1"],
			thunder: ["7M", "6M", "5M"],
			thunderbolt: ["7M", "6M", "5M"],
			thunderpunch: ["7T", "6T", "5T"],
			thunderwave: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			zenheadbutt: ["7T", "6T", "5T"],
		},
	},
	lillipup: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			afteryou: ["8E", "7T", "7E", "6T", "6E"],
			attract: ["8M", "7M", "6M", "5M"],
			babydolleyes: ["8L17", "7L10", "6L10"],
			bite: ["8L8", "7L8", "6L8", "5L8"],
			charm: ["8M", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			covet: ["8E", "7T", "6T", "5T"],
			crunch: ["8M", "8L24", "7L22", "6L22", "5L22"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M", "7E", "6E", "5E"],
			facade: ["8M", "7M", "6M", "5M"],
			firefang: ["8M", "7E", "6E", "5E"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "8L48", "7M", "7L40", "6M", "6L40", "5M", "5L40"],
			helpinghand: ["8M", "8L32", "7T", "7L12", "6T", "6L12", "5T", "5L12"],
			hiddenpower: ["7M", "6M", "5M"],
			howl: ["8E", "7E", "6E", "5E"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icefang: ["8M", "7E", "6E", "5E"],
			lastresort: ["8L44", "7T", "7L36", "6T", "6L36", "5T", "5L36"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			lick: ["8E", "7E", "6E", "5E"],
			mudslap: ["8E", "7E", "6E", "5E"],
			odorsleuth: ["7L5", "6L5", "5L5"],
			payback: ["8M"],
			playrough: ["8M", "8L20", "7L45", "6L45"],
			protect: ["8M", "7M", "6M", "5M"],
			psychicfangs: ["8M", "7E"],
			pursuit: ["7E", "6E", "5E"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "8L12", "7L29", "6M", "6L29", "5M", "5L29"],
			return: ["7M", "6M", "5M"],
			reversal: ["8M", "8L36", "7L33", "6L33", "5L33"],
			roar: ["8L40", "7M", "7L26", "6M", "6L26", "5M", "5L26"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["7E", "6E", "5E"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			takedown: ["8L28", "7L15", "6L15", "5L15"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderfang: ["8M", "7E", "6E", "5E"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "8L4", "7M", "7L19", "6L19", "5M", "5L19"],
			yawn: ["8E", "7E", "6E", "5E"],
		},
	},
	herdier: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			afteryou: ["7T", "6T"],
			attract: ["8M", "7M", "6M", "5M"],
			babydolleyes: ["8L19"],
			bite: ["8L1", "7L1", "6L1", "5L1"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["8M", "8L30", "7L24", "6L24", "5L24"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			firefang: ["8M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "8L66", "7M", "7L47", "6M", "6L47", "5M", "5L47"],
			helpinghand: ["8M", "8L42", "7T", "7L12", "6T", "6L12", "5T", "5L12"],
			hiddenpower: ["7M", "6M", "5M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icefang: ["8M"],
			lastresort: ["8L60", "7T", "7L42", "6T", "6L42", "5T", "5L42"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			odorsleuth: ["7L1", "6L1", "5L1"],
			payback: ["8M", "7M", "6M", "5M"],
			playrough: ["8M", "8L24", "7L52", "6L52"],
			protect: ["8M", "7M", "6M", "5M"],
			psychicfangs: ["8M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "8L12", "7L33", "6M", "6L33", "5M", "5L33"],
			return: ["7M", "6M", "5M"],
			reversal: ["8M", "8L48", "7L38", "6L38", "5L38"],
			roar: ["8L54", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			takedown: ["8L36", "7L15", "6L15", "5L15"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderfang: ["8M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "8L1", "7M", "7L20", "6L20", "5M", "5L20"],
		},
		encounters: [
			{generation: 5, level: 20, isHidden: true},
		],
	},
	stoutland: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			afteryou: ["7T", "6T"],
			attract: ["8M", "7M", "6M", "5M"],
			babydolleyes: ["8L19"],
			bite: ["8L1", "7L1", "6L1", "5L1"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["8M", "8L30", "7L24", "6L24", "5L24"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			firefang: ["8M", "8L1", "7L1", "6L1", "5L1"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "8L78", "7M", "7L59", "6M", "6L59", "5M", "5L59"],
			helpinghand: ["8M", "8L46", "7T", "7L12", "6T", "6L12", "5T", "5L12"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			icefang: ["8M", "8L1", "7L1", "6L1", "5L1"],
			ironhead: ["8M", "7T", "6T", "5T"],
			lastresort: ["8L70", "7T", "7L51", "6T", "6L51", "5T", "5L51"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			odorsleuth: ["7L1", "6L1", "5L1"],
			payback: ["8M", "7M", "6M", "5M"],
			playrough: ["8M", "8L24", "7L63", "6L63"],
			protect: ["8M", "7M", "6M", "5M"],
			psychicfangs: ["8M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "8L12", "7L36", "6M", "6L36", "5M", "5L36"],
			return: ["7M", "6M", "5M"],
			reversal: ["8M", "8L54", "7L42", "6L42", "5L42"],
			roar: ["8L62", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			takedown: ["8L38", "7L15", "6L15", "5L15"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderfang: ["8M", "8L1", "7L1", "6L1", "5L1"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "8L1", "7M", "7L20", "6L20", "5M", "5L20"],
		},
		encounters: [
			{generation: 5, level: 23},
		],
	},
	purrloin: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			assist: ["7L6", "6L6", "5L6"],
			assurance: ["8M", "8L21", "7L28", "6L28", "5L28"],
			attract: ["8M", "7M", "6M", "5M"],
			batonpass: ["8M"],
			captivate: ["7L33", "6L33", "5L33"],
			charm: ["8M", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			copycat: ["8E", "7E", "6E"],
			covet: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			cut: ["6M", "5M"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleteam: ["8E", "7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			encore: ["8M", "7E", "6E", "5E"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			fakeout: ["8L5", "7L21", "6L21", "5L21"],
			faketears: ["8M", "7E", "6E", "5E"],
			feintattack: ["7E", "6E", "5E"],
			foulplay: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["8L12", "7L12", "6L12", "5L12"],
			grassknot: ["8M", "7M", "6M", "5M"],
			growl: ["8L1", "7L3", "6L3", "5L3"],
			gunkshot: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["8L24", "7L24", "6M", "6L24", "5M", "5L24"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lashout: ["8T"],
			nastyplot: ["8M", "8L32", "7L42", "6L42", "5L42"],
			nightslash: ["8L36", "7L37", "6L37", "5L37"],
			payback: ["8M", "7M", "6M", "5M"],
			payday: ["8M", "7E", "6E", "5E"],
			playrough: ["8M", "8L40", "7L49", "6L49"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			pursuit: ["7L15", "6L15", "5L15"],
			quickattack: ["8E"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L4", "7L10", "6L10", "5L10"],
			scratch: ["8L1", "7L1", "6L1", "5L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			slash: ["8E", "7L30", "6L30", "5L30"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "7L39", "6T", "6L39", "5T", "5L39"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			suckerpunch: ["8L28", "7L46", "6L46", "5L46"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			taunt: ["8M", "7M", "6M", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			torment: ["8L16", "7M", "7L19", "6M", "6L19", "5M", "5L19"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			yawn: ["8E", "7E", "6E", "5E"],
		},
	},
	liepard: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			assist: ["7L1", "6L1", "5L1"],
			assurance: ["8M", "8L23", "7L31", "6L31", "5L31"],
			attract: ["8M", "7M", "6M", "5M"],
			batonpass: ["8M"],
			burningjealousy: ["8T"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			encore: ["8M", "5S0"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			fakeout: ["8L1", "7L22", "6L22", "5L22", "5S0"],
			faketears: ["8M"],
			foulplay: ["8M", "7T", "6T", "5T", "5S0"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["8L12", "7L12", "6L12", "5L12"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			growl: ["8L1", "7L1", "6L1", "5L1"],
			gunkshot: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["8L28", "7L26", "6M", "6L26", "5M", "5L26"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			nastyplot: ["8M", "8L40", "7L50", "6L50", "5L50"],
			nightslash: ["8L46", "7L43", "6L43", "5L43"],
			payback: ["8M", "7M", "6M", "5M"],
			payday: ["8M"],
			playrough: ["8M", "8L52", "7L58", "6L58"],
			protect: ["8M", "7M", "6M", "5M"],
			psychocut: ["8M"],
			psychup: ["7M", "6M", "5M"],
			pursuit: ["7L15", "6L15", "5L15"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L1"],
			scratch: ["8L1", "7L1", "6L1", "5L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			skittersmack: ["8T"],
			slash: ["7L34", "6L34", "5L34"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "7L47", "6T", "6L47", "5T", "5L47"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			suckerpunch: ["8L34", "7L55", "6L55", "5L55"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M", "5S0"],
			swift: ["8M"],
			taunt: ["8M", "7M", "7L38", "6M", "6L38", "5M", "5L38"],
			thief: ["8M", "7M", "6M", "5M"],
			throatchop: ["8M", "7T"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			torment: ["8L16", "7M", "7L19", "6M", "6L19", "5M", "5L19"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
		},
		eventData: [
			{generation: 5, level: 20, gender: "F", nature: "Jolly", isHidden: true, moves: ["fakeout", "foulplay", "encore", "swagger"]},
		],
	},
	pansage: {
		learnset: {
			acrobatics: ["7M", "7L31", "6M", "6L31", "5M", "5L31"],
			astonish: ["7E", "6E", "5E"],
			attract: ["7M", "6M", "5M"],
			bite: ["7L19", "6L19", "5L19", "5S0"],
			bulletseed: ["7E", "6E", "5E", "5S0"],
			confide: ["7M", "6M"],
			covet: ["7T", "7E", "6T", "6E", "5T", "5E"],
			crunch: ["7L43", "6L43", "5L43"],
			cut: ["6M", "5M"],
			dig: ["6M", "5M", "5S0", "5S2"],
			disarmingvoice: ["7E", "6E"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			energyball: ["7M", "6M", "5M"],
			facade: ["7M", "6M", "5M"],
			flash: ["6M", "5M"],
			fling: ["7M", "7L28", "6M", "6L28", "5M", "5L28"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["7L13", "6L13", "5L13"],
			gastroacid: ["7T", "6T"],
			gigadrain: ["7T", "6T", "5T"],
			grassknot: ["7M", "7L34", "6M", "6L34", "5M", "5L34"],
			grasspledge: ["7T"],
			grasswhistle: ["7E", "6E", "5E"],
			gunkshot: ["7T", "6T", "5T"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leafstorm: ["7E", "6E", "5E", "5S1"],
			leechseed: ["7L16", "6L16", "5L16"],
			leer: ["7L4", "6L4", "5L4", "5S1"],
			lick: ["7L7", "6L7", "5L7", "5S1"],
			lowkick: ["7T", "7E", "6T", "6E", "5T", "5E"],
			lowsweep: ["7M", "6M", "5M"],
			magicalleaf: ["7E", "6E", "5E"],
			nastyplot: ["7E", "6E", "5E"],
			naturalgift: ["7L40", "6L40", "5L40"],
			naturepower: ["7M", "6M"],
			payback: ["7M", "6M", "5M"],
			playnice: ["7L1", "6L1"],
			protect: ["7M", "6M", "5M"],
			recycle: ["7T", "7L37", "6T", "6L37", "5T", "5L37"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M", "5S2"],
			roleplay: ["7T", "7E", "6T", "6E", "5T", "5E"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			seedbomb: ["7T", "7L22", "6T", "6L22", "5T", "5L22", "5S2"],
			shadowclaw: ["7M", "6M", "5M"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M", "5S0", "5S2"],
			spikyshield: ["7E"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			synthesis: ["7T", "6T", "5T"],
			taunt: ["7M", "6M", "5M"],
			thief: ["7M", "6M", "5M"],
			tickle: ["7E", "6E", "5E"],
			torment: ["7M", "7L25", "6M", "6L25", "5M", "5L25"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			vinewhip: ["7L10", "6L10", "5L10", "5S1"],
			workup: ["7M", "5M"],
			worryseed: ["7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 1, shiny: 1, gender: "M", nature: "Brave", ivs: {spa: 31}, moves: ["bulletseed", "bite", "solarbeam", "dig"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["leer", "lick", "vinewhip", "leafstorm"]},
			{generation: 5, level: 30, gender: "M", nature: "Serious", moves: ["seedbomb", "solarbeam", "rocktomb", "dig"], pokeball: "cherishball"},
		],
	},
	simisage: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			attract: ["7M", "6M", "5M"],
			brickbreak: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			dig: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			energyball: ["7M", "6M", "5M"],
			facade: ["7M", "6M", "5M"],
			flash: ["6M", "5M"],
			fling: ["7M", "6M", "5M"],
			focusblast: ["7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["7L1", "6L1", "5L1"],
			gastroacid: ["7T", "6T"],
			gigadrain: ["7T", "6T", "5T"],
			gigaimpact: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			grasspledge: ["7T"],
			gunkshot: ["7T", "6T", "5T"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["7M", "6M", "5M"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leer: ["7L1", "6L1", "5L1"],
			lick: ["7L1", "6L1", "5L1"],
			lowkick: ["7T", "6T", "5T"],
			lowsweep: ["7M", "6M", "5M"],
			naturepower: ["7M", "6M"],
			payback: ["7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["7T", "7L1", "6T", "6L1", "5T", "5L1"],
			shadowclaw: ["7M", "6M", "5M"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			superpower: ["7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			synthesis: ["7T", "6T", "5T"],
			taunt: ["7M", "6M", "5M"],
			thief: ["7M", "6M", "5M"],
			throatchop: ["7T"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			workup: ["7M", "5M"],
			worryseed: ["7T", "6T", "5T"],
		},
	},
	pansear: {
		learnset: {
			acrobatics: ["7M", "7L31", "6M", "6L31", "5M", "5L31"],
			amnesia: ["7L25", "6L25", "5L25"],
			astonish: ["7E", "6E", "5E"],
			attract: ["7M", "6M", "5M"],
			belch: ["7E"],
			bite: ["7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			covet: ["7T", "7E", "6T", "6E", "5T", "5E"],
			crunch: ["7L43", "6L43", "5L43"],
			cut: ["6M", "5M"],
			dig: ["6M", "5M"],
			disarmingvoice: ["7E", "6E"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			fireblast: ["7M", "7L34", "6M", "6L34", "5M", "5L34"],
			firepledge: ["7T"],
			firepunch: ["7T", "7E", "6T", "6E", "5T", "5E"],
			firespin: ["7E", "6E", "5E"],
			flameburst: ["7L22", "6L22", "5L22"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["7M", "6M", "5M"],
			flareblitz: ["7E"],
			fling: ["7M", "7L28", "6M", "6L28", "5M", "5L28"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["7L13", "6L13", "5L13"],
			gastroacid: ["7T", "6T"],
			grassknot: ["7M", "6M", "5M"],
			gunkshot: ["7T", "6T", "5T"],
			heatwave: ["7T", "7E", "6T", "6E", "5T", "5E", "5S0"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["7L10", "6M", "6L10", "5M", "5L10", "5S0"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leer: ["7L4", "6L4", "5L4", "5S0"],
			lick: ["7L7", "6L7", "5L7", "5S0"],
			lowkick: ["7T", "7E", "6T", "6E", "5T", "5E"],
			lowsweep: ["7M", "6M", "5M"],
			nastyplot: ["7E", "6E", "5E"],
			naturalgift: ["7L40", "6L40", "5L40"],
			overheat: ["7M", "6M", "5M"],
			payback: ["7M", "6M", "5M"],
			playnice: ["7L1", "6L1"],
			protect: ["7M", "6M", "5M"],
			recycle: ["7T", "7L37", "6T", "6L37", "5T", "5L37"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M"],
			roleplay: ["7T", "7E", "6T", "6E", "5T", "5E"],
			round: ["7M", "6M", "5M"],
			scratch: ["7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			shadowclaw: ["7M", "6M", "5M"],
			sleeptalk: ["7M", "7E", "6M", "6E", "5T", "5E"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["7M", "6M", "5M"],
			thief: ["7M", "6M", "5M"],
			tickle: ["7E", "6E", "5E"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			willowisp: ["7M", "6M", "5M"],
			workup: ["7M", "5M"],
			yawn: ["7L16", "6L16", "5L16"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["leer", "lick", "incinerate", "heatwave"]},
		],
	},
	simisear: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			attract: ["7M", "6M", "5M"],
			brickbreak: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			dig: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			fireblast: ["7M", "6M", "5M"],
			firepledge: ["7T"],
			firepunch: ["7T", "6T", "5T"],
			flameburst: ["7L1", "6L1", "5L1"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["7M", "6M", "5M"],
			fling: ["7M", "6M", "5M"],
			focusblast: ["7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["7L1", "6L1", "5L1"],
			gastroacid: ["7T", "6T"],
			gigaimpact: ["7M", "6M", "6S0", "5M"],
			grassknot: ["7M", "6M", "5M"],
			gunkshot: ["7T", "6T", "5T"],
			heatwave: ["7T", "6T", "5T"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "6S0", "5M"],
			hyperbeam: ["7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leer: ["7L1", "6L1", "5L1"],
			lick: ["7L1", "6L1", "5L1"],
			lowkick: ["7T", "6T", "5T"],
			lowsweep: ["7M", "6M", "5M"],
			overheat: ["7M", "6M", "5M"],
			payback: ["7M", "6M", "5M"],
			poweruppunch: ["6M", "6S0"],
			protect: ["7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowclaw: ["7M", "6M", "5M"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			superpower: ["7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["7M", "6M", "5M"],
			thief: ["7M", "6M", "5M"],
			throatchop: ["7T"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			willowisp: ["7M", "6M", "5M"],
			workup: ["7M", "6S0", "5M"],
		},
		eventData: [
			{generation: 6, level: 5, perfectIVs: 2, moves: ["workup", "honeclaws", "poweruppunch", "gigaimpact"], pokeball: "cherishball"},
		],
	},
	panpour: {
		learnset: {
			acrobatics: ["7M", "7L31", "6M", "6L31", "5M", "5L31"],
			aquaring: ["7E", "6E", "5E"],
			aquatail: ["7T", "7E", "6T", "6E", "5T", "5E"],
			astonish: ["7E", "6E", "5E"],
			attract: ["7M", "6M", "5M"],
			bite: ["7L19", "6L19", "5L19"],
			blizzard: ["7M", "6M", "5M"],
			brine: ["7L34", "6L34", "5L34"],
			confide: ["7M", "6M"],
			covet: ["7T", "7E", "6T", "6E", "5T", "5E"],
			crunch: ["7L43", "6L43", "5L43"],
			cut: ["6M", "5M"],
			dig: ["6M", "5M"],
			disarmingvoice: ["7E", "6E"],
			dive: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			fling: ["7M", "7L28", "6M", "6L28", "5M", "5L28"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["7L13", "6L13", "5L13"],
			gastroacid: ["7T", "6T"],
			grassknot: ["7M", "6M", "5M"],
			gunkshot: ["7T", "6T", "5T"],
			hail: ["7M", "6M", "5M"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hydropump: ["7E", "6E", "5E", "5S0"],
			icebeam: ["7M", "6M", "5M"],
			icepunch: ["7T", "6T", "5T"],
			icywind: ["7T", "6T", "5T"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leer: ["7L4", "6L4", "5L4", "5S0"],
			lick: ["7L7", "6L7", "5L7", "5S0"],
			lowkick: ["7T", "7E", "6T", "6E", "5T", "5E"],
			lowsweep: ["7M", "6M", "5M"],
			mudsport: ["7E", "6E", "5E"],
			nastyplot: ["7E", "6E", "5E"],
			naturalgift: ["7L40", "6L40", "5L40"],
			payback: ["7M", "6M", "5M"],
			playnice: ["7L1", "6L1"],
			protect: ["7M", "6M", "5M"],
			raindance: ["7M", "6M", "5M"],
			recycle: ["7T", "7L37", "6T", "6L37", "5T", "5L37"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M"],
			roleplay: ["7T", "7E", "6T", "6E", "5T", "5E"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "7L22", "6M", "6L22", "5M", "5L22"],
			scratch: ["7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			shadowclaw: ["7M", "6M", "5M"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			substitute: ["7M", "6M", "5M"],
			surf: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["7M", "7L25", "6M", "6L25", "5M", "5L25"],
			thief: ["7M", "6M", "5M"],
			tickle: ["7E", "6E", "5E"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			waterfall: ["7M", "6M", "5M"],
			watergun: ["7L10", "6L10", "5L10", "5S0"],
			waterpledge: ["7T"],
			waterpulse: ["7T", "6T"],
			watersport: ["7L16", "6L16", "5L16"],
			workup: ["7M", "5M"],
		},
		eventData: [
			{generation: 5, level: 10, gender: "M", isHidden: true, moves: ["leer", "lick", "watergun", "hydropump"]},
		],
	},
	simipour: {
		learnset: {
			acrobatics: ["7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			attract: ["7M", "6M", "5M"],
			blizzard: ["7M", "6M", "5M"],
			brickbreak: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			dig: ["6M", "5M"],
			dive: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			fling: ["7M", "6M", "5M"],
			focusblast: ["7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["7L1", "6L1", "5L1"],
			gastroacid: ["7T", "6T"],
			gigaimpact: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			gunkshot: ["7T", "6T", "5T"],
			hail: ["7M", "6M", "5M"],
			helpinghand: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["7M", "6M", "5M"],
			icebeam: ["7M", "6M", "5M"],
			icepunch: ["7T", "6T", "5T"],
			icywind: ["7T", "6T", "5T"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leer: ["7L1", "6L1", "5L1"],
			lick: ["7L1", "6L1", "5L1"],
			lowkick: ["7T", "6T", "5T"],
			lowsweep: ["7M", "6M", "5M"],
			payback: ["7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["7M", "6M", "5M"],
			raindance: ["7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "7L1", "6M", "6L1", "5M", "5L1"],
			secretpower: ["6M"],
			shadowclaw: ["7M", "6M", "5M"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			substitute: ["7M", "6M", "5M"],
			superpower: ["7T", "6T", "5T"],
			surf: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["7M", "6M", "5M"],
			thief: ["7M", "6M", "5M"],
			throatchop: ["7T"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			waterfall: ["7M", "6M", "5M"],
			waterpledge: ["7T"],
			waterpulse: ["7T", "6T"],
			workup: ["7M", "5M"],
		},
	},
	munna: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			allyswitch: ["8M", "7T"],
			amnesia: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			barrier: ["7E", "6E", "5E"],
			batonpass: ["7E", "6E", "5E"],
			calmmind: ["8M", "8L28", "7M", "7L35", "6M", "6L35", "5M", "5L35"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "6E", "5E"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defensecurl: ["8L1", "7L1", "6L1", "5L1"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["8L44", "7M", "7L41", "7S0", "6M", "6L41", "5M", "5L41"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M", "8L48", "7L31", "6L31", "5L31"],
			gravity: ["7T", "6T", "5T"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			healbell: ["7T", "6T", "5T"],
			healingwish: ["8E", "7E", "6E"],
			helpinghand: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			hiddenpower: ["7M", "6M", "5M"],
			hypnosis: ["8L4", "7L19", "7S0", "6L19", "5L19"],
			imprison: ["8M", "8L12", "7L13", "6L13", "5L13"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			luckychant: ["7L5", "6L5", "5L5"],
			magiccoat: ["8L20", "7T", "7E", "6T", "6E", "5T", "5E"],
			moonblast: ["8L40"],
			moonlight: ["8L16", "7L17", "6L17", "5L17"],
			nightmare: ["7L29", "6L29", "5L29"],
			painsplit: ["7T", "6T", "5T"],
			powerswap: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			psybeam: ["8L8", "7L11", "6L11", "5L11"],
			psychic: ["8M", "8L36", "7M", "7L37", "6M", "6L37", "5M", "5L37"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7L1", "6L1", "5L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "7S0", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["7E", "6M", "6E", "5E"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "7E", "7S0", "6M", "6E", "5T", "5E"],
			snore: ["8M", "7T", "6T", "5T"],
			sonicboom: ["7E", "6E", "5E"],
			storedpower: ["8M", "8L1", "7L47", "6L47", "5L47"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M", "7E", "6E", "5E"],
			synchronoise: ["7L25", "6L25", "5L25"],
			telekinesis: ["7T", "7L43", "6L43", "5M", "5L43"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			wonderroom: ["8M", "8L52", "7T", "6T", "5T"],
			worryseed: ["7T", "6T", "5T"],
			yawn: ["8L32", "7L7", "6L7", "5L7"],
			zenheadbutt: ["8M", "8L24", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
		},
		eventData: [
			{generation: 7, level: 39, nature: "Mild", isHidden: true, moves: ["hypnosis", "dreameater", "rest", "sleeptalk"], pokeball: "dreamball"},
		],
	},
	musharna: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			allyswitch: ["8M", "7T"],
			amnesia: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "8L1", "7M", "6M", "5M"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defensecurl: ["8L1", "7L1", "6L1", "5L1", "5S0"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["8L1", "7M", "6M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M", "8L1"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			healbell: ["7T", "6T", "5T"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypnosis: ["8L1", "7L1", "6L1", "5L1", "5S0"],
			imprison: ["8M", "8L1"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			luckychant: ["7L1", "6L1", "5L1", "5S0"],
			magiccoat: ["8L1", "7T", "6T", "5T"],
			mistyexplosion: ["8T"],
			moonblast: ["8L1"],
			moonlight: ["8L1"],
			painsplit: ["7T", "6T", "5T"],
			powerswap: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			psybeam: ["8L1", "7L1", "6L1", "5L1", "5S0"],
			psychic: ["8M", "8L1", "7M", "6M", "5M"],
			psychicterrain: ["8M", "8L1", "7L1"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			storedpower: ["8M", "8L1"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			telekinesis: ["7T", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			wonderroom: ["8M", "8L1", "7T", "6T", "5T"],
			worryseed: ["7T", "6T", "5T"],
			yawn: ["8L1"],
			zenheadbutt: ["8M", "8L1", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 50, isHidden: true, moves: ["defensecurl", "luckychant", "psybeam", "hypnosis"]},
		],
	},
	pidove: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M"],
			aircutter: ["8L16", "7L15", "6L15", "5L15", "5S0"],
			airslash: ["8M", "8L32", "7L29", "6L29", "5L29"],
			attract: ["8M", "7M", "6M", "5M"],
			bestow: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			defog: ["8E", "7T"],
			detect: ["8L28", "7L22", "6L22", "5L22"],
			doubleteam: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "7L43", "6M", "6L43", "5M", "5L43"],
			featherdance: ["8L24", "7L36", "6L36", "5L36"],
			fly: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M", "5M"],
			growl: ["8L1", "7L4", "6L4", "5L4"],
			gust: ["8L1", "7L1", "6L1", "5L1", "5D", "5S0"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hypnosis: ["8E", "7E", "6E", "5E", "5D"],
			leer: ["8L4", "7L8", "6L8", "5L8"],
			luckychant: ["7E", "6E", "5E"],
			morningsun: ["8E", "7E", "6E", "5E", "5D"],
			nightslash: ["8E", "7E", "6E"],
			pluck: ["5M"],
			protect: ["8M", "7M", "6M", "5M"],
			quickattack: ["8L8", "7L11", "6L11", "5L11", "5S0"],
			raindance: ["8M", "7M", "6M", "5M"],
			razorwind: ["7L32", "6L32", "5L32"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["8L36", "7M", "7L18", "6M", "6L18", "5T", "5L18"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			skyattack: ["8L44", "7T", "7L50", "6T", "6L50", "5T", "5L50"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "7E", "6M", "6E", "5E"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["8L20", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			swift: ["8M"],
			tailwind: ["8L40", "7T", "7L46", "6T", "6L46", "5T", "5L46"],
			taunt: ["8M", "8L12", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			uturn: ["8M", "7M", "6M", "5M"],
			wish: ["8E", "7E", "6E", "5E"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 5, level: 1, shiny: 1, gender: "F", nature: "Hardy", ivs: {atk: 31}, abilities: ["superluck"], moves: ["gust", "quickattack", "aircutter"], pokeball: "pokeball"},
		],
	},
	tranquill: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M"],
			aircutter: ["8L16", "7L15", "6L15", "5L15"],
			airslash: ["8M", "8L38", "7L32", "6L32", "5L32"],
			attract: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			defog: ["7T"],
			detect: ["8L34", "7L23", "6L23", "5L23"],
			doubleteam: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "7L50", "6M", "6L50", "5M", "5L50"],
			featherdance: ["8L26", "7L41", "6L41", "5L41"],
			fly: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M", "5M"],
			growl: ["8L1", "7L1", "6L1", "5L1"],
			gust: ["8L1", "7L1", "6L1", "5L1"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			pluck: ["5M"],
			protect: ["8M", "7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			razorwind: ["7L36", "6L36", "5L36"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["8L44", "7M", "7L18", "6M", "6L18", "5T", "5L18"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			skyattack: ["8L56", "7T", "7L59", "6T", "6L59", "5T", "5L59"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["8L20", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			swift: ["8M"],
			tailwind: ["8L50", "7T", "7L54", "6T", "6L54", "5T", "5L54"],
			taunt: ["8M", "8L12", "7M", "7L27", "6M", "6L27", "5M", "5L27"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
		},
	},
	unfezant: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M"],
			aircutter: ["8L16", "7L15", "6L15", "5L15"],
			airslash: ["8M", "8L42", "7L33", "6L33", "5L33"],
			attract: ["8M", "7M", "6M", "5M"],
			bravebird: ["8M"],
			confide: ["7M", "6M"],
			defog: ["7T"],
			detect: ["8L36", "7L23", "6L23", "5L23"],
			doubleteam: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "7L55", "6M", "6L55", "5M", "5L55"],
			featherdance: ["8L26", "7L44", "6L44", "5L44"],
			fly: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			growl: ["8L1", "7L1", "6L1", "5L1"],
			gust: ["8L1", "7L1", "6L1", "5L1"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hurricane: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			pluck: ["5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			razorwind: ["7L38", "6L38", "5L38"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["8L50", "7M", "7L18", "6M", "6L18", "5T", "5L18"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			skyattack: ["8L66", "7T", "7L66", "6T", "6L66", "5T", "5L66"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["8L20", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			swift: ["8M"],
			tailwind: ["8L58", "7T", "7L60", "6T", "6L60", "5T", "5L60"],
			taunt: ["8M", "8L12", "7M", "7L27", "6M", "6L27", "5M", "5L27"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
		},
		encounters: [
			{generation: 5, level: 22},
		],
	},
	blitzle: {
		learnset: {
			agility: ["7L36", "6L36", "5L36"],
			attract: ["7M", "6M", "5M"],
			bounce: ["7T", "6T", "5T"],
			charge: ["7L8", "6L8", "5L8"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			discharge: ["7L32", "6L32", "5L32"],
			doubleedge: ["7E", "6E", "5E"],
			doublekick: ["7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["7E", "6E", "5E"],
			facade: ["7M", "6M", "5M"],
			feint: ["7E"],
			flamecharge: ["7M", "7L18", "6M", "6L18", "5M", "5L18"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			lightscreen: ["7M", "6M", "5M"],
			lowkick: ["7T"],
			magnetrise: ["7T", "6T", "5T"],
			mefirst: ["7E", "6E", "5E"],
			protect: ["7M", "6M", "5M"],
			pursuit: ["7L22", "6L22", "5L22"],
			quickattack: ["7L1", "6L1", "5L1"],
			rage: ["7E", "6E", "5E"],
			raindance: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			sandattack: ["7E", "6E", "5E"],
			screech: ["7E", "6E", "5E"],
			secretpower: ["6M"],
			shockwave: ["7T", "7L11", "7E", "6T", "6L11", "6E", "5L11", "5E"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["7M", "6M", "5T"],
			snatch: ["7T", "7E", "6T", "6E"],
			snore: ["7T", "6T", "5T"],
			spark: ["7L25", "6L25", "5L25"],
			stomp: ["7L29", "6L29", "5L29"],
			substitute: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tailwhip: ["7L4", "6L4", "5L4"],
			takedown: ["7E", "6E", "5E"],
			thrash: ["7L43", "6L43", "5L43"],
			thunder: ["7M", "6M", "5M"],
			thunderbolt: ["7M", "6M", "5M"],
			thunderwave: ["7M", "7L15", "6M", "6L15", "5M", "5L15"],
			toxic: ["7M", "6M", "5M"],
			voltswitch: ["7M", "6M", "5M"],
			wildcharge: ["7M", "7L39", "6M", "6L39", "5M", "5L39"],
		},
	},
	zebstrika: {
		learnset: {
			agility: ["7L42", "6L42", "5L42"],
			allyswitch: ["7T"],
			attract: ["7M", "6M", "5M"],
			bounce: ["7T", "6T", "5T"],
			charge: ["7L1", "6L1", "5L1"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			discharge: ["7L36", "6L36", "5L36"],
			doubleteam: ["7M", "6M", "5M"],
			facade: ["7M", "6M", "5M"],
			flamecharge: ["7M", "7L18", "6M", "6L18", "5M", "5L18"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["7M", "6M", "5M"],
			iondeluge: ["7L1", "6L1"],
			laserfocus: ["7T"],
			lightscreen: ["7M", "6M", "5M"],
			lowkick: ["7T"],
			magnetrise: ["7T", "6T", "5T"],
			overheat: ["7M", "6M", "5M"],
			protect: ["7M", "6M", "5M"],
			pursuit: ["7L22", "6L22", "5L22"],
			quickattack: ["7L1", "6L1", "5L1"],
			raindance: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			shockwave: ["7T", "7L11", "6T", "6L11", "5L11"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["7M", "6M", "5T"],
			snatch: ["7T", "6T"],
			snore: ["7T", "6T", "5T"],
			spark: ["7L25", "6L25", "5L25"],
			stomp: ["7L31", "6L31", "5L31"],
			substitute: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tailwhip: ["7L1", "6L1", "5L1"],
			thrash: ["7L53", "6L53", "5L53"],
			thunder: ["7M", "6M", "5M"],
			thunderbolt: ["7M", "6M", "5M"],
			thunderwave: ["7M", "7L1", "6M", "6L1", "5M", "5L1"],
			toxic: ["7M", "6M", "5M"],
			voltswitch: ["7M", "6M", "5M"],
			wildcharge: ["7M", "7L47", "6M", "6L47", "5M", "5L47"],
		},
	},
	roggenrola: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			autotomize: ["8E", "7E", "6E", "5E"],
			block: ["7T", "6T", "5T"],
			bodypress: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			explosion: ["8L44", "7M", "7L40", "6M", "6L40", "5M", "5L40"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gravity: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			harden: ["8L4", "7L4", "6L4", "5L4"],
			headbutt: ["8L24", "7L10", "6L10", "5L10"],
			heavyslam: ["8M", "7E", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M"],
			irondefense: ["8M", "8L20", "7T", "7L20", "6T", "6L20", "5T", "5L20"],
			lockon: ["7E", "6E", "5E"],
			magnitude: ["7E", "6E", "5E"],
			meteorbeam: ["8T"],
			mudslap: ["8L12", "7L17", "6L17", "5L17"],
			naturepower: ["7M", "6M"],
			protect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M", "8L32", "7L14", "6L14", "5L14"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "8L28", "7M", "7L27", "6M", "6L27", "5M", "5L27"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "7E", "6M", "6E", "5M", "5E"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L7", "6L7", "5L7"],
			sandstorm: ["8M", "8L36", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			sandtomb: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["8L16", "7M", "7L23", "6M", "6L23", "5M", "5L23"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "8L8", "7T", "7L30", "6T", "6L30", "5T", "5L30"],
			stoneedge: ["8M", "8L40", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			takedown: ["8E", "7E", "6E", "5E"],
			toxic: ["7M", "6M", "5M"],
			wideguard: ["8E", "7E", "6E"],
		},
	},
	boldore: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			bodypress: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			curse: ["5D"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			explosion: ["8L54", "7M", "7L55", "6M", "6L55", "5M", "5L55"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			harden: ["8L1", "7L1", "6L1", "5L1"],
			headbutt: ["8L24", "7L1", "6L1", "5L1"],
			heavyslam: ["8M", "5D"],
			hiddenpower: ["7M", "6M", "5M"],
			irondefense: ["8M", "8L20", "7T", "7L20", "6T", "6L20", "5T", "5L20"],
			meteorbeam: ["8T"],
			mudslap: ["8L12", "7L17", "6L17", "5L17"],
			naturepower: ["7M", "6M"],
			powergem: ["8M", "8L0", "7L1", "6L25", "5L25"],
			protect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M", "8L36", "7L14", "6L14", "5L14"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "8L30", "7M", "7L30", "6M", "6L30", "5M", "5L30"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L1"],
			sandstorm: ["8M", "8L42", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			sandtomb: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["8L16", "7M", "7L23", "6M", "6L23", "5M", "5L23", "5D"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "8L1", "7T", "7L36", "6T", "6L36", "5T", "5L36"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L48", "7M", "7L48", "6M", "6L48", "5M", "5L48"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			toxic: ["7M", "6M", "5M"],
		},
		encounters: [
			{generation: 5, level: 24},
		],
	},
	gigalith: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			bodypress: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			explosion: ["8L54", "7M", "7L55", "6M", "6L55", "5M", "5L55"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			harden: ["8L1", "7L1", "6L1", "5L1"],
			headbutt: ["8L24", "7L1", "6L1", "5L1"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			irondefense: ["8M", "8L20", "7T", "7L20", "6T", "6L20", "5T", "5L20"],
			ironhead: ["8M", "7T", "6T", "5T"],
			laserfocus: ["7T"],
			meteorbeam: ["8T"],
			mudslap: ["8L12", "7L17", "6L17", "5L17"],
			naturepower: ["7M", "6M"],
			powergem: ["8M", "8L1", "7L1", "6L25", "5L25"],
			protect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M", "8L36", "7L14", "6L14", "5L14"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "8L30", "7M", "7L30", "6M", "6L30", "5M", "5L30"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L1"],
			sandstorm: ["8M", "8L42", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			sandtomb: ["8M"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["8L16", "7M", "7L23", "6M", "6L23", "5M", "5L23"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			stealthrock: ["8M", "8L1", "7T", "7L36", "6T", "6L36", "5T", "5L36"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L48", "7M", "7L48", "6M", "6L48", "5M", "5L48"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			throatchop: ["8M", "7T"],
			toxic: ["7M", "6M", "5M"],
			weatherball: ["8M"],
		},
	},
	woobat: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M"],
			afteryou: ["7T", "6T", "5T"],
			aircutter: ["8L15", "7L21", "6L21", "5L21"],
			airslash: ["8M", "8L35", "7L32", "6L32", "5L32"],
			allyswitch: ["8M", "7T"],
			amnesia: ["8M", "8L30", "7L29", "6L29", "5L29"],
			assurance: ["8M", "8L25", "7L12", "6L12", "5L12"],
			attract: ["8M", "8L1", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			batonpass: ["8M"],
			calmmind: ["8M", "8L45", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			captivate: ["7E", "6E"],
			chargebeam: ["7M", "6M", "5M"],
			charm: ["8M", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			confusion: ["8L5", "7L1", "6L1", "5L1"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			embargo: ["7M", "6M", "5M"],
			endeavor: ["8L10", "7T", "7L47", "6T", "6L47", "5T", "5L47"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M"],
			faketears: ["8M", "7E", "6E", "5E"],
			flash: ["6M", "5M"],
			flatter: ["8E", "7E", "6E", "5E"],
			fly: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M", "8L50", "7L36", "6L36", "5L36"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gust: ["8L1", "7L8", "6L8", "5L8"],
			gyroball: ["8M", "7M", "6M", "5M"],
			heartstamp: ["7L15", "6L15", "5L15"],
			heatwave: ["8M", "7T", "6T", "5T"],
			helpinghand: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			hiddenpower: ["7M", "6M", "5M"],
			imprison: ["8M", "8L20", "7L19", "6L19", "5L19"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			nastyplot: ["8M"],
			odorsleuth: ["7L4", "6L4", "5L4"],
			pluck: ["5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "8L40", "7M", "7L41", "6M", "6L41", "5M", "5L41"],
			psychocut: ["8M"],
			psychoshift: ["8E", "7E", "6E"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["8E", "7M", "7E", "6M", "6E", "5T", "5E"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			simplebeam: ["8L55"],
			skillswap: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			speedswap: ["8M"],
			steelwing: ["8M", "7M", "6M"],
			storedpower: ["8M", "7E", "6E", "5E"],
			substitute: ["8M", "7M", "6M", "5M"],
			superfang: ["7T", "6T", "5T"],
			supersonic: ["8E", "7E", "6E", "5E"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			synchronoise: ["7E", "6E", "5E"],
			tailwind: ["7T", "6T", "5T"],
			taunt: ["8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			venomdrench: ["8M", "7E", "6E"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
	},
	swoobat: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M"],
			afteryou: ["7T", "6T", "5T"],
			aircutter: ["8L15", "7L21", "6L21", "5L21"],
			airslash: ["8M", "8L35", "7L32", "6L32", "5L32"],
			allyswitch: ["8M", "7T"],
			amnesia: ["8M", "8L30", "7L29", "6L29", "5L29"],
			assurance: ["8M", "8L25", "7L1", "6L1", "5L1"],
			attract: ["8M", "8L1", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			batonpass: ["8M"],
			calmmind: ["8M", "8L45", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			chargebeam: ["7M", "6M", "5M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "5L1"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			embargo: ["7M", "6M", "5M"],
			endeavor: ["8L1", "7T", "7L47", "6T", "6L47", "5T", "5L47"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M"],
			faketears: ["8M"],
			flash: ["6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M", "8L50", "7L36", "6L36", "5L36"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gust: ["8L1", "7L1", "6L1", "5L1"],
			gyroball: ["8M", "7M", "6M", "5M"],
			heartstamp: ["7L15", "6L15", "5L15"],
			heatwave: ["8M", "7T", "6T", "5T"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			imprison: ["8M", "8L20", "7L19", "6L19", "5L19"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			nastyplot: ["8M"],
			odorsleuth: ["7L1", "6L1", "5L1"],
			pluck: ["5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "8L40", "7M", "7L41", "6M", "6L41", "5M", "5L41"],
			psychicfangs: ["8M"],
			psychocut: ["8M"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			simplebeam: ["8L55"],
			skillswap: ["8M", "7T", "6T", "5T"],
			skyattack: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			speedswap: ["8M"],
			steelwing: ["8M", "7M", "6M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superfang: ["7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			tailwind: ["7T", "6T", "5T"],
			taunt: ["8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			venomdrench: ["8M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
	},
	drilbur: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			attract: ["8M", "7M", "6M", "5M"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crushclaw: ["8L24", "7E", "6E", "5E"],
			cut: ["6M", "5M"],
			dig: ["8M", "8L32", "7L19", "6M", "6L19", "5M", "5L19"],
			doubleteam: ["7M", "6M", "5M"],
			drillrun: ["8M", "8L40", "7T", "7L43", "6T", "6L43", "5T", "5L43"],
			earthpower: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			earthquake: ["8M", "8L44", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			fissure: ["8L48", "7L47", "6L47", "5L47"],
			fling: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["8L12", "7L12", "6L12", "5L12"],
			hiddenpower: ["7M", "6M", "5M"],
			highhorsepower: ["8M"],
			honeclaws: ["8L8", "7L22", "6M", "6L22", "5M", "5L22"],
			irondefense: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			metalclaw: ["8L16", "7L15", "6L15", "5L15"],
			metalsound: ["8E", "7E", "6E", "5E", "5D"],
			mudshot: ["8M"],
			mudslap: ["8L1", "7L8", "6L8", "5L8"],
			mudsport: ["7L1", "6L1", "5L1"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			rapidspin: ["8L1", "7L5", "7E", "6L5", "6E", "5L5", "5E", "5D"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockclimb: ["7E", "6E", "5E", "5D"],
			rockslide: ["8M", "8L28", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "8L20", "7M", "7L40", "6M", "6L40", "5M", "5L40"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			scratch: ["8L4", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			skullbash: ["7E", "6E", "5E"],
			slash: ["8E", "7L26", "6L26", "5L26"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			strength: ["6M", "5M"],
			submission: ["8E", "7E", "6E", "5E"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "8L36", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			toxic: ["7M", "6M", "5M"],
			xscissor: ["8M", "7M", "6M", "5M"],
		},
	},
	excadrill: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			attract: ["8M", "7M", "6M", "5M"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crushclaw: ["8L24"],
			cut: ["6M", "5M"],
			dig: ["8M", "8L34", "7L19", "6M", "6L19", "5M", "5L19"],
			doubleteam: ["7M", "6M", "5M"],
			drillrun: ["8M", "8L46", "7T", "7L55", "6T", "6L55", "5T", "5L55"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "8L52", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			fissure: ["8L58", "7L62", "6L62", "5L62"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["8L12", "7L12", "6L12", "5L12"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			highhorsepower: ["8M"],
			honeclaws: ["8L1", "7L22", "6M", "6L22", "5M", "5L22"],
			horndrill: ["8L0", "7L1", "6L31", "5L31"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			ironhead: ["8M", "7T", "6T", "5T"],
			magnetrise: ["7T", "6T", "5T"],
			metalclaw: ["8L16", "7L15", "6L15", "5L15"],
			mudshot: ["8M"],
			mudslap: ["8L1", "7L1", "6L1", "5L1"],
			mudsport: ["7L1", "6L1", "5L1"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			rapidspin: ["8L1", "7L1", "6L1", "5L1"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M"],
			rockslide: ["8M", "8L28", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rototiller: ["7L1", "6L1"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "8L20", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			slash: ["7L26", "6L26", "5L26"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "8L40", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			toxic: ["7M", "6M", "5M"],
			xscissor: ["8M", "7M", "6M", "5M"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	audino: {
		learnset: {
			afteryou: ["8L28", "7T", "7L41", "6T", "6L40", "5T", "5L40"],
			allyswitch: ["8M", "7T"],
			amnesia: ["8M", "7E", "6E", "5E"],
			attract: ["8M", "7M", "7L21", "6M", "6L15", "5M", "5L15"],
			babydolleyes: ["8L9", "7L5", "6L5"],
			bestow: ["7E", "6E", "5E"],
			blizzard: ["8M", "7M", "6M", "5M"],
			bodyslam: ["8M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			dig: ["8M", "6M", "5M"],
			disarmingvoice: ["8L4", "7L13", "6L13"],
			doubleedge: ["8L48", "7L49", "6L49", "5L50"],
			doubleslap: ["7L17", "6L10", "5L10", "5S0"],
			doubleteam: ["7M", "6M", "5M"],
			drainingkiss: ["8M", "7E", "6E"],
			drainpunch: ["8M", "7T", "6T", "5T"],
			dreameater: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["8M", "7E", "6E", "5E", "5D"],
			endure: ["8M"],
			entrainment: ["8L52", "7L29", "6L25", "5L25"],
			facade: ["8M", "7M", "6M", "5M"],
			fireblast: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			growl: ["8L16", "7L1", "6L1", "5L1"],
			healbell: ["7T", "7E", "6T", "6E", "5T", "5E"],
			healingwish: ["8E", "7E", "6E", "5E"],
			healpulse: ["8L44", "7L37", "6L35", "6S3", "5L35", "5S0", "5S1", "5S2"],
			helpinghand: ["8M", "8L12", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5D", "5S0", "5S1", "5S2"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "8L40", "7T", "7L1", "6T", "5T"],
			icebeam: ["8M", "7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			icywind: ["8M", "7T", "6T", "5T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			lastresort: ["8L60", "7T", "7L1", "6T", "6L1", "5T", "5L55"],
			lifedew: ["8L24"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			lowkick: ["8M", "7T", "6T", "5T"],
			luckychant: ["7E", "6E", "5E"],
			magiccoat: ["7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mistyterrain: ["8M", "8L56", "7L1", "6L1"],
			painsplit: ["7T", "6T", "5T"],
			playnice: ["8L1", "7L1", "6L1"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			present: ["5S1", "5S2"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			refresh: ["7L9", "6L5", "5L5", "5S0", "5S1", "5S2"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["7L25", "6M", "6L20", "5L20"],
			shadowball: ["8M", "7M", "6M", "5M"],
			signalbeam: ["7T", "6T", "5T"],
			simplebeam: ["8L36", "7L45", "6L45", "6S3", "5L45"],
			skillswap: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			stompingtantrum: ["8M", "7T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			sweetkiss: ["8E", "7E", "6E", "5E"],
			takedown: ["8L32", "7L33", "6L30", "5L30"],
			telekinesis: ["7T", "5M"],
			throatchop: ["8M", "7T"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "6S3", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trickroom: ["8M", "7M", "6M", "6S3", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			wish: ["8E", "7E", "6E", "5E"],
			workup: ["8M", "7M", "5M"],
			yawn: ["8E", "7E", "6E", "5E", "5D"],
			zenheadbutt: ["8M", "8L20", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 30, gender: "F", nature: "Calm", abilities: ["healer"], moves: ["healpulse", "helpinghand", "refresh", "doubleslap"], pokeball: "cherishball"},
			{generation: 5, level: 30, gender: "F", nature: "Serious", abilities: ["healer"], moves: ["healpulse", "helpinghand", "refresh", "present"], pokeball: "cherishball"},
			{generation: 5, level: 30, gender: "F", nature: "Jolly", abilities: ["healer"], moves: ["healpulse", "helpinghand", "refresh", "present"], pokeball: "cherishball"},
			{generation: 6, level: 100, nature: "Relaxed", abilities: ["regenerator"], moves: ["trickroom", "healpulse", "simplebeam", "thunderbolt"], pokeball: "cherishball"},
		],
	},
	timburr: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L8", "6L8", "5L8"],
			block: ["7T", "6T", "5T"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "8L16", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			chipaway: ["7L24", "6L24", "5L24"],
			coaching: ["8T"],
			cometpunch: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E"],
			defog: ["8E"],
			detect: ["8E", "7E", "6E", "5E"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			dynamicpunch: ["8L32", "7L34", "6L34", "5L34"],
			endure: ["8M", "7E", "6E", "5E"],
			facade: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M", "8L12", "7L4", "6L4", "5L4"],
			focuspunch: ["8L48", "7T", "7L46", "6T", "6L46", "5L46"],
			forcepalm: ["7E", "6E", "5E"],
			foresight: ["7E", "6E", "5E"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			hammerarm: ["8L36", "7L40", "6L40", "5L40"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			lowkick: ["8M", "8L4", "7T", "7L12", "6T", "6L12", "5T", "5L12"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			machpunch: ["8E", "7E", "6E", "5E"],
			megakick: ["8M"],
			megapunch: ["8M"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["8E", "7E", "6M"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M", "7E", "6E", "5E"],
			rockslide: ["8M", "8L20", "7M", "7L31", "6M", "6L31", "5M", "5L31"],
			rocksmash: ["6M", "5M"],
			rockthrow: ["8L8", "7L16", "6L16", "5L16"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L28", "7L37", "6L37", "5L37"],
			secretpower: ["6M"],
			slam: ["8L24"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["7M", "6M", "5M"],
			smellingsalts: ["7E", "6E", "5E"],
			snore: ["8M", "7T", "6T", "5T"],
			stoneedge: ["8M", "8L40", "7M", "7L43", "6M", "6L43", "5M", "5L43"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "8L44", "7T", "7L49", "6T", "6L49", "5T", "5L49"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			toxic: ["7M", "6M", "5M"],
			wakeupslap: ["7L20", "6L20", "5L20"],
			wideguard: ["8E", "7E", "6E", "5E"],
			workup: ["8M", "7M", "5M"],
		},
	},
	gurdurr: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L1", "6L1", "5L1"],
			block: ["7T", "6T", "5T"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "8L16", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			chipaway: ["7L24", "6L24", "5L24"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T", "5D"],
			dynamicpunch: ["8L36", "7L37", "6L37", "5L37"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M", "8L12", "7L1", "6L1", "5L1"],
			focuspunch: ["8L60", "7T", "7L53", "6T", "6L53", "5L53"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			hammerarm: ["8L42", "7L45", "6L45", "5L45"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			highhorsepower: ["8M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			lowkick: ["8M", "8L1", "7T", "7L12", "6T", "6L12", "5T", "5L12", "5D"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			machpunch: ["5D"],
			megakick: ["8M"],
			megapunch: ["8M"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M"],
			rockslide: ["8M", "8L20", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			rocksmash: ["6M", "5M"],
			rockthrow: ["8L1", "7L16", "6L16", "5L16"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L30", "7L41", "6L41", "5L41"],
			secretpower: ["6M"],
			slam: ["8L24"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stoneedge: ["8M", "8L48", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "8L54", "7T", "7L57", "6T", "6L57", "5T", "5L57"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			toxic: ["7M", "6M", "5M"],
			wakeupslap: ["7L20", "6L20", "5L20"],
			workup: ["8M", "7M", "5M"],
		},
	},
	conkeldurr: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L1", "6L1", "5L1"],
			block: ["7T", "6T", "5T"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "8L16", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			chipaway: ["7L24", "6L24", "5L24"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T"],
			dynamicpunch: ["8L36", "7L37", "6L37", "5L37"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M", "8L12", "7L1", "6L1", "5L1"],
			focuspunch: ["8L60", "7T", "7L53", "6T", "6L53", "5L53"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			hammerarm: ["8L42", "7L45", "6L45", "5L45"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			lowkick: ["8M", "8L1", "7T", "7L12", "6T", "6L12", "5T", "5L12"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M"],
			rockblast: ["8M"],
			rockslide: ["8M", "8L20", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			rocksmash: ["6M", "5M"],
			rockthrow: ["8L1", "7L16", "6L16", "5L16"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L30", "7L41", "6L41", "5L41"],
			secretpower: ["6M"],
			slam: ["8L24"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L48", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "8L54", "7T", "7L57", "6T", "6L57", "5T", "5L57"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			toxic: ["7M", "6M", "5M"],
			wakeupslap: ["7L20", "6L20", "5L20"],
			workup: ["8M", "7M", "5M"],
		},
	},
	tympole: {
		learnset: {
			acid: ["8L4"],
			afteryou: ["7T", "7E", "6T", "6E"],
			aquaring: ["8L32", "7L20", "6L20", "5L20"],
			attract: ["8M", "7M", "6M", "5M"],
			bounce: ["8M", "7T", "6T", "5T"],
			bubble: ["7L1", "6L1", "5L1"],
			bubblebeam: ["8L20", "7L12", "6L12", "5L12"],
			confide: ["7M", "6M"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			echoedvoice: ["8L1", "7M", "7L38", "6M", "6L38", "5M", "5L38"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flail: ["8L24", "7L34", "6L34", "5L34"],
			frustration: ["7M", "6M", "5M"],
			growl: ["8L1", "7L1", "6L1", "5L1"],
			hail: ["8M", "7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["8M", "8L48", "7L42", "6L42", "5L42"],
			hypervoice: ["8M", "8L36", "7T", "7L45", "6T", "6L45", "5T", "5L45"],
			icywind: ["8M", "7T", "6T", "5T"],
			infestation: ["7M", "6M"],
			mist: ["8E", "7E", "6E", "5E"],
			mudbomb: ["7E", "6E", "5E"],
			muddywater: ["8M", "8L40", "7L27", "6L27", "5L27"],
			mudshot: ["8M", "8L12", "7L16", "6L16", "5L16"],
			mudslap: ["8E"],
			mudsport: ["7E", "6E", "5E"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "8L44", "7M", "7L31", "6M", "6L31", "5M", "5L31"],
			refresh: ["7E", "6E", "5E"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "8L16", "7M", "7L9", "6M", "6L9", "5M", "5L9", "5D"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			substitute: ["8M", "7M", "6M", "5M"],
			supersonic: ["8L8", "7L5", "6L5", "5L5"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			toxic: ["8E", "7M", "6M", "5M"],
			uproar: ["8M", "8L28", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			venomdrench: ["8M", "7E"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E", "5D"],
			weatherball: ["8M"],
		},
	},
	palpitoad: {
		learnset: {
			acid: ["8L1"],
			afteryou: ["7T", "6T"],
			aquaring: ["8L37", "7L20", "6L20", "5L20"],
			attract: ["8M", "7M", "6M", "5M"],
			bounce: ["8M", "7T", "6T", "5T"],
			bubble: ["7L1", "6L1", "5L1"],
			bubblebeam: ["8L20", "7L12", "6L12", "5L12"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "6T", "5T"],
			echoedvoice: ["8L1", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flail: ["8L24", "7L37", "6L37", "5L37"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["7T", "6T", "5T"],
			growl: ["8L1", "7L1", "6L1", "5L1"],
			hail: ["8M", "7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["8M", "8L60", "7L47", "6L47", "5L47"],
			hypervoice: ["8M", "8L42", "7T", "7L51", "6T", "6L51", "5T", "5L51"],
			icywind: ["8M", "7T", "6T", "5T"],
			infestation: ["7M", "6M"],
			muddywater: ["8M", "8L48", "7L28", "6L28", "5L28"],
			mudshot: ["8M", "8L12", "7L16", "6L16", "5L16"],
			powerwhip: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "8L54", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "8L16", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			supersonic: ["8L1", "7L1", "6L1", "5L1"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "8L30", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			venomdrench: ["8M"],
			waterpulse: ["7T", "6T"],
			weatherball: ["8M"],
		},
	},
	seismitoad: {
		learnset: {
			acid: ["8L1", "7L1", "6L36", "5L36"],
			afteryou: ["7T", "6T"],
			aquaring: ["8L39", "7L20", "6L20", "5L20"],
			attract: ["8M", "7M", "6M", "5M"],
			bounce: ["8M", "7T", "6T", "5T"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			bubble: ["7L1", "6L1", "5L1"],
			bubblebeam: ["8L20", "7L12", "6L12", "5L12"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			dig: ["8M", "6M", "5M"],
			dive: ["8M"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "8L0", "7T", "7L44", "6T", "6L44", "5T", "5L44"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			echoedvoice: ["8L1", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flail: ["8L24", "7L39", "6L39", "5L39"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["8L1", "7T", "6T", "5T"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			growl: ["8L1", "7L1", "6L1", "5L1"],
			hail: ["8M", "7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["8M", "8L70", "7L53", "6L53", "5L53"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "8L46", "7T", "7L59", "6T", "6L59", "5T", "5L59"],
			icepunch: ["8M", "7T", "6T", "5T"],
			icywind: ["8M", "7T", "6T", "5T"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T"],
			liquidation: ["8M"],
			lowkick: ["8M", "7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			muddywater: ["8M", "8L54", "7L28", "6L28", "5L28"],
			mudshot: ["8M", "8L12", "7L16", "6L16", "5L16"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poweruppunch: ["6M"],
			powerwhip: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "8L62", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "8L16", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			scald: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			supersonic: ["8L1", "7L1", "6L1", "5L1"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "8L30", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			waterpulse: ["7T", "6T"],
			weatherball: ["8M"],
		},
		encounters: [
			{generation: 5, level: 15},
		],
	},
	throh: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L1", "6L5", "5L5"],
			bind: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5D"],
			block: ["7T", "6T", "5T"],
			bodyslam: ["8M", "7L21", "6L29", "5L29"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			bulkup: ["8M", "8L25", "7M", "7L25", "6M", "6L33", "5M", "5L33"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			circlethrow: ["8L10", "7L29", "6L37", "5L37"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M", "8L45", "7L33", "6L41", "5L41"],
			facade: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M", "8L5", "7L1", "6L9", "5L9"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T", "5D"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			lowkick: ["8M", "7T", "6T", "5T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			matblock: ["7L1"],
			megakick: ["8M"],
			megapunch: ["8M"],
			painsplit: ["7T", "6T", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M", "8L20", "7L13", "6L21", "5L21"],
			reversal: ["8M", "8L50", "7L45", "6L50", "5L53"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			seismictoss: ["8L40", "7L5", "6L13", "5L13"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			stormthrow: ["8L30", "7L17", "6L25", "5L25"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "8L55", "7T", "7L41", "6T", "6L48", "5T", "5L49", "5D"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			toxic: ["7M", "6M", "5M"],
			vitalthrow: ["8L35", "7L9", "6L17", "5L17"],
			wideguard: ["8L15", "7L37", "6L45", "5L45"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	sawk: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L1", "6L5", "5L5"],
			block: ["7T", "6T", "5T"],
			brickbreak: ["8M", "8L35", "7M", "7L21", "6M", "6L29", "5M", "5L29"],
			bulkup: ["8M", "8L25", "7M", "7L25", "6M", "6L33", "5M", "5L33"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			closecombat: ["8M", "8L55", "7L41", "6L48", "5L49"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["8L40", "7L13", "6L21", "5L21"],
			dig: ["8M", "6M", "5M"],
			doublekick: ["8L10", "7L5", "6L13", "5L13"],
			doubleteam: ["7M", "6M", "5M"],
			dualchop: ["7T", "6T", "5T", "5D"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M", "8L45", "7L33", "6L41", "5L41"],
			facade: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M", "8L5", "7L1", "6L9", "5L9"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			karatechop: ["7L17", "6L25", "5L25"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			lowkick: ["8M", "7T", "6T", "5T"],
			lowsweep: ["8M", "8L20", "7M", "7L9", "6M", "6L17", "5M", "5L17"],
			megakick: ["8M"],
			megapunch: ["8M"],
			painsplit: ["7T", "6T", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			quickguard: ["8L15", "7L37", "6L45", "5L45"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "8L30", "7L29", "6M", "6L37", "5M", "5L37"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M", "8L50", "7L45", "6L50", "5L53"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["8L1", "7L1", "6M", "6L1", "5M", "5L1", "5D"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "7T", "6T", "5T", "5D"],
			toxic: ["7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	sewaddle: {
		learnset: {
			agility: ["7E", "6E", "5E"],
			airslash: ["7E", "6E", "5E"],
			attract: ["7M", "6M", "5M"],
			batonpass: ["7E", "6E", "5E"],
			bugbite: ["7T", "7L8", "6T", "6L8", "5T", "5L8"],
			bugbuzz: ["7L36", "6L36", "5L36"],
			calmmind: ["7M", "6M", "5M"],
			camouflage: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			electroweb: ["7T", "6T", "5T"],
			endure: ["7L29", "6L29", "5L29"],
			energyball: ["7M", "6M", "5M"],
			facade: ["7M", "6M", "5M"],
			flail: ["7L43", "6L43", "5L43"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["7T", "6T", "5T"],
			grassknot: ["7M", "6M", "5M"],
			grassyterrain: ["7E"],
			hiddenpower: ["7M", "6M", "5M"],
			irondefense: ["7T", "6T", "5T"],
			lightscreen: ["7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			mefirst: ["7E", "6E", "5E"],
			mindreader: ["7E", "6E", "5E"],
			naturepower: ["7M", "6M"],
			payback: ["7M", "6M", "5M"],
			protect: ["7M", "6M", "5M"],
			razorleaf: ["7L15", "6L15", "5L15"],
			razorwind: ["7E", "6E", "5E"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			screech: ["7E", "6E", "5E"],
			secretpower: ["6M"],
			seedbomb: ["7T", "6T", "5T"],
			signalbeam: ["7T", "6T", "5T"],
			silverwind: ["7E", "6E", "5E"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			stickyweb: ["7L31", "6L31"],
			stringshot: ["7L1", "6L1", "5L1"],
			strugglebug: ["7L22", "6M", "6L22", "5M", "5L22"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			synthesis: ["7T", "6T", "5T"],
			tackle: ["7L1", "6L1", "5L1"],
			toxic: ["7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T"],
		},
	},
	swadloon: {
		learnset: {
			attract: ["7M", "6M", "5M"],
			bugbite: ["7T", "7L1", "6T", "6L1", "5T", "5L1"],
			calmmind: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			electroweb: ["7T", "6T", "5T"],
			energyball: ["7M", "6M", "5M"],
			facade: ["7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["7T", "6T", "5T"],
			grassknot: ["7M", "6M", "5M"],
			grasswhistle: ["7L1", "6L1", "5L1"],
			hiddenpower: ["7M", "6M", "5M"],
			irondefense: ["7T", "6T", "5T"],
			lightscreen: ["7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			naturepower: ["7M", "6M"],
			payback: ["7M", "6M", "5M"],
			protect: ["7M", "7L1", "6M", "6L20", "5M", "5L20"],
			razorleaf: ["7L1", "6L1", "5L1"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["7T", "6T", "5T"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			stringshot: ["7L1", "6L1", "5L1"],
			strugglebug: ["6M", "5M"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			synthesis: ["7T", "6T", "5T"],
			tackle: ["7L1", "6L1", "5L1"],
			toxic: ["7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T"],
		},
		encounters: [
			{generation: 5, level: 19},
		],
	},
	leavanny: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			attract: ["7M", "6M", "5M"],
			bugbite: ["7T", "7L1", "6T", "6L1", "5T", "5L1"],
			calmmind: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			electroweb: ["7T", "6T", "5T"],
			energyball: ["7M", "6M", "5M"],
			entrainment: ["7L43", "6L43", "5L43"],
			facade: ["7M", "6M", "5M"],
			falseswipe: ["7M", "7L1", "6M", "6L1", "5M", "5L1"],
			fellstinger: ["7L29", "6L34"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["7T", "6T", "5T"],
			gigaimpact: ["7M", "6M", "5M"],
			grassknot: ["7M", "6M", "5M"],
			healbell: ["7T", "6T", "5T"],
			helpinghand: ["7T", "7L32", "6T", "6L32", "5T", "5L32"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["7M", "6M", "5M"],
			irondefense: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			leafblade: ["7L36", "6L36", "5L36"],
			leafstorm: ["7L50", "6L50", "5L50"],
			lightscreen: ["7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			naturepower: ["7M", "6M"],
			payback: ["7M", "6M", "5M"],
			poisonjab: ["7M", "6M", "5M"],
			protect: ["7M", "6M", "5M"],
			razorleaf: ["7L1", "6L1", "5L1"],
			reflect: ["7M", "6M", "5M"],
			rest: ["7M", "6M", "5M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["7T", "6T", "5T"],
			shadowclaw: ["7M", "6M", "5M"],
			signalbeam: ["7T", "6T", "5T"],
			slash: ["7L1", "6L29", "5L29"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["7M", "6M", "5M"],
			steelwing: ["7M", "6M"],
			stringshot: ["7L1", "6L1", "5L1"],
			strugglebug: ["7L22", "6M", "6L22", "5M", "5L22"],
			substitute: ["7M", "6M", "5M"],
			sunnyday: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["7M", "7L46", "6M", "6L46", "5M", "5L46"],
			synthesis: ["7T", "6T", "5T"],
			tackle: ["7L1", "6L1", "5L1"],
			throatchop: ["7T"],
			toxic: ["7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T"],
			xscissor: ["7M", "7L39", "6M", "6L39", "5M", "5L39"],
		},
		encounters: [
			{generation: 5, level: 20, isHidden: true},
		],
	},
	venipede: {
		learnset: {
			agility: ["8M", "8L32", "7L29", "6L29", "5L29"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["8E"],
			bugbite: ["8L20", "7T", "7L22", "6T", "6L22", "5T", "5L22"],
			confide: ["7M", "6M"],
			defensecurl: ["8L1", "7L1", "6L1", "5L1"],
			doubleedge: ["8L44", "7L43", "6L43", "5L43"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["8E"],
			gyroball: ["8M", "7M", "6M", "5M"],
			hex: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			pinmissile: ["8M", "7E", "6E", "5E"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poisonsting: ["8L1", "7L5", "6L5", "5L5"],
			poisontail: ["8L12", "7L19", "6L19", "5L19"],
			protect: ["8M", "8L8", "7M", "7L15", "6M", "6L15", "5M", "5L15"],
			pursuit: ["7L12", "6L12", "5L12"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockclimb: ["7L40", "7E", "6L40", "6E", "5L40", "5E"],
			rocksmash: ["6M", "5M"],
			rollout: ["8L4", "7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L16", "7L8", "6L8", "5L8"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spikes: ["8M", "7E", "6E", "5E"],
			steamroller: ["7L33", "6L33", "5L33"],
			steelroller: ["8T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			takedown: ["8L28", "7E", "6E", "5E"],
			toxic: ["8L36", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			toxicspikes: ["8M", "7E", "6E", "5E"],
			twineedle: ["7E", "6E", "5E"],
			venomdrench: ["8M", "8L40", "7L38"],
			venoshock: ["8M", "8L24", "7M", "7L26", "6M", "6L26", "5M", "5L26"],
		},
	},
	whirlipede: {
		learnset: {
			agility: ["8M", "8L38", "7L32", "6L32", "5L32"],
			attract: ["8M", "7M", "6M", "5M"],
			bugbite: ["8L20", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			confide: ["7M", "6M"],
			defensecurl: ["8L1", "7L1", "6L1", "5L1"],
			doubleedge: ["8L56", "7L50", "6L50", "5L50"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			hex: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "8L0", "7T", "7L1", "6T", "6L22", "5T", "5L22"],
			payback: ["8M", "7M", "6M", "5M"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poisonsting: ["8L1", "7L1", "6L1", "5L1"],
			poisontail: ["8L12", "7L19", "6L19", "5L19"],
			protect: ["8M", "8L1", "7M", "7L15", "6M", "6L15", "5M", "5L15"],
			pursuit: ["7L12", "6L12", "5L12"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockclimb: ["7L46", "6L46", "5L46"],
			rocksmash: ["6M", "5M"],
			rollout: ["8L1", "7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L16", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spikes: ["8M"],
			steamroller: ["7L37", "6L37", "5L37"],
			steelroller: ["8T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			takedown: ["8L32"],
			toxic: ["8L44", "7M", "7L41", "6M", "6L41", "5M", "5L41"],
			toxicspikes: ["8M"],
			venomdrench: ["8M", "8L50", "7L43", "6L43"],
			venoshock: ["8M", "8L26", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
		},
	},
	scolipede: {
		learnset: {
			agility: ["8M", "8L42", "7L33", "6L33", "5L33"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			batonpass: ["8M", "8L1", "7L1", "6L30", "5L30"],
			bugbite: ["8L20", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crosspoison: ["8M"],
			cut: ["6M", "5M"],
			defensecurl: ["8L1", "7L1", "6L1", "5L1"],
			dig: ["8M", "6M", "5M"],
			doubleedge: ["8L66", "7L55", "6L55", "5L55"],
			doubleteam: ["7M", "6M", "5M"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			hex: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "8L1", "7T", "7L1", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			megahorn: ["8M", "8L74", "7L1", "6L1", "5L1"],
			payback: ["8M", "7M", "6M", "5M"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poisonsting: ["8L1", "7L1", "6L1", "5L1"],
			poisontail: ["8L12", "7L19", "6L19", "5L19", "5D"],
			protect: ["8M", "8L1", "7M", "7L15", "6M", "6L15", "5M", "5L15"],
			pursuit: ["7L12", "6L12", "5L12"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockclimb: ["7L50", "6L50", "5L50"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rollout: ["8L1", "7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L16", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			smartstrike: ["8M", "7M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spikes: ["8M"],
			steamroller: ["7L39", "6L39", "5L39"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T", "5D"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "7M", "6M", "5M"],
			takedown: ["8L34"],
			throatchop: ["8M", "7T"],
			toxic: ["8L50", "7M", "7L44", "6M", "6L44", "5M", "5L44"],
			toxicspikes: ["8M", "5D"],
			venomdrench: ["8M", "8L58", "7L47", "6L47"],
			venoshock: ["8M", "8L26", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			xscissor: ["8M", "7M", "6M", "5M"],
		},
	},
	cottonee: {
		learnset: {
			absorb: ["8L1", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M", "7E", "6E", "5E"],
			captivate: ["7E", "6E"],
			charm: ["8M", "8L27", "7L28", "6L28", "5L28"],
			confide: ["7M", "6M"],
			cottonguard: ["8L45", "7L37", "6L37", "5L37"],
			cottonspore: ["8L33", "7L17", "6L17", "5L17"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			encore: ["8M", "7E", "6E", "5E", "5D"],
			endeavor: ["8L42", "7T", "7L44", "6T", "6L44", "5T", "5L44"],
			endure: ["8M"],
			energyball: ["8M", "8L36", "7M", "7L35", "6M", "6L35", "5M", "5L35"],
			facade: ["8M", "7M", "6M", "5M"],
			fairywind: ["8L3", "7L1", "6L1"],
			faketears: ["8M", "7E", "6E", "5E"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "8L24", "7T", "7L26", "6T", "6L26", "5T", "5L26"],
			grassknot: ["8M", "7M", "6M", "5M"],
			grasswhistle: ["7E", "6E", "5E"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growth: ["8L18", "7L4", "6L4", "5L4"],
			helpinghand: ["8M", "8L1", "7T", "7L31", "6T", "6L31", "5T", "5L31"],
			hiddenpower: ["7M", "6M", "5M"],
			knockoff: ["7T", "6T", "5T"],
			leechseed: ["8L30", "7L8", "6L8", "5L8", "5D"],
			megadrain: ["8L12", "7L13", "6L13", "5L13"],
			memento: ["8E", "7E", "6E", "5E"],
			mistyterrain: ["8M", "7E"],
			naturalgift: ["7E", "6E", "5E"],
			naturepower: ["8E", "7M", "6M"],
			poisonpowder: ["8L21", "7L22", "6L22", "5L22"],
			protect: ["8M", "7M", "6M", "5M"],
			razorleaf: ["8L15", "7L19", "6L19", "5L19"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "8L48", "7M", "7L46", "6M", "6L46", "5M", "5L46"],
			stunspore: ["8L6", "7L10", "6L10", "5L10"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "8L39", "7M", "7L40", "6M", "6L40", "5M", "5L40"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			switcheroo: ["8E", "7E", "6E", "5E"],
			tailwind: ["7T", "6T", "5T"],
			taunt: ["8M", "7M", "6M", "5M"],
			tickle: ["8E", "7E", "6E", "5E"],
			toxic: ["7M", "6M", "5M"],
			worryseed: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
		},
	},
	whimsicott: {
		learnset: {
			absorb: ["8L1"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M", "5S0"],
			charm: ["8M", "8L1"],
			confide: ["7M", "6M"],
			cottonguard: ["8L1"],
			cottonspore: ["8L1", "7L1", "6L1", "5L1"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			encore: ["8M"],
			endeavor: ["8L1", "7T", "6T", "5T"],
			endure: ["8M"],
			energyball: ["8M", "8L1", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			fairywind: ["8L1"],
			faketears: ["8M"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "8L1", "7T", "6T", "5T", "5S0"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growth: ["8L1", "7L1", "6L1", "5L1"],
			gust: ["8L1", "7L10", "6L10", "5L10"],
			helpinghand: ["8M", "8L1", "7T", "6T", "5T", "5S0"],
			hiddenpower: ["7M", "6M", "5M"],
			hurricane: ["8M", "8L1", "7L46", "6L46", "5L46"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			knockoff: ["7T", "6T", "5T"],
			leechseed: ["8L1", "7L1", "6L1", "5L1"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			megadrain: ["8L1", "7L1", "6L1", "5L1"],
			memento: ["8L1"],
			mistyterrain: ["8M"],
			moonblast: ["8L1", "7L50", "6L50"],
			naturepower: ["7M", "6M"],
			playrough: ["8M"],
			poisonpowder: ["8L1"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			razorleaf: ["8L1"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			shadowball: ["8M", "7M", "6M", "5M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "8L1", "7M", "6M", "5M"],
			stunspore: ["8L1"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "8L1", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M", "5S0"],
			swift: ["8M"],
			tailwind: ["8L1", "7T", "7L28", "6T", "6L28", "5T", "5L28"],
			taunt: ["8M", "7M", "6M", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trickroom: ["8M", "7M", "6M", "5M"],
			uturn: ["8M", "7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 50, gender: "F", nature: "Timid", ivs: {spe: 31}, abilities: ["prankster"], moves: ["swagger", "gigadrain", "beatup", "helpinghand"], pokeball: "cherishball"},
		],
	},
	petilil: {
		learnset: {
			absorb: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			afteryou: ["9L27", "8L27", "7T", "7L44", "6T", "6L44", "5T", "5L44"],
			aromatherapy: ["8L12", "7L28", "6L28", "5L28"],
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7E", "6E", "5E"],
			bulletseed: ["9M"],
			charm: ["9M", "9L12", "8M", "7E", "6E", "5E", "5D"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			encore: ["9M", "8M", "7E"],
			endure: ["9M", "8M", "7E", "6E", "5E"],
			energyball: ["9M", "9L30", "8M", "8L30", "7M", "7L35", "6M", "6L35", "5M", "5L35"],
			entrainment: ["9L39", "8L39", "7L37", "6L37", "5L37"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["9M", "9L21", "8M", "8L21", "7T", "7L26", "6T", "6L26", "5T", "5L26"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			grasswhistle: ["7E", "6E", "5E"],
			grassyglide: ["8T"],
			growth: ["9L1", "8L1", "7L4", "6L4", "5L4"],
			healbell: ["7T", "6T", "5T"],
			healingwish: ["9E", "8E", "7E", "6E", "5E"],
			helpinghand: ["9M", "9L3", "8M", "8L3", "7T", "7L31", "6T", "6L31", "5T", "5L31"],
			hiddenpower: ["7M", "6M", "5M"],
			ingrain: ["9E", "8E", "7E", "6E", "5E"],
			laserfocus: ["7T"],
			leafstorm: ["9M", "9L42", "8M", "8L42", "7L46", "6L46", "5L46"],
			leechseed: ["9L24", "8L24", "7L8", "6L8", "5L8"],
			magicalleaf: ["9M", "9L15", "8M", "8L15", "7L19", "6L19", "5L19"],
			megadrain: ["9L9", "8L9", "7L13", "6L13", "5L13"],
			naturalgift: ["7E", "6E", "5E"],
			naturepower: ["7M", "6M"],
			pollenpuff: ["9M", "8M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T"],
			sleeppowder: ["9L18", "8L18", "7L10", "6L10", "5L10", "5D"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M"],
			stunspore: ["9L6", "8L6", "7L22", "6L22", "5L22"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "9L36", "8M", "8L36", "7M", "7L40", "6M", "6L40", "5M", "5L40"],
			swagger: ["7M", "6M", "5M"],
			sweetscent: ["9E", "8E", "7E", "6E", "5E", "5D"],
			synthesis: ["9L33", "8L33", "7T", "7L17", "6T", "6L17", "5T", "5L17"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			worryseed: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E"],
		},
	},
	lilligant: {
		learnset: {
			absorb: ["9L1", "8L1"],
			afteryou: ["9L1", "8L1", "7T", "6T", "5T"],
			aromatherapy: ["8L1"],
			attract: ["8M", "7M", "6M", "5M"],
			bulletseed: ["9M"],
			charm: ["9M", "9L1", "8M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "9L1", "8M", "8L1", "7M", "6M", "5M"],
			entrainment: ["9L1", "8L1"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["9M", "9L1", "8M", "8L1", "7T", "6T", "5T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			grassyglide: ["8T"],
			grassyterrain: ["9M", "8M"],
			growth: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			healbell: ["7T", "6T", "5T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			laserfocus: ["7T"],
			leafblade: ["8M"],
			leafstorm: ["9M", "9L1", "8M", "8L1"],
			leechseed: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			magicalleaf: ["9M", "9L5", "8M", "8L1"],
			megadrain: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["9L1", "8L1", "7L50", "6L50"],
			petaldance: ["9L0", "8L0", "7L46", "6L46", "5L46"],
			pollenpuff: ["9M", "8M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			quiverdance: ["9L1", "8L1", "7L28", "6L28", "5L28"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T"],
			sleeppowder: ["9L1", "8L1"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M"],
			solarblade: ["8M"],
			stunspore: ["9L1", "8L1"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "9L1", "8M", "8L1", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M"],
			synthesis: ["9L1", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			teeterdance: ["9L1", "8L1", "7L10", "6L10", "5L10"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			worryseed: ["7T", "6T", "5T"],
		},
	},
	lilliganthisui: {
		learnset: {
			absorb: ["9L1"],
			acrobatics: ["9M"],
			aerialace: ["9M"],
			afteryou: ["9L1"],
			airslash: ["9M"],
			axekick: ["9L5"],
			brickbreak: ["9M"],
			bulletseed: ["9M"],
			charm: ["9M"],
			closecombat: ["9M"],
			defog: ["9L1"],
			encore: ["9M"],
			endure: ["9M"],
			energyball: ["9M", "9L1"],
			entrainment: ["9L1"],
			facade: ["9M"],
			gigadrain: ["9M", "9L1"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			growth: ["9L1"],
			helpinghand: ["9M", "9L1"],
			hurricane: ["9M"],
			hyperbeam: ["9M"],
			icespinner: ["9M"],
			leafblade: ["9L1"],
			leafstorm: ["9M", "9L1"],
			leechseed: ["9L1"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			magicalleaf: ["9M", "9L1"],
			megadrain: ["9L1"],
			megakick: ["9L1"],
			metronome: ["9M"],
			petalblizzard: ["9L1"],
			poisonjab: ["9M"],
			pollenpuff: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			seedbomb: ["9M"],
			sleeppowder: ["9L1"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			solarblade: ["9L1"],
			stunspore: ["9L1"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L1"],
			swordsdance: ["9M"],
			synthesis: ["9L1"],
			takedown: ["9M"],
			teeterdance: ["9L1"],
			terablast: ["9M"],
			trailblaze: ["9M"],
			victorydance: ["9L0"],
		},
	},
	basculin: {
		learnset: {
			agility: ["9M", "8M", "7E", "6E", "5E", "5D"],
			aquajet: ["9L12", "8L12", "7L9", "6L13", "5L13"],
			aquatail: ["8L44", "7T", "7L20", "6T", "6L28", "5T", "5L28"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["9L16", "8L16", "7L7", "6L10", "5L10"],
			blizzard: ["9M"],
			bounce: ["8M", "7T", "6T", "5T"],
			brine: ["8M", "7E", "6E", "5E"],
			bubblebeam: ["9E", "8E", "7E", "6E", "5E"],
			chillingwater: ["9M"],
			chipaway: ["7L11", "6L16", "5L16"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L32", "8M", "8L32", "7L17", "6L24", "5L24"],
			cut: ["6M", "5M"],
			dive: ["8M", "6M", "5M"],
			doubleedge: ["9L52", "8L52", "7L26", "6L36", "5L36"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["9E", "8E", "7T", "6T", "5T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			finalgambit: ["9L40", "8L40", "7L38", "6L50", "5L51"],
			flail: ["9L8", "8L8", "7L34", "6L1", "5L46"],
			flipturn: ["8T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M"],
			hail: ["8M", "7M", "6M", "5M"],
			headbutt: ["9L24", "8L24", "7L5", "6L7", "5L7", "5D"],
			headsmash: ["9L56", "8L56", "7L46", "7E"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M"],
			icefang: ["9M", "8M"],
			icywind: ["9M", "8M", "7T", "6T", "5T"],
			liquidation: ["9M", "8M", "7T"],
			muddywater: ["8M", "7E", "6E", "5E"],
			mudshot: ["9M", "8M", "7E", "6E", "5E"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychicfangs: ["9M", "8M"],
			rage: ["7E", "6E", "5E"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M", "7E", "6E", "5E"],
			reversal: ["9M", "8M"],
			round: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L20", "8M", "8L20", "7L30", "6L41", "5L41"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			snowscape: ["9M"],
			soak: ["9L28", "8L28", "7L23", "6L32", "5L32"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M", "8M", "7E", "6E", "5E"],
			tackle: ["9L4", "8L4", "7L1", "6L1", "5L1"],
			tailwhip: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M", "9L36", "8L36", "7L14", "6L20", "5L20"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thrash: ["9L48", "8L48", "7L42", "6L1", "5L56"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "7L3", "6T", "6L4", "5T", "5L4"],
			waterfall: ["9M", "8M", "7M", "6M", "5M"],
			watergun: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			waterpulse: ["9M"],
			wavecrash: ["9L44"],
			whirlpool: ["8M", "7E", "6E", "5E"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T", "5D"],
		},
	},
	basculinwhitestriped: {
		learnset: {
			agility: ["9M"],
			aquajet: ["9L12"],
			bite: ["9L16"],
			blizzard: ["9M"],
			chillingwater: ["9M"],
			crunch: ["9M", "9L32"],
			doubleedge: ["9L52"],
			endeavor: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			flail: ["9L8"],
			headbutt: ["9L24"],
			headsmash: ["9L56"],
			hydropump: ["9M"],
			icebeam: ["9M"],
			icefang: ["9M"],
			icywind: ["9M"],
			lastrespects: ["9E"],
			liquidation: ["9M"],
			mudshot: ["9M"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scaryface: ["9M", "9L20"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			soak: ["9L28"],
			substitute: ["9M"],
			surf: ["9M"],
			swift: ["9M"],
			tackle: ["9L4"],
			tailwhip: ["9L1"],
			takedown: ["9M", "9L36"],
			terablast: ["9M"],
			thrash: ["9L48"],
			uproar: ["9L40"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M"],
			wavecrash: ["9L44"],
			zenheadbutt: ["9M"],
		},
	},
	basculegion: {
		learnset: {
			agility: ["9M"],
			aquajet: ["9L12"],
			bite: ["9L16"],
			blizzard: ["9M"],
			chillingwater: ["9M"],
			confuseray: ["9M"],
			crunch: ["9M", "9L32"],
			doubleedge: ["9L52"],
			endeavor: ["9E"],
			facade: ["9M"],
			flail: ["9L8"],
			gigaimpact: ["9M"],
			headbutt: ["9L24"],
			headsmash: ["9L56"],
			hex: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M"],
			icefang: ["9M"],
			icywind: ["9M"],
			lastrespects: ["9E"],
			liquidation: ["9M"],
			mudshot: ["9M"],
			nightshade: ["9M"],
			outrage: ["9M"],
			phantomforce: ["9M", "9L1"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scaryface: ["9M", "9L20"],
			shadowball: ["9M", "9L1"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			soak: ["9L28"],
			substitute: ["9M"],
			surf: ["9M"],
			tackle: ["9L4"],
			tailwhip: ["9L1"],
			takedown: ["9M", "9L36"],
			terablast: ["9M"],
			thrash: ["9L48"],
			uproar: ["9L40"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M"],
			wavecrash: ["9L44"],
		},
	},
	basculegionf: {
		learnset: {
			agility: ["9M"],
			aquajet: ["9L12"],
			bite: ["9L16"],
			blizzard: ["9M"],
			chillingwater: ["9M"],
			confuseray: ["9M"],
			crunch: ["9M", "9L32"],
			doubleedge: ["9L52"],
			endeavor: ["9E"],
			facade: ["9M"],
			flail: ["9L8"],
			gigaimpact: ["9M"],
			headbutt: ["9L24"],
			headsmash: ["9L56"],
			hex: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M"],
			icefang: ["9M"],
			icywind: ["9M"],
			lastrespects: ["9E"],
			liquidation: ["9M"],
			mudshot: ["9M"],
			nightshade: ["9M"],
			outrage: ["9M"],
			phantomforce: ["9M", "9L1"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scaryface: ["9M", "9L20"],
			shadowball: ["9M", "9L1"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			soak: ["9L28"],
			substitute: ["9M"],
			surf: ["9M"],
			tackle: ["9L4"],
			tailwhip: ["9L1"],
			takedown: ["9M", "9L36"],
			terablast: ["9M"],
			thrash: ["9L48"],
			uproar: ["9L40"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M"],
			wavecrash: ["9L44"],
		},
	},
	sandile: {
		learnset: {
			aquatail: ["9E", "8E", "7T", "6T", "5T"],
			assurance: ["8M", "7L16", "6L16", "5L16"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M", "7E", "6E", "5E"],
			bite: ["9L15", "8L15", "7L4", "6L4", "5L4"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "6E", "5E"],
			crunch: ["9M", "9L27", "8M", "8L27", "7L28", "6L28", "5L28"],
			cut: ["6M", "5M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T"],
			dig: ["9M", "9L21", "8M", "8L21", "7L31", "6M", "6L31", "5M", "5L31"],
			doubleedge: ["9E", "8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T"],
			earthquake: ["9M", "9L36", "8M", "8L36", "7M", "7L43", "6M", "6L43", "5M", "5L43"],
			embargo: ["7M", "7L22", "6M", "6L22", "5M", "5L22"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			firefang: ["9M", "8M", "7E", "6E", "5E"],
			fling: ["9M"],
			focusenergy: ["8M", "7E", "6E", "5E"],
			foulplay: ["9M", "9L33", "8M", "8L33", "7T", "7L37", "6T", "6L37", "5T", "5L37"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["9L6", "8L6", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			meanlook: ["7E", "6E", "5E"],
			mefirst: ["7E", "6E"],
			mudshot: ["9M"],
			mudslap: ["9M", "7L19", "6L19", "5L19"],
			payback: ["8M", "7M", "6M", "5M"],
			powertrip: ["9L1", "8L1", "7E"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			pursuit: ["7E", "6E", "5E"],
			rage: ["7L1", "6L1", "5L1"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["7M", "6M", "5M"],
			rockclimb: ["7E", "6E", "5E"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L3", "8L3", "7L7", "6L7", "5L7"],
			sandstorm: ["9M", "9L30", "8M", "8L30", "7M", "7L40", "6M", "6L40", "5M", "5L40"],
			sandtomb: ["9L9", "8M", "8L9", "7L13", "6L13", "5L13"],
			scaryface: ["9M", "9L12", "8M", "8L12", "7L34", "6L34", "5L34"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			shadowclaw: ["9M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["9E", "8E", "7T", "6T", "5T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["9L24", "8L24", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thrash: ["9L39", "8L39", "7L46", "6L46", "5L46"],
			thunderfang: ["9M", "8M", "7E", "6E", "5E"],
			torment: ["9L18", "8L18", "7M", "7L10", "6M", "6L10", "5M", "5L10"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
		},
	},
	krokorok: {
		learnset: {
			aerialace: ["9M"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["8M", "7L16", "6L16", "5L16"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M"],
			bite: ["9L15", "8L15", "7L1", "6L1", "5L1"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L27", "8M", "8L27", "7L28", "6L28", "5L28"],
			cut: ["6M", "5M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T"],
			dig: ["9M", "9L21", "8M", "8L21", "7L32", "6M", "6L32", "5M", "5L32"],
			doubleteam: ["7M", "6M", "5M"],
			dragonclaw: ["9M"],
			dragontail: ["9M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T"],
			earthquake: ["9M", "9L42", "8M", "8L42", "7M", "7L48", "6M", "6L48", "5M", "5L48"],
			embargo: ["7M", "7L22", "6M", "6L22", "5M", "5L22"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			firefang: ["9M", "8M"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T"],
			foulplay: ["9M", "9L35", "8M", "8L35", "7T", "7L40", "6T", "6L40", "5T", "5L40"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["9L1", "8L1", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudshot: ["9M"],
			mudslap: ["9M", "7L19", "6L19", "5L19"],
			payback: ["8M", "7M", "6M", "5M"],
			powertrip: ["9L1", "8L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			rage: ["7L1", "6L1", "5L1"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			roar: ["7M", "6M", "5M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			sandstorm: ["9M", "9L32", "8M", "8L32", "7M", "7L44", "6M", "6L44", "5M", "5L44"],
			sandtomb: ["9L9", "8M", "8L9", "7L13", "6L13", "5L13"],
			scaryface: ["9M", "9L12", "8M", "8L12", "7L36", "6L36", "5L36"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T"],
			stompingtantrum: ["9M", "8M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["9L24", "8L24", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thrash: ["9L47", "8L47", "7L52", "6L52", "5L52"],
			thunderfang: ["9M", "8M"],
			torment: ["9L18", "8L18", "7M", "7L10", "6M", "6L10", "5M", "5L10"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
		},
	},
	krookodile: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["8M", "7L16", "6L16", "5L16"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M"],
			bite: ["9L15", "8L15", "7L1", "6L1", "5L1"],
			block: ["7T", "6T", "5T"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			closecombat: ["9M", "8M"],
			confide: ["7M", "6M"],
			counter: ["5D"],
			crunch: ["9M", "9L27", "8M", "8L27", "7L28", "6L28", "5L28", "5D"],
			cut: ["6M", "5M"],
			darkestlariat: ["8M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T"],
			dig: ["9M", "9L21", "8M", "8L21", "7L32", "6M", "6L32", "5M", "5L32"],
			doubleteam: ["7M", "6M", "5M"],
			dragonclaw: ["9M", "8M", "7M", "6M", "5M"],
			dragonpulse: ["9M", "8M", "7T", "6T", "5T"],
			dragontail: ["9M", "7M", "6M", "5M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T"],
			earthquake: ["9M", "9L44", "8M", "8L44", "7M", "7L54", "6M", "6L54", "5M", "5L54"],
			embargo: ["7M", "7L22", "6M", "6L22", "5M", "5L22"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			firefang: ["9M", "8M"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T"],
			foulplay: ["9M", "9L35", "8M", "8L35", "7T", "7L42", "6T", "6L42", "5T", "5L42"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			gunkshot: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			highhorsepower: ["8M"],
			honeclaws: ["9L1", "8L1", "6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			meanlook: ["5D"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudshot: ["9M"],
			mudslap: ["9M", "7L19", "6L19", "5L19"],
			outrage: ["9M", "9L58", "8M", "8L58", "7T", "7L60", "6T", "6L1", "5T", "5L60"],
			payback: ["8M", "7M", "6M", "5M"],
			powertrip: ["9L1", "8L1", "7L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			rage: ["7L1", "6L1", "5L1"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			roar: ["7M", "6M", "5M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			sandstorm: ["9M", "9L32", "8M", "8L32", "7M", "7L48", "6M", "6L48", "5M", "5L48"],
			sandtomb: ["9L9", "8M", "8L9", "7L13", "6L13", "5L13"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L12", "8M", "8L12", "7L36", "6L36", "5L36"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["9L24", "8L24", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thrash: ["9L51", "8L51"],
			throatchop: ["8M", "7T"],
			thunderfang: ["9M", "8M"],
			torment: ["9L18", "8L18", "7M", "7L10", "6M", "6L10", "5M", "5L10"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
		},
	},
	darumaka: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			bellydrum: ["8L36", "7L30", "6L30", "5L30"],
			bite: ["8L8"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			ember: ["8L1"],
			encore: ["8M", "7E", "6E", "5E"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "7E", "6E", "5E"],
			extrasensory: ["8E", "7E"],
			facade: ["8M", "7M", "7L19", "6M", "6L19", "5M", "5L19"],
			fireblast: ["8M", "7M", "6M", "5M"],
			firefang: ["8M", "8L20", "7L11", "6L11", "5L11"],
			firepunch: ["8M", "8L28", "7T", "7L22", "6T", "6L22", "5T", "5L22"],
			firespin: ["8M"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flamewheel: ["8E", "7E", "6E", "5E"],
			flareblitz: ["8M", "8L40", "7L33", "6L33", "5L33"],
			fling: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M", "7E", "6E", "5E"],
			focuspunch: ["8E", "7T", "7E", "6T", "6E", "5E"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			hammerarm: ["8E", "7E", "6E", "5E"],
			headbutt: ["8L24", "7L14", "6L14", "5L14"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			incinerate: ["8L12", "7L6", "6M", "6L6", "5M", "5L6"],
			megakick: ["8M"],
			megapunch: ["8M"],
			overheat: ["8M", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			rage: ["7L9", "6L9", "5L9"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rollout: ["7L3", "6L3", "5L3"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			snatch: ["7T", "7E", "6T", "6E"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "8L48", "7T", "7L39", "6T", "6L39", "5T", "5L39"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			takedown: ["8E", "7E", "6E", "5E"],
			taunt: ["8M", "8L4", "7M", "7L35", "6M", "6L35", "5M", "5L35"],
			thief: ["8M", "7M", "6M", "5M"],
			thrash: ["8L44", "7L27", "6L27", "5L27"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "8L32", "7T", "7L17", "6T", "6L17", "5T", "5L17"],
			uturn: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "8L16", "7M", "7L25", "6L25", "5M", "5L25"],
			yawn: ["8E", "7E", "6E", "5E"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
	},
	darumakagalar: {
		learnset: {
			attract: ["8M"],
			avalanche: ["8M", "8L12"],
			bellydrum: ["8L36"],
			bite: ["8L8"],
			blizzard: ["8M", "8L40"],
			brickbreak: ["8M"],
			dig: ["8M"],
			encore: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			fireblast: ["8M"],
			firefang: ["8M"],
			firepunch: ["8M"],
			firespin: ["8M"],
			flamethrower: ["8M"],
			flamewheel: ["8E"],
			flareblitz: ["8M"],
			fling: ["8M"],
			focusenergy: ["8M"],
			focuspunch: ["8E"],
			freezedry: ["8E"],
			grassknot: ["8M"],
			gyroball: ["8M"],
			hammerarm: ["8E"],
			headbutt: ["8L24"],
			heatwave: ["8M"],
			icebeam: ["8M"],
			icefang: ["8M", "8L20"],
			icepunch: ["8M", "8L28"],
			incinerate: ["8E"],
			megakick: ["8M"],
			megapunch: ["8M"],
			overheat: ["8M"],
			powdersnow: ["8L1"],
			poweruppunch: ["8E"],
			protect: ["8M"],
			rest: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			superpower: ["8M", "8L48"],
			tackle: ["8L1"],
			takedown: ["8E"],
			taunt: ["8M", "8L4"],
			thief: ["8M"],
			thrash: ["8L44"],
			uproar: ["8M", "8L32"],
			uturn: ["8M"],
			willowisp: ["8M"],
			workup: ["8M", "8L16"],
			yawn: ["8E"],
			zenheadbutt: ["8M"],
		},
	},
	darmanitan: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			bellydrum: ["8L38", "7L30", "6L30", "6S1", "5L30", "5S0"],
			bite: ["8L1"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			bulkup: ["8M", "7M", "6M", "5M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			burningjealousy: ["8T"],
			confide: ["7M", "6M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthquake: ["8M", "7M", "6M", "5M"],
			ember: ["8L1"],
			encore: ["8M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "7L19", "6M", "6L19", "5M", "5L19"],
			fireblast: ["8M", "7M", "6M", "5M"],
			firefang: ["8M", "8L20", "7L11", "6L11", "5L11"],
			firepunch: ["8M", "8L28", "7T", "7L22", "6T", "6L22", "5T", "5L22"],
			firespin: ["8M"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flareblitz: ["8M", "8L44", "7L33", "6L33", "6S1", "5L33", "5S0"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			hammerarm: ["8L0", "7L1", "6L35", "6S1", "5L35", "5S0"],
			headbutt: ["8L24", "7L14", "6L14", "5L14"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			incinerate: ["8L12", "7L1", "6M", "6L1", "5M", "5L1"],
			irondefense: ["8M"],
			ironhead: ["8M"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mysticalfire: ["8M"],
			overheat: ["8M", "7M", "7L54", "6M", "6L54", "5M", "5L54"],
			payback: ["8M", "7M", "6M", "5M"],
			powerswap: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			rage: ["7L1", "6L1", "5L1"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			reversal: ["8M"],
			roar: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rollout: ["7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["7M", "6M", "5M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "8L56", "7T", "7L47", "6T", "6L47", "5T", "5L47"],
			swagger: ["7M", "7L17", "6M", "6L17", "5M", "5L17"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			taunt: ["8M", "8L1", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			thief: ["8M", "7M", "6M", "5M"],
			thrash: ["8L50", "7L27", "6L27", "6S1", "5L27", "5S0"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M"],
			uproar: ["8M", "8L32", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "8L16", "7M", "7L25", "6L25", "5M", "5L25"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 35, isHidden: true, moves: ["thrash", "bellydrum", "flareblitz", "hammerarm"]},
			{generation: 6, level: 35, gender: "M", nature: "Calm", ivs: {hp: 30, atk: 30, def: 30, spa: 30, spd: 30, spe: 30}, isHidden: true, moves: ["thrash", "bellydrum", "flareblitz", "hammerarm"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 6, level: 32, maxEggMoves: 1},
		],
	},
	darmanitangalar: {
		learnset: {
			attract: ["8M"],
			avalanche: ["8M", "8L12"],
			bellydrum: ["8L38"],
			bite: ["8L1"],
			blizzard: ["8M", "8L44"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M"],
			bulkup: ["8M"],
			bulldoze: ["8M"],
			burningjealousy: ["8T"],
			dig: ["8M"],
			earthquake: ["8M"],
			encore: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			fireblast: ["8M"],
			firefang: ["8M"],
			firepunch: ["8M"],
			firespin: ["8M"],
			flamethrower: ["8M"],
			flareblitz: ["8M"],
			fling: ["8M"],
			focusblast: ["8M"],
			focusenergy: ["8M"],
			gigaimpact: ["8M"],
			grassknot: ["8M"],
			gyroball: ["8M"],
			headbutt: ["8L24"],
			heatwave: ["8M"],
			hyperbeam: ["8M"],
			icebeam: ["8M"],
			icefang: ["8M", "8L20"],
			icepunch: ["8M", "8L28"],
			iciclecrash: ["8L0"],
			irondefense: ["8M"],
			ironhead: ["8M"],
			lashout: ["8T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			overheat: ["8M"],
			payback: ["8M"],
			powdersnow: ["8L1"],
			protect: ["8M"],
			psychic: ["8M"],
			rest: ["8M"],
			reversal: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			stoneedge: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			superpower: ["8M", "8L56"],
			tackle: ["8L1"],
			taunt: ["8M", "8L1"],
			thief: ["8M"],
			thrash: ["8L50"],
			uproar: ["8M", "8L32"],
			uturn: ["8M"],
			willowisp: ["8M"],
			workup: ["8M", "8L16"],
			zenheadbutt: ["8M"],
		},
	},
	maractus: {
		learnset: {
			absorb: ["8L1", "7L1", "6L1", "5L1"],
			acupressure: ["8L52", "7L29", "6L29", "5L29"],
			aerialace: ["7M", "6M", "5M"],
			afteryou: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L57"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			bounce: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			bulletseed: ["8M", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			cottonguard: ["8L60", "7L1", "6L1", "5L55"],
			cottonspore: ["8L40", "7L18", "6L18", "5L18"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "8L24", "7T", "7L26", "6T", "6L26", "5T", "5L26"],
			grassknot: ["8M", "7M", "6M", "5M"],
			grasswhistle: ["7E", "6E", "5E"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7E", "6E"],
			growth: ["8L4", "7L6", "6L6", "5L6"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hypervoice: ["8M", "7T", "6T", "5T"],
			ingrain: ["8L1", "7L33", "6L33", "5L33"],
			knockoff: ["7T", "6T", "5T"],
			leafstorm: ["8M"],
			leechseed: ["8L12", "7E", "6E", "5E", "5D"],
			megadrain: ["8L8", "7L13", "6L13", "5L13"],
			naturepower: ["7M", "6M"],
			needlearm: ["7L22", "6L22", "5L22"],
			peck: ["8L1", "7L1", "6L1", "5L1"],
			petalblizzard: ["8L36", "7L48", "6L48"],
			petaldance: ["8L56", "7L38", "6L38", "5L38"],
			pinmissile: ["8M", "8L20", "7L10", "6L10", "5L10", "5D"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "8L48", "7M", "7L50", "6M", "6L50", "5M", "5L50"],
			spikes: ["8M", "7E", "6E", "5E", "5D"],
			spikyshield: ["8L1", "7L1", "6L1"],
			substitute: ["8M", "7M", "6M", "5M"],
			suckerpunch: ["8L16", "7L42", "6L42", "5L42"],
			sunnyday: ["8M", "8L44", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			swagger: ["7M", "6M", "5M"],
			sweetscent: ["8L28", "7L3", "6L3", "5L3"],
			synthesis: ["8L32", "7T", "7L15", "6T", "6L15", "5T", "5L15"],
			throatchop: ["8M", "7T"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			weatherball: ["8M"],
			woodhammer: ["8E", "7E", "6E", "5E"],
			worryseed: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
		},
	},
	dwebble: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			attract: ["8M", "7M", "6M", "5M"],
			block: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			bugbite: ["8L12", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E"],
			curse: ["8E", "7E", "6E", "5E"],
			cut: ["6M", "5M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M", "7E", "6E", "5E"],
			facade: ["8M", "7M", "6M", "5M"],
			feintattack: ["7L13", "6L13", "5L13"],
			flail: ["8L16", "7L41", "6L41", "5L41"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["8L1", "7L1", "6L1", "5L1"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			irondefense: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			knockoff: ["8E", "7T", "6T", "5T"],
			naturepower: ["7M", "6M"],
			nightslash: ["8E", "7E", "6E", "5E"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M", "8L32", "7L5", "6L5", "5L5"],
			rockpolish: ["8L40", "7M", "7L19", "6M", "6L19", "5M", "5L19"],
			rockslide: ["8M", "8L24", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rockwrecker: ["8L48", "7L43", "6L43", "5L43"],
			rototiller: ["7E", "6E"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L11", "6L11", "5L11"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			sandtomb: ["8M", "7E", "6E", "5E"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			shellsmash: ["8L44", "7L37", "6L37", "5L37"],
			skittersmack: ["8T"],
			slash: ["8L20", "7L31", "6L31", "5L31"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["8L8", "7M", "7L17", "6M", "6L17", "5M", "5L17"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spikes: ["8M", "7E", "6E", "5E"],
			stealthrock: ["8M", "8L28", "7T", "7L24", "6T", "6L24", "5T", "5L24"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			wideguard: ["8E", "7E", "6E"],
			withdraw: ["8L4", "7L7", "6L7", "5L7"],
			xscissor: ["8M", "8L36", "7M", "7L35", "6M", "6L35", "5M", "5L35"],
		},
	},
	crustle: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			attract: ["8M", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			bodypress: ["8M"],
			bugbite: ["8L12", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			counter: ["5D"],
			cut: ["6M", "5M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			feintattack: ["7L13", "6L13", "5L13"],
			flail: ["8L16", "7L50", "6L50", "5L50"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["8L1"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			meteorbeam: ["8T"],
			naturepower: ["7M", "6M"],
			nightslash: ["5D"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M", "8L32", "7L1", "6L1", "5L1"],
			rockpolish: ["8L44", "7M", "7L19", "6M", "6L19", "5M", "5L19"],
			rockslide: ["8M", "8L24", "7M", "7L29", "6M", "6L29", "5M", "5L29", "5D"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rockwrecker: ["8L56", "7L55", "6L55", "5L55"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L1"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			sandtomb: ["8M"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			shellsmash: ["8L50", "7L1", "6L1", "5L1"],
			skittersmack: ["8T"],
			slash: ["8L20", "7L31", "6L31", "5L31"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["8L1", "7M", "7L17", "6M", "6L17", "5M", "5L17"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			solarblade: ["8M"],
			spikes: ["8M"],
			stealthrock: ["8M", "8L28", "7T", "7L24", "6T", "6L24", "5T", "5L24"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			withdraw: ["8L1", "7L1", "6L1", "5L1"],
			xscissor: ["8M", "8L38", "7M", "7L38", "6M", "6L38", "5M", "5L38"],
		},
		encounters: [
			{generation: 6, level: 33, maxEggMoves: 1},
		],
	},
	scraggy: {
		learnset: {
			acidspray: ["8E", "7E"],
			amnesia: ["8M", "7E", "6E", "5E"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M", "8L24"],
			brickbreak: ["8M", "8L32", "7M", "7L23", "6M", "6L20", "5M", "5L20"],
			bulkup: ["8M", "7M", "6M", "5M"],
			chipaway: ["7L27", "6L27", "5L27"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E"],
			crunch: ["8M", "8L40", "7L38", "6L38", "5L38"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			detect: ["8E", "7E", "6E", "5E"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dragonclaw: ["8M", "7M", "6M", "5M"],
			dragondance: ["8M", "7E", "6E", "5E"],
			dragonpulse: ["8M", "7T", "6T", "5T"],
			dragontail: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			dualchop: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "8L16", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			fakeout: ["8E", "7E", "6E", "5E", "5D"],
			faketears: ["8M"],
			feintattack: ["7L9", "7E", "6L9", "6E", "5L9", "5E"],
			firepunch: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focuspunch: ["8L48", "7T", "7L48", "6T", "6L48", "5L49"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			headbutt: ["8L8", "7L1", "6L12", "5L12", "5S0"],
			headsmash: ["8L52", "7L50", "6L50", "5L53"],
			hiddenpower: ["7M", "6M", "5M"],
			highjumpkick: ["8L44", "7L31", "6L31", "5L31", "5S0"],
			icepunch: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			ironhead: ["8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1", "5S0"],
			lowkick: ["8M", "8L1", "7T", "7L16", "6T", "6L1", "5T", "5L1", "5D", "5S0"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			payback: ["8M", "8L4", "7M", "7L20", "6M", "6L23", "5M", "5L23"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poweruppunch: ["8E", "7E", "6M"],
			protect: ["8M", "8L20", "7M", "6M", "5M"],
			quickguard: ["8E", "7E", "6E"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			roar: ["7M", "6M", "5M"],
			rockclimb: ["7L45", "6L45", "5L45"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L12", "7L5", "6L5", "5L5"],
			scaryface: ["8M", "8L28", "7L34", "6L34", "5L34"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snarl: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superfang: ["7T", "6T", "5T"],
			swagger: ["8L36", "7M", "7L12", "6M", "6L16", "5M", "5L16"],
			taunt: ["8M", "7M", "6M", "5M"],
			thief: ["8M"],
			thunderpunch: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
		},
		eventData: [
			{generation: 5, level: 1, gender: "M", nature: "Adamant", abilities: ["moxie"], moves: ["headbutt", "leer", "highjumpkick", "lowkick"], pokeball: "cherishball"},
		],
	},
	scrafty: {
		learnset: {
			amnesia: ["8M"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M", "8L24"],
			brickbreak: ["8M", "8L32", "7M", "7L23", "6M", "6L20", "5M", "5L20"],
			bulkup: ["8M", "7M", "6M", "5M"],
			chipaway: ["7L27", "6L27", "5L27"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L42", "7L38", "6L38", "5L38"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dragonclaw: ["8M", "7M", "6M", "5M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T", "6T", "5T"],
			dragontail: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T", "5S0"],
			dualchop: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "8L16", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			faketears: ["8M"],
			feintattack: ["7L1", "6L1", "5L1"],
			firepunch: ["8M", "7T", "6T", "5T", "5S0"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focuspunch: ["8L54", "7T", "7L58", "6T", "6L58", "5L58"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			headbutt: ["8L1", "7L1", "6L12", "5L12"],
			headsmash: ["8L60", "7L65", "6L65", "5L65"],
			hiddenpower: ["7M", "6M", "5M"],
			highjumpkick: ["8L48", "7L31", "6L31", "5L31"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			ironhead: ["8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			lowkick: ["8M", "8L1", "7T", "7L16", "6T", "6L1", "5T", "5L1"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			outrage: ["8M", "7T", "6T", "5T"],
			payback: ["8M", "8L1", "7M", "7L20", "6M", "6L23", "5M", "5L23", "5S0"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L20", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			roar: ["7M", "6M", "5M"],
			rockclimb: ["7L51", "6L51", "5L51"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L12", "7L1", "6L1", "5L1"],
			scaryface: ["8M", "8L28", "7L34", "6L34", "5L34"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snarl: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M", "5S0"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superfang: ["7T", "6T", "5T"],
			swagger: ["8L36", "7M", "7L12", "6M", "6L16", "5M", "5L16"],
			taunt: ["8M", "7M", "6M", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 50, gender: "M", nature: "Brave", abilities: ["moxie"], moves: ["firepunch", "payback", "drainpunch", "substitute"], pokeball: "cherishball"},
		],
	},
	sigilyph: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			aircutter: ["8L15", "7L21", "6L21", "5L21"],
			airslash: ["8M", "8L35", "7L41", "6L41", "5L41"],
			ancientpower: ["8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			confusion: ["8L1"],
			cosmicpower: ["8M", "8L30", "7L48", "6L48", "5L48"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M", "7E", "6E"],
			gigaimpact: ["8M"],
			gravity: ["8L5", "7T", "7L38", "6T", "6L38", "5T", "5L38"],
			gust: ["8L1", "7L1", "6L1", "5L1"],
			heatwave: ["8M", "7T", "6T", "5T", "5D"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypnosis: ["8L10", "7L4", "6L4", "5L4", "5D"],
			icebeam: ["8M", "7M", "6M", "5M"],
			icywind: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			lightscreen: ["8M", "8L50", "7M", "7L24", "6M", "6L24", "5M", "5L24"],
			magiccoat: ["7T", "6T", "5T"],
			magicroom: ["8M", "7T", "6T", "5T"],
			miracleeye: ["7L1", "6L1", "5L1"],
			mirrormove: ["7L34", "6L34", "5L34"],
			pluck: ["5M"],
			powerswap: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			psybeam: ["8L20", "7L18", "6L18", "5L18"],
			psychic: ["8M", "8L40", "7M", "7L44", "6M", "6L44", "5M", "5L44"],
			psychocut: ["8M"],
			psychoshift: ["8E", "7E", "6E", "5E"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "7M", "6M", "5M"],
			psywave: ["7L8", "6L8", "5L8"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "8L50", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["8E", "7M", "7E", "6M", "6E", "5T", "5E"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["8M", "8L60", "7T", "7E", "6T", "6E", "5T", "5E"],
			skyattack: ["8L55", "7T", "7L50", "6T", "6L50", "5T", "5L51"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			speedswap: ["8M"],
			steelwing: ["8M", "7M", "7E", "6M", "6E", "5E"],
			storedpower: ["8M", "7E", "6E", "5E", "5D"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			synchronoise: ["7L31", "6L31", "5L31"],
			tailwind: ["8L45", "7T", "7L11", "6T", "6L11", "5T", "5L11"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			whirlwind: ["8L25", "7L14", "6L14", "5L14"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
	},
	yamask: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			allyswitch: ["8M", "7T", "7E", "6E"],
			astonish: ["8L1", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			calmmind: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			craftyshield: ["8L20", "7E"],
			curse: ["8L36", "7L29", "6L29", "5L29"],
			darkpulse: ["8M", "8L44", "7M", "6M", "5T"],
			destinybond: ["8L52", "7L49", "6L49", "5L49"],
			disable: ["8L12", "7L5", "7E", "6L5", "6E", "5L5", "5E"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			endure: ["8M", "7E", "6E", "5E"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			faketears: ["8M", "7E", "6E", "5E"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			grudge: ["8L32", "7L41", "6L41", "5L41"],
			guardsplit: ["8L48", "7L33", "6L33", "5L33"],
			haze: ["8L4", "7L9", "6L9", "5L9"],
			healblock: ["7E", "6E", "5E"],
			hex: ["8M", "8L24", "7L17", "6L17", "5L17"],
			hiddenpower: ["7M", "6M", "5M"],
			imprison: ["8M", "7E", "6E", "5E"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			magiccoat: ["7T", "6T", "5T"],
			meanlook: ["8L28", "7L45", "6L45", "5L45"],
			memento: ["8E", "7E", "6E", "5E"],
			nastyplot: ["8M", "7E", "6E", "5E"],
			nightmare: ["7E", "6E", "5E"],
			nightshade: ["8L8", "7L13", "6L13", "5L13"],
			ominouswind: ["7L25", "6L25", "5L25"],
			painsplit: ["7T", "6T", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			poltergeist: ["8T"],
			powersplit: ["8L48", "7L33", "6L33", "5L33"],
			protect: ["8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			psychic: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "8L40", "7M", "7L37", "6M", "6L37", "5M", "5L37"],
			shockwave: ["7T", "6T"],
			skillswap: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			toxicspikes: ["8M", "7E", "6E"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8L16", "7M", "7L21", "6M", "6L21", "5M", "5L21"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	yamaskgalar: {
		learnset: {
			allyswitch: ["8M"],
			astonish: ["8L1"],
			attract: ["8M"],
			brutalswing: ["8M", "8L16"],
			calmmind: ["8M"],
			craftyshield: ["8L20"],
			curse: ["8L36"],
			darkpulse: ["8M"],
			destinybond: ["8L52"],
			disable: ["8L12"],
			earthpower: ["8M"],
			earthquake: ["8M", "8L44"],
			endure: ["8M"],
			energyball: ["8M"],
			facade: ["8M"],
			faketears: ["8M"],
			guardsplit: ["8L48"],
			haze: ["8L4"],
			hex: ["8M", "8L24"],
			imprison: ["8M"],
			irondefense: ["8M"],
			meanlook: ["8L28"],
			memento: ["8E"],
			nastyplot: ["8M"],
			nightshade: ["8L8"],
			payback: ["8M"],
			poltergeist: ["8T"],
			powersplit: ["8L48"],
			protect: ["8M", "8L1"],
			psychic: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sandstorm: ["8M"],
			shadowball: ["8M", "8L40"],
			skillswap: ["8M"],
			slam: ["8L32"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			substitute: ["8M"],
			thief: ["8M"],
			toxicspikes: ["8M"],
			trick: ["8M"],
			trickroom: ["8M"],
			willowisp: ["8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["8M"],
		},
	},
	cofagrigus: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			bodypress: ["8M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			craftyshield: ["8L20"],
			curse: ["8L38", "7L29", "6L29", "5L29"],
			darkpulse: ["8M", "8L50", "7M", "7S0", "6M", "5T"],
			destinybond: ["8L62", "7L57", "6L57", "5L57"],
			disable: ["8L12", "7L1", "6L1", "5L1"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			faketears: ["8M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			grudge: ["8L32", "7L45", "6L45", "5L45"],
			guardsplit: ["8L56", "7L33", "6L33", "5L33"],
			guardswap: ["8M"],
			haze: ["8L1", "7L1", "6L1", "5L1"],
			hex: ["8M", "8L24", "7L17", "6L17", "5L17"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			imprison: ["8M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			magiccoat: ["7T", "6T", "5T"],
			meanlook: ["8L28", "7L51", "6L51", "5L51"],
			nastyplot: ["8M"],
			nightshade: ["8L1", "7L13", "6L13", "5L13"],
			ominouswind: ["7L25", "6L25", "5L25"],
			painsplit: ["7T", "6T", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			phantomforce: ["8M"],
			poltergeist: ["8T"],
			powersplit: ["8L56", "7L33", "7S0", "6L33", "5L33"],
			powerswap: ["8M"],
			protect: ["8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			psychic: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L1", "7L1", "6L34", "5L34"],
			secretpower: ["6M"],
			shadowball: ["8M", "8L44", "7M", "7L39", "7S0", "6M", "6L39", "5M", "5L39"],
			shadowclaw: ["8M", "8L0"],
			shockwave: ["7T", "6T"],
			skillswap: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			toxicspikes: ["8M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8L16", "7M", "7L21", "7S0", "6M", "6L21", "5M", "5L21"],
			wonderroom: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 7, level: 66, gender: "M", moves: ["willowisp", "shadowball", "powersplit", "darkpulse"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 6, level: 32, maxEggMoves: 1},
		],
	},
	runerigus: {
		learnset: {
			allyswitch: ["8M"],
			amnesia: ["8M"],
			astonish: ["8L1"],
			attract: ["8M"],
			bodypress: ["8M"],
			brutalswing: ["8M", "8L16"],
			bulldoze: ["8M"],
			calmmind: ["8M"],
			craftyshield: ["8L20"],
			curse: ["8L38"],
			darkpulse: ["8M"],
			destinybond: ["8L62"],
			disable: ["8L12"],
			dragonpulse: ["8M"],
			earthpower: ["8M"],
			earthquake: ["8M", "8L50"],
			endure: ["8M"],
			energyball: ["8M"],
			facade: ["8M"],
			faketears: ["8M"],
			gigaimpact: ["8M"],
			grassknot: ["8M"],
			guardsplit: ["8L56"],
			guardswap: ["8M"],
			haze: ["8L1"],
			hex: ["8M", "8L24"],
			hyperbeam: ["8M"],
			imprison: ["8M"],
			irondefense: ["8M"],
			meanlook: ["8L28"],
			nastyplot: ["8M"],
			nightshade: ["8L1"],
			payback: ["8M"],
			phantomforce: ["8M"],
			poltergeist: ["8T"],
			powersplit: ["8L56"],
			powerswap: ["8M"],
			protect: ["8M", "8L1"],
			psychic: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			revenge: ["8M"],
			rockblast: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sandstorm: ["8M"],
			sandtomb: ["8M"],
			scaryface: ["8M", "8L1"],
			shadowball: ["8M", "8L44"],
			shadowclaw: ["8M", "8L0"],
			skillswap: ["8M"],
			slam: ["8L32"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stealthrock: ["8M"],
			stoneedge: ["8M"],
			substitute: ["8M"],
			taunt: ["8M"],
			thief: ["8M"],
			toxicspikes: ["8M"],
			trick: ["8M"],
			trickroom: ["8M"],
			willowisp: ["8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["8M"],
		},
	},
	tirtouga: {
		learnset: {
			ancientpower: ["8L12", "7L18", "6L18", "5L18"],
			aquajet: ["8L6", "7L15", "6L15", "5L15", "5S0"],
			aquatail: ["8L36", "7T", "7L41", "6T", "6L41", "5T", "5L41"],
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L1", "7E", "6L1", "6E", "5L1", "5E"],
			bite: ["8L15", "7L8", "6L8", "5L8", "5S0"],
			blizzard: ["8M", "7M", "6M", "5M"],
			block: ["8E", "7T", "6T", "5T"],
			bodyslam: ["8M", "7E", "6E", "5E", "5S0"],
			brine: ["8M", "8L21", "7L28", "6L28", "5L28"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L27", "7L21", "6L21", "5L21"],
			curse: ["8L30", "7L35", "6L35", "5L35"],
			dig: ["8M", "6M", "5M"],
			dive: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flail: ["7E", "6E", "5E", "5D"],
			frustration: ["7M", "6M", "5M"],
			guardswap: ["8M", "7E", "6E"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["8M", "8L42", "7L50", "6L50", "5L51"],
			icebeam: ["8M", "7M", "6M", "5M"],
			icywind: ["8M", "7T", "6T", "5T"],
			irondefense: ["8M", "8L33", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5E"],
			liquidation: ["8M", "7T", "7E"],
			meteorbeam: ["8T"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			protect: ["8M", "8L3", "7M", "7L11", "6M", "6L11", "5M", "5L11", "5S0"],
			raindance: ["8M", "8L39", "7M", "7L48", "6M", "6L48", "5M", "5L48"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "8L24", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			rocksmash: ["6M", "5M"],
			rockthrow: ["8E", "7E", "6E", "5E"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rollout: ["8E", "7L5", "6L5", "5L5", "5D"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shellsmash: ["8L45", "7L38", "6L38", "5L38"],
			slam: ["8E", "7E", "6E", "5E"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["8L9", "7M", "7L31", "6M", "6L31", "5M", "5L31"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			waterfall: ["8M", "7M", "6M", "5M"],
			watergun: ["8L1", "7L1", "6L1", "5L1"],
			waterpulse: ["8E", "7T", "7E", "6T", "6E", "5E"],
			whirlpool: ["8M", "7E", "6E", "5E"],
			wideguard: ["8L18", "7L25", "6L25", "5L25"],
			withdraw: ["8L1", "7L1", "6L1", "5L1"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", abilities: ["sturdy"], moves: ["bite", "protect", "aquajet", "bodyslam"], pokeball: "cherishball"},
		],
	},
	carracosta: {
		learnset: {
			ancientpower: ["8L12", "7L18", "6L18", "5L18"],
			aquajet: ["8L1", "7L15", "6L15", "5L15"],
			aquatail: ["8L36", "7T", "7L45", "6T", "6L45", "5T", "5L45"],
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L1", "6L1", "5L1"],
			bite: ["8L15", "7L8", "6L8", "5L8"],
			blizzard: ["8M", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			bodyslam: ["8M"],
			brine: ["8M", "8L21", "7L28", "6L28", "5L28"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L27", "7L21", "6L21", "5L21"],
			curse: ["8L30", "7L35", "6L35", "5L35"],
			dig: ["8M", "6M", "5M"],
			dive: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			guardswap: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["8M", "8L46", "7L61", "6L61", "5L61"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			icebeam: ["8M", "7M", "6M", "5M"],
			icywind: ["8M", "7T", "6T", "5T"],
			irondefense: ["8M", "8L33", "7T", "6T", "5T"],
			ironhead: ["8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T"],
			liquidation: ["8M", "7T"],
			lowkick: ["8M", "7T", "6T", "5T"],
			meteorbeam: ["8T"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			protect: ["8M", "8L1", "7M", "7L11", "6M", "6L11", "5M", "5L11"],
			raindance: ["8M", "8L41", "7M", "7L56", "6M", "6L56", "5M", "5L56"],
			razorshell: ["8M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "8L24", "7M", "7L51", "6M", "6L51", "5M", "5L51"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rollout: ["7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shellsmash: ["8L51", "7L40", "6L40", "5L40"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["8L9", "7M", "7L31", "6M", "6L31", "5M", "5L31"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			waterfall: ["8M", "7M", "6M", "5M"],
			watergun: ["8L1", "7L1", "6L1", "5L1"],
			waterpulse: ["7T", "6T"],
			whirlpool: ["8M"],
			wideguard: ["8L18", "7L25", "6L25", "5L25"],
			withdraw: ["8L1", "7L1", "6L1", "5L1"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	archen: {
		learnset: {
			acrobatics: ["8M", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M", "8L33", "7L21", "6L21", "5L21"],
			allyswitch: ["8M", "7T", "7E", "6E"],
			ancientpower: ["8L12", "7L18", "6L18", "5L18"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["8E", "7E", "6E", "5E"],
			bounce: ["8M", "7T", "6T", "5T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L30", "7L35", "6L35", "5L35"],
			cut: ["6M", "5M"],
			defog: ["8E", "7T", "7E", "6E", "5E"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["8E", "7M", "7L8", "6M", "6L8", "5M", "5L8", "5S0"],
			dragonbreath: ["8L9", "7L31", "6L31", "5L31"],
			dragonclaw: ["8M", "8L39", "7M", "7L48", "6M", "6L48", "5M", "5L48"],
			dragonpulse: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endeavor: ["8L45", "7T", "7L38", "6T", "6L38", "5T", "5L38"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			headsmash: ["8E", "7E", "6E", "5E", "5S0"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5E"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			meteorbeam: ["8T"],
			pluck: ["8L15", "7L15", "6L15", "5M", "5L15"],
			protect: ["8M", "7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1"],
			quickguard: ["8L18", "7L25", "6L25", "5L25"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["7M", "6M", "5M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "8L24", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			rocksmash: ["6M", "5M"],
			rockthrow: ["8L3", "7L5", "6L5", "5L5"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L27", "7L11", "6L11", "5L11", "5S0"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "7E", "6M", "6E", "5E"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			switcheroo: ["8E", "7E", "6E"],
			tailwind: ["8L36", "7T", "6T", "5T"],
			taunt: ["8M", "7M", "6M", "5M"],
			thrash: ["8L42", "7L50", "6L50", "5L51"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "8L21", "7M", "7L41", "6M", "6L41", "5M", "5L41"],
			wingattack: ["8L6", "7L1", "6L1", "5L1", "5S0"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 5, level: 15, gender: "M", moves: ["headsmash", "wingattack", "doubleteam", "scaryface"], pokeball: "cherishball"},
		],
	},
	archeops: {
		learnset: {
			acrobatics: ["8M", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M", "8L33", "7L21", "6L21", "5L21"],
			airslash: ["8M"],
			allyswitch: ["8M", "7T"],
			ancientpower: ["8L12", "7L18", "6L18", "5L18"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			bounce: ["8M", "7T", "6T", "5T"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L30", "7L35", "6L35", "5L35"],
			cut: ["6M", "5M"],
			defog: ["7T"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "7L8", "6M", "6L8", "5M", "5L8"],
			dragonbreath: ["8L9", "7L31", "6L31", "5L31"],
			dragonclaw: ["8M", "8L41", "7M", "7L56", "6M", "6L56", "5M", "5L56"],
			dragonpulse: ["8M", "7T", "6T", "5T"],
			dragontail: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endeavor: ["8L51", "7T", "7L40", "6T", "6L40", "5T", "5L40"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			meteorbeam: ["8T"],
			outrage: ["8M", "7T", "6T", "5T"],
			pluck: ["8L15", "7L15", "6L15", "5M", "5L15"],
			protect: ["8M", "7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1"],
			quickguard: ["8L18", "7L25", "6L25", "5L25"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["7M", "6M", "5M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "8L24", "7M", "7L51", "6M", "6L51", "5M", "5L51"],
			rocksmash: ["6M", "5M"],
			rockthrow: ["8L1", "7L1", "6L1", "5L1"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L27", "7L11", "6L11", "5L11"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			skyattack: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			tailwind: ["8L36", "7T", "6T", "5T"],
			taunt: ["8M", "7M", "6M", "5M"],
			thrash: ["8L46", "7L61", "6L61", "5L61"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "8L21", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			wingattack: ["8L1", "7L1", "6L1", "5L1"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	trubbish: {
		learnset: {
			acidspray: ["8L6", "7L12", "6L12", "5L12"],
			amnesia: ["8M", "8L9", "7L40", "6L40", "5L40"],
			attract: ["8M", "7M", "6M", "5M"],
			autotomize: ["8E", "7E"],
			belch: ["8L33", "7L42", "6L42"],
			clearsmog: ["8L12", "7L34", "6L34", "5L34"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			curse: ["8E", "7E", "6E", "5E"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleslap: ["7L14", "6L14", "5L14"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T"],
			endure: ["8M"],
			explosion: ["8L42", "7M", "7L47", "6M", "6L47", "5M", "5L47"],
			facade: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gunkshot: ["8M", "8L39", "7T", "7L45", "6T", "6L45", "5T", "5L45"],
			haze: ["8E", "7E", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			mudsport: ["7E", "6E", "5E"],
			painsplit: ["8L37", "7T", "6T", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			poisongas: ["8L1", "7L1", "6L1", "5L1"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			recycle: ["8L3", "7T", "7L3", "6T", "6L3", "5T", "5L3"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M", "7E", "6E", "5E"],
			rollout: ["8E", "7E", "6E", "5E"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8E", "7E", "6E", "5E"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			selfdestruct: ["8M", "7E", "6E", "5E"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludge: ["8L18", "7L18", "6L18", "5L18"],
			sludgebomb: ["8M", "8L27", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			spikes: ["8M", "7E", "6E", "5E"],
			spite: ["7T", "6T", "5T"],
			stockpile: ["8L21", "7L23", "6L23", "5L23"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swallow: ["8L21", "7L23", "6L23", "5L23"],
			takedown: ["8L24", "7L25", "6L25", "5L25"],
			thief: ["8M", "7M", "6M", "5M"],
			toxic: ["8L30", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			toxicspikes: ["8M", "8L15", "7L7", "6L7", "5L7"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
		},
	},
	garbodor: {
		learnset: {
			acidspray: ["8L1", "7L12", "6L12", "5L12"],
			amnesia: ["8M", "8L9", "7L46", "6L46", "5L46"],
			attract: ["8M", "7M", "6M", "5M"],
			belch: ["8L33", "7L49", "6L49"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L24", "7L25", "6L25", "5L25"],
			clearsmog: ["8L12", "7L34", "6L34", "5L34"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			crosspoison: ["8M"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleslap: ["7L14", "6L14", "5L14"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T"],
			endure: ["8M"],
			explosion: ["8L48", "7M", "7L59", "6M", "6L59", "5M", "5L59"],
			facade: ["8M", "7M", "6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gunkshot: ["8M", "8L43", "7T", "7L54", "6T", "6L54", "5T", "5L54"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			metalclaw: ["8L1"],
			painsplit: ["8L39", "7T", "6T", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			poisongas: ["8L1", "7L1", "6L1", "5L1"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			recycle: ["8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			selfdestruct: ["8M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludge: ["8L18", "7L18", "6L18", "5L18"],
			sludgebomb: ["8M", "8L27", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spikes: ["8M"],
			spite: ["7T", "6T", "5T"],
			stockpile: ["8L21", "7L23", "6L23", "5L23"],
			stompingtantrum: ["8M", "7T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swallow: ["8L21", "7L23", "6L23", "5L23"],
			takedown: ["8L1"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			toxic: ["8L30", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			toxicspikes: ["8M", "8L15", "7L1", "6L1", "5L1"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
		},
		encounters: [
			{generation: 5, level: 31},
			{generation: 6, level: 30},
			{generation: 7, level: 24},
		],
	},
	zorua: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			agility: ["9M", "9L32", "8M", "8L32", "7L37", "6L37", "5L37"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			bounce: ["8M", "7T", "6T", "5T"],
			burningjealousy: ["8T"],
			calmmind: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["7E", "6E", "5E"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			copycat: ["9E", "8E", "7E", "6E"],
			counter: ["9E", "8E", "7E", "6E", "5E"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			darkpulse: ["9M", "8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			detect: ["9E", "8E", "7E", "6E", "5E"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			embargo: ["7M", "7L41", "6M", "6L41", "5M", "5L41"],
			encore: ["9M"],
			endure: ["9M", "8M"],
			extrasensory: ["9E", "8E", "7E", "6E", "5E"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			faketears: ["9M", "9L28", "8M", "8L28", "7L9", "6L9", "5L9"],
			feintattack: ["7L17", "6L17", "5L17"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			foulplay: ["9M", "9L48", "8M", "8L48", "7T", "7L29", "6T", "6L29", "5T", "5L29"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["9L12", "8L12", "7L13", "6L13", "5L13"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["9L8", "8L8", "6M", "5M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			imprison: ["9M", "9L36", "8M", "8L36", "7L53", "6L53", "5L53"],
			incinerate: ["6M", "5M"],
			knockoff: ["9L24", "8L24", "7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			memento: ["9E", "8E", "7E", "6E", "5E"],
			nastyplot: ["9M", "9L44", "8M", "8L44", "7L49", "6L49", "5L49"],
			nightdaze: ["9L40", "8L40", "7L57", "6L57", "5L57"],
			nightshade: ["9M"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			punishment: ["7L45", "6L45", "5L45"],
			pursuit: ["7L5", "6L5", "5L5"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			roar: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L16", "8M", "8L16", "7L21", "6L21", "5L21"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M"],
			shadowclaw: ["9M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "7E", "6T", "6E", "5T", "5E"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			suckerpunch: ["9E", "8E", "7E", "6E", "5E"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M"],
			takedown: ["9M"],
			taunt: ["9M", "9L20", "8M", "8L20", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			torment: ["9L4", "8L4", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			toxic: ["7M", "6M", "5M"],
			trick: ["9M", "8M", "7T", "6T", "5T"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["9M", "8M", "7M", "6M", "5M"],
		},
	},
	zoruahisui: {
		learnset: {
			agility: ["9M", "9L32"],
			bittermalice: ["9L40"],
			calmmind: ["9M"],
			comeuppance: ["9E"],
			confuseray: ["9M"],
			curse: ["9L16"],
			darkpulse: ["9M"],
			detect: ["9E"],
			dig: ["9M"],
			endure: ["9M"],
			extrasensory: ["9E"],
			facade: ["9M"],
			faketears: ["9M"],
			fling: ["9M"],
			foulplay: ["9M", "9L48"],
			gigaimpact: ["9M"],
			hex: ["9M"],
			honeclaws: ["9L8"],
			hyperbeam: ["9M"],
			icywind: ["9M"],
			imprison: ["9M"],
			knockoff: ["9L24"],
			leer: ["9L1"],
			memento: ["9E"],
			nastyplot: ["9M", "9L44"],
			nightshade: ["9M"],
			phantomforce: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scratch: ["9L1"],
			shadowball: ["9M", "9L36"],
			shadowclaw: ["9M"],
			shadowsneak: ["9L12"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			snarl: ["9M"],
			snowscape: ["9M"],
			spite: ["9L28"],
			substitute: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L20"],
			terablast: ["9M"],
			thief: ["9M"],
			torment: ["9L4"],
			trick: ["9M"],
			uturn: ["9M"],
			willowisp: ["9M"],
		},
	},
	zoroark: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			agility: ["9M", "9L34", "8M", "8L34", "7L39", "6L39", "5L39", "5S0"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			bodyslam: ["9M"],
			bounce: ["8M", "7T", "6T", "5T"],
			brickbreak: ["9M"],
			burningjealousy: ["8T"],
			calmmind: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["9M"],
			cut: ["6M", "5M"],
			darkpulse: ["9M", "8M", "7M", "6M", "6S1", "5T"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			embargo: ["7M", "7L44", "6M", "6L44", "5M", "5L44", "5S0"],
			encore: ["8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			faketears: ["9M", "9L28", "8M", "8L28"],
			feintattack: ["7L17", "6L17", "5L17"],
			flamethrower: ["9M", "8M", "7M", "6M", "6S1", "5M"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M"],
			foulplay: ["9M", "9L58", "8M", "8L58", "7T", "7L29", "6T", "6L29", "5T", "5L29"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["9L12", "8L12", "7L13", "6L13", "6S2", "5L13"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["9L1", "8L1", "7L1", "6M", "6L1", "5M", "5L1"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			hypervoice: ["9M", "8M", "7T", "6T", "5T"],
			imprison: ["9M", "9L40", "8M", "8L40", "7L1", "6L1", "5L59"],
			incinerate: ["6M", "5M"],
			knockoff: ["9L24", "8L24", "7T", "6T", "5T"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L52", "8M", "8L52", "7L54", "6L54", "6S2", "5L54"],
			nightdaze: ["9L46", "8L46", "7L1", "6L1", "5L64"],
			nightshade: ["9M"],
			nightslash: ["9L0", "8L0", "7L1", "6L30", "5L30"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychic: ["9M"],
			psychup: ["7M", "6M", "5M"],
			punishment: ["7L49", "6L49", "6S2", "5L49", "5S0"],
			pursuit: ["7L1", "6L1", "5L1"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			roar: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M", "8L16", "7L21", "6L21", "6S2", "5L21"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "6S1"],
			snarl: ["9M", "8M", "7M", "6M", "5M", "5S0"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			suckerpunch: ["6S1"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M"],
			takedown: ["9M"],
			taunt: ["9M", "9L20", "8M", "8L20", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			throatchop: ["8M", "7T"],
			torment: ["9L1", "8L1", "7M", "7L34", "6M", "6L34", "5M", "5L34"],
			toxic: ["7M", "6M", "5M"],
			trick: ["9M", "8M", "7T", "6T", "5T"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
		},
		eventData: [
			{generation: 5, level: 50, gender: "M", nature: "Quirky", moves: ["agility", "embargo", "punishment", "snarl"], pokeball: "cherishball"},
			{generation: 6, level: 50, moves: ["sludgebomb", "darkpulse", "flamethrower", "suckerpunch"], pokeball: "ultraball"},
			{generation: 6, level: 45, gender: "M", nature: "Naughty", moves: ["scaryface", "furyswipes", "nastyplot", "punishment"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 5, level: 25},
		],
	},
	minccino: {
		learnset: {
			afteryou: ["8L28", "7T", "7L49", "6T", "6L49", "5T", "5L49"],
			aquatail: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			babydolleyes: ["8L1", "7L3", "6L3"],
			calmmind: ["8M", "7M", "6M", "5M"],
			captivate: ["7L39", "6L39", "5L39"],
			charm: ["8M", "8L16", "7L27", "6L27", "5L27"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			dig: ["8M", "6M", "5M"],
			doubleslap: ["7L13", "6L13", "5L13"],
			doubleteam: ["7M", "6M", "5M"],
			echoedvoice: ["8L8", "7M", "7L33", "6M", "6L33", "5M", "5L33"],
			encore: ["8M", "8L24", "7L15", "6L15", "5L15"],
			endure: ["8M", "7E", "6E", "5E"],
			facade: ["8M", "7M", "6M", "5M"],
			faketears: ["8M", "7E", "6E", "5E"],
			flail: ["8E", "7E", "6E", "5E"],
			fling: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			growl: ["5L3"],
			gunkshot: ["8M", "7T", "6T", "5T"],
			helpinghand: ["8M", "8L4", "7T", "7L7", "6T", "6L7", "5T", "5L7"],
			hiddenpower: ["7M", "6M", "5M"],
			hypervoice: ["8M", "8L44", "7T", "7L43", "6T", "6L43", "5T", "5L43"],
			irontail: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			lastresort: ["8L48", "7T", "7L45", "6T", "6L45", "5T", "5L45"],
			mudslap: ["7E", "6E", "5E"],
			playrough: ["8M"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			shockwave: ["7T", "6T"],
			sing: ["8L12", "7L21", "6L21", "5L21"],
			slam: ["8L40", "7L37", "6L37", "5L37"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			snore: ["8M", "7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M", "8L20", "7L19", "6L19", "5L19"],
			tailslap: ["8M", "8L32", "7L25", "6L25", "5L25"],
			tailwhip: ["8E", "7E", "6E", "5E"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			tickle: ["8L36", "7L9", "6L9", "5L9"],
			toxic: ["7M", "6M", "5M"],
			tripleaxel: ["8T"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			wakeupslap: ["7L31", "6L31", "5L31"],
			workup: ["8M", "7M", "5M"],
		},
	},
	cinccino: {
		learnset: {
			afteryou: ["8L1", "7T", "6T", "5T"],
			aquatail: ["7T", "6T", "5T"],
			attract: ["8M", "7M", "6M", "5M"],
			babydolleyes: ["8L1"],
			bulletseed: ["8M", "8L1", "7L1", "6L1", "5L1"],
			calmmind: ["8M", "7M", "6M", "5M"],
			charm: ["8M", "8L1"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			echoedvoice: ["8L1", "7M", "6M", "5M"],
			encore: ["8M", "8L1"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			faketears: ["8M"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			gunkshot: ["8M", "7T", "6T", "5T"],
			helpinghand: ["8M", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "8L1", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			lastresort: ["8L1", "7T", "6T", "5T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			playrough: ["8M"],
			pound: ["8L1"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockblast: ["8M", "8L1", "7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			shockwave: ["7T", "6T"],
			sing: ["8L1", "7L1", "6L1", "5L1"],
			slam: ["8L1"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M", "8L1"],
			tailslap: ["8M", "8L1", "7L1", "6L1", "5L1"],
			thief: ["8M", "7M", "6M", "5M"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			tickle: ["8L1", "7L1", "6L1", "5L1"],
			toxic: ["7M", "6M", "5M"],
			tripleaxel: ["8T"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
		},
	},
	gothita: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M"],
			captivate: ["7E", "6E", "5E"],
			chargebeam: ["7M", "6M", "5M"],
			charm: ["9M", "9L16", "8M", "8L16", "7L46", "6L46", "5L46"],
			confide: ["7M", "6M"],
			confusion: ["9L1", "8L1", "7L3", "6L3", "5L3"],
			covet: ["7T", "6T", "5T"],
			darkpulse: ["9M", "8M", "7M", "7E", "6M", "6E", "5E"],
			doubleslap: ["7L14", "6L14", "5L14"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "7L19", "6M", "6L19", "5M", "5L19"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			fakeout: ["9E", "8E"],
			faketears: ["9M", "9L28", "8M", "8L28", "7L10", "6L10", "5L10"],
			feintattack: ["7L24", "6L24", "5L24"],
			flash: ["6M", "5M"],
			flatter: ["9L40", "8L40", "7L28", "6L28", "5L28"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["9L44", "8M", "8L44", "7L31", "6L31", "5L31"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			guardswap: ["8M"],
			healbell: ["7T", "6T", "5T"],
			healblock: ["7L33", "6L33", "5L33"],
			healpulse: ["9E", "8E", "7E", "6E"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hypnosis: ["9L24", "8L24"],
			imprison: ["9M"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magicroom: ["9L48", "8M", "8L48", "7T", "7L48", "6T", "6L48", "5T", "5L48"],
			meanlook: ["9E", "8E", "7E", "6E", "5E"],
			miracleeye: ["7E", "6E", "5E"],
			mirrorcoat: ["9E", "8E", "7E", "6E", "5E"],
			nastyplot: ["9M", "8M"],
			payback: ["8M", "7M", "6M", "5M"],
			playnice: ["9L4", "8L4", "7L8", "6L8"],
			pound: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psybeam: ["9M", "9L12", "8L12", "7L16", "6L16", "5L16"],
			psychic: ["9M", "9L36", "8M", "8L36", "7M", "7L37", "6M", "6L37", "5M", "5L37"],
			psychicterrain: ["9M"],
			psychup: ["9L33", "8L33", "7M", "6M", "5M"],
			psyshock: ["9M", "9L20", "8M", "8L20", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			reflect: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "7L40", "6L40", "5M", "5L40"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			tickle: ["9L8", "8L8", "7L7", "6L7", "5L7"],
			torment: ["9E", "8E", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["9M", "8M", "7T", "6T", "5T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T"],
		},
	},
	gothorita: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M"],
			chargebeam: ["7M", "6M", "5M"],
			charm: ["9M", "9L16", "8M", "8L16", "7L50", "6L50", "5L50"],
			confide: ["7M", "6M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			covet: ["7T", "6T", "5T"],
			darkpulse: ["9M", "8M", "7M", "6M"],
			doubleslap: ["7L14", "6L14", "5L14"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "7L19", "6M", "6L19", "5M", "5L19"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			faketears: ["9M", "9L28", "8M", "8L28", "7L10", "6L10", "5L1"],
			feintattack: ["7L24", "6L24", "5L24"],
			flash: ["6M", "5M"],
			flatter: ["9L46", "8L46", "7L28", "6L28", "5L28", "5S0", "5S1"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["9L52", "8M", "8L52", "7L31", "6L31", "5L31", "5S0", "5S1"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			guardswap: ["8M"],
			healbell: ["7T", "6T", "5T"],
			healblock: ["7L34", "6L34", "5L34"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hypnosis: ["9L24", "8L24"],
			imprison: ["9M", "5S1"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magicroom: ["9L58", "8M", "8L58", "7T", "7L53", "6T", "6L53", "5T", "5L53"],
			metronome: ["9M"],
			mirrorcoat: ["5S0"],
			nastyplot: ["9M", "8M"],
			payback: ["8M", "7M", "6M", "5M"],
			playnice: ["9L1", "8L1", "7L1", "6L1"],
			pound: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psybeam: ["9M", "9L12", "8L12", "7L16", "6L16", "5L16"],
			psychic: ["9M", "8M", "8L40", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			psychicterrain: ["9M"],
			psychup: ["9L35", "8L35", "7M", "6M", "5M"],
			psyshock: ["9M", "9L20", "8M", "8L20", "7M", "7L25", "6M", "6L25", "5M", "5L25", "5S0", "5S1"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			reflect: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "7L43", "6L43", "5M", "5L43"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			tickle: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["9M", "8M", "7T", "6T", "5T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 32, gender: "M", isHidden: true, moves: ["psyshock", "flatter", "futuresight", "mirrorcoat"]},
			{generation: 5, level: 32, gender: "M", isHidden: true, moves: ["psyshock", "flatter", "futuresight", "imprison"]},
		],
		encounters: [
			{generation: 5, level: 31},
		],
	},
	gothitelle: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M", "5M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			calmmind: ["9M", "8M", "7M", "6M", "5M"],
			chargebeam: ["7M", "6M", "5M"],
			charm: ["9M", "9L16", "8M", "8L16", "7L54", "6L54", "5L54"],
			confide: ["7M", "6M"],
			confusion: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			cosmicpower: ["8M"],
			covet: ["7T", "6T", "5T"],
			darkpulse: ["9M", "8M", "7M", "6M"],
			doubleslap: ["7L14", "6L14", "5L14"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "7L19", "6M", "6L19", "5M", "5L19"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			faketears: ["9M", "9L28", "8M", "8L28", "7L10", "6L10", "5L1"],
			feintattack: ["7L24", "6L24", "5L24"],
			flash: ["6M", "5M"],
			flatter: ["9L48", "8L48", "7L28", "6L28", "5L28"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["9L56", "8M", "8L56", "7L31", "6L31", "5L31"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			guardswap: ["8M"],
			healbell: ["7T", "6T", "5T"],
			healblock: ["7L34", "6L34", "5L34"],
			helpinghand: ["9M", "8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			hypnosis: ["9L24", "8L24"],
			imprison: ["9M", "8M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magicroom: ["9L64", "8M", "8L64", "7T", "7L59", "6T", "6L59", "5T", "5L59"],
			metronome: ["9M"],
			nastyplot: ["9M", "8M"],
			payback: ["8M", "7M", "6M", "5M"],
			playnice: ["9L1", "8L1", "7L1", "6L1"],
			pound: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psybeam: ["9M", "9L12", "8L12", "7L16", "6L16", "5L16"],
			psychic: ["9M", "9L40", "8M", "8L40", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			psychicterrain: ["9M"],
			psychup: ["9L35", "8L35", "7M", "6M", "5M"],
			psyshock: ["9M", "9L20", "8M", "8L20", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			reflect: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["9M", "8M", "7T", "6T", "5T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "7L45", "6L45", "5M", "5L45"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thunderbolt: ["9M", "8M", "7M", "6M", "5M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			tickle: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["9M", "8M", "7T", "6T", "5T"],
			trickroom: ["9M", "8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T"],
		},
		encounters: [
			{generation: 5, level: 34},
		],
	},
	solosis: {
		learnset: {
			acidarmor: ["8E", "7E", "6E", "5E"],
			afteryou: ["7T", "6T", "5T"],
			allyswitch: ["8M", "8L28", "7T"],
			astonish: ["8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			charm: ["8M", "8L16", "7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E"],
			confusion: ["8L1"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			encore: ["8M"],
			endeavor: ["8L8", "7T", "7L28", "6T", "6L28", "5T", "5L28"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			explosion: ["7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M", "8L44", "7L31", "6L31", "5L31"],
			gravity: ["7T", "6T", "5T"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			healblock: ["7L46", "6L46", "5L46"],
			helpinghand: ["8M", "7T", "7E", "6T", "6E"],
			hiddenpower: ["7M", "7L14", "6M", "6L14", "5M", "5L14"],
			imprison: ["8M", "7E", "6E", "5E"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T"],
			lightscreen: ["8M", "8L24", "7M", "7L16", "6M", "6L16", "5M", "5L16"],
			magiccoat: ["7T", "6T", "5T"],
			nightshade: ["7E", "6E", "5E"],
			painsplit: ["8L33", "7T", "7L33", "6T", "6L33", "5T", "5L33"],
			powerswap: ["8M"],
			protect: ["8M", "8L1", "7M", "6M", "5M"],
			psybeam: ["8L12"],
			psychic: ["8M", "8L36", "7M", "7L37", "6M", "6L37", "5M", "5L37"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "8L20", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			psywave: ["7L1", "6L1", "5L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			recover: ["8L4", "7L24", "6L24", "5L24"],
			reflect: ["8M", "8L24", "7M", "7L3", "6M", "6L3", "5M", "5L3"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			rollout: ["7L7", "6L7", "5L7"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["7E", "6M", "6E", "5E"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["8M", "8L40", "7T", "7L40", "6T", "6L40", "5T", "5L40"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snatch: ["7T", "7L10", "6T", "6L10", "5T", "5L10"],
			snore: ["8M", "7T", "6T", "5T"],
			steelroller: ["8T"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			trickroom: ["8M", "7M", "6M", "5M"],
			wonderroom: ["8M", "8L48", "7T", "7L48", "6T", "6L48", "5T", "5L48"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
	},
	duosion: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			allyswitch: ["8M", "8L28", "7T"],
			attract: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			charm: ["8M", "8L16", "7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			confusion: ["8L1"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			encore: ["8M"],
			endeavor: ["8L1", "7T", "7L28", "6T", "6L28", "5T", "5L28"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			explosion: ["7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M", "8L52", "7L31", "6L31", "5L31"],
			gravity: ["7T", "6T", "5T"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			healblock: ["7L50", "6L50", "5L50"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "7L14", "6M", "6L14", "5M", "5L14"],
			imprison: ["8M", "5D"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T"],
			lightscreen: ["8M", "8L24", "7M", "7L16", "6M", "6L16", "5M", "5L16"],
			magiccoat: ["7T", "6T", "5T"],
			painsplit: ["8L35", "7T", "7L34", "6T", "6L34", "5T", "5L34"],
			powerswap: ["8M"],
			protect: ["8M", "8L1", "7M", "6M", "5M"],
			psybeam: ["8L12"],
			psychic: ["8M", "8L40", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "8L20", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			psywave: ["7L1", "6L1", "5L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			recover: ["8L1", "7L24", "6L24", "5L24", "5D"],
			reflect: ["8M", "8L24", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			rollout: ["7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["8M", "8L46", "7T", "7L43", "6T", "6L43", "5T", "5L43"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snatch: ["7T", "7L1", "6T", "6L1", "5T", "5L1"],
			snore: ["8M", "7T", "6T", "5T"],
			steelroller: ["8T"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T", "5D"],
			trickroom: ["8M", "7M", "6M", "5M"],
			wonderroom: ["8M", "8L58", "7T", "7L53", "6T", "6L53", "5T", "5L53"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		encounters: [
			{generation: 5, level: 31},
		],
	},
	reuniclus: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			allyswitch: ["8M", "8L28", "7T"],
			attract: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			charm: ["8M", "8L16", "7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			confusion: ["8L1"],
			dizzypunch: ["7L1", "6L41", "5L41"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			encore: ["8M"],
			endeavor: ["8L1", "7T", "7L28", "6T", "6L28", "5T", "5L28"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			explosion: ["7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M", "8L56", "7L31", "6L31", "5L31"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M", "5M"],
			hammerarm: ["8L0"],
			healblock: ["7L54", "6L54", "5L54"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "7L14", "6M", "6L14", "5M", "5L14"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "8L24", "7M", "7L16", "6M", "6L16", "5M", "5L16"],
			magiccoat: ["7T", "6T", "5T"],
			megapunch: ["8M"],
			painsplit: ["8L35", "7T", "7L34", "6T", "6L34", "5T", "5L34"],
			powerswap: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "8L1", "7M", "6M", "5M"],
			psybeam: ["8L12"],
			psychic: ["8M", "8L40", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["8M", "8L20", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			psywave: ["7L1", "6L1", "5L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			recover: ["8L1", "7L24", "6L24", "5L24"],
			reflect: ["8M", "8L24", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			rollout: ["7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			skillswap: ["8M", "8L48", "7T", "7L45", "6T", "6L45", "5T", "5L45"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snatch: ["7T", "7L1", "6T", "6L1", "5T", "5L1"],
			snore: ["8M", "7T", "6T", "5T"],
			steelroller: ["8T"],
			storedpower: ["8M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			wonderroom: ["8M", "8L64", "7T", "7L59", "6T", "6L59", "5T", "5L59"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		encounters: [
			{generation: 5, level: 34},
		],
	},
	ducklett: {
		learnset: {
			aerialace: ["7M", "7L15", "6M", "6L15", "5M", "5L15"],
			aircutter: ["7E", "6E", "5E"],
			airslash: ["7L27", "6L27", "5L27"],
			aquajet: ["7E"],
			aquaring: ["7L24", "6L24", "5L24"],
			attract: ["7M", "6M", "5M"],
			bravebird: ["7L41", "6L41", "5L41"],
			brine: ["7E", "6E", "5E", "5D"],
			bubblebeam: ["7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			defog: ["7T", "7L6", "6L6", "5L6", "5D"],
			dive: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			featherdance: ["7L21", "6L21", "5L21"],
			fly: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gust: ["7E", "6E", "5E"],
			hail: ["7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			hurricane: ["7L46", "6L46", "5L46"],
			icebeam: ["7M", "6M", "5M"],
			icywind: ["7T", "6T", "5T"],
			liquidation: ["7T"],
			luckychant: ["7E", "6E", "5E"],
			mefirst: ["7E", "6E", "5E", "5D"],
			mirrormove: ["7E", "6E", "5E"],
			mudsport: ["7E", "6E"],
			pluck: ["5M"],
			protect: ["7M", "6M", "5M"],
			raindance: ["7M", "7L34", "6M", "6L34", "5M", "5L34"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["7M", "7L30", "6M", "6L30", "5T", "5L30"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			steelwing: ["7M", "7E", "6M", "6E", "5E"],
			substitute: ["7M", "6M", "5M"],
			surf: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tailwind: ["7T", "7L37", "6T", "6L37", "5T", "5L37"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			watergun: ["7L1", "6L1", "5L1"],
			waterpulse: ["7T", "7L13", "6T", "6L13", "5L13"],
			watersport: ["7L3", "6L3", "5L3"],
			wingattack: ["7L9", "6L9", "5L9"],
		},
	},
	swanna: {
		learnset: {
			aerialace: ["7M", "7L15", "6M", "6L15", "5M", "5L15"],
			airslash: ["7L27", "6L27", "5L27"],
			aquaring: ["7L24", "6L24", "5L24"],
			attract: ["7M", "6M", "5M"],
			bravebird: ["7L47", "6L47", "5L47"],
			bubblebeam: ["7L19", "6L19", "5L19"],
			confide: ["7M", "6M"],
			defog: ["7T", "7L1", "6L1", "5L1"],
			dive: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			facade: ["7M", "6M", "5M"],
			featherdance: ["7L21", "6L21", "5L21"],
			fly: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["7M", "6M", "5M"],
			hail: ["7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			hurricane: ["7L55", "6L55", "5L55"],
			hyperbeam: ["7M", "6M", "5M"],
			icebeam: ["7M", "6M", "5M"],
			icywind: ["7T", "6T", "5T"],
			liquidation: ["7T"],
			pluck: ["5M"],
			protect: ["7M", "6M", "5M"],
			raindance: ["7M", "7L34", "6M", "6L34", "5M", "5L34"],
			rest: ["7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["7M", "7L30", "6M", "6L30", "5T", "5L30"],
			round: ["7M", "6M", "5M"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			skyattack: ["7T", "6T", "5T"],
			sleeptalk: ["7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			steelwing: ["7M", "6M"],
			substitute: ["7M", "6M", "5M"],
			surf: ["7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tailwind: ["7T", "7L40", "6T", "6L40", "5T", "5L40"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			watergun: ["7L1", "6L1", "5L1"],
			waterpulse: ["7T", "7L13", "6T", "6L13", "5L13"],
			watersport: ["7L1", "6L1", "5L1"],
			wingattack: ["7L1", "6L1", "5L1"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	vanillite: {
		learnset: {
			acidarmor: ["8L32", "7L31", "6L31", "5L31"],
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L7", "6L7", "5L7"],
			attract: ["8M", "7M", "6M", "5M"],
			auroraveil: ["8E"],
			autotomize: ["8E", "7E", "6E", "5E"],
			avalanche: ["8M", "8L16", "7L19", "6L19", "5L19"],
			blizzard: ["8M", "8L44", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			confide: ["7M", "6M"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			explosion: ["8E", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			hail: ["8M", "8L20", "7M", "7L40", "6M", "6L40", "5M", "5L40"],
			harden: ["8L1", "7L4", "6L4", "5L4"],
			hiddenpower: ["7M", "6M", "5M"],
			hypervoice: ["8M"],
			icebeam: ["8M", "8L40", "7M", "7L35", "6M", "6L35", "5M", "5L35"],
			iceshard: ["8E", "7E", "6E", "5E"],
			iciclecrash: ["8E"],
			iciclespear: ["8M", "8L24", "7L1", "6L1", "5L1"],
			icywind: ["8M", "8L12", "7T", "7L13", "6T", "6L13", "5T", "5L13"],
			imprison: ["8M", "7E", "6E", "5E"],
			irondefense: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magnetrise: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			mirrorcoat: ["8L36", "7L44", "6L44", "5L44"],
			mirrorshot: ["7L26", "6L26", "5L26"],
			mist: ["8L8", "7L16", "6L16", "5L16"],
			naturalgift: ["7E", "6E", "5E"],
			powdersnow: ["7E", "6E", "5E"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			sheercold: ["8L48", "7L53", "6L53", "5L53"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "8L4", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "8L28", "7T", "7L10", "6T", "6L10", "5T", "5L10"],
			waterpulse: ["7T", "7E", "6T", "6E", "5E"],
		},
	},
	vanillish: {
		learnset: {
			acidarmor: ["8L32", "7L31", "6L31", "5L31"],
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			avalanche: ["8M", "8L16", "7L19", "6L19", "5L19"],
			blizzard: ["8M", "8L50", "7M", "7L53", "6M", "6L53", "5M", "5L53"],
			confide: ["7M", "6M"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			explosion: ["7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			hail: ["8M", "8L20", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			harden: ["8L1", "7L1", "6L1", "5L1"],
			hiddenpower: ["7M", "6M", "5M"],
			hypervoice: ["8M"],
			icebeam: ["8M", "8L44", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			iceshard: ["5D"],
			iciclespear: ["8M", "8L24", "7L1", "6L1", "5L1"],
			icywind: ["8M", "8L12", "7T", "7L13", "6T", "6L13", "5T", "5L13"],
			imprison: ["8M", "5D"],
			irondefense: ["8M", "7T", "6T", "5T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magnetrise: ["7T", "6T", "5T"],
			mirrorcoat: ["8L38", "7L47", "6L47", "5L47"],
			mirrorshot: ["7L26", "6L26", "5L26", "5D"],
			mist: ["8L1", "7L16", "6L16", "5L16"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			sheercold: ["8L56", "7L58", "6L58", "5L58"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "8L1", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "8L28", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			waterpulse: ["7T", "6T"],
		},
	},
	vanilluxe: {
		learnset: {
			acidarmor: ["8L32", "7L31", "6L31", "5L31"],
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			avalanche: ["8M", "8L16", "7L19", "6L19", "5L19"],
			beatup: ["8M"],
			blizzard: ["8M", "8L52", "7M", "7L59", "6M", "6L59", "5M", "5L59"],
			confide: ["7M", "6M"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			explosion: ["7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			freezedry: ["8L1", "7L1", "6L1"],
			frostbreath: ["7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			hail: ["8M", "8L20", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			harden: ["8L1", "7L1", "6L1", "5L1"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M"],
			icebeam: ["8M", "8L44", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			iciclecrash: ["8L1"],
			iciclespear: ["8M", "8L24", "7L1", "6L1", "5L1"],
			icywind: ["8M", "8L12", "7T", "7L13", "6T", "6L13", "5T", "5L13"],
			imprison: ["8M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magnetrise: ["7T", "6T", "5T"],
			mirrorcoat: ["8L38", "7L50", "6L50", "5L50"],
			mirrorshot: ["7L26", "6L26", "5L26"],
			mist: ["8L1", "7L16", "6L16", "5L16"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			sheercold: ["8L60", "7L1", "6L1", "5L67"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "8L1", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "8L28", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			waterpulse: ["7T", "6T"],
			weatherball: ["8M", "8L1", "7L1", "6L1", "5L1"],
		},
	},
	deerling: {
		learnset: {
			agility: ["9M", "9E", "7E", "6E", "5E"],
			aromatherapy: ["7L28", "6L28", "5L28", "5S0"],
			attract: ["7M", "6M", "5M"],
			batonpass: ["9M", "9E", "7E", "6E", "5E"],
			bodyslam: ["9M"],
			bounce: ["7T", "6T", "5T"],
			bulldoze: ["9M"],
			bulletseed: ["9M", "9L16"],
			camouflage: ["7L1", "6L1", "5L1"],
			charm: ["9M", "9L32", "7L36", "6L36", "5L36"],
			confide: ["7M", "6M"],
			dig: ["9M"],
			doubleedge: ["9L37", "7L46", "6L46", "5L46"],
			doublekick: ["9L10", "7L10", "6L10", "5L10"],
			doubleteam: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M"],
			energyball: ["9M", "9L28", "7M", "7L32", "6M", "6L32", "5M", "5L32"],
			facade: ["9M", "7M", "6M", "5M"],
			faketears: ["9M", "9E", "7E", "6E", "5E"],
			feintattack: ["7L16", "6L16", "5L16", "5S0"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["9M", "7T", "6T", "5T"],
			grassknot: ["9M", "7M", "6M", "5M"],
			grasswhistle: ["7E", "6E", "5E"],
			grassyterrain: ["9M"],
			growl: ["9L4", "7L4", "6L4", "5L4"],
			headbutt: ["9E", "7E"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			jumpkick: ["7L24", "6L24", "5L24", "5S0"],
			lastresort: ["7T", "6T", "5T"],
			leafstorm: ["9M"],
			leechseed: ["9L13", "7L13", "6L13", "5L13"],
			lightscreen: ["9M", "7M", "6M", "5M"],
			magicalleaf: ["9M"],
			naturalgift: ["7E", "6E", "5E"],
			naturepower: ["7M", "7L41", "6M", "6L41", "5L41"],
			odorsleuth: ["7E", "6E", "5E"],
			playrough: ["9M"],
			protect: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M"],
			rest: ["9M", "7M", "6M", "5M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			sandattack: ["9L7", "7L7", "6L7", "5L7"],
			secretpower: ["6M"],
			seedbomb: ["9M", "7T", "6T", "5T"],
			shadowball: ["9M", "7M", "6M", "5M"],
			sleeptalk: ["9M", "9E", "7M", "7E", "6M", "6E", "5T", "5E"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["9M", "9L42", "7M", "7L51", "6M", "6L51", "5M", "5L51"],
			substitute: ["9M", "7M", "6M", "5M"],
			sunnyday: ["9M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			synthesis: ["9E", "7T", "7E", "6T", "6E", "5T", "5E"],
			tackle: ["9L1", "7L1", "6L1", "5L1"],
			takedown: ["9M", "9L20", "7L20", "6L20", "5L20", "5S0"],
			terablast: ["9M"],
			thunderwave: ["9M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			workup: ["7M", "5M"],
			worryseed: ["9E", "7T", "7E", "6T", "6E", "5T", "5E"],
			zenheadbutt: ["9M", "9L24"],
		},
		eventData: [
			{generation: 5, level: 30, gender: "F", isHidden: true, moves: ["feintattack", "takedown", "jumpkick", "aromatherapy"]},
		],
	},
	sawsbuck: {
		learnset: {
			agility: ["9M"],
			aromatherapy: ["7L28", "6L28", "5L28"],
			attract: ["7M", "6M", "5M"],
			batonpass: ["9M"],
			bodyslam: ["9M"],
			bounce: ["7T", "6T", "5T"],
			bulldoze: ["9M"],
			bulletseed: ["9M", "9L16"],
			camouflage: ["7L1", "6L1", "5L1"],
			charm: ["9M", "9L36", "7L36", "6L36", "5L36"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			dig: ["9M"],
			doubleedge: ["9L44", "7L52", "6L52", "5L52"],
			doublekick: ["9L10", "7L10", "6L10", "5L10"],
			doubleteam: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M"],
			energyball: ["9M", "9L28", "7M", "7L32", "6M", "6L32", "5M", "5L32"],
			facade: ["9M", "7M", "6M", "5M"],
			faketears: ["9M"],
			feintattack: ["7L16", "6L16", "5L16"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["9M", "7T", "6T", "5T"],
			gigaimpact: ["9M", "7M", "6M", "5M"],
			grassknot: ["9M", "7M", "6M", "5M"],
			grassyterrain: ["9M"],
			growl: ["9L1", "7L1", "6L1", "5L1"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			hornleech: ["9L0", "7L1", "6L37", "5L37"],
			hyperbeam: ["9M", "7M", "6M", "5M"],
			jumpkick: ["7L24", "6L24", "5L24"],
			lastresort: ["7T", "6T", "5T"],
			leafstorm: ["9M"],
			leechseed: ["9L13", "7L13", "6L13", "5L13"],
			lightscreen: ["9M", "7M", "6M", "5M"],
			magicalleaf: ["9M"],
			megahorn: ["9L1", "7L1", "6L1", "5L1"],
			naturepower: ["7M", "7L44", "6M", "6L44", "5L44"],
			playrough: ["9M"],
			protect: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M"],
			rest: ["9M", "7M", "6M", "5M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["7M", "6M", "5M"],
			sandattack: ["9L1", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			seedbomb: ["9M", "7T", "6T", "5T"],
			shadowball: ["9M", "7M", "6M", "5M"],
			sleeptalk: ["9M", "7M", "6M", "5T"],
			smartstrike: ["9M"],
			snore: ["7T", "6T", "5T"],
			solarbeam: ["9M", "9L52", "7M", "7L60", "6M", "6L60", "5M", "5L60"],
			stompingtantrum: ["9M", "7T"],
			substitute: ["9M", "7M", "6M", "5M"],
			sunnyday: ["9M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["9M", "7M", "6M", "5M"],
			synthesis: ["7T", "6T", "5T"],
			tackle: ["9L1", "7L1", "6L1", "5L1"],
			takedown: ["9M", "9L20", "7L20", "6L20", "5L20"],
			terablast: ["9M"],
			thunderwave: ["9M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			wildcharge: ["9M", "7M", "6M", "5M"],
			workup: ["7M", "5M"],
			worryseed: ["7T", "6T", "5T"],
			zenheadbutt: ["9M", "9L24"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	emolga: {
		learnset: {
			acrobatics: ["8M", "8L25", "7M", "7L30", "6M", "6L30", "5M", "5L30"],
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M", "8L55", "7L46", "6L46", "5L46"],
			airslash: ["8M", "7E", "6E", "5E", "5D"],
			astonish: ["8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			batonpass: ["8M", "7E", "6E", "5E"],
			charge: ["8L20", "7L10", "6L10", "5L10"],
			chargebeam: ["7M", "6M", "5M"],
			charm: ["8M", "7E", "6E", "5E", "5D"],
			confide: ["7M", "6M"],
			covet: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			cut: ["6M", "5M"],
			defog: ["8E", "7T"],
			discharge: ["8L50", "7L50", "6L50", "5L50"],
			doubleteam: ["8L5", "7M", "7L19", "6M", "6L19", "5M", "5L19"],
			dualwingbeat: ["8T"],
			eerieimpulse: ["8M"],
			electroball: ["8M", "7L26", "6L26", "5L26"],
			electroweb: ["8M", "7T", "6T"],
			encore: ["8M", "8L35", "7L38", "6L38", "5L38"],
			endure: ["8M"],
			energyball: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			iondeluge: ["7E", "6E"],
			irontail: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			knockoff: ["7T", "6T", "5T"],
			lastresort: ["7T", "6T", "5T"],
			lightscreen: ["8M", "8L45", "7M", "7L34", "6M", "6L34", "5M", "5L34"],
			nuzzle: ["8L1", "7L15", "6L15"],
			protect: ["8M", "7M", "6M", "5M"],
			pursuit: ["7L16", "6L16", "5L16"],
			quickattack: ["8L10", "7L4", "6L4", "5L4", "5D"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			risingvoltage: ["8T"],
			roost: ["8E", "7M", "7E", "6M", "6E", "5T", "5E"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shockwave: ["8E", "7T", "7L22", "7E", "6T", "6L22", "6E", "5L22", "5E"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M"],
			spark: ["8L30", "7L13", "6L13", "5L13"],
			speedswap: ["8M", "7E"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			tailwhip: ["8L1", "7L7", "6L7", "5L7"],
			tailwind: ["7T", "6T", "5T"],
			taunt: ["8M", "7M", "6M", "5M"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thundershock: ["8L15", "7L1", "6L1", "5L1"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			tickle: ["8E", "7E", "6E", "5E"],
			toxic: ["7M", "6M", "5M"],
			uturn: ["8M", "7M", "6M", "5M"],
			voltswitch: ["8M", "8L40", "7M", "7L42", "6M", "6L42", "5M", "5L42"],
			wildcharge: ["8M", "7M", "6M", "5M"],
		},
	},
	karrablast: {
		learnset: {
			acidspray: ["8L16"],
			aerialace: ["7M", "6M", "5M"],
			attract: ["8M", "7M", "6M", "5M"],
			bugbite: ["8E", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			bugbuzz: ["8M", "8L44", "7L28", "6L28", "5L28", "5S0"],
			confide: ["7M", "6M"],
			counter: ["8E", "7E", "6E", "5E"],
			cut: ["6M", "5M"],
			doubleedge: ["8L48", "7L56", "6L56", "5L56"],
			doubleteam: ["7M", "6M", "5M"],
			drillrun: ["8M", "7T", "7E", "6T", "6E"],
			encore: ["8M"],
			endure: ["8M", "8L8", "7L8", "6L8", "5L8", "5D"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			falseswipe: ["8M", "8L12", "7M", "7L25", "6M", "6L25", "5M", "5L25", "5S0"],
			feintattack: ["7E", "6E", "5E"],
			flail: ["8L24", "7L49", "6L49", "5L49", "5S1"],
			frustration: ["7M", "6M", "5M"],
			furyattack: ["7L16", "6L16", "5L16", "5S0"],
			furycutter: ["8L4", "7L13", "6L13", "5L13"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			headbutt: ["8L20", "7L20", "6L20", "5L20", "5S0"],
			hiddenpower: ["7M", "6M", "5M"],
			hornattack: ["7E", "6E", "5E"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			leer: ["8L1", "7L4", "6L4", "5L4"],
			megahorn: ["8M", "7E", "6E", "5E", "5D", "5S1"],
			nightslash: ["8E"],
			peck: ["8L1", "7L1", "6L1", "5L1"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			pursuit: ["7E", "6E", "5E"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L28", "7L40", "6L40", "5L40"],
			screech: ["8M", "7E", "6E", "5E"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			slash: ["8E", "7L32", "6L32", "5L32"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "8L36", "7M", "7L52", "6M", "6L52", "5M", "5L52"],
			takedown: ["8L40", "7L37", "6L37", "5L37", "5S1"],
			toxic: ["7M", "6M", "5M"],
			xscissor: ["8M", "8L32", "7M", "7L44", "6M", "6L44", "5M", "5L44", "5S1"],
		},
		eventData: [
			{generation: 5, level: 30, moves: ["furyattack", "headbutt", "falseswipe", "bugbuzz"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["megahorn", "takedown", "xscissor", "flail"], pokeball: "cherishball"},
		],
	},
	escavalier: {
		learnset: {
			acidspray: ["8L16"],
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bugbite: ["7T", "6T", "5T"],
			bugbuzz: ["8M", "8L44", "7L28", "6L28", "5L28"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doubleedge: ["8L1", "7L1", "6L1"],
			doubleteam: ["7M", "6M", "5M"],
			drillrun: ["8M", "7T", "6T"],
			encore: ["8M"],
			endure: ["8M", "8L1"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			falseswipe: ["8M", "8L12", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			fellstinger: ["8L1", "7L1", "6L1"],
			flail: ["8L1"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furyattack: ["7L16", "6L16", "5L16"],
			furycutter: ["8L1"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gigaimpact: ["8M", "8L48", "7M", "7L56", "6M", "6L56", "5M", "5L56"],
			headbutt: ["8L20", "7L20", "6L20", "5L20"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "8L28", "7T", "7L40", "6T", "6L40", "5T", "5L40"],
			ironhead: ["8M", "8L40", "7T", "7L37", "6T", "6L37", "5T", "5L37"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			megahorn: ["8M"],
			metalburst: ["8L52"],
			peck: ["8L1", "7L1", "6L1", "5L1"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			quickguard: ["8L1", "7L1", "6L1", "5L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			razorshell: ["8M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M", "8L24", "7L49", "6L49", "5L49"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			slash: ["7L32", "6L32", "5L32"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "8L36", "7M", "7L52", "6M", "6L52", "5M", "5L52"],
			takedown: ["8L1"],
			taunt: ["8M"],
			toxic: ["7M", "6M", "5M"],
			twineedle: ["7L1", "6L1", "5L1"],
			xscissor: ["8M", "8L32", "7M", "7L44", "6M", "6L44", "5M", "5L44"],
		},
	},
	foongus: {
		learnset: {
			absorb: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			afteryou: ["7T", "6T", "5T"],
			astonish: ["9L1", "8L1", "7L8", "6L8", "5L8"],
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L12", "6L12", "5L12"],
			bodyslam: ["9M", "8M", "7E", "6E", "5E"],
			bulletseed: ["9M"],
			clearsmog: ["9L20", "8L20", "7L39", "6L39", "5L39"],
			confide: ["7M", "6M"],
			defensecurl: ["9E", "8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["9M", "8M", "7E", "6E", "5E"],
			energyball: ["9M", "8M", "7M", "6M", "5M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			feintattack: ["7L20", "6L20", "5L20"],
			flash: ["6M", "5M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["7T", "7E", "6T", "6E", "5T", "5E"],
			gigadrain: ["9M", "9L28", "8M", "8L28", "7T", "7L28", "6T", "6L28", "5T", "5L28"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			grassyterrain: ["9M"],
			growth: ["9L4", "8L4", "7L6", "7E", "6L6", "6E", "5L6", "5E"],
			hiddenpower: ["7M", "6M", "5M"],
			ingrain: ["9L32", "8L32", "7L18", "6L18", "5L18"],
			leafstorm: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L12", "8L12", "7L15", "6L15", "5L15"],
			naturepower: ["7M", "6M"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonpowder: ["9E", "8E", "7E", "6E", "5E"],
			pollenpuff: ["9M", "8M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			ragepowder: ["9L40", "8L40", "7L45", "6L45", "5L45"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rollout: ["9E", "8E", "7E", "6E", "5E"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["9M", "9L44", "8M", "8L44", "7M", "7L43", "6M", "6L43", "5M", "5L43"],
			spore: ["9L48", "8L48", "7L50", "6L50", "5L50"],
			stunspore: ["9L8", "8L8", "7E", "6E", "5E"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			sweetscent: ["9L24", "8L24", "7L24", "6L24", "5L24"],
			synthesis: ["9L16", "8L16", "7T", "7L35", "6T", "6L35", "5T", "5L35"],
			terablast: ["9M"],
			toxic: ["9L36", "8L36", "7M", "7L32", "6M", "6L32", "5M", "5L32"],
			venoshock: ["9M", "8M", "7M", "6M", "5M"],
			worryseed: ["9E", "8E", "7T", "6T", "5T"],
		},
	},
	amoonguss: {
		learnset: {
			absorb: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			afteryou: ["7T", "6T", "5T"],
			astonish: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L1", "6L1", "5L1"],
			bodyslam: ["9M", "8M"],
			bulletseed: ["9M"],
			clearsmog: ["9L20", "8L20", "8S0", "7L43", "6L43", "5L43"],
			confide: ["7M", "6M"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M", "6M", "5M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			feintattack: ["7L20", "6L20", "5L20"],
			flash: ["6M", "5M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["7T", "6T", "5T"],
			gigadrain: ["9M", "9L28", "8M", "8L28", "7T", "7L28", "6T", "6L28", "5T", "5L28"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			grassyterrain: ["9M", "8M"],
			growth: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			ingrain: ["9L32", "8L32", "7L18", "6L18", "5L18"],
			leafstorm: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L12", "8L12", "7L15", "6L15", "5L15"],
			naturepower: ["7M", "6M"],
			payback: ["8M", "7M", "6M", "5M"],
			pollenpuff: ["9M", "8M"],
			protect: ["9M", "8M", "8S0", "7M", "6M", "5M"],
			ragepowder: ["9L42", "8L42", "8S0", "7L54", "6L54", "5L54"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "8M", "7T", "6T", "5T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["9M", "9L48", "8M", "8L48", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			spore: ["9L54", "8L54", "8S0", "7L62", "6L62", "5L62"],
			stompingtantrum: ["9M", "8M", "7T"],
			stunspore: ["9L1", "8L1"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			sweetscent: ["9L24", "8L24", "7L24", "6L24", "5L24"],
			synthesis: ["9L16", "8L16", "7T", "7L35", "6T", "6L35", "5T", "5L35"],
			terablast: ["9M"],
			toxic: ["9L36", "8L36", "7M", "7L32", "6M", "6L32", "5M", "5L32"],
			venoshock: ["9M", "8M", "7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T"],
		},
		eventData: [
			{generation: 8, level: 50, shiny: true, gender: "F", nature: "Sassy", ivs: {hp: 31, atk: 0, def: 31, spa: 31, spd: 31, spe: 0}, isHidden: true, moves: ["clearsmog", "spore", "protect", "ragepowder"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 5, level: 37},
			{generation: 5, level: 35, isHidden: true},
		],
	},
	frillish: {
		learnset: {
			absorb: ["8L1", "7L5", "6L5", "5L5"],
			acidarmor: ["8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["8M", "7M", "6M", "5M"],
			brine: ["8M", "8L24", "7L32", "6L32", "5L32"],
			bubble: ["7L1", "6L1", "5L1"],
			bubblebeam: ["8E", "7L13", "6L13", "5L13"],
			confide: ["7M", "6M"],
			confuseray: ["8E", "7E", "6E", "5E"],
			constrict: ["7E", "6E", "5E"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			destinybond: ["8L44"],
			dive: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			hail: ["8M", "7M", "6M", "5M"],
			hex: ["8M", "8L20", "7L43", "6L43", "5L43"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["8M", "8L41", "7L49", "6L49", "5L49"],
			icebeam: ["8M", "7M", "6M", "5M"],
			icywind: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			magiccoat: ["7T", "6T", "5T"],
			mist: ["8E", "7E", "6E", "5E"],
			nightshade: ["8L8", "7L9", "6L9", "5L9"],
			ominouswind: ["7L27", "6L27", "5L27"],
			painsplit: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			poisonsting: ["8L4"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			raindance: ["8M", "8L16", "7M", "7L37", "6M", "6L37", "5M", "5L37"],
			recover: ["8L28", "7L17", "7E", "6L17", "6E", "5L17", "5E"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "8L32", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			strengthsap: ["8E"],
			substitute: ["8M", "7M", "6M", "5M"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			waterfall: ["8M", "7M", "6M", "5M"],
			watergun: ["8L1"],
			waterpulse: ["8L12", "7T", "7L22", "6T", "6L22", "5L22"],
			watersport: ["7L1", "6L1", "5L1"],
			waterspout: ["8L48", "7L61", "6L61", "5L61"],
			whirlpool: ["8M", "8L36"],
			willowisp: ["8M", "7M", "6M", "5M"],
			wringout: ["7L55", "6L55", "5L55"],
		},
	},
	jellicent: {
		learnset: {
			absorb: ["8L1", "7L1", "6L1", "5L1"],
			acidarmor: ["8L1"],
			attract: ["8M", "7M", "6M", "5M"],
			bind: ["7T", "6T", "5T"],
			blizzard: ["8M", "7M", "6M", "5M"],
			brine: ["8M", "8L24", "7L32", "6L32", "5L32", "5S0"],
			bubble: ["7L1", "6L1", "5L1"],
			bubblebeam: ["7L13", "6L13", "5L13"],
			confide: ["7M", "6M"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			destinybond: ["8L48"],
			dive: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			hail: ["8M", "7M", "6M", "5M"],
			hex: ["8M", "8L20", "7L45", "6L45", "5L45"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["8M", "8L43", "7L53", "6L53", "5L53"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			icebeam: ["8M", "7M", "6M", "5M"],
			icywind: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			magiccoat: ["7T", "6T", "5T"],
			muddywater: ["8M"],
			nightshade: ["8L1", "7L1", "6L1", "5L1"],
			ominouswind: ["7L27", "6L27", "5L27", "5S0"],
			painsplit: ["7T", "6T", "5T"],
			poisonsting: ["8L1"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			raindance: ["8M", "8L16", "7M", "7L37", "6M", "6L37", "5M", "5L37", "5S0"],
			recover: ["8L28", "7L17", "6L17", "5L17"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "8L32", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			waterfall: ["8M", "7M", "6M", "5M"],
			watergun: ["8L1"],
			waterpulse: ["8L12", "7T", "7L22", "6T", "6L22", "5L22", "5S0"],
			watersport: ["7L1", "6L1", "5L1"],
			waterspout: ["8L54", "7L1", "6L1", "5L69"],
			whirlpool: ["8M", "8L36"],
			willowisp: ["8M", "7M", "6M", "5M"],
			wringout: ["7L1", "6L1", "5L61"],
		},
		eventData: [
			{generation: 5, level: 40, isHidden: true, moves: ["waterpulse", "ominouswind", "brine", "raindance"]},
		],
		encounters: [
			{generation: 5, level: 5},
		],
	},
	alomomola: {
		learnset: {
			acrobatics: ["9M"],
			aquajet: ["9L9", "7L9", "6L9", "5L9"],
			aquaring: ["9L5", "7L5", "6L5", "5L5", "5D"],
			attract: ["7M", "6M", "5M"],
			batonpass: ["9M"],
			blizzard: ["9M", "7M", "6M", "5M"],
			bodyslam: ["9M"],
			bounce: ["9E", "7T", "6T", "5T"],
			brine: ["9L41", "7L41", "6L41", "5L41"],
			calmmind: ["9M", "7M", "6M", "5M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			dive: ["6M", "5M"],
			doubleslap: ["7L13", "6L13", "5L13"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["9M", "9E", "7E", "6E", "5E"],
			facade: ["9M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M"],
			hail: ["7M", "6M", "5M"],
			healingwish: ["9L29", "7L1", "6L1", "5L57"],
			healpulse: ["7L17", "6L17", "5L17"],
			helpinghand: ["9M", "9L13", "7T", "7L1", "6T", "6L49", "5T", "5L49"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["9M", "9L55", "7L1", "6L1", "5L61"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "7M", "6M", "5M"],
			icywind: ["9M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lightscreen: ["9M", "7M", "6M", "5M"],
			liquidation: ["9M", "7T"],
			magiccoat: ["7T", "6T", "5T"],
			mirrorcoat: ["9E", "7E", "6E", "5E", "5D"],
			mist: ["9E", "7E", "6E", "5E"],
			mistyterrain: ["9M"],
			painsplit: ["9E", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			playnice: ["9L1", "7L1"],
			playrough: ["9M"],
			pound: ["9L1", "7L1", "6L1", "5L1"],
			protect: ["9M", "9L21", "7M", "7L21", "6M", "6L21", "5M", "5L21"],
			psychic: ["9M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M"],
			refresh: ["7E", "6E", "5E"],
			rest: ["9M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			safeguard: ["9L45", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			scald: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["9M", "7M", "6M", "5M"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			snowscape: ["9M"],
			soak: ["9L33", "7L33", "6L33", "5L33"],
			substitute: ["9M", "7M", "6M", "5M"],
			surf: ["9M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			takedown: ["9M"],
			terablast: ["9M"],
			tickle: ["9E", "7E", "6E", "5E"],
			toxic: ["7M", "6M", "5M"],
			wakeupslap: ["7L29", "6L29", "5L29"],
			waterfall: ["9M", "7M", "6M", "5M"],
			waterpulse: ["9M", "9L25", "7T", "7L25", "6T", "6L25", "5L25"],
			watersport: ["7L1", "6L1", "5L1"],
			whirlpool: ["9L49", "7L49"],
			wideguard: ["9L13", "7L1", "6L1", "5L53"],
			wish: ["9L37", "7L37", "6L37", "5L37"],
			zenheadbutt: ["9M"],
		},
	},
	joltik: {
		learnset: {
			absorb: ["8L1", "7L1"],
			agility: ["8M", "8L24", "7L37", "6L37", "5L37"],
			attract: ["8M", "7M", "6M", "5M"],
			bounce: ["8M", "7T", "6T", "5T"],
			bugbite: ["8L8", "7T", "7L18", "6T", "6L18", "5T", "5L18"],
			bugbuzz: ["8M", "8L48", "7L48", "6L48", "5L48"],
			camouflage: ["7E", "6E"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crosspoison: ["8M", "7E", "6E", "5E"],
			cut: ["6M", "5M"],
			disable: ["7E", "6E", "5E"],
			discharge: ["8L37", "7L45", "6L45", "5L45"],
			doubleteam: ["8E", "7M", "6M", "5M"],
			electroball: ["8M", "8L20", "7L29", "6L29", "5L29"],
			electroweb: ["8M", "8L4", "7T", "7L15", "6T", "6L15", "5T", "5L15"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			feintattack: ["7E", "6E", "5E"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["8L1", "7L12", "6L12", "5L12"],
			gastroacid: ["8L44", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			infestation: ["8E", "7M", "6M"],
			leechlife: ["8M", "7M", "6L1", "5L1"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			lunge: ["8E", "7E"],
			magnetrise: ["7T", "6T", "5T"],
			pinmissile: ["8M", "7E", "6E", "5E"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			poisonsting: ["8E", "7E", "6E", "5E"],
			protect: ["8M", "7M", "6M", "5M"],
			pursuit: ["7E", "6E", "5E"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			risingvoltage: ["8T"],
			rockclimb: ["7E", "6E", "5E"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L40", "7L7", "6L7", "5L7"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "7L34", "6T", "6L34", "5T", "5L34"],
			skittersmack: ["8T"],
			slash: ["8L32", "7L26", "6L26", "5L26"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			speedswap: ["8M"],
			spiderweb: ["7L1", "6L1", "5L1"],
			stringshot: ["8L12", "7L1", "6L1", "5L1"],
			strugglebug: ["8E", "6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			suckerpunch: ["8L28", "7L40", "6L40", "5L40"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "8L16", "7M", "7L4", "6M", "6L4", "5M", "5L4"],
			toxic: ["7M", "6M", "5M"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			xscissor: ["8M", "7M", "6M", "5M"],
		},
	},
	galvantula: {
		learnset: {
			absorb: ["8L1", "7L1"],
			agility: ["8M", "8L24", "7L40", "6L40", "5L40"],
			attract: ["8M", "7M", "6M", "5M"],
			bounce: ["8M", "7T", "6T", "5T"],
			bugbite: ["8L1", "7T", "7L18", "6T", "6L18", "5T", "5L18"],
			bugbuzz: ["8M", "8L56", "7L60", "6L60", "5L60"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crosspoison: ["8M"],
			cut: ["6M", "5M"],
			disable: ["5D"],
			discharge: ["8L39", "7L54", "6L54", "5L54"],
			doubleteam: ["7M", "6M", "5M"],
			electroball: ["8M", "8L20", "7L29", "6L29", "5L29", "5D"],
			electroweb: ["8M", "8L1", "7T", "7L15", "6T", "6L15", "5T", "5L15"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["8L1", "7L12", "6L12", "5L12"],
			gastroacid: ["8L50", "7T", "7L23", "6T", "6L23", "5T", "5L23"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			leechlife: ["8M", "7M", "6L1", "5L1"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			pursuit: ["5D"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L44", "7L7", "6L7", "5L7"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "7L34", "6T", "6L34", "5T", "5L34"],
			skittersmack: ["8T"],
			slash: ["8L32", "7L26", "6L26", "5L26"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			speedswap: ["8M"],
			spiderweb: ["7L1", "6L1", "5L1"],
			stickyweb: ["8L0", "7L1", "6L1"],
			stringshot: ["8L12", "7L1", "6L1", "5L1"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			suckerpunch: ["8L28", "7L46", "6L46", "5L46"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			thief: ["8M", "7M", "6M", "5M"],
			throatchop: ["8M", "7T"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "8L16", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			toxic: ["7M", "6M", "5M"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			xscissor: ["8M", "7M", "6M", "5M"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	ferroseed: {
		learnset: {
			acidspray: ["8E", "7E", "6E"],
			assurance: ["8M"],
			attract: ["8M"],
			bulletseed: ["8M", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			curse: ["8L41", "7L9", "6L9", "5L9"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			explosion: ["8L50", "7M", "7L55", "6M", "6L55", "5M", "5L55"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "8L20", "7M", "7L52", "6M", "6L52", "5M", "5L52"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gravity: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			gyroball: ["8M", "8L45", "7M", "7L21", "6M", "6L21", "5M", "5L21"],
			harden: ["8L1", "7L1", "6L1", "5L1"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			ingrain: ["8L15", "7L35", "6L35", "5L35"],
			irondefense: ["8M", "8L35", "7T", "7L26", "6T", "6L26", "5T", "5L26"],
			ironhead: ["8M", "8L25", "7T", "7L43", "6T", "6L43", "5T", "5L43"],
			knockoff: ["8E", "7T", "6T"],
			leechseed: ["8E", "7E", "6E", "5E"],
			magnetrise: ["7T", "6T", "5T"],
			metalclaw: ["8L5", "7L14", "6L14", "5L14"],
			mirrorshot: ["7L30", "6L30", "5L30"],
			naturepower: ["7M", "6M"],
			payback: ["8M", "7M", "7L47", "6M", "6L47", "5M", "5L47"],
			pinmissile: ["8M", "8L10", "7L18", "6L18", "5L18"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			rockclimb: ["7E", "6E", "5E"],
			rockpolish: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rollout: ["7L6", "6L6", "5L6"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			selfdestruct: ["8M", "8L30", "7L38", "6L38", "5L38"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spikes: ["8M", "7E", "6E", "5E"],
			stealthrock: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["8E", "7M", "6M", "5M"],
			worryseed: ["7T", "7E", "6T", "6E", "5T", "5E"],
		},
	},
	ferrothorn: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			assurance: ["8M"],
			attract: ["8M"],
			block: ["7T", "6T"],
			bodypress: ["8M"],
			brutalswing: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			bulletseed: ["8M"],
			confide: ["7M", "6M"],
			curse: ["8L43", "7L1", "6L1", "5L1"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			explosion: ["8L56", "7M", "7L67", "6M", "6L67", "5M", "5L67"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "8L20", "7M", "7L61", "6M", "6L61", "5M", "5L61"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			gyroball: ["8M", "8L49", "7M", "7L21", "6M", "6L21", "5M", "5L21"],
			harden: ["8L1", "7L1", "6L1", "5L1"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			ingrain: ["8L15", "7L35", "6L35", "5L35"],
			irondefense: ["8M", "8L35", "7T", "7L26", "6T", "6L26", "5T", "5L26"],
			ironhead: ["8M", "8L25", "7T", "7L46", "6T", "6L46", "5T", "5L46"],
			knockoff: ["7T", "6T", "5T"],
			magnetrise: ["7T", "6T", "5T"],
			metalclaw: ["8L1", "7L14", "6L14", "5L14"],
			mirrorshot: ["7L30", "6L30", "5L30"],
			naturepower: ["7M", "6M"],
			payback: ["8M", "7M", "7L53", "6M", "6L53", "5M", "5L53"],
			pinmissile: ["8M", "8L1", "7L18", "6L18", "5L18"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			powerwhip: ["8M", "8L0", "7L1", "6L40", "5L40"],
			protect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			rockclimb: ["7L1", "6L1", "5L1"],
			rockpolish: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rollout: ["7L1", "6L1", "5L1"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			selfdestruct: ["8M", "8L30", "7L38", "6L38", "5L38"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spikes: ["8M"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1", "5L1"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			worryseed: ["7T", "6T", "5T"],
		},
	},
	klink: {
		learnset: {
			assurance: ["8M"],
			autotomize: ["8L20", "7L31", "6L31", "5L31"],
			bind: ["8L4", "7T", "7L21", "6T", "6L21", "5T", "5L21"],
			charge: ["8L8", "7L6", "6L6", "5L6"],
			chargebeam: ["8L12", "7M", "7L26", "6M", "6L26", "5M", "5L26"],
			confide: ["7M", "6M"],
			discharge: ["8L24", "7L42", "6L42", "5L42"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			geargrind: ["8L32", "7L16", "6L16", "5L16"],
			gravity: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "8L48", "7M", "7L57", "6M", "6L57", "5M", "5L57"],
			irondefense: ["8M", "7T", "6T", "5T"],
			lockon: ["8L36", "7L50", "6L50", "5L51"],
			magiccoat: ["7T", "6T", "5T"],
			magnetrise: ["7T", "6T", "5T"],
			metalsound: ["8L16", "7L45", "6L45", "5L45"],
			mirrorshot: ["7L36", "6L36", "5L36"],
			powergem: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			risingvoltage: ["8T"],
			rockpolish: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L28", "7L39", "6L39", "5L39"],
			secretpower: ["6M"],
			shiftgear: ["8L40", "7L48", "6L48", "5L48"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thundershock: ["8L1", "7L11", "6L11", "5L11"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			visegrip: ["8L1", "7L1", "6L1", "5L1"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zapcannon: ["8L44", "7L54", "6L54", "5L54"],
		},
	},
	klang: {
		learnset: {
			allyswitch: ["8M", "7T"],
			assurance: ["8M"],
			autotomize: ["8L20", "7L31", "6L31", "5L31"],
			bind: ["8L1", "7T", "7L21", "6T", "6L21", "5T", "5L21"],
			charge: ["8L1", "7L1", "6L1", "5L1"],
			chargebeam: ["8L12", "7M", "7L26", "6M", "6L26", "5M", "5L26", "5D"],
			confide: ["7M", "6M"],
			discharge: ["8L24", "7L44", "6L44", "5L44"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			geargrind: ["8L32", "7L1", "6L1", "5L1"],
			gravity: ["7T", "6T", "5T", "5D"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "8L54", "7M", "7L64", "6M", "6L64", "5M", "5L64"],
			irondefense: ["8M", "7T", "6T", "5T"],
			lockon: ["8L36", "7L56", "6L56", "5L56"],
			magiccoat: ["7T", "6T", "5T"],
			magnetrise: ["7T", "6T", "5T", "5D"],
			metalsound: ["8L16", "7L48", "6L48", "5L48"],
			mirrorshot: ["7L36", "6L36", "5L36"],
			powergem: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			risingvoltage: ["8T"],
			rockpolish: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L28", "7L40", "6L40", "5L40"],
			secretpower: ["6M"],
			shiftgear: ["8L42", "7L52", "6L52", "5L52"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thundershock: ["8L1", "7L1", "6L1", "5L1"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			visegrip: ["8L1", "7L1", "6L1", "5L1"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zapcannon: ["8L48", "7L60", "6L60", "5L60"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	klinklang: {
		learnset: {
			allyswitch: ["8M", "7T"],
			assurance: ["8M"],
			autotomize: ["8L20", "7L31", "6L31", "5L31"],
			bind: ["8L1", "7T", "7L21", "6T", "6L21", "5T", "5L21"],
			charge: ["8L1", "7L1", "6L1", "5L1"],
			chargebeam: ["8L12", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			confide: ["7M", "6M"],
			discharge: ["8L24", "7L44", "6L44", "5L44"],
			doubleteam: ["7M", "6M", "5M"],
			electricterrain: ["8M", "8L64"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			geargrind: ["8L32", "7L1", "6L1", "5L1"],
			gearup: ["8L1", "7L1"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "8L56", "7M", "7L72", "6M", "6L72", "5M", "5L72"],
			irondefense: ["8M", "7T", "6T", "5T"],
			lockon: ["8L36", "7L60", "6L60", "5L60"],
			magiccoat: ["7T", "6T", "5T"],
			magneticflux: ["8L1", "7L1", "6L1"],
			magnetrise: ["7T", "6T", "5T"],
			metalsound: ["8L16", "7L48", "6L48", "5L48"],
			mirrorshot: ["7L36", "6L36", "5L36"],
			powergem: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			risingvoltage: ["8T"],
			rockpolish: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L28", "7L40", "6L40", "5L40"],
			secretpower: ["6M"],
			shiftgear: ["8L42", "7L54", "6L54", "5L54"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thundershock: ["8L1", "7L1", "6L1", "5L1"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trickroom: ["8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			visegrip: ["8L1", "7L1", "6L1", "5L1"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zapcannon: ["8L48", "7L66", "6L1", "5L66"],
		},
	},
	tynamo: {
		learnset: {
			chargebeam: ["9M", "9L1", "7M", "7L1", "6M", "6L1", "5L1"],
			magnetrise: ["7T", "6T", "5T"],
			spark: ["9L1", "7L1", "6L1", "5L1"],
			tackle: ["9L1", "7L1", "6L1", "5L1"],
			terablast: ["9M"],
			thunderwave: ["9M", "9L1", "7M", "7L1", "6M", "6L1", "5L1"],
		},
	},
	eelektrik: {
		learnset: {
			acid: ["9L19", "7L19", "6L19", "5L19"],
			acidspray: ["9M", "9L49", "7L49", "6L49", "5L49"],
			acrobatics: ["9M", "7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			attract: ["7M", "6M", "5M"],
			bind: ["9L9", "7T", "7L9", "6T", "6L9", "5T", "5L9"],
			bodyslam: ["9M"],
			bounce: ["7T", "6T", "5T"],
			chargebeam: ["9M", "9L1", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			coil: ["9L54", "7L54", "6L54", "5L54"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L0", "7L1", "6L39", "5L39"],
			discharge: ["9L29", "7L29", "6L29", "5L29"],
			doubleteam: ["7M", "6M", "5M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["9M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["9L64", "7T", "7L64", "6T", "6L64", "5T", "5L64"],
			gigadrain: ["9M", "7T", "6T", "5T"],
			headbutt: ["9L1", "7L1", "6L1", "5L1"],
			hiddenpower: ["7M", "6M", "5M"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lightscreen: ["9M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T"],
			protect: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M"],
			rest: ["9M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["9M", "7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			spark: ["9L1", "7L1", "6L1", "5L1"],
			substitute: ["9M", "7M", "6M", "5M"],
			superfang: ["7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L74", "7L74", "6L74", "5L74"],
			throatchop: ["7T"],
			thunder: ["9M", "7M", "6M", "5M"],
			thunderbolt: ["9M", "9L44", "7M", "7L44", "6M", "6L44", "5M", "5L44"],
			thunderfang: ["9M"],
			thunderwave: ["9M", "9L1", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			toxic: ["7M", "6M", "5M"],
			uturn: ["9M", "7M", "6M", "5M"],
			voltswitch: ["9M", "7M", "6M", "5M"],
			wildcharge: ["9M", "9L59", "7M", "7L59", "6M", "6L59", "5M", "5L59"],
			zapcannon: ["9L69", "7L69", "6L69", "5L69"],
		},
	},
	eelektross: {
		learnset: {
			acid: ["9L1", "7L1", "6L1", "5L1"],
			acidspray: ["9M"],
			acrobatics: ["9M", "7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			attract: ["7M", "6M", "5M"],
			bind: ["7T", "6T", "5T"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bounce: ["7T", "6T", "5T"],
			brickbreak: ["9M", "7M", "6M", "5M"],
			bulkup: ["9M"],
			bulldoze: ["9M"],
			chargebeam: ["9M", "7M", "6M", "5M"],
			closecombat: ["9M"],
			coil: ["9L1", "7L1", "6L1"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			crunch: ["9M", "9L1", "7L1", "6L1", "5L1"],
			crushclaw: ["9L1", "7L1", "6L1", "5L1"],
			cut: ["6M", "5M"],
			discharge: ["9L1", "7L1", "6L1", "5L1"],
			doubleteam: ["7M", "6M", "5M"],
			dragonclaw: ["9M", "7M", "6M", "5M"],
			dragonpulse: ["9M", "7T", "6T"],
			dragontail: ["9M", "7M", "6M", "5M"],
			drainpunch: ["9M", "7T", "6T", "5T"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M", "5M"],
			firepunch: ["9M", "7T", "6T", "5T"],
			flamethrower: ["9M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["9M", "7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["9L1", "7T", "7L1", "6T", "6L1", "5T"],
			gigadrain: ["9M", "7T", "6T", "5T"],
			gigaimpact: ["9M", "7M", "6M", "5M"],
			grassknot: ["9M", "7M", "6M", "5M"],
			headbutt: ["9L1", "7L1", "6L1", "5L1"],
			heavyslam: ["9M"],
			hex: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "7M", "6M", "5M"],
			iondeluge: ["7L1", "6L1"],
			irontail: ["7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lightscreen: ["9M", "7M", "6M", "5M"],
			liquidation: ["9M"],
			magnetrise: ["7T", "6T", "5T"],
			outrage: ["9M", "7T", "6T"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M"],
			raindance: ["9M", "7M", "6M", "5M"],
			rest: ["9M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["7M", "6M", "5M"],
			rockslide: ["9M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "7M", "6M", "5M"],
			round: ["7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["9M", "7M", "6M", "5T"],
			snore: ["7T", "6T", "5T"],
			stompingtantrum: ["9M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "7M", "6M", "5M"],
			sunnyday: ["9M"],
			superfang: ["7T", "6T", "5T"],
			superpower: ["7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L1", "7L1", "6L1"],
			throatchop: ["7T"],
			thunder: ["9M", "7M", "6M", "5M"],
			thunderbolt: ["9M", "7M", "6M", "5M"],
			thunderfang: ["9M"],
			thunderpunch: ["9M", "7T", "6T", "5T"],
			thunderwave: ["9M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uturn: ["9M", "7M", "6M", "5M"],
			voltswitch: ["9M", "7M", "6M", "5M"],
			wildcharge: ["9M", "9L5", "7M", "6M", "5M"],
			zapcannon: ["9L1", "7L1", "6L1"],
			zenheadbutt: ["9M"],
		},
	},
	elgyem: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			agility: ["8M"],
			allyswitch: ["8M", "7T", "7E", "6E", "5M"],
			astonish: ["8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			barrier: ["7E", "6E", "5E", "5D"],
			calmmind: ["8M", "8L48", "7M", "7L43", "6M", "6L43", "5M", "5L43"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "5L1"],
			cosmicpower: ["8M", "7E", "6E"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			destinybond: ["8E"],
			disable: ["8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			growl: ["8L1", "7L4", "6L4", "5L4"],
			guardsplit: ["8L24", "7L50", "6L50", "5L50"],
			guardswap: ["8M", "7E", "6E", "5E"],
			headbutt: ["8L30", "7L18", "6L18", "5L18"],
			healblock: ["7L8", "6L8", "5L8", "5D"],
			hiddenpower: ["7M", "7L22", "6M", "6L22", "5M", "5L22"],
			imprison: ["8M", "8L6", "7L25", "6L25", "5L25"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			meteorbeam: ["8T"],
			miracleeye: ["7L11", "6L11", "5L11"],
			nastyplot: ["8M", "7E", "6E", "5E", "5D"],
			painsplit: ["7T", "6T", "5T"],
			powersplit: ["8L24", "7L50", "6L50", "5L50"],
			powerswap: ["8M", "7E", "6E", "5E"],
			protect: ["8M", "7M", "6M", "5M"],
			psybeam: ["8L18", "7L15", "6L15", "5L15"],
			psychic: ["8M", "8L60", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			psychup: ["8E", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			recover: ["8L43", "7L46", "6L46", "5L46"],
			recycle: ["7T", "6T", "5T"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			simplebeam: ["7L29", "6L29", "5L29"],
			skillswap: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			synchronoise: ["7L53", "6L53", "5L53"],
			telekinesis: ["7T", "5M"],
			teleport: ["8L12", "7E", "6E", "5E"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			wonderroom: ["8M", "8L54", "7T", "7L56", "6T", "6L56", "5T", "5L56"],
			zenheadbutt: ["8M", "8L36", "7T", "7L32", "6T", "6L32", "5T", "5L32"],
		},
	},
	beheeyem: {
		learnset: {
			afteryou: ["7T", "6T", "5T"],
			agility: ["8M"],
			allyswitch: ["8M", "7T", "5M"],
			attract: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "8L52", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			confusion: ["8L1", "7L1", "6L1", "5L1"],
			cosmicpower: ["8M"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["8M"],
			frustration: ["7M", "6M", "5M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			growl: ["8L1", "7L1", "6L1", "5L1"],
			guardsplit: ["8L24", "7L56", "6L56", "5L56"],
			guardswap: ["8M"],
			headbutt: ["8L30", "7L18", "6L18", "5L18"],
			healblock: ["7L1", "6L1", "5L1"],
			hiddenpower: ["7M", "7L22", "6M", "6L22", "5M", "5L22"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			imprison: ["8M", "8L1", "7L25", "6L25", "5L25"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			meteorbeam: ["8T"],
			miracleeye: ["7L1", "6L1", "5L1"],
			nastyplot: ["8M"],
			painsplit: ["7T", "6T", "5T"],
			powersplit: ["8L24", "7L58", "6L58", "5L58"],
			powerswap: ["8M"],
			protect: ["8M", "7M", "6M", "5M"],
			psybeam: ["8L18", "7L15", "6L15", "5L15"],
			psychic: ["8M", "8L68", "7M", "7L39", "6M", "6L39", "5M", "5L39"],
			psychicterrain: ["8M", "8L1", "7L1"],
			psychup: ["7M", "7L36", "6M", "6L36", "5M", "5L36"],
			psyshock: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			recover: ["8L45", "7L50", "6L50", "5L50"],
			recycle: ["7T", "6T", "5T"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			simplebeam: ["7L29", "6L29", "5L29"],
			skillswap: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			synchronoise: ["7L1", "6L1", "5L63"],
			telekinesis: ["7T", "5M"],
			teleport: ["8L1"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			triattack: ["8M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			wonderroom: ["8M", "8L60", "7T", "7L1", "6T", "6L1", "5T", "5L68"],
			zenheadbutt: ["8M", "8L36", "7T", "7L32", "6T", "6L32", "5T", "5L32"],
		},
	},
	litwick: {
		learnset: {
			acid: ["7E", "6E", "5E"],
			acidarmor: ["8E", "7E", "6E", "5E"],
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			burningjealousy: ["8T"],
			calmmind: ["8M", "7M", "6M", "5M"],
			captivate: ["7E", "6E", "5E"],
			clearsmog: ["8E", "7E", "6E", "5E"],
			confide: ["7M", "6M"],
			confuseray: ["8L12", "7L10", "6L10", "5L10"],
			curse: ["8L32", "7L43", "6L43", "5L43"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			ember: ["8L4", "7L1", "6L1", "5L1"],
			endure: ["8M", "7E", "6E", "5E"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			fireblast: ["8M", "7M", "6M", "5M"],
			firespin: ["8M", "8L24", "7L7", "6L7", "5L7"],
			flameburst: ["7L20", "6L20", "5L20"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			haze: ["8E", "7E", "6E", "5E"],
			heatwave: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			hex: ["8M", "8L16", "7L28", "6L28", "5L28"],
			hiddenpower: ["7M", "6M", "5M"],
			imprison: ["8M", "8L44", "7L24", "6L24", "5L24"],
			incinerate: ["6M", "5M"],
			inferno: ["8L40", "7L38", "6L38", "5L38"],
			memento: ["8L56", "7L33", "6L33", "5L33"],
			minimize: ["8L8", "7L3", "6L3", "5L3"],
			mysticalfire: ["8M"],
			nightshade: ["8L28", "7L13", "6L13", "5L13"],
			overheat: ["8M", "8L52", "7M", "7L61", "6M", "6L61", "5M", "5L61"],
			painsplit: ["8L48", "7T", "7L55", "6T", "6L55", "5T", "5L55"],
			payback: ["8M", "7M", "6M", "5M"],
			poltergeist: ["8T"],
			powersplit: ["8E", "7E", "6E"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "8L36", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			shockwave: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smog: ["8L1", "7L5", "6L5", "5L5"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spite: ["7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8L20", "7M", "7L16", "6M", "6L16", "5M", "5L16"],
		},
	},
	lampent: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			burningjealousy: ["8T"],
			calmmind: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			confuseray: ["8L12", "7L10", "6L10", "5L10"],
			curse: ["8L32", "7L45", "6L45", "5L45"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			ember: ["8L1", "7L1", "6L1", "5L1"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			fireblast: ["8M", "7M", "6M", "5M"],
			firespin: ["8M", "8L24", "7L7", "6L7", "5L7"],
			flameburst: ["7L20", "6L20", "5L20"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hex: ["8M", "8L16", "7L28", "6L28", "5L28"],
			hiddenpower: ["7M", "6M", "5M"],
			imprison: ["8M", "8L46", "7L24", "6L24", "5L24"],
			incinerate: ["6M", "5M"],
			inferno: ["8L40", "7L38", "6L38", "5L38"],
			memento: ["8L64", "7L33", "6L33", "5L33"],
			minimize: ["8L1", "7L1", "6L1", "5L1"],
			mysticalfire: ["8M"],
			nightshade: ["8L28", "7L13", "6L13", "5L13"],
			overheat: ["8M", "8L58", "7M", "7L69", "6M", "6L69", "5M", "5L69"],
			painsplit: ["8L52", "7T", "7L61", "6T", "6L61", "5T", "5L61"],
			payback: ["8M", "7M", "6M", "5M"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "8L36", "7M", "7L53", "6M", "6L53", "5M", "5L53"],
			shockwave: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smog: ["8L1", "7L1", "6L1", "5L1"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spite: ["7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8L20", "7M", "7L16", "6M", "6L16", "5M", "5L16"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	chandelure: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1"],
			attract: ["8M", "7M", "6M", "5M"],
			burningjealousy: ["8T"],
			calmmind: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			confuseray: ["8L1", "7L1", "6L1", "5L1"],
			curse: ["8L1"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleteam: ["7M", "6M", "5M"],
			dreameater: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			ember: ["8L1"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M", "5S0"],
			facade: ["8M", "7M", "6M", "5M"],
			fireblast: ["8M", "7M", "6M", "5M"],
			firespin: ["8M", "8L1"],
			flameburst: ["7L1", "6L1", "5L1"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			heatwave: ["8M", "7T", "6T", "5T", "5S0"],
			hex: ["8M", "8L1", "7L1", "6L1", "5L1"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			imprison: ["8M", "8L1"],
			incinerate: ["6M", "5M"],
			inferno: ["8L1"],
			laserfocus: ["7T"],
			memento: ["8L1"],
			minimize: ["8L1"],
			mysticalfire: ["8M"],
			nightshade: ["8L1"],
			overheat: ["8M", "8L1", "7M", "6M", "5M"],
			painsplit: ["8L1", "7T", "7L1", "6T", "6L1", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M", "5S0"],
			psychup: ["7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["8M", "8L1", "7M", "6M", "5M", "5S0"],
			shockwave: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smog: ["8L1", "7L1", "6L1", "5L1"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spite: ["7T", "6T", "5T"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M", "7T", "6T", "5T"],
			trickroom: ["8M", "7M", "6M", "5M"],
			willowisp: ["8M", "8L1", "7M", "6M", "5M"],
		},
		eventData: [
			{generation: 5, level: 50, gender: "F", nature: "Modest", ivs: {spa: 31}, abilities: ["flashfire"], moves: ["heatwave", "shadowball", "energyball", "psychic"], pokeball: "cherishball"},
		],
	},
	axew: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			aquatail: ["9E", "7T", "6T", "5T"],
			assurance: ["9L9", "8M", "8L9", "7L7", "6L7", "5L7"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["9L3", "8L3"],
			breakingswipe: ["9L30", "8M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "6E", "5E", "5D"],
			crunch: ["9M", "9L24", "8M", "8L24"],
			cut: ["6M", "5M"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T"],
			dragonclaw: ["9M", "9L18", "8M", "8L18", "7M", "7L28", "6M", "6L28", "5M", "5L28", "5S1"],
			dragondance: ["9M", "9L27", "8M", "8L27", "7L32", "6L32", "5L32"],
			dragonpulse: ["9M", "9L36", "8M", "8L36", "7T", "7L41", "7E", "6T", "6L41", "6E", "5T", "5L41", "5E"],
			dragonrage: ["7L10", "6L10", "5L10", "5D", "5S0", "5S1", "5S2"],
			dragontail: ["9M"],
			dualchop: ["8L30", "7T", "7L13", "6T", "6L13", "5T", "5L13"],
			endeavor: ["9E", "8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			endure: ["9M", "8M", "7E", "6E", "5E", "5S1"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			falseswipe: ["9M", "9L6", "8M", "8L6", "7M", "7L24", "6M", "6L24", "5M", "5L24"],
			firstimpression: ["9E", "8E"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusenergy: ["9L33", "8M", "7E", "6E", "5E"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M", "9L48", "8M", "8L48", "7M", "7L61", "6M", "6L61", "5M", "5L61", "5S2"],
			guillotine: ["9L45", "8L45", "7L50", "6L50", "5L51"],
			harden: ["8E", "7E", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M"],
			irontail: ["9E", "8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			laserfocus: ["8L33"],
			leer: ["9L1", "8L1", "7L4", "6L4", "5L4"],
			nightslash: ["9E", "8E", "7E", "6E", "5E", "5D"],
			outrage: ["9M", "9L42", "8M", "8L42", "7T", "7L56", "6T", "6L56", "5T", "5L56", "5S2"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			razorwind: ["7E", "6E", "5E"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M", "5S1"],
			reversal: ["9M", "8M", "7E", "6E", "5E"],
			roar: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L21", "8M", "8L21", "7L16", "6L16", "5L16"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S0", "5S2"],
			secretpower: ["6M"],
			shadowclaw: ["9M"],
			shockwave: ["7T", "6T"],
			slash: ["9L15", "8L15", "7L20", "6L20", "5L20"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M"],
			snore: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["9M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["9M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "9L39", "8M", "8L39", "7M", "7L46", "6M", "6L46", "5M", "5L46"],
			takedown: ["9M"],
			taunt: ["9M", "9L12", "8M", "8L12", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M"],
		},
		eventData: [
			{generation: 5, level: 1, shiny: 1, gender: "M", nature: "Naive", ivs: {spe: 31}, abilities: ["moldbreaker"], moves: ["scratch", "dragonrage"], pokeball: "pokeball"},
			{generation: 5, level: 10, gender: "F", abilities: ["moldbreaker"], moves: ["dragonrage", "return", "endure", "dragonclaw"], pokeball: "cherishball"},
			{generation: 5, level: 30, gender: "M", nature: "Naive", abilities: ["rivalry"], moves: ["dragonrage", "scratch", "outrage", "gigaimpact"], pokeball: "cherishball"},
		],
	},
	fraxure: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["9L9", "8M", "8L9", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["9L1", "8L1"],
			breakingswipe: ["9L30", "8M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L24", "8M", "8L24"],
			cut: ["6M", "5M"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T"],
			dragonclaw: ["9M", "9L18", "8M", "8L18", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			dragondance: ["9M", "9L27", "8M", "8L27", "7L32", "6L32", "5L32"],
			dragonpulse: ["9M", "9L36", "8M", "8L36", "7T", "7L42", "6T", "6L42", "5T", "5L42"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragontail: ["9M", "7M", "6M", "5M"],
			dualchop: ["8L30", "7T", "7L13", "6T", "6L13", "5T", "5L13"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			falseswipe: ["9M", "9L1", "8M", "8L1", "7M", "7L24", "6M", "6L24", "5M", "5L24"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusenergy: ["9L33", "8M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M", "9L56", "8M", "8L56", "7M", "7L66", "6M", "6L66", "5M", "5L66"],
			guillotine: ["9L51", "8L51", "7L54", "6L54", "5L54"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M"],
			irontail: ["8M", "7T", "6T", "5T"],
			laserfocus: ["8L33"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			outrage: ["9M", "9L46", "8M", "8L46", "7T", "7L60", "6T", "6L60", "5T", "5L60"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			reversal: ["9M", "8M"],
			roar: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L21", "8M", "8L21", "7L16", "6L16", "5L16"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			slash: ["9L15", "8L15", "7L20", "6L20", "5L20"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M"],
			snore: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["9M", "8M", "7T"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["9M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "9L41", "8M", "8L41", "7M", "7L48", "6M", "6L48", "5M", "5L48"],
			takedown: ["9M"],
			taunt: ["9M", "9L12", "8M", "8L12", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	haxorus: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["9L9", "8M", "8L9", "7L1", "6L1", "5L1"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["9L1", "8L1"],
			bodyslam: ["9M"],
			breakingswipe: ["9L30", "8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			closecombat: ["9M", "8M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L24", "8M", "8L24"],
			cut: ["6M", "5M"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T"],
			dragonclaw: ["9M", "9L18", "8M", "8L18", "7M", "7L28", "6M", "6L28", "5M", "5L28"],
			dragondance: ["9M", "9L27", "8M", "8L27", "7L32", "6L32", "5L32", "5S0"],
			dragonpulse: ["9M", "9L36", "8M", "8L36", "7T", "7L42", "6T", "6L42", "5T", "5L42"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragontail: ["9M", "7M", "6M", "5M"],
			dualchop: ["8L30", "7T", "7L13", "6T", "6L13", "5T", "5L13", "5S0"],
			earthquake: ["9M", "8M", "7M", "6M", "5M", "5S0"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			falseswipe: ["9M", "9L1", "8M", "8L1", "7M", "7L24", "6M", "6L24", "5M", "5L24"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M"],
			focusenergy: ["9L33", "8M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M", "9L60", "8M", "8L60", "7M", "7L74", "6M", "6L74", "5M", "5L74"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			guillotine: ["9L53", "8L53", "7L58", "6L58", "5L58"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			ironhead: ["9M"],
			irontail: ["8M", "7T", "6T", "5T"],
			laserfocus: ["8L33", "7T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			lowsweep: ["9M"],
			outrage: ["9M", "9L46", "8M", "8L46", "7T", "7L1", "6T", "6L1", "5T", "5L66"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychocut: ["8M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			reversal: ["9M", "8M"],
			roar: ["7M", "6M", "5M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L21", "8M", "8L21", "7L16", "6L16", "5L16"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			slash: ["9L15", "8L15", "7L20", "6L20", "5L20"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M", "8M"],
			snore: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["9M", "8M", "7T"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "9L41", "8M", "8L41", "7M", "7L50", "6M", "6L50", "5M", "5L50"],
			takedown: ["9M"],
			taunt: ["9M", "9L12", "8M", "8L12", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M", "5S0"],
		},
		eventData: [
			{generation: 5, level: 59, gender: "F", nature: "Naive", ivs: {hp: 30, atk: 30, def: 30, spa: 30, spd: 30, spe: 30}, abilities: ["moldbreaker"], moves: ["earthquake", "dualchop", "xscissor", "dragondance"], pokeball: "cherishball"},
		],
	},
	cubchoo: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			assurance: ["8M", "7E", "6E", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			avalanche: ["9M", "8M", "7E", "6E", "5E"],
			bide: ["7L9", "6L9", "5L9", "5S0"],
			blizzard: ["9M", "9L39", "8M", "8L39", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brine: ["9L15", "8M", "8L15", "7L21", "6L21", "5L21"],
			bulldoze: ["9M"],
			charm: ["9M", "9L27", "8M", "8L27", "7L29", "6L29", "5L29"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["9M"],
			cut: ["6M", "5M"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M", "8M", "7E", "6E", "5E"],
			endure: ["9M", "9L3", "8M", "8L3", "7L25", "6L25", "5L25"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			flail: ["9L24", "8L24", "7L36", "6L36", "5L36"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focuspunch: ["9E", "8E", "7T", "7E", "6T", "6E", "5E"],
			frostbreath: ["9L18", "8L18", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["9L6", "8L6", "7L17", "6L17", "5L17"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			growl: ["9L1", "8L1", "7L1", "6L1", "5L5", "5S0"],
			hail: ["8M", "8L30", "7M", "7L49", "6M", "6L49", "5M", "5L49"],
			heavyslam: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M"],
			icefang: ["9M", "8M"],
			icepunch: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			icywind: ["9M", "9L9", "8M", "8L9", "7T", "7L13", "6T", "6L13", "5T", "5L13", "5S0"],
			liquidation: ["9M"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metalclaw: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			nightslash: ["9E", "8E", "7E", "6E", "5E"],
			playnice: ["9L12", "8L12", "7L15", "6L15"],
			playrough: ["9M", "8M", "7E", "6E"],
			powdersnow: ["9L1", "8L1", "7L5", "6L5", "5L1", "5S0"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "9L36", "8M", "8L36", "7M", "7L41", "6M", "6L41", "5M", "5L41"],
			return: ["7M", "6M", "5M"],
			rockslide: ["9M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			sheercold: ["9L42", "8L42", "7L57", "6L57", "5L57"],
			slash: ["9L21", "8L21", "7L33", "6L33", "5L33"],
			sleeptalk: ["9M", "8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			snarl: ["9M"],
			snore: ["8M", "7T", "6T", "5T"],
			snowscape: ["9M", "9L30"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L33", "8L33", "7L53", "6L53", "5L53"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			waterpulse: ["9M", "7T", "6T"],
			xscissor: ["9M"],
			yawn: ["9E", "8E", "7E", "6E", "5E"],
		},
		eventData: [
			{generation: 5, level: 15, moves: ["powdersnow", "growl", "bide", "icywind"], pokeball: "cherishball"},
		],
	},
	beartic: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			aquajet: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			avalanche: ["9M", "8M"],
			bide: ["7L1", "6L1", "5L1"],
			blizzard: ["9M", "9L41", "8M", "8L41", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			brine: ["9L15", "8M", "8L15", "7L21", "6L21", "5L21"],
			bulkup: ["9M", "8M", "7M", "6M", "5M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			charm: ["9M", "9L1", "8M", "8L1"],
			chillingwater: ["9M"],
			closecombat: ["9M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			crunch: ["9M"],
			cut: ["6M", "5M"],
			dig: ["9M", "8M", "6M", "5M"],
			dive: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthquake: ["9M"],
			echoedvoice: ["7M", "6M", "5M"],
			encore: ["9M", "8M"],
			endure: ["9M", "9L1", "8M", "8L1", "7L25", "6L25", "5L25"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			flail: ["9L24", "8L24", "7L36", "6L36", "5L36"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frostbreath: ["9L18", "8L18", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["9L1", "8L1", "7L17", "6L17", "5L17"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			growl: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			hail: ["8M", "8L30", "7M", "7L53", "6M", "6L53", "5M", "5L53"],
			heavyslam: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			icebeam: ["9M", "8M", "7M", "6M", "5M"],
			icefang: ["9M", "8M"],
			icepunch: ["9M", "8M", "7T", "6T", "5T"],
			iciclecrash: ["9L0", "8L0", "7L1", "6L37", "5L37"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L9", "8M", "8L9", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			liquidation: ["9M", "8M"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metalclaw: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			playnice: ["9L12", "8L12", "7L15", "6L9"],
			playrough: ["9M", "8M"],
			powdersnow: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "9L36", "8M", "8L36", "7M", "7L41", "6M", "6L41", "5M", "5L41"],
			return: ["7M", "6M", "5M"],
			reversal: ["9M"],
			roar: ["7M", "6M", "5M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			sheercold: ["9L46", "8L46", "7L1", "6L1", "5L66"],
			slash: ["9L21", "8L21", "7L33", "6L33", "5L33"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M"],
			snore: ["8M", "7T", "6T", "5T"],
			snowscape: ["9M", "9L30"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["9L51", "8M", "8L51", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			surf: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["9L27", "8L27", "7M", "7L29", "6M", "6L29", "5M", "5L29"],
			swordsdance: ["9M", "8M", "7M", "6M", "5M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L33", "8L33", "7L1", "6L1", "5L59"],
			throatchop: ["8M", "7T"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			waterpulse: ["9M", "7T", "6T"],
			xscissor: ["9M"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	cryogonal: {
		learnset: {
			acidarmor: ["9L52", "8L52", "7L17", "6L29", "5L29"],
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			ancientpower: ["9L20", "8L24", "7L21"],
			attract: ["7M", "6M", "5M"],
			aurorabeam: ["9L24", "8L28", "7L13", "6L25", "5L25"],
			auroraveil: ["9E", "7M"],
			avalanche: ["9M", "8M"],
			bind: ["9L1", "8L1", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			blizzard: ["9M", "8M", "7M", "6M", "5M"],
			bodyslam: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M", "9L4", "8L4", "7L41", "6L45", "5L45"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["9M", "8M"],
			explosion: ["9E", "7M", "6M", "5M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			flashcannon: ["9M", "8M", "7M", "6M", "5M"],
			freezedry: ["9L36", "8L36", "7L49", "6L50"],
			frostbreath: ["9E", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M"],
			hail: ["8M", "7M", "6M", "5M"],
			haze: ["9L16", "8L20", "7L9", "6L1", "5L21"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			icebeam: ["9M", "9L48", "8M", "8L48", "7M", "7L25", "6M", "6L33", "5M", "5L33"],
			iceshard: ["9L1", "8L1", "7L1", "6L1", "5L5"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L12", "8M", "8L16", "7T", "7L5", "6T", "6L17", "5T", "5L17"],
			irondefense: ["9M", "8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["8L12", "7T"],
			lightscreen: ["9M", "9L40", "8M", "8L40", "7M", "7L29", "6M", "6L37", "5M", "5L37"],
			magiccoat: ["7T", "6T", "5T"],
			mist: ["9L16", "8L20", "7L9", "6L1", "5L21"],
			nightslash: ["9L32", "8L32", "7L1", "6L1", "5L57"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rapidspin: ["9L8", "8L8", "7L1", "6L13", "5L13"],
			recover: ["9L44", "8L44", "7L45", "6L49", "5L49"],
			reflect: ["9M", "9L40", "8M", "8L40", "7M", "7L33", "6M", "6L37", "5M", "5L37"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			sharpen: ["7L1", "6L9", "5L9"],
			sheercold: ["9L60", "8L60", "7L1", "6L1", "5L61"],
			signalbeam: ["7T", "6T", "5T"],
			slash: ["9L28", "8L32", "7L37", "6L41", "5L41"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			snowscape: ["9M"],
			solarbeam: ["9M", "9L56", "8M", "8L56", "7M", "7L50", "6M", "6L53", "5M", "5L53"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M"],
			tripleaxel: ["8T"],
			waterpulse: ["9M", "7T", "6T"],
		},
	},
	shelmet: {
		learnset: {
			absorb: ["8L1", "7L1"],
			acid: ["8L4", "7L4", "6L4", "5L4", "5D"],
			acidarmor: ["8L24", "7L32", "6L32", "5L32"],
			attract: ["8M", "7M", "6M", "5M"],
			batonpass: ["8M", "7E", "6E", "5E", "5D"],
			bide: ["7L8", "6L8", "5L8"],
			bodyslam: ["8M", "8L36", "7L40", "6L40", "5L40", "5S1"],
			bugbite: ["7T", "6T", "5T"],
			bugbuzz: ["8M", "8L44", "7L44", "6L44", "5L44", "5S1"],
			confide: ["7M", "6M"],
			curse: ["8L8", "7L13", "6L13", "5L13"],
			doubleedge: ["8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M"],
			encore: ["8M", "7E", "6E", "5E", "5D", "5S1"],
			endure: ["8M", "7E", "6E", "5E"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			feint: ["8E", "7E", "6E", "5E"],
			finalgambit: ["8L48", "7L56", "6L56", "5L56"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["7T", "6T", "5T"],
			gigadrain: ["8M", "8L28", "7T", "7L37", "6T", "6L37", "5T", "5L37", "5S1"],
			guardsplit: ["8E", "7E", "6E", "5E"],
			guardswap: ["8M", "8L32", "7L50", "6L50", "5L52"],
			hiddenpower: ["7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			leechlife: ["8M", "7M", "6L1", "5L1"],
			megadrain: ["8L12", "7L20", "6L20", "5L20", "5S0"],
			mindreader: ["8E", "7E", "6E", "5E"],
			mudshot: ["8M"],
			mudslap: ["7E", "6E", "5E"],
			protect: ["8M", "8L1", "7M", "7L28", "6M", "6L28", "5M", "5L28", "5S0"],
			pursuit: ["7E", "6E", "5E"],
			raindance: ["8M", "7M", "6M", "5M"],
			recover: ["8L40", "7L49", "6L49", "5L49"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T", "5T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			spikes: ["8M", "7E", "6E", "5E"],
			strugglebug: ["8L16", "7L16", "6M", "6L16", "5M", "5L16", "5S0"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			toxicspikes: ["8M", "7E"],
			venoshock: ["8M", "7M", "6M", "5M"],
			yawn: ["8L20", "7L25", "6L25", "5L25", "5S0"],
		},
		eventData: [
			{generation: 5, level: 30, moves: ["strugglebug", "megadrain", "yawn", "protect"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["encore", "gigadrain", "bodyslam", "bugbuzz"], pokeball: "cherishball"},
		],
	},
	accelgor: {
		learnset: {
			absorb: ["8L1", "7L1"],
			acid: ["8L1"],
			acidarmor: ["8L1"],
			acidspray: ["8L1", "7L1", "6L1", "5L1"],
			agility: ["8M", "8L24", "7L32", "6L32", "5L32"],
			attract: ["8M", "7M", "6M", "5M"],
			batonpass: ["8M"],
			bodyslam: ["8M", "8L1"],
			bugbite: ["7T", "6T", "5T"],
			bugbuzz: ["8M", "8L44", "7L44", "6L44", "5L44"],
			confide: ["7M", "6M"],
			curse: ["8L1"],
			doubleteam: ["8L1", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			drainpunch: ["8M"],
			encore: ["8M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			finalgambit: ["8L48", "7L1", "6L1", "5L56"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gastroacid: ["7T", "6T", "5T"],
			gigadrain: ["8M", "8L28", "7T", "7L37", "6T", "6L37", "5T", "5L37"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			guardswap: ["8M", "8L1"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			leechlife: ["8M", "7M", "6L1", "5L1"],
			mefirst: ["7L28", "6L28", "5L28"],
			megadrain: ["8L12", "7L20", "6L20", "5L20"],
			mudshot: ["8M"],
			powerswap: ["8M", "8L32", "7L1", "6L1", "5L52"],
			protect: ["8M", "7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			recover: ["8L40", "7L49", "6L49", "5L49"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			reversal: ["8M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T", "5T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			spikes: ["8M"],
			strugglebug: ["8L16", "7L16", "6M", "6L16", "5M", "5L16"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M", "8L20", "7L25", "6L25", "5L25"],
			toxic: ["8L52", "7M", "6M", "5M"],
			toxicspikes: ["8M"],
			uturn: ["8M", "8L36", "7M", "7L40", "6M", "6L40", "5M", "5L40"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M", "5M"],
			watershuriken: ["8L1", "7L1", "6L1"],
			yawn: ["8L1"],
		},
	},
	stunfisk: {
		learnset: {
			aquatail: ["7T", "6T", "5T"],
			astonish: ["8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			bide: ["7L5", "6L5", "5L5"],
			bounce: ["8M", "8L35", "7T", "7L35", "6T", "6L35", "5T", "5L35"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			camouflage: ["7L17", "6L17", "5L17"],
			charge: ["8L20"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "6E", "5E", "5D"],
			dig: ["8M", "6M", "5M"],
			discharge: ["8L45", "7L25", "6L25", "5L25"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			earthquake: ["8M", "7M", "6M", "5M"],
			eerieimpulse: ["8M", "7E", "6E"],
			electricterrain: ["8M", "8L30"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "8L5", "7L30", "6L30", "5L30"],
			facade: ["8M", "7M", "6M", "5M"],
			fissure: ["8L55", "7L1", "6L1", "5L61"],
			flail: ["8L50", "7L1", "6L1", "5L55"],
			flash: ["6M", "5M"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			hiddenpower: ["7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			lashout: ["8T"],
			magnetrise: ["7T", "6T", "5T"],
			mefirst: ["7E", "6E"],
			mudbomb: ["7L21", "6L21", "5L21"],
			muddywater: ["8M", "8L40", "7L40", "6L40", "5L40"],
			mudshot: ["8M", "8L10", "7L13", "6L13", "5L13"],
			mudslap: ["8L1", "7L1", "6L1", "5L1", "5D"],
			mudsport: ["7L1", "6L1", "5L1"],
			painsplit: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflecttype: ["8E", "7E", "6E"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M", "8L15", "7L50", "6L50", "5L50"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			shockwave: ["7T", "7E", "6T", "6E", "5E"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			spark: ["8E", "7E", "6E", "5E"],
			spite: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			suckerpunch: ["8L25"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["8L1", "7L1", "6L1"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			thundershock: ["8L1", "7L9", "6L9", "5L9"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			watergun: ["8L1", "7L1", "6L1"],
			waterpulse: ["7T", "6T"],
			yawn: ["8E", "7E", "6E", "5E", "5D"],
		},
	},
	stunfiskgalar: {
		learnset: {
			astonish: ["8E"],
			attract: ["8M"],
			bind: ["8E"],
			bounce: ["8M", "8L35"],
			bulldoze: ["8M"],
			counter: ["8E"],
			crunch: ["8M"],
			curse: ["8E"],
			dig: ["8M"],
			earthpower: ["8M"],
			earthquake: ["8M"],
			endure: ["8M", "8L5"],
			facade: ["8M"],
			fissure: ["8L55"],
			flail: ["8L50"],
			flashcannon: ["8M"],
			foulplay: ["8M"],
			icefang: ["8M"],
			irondefense: ["8M", "8L30"],
			lashout: ["8T"],
			metalclaw: ["8L1"],
			metalsound: ["8L20"],
			muddywater: ["8M", "8L40"],
			mudshot: ["8M", "8L10"],
			mudslap: ["8L1"],
			painsplit: ["8E"],
			payback: ["8M"],
			protect: ["8M"],
			raindance: ["8M"],
			reflecttype: ["8E"],
			rest: ["8M"],
			revenge: ["8M", "8L15"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			sandstorm: ["8M"],
			scald: ["8M"],
			screech: ["8M"],
			sleeptalk: ["8M"],
			sludgebomb: ["8M"],
			sludgewave: ["8M"],
			snaptrap: ["8L45"],
			snore: ["8M"],
			spite: ["8E"],
			stealthrock: ["8M"],
			steelbeam: ["8T"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M"],
			substitute: ["8M"],
			suckerpunch: ["8L25"],
			surf: ["8M"],
			tackle: ["8L1"],
			terrainpulse: ["8T"],
			thunderwave: ["8M"],
			uproar: ["8M"],
			watergun: ["8L1"],
			yawn: ["8E"],
		},
	},
	mienfoo: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M"],
			allyswitch: ["8M", "7T", "7E", "6E"],
			attract: ["8M", "7M", "6M", "5M"],
			aurasphere: ["8M", "8L45", "7L61", "6L61", "5L61"],
			batonpass: ["8M", "7E", "6E", "5E"],
			bounce: ["8M", "8L51", "7T", "7L49", "6T", "6L49", "5T", "5L49"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			bulkup: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "8L55", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			detect: ["8L1", "7L9", "6L9", "5L9"],
			dig: ["8M", "6M", "5M"],
			doubleslap: ["7L17", "6L17", "5L17"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "8L35", "7T", "7L33", "6T", "6L33", "5T", "5L33"],
			dualchop: ["7T", "6T", "5T"],
			endure: ["8M", "7E", "6E", "5E"],
			facade: ["8M", "7M", "6M", "5M"],
			fakeout: ["8L5", "7L13", "6L13", "5L13"],
			feint: ["8E", "7E", "6E", "5E"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M"],
			focuspunch: ["8E", "7T", "6T"],
			forcepalm: ["8L25", "7L29", "6L29", "5L29"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["8L15"],
			grassknot: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			highjumpkick: ["8L60", "7L50", "6L50", "5L53"],
			honeclaws: ["8L40"],
			jumpkick: ["7L37", "6L37", "5L37"],
			knockoff: ["8E", "7T", "7E", "6T", "6E", "5T", "5E"],
			lowkick: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			meditate: ["7L5", "6L5", "5L5"],
			mefirst: ["7E", "6E", "5E"],
			megakick: ["8M"],
			megapunch: ["8M"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			quickguard: ["8L20", "7L45", "6L45", "5L45"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M", "8L10", "7L57", "6L57", "5L57"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smellingsalts: ["7E", "6E", "5E"],
			snore: ["8M", "7T", "6T", "5T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M", "7L21", "6L21", "5L21"],
			swordsdance: ["8M", "7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uturn: ["8M", "8L30", "7M", "7L41", "6M", "6L41", "5M", "5L41"],
			vitalthrow: ["8E", "7E", "6E", "5E"],
			workup: ["8M", "7M", "5M"],
		},
	},
	mienshao: {
		learnset: {
			acrobatics: ["8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M"],
			allyswitch: ["8M", "7T"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			aurasphere: ["8M", "8L45", "7L1", "6L1", "5L70"],
			batonpass: ["8M"],
			blazekick: ["8M"],
			bounce: ["8M", "8L53", "7T", "7L49", "6T", "6L49", "5T", "5L49"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			brutalswing: ["8M"],
			bulkup: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "8L59", "7M", "7L25", "6M", "6L25", "5M", "5L25"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			detect: ["8L1", "7L1", "6L1", "5L1"],
			dig: ["8M", "6M", "5M"],
			doubleslap: ["7L17", "6L17", "5L17"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "8L35", "7T", "7L33", "6T", "6L33", "5T", "5L33"],
			dualchop: ["7T", "7S0", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			fakeout: ["8L1", "7L1", "7S0", "6L1", "5L1"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T"],
			forcepalm: ["8L25", "7L29", "6L29", "5L29"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["8L15"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			highjumpkick: ["8L66", "7L56", "7S0", "6L56", "5L56"],
			honeclaws: ["8L40"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			jumpkick: ["7L37", "6L37", "5L37"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			lowkick: ["8M", "7T", "6T", "5T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			meditate: ["7L1", "6L1", "5L1"],
			megakick: ["8M"],
			megapunch: ["8M"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			quickguard: ["8L1"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M", "8L1", "7L1", "6L1", "5L63"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M", "7L21", "6L21", "5L21"],
			swordsdance: ["8M", "7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uturn: ["8M", "8L30", "7M", "7L41", "7S0", "6M", "6L41", "5M", "5L41"],
			wideguard: ["8L20", "7L45", "6L45", "5L45"],
			workup: ["8M", "7M", "5M"],
		},
		eventData: [
			{generation: 7, level: 65, gender: "M", abilities: ["innerfocus"], moves: ["fakeout", "dualchop", "highjumpkick", "uturn"], pokeball: "cherishball"},
		],
	},
	druddigon: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["8L5", "7L9", "6L9", "5L9", "5D"],
			bodyslam: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			chargebeam: ["7M", "6M", "5M"],
			chipaway: ["7L31", "6L31", "5L31"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L40", "7L25", "6L25", "5L25"],
			crushclaw: ["7E", "6E", "5E"],
			cut: ["6M", "5M"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["8T", "7T", "6T", "5T"],
			dragonclaw: ["8M", "8L30", "7M", "7L27", "6M", "6L27", "5M", "5L27"],
			dragonpulse: ["8M", "7T", "6T", "5T"],
			dragonrage: ["7L18", "6L18", "5L18"],
			dragontail: ["8L10", "7M", "7L45", "6M", "6L45", "5M", "5L45"],
			dualwingbeat: ["8T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			feintattack: ["7E", "6E", "5E"],
			firefang: ["8M", "7E", "6E", "5E", "5D"],
			firepunch: ["8M", "7T", "6T", "5T"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			glare: ["8E", "7E", "6E", "5E"],
			gunkshot: ["8M", "7T", "6T", "5T"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["8L35", "7L5", "6M", "6L5", "5M", "5L5"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			ironhead: ["8M", "8L45", "7T", "6T", "5T"],
			irontail: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1", "5S0"],
			megapunch: ["8M"],
			metalclaw: ["8L15", "7E", "6E", "5E"],
			nightslash: ["8E", "7L40", "6L40", "5L40"],
			outrage: ["8M", "8L50", "7T", "7L62", "6T", "6L62", "5T", "5L62"],
			payback: ["8M", "7M", "6M", "5M"],
			poisontail: ["8E", "7E", "6E", "5E"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			pursuit: ["7E", "6E", "5E"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M", "7L35", "6L35", "5L35"],
			roar: ["7M", "6M", "5M"],
			rockclimb: ["7L49", "6L49", "5L49"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L20", "7L13", "6L13", "5L13"],
			scratch: ["8L1", "7L1", "6L1", "5L1", "5S0"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			slash: ["8L25", "7L21", "6L21", "5L21"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			sludgebomb: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snarl: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "7E", "6T", "6E", "5T", "5E"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			suckerpunch: ["8E", "7E", "6E", "5E", "5D"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "8L55", "7T", "7L55", "6T", "6L55", "5T", "5L55"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			taunt: ["8M", "7M", "6M", "5M"],
			thunderfang: ["8M", "7E", "6E", "5E"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
		},
		eventData: [
			{generation: 5, level: 1, shiny: true, moves: ["leer", "scratch"], pokeball: "pokeball"},
		],
	},
	golett: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L1"],
			block: ["7T", "6T", "5T"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			curse: ["8L16", "7L45", "6L40", "5L40"],
			defensecurl: ["8L4", "7L1", "6L1", "5L1"],
			dig: ["8M"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T"],
			dynamicpunch: ["8L56", "7L35", "6L30", "5L30"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "8L52", "7M", "7L50", "6M", "6L45", "5M", "5L45"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T", "5D"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focuspunch: ["7T", "7L61", "6T", "6L55", "5L55"],
			frustration: ["7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			gyroball: ["8M", "7M", "6M", "5M"],
			hammerarm: ["8L48", "7L55", "6L50", "5L50"],
			heavyslam: ["8M", "8L40"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			icebeam: ["8M", "7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			icywind: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			irondefense: ["8M", "8L28", "7T", "7L17", "6T", "6L17", "5T", "5L17"],
			lowkick: ["8M", "7T", "6T", "5T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magnitude: ["7L30", "6L25", "5L25"],
			megakick: ["8M"],
			megapunch: ["8M", "8L32", "7L25", "6L21", "5L21"],
			mudslap: ["8L1", "7L5", "6L5", "5L5"],
			nightshade: ["8L20", "7L40", "6L35", "5L35"],
			phantomforce: ["8M", "8L44"],
			poltergeist: ["8T"],
			pound: ["8L8", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rollout: ["7L9", "6L9", "5L9", "5D"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "8L36", "7M", "6M", "5M"],
			shadowpunch: ["8L12", "7L13", "6L13", "5L13"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "8L24", "7L21"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T", "5D"],
			toxic: ["7M", "6M", "5M"],
		},
	},
	golurk: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1", "5L1"],
			block: ["7T", "6T", "5T"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M", "5M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			chargebeam: ["7M", "6M", "5M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			curse: ["8L16", "7L47", "6L40", "5L40"],
			darkestlariat: ["8M"],
			defensecurl: ["8L1", "7L1", "6L1", "5L1"],
			dig: ["8M"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M", "7T", "6T", "5T"],
			dynamicpunch: ["8L64", "7L35", "6L30", "5L30"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "8L58", "7M", "7L54", "6M", "6L50", "5M", "5L50"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			firepunch: ["8M", "7T", "6T", "5T"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focuspunch: ["8L1", "7T", "7L69", "6T", "6L1", "5L70"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			gyroball: ["8M", "7M", "6M", "5M", "5S0"],
			hammerarm: ["8L52", "7L61", "6L60", "5L60", "5S0"],
			heatcrash: ["8M"],
			heavyslam: ["8M", "8L40", "7L1", "6L43", "5L43"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			highhorsepower: ["8M", "8L1", "7L1"],
			hyperbeam: ["8M", "7M", "6M", "5M", "5S0"],
			icebeam: ["8M", "7M", "6M", "5M"],
			icepunch: ["8M", "7T", "6T", "5T"],
			icywind: ["8M", "7T", "6T", "5T"],
			imprison: ["8M"],
			irondefense: ["8M", "8L28", "7T", "7L17", "6T", "6L17", "5T", "5L17"],
			lowkick: ["8M", "7T", "6T", "5T"],
			lowsweep: ["8M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magnitude: ["7L30", "6L25", "5L25"],
			megakick: ["8M"],
			megapunch: ["8M", "8L32", "7L25", "6L21", "5L21"],
			mudslap: ["8L1", "7L1", "6L1", "5L1"],
			nightshade: ["8L20", "7L40", "6L35", "5L35"],
			phantomforce: ["8M", "8L46", "7L76", "6L1"],
			poltergeist: ["8T"],
			pound: ["8L1", "7L1", "6L1", "5L1"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			rollout: ["7L9", "6L9", "5L9"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "8L36", "7M", "6M", "5M"],
			shadowpunch: ["8L12", "7L13", "6L13", "5L13", "5S0"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "8L24", "7T", "7L21"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			telekinesis: ["7T", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			toxic: ["7M", "6M", "5M"],
			trick: ["8M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 70, shiny: true, abilities: ["ironfist"], moves: ["shadowpunch", "hyperbeam", "gyroball", "hammerarm"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 6, level: 30},
		],
	},
	pawniard: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			airslash: ["9M"],
			assurance: ["9L25", "8M", "8L25", "7L33", "6L33", "5L33"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dualchop: ["7T", "6T", "5T"],
			embargo: ["7M", "7L41", "6M", "6L41", "5M", "5L41"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			falseswipe: ["9M", "8M", "7M", "6M", "5M"],
			feintattack: ["7L17", "6L17", "5L17"],
			flashcannon: ["9M"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["9L5", "8L5", "7L9", "6L9", "5L9", "5D"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			guillotine: ["9L65", "8L65", "7L62", "6L62", "5L62"],
			headbutt: ["9E", "8E", "7E", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			irondefense: ["9M", "9L45", "8M", "8L45", "7T", "7L46", "6T", "6L46", "5T", "5L46"],
			ironhead: ["9M", "9L55", "8M", "8L55", "7T", "7L54", "6T", "6L54", "5T", "5L54"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["8L50"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L6", "6L6", "5L6"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T"],
			meanlook: ["9E", "8E", "7E", "6E", "5E"],
			metalclaw: ["9M", "9L10", "8L10", "7L25", "6L25", "5L25"],
			metalsound: ["9L30", "8L30", "7L38", "6L38", "5L38"],
			nightslash: ["9L40", "8L40", "7L49", "6L49", "5L49"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychocut: ["8M", "7E", "6E", "5E", "5D"],
			pursuit: ["7E", "6E", "5E"],
			quickguard: ["9E", "8E", "7E", "6E"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["9L50", "8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M", "7E", "6E", "5E"],
			rockpolish: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L20", "8M", "8L20", "7L22", "6L22", "5L22"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			slash: ["9L35", "8L35", "7L30", "6L30", "5L30"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			stealthrock: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			steelbeam: ["9M", "8T"],
			stoneedge: ["9M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			suckerpunch: ["9E", "8E", "7E", "6E", "5E", "5D"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["9M", "9L60", "8M", "8L60", "7M", "7L57", "6M", "6L57", "5M", "5L57"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			torment: ["9L15", "8L15", "7M", "7L14", "6M", "6L14", "5M", "5L14"],
			toxic: ["7M", "6M", "5M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M"],
		},
	},
	bisharp: {
		learnset: {
			aerialace: ["9M", "7M", "6M", "5M"],
			airslash: ["9M", "8M"],
			assurance: ["9L25", "8M", "8L25", "7L33", "6L33", "5L33"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dualchop: ["7T", "6T", "5T"],
			embargo: ["7M", "7L41", "6M", "6L41", "5M", "5L41"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			falseswipe: ["9M", "8M", "7M", "6M", "5M"],
			feintattack: ["7L17", "6L17", "5L17"],
			flashcannon: ["9M"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			guillotine: ["9L71", "8L71", "7L1", "6L1", "5L71"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			irondefense: ["9M", "9L45", "8M", "8L45", "7T", "7L46", "6T", "6L46", "5T", "5L46"],
			ironhead: ["9M", "9L57", "8M", "8L57", "7T", "7L1", "6T", "6L1", "5T", "5L57"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["8L50", "7T"],
			lashout: ["8T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			lowkick: ["9M", "8M", "7T", "6T", "5T"],
			lowsweep: ["9M", "8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T"],
			metalburst: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			metalclaw: ["9M", "9L1", "8L1", "7L25", "6L25", "5L25"],
			metalsound: ["9L30", "8L30", "7L38", "6L38", "5L38"],
			nightslash: ["9L40", "8L40", "7L49", "6L49", "5L49"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychocut: ["8M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["9L50", "8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["9M"],
			rockpolish: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M", "8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L20", "8M", "8L20", "7L22", "6L22", "5L22"],
			scratch: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			slash: ["9L35", "8L35", "7L30", "6L30", "5L30"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M", "8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T"],
			steelbeam: ["9M", "8T"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["9M", "9L64", "8M", "8L64", "7M", "7L63", "6M", "6L63", "5M", "5L63"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			throatchop: ["8M", "7T"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			torment: ["9L15", "8L15", "7M", "7L1", "6M", "6L1", "5M", "5L1"],
			toxic: ["7M", "6M", "5M"],
			xscissor: ["9M", "8M", "7M", "6M", "5M"],
		},
		encounters: [
			{generation: 7, level: 33},
		],
	},
	kingambit: {
		learnset: {
			aerialace: ["9M"],
			airslash: ["9M"],
			assurance: ["9L25"],
			brickbreak: ["9M"],
			darkpulse: ["9M"],
			dig: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			flashcannon: ["9M"],
			fling: ["9M"],
			focusblast: ["9M"],
			foulplay: ["9M"],
			furycutter: ["9L1"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			guillotine: ["9L71"],
			hyperbeam: ["9M"],
			irondefense: ["9M", "9L45"],
			ironhead: ["9M", "9L57"],
			kowtowcleave: ["9L0"],
			leer: ["9L1"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			metalburst: ["9L1"],
			metalclaw: ["9M", "9L1"],
			metalsound: ["9L30"],
			nightslash: ["9L40"],
			poisonjab: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			retaliate: ["9L50"],
			reversal: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "9L20"],
			scratch: ["9L1"],
			shadowclaw: ["9M"],
			slash: ["9L35"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			stealthrock: ["9M"],
			steelbeam: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			swordsdance: ["9M", "9L64"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderwave: ["9M"],
			torment: ["9L15"],
			xscissor: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	bouffalant: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			amnesia: ["8M", "7E", "6E", "5E"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			belch: ["8E", "7E", "6E"],
			bodyslam: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			cottonguard: ["8E", "7E"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthquake: ["8M", "7M", "6M", "6S0", "5M"],
			endeavor: ["8E", "7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "6S0", "5M"],
			focusenergy: ["8M", "8L5", "7L36", "6L36", "5L36"],
			frustration: ["7M", "6M", "5M"],
			furyattack: ["8L10", "7L11", "6L11", "5L11"],
			gigaimpact: ["8M", "8L55", "7M", "7L61", "6M", "6L61", "5M", "5L61"],
			headbutt: ["8E", "7E", "6E", "5E"],
			headcharge: ["8L40", "7L31", "6L31", "6S0", "5L31"],
			hiddenpower: ["7M", "6M", "5M"],
			highhorsepower: ["8M"],
			hornattack: ["8L25", "7L16", "6L16", "5L16"],
			ironhead: ["8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			megahorn: ["8M", "8L50", "7L41", "6L41", "5L41"],
			mudshot: ["8M", "7E", "6E", "5E"],
			mudslap: ["8E", "7E", "6E", "5E"],
			outrage: ["8M", "7T", "6T", "5T"],
			payback: ["8M", "7M", "6M", "5M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			pursuit: ["7L1", "6L1", "5L1"],
			rage: ["7L6", "6L6", "5L6"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M", "8L15", "7L26", "6L26", "5L26"],
			reversal: ["8M", "8L30", "7L46", "6L46", "5L46"],
			rockclimb: ["7E", "6E", "5E"],
			rockslide: ["8M", "7M", "6M", "6S0", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "8L20", "7L21", "6L21", "5L21"],
			secretpower: ["6M"],
			skullbash: ["8E", "7E", "6E", "5E"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "6T", "5T"],
			stomp: ["8E", "7E", "6E", "5E"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["8M", "8L45", "7M", "7L56", "6M", "6L56", "5M", "5L56"],
			tackle: ["8L1"],
			taunt: ["8M", "7M", "6M", "5M"],
			thrash: ["7L50", "6L50", "5L51"],
			throatchop: ["8M", "8L35"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 6, level: 50, nature: "Adamant", ivs: {hp: 31, atk: 31}, isHidden: true, moves: ["headcharge", "facade", "earthquake", "rockslide"], pokeball: "cherishball"},
		],
	},
	rufflet: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "9L30", "8L30", "7M", "7L23", "6M", "6L23", "5M", "5L23"],
			agility: ["9M", "8M"],
			aircutter: ["9M"],
			airslash: ["9M", "9L55", "8M", "8L55", "7L41", "6L41", "5L41"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			bodyslam: ["9M"],
			bravebird: ["9M", "9L72", "8M", "8L72", "7L59", "6L59", "5L59"],
			bulkup: ["9M", "8M", "7M", "6M", "5M"],
			closecombat: ["9M", "8M"],
			confide: ["7M", "6M"],
			crushclaw: ["9L48", "8L48", "7L46", "6L46", "5L46"],
			cut: ["6M", "5M"],
			defog: ["9L60", "8L60", "7T", "7L32", "6L32", "5L32"],
			doubleteam: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			fly: ["9M", "8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furyattack: ["7L5", "6L5", "5L5"],
			heatwave: ["9M", "8M", "7T", "6T", "5T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["9L6", "8L6", "7L14", "6M", "6L14", "5M", "5L14"],
			hurricane: ["9M", "8M"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			peck: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			pluck: ["5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocksmash: ["9E", "6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			roost: ["9E", "7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L24", "8M", "8L24", "7L19", "6L19", "5L19"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			skydrop: ["7M", "7L50", "6M", "6L50", "5M", "5L50"],
			slash: ["9L36", "8L36", "7L28", "6L28", "5L28"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M", "8M"],
			tailwind: ["9M", "9L18", "8L18", "7T", "7L37", "6T", "6L37", "5T", "5L37"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L66", "8L66", "7L64", "6L64", "5L64"],
			toxic: ["7M", "6M", "5M"],
			uturn: ["9M", "8M", "7M", "6M", "5M"],
			whirlwind: ["9L42", "8L42", "7L55", "6L55", "5L55"],
			wingattack: ["9L12", "8L12", "7L10", "6L10", "5L10"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	braviary: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "9L30", "8L30", "7M", "7L23", "6M", "6L23", "5M", "5L23", "5S0"],
			agility: ["9M", "8M"],
			aircutter: ["9M"],
			airslash: ["9M", "9L57", "8M", "8L57", "7L41", "6L41", "5L41"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M", "5M"],
			bodyslam: ["9M"],
			bravebird: ["9M", "8M", "8L80", "7L1", "6L1", "5L63"],
			bulkup: ["9M", "8M", "7M", "6M", "5M"],
			closecombat: ["9M", "8M"],
			confide: ["7M", "6M"],
			crushclaw: ["9L48", "8L48", "7L46", "6L46", "5L46"],
			cut: ["6M", "5M"],
			defog: ["9L64", "8L64", "7T", "7L32", "6L32", "5L32"],
			doubleteam: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			fly: ["9M", "8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furyattack: ["7L1", "6L1", "5L1"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			heatwave: ["9M", "8M", "7T", "6T", "5T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["9L1", "8L1", "7L14", "6M", "6L14", "5M", "5L14", "5S0"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			ironhead: ["9M", "8M"],
			laserfocus: ["7T"],
			leer: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			metalclaw: ["9M"],
			peck: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			pluck: ["5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			reversal: ["9M"],
			rockslide: ["9M", "8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L24", "8M", "8L24", "7L19", "6L19", "5L19", "5S0"],
			secretpower: ["6M"],
			shadowclaw: ["9M", "8M", "7M", "6M", "5M"],
			skyattack: ["9L1", "8L1", "7T", "6T", "5T"],
			skydrop: ["7M", "7L50", "6M", "6L50", "5M", "5L50"],
			slash: ["9L36", "8L36", "7L28", "6L28", "5L28"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["9L0", "8M", "8L0", "7T", "7L1", "6T", "6L1", "5T", "5L51"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M", "8M"],
			tailwind: ["9M", "9L18", "8L18", "7T", "7L37", "6T", "6L37", "5T", "5L37"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L72", "8L72", "7L1", "6L1", "5L70"],
			toxic: ["7M", "6M", "5M"],
			uturn: ["9M", "8M", "7M", "6M", "5M"],
			whirlwind: ["9L42", "8L42", "7L1", "6L1", "5L57"],
			wingattack: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S0"],
			workup: ["8M", "7M", "5M"],
			zenheadbutt: ["9M", "8M", "7T", "6T"],
		},
		eventData: [
			{generation: 5, level: 25, gender: "M", isHidden: true, moves: ["wingattack", "honeclaws", "scaryface", "aerialace"]},
		],
		encounters: [
			{generation: 6, level: 45},
		],
	},
	braviaryhisui: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M", "9L30"],
			agility: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M", "9L57"],
			bodyslam: ["9M"],
			bravebird: ["9M"],
			bulkup: ["9M"],
			calmmind: ["9M"],
			closecombat: ["9M"],
			confuseray: ["9M"],
			crushclaw: ["9L48"],
			dazzlinggleam: ["9M"],
			defog: ["9L64"],
			endure: ["9M"],
			esperwing: ["9L0"],
			facade: ["9M"],
			fly: ["9M"],
			gigaimpact: ["9M"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			honeclaws: ["9L1"],
			hurricane: ["9M", "9L80"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icywind: ["9M"],
			leer: ["9L1"],
			metalclaw: ["9M"],
			nightshade: ["9M"],
			peck: ["9L1"],
			protect: ["9M"],
			psybeam: ["9M"],
			psychic: ["9M"],
			psychicterrain: ["9M"],
			psyshock: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			scaryface: ["9M", "9L24"],
			shadowball: ["9M"],
			shadowclaw: ["9M"],
			skyattack: ["9L1"],
			slash: ["9L36"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			superpower: ["9L1"],
			swift: ["9M"],
			tailwind: ["9M", "9L18"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L72"],
			uturn: ["9M"],
			whirlwind: ["9L42"],
			wingattack: ["9L1"],
			zenheadbutt: ["9M"],
		},
	},
	vullaby: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			airslash: ["8M", "8L42", "7L41", "6L41", "5L41"],
			assurance: ["8M"],
			attract: ["8M", "8L66", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			bravebird: ["8M", "8L72", "7L59", "6L59", "5L59"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			darkpulse: ["8M", "8L48", "7M", "7L46", "6M", "6L46", "5T", "5L46"],
			defog: ["8L60", "7T", "7L32", "6L32", "5L32"],
			doubleteam: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			embargo: ["7M", "7L50", "6M", "6L50", "5M", "5L50"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			faketears: ["8M", "7E", "6E", "5E"],
			feintattack: ["7L23", "6L23", "5L23"],
			flatter: ["8L6", "7L19", "6L19", "5L19"],
			fly: ["8M", "7M", "6M", "5M"],
			foulplay: ["8M", "7T", "7E", "6T", "6E", "5T"],
			frustration: ["7M", "6M", "5M"],
			furyattack: ["7L5", "6L5", "5L5"],
			gust: ["8L1", "7L1", "6L1", "5L1"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "8L30", "5T"],
			knockoff: ["8L24", "7T", "7E", "6T", "6E", "5T", "5E"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			meanlook: ["8E", "7E", "6E", "5E"],
			mirrormove: ["7L64", "6L64", "5L64"],
			nastyplot: ["8M", "8L54", "7L14", "6L14", "5L14"],
			payback: ["8M", "7M", "6M", "5M"],
			pluck: ["8L12", "7L10", "6L10", "5M", "5L10"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			punishment: ["7L28", "6L28", "5L28"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["8E", "7M", "7E", "6M", "6E", "5T", "5E"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M", "7E", "6E", "5E"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "7E", "6M", "6E", "5E"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			tailwind: ["8L18", "7T", "7L37", "6T", "6L37", "5T", "5L37"],
			taunt: ["8M", "7M", "6M", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["8E", "7M", "6M", "5M"],
			uturn: ["8M", "7M", "6M", "5M"],
			whirlwind: ["8L36", "7L55", "6L55", "5L55"],
		},
	},
	mandibuzz: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			airslash: ["8M", "8L42", "7L41", "6L41", "5L41"],
			assurance: ["8M"],
			attract: ["8M", "8L72", "7M", "6M", "5M"],
			block: ["7T", "6T", "5T"],
			bonerush: ["8L0", "7L1", "6L1", "5L51"],
			bravebird: ["8M", "8L80", "7L1", "6L1", "5L63"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			darkpulse: ["8M", "8L48", "7M", "7L46", "6M", "6L46", "5T", "5L46"],
			defog: ["8L64", "7T", "7L32", "6L32", "5L32"],
			doubleteam: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			embargo: ["7M", "7L50", "6M", "6L50", "5M", "5L50"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			faketears: ["8M"],
			feintattack: ["7L23", "6L23", "5L23", "5S0"],
			flatter: ["8L1", "7L19", "6L19", "5L19", "5S0"],
			fly: ["8M", "7M", "6M", "5M"],
			foulplay: ["8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			furyattack: ["7L1", "6L1", "5L1"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gust: ["8L1", "7L1", "6L1", "5L1"],
			heatwave: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irondefense: ["8M", "8L30", "7T", "5T"],
			knockoff: ["8L24", "7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			mirrormove: ["7L1", "6L1", "5L70"],
			nastyplot: ["8M", "8L57", "7L14", "6L14", "5L14", "5S0"],
			payback: ["8M", "7M", "6M", "5M"],
			pluck: ["8L1", "7L1", "6L1", "5M", "5L1", "5S0"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			punishment: ["7L28", "6L28", "5L28"],
			raindance: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			skyattack: ["8L1", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snarl: ["8M", "7M", "6M", "5M"],
			snatch: ["7T", "6T", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			tailwind: ["8L18", "7T", "7L37", "6T", "6L37", "5T", "5L37"],
			taunt: ["8M", "7M", "6M", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["8L1", "7M", "6M", "5M"],
			uturn: ["8M", "7M", "6M", "5M"],
			whirlwind: ["8L36", "7L1", "6L1", "5L57"],
		},
		eventData: [
			{generation: 5, level: 25, gender: "F", isHidden: true, moves: ["pluck", "nastyplot", "flatter", "feintattack"]},
		],
	},
	heatmor: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			amnesia: ["8M", "8L45", "7L47", "6L44", "5L46"],
			attract: ["8M", "7M", "6M", "5M"],
			belch: ["8E", "7E", "6E"],
			bind: ["8L30", "7T", "7L11", "6T", "6L11", "5T", "5L11"],
			bodyslam: ["8M", "7E", "6E", "5E"],
			brutalswing: ["8M"],
			bugbite: ["8L15", "7T", "7L36", "6T", "6L36", "5T", "5L36"],
			burningjealousy: ["8T"],
			confide: ["7M", "6M"],
			curse: ["8E", "7E", "6E", "5E"],
			cut: ["6M", "5M"],
			dig: ["8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["8M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			feintattack: ["7E", "6E", "5E"],
			fireblast: ["8M", "7M", "6M", "5M"],
			firelash: ["8L35", "7L44"],
			firepunch: ["8M", "7T", "6T", "5T"],
			firespin: ["8M", "8L50", "7L16", "6L16", "5L16"],
			flameburst: ["7L31", "6L31", "5L31"],
			flamethrower: ["8M", "7M", "7L50", "6M", "6L47", "5M", "5L51"],
			flareblitz: ["8M", "8L60", "7L61"],
			fling: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			furyswipes: ["8L5", "7L21", "6L21", "5L21"],
			gastroacid: ["7T", "6T", "5T"],
			gigadrain: ["8M", "7T", "6T", "5T", "5D"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			heatwave: ["8M", "7T", "7E", "6T", "6E", "5T", "5E", "5D"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["8L40", "7L1", "6M", "6L1", "5M"],
			incinerate: ["8L10", "7L1", "6M", "6L1", "5M", "5L1", "5D"],
			inferno: ["8L55", "7L66", "6L1", "5L61"],
			knockoff: ["7T", "6T", "5T"],
			lick: ["8L1", "7L1", "6L1", "5L1"],
			lowkick: ["8M", "7T", "6T", "5T"],
			nightslash: ["8E", "7E", "6E", "5E"],
			odorsleuth: ["7L6", "6L6", "5L6"],
			overheat: ["8M", "7M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M", "5M"],
			pursuit: ["7E", "6E", "5E"],
			raindance: ["8M", "7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			slash: ["8L25", "7L41", "6L41", "5L41"],
			sleeptalk: ["8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			snatch: ["7T", "7L26", "6T", "6L26", "5T", "5L26"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			spitup: ["8L20", "7L56", "6L50", "5L56"],
			stockpile: ["8L20", "7L56", "6L50", "5L56"],
			stompingtantrum: ["8M", "7T"],
			substitute: ["8M", "7M", "6M", "5M"],
			suckerpunch: ["8E", "7E", "6E", "5E"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			swallow: ["8L20", "7L56", "6L50", "5L56"],
			tackle: ["8L1", "7L1", "6L1"],
			taunt: ["8M", "7M", "6M", "5M"],
			thief: ["8M", "7M", "6M", "5M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			tickle: ["8E", "7E", "6E", "5E"],
			toxic: ["7M", "6M", "5M"],
			willowisp: ["8M", "7M", "6M", "5M"],
			wrap: ["7E", "6E", "5E"],
		},
	},
	durant: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			agility: ["8M", "8L24", "7L6", "6L16", "5L16"],
			attract: ["8M", "7M", "6M", "5M"],
			batonpass: ["8M", "7E", "6E", "5E"],
			beatup: ["8M", "8L12"],
			bite: ["8L20", "7L1", "6L11", "5L11"],
			bugbite: ["8L16", "7T", "7L16", "6T", "6L26", "5T", "5L26"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L36", "7L21", "6L31", "5L31"],
			cut: ["6M", "5M"],
			dig: ["8M", "8L28", "7L31", "6M", "6L41", "5M", "5L41"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M", "7E", "6E", "5E", "5D"],
			energyball: ["8M", "7M", "6M", "5M"],
			entrainment: ["8L48", "7L36", "6L46", "5L46"],
			facade: ["8M", "7M", "6M", "5M"],
			feintattack: ["7E", "6E", "5E"],
			firstimpression: ["8E"],
			flail: ["8E"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["8L1", "7L1", "6L6", "5L6", "5D"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			guillotine: ["8L56", "7L1", "6L1", "5L61"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			infestation: ["8E"],
			irondefense: ["8M", "8L52", "7T", "7L46", "6T", "6L1", "5T", "5L56"],
			ironhead: ["8M", "8L44", "7T", "7L26", "6T", "6L36", "5T", "5L36"],
			metalburst: ["8E"],
			metalclaw: ["8L8", "7L11", "6L21", "5L21"],
			metalsound: ["8L40", "7L1", "6L1", "5L66"],
			protect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockclimb: ["7E", "6E", "5E"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sandattack: ["8L1", "7L1", "6L1", "5L1"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "7E", "6E", "5E"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			strugglebug: ["8E", "6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			thunderfang: ["8M", "7E", "6E", "5E", "5D"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			visegrip: ["8L4", "7L1", "6L1", "5L1"],
			xscissor: ["8M", "8L32", "7M", "7L41", "6M", "6L51", "5M", "5L51"],
		},
	},
	deino: {
		learnset: {
			aquatail: ["7T", "6T", "5T"],
			assurance: ["9L16", "8M", "8L16", "7E", "6E", "5E"],
			astonish: ["9E", "8E", "7E", "6E", "5E"],
			attract: ["8M", "7M", "6M", "5M"],
			belch: ["9E", "8E", "7E"],
			bite: ["9L8", "8L8", "7L9", "6L9", "5L9"],
			bodyslam: ["9M", "9L44", "8M", "8L44", "7L48", "6L48", "5L48"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L32", "8M", "8L32", "7L25", "6L25", "5L25"],
			darkpulse: ["9M", "8M", "7M", "7E", "6M", "6E", "5T", "5E"],
			doublehit: ["9E", "8E", "7E", "6E", "5E"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T"],
			dragonbreath: ["9L4", "8L4", "7L17", "6L17", "5L17"],
			dragonpulse: ["9M", "9L40", "8M", "8L40", "7T", "7L32", "6T", "6L32", "5T", "5L32"],
			dragonrage: ["7L1", "6L1", "5L1", "5S0"],
			dragonrush: ["9L52", "8L52", "7L42", "6L42", "5L42"],
			dragontail: ["9M", "7M", "6M", "5M"],
			earthpower: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			firefang: ["9M", "8M", "7E", "6E", "5E"],
			focusenergy: ["9L1", "8M", "8L1", "7L4", "6L4", "5L4"],
			frustration: ["7M", "6M", "5M"],
			headbutt: ["9L20", "8L20", "7L12", "6L12", "5L12"],
			headsmash: ["9E", "8E", "7E", "6E", "5E"],
			hiddenpower: ["7M", "6M", "5M"],
			hypervoice: ["9M", "9L48", "8M", "8L48", "7T", "7L58", "6T", "6L58", "5T", "5L58"],
			icefang: ["9M", "8M", "7E", "6E", "5E"],
			incinerate: ["6M", "5M"],
			nastyplot: ["9M", "9L56", "8M", "8L56"],
			outrage: ["9M", "9L60", "8M", "8L60", "7T", "7L62", "6T", "6L62", "5T", "5L62"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["9L12", "8L12", "7M", "7L20", "6M", "6L20", "5M", "5L20"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L36", "8M", "8L36", "7L50", "6L50", "5L52"],
			screech: ["8M", "7E", "6E", "5E"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			slam: ["9L28", "8L28", "7L28", "6L28", "5L28"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S0"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thunderfang: ["9M", "8M", "7E", "6E", "5E"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			workup: ["9L24", "8M", "8L24", "7M", "7L38", "6L38", "5M", "5L38"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 1, shiny: true, moves: ["tackle", "dragonrage"], pokeball: "pokeball"},
		],
	},
	zweilous: {
		learnset: {
			aquatail: ["7T", "6T", "5T"],
			assurance: ["9L16", "8M", "8L16"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M"],
			bite: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			bodyslam: ["9M", "9L44", "8M", "8L44", "7L48", "6L48", "5L48"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L32", "8M", "8L32", "7L25", "6L25", "5L25"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T"],
			doublehit: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T"],
			dragonbreath: ["9L1", "8L1", "7L17", "6L17", "5L17"],
			dragonpulse: ["9M", "9L40", "8M", "8L40", "7T", "7L32", "6T", "6L32", "5T", "5L32"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragonrush: ["9L54", "8L54", "7L42", "6L42", "5L42"],
			dragontail: ["9M", "7M", "6M", "5M"],
			earthpower: ["9M", "8M", "7T", "6T", "5T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			firefang: ["9M", "8M"],
			focusenergy: ["9L1", "8M", "8L1", "7L1", "6L1", "5L1"],
			frustration: ["7M", "6M", "5M"],
			headbutt: ["9L20", "8L20", "7L12", "6L12", "5L12"],
			helpinghand: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M"],
			hypervoice: ["9M", "9L48", "8M", "8L48", "7T", "7L64", "6T", "6L64", "5T", "5L64"],
			icefang: ["9M", "8M"],
			incinerate: ["6M", "5M"],
			nastyplot: ["9M", "9L60", "8M", "8L60"],
			outrage: ["9M", "9L66", "8M", "8L66", "7T", "7L71", "6T", "6L71", "5T", "5L71"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["9L12", "8L12", "7M", "7L20", "6M", "6L20", "5M", "5L20"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "9L36", "8M", "8L36", "7L55", "6L55", "5L55"],
			screech: ["8M"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			slam: ["9L28", "8L28", "7L28", "6L28", "5L28"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			stompingtantrum: ["9M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thunderfang: ["9M", "8M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["8M", "7T", "6T", "5T"],
			workup: ["9L24", "8M", "8L24", "7M", "7L38", "6L38", "5M", "5L38"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T"],
		},
		encounters: [
			{generation: 5, level: 49},
		],
	},
	hydreigon: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			aquatail: ["7T", "6T", "5T"],
			assurance: ["9L16", "8M", "8L16"],
			attract: ["8M", "7M", "6M", "5M"],
			beatup: ["8M"],
			bite: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			bodyslam: ["9M", "9L44", "8M", "8L44", "7L48", "6L48", "5L48"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "8M", "7M", "6M", "5M"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L32", "8M", "8L32", "7L25", "6L25", "6S1", "5L25"],
			darkpulse: ["9M", "8M", "7M", "6M", "5T"],
			defog: ["7T"],
			doublehit: ["9L1", "8L1"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["9M", "8T", "7T", "6T", "5T"],
			dragonbreath: ["9L1", "8L1", "7L17", "6L17", "5L17", "5S0"],
			dragondance: ["9M", "8M"],
			dragonpulse: ["9M", "9L40", "8M", "8L40", "7T", "7L32", "6T", "6L32", "5T", "5L32"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragonrush: ["9L54", "8L54", "7L42", "6L42", "6S1", "5L42"],
			dragontail: ["9M", "7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			earthpower: ["9M", "8M", "7T", "6T", "5T"],
			earthquake: ["9M", "8M", "7M", "6M", "5M"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M"],
			firefang: ["9M", "8M"],
			firespin: ["9M", "8M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M", "5S0"],
			flashcannon: ["9M", "8M", "7M", "6M", "5M"],
			fly: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M", "5S0"],
			focusenergy: ["9L1", "8M", "8L1", "7L1", "6L1", "5L1"],
			frustration: ["7M", "6M", "6S1", "5M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			headbutt: ["9L20", "8L20", "7L12", "6L12", "5L12"],
			heatwave: ["9M", "8M", "7T", "6T", "5T"],
			helpinghand: ["9M", "8M"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "9L76", "8M", "8L76", "7M", "6M", "5M"],
			hypervoice: ["9M", "9L48", "8M", "8L48", "7T", "7L1", "6T", "6L1", "5T", "5L68", "5S0"],
			icefang: ["9M", "8M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T"],
			nastyplot: ["9M", "9L60", "8M", "8L60"],
			outrage: ["9M", "9L68", "8M", "8L68", "7T", "7L1", "6T", "6L1", "5T", "5L79"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			raindance: ["9M", "8M", "7M", "6M", "5M"],
			reflect: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roar: ["9L12", "8L12", "7M", "7L20", "6M", "6L20", "5M", "5L20"],
			rockslide: ["9M", "8M", "7M", "6M", "6S1", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["9M", "8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "9L36", "8M", "8L36", "7L55", "6L55", "5L55"],
			screech: ["8M"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			slam: ["9L28", "8L28", "7L28", "6L28", "5L28"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snarl: ["9M", "8M"],
			snore: ["8M", "7T", "6T", "5T"],
			spite: ["7T", "6T", "5T"],
			stealthrock: ["9M"],
			steelwing: ["8M", "7M", "6M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tackle: ["9L1", "8L1"],
			tailwind: ["9M", "7T", "6T", "5T"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			throatchop: ["8M", "7T"],
			thunderfang: ["9M", "8M"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			triattack: ["9L1", "8M", "8L1", "7L1", "6L1", "5L1"],
			uproar: ["8M", "7T", "6T", "5T"],
			uturn: ["9M", "8M", "7M", "6M", "5M"],
			workup: ["9L24", "8M", "8L24", "7M", "7L38", "6L38", "5M", "5L38"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 70, shiny: true, gender: "M", moves: ["hypervoice", "dragonbreath", "flamethrower", "focusblast"], pokeball: "cherishball"},
			{generation: 6, level: 52, gender: "M", perfectIVs: 2, moves: ["dragonrush", "crunch", "rockslide", "frustration"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 6, level: 59},
		],
	},
	larvesta: {
		learnset: {
			absorb: ["9E", "8E", "7L10"],
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			amnesia: ["9M", "9L54", "8M", "8L54", "7L80", "6L80", "5L80"],
			attract: ["8M"],
			bodyslam: ["9M"],
			bugbite: ["9L24", "8L24", "7T", "7L40", "6T", "6L40", "5T", "5L40"],
			bugbuzz: ["9M", "9L42", "8M", "8L42", "7L70", "6L70", "5L70"],
			calmmind: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			doubleedge: ["9L60", "8L60", "7L50", "6L50", "5L50"],
			doubleteam: ["7M", "6M", "5M"],
			ember: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			endure: ["9M", "8M", "7E", "6E", "5E"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			fireblast: ["9M", "8M", "7M", "6M", "5M"],
			firespin: ["9M"],
			flamecharge: ["9M", "9L6", "8L6", "7M", "7L30", "6M", "6L30", "5M", "5L30"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M"],
			flamewheel: ["9L18", "8L18", "7L60", "6L60", "5L60"],
			flareblitz: ["9M", "9L66", "8M", "8L66", "7L100", "6L100", "5L100"],
			foresight: ["7E", "6E", "5E"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["9M", "8M", "7T", "6T", "5T"],
			harden: ["9E", "8E", "7E", "6E", "5E"],
			heatwave: ["9M", "8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			leechlife: ["9M", "9L36", "8M", "8L36", "7M", "6L10", "5L10"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "7E", "6T", "6E", "5T", "5E"],
			morningsun: ["9E", "7E", "6E", "5E"],
			overheat: ["9M", "8M", "7M", "6M", "5M"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychic: ["9M", "8M", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			screech: ["9L30", "8M", "8L30"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T", "5T"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M"],
			stringshot: ["9L1", "8L1", "7L1", "7E", "6L1", "6E", "5L1", "5E"],
			strugglebug: ["9M", "9L12", "8L12", "6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			takedown: ["9M", "9L48", "8L48", "7L20", "6L20", "5L20"],
			terablast: ["9M"],
			thrash: ["9E", "8E", "7L90", "6L90", "5L90"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M", "7M", "6M", "5M"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "7M", "6M", "5M"],
			zenheadbutt: ["9M", "8M", "7T", "7E", "6T", "6E", "5T", "5E"],
		},
	},
	volcarona: {
		learnset: {
			absorb: ["7L1"],
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			amnesia: ["9M", "9L54", "8M", "8L54", "7L1", "6L1"],
			attract: ["8M"],
			bodyslam: ["9M"],
			bugbite: ["9L24", "8L24", "7T", "6T", "5T"],
			bugbuzz: ["9M", "9L42", "8M", "8L42", "7L1", "6L1", "5L70", "5S1"],
			calmmind: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			defog: ["7T"],
			doubleedge: ["9L1", "8L1"],
			doubleteam: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			ember: ["9L1", "8L1", "7L1", "6L1", "5L1"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			fierydance: ["9L1", "8L1", "7L1", "6L1", "5L100"],
			fireblast: ["9M", "9L70", "8M", "8L70", "7M", "6M", "5M"],
			firespin: ["9M", "9L1", "8M", "8L1", "7L30", "6L30", "5L30", "5S0"],
			flamecharge: ["9M", "9L1", "8L1", "7M", "6M", "5M"],
			flamethrower: ["9M", "8M", "7M", "6M", "5M"],
			flamewheel: ["9L18", "8L18", "7L1", "6L1"],
			flareblitz: ["9M", "9L1", "8M", "8L1", "7L1", "6L1"],
			fly: ["9M", "8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["9M", "8M", "7T", "6T", "5T"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			gust: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S0"],
			heatwave: ["9M", "9L48", "8M", "8L48", "7T", "7L1", "6T", "6L1", "5T", "5L60"],
			hiddenpower: ["7M", "6M", "5M"],
			hurricane: ["9M", "9L62", "8M", "8L62", "7L1", "6L1", "5L90"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M", "5S1"],
			incinerate: ["6M", "5M"],
			leechlife: ["9M", "9L36", "8M", "8L36", "7M", "6L1", "5L1", "5S0"],
			lightscreen: ["9M", "8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T"],
			mysticalfire: ["8M"],
			overheat: ["9M", "8M", "7M", "6M", "5M", "5S1"],
			poisonjab: ["9M", "8M", "7M", "6M", "5M"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychic: ["9M", "8M", "7M", "6M", "5M"],
			quiverdance: ["9L0", "8L0", "7L1", "6L1", "5L59", "5S1"],
			ragepowder: ["9L78", "8L78", "7L1", "6L1", "5L80"],
			raindance: ["9M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			screech: ["9L30", "8M", "8L30"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T", "5T"],
			silverwind: ["7L50", "6L50", "5L50"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["9M", "8M", "7M", "6M", "5M"],
			stringshot: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S0"],
			strugglebug: ["9M", "9L1", "8L1", "6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M", "8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			tailwind: ["9M", "7T", "6T", "5T"],
			takedown: ["9M", "9L1", "8L1"],
			terablast: ["9M"],
			thrash: ["7L1", "6L1"],
			toxic: ["7M", "6M", "5M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M", "7M", "6M", "5M"],
			whirlwind: ["9L1", "8L1", "7L40", "6L40", "5L40"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M"],
			willowisp: ["9M", "8M", "7M", "6M", "5M"],
			zenheadbutt: ["9M", "8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 35, moves: ["stringshot", "leechlife", "gust", "firespin"]},
			{generation: 5, level: 77, gender: "M", nature: "Calm", ivs: {hp: 30, atk: 30, def: 30, spa: 30, spd: 30, spe: 30}, moves: ["bugbuzz", "overheat", "hyperbeam", "quiverdance"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 7, level: 41},
		],
	},
	cobalion: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			airslash: ["8M"],
			block: ["7T", "6T", "5T"],
			bounce: ["8M", "7T", "6T", "5T"],
			brickbreak: ["8M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			closecombat: ["8M", "8L70", "8S5", "7L1", "6L1", "5L73"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doublekick: ["8L21", "7L1", "6L7", "5L7"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			falseswipe: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M", "8L1", "7T", "7L13", "6T", "6L25", "5T", "5L25", "5S0", "5S1"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			ironhead: ["8M", "8L63", "8S5", "7T", "7L25", "7S4", "6T", "6L37", "6S3", "5T", "5L37", "5S0", "5S1"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			magnetrise: ["7T", "6T", "5T"],
			megahorn: ["8M"],
			metalburst: ["8L35", "7L1", "6L1", "5L67"],
			metalclaw: ["8L7", "7L1", "6L13", "5L13"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "7S4", "6L1", "5L1"],
			quickguard: ["8L14", "7L42", "6L1", "5L55", "5S2"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "8L28", "7L19", "6M", "6L31", "6S3", "5M", "5L31", "5S0", "5S1"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["7M", "6M", "5M"],
			rockpolish: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sacredsword: ["8L49", "8S5", "7L31", "7S4", "6L42", "6S3", "5L42", "5S0", "5S1", "5S2"],
			safeguard: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smartstrike: ["8M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			steelbeam: ["8T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			swordsdance: ["8M", "8L56", "8S5", "7M", "7L37", "7S4", "6M", "6L49", "6S3", "5M", "5L49", "5S2"],
			takedown: ["8L42", "7L7", "6L19", "5L19"],
			taunt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			workup: ["8M", "8L1", "7M", "7L49", "6L1", "5M", "5L61", "5S2"],
			xscissor: ["8M", "7M", "6M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 42, shiny: 1, moves: ["helpinghand", "retaliate", "ironhead", "sacredsword"]},
			{generation: 5, level: 45, shiny: 1, moves: ["helpinghand", "retaliate", "ironhead", "sacredsword"]},
			{generation: 5, level: 65, shiny: 1, moves: ["sacredsword", "swordsdance", "quickguard", "workup"]},
			{generation: 6, level: 50, shiny: 1, moves: ["retaliate", "ironhead", "sacredsword", "swordsdance"]},
			{generation: 7, level: 60, shiny: 1, moves: ["sacredsword", "swordsdance", "quickattack", "ironhead"]},
			{generation: 8, level: 70, shiny: 1, moves: ["sacredsword", "swordsdance", "ironhead", "closecombat"]},
		],
		eventOnly: true,
	},
	terrakion: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			airslash: ["8M"],
			block: ["7T", "6T", "5T"],
			brickbreak: ["8M"],
			bulldoze: ["8M", "7M", "6M", "5M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			closecombat: ["8M", "8L70", "8S5", "7L1", "6L1", "5L73"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doublekick: ["8L21", "7L1", "6L7", "5L7"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["8M", "7T", "6T", "5T"],
			earthquake: ["8M", "7M", "6M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			falseswipe: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M", "8L1", "7T", "7L13", "6T", "6L25", "5T", "5L25", "5S0", "5S1"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			ironhead: ["8M", "7T", "6T", "5T"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			megahorn: ["8M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1"],
			quickguard: ["8L14", "7L42", "6L1", "5L55", "5S2"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "8L28", "7L19", "6M", "6L31", "6S3", "5M", "5L31", "5S0", "5S1"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["7M", "6M", "5M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["8M", "8L35", "7M", "7L25", "7S4", "6M", "6L37", "6S3", "5M", "5L37", "5S0", "5S1"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sacredsword: ["8L49", "8S5", "7L31", "7S4", "6L42", "6S3", "5L42", "5S0", "5S1", "5S2"],
			safeguard: ["8M", "7M", "6M", "5M"],
			sandstorm: ["8M", "7M", "6M", "5M"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smackdown: ["8L7", "7M", "7L1", "6M", "6L13", "5M", "5L13"],
			smartstrike: ["8M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["8M", "7T", "6T", "5T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "8L63", "8S5", "7M", "7L55", "7S4", "6M", "6L67", "5M", "5L67"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			swordsdance: ["8M", "8L56", "8S5", "7M", "7L37", "7S4", "6M", "6L49", "6S3", "5M", "5L49", "5S2"],
			takedown: ["8L42", "7L7", "6L19", "5L19"],
			taunt: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			workup: ["8M", "8L1", "7M", "7L49", "6L1", "5M", "5L61", "5S2"],
			xscissor: ["8M", "7M", "6M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 42, shiny: 1, moves: ["helpinghand", "retaliate", "rockslide", "sacredsword"]},
			{generation: 5, level: 45, shiny: 1, moves: ["helpinghand", "retaliate", "rockslide", "sacredsword"]},
			{generation: 5, level: 65, shiny: 1, moves: ["sacredsword", "swordsdance", "quickguard", "workup"]},
			{generation: 6, level: 50, shiny: 1, moves: ["retaliate", "rockslide", "sacredsword", "swordsdance"]},
			{generation: 7, level: 60, shiny: 1, moves: ["sacredsword", "swordsdance", "rockslide", "stoneedge"]},
			{generation: 8, level: 70, shiny: 1, moves: ["sacredsword", "swordsdance", "stoneedge", "closecombat"]},
		],
		eventOnly: true,
	},
	virizion: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			airslash: ["8M"],
			block: ["7T", "6T", "5T"],
			bounce: ["8M", "7T", "6T", "5T"],
			brickbreak: ["8M"],
			calmmind: ["8M", "7M", "6M", "5M"],
			closecombat: ["8M", "8L70", "8S5", "7L1", "6L1", "5L73"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doublekick: ["8L21", "7L1", "6L7", "5L7"],
			doubleteam: ["7M", "6M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			facade: ["8M", "7M", "6M", "5M"],
			falseswipe: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigadrain: ["8M", "8L35", "7T", "7L25", "7S4", "6T", "6L37", "6S3", "5T", "5L37", "5S0", "5S1"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			grassknot: ["8M", "7M", "6M", "5M"],
			grassyglide: ["8T"],
			helpinghand: ["8M", "8L1", "7T", "7L13", "6T", "6L25", "5T", "5L25", "5S0", "5S1"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			laserfocus: ["7T"],
			leafblade: ["8M", "8L63", "8S5", "7L1", "7S4", "6L1", "5L67"],
			leafstorm: ["8M"],
			leer: ["8L1", "7L1", "6L1", "5L1"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magicalleaf: ["8M", "8L7", "7L1", "6L13", "5L13"],
			megahorn: ["8M"],
			naturepower: ["7M", "6M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1"],
			quickguard: ["8L14", "7L42", "6L1", "5L55", "5S2"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "8L28", "7L19", "6M", "6L31", "6S3", "5M", "5L31", "5S0", "5S1"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sacredsword: ["8L49", "8S5", "7L31", "7S4", "6L42", "6S3", "5L42", "5S0", "5S1", "5S2"],
			safeguard: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smartstrike: ["8M"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			solarblade: ["8M"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			swordsdance: ["8M", "8L56", "8S5", "7M", "7L37", "7S4", "6M", "6L49", "6S3", "5M", "5L49", "5S2"],
			synthesis: ["7T", "6T", "5T"],
			takedown: ["8L42", "7L7", "6L19", "5L19"],
			taunt: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			workup: ["8M", "8L1", "7M", "7L49", "6L1", "5M", "5L61", "5S2"],
			worryseed: ["7T", "6T", "5T"],
			xscissor: ["8M", "7M", "6M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 42, shiny: 1, moves: ["helpinghand", "retaliate", "gigadrain", "sacredsword"]},
			{generation: 5, level: 45, shiny: 1, moves: ["helpinghand", "retaliate", "gigadrain", "sacredsword"]},
			{generation: 5, level: 65, shiny: 1, moves: ["sacredsword", "swordsdance", "quickguard", "workup"]},
			{generation: 6, level: 50, shiny: 1, moves: ["retaliate", "gigadrain", "sacredsword", "swordsdance"]},
			{generation: 7, level: 60, shiny: 1, moves: ["sacredsword", "swordsdance", "gigadrain", "leafblade"]},
			{generation: 8, level: 70, shiny: 1, moves: ["sacredsword", "swordsdance", "leafblade", "closecombat"]},
		],
		eventOnly: true,
	},
	tornadus: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M", "5M"],
			aerialace: ["7M", "6M", "5M"],
			agility: ["9M", "9L25", "8M", "8L25", "8S7", "7L31", "6L37", "6S3", "5L37", "5S0"],
			aircutter: ["9M", "9L20", "8L20", "7L19", "6L25", "5L25", "5S0"],
			airslash: ["9M", "9L35", "8M", "8L35", "7L37", "7S4", "7S5", "6L43", "6S3", "5L43", "5S2"],
			assurance: ["8M"],
			astonish: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S1"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["9L15", "8L15", "7L7", "6L13", "5L13"],
			bleakwindstorm: ["9L77"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L40", "8M", "8L40", "7L43", "7S4", "7S5", "6L49", "6S3", "5L49"],
			darkpulse: ["9M", "8M", "7M", "7L67", "6M", "6L73", "5T", "5L73"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			embargo: ["7M", "6M", "5M"],
			endure: ["9M", "8M"],
			extrasensory: ["9L45", "8L45", "7L25", "6L31", "6S3", "5L31", "5S0"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			fly: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "7M", "6M", "5M"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "7S6", "6M", "5M"],
			gust: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S1"],
			hammerarm: ["9L55", "8L55", "7L1", "6L1", "5L79", "5S2"],
			heatwave: ["9M", "8M", "8S7", "7T", "7S6", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M", "5S2"],
			hurricane: ["9M", "9L65", "8M", "8L65", "8S7", "7L1", "7S6", "6L1", "5L67", "5S2"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			icywind: ["9M", "8M", "8S7", "7T", "6T", "5T"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["9L5", "8L5"],
			metronome: ["9M"],
			nastyplot: ["9M", "8M"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychic: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "9L60", "8M", "8L60", "7M", "7L55", "7S4", "7S5", "6M", "6L61", "5M", "5L61"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M", "7L13", "6L19", "5L19", "5S0"],
			reversal: ["9M"],
			rocksmash: ["6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "8M"],
			secretpower: ["6M"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			snowscape: ["9M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["9L10", "8L10", "7M", "7L1", "6M", "6L7", "5M", "5L7"],
			tailwind: ["9M", "9L30", "8L30", "7T", "7L49", "7S4", "7S5", "7S6", "6T", "6L1", "5T", "5L55"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thrash: ["9L70", "8L70", "7L1", "6L1", "5L85"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["9L50", "8M", "8L50", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S1"],
			uturn: ["9M", "8M", "7M", "6M", "5M"],
			weatherball: ["8M"],
		},
		eventData: [
			{generation: 5, level: 40, shiny: 1, moves: ["revenge", "aircutter", "extrasensory", "agility"]},
			{generation: 5, level: 5, isHidden: true, moves: ["uproar", "astonish", "gust"], pokeball: "dreamball"},
			{generation: 5, level: 70, moves: ["hurricane", "hammerarm", "airslash", "hiddenpower"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["extrasensory", "agility", "airslash", "crunch"]},
			{generation: 7, level: 60, shiny: 1, moves: ["airslash", "crunch", "tailwind", "raindance"]},
			{generation: 7, level: 60, moves: ["airslash", "crunch", "tailwind", "raindance"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["hurricane", "heatwave", "grassknot", "tailwind"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["hurricane", "agility", "icywind", "heatwave"]},
		],
		eventOnly: true,
	},
	tornadustherian: {
		eventOnly: true,
	},
	thundurus: {
		learnset: {
			acrobatics: ["9M"],
			agility: ["9M", "9L25", "8M", "8L25", "7L31", "6L37", "6S3", "5L37", "5S0"],
			assurance: ["8M"],
			astonish: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S1"],
			attract: ["8M", "7M", "6M", "5M"],
			bite: ["9L15", "8L15", "7L7", "6L13", "5L13"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M"],
			charge: ["9L30", "8L30", "7L49", "7S4", "7S5", "6L1", "5L55"],
			chargebeam: ["9M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L40", "8M", "8L40", "7L43", "7S4", "7S5", "6L49", "6S3", "5L49"],
			darkpulse: ["9M", "8M", "7M", "7L67", "6M", "6L73", "5T", "5L73"],
			defog: ["7T"],
			discharge: ["9L45", "8L45", "7L37", "7S4", "7S5", "6L43", "6S3", "5L43"],
			doubleteam: ["7M", "6M", "5M"],
			eerieimpulse: ["9M", "8M"],
			electricterrain: ["9M"],
			electroball: ["9M", "8M"],
			electroweb: ["8M", "7T", "6T"],
			embargo: ["7M", "6M", "5M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			flashcannon: ["9M", "8M", "7M", "6M", "5M"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			fly: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "7M", "7S6", "6M", "5M", "5S2"],
			foulplay: ["9M", "8M", "7T", "6T", "5T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "7S6", "6M", "5M"],
			hammerarm: ["9L55", "8L55", "7L1", "6L1", "5L79", "5S2"],
			healblock: ["7L25", "6L31", "6S3", "5L31", "5S0"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			lashout: ["8T"],
			leer: ["9L5", "8L5"],
			nastyplot: ["9M", "8M", "7L1", "7S4", "7S5", "7S6", "6L1", "5L61"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychic: ["9M", "8M", "7M", "6M", "5M"],
			raindance: ["9M", "9L60", "8M", "8L60", "8S7", "7M", "6M", "5M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M", "7L13", "6L19", "5L19", "5S0"],
			risingvoltage: ["8T"],
			rocksmash: ["6M", "5M"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			scaryface: ["9M", "8M"],
			secretpower: ["6M"],
			shockwave: ["9L20", "8L20", "7T", "7L19", "6T", "6L25", "5L25", "5S0"],
			skydrop: ["7M", "6M", "5M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "8S7", "7M", "6M", "5M"],
			smackdown: ["7M", "6M", "5M"],
			smartstrike: ["9M", "8M", "7M"],
			snarl: ["9M"],
			snore: ["8M", "7T", "6T", "5T"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["9L10", "8L10", "7M", "7L1", "6M", "6L7", "5M", "5L7"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M", "5M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M", "5M"],
			thrash: ["9L70", "8L70", "7L1", "6L1", "5L85"],
			thunder: ["9M", "9L65", "8M", "8L65", "8S7", "7M", "7L61", "6M", "6L67", "5M", "5L67", "5S2"],
			thunderbolt: ["9M", "8M", "7M", "7S6", "6M", "5M"],
			thunderpunch: ["9M", "8M", "7T", "6T", "5T"],
			thundershock: ["9L1", "8L1", "7L1", "6L1", "5L1", "5S1"],
			thunderwave: ["9M", "8M", "7M", "6M", "5M"],
			torment: ["7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			uproar: ["9L50", "8M", "8L50", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S1"],
			uturn: ["9M", "8M", "7M", "6M", "5M"],
			voltswitch: ["9M", "9L35", "8M", "8L35", "7M", "6M", "5M"],
			weatherball: ["8M", "8S7"],
			wildboltstorm: ["9L75"],
			wildcharge: ["9M", "8M", "7M", "6M", "5M", "5S2"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 5, level: 40, shiny: 1, moves: ["revenge", "shockwave", "healblock", "agility"]},
			{generation: 5, level: 5, isHidden: true, moves: ["uproar", "astonish", "thundershock"], pokeball: "dreamball"},
			{generation: 5, level: 70, moves: ["thunder", "hammerarm", "focusblast", "wildcharge"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["healblock", "agility", "discharge", "crunch"]},
			{generation: 7, level: 60, shiny: 1, moves: ["discharge", "crunch", "charge", "nastyplot"]},
			{generation: 7, level: 60, moves: ["discharge", "crunch", "charge", "nastyplot"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["thunderbolt", "focusblast", "grassknot", "nastyplot"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["thunder", "raindance", "weatherball", "sludgewave"]},
		],
		eventOnly: true,
	},
	thundurustherian: {
		eventOnly: true,
	},
	reshiram: {
		learnset: {
			ancientpower: ["8L1", "7L15", "6L15", "5L15"],
			blueflare: ["8L88", "7L100", "7S6", "6L100", "5L100", "5S2"],
			bodypress: ["8M"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L16", "7L71", "6L71", "5L71"],
			cut: ["6M", "5M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["8T", "7T", "7S6", "6T", "5T", "5S2"],
			dragonbreath: ["8L1", "7L29", "6L29", "6S3", "5L29", "5S0"],
			dragonclaw: ["8M", "8S7", "7M", "6M", "5M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8L32", "7T", "7L54", "7S4", "7S5", "6T", "6L54", "5T", "5L54", "5S1"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragontail: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "7S6", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			endure: ["8M"],
			extrasensory: ["8L24", "8S7", "7L43", "7S4", "7S5", "6L43", "6S3", "5L43", "5S0", "5S1"],
			facade: ["8M", "7M", "6M", "5M"],
			fireblast: ["8M", "8L64", "7M", "7L78", "6M", "6L78", "5M", "5L78"],
			firefang: ["8M", "8L1", "7L1", "6L1", "5L1"],
			flamecharge: ["7M", "6M", "5M"],
			flamethrower: ["8M", "8L40", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			flareblitz: ["8M"],
			fling: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			fusionflare: ["8L48", "8S7", "7L50", "7S4", "7S5", "7S6", "6L50", "6S3", "5L50", "5S0", "5S1", "5S2"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			heatcrash: ["8M"],
			heatwave: ["8M", "7T", "6T", "5T"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "8L56", "7T", "7L92", "6T", "6L92", "5T", "5L92"],
			imprison: ["8M", "8L72", "7L64", "6L8", "5L8", "5S1"],
			incinerate: ["6M", "5M"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			mist: ["5S2"],
			mysticalfire: ["8M"],
			nobleroar: ["8L1", "8S7", "7L64"],
			outrage: ["8M", "8L80", "7T", "7L85", "6T", "6L85", "5T", "5L85"],
			overheat: ["8M", "7M", "6M", "5M"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			raindance: ["8M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			slash: ["8L8", "7L36", "7S4", "7S5", "6L36", "6S3", "5L36", "5S0"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "5M"],
			steelwing: ["8M", "7M", "6M"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			tailwind: ["7T", "6T", "5T"],
			toxic: ["7M", "6M", "5M"],
			weatherball: ["8M"],
			willowisp: ["8M", "7M", "6M", "5M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 50, moves: ["dragonbreath", "slash", "extrasensory", "fusionflare"]},
			{generation: 5, level: 70, moves: ["extrasensory", "fusionflare", "dragonpulse", "imprison"]},
			{generation: 5, level: 100, moves: ["blueflare", "fusionflare", "mist", "dracometeor"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["dragonbreath", "slash", "extrasensory", "fusionflare"]},
			{generation: 7, level: 60, shiny: 1, moves: ["slash", "extrasensory", "fusionflare", "dragonpulse"]},
			{generation: 7, level: 60, moves: ["slash", "extrasensory", "fusionflare", "dragonpulse"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["fusionflare", "blueflare", "dracometeor", "earthpower"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["nobleroar", "extrasensory", "fusionflare", "dragonclaw"]},
		],
		eventOnly: true,
	},
	zekrom: {
		learnset: {
			ancientpower: ["8L1", "7L15", "6L15", "5L15"],
			bodypress: ["8M"],
			boltstrike: ["8L88", "7L100", "7S6", "6L100", "5L100", "5S2"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L16", "7L71", "6L71", "5L71"],
			cut: ["6M", "5M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["8T", "7T", "6T", "5T"],
			dragonbreath: ["8L1", "7L29", "6L29", "6S3", "5L29", "5S0"],
			dragonclaw: ["8M", "8L32", "8S7", "7M", "7L54", "7S4", "7S5", "6M", "6L54", "5M", "5L54", "5S1"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T", "6T", "5T"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragontail: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			electroball: ["8M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			fusionbolt: ["8L48", "8S7", "7L50", "7S4", "7S5", "7S6", "6L50", "6S3", "5L50", "5S0", "5S1", "5S2"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			haze: ["5S2"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "8L56", "7T", "7L92", "6T", "6L92", "5T", "5L92"],
			imprison: ["8M", "8L72", "7L64", "6L8", "5L8", "5S1"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			magnetrise: ["7T", "6T", "5T"],
			nobleroar: ["8L1", "8S7", "7L64"],
			outrage: ["8M", "8L80", "7T", "7L85", "7S6", "6T", "6L85", "5T", "5L85", "5S2"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			risingvoltage: ["8T"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "5M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			slash: ["8L8", "8S7", "7L36", "7S4", "7S5", "6L36", "6S3", "5L36", "5S0"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			stoneedge: ["8M", "7M", "7S6", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			tailwind: ["7T", "6T", "5T"],
			thunder: ["8M", "8L64", "7M", "7L78", "6M", "6L78", "5M", "5L78"],
			thunderbolt: ["8M", "8L40", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			thunderfang: ["8M", "8L1", "7L1", "6L1", "5L1"],
			thunderpunch: ["8M", "7T", "6T", "5T"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			voltswitch: ["8M", "7M", "6M", "5M"],
			weatherball: ["8M"],
			wildcharge: ["8M", "7M", "6M", "5M"],
			zenheadbutt: ["8M", "8L24", "7T", "7L43", "7S4", "7S5", "6T", "6L43", "6S3", "5T", "5L43", "5S0", "5S1"],
		},
		eventData: [
			{generation: 5, level: 50, moves: ["dragonbreath", "slash", "zenheadbutt", "fusionbolt"]},
			{generation: 5, level: 70, moves: ["zenheadbutt", "fusionbolt", "dragonclaw", "imprison"]},
			{generation: 5, level: 100, moves: ["boltstrike", "fusionbolt", "haze", "outrage"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: 1, moves: ["dragonbreath", "slash", "zenheadbutt", "fusionbolt"]},
			{generation: 7, level: 60, shiny: 1, moves: ["slash", "zenheadbutt", "fusionbolt", "dragonclaw"]},
			{generation: 7, level: 60, moves: ["slash", "zenheadbutt", "fusionbolt", "dragonclaw"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["fusionbolt", "boltstrike", "outrage", "stoneedge"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["nobleroar", "slash", "fusionbolt", "dragonclaw"]},
		],
		eventOnly: true,
	},
	landorus: {
		learnset: {
			attract: ["8M", "7M", "6M", "5M"],
			block: ["9L10", "8L10", "7T", "7L1", "6T", "6L1", "5T", "5L1", "5S1"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M", "7M", "6M", "5M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["9M", "8M", "7M", "6M", "5M"],
			bulldoze: ["9M", "9L15", "8M", "8L15", "8S5", "7M", "7L13", "6M", "6L19", "5M", "5L19"],
			calmmind: ["9M", "8M", "7M", "6M", "5M"],
			confide: ["7M", "6M"],
			crunch: ["9M"],
			defog: ["7T"],
			dig: ["9M", "8M", "6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			earthpower: ["9M", "9L40", "8M", "8L40", "7T", "7L37", "7S4", "6T", "6L43", "6S2", "5T", "5L43"],
			earthquake: ["9M", "9L65", "8M", "8L65", "7M", "7L49", "7S4", "6M", "6L55", "6S3", "5M", "5L55", "5S0"],
			endure: ["9M", "8M"],
			explosion: ["7M", "6M", "5M"],
			extrasensory: ["9L45", "8L45", "7L25", "6L31", "6S2", "5L31"],
			facade: ["9M", "8M", "7M", "6M", "5M"],
			fissure: ["9L75", "8L75", "7L1", "6L1", "5L67", "5S0"],
			fling: ["9M", "8M", "7M", "6M", "5M"],
			fly: ["9M", "8M", "7M", "6M", "5M"],
			focusblast: ["9M", "8M", "8S5", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M", "8M", "7M", "6M", "5M"],
			grassknot: ["9M", "8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			hammerarm: ["9L55", "8L55", "7L1", "6L1", "5L79"],
			hiddenpower: ["7M", "6M", "5M"],
			hyperbeam: ["9M", "8M", "7M", "6M", "5M"],
			imprison: ["9M", "9L30", "8M", "8L30", "7L1", "6L7", "5L7"],
			irontail: ["8M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "6S3", "5T"],
			leer: ["9L5", "8L5"],
			mudshot: ["9M", "8M", "7L1", "6L1", "5L1", "5S1"],
			mudslap: ["9M"],
			nastyplot: ["9M"],
			outrage: ["9M", "9L70", "8M", "8L70", "7T", "7L1", "6T", "6L1", "5T", "5L85"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["9M", "8M", "7M", "6M", "5M"],
			psychic: ["9M", "8M", "7M", "6M", "5M"],
			punishment: ["7L7", "6L13", "5L13"],
			raindance: ["9M"],
			rest: ["9M", "8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockpolish: ["7M", "6M", "5M"],
			rockslide: ["9M", "9L35", "8M", "8L35", "8S5", "7M", "7L43", "7S4", "6M", "6L49", "6S2", "5M", "5L49", "5S0"],
			rocksmash: ["6M", "5M"],
			rockthrow: ["8L1", "7L19", "6L25", "5L25"],
			rocktomb: ["9M", "9L20", "8M", "8L20", "7M", "7L1", "6M", "6L1", "6S3", "5M", "5L1", "5S1"],
			roleplay: ["7T", "6T", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			sandsearstorm: ["9L80"],
			sandstorm: ["9M", "9L60", "8M", "8L60", "7M", "7L55", "7S4", "6M", "6L61", "5M", "5L61", "5S0"],
			sandtomb: ["9L1", "8M", "8L1", "8S5"],
			scaryface: ["9M", "8M"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M", "7M", "6M", "5T"],
			sludgebomb: ["9M", "8M", "7M", "6M", "5M"],
			sludgewave: ["8M", "7M", "6M", "5M"],
			smackdown: ["9L1", "7M", "6M", "5M"],
			snore: ["8M", "7T", "6T", "5T"],
			stealthrock: ["9M", "8M", "7T", "6T", "5T"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "9L50", "8M", "8L50", "7M", "7L67", "6M", "6L73", "5M", "5L73"],
			strength: ["6M", "5M"],
			substitute: ["9M", "8M", "7M", "6M", "5M"],
			sunnyday: ["9M"],
			superpower: ["8M", "7T", "6T", "5T"],
			swagger: ["7M", "6M", "5M"],
			swordsdance: ["9M", "8M", "8L25", "7M", "7L31", "6M", "6L37", "6S2", "5M", "5L37"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			toxic: ["7M", "6M", "5M"],
			uturn: ["9M", "8M", "7M", "6M", "6S3", "5M"],
			weatherball: ["8M"],
		},
		eventData: [
			{generation: 5, level: 70, shiny: 1, moves: ["rockslide", "earthquake", "sandstorm", "fissure"]},
			{generation: 5, level: 5, isHidden: true, moves: ["block", "mudshot", "rocktomb"], pokeball: "dreamball"},
			{generation: 6, level: 65, shiny: 1, moves: ["extrasensory", "swordsdance", "earthpower", "rockslide"]},
			{generation: 6, level: 50, nature: "Adamant", ivs: {hp: 31, atk: 31, def: 31, spa: 1, spd: 31, spe: 24}, moves: ["earthquake", "knockoff", "uturn", "rocktomb"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["earthpower", "rockslide", "earthquake", "sandstorm"]},
			{generation: 8, level: 70, shiny: 1, moves: ["sandtomb", "rockslide", "bulldoze", "focusblast"]},
		],
		eventOnly: true,
	},
	landorustherian: {
		eventOnly: true,
	},
	kyurem: {
		learnset: {
			ancientpower: ["8L1", "7L15", "6L15", "5L15"],
			blizzard: ["8M", "8L56", "7M", "7L78", "6M", "6L78", "5M", "5L78"],
			bodypress: ["8M"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["8T", "7T", "6T", "6S3", "5T"],
			dragonbreath: ["8L1", "7L29", "6L29", "6S2", "5L29"],
			dragonclaw: ["8M", "7M", "6M", "5M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8L24", "7T", "7L57", "7S4", "6T", "6L57", "5T", "5L57", "5S0", "5S1"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragontail: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["8L16", "7T", "7L71", "6T", "6L71", "5T", "5L71", "5S0"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			freezedry: ["8L1"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			glaciate: ["8L80", "7L50", "7S4", "6L50", "6S2", "6S3", "5L50", "5S0", "5S1"],
			hail: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "8L40", "8S5", "7T", "7L92", "6T", "6L92", "5T", "5L92"],
			icebeam: ["8M", "8L32", "8S5", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			iciclespear: ["8M"],
			icywind: ["8M", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			imprison: ["8M", "8L64", "7L64", "6L8", "5L8", "5S0", "5S1"],
			ironhead: ["8M", "7T", "6T", "6S3", "5T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			nobleroar: ["8L1", "7L64"],
			outrage: ["8M", "8L72", "7T", "7L85", "6T", "6L85", "5T", "5L85"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L48", "8S5", "7L43", "7S4", "6L43", "6S2", "6S3", "5L43", "5S1"],
			secretpower: ["6M"],
			shadowball: ["8M", "8S5", "7M", "6M", "5M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			sheercold: ["8L88"],
			signalbeam: ["7T", "6T", "5T"],
			slash: ["8L8", "7L36", "7S4", "6L36", "6S2", "5L36"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			toxic: ["7M", "6M", "5M"],
			weatherball: ["8M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 75, shiny: 1, moves: ["glaciate", "dragonpulse", "imprison", "endeavor"]},
			{generation: 5, level: 70, shiny: 1, moves: ["scaryface", "glaciate", "dragonpulse", "imprison"]},
			{generation: 6, level: 50, shiny: 1, moves: ["dragonbreath", "slash", "scaryface", "glaciate"]},
			{generation: 6, level: 100, moves: ["glaciate", "scaryface", "dracometeor", "ironhead"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["slash", "scaryface", "glaciate", "dragonpulse"]},
			{generation: 8, level: 70, shiny: 1, moves: ["icebeam", "hypervoice", "shadowball", "scaryface"]},
		],
		eventOnly: true,
	},
	kyuremblack: {
		learnset: {
			ancientpower: ["8L1", "7L15", "6L15", "5L15"],
			blizzard: ["8M", "8L56", "7M", "7L78", "6M", "6L78", "5M", "5L78"],
			bodypress: ["8M"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["8T", "7T", "6T", "6S3", "5T"],
			dragonbreath: ["8L1", "7L29", "6L29", "6S2", "5L29"],
			dragonclaw: ["8M", "7M", "6M", "5M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8L24", "7T", "7L57", "7S4", "6T", "6L57", "5T", "5L57", "5S0", "5S1"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragontail: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["8L16", "7T", "7L71", "6T", "6L71", "5T", "5L71", "5S0"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			freezedry: ["8L1"],
			freezeshock: ["8L80", "7L50", "7S4", "6L50", "6S2", "6S3", "5L43", "5S0", "5S1"],
			frustration: ["7M", "6M", "5M"],
			fusionbolt: ["8L48", "8S5", "7L43", "7S4", "6L43", "6S2", "6S3", "5L50", "5S1"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			hail: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "8L40", "8S5", "7T", "7L92", "6T", "6L92", "5T", "5L92"],
			icebeam: ["8M", "8L32", "8S5", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			iciclespear: ["8M"],
			icywind: ["8M", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			imprison: ["8M", "8L64", "7L64", "6L8", "5L8", "5S0", "5S1"],
			ironhead: ["8M", "7T", "6T", "6S3", "5T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			nobleroar: ["8L1", "7L64"],
			outrage: ["8M", "8L72", "7T", "7L85", "6T", "6L85", "5T", "5L85"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			shadowball: ["8M", "8S5", "7M", "6M", "5M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			sheercold: ["8L88"],
			signalbeam: ["7T", "6T", "5T"],
			slash: ["8L8", "7L36", "7S4", "6L36", "6S2", "5L36"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			toxic: ["7M", "6M", "5M"],
			weatherball: ["8M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 75, shiny: 1, moves: ["freezeshock", "dragonpulse", "imprison", "endeavor"]},
			{generation: 5, level: 70, shiny: 1, moves: ["fusionbolt", "freezeshock", "dragonpulse", "imprison"]},
			{generation: 6, level: 50, shiny: 1, moves: ["dragonbreath", "slash", "fusionbolt", "freezeshock"]},
			{generation: 6, level: 100, moves: ["freezeshock", "fusionbolt", "dracometeor", "ironhead"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["slash", "fusionbolt", "freezeshock", "dragonpulse"]},
			{generation: 8, level: 70, shiny: 1, moves: ["icebeam", "hypervoice", "shadowball", "fusionbolt"]},
		],
		eventOnly: true,
	},
	kyuremwhite: {
		learnset: {
			ancientpower: ["8L1", "7L15", "6L15", "5L15"],
			blizzard: ["8M", "8L56", "7M", "7L78", "6M", "6L78", "5M", "5L78"],
			bodypress: ["8M"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			confide: ["7M", "6M"],
			cut: ["6M", "5M"],
			doubleteam: ["7M", "6M", "5M"],
			dracometeor: ["8T", "7T", "6T", "6S3", "5T"],
			dragonbreath: ["8L1", "7L29", "6L29", "6S2", "5L29"],
			dragonclaw: ["8M", "7M", "6M", "5M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8L24", "7T", "7L57", "7S4", "6T", "6L57", "5T", "5L57", "5S0", "5S1"],
			dragonrage: ["7L1", "6L1", "5L1"],
			dragontail: ["7M", "6M", "5M"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "7T", "6T", "5T"],
			echoedvoice: ["7M", "6M", "5M"],
			endeavor: ["8L16", "7T", "7L71", "6T", "6L71", "5T", "5L71", "5S0"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fling: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			focusblast: ["8M", "7M", "6M", "5M"],
			freezedry: ["8L1"],
			frustration: ["7M", "6M", "5M"],
			fusionflare: ["8L48", "8S5", "7L43", "7S4", "6L43", "6S2", "6S3", "5L50", "5S1"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			hail: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			hypervoice: ["8M", "8L40", "8S5", "7T", "7L92", "6T", "6L92", "5T", "5L92"],
			icebeam: ["8M", "8L32", "8S5", "7M", "7L22", "6M", "6L22", "5M", "5L22"],
			iceburn: ["8L80", "7L50", "7S4", "6L50", "6S2", "6S3", "5L43", "5S0", "5S1"],
			iciclespear: ["8M"],
			icywind: ["8M", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			imprison: ["8M", "8L64", "7L64", "6L8", "5L8", "5S0", "5S1"],
			ironhead: ["8M", "7T", "6T", "6S3", "5T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			nobleroar: ["8L1", "7L64"],
			outrage: ["8M", "8L72", "7T", "7L85", "6T", "6L85", "5T", "5L85"],
			payback: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockslide: ["8M", "7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			rocktomb: ["8M", "7M", "6M", "5M"],
			roost: ["7M", "6M", "5T"],
			round: ["8M", "7M", "6M", "5M"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			shadowball: ["8M", "8S5", "7M", "6M", "5M"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			sheercold: ["8L88"],
			signalbeam: ["7T", "6T", "5T"],
			slash: ["8L8", "7L36", "7S4", "6L36", "6S2", "5L36"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			steelwing: ["8M", "7M", "6M"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			toxic: ["7M", "6M", "5M"],
			weatherball: ["8M"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 75, shiny: 1, moves: ["iceburn", "dragonpulse", "imprison", "endeavor"]},
			{generation: 5, level: 70, shiny: 1, moves: ["fusionflare", "iceburn", "dragonpulse", "imprison"]},
			{generation: 6, level: 50, shiny: 1, moves: ["dragonbreath", "slash", "fusionflare", "iceburn"]},
			{generation: 6, level: 100, moves: ["iceburn", "fusionflare", "dracometeor", "ironhead"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["slash", "fusionflare", "iceburn", "dragonpulse"]},
			{generation: 8, level: 70, shiny: 1, moves: ["icebeam", "hypervoice", "shadowball", "fusionflare"]},
		],
		eventOnly: true,
	},
	keldeo: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			airslash: ["8M"],
			aquajet: ["8L1", "7L1", "6L1", "6S2", "6S3", "5L1", "5S0", "5S1"],
			aquatail: ["8L35", "7T", "7L37", "6T", "6L37", "5T", "5L37"],
			aurasphere: ["8M"],
			bounce: ["8M", "7T", "6T", "5T"],
			brickbreak: ["8M"],
			bubblebeam: ["8L7", "7L1", "6L13", "6S3", "5L13", "5S0"],
			calmmind: ["8M", "7M", "6M", "5M"],
			closecombat: ["8M", "8L70", "7L73", "6L73", "5L73"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T", "5T"],
			cut: ["6M", "5M"],
			doublekick: ["8L21", "7L1", "6L7", "6S2", "6S3", "5L7", "5S0"],
			doubleteam: ["7M", "6M", "5M"],
			endeavor: ["7T", "6T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M", "5M"],
			falseswipe: ["8M", "7M", "6M", "5M"],
			flipturn: ["8T"],
			focusblast: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			hail: ["8M", "7M", "6M", "5M"],
			helpinghand: ["8M", "8L1", "7T", "7L25", "6T", "6L25", "5T", "5L25"],
			hiddenpower: ["7M", "6M", "5M"],
			hydropump: ["8M", "8L63", "8S4", "7L67", "6L67", "6S2", "5L67", "5S1"],
			hyperbeam: ["8M", "7M", "6M", "5M"],
			icywind: ["8M", "7T", "6T", "5T"],
			lastresort: ["7T", "6T", "5T"],
			leer: ["8L1", "7L1", "6L1", "6S2", "6S3", "5L1", "5S0"],
			liquidation: ["8M", "7T"],
			lowkick: ["8M", "7T"],
			megahorn: ["8M"],
			muddywater: ["8M"],
			poisonjab: ["8M", "7M", "6M", "5M"],
			protect: ["8M", "7M", "6M", "5M"],
			psychup: ["7M", "6M", "5M"],
			quickguard: ["8L14", "7L55", "6L55", "5L55"],
			raindance: ["8M", "7M", "6M", "5M"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			retaliate: ["8M", "8L28", "7L31", "6M", "6L31", "5M", "5L31"],
			return: ["7M", "6M", "5M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["7M", "6M", "5M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			sacredsword: ["8L49", "8S4", "7L43", "6L43", "5L43", "5S1"],
			safeguard: ["8M", "7M", "6M", "5M"],
			scald: ["8M", "7M", "6M", "5M"],
			secretpower: ["6M"],
			secretsword: ["8L1", "8S4", "7T", "6T", "5T"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			smartstrike: ["8M"],
			snore: ["8M", "7T", "6T", "5T"],
			stoneedge: ["8M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			sunnyday: ["8M"],
			superpower: ["8M", "7T", "6T", "5T"],
			surf: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			swordsdance: ["8M", "8L56", "8S4", "7M", "7L49", "6M", "6L49", "5M", "5L49", "5S1"],
			takedown: ["8L42", "7L19", "6L19", "5L19"],
			taunt: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			waterpulse: ["7T", "6T"],
			workup: ["8M", "8L1", "7M", "7L61", "6L61", "5M", "5L61"],
			xscissor: ["8M", "7M", "6M", "5M"],
		},
		eventData: [
			{generation: 5, level: 15, moves: ["aquajet", "leer", "doublekick", "bubblebeam"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["sacredsword", "hydropump", "aquajet", "swordsdance"], pokeball: "cherishball"},
			{generation: 6, level: 15, moves: ["aquajet", "leer", "doublekick", "hydropump"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["aquajet", "leer", "doublekick", "bubblebeam"], pokeball: "cherishball"},
			{generation: 8, level: 65, moves: ["secretsword", "sacredsword", "swordsdance", "hydropump"]},
		],
		eventOnly: true,
	},
	keldeoresolute: {
		eventOnly: true,
	},
	meloetta: {
		learnset: {
			acrobatics: ["9M", "9L26", "7M", "7L26", "6M", "6L26", "5M", "5L26"],
			allyswitch: ["7T"],
			batonpass: ["9M"],
			brickbreak: ["9M", "7M", "6M", "5M"],
			calmmind: ["9M", "7M", "6M", "5M"],
			celebrate: ["7S3"],
			chargebeam: ["7M", "6M", "5M"],
			charm: ["9M"],
			closecombat: ["9M", "9L78", "7L78", "7S2", "6L78", "5L78", "5S1"],
			confide: ["7M", "6M"],
			confusion: ["9L1", "7L1", "6L11", "5L11", "5S0"],
			covet: ["7T", "6T", "5T"],
			dazzlinggleam: ["9M", "7M", "6M"],
			disarmingvoice: ["9M"],
			doubleteam: ["7M", "6M", "5M"],
			drainpunch: ["9M", "7T", "6T", "5T"],
			dreameater: ["7M", "6M", "5M"],
			dualchop: ["7T", "6T", "5T"],
			echoedvoice: ["9L36", "7M", "7L36", "6M", "6L36", "5M", "5L36"],
			embargo: ["7M", "6M", "5M"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M", "5M"],
			facade: ["9M", "7M", "6M", "5M"],
			faketears: ["9M"],
			firepunch: ["9M", "7T", "6T", "5T"],
			flash: ["6M", "5M"],
			fling: ["9M", "7M", "6M", "5M"],
			focusblast: ["9M", "7M", "6M", "5M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M", "5M"],
			gigaimpact: ["9M", "7M", "6M", "5M"],
			grassknot: ["9M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			healbell: ["7T", "6T", "5T"],
			helpinghand: ["9M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["9M", "7M", "6M", "5M"],
			hypervoice: ["9M", "9L64", "7T", "7L64", "6T", "6L64", "5T", "5L64"],
			icepunch: ["9M", "7T", "6T", "5T"],
			knockoff: ["7T", "6T", "5T"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T", "5T"],
			lightscreen: ["9M", "7M", "6M", "5M"],
			lowkick: ["9M", "7T", "6T", "5T"],
			lowsweep: ["9M", "7M", "6M", "5M"],
			magiccoat: ["7T", "6T", "5T"],
			magicroom: ["7T", "6T", "5T"],
			metronome: ["9M"],
			payback: ["7M", "6M", "5M"],
			perishsong: ["9L85", "7L85", "6L85", "5L85"],
			playrough: ["9M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M", "5M"],
			psybeam: ["9M", "9L31", "7L31", "6L31", "5L31"],
			psychic: ["9M", "9L57", "7M", "7L57", "7S2", "6M", "6L57", "5M", "5L57", "5S1"],
			psychup: ["7M", "6M", "5M"],
			psyshock: ["9M", "7M", "6M", "5M"],
			quickattack: ["9L1", "7L1", "6L6", "5L6", "5S0"],
			raindance: ["9M", "7M", "6M", "5M"],
			recycle: ["7T", "6T", "5T"],
			relicsong: ["9L50", "7T", "7S3", "6T", "5T"],
			rest: ["9M", "7M", "6M", "5M"],
			retaliate: ["6M", "5M"],
			return: ["7M", "6M", "5M"],
			reversal: ["9M"],
			rocksmash: ["6M", "5M"],
			roleplay: ["9L71", "7T", "7L71", "6T", "6L71", "5T", "5L71"],
			round: ["9L1", "7M", "7L1", "7S3", "6M", "6L1", "5M", "5L1", "5S0", "5S1"],
			safeguard: ["7M", "6M", "5M"],
			secretpower: ["6M"],
			shadowball: ["9M", "7M", "6M", "5M"],
			shadowclaw: ["9M", "7M", "6M", "5M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T", "5T"],
			sing: ["9L1", "7L1", "7S2", "7S3", "6L16", "5L16"],
			skillswap: ["9M", "7T", "6T", "5T"],
			sleeptalk: ["9M", "7M", "6M", "5T"],
			snatch: ["7T", "6T", "5T"],
			snore: ["7T", "6T", "5T"],
			stoneedge: ["9M", "7M", "6M", "5M"],
			strength: ["6M", "5M"],
			substitute: ["9M", "7M", "6M", "5M"],
			sunnyday: ["9M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			teeterdance: ["9L21", "7L21", "6L21", "5L21", "5S1"],
			telekinesis: ["7T", "5M"],
			terablast: ["9M"],
			thunder: ["9M", "7M", "6M", "5M"],
			thunderbolt: ["9M", "7M", "6M", "5M"],
			thunderpunch: ["9M", "7T", "6T", "5T"],
			thunderwave: ["9M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			trick: ["9M", "7T", "6T", "5T"],
			trickroom: ["9M", "7M", "6M", "5M"],
			uproar: ["7T", "6T", "5T"],
			uturn: ["9M", "9L43", "7M", "7L43", "6M", "6L43", "5M", "5L43"],
			wakeupslap: ["7L50", "6L50", "5L50"],
			wonderroom: ["7T", "6T", "5T"],
			workup: ["7M", "5M"],
			zenheadbutt: ["9M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 15, moves: ["quickattack", "confusion", "round"], pokeball: "cherishball"},
			{generation: 5, level: 50, moves: ["round", "teeterdance", "psychic", "closecombat"], pokeball: "cherishball"},
			{generation: 7, level: 15, moves: ["sing", "psychic", "closecombat"], pokeball: "cherishball"},
			{generation: 7, level: 50, moves: ["sing", "celebrate", "round", "relicsong"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	genesect: {
		learnset: {
			aerialace: ["7M", "6M", "5M"],
			allyswitch: ["8M", "7T"],
			assurance: ["8M"],
			blazekick: ["8M", "5S2"],
			blizzard: ["8M", "7M", "6M", "5M"],
			bugbite: ["7T", "6T", "5T"],
			bugbuzz: ["8M", "8L56", "7L55", "6L55", "5L55"],
			chargebeam: ["7M", "6M", "5M"],
			confide: ["7M", "6M"],
			darkpulse: ["8M", "7M", "6M", "5T"],
			doubleteam: ["7M", "6M", "5M"],
			electroweb: ["8M", "7T", "6T", "5T"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M", "5M"],
			explosion: ["7M", "6M", "5M"],
			extremespeed: ["5S2"],
			facade: ["8M", "7M", "6M", "5M"],
			fellstinger: ["8L21", "8S4", "7L1", "6L1"],
			flamecharge: ["8L28", "7M", "7L18", "6M", "6L18", "5M", "5L18"],
			flamethrower: ["8M", "7M", "6M", "5M"],
			flash: ["6M", "5M"],
			flashcannon: ["8M", "7M", "6M", "5M"],
			fly: ["8M", "7M", "6M", "5M"],
			frustration: ["7M", "6M", "5M"],
			furycutter: ["8L1", "7L7", "6L7", "5L7"],
			gigadrain: ["8M", "7T", "6T", "5T"],
			gigaimpact: ["8M", "7M", "6M", "5M"],
			gravity: ["7T", "6T", "5T"],
			gunkshot: ["8M", "7T", "6T", "5T"],
			hiddenpower: ["7M", "6M", "5M"],
			honeclaws: ["6M", "5M"],
			hyperbeam: ["8M", "7M", "7L73", "6M", "6L73", "5M", "5L73"],
			icebeam: ["8M", "7M", "6M", "5M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T", "5T"],
			ironhead: ["8M", "7T", "6T", "5T"],
			lastresort: ["7T", "6T", "5T"],
			leechlife: ["8M"],
			lightscreen: ["8M", "7M", "6M", "5M"],
			lockon: ["8L77", "7L11", "6L11", "5L11"],
			magiccoat: ["7T", "6T", "5T"],
			magnetbomb: ["7L22", "6L22", "6S3", "5L22", "5S0", "5S1"],
			magnetrise: ["8L49", "7T", "7L1", "6T", "6L1", "5T", "5L1"],
			metalclaw: ["8L14", "8S4", "7L1", "6L1", "5L1"],
			metalsound: ["8L35", "7L33", "6L33", "5L33"],
			protect: ["8M", "7M", "6M", "5M"],
			psychic: ["8M", "7M", "6M", "5M"],
			quickattack: ["8L1", "7L1", "6L1", "5L1"],
			recycle: ["7T", "6T", "5T"],
			reflect: ["8M", "7M", "6M", "5M"],
			rest: ["8M", "7M", "6M", "5M"],
			return: ["7M", "6M", "5M"],
			rockpolish: ["7M", "6M", "5M"],
			round: ["8M", "7M", "6M", "5M"],
			screech: ["8M", "8L7", "7L1", "6L1", "5L1"],
			secretpower: ["6M"],
			selfdestruct: ["8M", "8L91", "7L77", "6L77", "5L77"],
			shadowclaw: ["8M", "7M", "6M", "5M"],
			shiftgear: ["5S2"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "7L40", "6T", "6L40", "6S3", "5T", "5L40", "5S0", "5S1"],
			simplebeam: ["8L63", "7L62", "6L62", "5L62"],
			slash: ["7L29", "6L29", "5L29"],
			sleeptalk: ["8M", "7M", "6M", "5T"],
			snore: ["8M", "7T", "6T", "5T"],
			solarbeam: ["8M", "7M", "6M", "6S3", "5M", "5S0", "5S1"],
			steelbeam: ["8T"],
			strugglebug: ["6M", "5M"],
			substitute: ["8M", "7M", "6M", "5M"],
			swagger: ["7M", "6M", "5M"],
			swift: ["8M"],
			technoblast: ["8L84", "8S4", "7L1", "6L1", "6S3", "5L1", "5S0", "5S1", "5S2"],
			telekinesis: ["7T"],
			thunder: ["8M", "7M", "6M", "5M"],
			thunderbolt: ["8M", "7M", "6M", "5M"],
			thunderwave: ["8M", "7M", "6M", "5M"],
			toxic: ["7M", "6M", "5M"],
			triattack: ["8M", "7L44", "6L44", "5L44"],
			uturn: ["8M", "7M", "6M", "5M"],
			xscissor: ["8M", "8L42", "8S4", "7M", "7L51", "6M", "6L51", "5M", "5L51"],
			zapcannon: ["8L70", "7L66", "6L66", "5L66"],
			zenheadbutt: ["8M", "7T", "6T", "5T"],
		},
		eventData: [
			{generation: 5, level: 50, moves: ["technoblast", "magnetbomb", "solarbeam", "signalbeam"], pokeball: "cherishball"},
			{generation: 5, level: 15, moves: ["technoblast", "magnetbomb", "solarbeam", "signalbeam"], pokeball: "cherishball"},
			{generation: 5, level: 100, shiny: true, nature: "Hasty", ivs: {atk: 31, spe: 31}, moves: ["extremespeed", "technoblast", "blazekick", "shiftgear"], pokeball: "cherishball"},
			{generation: 6, level: 100, moves: ["technoblast", "magnetbomb", "solarbeam", "signalbeam"], pokeball: "cherishball"},
			{generation: 8, level: 60, moves: ["technoblast", "xscissor", "metalclaw", "fellstinger"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	genesectburn: {
		eventOnly: true,
	},
	genesectchill: {
		eventOnly: true,
	},
	genesectdouse: {
		eventOnly: true,
	},
	genesectshock: {
		eventOnly: true,
	},
	chespin: {
		learnset: {
			aerialace: ["9M", "7M", "6M"],
			attract: ["7M", "6M"],
			bellydrum: ["9E", "7E", "6E"],
			bite: ["9L11", "7L11", "6L11"],
			bodyslam: ["9M", "9L42", "7L42", "6L42"],
			brickbreak: ["9M", "7M", "6M"],
			bulkup: ["7M", "7L39", "6M", "6L39"],
			bulldoze: ["9M", "7M", "6M"],
			bulletseed: ["9M"],
			confide: ["7M", "6M"],
			curse: ["9E", "7E", "6E"],
			cut: ["6M"],
			defensecurl: ["7E", "6E"],
			dig: ["9M", "6M"],
			doubleteam: ["7M", "6M"],
			drainpunch: ["9M", "7T", "6T"],
			dualchop: ["7T", "6T"],
			endeavor: ["7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			flash: ["6M"],
			fling: ["9M", "7M", "6M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			grassknot: ["9M", "7M", "6M"],
			grasspledge: ["9M", "7T", "6T"],
			grassyterrain: ["9M"],
			growl: ["9L1", "7L1", "6L1"],
			gyroball: ["7M", "6M"],
			helpinghand: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			irondefense: ["7T", "6T"],
			ironhead: ["9M", "7T", "6T"],
			irontail: ["7T", "6T"],
			leafstorm: ["9M"],
			leechseed: ["9L15", "7L15", "6L15"],
			lowkick: ["9M", "7T", "6T"],
			lowsweep: ["7M", "6M"],
			magicalleaf: ["9M"],
			metalclaw: ["9M"],
			mudshot: ["9M", "9L35", "7L35", "6L35"],
			naturepower: ["7M", "6M"],
			painsplit: ["9L45", "7T", "7L45", "6T", "6L45"],
			payback: ["7M", "6M"],
			pinmissile: ["9L18", "7L18", "6L18"],
			poisonjab: ["9M", "7M", "6M"],
			poweruppunch: ["7E", "6M"],
			protect: ["9M", "7M", "6M"],
			quickguard: ["9E", "7E", "6E"],
			raindance: ["9M"],
			reflect: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			retaliate: ["6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockslide: ["9M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "7M", "6M"],
			rollout: ["9L8", "7L8", "7E", "6L8", "6E"],
			round: ["7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "9L32", "7T", "7L32", "6T", "6L32"],
			shadowclaw: ["9M", "7M", "6M"],
			sleeptalk: ["9M", "7M", "6M"],
			sludgebomb: ["7M", "6M"],
			smackdown: ["7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			spikes: ["9M", "9E", "7E", "6E"],
			stompingtantrum: ["9M", "7T"],
			stoneedge: ["7M", "6M"],
			strength: ["6M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			superfang: ["9E", "7T", "6T"],
			superpower: ["7T", "6T"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			swordsdance: ["7M", "6M"],
			synthesis: ["9E", "7T", "7E", "6T", "6E"],
			tackle: ["6L1"],
			takedown: ["9M", "9L27", "7L27", "6L27"],
			taunt: ["7M", "6M"],
			terablast: ["9M"],
			thunderpunch: ["9M", "7T", "6T"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			vinewhip: ["9L1", "7L1", "6L5"],
			wideguard: ["9E"],
			woodhammer: ["9L48", "7L48", "6L48"],
			workup: ["7M"],
			worryseed: ["7T", "6T"],
			zenheadbutt: ["9M", "7T", "6T"],
		},
	},
	quilladin: {
		learnset: {
			aerialace: ["9M", "7M", "6M"],
			attract: ["7M", "6M"],
			bite: ["9L11", "7L11", "6L11"],
			bodyslam: ["9M", "9L43", "7L48", "6L48"],
			brickbreak: ["9M", "7M", "6M"],
			bulkup: ["9M", "9L38", "7M", "7L44", "6M", "6L44"],
			bulldoze: ["9M", "7M", "6M"],
			bulletseed: ["9M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			dig: ["9M", "6M"],
			doubleteam: ["7M", "6M"],
			drainpunch: ["9M", "7T", "6T"],
			dualchop: ["7T", "6T"],
			endeavor: ["7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			flash: ["6M"],
			fling: ["9M", "7M", "6M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			grassknot: ["9M", "7M", "6M"],
			grasspledge: ["9M", "7T", "6T"],
			grassyterrain: ["9M"],
			growl: ["9L1", "7L1", "6L1"],
			gyroball: ["7M", "6M"],
			helpinghand: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["6M"],
			irondefense: ["9M", "7T", "6T"],
			ironhead: ["9M", "7T", "6T"],
			irontail: ["7T", "6T"],
			leafstorm: ["9M"],
			leechseed: ["9L15", "7L15", "6L15"],
			lowkick: ["9M", "7T", "6T"],
			lowsweep: ["9M", "7M", "6M"],
			magicalleaf: ["9M"],
			metalclaw: ["9M"],
			mudshot: ["9M", "9L34", "7L39", "6L39"],
			naturepower: ["7M", "6M"],
			needlearm: ["7L1", "6L26"],
			painsplit: ["9L47", "7T", "7L52", "6T", "6L52"],
			payback: ["7M", "6M"],
			pinmissile: ["9L24", "7L19", "6L20"],
			poisonjab: ["9M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M"],
			raindance: ["9M"],
			reflect: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			retaliate: ["6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockslide: ["9M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "7M", "6M"],
			rollout: ["9L8", "7L8", "6L8"],
			round: ["7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "9L20", "7T", "7L35", "6T", "6L35"],
			shadowclaw: ["9M", "7M", "6M"],
			sleeptalk: ["9M", "7M", "6M"],
			sludgebomb: ["7M", "6M"],
			smackdown: ["7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			spikes: ["9M"],
			stompingtantrum: ["9M", "7T"],
			stoneedge: ["9M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			superfang: ["7T", "6T"],
			superpower: ["7T", "6T"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			swordsdance: ["7M", "6M"],
			synthesis: ["7T", "6T"],
			tackle: ["6L1"],
			takedown: ["9M", "9L29", "7L29", "6L30"],
			taunt: ["9M", "7M", "6M"],
			terablast: ["9M"],
			thunderpunch: ["9M", "7T", "6T"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			vinewhip: ["9L1", "7L1", "6L5"],
			woodhammer: ["9L53", "7L56", "6L55"],
			workup: ["7M"],
			worryseed: ["7T", "6T"],
			zenheadbutt: ["9M", "7T", "6T"],
		},
	},
	chesnaught: {
		learnset: {
			aerialace: ["9M", "7M", "6M"],
			attract: ["7M", "6M"],
			bellydrum: ["7L1", "6L1"],
			bite: ["9L11", "7L11", "6L11"],
			block: ["7T", "6T"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L54", "7L54", "6L48"],
			brickbreak: ["9M", "7M", "6M"],
			bulkup: ["9M", "9L48", "7M", "7L48", "6M", "6L44"],
			bulldoze: ["9M", "7M", "6M"],
			bulletseed: ["9M"],
			closecombat: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M"],
			cut: ["6M"],
			dig: ["9M", "6M"],
			doubleteam: ["7M", "6M"],
			dragonclaw: ["9M", "7M", "6M"],
			drainpunch: ["9M", "7T", "6T"],
			dualchop: ["7T", "6T"],
			earthquake: ["9M", "7M", "6M"],
			endeavor: ["7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			feint: ["9L1", "7L1", "6L1"],
			flash: ["6M"],
			fling: ["9M", "7M", "6M"],
			focusblast: ["9M", "7M", "6M"],
			focuspunch: ["7T", "6T"],
			frenzyplant: ["9M", "7T", "6T"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			gigaimpact: ["9M", "9L78", "7M", "7L78", "6M", "6L70"],
			grassknot: ["9M", "7M", "6M"],
			grasspledge: ["9M", "7T", "6T"],
			grassyterrain: ["9M"],
			growl: ["9L1", "7L1", "6L1"],
			gyroball: ["7M", "6M"],
			hammerarm: ["9L1", "7L1", "6L1"],
			helpinghand: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["6M"],
			hyperbeam: ["9M", "7M", "6M"],
			irondefense: ["9M", "7T", "6T"],
			ironhead: ["9M", "7T", "6T"],
			irontail: ["7T", "6T"],
			leafstorm: ["9M"],
			leechseed: ["9L15", "7L15", "6L15"],
			lowkick: ["9M", "7T", "6T"],
			lowsweep: ["9M", "7M", "6M"],
			magicalleaf: ["9M"],
			metalclaw: ["9M"],
			mudshot: ["9M", "9L41", "7L41", "6L41"],
			mudslap: ["9M"],
			naturepower: ["7M", "6M"],
			needlearm: ["7L1", "6L26"],
			painsplit: ["9L60", "7T", "7L60", "6T", "6L52"],
			payback: ["7M", "6M"],
			pinmissile: ["9L19", "7L19", "6L20"],
			poisonjab: ["9M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M"],
			raindance: ["9M"],
			reflect: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			retaliate: ["6M"],
			return: ["7M", "6M"],
			reversal: ["9M"],
			roar: ["7M", "6M"],
			rockslide: ["9M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "7M", "6M"],
			rollout: ["9L1", "7L1", "6L8"],
			round: ["7M", "6M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "9L35", "7T", "7L35", "6T", "6L35"],
			shadowclaw: ["9M", "7M", "6M"],
			sleeptalk: ["9M", "7M", "6M"],
			sludgebomb: ["7M", "6M"],
			smackdown: ["7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			spikes: ["9M"],
			spikyshield: ["9L0", "7L1", "6L36"],
			stompingtantrum: ["9M", "7T"],
			stoneedge: ["9M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			superfang: ["7T", "6T"],
			superpower: ["7T", "6T"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			swordsdance: ["9M", "7M", "6M"],
			synthesis: ["7T", "6T"],
			tackle: ["9L1", "7L1", "6L1"],
			takedown: ["9M", "9L29", "7L29", "6L30"],
			taunt: ["9M", "7M", "6M"],
			terablast: ["9M"],
			thunderpunch: ["9M", "7T", "6T"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			vinewhip: ["9L1", "7L1", "6L5"],
			woodhammer: ["9L66", "7L66", "6L55"],
			workup: ["7M"],
			worryseed: ["7T", "6T"],
			zenheadbutt: ["9M", "7T", "6T"],
		},
	},
	fennekin: {
		learnset: {
			agility: ["9M"],
			attract: ["7M", "6M"],
			calmmind: ["9M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9E"],
			covet: ["7T", "6T"],
			cut: ["6M"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			echoedvoice: ["7M", "6M"],
			embargo: ["7M", "6M"],
			ember: ["9L5", "7L5", "6L5"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M"],
			fireblast: ["9M", "9L48", "7M", "7L48", "6M", "6L48"],
			firepledge: ["9M", "7T", "6T"],
			firespin: ["9M", "9L20", "7L20", "6L20"],
			flamecharge: ["9M", "9L14", "7M", "7L14", "6M", "6L14"],
			flamethrower: ["9M", "9L35", "7M", "7L35", "6M", "6L35", "6S0"],
			flareblitz: ["9M"],
			foulplay: ["9M", "7T", "6T"],
			frustration: ["7M", "6M"],
			grassknot: ["9M", "7M", "6M"],
			heatwave: ["9M", "9E", "7T", "7E", "6T", "6E"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M", "6S0"],
			howl: ["9L11", "7L11", "6L11"],
			hypnosis: ["9E", "7E", "6E"],
			imprison: ["9M"],
			incinerate: ["6M"],
			irontail: ["7T", "6T"],
			lightscreen: ["9M", "9L25", "7M", "7L27", "6M", "6L27"],
			luckychant: ["7L25", "6L25"],
			magiccoat: ["7T", "7E", "6T", "6E"],
			magicroom: ["9E", "7T", "7L46", "6T", "6L46"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			overheat: ["9M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M"],
			psybeam: ["9M", "9L17", "7L17", "6L17"],
			psychic: ["9M", "9L41", "7M", "7L41", "6M", "6L41"],
			psychicterrain: ["9M", "7E"],
			psychup: ["7M", "6M"],
			psyshock: ["9M", "9L31", "7M", "7L31", "6M", "6L31"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["7M", "6M"],
			safeguard: ["7M", "6M"],
			scratch: ["9L1", "7L1", "6L1", "6S0"],
			secretpower: ["6M"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "9L43", "7M", "7L43", "6M", "6L43"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tailwhip: ["9L1", "7L1", "6L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["9M"],
			trickroom: ["9M"],
			willowisp: ["9M", "9L38", "7M", "7L38", "6M", "6L38"],
			wish: ["9E", "7E", "6E"],
			workup: ["7M"],
		},
		eventData: [
			{generation: 6, level: 15, gender: "F", nature: "Hardy", moves: ["scratch", "flamethrower", "hiddenpower"], pokeball: "cherishball"},
		],
	},
	braixen: {
		learnset: {
			agility: ["9M"],
			allyswitch: ["7T"],
			attract: ["7M", "6M"],
			calmmind: ["9M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			cut: ["6M"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			echoedvoice: ["7M", "6M"],
			embargo: ["7M", "6M"],
			ember: ["9L1", "7L1", "6L5"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M"],
			fireblast: ["9M", "9L59", "7M", "7L59", "6M", "6L55"],
			firepledge: ["9M", "7T", "6T"],
			firepunch: ["9M", "7T", "6T"],
			firespin: ["9M", "9L22", "7L22", "6L22"],
			flamecharge: ["9M", "9L14", "7M", "7L14", "6M", "6L14"],
			flamethrower: ["9M", "9L41", "7M", "7L41", "6M", "6L41"],
			flareblitz: ["9M"],
			foulplay: ["9M", "7T", "6T"],
			frustration: ["7M", "6M"],
			grassknot: ["9M", "7M", "6M"],
			heatwave: ["9M", "7T", "6T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M"],
			howl: ["9L11", "7L11", "6L11"],
			imprison: ["9M"],
			incinerate: ["6M"],
			irontail: ["7T", "6T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L28", "7M", "7L31", "6M", "6L30"],
			lowkick: ["9M", "7T", "6T"],
			luckychant: ["7L28", "6L27"],
			magiccoat: ["7T", "6T"],
			magicroom: ["9L56", "7T", "7L56", "6T", "6L53"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			overheat: ["9M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M"],
			psybeam: ["9M", "9L18", "7L18", "6L18"],
			psychic: ["9M", "9L49", "7M", "7L49", "6M", "6L48"],
			psychicterrain: ["9M"],
			psychup: ["7M", "6M"],
			psyshock: ["9M", "9L36", "7M", "7L36", "6M", "6L34"],
			raindance: ["9M", "7M", "6M"],
			recycle: ["7T", "6T"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["7M", "6M"],
			safeguard: ["7M", "6M"],
			scratch: ["9L1", "7L1", "6L1"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			skillswap: ["9M", "7T", "6T"],
			sleeptalk: ["9M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "9L52", "7M", "7L52", "6M", "6L51"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tailwhip: ["9L1", "7L1", "6L1"],
			takedown: ["9M"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			thunderpunch: ["9M", "7T", "6T"],
			toxic: ["7M", "6M"],
			trick: ["9M", "7T", "6T"],
			trickroom: ["9M"],
			willowisp: ["9M", "9L45", "7M", "7L45", "6M", "6L45"],
			wonderroom: ["7T", "6T"],
			workup: ["7M"],
			zenheadbutt: ["9M", "7T", "6T"],
		},
	},
	delphox: {
		learnset: {
			agility: ["9M"],
			allyswitch: ["7T"],
			attract: ["7M", "6M"],
			blastburn: ["9M", "7T", "6T"],
			calmmind: ["9M", "7M", "6M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			covet: ["7T", "6T"],
			cut: ["6M"],
			dazzlinggleam: ["9M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			echoedvoice: ["7M", "6M"],
			embargo: ["7M", "6M"],
			ember: ["9L1", "7L1", "6L5"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M"],
			fireblast: ["9M", "9L74", "7M", "7L74", "6M", "6L61"],
			firepledge: ["9M", "7T", "6T"],
			firepunch: ["9M", "7T", "6T"],
			firespin: ["9M", "9L22", "7L22", "6L22"],
			flamecharge: ["9M", "9L14", "7M", "7L14", "6M", "6L14"],
			flamethrower: ["9M", "9L45", "7M", "7L45", "6M", "6L42"],
			flareblitz: ["9M"],
			focusblast: ["9M"],
			foulplay: ["9M", "7T", "6T"],
			frustration: ["7M", "6M"],
			futuresight: ["9L1", "7L1", "6L1"],
			gigaimpact: ["9M", "7M", "6M"],
			grassknot: ["9M", "7M", "6M"],
			heatwave: ["9M", "7T", "6T"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hiddenpower: ["7M", "6M"],
			howl: ["9L1", "7L1", "6L11"],
			hyperbeam: ["9M", "7M", "6M"],
			hypervoice: ["9M"],
			imprison: ["9M"],
			incinerate: ["6M"],
			irontail: ["7T", "6T"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L28", "7M", "7L31", "6M", "6L30"],
			lowkick: ["9M", "7T", "6T"],
			luckychant: ["7L28", "6L27"],
			magiccoat: ["7T", "6T"],
			magicroom: ["9L68", "7T", "7L68", "6T", "6L58"],
			metronome: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			mysticalfire: ["9L0", "7L1", "6L36"],
			nastyplot: ["9M"],
			nightshade: ["9M"],
			overheat: ["9M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M"],
			psybeam: ["9M", "9L18", "7L18", "6L18"],
			psychic: ["9M", "9L57", "7M", "7L57", "6M", "6L51"],
			psychicterrain: ["9M"],
			psychup: ["7M", "6M"],
			psyshock: ["9M", "9L38", "7M", "7L38", "6M", "6L34"],
			raindance: ["9M", "7M", "6M"],
			recycle: ["7T", "6T"],
			reflect: ["9M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			roleplay: ["9L1", "7T", "7L1", "6T", "6L1"],
			round: ["7M", "6M"],
			safeguard: ["7M", "6M"],
			scratch: ["9L1", "7L1", "6L1"],
			secretpower: ["6M"],
			shadowball: ["9M", "9L1", "7M", "7L1", "6M", "6L1"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T"],
			skillswap: ["9M", "7T", "6T"],
			sleeptalk: ["9M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "9L62", "7M", "7L62", "6M", "6L55"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			switcheroo: ["9L1", "7L1", "6L1"],
			tailwhip: ["9L1", "7L1", "6L1"],
			takedown: ["9M"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			thunderpunch: ["9M", "7T", "6T"],
			toxic: ["7M", "6M"],
			trick: ["9M", "7T", "6T"],
			trickroom: ["9M", "7M", "6M"],
			willowisp: ["9M", "9L51", "7M", "7L51", "6M", "6L47"],
			wonderroom: ["7T", "6T"],
			workup: ["7M"],
			zenheadbutt: ["9M", "7T", "6T"],
		},
	},
	froakie: {
		learnset: {
			acrobatics: ["9M", "7M", "6M"],
			aerialace: ["9M", "7M", "6M"],
			attract: ["7M", "6M"],
			bestow: ["7E", "6E"],
			blizzard: ["9M", "7M", "6M"],
			bounce: ["9L39", "7T", "7L39", "6T", "6L39"],
			bubble: ["7L5", "6L5", "6S0"],
			camouflage: ["7E", "6E"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			counter: ["9E"],
			cut: ["6M"],
			dig: ["9M", "6M"],
			dive: ["6M"],
			doubleteam: ["9L43", "7M", "7L43", "6M", "6L43"],
			echoedvoice: ["7M", "6M"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M"],
			falseswipe: ["9M"],
			fling: ["9M", "9L25", "7M", "7L25", "6M", "6L25"],
			frustration: ["7M", "6M"],
			grassknot: ["9M", "7M", "6M"],
			growl: ["9L1", "7L1", "6L1", "6S0"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M"],
			hydropump: ["9M", "9L48", "7L48", "6L48"],
			icebeam: ["9M", "7M", "6M"],
			icywind: ["9M", "7T", "6T"],
			lick: ["9L10", "7L10", "6L10"],
			liquidation: ["9M"],
			mindreader: ["7E", "6E"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			mudsport: ["7E", "6E"],
			pound: ["9L1", "7L1", "6L1", "6S0"],
			poweruppunch: ["7E", "6M"],
			protect: ["9M", "7M", "6M"],
			quickattack: ["9L8", "7L8", "6L8"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			retaliate: ["9E"],
			return: ["7M", "6M", "6S0"],
			rockslide: ["9M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "7M", "6M"],
			roleplay: ["7T", "6T"],
			round: ["9L21", "7M", "7L21", "6M", "6L21"],
			scald: ["7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "7M", "6M"],
			smackdown: ["9L29", "7M", "7L29", "6M", "6L29"],
			smokescreen: ["9L18", "7L18", "6L18"],
			snatch: ["7T", "6T"],
			snore: ["7T", "6T"],
			snowscape: ["9M"],
			spikes: ["9M", "9E"],
			spite: ["7T", "6T"],
			strength: ["6M"],
			substitute: ["9M", "9L35", "7M", "7L35", "6M", "6L35"],
			surf: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			switcheroo: ["9E"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			toxic: ["7M", "6M"],
			toxicspikes: ["9M", "9E", "7E", "6E"],
			trailblaze: ["9M"],
			uturn: ["9M", "7M", "6M"],
			waterfall: ["9M", "7M", "6M"],
			watergun: ["9L5"],
			waterpledge: ["9M", "7T", "6T"],
			waterpulse: ["9M", "9L14", "7T", "7L14", "6T", "6L14"],
			watersport: ["7E", "6E"],
			workup: ["7M"],
		},
		eventData: [
			{generation: 6, level: 7, moves: ["pound", "growl", "bubble", "return"], pokeball: "cherishball"},
		],
	},
	frogadier: {
		learnset: {
			acrobatics: ["9M", "7M", "6M"],
			aerialace: ["9M", "7M", "6M"],
			attract: ["7M", "6M"],
			blizzard: ["9M", "7M", "6M"],
			bounce: ["9L45", "7T", "7L45", "6T", "6L44"],
			bubble: ["7L1", "6L5"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			darkpulse: ["7M", "6M"],
			dig: ["9M", "6M"],
			dive: ["6M"],
			doubleteam: ["9L50", "7M", "7L50", "6M", "6L48"],
			echoedvoice: ["7M", "6M"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M"],
			falseswipe: ["9M"],
			fling: ["9M", "9L28", "7M", "7L28", "6M", "6L28"],
			frustration: ["7M", "6M"],
			grassknot: ["9M", "7M", "6M"],
			growl: ["9L1", "7L1", "6L1"],
			gunkshot: ["9M", "7T", "6T"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M"],
			hydropump: ["9M", "9L56", "7L56", "6L55"],
			icebeam: ["9M", "7M", "6M"],
			icepunch: ["9M", "7T", "6T"],
			icywind: ["9M", "7T", "6T"],
			lick: ["9L10", "7L10", "6L10"],
			liquidation: ["9M"],
			lowkick: ["9M", "7T", "6T"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			pound: ["9L1", "7L1", "6L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M"],
			quickattack: ["9L8", "7L8", "6L8"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["9M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "7M", "6M"],
			roleplay: ["7T", "6T"],
			round: ["9L23", "7M", "7L23", "6M", "6L23"],
			scald: ["7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "7M", "6M"],
			smackdown: ["9L33", "7M", "7L33", "6M", "6L33"],
			smokescreen: ["9L19", "7L19", "6L20"],
			snatch: ["7T", "6T"],
			snore: ["7T", "6T"],
			snowscape: ["9M"],
			spikes: ["9M"],
			spite: ["7T", "6T"],
			strength: ["6M"],
			substitute: ["9M", "9L40", "7M", "7L40", "6M", "6L38"],
			surf: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			toxic: ["7M", "6M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "7M", "6M"],
			waterfall: ["9M", "7M", "6M"],
			watergun: ["9L1"],
			waterpledge: ["9M", "7T", "6T"],
			waterpulse: ["9M", "9L14", "7T", "7L14", "6T", "6L14"],
			workup: ["7M"],
		},
	},
	greninja: {
		learnset: {
			acrobatics: ["9M", "7M", "6M"],
			aerialace: ["9M", "9L33", "7M", "6M"],
			attract: ["7M", "6M"],
			blizzard: ["9M", "7M", "6M"],
			bounce: ["7T", "6T"],
			brickbreak: ["9M"],
			brutalswing: ["7M"],
			bubble: ["7L1", "6L5"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			darkpulse: ["9M", "7M", "6M"],
			dig: ["9M", "6M"],
			dive: ["6M"],
			doubleteam: ["9L56", "7M", "7L56", "6M", "6L52"],
			echoedvoice: ["7M", "6M"],
			endure: ["9M"],
			extrasensory: ["9L49", "7L49", "6L49"],
			facade: ["9M", "7M", "6M"],
			falseswipe: ["9M"],
			feintattack: ["7L33", "6L33"],
			fling: ["9M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "7M", "6M"],
			grassknot: ["9M", "7M", "6M"],
			growl: ["9L1", "7L1", "6L1"],
			gunkshot: ["9M", "7T", "6T", "6S1"],
			happyhour: ["6S1"],
			haze: ["9L1", "7L1", "6L56"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M"],
			hydrocannon: ["9M", "7T", "6T", "6S1"],
			hydropump: ["9M", "9L68", "7L68", "6L60", "6S0"],
			hyperbeam: ["9M", "7M", "6M"],
			icebeam: ["9M", "7M", "6M"],
			icepunch: ["9M", "7T", "6T"],
			icywind: ["9M", "7T", "6T"],
			lick: ["9L10", "7L10", "6L10"],
			liquidation: ["9M"],
			lowkick: ["9M", "7T", "6T"],
			lowsweep: ["9M"],
			matblock: ["7L1", "6L1", "6S1"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			nightslash: ["9L1", "7L1", "6L1"],
			pound: ["9L1", "7L1", "6L1"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M"],
			quickattack: ["9L1", "7L1", "6L8"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["9M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "7M", "6M"],
			roleplay: ["9L1", "7T", "7L1", "6T", "6L1"],
			round: ["7M", "6M"],
			scald: ["7M", "6M"],
			secretpower: ["6M"],
			shadowsneak: ["9L23", "7L23", "6L23", "6S0"],
			sleeptalk: ["9M", "7M", "6M"],
			smackdown: ["7M", "6M"],
			smokescreen: ["9L19", "7L19", "6L20"],
			snatch: ["7T", "6T"],
			snore: ["7T", "6T"],
			snowscape: ["9M"],
			spikes: ["9M", "9L28", "7L28", "6L28"],
			spite: ["7T", "6T"],
			strength: ["6M"],
			substitute: ["9M", "9L42", "7M", "7L42", "6M", "6L43", "6S0"],
			surf: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			toxic: ["7M", "6M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "7M", "6M"],
			waterfall: ["9M", "7M", "6M"],
			watergun: ["9L1"],
			waterpledge: ["9M", "7T", "6T"],
			waterpulse: ["9M", "9L14", "7T", "7L14", "6T", "6L14"],
			watershuriken: ["9L0", "7L1", "6L36", "6S0"],
			workup: ["7M"],
		},
		eventData: [
			{generation: 6, level: 36, ivs: {spe: 31}, isHidden: true, moves: ["watershuriken", "shadowsneak", "hydropump", "substitute"], pokeball: "cherishball"},
			{generation: 6, level: 100, isHidden: true, moves: ["hydrocannon", "gunkshot", "matblock", "happyhour"], pokeball: "cherishball"},
		],
	},
	greninjaash: {
		learnset: {
			acrobatics: ["7M"],
			aerialace: ["9M", "9L33", "7M", "7S0"],
			attract: ["7M"],
			blizzard: ["9M", "7M"],
			bounce: ["7T"],
			brickbreak: ["9M"],
			brutalswing: ["7M"],
			bubble: ["7L1"],
			chillingwater: ["9M"],
			confide: ["7M"],
			darkpulse: ["9M", "7M"],
			dig: ["9M"],
			doubleteam: ["9L56", "7M", "7L56", "7S0"],
			echoedvoice: ["7M"],
			endure: ["9M"],
			extrasensory: ["9L49", "7L49"],
			facade: ["9M", "7M"],
			falseswipe: ["9M"],
			feintattack: ["7L33"],
			fling: ["9M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["9M", "7M"],
			grassknot: ["9M", "7M"],
			growl: ["9L1", "7L1"],
			gunkshot: ["9M", "7T"],
			haze: ["9L1", "7L1"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			hydrocannon: ["9M", "7T"],
			hydropump: ["9M", "9L68", "7L68"],
			hyperbeam: ["9M", "7M"],
			icebeam: ["9M", "7M"],
			icepunch: ["9M", "7T"],
			icywind: ["9M", "7T"],
			lick: ["9L10", "7L10"],
			liquidation: ["9M"],
			lowkick: ["9M", "7T"],
			lowsweep: ["9M"],
			matblock: ["7L1"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			nightslash: ["9L1", "7L1", "7S0"],
			pound: ["9L1", "7L1"],
			protect: ["9M", "7M"],
			quickattack: ["9L1", "7L1"],
			raindance: ["9M", "7M"],
			rest: ["9M", "7M"],
			return: ["7M"],
			rockslide: ["9M", "7M"],
			rocktomb: ["9M", "7M"],
			roleplay: ["9L1", "7T", "7L1"],
			round: ["7M"],
			scald: ["7M"],
			shadowsneak: ["9L23", "7L23"],
			sleeptalk: ["9M", "7M"],
			smackdown: ["7M"],
			smokescreen: ["9L19", "7L19"],
			snatch: ["7T"],
			snore: ["7T"],
			snowscape: ["9M"],
			spikes: ["9M", "9L28", "7L28"],
			spite: ["7T"],
			substitute: ["9M", "9L42", "7M", "7L42"],
			surf: ["9M", "7M"],
			swagger: ["7M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "7M"],
			terablast: ["9M"],
			thief: ["9M", "7M"],
			toxic: ["7M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "7M"],
			waterfall: ["9M", "7M"],
			watergun: ["9L1"],
			waterpledge: ["9M", "7T"],
			waterpulse: ["9M", "9L14", "7T", "7L14"],
			watershuriken: ["9L0", "7L1", "7S0"],
			workup: ["7M"],
		},
		eventData: [
			{generation: 7, level: 36, ivs: {hp: 20, atk: 31, def: 20, spa: 31, spd: 20, spe: 31}, moves: ["watershuriken", "aerialace", "doubleteam", "nightslash"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	bunnelby: {
		learnset: {
			agility: ["8M", "7L1", "6L1"],
			attract: ["8M", "7M", "6M"],
			bounce: ["8M", "8L27", "7T", "7L38", "6T", "6L38"],
			brickbreak: ["8M", "7M", "6M"],
			bulkup: ["8M", "7M", "6M"],
			bulldoze: ["8M", "8L21", "7M", "6M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			defensecurl: ["8E", "7E", "6E"],
			dig: ["8M", "8L24", "7L33", "6M", "6L33"],
			doublekick: ["8L18", "7L20", "6L20"],
			doubleslap: ["7L10", "6L10"],
			doubleteam: ["7M", "6M"],
			earthquake: ["8M", "8L36", "7M", "7L49", "6M", "6L49"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			facade: ["8M", "7M", "7L47", "6M", "6L47"],
			flail: ["8L15", "7L29", "6L29"],
			fling: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			hiddenpower: ["7M", "6M"],
			ironhead: ["8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			laserfocus: ["8L6"],
			lastresort: ["7T", "6T"],
			leer: ["8L1", "7L1", "6L1"],
			mudshot: ["8M", "8L12", "7L18", "6L18"],
			mudslap: ["8L1", "7L13", "6L13"],
			naturepower: ["7M", "6M"],
			odorsleuth: ["7L25", "6L25"],
			payback: ["8M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			quickattack: ["8L9", "7L7", "6L7"],
			recycle: ["7T", "6T"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["8M", "7M", "6M"],
			rollout: ["8E", "7E", "6E"],
			round: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			smackdown: ["7M", "6M"],
			snore: ["8M", "7T", "6T"],
			spikes: ["8M", "7E", "6E"],
			stoneedge: ["8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			superfang: ["8L39", "7T", "7L42", "6T", "6L42"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["8M", "8L33"],
			tackle: ["8L3", "7L1", "6L1"],
			takedown: ["8L30", "7L15", "6L15"],
			thief: ["8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["8M", "7M", "6M"],
			wildcharge: ["8M", "7M", "6M"],
			workup: ["8M", "7M"],
		},
	},
	diggersby: {
		learnset: {
			agility: ["8M", "7L1", "6L1"],
			attract: ["8M", "7M", "6M"],
			bodyslam: ["8M"],
			bounce: ["8M", "8L33", "7T", "7L43", "6T", "6L42"],
			brickbreak: ["8M", "7M", "6M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "7M", "6M"],
			bulldoze: ["8M", "8L23", "7M", "7L1", "6M", "6L1"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			dig: ["8M", "8L28", "7L37", "6M", "6L37"],
			doublekick: ["8L18", "7L21", "6L20"],
			doubleslap: ["7L13"],
			doubleteam: ["7M", "6M"],
			earthpower: ["8M", "7T", "6T"],
			earthquake: ["8M", "8L48", "7M", "7L57", "6M", "6L57"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			facade: ["8M", "7M", "7L54", "6M", "6L53"],
			firepunch: ["8M", "7T", "6T"],
			flail: ["8L15", "7L32", "6L31"],
			fling: ["8M", "7M", "6M"],
			focuspunch: ["7T", "6T"],
			foulplay: ["8M", "7T", "6T"],
			frustration: ["7M", "6M"],
			gastroacid: ["7T", "6T"],
			gigaimpact: ["8M", "7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			gunkshot: ["8M", "7T", "6T"],
			hammerarm: ["8L58", "7L1", "6L1"],
			hiddenpower: ["7M", "6M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M", "6M"],
			icepunch: ["8M", "7T", "6T"],
			ironhead: ["8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			knockoff: ["7T", "6T"],
			laserfocus: ["8L1"],
			lastresort: ["7T", "6T"],
			leer: ["8L1", "7L1", "6L1"],
			lowkick: ["8M", "7T", "6T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudshot: ["8M", "8L12", "7L18", "6L18"],
			mudslap: ["8L1", "7L13", "6L13"],
			naturepower: ["7M", "6M"],
			odorsleuth: ["7L27", "6L26"],
			payback: ["8M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			quickattack: ["8L9", "7L7", "6L7"],
			recycle: ["7T", "6T"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["8M", "7M", "6M"],
			rototiller: ["7L1", "6L1"],
			round: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			smackdown: ["7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			spikes: ["8M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			superfang: ["8L53", "7T", "7L48", "6T", "6L48"],
			superpower: ["8M", "7T", "6T"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["8M", "8L43", "7M", "7L1", "6M", "6L1"],
			tackle: ["8L1", "7L1", "6L1"],
			takedown: ["8L38", "7L15", "6L15"],
			thief: ["8M", "7M", "6M"],
			thunderpunch: ["8M", "7T", "6T"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["8M", "7T", "6T"],
			uturn: ["8M", "7M", "6M"],
			wildcharge: ["8M", "7M", "6M"],
			workup: ["8M", "7M"],
		},
	},
	fletchling: {
		learnset: {
			acrobatics: ["9M", "9L20", "8M", "8L20", "7M", "7L39", "6M", "6L39"],
			aerialace: ["9M", "9L30", "8L30", "7M", "6M"],
			agility: ["9M", "9L25", "8M", "8L25", "7L13", "6L13"],
			aircutter: ["9M"],
			airslash: ["9M"],
			attract: ["8M", "7M", "6M"],
			bravebird: ["9M"],
			confide: ["7M", "6M"],
			defog: ["9E", "8E", "7T"],
			doubleteam: ["7M", "6M"],
			dualwingbeat: ["8T"],
			ember: ["9L10", "8L10"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			flail: ["9L15", "8L15", "7L16", "6L16"],
			flamecharge: ["9M", "9E", "8E", "7M", "7L34", "6M", "6L34"],
			flareblitz: ["9M"],
			fly: ["9M", "9L50", "8M", "8L50", "7M", "6M"],
			frustration: ["7M", "6M"],
			growl: ["9L1", "8L1", "7L1", "6L1"],
			heatwave: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hurricane: ["9M"],
			mefirst: ["7L41", "6L41"],
			naturalgift: ["7L29", "6L29"],
			overheat: ["9M", "8M", "7M", "6M"],
			peck: ["9L1", "8L1", "7L10", "6L10"],
			protect: ["9M", "8M", "7M", "6M"],
			quickattack: ["9L5", "8L5", "7L6", "6L6"],
			quickguard: ["9E", "8E", "7E", "6E"],
			raindance: ["9M"],
			razorwind: ["7L25", "6L25"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			roost: ["9L45", "8L45", "7M", "7L21", "6M", "6L21"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snatch: ["7T", "7E", "6T", "6E"],
			snore: ["8M", "7T", "6T"],
			steelwing: ["9L40", "8M", "8L40", "7M", "7L48", "6M", "6L48"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M", "6M"],
			tackle: ["7L1", "6L1"],
			tailwind: ["9M", "9L35", "8L35", "7T", "7L45", "7E", "6T", "6L45", "6E"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["9M", "8M", "7M", "6M"],
			willowisp: ["8M"],
			workup: ["8M", "7M"],
		},
	},
	fletchinder: {
		learnset: {
			acrobatics: ["9M", "9L22", "8M", "8L22", "7M", "7L42", "6M", "6L42"],
			aerialace: ["9M", "9L36", "8L36", "7M", "6M"],
			agility: ["9M", "9L29", "8M", "8L29", "7L13", "6L13"],
			aircutter: ["9M"],
			airslash: ["9M"],
			attract: ["8M", "7M", "6M"],
			bravebird: ["9M"],
			confide: ["7M", "6M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M"],
			dualwingbeat: ["8T"],
			ember: ["9L1", "8L1", "7L1", "6L17"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			feint: ["9L1", "8L1"],
			fireblast: ["9M", "8M", "7M", "6M"],
			firespin: ["9M", "8M"],
			flail: ["9L15", "8L15", "7L16", "6L16"],
			flamecharge: ["9M", "9L0", "8L0", "7M", "7L38", "6M", "6L38"],
			flamethrower: ["9M", "8M", "7M", "6M"],
			flareblitz: ["9M"],
			fly: ["9M", "9L64", "8M", "8L64", "7M", "6M"],
			frustration: ["7M", "6M"],
			growl: ["9L1", "8L1", "7L1", "6L1"],
			heatwave: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hurricane: ["9M"],
			incinerate: ["6M"],
			mefirst: ["7L46", "6L46"],
			naturalgift: ["7L31", "6L31"],
			overheat: ["9M", "8M", "7M", "6M"],
			peck: ["9L1", "8L1", "7L10", "6L10"],
			protect: ["9M", "8M", "7M", "6M"],
			quickattack: ["9L1", "8L1", "7L1", "6L6"],
			raindance: ["9M"],
			razorwind: ["7L27", "6L27"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			roost: ["9L57", "8L57", "7M", "7L25", "6M", "6L25"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			steelwing: ["9L50", "8M", "8L50", "7M", "7L55", "6M", "6L55"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M", "6M"],
			tackle: ["7L1", "6L1"],
			tailwind: ["9M", "9L43", "8L43", "7T", "7L51", "6T", "6L51"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["9M", "8M", "7M", "6M"],
			willowisp: ["9M", "8M", "7M", "6M"],
			workup: ["8M", "7M"],
		},
		encounters: [
			{generation: 7, level: 16},
		],
	},
	talonflame: {
		learnset: {
			acrobatics: ["9M", "9L22", "8M", "8L22", "7M", "7L44", "6M", "6L44"],
			aerialace: ["9M", "9L38", "8L38", "7M", "6M"],
			agility: ["9M", "9L29", "8M", "8L29", "7L13", "6L13"],
			aircutter: ["9M"],
			airslash: ["9M"],
			attract: ["8M", "7M", "6M"],
			bravebird: ["9M", "9L83", "8M", "8L83", "7L1", "6L1"],
			bulkup: ["9M", "8M", "7M", "6M"],
			confide: ["7M", "6M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M"],
			dualwingbeat: ["8T"],
			ember: ["9L1", "8L1", "7L1", "6L17"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			feint: ["9L1", "8L1"],
			fireblast: ["9M", "8M", "7M", "6M"],
			firespin: ["9M", "8M"],
			flail: ["9L15", "8L15", "7L16", "6L16"],
			flamecharge: ["9M", "9L1", "8L1", "7M", "7L39", "6M", "6L39"],
			flamethrower: ["9M", "8M", "7M", "6M"],
			flareblitz: ["9M", "9L1", "8M", "8L1", "7L1", "6L1"],
			fly: ["9M", "9L74", "8M", "8L74", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			growl: ["9L1", "8L1", "7L1", "6L1"],
			heatwave: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["6M"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			incinerate: ["6M"],
			mefirst: ["7L49", "6L49"],
			naturalgift: ["7L31", "6L31"],
			overheat: ["9M", "8M", "7M", "6M"],
			peck: ["9L1", "8L1", "7L1", "6L10"],
			protect: ["9M", "8M", "7M", "6M"],
			quickattack: ["9L1", "8L1", "7L1", "6L6"],
			raindance: ["9M"],
			razorwind: ["7L27", "6L27"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			roost: ["9L65", "8L65", "7M", "7L25", "6M", "6L25"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			solarbeam: ["9M", "8M", "7M", "6M"],
			steelwing: ["9L56", "8M", "8L56", "7M", "7L60", "6M", "6L60"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M", "6M"],
			tackle: ["7L1", "6L1"],
			tailwind: ["9M", "9L47", "8L47", "7T", "7L55", "6T", "6L55"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["9M", "8M", "7M", "6M"],
			willowisp: ["9M", "8M", "7M", "6M"],
			workup: ["8M", "7M"],
		},
	},
	scatterbug: {
		learnset: {
			bugbite: ["9L15", "7T", "7L15", "6T", "6L15"],
			poisonpowder: ["9E", "7E", "6E"],
			pounce: ["9M"],
			ragepowder: ["9E", "7E", "6E"],
			stringshot: ["9L1", "7L1", "6L1"],
			strugglebug: ["9M"],
			stunspore: ["9L6", "7L6", "7E", "6L6", "6E"],
			tackle: ["9L1", "7L1", "6L1"],
			terablast: ["9M"],
		},
	},
	spewpa: {
		learnset: {
			bugbite: ["7T", "6T"],
			electroweb: ["7T", "6T"],
			harden: ["9L1", "7L1", "6L1"],
			irondefense: ["9M", "7T", "6T"],
			pounce: ["9M"],
			protect: ["9M", "9L0", "7M", "7L1", "6M", "6L9"],
			strugglebug: ["9M"],
			terablast: ["9M"],
		},
	},
	vivillon: {
		learnset: {
			acrobatics: ["9M", "7M", "6M"],
			aerialace: ["7M", "6M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			aromatherapy: ["7L31", "6L31"],
			attract: ["7M", "6M"],
			bugbite: ["7T", "6T"],
			bugbuzz: ["9M", "9L35", "7L35", "6L35"],
			calmmind: ["7M", "6M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M", "9L25", "7L25", "6L25"],
			dreameater: ["7M", "6M"],
			electroweb: ["7T", "6T"],
			endeavor: ["7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			gigaimpact: ["9M", "7M", "6M"],
			gust: ["9L0", "7L1", "6L1"],
			hiddenpower: ["7M", "6M"],
			hurricane: ["9M", "9L50", "7L50", "6L50"],
			hyperbeam: ["9M", "7M", "6M"],
			infestation: ["7M", "6M"],
			irondefense: ["9M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L12", "7M", "7L12", "6M", "6L1"],
			poisonpowder: ["9L1", "7L1", "6L1"],
			pollenpuff: ["9M"],
			pounce: ["9M"],
			powder: ["7L1", "6L1"],
			protect: ["9M", "7M", "6M"],
			psybeam: ["9M", "9L17", "7L17", "6L17"],
			psychic: ["9M", "7M", "6M"],
			psychup: ["7M", "6M"],
			quiverdance: ["9L45", "7L45", "6L45"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			roost: ["7M", "6M"],
			round: ["7M", "6M"],
			safeguard: ["9L31", "7M", "7L41", "6M", "6L41"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			sleeppowder: ["9L1", "7L1", "6L1"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			strugglebug: ["9M", "9L1", "7L1", "6M", "6L12"],
			stunspore: ["9L1", "7L1", "6L1"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			supersonic: ["9L21", "7L21", "6L21"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tailwind: ["9M", "7T", "6T"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["9M", "7M", "6M"],
		},
	},
	vivillonfancy: {
		learnset: {
			acrobatics: ["9M", "7M", "6M"],
			aerialace: ["7M", "6M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			aromatherapy: ["7L31", "6L31"],
			attract: ["7M", "6M"],
			bugbite: ["7T", "6T"],
			bugbuzz: ["9M", "9L35", "7L35", "6L35"],
			calmmind: ["7M", "6M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M", "9L25", "7L25", "6L25"],
			dreameater: ["7M", "6M"],
			electroweb: ["7T", "6T"],
			endeavor: ["7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			gigaimpact: ["9M", "7M", "6M"],
			gust: ["9L0", "7L1", "6L1", "6S0"],
			hiddenpower: ["7M", "6M"],
			holdhands: ["6S0"],
			hurricane: ["9M", "9L50", "7L50", "6L50"],
			hyperbeam: ["9M", "7M", "6M"],
			infestation: ["7M", "6M"],
			irondefense: ["9M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L12", "7M", "7L12", "6M", "6L1", "6S0"],
			poisonpowder: ["9L1", "7L1", "6L1"],
			pollenpuff: ["9M"],
			pounce: ["9M"],
			powder: ["7L1", "6L1"],
			protect: ["9M", "7M", "6M"],
			psybeam: ["9M", "9L17", "7L17", "6L17"],
			psychic: ["9M", "7M", "6M"],
			psychup: ["7M", "6M"],
			quiverdance: ["9L45", "7L45", "6L45"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			roost: ["7M", "6M"],
			round: ["7M", "6M"],
			safeguard: ["9L31", "7M", "7L41", "6M", "6L41"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			sleeppowder: ["9L1", "7L1", "6L1"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			strugglebug: ["9M", "9L1", "7L1", "6M", "6L12", "6S0"],
			stunspore: ["9L1", "7L1", "6L1"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			supersonic: ["9L21", "7L21", "6L21"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tailwind: ["9M", "7T", "6T"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["9M", "7M", "6M"],
		},
		eventData: [
			{generation: 6, level: 12, moves: ["gust", "lightscreen", "strugglebug", "holdhands"], pokeball: "cherishball"},
		],
	},
	vivillonpokeball: {
		learnset: {
			acrobatics: ["9M", "7M", "6M"],
			aerialace: ["7M", "6M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			aromatherapy: ["7L31", "6L31"],
			attract: ["7M", "6M"],
			bugbite: ["7T", "6T"],
			bugbuzz: ["9M", "9L35", "7L35", "6L35"],
			calmmind: ["7M", "6M"],
			confide: ["7M", "6M"],
			confuseray: ["9M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M", "9L25", "7L25", "6L25"],
			dreameater: ["7M", "6M"],
			electroweb: ["7T", "6T"],
			endeavor: ["7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			gigaimpact: ["9M", "7M", "6M"],
			gust: ["9L0", "7L1", "6L1", "6S0"],
			hiddenpower: ["7M", "6M"],
			hurricane: ["9M", "9L50", "7L50", "6L50"],
			hyperbeam: ["9M", "7M", "6M"],
			infestation: ["7M", "6M"],
			irondefense: ["9M"],
			laserfocus: ["7T"],
			lightscreen: ["9M", "9L12", "7M", "7L12", "6M", "6L1", "6S0"],
			poisonpowder: ["9L1", "7L1", "6L1"],
			pollenpuff: ["9M"],
			pounce: ["9M"],
			powder: ["7L1", "6L1"],
			protect: ["9M", "7M", "6M"],
			psybeam: ["9M", "9L17", "7L17", "6L17"],
			psychic: ["9M", "7M", "6M"],
			psychup: ["7M", "6M"],
			quiverdance: ["9L45", "7L45", "6L45"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			roost: ["7M", "6M"],
			round: ["7M", "6M"],
			safeguard: ["9L31", "7M", "7L41", "6M", "6L41"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			sleeppowder: ["9L1", "7L1", "6L1"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			strugglebug: ["9M", "9L1", "7L1", "6M", "6L12", "6S0"],
			stunspore: ["9L1", "7L1", "6L1", "6S0"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			supersonic: ["9L21", "7L21", "6L21"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tailwind: ["9M", "7T", "6T"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["9M", "7M", "6M"],
		},
		eventData: [
			{generation: 6, level: 12, moves: ["stunspore", "gust", "lightscreen", "strugglebug"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	litleo: {
		learnset: {
			acrobatics: ["9M"],
			attract: ["7M", "6M"],
			bodyslam: ["9M"],
			bulldoze: ["9M", "7M", "6M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L39", "7L39", "6L39"],
			darkpulse: ["7M", "6M"],
			dig: ["9M", "6M"],
			doubleteam: ["7M", "6M"],
			echoedvoice: ["9L33", "7M", "7L33", "6M", "6L33"],
			ember: ["9L5", "7L5", "6L5"],
			endeavor: ["9L28", "7T", "7L28", "6T", "6L28"],
			endure: ["9M"],
			entrainment: ["9E", "7E", "6E"],
			facade: ["9M", "7M", "6M"],
			fireblast: ["9M", "7M", "6M"],
			firefang: ["9M", "9L23", "7L23", "6L23"],
			firespin: ["9M", "9E", "7E", "6E"],
			flamecharge: ["9M", "7M", "6M"],
			flamethrower: ["9M", "9L36", "7M", "7L36", "6M", "6L36"],
			flareblitz: ["9M", "9E", "7E"],
			frustration: ["7M", "6M"],
			headbutt: ["9L11", "7L11", "6L11"],
			heatwave: ["9M", "7T", "6T"],
			helpinghand: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hypervoice: ["9M", "9L43", "7T", "7L43", "6T", "6L43"],
			incinerate: ["9L46", "7L46", "6M", "6L46"],
			irontail: ["7T", "6T"],
			leer: ["9L1", "7L1", "6L1"],
			mudslap: ["9M"],
			nobleroar: ["9L15", "7L15", "6L15"],
			overheat: ["9M", "9L50", "7M", "7L50", "6M", "6L50"],
			payback: ["7M", "6M"],
			protect: ["9M", "7M", "6M"],
			psychicfangs: ["9M"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			retaliate: ["6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rocksmash: ["6M"],
			round: ["7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "7M", "6M"],
			snarl: ["9M", "7M", "6M"],
			snatch: ["7T", "7E", "6T", "6E"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tackle: ["9L1", "7L1", "6L1"],
			takedown: ["9M", "9L20", "7L20", "6L20"],
			taunt: ["9M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			wildcharge: ["9M", "7M", "6M"],
			willowisp: ["9M", "7M", "6M"],
			workup: ["9L8", "7M", "7L8", "6L8"],
			yawn: ["9E", "7E", "6E"],
		},
	},
	pyroar: {
		learnset: {
			acrobatics: ["9M"],
			attract: ["7M", "6M"],
			bodyslam: ["9M"],
			bounce: ["7T", "6T"],
			bulldoze: ["9M", "7M", "6M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L42", "7L42", "6L42"],
			darkpulse: ["9M", "7M", "6M", "6S0"],
			dig: ["9M", "6M"],
			doubleteam: ["7M", "6M"],
			echoedvoice: ["9L33", "7M", "7L33", "6M", "6L33"],
			ember: ["9L1", "7L1", "6L5"],
			endeavor: ["9L28", "7T", "7L28", "6T", "6L28"],
			endure: ["9M"],
			facade: ["9M", "7M", "6M"],
			fireblast: ["9M", "7M", "6M", "6S0"],
			firefang: ["9M", "9L23", "7L23", "6L23"],
			firespin: ["9M"],
			flamecharge: ["9M", "7M", "6M"],
			flamethrower: ["9M", "9L38", "7M", "7L38", "6M", "6L38"],
			flareblitz: ["9M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "7M", "6M"],
			headbutt: ["9L11", "7L11", "6L11"],
			heatwave: ["9M", "7T", "6T"],
			helpinghand: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["9M", "9L1", "7M", "7L1", "6M", "6L1"],
			hypervoice: ["9M", "9L48", "7T", "7L48", "6T", "6L48", "6S0"],
			incinerate: ["9L51", "7L51", "6M", "6L51"],
			irontail: ["7T", "6T"],
			leer: ["9L1", "7L1", "6L1"],
			mudslap: ["9M"],
			nobleroar: ["9L15", "7L15", "6L15"],
			overheat: ["9M", "9L57", "7M", "7L57", "6M", "6L57"],
			payback: ["7M", "6M"],
			protect: ["9M", "7M", "6M"],
			psychicfangs: ["9M"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			retaliate: ["6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rocksmash: ["6M"],
			round: ["7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "7M", "6M"],
			snarl: ["9M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tackle: ["9L1", "7L1", "6L1"],
			takedown: ["9M", "9L20", "7L20", "6L20"],
			taunt: ["9M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			thunderfang: ["9M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			wildcharge: ["9M", "7M", "6M"],
			willowisp: ["9M", "7M", "6M"],
			workup: ["9L1", "7M", "7L1", "6L8"],
		},
		eventData: [
			{generation: 6, level: 49, gender: "M", perfectIVs: 2, abilities: ["unnerve"], moves: ["hypervoice", "fireblast", "darkpulse"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 6, level: 30},
		],
	},
	flabebe: {
		learnset: {
			afteryou: ["7T", "6T"],
			allyswitch: ["7T"],
			aromatherapy: ["7L33", "6L33"],
			attract: ["7M", "6M"],
			batonpass: ["9M"],
			calmmind: ["9M", "7M", "6M"],
			camouflage: ["7E", "6E"],
			captivate: ["7E", "6E"],
			charm: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			copycat: ["9E", "7E", "6E"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["9M", "7M", "6M"],
			disarmingvoice: ["9M"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M"],
			echoedvoice: ["7M", "6M"],
			endeavor: ["9E", "7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			fairywind: ["9L6", "7L6", "6L6"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			grassknot: ["9M", "7M", "6M"],
			grassyterrain: ["9M", "9L24", "7L24", "6L24"],
			healbell: ["7T", "6T"],
			helpinghand: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			lightscreen: ["9M"],
			luckychant: ["7L10", "6L10"],
			magicalleaf: ["9M", "9L22", "7L22", "6L22"],
			magiccoat: ["7T", "6T"],
			mistyterrain: ["9M", "9L37", "7L37", "6L37"],
			moonblast: ["9L41", "7L41", "6L41"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["9L28", "7L28", "6L28"],
			petaldance: ["9L45", "7L45", "6L45"],
			pollenpuff: ["9M"],
			protect: ["9M", "7M", "6M"],
			psychic: ["9M", "7M", "6M"],
			raindance: ["9M", "7M", "6M"],
			razorleaf: ["9L15", "7L15", "6L15"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["7M", "6M"],
			safeguard: ["9L10", "7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "7T", "6T"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "9L48", "7M", "7L48", "6M", "6L48"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			synthesis: ["9L33", "7T", "6T"],
			tackle: ["9L1", "7L1", "6L1"],
			tearfullook: ["9E", "7E"],
			terablast: ["9M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			vinewhip: ["9L1", "7L1", "6L1"],
			wish: ["9L20", "7L20", "6L20"],
			worryseed: ["7T", "6T"],
		},
	},
	floette: {
		learnset: {
			afteryou: ["7T", "6T"],
			allyswitch: ["7T"],
			aromatherapy: ["7L38", "6L38"],
			attract: ["7M", "6M"],
			batonpass: ["9M"],
			calmmind: ["9M", "7M", "6M"],
			charm: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["9M", "7M", "6M"],
			disarmingvoice: ["9M"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M"],
			echoedvoice: ["7M", "6M"],
			endeavor: ["7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			fairywind: ["9L1", "7L1", "6L6"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			grassknot: ["9M", "7M", "6M"],
			grassyterrain: ["9M", "9L27", "7L27", "6L27"],
			healbell: ["7T", "6T"],
			helpinghand: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			lightscreen: ["9M"],
			luckychant: ["7L10", "6L10"],
			magicalleaf: ["9M", "9L25", "7L25", "6L25"],
			magiccoat: ["7T", "6T"],
			metronome: ["9M"],
			mistyterrain: ["9M", "9L43", "7L43", "6L43"],
			moonblast: ["9L46", "7L46", "6L46"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["9L33", "7L33", "6L33"],
			petaldance: ["9L51", "7L51", "6L51"],
			pollenpuff: ["9M"],
			protect: ["9M", "7M", "6M"],
			psychic: ["9M", "7M", "6M"],
			raindance: ["9M", "7M", "6M"],
			razorleaf: ["9L15", "7L15", "6L15"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["7M", "6M"],
			safeguard: ["9L10", "7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "7T", "6T"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "9L58", "7M", "7L58", "6M", "6L58"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			synthesis: ["9L38", "7T", "6T"],
			tackle: ["9L1", "7L1", "6L1"],
			terablast: ["9M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			trick: ["9M"],
			vinewhip: ["9L1", "7L1", "6L1"],
			wish: ["9L20", "7L20", "6L20"],
			worryseed: ["7T", "6T"],
		},
	},
	floetteeternal: {
		learnset: {
			afteryou: ["7T"],
			allyswitch: ["7T"],
			aromatherapy: ["6L38"],
			attract: ["6M"],
			calmmind: ["6M"],
			confide: ["6M"],
			covet: ["7T"],
			dazzlinggleam: ["6M"],
			doubleteam: ["6M"],
			echoedvoice: ["6M"],
			endeavor: ["7T"],
			energyball: ["6M"],
			facade: ["6M"],
			fairywind: ["6L6"],
			flash: ["6M"],
			frustration: ["6M"],
			gigadrain: ["7T"],
			grassknot: ["6M"],
			grassyterrain: ["6L27"],
			healbell: ["7T"],
			helpinghand: ["7T"],
			hiddenpower: ["6M"],
			lightofruin: ["6L50"],
			luckychant: ["6L10"],
			magicalleaf: ["6L25"],
			magiccoat: ["7T"],
			mistyterrain: ["6L43"],
			moonblast: ["6L46"],
			naturepower: ["6M"],
			petalblizzard: ["6L33"],
			petaldance: ["6L51"],
			protect: ["6M"],
			psychic: ["6M"],
			raindance: ["6M"],
			razorleaf: ["6L15"],
			rest: ["6M"],
			return: ["6M"],
			round: ["6M"],
			safeguard: ["6M"],
			secretpower: ["6M"],
			seedbomb: ["7T"],
			sleeptalk: ["6M"],
			snore: ["7T"],
			solarbeam: ["6M", "6L58"],
			substitute: ["6M"],
			sunnyday: ["6M"],
			swagger: ["6M"],
			synthesis: ["7T"],
			tackle: ["6L1"],
			toxic: ["6M"],
			vinewhip: ["6L1"],
			wish: ["6L20"],
			worryseed: ["7T"],
		},
	},
	florges: {
		learnset: {
			afteryou: ["7T", "6T"],
			allyswitch: ["7T"],
			aromatherapy: ["7L1", "6L1"],
			attract: ["7M", "6M"],
			batonpass: ["9M"],
			calmmind: ["9M", "7M", "6M"],
			charm: ["9M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["9M", "7M", "6M"],
			defog: ["7T"],
			disarmingvoice: ["9M", "9L1", "7L1", "6L1"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M"],
			echoedvoice: ["7M", "6M"],
			endeavor: ["7T", "6T"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			flash: ["6M"],
			flowershield: ["7L1", "6L1"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			gigaimpact: ["9M", "7M", "6M"],
			grassknot: ["9M", "9L1", "7M", "7L1", "6M", "6L1"],
			grassyterrain: ["9M", "9L1", "7L1", "6L1"],
			healbell: ["7T", "6T"],
			helpinghand: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["9M", "7M", "6M"],
			lightscreen: ["9M", "7M", "6M"],
			luckychant: ["7L1", "6L1"],
			magicalleaf: ["9M", "9L1", "7L1", "6L1"],
			magiccoat: ["7T", "6T"],
			metronome: ["9M"],
			mistyterrain: ["9M", "9L1", "7L1", "6L1"],
			moonblast: ["9L5", "7L1", "6L1"],
			naturepower: ["7M", "6M"],
			petalblizzard: ["9L1", "7L1", "6L1"],
			petaldance: ["9L1", "7L1", "6L1"],
			pollenpuff: ["9M"],
			protect: ["9M", "7M", "6M"],
			psychic: ["9M", "7M", "6M"],
			raindance: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["7M", "6M"],
			safeguard: ["9L1", "7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "7T", "6T"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "9L1", "7M", "6M"],
			storedpower: ["9M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			synthesis: ["9L1", "7T", "6T"],
			terablast: ["9M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			trick: ["9M"],
			wish: ["9L1", "7L1", "6L1"],
			worryseed: ["7T", "6T"],
		},
	},
	skiddo: {
		learnset: {
			attract: ["7M", "6M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "7M", "6M"],
			bulkup: ["9M", "9L34", "7M", "7L34", "6M", "6L34"],
			bulldoze: ["9M", "9L26", "7M", "7L26", "6M", "6L26"],
			bulletseed: ["9M"],
			confide: ["7M", "6M"],
			defensecurl: ["9E", "7E", "6E"],
			dig: ["9M", "6M"],
			doubleedge: ["9L38", "7L38", "6L38"],
			doubleteam: ["7M", "6M"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			grassknot: ["9M", "7M", "6M"],
			grassyterrain: ["9M", "9E", "7E"],
			growth: ["9L1", "7L1", "6L1"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M"],
			hornleech: ["9L42", "7L42", "6L42"],
			irontail: ["7T", "6T"],
			leafblade: ["9L45", "7L45", "6L45"],
			leafstorm: ["9M"],
			leechseed: ["9L12", "7L12", "6L12"],
			magicalleaf: ["9M"],
			milkdrink: ["9E", "7L50", "7E", "6L50", "6E"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			naturepower: ["7M", "6M"],
			payback: ["7M", "6M"],
			playrough: ["9M"],
			protect: ["9M", "7M", "6M"],
			raindance: ["9M", "7M", "6M"],
			razorleaf: ["9L13", "7L13", "6L13"],
			rest: ["9M", "7M", "6M"],
			retaliate: ["6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockslide: ["9M", "7M", "6M"],
			rocksmash: ["6M"],
			rollout: ["9E", "7E", "6E"],
			round: ["7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "9L30", "7T", "7L30", "6T", "6L30"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			stompingtantrum: ["9M"],
			strength: ["6M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			surf: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			synthesis: ["9L20", "7T", "7L20", "6T", "6L20"],
			tackle: ["9L1", "7L1", "6L1"],
			tailwhip: ["9L9", "7L9", "6L9"],
			takedown: ["9M", "9L22", "7L22", "6L22"],
			terablast: ["9M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			vinewhip: ["9L7", "7L7", "6L7"],
			wildcharge: ["9M", "7M", "6M"],
			workup: ["7M"],
			worryseed: ["9L16", "7T", "7L16", "6T", "6L16"],
			zenheadbutt: ["9M", "7T", "6T"],
		},
	},
	gogoat: {
		learnset: {
			aerialace: ["9M", "9L0", "7M", "7L1", "6M", "6L1"],
			attract: ["7M", "6M"],
			bodyslam: ["9M"],
			bounce: ["7T", "6T"],
			brickbreak: ["9M", "7M", "6M"],
			bulkup: ["9M", "9L34", "7M", "7L34", "6M", "6L34"],
			bulldoze: ["9M", "9L26", "7M", "7L26", "6M", "6L26"],
			bulletseed: ["9M"],
			confide: ["7M", "6M"],
			dig: ["9M", "6M"],
			doubleedge: ["9L40", "7L40", "6L40"],
			doubleteam: ["7M", "6M"],
			earthquake: ["9M", "9L1", "7M", "7L1", "6M", "6L60"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigadrain: ["9M", "7T", "6T"],
			gigaimpact: ["9M", "7M", "6M"],
			grassknot: ["9M", "7M", "6M"],
			grassyterrain: ["9M"],
			growth: ["9L1", "7L1", "6L1"],
			helpinghand: ["9M"],
			hiddenpower: ["7M", "6M"],
			hornleech: ["9L47", "7L47", "6L47"],
			hyperbeam: ["9M", "7M", "6M"],
			irontail: ["7T", "6T"],
			leafblade: ["9L55", "7L55", "6L55"],
			leafstorm: ["9M"],
			leechseed: ["9L12", "7L12", "6L12"],
			magicalleaf: ["9M"],
			milkdrink: ["9L58", "7L58", "6L58"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			naturepower: ["7M", "6M"],
			payback: ["7M", "6M"],
			playrough: ["9M"],
			protect: ["9M", "7M", "6M"],
			raindance: ["9M", "7M", "6M"],
			razorleaf: ["9L13", "7L13", "6L13"],
			rest: ["9M", "7M", "6M"],
			retaliate: ["6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockslide: ["9M", "7M", "6M"],
			rocksmash: ["6M"],
			round: ["7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["9M", "9L30", "7T", "7L30", "6T", "6L30"],
			sleeptalk: ["9M", "7M", "6M"],
			snore: ["7T", "6T"],
			solarbeam: ["9M", "7M", "6M"],
			stompingtantrum: ["9M", "7T"],
			strength: ["6M"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			superpower: ["7T", "6T"],
			surf: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			synthesis: ["9L20", "7T", "7L20", "6T", "6L20"],
			tackle: ["9L1", "7L1", "6L1"],
			tailwhip: ["9L1", "7L1", "6L9"],
			takedown: ["9M", "9L22", "7L22", "6L22"],
			terablast: ["9M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			vinewhip: ["9L1", "7L1", "6L7"],
			wildcharge: ["9M", "7M", "6M"],
			workup: ["7M"],
			worryseed: ["9L16", "7T", "7L16", "6T", "6L16"],
			zenheadbutt: ["9M", "7T", "6T"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	pancham: {
		learnset: {
			aerialace: ["7M", "6M"],
			armthrust: ["8L4", "7L7", "6L7", "6S0"],
			attract: ["8M", "7M", "6M"],
			block: ["7T", "6T"],
			bodyslam: ["8M", "8L36", "7L33", "6L33"],
			brickbreak: ["8M", "7M", "6M"],
			bulkup: ["8M", "7M", "6M"],
			bulldoze: ["8M", "7M", "6M"],
			circlethrow: ["8L12", "7L25", "6L25"],
			coaching: ["8T"],
			cometpunch: ["7L15", "6L15"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			crunch: ["8M", "8L33", "7L39", "6L39"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M", "6S0"],
			dig: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			drainpunch: ["8M", "7T", "6T"],
			dualchop: ["7T", "6T"],
			echoedvoice: ["7M", "6M"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			entrainment: ["8L44", "7L42", "6L42"],
			facade: ["8M", "7M", "6M"],
			falseswipe: ["8M", "7M", "6M"],
			firepunch: ["8M", "7T", "6T"],
			fling: ["8M", "7M", "6M"],
			focuspunch: ["7T", "6T"],
			foulplay: ["8M", "7T", "7E", "6T", "6E"],
			frustration: ["7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			gunkshot: ["8M", "7T", "6T"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hypervoice: ["8M", "7T", "6T"],
			icepunch: ["8M", "7T", "6T"],
			ironhead: ["8M", "7T", "6T"],
			karatechop: ["7L12", "6L12"],
			knockoff: ["7T", "6T"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1"],
			lowkick: ["8M", "7T", "6T"],
			lowsweep: ["8M", "8L16", "7M", "6M"],
			mefirst: ["7E", "6E"],
			megakick: ["8M"],
			megapunch: ["8M"],
			partingshot: ["8L40", "7L45", "6L45"],
			payback: ["8M", "7M", "6M"],
			powertrip: ["8E", "7E"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			quash: ["8E", "7E", "6E"],
			quickguard: ["8E", "7E", "6E"],
			raindance: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M", "6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			seismictoss: ["8E"],
			shadowclaw: ["8M", "7M", "6M"],
			skyuppercut: ["7L48", "6L48"],
			slash: ["8L24", "7L20", "6L20"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			spite: ["7T", "6T"],
			stoneedge: ["8M", "7M", "6M", "6S0"],
			stormthrow: ["8E", "7E", "6E"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["8M", "7M", "6M"],
			tackle: ["8L1", "7L1", "6L1"],
			taunt: ["8M", "8L8"],
			thunderpunch: ["8M", "7T", "6T"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["8M", "7T", "6T"],
			vitalthrow: ["8L28", "7L27", "6L27"],
			workup: ["8M", "8L20", "7M", "7L10", "6L10"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 6, level: 30, gender: "M", nature: "Adamant", abilities: ["moldbreaker"], moves: ["armthrust", "stoneedge", "darkpulse"], pokeball: "cherishball"},
		],
	},
	pangoro: {
		learnset: {
			aerialace: ["7M", "6M"],
			armthrust: ["8L1", "7L1", "6L7"],
			attract: ["8M", "7M", "6M"],
			beatup: ["8M"],
			block: ["7T", "6T"],
			bodyslam: ["8M", "8L40", "7L35", "6L35"],
			brickbreak: ["8M", "7M", "6M"],
			bulkup: ["8M", "7M", "6M"],
			bulldoze: ["8M", "7M", "6M"],
			bulletpunch: ["8L1", "7L1"],
			circlethrow: ["8L12", "7L25", "6L25"],
			closecombat: ["8M"],
			coaching: ["8T"],
			cometpunch: ["7L15", "6L15"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			crunch: ["8M", "8L35", "7L42", "6L42"],
			cut: ["6M"],
			darkestlariat: ["8M"],
			darkpulse: ["8M", "7M", "6M"],
			dig: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			dragonclaw: ["8M", "7M", "6M"],
			drainpunch: ["8M", "7T", "6T"],
			dualchop: ["7T", "6T"],
			earthquake: ["8M", "7M", "6M"],
			echoedvoice: ["7M", "6M"],
			embargo: ["7M", "6M"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			entrainment: ["8L52", "7L1", "6L1"],
			facade: ["8M", "7M", "6M"],
			falseswipe: ["8M", "7M", "6M"],
			firepunch: ["8M", "7T", "6T"],
			fling: ["8M", "7M", "6M"],
			focusblast: ["8M", "7M", "6M"],
			focusenergy: ["8M"],
			focuspunch: ["7T", "6T"],
			foulplay: ["8M", "7T", "6T"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			gunkshot: ["8M", "7T", "6T"],
			hammerarm: ["8L58", "7L1", "6L1"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["6M"],
			hyperbeam: ["8M", "7M", "6M"],
			hypervoice: ["8M", "7T", "6T"],
			icepunch: ["8M", "7T", "6T"],
			infestation: ["7M", "6M"],
			ironhead: ["8M", "7T", "6T"],
			karatechop: ["7L12", "6L12"],
			knockoff: ["7T", "6T"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "6L1"],
			lowkick: ["8M", "7T", "6T"],
			lowsweep: ["8M", "8L16", "7M", "7L1", "6M", "6L70"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nightslash: ["8L0"],
			outrage: ["8M", "7T", "6T"],
			partingshot: ["8L46", "7L48", "6L48"],
			payback: ["8M", "7M", "6M"],
			poisonjab: ["8M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			quash: ["7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M", "6M"],
			return: ["7M", "6M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M"],
			skyuppercut: ["7L52", "6L52"],
			slash: ["8L24", "7L20", "6L20"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			snarl: ["8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			spite: ["7T", "6T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["8M", "7M", "6M"],
			tackle: ["8L1", "7L1", "6L1"],
			taunt: ["8M", "8L1", "7M", "7L65", "6M", "6L65"],
			thief: ["8M", "7M", "6M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "7T", "6T"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["8M", "7T", "6T"],
			vitalthrow: ["8L28", "7L27", "6L27"],
			workup: ["8M", "8L20", "7M", "7L1", "6L10"],
			xscissor: ["8M", "7M", "6M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		encounters: [
			{generation: 7, level: 24},
		],
	},
	furfrou: {
		learnset: {
			attract: ["7M", "6M"],
			babydolleyes: ["7L9", "6L9"],
			bite: ["7L22", "6L22"],
			captivate: ["7E", "6E"],
			chargebeam: ["7M", "6M"],
			charm: ["7L38", "6L38"],
			confide: ["7M", "6M"],
			cottonguard: ["7L48", "6L48"],
			darkpulse: ["7M", "6M"],
			dig: ["6M"],
			doubleteam: ["7M", "6M"],
			echoedvoice: ["7M", "6M"],
			endeavor: ["7T", "6T"],
			facade: ["7M", "6M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["7M", "6M"],
			grassknot: ["7M", "6M"],
			growl: ["7L1", "6L1"],
			headbutt: ["7L12", "6L12"],
			helpinghand: ["7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hypervoice: ["7T", "6T"],
			irontail: ["7T", "6T"],
			lastresort: ["7T", "6T"],
			mimic: ["7E", "6E"],
			odorsleuth: ["7L27", "6L27"],
			protect: ["7M", "6M"],
			raindance: ["7M", "6M"],
			refresh: ["7E", "6E"],
			rest: ["7M", "6M"],
			retaliate: ["7L33", "6M", "6L33"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rocksmash: ["6M"],
			roleplay: ["7T", "7E", "6T", "6E"],
			round: ["7M", "6M"],
			sandattack: ["7L5", "6L5"],
			secretpower: ["6M"],
			sleeptalk: ["7M", "6M"],
			snarl: ["7M", "6M"],
			snore: ["7T", "6T"],
			substitute: ["7M", "6M"],
			suckerpunch: ["7L42", "6L42"],
			sunnyday: ["7M", "6M"],
			surf: ["7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["7L1", "6L1"],
			tailwhip: ["7L15", "6L15"],
			takedown: ["7L35", "6L35"],
			thunderwave: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["7T", "6T"],
			uturn: ["7M", "6M"],
			wildcharge: ["7M", "6M"],
			workup: ["7M", "7E", "6E"],
			zenheadbutt: ["7T", "6T"],
		},
	},
	espurr: {
		learnset: {
			allyswitch: ["8M", "7T"],
			assist: ["7E", "6E"],
			attract: ["8M", "7M", "6M"],
			barrier: ["7E", "6E"],
			calmmind: ["8M", "7M", "6M"],
			chargebeam: ["7M", "6M"],
			confide: ["7M", "6M"],
			confusion: ["8L9", "7L9", "6L9"],
			covet: ["8L18", "7T", "7L5", "6T", "6L5"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			disarmingvoice: ["8L6", "7L22", "6L22"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			echoedvoice: ["7M", "6M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M"],
			fakeout: ["8L3", "7L19", "6L19"],
			faketears: ["8M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gravity: ["7T", "6T"],
			healbell: ["7T", "6T"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			irontail: ["8M", "7T", "6T"],
			leer: ["8L1", "7L1", "6L1"],
			lightscreen: ["8M", "8L30", "7M", "7L13", "6M", "6L13"],
			magiccoat: ["7T", "6T"],
			magicroom: ["8M", "7T", "6T"],
			nastyplot: ["8M"],
			payback: ["8M", "7M", "6M"],
			payday: ["8M"],
			playrough: ["8M"],
			protect: ["8M", "7M", "6M"],
			psybeam: ["8L21", "7L17", "6L17"],
			psychic: ["8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			psyshock: ["8M", "8L33", "7M", "7L25", "6M", "6L25"],
			raindance: ["8M", "7M", "6M"],
			recycle: ["7T", "6T"],
			reflect: ["8M", "8L30", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			roleplay: ["7T", "6T"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			scratch: ["8L1", "7L1", "6L1"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T"],
			skillswap: ["8M", "7T"],
			sleeptalk: ["8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			telekinesis: ["7T"],
			thunderbolt: ["8M", "7M", "6M"],
			thunderwave: ["8M", "7M", "6M"],
			tickle: ["8E"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["8M", "7T", "7E", "6T", "6E"],
			trickroom: ["8M", "7M", "6M"],
			wonderroom: ["8M", "7T", "6T"],
			workup: ["8M", "7M"],
			yawn: ["8E", "7E", "6E"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	meowstic: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M"],
			calmmind: ["8M", "7M", "6M"],
			chargebeam: ["7M", "6M"],
			charm: ["8M", "8L15", "7L28", "6L28"],
			confide: ["7M", "6M"],
			confusion: ["8L9", "7L1", "6L9"],
			covet: ["8L18", "7T", "7L1", "6T", "6L5"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			dig: ["8M", "6M"],
			disarmingvoice: ["8L1", "7L22", "6L22"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			echoedvoice: ["7M", "6M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M"],
			fakeout: ["8L1", "7L19", "6L19"],
			faketears: ["8M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			gravity: ["7T", "6T"],
			healbell: ["7T", "6T"],
			helpinghand: ["8M", "8L12", "7T", "7L1", "6T", "6L1"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			imprison: ["8M", "8L44", "7L45", "6L45"],
			irontail: ["8M", "7T", "6T"],
			leer: ["8L1", "7L1", "6L1"],
			lightscreen: ["8M", "8L34", "7M", "7L13", "6M", "6L13"],
			magiccoat: ["7T", "6T"],
			magicroom: ["8M", "7T", "6T"],
			meanlook: ["8L1", "7L1", "6L1"],
			miracleeye: ["7L31", "6L31"],
			mistyterrain: ["8M", "8L59", "7L50", "6L50"],
			nastyplot: ["8M"],
			payback: ["8M", "7M", "6M"],
			payday: ["8M"],
			playrough: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			psybeam: ["8L21", "7L17", "6L17"],
			psychic: ["8M", "8L54", "7M", "7L40", "6M", "6L40"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M"],
			psyshock: ["8M", "8L39", "7M", "7L25", "6M", "6L25"],
			quickguard: ["8L49", "7L1", "6L1"],
			raindance: ["8M", "7M", "6M"],
			recycle: ["7T", "6T"],
			reflect: ["8M", "8L34", "7M", "7L35", "6M", "6L35"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			roleplay: ["8L29", "7T", "7L43", "6T", "6L43"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			scratch: ["8L1", "7L1", "6L1"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T"],
			skillswap: ["8M", "7T"],
			sleeptalk: ["8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			substitute: ["8M", "7M", "6M"],
			suckerpunch: ["8L24", "7L48", "6L48"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			tailslap: ["8M"],
			telekinesis: ["7T"],
			thunderbolt: ["8M", "7M", "6M"],
			thunderwave: ["8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["8M", "7T", "6T"],
			trickroom: ["8M", "7M", "6M"],
			wonderroom: ["8M", "7T", "6T"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	meowsticf: {
		learnset: {
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M"],
			calmmind: ["8M", "7M", "6M"],
			chargebeam: ["8L15", "7M", "7L28", "6M", "6L28"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			confusion: ["8L9", "7L1", "6L9"],
			covet: ["8L18", "7T", "7L1", "6L5"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			dig: ["8M", "6M"],
			disarmingvoice: ["8L1", "7L22", "6L22"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			echoedvoice: ["7M", "6M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			expandingforce: ["8T"],
			extrasensory: ["8L44", "7L35", "6L35"],
			facade: ["8M", "7M", "6M"],
			fakeout: ["8L1", "7L19", "6L19"],
			faketears: ["8M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			futuresight: ["8M", "8L59", "7L50", "6L50"],
			gigaimpact: ["8M", "7M", "6M"],
			gravity: ["7T"],
			healbell: ["7T"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			irontail: ["8M", "7T"],
			leer: ["8L1", "7L1", "6L1"],
			lightscreen: ["8M", "8L34", "7M", "7L13", "6M", "6L13"],
			magicalleaf: ["8M", "8L1", "7L1", "6L1"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			mefirst: ["7L1", "6L1"],
			nastyplot: ["8M"],
			payback: ["8M", "7M", "6M"],
			payday: ["8M"],
			playrough: ["8M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			psybeam: ["8L21", "7L17", "6L17"],
			psychic: ["8M", "8L54", "7M", "7L40", "6M", "6L40"],
			psychicterrain: ["8M"],
			psychup: ["7M", "6M"],
			psyshock: ["8M", "8L39", "7M", "7L25", "6M", "6L25"],
			raindance: ["8M", "7M", "6M"],
			recycle: ["7T"],
			reflect: ["8M", "8L34", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			roleplay: ["8L29", "7T", "7L43", "6L43"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			scratch: ["8L1", "7L1", "6L1"],
			secretpower: ["6M"],
			shadowball: ["8M", "8L49", "7M", "7L31", "6M", "6L31"],
			shockwave: ["7T"],
			signalbeam: ["7T", "7L45", "6L45"],
			skillswap: ["8M", "7T"],
			sleeptalk: ["8M", "7M", "6M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			storedpower: ["8M", "8L12", "7L1", "6L1"],
			substitute: ["8M", "7M", "6M"],
			suckerpunch: ["8L24", "7L48", "6L48"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			tailslap: ["8M"],
			telekinesis: ["7T"],
			thunderbolt: ["8M", "7M", "6M"],
			thunderwave: ["8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["8M", "7T"],
			trickroom: ["8M", "7M", "6M"],
			wonderroom: ["8M", "7T"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	honedge: {
		learnset: {
			aerialace: ["8L12", "7M", "7L22", "6M", "6L22"],
			afteryou: ["7T", "6T"],
			attract: ["8M", "7M", "6M"],
			autotomize: ["8L8", "7L18", "6L18"],
			block: ["8E"],
			brickbreak: ["8M", "7M", "6M"],
			brutalswing: ["8M", "7M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			destinybond: ["8E", "7E", "6E"],
			doubleteam: ["7M", "6M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			falseswipe: ["8M", "7M", "6M"],
			flashcannon: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			furycutter: ["8L1", "7L5", "6L5"],
			gyroball: ["8M", "7M", "6M"],
			hiddenpower: ["7M", "6M"],
			irondefense: ["8M", "8L32", "7T", "7L32", "6T", "6L32"],
			ironhead: ["8M", "8L36", "7T", "7L42", "6T", "6L42"],
			laserfocus: ["7T"],
			magnetrise: ["7T", "6T"],
			metalsound: ["8L16", "7L8", "7E", "6L8", "6E"],
			nightslash: ["8L24", "7L35", "6L35"],
			powertrick: ["8L40", "7L39", "6L39"],
			protect: ["8M", "7M", "6M"],
			psychocut: ["8M"],
			pursuit: ["7L13", "6L13"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M", "8L28", "7L26", "6M", "6L26"],
			return: ["7M", "6M"],
			reversal: ["8M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			round: ["8M"],
			sacredsword: ["8L48", "7L47", "6L47"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M"],
			shadowsneak: ["8L4", "7L20", "7E", "6L20", "6E"],
			shockwave: ["7T", "6T"],
			slash: ["8L20", "7L29", "6L29"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			solarblade: ["8M"],
			spite: ["7T", "6T"],
			steelbeam: ["8T"],
			substitute: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["8M", "8L44", "7M", "7L1", "6M", "6L1"],
			tackle: ["8L1", "7L1", "6L1"],
			toxic: ["7M", "6M"],
			wideguard: ["8E", "7E", "6E"],
		},
	},
	doublade: {
		learnset: {
			aerialace: ["8L12", "7M", "7L22", "6M", "6L22"],
			afteryou: ["7T", "6T"],
			attract: ["8M", "7M", "6M"],
			autotomize: ["8L1", "7L18", "6L18"],
			brickbreak: ["8M", "7M", "6M"],
			brutalswing: ["8M", "7M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			doubleteam: ["7M", "6M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			falseswipe: ["8M", "7M", "6M"],
			flashcannon: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			furycutter: ["8L1", "7L1", "6L5"],
			gyroball: ["8M", "7M", "6M"],
			hiddenpower: ["7M", "6M"],
			irondefense: ["8M", "8L32", "7T", "7L32", "6T", "6L32"],
			ironhead: ["8M", "8L38", "7T", "7L45", "6T", "6L45"],
			laserfocus: ["7T"],
			magnetrise: ["7T", "6T"],
			metalsound: ["8L16", "7L8", "6L8"],
			nightslash: ["8L24", "7L36", "6L36"],
			powertrick: ["8L44", "7L41", "6L41"],
			protect: ["8M", "7M", "6M"],
			psychocut: ["8M"],
			pursuit: ["7L13", "6L13"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M", "8L28", "7L26", "6M", "6L26"],
			return: ["7M", "6M"],
			reversal: ["8M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			round: ["8M"],
			sacredsword: ["8L56", "7L51", "6L51"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M"],
			shadowsneak: ["8L1", "7L20", "6L20"],
			shockwave: ["7T", "6T"],
			slash: ["8L20", "7L29", "6L29"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			solarblade: ["8M"],
			spite: ["7T", "6T"],
			steelbeam: ["8T"],
			substitute: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["8M", "8L50", "7M", "7L1", "6M", "6L1"],
			tackle: ["8L1", "7L1", "6L1"],
			toxic: ["7M", "6M"],
		},
	},
	aegislash: {
		learnset: {
			aerialace: ["8L1", "7M", "7L1", "6M", "6L1"],
			afteryou: ["7T", "6T"],
			airslash: ["8M"],
			attract: ["8M", "7M", "6M"],
			autotomize: ["8L1", "7L1", "6L1"],
			block: ["7T", "6T"],
			brickbreak: ["8M", "7M", "6M"],
			brutalswing: ["8M", "7M"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			doubleteam: ["7M", "6M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			falseswipe: ["8M", "7M", "6M"],
			flashcannon: ["8M", "7M", "6M", "6S0"],
			frustration: ["7M", "6M"],
			furycutter: ["8L1", "7L1", "6L1"],
			gigaimpact: ["8M", "7M", "6M"],
			gyroball: ["8M", "7M", "6M"],
			headsmash: ["8L1", "7L1", "6L1"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			irondefense: ["8M", "8L1", "7T", "7L1", "6T", "6L1"],
			ironhead: ["8M", "8L1", "7T", "7L1", "6T", "6L1"],
			kingsshield: ["8L0", "7L1", "6L1", "6S0"],
			laserfocus: ["7T"],
			magnetrise: ["7T", "6T"],
			metalsound: ["8L1"],
			nightslash: ["8L1", "7L1", "6L1"],
			powertrick: ["8L1", "7L1", "6L1"],
			protect: ["8M", "7M", "6M"],
			psychocut: ["8M"],
			pursuit: ["7L1", "6L1"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M", "8L1", "6M"],
			return: ["7M", "6M"],
			reversal: ["8M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			round: ["8M", "7M", "6M"],
			sacredsword: ["8L1", "7L1", "6L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M", "6S0"],
			shadowclaw: ["8M", "7M", "6M"],
			shadowsneak: ["8L1", "7L1", "6L1"],
			shockwave: ["7T", "6T"],
			slash: ["8L1", "7L1", "6L1"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			solarblade: ["8M"],
			spite: ["7T", "6T"],
			steelbeam: ["8T"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["8M", "8L1", "7M", "7L1", "6M", "6L1"],
			tackle: ["8L1"],
			toxic: ["7M", "6M"],
			wideguard: ["6S0"],
		},
		eventData: [
			{generation: 6, level: 50, gender: "F", nature: "Quiet", moves: ["wideguard", "kingsshield", "shadowball", "flashcannon"], pokeball: "cherishball"},
		],
	},
	spritzee: {
		learnset: {
			afteryou: ["8E", "7T", "6T"],
			allyswitch: ["8M", "7T"],
			aromatherapy: ["8L12", "7L25", "6L25"],
			attract: ["8M", "8L18", "7M", "7L29", "6M", "6L29"],
			calmmind: ["8M", "8L33", "7M", "7L17", "6M", "6L17"],
			captivate: ["7E", "6E"],
			chargebeam: ["7M", "6M"],
			charm: ["8M", "8L30", "7L35", "6L35"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			disable: ["8E", "7E", "6E"],
			disarmingvoice: ["7L50", "6L50"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["8M", "8L9", "7L21", "6L21"],
			dreameater: ["7M", "6M"],
			echoedvoice: ["8L6", "7M", "7L13", "6M", "6L13"],
			encore: ["8M"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			facade: ["8M", "7M", "6M"],
			fairywind: ["8L1", "7L1", "6L1"],
			faketears: ["8M"],
			flail: ["8L21", "7L38", "6L38"],
			flash: ["6M"],
			flashcannon: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gyroball: ["8M", "7M", "6M"],
			healbell: ["7T", "6T"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			lightscreen: ["8M", "7M", "6M"],
			magiccoat: ["7T", "6T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M", "8L24", "7L42", "6L42"],
			moonblast: ["8L36", "7L31", "6L31"],
			nastyplot: ["8M", "7E"],
			odorsleuth: ["7L8", "6L8"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "8L27", "7M", "7L48", "6M", "6L48"],
			psychup: ["7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			refresh: ["7E", "6E"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			skillswap: ["8M", "8L39", "7T", "7L44", "6T", "6L44"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			sweetkiss: ["8L3", "7L6", "6L6"],
			sweetscent: ["8L1", "7L1", "6L1"],
			telekinesis: ["7T"],
			thunderbolt: ["8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trickroom: ["8M", "7M", "6M"],
			wish: ["8E", "7E", "6E"],
		},
	},
	aromatisse: {
		learnset: {
			afteryou: ["7T", "6T"],
			allyswitch: ["8M", "7T"],
			aromatherapy: ["8L12", "7L25", "6L25"],
			aromaticmist: ["8L1", "7L1", "6L1"],
			attract: ["8M", "8L18", "7M", "7L29", "6M", "6L29"],
			calmmind: ["8M", "8L33", "7M", "7L17", "6M", "6L17"],
			chargebeam: ["7M", "6M"],
			charm: ["8M", "8L30", "7L35", "6L35"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			disable: ["6S0"],
			disarmingvoice: ["8L9", "7L53", "6L53"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["8M", "8L15", "7L21", "6L21"],
			drainpunch: ["8M", "7T", "6T"],
			dreameater: ["7M", "6M"],
			echoedvoice: ["8L1", "7M", "7L13", "6M", "6L13"],
			encore: ["8M"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			facade: ["8M", "7M", "6M"],
			fairywind: ["8L1", "7L1", "6L1"],
			faketears: ["8M"],
			flail: ["8L21", "7L38", "6L38"],
			flash: ["6M"],
			flashcannon: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			gyroball: ["8M", "7M", "6M"],
			healbell: ["7T", "6T"],
			healpulse: ["8L1", "7L1", "6L1", "6S0"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			lightscreen: ["8M", "7M", "6M"],
			magiccoat: ["7T", "6T"],
			metronome: ["8M"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M", "8L24", "7L42", "6L42"],
			moonblast: ["8L36", "7L31", "6L31", "6S0"],
			nastyplot: ["8M"],
			odorsleuth: ["7L1", "6L8"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "8L27", "7M", "7L48", "6M", "6L48"],
			psychup: ["8L42", "7M", "7L64", "6M", "6L64"],
			psyshock: ["8M", "7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "7L57", "6M", "6L57"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			skillswap: ["8M", "8L39", "7T", "7L44", "6T", "6L44"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			sweetkiss: ["8L1", "7L1", "6L6"],
			sweetscent: ["8L1", "7L1", "6L1"],
			telekinesis: ["7T"],
			thunder: ["8M", "7M", "6M"],
			thunderbolt: ["8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trickroom: ["8M", "7M", "6M", "6S0"],
		},
		eventData: [
			{generation: 6, level: 50, nature: "Relaxed", isHidden: true, moves: ["trickroom", "healpulse", "disable", "moonblast"], pokeball: "cherishball"},
		],
	},
	swirlix: {
		learnset: {
			afteryou: ["8E", "7T", "7E", "6T", "6E"],
			amnesia: ["8M"],
			aromatherapy: ["8L9", "7L26", "6L26"],
			attract: ["8M", "7M", "6M"],
			bellydrum: ["7E", "6E"],
			calmmind: ["8M", "7M", "6M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			copycat: ["8E", "7E", "6E"],
			cottonguard: ["8L36", "7L41", "6L41"],
			cottonspore: ["8L24", "7L17", "6L17"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["8M", "8L12", "7L31", "6L31"],
			dreameater: ["7M", "6M"],
			endeavor: ["8L39", "7T", "7L21", "6T", "6L21"],
			endure: ["8M"],
			energyball: ["8M", "8L27", "7M", "7L36", "6M", "6L36"],
			facade: ["8M", "7M", "6M"],
			fairywind: ["8L6", "7L5", "6L5"],
			faketears: ["8M", "8L15", "7L10", "6L10"],
			flamethrower: ["8M", "7M", "6M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gastroacid: ["7T", "6T"],
			healbell: ["7T", "6T"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			lightscreen: ["8M", "7M", "7L58", "6M", "6L58"],
			magiccoat: ["7T", "6T"],
			mistyexplosion: ["8T"],
			playnice: ["8L3", "7L8", "6L8"],
			playrough: ["8M", "8L33", "7L49", "6L49"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "8L18", "7M", "7L13", "6M", "6L13"],
			safeguard: ["8M", "7M", "7L67", "6M", "6L67"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stickyweb: ["8E", "7E"],
			stringshot: ["8L21"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			sweetscent: ["8L1", "7L1", "6L1"],
			tackle: ["8L1", "7L1", "6L1"],
			thief: ["8M", "7M", "6M"],
			thunderbolt: ["8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			wish: ["8L30", "7L45", "6L45"],
			yawn: ["8E", "7E", "6E"],
		},
	},
	slurpuff: {
		learnset: {
			afteryou: ["7T", "6T"],
			amnesia: ["8M"],
			aromatherapy: ["8L9", "7L26", "6L26"],
			attract: ["8M", "7M", "6M"],
			calmmind: ["8M", "7M", "6M"],
			charm: ["8M"],
			confide: ["7M", "6M"],
			cottonguard: ["8L36", "7L41", "6L41"],
			cottonspore: ["8L24", "7L17", "6L17"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["8M", "8L12", "7L31", "6L31"],
			drainpunch: ["8M", "7T", "6T"],
			dreameater: ["7M", "6M"],
			endeavor: ["8L39", "7T", "7L21", "6T", "6L21"],
			endure: ["8M"],
			energyball: ["8M", "8L27", "7M", "7L36", "6M", "6L36"],
			facade: ["8M", "7M", "6M"],
			fairywind: ["8L1", "7L1", "6L5"],
			faketears: ["8M", "8L15", "7L10", "6L10"],
			flamethrower: ["8M", "7M", "6M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			gastroacid: ["7T", "6T"],
			gigaimpact: ["8M", "7M", "6M"],
			healbell: ["7T", "6T"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			lightscreen: ["8M", "7M", "7L58", "6M", "6L58"],
			magiccoat: ["7T", "6T"],
			metronome: ["8M"],
			mistyexplosion: ["8T"],
			playnice: ["8L1", "7L1", "6L8"],
			playrough: ["8M", "8L33", "7L49", "6L49"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "8L18", "7M", "7L13", "6M", "6L13"],
			safeguard: ["8M", "7M", "7L67", "6M", "6L67"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stickyweb: ["8L42"],
			stringshot: ["8L21"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			sweetscent: ["8L1", "7L1", "6L1"],
			tackle: ["8L1", "7L1", "6L1"],
			thief: ["8M", "7M", "6M"],
			thunder: ["8M"],
			thunderbolt: ["8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			wish: ["8L30", "7L45", "6L45"],
		},
	},
	inkay: {
		learnset: {
			acupressure: ["8E"],
			aerialace: ["7M", "6M"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M"],
			batonpass: ["8M"],
			bind: ["7T", "6T"],
			calmmind: ["8M", "7M", "6M"],
			camouflage: ["7E", "6E"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "6L1"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			destinybond: ["8E", "7E", "6E"],
			disable: ["8E"],
			doubleteam: ["7M", "6M"],
			embargo: ["7M", "6M"],
			endure: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M"],
			faketears: ["8M"],
			flamethrower: ["8M", "7M", "6M"],
			flash: ["6M"],
			flatter: ["7E", "6E"],
			fling: ["8M", "7M", "6M"],
			foulplay: ["8M", "8L33", "7T", "7L8", "6T", "6L8", "6S0"],
			frustration: ["7M", "6M"],
			futuresight: ["8M"],
			guardswap: ["8M", "7E"],
			happyhour: ["6S0"],
			hiddenpower: ["7M", "6M"],
			hypnosis: ["8L3", "7L18", "6L18", "6S0"],
			knockoff: ["7T", "6T"],
			lashout: ["8T"],
			lightscreen: ["8M", "7M", "7L31", "6M", "6L31"],
			liquidation: ["8M"],
			nastyplot: ["8M"],
			nightslash: ["8L24", "7L46", "6L46"],
			payback: ["8M", "8L9", "7M", "7L27", "6M", "6L27"],
			peck: ["8L1", "7L1", "6L1"],
			pluck: ["8L12", "7L35", "6L35"],
			powersplit: ["7E", "6E"],
			protect: ["8M", "7M", "6M"],
			psybeam: ["8L15", "7L21", "6L21"],
			psychic: ["8M", "7M", "6M"],
			psychocut: ["8M", "8L27", "7L39", "6L39"],
			psychup: ["7M", "6M"],
			psywave: ["7L13", "6L13"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "7L4", "6M", "6L4"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			roleplay: ["7T", "6T"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			simplebeam: ["7E", "6E"],
			slash: ["8L21", "7L43", "6L43"],
			sleeptalk: ["8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			spite: ["7T", "6T"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			superpower: ["8M", "8L39", "7T", "7L48", "6T", "6L48"],
			swagger: ["8L18", "7M", "7L12", "6M", "6L12"],
			switcheroo: ["8L31", "7L23", "6L23"],
			tackle: ["8L1", "7L1", "6L1"],
			taunt: ["8M", "7M", "6M"],
			telekinesis: ["7T"],
			thief: ["8M", "7M", "6M"],
			thunderbolt: ["8M", "7M", "6M"],
			topsyturvy: ["8L36", "7L15", "6L15", "6S0"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trickroom: ["8M", "7M", "6M"],
			wrap: ["8L6"],
		},
		eventData: [
			{generation: 6, level: 10, moves: ["happyhour", "foulplay", "hypnosis", "topsyturvy"], pokeball: "cherishball"},
		],
	},
	malamar: {
		learnset: {
			aerialace: ["7M", "6M"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M"],
			batonpass: ["8M"],
			bind: ["7T", "6T"],
			block: ["7T", "6T"],
			brutalswing: ["8M", "7M"],
			calmmind: ["8M", "7M", "6M"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "6L1"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			embargo: ["7M", "6M"],
			endure: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "6M", "6S0"],
			faketears: ["8M"],
			flamethrower: ["8M", "7M", "6M"],
			flash: ["6M"],
			fling: ["8M", "7M", "6M"],
			foulplay: ["8M", "8L37", "7T", "7L8", "6T", "6L8"],
			frustration: ["7M", "6M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M", "6M"],
			guardswap: ["8M"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			hypnosis: ["8L1", "7L18", "6L18"],
			knockoff: ["7T", "6T", "6S0"],
			lashout: ["8T"],
			lightscreen: ["8M", "7M", "7L31", "6M", "6L31"],
			liquidation: ["8M"],
			nastyplot: ["8M"],
			nightslash: ["8L24", "7L46", "6L46"],
			payback: ["8M", "8L9", "7M", "7L27", "6M", "6L27"],
			peck: ["8L1", "7L1", "6L1"],
			pluck: ["8L12", "7L35", "6L35"],
			protect: ["8M", "7M", "6M"],
			psybeam: ["8L15", "7L21", "6L21"],
			psychic: ["8M", "7M", "6M"],
			psychocut: ["8M", "8L27", "7L39", "6L39"],
			psychup: ["7M", "6M"],
			psyshock: ["8M", "7M", "6M"],
			psywave: ["7L13", "6L13"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "7L1", "6M", "6L4"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M", "6M"],
			return: ["7M", "6M"],
			reversal: ["8M", "8L1", "7L1", "6L1"],
			rockslide: ["8M", "7M", "6M", "6S0"],
			roleplay: ["7T", "6T"],
			round: ["8M", "7M", "6M"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			signalbeam: ["7T", "6T"],
			slash: ["8L21", "7L43", "6L43"],
			sleeptalk: ["8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			spite: ["7T", "6T"],
			storedpower: ["8M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			superpower: ["8M", "8L47", "7T", "7L48", "6T", "6L1", "6S0"],
			swagger: ["8L18", "7M", "7L12", "6M", "6L12"],
			switcheroo: ["8L33", "7L23", "6L23"],
			tackle: ["8L1", "7L1", "6L1"],
			taunt: ["8M", "7M", "6M"],
			telekinesis: ["7T"],
			thief: ["8M", "7M", "6M"],
			throatchop: ["8M", "7T"],
			thunderbolt: ["8M", "7M", "6M"],
			topsyturvy: ["8L42", "7L15", "6L15"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trickroom: ["8M", "7M", "6M"],
			wrap: ["8L1"],
		},
		eventData: [
			{generation: 6, level: 50, nature: "Adamant", ivs: {hp: 31, atk: 31}, abilities: ["contrary"], moves: ["superpower", "knockoff", "facade", "rockslide"], pokeball: "cherishball"},
		],
	},
	binacle: {
		learnset: {
			aerialace: ["7M", "6M"],
			ancientpower: ["8L20", "7L28", "6L28"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M"],
			beatup: ["8M"],
			blizzard: ["8M", "7M", "6M"],
			brickbreak: ["8M", "7M", "6M"],
			bulldoze: ["8M", "7M", "6M"],
			clamp: ["7L20", "6L20"],
			confide: ["7M", "6M"],
			crosschop: ["8L44", "7L49", "6L49"],
			cut: ["6M"],
			dig: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			dualchop: ["7T", "6T"],
			earthquake: ["8M", "7M", "6M"],
			embargo: ["7M", "6M"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			falseswipe: ["8M", "7M", "6M"],
			fling: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			furycutter: ["8L12", "7L37", "6L37"],
			furyswipes: ["8L16", "7L10", "6L10"],
			grassknot: ["8M", "7M", "6M"],
			helpinghand: ["8M", "7T", "7E", "6T", "6E"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["8L32", "7L32", "6M", "6L32"],
			icebeam: ["8M", "7M", "6M"],
			icywind: ["8M", "7T", "6T"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T"],
			liquidation: ["8M", "7T"],
			mudshot: ["8M"],
			mudslap: ["8L1", "7L18", "6L18"],
			naturepower: ["7M", "6M"],
			nightslash: ["8E", "7L41", "6L41"],
			payback: ["8M", "7M", "6M"],
			poisonjab: ["8M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			razorshell: ["8M", "8L36", "7L45", "6L45"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockblast: ["8M"],
			rockpolish: ["8L24", "7M", "7L24", "6M", "6L24"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			sandattack: ["8E", "7L1", "6L1"],
			sandstorm: ["8M", "7M", "6M"],
			scald: ["8M", "7M", "6M"],
			scratch: ["8L1", "7L1", "6L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M"],
			shellsmash: ["8L40", "7L1", "6L1"],
			slash: ["8L28", "7L13", "6L13"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			smackdown: ["7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stealthrock: ["8M", "7T", "6T"],
			stoneedge: ["8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			switcheroo: ["8E", "7E", "6E"],
			swordsdance: ["8M", "7M", "6M"],
			taunt: ["8M", "7M", "6M"],
			thief: ["8M", "7M", "6M"],
			tickle: ["7E", "6E"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["8M"],
			watergun: ["8L8", "7L4", "6L4"],
			waterpulse: ["7T", "6T"],
			watersport: ["7E", "6E"],
			withdraw: ["8L4", "7L7", "6L7"],
			xscissor: ["8M", "7M", "6M"],
		},
	},
	barbaracle: {
		learnset: {
			aerialace: ["7M", "6M"],
			ancientpower: ["8L20", "7L28", "6L28"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M"],
			beatup: ["8M"],
			blizzard: ["8M", "7M", "6M"],
			brickbreak: ["8M", "7M", "6M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "7M", "6M"],
			bulldoze: ["8M", "7M", "6M"],
			clamp: ["7L20", "6L20"],
			confide: ["7M", "6M"],
			crosschop: ["8L48", "7L55", "6L55"],
			cut: ["6M"],
			dig: ["8M", "6M"],
			dive: ["8M"],
			doubleteam: ["7M", "6M"],
			dragonclaw: ["8M", "7M", "6M"],
			dualchop: ["7T", "6T"],
			earthpower: ["8M", "7T", "6T"],
			earthquake: ["8M", "7M", "6M"],
			embargo: ["7M", "6M"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			falseswipe: ["8M", "7M", "6M"],
			fling: ["8M", "7M", "6M"],
			focusblast: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			furycutter: ["8L12", "7L37", "6L37"],
			furyswipes: ["8L16", "7L10", "6L10"],
			gigaimpact: ["8M", "7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			helpinghand: ["8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["8L32", "7L32", "6M", "6L32"],
			hyperbeam: ["8M", "7M", "6M"],
			icebeam: ["8M", "7M", "6M"],
			icywind: ["8M", "7T", "6T"],
			infestation: ["7M", "6M"],
			irondefense: ["8M", "7T", "6T"],
			laserfocus: ["7T"],
			liquidation: ["8M", "7T"],
			lowkick: ["8M", "7T", "6T"],
			meteorbeam: ["8T"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			mudslap: ["8L1", "7L18", "6L18"],
			naturepower: ["7M", "6M"],
			nightslash: ["7L44", "6L44"],
			payback: ["8M", "7M", "6M"],
			poisonjab: ["8M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			razorshell: ["8M", "8L36", "7L48", "6L48"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockblast: ["8M"],
			rockpolish: ["8L24", "7M", "7L24", "6M", "6L24"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			sandattack: ["7L1", "6L1"],
			sandstorm: ["8M", "7M", "6M"],
			scald: ["8M", "7M", "6M"],
			scratch: ["8L1", "7L1", "6L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			shadowclaw: ["8M", "7M", "6M"],
			shellsmash: ["8L42", "7L1", "6L1"],
			skullbash: ["8L1", "7L1", "6L1"],
			slash: ["8L28", "7L13", "6L13"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			smackdown: ["7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stealthrock: ["8M", "7T", "6T"],
			stoneedge: ["8M", "8L54", "7M", "7L1", "6M", "6L1"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["8M", "7M", "6M"],
			taunt: ["8M", "7M", "6M"],
			thief: ["8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["8M"],
			watergun: ["8L1", "7L1", "6L4"],
			waterpulse: ["7T", "6T"],
			whirlpool: ["8M"],
			withdraw: ["8L1", "7L7", "6L7"],
			xscissor: ["8M", "7M", "6M"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	skrelp: {
		learnset: {
			acid: ["9L5", "8L5", "7L15", "6L15"],
			acidarmor: ["9E", "8E", "7E", "6E"],
			acidspray: ["9M"],
			aquatail: ["9L45", "8L45", "7T", "7L35", "6T", "6L35"],
			attract: ["8M", "7M", "6M"],
			bounce: ["8M", "7T", "6T"],
			bubble: ["7L12", "6L12"],
			camouflage: ["7L19", "6L19"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			dive: ["8M", "6M"],
			doubleteam: ["9L20", "8L20", "7M", "7L28", "6M", "6L28"],
			dragonpulse: ["9M", "9L40", "8M", "8L40", "7T", "7L49", "6T", "6L49"],
			dragontail: ["9M", "9E", "8E"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			feintattack: ["7L5", "6L5"],
			flipturn: ["8T"],
			frustration: ["7M", "6M"],
			gunkshot: ["9M", "8M", "7T", "6T"],
			hail: ["8M", "7M", "6M"],
			haze: ["9E", "8E", "7E", "6E"],
			hiddenpower: ["7M", "6M"],
			hydropump: ["9M", "9L55", "8M", "8L55", "7L42", "6L42"],
			icywind: ["9M", "8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			liquidation: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			outrage: ["9M", "8M", "7T", "6T"],
			playrough: ["9M", "8M", "7E", "6E"],
			poisontail: ["9M", "9L25", "8L25", "7L23", "6L23"],
			protect: ["9M", "8M", "7M", "6M"],
			raindance: ["9M", "8M", "7M", "6M"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "7M", "6M"],
			scald: ["8M", "7M", "6M"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M"],
			shockwave: ["7T", "6T"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			sludgebomb: ["9M", "9L50", "8M", "8L50", "7M", "7L38", "6M", "6L38"],
			sludgewave: ["8M", "7M", "6M"],
			smokescreen: ["9L1", "8L1", "7L1", "6L1"],
			snore: ["8M", "7T", "6T"],
			snowscape: ["9M"],
			substitute: ["9M", "8M", "7M", "6M"],
			surf: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			tailwhip: ["9L15", "8L15", "7L9", "6L9"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderbolt: ["9M", "8M", "7M", "6M"],
			toxic: ["9L35", "8L35", "7M", "7L32", "6M", "6L32"],
			toxicspikes: ["9M", "8M", "7E", "6E"],
			twister: ["9E", "8E"],
			venomdrench: ["8M", "7E", "6E"],
			venoshock: ["9M", "8M", "7M", "6M"],
			waterfall: ["9M", "8M", "7M", "6M"],
			watergun: ["9L10", "8L10", "7L1", "6L1"],
			waterpulse: ["9M", "9L30", "8L30", "7T", "7L25", "6T", "6L25"],
		},
	},
	dragalge: {
		learnset: {
			acid: ["9L1", "8L1", "7L15", "6L15"],
			acidspray: ["9M"],
			aquatail: ["9L45", "8L45", "7T", "7L35", "6T", "6L35"],
			attract: ["8M", "7M", "6M"],
			bounce: ["8M", "7T", "6T"],
			bubble: ["7L12", "6L12"],
			camouflage: ["7L19", "6L19"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			dive: ["8M", "6M"],
			doubleteam: ["9L20", "8L20", "7M", "7L28", "6M", "6L28"],
			dracometeor: ["9M", "8T", "7T", "6T"],
			dragonpulse: ["9M", "9L40", "8M", "8L40", "7T", "7L53", "6T", "6L53"],
			dragontail: ["7M", "7L1", "6M", "6L1"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			feintattack: ["7L1", "6L5"],
			flipturn: ["8T"],
			focusblast: ["9M", "8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			gunkshot: ["9M", "8M", "7T", "6T"],
			hail: ["8M", "7M", "6M"],
			hiddenpower: ["7M", "6M"],
			hydropump: ["9M", "9L59", "8M", "8L59", "7L42", "6L42"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			icywind: ["9M", "8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			liquidation: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			outrage: ["9M", "9L66", "8M", "8L66", "7T", "6T"],
			playrough: ["9M", "8M"],
			poisontail: ["9M", "9L25", "8L25", "7L23", "6L23"],
			protect: ["9M", "8M", "7M", "6M"],
			raindance: ["9M", "8M", "7M", "6M"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "7M", "6M"],
			scald: ["8M", "7M", "6M"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M"],
			shockwave: ["7T", "6T"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			sludgebomb: ["9M", "9L52", "8M", "8L52", "7M", "7L38", "6M", "6L38"],
			sludgewave: ["8M", "7M", "6M"],
			smokescreen: ["9L1", "8L1", "7L1", "6L1"],
			snore: ["8M", "7T", "6T"],
			snowscape: ["9M"],
			substitute: ["9M", "8M", "7M", "6M"],
			surf: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			tailwhip: ["9L15", "8L15", "7L9", "6L9"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M", "8M", "7M", "6M"],
			thunderbolt: ["9M", "8M", "7M", "6M"],
			toxic: ["9L35", "8L35", "7M", "7L32", "6M", "6L32"],
			toxicspikes: ["9M", "8M"],
			twister: ["7L1", "6L1"],
			venomdrench: ["8M"],
			venoshock: ["9M", "8M", "7M", "6M"],
			waterfall: ["9M", "8M", "7M", "6M"],
			watergun: ["9L1", "8L1", "7L1", "6L1"],
			waterpulse: ["9M", "9L30", "8L30", "7T", "7L25", "6T", "6L25"],
		},
		encounters: [
			{generation: 6, level: 35},
		],
	},
	clauncher: {
		learnset: {
			aquajet: ["9L15", "8L15", "7L43", "7E", "6L43", "6E"],
			aquatail: ["9E", "8E", "7T", "6T"],
			attract: ["8M", "7M", "6M"],
			aurasphere: ["9M", "9L40", "8M", "8L40"],
			blizzard: ["9M"],
			bounce: ["9L45", "8M", "8L45", "7T", "6T"],
			bubble: ["7L12", "6L12"],
			bubblebeam: ["9E", "8E", "7L20", "6L20"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crabhammer: ["9L55", "8L55", "7L30", "7E", "6L30", "6E"],
			cut: ["6M"],
			darkpulse: ["9M"],
			dive: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			dragonpulse: ["9M", "8M", "7T", "6T"],
			endure: ["9M", "8M", "7E", "6E"],
			entrainment: ["9E", "8E", "7E", "6E"],
			facade: ["9M", "8M", "7M", "6M"],
			flail: ["9L10", "8L10", "7L16", "6L16"],
			flashcannon: ["9M", "8M", "7M", "6M"],
			flipturn: ["8T"],
			frustration: ["7M", "6M"],
			helpinghand: ["9M", "8M", "7T", "7E", "6T", "6E"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["9L25", "8L25"],
			hydropump: ["9M"],
			icebeam: ["9M", "8M", "7M", "6M"],
			icywind: ["9M", "8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			liquidation: ["9M"],
			muddywater: ["9L50", "8M", "8L50", "7L48", "6L48"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M", "6M"],
			raindance: ["9M", "8M", "7M", "6M"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			scald: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			smackdown: ["9L20", "8L20", "7M", "7L39", "6M", "6L39"],
			snore: ["8M", "7T", "6T"],
			splash: ["9L1", "8L1", "7L1", "6L1"],
			substitute: ["9M", "8M", "7M", "6M"],
			surf: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["9M", "9L35", "8M", "8L35", "7M", "7L25", "6M", "6L25"],
			takedown: ["9M"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			thief: ["9M"],
			toxic: ["7M", "6M"],
			uturn: ["9M", "8M", "7M", "6M"],
			venoshock: ["9M", "8M", "7M", "6M"],
			visegrip: ["9L5", "8L5", "7L9", "6L9"],
			waterfall: ["9M", "8M", "7M", "6M"],
			watergun: ["9L1", "8L1", "7L1", "6L1"],
			waterpulse: ["9M", "9L30", "8L30", "7T", "7L34", "6T", "6L34"],
			watersport: ["7L7", "6L7"],
		},
	},
	clawitzer: {
		learnset: {
			aquajet: ["9L15", "8L15", "7L49", "6L47"],
			aquatail: ["7T", "6T"],
			attract: ["8M", "7M", "6M"],
			aurasphere: ["9M", "9L42", "8M", "8L42", "7L1", "6L1"],
			blizzard: ["9M"],
			bodyslam: ["9M"],
			bounce: ["9L49", "8M", "8L49", "7T", "6T"],
			bubble: ["7L12", "6L12"],
			bubblebeam: ["7L20", "6L20"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crabhammer: ["9L63", "8L63", "7L30", "6L30"],
			cut: ["6M"],
			darkpulse: ["9M", "9L1", "8M", "8L1", "7M", "7L1", "6M", "6L1"],
			dive: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			dragonpulse: ["9M", "9L1", "8M", "8L1", "7T", "7L1", "6T", "6L1"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			flail: ["9L1", "8L1", "7L16", "6L16"],
			flashcannon: ["9M", "8M", "7M", "6M"],
			flipturn: ["8T"],
			focusblast: ["9M", "8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			healpulse: ["9L1", "8L1", "7L1", "6L1"],
			helpinghand: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["9L25", "8L25"],
			hydropump: ["9M"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			icebeam: ["9M", "8M", "7M", "6M"],
			icywind: ["9M", "8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			laserfocus: ["7T"],
			liquidation: ["9M", "8M", "7T"],
			muddywater: ["9L56", "8M", "8L56", "7L57", "6L53"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M", "6M"],
			raindance: ["9M", "8M", "7M", "6M"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			scald: ["8M", "7M", "6M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			sludgebomb: ["9M", "8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			smackdown: ["9L20", "8L20", "7M", "7L42", "6M", "6L42"],
			snore: ["8M", "7T", "6T"],
			splash: ["9L1", "8L1", "7L1", "6L1"],
			substitute: ["9M", "8M", "7M", "6M"],
			surf: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swordsdance: ["9M", "9L35", "8M", "8L35", "7M", "7L25", "6M", "6L25"],
			takedown: ["9M"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			thief: ["9M"],
			toxic: ["7M", "6M"],
			uturn: ["9M", "8M", "7M", "6M"],
			venoshock: ["9M", "8M", "7M", "6M"],
			visegrip: ["9L1", "8L1", "7L1", "6L9"],
			waterfall: ["9M", "8M", "7M", "6M"],
			watergun: ["9L1", "8L1", "7L1", "6L1"],
			waterpulse: ["9M", "9L30", "8L30", "7T", "7L34", "6T", "6L34"],
			watersport: ["7L1", "6L7"],
		},
		encounters: [
			{generation: 6, level: 35},
		],
	},
	helioptile: {
		learnset: {
			agility: ["8M", "7E", "6E"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M"],
			bulldoze: ["8M", "8L20", "7M", "7L35", "6M", "6L35"],
			camouflage: ["7E", "6E"],
			charge: ["8L16", "7L11", "6L11"],
			chargebeam: ["7M", "6M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			dig: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			dragonrush: ["8E"],
			dragontail: ["8E", "7M", "6M"],
			electricterrain: ["8M", "7E", "6E"],
			electrify: ["8L40", "7L45", "6L45"],
			electroball: ["8M"],
			electroweb: ["8M", "7T", "6T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			flash: ["6M"],
			frustration: ["7M", "6M"],
			glare: ["8E", "7E", "6E"],
			grassknot: ["8M", "7M", "6M"],
			hiddenpower: ["7M", "6M"],
			irontail: ["8M", "7T", "6T"],
			lightscreen: ["8M", "7M", "6M"],
			lowsweep: ["8M", "7M", "6M"],
			magnetrise: ["7T", "6T"],
			mudslap: ["8L1", "7L13", "6L13"],
			paraboliccharge: ["8L28", "7L25", "6L25"],
			pound: ["8L4", "7L1", "6L1"],
			protect: ["8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			quickattack: ["8L12", "7L17", "6L17"],
			raindance: ["8M", "7M", "6M"],
			razorwind: ["7L22", "6L22"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			risingvoltage: ["8T"],
			rockslide: ["8M", "7M", "6M"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			scaleshot: ["8T"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			substitute: ["8M", "7M", "6M"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			tailwhip: ["8L1", "7L1", "6L1"],
			thunder: ["8M", "8L44", "7M", "6M"],
			thunderbolt: ["8M", "8L36", "7M", "7L49", "6M", "6L49"],
			thundershock: ["8L8", "7L6", "6L6"],
			thunderwave: ["8M", "8L32", "7M", "7L31", "6M", "6L31"],
			toxic: ["7M", "6M"],
			uturn: ["8M", "7M", "6M"],
			voltswitch: ["8M", "8L24", "7M", "7L40", "6M", "6L40"],
			wildcharge: ["8M", "7M", "6M"],
		},
	},
	heliolisk: {
		learnset: {
			agility: ["8M"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "8L1", "7M", "6M"],
			charge: ["8L1", "7L1", "6L1"],
			chargebeam: ["7M", "6M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			dig: ["8M", "6M"],
			discharge: ["8L1"],
			doubleteam: ["7M", "6M"],
			dragonpulse: ["8M", "7T", "6T"],
			dragontail: ["7M", "6M"],
			eerieimpulse: ["8M", "8L1", "7L1", "6L1"],
			electricterrain: ["8M"],
			electrify: ["8L1", "7L1", "6L1"],
			electroball: ["8M"],
			electroweb: ["8M", "7T", "6T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			firepunch: ["8M", "7T", "6T"],
			flash: ["6M"],
			focusblast: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			hypervoice: ["8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			lightscreen: ["8M", "7M", "6M"],
			lowkick: ["8M", "7T", "6T"],
			lowsweep: ["8M", "7M", "6M"],
			magnetrise: ["7T", "6T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudslap: ["8L1"],
			paraboliccharge: ["8L1", "7L1", "6L1"],
			pound: ["8L1"],
			protect: ["8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			quickattack: ["8L1", "7L1", "6L1"],
			raindance: ["8M", "7M", "6M"],
			razorwind: ["7L1", "6L1"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			risingvoltage: ["8T"],
			rockslide: ["8M", "7M", "6M"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			scaleshot: ["8T"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			solarbeam: ["8M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			tailwhip: ["8L1"],
			thunder: ["8M", "8L1", "7M", "7L1", "6M", "6L1"],
			thunderbolt: ["8M", "8L1", "7M", "6M"],
			thunderpunch: ["8M", "7T", "6T"],
			thundershock: ["8L1"],
			thunderwave: ["8M", "8L1", "7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["8M", "7M", "6M"],
			voltswitch: ["8M", "8L1", "7M", "6M"],
			weatherball: ["8M"],
			wildcharge: ["8M", "7M", "6M"],
		},
	},
	tyrunt: {
		learnset: {
			aerialace: ["7M", "6M"],
			ancientpower: ["8L8", "7L26", "6L26"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M"],
			bide: ["7L12", "6L12"],
			bite: ["8L16", "7L17", "6L17"],
			block: ["7T", "6T"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M"],
			bulldoze: ["8M", "7M", "6M"],
			charm: ["8M", "8L12", "7L20", "6L20"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L32", "7L34", "6L34"],
			curse: ["8E", "7E", "6E"],
			darkpulse: ["8M", "7M", "6M"],
			dig: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			dracometeor: ["8T", "7T", "6T"],
			dragonclaw: ["8M", "8L36", "7M", "7L37", "6M", "6L37"],
			dragondance: ["8M", "7E", "6E"],
			dragonpulse: ["8M", "7T", "6T"],
			dragontail: ["8L20", "7M", "7L30", "6M", "6L30"],
			earthpower: ["8M", "7T", "6T"],
			earthquake: ["8M", "8L44", "7M", "7L44", "6M", "6L44"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			firefang: ["8M", "7E", "6E"],
			frustration: ["7M", "6M"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["6M"],
			horndrill: ["8L48", "7L49", "6L49"],
			hypervoice: ["8M", "7T", "6T"],
			icefang: ["8M", "7E", "6E"],
			irondefense: ["8M", "7T", "6T"],
			ironhead: ["8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			lashout: ["8T"],
			meteorbeam: ["8T"],
			outrage: ["8M", "7T", "6T"],
			playrough: ["8M"],
			poisonfang: ["8E", "7E", "6E"],
			protect: ["8M", "7M", "6M"],
			psychicfangs: ["8M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			roar: ["8L4", "7M", "7L6", "6M", "6L6", "6S0"],
			rockblast: ["8M"],
			rockpolish: ["8E", "7M", "7E", "6M", "6E"],
			rockslide: ["8M", "8L28", "7M", "6M"],
			rocksmash: ["6M"],
			rockthrow: ["8E"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			scaleshot: ["8T"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stealthrock: ["8M", "7T", "7L15", "6T", "6L15"],
			stomp: ["8L24", "7L10", "6L10", "6S0"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			swagger: ["7M", "6M"],
			tackle: ["8L1", "7L1", "6L1", "6S0"],
			tailwhip: ["8L1", "7L1", "6L1", "6S0"],
			thrash: ["8L40", "7L40", "6L40"],
			thunderfang: ["8M", "7E", "6E"],
			toxic: ["7M", "6M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 6, level: 10, isHidden: true, moves: ["tailwhip", "tackle", "roar", "stomp"], pokeball: "cherishball"},
		],
	},
	tyrantrum: {
		learnset: {
			aerialace: ["7M", "6M"],
			ancientpower: ["8L1", "7L26", "6L26"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M"],
			bide: ["7L12", "6L12"],
			bite: ["8L16", "7L17", "6L17"],
			block: ["7T", "6T"],
			bodyslam: ["8M"],
			breakingswipe: ["8M"],
			brickbreak: ["8M", "7M", "6M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M"],
			charm: ["8M", "8L12", "7L20", "6L20"],
			closecombat: ["8M"],
			confide: ["7M", "6M"],
			crunch: ["8M", "8L32", "7L34", "6L34"],
			darkpulse: ["8M", "7M", "6M"],
			dig: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			dracometeor: ["8T", "7T", "6T"],
			dragonclaw: ["8M", "8L36", "7M", "7L37", "6M", "6L37"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T", "6T"],
			dragontail: ["8L20", "7M", "7L30", "6M", "6L30"],
			earthpower: ["8M", "7T", "6T"],
			earthquake: ["8M", "8L48", "7M", "7L47", "6M", "6L47"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			firefang: ["8M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "8L60", "7M", "7L68", "6M", "6L75"],
			headsmash: ["8L66", "7L1", "6L1"],
			hiddenpower: ["7M", "6M"],
			highhorsepower: ["8M"],
			honeclaws: ["6M"],
			horndrill: ["8L54", "7L53", "6L53"],
			hyperbeam: ["8M", "7M", "6M"],
			hypervoice: ["8M", "7T", "6T"],
			icefang: ["8M"],
			irondefense: ["8M", "7T", "6T"],
			ironhead: ["8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			lashout: ["8T"],
			meteorbeam: ["8T"],
			outrage: ["8M", "7T", "6T"],
			playrough: ["8M"],
			protect: ["8M", "7M", "6M"],
			psychicfangs: ["8M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			roar: ["8L1", "7M", "7L1", "6M", "6L6"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M"],
			rockslide: ["8M", "8L28", "7M", "7L1", "6M", "6L68"],
			rocksmash: ["6M"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			scaleshot: ["8T"],
			scaryface: ["8M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stealthrock: ["8M", "7T", "7L15", "6T", "6L15"],
			stomp: ["8L24", "7L1", "6L10"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			swagger: ["7M", "6M"],
			tackle: ["8L1", "7L1", "6L1"],
			tailwhip: ["8L1", "7L1", "6L1"],
			thrash: ["8L42", "7L42", "6L42"],
			thunderfang: ["8M"],
			toxic: ["7M", "6M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	amaura: {
		learnset: {
			ancientpower: ["8L8", "7L26", "6L26"],
			aquatail: ["7T", "6T"],
			attract: ["8M", "7M", "6M"],
			aurorabeam: ["8L24", "7L20", "6L20"],
			auroraveil: ["8E"],
			avalanche: ["8M", "7L34", "6L34"],
			barrier: ["7E", "6E"],
			blizzard: ["8M", "8L52", "7M", "7L65", "6M", "6L65"],
			bodyslam: ["8M"],
			bulldoze: ["8M", "7M", "6M"],
			calmmind: ["8M", "7M", "6M"],
			chargebeam: ["7M", "6M"],
			confide: ["7M", "6M"],
			darkpulse: ["8M", "7M", "6M"],
			discharge: ["8E", "7E", "6E"],
			doubleteam: ["7M", "6M"],
			dragontail: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			earthpower: ["8M", "7T", "6T"],
			echoedvoice: ["7M", "6M"],
			encore: ["8M", "8L4", "7L44", "6L44"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			flash: ["6M"],
			flashcannon: ["8M", "7M", "6M"],
			freezedry: ["8L36"],
			frostbreath: ["7M", "6M"],
			frustration: ["7M", "6M"],
			growl: ["8L1", "7L1", "6L1", "6S0"],
			hail: ["8M", "8L48", "7M", "7L38", "6M", "6L38"],
			haze: ["8E", "7E", "6E"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "8L56", "7M", "7L57", "6M", "6L57"],
			hypervoice: ["8M", "7T", "6T"],
			icebeam: ["8M", "8L40", "7M", "7L50", "6M", "6L50"],
			icywind: ["8M", "8L12", "7T", "7L13", "6T", "6L13"],
			irondefense: ["8M", "7T", "6T"],
			ironhead: ["8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			lightscreen: ["8M", "8L44", "7M", "7L47", "6M", "6L47"],
			magnetrise: ["7T", "7E", "6T", "6E"],
			meteorbeam: ["8T"],
			mirrorcoat: ["8E", "7E", "6E"],
			mist: ["8L20", "7L18", "6L18"],
			mudshot: ["8M"],
			naturepower: ["8L32", "7M", "7L41", "6M", "6L41"],
			outrage: ["8M", "7T", "6T"],
			powdersnow: ["8L1", "7L1", "6L1", "6S0"],
			protect: ["8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			rockthrow: ["8E", "7L10", "6L10", "6S0"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "8L16", "7M", "7L30", "6M", "6L30"],
			safeguard: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stealthrock: ["8M", "7T", "6T"],
			stoneedge: ["8M", "7M", "6M"],
			substitute: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			takedown: ["8E", "7L15", "6L15"],
			thunderbolt: ["8M", "7M", "6M"],
			thunderwave: ["8M", "8L28", "7M", "7L5", "6M", "6L5", "6S0"],
			toxic: ["7M", "6M"],
			waterpulse: ["7T", "6T"],
			weatherball: ["8M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 6, level: 10, isHidden: true, moves: ["growl", "powdersnow", "thunderwave", "rockthrow"], pokeball: "cherishball"},
		],
	},
	aurorus: {
		learnset: {
			ancientpower: ["8L1", "7L26", "6L26"],
			aquatail: ["7T", "6T"],
			attract: ["8M", "7M", "6M"],
			aurorabeam: ["8L24", "7L20", "6L20"],
			avalanche: ["8M", "7L34", "6L34"],
			blizzard: ["8M", "8L60", "7M", "7L74", "6M", "6L74"],
			bodyslam: ["8M"],
			bulldoze: ["8M", "7M", "6M"],
			calmmind: ["8M", "7M", "6M"],
			chargebeam: ["7M", "6M"],
			confide: ["7M", "6M"],
			darkpulse: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			dragontail: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			earthpower: ["8M", "7T", "6T"],
			earthquake: ["8M", "7M", "6M"],
			echoedvoice: ["7M", "6M"],
			encore: ["8M", "8L1", "7L46", "6L46"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			flash: ["6M"],
			flashcannon: ["8M", "7M", "6M"],
			freezedry: ["8L36", "7L1", "6L1"],
			frostbreath: ["7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			growl: ["8L1", "7L1", "6L1"],
			hail: ["8M", "8L54", "7M", "7L38", "6M", "6L38"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "8L66", "7M", "7L63", "6M", "6L63"],
			hypervoice: ["8M", "7T", "6T"],
			icebeam: ["8M", "8L42", "7M", "7L56", "6M", "6L56"],
			iciclespear: ["8M"],
			icywind: ["8M", "8L12", "7T", "7L13", "6T", "6L13"],
			irondefense: ["8M", "7T", "6T"],
			ironhead: ["8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			lightscreen: ["8M", "8L48", "7M", "7L50", "6M", "6L50"],
			magnetrise: ["7T", "6T"],
			meteorbeam: ["8T"],
			mist: ["8L20", "7L18", "6L18"],
			mudshot: ["8M"],
			naturepower: ["8L32", "7M", "7L43", "6M", "6L43"],
			outrage: ["8M", "7T", "6T"],
			powdersnow: ["8L1", "7L1", "6L1"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockblast: ["8M"],
			rockpolish: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			rockthrow: ["7L1", "6L10"],
			rocktomb: ["8M", "7M", "6M"],
			round: ["8M", "8L16", "7M", "7L30", "6M", "6L30"],
			safeguard: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stealthrock: ["8M", "7T", "6T"],
			stoneedge: ["8M", "7M", "6M"],
			substitute: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			takedown: ["7L15", "6L15"],
			thunder: ["8M", "7M", "6M"],
			thunderbolt: ["8M", "7M", "6M"],
			thunderwave: ["8M", "8L28", "7M", "7L1", "6M", "6L5"],
			toxic: ["7M", "6M"],
			waterpulse: ["7T", "6T"],
			weatherball: ["8M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
	},
	sylveon: {
		learnset: {
			attract: ["8M", "7M", "6M"],
			babydolleyes: ["9L15", "8L15", "7L9", "6S1"],
			batonpass: ["9M", "9L1", "8M", "8L1"],
			bite: ["9L1", "8L1"],
			bodyslam: ["9M", "8M"],
			calmmind: ["9M", "8M", "7M", "7S2", "6M"],
			celebrate: ["6S0"],
			charm: ["9M", "9L1", "8M", "8L1"],
			confide: ["7M", "6M"],
			copycat: ["9L1", "8L1"],
			covet: ["9L1", "8L1", "7T", "6T"],
			cut: ["6M"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			dig: ["9M", "8M", "6M"],
			disarmingvoice: ["9M", "9L0", "8L0", "7L1", "6L1", "6S1"],
			doubleedge: ["9L1", "8L1"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M", "9L30", "8M", "8L30", "7L20", "7S2", "6L20", "6S1"],
			echoedvoice: ["7M", "6M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			fairywind: ["7L1", "6L9", "6S0"],
			faketears: ["9M", "8M"],
			flash: ["6M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			growl: ["9L1", "8L1"],
			healbell: ["7T", "6T"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "7L1", "6T", "6L1", "6S0"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["9M", "8M", "7M", "7S2", "6M"],
			hypervoice: ["9M", "8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			laserfocus: ["7T"],
			lastresort: ["9L55", "8L55", "7T", "7L41", "6T", "6L41"],
			lightscreen: ["9M", "9L25", "8M", "8L25", "7M", "7L33", "6M", "6L33"],
			magicalleaf: ["9M", "8M"],
			magiccoat: ["7T", "6T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M", "9L35", "8M", "8L35", "7L29", "6L29"],
			moonblast: ["9L50", "8L50", "7L37", "6L37"],
			mysticalfire: ["8M"],
			payday: ["8M"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M", "6M"],
			psychic: ["9M"],
			psychup: ["9L45", "8L45", "7M", "7L45", "6M", "6L45"],
			psyshock: ["9M", "8M", "7M", "7S2", "6M"],
			quickattack: ["9L10", "8L10", "7L13", "6L13", "6S1"],
			raindance: ["9M", "8M", "7M", "6M"],
			reflect: ["9M", "8M", "7M", "6M"],
			rest: ["9M", "8M", "7M", "6M"],
			retaliate: ["8M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			sandattack: ["9L5", "8L5", "7L5", "6L5", "6S0"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M"],
			skillswap: ["9M", "9L40", "8M", "8L40", "7T", "7L25", "6T", "6L25"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M", "9L20", "8M", "8L20", "7L17", "6L17"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			tailwhip: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M", "9L1", "8L1"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			weatherball: ["8M"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 6, level: 10, moves: ["celebrate", "helpinghand", "sandattack", "fairywind"], pokeball: "cherishball"},
			{generation: 6, level: 10, gender: "F", moves: ["disarmingvoice", "babydolleyes", "quickattack", "drainingkiss"], pokeball: "cherishball"},
			{generation: 7, level: 50, gender: "F", isHidden: true, moves: ["hyperbeam", "drainingkiss", "psyshock", "calmmind"], pokeball: "cherishball"},
		],
	},
	hawlucha: {
		learnset: {
			acrobatics: ["9M", "8M", "7M", "6M"],
			aerialace: ["9M", "9L12", "8L12", "7M", "7L16", "6M", "6L16"],
			agility: ["9M", "8M", "7E", "6E"],
			allyswitch: ["8M", "7T", "7E", "6E"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M"],
			batonpass: ["9M", "8M", "7E", "6E"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M"],
			bounce: ["9L28", "8M", "8L28", "7T", "7L32", "6T", "6L32"],
			bravebird: ["9M", "8M"],
			brickbreak: ["9M", "9L24", "8M", "7M", "6M"],
			bulkup: ["9M", "8M", "7M", "6M"],
			closecombat: ["9M", "8M"],
			coaching: ["8T"],
			confide: ["7M", "6M"],
			crosschop: ["9E", "8E"],
			cut: ["6M"],
			defog: ["9E", "8E", "7T"],
			detect: ["9L8", "8L8", "7L1", "6L1"],
			dig: ["9M", "8M", "6M"],
			doubleteam: ["7M", "6M"],
			drainpunch: ["9M", "8M", "7T", "6T"],
			dualchop: ["7T", "6T"],
			dualwingbeat: ["8T"],
			encore: ["9M", "9L16", "8M", "8L16", "7L20", "6L20"],
			endeavor: ["9L52", "8L52", "7T", "7L36", "6T", "6L36"],
			endure: ["9M", "8M"],
			entrainment: ["9E", "8E", "7E", "6E"],
			facade: ["9M", "8M", "7M", "6M"],
			falseswipe: ["9M", "8M", "7M", "6M"],
			featherdance: ["9L20", "8L20", "7L40", "6L40"],
			feint: ["9E", "8E", "7E"],
			firepunch: ["9M", "8M", "7T", "6T"],
			fling: ["9M", "8M", "7M", "7L24", "6M", "6L24"],
			fly: ["9M", "8M", "7M", "6M"],
			flyingpress: ["9L44", "8L44", "7L28", "6L28"],
			focusblast: ["9M", "8M", "7M", "6M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			grassknot: ["9M", "8M", "7M", "6M"],
			helpinghand: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			highjumpkick: ["9L48", "8L48", "7L44", "6L44"],
			honeclaws: ["9L1", "8L1", "7L1", "6M", "6L1"],
			hyperbeam: ["9M"],
			ironhead: ["9M", "8M", "7T", "6T"],
			karatechop: ["7L4", "6L4"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T"],
			lowkick: ["9M", "8M", "7T", "6T"],
			lowsweep: ["9M", "8M", "7M", "6M"],
			meanlook: ["9E", "8E"],
			mefirst: ["7E", "6E"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudsport: ["7E", "6E"],
			payback: ["8M", "7M", "6M"],
			poisonjab: ["9M", "8M", "7M", "6M"],
			poweruppunch: ["6M"],
			protect: ["9M", "8M", "7M", "6M"],
			quickguard: ["9E", "8E", "7E", "6E"],
			raindance: ["9M", "8M", "7M", "6M"],
			rest: ["9M", "8M", "7M", "6M"],
			retaliate: ["8M", "6M"],
			return: ["7M", "6M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "8M", "7M", "6M"],
			roost: ["9L36", "8L36", "7M", "7L12", "6M", "6L12"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			skyattack: ["9L56", "8L56", "7T", "7L48", "6T", "6L48"],
			skydrop: ["7M", "7L55", "6M", "6L55"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			steelwing: ["8M", "7M", "6M"],
			stoneedge: ["9M", "8M", "7M", "6M"],
			strength: ["6M"],
			submission: ["8L24"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			swagger: ["7M", "6M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "9L40", "8M", "8L40", "7M", "7L60", "6M", "6L60"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			tailwind: ["7T", "6T"],
			takedown: ["9M"],
			taunt: ["9M", "9L32", "8M", "8L32", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["9M", "8M", "7T", "6T"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M", "7M", "6M"],
			wingattack: ["9L4", "8L4", "7L8", "6L8"],
			workup: ["8M", "7M"],
			xscissor: ["9M", "8M", "7M", "6M"],
			zenheadbutt: ["9M", "8M", "7T", "6T"],
		},
	},
	dedenne: {
		learnset: {
			aerialace: ["7M", "6M"],
			agility: ["9M"],
			allyswitch: ["8M", "7T"],
			attract: ["8M", "7M", "6M"],
			charge: ["9L10", "8L10", "7L11", "6L11"],
			chargebeam: ["9M", "7M", "7L34", "6M", "6L34"],
			charm: ["9M", "9L20", "8M", "8L20", "7L14", "6L14"],
			confide: ["7M", "6M"],
			covet: ["9E", "8E", "7T", "7E", "6T", "6E"],
			cut: ["6M"],
			dazzlinggleam: ["9M", "8M"],
			dig: ["9M", "8M", "6M"],
			discharge: ["9L40", "8L40", "7L50", "6L50"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M"],
			eerieimpulse: ["9M", "8M", "7E", "6E"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "8M"],
			electroweb: ["8M", "7T", "6T"],
			endure: ["9M", "8M"],
			entrainment: ["9L55", "8L55", "7L39", "6L39"],
			facade: ["9M", "8M", "7M", "6M"],
			flash: ["6M"],
			fling: ["9M", "8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			grassknot: ["9M", "8M", "7M", "6M"],
			helpinghand: ["9M", "8M", "7T", "7E", "6T", "6E"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["9M"],
			irontail: ["8M", "7T", "6T"],
			lastresort: ["7T", "6T"],
			lightscreen: ["9M"],
			magnetrise: ["9E", "8E", "7T", "6T"],
			mistyterrain: ["9M"],
			naturalgift: ["7E", "6E"],
			nuzzle: ["9L1", "8L1", "7L20", "6L20"],
			paraboliccharge: ["9L25", "8L25", "7L17", "6L17"],
			playrough: ["9M", "9L45", "8M", "8L45", "7L42", "6L42"],
			protect: ["9M", "8M", "7M", "6M"],
			raindance: ["9M", "8M", "7M", "6M"],
			recycle: ["7T", "6T"],
			rest: ["9M", "9L35", "8M", "8L35", "7M", "7L30", "6M", "6L30"],
			retaliate: ["8M", "6M"],
			return: ["7M", "6M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["9M"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snore: ["9L35", "8M", "8L35", "7T", "7L31", "6T", "6L31"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			superfang: ["9L50", "8L50", "7T", "6T"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tackle: ["9L5", "8L5", "7L1", "6L1"],
			tailwhip: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M"],
			tearfullook: ["9E", "8E", "7E"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M"],
			thunder: ["9M", "9L60", "8M", "8L60", "7M", "7L45", "6M", "6L45"],
			thunderbolt: ["9M", "8M", "7M", "6M"],
			thunderpunch: ["9M", "8M", "7T", "6T"],
			thundershock: ["9L15", "8L15", "7L7", "6L7"],
			thunderwave: ["9M", "8M", "7M", "7L23", "6M", "6L23"],
			toxic: ["7M", "6M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M", "7M", "6M"],
			voltswitch: ["9M", "9L30", "8M", "8L30", "7M", "7L26", "6M", "6L26"],
			wildcharge: ["9M", "8M", "7M", "6M"],
		},
	},
	carbink: {
		learnset: {
			afteryou: ["7T", "6T"],
			allyswitch: ["8M", "7T"],
			ancientpower: ["9L20", "8L20", "7L31", "6L31"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M"],
			calmmind: ["9M", "8M", "7M", "6M"],
			charm: ["9M", "8M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			earthpower: ["9M", "8M", "7T", "6T"],
			endure: ["9M", "8M"],
			explosion: ["7M", "6M"],
			facade: ["9M", "8M", "7M", "6M"],
			flail: ["9L15", "8L15", "7L35", "6L35"],
			flash: ["6M"],
			flashcannon: ["9M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M"],
			gravity: ["7T", "6T"],
			guardsplit: ["9L5", "8L5", "7L27", "6L27"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M"],
			hail: ["8M", "7M", "6M"],
			harden: ["9L1", "8L1", "7L1", "6L1"],
			heavyslam: ["9M"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["9M"],
			irondefense: ["9M", "8M", "7T", "6T"],
			ironhead: ["9M"],
			lightscreen: ["9M", "9L30", "8M", "8L30", "7M", "7L60", "6M", "6L60"],
			magiccoat: ["7T", "6T"],
			magnetrise: ["7T", "6T"],
			meteorbeam: ["8T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M"],
			moonblast: ["9L55", "8L55", "7L50", "6L50"],
			naturepower: ["7M", "6M"],
			powergem: ["9M", "9L45", "8M", "8L45", "7L46", "6L46"],
			protect: ["9M", "8M", "7M", "6M"],
			psychic: ["9M", "8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			raindance: ["9M"],
			reflect: ["9M", "8M", "7M", "7L18", "6M", "6L18"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockblast: ["9M"],
			rockpolish: ["9L25", "8L25", "7M", "6M"],
			rockslide: ["9M", "9L35", "8M", "8L35", "7M", "6M"],
			rockthrow: ["7L5", "6L5"],
			rocktomb: ["9M", "8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "7L70", "6M", "6L70"],
			sandstorm: ["9M", "8M", "7M", "6M"],
			sandtomb: ["8M"],
			secretpower: ["6M"],
			sharpen: ["7L8", "6L8"],
			skillswap: ["9M", "9L40", "8M", "8L40", "7T", "7L40", "6T", "6L40"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			smackdown: ["9L10", "8L10", "7M", "7L12", "6M", "6L12"],
			snore: ["8M", "7T", "6T"],
			spikes: ["9M"],
			stealthrock: ["9M", "9L50", "8M", "8L50", "7T", "7L21", "6T", "6L21"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "9L60", "8M", "8L60", "7M", "7L49", "6M", "6L49"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			toxic: ["7M", "6M"],
			trickroom: ["9M", "8M", "7M", "6M"],
			wonderroom: ["8M", "7T", "6T"],
		},
	},
	goomy: {
		learnset: {
			absorb: ["9L1", "8L1", "7L5", "6L5"],
			acidarmor: ["7E", "6E"],
			attract: ["8M", "7M", "6M"],
			bide: ["7L13", "6L13"],
			bodyslam: ["9M", "9L45", "8M", "8L45", "7L32", "7S0", "6L32"],
			bubble: ["7L1", "6L1"],
			charm: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			counter: ["9E", "8E", "7E", "7S0", "6E"],
			curse: ["9L41", "8L41", "7E", "6E"],
			doubleteam: ["7M", "6M"],
			dracometeor: ["9M", "8T", "7T", "6T"],
			dragonbreath: ["9L10", "8L10", "7L18", "6L18"],
			dragonpulse: ["9M", "9L35", "8M", "8L35", "7T", "7L42", "7S0", "6T", "6L42"],
			endure: ["9M", "8M", "7E", "6E"],
			facade: ["9M", "8M", "7M", "6M"],
			flail: ["9L20", "8L20", "7L28", "6L28"],
			frustration: ["7M", "6M"],
			hiddenpower: ["7M", "6M"],
			infestation: ["7M", "6M"],
			irontail: ["8M", "7T", "7E", "6T", "6E"],
			lifedew: ["9E", "8E"],
			muddywater: ["9L50", "8M", "8L50", "7L38", "6L38"],
			mudshot: ["9M", "8M"],
			outrage: ["9M", "8M", "7T", "6T"],
			poisontail: ["7E", "6E"],
			protect: ["9M", "9L15", "8M", "8L15", "7M", "7L9", "6M", "6L9"],
			raindance: ["9M", "9L30", "8M", "8L30", "7M", "7L25", "6M", "6L25"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			sludgebomb: ["9M", "8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderbolt: ["9M", "8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			watergun: ["9L5", "8L5"],
			waterpulse: ["9M", "9L25", "8L25", "7T", "6T"],
		},
		eventData: [
			{generation: 7, level: 1, shiny: 1, isHidden: true, moves: ["bodyslam", "dragonpulse", "counter"], pokeball: "cherishball"},
		],
	},
	sliggoo: {
		learnset: {
			absorb: ["9L1", "8L1", "7L1", "6L5"],
			acidarmor: ["9L1"],
			acidspray: ["9M", "9L0", "8L0"],
			attract: ["8M", "7M", "6M"],
			bide: ["7L13", "6L13"],
			blizzard: ["9M", "8M", "7M", "6M"],
			bodyslam: ["9M", "9L49", "8M", "8L49", "7L32", "6L32"],
			bubble: ["7L1", "6L1"],
			charm: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			curse: ["9L43", "8L43"],
			doubleteam: ["7M", "6M"],
			dracometeor: ["9M", "8T", "7T", "6T"],
			dragonbreath: ["9L1", "8L1", "7L18", "6L18"],
			dragonpulse: ["9M", "9L35", "8M", "8L35", "7T", "7L47", "6T", "6L47"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			flail: ["9L20", "8L20", "7L28", "6L28"],
			frustration: ["7M", "6M"],
			hiddenpower: ["7M", "6M"],
			icebeam: ["9M", "8M", "7M", "6M"],
			infestation: ["7M", "6M"],
			irontail: ["8M", "7T", "6T"],
			muddywater: ["9L56", "8M", "8L56", "7L38", "6L38"],
			mudshot: ["9M", "8M"],
			outrage: ["9M", "8M", "7T", "6T"],
			protect: ["9M", "9L15", "8M", "8L15", "7M", "7L9", "6M", "6L9"],
			raindance: ["9M", "9L30", "8M", "8L30", "7M", "7L25", "6M", "6L25"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			sludgebomb: ["9M", "8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M", "8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			watergun: ["9L1", "8L1"],
			waterpulse: ["9M", "9L25", "8L25", "7T", "6T"],
		},
		encounters: [
			{generation: 6, level: 30},
		],
	},
	sliggoohisui: {
		learnset: {
			absorb: ["9L1"],
			acidarmor: ["9L1"],
			acidspray: ["9M"],
			blizzard: ["9M"],
			bodyslam: ["9M"],
			charm: ["9M"],
			chillingwater: ["9M"],
			curse: ["9L43"],
			dracometeor: ["9M"],
			dragonbreath: ["9L1"],
			dragonpulse: ["9M", "9L35"],
			endure: ["9M"],
			facade: ["9M"],
			flail: ["9L20"],
			flashcannon: ["9M"],
			heavyslam: ["9M"],
			icebeam: ["9M"],
			icespinner: ["9M"],
			ironhead: ["9M", "9L49"],
			muddywater: ["9L56"],
			mudshot: ["9M"],
			outrage: ["9M"],
			protect: ["9M", "9L15"],
			raindance: ["9M", "9L30"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			shelter: ["9L0"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			steelbeam: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M", "9L25"],
		},
	},
	goodra: {
		learnset: {
			absorb: ["9L1", "8L1", "7L1", "6L5"],
			acidspray: ["9M", "9L1", "8L1"],
			aquatail: ["9L0", "8L0", "7T", "7L1", "6T", "6L50"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M"],
			bide: ["7L13", "6L13"],
			blizzard: ["9M", "8M", "7M", "6M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "9L49", "8M", "8L49", "7L32", "6L32"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bubble: ["7L1", "6L1"],
			bulldoze: ["9M", "8M", "7M", "6M"],
			charm: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			curse: ["9L43", "8L43"],
			doubleteam: ["7M", "6M"],
			dracometeor: ["9M", "8T", "7T", "6T"],
			dragonbreath: ["9L1", "8L1", "7L18", "6L18"],
			dragonclaw: ["9M"],
			dragonpulse: ["9M", "8M", "8L35", "7T", "7L47", "6T", "6L47"],
			dragontail: ["9M", "7M", "6M"],
			earthquake: ["9M", "8M", "7M", "6M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			feint: ["9L1", "8L1", "7L1", "6L1"],
			fireblast: ["9M", "8M", "7M", "6M"],
			firepunch: ["9M", "8M", "7T", "6T"],
			flail: ["9L20", "8L20", "7L28", "6L28"],
			flamethrower: ["9M", "8M", "7M", "6M"],
			focusblast: ["9M", "8M", "7M", "6M"],
			focuspunch: ["7T", "6T"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			hail: ["8M", "7M", "6M"],
			hiddenpower: ["7M", "6M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			icebeam: ["9M", "8M", "7M", "6M"],
			incinerate: ["6M"],
			infestation: ["7M", "6M"],
			irontail: ["8M", "7T", "6T"],
			laserfocus: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			muddywater: ["9L58", "8M", "8L58", "7L38", "6L38"],
			mudshot: ["9M", "8M"],
			outrage: ["9M", "8M", "7T", "7L1", "6T", "6L1"],
			poisontail: ["9M", "9L1", "8L1"],
			powerwhip: ["9L67", "8M", "8L67", "7L50", "6L55"],
			protect: ["9M", "9L15", "8M", "8L15", "7M", "7L1", "6M", "6L9"],
			raindance: ["9M", "9L30", "8M", "8L30", "7M", "7L25", "6M", "6L25"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			rocksmash: ["6M"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			sludgebomb: ["9M", "8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			stompingtantrum: ["9M", "8M", "7T"],
			strength: ["6M"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			surf: ["9M", "8M"],
			swagger: ["7M", "6M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M"],
			tearfullook: ["9L1", "8L1"],
			terablast: ["9M"],
			thunder: ["9M", "8M", "7M", "6M"],
			thunderbolt: ["9M", "8M", "7M", "6M"],
			thunderpunch: ["9M", "8M", "7T", "6T"],
			toxic: ["7M", "6M"],
			watergun: ["9L1", "8L1"],
			waterpulse: ["9M", "9L25", "8L25", "7T", "6T"],
			weatherball: ["8M"],
		},
	},
	goodrahisui: {
		learnset: {
			absorb: ["9L1"],
			acidspray: ["9M", "9L1"],
			blizzard: ["9M"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L49"],
			bulldoze: ["9M"],
			charm: ["9M"],
			chillingwater: ["9M"],
			curse: ["9L43"],
			dracometeor: ["9M"],
			dragonbreath: ["9L1"],
			dragonclaw: ["9M"],
			dragonpulse: ["9M", "9L35"],
			dragontail: ["9M"],
			earthquake: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			feint: ["9L1"],
			fireblast: ["9M"],
			firepunch: ["9M"],
			flail: ["9L20"],
			flamethrower: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M"],
			heavyslam: ["9M", "9L67"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M"],
			icespinner: ["9M"],
			ironhead: ["9M", "9L49"],
			irontail: ["9L0"],
			muddywater: ["9L58"],
			mudshot: ["9M"],
			outrage: ["9M"],
			protect: ["9M", "9L15"],
			raindance: ["9M", "9L30"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			shelter: ["9L1"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			steelbeam: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			surf: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			tearfullook: ["9L1"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderpunch: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M", "9L25"],
		},
	},
	klefki: {
		learnset: {
			astonish: ["9L1", "8L1", "7L8", "6L8"],
			attract: ["8M", "7M", "6M"],
			calmmind: ["9M", "8M", "7M", "6M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			craftyshield: ["8L16", "7L23", "6L23"],
			cut: ["6M"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M", "9L24", "8M", "8L24", "7L18", "6L18"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			fairylock: ["9L16", "8L1", "7L1", "6L1"],
			fairywind: ["9L8", "8L8", "7L5", "6L5"],
			flashcannon: ["9M", "9L36", "8M", "8L36", "7M", "6M"],
			foulplay: ["9M", "9L48", "8M", "8L48", "7T", "7L27", "6T", "6L27"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			healblock: ["7L50", "6L50"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			imprison: ["9M", "9L32", "8M", "8L32", "7L36", "6L36"],
			irondefense: ["9M", "8M", "7T", "7E", "6T", "6E"],
			lastresort: ["9L52", "8L52", "7T", "6T"],
			lightscreen: ["9M", "8M", "7M", "6M"],
			lockon: ["7E", "6E"],
			magiccoat: ["7T", "6T"],
			magicroom: ["9L44", "8M", "8L44", "7T", "7L44", "6T", "6L44"],
			magnetrise: ["9E", "8E", "7T", "6T"],
			metalsound: ["9L20", "8L20", "7L12", "6L12"],
			mirrorshot: ["7L34", "6L34"],
			mistyterrain: ["9M", "8M"],
			playrough: ["9M", "9L40", "8M", "8L40", "7L43", "6L43"],
			protect: ["9M", "8M", "7M", "6M"],
			psychic: ["9M", "8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			psyshock: ["9M", "8M", "7M", "6M"],
			raindance: ["9M", "8M", "7M", "6M"],
			recycle: ["9L28", "8L28", "7T", "7L40", "6T", "6L40"],
			reflect: ["9M", "8M", "7M", "6M"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			sandstorm: ["9M"],
			secretpower: ["6M"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			spikes: ["9M", "8M", "7L15", "6L15"],
			steelbeam: ["9M", "8T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			switcheroo: ["9E", "8E", "7E", "6E"],
			tackle: ["9L4", "8L4", "7L1", "6L1"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "7E", "6M", "6E"],
			thunderwave: ["9M", "8M", "7M", "6M"],
			torment: ["9L12", "8L12", "7M", "7L32", "6M", "6L32"],
			toxic: ["7M", "6M"],
			trickroom: ["9M"],
		},
	},
	phantump: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L5", "6L5"],
			attract: ["8M", "7M", "6M"],
			bestow: ["7E", "6E"],
			branchpoke: ["8L4"],
			bulldoze: ["8M", "7M", "6M"],
			confide: ["7M", "6M"],
			confuseray: ["8L12", "7L1", "6L1"],
			curse: ["8L32", "7L28", "6L28"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			destinybond: ["8L48", "7L39", "6L39"],
			dig: ["8M", "6M"],
			disable: ["8E"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			facade: ["8M", "7M", "6M"],
			feintattack: ["7L19", "6L19"],
			forestscurse: ["8L52", "7L35", "6L35"],
			foulplay: ["8M", "7T", "6T"],
			frustration: ["7M", "6M"],
			gigadrain: ["8M", "7T", "6T"],
			grassknot: ["8M", "7M", "6M"],
			grassyglide: ["8T"],
			growth: ["8L24", "7L8", "6L8"],
			grudge: ["8E", "7E", "6E"],
			hex: ["8M", "8L20"],
			hiddenpower: ["7M", "6M"],
			hornleech: ["8L28", "7L54", "6L54"],
			imprison: ["8M", "7E", "6E"],
			ingrain: ["8L40", "7L13", "6L13"],
			leechseed: ["8L8", "7L23", "6L23"],
			magicalleaf: ["8M"],
			magiccoat: ["7T", "6T"],
			naturepower: ["7M", "6M"],
			painsplit: ["7T", "6T"],
			phantomforce: ["8M", "8L36", "7L45", "6L45"],
			poisonjab: ["8M", "7M", "6M"],
			poltergeist: ["8T"],
			poweruppunch: ["7E"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			roleplay: ["7T", "6T"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T"],
			shadowball: ["8M", "7M", "6M"],
			shadowclaw: ["8M", "7M", "6M"],
			skillswap: ["8M", "7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			solarbeam: ["8M", "7M", "6M"],
			spite: ["7T", "6T"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			suckerpunch: ["8E"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["8L1", "7L1", "6L1"],
			telekinesis: ["7T"],
			thief: ["8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["8M", "7T", "6T"],
			trickroom: ["8M", "7M", "6M"],
			venomdrench: ["8M", "7E", "6E"],
			willowisp: ["8M", "8L16", "7M", "7L31", "6M", "6L31"],
			woodhammer: ["8L44", "7L49", "6L49"],
			worryseed: ["7T", "6T"],
		},
	},
	trevenant: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L5"],
			attract: ["8M", "7M", "6M"],
			block: ["7T", "6T"],
			branchpoke: ["8L1"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M", "6M"],
			calmmind: ["8M", "7M", "6M"],
			confide: ["7M", "6M"],
			confuseray: ["8L12", "7L1", "6L1"],
			curse: ["8L32", "7L28", "6L28"],
			cut: ["6M"],
			darkpulse: ["8M", "7M", "6M"],
			destinybond: ["8L48", "7L39", "6L39"],
			dig: ["8M", "6M"],
			doubleteam: ["7M", "6M"],
			drainpunch: ["8M", "7T", "6T"],
			dreameater: ["7M", "6M"],
			earthquake: ["8M", "7M", "6M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			facade: ["8M", "7M", "6M"],
			feintattack: ["7L19", "6L19"],
			focusblast: ["8M", "7M", "6M"],
			forestscurse: ["8L52", "7L35", "6L35"],
			foulplay: ["8M", "7T", "6T"],
			frustration: ["7M", "6M"],
			gigadrain: ["8M", "7T", "6T"],
			gigaimpact: ["8M", "7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			grassyglide: ["8T"],
			growth: ["8L24", "7L1", "6L8"],
			hex: ["8M", "8L20"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["6M"],
			hornleech: ["8L28", "7L1", "6L1"],
			hyperbeam: ["8M", "7M", "6M"],
			imprison: ["8M"],
			ingrain: ["8L40", "7L13", "6L13"],
			leafstorm: ["8M"],
			leechseed: ["8L1", "7L23", "6L23"],
			magicalleaf: ["8M"],
			magiccoat: ["7T", "6T"],
			naturepower: ["7M", "6M"],
			painsplit: ["7T", "6T"],
			phantomforce: ["8M", "8L36", "7L45", "6L45"],
			poisonjab: ["8M", "7M", "6M"],
			poltergeist: ["8T"],
			poweruppunch: ["6M"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			roleplay: ["7T", "6T"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			seedbomb: ["8M", "7T", "6T"],
			shadowball: ["8M", "7M", "6M"],
			shadowclaw: ["8M", "8L0", "7M", "7L1", "6M", "6L55"],
			skillswap: ["8M", "7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			solarbeam: ["8M", "7M", "6M"],
			spite: ["7T", "6T"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["8L1", "7L1", "6L1"],
			telekinesis: ["7T"],
			thief: ["8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["8M", "7T", "6T"],
			trickroom: ["8M", "7M", "6M"],
			venomdrench: ["8M"],
			willowisp: ["8M", "8L16", "7M", "7L31", "6M", "6L31"],
			woodhammer: ["8L44", "7L49", "6L49"],
			worryseed: ["7T", "6T"],
			xscissor: ["8M", "7M", "6M"],
		},
	},
	pumpkaboo: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1"],
			attract: ["8M", "7M", "6M"],
			bestow: ["7E", "6E"],
			bulletseed: ["8M", "8L20", "7L26", "6L26"],
			chargebeam: ["7M", "6M"],
			confide: ["7M", "6M"],
			confuseray: ["8L8", "7L1", "6L1"],
			curse: ["8E", "7E"],
			darkpulse: ["8M", "7M", "6M"],
			destinybond: ["8E", "7E", "6E"],
			disable: ["8E", "7E", "6E"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			explosion: ["7M", "6M"],
			facade: ["8M", "7M", "6M"],
			fireblast: ["8M", "7M", "6M"],
			flamecharge: ["7M", "6M"],
			flamethrower: ["8M", "7M", "6M"],
			flash: ["6M"],
			foulplay: ["8M", "7T", "6T"],
			frustration: ["7M", "6M"],
			gigadrain: ["8M", "7T", "6T"],
			grassknot: ["8M", "7M", "6M"],
			grassyglide: ["8T"],
			gyroball: ["8M", "7M", "6M"],
			hex: ["8M"],
			hiddenpower: ["7M", "6M"],
			imprison: ["8M"],
			incinerate: ["6M"],
			leechseed: ["8L16", "7L20", "6L20"],
			lightscreen: ["8M", "7M", "6M"],
			magiccoat: ["7T", "6T"],
			mysticalfire: ["8M"],
			naturepower: ["7M", "6M"],
			painsplit: ["8L44", "7T", "7L42", "6T", "6L42"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "7M", "6M"],
			razorleaf: ["8L12", "7L16", "6L16"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			roleplay: ["7T", "6T"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			scaryface: ["8M", "8L24", "7L4", "6L4"],
			secretpower: ["6M"],
			seedbomb: ["8M", "8L32", "7T", "7L48", "6T", "6L48"],
			shadowball: ["8M", "8L36", "7M", "7L36", "6M", "6L36"],
			shadowsneak: ["8L4", "7L30", "6L30"],
			skillswap: ["8M", "7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			snore: ["8M"],
			solarbeam: ["8M", "7M", "6M"],
			spite: ["7T", "6T"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			synthesis: ["7T", "6T"],
			telekinesis: ["7T"],
			thief: ["8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["8M", "8L40", "7T", "7L1", "6T", "6L1"],
			trickortreat: ["8L1", "7L23", "6L6"],
			trickroom: ["8M", "7M", "6M"],
			willowisp: ["8M", "7M", "6M"],
			worryseed: ["8L28", "7T", "7L11", "6T", "6L11"],
		},
	},
	pumpkaboosuper: {
		learnset: {
			astonish: ["6S0"],
			scaryface: ["6S0"],
			shadowsneak: ["6S0"],
			trickortreat: ["6S0"],
		},
		eventData: [
			{generation: 6, level: 50, moves: ["trickortreat", "astonish", "scaryface", "shadowsneak"], pokeball: "cherishball"},
		],
	},
	gourgeist: {
		learnset: {
			allyswitch: ["8M", "7T"],
			astonish: ["8L1", "7L1", "6L1"],
			attract: ["8M", "7M", "6M"],
			brutalswing: ["8M"],
			bulletseed: ["8M", "8L20", "7L26", "6L26"],
			chargebeam: ["7M", "6M"],
			confide: ["7M", "6M"],
			confuseray: ["8L1", "7L1", "6L1"],
			darkpulse: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			dreameater: ["7M", "6M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "6M"],
			explosion: ["8L1", "7M", "7L1", "6M", "6L1"],
			facade: ["8M", "7M", "6M"],
			fireblast: ["8M", "7M", "6M"],
			flamecharge: ["7M", "6M"],
			flamethrower: ["8M", "7M", "6M"],
			flash: ["6M"],
			focusblast: ["8M", "7M", "6M"],
			foulplay: ["8M", "7T", "6T"],
			frustration: ["7M", "6M"],
			gigadrain: ["8M", "7T", "6T"],
			gigaimpact: ["8M", "7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			grassyglide: ["8T"],
			gyroball: ["8M", "7M", "6M"],
			hex: ["8M"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			imprison: ["8M"],
			incinerate: ["6M"],
			leechseed: ["8L16", "7L20", "6L20"],
			lightscreen: ["8M", "7M", "6M"],
			magiccoat: ["7T", "6T"],
			moonblast: ["8L1"],
			mysticalfire: ["8M"],
			nastyplot: ["8M"],
			naturepower: ["7M", "6M"],
			painsplit: ["8L44", "7T", "7L42", "6T", "6L42"],
			phantomforce: ["8M", "8L48", "7L1", "6L1"],
			poltergeist: ["8T"],
			powerwhip: ["8M"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "7M", "6M"],
			razorleaf: ["8L12", "7L16", "6L16"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			roleplay: ["7T", "6T"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			scaryface: ["8M", "8L24", "7L1", "6L4"],
			secretpower: ["6M"],
			seedbomb: ["8M", "8L32", "7T", "7L48", "6T", "6L48"],
			shadowball: ["8M", "8L36", "7M", "7L36", "6M", "6L36"],
			shadowsneak: ["8L1", "7L30", "6L30"],
			skillswap: ["8M", "7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			snore: ["8M"],
			solarbeam: ["8M", "7M", "6M"],
			spite: ["7T", "6T"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			synthesis: ["7T", "6T"],
			telekinesis: ["7T"],
			thief: ["8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["8M", "8L40", "7T", "7L1", "6T", "6L1"],
			trickortreat: ["8L1", "7L23", "6L6"],
			trickroom: ["8M", "7M", "6M"],
			willowisp: ["8M", "7M", "6M"],
			worryseed: ["8L28", "7T", "7L11", "6T", "6L11"],
		},
	},
	bergmite: {
		learnset: {
			afteryou: ["7T", "6T"],
			attract: ["8M", "7M", "6M"],
			auroraveil: ["9E", "8E"],
			avalanche: ["9M", "9L18", "8M", "8L18", "7L39", "6L39"],
			barrier: ["7E", "6E"],
			bite: ["9L21", "8L21", "7L1", "6L1"],
			blizzard: ["9M", "9L39", "8M", "8L39", "7M", "7L43", "6M", "6L43"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L33", "8M", "8L33"],
			curse: ["9L9", "8L9", "7L22", "6L22"],
			doubleedge: ["9L42", "8L42", "7L49", "6L49"],
			doubleteam: ["7M", "6M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			flash: ["6M"],
			flashcannon: ["8M", "7M", "6M"],
			frostbreath: ["7M", "6M"],
			frustration: ["7M", "6M"],
			gyroball: ["8M", "7M", "6M"],
			hail: ["8M", "7M", "6M"],
			harden: ["9L1", "8L1", "7L1", "6L1"],
			hiddenpower: ["7M", "6M"],
			iceball: ["7L30", "6L30"],
			icebeam: ["9M", "8M", "7M", "6M"],
			icefang: ["9M", "9L24", "8M", "8L24", "7L26", "6L26"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L12", "8M", "8L12", "7T", "7L10", "6T", "6L10"],
			irondefense: ["9M", "9L27", "8M", "8L27", "7T", "6T"],
			mirrorcoat: ["9E", "8E", "7E", "6E"],
			mist: ["9E", "8E", "7E", "6E"],
			powdersnow: ["9L6", "8L6", "7L5", "6L5"],
			protect: ["9M", "9L15", "8M", "8L15", "7M", "6M"],
			raindance: ["9M", "8M", "7M", "6M"],
			rapidspin: ["9L1", "8L1", "7L35", "6L35"],
			recover: ["9L30", "8L30", "7L47", "7E", "6L47", "6E"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockpolish: ["7M", "6M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sharpen: ["7L20", "6L20"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			snowscape: ["9M"],
			stoneedge: ["9M", "8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["9M", "8M", "7M", "6M"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["9L3", "8L3", "7L1", "6L1"],
			takedown: ["9M", "9L36", "8L36", "7L15", "6L15"],
			terablast: ["9M"],
			toxic: ["7M", "6M"],
			waterpulse: ["7T", "6T"],
		},
	},
	avalugg: {
		learnset: {
			afteryou: ["7T", "6T"],
			attract: ["8M", "7M", "6M"],
			avalanche: ["9M", "9L18", "8M", "8L18", "7L42", "6L42"],
			bite: ["9L21", "8L21", "7L1", "6L1"],
			blizzard: ["9M", "9L41", "8M", "8L41", "7M", "7L46", "6M", "6L46"],
			block: ["7T", "6T"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "9L0", "8M", "8L0", "7L1"],
			bulldoze: ["9M", "8M", "7M", "6M"],
			chillingwater: ["9M"],
			confide: ["7M", "6M"],
			crunch: ["9M", "9L33", "8M", "8L33", "7L1", "6L1"],
			curse: ["9L9", "8L9", "7L22", "6L22"],
			doubleedge: ["9L46", "8L46", "7L56", "6L56"],
			doubleteam: ["7M", "6M"],
			earthquake: ["9M", "8M", "7M", "6M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			flash: ["6M"],
			flashcannon: ["9M", "8M", "7M", "6M"],
			frostbreath: ["7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			gyroball: ["8M", "7M", "6M"],
			hail: ["8M", "7M", "6M"],
			harden: ["9L1", "8L1", "7L1", "6L1"],
			heavyslam: ["9M", "8M"],
			hiddenpower: ["7M", "6M"],
			highhorsepower: ["8M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			iceball: ["7L30", "6L30"],
			icebeam: ["9M", "8M", "7M", "6M"],
			icefang: ["9M", "9L24", "8M", "8L24", "7L26", "6L26"],
			icespinner: ["9M"],
			iciclecrash: ["9L51"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L12", "8M", "8L12", "7T", "7L10", "6T", "6L10"],
			irondefense: ["9M", "9L27", "8M", "8L27", "7T", "7L1", "6T", "6L1"],
			ironhead: ["9M", "8M", "7T", "6T"],
			powdersnow: ["9L1", "8L1", "7L1", "6L5"],
			protect: ["9M", "9L15", "8M", "8L15", "7M", "6M"],
			raindance: ["9M", "8M", "7M", "6M"],
			rapidspin: ["9L1", "8L1", "7L35", "6L35"],
			recover: ["9L30", "8L30", "7L51", "6L51"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockpolish: ["7M", "6M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M", "8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			sharpen: ["7L20", "6L20"],
			skullbash: ["8L51", "7L1", "6L1"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			snowscape: ["9M"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["9M", "8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			surf: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M", "9L36", "8L36", "7L15", "6L15"],
			terablast: ["9M"],
			toxic: ["7M", "6M"],
			waterpulse: ["7T", "6T"],
			wideguard: ["9L1", "8L1", "7L1"],
		},
	},
	noibat: {
		learnset: {
			absorb: ["9L1", "8L1", "7L5"],
			acrobatics: ["9M", "8M", "7M", "6M"],
			aerialace: ["9M", "7M", "6M"],
			agility: ["9M", "8M", "7L18", "6L18"],
			aircutter: ["9M", "9L24", "8L24", "7L23", "6L23"],
			airslash: ["9M", "9L36", "8M", "8L36", "7L48", "6L48"],
			attract: ["8M", "7M", "6M"],
			bite: ["9L20", "8L20", "7L13", "6L13"],
			brickbreak: ["9M", "8M", "7M", "6M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			darkpulse: ["9M", "8M", "7M", "6M"],
			defog: ["9E", "8E", "7T"],
			doubleteam: ["9L12", "8L12", "7M", "6M"],
			dracometeor: ["9M", "8T", "7T", "6T"],
			dragonclaw: ["9M"],
			dragonpulse: ["9M", "8M", "7T", "6T"],
			dragonrush: ["9E", "8E"],
			dreameater: ["7M", "6M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			fly: ["9M", "8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gust: ["9L4", "8L4", "7L11", "6L11"],
			heatwave: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hurricane: ["9M", "9L52", "8M", "8L52", "7L58", "6L58"],
			hypervoice: ["9M", "8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			leechlife: ["9M", "8M", "7M", "6L5"],
			outrage: ["9M", "8M", "7T", "7E", "6T", "6E"],
			protect: ["9M", "8M", "7M", "6M"],
			psychic: ["9M", "8M", "7M", "6M"],
			razorwind: ["7L31", "6L31"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			roost: ["9L44", "8L44", "7M", "7L27", "6M", "6L27"],
			round: ["8M", "7M", "6M"],
			screech: ["9L40", "8M", "8L40", "7L1", "6L1"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M"],
			shadowclaw: ["9M", "8M", "7M", "6M"],
			skyattack: ["7T", "6T"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snatch: ["7T", "7E", "6T", "6E"],
			snore: ["8M", "7T", "6T"],
			solarbeam: ["9M", "8M", "7M", "6M"],
			steelwing: ["8M", "7M", "6M"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			superfang: ["9L32", "8L32", "7T", "7L43", "6T", "6L43"],
			supersonic: ["9L8", "8L8", "7L1", "6L1"],
			swagger: ["7M", "6M"],
			swift: ["9M", "8M"],
			switcheroo: ["7E", "6E"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			tailwind: ["9M", "9L49", "8L49", "7T", "7L35", "7E", "6T", "6L35", "6E"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["8M", "7T", "6T"],
			uturn: ["9M", "8M", "7M", "6M"],
			waterpulse: ["7T", "6T"],
			whirlwind: ["9L28", "8L28", "7L40", "6L40"],
			wildcharge: ["9M", "8M", "7M", "6M"],
			wingattack: ["9L16", "8L16", "7L16", "6L16"],
			xscissor: ["9M", "8M", "7M", "6M"],
		},
	},
	noivern: {
		learnset: {
			absorb: ["9L1", "8L1", "7L1"],
			acrobatics: ["9M", "8M", "7M", "6M"],
			aerialace: ["9M", "7M", "6M"],
			agility: ["9M", "8M", "7L18", "6L18"],
			aircutter: ["9M", "9L24", "8L24", "7L23", "6L23"],
			airslash: ["9M", "9L36", "8M", "8L36", "7L53", "6L53"],
			attract: ["8M", "7M", "6M"],
			bite: ["9L20", "8L20", "7L13", "6L13"],
			bodyslam: ["9M"],
			boomburst: ["9L62", "8L62", "7L1", "6L1"],
			brickbreak: ["9M", "8M", "7M", "6M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			darkpulse: ["9M", "8M", "7M", "6M"],
			defog: ["7T"],
			doubleteam: ["9L12", "8L12", "7M", "6M"],
			dracometeor: ["9M", "8T", "7T", "6T"],
			dragonclaw: ["9M", "8M", "7M", "6M"],
			dragondance: ["9M", "8M"],
			dragonpulse: ["9M", "9L0", "8M", "8L0", "7T", "7L1", "6T", "6L1"],
			dragontail: ["9M"],
			dreameater: ["7M", "6M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "6M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M", "6M"],
			flamethrower: ["9M", "8M", "7M", "6M"],
			fly: ["9M", "8M", "7M", "6M"],
			focusblast: ["9M", "8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			gust: ["9L1", "8L1", "7L11", "6L11"],
			heatwave: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["6M"],
			hurricane: ["9M", "9L56", "8M", "8L56", "7L1", "6L1"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			hypervoice: ["9M", "8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			laserfocus: ["7T"],
			leechlife: ["9M", "8M", "7M", "6L5"],
			moonlight: ["9L1", "8L1", "7L1", "6L1"],
			outrage: ["9M", "8M", "7T", "6T"],
			protect: ["9M", "8M", "7M", "6M"],
			psychic: ["9M", "8M", "7M", "6M"],
			razorwind: ["7L31", "6L31"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			roost: ["9L44", "8L44", "7M", "7L27", "6M", "6L27"],
			round: ["8M", "7M", "6M"],
			scaryface: ["9M"],
			screech: ["9L40", "8M", "8L40", "7L1", "6L1"],
			secretpower: ["6M"],
			shadowball: ["9M", "8M", "7M", "6M"],
			shadowclaw: ["9M", "8M", "7M", "6M"],
			skyattack: ["7T", "6T"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["8M", "7T", "6T"],
			solarbeam: ["9M", "8M", "7M", "6M"],
			steelwing: ["8M", "7M", "6M"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			superfang: ["9L32", "8L32", "7T", "7L43", "6T", "6L43"],
			supersonic: ["9L1", "8L1", "7L1", "6L1"],
			swagger: ["7M", "6M"],
			swift: ["9M", "8M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			tailwind: ["9M", "9L51", "8L51", "7T", "7L35", "6T", "6L35"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M", "6M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["8M", "7T", "6T"],
			uturn: ["9M", "8M", "7M", "6M"],
			waterpulse: ["9M", "7T", "6T"],
			whirlwind: ["9L28", "8L28", "7L40", "6L40"],
			wildcharge: ["9M", "8M", "7M", "6M"],
			wingattack: ["9L16", "8L16", "7L16", "6L16"],
			xscissor: ["9M", "8M", "7M", "6M"],
		},
	},
	xerneas: {
		learnset: {
			aromatherapy: ["8L25", "7L1", "6L1", "6S1"],
			aurorabeam: ["8L10", "7L10", "6L10"],
			block: ["7T", "6T"],
			bodyslam: ["8M"],
			calmmind: ["8M", "7M", "6M"],
			closecombat: ["8M", "8L75", "7L80", "6L80"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			dazzlinggleam: ["8M", "8S5", "7M", "6M"],
			defog: ["7T"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["8M"],
			echoedvoice: ["7M", "6M"],
			endeavor: ["7T", "6T"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			flash: ["6M"],
			flashcannon: ["8M", "7M", "6M"],
			focusblast: ["8M", "7M", "7S4", "6M", "6S1"],
			frustration: ["7M", "6M"],
			geomancy: ["8L55", "7L26", "7S2", "7S3", "7S4", "6L26", "6S0", "6S1"],
			gigaimpact: ["8M", "8L85", "7M", "7L88", "6M", "6L88"],
			grassknot: ["8M", "7M", "7S4", "6M"],
			gravity: ["8L1", "7T", "7L18", "6T", "6L18", "6S0"],
			hail: ["8M", "7M", "6M"],
			healpulse: ["8L65", "7L1", "6L1"],
			hiddenpower: ["7M", "6M"],
			hornleech: ["8L35", "8S5", "7L55", "7S2", "7S3", "6L55"],
			hyperbeam: ["8M", "7M", "6M"],
			hypervoice: ["8M", "7T", "6T"],
			ingrain: ["8L45", "8S5", "7L1", "6L1"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "8L5", "7M", "7L5", "6M", "6L5"],
			megahorn: ["8M", "8L70", "7L44", "6L44", "6S0"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M", "8L40", "7L63", "6L63"],
			moonblast: ["8L60", "8S5", "7L35", "7S2", "7S3", "7S4", "6L35", "6S0", "6S1"],
			naturepower: ["8L15", "7M", "7L72", "6M", "6L72"],
			nightslash: ["8L20", "7L51", "7S2", "7S3", "6L51"],
			outrage: ["8M", "8L80", "7T", "7L93", "6T", "6L93"],
			playrough: ["8M"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "7M", "6M"],
			psychup: ["8L30", "7M", "7L59", "6M", "6L59"],
			psyshock: ["8M", "7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			sleeptalk: ["8M", "7M", "6M"],
			smartstrike: ["8M"],
			snore: ["8M", "7T", "6T"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			tackle: ["8L1"],
			takedown: ["8L50", "7L1", "6L1"],
			terrainpulse: ["8T"],
			thunder: ["8M", "7M", "6M"],
			thunderbolt: ["8M", "7M", "6M"],
			thunderwave: ["8M", "7M", "6M"],
			toxic: ["7M", "6M"],
			wonderroom: ["8M", "7T", "6T"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 6, level: 50, moves: ["gravity", "geomancy", "moonblast", "megahorn"]},
			{generation: 6, level: 100, shiny: true, moves: ["geomancy", "moonblast", "aromatherapy", "focusblast"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["geomancy", "hornleech", "nightslash", "moonblast"]},
			{generation: 7, level: 60, moves: ["geomancy", "hornleech", "nightslash", "moonblast"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["geomancy", "focusblast", "grassknot", "moonblast"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["ingrain", "dazzlinggleam", "moonblast", "hornleech"]},
		],
		eventOnly: true,
	},
	yveltal: {
		learnset: {
			acrobatics: ["8M", "7M", "6M"],
			aerialace: ["7M", "6M"],
			airslash: ["8M", "8L35", "7L10", "6L10"],
			block: ["7T", "6T"],
			bodyslam: ["8M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			darkpulse: ["8M", "8L40", "7M", "7L44", "7S2", "7S3", "7S4", "6M", "6L44", "6S0", "6S1"],
			defog: ["7T"],
			disable: ["8L15", "7L35", "6L35", "6S0"],
			doubleteam: ["8L1", "7M", "7L5", "6M", "6L5"],
			dragonclaw: ["8M", "7M", "6M"],
			dragonrush: ["8L65", "8S5", "7L63", "6L63"],
			dreameater: ["7M", "6M"],
			dualwingbeat: ["8T"],
			embargo: ["7M", "6M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			fly: ["8M", "7M", "6M"],
			focusblast: ["8M", "8L75", "7M", "7L72", "6M", "6L72"],
			foulplay: ["8M", "8L60", "7T", "7L51", "6T", "6L51", "6S1"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			gust: ["8L1"],
			heatwave: ["8M", "7T", "7S4", "6T"],
			hiddenpower: ["7M", "6M"],
			honeclaws: ["6M"],
			hurricane: ["8M", "8L70", "7L1", "6L1"],
			hyperbeam: ["8M", "8L85", "7M", "7L88", "6M", "6L88"],
			hypervoice: ["8M", "7T", "6T"],
			knockoff: ["7T", "6T"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			oblivionwing: ["8L50", "8S5", "7L26", "7S2", "7S3", "7S4", "6L26", "6S0", "6S1"],
			payback: ["8M"],
			phantomforce: ["8M", "8L55", "7L55", "7S2", "7S3", "6L55"],
			protect: ["8M", "7M", "6M"],
			psychic: ["8M", "8L45", "7M", "7L59", "7S2", "7S3", "6M", "6L59"],
			raindance: ["8M", "7M", "6M"],
			razorwind: ["7L1", "6L1"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockslide: ["8M", "7M", "6M"],
			roost: ["8L30", "7M", "7L1", "6M", "6L1"],
			round: ["8M", "7M", "6M"],
			secretpower: ["6M"],
			shadowball: ["8M", "7M", "6M"],
			shadowclaw: ["8M", "7M", "6M"],
			skyattack: ["8L80", "7T", "7L93", "6T", "6L93"],
			skydrop: ["7M", "6M"],
			sleeptalk: ["8M", "7M", "6M"],
			snarl: ["8M", "8L10", "7M", "7L18", "6M", "6L18", "6S0"],
			snore: ["8M", "7T", "6T"],
			steelwing: ["8M", "7M", "6M"],
			substitute: ["8M", "7M", "6M"],
			suckerpunch: ["8L20", "8S5", "7L80", "6L80", "6S1"],
			sunnyday: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			tailwind: ["8L25", "7T", "7S4", "6T"],
			taunt: ["8M", "8L5", "8S5", "7M", "7L1", "6M", "6L1"],
			thief: ["8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["8M", "7M", "6M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 6, level: 50, moves: ["snarl", "oblivionwing", "disable", "darkpulse"]},
			{generation: 6, level: 100, shiny: true, moves: ["oblivionwing", "suckerpunch", "darkpulse", "foulplay"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: 1, moves: ["oblivionwing", "darkpulse", "phantomforce", "psychic"]},
			{generation: 7, level: 60, moves: ["oblivionwing", "darkpulse", "phantomforce", "psychic"], pokeball: "cherishball"},
			{generation: 7, level: 100, moves: ["oblivionwing", "darkpulse", "heatwave", "tailwind"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["taunt", "oblivionwing", "dragonrush", "suckerpunch"]},
		],
		eventOnly: true,
	},
	zygarde: {
		learnset: {
			bind: ["8L1", "8S9", "7T", "7L18", "7S2", "7S3", "7S4", "6T", "6L18"],
			bite: ["8L1", "7L1", "6L1"],
			block: ["7T", "6T"],
			bodyslam: ["8M"],
			breakingswipe: ["8M"],
			brickbreak: ["8M", "7M", "6M"],
			bulldoze: ["8M", "8L1", "7M", "7L1", "6M", "6L1"],
			camouflage: ["7L59", "6L59", "6S0"],
			coil: ["8L72", "7L72", "6L80"],
			confide: ["7M", "6M"],
			coreenforcer: ["8L1", "7T"],
			crunch: ["8M", "8L32", "7L51", "6L51", "6S0"],
			dig: ["8M", "8L16", "7L10", "7S2", "6M", "6L10"],
			doubleteam: ["7M", "6M"],
			dracometeor: ["8T", "7T", "6T"],
			dragonbreath: ["8L1", "7L1", "7S5", "7S6", "6L1"],
			dragondance: ["8M", "7T", "7S7", "7S8", "6L72"],
			dragonpulse: ["8M", "8L40", "8S9", "7T", "7L63", "6T", "6L63", "6S0"],
			dragontail: ["7M", "6M"],
			earthpower: ["8M", "7T", "6T"],
			earthquake: ["8M", "8L80", "7M", "7L55", "6M", "6L55", "6S0"],
			endure: ["8M"],
			extremespeed: ["7T", "7S7", "7S8", "6L88", "6S1"],
			facade: ["8M", "7M", "6M"],
			focusblast: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			glare: ["8L56", "7L1", "7S5", "7S6", "6L1", "6S1"],
			grassknot: ["8M", "7M", "6M"],
			haze: ["8L8", "7L44", "7S3", "7S4", "6L44"],
			hiddenpower: ["7M", "6M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M", "6M"],
			hypervoice: ["8M", "7T", "6T"],
			irontail: ["8M", "7T", "6T"],
			landswrath: ["8L48", "8S9", "7L26", "7S2", "7S3", "7S4", "7S5", "7S6", "6L26", "6S1"],
			outrage: ["8M", "8L88", "7T", "7L80", "7S7", "7S8", "6T", "6L93", "6S1"],
			painsplit: ["7T", "6T"],
			payback: ["8M"],
			protect: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M"],
			return: ["7M", "6M"],
			reversal: ["8M"],
			rockslide: ["8M", "7M", "6M"],
			rocksmash: ["6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "8L24", "7M", "7L5", "7S2", "7S5", "7S6", "6M", "6L5"],
			sandstorm: ["8M", "8L64", "7M", "7L35", "7S3", "7S4", "6M", "6L35"],
			scaleshot: ["8T"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			shockwave: ["7T", "6T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			snore: ["8M", "7T", "6T"],
			spite: ["7T", "6T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			thousandarrows: ["8L1", "8S9", "7T", "7S7", "7S8"],
			thousandwaves: ["8L1", "7T"],
			toxic: ["7M", "6M"],
			zenheadbutt: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 6, level: 70, moves: ["crunch", "earthquake", "camouflage", "dragonpulse"]},
			{generation: 6, level: 100, moves: ["landswrath", "extremespeed", "glare", "outrage"], pokeball: "cherishball"},
			{generation: 7, level: 30, moves: ["safeguard", "dig", "bind", "landswrath"]}, // 10%
			{generation: 7, level: 50, moves: ["bind", "landswrath", "sandstorm", "haze"]},
			{generation: 7, level: 50, isHidden: true, moves: ["bind", "landswrath", "sandstorm", "haze"]},
			{generation: 7, level: 60, shiny: true, moves: ["landswrath", "glare", "safeguard", "dragonbreath"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: true, isHidden: true, moves: ["landswrath", "glare", "safeguard", "dragonbreath"], pokeball: "cherishball"}, // 10%
			{generation: 7, level: 100, shiny: true, moves: ["thousandarrows", "outrage", "extremespeed", "dragondance"], pokeball: "cherishball"},
			{generation: 7, level: 100, shiny: true, isHidden: true, moves: ["thousandarrows", "outrage", "extremespeed", "dragondance"], pokeball: "cherishball"}, // 10%
			{generation: 8, level: 70, shiny: 1, isHidden: true, moves: ["thousandarrows", "landswrath", "dragonpulse", "bind"]},
		],
		eventOnly: true,
	},
	zygarde10: {
		learnset: {
			bind: ["8S5", "7S0", "7S1", "7S2"],
			dig: ["7S0", "7S2"],
			dragonbreath: ["7S3"],
			dragondance: ["7S4"],
			dragonpulse: ["8S5"],
			extremespeed: ["7S4"],
			glare: ["7S3"],
			haze: ["7S1"],
			landswrath: ["8S5", "7S0", "7S1", "7S2", "7S3"],
			outrage: ["7S4"],
			safeguard: ["7S0", "7S2", "7S3"],
			sandstorm: ["7S1"],
			thousandarrows: ["8S5", "7S4"],
		},
		eventData: [
			{generation: 7, level: 30, moves: ["safeguard", "dig", "bind", "landswrath"]},
			{generation: 7, level: 50, isHidden: true, moves: ["bind", "landswrath", "sandstorm", "haze"]}, // 50%
			{generation: 7, level: 50, isHidden: true, moves: ["safeguard", "dig", "bind", "landswrath"]},
			{generation: 7, level: 60, shiny: true, isHidden: true, moves: ["landswrath", "glare", "safeguard", "dragonbreath"], pokeball: "cherishball"},
			{generation: 7, level: 100, shiny: true, isHidden: true, moves: ["thousandarrows", "outrage", "extremespeed", "dragondance"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, isHidden: true, moves: ["thousandarrows", "landswrath", "dragonpulse", "bind"]}, // 50%
		],
		eventOnly: true,
	},
	diancie: {
		learnset: {
			afteryou: ["7T", "6T"],
			allyswitch: ["8M"],
			amnesia: ["9M", "8M"],
			ancientpower: ["9L28", "8L28", "7L27", "6L31"],
			batonpass: ["9M", "8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			calmmind: ["9M", "8M", "7M", "6M"],
			charm: ["9M", "8M"],
			confide: ["7M", "6M"],
			covet: ["7T", "6T"],
			dazzlinggleam: ["9M", "8M", "7M", "6M"],
			diamondstorm: ["9L1", "8L91", "7L50", "6L50", "6S0", "6S1"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["9M", "8M"],
			earthpower: ["9M", "8M", "7T", "6T"],
			encore: ["9M", "8M"],
			endeavor: ["7T", "6T"],
			endure: ["9M", "8M"],
			explosion: ["7M", "6M"],
			facade: ["9M", "8M", "7M", "6M"],
			faketears: ["9M", "8M"],
			flail: ["9L21", "8L21", "7L31", "6L35"],
			flash: ["6M"],
			flashcannon: ["9M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			gravity: ["7T", "6T"],
			guardsplit: ["9L7", "8L7", "7L21", "6L27"],
			guardswap: ["8M"],
			gyroball: ["8M", "7M", "6M"],
			hail: ["8M", "7M", "6M"],
			harden: ["9L1", "8L1", "7L1", "6L1"],
			healbell: ["7T", "6T"],
			helpinghand: ["9M", "8M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			irondefense: ["9M", "8M", "7T", "6T"],
			lastresort: ["7T", "6T"],
			lightscreen: ["9M", "9L42", "8M", "8L42", "7M", "7L60", "6M", "6L60"],
			magnetrise: ["7T", "6T"],
			meteorbeam: ["8T"],
			metronome: ["9M", "8M"],
			mistyexplosion: ["8T"],
			moonblast: ["9L77", "8L77", "7L50", "6L50", "6S0", "6S1"],
			mysticalfire: ["8M"],
			naturepower: ["7M", "6M"],
			playrough: ["9M", "8M"],
			powergem: ["9M", "9L63", "8M", "8L63", "7L40"],
			protect: ["9M", "8M", "7M", "6M"],
			psychic: ["9M", "8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			psyshock: ["9M", "8M", "7M", "6M"],
			raindance: ["9M"],
			reflect: ["9M", "8M", "7M", "7L12", "6M", "6L18", "6S0", "6S1"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M", "6S0", "6S1"],
			rockpolish: ["9L35", "8L35", "7M", "6M"],
			rockslide: ["9M", "9L49", "8M", "8L49", "7M", "6M"],
			rockthrow: ["7L1", "6L5"],
			rocktomb: ["9M", "8M", "7M", "6M"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "7L70", "6M", "6L70"],
			sandstorm: ["9M", "8M", "7M", "6M"],
			sandtomb: ["8M"],
			secretpower: ["6M"],
			sharpen: ["7L5", "6L8"],
			skillswap: ["9M", "9L56", "8M", "8L56", "7T", "7L35", "6T", "6L40"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			smackdown: ["9L14", "8L14", "7M", "7L8", "6M", "6L12"],
			snore: ["8M", "7T", "6T"],
			snowscape: ["9M"],
			spikes: ["9M"],
			stealthrock: ["9M", "9L70", "8M", "8L70", "7T", "7L18", "6T", "6L21"],
			stoneedge: ["9M", "9L84", "8M", "8L84", "7M", "7L49", "6M", "6L49"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			tackle: ["9L1", "8L1", "7L1", "6L1"],
			takedown: ["9M"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			toxic: ["7M", "6M"],
			trickroom: ["9M", "8M", "7M", "7L46", "6M", "6L46"],
			wonderroom: ["8M", "7T", "6T"],
		},
		eventData: [
			{generation: 6, level: 50, moves: ["diamondstorm", "reflect", "return", "moonblast"], pokeball: "cherishball"},
			{generation: 6, level: 50, shiny: true, moves: ["diamondstorm", "moonblast", "reflect", "return"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	hoopa: {
		learnset: {
			allyswitch: ["9L1", "7T", "7L1", "6L1"],
			astonish: ["9L6", "7L6", "6L6", "6S0"],
			block: ["7T", "6T"],
			brickbreak: ["9M", "7M", "6M"],
			calmmind: ["9M", "7M", "6M"],
			chargebeam: ["7M", "6M"],
			confide: ["7M", "6M"],
			confusion: ["9L1", "7L1", "6L1"],
			covet: ["7T", "6T"],
			darkpulse: ["9M", "7M", "7L55", "6L55"],
			destinybond: ["9L1", "7L1", "6L1"],
			doubleteam: ["7M", "6M"],
			drainpunch: ["9M", "7T", "6T"],
			dreameater: ["7M", "6M"],
			dualchop: ["7T", "6T"],
			embargo: ["7M", "6M"],
			endure: ["9M"],
			energyball: ["9M", "7M", "6M"],
			facade: ["9M", "7M", "6M"],
			firepunch: ["9M", "7T", "6T"],
			flash: ["6M"],
			fling: ["9M", "7M", "6M"],
			focusblast: ["9M", "7M", "6M"],
			focuspunch: ["7T", "6T"],
			foulplay: ["9M", "7T", "6T"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "7M", "6M"],
			grassknot: ["9M", "7M", "6M"],
			gravity: ["7T", "6T"],
			guardsplit: ["9L29", "7L29", "6L29"],
			gunkshot: ["9M", "7T", "6T"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["9M", "7M", "6M"],
			hyperspacefury: ["7L1", "6L1"],
			hyperspacehole: ["9L85", "7L1", "7S1", "6L1", "6S0"],
			icepunch: ["9M", "7T", "6T"],
			knockoff: ["7T", "7L46", "6T", "6L46"],
			laserfocus: ["7T"],
			lastresort: ["7T", "6T"],
			lightscreen: ["9M", "9L15", "7M", "7L15", "6M", "6L15"],
			magiccoat: ["7T", "7L10", "6T", "6L10"],
			magicroom: ["7T", "6T"],
			nastyplot: ["9M", "9L68", "7L68", "7S1", "6L68", "6S0"],
			phantomforce: ["9M", "9L35", "7L35", "6L35"],
			powersplit: ["9L29", "7L29", "6L29"],
			poweruppunch: ["6M"],
			protect: ["9M", "7M", "6M"],
			psybeam: ["9M", "9L19", "7L19", "6L15"],
			psychic: ["9M", "9L75", "7M", "7L75", "7S1", "6M", "6L75", "6S0"],
			psychicterrain: ["9M"],
			psychup: ["7M", "6M"],
			psyshock: ["9M", "7M", "6M"],
			quash: ["7M", "6M"],
			raindance: ["9M", "7M", "6M"],
			recycle: ["7T", "6T"],
			reflect: ["9M", "7M", "6M"],
			rest: ["9M", "7M", "6M"],
			return: ["7M", "6M"],
			rocktomb: ["9M"],
			roleplay: ["7T", "6T"],
			round: ["7M", "6M"],
			safeguard: ["7M", "6M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			secretpower: ["6M"],
			shadowball: ["9M", "9L55", "7M", "7L55", "7S1", "6M", "6L55"],
			shockwave: ["7T", "6T"],
			signalbeam: ["7T", "6T"],
			skillswap: ["9M", "9L25", "7T", "7L25", "6T", "6L25"],
			sleeptalk: ["9M", "7M", "6M"],
			snatch: ["7T", "6T"],
			snore: ["7T", "6T"],
			substitute: ["9M", "7M", "6M"],
			sunnyday: ["9M", "7M", "6M"],
			swagger: ["7M", "6M"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "7M", "6M"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			thief: ["9M", "7M", "6M"],
			throatchop: ["7T"],
			thunderbolt: ["9M", "7M", "6M"],
			thunderpunch: ["9M", "7T", "6T"],
			thunderwave: ["9M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			trick: ["9M", "9L10", "7T", "7L1", "6T", "6L1"],
			trickroom: ["9M", "9L50", "7M", "7L50", "6M", "6L50"],
			uproar: ["7T", "6T"],
			wonderroom: ["9L50", "7T", "7L50", "6T", "6L50"],
			zenheadbutt: ["9M", "9L46", "7T", "7L46", "6T", "6L46"],
		},
		eventData: [
			{generation: 6, level: 50, moves: ["hyperspacehole", "nastyplot", "psychic", "astonish"], pokeball: "cherishball"},
			{generation: 7, level: 15, moves: ["shadowball", "nastyplot", "psychic", "hyperspacehole"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	hoopaunbound: {
		eventOnly: true,
	},
	volcanion: {
		learnset: {
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M", "7L46", "6L46"],
			brickbreak: ["9M", "8M", "7M", "6M"],
			bulldoze: ["9M", "8M", "7M", "6M"],
			confide: ["7M", "6M"],
			cut: ["6M"],
			defog: ["7T"],
			dig: ["9M"],
			doubleteam: ["7M", "6M"],
			earthpower: ["9M", "8M", "7T", "6T"],
			earthquake: ["9M", "8M", "7M", "6M"],
			endure: ["9M", "8M"],
			explosion: ["9L90", "8L90", "7M", "7L76", "6M", "6L76", "6S1"],
			facade: ["9M", "8M", "7M", "6M"],
			fireblast: ["9M", "8M", "7M", "6M"],
			firefang: ["9M"],
			firespin: ["9M", "9L1", "8M", "8L1"],
			flamecharge: ["9M", "9L18", "8L18", "7M", "7L15", "6M", "6L15"],
			flamethrower: ["9M", "8M", "7M", "6M", "6S1"],
			flareblitz: ["9M", "9L78", "8M", "8L78", "8S2", "7L1", "6L1"],
			flashcannon: ["9M", "8M", "7M", "6M"],
			fling: ["9M", "8M", "7M", "6M"],
			focusblast: ["9M", "8M", "7M", "6M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["9M", "8M", "7M", "6M"],
			gyroball: ["8M", "7M", "6M"],
			haze: ["9L60", "8L60", "8S2", "7L11", "6L11"],
			heatcrash: ["8M"],
			heatwave: ["9M", "8M", "7T", "6T"],
			heavyslam: ["9M", "8M"],
			hiddenpower: ["7M", "6M"],
			hydropump: ["9M", "9L66", "8M", "8L66", "7L50", "6L50", "6S0", "6S1"],
			hyperbeam: ["9M", "8M", "7M", "6M"],
			incinerate: ["9L36", "8L36", "8S2", "6M"],
			leer: ["9L6", "8L6"],
			liquidation: ["9M", "8M", "7T"],
			mist: ["9L60", "8L60", "7L8", "6L8", "6S0"],
			mistyterrain: ["9M", "8M"],
			mudshot: ["9M", "8M"],
			overheat: ["9M", "9L84", "8M", "8L84", "7M", "7L65", "6M", "6L65", "6S0"],
			protect: ["9M", "8M", "7M", "6M"],
			raindance: ["9M", "8M"],
			rest: ["9M", "8M", "7M", "6M"],
			return: ["7M", "6M"],
			roar: ["7M", "6M"],
			rockslide: ["9M", "8M", "7M", "6M"],
			rocksmash: ["6M"],
			rocktomb: ["9M"],
			round: ["8M", "7M", "6M"],
			sandstorm: ["9M", "8M", "7M", "6M"],
			scald: ["9L48", "8M", "8L48", "7M", "7L32", "6M", "6L32"],
			scaryface: ["9M", "9L30", "8M", "8L30"],
			scorchingsands: ["8T"],
			secretpower: ["6M"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M", "7M", "6M"],
			sludgebomb: ["9M", "8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			smackdown: ["7M", "6M"],
			snore: ["8M", "7T", "6T"],
			solarbeam: ["9M", "8M", "7M", "6M"],
			steameruption: ["9L1", "8L72", "8S2", "7L1", "6L1", "6S0", "6S1"],
			stomp: ["9L42", "8L42", "7L28", "6L28"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M", "6M"],
			strength: ["6M"],
			substitute: ["9M", "8M", "7M", "6M"],
			sunnyday: ["9M", "8M", "7M", "6M"],
			superpower: ["8M", "7T", "6T"],
			swagger: ["7M", "6M"],
			takedown: ["9M", "9L54", "8L54", "7L1", "6L1"],
			taunt: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M", "8M"],
			toxic: ["7M", "6M"],
			watergun: ["9L1", "8L1"],
			waterpulse: ["9M", "9L24", "8L24", "7T", "7L21", "6T", "6L21"],
			weatherball: ["9L12", "8M", "8L12", "7L40", "6L40"],
			wildcharge: ["9M"],
			willowisp: ["9M", "8M", "7M", "6M"],
		},
		eventData: [
			{generation: 6, level: 70, moves: ["steameruption", "overheat", "hydropump", "mist"], pokeball: "cherishball"},
			{generation: 6, level: 70, moves: ["steameruption", "flamethrower", "hydropump", "explosion"], pokeball: "cherishball"},
			{generation: 8, level: 60, moves: ["steameruption", "flareblitz", "incinerate", "haze"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	rowlet: {
		learnset: {
			aerialace: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			astonish: ["9L6", "8L6", "7L11"],
			attract: ["8M", "7M"],
			batonpass: ["8M", "7E"],
			bravebird: ["9M", "9L36", "8M", "8L36", "7L43"],
			bulletseed: ["9M"],
			confide: ["7M"],
			confuseray: ["9M", "9E", "8E", "7E"],
			covet: ["7T"],
			curse: ["7E"],
			defog: ["9E", "8E", "7T", "7E"],
			doubleteam: ["9E", "8E", "7M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			falseswipe: ["9M", "8M", "7M"],
			featherdance: ["9L33", "8L33", "7L39"],
			foresight: ["7L18"],
			frustration: ["7M"],
			furyattack: ["7L29"],
			gigadrain: ["9M", "8M", "7T"],
			grassknot: ["9M", "8M", "7M"],
			grasspledge: ["9M", "8T", "7T"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			growl: ["9L1", "8L1", "7L4"],
			haze: ["7E"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			knockoff: ["9E", "8E"],
			leafage: ["9L3", "8L3", "7L1"],
			leafblade: ["9L30", "8M", "8L30", "7L36"],
			leafstorm: ["9M"],
			lightscreen: ["9M", "8M", "7M"],
			magicalleaf: ["9M"],
			nastyplot: ["9M", "9L24", "8M", "8L24", "7L46"],
			naturepower: ["7M"],
			nightshade: ["9M"],
			ominouswind: ["7L16", "7E"],
			peck: ["9L9", "8L9", "7L8"],
			pluck: ["9L21", "8L21", "7L22"],
			protect: ["9M", "8M", "7M"],
			raindance: ["9M"],
			razorleaf: ["9L15", "8L15", "7L14"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			roost: ["9E", "7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["9M", "8M", "7T"],
			shadowclaw: ["9M", "8M", "7M"],
			shadowsneak: ["9L12", "8L12"],
			skyattack: ["7T"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["9M", "8M", "7M"],
			steelwing: ["8M", "7M"],
			substitute: ["9M", "8M", "7M"],
			suckerpunch: ["9L27", "8L27", "7L32"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M"],
			synthesis: ["9L18", "8L18", "7T", "7L25"],
			tackle: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			workup: ["8M", "7M"],
			worryseed: ["7T"],
		},
	},
	dartrix: {
		learnset: {
			aerialace: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			astonish: ["9L1", "8L1", "7L11"],
			attract: ["8M", "7M"],
			batonpass: ["9M", "8M"],
			bravebird: ["9M", "9L50", "8M", "8L50", "7L51"],
			bulletseed: ["9M"],
			confide: ["7M"],
			covet: ["7T"],
			defog: ["7T"],
			doubleteam: ["7M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			falseswipe: ["9M", "8M", "7M"],
			featherdance: ["9L45", "8L45", "7L46"],
			foresight: ["7L19"],
			frustration: ["7M"],
			furyattack: ["7L33"],
			gigadrain: ["9M", "8M", "7T"],
			grassknot: ["9M", "8M", "7M"],
			grasspledge: ["9M", "8T", "7T"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			growl: ["9L1", "8L1", "7L1"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			leafage: ["9L1", "8L1", "7L1"],
			leafblade: ["9L40", "8M", "8L40", "7L42"],
			leafstorm: ["9M"],
			lightscreen: ["8M", "7M"],
			magicalleaf: ["9M"],
			nastyplot: ["9M", "9L30", "8M", "8L30", "7L55"],
			naturepower: ["7M"],
			ominouswind: ["7L16"],
			peck: ["9L9", "8L9", "7L1"],
			pluck: ["9L25", "8L25", "7L24"],
			protect: ["9M", "8M", "7M"],
			raindance: ["9M"],
			razorleaf: ["9L15", "8L15", "7L14"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["9M", "8M", "7T"],
			shadowclaw: ["9M", "8M", "7M"],
			shadowsneak: ["9L12", "8L12"],
			skyattack: ["7T"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["9M", "8M", "7M"],
			steelwing: ["8M", "7M"],
			substitute: ["9M", "8M", "7M"],
			suckerpunch: ["9L35", "8L35", "7L37"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M"],
			synthesis: ["9L20", "8L20", "7T", "7L28"],
			tackle: ["9L1", "8L1", "7L1"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			workup: ["8M", "7M"],
			worryseed: ["7T"],
		},
	},
	decidueye: {
		learnset: {
			acrobatics: ["9M", "8M", "7M"],
			aerialace: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M", "8M"],
			astonish: ["9L1", "8L1", "7L11"],
			attract: ["8M", "7M"],
			batonpass: ["9M", "8M"],
			bravebird: ["9M", "9L58", "8M", "8L58", "7L55", "7S0"],
			bulletseed: ["9M"],
			confide: ["7M"],
			confuseray: ["9M"],
			covet: ["7T"],
			defog: ["7T"],
			doubleteam: ["7M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			falseswipe: ["9M", "8M", "7M"],
			featherdance: ["9L51", "8L51", "7L49"],
			foresight: ["7L19"],
			frenzyplant: ["9M", "8T", "7T"],
			frustration: ["7M"],
			furyattack: ["7L33"],
			gigadrain: ["9M", "8M", "7T"],
			gigaimpact: ["9M", "8M", "7M"],
			grassknot: ["9M", "8M", "7M"],
			grasspledge: ["9M", "8T", "7T"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			growl: ["9L1", "8L1", "7L1"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			imprison: ["9M", "8M"],
			laserfocus: ["7T"],
			leafage: ["9L1", "8L1", "7L1"],
			leafblade: ["9L44", "8M", "8L44", "7L44", "7S0"],
			leafstorm: ["9M", "9L1", "8M", "8L1", "7L1"],
			lightscreen: ["9M", "8M", "7M"],
			lowkick: ["9M"],
			lowsweep: ["9M", "8M", "7M"],
			magicalleaf: ["9M"],
			nastyplot: ["9M", "9L30", "8M", "8L30", "7L60"],
			naturepower: ["7M"],
			nightshade: ["9M"],
			ominouswind: ["7L16"],
			peck: ["9L9", "8L9", "7L1"],
			phantomforce: ["9M", "9L1", "8M", "8L1", "7L1", "7S0"],
			pluck: ["9L25", "8L25", "7L24"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "7M"],
			psychocut: ["8M"],
			raindance: ["9M"],
			razorleaf: ["9L15", "8L15", "7L14"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["9M", "8M", "7T"],
			shadowball: ["9M", "8M", "7M"],
			shadowclaw: ["9M", "8M", "7M"],
			shadowsneak: ["9L12", "8L12", "7L1", "7S0"],
			skittersmack: ["8T"],
			skyattack: ["7T"],
			sleeptalk: ["9M", "8M", "7M"],
			smackdown: ["7M"],
			snore: ["8M", "7T"],
			solarbeam: ["9M", "8M", "7M"],
			solarblade: ["8M"],
			spiritshackle: ["9L0", "8L0", "7L1"],
			spite: ["9L1", "8L1", "7T"],
			steelwing: ["8M", "7M"],
			substitute: ["9M", "8M", "7M"],
			suckerpunch: ["9L37", "8L37", "7L38"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M"],
			synthesis: ["9L20", "8L20", "7T", "7L28"],
			tackle: ["9L1", "8L1", "7L1"],
			tailwind: ["9M", "7T"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L1", "8M", "8L1", "7M", "7L1"],
			workup: ["8M", "7M"],
			worryseed: ["7T"],
		},
		eventData: [
			{generation: 7, level: 50, isHidden: true, moves: ["leafblade", "phantomforce", "shadowsneak", "bravebird"], pokeball: "pokeball"},
		],
	},
	decidueyehisui: {
		learnset: {
			aerialace: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			aurasphere: ["9M"],
			batonpass: ["9M"],
			bravebird: ["9M", "9L58"],
			brickbreak: ["9M"],
			bulkup: ["9M", "9L30"],
			bulletseed: ["9M"],
			closecombat: ["9M"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			featherdance: ["9L51"],
			focusblast: ["9M"],
			frenzyplant: ["9M"],
			gigadrain: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grasspledge: ["9M"],
			grassyterrain: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			leafage: ["9L1"],
			leafblade: ["9L44"],
			leafstorm: ["9M", "9L1"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			magicalleaf: ["9M"],
			nastyplot: ["9M"],
			peck: ["9L9"],
			pluck: ["9L25"],
			protect: ["9M"],
			raindance: ["9M"],
			razorleaf: ["9L15"],
			rest: ["9M"],
			reversal: ["9M"],
			rocktomb: ["9M"],
			scaryface: ["9M"],
			seedbomb: ["9M"],
			shadowclaw: ["9M"],
			shadowsneak: ["9L12"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			substitute: ["9M"],
			suckerpunch: ["9L37"],
			sunnyday: ["9M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			synthesis: ["9L20"],
			tackle: ["9L1"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			trailblaze: ["9M"],
			triplearrows: ["9L0"],
			uturn: ["9M", "9L1"],
		},
	},
	litten: {
		learnset: {
			acrobatics: ["8M", "7M"],
			attract: ["8M", "7M"],
			bite: ["8L15", "7L22"],
			bodyslam: ["8M", "7E"],
			bulkup: ["8M", "7M"],
			confide: ["7M"],
			covet: ["7T"],
			crunch: ["8M", "7E"],
			doublekick: ["8L18", "7L16"],
			doubleteam: ["7M"],
			ember: ["8L3", "7L1"],
			endeavor: ["7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fakeout: ["8E", "7E"],
			fireblast: ["8M", "7M"],
			firefang: ["8M", "8L21", "7L14"],
			firepledge: ["8T", "7T"],
			firespin: ["8M"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "8L30", "7M", "7L36"],
			flareblitz: ["8M", "8L36", "7L43"],
			frustration: ["7M"],
			furyswipes: ["8L12", "7L29"],
			growl: ["8L1", "7L4"],
			heatwave: ["8M", "7T", "7E"],
			hiddenpower: ["7M"],
			leechlife: ["8M", "7M"],
			leer: ["7L11"],
			lick: ["8L6", "7L8"],
			nastyplot: ["8M", "7E"],
			outrage: ["8M", "7T", "7L46"],
			overheat: ["8M", "7M"],
			partingshot: ["8E"],
			payday: ["8M"],
			powertrip: ["8E", "7E"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M", "7E"],
			roar: ["8L9", "7M", "7L18"],
			round: ["8M", "7M"],
			scaryface: ["8M", "8L24", "7L39"],
			scratch: ["8L1", "7L1"],
			shadowclaw: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["8L27", "7M", "7L25"],
			swordsdance: ["8M", "7M"],
			taunt: ["8M", "7M"],
			thrash: ["8L33", "7L32"],
			torment: ["7M"],
			toxic: ["7M"],
			uturn: ["8M", "7M"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
		},
	},
	torracat: {
		learnset: {
			acrobatics: ["8M", "7M"],
			attract: ["8M", "7M"],
			bite: ["8L15", "7L24"],
			bodyslam: ["8M"],
			bulkup: ["8M", "7M"],
			confide: ["7M"],
			covet: ["7T"],
			crunch: ["8M"],
			doublekick: ["8L20", "7L16"],
			doubleteam: ["7M"],
			dualchop: ["7T"],
			ember: ["8L1", "7L1"],
			endeavor: ["7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			firefang: ["8M", "8L25", "7L14"],
			firepledge: ["8T", "7T"],
			firespin: ["8M"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "8L40", "7M", "7L42"],
			flareblitz: ["8M", "8L50", "7L51"],
			frustration: ["7M"],
			furyswipes: ["8L12", "7L33"],
			growl: ["8L1", "7L1"],
			heatwave: ["8M", "7T"],
			hiddenpower: ["7M"],
			leechlife: ["8M", "7M"],
			leer: ["7L11"],
			lick: ["8L1", "7L1"],
			nastyplot: ["8M"],
			outrage: ["8M", "7T", "7L55"],
			overheat: ["8M", "7M"],
			payday: ["8M"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			roar: ["8L9", "7M", "7L19"],
			round: ["8M", "7M"],
			scaryface: ["8M", "8L30", "7L46"],
			scratch: ["8L1", "7L1"],
			shadowclaw: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["8L35", "7M", "7L28"],
			swordsdance: ["8M", "7M"],
			taunt: ["8M", "7M"],
			thrash: ["8L45", "7L37"],
			torment: ["7M"],
			toxic: ["7M"],
			uturn: ["8M", "7M"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
		},
	},
	incineroar: {
		learnset: {
			acrobatics: ["8M", "7M"],
			assurance: ["8M"],
			attract: ["8M", "7M"],
			bind: ["7T"],
			bite: ["8L15", "7L24"],
			blastburn: ["8T", "7T"],
			blazekick: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "8L1", "7M", "7L1"],
			bulldoze: ["8M", "7M"],
			burningjealousy: ["8T"],
			closecombat: ["8M"],
			confide: ["7M"],
			covet: ["7T"],
			crosschop: ["8L1", "7L66"],
			crunch: ["8M"],
			darkestlariat: ["8M", "8L0", "7L1", "7S0"],
			darkpulse: ["8M", "7M"],
			doublekick: ["8L20", "7L16"],
			doubleteam: ["7M"],
			drainpunch: ["8M", "7T"],
			dualchop: ["7T"],
			earthquake: ["8M", "7M"],
			embargo: ["7M"],
			ember: ["8L1", "7L1"],
			endeavor: ["7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fakeout: ["7S0"],
			fireblast: ["8M", "7M"],
			firefang: ["8M", "8L25", "7L14"],
			firepledge: ["8T", "7T"],
			firepunch: ["8M", "7T"],
			firespin: ["8M"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "8L44", "7M", "7L44"],
			flareblitz: ["8M", "8L58", "7L55", "7S0"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			furyswipes: ["8L12", "7L33"],
			gigaimpact: ["8M", "7M"],
			growl: ["8L1", "7L1"],
			heatcrash: ["8M"],
			heatwave: ["8M", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			ironhead: ["8M", "7T"],
			knockoff: ["7T"],
			lashout: ["8T"],
			leechlife: ["8M", "7M"],
			leer: ["7L11"],
			lick: ["8L1", "7L1"],
			lowkick: ["8M", "7T"],
			lowsweep: ["8M", "7M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["8M"],
			outrage: ["8M", "7T", "7L60"],
			overheat: ["8M", "7M"],
			payday: ["8M"],
			protect: ["8M", "7M"],
			quash: ["7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["8L9", "7M", "7L19"],
			round: ["8M", "7M"],
			scaryface: ["8M", "8L30", "7L49"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "7L1"],
			shadowclaw: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			stompingtantrum: ["8M", "7T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			superpower: ["8M", "7T"],
			swagger: ["8L32", "7M", "7L28"],
			swordsdance: ["8M", "7M"],
			taunt: ["8M", "7M"],
			thrash: ["8L51", "7L38"],
			throatchop: ["8M", "8L1", "7T", "7L1"],
			thunderpunch: ["8M", "7T"],
			torment: ["7M"],
			toxic: ["7M"],
			uturn: ["8M", "7M", "7S0"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 50, isHidden: true, moves: ["fakeout", "uturn", "darkestlariat", "flareblitz"], pokeball: "pokeball"},
		],
	},
	popplio: {
		learnset: {
			acrobatics: ["8M", "7M"],
			amnesia: ["8M", "7E"],
			aquajet: ["8L9", "7L14"],
			aquaring: ["8E", "7E"],
			aquatail: ["7T"],
			aromaticmist: ["7E"],
			attract: ["8M", "7M"],
			babydolleyes: ["8L12", "7L11"],
			blizzard: ["8M", "7M"],
			brine: ["8M"],
			bubblebeam: ["8L21", "7L22"],
			captivate: ["7L39"],
			charm: ["8M", "7E"],
			confide: ["7M"],
			covet: ["7T"],
			disarmingvoice: ["8L6", "7L8"],
			dive: ["8M"],
			doubleslap: ["7L29"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			echoedvoice: ["7M"],
			encore: ["8M", "8L24", "7L18"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			flipturn: ["8T"],
			frustration: ["7M"],
			growl: ["8L1", "7L4"],
			hail: ["8M", "7M"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hydropump: ["8M", "8L36", "7L43"],
			hypervoice: ["8M", "8L30", "7T", "7L32"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "8L15", "7T", "7L16"],
			irontail: ["8M", "7T"],
			lifedew: ["8E"],
			mistyterrain: ["8M", "8L27", "7L46"],
			moonblast: ["8L33", "7L36"],
			perishsong: ["8E", "7E"],
			playrough: ["8M"],
			pound: ["8L1", "7L1"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			scald: ["8M", "7M"],
			sing: ["8L18", "7L25"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			toxic: ["7M"],
			tripleaxel: ["8T"],
			uproar: ["8M", "7T"],
			waterfall: ["8M", "7M"],
			watergun: ["8L3", "7L1"],
			waterpledge: ["8T", "7T"],
			waterpulse: ["7T"],
			whirlpool: ["8M"],
			wonderroom: ["8M", "7T", "7E"],
			workup: ["8M", "7M"],
		},
	},
	brionne: {
		learnset: {
			acrobatics: ["8M", "7M"],
			amnesia: ["8M"],
			aquajet: ["8L9", "7L14"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			babydolleyes: ["8L12", "7L11"],
			blizzard: ["8M", "7M"],
			brine: ["8M"],
			bubblebeam: ["8L25", "7L24"],
			captivate: ["7L46"],
			charm: ["8M"],
			confide: ["7M"],
			covet: ["7T"],
			disarmingvoice: ["8L1", "7L1"],
			dive: ["8M"],
			doubleslap: ["7L33"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			echoedvoice: ["7M"],
			encore: ["8M", "8L30", "7L19"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			flipturn: ["8T"],
			frustration: ["7M"],
			growl: ["8L1", "7L1"],
			hail: ["8M", "7M"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hydropump: ["8M", "8L50", "7L51"],
			hypervoice: ["8M", "8L40", "7T", "7L37"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "8L15", "7T", "7L16"],
			irontail: ["8M", "7T"],
			mistyterrain: ["8M", "8L35", "7L55"],
			moonblast: ["8L45", "7L42"],
			playrough: ["8M"],
			pound: ["8L1", "7L1"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			scald: ["8M", "7M"],
			sing: ["8L20", "7L28"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			toxic: ["7M"],
			tripleaxel: ["8T"],
			uproar: ["8M", "7T"],
			waterfall: ["8M", "7M"],
			watergun: ["8L1", "7L1"],
			waterpledge: ["8T", "7T"],
			waterpulse: ["7T"],
			whirlpool: ["8M"],
			wonderroom: ["8M", "7T"],
			workup: ["8M", "7M"],
		},
	},
	primarina: {
		learnset: {
			acrobatics: ["8M", "7M"],
			amnesia: ["8M"],
			aquajet: ["8L9", "7L14"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			babydolleyes: ["8L12", "7L11"],
			blizzard: ["8M", "7M"],
			brine: ["8M"],
			bubblebeam: ["8L25", "7L24"],
			calmmind: ["8M"],
			captivate: ["7L49"],
			charm: ["8M"],
			confide: ["7M"],
			covet: ["7T"],
			dazzlinggleam: ["8M", "7M"],
			disarmingvoice: ["8L1", "7L1"],
			dive: ["8M"],
			doubleslap: ["7L33"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			echoedvoice: ["7M"],
			encore: ["8M", "8L30", "7L19"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			facade: ["8M", "7M"],
			flipturn: ["8T"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			growl: ["8L1", "7L1"],
			hail: ["8M", "7M"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hydrocannon: ["8T", "7T"],
			hydropump: ["8M", "8L58", "7L55"],
			hyperbeam: ["8M"],
			hypervoice: ["8M", "8L44", "7T", "7L38", "7S0"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "8L15", "7T", "7L16", "7S0"],
			irontail: ["8M", "7T"],
			lightscreen: ["8M", "7M"],
			liquidation: ["8M", "7T"],
			magiccoat: ["7T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M", "8L37", "7L60"],
			moonblast: ["8L51", "7L44", "7S0"],
			perishsong: ["7S0"],
			playrough: ["8M"],
			pound: ["8L1", "7L1"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psychup: ["7M"],
			raindance: ["8M", "7M"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			scald: ["8M", "7M"],
			shadowball: ["8M", "7M"],
			sing: ["8L20", "7L28"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			sparklingaria: ["8L0", "7L1"],
			storedpower: ["8M"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			toxic: ["7M"],
			tripleaxel: ["8T"],
			uproar: ["8M", "7T"],
			waterfall: ["8M", "7M"],
			watergun: ["8L1", "7L1"],
			waterpledge: ["8T", "7T"],
			waterpulse: ["7T"],
			weatherball: ["8M"],
			whirlpool: ["8M"],
			wonderroom: ["8M", "7T"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 50, isHidden: true, moves: ["hypervoice", "moonblast", "icywind", "perishsong"], pokeball: "pokeball"},
		],
	},
	pikipek: {
		learnset: {
			aerialace: ["7M"],
			attract: ["7M"],
			boomburst: ["7E"],
			bravebird: ["7E"],
			brickbreak: ["7M"],
			bulletseed: ["7L31"],
			confide: ["7M"],
			defog: ["7T"],
			doubleteam: ["7M"],
			drillpeck: ["7L27"],
			echoedvoice: ["7M", "7L7"],
			featherdance: ["7L33"],
			flamecharge: ["7M"],
			fly: ["7M"],
			frustration: ["7M"],
			furyattack: ["7L21"],
			growl: ["7L3"],
			gunkshot: ["7T"],
			heatwave: ["7T"],
			hiddenpower: ["7M"],
			hypervoice: ["7T", "7L37"],
			knockoff: ["7T"],
			mirrormove: ["7E"],
			peck: ["7L1"],
			pluck: ["7L15"],
			protect: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			rocksmash: ["7L9"],
			roost: ["7M", "7L19"],
			round: ["7M"],
			screech: ["7L25"],
			skyattack: ["7T"],
			sleeptalk: ["7M"],
			smackdown: ["7M"],
			snore: ["7T"],
			steelwing: ["7M"],
			substitute: ["7M"],
			sunnyday: ["7M"],
			supersonic: ["7L13"],
			swagger: ["7M"],
			swordsdance: ["7M"],
			tailwind: ["7T", "7E"],
			thief: ["7M"],
			toxic: ["7M"],
			uproar: ["7T", "7E"],
			uturn: ["7M"],
			workup: ["7M"],
		},
	},
	trumbeak: {
		learnset: {
			aerialace: ["7M"],
			attract: ["7M"],
			brickbreak: ["7M"],
			bulletseed: ["7L37"],
			confide: ["7M"],
			defog: ["7T"],
			doubleteam: ["7M"],
			drillpeck: ["7L32"],
			echoedvoice: ["7M", "7L1"],
			featherdance: ["7L40"],
			flamecharge: ["7M"],
			fly: ["7M"],
			frustration: ["7M"],
			furyattack: ["7L24"],
			growl: ["7L1"],
			gunkshot: ["7T"],
			heatwave: ["7T"],
			hiddenpower: ["7M"],
			hypervoice: ["7T", "7L45"],
			knockoff: ["7T"],
			peck: ["7L1"],
			pluck: ["7L16"],
			protect: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			rockblast: ["7L1"],
			rocksmash: ["7L1"],
			roost: ["7M", "7L21"],
			round: ["7M"],
			screech: ["7L29"],
			skyattack: ["7T"],
			sleeptalk: ["7M"],
			smackdown: ["7M"],
			snore: ["7T"],
			steelwing: ["7M"],
			substitute: ["7M"],
			sunnyday: ["7M"],
			supersonic: ["7L13"],
			swagger: ["7M"],
			swordsdance: ["7M"],
			tailwind: ["7T"],
			thief: ["7M"],
			toxic: ["7M"],
			uproar: ["7T"],
			uturn: ["7M"],
			workup: ["7M"],
		},
	},
	toucannon: {
		learnset: {
			aerialace: ["7M"],
			attract: ["7M"],
			beakblast: ["7L1"],
			brickbreak: ["7M"],
			bulletseed: ["7L40"],
			confide: ["7M"],
			defog: ["7T"],
			doubleteam: ["7M"],
			drillpeck: ["7L34"],
			echoedvoice: ["7M", "7L1"],
			featherdance: ["7L44"],
			flamecharge: ["7M"],
			flashcannon: ["7M"],
			fly: ["7M"],
			frustration: ["7M"],
			furyattack: ["7L24"],
			growl: ["7L1"],
			gunkshot: ["7T"],
			heatwave: ["7T"],
			hiddenpower: ["7M"],
			hypervoice: ["7T", "7L50"],
			knockoff: ["7T"],
			overheat: ["7M"],
			peck: ["7L1"],
			pluck: ["7L16"],
			protect: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			rockblast: ["7L1"],
			rocksmash: ["7L1"],
			roost: ["7M", "7L21"],
			round: ["7M"],
			screech: ["7L30"],
			seedbomb: ["7T"],
			skyattack: ["7T"],
			sleeptalk: ["7M"],
			smackdown: ["7M"],
			snore: ["7T"],
			steelwing: ["7M"],
			substitute: ["7M"],
			sunnyday: ["7M"],
			supersonic: ["7L13"],
			swagger: ["7M"],
			swordsdance: ["7M"],
			tailwind: ["7T"],
			thief: ["7M"],
			toxic: ["7M"],
			uproar: ["7T"],
			uturn: ["7M"],
			workup: ["7M"],
		},
		encounters: [
			{generation: 7, level: 26},
		],
	},
	yungoos: {
		learnset: {
			attract: ["7M"],
			bide: ["7L16"],
			bite: ["9L19", "7L19"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			confide: ["7M"],
			crunch: ["9M", "9L34", "7L34"],
			dig: ["9M"],
			doubleteam: ["7M"],
			earthquake: ["7M"],
			echoedvoice: ["7M"],
			endeavor: ["9E", "7T"],
			endure: ["9M"],
			facade: ["9M", "7M"],
			firefang: ["9M", "9E", "7E"],
			frustration: ["7M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			hyperfang: ["7L37"],
			icefang: ["9M", "9E", "7E"],
			irontail: ["7T"],
			lastresort: ["9E", "7T", "7E"],
			leer: ["9L3", "7L3"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L22", "7L22"],
			odorsleuth: ["7L13"],
			payback: ["9L7", "7M"],
			protect: ["9M", "7M"],
			psychicfangs: ["9M"],
			pursuit: ["7L7"],
			raindance: ["9M"],
			rest: ["9M", "9L43", "7M", "7L46"],
			return: ["7M"],
			revenge: ["7E"],
			reversal: ["9M"],
			rocktomb: ["9M", "7M"],
			round: ["7M"],
			sandattack: ["9L10", "7L10"],
			sandstorm: ["9M", "7M"],
			scaryface: ["9M", "9L31", "7L31"],
			seedbomb: ["9M"],
			shockwave: ["7T"],
			sleeptalk: ["9M", "7M"],
			snore: ["7T"],
			stompingtantrum: ["9M", "7T"],
			substitute: ["9M", "7M"],
			sunnyday: ["9M"],
			superfang: ["9L25", "7T", "7L25"],
			swagger: ["7M"],
			tackle: ["9L1", "7L1"],
			takedown: ["9M", "9L28", "7L28"],
			taunt: ["9M", "7M"],
			terablast: ["9M"],
			thief: ["9M", "7M"],
			thrash: ["9L40", "7L43"],
			thunderfang: ["9M", "9E", "7E"],
			torment: ["7M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["7T"],
			uturn: ["9M", "7M"],
			wildcharge: ["9M"],
			workup: ["9L13", "7M"],
			yawn: ["9L37", "7L40"],
			zenheadbutt: ["9M"],
		},
	},
	gumshoos: {
		learnset: {
			attract: ["7M"],
			bide: ["7L16"],
			bite: ["9L19", "7L19"],
			block: ["7T"],
			bodyslam: ["9M"],
			bulldoze: ["9M", "7M"],
			chillingwater: ["9M"],
			confide: ["7M"],
			crunch: ["9M", "9L39", "7L39"],
			dig: ["9M"],
			doubleteam: ["7M"],
			dualchop: ["7T"],
			earthquake: ["9M", "7M"],
			echoedvoice: ["7M"],
			endeavor: ["7T"],
			endure: ["9M"],
			facade: ["9M", "7M"],
			firefang: ["9M"],
			firepunch: ["9M", "7T"],
			fling: ["9M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M"],
			hyperfang: ["7L43"],
			icefang: ["9M"],
			icepunch: ["9M", "7T"],
			ironhead: ["9M", "7T"],
			irontail: ["7T"],
			lastresort: ["7T"],
			leer: ["9L1", "7L1"],
			lowsweep: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L23", "7L23"],
			odorsleuth: ["7L13"],
			payback: ["9L1", "7M"],
			protect: ["9M", "7M"],
			psychicfangs: ["9M"],
			pursuit: ["7L1"],
			raindance: ["9M"],
			rest: ["9M", "9L52", "7M", "7L55"],
			return: ["7M"],
			reversal: ["9M"],
			roar: ["7M"],
			rocktomb: ["9M", "7M"],
			round: ["7M"],
			sandattack: ["9L1", "7L1"],
			sandstorm: ["9M", "7M"],
			scaryface: ["9M", "9L35", "7L35"],
			seedbomb: ["9M"],
			shockwave: ["7T"],
			sleeptalk: ["9M", "7M"],
			snore: ["7T"],
			stompingtantrum: ["9M", "7T"],
			substitute: ["9M", "7M"],
			sunnyday: ["9M"],
			superfang: ["9L27", "7T", "7L27"],
			swagger: ["7M"],
			tackle: ["9L1", "7L1"],
			takedown: ["9M", "9L31", "7L31"],
			taunt: ["9M", "7M"],
			terablast: ["9M"],
			thief: ["9M", "7M"],
			thrash: ["9L47", "7L51"],
			thunderfang: ["9M"],
			thunderpunch: ["9M", "7T"],
			torment: ["7M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["7T"],
			uturn: ["9M", "7M"],
			wildcharge: ["9M"],
			workup: ["9L13", "7M"],
			yawn: ["9L43", "7L47"],
			zenheadbutt: ["9M", "7T"],
		},
		encounters: [
			{generation: 7, level: 17},
		],
	},
	gumshoostotem: {
		learnset: {
			attract: ["7M"],
			bide: ["7L16", "7S0"],
			bite: ["7L19", "7S0"],
			block: ["7T"],
			bulldoze: ["7M"],
			confide: ["7M"],
			crunch: ["7L39"],
			doubleteam: ["7M"],
			dualchop: ["7T"],
			earthquake: ["7M"],
			echoedvoice: ["7M"],
			endeavor: ["7T"],
			facade: ["7M"],
			firepunch: ["7T"],
			fling: ["7M"],
			frustration: ["7M"],
			hiddenpower: ["7M"],
			hyperfang: ["7L43"],
			icepunch: ["7T"],
			ironhead: ["7T"],
			irontail: ["7T"],
			lastresort: ["7T"],
			leer: ["7L1"],
			mudslap: ["7L23"],
			odorsleuth: ["7L13", "7S0"],
			payback: ["7M"],
			protect: ["7M"],
			pursuit: ["7L1"],
			rest: ["7M", "7L55"],
			return: ["7M"],
			roar: ["7M"],
			rocktomb: ["7M"],
			round: ["7M"],
			sandattack: ["7L1", "7S0"],
			sandstorm: ["7M"],
			scaryface: ["7L35"],
			shockwave: ["7T"],
			sleeptalk: ["7M"],
			snore: ["7T"],
			stompingtantrum: ["7T"],
			substitute: ["7M"],
			superfang: ["7T", "7L27"],
			swagger: ["7M"],
			tackle: ["7L1"],
			takedown: ["7L31"],
			taunt: ["7M"],
			thief: ["7M"],
			thrash: ["7L51"],
			thunderpunch: ["7T"],
			torment: ["7M"],
			toxic: ["7M"],
			uproar: ["7T"],
			uturn: ["7M"],
			workup: ["7M"],
			yawn: ["7L47"],
			zenheadbutt: ["7T"],
		},
		eventData: [
			{generation: 7, level: 20, perfectIVs: 3, moves: ["sandattack", "odorsleuth", "bide", "bite"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	grubbin: {
		learnset: {
			acrobatics: ["8M", "7M", "7L19"],
			attract: ["8M", "7M"],
			bite: ["8L15", "7L10"],
			bugbite: ["8L10", "7T", "7L13"],
			chargebeam: ["7M"],
			confide: ["7M"],
			crunch: ["8M", "8L35", "7L22"],
			dig: ["8M", "8L40", "7L28"],
			discharge: ["8E"],
			doubleteam: ["7M"],
			electroweb: ["8M", "7T", "7E"],
			endure: ["8M", "7E"],
			facade: ["8M", "7M"],
			frustration: ["7M"],
			harden: ["8E", "7E"],
			hiddenpower: ["7M"],
			lightscreen: ["8M", "7M"],
			magnetrise: ["7T"],
			mudshot: ["8M", "7E"],
			mudslap: ["8L1", "7L7"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			screech: ["8M"],
			shockwave: ["7T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["8L21", "7L16"],
			stickyweb: ["8L25"],
			stringshot: ["8L5", "7L4"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			thunderbolt: ["8M", "7M"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			visegrip: ["8L1", "7L1"],
			voltswitch: ["8M", "7M"],
			wildcharge: ["8M", "7M"],
			xscissor: ["8M", "8L30", "7M", "7L25"],
		},
	},
	charjabug: {
		learnset: {
			acrobatics: ["8M", "7M", "7L19"],
			attract: ["8M", "7M"],
			bite: ["8L15", "7L1"],
			bugbite: ["8L1", "7T", "7L13"],
			charge: ["8L0", "7L1"],
			chargebeam: ["7M"],
			confide: ["7M"],
			crunch: ["8M", "8L43", "7L25"],
			dig: ["8M", "8L50", "7L37"],
			discharge: ["8L64", "7L43"],
			doubleteam: ["7M"],
			eerieimpulse: ["8M"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			frustration: ["7M"],
			hiddenpower: ["7M"],
			irondefense: ["8M", "8L57", "7T", "7L49"],
			lightscreen: ["8M", "7M"],
			magnetrise: ["7T"],
			mudshot: ["8M"],
			mudslap: ["8L1", "7L1"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			screech: ["8M"],
			shockwave: ["7T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["8L23", "7L16"],
			stickyweb: ["8L29"],
			stringshot: ["8L1", "7L1"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			thunderbolt: ["8M", "7M"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			visegrip: ["8L1", "7L1"],
			voltswitch: ["8M", "7M"],
			wildcharge: ["8M", "7M"],
			xscissor: ["8M", "8L36", "7M", "7L31"],
		},
	},
	vikavolt: {
		learnset: {
			acrobatics: ["8M", "7M", "7L19"],
			agility: ["8M", "8L57", "7L49"],
			airslash: ["8M", "7L1"],
			attract: ["8M", "7M"],
			bite: ["8L15", "7L1"],
			bugbite: ["8L1", "7T", "7L13"],
			bugbuzz: ["8M", "8L36", "7L31"],
			charge: ["8L1", "7L1"],
			chargebeam: ["7M"],
			confide: ["7M"],
			crunch: ["8M", "8L1"],
			dig: ["8M", "8L1", "7L37"],
			discharge: ["8L1"],
			doubleteam: ["7M"],
			dualwingbeat: ["8T"],
			eerieimpulse: ["8M"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			facade: ["8M", "7M"],
			flashcannon: ["8M", "7M"],
			fly: ["8M", "8L50"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			guillotine: ["8L43", "7L25"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			irondefense: ["8M", "8L1", "7T"],
			laserfocus: ["7T"],
			lightscreen: ["8M", "7M"],
			magnetrise: ["7T"],
			mudshot: ["8M"],
			mudslap: ["8L1", "7L1"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			risingvoltage: ["8T"],
			roost: ["7M"],
			round: ["8M", "7M"],
			screech: ["8M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			skittersmack: ["8T"],
			skydrop: ["7M"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			spark: ["8L23", "7L16"],
			stickyweb: ["8L29"],
			stringshot: ["8L1", "7L1"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "8L0", "7M", "7L1"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			visegrip: ["8L1", "7L1"],
			voltswitch: ["8M", "7M"],
			wildcharge: ["8M", "7M"],
			xscissor: ["8M", "8L1", "7M"],
			zapcannon: ["8L64", "7L41"],
		},
	},
	vikavolttotem: {
		learnset: {
			acrobatics: ["7M", "7L19", "7S0"],
			agility: ["7L49"],
			airslash: ["7L1"],
			attract: ["7M"],
			bite: ["7L1"],
			bugbite: ["7T", "7L13"],
			bugbuzz: ["7L31", "7S0"],
			charge: ["7L1"],
			chargebeam: ["7M"],
			confide: ["7M"],
			dig: ["7L37"],
			doubleteam: ["7M"],
			electroweb: ["7T"],
			energyball: ["7M"],
			facade: ["7M"],
			flashcannon: ["7M"],
			frustration: ["7M"],
			gigaimpact: ["7M"],
			guillotine: ["7L25", "7S0"],
			hiddenpower: ["7M"],
			hyperbeam: ["7M"],
			irondefense: ["7T"],
			laserfocus: ["7T"],
			lightscreen: ["7M"],
			magnetrise: ["7T"],
			mudslap: ["7L1"],
			poisonjab: ["7M"],
			protect: ["7M"],
			raindance: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			roost: ["7M"],
			round: ["7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			skydrop: ["7M"],
			sleeptalk: ["7M"],
			snore: ["7T"],
			solarbeam: ["7M"],
			spark: ["7L16", "7S0"],
			stringshot: ["7L1"],
			substitute: ["7M"],
			swagger: ["7M"],
			thunder: ["7M"],
			thunderbolt: ["7M", "7L1"],
			thunderwave: ["7M"],
			toxic: ["7M"],
			visegrip: ["7L1"],
			voltswitch: ["7M"],
			wildcharge: ["7M"],
			xscissor: ["7M"],
			zapcannon: ["7L41"],
		},
		eventData: [
			{generation: 7, level: 35, perfectIVs: 3, moves: ["spark", "acrobatics", "guillotine", "bugbuzz"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	crabrawler: {
		learnset: {
			amnesia: ["9M", "7E"],
			attract: ["7M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "9L22", "7M"],
			brutalswing: ["7M"],
			bubble: ["7L1"],
			bubblebeam: ["9L13", "7L17"],
			bulkup: ["9M", "7M"],
			bulldoze: ["9M", "7M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "9L49", "7L49"],
			confide: ["7M"],
			crabhammer: ["9L37", "7L37"],
			dig: ["9M"],
			dizzypunch: ["7L25"],
			doubleteam: ["7M"],
			drainpunch: ["9M", "7T"],
			dualchop: ["7T"],
			dynamicpunch: ["9L45", "7L45"],
			earthquake: ["9M", "7M"],
			endeavor: ["9E", "7T", "7E"],
			endure: ["9M"],
			facade: ["9M", "7M"],
			fling: ["9M", "7M"],
			focusblast: ["9M", "7M"],
			focuspunch: ["9E", "7T"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gunkshot: ["9M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			icepunch: ["9M", "7T"],
			irondefense: ["9M", "9L42", "7T", "7L42"],
			ironhead: ["9M", "7T"],
			leer: ["9L9", "7L9"],
			liquidation: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			payback: ["9L29", "7M", "7L29"],
			poweruppunch: ["7L22"],
			protect: ["9M", "9L17", "7M"],
			pursuit: ["7L13"],
			raindance: ["9M", "7M"],
			rest: ["9M", "7M"],
			return: ["7M"],
			reversal: ["9M", "9L33", "7L33"],
			rockslide: ["9M", "7M"],
			rocksmash: ["9L5", "7L5"],
			rocktomb: ["9M", "7M"],
			round: ["7M"],
			scald: ["7M"],
			slam: ["9L25"],
			sleeptalk: ["9M", "7M"],
			snore: ["7T"],
			stompingtantrum: ["9M"],
			stoneedge: ["7M"],
			substitute: ["9M", "7M"],
			sunnyday: ["9M", "7M"],
			superpower: ["9E", "7T", "7E"],
			swagger: ["7M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "7M"],
			thunderpunch: ["9M", "7T"],
			toxic: ["7M"],
			visegrip: ["9L1"],
			wideguard: ["9E", "7E"],
			workup: ["7M"],
			zenheadbutt: ["9M", "7T"],
		},
	},
	crabominable: {
		learnset: {
			amnesia: ["9M"],
			attract: ["7M"],
			avalanche: ["9M", "9L29", "7L29"],
			blizzard: ["9M", "7M"],
			block: ["7T"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "9L22", "7M"],
			brutalswing: ["7M"],
			bubble: ["7L1"],
			bubblebeam: ["9L17", "7L17"],
			bulkup: ["9M", "7M"],
			bulldoze: ["9M", "7M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "9L49", "7L49"],
			confide: ["7M"],
			dig: ["9M"],
			dizzypunch: ["7L25"],
			doubleteam: ["7M"],
			drainpunch: ["9M", "7T"],
			dualchop: ["7T"],
			dynamicpunch: ["9L45", "7L45"],
			earthquake: ["9M", "7M"],
			endeavor: ["7T"],
			endure: ["9M"],
			facade: ["9M", "7M"],
			fling: ["9M", "7M"],
			focusblast: ["9M", "7M"],
			focuspunch: ["7T"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gigaimpact: ["9M", "7M"],
			gunkshot: ["9M"],
			hail: ["7M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "7M"],
			icehammer: ["9L37", "7L37"],
			icepunch: ["9M", "9L0", "7T", "7L1"],
			icespinner: ["9M"],
			icywind: ["9M", "7T"],
			irondefense: ["9M", "9L42", "7T", "7L42"],
			ironhead: ["9M", "7T"],
			leer: ["9L1", "7L1"],
			liquidation: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			payback: ["7M"],
			poweruppunch: ["7L22"],
			protect: ["9M", "9L1", "7M"],
			pursuit: ["7L1"],
			raindance: ["9M", "7M"],
			rest: ["9M", "7M"],
			return: ["7M"],
			reversal: ["9M", "9L33", "7L33"],
			rockslide: ["9M", "7M"],
			rocksmash: ["9L1", "7L1"],
			rocktomb: ["9M", "7M"],
			round: ["7M"],
			scald: ["7M"],
			scaryface: ["9M"],
			slam: ["9L25"],
			sleeptalk: ["9M", "7M"],
			snore: ["7T"],
			snowscape: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["7M"],
			substitute: ["9M", "7M"],
			sunnyday: ["9M", "7M"],
			superpower: ["7T"],
			swagger: ["7M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "7M"],
			thunderpunch: ["9M", "7T"],
			toxic: ["7M"],
			workup: ["7M"],
			zenheadbutt: ["9M", "7T"],
		},
	},
	oricorio: {
		learnset: {
			acrobatics: ["9M", "9L23", "7M"],
			aerialace: ["9M", "7M"],
			agility: ["9M", "9L43", "7L46"],
			aircutter: ["9M", "9L13", "7L13"],
			airslash: ["9M", "9L36", "7L36"],
			attract: ["9E", "7M"],
			batonpass: ["9M", "9L16", "7L16"],
			calmmind: ["9M", "7M"],
			captivate: ["7L33", "7E"],
			charm: ["9M"],
			confide: ["7M"],
			covet: ["7T"],
			defog: ["9E", "7T"],
			doubleslap: ["7L23"],
			doubleteam: ["7M"],
			embargo: ["7M"],
			endure: ["9M"],
			facade: ["9M", "7M"],
			featherdance: ["9L20", "7L20"],
			flatter: ["9L33"],
			fly: ["9M", "7M"],
			frustration: ["7M"],
			growl: ["9L4", "7L4"],
			helpinghand: ["9M", "9L10", "7T", "7L10"],
			hiddenpower: ["7M"],
			hurricane: ["9M", "9L47", "7L50"],
			icywind: ["9M", "7T"],
			mirrormove: ["7L43"],
			peck: ["9L6", "7L6"],
			pluck: ["9E", "7E"],
			pound: ["9L1", "7L1"],
			protect: ["9M", "7M"],
			quash: ["7M"],
			quiverdance: ["9E"],
			raindance: ["9M"],
			rest: ["9M", "7M"],
			return: ["7M"],
			revelationdance: ["9L40", "7L40"],
			reversal: ["9M"],
			roleplay: ["7T"],
			roost: ["9L30", "7M", "7L30"],
			round: ["7M"],
			safeguard: ["9E", "7M", "7E"],
			sandstorm: ["9M", "7M"],
			skyattack: ["7T"],
			sleeptalk: ["9M", "7M"],
			snore: ["7T"],
			steelwing: ["7M"],
			substitute: ["9M", "7M"],
			sunnyday: ["9M"],
			swagger: ["7M"],
			swift: ["9M"],
			swordsdance: ["9M", "7M"],
			tailwind: ["9M", "7T", "7E"],
			takedown: ["9M"],
			taunt: ["9M", "7M"],
			teeterdance: ["9L26", "7L26"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uturn: ["9M", "7M"],
			workup: ["7M"],
		},
	},
	cutiefly: {
		learnset: {
			absorb: ["8L1", "7L1"],
			acrobatics: ["8M", "7M"],
			aerialace: ["7M"],
			afteryou: ["7T"],
			allyswitch: ["8M", "7T"],
			aromatherapy: ["8L30", "7L36"],
			aromaticmist: ["8E"],
			attract: ["8M", "7M"],
			batonpass: ["8M", "7E"],
			bestow: ["7E"],
			bugbite: ["7T"],
			bugbuzz: ["8M", "8L48", "7L26"],
			calmmind: ["8M", "7M"],
			charm: ["8M"],
			confide: ["7M"],
			covet: ["7T"],
			dazzlinggleam: ["8M", "8L42", "7M", "7L31"],
			defog: ["7T"],
			doubleteam: ["7M"],
			drainingkiss: ["8M", "8L18", "7L16"],
			dreameater: ["7M"],
			dualwingbeat: ["8T"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			facade: ["8M", "7M"],
			fairywind: ["8L1", "7L4"],
			faketears: ["8M"],
			frustration: ["7M"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			imprison: ["8M"],
			infestation: ["7M"],
			lastresort: ["7T"],
			leechlife: ["8M", "7M"],
			lightscreen: ["8M", "7M"],
			magicroom: ["8M", "7T"],
			moonblast: ["8E", "7E"],
			playrough: ["8M"],
			powder: ["7E"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psychup: ["7M"],
			quiverdance: ["8L54", "7L41"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			signalbeam: ["7T"],
			silverwind: ["7L13"],
			skillswap: ["8M", "7T", "7E"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			speedswap: ["8M", "7E"],
			stickyweb: ["8E", "7E"],
			strugglebug: ["8L24", "7L10"],
			stunspore: ["8L6", "7L7"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			sweetscent: ["8L12", "7L21"],
			swift: ["8M"],
			switcheroo: ["8L36"],
			tailwind: ["7T"],
			telekinesis: ["7T"],
			thief: ["8M", "7M"],
			toxic: ["7M"],
			trick: ["8M", "7T"],
			uturn: ["8M", "7M"],
			wonderroom: ["8M", "7T"],
		},
	},
	ribombee: {
		learnset: {
			absorb: ["8L1", "7L1"],
			acrobatics: ["8M", "7M"],
			aerialace: ["7M"],
			afteryou: ["7T"],
			allyswitch: ["8M", "7T"],
			aromatherapy: ["8L32", "7L42"],
			attract: ["8M", "7M"],
			batonpass: ["8M"],
			bugbite: ["7T"],
			bugbuzz: ["8M", "8L56", "7L28"],
			calmmind: ["8M", "7M"],
			charm: ["8M"],
			confide: ["7M"],
			covet: ["8L1", "7T"],
			dazzlinggleam: ["8M", "8L48", "7M", "7L35"],
			defog: ["7T"],
			doubleteam: ["7M"],
			drainingkiss: ["8M", "8L18", "7L16"],
			dreameater: ["7M"],
			dualwingbeat: ["8T"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			facade: ["8M", "7M"],
			fairywind: ["8L1", "7L1"],
			faketears: ["8M"],
			frustration: ["7M"],
			gigaimpact: ["8M"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M"],
			imprison: ["8M"],
			infestation: ["7M"],
			lastresort: ["7T"],
			leechlife: ["8M", "7M"],
			lightscreen: ["8M", "7M"],
			magicalleaf: ["8M"],
			magicroom: ["8M", "7T"],
			naturepower: ["7M"],
			playrough: ["8M"],
			pollenpuff: ["8M", "8L0", "7L1"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psychup: ["7M"],
			quiverdance: ["8L64", "7L49"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			signalbeam: ["7T"],
			silverwind: ["7L13"],
			skillswap: ["8M", "7T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			speedswap: ["8M"],
			strugglebug: ["8L24", "7L1"],
			stunspore: ["8L1", "7L1"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			sweetscent: ["8L1", "7L21"],
			swift: ["8M"],
			switcheroo: ["8L40"],
			tailwind: ["7T"],
			telekinesis: ["7T"],
			thief: ["8M", "7M"],
			toxic: ["7M"],
			trick: ["8M", "7T"],
			uturn: ["8M", "7M"],
			wonderroom: ["8M", "7T"],
		},
	},
	ribombeetotem: {
		learnset: {
			absorb: ["7L1"],
			acrobatics: ["7M"],
			aerialace: ["7M"],
			afteryou: ["7T"],
			allyswitch: ["7T"],
			aromatherapy: ["7L42", "7S0"],
			attract: ["7M"],
			bugbite: ["7T"],
			bugbuzz: ["7L28", "7S0"],
			calmmind: ["7M"],
			confide: ["7M"],
			covet: ["7T"],
			dazzlinggleam: ["7M", "7L35", "7S0"],
			defog: ["7T"],
			doubleteam: ["7M"],
			drainingkiss: ["7L16"],
			dreameater: ["7M"],
			energyball: ["7M"],
			facade: ["7M"],
			fairywind: ["7L1"],
			frustration: ["7M"],
			helpinghand: ["7T"],
			hiddenpower: ["7M"],
			infestation: ["7M"],
			lastresort: ["7T"],
			leechlife: ["7M"],
			lightscreen: ["7M"],
			magicroom: ["7T"],
			naturepower: ["7M"],
			pollenpuff: ["7L1"],
			protect: ["7M"],
			psychic: ["7M"],
			psychup: ["7M"],
			quiverdance: ["7L49", "7S0"],
			reflect: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			roost: ["7M"],
			round: ["7M"],
			safeguard: ["7M"],
			signalbeam: ["7T"],
			silverwind: ["7L13"],
			skillswap: ["7T"],
			sleeptalk: ["7M"],
			snore: ["7T"],
			solarbeam: ["7M"],
			strugglebug: ["7L1"],
			stunspore: ["7L1"],
			substitute: ["7M"],
			sunnyday: ["7M"],
			swagger: ["7M"],
			sweetscent: ["7L21"],
			tailwind: ["7T"],
			telekinesis: ["7T"],
			thief: ["7M"],
			toxic: ["7M"],
			trick: ["7T"],
			uturn: ["7M"],
			wonderroom: ["7T"],
		},
		eventData: [
			{generation: 7, level: 50, perfectIVs: 3, moves: ["bugbuzz", "dazzlinggleam", "aromatherapy", "quiverdance"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	rockruff: {
		learnset: {
			attract: ["8M", "7M"],
			bite: ["9L20", "8L20", "7L7"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			charm: ["9M"],
			confide: ["7M"],
			covet: ["7T"],
			crunch: ["9M", "9L36", "8M", "8L36", "7L40"],
			crushclaw: ["7E"],
			dig: ["9M"],
			doubleteam: ["9L8", "8L8", "7M"],
			earthpower: ["9M", "8M", "7T"],
			echoedvoice: ["7M"],
			endeavor: ["9E", "8E", "7T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			firefang: ["9M", "8M", "7E"],
			frustration: ["7M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			howl: ["9L16", "8L16", "7L12"],
			hypervoice: ["9M", "8M", "7T"],
			irondefense: ["9M", "8M", "7T"],
			ironhead: ["9M", "8M", "7T"],
			irontail: ["8M", "7T"],
			lastresort: ["9E", "8E", "7T"],
			leer: ["9L1", "8L1", "7L1"],
			mudslap: ["9M"],
			odorsleuth: ["7L18"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M"],
			psychicfangs: ["9M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			roar: ["9L28", "8L28", "7M", "7L26"],
			rockclimb: ["7L45"],
			rockpolish: ["7M"],
			rockslide: ["9M", "9L32", "8M", "8L32", "7M", "7L34"],
			rockthrow: ["9L12", "8L12", "7L15"],
			rocktomb: ["9M", "9L24", "8M", "8L24", "7M", "7L23"],
			round: ["8M", "7M"],
			sandattack: ["9L4", "8L4", "7L4"],
			sandstorm: ["9M"],
			scaryface: ["9M", "9L40", "8M", "8L40", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snarl: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "9L44", "8M", "8L44", "7T", "7L29"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "9L48", "8M", "8L48", "7M", "7L48"],
			substitute: ["9M", "8M", "7M"],
			suckerpunch: ["7E"],
			swagger: ["7M"],
			swordsdance: ["9M", "8M"],
			tackle: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M"],
			terablast: ["9M"],
			thrash: ["9E", "8E", "7E"],
			thunderfang: ["9M", "8M", "7E"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
	},
	rockruffdusk: {
		learnset: {
			attract: ["8M", "7M"],
			bite: ["9L20", "8L20", "7L7", "7S1", "7S0"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			charm: ["9M"],
			confide: ["7M"],
			covet: ["7T"],
			crunch: ["9M", "9L36", "8M", "8L36", "7L40"],
			crushclaw: ["7E"],
			dig: ["9M"],
			doubleteam: ["9L8", "8L8", "7M"],
			earthpower: ["9M", "8M", "7T"],
			echoedvoice: ["7M"],
			endeavor: ["9E", "8E", "7T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			firefang: ["9M", "8M", "7E", "7S0"],
			frustration: ["7M"],
			happyhour: ["7S1", "7S0"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			howl: ["9L16", "8L16", "7L12"],
			hypervoice: ["9M", "8M", "7T"],
			irondefense: ["9M", "8M", "7T"],
			ironhead: ["9M", "8M", "7T"],
			irontail: ["8M", "7T"],
			lastresort: ["9E", "8E", "7T"],
			leer: ["9L1", "8L1", "7L1"],
			mudslap: ["9M"],
			odorsleuth: ["7L18"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M"],
			psychicfangs: ["9M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			roar: ["9L28", "8L28", "7M", "7L26"],
			rockclimb: ["7L45"],
			rockpolish: ["7M"],
			rockslide: ["9M", "9L32", "8M", "8L32", "7M", "7L34"],
			rockthrow: ["9L12", "8L12", "7L15"],
			rocktomb: ["9M", "9L24", "8M", "8L24", "7M", "7L23"],
			round: ["8M", "7M"],
			sandattack: ["9L4", "8L4", "7L4"],
			sandstorm: ["9M"],
			scaryface: ["9M", "9L40", "8M", "8L40", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snarl: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "9L44", "8M", "8L44", "7T", "7L29"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "9L48", "8M", "8L48", "7M", "7L48"],
			substitute: ["9M", "8M", "7M"],
			suckerpunch: ["7E"],
			swagger: ["7M"],
			swordsdance: ["9M", "8M"],
			tackle: ["9L1", "8L1", "7L1", "7S1", "7S0"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M"],
			terablast: ["9M"],
			thrash: ["9E", "8E", "7E"],
			thunderfang: ["9M", "8M", "7E", "7S1"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 10, moves: ["tackle", "bite", "firefang", "happyhour"], pokeball: "cherishball"},
			{generation: 7, level: 10, moves: ["tackle", "bite", "thunderfang", "happyhour"], pokeball: "cherishball"},
		],
	},
	lycanroc: {
		learnset: {
			accelerock: ["9L1", "8L1", "7L1"],
			agility: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M", "7M"],
			bite: ["9L20", "8L20", "7L1"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M"],
			bulkup: ["9M", "8M", "7M"],
			bulldoze: ["9M"],
			charm: ["9M"],
			closecombat: ["9M", "8M"],
			confide: ["7M"],
			covet: ["7T"],
			crunch: ["9M", "9L42", "8M", "8L42", "7L40"],
			dig: ["9M"],
			doubleteam: ["9L1", "8L1", "7M"],
			drillrun: ["9M", "8M", "7T"],
			earthpower: ["9M", "8M", "7T"],
			echoedvoice: ["7M"],
			endeavor: ["7T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			firefang: ["9M", "8M"],
			frustration: ["7M"],
			gigaimpact: ["9M", "8M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			howl: ["9L16", "8L16", "7L12"],
			hypervoice: ["9M", "8M", "7T"],
			irondefense: ["9M", "8M", "7T"],
			ironhead: ["9M", "8M", "7T"],
			irontail: ["8M", "7T"],
			lastresort: ["7T"],
			leer: ["9L1", "8L1", "7L1"],
			mudslap: ["9M"],
			odorsleuth: ["7L18"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M"],
			psychicfangs: ["9M", "8M"],
			quickattack: ["9L1", "8L1", "7L1"],
			quickguard: ["9L1", "8L1", "7L1"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			roar: ["9L30", "8L30", "7M", "7L26"],
			rockblast: ["9M", "8M"],
			rockclimb: ["7L45"],
			rockpolish: ["7M"],
			rockslide: ["9M", "9L36", "8M", "8L36", "7M", "7L34"],
			rockthrow: ["9L12", "8L12", "7L15"],
			rocktomb: ["9M", "9L24", "8M", "8L24", "7M", "7L23"],
			round: ["8M", "7M"],
			sandattack: ["9L1", "8L1", "7L1"],
			sandstorm: ["9M", "8M"],
			scaryface: ["9M", "9L48", "8M", "8L48", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snarl: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "9L54", "8M", "8L54", "7T", "7L29"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "9L60", "8M", "8L60", "7M", "7L48"],
			substitute: ["9M", "8M", "7M"],
			suckerpunch: ["9L0", "8L0"],
			sunnyday: ["9M"],
			swagger: ["7M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M", "7M"],
			tackle: ["9L1", "8L1", "7L1"],
			tailslap: ["8M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M"],
			terablast: ["9M"],
			thunderfang: ["9M", "8M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
	},
	lycanrocmidnight: {
		learnset: {
			attract: ["8M", "7M"],
			bite: ["9L20", "8L20", "7L1"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M"],
			bulkup: ["9M", "8M", "7M"],
			bulldoze: ["9M"],
			charm: ["9M"],
			closecombat: ["9M", "8M"],
			confide: ["7M"],
			counter: ["9L0", "8L0", "7L1"],
			covet: ["7T"],
			crunch: ["9M", "9L42", "8M", "8L42", "7L40"],
			dig: ["9M"],
			doubleteam: ["9L1", "8L1", "7M"],
			dualchop: ["7T"],
			earthpower: ["9M", "8M", "7T"],
			echoedvoice: ["7M"],
			endeavor: ["7T"],
			endure: ["9M", "9L1", "8M", "8L1"],
			facade: ["9M", "8M", "7M"],
			firefang: ["9M", "8M", "7S0"],
			firepunch: ["9M", "8M", "7T"],
			fling: ["9M"],
			focuspunch: ["7T"],
			foulplay: ["9M", "8M", "7T"],
			frustration: ["7M"],
			gigaimpact: ["9M", "8M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			howl: ["9L16", "8L16", "7L12"],
			hypervoice: ["9M", "8M", "7T"],
			irondefense: ["9M", "8M", "7T"],
			ironhead: ["9M", "8M", "7T"],
			irontail: ["8M", "7T"],
			laserfocus: ["7T"],
			lashout: ["8T"],
			lastresort: ["7T"],
			leer: ["9L1", "8L1", "7L1"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudslap: ["9M"],
			odorsleuth: ["7L18"],
			outrage: ["9M", "8M", "7T"],
			payback: ["8M"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M"],
			psychicfangs: ["9M", "8M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			reversal: ["9M", "9L1", "8M", "8L1", "7L1"],
			roar: ["9L30", "8L30", "7M", "7L26"],
			rockblast: ["9M", "8M"],
			rockclimb: ["7L45"],
			rockpolish: ["7M"],
			rockslide: ["9M", "9L36", "8M", "8L36", "7M", "7L34"],
			rockthrow: ["9L12", "8L12", "7L15"],
			rocktomb: ["9M", "9L24", "8M", "8L24", "7M", "7L23"],
			round: ["8M", "7M"],
			sandattack: ["9L1", "8L1", "7L1"],
			sandstorm: ["9M", "8M"],
			scaryface: ["9M", "9L48", "8M", "8L48", "7L37"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M", "8M", "7M"],
			snarl: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "9L54", "8M", "8L54", "7T", "7L29"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "9L60", "8M", "8L60", "7M", "7L48", "7S0"],
			substitute: ["9M", "8M", "7M"],
			suckerpunch: ["7S0"],
			sunnyday: ["9M"],
			swagger: ["7M"],
			swordsdance: ["9M", "8M", "7M", "7S0"],
			tackle: ["9L1", "8L1", "7L1"],
			takedown: ["9M"],
			taunt: ["9M", "9L1", "8M", "8L1", "7M", "7L1"],
			terablast: ["9M"],
			throatchop: ["8M", "7T"],
			thunderfang: ["9M", "8M"],
			thunderpunch: ["9M", "8M", "7T"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 50, isHidden: true, moves: ["stoneedge", "firefang", "suckerpunch", "swordsdance"], pokeball: "cherishball"},
		],
	},
	lycanrocdusk: {
		learnset: {
			accelerock: ["9L1", "8L1", "7L1"],
			attract: ["8M", "7M"],
			bite: ["9L20", "8L20", "7L1"],
			brickbreak: ["9M", "8M", "7M"],
			bulkup: ["9M", "8M", "7M"],
			bulldoze: ["9M"],
			charm: ["9M"],
			closecombat: ["9M", "8M"],
			confide: ["7M"],
			counter: ["9L1", "8L1", "7L1"],
			covet: ["7T"],
			crunch: ["9M", "9L42", "8M", "8L42", "7L40"],
			crushclaw: ["9L0", "8L0"],
			dig: ["9M"],
			doubleteam: ["9L1", "8L1", "7M"],
			drillrun: ["9M", "8M", "7T"],
			earthpower: ["9M", "8M", "7T"],
			echoedvoice: ["7M"],
			endeavor: ["7T"],
			endure: ["9M", "9L1", "8M", "8L1"],
			facade: ["9M", "8M", "7M"],
			firefang: ["9M", "8M"],
			focusenergy: ["8M"],
			frustration: ["7M"],
			gigaimpact: ["9M", "8M"],
			helpinghand: ["9M"],
			hiddenpower: ["7M"],
			howl: ["9L16", "8L16", "7L12"],
			hypervoice: ["9M", "8M", "7T"],
			irondefense: ["9M", "8M", "7T"],
			ironhead: ["9M", "8M", "7T"],
			irontail: ["8M", "7T"],
			lastresort: ["7T"],
			leer: ["9L1", "8L1", "7L1"],
			odorsleuth: ["7L18"],
			outrage: ["9M", "8M", "7T"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M"],
			psychicfangs: ["9M", "8M"],
			quickattack: ["9L1", "8L1"],
			quickguard: ["9L1", "8L1"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			reversal: ["9M", "9L1", "8M", "8L1"],
			roar: ["9L30", "8L30", "7M", "7L26"],
			rockblast: ["9M", "8M"],
			rockclimb: ["7L45"],
			rockpolish: ["7M"],
			rockslide: ["9M", "9L36", "8M", "8L36", "7M", "7L34"],
			rockthrow: ["9L12", "8L12", "7L15"],
			rocktomb: ["9M", "9L24", "8M", "8L24", "7M", "7L23"],
			round: ["8M", "7M"],
			sandattack: ["9L1", "8L1", "7L1"],
			sandstorm: ["9M", "8M"],
			scaryface: ["9M", "9L48", "8M", "8L48", "7L37"],
			sleeptalk: ["9M", "8M", "7M"],
			snarl: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "9L54", "8M", "8L54", "7T", "7L29"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M", "9L60", "8M", "8L60", "7M", "7L48"],
			substitute: ["9M", "8M", "7M"],
			suckerpunch: ["9L1", "8L1"],
			swagger: ["7M"],
			swordsdance: ["9M", "8M", "7M"],
			tackle: ["9L1", "8L1", "7L1"],
			tailslap: ["8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L1", "8M", "8L1", "7M"],
			terablast: ["9M"],
			thrash: ["7L1"],
			thunderfang: ["9M", "8M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			workup: ["8M"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
	},
	wishiwashi: {
		learnset: {
			aquaring: ["8L36", "7L17"],
			aquatail: ["8L32", "7T", "7L38"],
			attract: ["8M", "7M"],
			beatup: ["8M", "8L8", "7L33"],
			brine: ["8M", "8L12", "7L14"],
			bulldoze: ["8M", "7M"],
			confide: ["7M"],
			covet: ["7T"],
			dive: ["8M", "8L20", "7L30"],
			doubleedge: ["8L48", "7L41"],
			doubleteam: ["7M"],
			earthquake: ["8M", "7M"],
			endeavor: ["8L40", "7T", "7L49"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			feintattack: ["7L9"],
			flipturn: ["8T"],
			frustration: ["7M"],
			growl: ["8L1", "7L1"],
			hail: ["8M", "7M"],
			helpinghand: ["8M", "8L4", "7T", "7L6"],
			hiddenpower: ["7M"],
			hydropump: ["8M", "8L44", "7L54"],
			icebeam: ["8M", "7M"],
			irontail: ["8M", "7T"],
			liquidation: ["8M"],
			mist: ["8E", "7E"],
			muddywater: ["8M", "7E"],
			mudshot: ["8M"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			scald: ["8M", "7M"],
			scaleshot: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			soak: ["8L24", "7L46"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			takedown: ["8E", "7L25"],
			tearfullook: ["8L16", "7L22"],
			toxic: ["7M"],
			uproar: ["8M", "8L28"],
			uturn: ["8M", "7M"],
			waterfall: ["8M", "7M"],
			watergun: ["8L1", "7L1"],
			waterpulse: ["8E", "7T", "7E"],
			watersport: ["7E"],
			whirlpool: ["8M", "7E"],
		},
	},
	mareanie: {
		learnset: {
			acidspray: ["9M", "9L40"],
			afteryou: ["7T"],
			attract: ["8M", "7M"],
			bite: ["9L10", "8L10", "7L9"],
			blizzard: ["9M", "8M", "7M"],
			brine: ["8M"],
			chillingwater: ["9M"],
			confide: ["7M"],
			covet: ["7T"],
			doubleteam: ["7M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gastroacid: ["7T"],
			gunkshot: ["9M", "8M", "7T"],
			hail: ["8M", "7M"],
			haze: ["9E", "8E", "7E"],
			hiddenpower: ["7M"],
			hydropump: ["9M", "8M"],
			icebeam: ["9M", "8M", "7M"],
			icespinner: ["9M"],
			icywind: ["9M", "8M", "7T"],
			infestation: ["9E", "8E", "7M"],
			irondefense: ["9M", "8M", "7T"],
			knockoff: ["7T"],
			liquidation: ["9M", "9L35", "8M", "8L35", "7T", "7L49"],
			magiccoat: ["7T"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			painsplit: ["7T"],
			payback: ["8M", "7M"],
			peck: ["9L1", "8L1", "7L5"],
			pinmissile: ["9L25", "8M", "8L25", "7L45"],
			poisonjab: ["9M", "9L45", "8M", "8L45", "7M", "7L37"],
			poisonsting: ["9L1", "8L1", "7L1"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M"],
			raindance: ["9M", "8M", "7M"],
			recover: ["9L20", "8L20", "7L33"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scald: ["8M", "7M"],
			sleeptalk: ["9M", "8M", "7M"],
			sludgebomb: ["9M", "8M", "7M"],
			sludgewave: ["8M", "7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			spikecannon: ["7L29"],
			spite: ["7T"],
			spitup: ["9E", "8E", "7E"],
			stockpile: ["9E", "8E", "7E", "7S0"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swallow: ["9E", "8E", "7E", "7S0"],
			terablast: ["9M"],
			toxic: ["9L50", "8L50", "7M", "7L21", "7S0"],
			toxicspikes: ["9M", "9L30", "8M", "8L30", "7L13"],
			venomdrench: ["8M", "8L40", "7L41"],
			venoshock: ["9M", "9L15", "8M", "8L15", "7M", "7L25"],
			waterpulse: ["9M", "7T"],
			wideguard: ["9L5", "8L5", "7L17"],
		},
		eventData: [
			{generation: 7, level: 1, shiny: 1, isHidden: true, moves: ["toxic", "stockpile", "swallow"], pokeball: "cherishball"},
		],
	},
	toxapex: {
		learnset: {
			acidspray: ["9M", "9L42"],
			afteryou: ["7T"],
			attract: ["8M", "7M"],
			banefulbunker: ["9L0", "8L0", "7L1"],
			bite: ["9L1", "8L1", "7L1"],
			blizzard: ["9M", "8M", "7M"],
			block: ["7T"],
			bodyslam: ["9M"],
			brine: ["8M"],
			chillingwater: ["9M"],
			confide: ["7M"],
			covet: ["7T"],
			crosspoison: ["8M"],
			doubleteam: ["7M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gastroacid: ["7T"],
			gigaimpact: ["9M"],
			gunkshot: ["9M", "8M", "7T"],
			hail: ["8M", "7M"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "8M", "7M"],
			icespinner: ["9M"],
			icywind: ["9M", "8M", "7T"],
			infestation: ["7M"],
			irondefense: ["9M", "8M", "7T"],
			knockoff: ["7T"],
			lightscreen: ["8M", "7M"],
			liquidation: ["9M", "9L35", "8M", "8L35", "7T", "7L58"],
			magiccoat: ["7T"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			painsplit: ["7T"],
			payback: ["8M", "7M"],
			peck: ["9L1", "8L1", "7L1"],
			pinmissile: ["9L25", "8M", "8L25", "7L51"],
			poisonjab: ["9M", "9L49", "8M", "8L49", "7M", "7L37"],
			poisonsting: ["9L1", "8L1", "7L1"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M"],
			raindance: ["9M", "8M", "7M"],
			recover: ["9L20", "8L20", "7L33"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scald: ["8M", "7M"],
			scaryface: ["9M"],
			sleeptalk: ["9M", "8M", "7M"],
			sludgebomb: ["9M", "8M", "7M"],
			sludgewave: ["8M", "7M"],
			smackdown: ["7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			spikecannon: ["7L29"],
			spite: ["7T"],
			substitute: ["9M", "8M", "7M"],
			surf: ["9M", "8M", "7M"],
			swagger: ["7M"],
			terablast: ["9M"],
			toxic: ["9L56", "8L56", "7M", "7L21"],
			toxicspikes: ["9M", "9L30", "8M", "8L30", "7L1"],
			venomdrench: ["8M", "8L42", "7L44"],
			venoshock: ["9M", "9L15", "8M", "8L15", "7M", "7L25"],
			waterpulse: ["9M", "7T"],
			wideguard: ["9L1", "8L1", "7L17"],
		},
	},
	mudbray: {
		learnset: {
			attract: ["8M", "7M"],
			bide: ["7L22"],
			bodyslam: ["9M", "8M", "7E"],
			bulldoze: ["9M", "9L12", "8M", "8L12", "7M", "7L10"],
			closecombat: ["9M", "8M", "7E"],
			confide: ["7M"],
			counter: ["9L24", "8L24", "7L36"],
			doubleedge: ["9E", "8E", "7E"],
			doublekick: ["9L8", "8L8", "7L15"],
			doubleteam: ["7M"],
			earthpower: ["9M", "8M", "7T"],
			earthquake: ["9M", "9L36", "8M", "8L36", "7M", "7L38"],
			endeavor: ["9E", "7T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fissure: ["9E", "8E"],
			frustration: ["7M"],
			heavyslam: ["9M", "9L32", "8M", "8L32", "7L31"],
			hiddenpower: ["7M"],
			highhorsepower: ["9L28", "8M", "8L28", "7L24"],
			irondefense: ["9M", "9L4", "8M", "8L4", "7T", "7L29"],
			ironhead: ["9M", "8M", "7T"],
			lowkick: ["9M", "8M", "7T"],
			lowsweep: ["9M", "8M", "7M"],
			magnitude: ["7E"],
			megakick: ["9L40", "8M", "8L40", "7L43"],
			mudbomb: ["7E"],
			mudslap: ["9M", "9L1", "8L1", "7L1"],
			mudsport: ["7L3"],
			payback: ["8M", "7M"],
			protect: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			roar: ["9E", "8E", "7M"],
			rockslide: ["9M", "8M", "7M"],
			rocksmash: ["9L1", "8L1"],
			rocktomb: ["9M", "8M", "7M"],
			rototiller: ["7L8"],
			round: ["8M", "7M"],
			sandstorm: ["9M", "8M", "7M"],
			sandtomb: ["8M"],
			sleeptalk: ["9M", "8M", "7M"],
			smackdown: ["9E", "8E"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "8M", "7T"],
			stomp: ["9L16", "8L16", "7L17"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M"],
			strength: ["9L20", "8L20"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M"],
			superpower: ["9L44", "8M", "8L44", "7T", "7L45"],
			swagger: ["7M"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M"],
		},
	},
	mudsdale: {
		learnset: {
			attract: ["8M", "7M"],
			bide: ["7L22"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "9L12", "8M", "8L12", "7M", "7L1"],
			closecombat: ["9M", "8M"],
			confide: ["7M"],
			counter: ["9L24", "8L24", "7L42"],
			doublekick: ["9L1", "8L1", "7L15"],
			doubleteam: ["7M"],
			earthpower: ["9M", "8M", "7T"],
			earthquake: ["9M", "9L40", "8M", "8L40", "7M", "7L47"],
			endeavor: ["7T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			focusblast: ["9M", "8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["9M", "8M", "7M"],
			heavyslam: ["9M", "9L34", "8M", "8L34", "7L34"],
			hiddenpower: ["7M"],
			highhorsepower: ["9L28", "8M", "8L28", "7L24"],
			hyperbeam: ["9M", "8M"],
			irondefense: ["9M", "9L1", "8M", "8L1", "7T", "7L29"],
			ironhead: ["9M", "8M", "7T"],
			lashout: ["8T"],
			lowkick: ["9M", "8M", "7T"],
			lowsweep: ["9M", "8M", "7M"],
			megakick: ["9L46", "8M", "8L46", "7L55"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "9L1", "8L1", "7L1"],
			mudsport: ["7L1"],
			payback: ["8M", "7M"],
			protect: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			roar: ["7M"],
			rockslide: ["9M", "8M", "7M"],
			rocksmash: ["9L1", "8L1"],
			rocktomb: ["9M", "8M", "7M"],
			rototiller: ["7L1"],
			round: ["8M", "7M"],
			sandstorm: ["9M", "8M", "7M"],
			sandtomb: ["8M"],
			scaryface: ["9M", "8M"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["9M", "8M", "7T"],
			stomp: ["9L16", "8L16", "7L17"],
			stompingtantrum: ["9M", "8M", "7T"],
			stoneedge: ["9M"],
			strength: ["9L20", "8L20"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M"],
			superpower: ["9L52", "8M", "8L52", "7T", "7L60"],
			swagger: ["7M"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M"],
		},
		encounters: [
			{generation: 7, level: 29},
		],
	},
	dewpider: {
		learnset: {
			aquaring: ["8L16", "7L24"],
			attract: ["8M", "7M"],
			aurorabeam: ["7E"],
			bite: ["8L8", "7L21"],
			blizzard: ["8M", "7M"],
			bubble: ["7L1"],
			bubblebeam: ["8L12", "7L16"],
			bugbite: ["8L4", "7T", "7L13"],
			bugbuzz: ["8M"],
			confide: ["7M"],
			crunch: ["8M", "8L24", "7L32"],
			doubleteam: ["7M"],
			endure: ["8M"],
			entrainment: ["8L32", "7L48"],
			facade: ["8M", "7M"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gigadrain: ["8M", "7T"],
			headbutt: ["8L20"],
			hiddenpower: ["7M"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "7T"],
			infestation: ["8L1", "7M", "7L5"],
			irondefense: ["8M", "7T"],
			leechlife: ["8M", "8L44", "7M", "7L29"],
			liquidation: ["8M", "8L40", "7T", "7L45"],
			lunge: ["8L36", "7L37"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			mirrorcoat: ["8L48", "7L40"],
			poisonjab: ["8M", "7M"],
			powersplit: ["8E", "7E"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			scald: ["8M", "7M"],
			signalbeam: ["7T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			soak: ["8L28"],
			spiderweb: ["7L8"],
			spitup: ["8E", "7E"],
			stickyweb: ["8E", "7E"],
			stockpile: ["8E", "7E"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			toxic: ["7M"],
			waterfall: ["8M", "7M"],
			watergun: ["8L1"],
			waterpulse: ["7T"],
			watersport: ["7L1"],
			wonderroom: ["8M", "7T"],
			xscissor: ["8M", "7M"],
		},
	},
	araquanid: {
		learnset: {
			aquaring: ["8L16", "7L26"],
			attract: ["8M", "7M"],
			bite: ["8L1", "7L21"],
			blizzard: ["8M", "7M"],
			bubble: ["7L1"],
			bubblebeam: ["8L12", "7L16"],
			bugbite: ["8L1", "7T", "7L1"],
			bugbuzz: ["8M"],
			confide: ["7M"],
			crunch: ["8M", "8L26", "7L38"],
			dive: ["8M"],
			doubleteam: ["7M"],
			endure: ["8M"],
			entrainment: ["8L38", "7L62"],
			facade: ["8M", "7M"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gigadrain: ["8M", "7T"],
			headbutt: ["8L20"],
			hiddenpower: ["7M"],
			hydropump: ["8M"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "7T"],
			infestation: ["8L1", "7M", "7L1"],
			irondefense: ["8M", "7T"],
			laserfocus: ["7T"],
			leechlife: ["8M", "8L56", "7M", "7L33"],
			liquidation: ["8M", "8L50", "7T", "7L57"],
			lunge: ["8L44", "7L45"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			mirrorcoat: ["8L62", "7L50"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scald: ["8M", "7M"],
			signalbeam: ["7T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			soak: ["8L32", "7L1"],
			spiderweb: ["7L1"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			toxic: ["7M"],
			waterfall: ["8M", "7M"],
			watergun: ["8L1"],
			waterpulse: ["7T"],
			wideguard: ["8L1", "7L1"],
			wonderroom: ["8M", "7T"],
			xscissor: ["8M", "7M"],
		},
	},
	araquanidtotem: {
		learnset: {
			aquaring: ["7L26"],
			attract: ["7M"],
			bite: ["7L21", "7S0"],
			blizzard: ["7M"],
			bubble: ["7L1"],
			bubblebeam: ["7L16", "7S0"],
			bugbite: ["7T", "7L1", "7S0"],
			confide: ["7M"],
			crunch: ["7L38"],
			doubleteam: ["7M"],
			entrainment: ["7L62"],
			facade: ["7M"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gigadrain: ["7T"],
			hiddenpower: ["7M"],
			icebeam: ["7M"],
			icywind: ["7T"],
			infestation: ["7M", "7L1"],
			irondefense: ["7T"],
			laserfocus: ["7T"],
			leechlife: ["7M", "7L33"],
			liquidation: ["7T", "7L57"],
			lunge: ["7L45"],
			magiccoat: ["7T"],
			magicroom: ["7T"],
			mirrorcoat: ["7L50"],
			poisonjab: ["7M"],
			protect: ["7M"],
			raindance: ["7M"],
			reflect: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			round: ["7M"],
			safeguard: ["7M"],
			scald: ["7M"],
			signalbeam: ["7T"],
			sleeptalk: ["7M"],
			snore: ["7T"],
			soak: ["7L1"],
			spiderweb: ["7L1", "7S0"],
			substitute: ["7M"],
			surf: ["7M"],
			swagger: ["7M"],
			toxic: ["7M"],
			waterfall: ["7M"],
			waterpulse: ["7T"],
			wideguard: ["7L1"],
			wonderroom: ["7T"],
			xscissor: ["7M"],
		},
		eventData: [
			{generation: 7, level: 25, perfectIVs: 3, moves: ["spiderweb", "bugbite", "bubblebeam", "bite"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	fomantis: {
		learnset: {
			aromatherapy: ["8E", "7E"],
			attract: ["8M", "7M"],
			bugbite: ["7T"],
			bulletseed: ["9M", "8M"],
			confide: ["7M"],
			defog: ["9E", "8E", "7T", "7E"],
			doubleteam: ["7M"],
			dualchop: ["7T"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			falseswipe: ["9M", "8M", "7M"],
			fling: ["9M", "8M", "7M"],
			frustration: ["7M"],
			furycutter: ["9L1", "8L1", "7L1"],
			gigadrain: ["9M", "8M", "7T", "7E"],
			grassknot: ["9M", "8M", "7M"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			growth: ["9L5", "8L5", "7L14"],
			hiddenpower: ["7M"],
			ingrain: ["9L10", "8L10", "7L19"],
			leafage: ["9L1", "8L1", "7L5"],
			leafblade: ["9L40", "8M", "8L40", "7L23"],
			leafstorm: ["9M", "8M", "7E"],
			leechlife: ["9M", "8M", "7M"],
			magicalleaf: ["9M", "8M"],
			naturepower: ["7M"],
			payback: ["8M", "7M"],
			poisonjab: ["8M", "7M"],
			protect: ["9M", "8M", "7M"],
			razorleaf: ["9L15", "8L15", "7L10"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["9M", "8M", "7T"],
			signalbeam: ["7T"],
			slash: ["9L25", "8L25", "7L32"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["9M", "9L50", "8M", "8L50", "7M", "7L41"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M", "9L45", "8M", "8L45", "7M", "7L46"],
			swagger: ["7M"],
			sweetscent: ["9L20", "8L20", "7L37"],
			swordsdance: ["9M", "8M", "7M"],
			synthesis: ["9L35", "8L35", "7T", "7L28"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			weatherball: ["8M", "7E"],
			worryseed: ["9E", "8E", "7T"],
			xscissor: ["9M", "9L30", "8M", "8L30", "7M"],
		},
	},
	lurantis: {
		learnset: {
			aerialace: ["7M"],
			attract: ["8M", "7M"],
			brickbreak: ["9M", "8M", "7M"],
			bugbite: ["7T"],
			bulletseed: ["9M", "8M"],
			confide: ["7M"],
			crosspoison: ["8M"],
			defog: ["7T"],
			doubleteam: ["7M"],
			dualchop: ["8L1", "7T"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			falseswipe: ["9M", "8M", "7M"],
			fling: ["9M", "8M", "7M"],
			frustration: ["7M"],
			furycutter: ["9L1", "8L1", "7L1"],
			gigadrain: ["9M", "8M", "7T"],
			gigaimpact: ["9M", "8M", "7M"],
			grassknot: ["9M", "8M", "7M"],
			grassyglide: ["8T"],
			grassyterrain: ["9M", "8M"],
			growth: ["9L1", "8L1", "7L1"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M", "8M", "7M"],
			ingrain: ["9L1", "8L1", "7L19"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			leafage: ["9L1", "8L1", "7L1"],
			leafblade: ["9L44", "8M", "8L44", "7L23"],
			leafstorm: ["9M", "8M"],
			leechlife: ["9M", "8M", "7M"],
			lowsweep: ["9M", "8M", "7M"],
			magicalleaf: ["9M", "8M"],
			naturepower: ["7M"],
			nightslash: ["9L1", "8L1", "7L1"],
			payback: ["8M", "7M"],
			petalblizzard: ["9L0", "8L0", "7L1"],
			poisonjab: ["9M", "8M", "7M"],
			pollenpuff: ["9M"],
			protect: ["9M", "8M", "7M"],
			psychocut: ["8M"],
			raindance: ["9M"],
			razorleaf: ["9L15", "8L15", "7L1"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scaryface: ["9M"],
			seedbomb: ["9M", "8M", "7T"],
			signalbeam: ["7T"],
			slash: ["9L25", "8L25", "7L32"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["9M", "9L1", "8M", "8L1", "7M"],
			solarblade: ["9L63", "8M", "8L63", "7L47"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M", "9L51", "8M", "8L51", "7M", "7L55"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			sweetscent: ["9L20", "8L20", "7L40"],
			swordsdance: ["9M", "8M", "7M"],
			synthesis: ["9L37", "8L37", "7T", "7L28"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			weatherball: ["8M"],
			worryseed: ["7T"],
			xscissor: ["9M", "9L30", "8M", "8L30", "7M", "7L1"],
		},
	},
	lurantistotem: {
		learnset: {
			aerialace: ["7M"],
			attract: ["7M"],
			brickbreak: ["7M"],
			bugbite: ["7T"],
			confide: ["7M"],
			defog: ["7T"],
			doubleteam: ["7M"],
			dualchop: ["7T"],
			energyball: ["7M"],
			facade: ["7M"],
			falseswipe: ["7M"],
			fling: ["7M"],
			frustration: ["7M"],
			furycutter: ["7L1"],
			gigadrain: ["7T"],
			gigaimpact: ["7M"],
			grassknot: ["7M"],
			growth: ["7L1", "7S0"],
			hiddenpower: ["7M"],
			hyperbeam: ["7M"],
			ingrain: ["7L19", "7S0"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			leafage: ["7L1"],
			leafblade: ["7L23", "7S0"],
			leechlife: ["7M"],
			lowsweep: ["7M"],
			naturepower: ["7M"],
			nightslash: ["7L1"],
			payback: ["7M"],
			petalblizzard: ["7L1"],
			poisonjab: ["7M"],
			protect: ["7M"],
			razorleaf: ["7L1"],
			rest: ["7M"],
			return: ["7M"],
			round: ["7M"],
			safeguard: ["7M"],
			seedbomb: ["7T"],
			signalbeam: ["7T"],
			slash: ["7L32"],
			sleeptalk: ["7M"],
			snore: ["7T"],
			solarbeam: ["7M"],
			solarblade: ["7L47"],
			substitute: ["7M"],
			sunnyday: ["7M", "7L55"],
			superpower: ["7T"],
			swagger: ["7M"],
			sweetscent: ["7L40"],
			swordsdance: ["7M"],
			synthesis: ["7T", "7L28", "7S0"],
			toxic: ["7M"],
			worryseed: ["7T"],
			xscissor: ["7M", "7L1"],
		},
		eventData: [
			{generation: 7, level: 30, perfectIVs: 3, moves: ["growth", "ingrain", "leafblade", "synthesis"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	morelull: {
		learnset: {
			absorb: ["8L1", "7L1"],
			afteryou: ["7T"],
			amnesia: ["8M", "7E"],
			astonish: ["8L1", "7L4"],
			attract: ["8M", "7M"],
			confide: ["7M"],
			confuseray: ["8L4", "7L25"],
			dazzlinggleam: ["8M", "8L32", "7M"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			dreameater: ["8L44", "7M", "7L43"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			facade: ["8M"],
			flash: ["7L8"],
			frustration: ["7M"],
			gigadrain: ["8M", "8L28", "7T", "7L29"],
			grassknot: ["8M", "7M"],
			growth: ["8E", "7E"],
			hiddenpower: ["7M"],
			ingrain: ["8L8", "7L22"],
			leechseed: ["8E", "7E"],
			lightscreen: ["8M", "7M"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			megadrain: ["8L12", "7L15"],
			moonblast: ["8L40", "7L39"],
			moonlight: ["8L20", "7L11"],
			naturepower: ["7M"],
			poisonpowder: ["8E", "7E"],
			pollenpuff: ["8M"],
			protect: ["8M", "7M"],
			recycle: ["7T"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["8M", "7T"],
			signalbeam: ["7T"],
			sleeppowder: ["8L16", "7L18"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			spore: ["8L36", "7L36"],
			spotlight: ["7L46"],
			strengthsap: ["8L25", "7L32"],
			stunspore: ["8E", "7E"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			synthesis: ["7T"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			wonderroom: ["8M", "7T"],
			worryseed: ["7T"],
		},
	},
	shiinotic: {
		learnset: {
			absorb: ["8L1", "7L1"],
			afteryou: ["7T"],
			amnesia: ["8M"],
			astonish: ["8L1", "7L1"],
			attract: ["8M", "7M"],
			chargebeam: ["7M"],
			confide: ["7M"],
			confuseray: ["8L1", "7L26"],
			dazzlinggleam: ["8M", "8L38", "7M"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			drainpunch: ["8M"],
			dreameater: ["8L56", "7M", "7L49"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			facade: ["8M"],
			flash: ["7L1"],
			frustration: ["7M"],
			gigadrain: ["8M", "8L32", "7T", "7L31"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M"],
			ingrain: ["8L1", "7L1"],
			lightscreen: ["8M", "7M"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			megadrain: ["8L12", "7L15"],
			moonblast: ["8L50", "7L44"],
			moonlight: ["8L20", "7L11"],
			naturepower: ["7M"],
			pollenpuff: ["8M"],
			protect: ["8M", "7M"],
			raindance: ["8M"],
			recycle: ["7T"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["8M", "7T"],
			signalbeam: ["7T"],
			sleeppowder: ["8L16", "7L18"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			spore: ["8L44", "7L40"],
			spotlight: ["7L53"],
			strengthsap: ["8L27", "7L35"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			synthesis: ["7T"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			weatherball: ["8M"],
			wonderroom: ["8M", "7T"],
			worryseed: ["7T"],
		},
	},
	salandit: {
		learnset: {
			acidspray: ["9M"],
			agility: ["9M"],
			attract: ["8M", "7M"],
			beatup: ["8M"],
			belch: ["9E", "8E", "7E"],
			confide: ["7M"],
			covet: ["7T"],
			doubleslap: ["7L21"],
			doubleteam: ["7M"],
			dragonclaw: ["9M", "8M", "7M"],
			dragonpulse: ["9M", "9L40", "8M", "8L40", "7T", "7L48"],
			dragonrage: ["7L13"],
			ember: ["9L10", "8L10", "7L5"],
			endeavor: ["9L55", "8L60"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["9E", "8E", "7E"],
			fireblast: ["9M", "8M", "7M"],
			firefang: ["9M"],
			flameburst: ["7L24"],
			flamecharge: ["7M"],
			flamethrower: ["9M", "9L45", "8M", "8L50", "7M", "7L40"],
			flareblitz: ["9M"],
			fling: ["9M", "8M", "7M"],
			foulplay: ["9M", "8M", "7T"],
			frustration: ["7M"],
			gunkshot: ["9M", "8M", "7T"],
			heatwave: ["9M", "8M", "7T"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			incinerate: ["9L30", "8L30"],
			irontail: ["8M", "7T"],
			knockoff: ["7T", "7E"],
			leechlife: ["9M", "8M", "7M"],
			mudslap: ["9M", "9E", "8E"],
			nastyplot: ["9M", "9L25", "8M", "8L25", "7L32"],
			overheat: ["9M", "8M", "7M"],
			payback: ["8M", "7M"],
			poisonfang: ["9L15", "8L15"],
			poisongas: ["9L1", "8L1", "7L1"],
			poisonjab: ["9M", "8M", "7M"],
			poisontail: ["9M"],
			protect: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			sandattack: ["9E", "8E", "7E"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			scratch: ["9L1", "8L1", "7L1"],
			shadowclaw: ["9M", "8M", "7M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M"],
			sludgebomb: ["9M", "8M", "7M"],
			sludgewave: ["8M", "7M"],
			smog: ["9L5", "8L5", "7L16"],
			snatch: ["7T", "7E"],
			snore: ["8M", "7T"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M"],
			swagger: ["7M"],
			sweetscent: ["9L20", "8L20", "7L8"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			thunderwave: ["9M", "8M"],
			torment: ["7M"],
			toxic: ["9L50", "8L55", "7M", "7L29"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			venomdrench: ["8M", "8L45", "7L45"],
			venoshock: ["9M", "9L35", "8M", "8L35", "7M", "7L37"],
			willowisp: ["9M", "8M", "7M"],
		},
	},
	salazzle: {
		learnset: {
			acidspray: ["9M"],
			acrobatics: ["9M", "8M", "7M"],
			agility: ["9M"],
			attract: ["8M", "7M"],
			beatup: ["8M"],
			bodyslam: ["9M"],
			breakingswipe: ["8M"],
			captivate: ["7L1"],
			confide: ["7M"],
			corrosivegas: ["8T"],
			covet: ["7T"],
			crosspoison: ["8M"],
			disable: ["9L1", "8L1", "7L1"],
			doubleslap: ["7L21"],
			doubleteam: ["7M"],
			dragonclaw: ["9M", "8M", "7M"],
			dragondance: ["9M", "8M"],
			dragonpulse: ["9M", "9L44", "8M", "8L44", "7T", "7L56"],
			dragonrage: ["7L13"],
			dragontail: ["7M"],
			ember: ["9L1", "8L1", "7L1"],
			encore: ["9M", "9L1", "8M", "8L1", "7L1"],
			endeavor: ["9L1", "8L1"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			fakeout: ["7S0"],
			faketears: ["9M", "8M"],
			fireblast: ["9M", "8M", "7M"],
			firefang: ["9M"],
			firelash: ["9L0", "8L0"],
			flameburst: ["7L24"],
			flamecharge: ["7M"],
			flamethrower: ["9M", "9L51", "8M", "8L58", "7M", "7L44", "7S0"],
			flareblitz: ["9M", "8M"],
			fling: ["9M", "8M", "7M"],
			foulplay: ["9M", "8M", "7T"],
			frustration: ["7M"],
			gigaimpact: ["9M"],
			gunkshot: ["9M", "8M", "7T"],
			heatwave: ["9M", "8M", "7T"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			incinerate: ["9L30", "8L30"],
			irontail: ["8M", "7T"],
			knockoff: ["9L1", "8L1", "7T"],
			laserfocus: ["7T"],
			leechlife: ["9M", "8M", "7M"],
			mudslap: ["9M"],
			nastyplot: ["9M", "9L25", "8M", "8L25", "7L32"],
			overheat: ["9M", "8M", "7M"],
			payback: ["8M", "7M"],
			poisonfang: ["9L15", "8L15"],
			poisongas: ["9L1", "8L1", "7L1"],
			poisonjab: ["9M", "8M", "7M"],
			poisontail: ["9M"],
			pound: ["9L1", "8L1", "7L1"],
			protect: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			scratch: ["9L1", "8L1"],
			shadowclaw: ["9M", "8M", "7M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M", "7M"],
			sludgebomb: ["9M", "8M", "7M", "7S0"],
			sludgewave: ["8M", "7M"],
			smog: ["9L1", "8L1", "7L16"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M"],
			swagger: ["9L1", "8L1", "7M", "7L1"],
			sweetscent: ["9L20", "8L20", "7L1"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			thunderwave: ["9M", "8M"],
			torment: ["9L1", "8L1", "7M", "7L1"],
			toxic: ["9L58", "8L65", "7M", "7L29", "7S0"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			venomdrench: ["8M", "8L51", "7L51"],
			venoshock: ["9M", "9L37", "8M", "8L37", "7M", "7L39"],
			willowisp: ["9M", "8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 50, moves: ["fakeout", "toxic", "sludgebomb", "flamethrower"], pokeball: "cherishball"},
		],
		encounters: [
			{generation: 7, level: 16},
		],
	},
	salazzletotem: {
		learnset: {
			acrobatics: ["7M"],
			attract: ["7M"],
			captivate: ["7L1"],
			confide: ["7M"],
			covet: ["7T"],
			disable: ["7L1"],
			doubleslap: ["7L21", "7S0"],
			doubleteam: ["7M"],
			dragonclaw: ["7M"],
			dragonpulse: ["7T", "7L56"],
			dragonrage: ["7L13"],
			dragontail: ["7M"],
			ember: ["7L1"],
			encore: ["7L1"],
			facade: ["7M"],
			fireblast: ["7M"],
			flameburst: ["7L24", "7S0"],
			flamecharge: ["7M"],
			flamethrower: ["7M", "7L44"],
			fling: ["7M"],
			foulplay: ["7T"],
			frustration: ["7M"],
			gunkshot: ["7T"],
			heatwave: ["7T"],
			helpinghand: ["7T"],
			hiddenpower: ["7M"],
			irontail: ["7T"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			leechlife: ["7M"],
			nastyplot: ["7L32"],
			overheat: ["7M"],
			payback: ["7M"],
			poisongas: ["7L1"],
			poisonjab: ["7M"],
			pound: ["7L1"],
			protect: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			round: ["7M"],
			shadowclaw: ["7M"],
			sleeptalk: ["7M"],
			sludgebomb: ["7M"],
			sludgewave: ["7M"],
			smog: ["7L16", "7S0"],
			snatch: ["7T"],
			snore: ["7T"],
			substitute: ["7M"],
			swagger: ["7M", "7L1"],
			sweetscent: ["7L1"],
			taunt: ["7M"],
			thief: ["7M"],
			torment: ["7M", "7L1"],
			toxic: ["7M", "7L29", "7S0"],
			venomdrench: ["7L51"],
			venoshock: ["7M", "7L39"],
			willowisp: ["7M"],
		},
		eventData: [
			{generation: 7, level: 30, perfectIVs: 3, moves: ["smog", "doubleslap", "flameburst", "toxic"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	stufful: {
		learnset: {
			aerialace: ["7M"],
			attract: ["8M", "7M"],
			babydolleyes: ["8L4", "7L10"],
			bide: ["7L5"],
			bind: ["7T"],
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "8L12", "7M", "7L14"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			charm: ["8M"],
			coaching: ["8T"],
			confide: ["7M"],
			defensecurl: ["8E"],
			doubleedge: ["8L44", "7L46"],
			doubleteam: ["7M"],
			dualchop: ["7T"],
			earthquake: ["8M", "7M"],
			endure: ["8M", "8L16", "7E"],
			facade: ["8M", "7M"],
			flail: ["8L28", "7L19"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["7T"],
			forcepalm: ["8E", "7E"],
			frustration: ["7M"],
			hammerarm: ["8L32", "7L32"],
			hiddenpower: ["7M"],
			icepunch: ["8M", "7T", "7E"],
			ironhead: ["8M", "7T"],
			leer: ["8L1", "7L1"],
			lowsweep: ["8M", "7M"],
			megakick: ["8M", "7E"],
			megapunch: ["8M"],
			painsplit: ["8L40", "7T", "7L41"],
			payback: ["8M", "8L8", "7M", "7L23"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			roleplay: ["7T"],
			rollout: ["8E"],
			round: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			stomp: ["8E"],
			stompingtantrum: ["8M", "7T", "7E"],
			strength: ["8L20"],
			substitute: ["8M", "7M"],
			superpower: ["8M", "8L48", "7T", "7L50"],
			swagger: ["7M"],
			swordsdance: ["8M", "7M"],
			tackle: ["8L1", "7L1"],
			takedown: ["8L24", "7L28"],
			taunt: ["8M", "7M"],
			thrash: ["8L36", "7L37"],
			thunderpunch: ["8M", "7T", "7E"],
			toxic: ["7M"],
			wideguard: ["7E"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	bewear: {
		learnset: {
			aerialace: ["7M"],
			attract: ["8M", "7M"],
			babydolleyes: ["8L1", "7L10", "7S0"],
			bide: ["7L5"],
			bind: ["8L0", "7T", "7L1", "7S0"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "8L12", "7M", "7L14", "7S0"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			charm: ["8M"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M"],
			darkestlariat: ["8M"],
			doubleedge: ["8L54", "7L56"],
			doubleteam: ["7M"],
			dragonclaw: ["8M", "7M"],
			drainpunch: ["8M", "7T"],
			dualchop: ["7T"],
			earthquake: ["8M", "7M"],
			endure: ["8M", "8L16"],
			facade: ["8M", "7M"],
			flail: ["8L30", "7L19"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			hammerarm: ["8L36", "7L36"],
			hiddenpower: ["7M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M"],
			icepunch: ["8M", "7T"],
			ironhead: ["8M", "7T"],
			leer: ["8L1", "7L1"],
			lowkick: ["8M", "7T"],
			lowsweep: ["8M", "7M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			painsplit: ["8L48", "7T", "7L49"],
			payback: ["8M", "8L1", "7M", "7L23"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			shadowclaw: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			stompingtantrum: ["8M", "7T"],
			strength: ["8L20"],
			substitute: ["8M", "7M"],
			superpower: ["8M", "8L60", "7T", "7L62", "7S0"],
			swagger: ["7M"],
			swordsdance: ["8M", "7M"],
			tackle: ["8L1", "7L1"],
			takedown: ["8L24", "7L30"],
			taunt: ["8M", "7M"],
			thrash: ["8L42", "7L43"],
			thunderpunch: ["8M", "7T"],
			toxic: ["7M"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 50, gender: "F", isHidden: true, moves: ["babydolleyes", "brutalswing", "superpower", "bind"], pokeball: "cherishball"},
		],
	},
	bounsweet: {
		learnset: {
			acupressure: ["9E", "8E", "7E"],
			aromatherapy: ["8L36"],
			aromaticmist: ["9L32", "8L32", "7L33"],
			attract: ["8M", "7M"],
			bounce: ["8M", "7T"],
			bulletseed: ["9M"],
			charm: ["9M", "8M", "7E"],
			confide: ["7M"],
			covet: ["7T"],
			dazzlinggleam: ["9M", "8M", "7M"],
			doubleteam: ["7M"],
			drainingkiss: ["9M", "8M"],
			endeavor: ["9E", "8E", "7T"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			feint: ["7E"],
			flail: ["9L24", "8L24", "7L29"],
			frustration: ["7M"],
			gigadrain: ["9M", "8M", "7T"],
			grassknot: ["9M", "8M", "7M"],
			grasswhistle: ["7E"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			leafstorm: ["9M"],
			lightscreen: ["9M", "8M", "7M"],
			magicalleaf: ["9M", "9L20", "8M", "8L20", "7L21"],
			naturepower: ["7M"],
			playnice: ["9L4", "8L4", "7L5"],
			playrough: ["9M", "8M", "7E"],
			protect: ["9M", "8M", "7M"],
			rapidspin: ["9L8", "8L8", "7L9"],
			razorleaf: ["9L12", "8L12", "7L13"],
			reflect: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["9M", "8M", "7T"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["9M", "8M", "7M"],
			splash: ["9L1", "8L1", "7L1"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			sweetscent: ["9L16", "8L16", "7L17"],
			swift: ["9M"],
			synthesis: ["9E", "8E", "7T", "7E"],
			takedown: ["9M"],
			teeterdance: ["9L28", "8L28", "7L25"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			worryseed: ["7T"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
	},
	steenee: {
		learnset: {
			aromatherapy: ["8L46", "7L41"],
			aromaticmist: ["9L40", "8L40", "7L33"],
			attract: ["8M", "7M"],
			bounce: ["8M", "7T"],
			bulletseed: ["9M"],
			captivate: ["7L37"],
			charm: ["9M", "8M"],
			confide: ["7M"],
			covet: ["7T"],
			dazzlinggleam: ["9M", "8M", "7M"],
			doubleslap: ["7L1", "7S0"],
			doubleteam: ["7M"],
			drainingkiss: ["9M", "8M"],
			endeavor: ["7T"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			flail: ["9L1", "8L1"],
			fling: ["9M", "8M", "7M"],
			frustration: ["7M"],
			gigadrain: ["9M", "8M", "7T"],
			grassknot: ["9M", "8M", "7M"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			knockoff: ["7T"],
			leafstorm: ["9M", "9L46", "8M", "8L52", "7L45"],
			lightscreen: ["9M", "8M", "7M"],
			lowsweep: ["9M", "8M", "7M"],
			magicalleaf: ["9M", "9L22", "8M", "8L22", "7L21", "7S0"],
			naturepower: ["7M"],
			payback: ["8M", "7M"],
			playnice: ["9L1", "8L1", "7L1"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M", "7M"],
			rapidspin: ["9L1", "8L1", "7L1"],
			razorleaf: ["9L1", "8L1", "7L1"],
			reflect: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["9M", "8M", "7T"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["9M", "8M", "7M"],
			splash: ["9L1", "8L1", "7L1"],
			stomp: ["9L28", "8L28", "7L29"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			sweetscent: ["9L16", "8L16", "7L17", "7S0"],
			swift: ["9M"],
			synthesis: ["7T"],
			takedown: ["9M"],
			teeterdance: ["9L34", "8L34", "7L25"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			tripleaxel: ["8T"],
			worryseed: ["7T"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 20, nature: "Naive", abilities: ["leafguard"], moves: ["magicalleaf", "doubleslap", "sweetscent"], pokeball: "cherishball"},
		],
	},
	tsareena: {
		learnset: {
			acrobatics: ["9M", "8M", "7M"],
			aromatherapy: ["8L46", "7L41"],
			aromaticmist: ["9L40", "8L40", "7L33"],
			attract: ["8M", "7M"],
			bounce: ["8M", "7T"],
			bulletseed: ["9M"],
			captivate: ["7L37"],
			charm: ["9M", "8M"],
			confide: ["7M"],
			covet: ["7T"],
			dazzlinggleam: ["9M", "8M", "7M"],
			doubleslap: ["7L1"],
			doubleteam: ["7M"],
			drainingkiss: ["9M", "8M"],
			endeavor: ["7T"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			flail: ["9L1", "8L1"],
			fling: ["9M", "8M", "7M"],
			frustration: ["7M"],
			gigadrain: ["9M", "8M", "7T"],
			gigaimpact: ["9M", "8M", "7M"],
			grassknot: ["9M", "8M", "7M"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			helpinghand: ["9M", "8M", "7T"],
			hiddenpower: ["7M"],
			highjumpkick: ["9L58", "8L58", "7L49"],
			hyperbeam: ["9M", "8M"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			leafstorm: ["9M", "9L46", "8M", "8L52", "7L45"],
			lightscreen: ["9M", "8M", "7M"],
			lowkick: ["9M", "8M", "7T"],
			lowsweep: ["9M", "8M", "7M"],
			magicalleaf: ["9M", "9L22", "8M", "8L22", "7L21"],
			megakick: ["8M"],
			naturepower: ["7M"],
			payback: ["8M", "7M"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			powerwhip: ["9L1", "8M", "8L1", "7L53"],
			protect: ["9M", "8M", "7M"],
			punishment: ["7L1"],
			rapidspin: ["9L1", "8L1", "7L1"],
			razorleaf: ["9L1", "8L1", "7L1"],
			reflect: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["9M", "8M", "7T"],
			sleeptalk: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["9M", "8M", "7M"],
			solarblade: ["8M"],
			splash: ["9L1", "8L1", "7L1"],
			stomp: ["9L28", "8L28", "7L29"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["9L1", "8L1", "7M", "7L1"],
			sweetscent: ["9L16", "8L16", "7L17"],
			swift: ["9M"],
			synthesis: ["7T"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			teeterdance: ["9L34", "8L34", "7L25"],
			terablast: ["9M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			tripleaxel: ["8T"],
			tropkick: ["9L0", "8L0", "7L1"],
			uturn: ["9M", "8M", "7M"],
			worryseed: ["7T"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
	},
	comfey: {
		learnset: {
			acrobatics: ["8M", "7M"],
			afteryou: ["8E", "7T", "7E"],
			allyswitch: ["8M", "7T"],
			amnesia: ["8M", "7E"],
			aromatherapy: ["8L36", "7L43"],
			attract: ["8M", "7M"],
			bind: ["7T"],
			bulletseed: ["8M"],
			calmmind: ["8M", "7M"],
			celebrate: ["7S0"],
			charm: ["8M"],
			confide: ["7M"],
			covet: ["7T"],
			dazzlinggleam: ["8M", "7M"],
			defog: ["7T"],
			doubleteam: ["7M"],
			drainingkiss: ["8M", "8L9", "7L7", "7S0"],
			echoedvoice: ["7M"],
			encore: ["8M"],
			endure: ["8M", "7E"],
			energyball: ["8M", "7M"],
			facade: ["8M", "7M"],
			fling: ["8M", "7M"],
			floralhealing: ["8L30", "7L37"],
			flowershield: ["8L12", "7L1"],
			frustration: ["7M"],
			gigadrain: ["8M", "7T"],
			grassknot: ["8M", "8L24", "7M", "7L34"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L48", "7L46"],
			growth: ["8L1", "7L13"],
			healbell: ["7T"],
			helpinghand: ["8M", "8L6", "7T", "7L1"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			leaftornado: ["8E"],
			leechseed: ["8L21", "7L4", "7S0"],
			lightscreen: ["8M", "7M"],
			luckychant: ["7E"],
			magicalleaf: ["8M", "8L15", "7L10", "7S0"],
			magiccoat: ["7T"],
			naturalgift: ["7L22"],
			naturepower: ["7M"],
			painsplit: ["7T"],
			petalblizzard: ["8L33", "7L25"],
			petaldance: ["8L45", "7L40"],
			playrough: ["8M", "8L39", "7L49"],
			pollenpuff: ["8M"],
			protect: ["8M", "7M"],
			psychup: ["7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			seedbomb: ["8M", "7T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			sweetkiss: ["8L27", "7L19"],
			sweetscent: ["8L42", "7L31"],
			synthesis: ["8L18", "7T", "7L28"],
			tailwind: ["7T"],
			taunt: ["8M", "7M"],
			telekinesis: ["7T"],
			thief: ["8M", "7M"],
			toxic: ["7M"],
			trick: ["8M", "7T"],
			trickroom: ["8M", "7M"],
			uturn: ["8M", "7M"],
			vinewhip: ["8L3", "7L1"],
			worryseed: ["8E", "7T"],
			wrap: ["8L1", "7L16"],
		},
		eventData: [
			{generation: 7, level: 10, nature: "Jolly", moves: ["celebrate", "leechseed", "drainingkiss", "magicalleaf"], pokeball: "cherishball"},
		],
	},
	oranguru: {
		learnset: {
			afteryou: ["9L5", "8L5", "7T", "7L4"],
			allyswitch: ["8M", "7T", "7S1"],
			attract: ["8M"],
			block: ["7T"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["9M", "8M", "7M"],
			calmmind: ["9M", "9L10", "8M", "8L10", "7M", "7L39"],
			chargebeam: ["9M", "7M"],
			chillingwater: ["9M"],
			confide: ["7M"],
			confusion: ["9L1", "8L1", "7L1"],
			covet: ["7T"],
			doubleteam: ["7M"],
			dreameater: ["9E", "8E", "7M"],
			earthquake: ["9M", "8M", "7M"],
			embargo: ["7M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			expandingforce: ["8T"],
			extrasensory: ["9E", "8E", "7E"],
			facade: ["9M", "8M", "7M"],
			feintattack: ["7L22"],
			fling: ["9M", "8M", "7M"],
			focusblast: ["9M", "8M", "7M"],
			foulplay: ["9M", "9L55", "8M", "8L55", "7T", "7L36", "7S1"],
			frustration: ["7M"],
			futuresight: ["9L60", "8M", "8L60", "7L46"],
			gigaimpact: ["9M", "8M", "7M"],
			gravity: ["7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M"],
			imprison: ["9M", "8M"],
			instruct: ["9L50", "8L50", "7L32", "7S0", "7S1"],
			knockoff: ["7T"],
			lastresort: ["9E", "8E"],
			lightscreen: ["9M", "8M", "7M"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nastyplot: ["9M", "9L30", "8M", "8L30", "7L25"],
			naturepower: ["7M"],
			painsplit: ["7T"],
			payback: ["8M", "7M"],
			protect: ["9M", "8M", "7M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L45", "8M", "8L45", "7M", "7L43", "7S0"],
			psychicterrain: ["9M", "8M", "7E", "7S0"],
			psychup: ["9L20", "8L20", "7M", "7L18"],
			psyshock: ["9M", "8M", "7M"],
			quash: ["9L25", "8L25", "7M", "7L11"],
			raindance: ["9M", "8M", "7M"],
			reflect: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			rockslide: ["9M", "8M", "7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scaryface: ["9M"],
			shadowball: ["9M", "8M", "7M"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "8M", "7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			spite: ["7T"],
			storedpower: ["9M", "9L15", "8M", "8L15", "7L15"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M", "8M", "7M"],
			swagger: ["7M"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L1", "8M", "8L1", "7M", "7L8"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			thunder: ["9M", "8M", "7M"],
			thunderbolt: ["9M", "8M", "7M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			trick: ["9M", "8M", "7T"],
			trickroom: ["9M", "9L40", "8M", "8L40", "7M", "7L50", "7S1"],
			wonderroom: ["8M", "7T", "7E"],
			workup: ["8M", "7M"],
			yawn: ["9E", "8E"],
			zenheadbutt: ["9M", "9L35", "8M", "8L35", "7T", "7L29"],
		},
		eventData: [
			{generation: 7, level: 1, shiny: 1, abilities: ["telepathy"], moves: ["instruct", "psychic", "psychicterrain"], pokeball: "cherishball"},
			{generation: 7, level: 50, isHidden: true, moves: ["instruct", "foulplay", "trickroom", "allyswitch"], pokeball: "pokeball"},
		],
	},
	passimian: {
		learnset: {
			acrobatics: ["9M", "8M", "7M"],
			aerialace: ["7M"],
			assurance: ["8M"],
			attract: ["8M", "7M"],
			batonpass: ["9M"],
			beatup: ["9L15", "8M", "8L15", "7L15"],
			bestow: ["7L25", "7S0"],
			block: ["7T"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["9M", "9L35", "8M", "8L35", "7M", "7L32"],
			bulldoze: ["9M", "8M", "7M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "9L50", "8M", "8L50", "7L43", "7S1"],
			coaching: ["8T"],
			confide: ["7M"],
			counter: ["9E", "8E"],
			doubleedge: ["9L45", "8L45", "7L36"],
			doubleteam: ["7M"],
			drainpunch: ["9M", "8M", "7T"],
			earthquake: ["9M", "8M", "7M"],
			electroweb: ["8M", "7T"],
			endeavor: ["7T"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			feint: ["9E", "8E", "7E", "7S0"],
			fling: ["9M", "9L30", "8M", "8L30", "7M", "7L39", "7S0"],
			focusblast: ["9M", "8M", "7M"],
			focusenergy: ["9L10", "8M", "8L10", "7L11"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gigaimpact: ["9M", "9L60", "8M", "8L60", "7M", "7L50"],
			grassknot: ["9M", "8M", "7M"],
			gunkshot: ["9M", "8M", "7T", "7S1"],
			gyroball: ["8M", "7M"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M", "8M", "7M"],
			ironhead: ["9M", "8M", "7T", "7E"],
			irontail: ["8M", "7T"],
			knockoff: ["9E", "8E", "7T", "7S1"],
			laserfocus: ["7T"],
			leer: ["9L1", "8L1", "7L4"],
			lowkick: ["9M"],
			lowsweep: ["9M", "8M", "7M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			payback: ["8M", "7M"],
			protect: ["9M", "8M", "7M"],
			quickattack: ["9E", "8E", "7E"],
			quickguard: ["9E", "8E", "7E"],
			raindance: ["9M", "8M", "7M"],
			rest: ["9M", "8M", "7M"],
			retaliate: ["8M"],
			return: ["7M"],
			revenge: ["8M"],
			reversal: ["9M", "9L55", "8M", "8L55", "7L46"],
			rockslide: ["9M", "8M", "7M"],
			rocksmash: ["9L5", "8L5", "7L8"],
			rocktomb: ["9M", "8M", "7M"],
			round: ["8M", "7M"],
			scaryface: ["9M", "9L20", "8M", "8L20", "7L18"],
			seedbomb: ["9M", "8M", "7T"],
			seismictoss: ["9E", "8E", "7E"],
			shadowball: ["9M", "8M", "7M"],
			shockwave: ["7T"],
			sleeptalk: ["9M", "8M", "7M"],
			smackdown: ["7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M", "8M", "7M"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			tackle: ["9L1", "8L1", "7L1"],
			takedown: ["9M", "9L25", "8L25", "7L22"],
			taunt: ["9M", "8M", "7M"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			thrash: ["9L40", "8L40", "7L29"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uproar: ["8M", "7T"],
			uturn: ["9M", "8M", "7M", "7S1"],
			vitalthrow: ["8E", "7E"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 1, shiny: 1, moves: ["bestow", "fling", "feint"], pokeball: "cherishball"},
			{generation: 7, level: 50, isHidden: true, moves: ["closecombat", "uturn", "knockoff", "gunkshot"], pokeball: "pokeball"},
		],
	},
	wimpod: {
		learnset: {
			aquajet: ["8E", "7E"],
			assurance: ["8M"],
			attract: ["8M", "7M"],
			bugbuzz: ["8M"],
			confide: ["7M"],
			defensecurl: ["8L1"],
			doubleteam: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			frustration: ["7M"],
			hail: ["8M", "7M"],
			harden: ["8E", "7E"],
			hiddenpower: ["7M"],
			leechlife: ["8M", "7M"],
			metalclaw: ["8E", "7E"],
			mudshot: ["8M"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rollout: ["8E"],
			round: ["8M", "7M"],
			sandattack: ["8L1", "7L1"],
			scald: ["8M", "7M"],
			screech: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spikes: ["8M", "7E"],
			strugglebug: ["8L1", "7L1"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			taunt: ["8M", "7M"],
			toxic: ["7M"],
			waterfall: ["8M", "7M"],
			wideguard: ["8E", "7E"],
		},
	},
	golisopod: {
		learnset: {
			aerialace: ["7M"],
			assurance: ["8M"],
			attract: ["8M", "7M"],
			blizzard: ["8M", "7M"],
			brickbreak: ["8M", "7M"],
			bugbite: ["8L16", "7T", "7L10"],
			bugbuzz: ["8M"],
			bulkup: ["8M", "7M"],
			closecombat: ["8M"],
			confide: ["7M"],
			darkpulse: ["8M", "7M"],
			defensecurl: ["8L1"],
			dive: ["8M"],
			doubleteam: ["7M"],
			drillrun: ["8M", "7T"],
			dualchop: ["7T"],
			endeavor: ["7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			falseswipe: ["8M", "7M"],
			firstimpression: ["8L0", "7L1"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			furycutter: ["8L8", "7L1"],
			gigaimpact: ["8M", "7M"],
			hail: ["8M", "7M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "7T"],
			irondefense: ["8M", "8L20", "7T", "7L36"],
			ironhead: ["8M", "7T"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			leechlife: ["8M", "7M"],
			liquidation: ["8M", "8L44", "7T", "7L48"],
			muddywater: ["8M"],
			mudshot: ["8M", "8L12"],
			painsplit: ["7T"],
			payback: ["8M", "7M"],
			pinmissile: ["8M", "8L36", "7L41"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			psychup: ["7M"],
			raindance: ["8M", "7M"],
			razorshell: ["8M", "8L32", "7L26"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockslide: ["8M", "7M"],
			rocksmash: ["8L4", "7L1"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			sandattack: ["8L1", "7L1"],
			scald: ["8M", "7M"],
			screech: ["8M"],
			shadowclaw: ["8M", "7M"],
			skittersmack: ["8T"],
			slash: ["8L28", "7L21"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			sludgewave: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snore: ["8M", "7T"],
			spikes: ["8M"],
			spite: ["8L1", "7T", "7L13"],
			strugglebug: ["8L1", "7L1"],
			substitute: ["8M", "7M"],
			suckerpunch: ["8L24", "7L31"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "8L40", "7M", "7L16"],
			taunt: ["8M", "7M"],
			throatchop: ["8M", "7T"],
			toxic: ["7M"],
			venoshock: ["8M", "7M"],
			waterfall: ["8M", "7M"],
			waterpulse: ["7T"],
			xscissor: ["8M", "7M"],
		},
	},
	sandygast: {
		learnset: {
			absorb: ["9L1", "8L1", "7L1"],
			afteryou: ["7T"],
			amnesia: ["9M", "8M", "7E"],
			ancientpower: ["9E", "8E", "7E"],
			astonish: ["9L5", "8L5", "7L5"],
			attract: ["8M", "7M"],
			block: ["7T"],
			brine: ["8M"],
			bulldoze: ["9M", "9L25", "8M", "8L25", "7M", "7L23"],
			chillingwater: ["9M"],
			confide: ["7M"],
			confuseray: ["9M"],
			curse: ["9E", "8E", "7E"],
			destinybond: ["9E", "8E", "7E"],
			doubleteam: ["7M"],
			earthpower: ["9M", "9L50", "8M", "8L50", "7T", "7L45"],
			earthquake: ["9M", "8M", "7M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			flashcannon: ["9M"],
			fling: ["9M"],
			frustration: ["7M"],
			gigadrain: ["9M", "9L35", "8M", "8L35", "7T", "7L36"],
			gravity: ["7T"],
			harden: ["9L1", "8L1", "7L1"],
			hex: ["9M"],
			hiddenpower: ["7M"],
			hypnosis: ["9L30", "8L30", "7L27"],
			imprison: ["9M"],
			infestation: ["7M"],
			irondefense: ["9M", "9L40", "8M", "8L40", "7T", "7L32"],
			megadrain: ["9L15", "8L15", "7L18"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M"],
			nightshade: ["9M"],
			painsplit: ["7T"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "7M"],
			psychic: ["9M", "8M", "7M"],
			raindance: ["9M"],
			recycle: ["7T"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["9M", "8M", "7M"],
			rocktomb: ["9M", "8M", "7M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			sandattack: ["9L20", "8L20", "7L9"],
			sandstorm: ["9M", "9L60", "8M", "8L60", "7M", "7L54"],
			sandtomb: ["9L10", "8M", "8L10", "7L14"],
			scaryface: ["9M"],
			scorchingsands: ["8T"],
			shadowball: ["9M", "9L45", "8M", "8L45", "7M", "7L41"],
			shoreup: ["9L55", "8L55", "7L50"],
			skillswap: ["9M", "8M", "7T"],
			sleeptalk: ["9M", "8M", "7M"],
			sludgebomb: ["9M", "8M"],
			snore: ["8M", "7T"],
			spite: ["7T"],
			spitup: ["9E", "8E", "7E"],
			stealthrock: ["9M", "8M", "7T"],
			stockpile: ["9E", "8E", "7E"],
			stoneedge: ["9M", "8M", "7M"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M"],
			swagger: ["7M"],
			swallow: ["9E", "8E", "7E"],
			terablast: ["9M"],
			toxic: ["7M"],
			trick: ["9M", "8M", "7T"],
		},
	},
	palossand: {
		learnset: {
			absorb: ["9L1", "8L1", "7L1"],
			afteryou: ["7T"],
			amnesia: ["9M", "8M"],
			astonish: ["9L1", "8L1", "7L1"],
			attract: ["8M", "7M"],
			block: ["7T"],
			bodyslam: ["9M"],
			brine: ["8M"],
			bulldoze: ["9M", "9L25", "8M", "8L25", "7M", "7L23"],
			chillingwater: ["9M"],
			confide: ["7M"],
			confuseray: ["9M"],
			doubleteam: ["7M"],
			earthpower: ["9M", "9L54", "8M", "8L54", "7T", "7L47"],
			earthquake: ["9M", "8M", "7M"],
			embargo: ["7M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			facade: ["9M", "8M", "7M"],
			flashcannon: ["9M"],
			fling: ["9M", "8M", "7M"],
			frustration: ["7M"],
			gigadrain: ["9M", "9L35", "8M", "8L35", "7T", "7L36"],
			gigaimpact: ["9M", "8M", "7M"],
			gravity: ["7T"],
			harden: ["9L1", "8L1", "7L1"],
			hex: ["9M"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M"],
			hypnosis: ["9L30", "8L30", "7L27"],
			imprison: ["9M"],
			infestation: ["7M"],
			irondefense: ["9M", "9L40", "8M", "8L40", "7T", "7L32"],
			megadrain: ["9L15", "8L15", "7L18"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M"],
			nightshade: ["9M"],
			painsplit: ["7T"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "7M"],
			psychic: ["9M", "8M", "7M"],
			quash: ["7M"],
			raindance: ["9M"],
			recycle: ["7T"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["9M", "8M", "7M"],
			rocktomb: ["9M", "8M", "7M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			sandattack: ["9L20", "8L20", "7L1"],
			sandstorm: ["9M", "9L68", "8M", "8L68", "7M", "7L60"],
			sandtomb: ["9L1", "8M", "8L1", "7L14"],
			scaryface: ["9M"],
			scorchingsands: ["8T"],
			shadowball: ["9M", "9L47", "8M", "8L47", "7M", "7L41"],
			shoreup: ["9L61", "8L61", "7L54"],
			skillswap: ["9M", "8M", "7T"],
			sleeptalk: ["9M", "8M", "7M"],
			sludgebomb: ["9M", "8M", "7M"],
			snore: ["8M", "7T"],
			spite: ["7T"],
			stealthrock: ["9M", "8M", "7T"],
			stoneedge: ["9M", "8M", "7M"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M"],
			swagger: ["7M"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			toxic: ["7M"],
			trick: ["9M", "8M", "7T"],
		},
	},
	pyukumuku: {
		learnset: {
			attract: ["8M", "7M"],
			batonpass: ["8M", "8L1", "7L1"],
			bestow: ["7E"],
			bide: ["7L1"],
			block: ["7T"],
			confide: ["7M"],
			counter: ["8L20", "7L17"],
			curse: ["8L30", "7L25"],
			doubleteam: ["7M"],
			endure: ["8M", "7E"],
			gastroacid: ["8L35", "7T", "7L29"],
			hail: ["8M", "7M"],
			harden: ["8L1", "7L1"],
			helpinghand: ["8M", "8L5", "7T", "7L5"],
			lightscreen: ["8M", "7M"],
			memento: ["8L60", "7L49"],
			mirrorcoat: ["8E"],
			mudsport: ["7L1"],
			painsplit: ["8L40", "7T", "7L33"],
			protect: ["8M", "7M"],
			psychup: ["7M"],
			purify: ["8L25", "7L21"],
			quash: ["7M"],
			raindance: ["8M", "7M"],
			recover: ["8L45", "7L37"],
			recycle: ["7T"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			safeguard: ["8M", "8L15", "7M", "7L13"],
			screech: ["8M"],
			sleeptalk: ["8M", "7M"],
			soak: ["8L50", "7L41"],
			spite: ["8E", "7T", "7E"],
			substitute: ["8M", "7M"],
			swagger: ["8E", "7M"],
			taunt: ["8M", "8L10", "7M", "7L9"],
			tickle: ["8E", "7E"],
			toxic: ["8L55", "7M", "7L45"],
			venomdrench: ["8M", "7E"],
			watersport: ["7L1"],
		},
	},
	typenull: {
		learnset: {
			aerialace: ["8L5", "7M", "7L20"],
			airslash: ["8M", "8L30", "7L60", "7S1"],
			confide: ["7M"],
			crushclaw: ["8L25", "7L25", "7S0"],
			doubleedge: ["8L55", "7L80"],
			doublehit: ["8L15", "7L55", "7S1"],
			doubleteam: ["7M"],
			dragonclaw: ["8M", "7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			flamecharge: ["7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			hail: ["8M", "7M"],
			healblock: ["7L85"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M"],
			icywind: ["8M", "7T"],
			imprison: ["8M", "8L1", "7L15"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "8L45", "8S2", "7T", "7L50", "7S1"],
			lastresort: ["7T"],
			magiccoat: ["7T"],
			metalsound: ["8L20", "7L45", "7S1"],
			payback: ["8M", "7M"],
			protect: ["8M", "7M"],
			punishment: ["7L65"],
			pursuit: ["7L10"],
			rage: ["7L5"],
			raindance: ["8M", "7M"],
			razorwind: ["7L70"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			scaryface: ["8M", "8L10", "7L30", "7S0"],
			shadowclaw: ["8M", "7M"],
			signalbeam: ["7T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "7M"],
			tackle: ["8L1", "7L1"],
			takedown: ["8L50", "8S2", "7L40", "7S0"],
			terrainpulse: ["8T"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			triattack: ["8M", "8L35", "8S2", "7L75"],
			uturn: ["8M", "7M"],
			workup: ["8M", "7M"],
			xscissor: ["8M", "8L40", "8S2", "7M", "7L35", "7S0"],
		},
		eventData: [
			{generation: 7, level: 40, shiny: 1, perfectIVs: 3, moves: ["crushclaw", "scaryface", "xscissor", "takedown"], pokeball: "pokeball"},
			{generation: 7, level: 60, shiny: 1, perfectIVs: 3, moves: ["metalsound", "ironhead", "doublehit", "airslash"], pokeball: "pokeball"},
			{generation: 8, level: 50, shiny: 1, perfectIVs: 3, moves: ["triattack", "xscissor", "ironhead", "takedown"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	silvally: {
		learnset: {
			aerialace: ["8L1", "7M", "7L20"],
			airslash: ["8M", "8L30", "7L60"],
			bite: ["8L1", "7L15"],
			confide: ["7M"],
			crunch: ["8M", "8L45", "7L50"],
			crushclaw: ["8L25", "7L25"],
			defog: ["7T"],
			doubleedge: ["8L55", "7L80"],
			doublehit: ["8L15", "7L55"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T"],
			dragonclaw: ["8M", "7M"],
			endure: ["8M"],
			explosion: ["8L1", "7M"],
			facade: ["8M", "7M"],
			firefang: ["8M", "8L1", "7L1"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "7M"],
			flashcannon: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			grasspledge: ["8T", "7T"],
			hail: ["8M", "7M"],
			healblock: ["7L1"],
			heatwave: ["8M", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M", "7T"],
			icebeam: ["8M", "7M"],
			icefang: ["8M", "8L1", "7L1"],
			icywind: ["8M", "7T"],
			imprison: ["8M", "8L1", "7L1"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "8L1", "7T", "7L1"],
			laserfocus: ["7T"],
			lastresort: ["7T"],
			magiccoat: ["7T"],
			metalsound: ["8L20", "7L45"],
			multiattack: ["8L0", "7L1", "7S0"],
			outrage: ["8M", "7T"],
			partingshot: ["8L60", "7L85", "7S0"],
			payback: ["8M", "7M"],
			poisonfang: ["8L1", "7L1"],
			protect: ["8M", "7M"],
			psychicfangs: ["8M"],
			punishment: ["7L65", "7S0"],
			pursuit: ["7L10"],
			rage: ["7L5"],
			raindance: ["8M", "7M"],
			razorwind: ["7L70"],
			rest: ["8M", "7M"],
			return: ["7M"],
			reversal: ["8M"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			scaryface: ["8M", "8L1", "7L30", "7S0"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "7M"],
			shadowclaw: ["8M", "7M"],
			signalbeam: ["7T"],
			sleeptalk: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snore: ["8M", "7T"],
			steelbeam: ["8T"],
			steelwing: ["8M", "7M"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "7M"],
			tackle: ["8L1", "7L1"],
			tailwind: ["7T"],
			takedown: ["8L50", "7L40"],
			terrainpulse: ["8T"],
			thunderbolt: ["8M", "7M"],
			thunderfang: ["8M", "8L1", "7L1"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			triattack: ["8M", "8L35", "7L75"],
			uturn: ["8M", "7M"],
			workup: ["8M", "7M"],
			xscissor: ["8M", "8L40", "7M", "7L35"],
			zenheadbutt: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 100, shiny: true, moves: ["multiattack", "partingshot", "punishment", "scaryface"], pokeball: "cherishball"},
		],
	},
	minior: {
		learnset: {
			acrobatics: ["7M"],
			ancientpower: ["7L17"],
			attract: ["7M"],
			autotomize: ["7L31"],
			bulldoze: ["7M"],
			calmmind: ["7M"],
			chargebeam: ["7M"],
			confide: ["7M"],
			confuseray: ["7L10"],
			cosmicpower: ["7L36"],
			dazzlinggleam: ["7M"],
			defensecurl: ["7L3"],
			doubleedge: ["7L43"],
			doubleteam: ["7M"],
			earthquake: ["7M"],
			endeavor: ["7T"],
			explosion: ["7M", "7L50"],
			facade: ["7M"],
			frustration: ["7M"],
			gigaimpact: ["7M"],
			gravity: ["7T"],
			gyroball: ["7M"],
			hiddenpower: ["7M"],
			hyperbeam: ["7M"],
			ironhead: ["7T"],
			lastresort: ["7T"],
			lightscreen: ["7M"],
			magnetrise: ["7T"],
			powergem: ["7L38"],
			protect: ["7M"],
			psychic: ["7M"],
			psychup: ["7M"],
			reflect: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["7M"],
			rocktomb: ["7M"],
			rollout: ["7L8"],
			round: ["7M"],
			safeguard: ["7M"],
			sandstorm: ["7M"],
			selfdestruct: ["7L22"],
			shellsmash: ["7L45"],
			sleeptalk: ["7M"],
			snore: ["7T"],
			solarbeam: ["7M"],
			stealthrock: ["7T", "7L24"],
			stoneedge: ["7M"],
			substitute: ["7M"],
			swagger: ["7M"],
			swift: ["7L15"],
			tackle: ["7L1"],
			takedown: ["7L29"],
			telekinesis: ["7T"],
			toxic: ["7M"],
			uturn: ["7M"],
			zenheadbutt: ["7T"],
		},
	},
	komala: {
		learnset: {
			acrobatics: ["9M", "7M"],
			attract: ["7M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "7M"],
			bulkup: ["9M", "7M"],
			bulldoze: ["9M", "7M"],
			calmmind: ["9M", "7M"],
			charm: ["9M", "9E", "7E"],
			confide: ["7M"],
			defensecurl: ["9L1", "7L1"],
			doubleteam: ["7M"],
			earthquake: ["9M", "7M"],
			endeavor: ["7T"],
			endure: ["9M"],
			facade: ["9M", "7M"],
			flail: ["9L26", "7L26"],
			fling: ["9M"],
			frustration: ["7M"],
			gigaimpact: ["9M"],
			gunkshot: ["9M"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M"],
			icespinner: ["9M"],
			ironhead: ["9M", "7T"],
			knockoff: ["7T"],
			lastresort: ["7T"],
			lowkick: ["9M"],
			lowsweep: ["9M", "7M"],
			metalclaw: ["9M"],
			payback: ["7M"],
			playrough: ["9M", "9E", "7E"],
			protect: ["9M", "7M"],
			psychup: ["9L36", "7M", "7L36"],
			quash: ["7M"],
			raindance: ["9M"],
			rapidspin: ["9L11", "7L11"],
			return: ["7M"],
			reversal: ["9M"],
			rockslide: ["9M", "7M"],
			rocktomb: ["9M"],
			rollout: ["9L1", "7L1"],
			round: ["7M"],
			seedbomb: ["9M"],
			shadowclaw: ["9M", "7M"],
			sing: ["9E", "7E"],
			slam: ["9L21", "7L21"],
			sleeptalk: ["9M", "7M"],
			snore: ["7T"],
			spitup: ["9L6", "7L6"],
			stockpile: ["9L6", "7L6"],
			stompingtantrum: ["9M", "7T"],
			substitute: ["9M", "7M"],
			suckerpunch: ["9L31", "7L31"],
			sunnyday: ["9M", "7M"],
			superpower: ["9E", "7T"],
			swagger: ["7M"],
			swallow: ["9L6", "7L6"],
			swordsdance: ["9M", "7M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L46", "7L46"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			uturn: ["9M", "7M"],
			wish: ["9E", "7E"],
			woodhammer: ["9L41", "7L41"],
			workup: ["7M"],
			yawn: ["9L16", "7L16"],
			zenheadbutt: ["9M", "7T"],
		},
	},
	turtonator: {
		learnset: {
			attract: ["8M", "7M"],
			block: ["7T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L32", "7L33", "7S0"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			burningjealousy: ["8T"],
			chargebeam: ["7M"],
			confide: ["7M"],
			curse: ["8E"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T"],
			dragonclaw: ["8M", "7M"],
			dragonpulse: ["8M", "8L28", "7T", "7L41"],
			dragontail: ["7M", "7S1"],
			earthquake: ["8M", "7M"],
			ember: ["8L4", "7L1"],
			endeavor: ["7T"],
			endure: ["8M", "8L12", "7L21"],
			explosion: ["8L52", "7M", "7L53"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			firespin: ["8M", "7E"],
			flail: ["8L16", "7L17"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "8L36", "7M", "7L29", "7S0", "7S1"],
			flashcannon: ["8M", "7M"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			headsmash: ["8E", "7E"],
			heatcrash: ["8M"],
			heatwave: ["8M", "7T"],
			heavyslam: ["8M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M", "7T"],
			incinerate: ["8L20", "7L13"],
			irondefense: ["8M", "8L24", "7T", "7L25"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T"],
			lashout: ["8T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			outrage: ["8M", "7T"],
			overheat: ["8M", "8L48", "7M", "7L49"],
			payback: ["8M", "7M"],
			protect: ["8M", "8L8", "7M", "7L9"],
			rapidspin: ["8E"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M", "7E"],
			roar: ["7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			scaleshot: ["8T"],
			scorchingsands: ["8T"],
			shellsmash: ["8L44", "7L37"],
			shelltrap: ["8L40", "7L45", "7S1"],
			shockwave: ["7T"],
			sleeptalk: ["8M", "7M"],
			smackdown: ["7M"],
			smog: ["8L1", "7L5"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			tackle: ["8L1", "7L1"],
			taunt: ["8M", "7M"],
			toxic: ["7M"],
			uproar: ["8M", "7T"],
			venoshock: ["8M", "7M"],
			wideguard: ["8E", "7E", "7S0"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 1, shiny: 1, moves: ["flamethrower", "bodyslam", "wideguard"], pokeball: "cherishball"},
			{generation: 7, level: 30, gender: "M", nature: "Brave", moves: ["flamethrower", "shelltrap", "dragontail"], pokeball: "cherishball"},
		],
	},
	togedemaru: {
		learnset: {
			afteryou: ["7T"],
			agility: ["8M"],
			assurance: ["8M"],
			attract: ["8M", "7M"],
			bounce: ["8M", "7T"],
			charge: ["8L10", "7L13"],
			chargebeam: ["7M"],
			confide: ["7M"],
			covet: ["7T"],
			defensecurl: ["8L5", "7L5"],
			disarmingvoice: ["8E", "7E"],
			discharge: ["8L45", "7L29"],
			doubleteam: ["7M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M", "8L50", "7L37"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			encore: ["8M", "7E"],
			endeavor: ["7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fakeout: ["8E", "7E"],
			fellstinger: ["8L20", "7L53"],
			flail: ["8E", "7E"],
			fling: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			gravity: ["7T"],
			gyroball: ["8M", "7M"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T"],
			lastresort: ["7T"],
			magnetrise: ["8L35", "7T", "7L25"],
			nuzzle: ["8L1", "7L21"],
			payback: ["8M", "7M"],
			pinmissile: ["8M", "8L30", "7L45"],
			poisonjab: ["8M", "7M"],
			present: ["8E", "7E"],
			protect: ["8M", "7M"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			reversal: ["8M", "7E"],
			risingvoltage: ["8T"],
			roleplay: ["7T"],
			rollout: ["7L9"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["8L25", "7L17"],
			spikyshield: ["8L60", "7L49"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "7M"],
			superfang: ["7T"],
			swagger: ["7M"],
			swift: ["8M"],
			tackle: ["8L1", "7L1"],
			thief: ["8M", "7M"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "7M"],
			thundershock: ["8L15", "7L1"],
			thunderwave: ["8M", "7M"],
			tickle: ["8E", "7E"],
			toxic: ["7M"],
			twineedle: ["7E"],
			uturn: ["8M", "7M"],
			voltswitch: ["8M", "7M"],
			wildcharge: ["8M", "8L55", "7M", "7L41"],
			wish: ["8E", "7E"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "7T"],
			zingzap: ["8L40", "7L33"],
		},
	},
	togedemarutotem: {
		learnset: {
			afteryou: ["7T"],
			attract: ["7M"],
			bounce: ["7T"],
			charge: ["7L13"],
			chargebeam: ["7M"],
			confide: ["7M"],
			covet: ["7T"],
			defensecurl: ["7L5"],
			discharge: ["7L29", "7S0"],
			doubleteam: ["7M"],
			electricterrain: ["7L37"],
			electroweb: ["7T"],
			endeavor: ["7T"],
			facade: ["7M"],
			fellstinger: ["7L53"],
			fling: ["7M"],
			frustration: ["7M"],
			gigaimpact: ["7M"],
			grassknot: ["7M"],
			gravity: ["7T"],
			gyroball: ["7M"],
			helpinghand: ["7T"],
			hiddenpower: ["7M"],
			ironhead: ["7T"],
			irontail: ["7T"],
			lastresort: ["7T"],
			magnetrise: ["7T", "7L25", "7S0"],
			nuzzle: ["7L21", "7S0"],
			payback: ["7M"],
			pinmissile: ["7L45"],
			poisonjab: ["7M"],
			protect: ["7M"],
			reflect: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			roleplay: ["7T"],
			rollout: ["7L9"],
			round: ["7M"],
			shockwave: ["7T"],
			sleeptalk: ["7M"],
			snore: ["7T"],
			spark: ["7L17"],
			spikyshield: ["7L49"],
			substitute: ["7M"],
			superfang: ["7T"],
			swagger: ["7M"],
			tackle: ["7L1"],
			thief: ["7M"],
			thunder: ["7M"],
			thunderbolt: ["7M"],
			thundershock: ["7L1"],
			thunderwave: ["7M"],
			toxic: ["7M"],
			uturn: ["7M"],
			voltswitch: ["7M"],
			wildcharge: ["7M", "7L41"],
			workup: ["7M"],
			zenheadbutt: ["7T"],
			zingzap: ["7L33", "7S0"],
		},
		eventData: [
			{generation: 7, level: 30, perfectIVs: 3, moves: ["nuzzle", "magnetrise", "discharge", "zingzap"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	mimikyu: {
		learnset: {
			afteryou: ["7T"],
			astonish: ["9L1", "8L1", "7L1", "7S0", "7S1"],
			attract: ["8M", "7M"],
			babydolleyes: ["9L18", "8L18", "7L10", "7S0"],
			beatup: ["8M"],
			bulkup: ["9M", "8M", "7M"],
			burningjealousy: ["8T"],
			chargebeam: ["7M"],
			charm: ["9M", "9L48", "8M", "8L48", "7L28"],
			confide: ["7M"],
			confuseray: ["9M"],
			copycat: ["9L1", "8L1", "7L1", "7S0", "7S1"],
			covet: ["7T"],
			curse: ["9E", "8E", "7E"],
			darkpulse: ["9M", "8M", "7M"],
			dazzlinggleam: ["9M", "8M", "7M"],
			destinybond: ["9E", "8E", "7E", "7S2"],
			doubleteam: ["9L12", "8L12", "7M", "7L5"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M", "7T"],
			dreameater: ["7M"],
			embargo: ["7M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M", "7M"],
			feintattack: ["7L23"],
			fling: ["9M", "8M", "7M"],
			frustration: ["7M"],
			gigadrain: ["9M", "8M", "7T"],
			gigaimpact: ["9M", "8M"],
			grudge: ["8E", "7E"],
			hex: ["9M", "8M"],
			hiddenpower: ["7M"],
			honeclaws: ["9L30", "8L30", "7L41"],
			hyperbeam: ["9M", "8M", "7M"],
			infestation: ["7M"],
			lastresort: ["7T"],
			leechlife: ["9M", "8M", "7M"],
			lightscreen: ["9M", "8M", "7M"],
			magicroom: ["8M", "7T"],
			mimic: ["9L24", "8L24", "7L19", "7S2"],
			mistyterrain: ["9M"],
			nightmare: ["7E"],
			nightshade: ["9M"],
			painsplit: ["9L60", "8L60", "7T", "7L50"],
			payback: ["8M", "7M"],
			phantomforce: ["9M", "8M"],
			playrough: ["9M", "9L54", "8M", "8L54", "7L46", "7S1"],
			pounce: ["9M"],
			protect: ["9M", "8M", "7M"],
			psychic: ["9M", "8M", "7M"],
			psychup: ["7M"],
			raindance: ["9M"],
			rest: ["9M", "8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scratch: ["9L1", "8L1", "7L1"],
			screech: ["8M"],
			shadowball: ["9M", "8M", "7M"],
			shadowclaw: ["9M", "9L42", "8M", "8L42", "7M", "7L37"],
			shadowsneak: ["9L6", "8L6", "7L14"],
			slash: ["9L36", "8L36", "7L32"],
			sleeptalk: ["9M", "8M", "7M"],
			snatch: ["7T", "7S2"],
			snore: ["8M", "7T"],
			spite: ["7T"],
			splash: ["9L1", "8L1", "7L1", "7S0"],
			substitute: ["9M", "8M", "7M", "7S1"],
			sunnyday: ["9M"],
			swagger: ["7M"],
			swordsdance: ["9M", "8M", "7M"],
			takedown: ["9M"],
			taunt: ["9M", "8M", "7M"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			thief: ["9M", "8M", "7M"],
			thunder: ["9M", "8M", "7M"],
			thunderbolt: ["9M", "8M", "7M"],
			thunderwave: ["9M", "8M", "7M"],
			toxic: ["7M"],
			trailblaze: ["9M"],
			trick: ["9M", "8M", "7T", "7S2"],
			trickroom: ["9M", "8M", "7M"],
			willowisp: ["9M", "8M", "7M"],
			woodhammer: ["9L1", "8L1", "7L1"],
			workup: ["8M", "7M"],
			xscissor: ["9M", "8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 10, moves: ["copycat", "babydolleyes", "splash", "astonish"], pokeball: "cherishball"},
			{generation: 7, level: 10, shiny: true, moves: ["astonish", "playrough", "copycat", "substitute"], pokeball: "cherishball"},
			{generation: 7, level: 50, shiny: true, moves: ["mimic", "snatch", "trick", "destinybond"], pokeball: "cherishball"},
		],
	},
	mimikyutotem: {
		learnset: {
			afteryou: ["7T"],
			astonish: ["7L1"],
			attract: ["7M"],
			babydolleyes: ["7L10"],
			bulkup: ["7M"],
			chargebeam: ["7M"],
			charm: ["7L28", "7S0"],
			confide: ["7M"],
			copycat: ["7L1"],
			covet: ["7T"],
			darkpulse: ["7M"],
			dazzlinggleam: ["7M"],
			doubleteam: ["7M", "7L5"],
			drainpunch: ["7T"],
			dreameater: ["7M"],
			embargo: ["7M"],
			facade: ["7M"],
			feintattack: ["7L23", "7S0"],
			fling: ["7M"],
			frustration: ["7M"],
			gigadrain: ["7T"],
			hiddenpower: ["7M"],
			honeclaws: ["7L41"],
			hyperbeam: ["7M"],
			infestation: ["7M"],
			lastresort: ["7T"],
			leechlife: ["7M"],
			lightscreen: ["7M"],
			magicroom: ["7T"],
			mimic: ["7L19"],
			painsplit: ["7T", "7L50"],
			payback: ["7M"],
			playrough: ["7L46"],
			protect: ["7M"],
			psychic: ["7M"],
			psychup: ["7M"],
			rest: ["7M"],
			return: ["7M"],
			round: ["7M"],
			safeguard: ["7M"],
			scratch: ["7L1"],
			shadowball: ["7M"],
			shadowclaw: ["7M", "7L37", "7S0"],
			shadowsneak: ["7L14"],
			slash: ["7L32", "7S0"],
			sleeptalk: ["7M"],
			snatch: ["7T"],
			snore: ["7T"],
			spite: ["7T"],
			splash: ["7L1"],
			substitute: ["7M"],
			swagger: ["7M"],
			swordsdance: ["7M"],
			taunt: ["7M"],
			telekinesis: ["7T"],
			thief: ["7M"],
			thunder: ["7M"],
			thunderbolt: ["7M"],
			thunderwave: ["7M"],
			toxic: ["7M"],
			trick: ["7T"],
			trickroom: ["7M"],
			willowisp: ["7M"],
			woodhammer: ["7L1"],
			workup: ["7M"],
			xscissor: ["7M"],
		},
		eventData: [
			{generation: 7, level: 40, perfectIVs: 3, moves: ["feintattack", "charm", "slash", "shadowclaw"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	bruxish: {
		learnset: {
			aerialace: ["7M"],
			afteryou: ["7T"],
			agility: ["9M"],
			allyswitch: ["7T"],
			aquajet: ["9L17", "7L17"],
			aquatail: ["9L33", "7T", "7L33"],
			astonish: ["9L4", "7L4"],
			attract: ["7M"],
			bite: ["9L12", "7L12"],
			blizzard: ["9M", "7M"],
			bulkup: ["9M", "7M"],
			calmmind: ["9M", "7M"],
			chillingwater: ["9M"],
			confide: ["7M"],
			confusion: ["9L9", "7L9"],
			crunch: ["9M", "9L28", "7L28"],
			disable: ["9L20", "7L20"],
			doubleteam: ["7M"],
			dreameater: ["7M"],
			embargo: ["7M"],
			endure: ["9M"],
			facade: ["9M", "7M"],
			fling: ["7M"],
			frostbreath: ["7M"],
			frustration: ["7M"],
			gigaimpact: ["9M", "7M"],
			hiddenpower: ["7M"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "7M"],
			icefang: ["9M", "9E", "7E"],
			icywind: ["9M"],
			irontail: ["7T"],
			lightscreen: ["9M", "7M"],
			liquidation: ["9M", "7T"],
			magiccoat: ["7T"],
			magicroom: ["7T"],
			painsplit: ["7T"],
			payback: ["7M"],
			poisonfang: ["9E", "7E"],
			protect: ["9M", "7M"],
			psychic: ["9M", "7M"],
			psychicfangs: ["9M", "9L41", "7L41"],
			psychicterrain: ["9M"],
			psyshock: ["9M", "9L25"],
			psywave: ["7L25"],
			rage: ["7E"],
			raindance: ["9M", "7M"],
			reflect: ["9M", "7M"],
			rest: ["9M", "7M"],
			return: ["7M"],
			round: ["7M"],
			safeguard: ["7M"],
			scald: ["7M"],
			scaryface: ["9M"],
			screech: ["9L36", "7L36"],
			signalbeam: ["7T"],
			sleeptalk: ["9M", "7M"],
			snatch: ["7T"],
			snore: ["7T"],
			substitute: ["9M", "7M"],
			superfang: ["9E"],
			surf: ["9M", "7M"],
			swagger: ["7M"],
			swordsdance: ["9M", "7M"],
			synchronoise: ["7L44"],
			takedown: ["9M"],
			taunt: ["9M", "7M"],
			telekinesis: ["7T"],
			terablast: ["9M"],
			torment: ["7M"],
			toxic: ["7M"],
			trickroom: ["9M", "7M"],
			uproar: ["7T"],
			venoshock: ["9M", "7M"],
			waterfall: ["9M", "7M"],
			watergun: ["9L1", "7L1"],
			waterpulse: ["9M", "9E", "7T", "7E"],
			wavecrash: ["9L44"],
			wonderroom: ["7T"],
		},
	},
	drampa: {
		learnset: {
			amnesia: ["8M"],
			attract: ["8M", "7M"],
			blizzard: ["8M", "7M"],
			block: ["7T"],
			breakingswipe: ["8M"],
			bulldoze: ["8M", "7M"],
			calmmind: ["8M", "7M"],
			confide: ["7M"],
			defog: ["7T"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T"],
			dragonbreath: ["8L25", "7L29"],
			dragonclaw: ["8M", "7M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8L35", "7T", "7L41"],
			dragonrage: ["7L21"],
			dragonrush: ["8E", "7E"],
			dragontail: ["7M"],
			earthquake: ["8M", "7M"],
			echoedvoice: ["8L1", "7M", "7L1", "7S0"],
			endeavor: ["7T"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			extrasensory: ["8L30", "7L37"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			flamethrower: ["8M", "7M"],
			fling: ["8M", "7M"],
			fly: ["8M", "8L45", "7M", "7L45"],
			focusblast: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			glare: ["8L15", "7L13"],
			grassknot: ["8M", "7M"],
			heatwave: ["8M", "7T"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hurricane: ["8M", "7E", "7S0"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M", "8L50", "7T", "7L49"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "7T"],
			lashout: ["8T"],
			lightscreen: ["8M", "8L40", "7M", "7L17"],
			mist: ["8E", "7E"],
			naturalgift: ["7L25"],
			naturepower: ["7M"],
			outrage: ["8M", "8L55", "7T", "7L53"],
			playnice: ["8L1", "7L1", "7S0"],
			playrough: ["8M", "7E"],
			protect: ["8M", "8L10", "7M", "7L9"],
			psychup: ["7M"],
			raindance: ["8M", "7M"],
			razorwind: ["7E"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "8L20", "7M", "7L33"],
			scaleshot: ["8T"],
			shadowball: ["8M", "7M"],
			shadowclaw: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			sleeptalk: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			steelwing: ["8M", "7M"],
			stompingtantrum: ["8M", "7T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			superpower: ["8M", "7T"],
			surf: ["8M", "7M"],
			swift: ["8M"],
			tailwind: ["7T"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "7M"],
			thunderwave: ["8M", "7M"],
			tickle: ["8E"],
			toxic: ["7M"],
			twister: ["8L5", "7L5"],
			uproar: ["8M", "7T"],
			waterpulse: ["7T"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 1, shiny: 1, isHidden: true, moves: ["playnice", "echoedvoice", "hurricane"], pokeball: "cherishball"},
		],
	},
	dhelmise: {
		learnset: {
			absorb: ["8L1", "7L1"],
			aerialace: ["7M"],
			allyswitch: ["8M", "7T"],
			anchorshot: ["8L52", "7L32"],
			assurance: ["8M"],
			astonish: ["8L4", "7L1"],
			attract: ["7M"],
			block: ["7T"],
			bodypress: ["8M"],
			brickbreak: ["8M", "7M"],
			brine: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			confide: ["7M"],
			doubleteam: ["7M"],
			earthquake: ["8M", "7M"],
			embargo: ["7M"],
			endure: ["8M"],
			energyball: ["8M", "8L56", "7M", "7L41"],
			facade: ["8M", "7M"],
			flashcannon: ["8M", "7M"],
			frustration: ["7M"],
			gigadrain: ["8M", "8L28", "7T", "7L23"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			grassyglide: ["8T"],
			growth: ["8L16", "7L1"],
			gyroball: ["8M", "8L20", "7M", "7L14"],
			heavyslam: ["8M", "8L36", "7L50"],
			helpinghand: ["8M", "7T"],
			hex: ["8M"],
			hiddenpower: ["7M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "7T"],
			knockoff: ["7T"],
			liquidation: ["8M"],
			megadrain: ["8L12", "7L5"],
			metalsound: ["8L48", "7L18"],
			muddywater: ["8M"],
			painsplit: ["7T"],
			payback: ["8M", "7M"],
			phantomforce: ["8M", "8L60", "7L54"],
			poltergeist: ["8T"],
			powerwhip: ["8M", "8L64", "7L59"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			rapidspin: ["8L1", "7L1"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockslide: ["8M", "7M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			shadowball: ["8M", "8L44", "7M", "7L36"],
			shadowclaw: ["8M", "7M"],
			slam: ["8L40", "7L45"],
			sleeptalk: ["8M", "7M"],
			sludgewave: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			solarblade: ["8M"],
			spite: ["7T"],
			steelroller: ["8T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			switcheroo: ["8L24", "7L1"],
			swordsdance: ["8M", "7M"],
			synthesis: ["7T"],
			telekinesis: ["7T"],
			thief: ["8M", "7M"],
			toxic: ["7M"],
			whirlpool: ["8M", "8L32", "7L27"],
			wrap: ["8L8", "7L9"],
		},
	},
	jangmoo: {
		learnset: {
			aerialace: ["7M"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			bide: ["7L9"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			confide: ["7M"],
			counter: ["8E", "7E"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T"],
			dragonbreath: ["8E", "7E"],
			dragonclaw: ["8M", "8L32", "7M", "7L41"],
			dragondance: ["8M", "8L40", "7L49"],
			dragonpulse: ["8M", "7T"],
			dragontail: ["8L8", "7M", "7L17"],
			dualchop: ["7T"],
			earthquake: ["8M", "7M"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			falseswipe: ["8M", "7M"],
			fling: ["8M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["8E"],
			frustration: ["7M"],
			headbutt: ["8L16", "7L25"],
			hiddenpower: ["7M"],
			irondefense: ["8M", "8L28", "7T", "7L37"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T"],
			leer: ["8L1", "7L5"],
			lowkick: ["8M", "7T"],
			nobleroar: ["8L36", "7L45"],
			outrage: ["8M", "8L44", "7T", "7L53"],
			payback: ["8M", "7M"],
			protect: ["8M", "8L4", "7M", "7L13"],
			rest: ["8M", "7M"],
			return: ["7M"],
			reversal: ["8M", "7E"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L12", "7L21"],
			screech: ["8M", "8L24", "7L33"],
			shadowclaw: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			swordsdance: ["8M", "7M"],
			tackle: ["8L1", "7L1"],
			taunt: ["8M", "7M"],
			toxic: ["7M"],
			uproar: ["8M", "7T"],
			workup: ["8M", "8L20", "7M", "7L29"],
			xscissor: ["8M", "7M"],
		},
	},
	hakamoo: {
		learnset: {
			aerialace: ["7M"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			autotomize: ["8L1", "7L1"],
			bide: ["7L1"],
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			closecombat: ["8M", "8L56", "7L63"],
			coaching: ["8T"],
			confide: ["7M"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T"],
			dragonclaw: ["8M", "8L32", "7M", "7L43"],
			dragondance: ["8M", "8L44", "7L53"],
			dragonpulse: ["8M", "7T"],
			dragontail: ["8L1", "7M", "7L17"],
			drainpunch: ["8M", "7T"],
			dualchop: ["7T"],
			earthquake: ["8M", "7M"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			falseswipe: ["8M", "7M"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			frustration: ["7M"],
			headbutt: ["8L16", "7L25"],
			hiddenpower: ["7M"],
			irondefense: ["8M", "8L28", "7T", "7L38"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T"],
			leer: ["8L1", "7L1"],
			lowkick: ["8M", "7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nobleroar: ["8L38", "7L48"],
			outrage: ["8M", "8L50", "7T", "7L58"],
			payback: ["8M", "7M"],
			protect: ["8M", "8L1", "7M", "7L1"],
			rest: ["8M", "7M"],
			return: ["7M"],
			reversal: ["8M"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L12", "7L21"],
			screech: ["8M", "8L24", "7L33"],
			shadowclaw: ["8M", "7M"],
			skyuppercut: ["7L1"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			swordsdance: ["8M", "7M"],
			tackle: ["8L1", "7L1"],
			taunt: ["8M", "7M"],
			toxic: ["7M"],
			uproar: ["8M", "7T"],
			workup: ["8M", "8L20", "7M", "7L29"],
			xscissor: ["8M", "7M"],
		},
	},
	kommoo: {
		learnset: {
			aerialace: ["7M"],
			aquatail: ["7T"],
			attract: ["8M", "7M"],
			aurasphere: ["8M"],
			autotomize: ["8L1", "7L1"],
			bellydrum: ["8L1", "7L1"],
			bide: ["7L1"],
			bodypress: ["8M"],
			boomburst: ["8L76"],
			breakingswipe: ["8M"],
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			clangingscales: ["8L0", "7L1"],
			clangoroussoul: ["8L68"],
			closecombat: ["8M", "8L60", "7L75"],
			coaching: ["8T"],
			confide: ["7M"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T"],
			dragonclaw: ["8M", "8L32", "7M", "7L43"],
			dragondance: ["8M", "8L44", "7L59"],
			dragonpulse: ["8M", "7T"],
			dragontail: ["8L1", "7M", "7L17"],
			drainpunch: ["8M", "7T"],
			dualchop: ["7T"],
			earthquake: ["8M", "7M"],
			echoedvoice: ["7M"],
			endeavor: ["7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			falseswipe: ["8M", "7M"],
			firepunch: ["8M", "7T"],
			flamethrower: ["8M", "7M"],
			flashcannon: ["8M", "7M"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			headbutt: ["8L16", "7L25"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M", "7T"],
			icepunch: ["8M", "7T"],
			irondefense: ["8M", "8L28", "7T", "7L38"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T"],
			laserfocus: ["7T"],
			leer: ["8L1", "7L1"],
			lowkick: ["8M", "7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			nobleroar: ["8L38", "7L51"],
			outrage: ["8M", "8L52", "7T", "7L67"],
			payback: ["8M", "7M"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "8L1", "7M", "7L1"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L12", "7L21"],
			screech: ["8M", "8L24", "7L33"],
			shadowclaw: ["8M", "7M"],
			shockwave: ["7T"],
			skyuppercut: ["7L1"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["8M", "7T"],
			stompingtantrum: ["8M", "7T"],
			substitute: ["8M", "7M"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			swordsdance: ["8M", "7M"],
			tackle: ["8L1", "7L1"],
			taunt: ["8M", "7M"],
			thunderpunch: ["8M", "7T"],
			toxic: ["7M"],
			uproar: ["8M", "7T"],
			waterpulse: ["7T"],
			workup: ["8M", "8L20", "7M", "7L29"],
			xscissor: ["8M", "7M"],
		},
		encounters: [
			{generation: 7, level: 41},
		],
	},
	kommoototem: {
		learnset: {
			aerialace: ["7M"],
			aquatail: ["7T"],
			attract: ["7M"],
			autotomize: ["7L1"],
			bellydrum: ["7L1"],
			bide: ["7L1"],
			brickbreak: ["7M"],
			brutalswing: ["7M"],
			bulkup: ["7M"],
			bulldoze: ["7M"],
			clangingscales: ["7L1"],
			closecombat: ["7L75"],
			confide: ["7M"],
			doubleteam: ["7M"],
			dracometeor: ["7T"],
			dragonclaw: ["7M", "7L43", "7S0"],
			dragondance: ["7L59"],
			dragonpulse: ["7T"],
			dragontail: ["7M", "7L17"],
			drainpunch: ["7T"],
			dualchop: ["7T"],
			earthquake: ["7M"],
			echoedvoice: ["7M"],
			endeavor: ["7T"],
			facade: ["7M"],
			falseswipe: ["7M"],
			firepunch: ["7T"],
			flamethrower: ["7M"],
			flashcannon: ["7M"],
			fling: ["7M"],
			focusblast: ["7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gigaimpact: ["7M"],
			headbutt: ["7L25"],
			hiddenpower: ["7M"],
			hyperbeam: ["7M"],
			hypervoice: ["7T"],
			icepunch: ["7T"],
			irondefense: ["7T", "7L38", "7S0"],
			ironhead: ["7T"],
			irontail: ["7T"],
			laserfocus: ["7T"],
			leer: ["7L1"],
			lowkick: ["7T"],
			nobleroar: ["7L51"],
			outrage: ["7T", "7L67"],
			payback: ["7M"],
			poisonjab: ["7M"],
			protect: ["7M", "7L1"],
			rest: ["7M"],
			return: ["7M"],
			roar: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["7M"],
			rocktomb: ["7M"],
			round: ["7M"],
			safeguard: ["7M"],
			sandstorm: ["7M"],
			scaryface: ["7L21"],
			screech: ["7L33", "7S0"],
			shadowclaw: ["7M"],
			shockwave: ["7T"],
			skyuppercut: ["7L1"],
			sleeptalk: ["7M"],
			snore: ["7T"],
			stealthrock: ["7T"],
			stompingtantrum: ["7T"],
			substitute: ["7M"],
			superpower: ["7T"],
			swagger: ["7M"],
			swordsdance: ["7M"],
			tackle: ["7L1"],
			taunt: ["7M"],
			thunderpunch: ["7T"],
			toxic: ["7M"],
			uproar: ["7T"],
			waterpulse: ["7T"],
			workup: ["7M", "7L29", "7S0"],
			xscissor: ["7M"],
		},
		eventData: [
			{generation: 7, level: 50, perfectIVs: 3, moves: ["workup", "screech", "irondefense", "dragonclaw"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	tapukoko: {
		learnset: {
			acrobatics: ["8M", "7M"],
			aerialace: ["7M"],
			agility: ["8M", "8L35", "7L53", "7S0", "7S1"],
			assurance: ["8M"],
			bravebird: ["8M", "8L65", "8S3", "7L1"],
			calmmind: ["8M", "7M"],
			charge: ["8L30", "7L26"],
			confide: ["7M"],
			dazzlinggleam: ["8M", "7M", "7S2"],
			defog: ["7T"],
			discharge: ["8L45", "7L48", "7S0", "7S1"],
			doubleteam: ["7M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M", "8L75", "7L1"],
			electroball: ["8M", "7L58", "7S0", "7S1"],
			electroweb: ["8M", "7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fairywind: ["8L10"],
			falseswipe: ["8M", "8L15", "7M", "7L1"],
			fly: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M", "7T"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "7T"],
			lightscreen: ["8M", "7M"],
			meanlook: ["8L50", "7L1"],
			mirrormove: ["7L38"],
			naturepower: ["7M"],
			naturesmadness: ["8L55", "7L43", "7S0", "7S1", "7S2"],
			powerswap: ["8M", "8L70", "7L1"],
			protect: ["8M", "7M"],
			psychup: ["7M"],
			quickattack: ["8L1", "8S3", "7L1"],
			raindance: ["8M", "7M"],
			reflect: ["8M", "7M"],
			rest: ["8M"],
			return: ["7M"],
			roar: ["7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			screech: ["8M", "8L40", "7L20"],
			shockwave: ["8L25", "7T", "7L14"],
			skyattack: ["7T"],
			skydrop: ["7M"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spark: ["8L20", "7L8"],
			steelwing: ["8M", "7M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			taunt: ["8M", "8S3", "7M"],
			telekinesis: ["7T"],
			thief: ["8M", "7M"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "8S3", "7M", "7S2"],
			thunderpunch: ["8M", "7T"],
			thundershock: ["8L1", "7L1"],
			thunderwave: ["8M", "7M"],
			torment: ["7M"],
			toxic: ["7M"],
			uturn: ["8M", "7M"],
			voltswitch: ["8M", "7M", "7S2"],
			wildcharge: ["8M", "8L60", "7M", "7L32"],
			withdraw: ["8L5", "7L1"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 60, moves: ["naturesmadness", "discharge", "agility", "electroball"]},
			{generation: 7, level: 60, shiny: true, nature: "Timid", moves: ["naturesmadness", "discharge", "agility", "electroball"], pokeball: "cherishball"},
			{generation: 7, level: 60, shiny: true, moves: ["thunderbolt", "dazzlinggleam", "voltswitch", "naturesmadness"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["thunderbolt", "quickattack", "bravebird", "taunt"]},
		],
		eventOnly: true,
	},
	tapulele: {
		learnset: {
			allyswitch: ["8M", "7T"],
			aromatherapy: ["8L10", "7L1"],
			aromaticmist: ["8L30", "7L1"],
			astonish: ["8L1", "7L1"],
			calmmind: ["8M", "7M"],
			chargebeam: ["7M"],
			charm: ["8M", "8S2"],
			confide: ["7M"],
			confusion: ["8L1", "7L1"],
			dazzlinggleam: ["8M", "7M"],
			doubleteam: ["7M"],
			drainingkiss: ["8M", "8L15", "7L1"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			extrasensory: ["8L40", "7L48", "7S0", "7S1"],
			facade: ["8M", "7M"],
			flatter: ["8L25", "7L53", "7S0", "7S1"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			frustration: ["7M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			gravity: ["7T"],
			guardswap: ["8M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			irondefense: ["8M", "7T"],
			lightscreen: ["8M", "7M"],
			magiccoat: ["7T"],
			magicroom: ["8M", "8S2", "7T"],
			meanlook: ["8L50", "7L1"],
			moonblast: ["8L60", "7L58", "7S0", "7S1"],
			naturepower: ["7M"],
			naturesmadness: ["8L55", "7L43", "7S0", "7S1"],
			playrough: ["8M", "8S2"],
			powerswap: ["8M"],
			protect: ["8M", "7M"],
			psybeam: ["8L20", "7L14"],
			psychic: ["8M", "8S2", "7M"],
			psychicterrain: ["8M", "8L75", "7L1"],
			psychocut: ["8M"],
			psychup: ["7M"],
			psyshock: ["8M", "8L45", "7M", "7L32"],
			psywave: ["7L8"],
			reflect: ["8M", "7M"],
			rest: ["8M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			shadowball: ["8M", "7M"],
			skillswap: ["8M", "8L70", "7T", "7L26"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			speedswap: ["8M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			sweetscent: ["8L35", "7L20"],
			taunt: ["8M", "7M"],
			telekinesis: ["7T"],
			thief: ["8M", "7M"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "7M"],
			tickle: ["8L65", "7L38"],
			torment: ["7M"],
			toxic: ["7M"],
			withdraw: ["8L5", "7L1"],
			wonderroom: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 60, moves: ["naturesmadness", "extrasensory", "flatter", "moonblast"]},
			{generation: 7, level: 60, shiny: true, moves: ["naturesmadness", "extrasensory", "flatter", "moonblast"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["psychic", "playrough", "magicroom", "charm"]},
		],
		eventOnly: true,
	},
	tapubulu: {
		learnset: {
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulletseed: ["8M"],
			calmmind: ["8M", "7M"],
			closecombat: ["8M"],
			confide: ["7M"],
			darkestlariat: ["8M"],
			dazzlinggleam: ["8M", "7M"],
			disable: ["8L10", "7L1"],
			dualchop: ["7T"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			facade: ["8M", "7M"],
			falseswipe: ["8M", "7M"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			gigadrain: ["8M", "7T", "7L14"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			grassyterrain: ["8M", "8L75", "7L1"],
			guardswap: ["8M"],
			hiddenpower: ["7M"],
			highhorsepower: ["8M"],
			hornattack: ["8L30", "7L8"],
			hornleech: ["8L40", "7L32"],
			hyperbeam: ["8M", "7M"],
			irondefense: ["8M", "7T"],
			leafage: ["8L1", "7L1"],
			leechseed: ["8L15", "7L26"],
			lightscreen: ["8M", "7M"],
			meanlook: ["8L50", "7L1"],
			megadrain: ["8L20"],
			megahorn: ["8M", "8L65", "8S2", "7L53", "7S0", "7S1"],
			megapunch: ["8M"],
			naturepower: ["7M"],
			naturesmadness: ["8L55", "7L43", "7S0", "7S1"],
			payback: ["8M", "7M"],
			powerswap: ["8M"],
			protect: ["8M", "7M"],
			psychup: ["7M"],
			reflect: ["8M", "7M"],
			rest: ["8M"],
			return: ["7M"],
			revenge: ["8M"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			rocksmash: ["8L1"],
			rocktomb: ["8M", "7M"],
			rototiller: ["7L38"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scaryface: ["8M", "8L35", "8S2", "7L20"],
			seedbomb: ["8M", "7T"],
			skullbash: ["8L70", "7L58", "7S0", "7S1"],
			sleeptalk: ["8M", "7M"],
			smartstrike: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			stoneedge: ["8M", "7M"],
			storedpower: ["8M"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			superpower: ["8M", "8S2", "7T", "7L1"],
			swagger: ["7M"],
			swordsdance: ["8M", "7M"],
			synthesis: ["7T"],
			taunt: ["8M", "7M"],
			telekinesis: ["7T"],
			torment: ["7M"],
			toxic: ["7M"],
			whirlwind: ["8L25", "7L1"],
			withdraw: ["8L5", "7L1"],
			woodhammer: ["8L60", "8S2", "7L1"],
			workup: ["8M", "7M"],
			worryseed: ["7T"],
			zenheadbutt: ["8M", "8L45", "7T", "7L48", "7S0", "7S1"],
		},
		eventData: [
			{generation: 7, level: 60, moves: ["naturesmadness", "zenheadbutt", "megahorn", "skullbash"]},
			{generation: 7, level: 60, shiny: true, moves: ["naturesmadness", "zenheadbutt", "megahorn", "skullbash"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["superpower", "megahorn", "woodhammer", "scaryface"]},
		],
		eventOnly: true,
	},
	tapufini: {
		learnset: {
			aquaring: ["8L15", "7L53", "7S0", "7S1"],
			blizzard: ["8M", "7M"],
			brine: ["8M", "8L25", "8S2", "7L32"],
			calmmind: ["8M", "7M"],
			confide: ["7M"],
			dazzlinggleam: ["8M", "7M"],
			defog: ["8L30", "7T", "7L38"],
			disarmingvoice: ["8L1"],
			dive: ["8M"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fling: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			gravity: ["7T"],
			guardswap: ["8M"],
			haze: ["8L10", "7L1"],
			healpulse: ["8L35", "7L1"],
			hiddenpower: ["7M"],
			hydropump: ["8M", "8L65", "7L58", "7S0", "7S1"],
			hyperbeam: ["8M", "7M"],
			icebeam: ["8M", "7M"],
			icepunch: ["8M", "7T"],
			icywind: ["8M", "7T"],
			irondefense: ["8M", "7T"],
			knockoff: ["7T"],
			lightscreen: ["8M", "7M"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			meanlook: ["8L50", "7L1"],
			mist: ["8L10", "7L1"],
			mistyterrain: ["8M", "8L75", "7L1"],
			moonblast: ["8L60", "8S2", "7L1"],
			muddywater: ["8M", "8L45", "7L48", "7S0", "7S1"],
			naturepower: ["7M"],
			naturesmadness: ["8L55", "7L43", "7S0", "7S1"],
			playrough: ["8M"],
			protect: ["8M", "7M"],
			psychup: ["7M"],
			raindance: ["8M", "7M"],
			reflect: ["8M", "7M"],
			refresh: ["7L26"],
			rest: ["8M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scald: ["8M", "7M"],
			shadowball: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T"],
			soak: ["8L70", "7L20"],
			storedpower: ["8M"],
			substitute: ["8M", "7M"],
			surf: ["8M", "8L40", "7M"],
			swagger: ["7M"],
			taunt: ["8M", "7M"],
			telekinesis: ["7T"],
			torment: ["7M"],
			toxic: ["7M"],
			trick: ["8M", "7T"],
			waterfall: ["8M", "7M"],
			watergun: ["8L1", "7L1"],
			waterpulse: ["8L20", "8S2", "7T", "7L8"],
			whirlpool: ["8M", "8S2", "7L14"],
			withdraw: ["8L5", "7L1"],
			wonderroom: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 60, moves: ["naturesmadness", "muddywater", "aquaring", "hydropump"]},
			{generation: 7, level: 60, shiny: true, moves: ["naturesmadness", "muddywater", "aquaring", "hydropump"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["whirlpool", "waterpulse", "brine", "moonblast"]},
		],
		eventOnly: true,
	},
	cosmog: {
		learnset: {
			splash: ["8L1", "8S1", "7L1", "7S0"],
			teleport: ["8L1", "8S1", "7L23"],
		},
		eventData: [
			{generation: 7, level: 5, moves: ["splash"]},
			{generation: 8, level: 5, moves: ["splash", "teleport"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	cosmoem: {
		learnset: {
			cosmicpower: ["8M", "8L0", "7L1"],
			teleport: ["8L1", "7L1"],
		},
	},
	solgaleo: {
		learnset: {
			agility: ["8M"],
			bulldoze: ["8M", "7M"],
			calmmind: ["8M", "7M"],
			closecombat: ["8M"],
			confide: ["7M"],
			cosmicpower: ["8M", "8L1", "7L1", "7S0", "7S1"],
			crunch: ["8M", "8L42", "7L37", "7S0", "7S1"],
			doubleteam: ["7M"],
			earthquake: ["8M", "7M"],
			endeavor: ["7T"],
			endure: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			firespin: ["8M", "8S3"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "7M"],
			flareblitz: ["8M", "8L70", "7L61"],
			flashcannon: ["8M", "8L28", "7M", "7L23"],
			focusblast: ["8M", "7M"],
			frustration: ["7M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "8L84", "7M", "7L73"],
			gyroball: ["8M", "7M"],
			heatcrash: ["8M"],
			heavyslam: ["8M"],
			helpinghand: ["8M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M", "7T"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "8L7", "7T", "7L7"],
			irontail: ["8M", "8S3", "7T"],
			knockoff: ["7T"],
			lastresort: ["7T"],
			lightscreen: ["8M", "7M"],
			metalburst: ["8L49", "7L43"],
			metalclaw: ["8L1", "7L1"],
			metalsound: ["8L14", "7L13"],
			meteorbeam: ["8T"],
			morningsun: ["8L35", "7L31", "7S2"],
			mysticalfire: ["8M"],
			nobleroar: ["8L1", "8S3", "7L59", "7S2"],
			outrage: ["8M", "7T"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psychicfangs: ["8M"],
			psychup: ["7M"],
			psyshock: ["8M", "7M"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roar: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scaryface: ["8M"],
			shockwave: ["7T"],
			sleeptalk: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "8L63", "7M", "7L47"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			sunsteelstrike: ["8L0", "7L1", "7S0", "7S1", "7S2"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			swift: ["8M"],
			teleport: ["8L1", "7L1"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "7M"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			trickroom: ["8M", "7M"],
			wakeupslap: ["7L1"],
			wideguard: ["8L77", "7L67"],
			wildcharge: ["8M", "8L56", "7M"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "8L21", "8S3", "7T", "7L19", "7S0", "7S1", "7S2"],
		},
		eventData: [
			{generation: 7, level: 55, moves: ["sunsteelstrike", "cosmicpower", "crunch", "zenheadbutt"]},
			{generation: 7, level: 60, moves: ["sunsteelstrike", "cosmicpower", "crunch", "zenheadbutt"]},
			{generation: 7, level: 60, shiny: true, moves: ["sunsteelstrike", "zenheadbutt", "nobleroar", "morningsun"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["zenheadbutt", "firespin", "irontail", "nobleroar"]},
		],
	},
	lunala: {
		learnset: {
			acrobatics: ["8M", "7M"],
			aerialace: ["7M"],
			agility: ["8M"],
			airslash: ["8M", "8L21", "7L19"],
			blizzard: ["8M", "7M"],
			calmmind: ["8M", "7M"],
			chargebeam: ["7M"],
			confide: ["7M"],
			confuseray: ["8L14", "7L13"],
			confusion: ["8L1", "7L1"],
			cosmicpower: ["8M", "8L1", "7L1", "7S0", "7S1"],
			dazzlinggleam: ["8M", "7M"],
			defog: ["7T"],
			doubleteam: ["7M"],
			dreameater: ["8L70", "7M", "7L59"],
			dualwingbeat: ["8T"],
			endure: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M"],
			fly: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			frustration: ["7M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M"],
			heatwave: ["8M", "7T"],
			helpinghand: ["8M"],
			hex: ["8M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "8L84", "7M", "7L73"],
			hypnosis: ["8L1", "7L1"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "7T"],
			lightscreen: ["8M", "7M"],
			magiccoat: ["8L49", "8S3", "7T", "7L43"],
			magicroom: ["8M", "7T"],
			meteorbeam: ["8T"],
			moonblast: ["8L56", "8S3", "7L47", "7S2"],
			moongeistbeam: ["8L0", "7L1", "7S0", "7S1", "7S2"],
			moonlight: ["8L35", "7L31", "7S2"],
			nightdaze: ["8L42", "7L37", "7S0", "7S1"],
			nightshade: ["8L7", "7L7"],
			phantomforce: ["8M", "8L63", "7L61"],
			poltergeist: ["8T"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psychocut: ["8M"],
			psychup: ["7M"],
			psyshock: ["8M", "7M", "7S2"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roar: ["7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scaryface: ["8M"],
			shadowball: ["8M", "8L28", "8S3", "7M", "7L23", "7S0", "7S1"],
			shadowclaw: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			skyattack: ["7T"],
			skydrop: ["7M"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			spite: ["7T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M", "8S3"],
			tailwind: ["7T"],
			telekinesis: ["7T"],
			teleport: ["8L1", "7L1"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "7M"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			trick: ["8M", "7T"],
			trickroom: ["8M", "7M"],
			wideguard: ["8L77", "7L67"],
			willowisp: ["8M", "7M"],
			wonderroom: ["8M", "7T"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 55, moves: ["moongeistbeam", "cosmicpower", "nightdaze", "shadowball"]},
			{generation: 7, level: 60, moves: ["moongeistbeam", "cosmicpower", "nightdaze", "shadowball"]},
			{generation: 7, level: 60, shiny: true, moves: ["moongeistbeam", "psyshock", "moonblast", "moonlight"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["shadowball", "moonblast", "magiccoat", "swift"]},
		],
	},
	nihilego: {
		learnset: {
			acid: ["8L5", "7L1"],
			acidspray: ["8L15", "8S2", "7L47", "7S0", "7S1"],
			allyswitch: ["8M", "7T"],
			bind: ["7T"],
			bodyslam: ["8M"],
			brutalswing: ["8M", "8S2", "7M"],
			chargebeam: ["7M"],
			clearsmog: ["8L20", "7L7"],
			confide: ["7M"],
			constrict: ["7L1"],
			corrosivegas: ["8T"],
			crosspoison: ["8M"],
			dazzlinggleam: ["8M", "7M"],
			doubleteam: ["7M"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			foulplay: ["8M", "7T"],
			frustration: ["7M"],
			grassknot: ["8M", "7M"],
			guardsplit: ["8L25", "7L1"],
			gunkshot: ["8M", "7T"],
			headbutt: ["8L35", "7L19"],
			headsmash: ["8L70", "7L73"],
			hex: ["8M"],
			hiddenpower: ["7M"],
			ironhead: ["8M", "7T"],
			knockoff: ["7T"],
			lightscreen: ["8M", "7M"],
			magiccoat: ["7T"],
			meteorbeam: ["8T"],
			mirrorcoat: ["8L60", "7L43", "7S0", "7S1"],
			painsplit: ["7T"],
			poisonjab: ["8M", "7M"],
			pound: ["8L1", "7L1"],
			powergem: ["8M", "8L50", "7L37", "7S0", "7S1"],
			powersplit: ["8L25", "7L1"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psyshock: ["8M", "7M"],
			psywave: ["7L13"],
			reflect: ["7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "7L31"],
			sandstorm: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			sludgewave: ["8M", "8S2", "7M"],
			snore: ["8M", "7T"],
			spite: ["7T"],
			stealthrock: ["8M", "8L55", "7T", "7L59", "7S1"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			telekinesis: ["7T"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "7M"],
			thunderwave: ["8M", "7M"],
			tickle: ["8L10", "7L1"],
			toxic: ["7M"],
			toxicspikes: ["8M", "8L40", "7L29"],
			trickroom: ["8M", "7M"],
			venomdrench: ["8M", "8L45", "7L53", "7S0"],
			venoshock: ["8M", "8L30", "7M", "7L23"],
			wonderroom: ["8M", "8L65", "8S2", "7T", "7L67"],
			worryseed: ["7T"],
			wrap: ["8L1"],
			zenheadbutt: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 55, moves: ["powergem", "mirrorcoat", "acidspray", "venomdrench"]},
			{generation: 7, level: 60, shiny: 1, moves: ["powergem", "acidspray", "stealthrock", "mirrorcoat"]},
			{generation: 8, level: 70, shiny: 1, moves: ["wonderroom", "sludgewave", "brutalswing", "acidspray"]},
		],
		eventOnly: true,
	},
	buzzwole: {
		learnset: {
			bodyslam: ["8M"],
			bounce: ["8M", "7T"],
			brickbreak: ["8M", "7M"],
			bugbite: ["7T"],
			bulkup: ["8M", "8L20", "7M", "7L13"],
			bulldoze: ["8M", "7M"],
			closecombat: ["8M"],
			coaching: ["8T"],
			cometpunch: ["7L7"],
			confide: ["7M"],
			counter: ["8L55", "7L43", "7S0", "7S1"],
			darkestlariat: ["8M"],
			doubleteam: ["7M"],
			drainpunch: ["8M", "7T"],
			dualchop: ["7T"],
			dualwingbeat: ["8T"],
			dynamicpunch: ["8L50", "8S2", "7L59", "7S0", "7S1"],
			earthquake: ["8M", "7M"],
			endeavor: ["7T"],
			endure: ["8M", "8L25", "7L23"],
			facade: ["8M", "7M"],
			falseswipe: ["8M", "7M"],
			fellstinger: ["8L10", "7L1"],
			fling: ["8M", "7M"],
			focusenergy: ["8M", "8L45", "7L1"],
			focuspunch: ["8L70", "7T", "7L73"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			gyroball: ["8M", "7M"],
			hammerarm: ["8L60", "7L47", "7S0", "7S1"],
			harden: ["8L1", "7L1"],
			hiddenpower: ["7M"],
			highhorsepower: ["8M"],
			icepunch: ["8M", "7T", "7L1"],
			ironhead: ["8M", "7T"],
			leechlife: ["8M", "8S2", "7M", "7L29"],
			lowsweep: ["8M", "7M"],
			lunge: ["8L40", "7L53", "7S0", "7S1"],
			megapunch: ["8M", "8L35", "7L37"],
			outrage: ["8M", "7T"],
			payback: ["8M", "7M"],
			poisonjab: ["8M", "7M"],
			poweruppunch: ["8L1", "8S2", "7L1"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			reversal: ["8M", "8L30", "7L1"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			smackdown: ["7M"],
			snore: ["8M", "7T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "7M"],
			superpower: ["8M", "8L65", "7T", "7L67"],
			swagger: ["7M"],
			taunt: ["8M", "8L5", "8S2", "7M", "7L31"],
			thunderpunch: ["8M", "7T", "7L1"],
			toxic: ["7M"],
			vitalthrow: ["8L15", "7L19"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 65, moves: ["counter", "hammerarm", "lunge", "dynamicpunch"]},
			{generation: 7, level: 60, shiny: 1, moves: ["counter", "hammerarm", "lunge", "dynamicpunch"]},
			{generation: 8, level: 70, shiny: 1, moves: ["poweruppunch", "taunt", "leechlife", "dynamicpunch"]},
		],
		eventOnly: true,
	},
	pheromosa: {
		learnset: {
			agility: ["8M", "8L40", "7L37"],
			assurance: ["8M"],
			blizzard: ["8M", "7M"],
			block: ["7T"],
			bounce: ["8M", "8L50", "7T", "7L29"],
			brickbreak: ["8M", "7M"],
			bugbite: ["8L15", "7T"],
			bugbuzz: ["8M", "8L60", "7L53", "7S0", "7S1"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M"],
			doublekick: ["8L25", "7L1"],
			doubleteam: ["7M"],
			drillrun: ["8M", "7T"],
			echoedvoice: ["7M"],
			electroweb: ["8M", "7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			falseswipe: ["8M", "7M"],
			feint: ["8L1", "7L19"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			foulplay: ["8M", "7T"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			hiddenpower: ["7M"],
			highjumpkick: ["8L70", "8S2", "7L67"],
			hyperbeam: ["8M", "7M"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "7T"],
			jumpkick: ["7L31"],
			laserfocus: ["7T"],
			leer: ["8L5", "7L1"],
			lowkick: ["8M", "8L20", "7T", "7L1"],
			lowsweep: ["8M", "7M"],
			lunge: ["8L45", "8S2", "7L47", "7S0", "7S1"],
			mefirst: ["7L59", "7S0", "7S1"],
			outrage: ["8M", "7T"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			quickguard: ["8L10", "7L1"],
			quiverdance: ["8L65", "7L1"],
			rapidspin: ["8L1", "7L1"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roost: ["7M"],
			round: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			silverwind: ["7L23"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			speedswap: ["8M", "8L55", "7L73"],
			stomp: ["8L35", "7L13"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M", "8S2", "7L7"],
			taunt: ["8M", "7M"],
			throatchop: ["8M", "8S2", "7T"],
			torment: ["7M"],
			toxic: ["7M"],
			tripleaxel: ["8T"],
			triplekick: ["8L30", "7L43", "7S0", "7S1"],
			uturn: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 60, moves: ["triplekick", "lunge", "bugbuzz", "mefirst"]},
			{generation: 7, level: 60, shiny: 1, moves: ["triplekick", "lunge", "bugbuzz", "mefirst"]},
			{generation: 8, level: 70, shiny: 1, moves: ["highjumpkick", "swift", "throatchop", "lunge"]},
		],
		eventOnly: true,
	},
	xurkitree: {
		learnset: {
			bind: ["7T"],
			brutalswing: ["8M", "8S2", "7M"],
			calmmind: ["8M", "7M"],
			charge: ["8L5", "7L1"],
			chargebeam: ["7M"],
			confide: ["7M"],
			dazzlinggleam: ["8M", "7M"],
			discharge: ["8L45", "8S2", "7L47", "7S0", "7S1"],
			doubleteam: ["7M"],
			eerieimpulse: ["8M", "8L35", "8S2", "7L29"],
			electricterrain: ["8M", "8L60", "7L53", "7S0", "7S1"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			facade: ["8M", "7M"],
			fling: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			gravity: ["7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			hypnosis: ["8L30", "7L43", "7S0", "7S1"],
			ingrain: ["8L15", "7L19"],
			iondeluge: ["7L67"],
			lightscreen: ["8M", "7M"],
			magiccoat: ["7T"],
			magnetrise: ["8L50", "7T"],
			naturepower: ["7M"],
			powerwhip: ["8M", "8L65", "8S2", "7L59", "7S0", "7S1"],
			protect: ["8M", "7M"],
			raindance: ["8M", "7M"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			shockwave: ["8L25", "7T", "7L13"],
			signalbeam: ["7T", "7L31"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			spark: ["8L20", "7L1"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			tailglow: ["7L1"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "8L55", "7M", "7L37"],
			thunderpunch: ["8M", "8L40", "7T", "7L23"],
			thundershock: ["8L1", "7L1"],
			thunderwave: ["8M", "8L10", "7M", "7L7"],
			toxic: ["7M"],
			voltswitch: ["8M", "7M"],
			wildcharge: ["8M", "7M"],
			wrap: ["8L1", "7L1"],
			zapcannon: ["8L70", "7L73"],
		},
		eventData: [
			{generation: 7, level: 65, moves: ["hypnosis", "discharge", "electricterrain", "powerwhip"]},
			{generation: 7, level: 60, shiny: 1, moves: ["hypnosis", "discharge", "electricterrain", "powerwhip"]},
			{generation: 8, level: 70, shiny: 1, moves: ["powerwhip", "discharge", "eerieimpulse", "brutalswing"]},
		],
		eventOnly: true,
	},
	celesteela: {
		learnset: {
			absorb: ["8L1", "7L1"],
			acrobatics: ["8M", "7M"],
			airslash: ["8M", "7L1"],
			autotomize: ["8L30", "7L43", "7S0", "7S1"],
			block: ["7T"],
			bodyslam: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			confide: ["7M"],
			doubleedge: ["8L65", "7L73"],
			doubleteam: ["7M"],
			earthquake: ["8M", "8S2", "7M"],
			endure: ["8M"],
			energyball: ["8M", "7M"],
			explosion: ["7M"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "7M"],
			flashcannon: ["8M", "8L40", "7M", "7L37"],
			fly: ["8M", "7M"],
			frustration: ["7M"],
			gigadrain: ["8M", "8L35", "7T", "7L31"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			gravity: ["7T"],
			gyroball: ["8M", "8S2", "7M"],
			harden: ["8L5", "7L1"],
			heavyslam: ["8M", "8L60", "7L67"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			ingrain: ["8L25", "7L1"],
			irondefense: ["8M", "8L50", "7T", "7L59", "7S0", "7S1"],
			ironhead: ["8M", "7T", "7L29"],
			leechseed: ["8L55", "8S2", "7L19"],
			magnetrise: ["7T"],
			megadrain: ["8L15", "7L13"],
			megahorn: ["8M"],
			metalsound: ["8L45", "7L23"],
			meteorbeam: ["8T"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockslide: ["8M", "7M"],
			round: ["8M", "7M"],
			seedbomb: ["8M", "7T", "7L47", "7S0", "7S1"],
			selfdestruct: ["8M"],
			shockwave: ["7T"],
			skullbash: ["8L70", "7L53", "7S0", "7S1"],
			sleeptalk: ["8M", "7M"],
			smackdown: ["8L20", "8S2", "7M", "7L7"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "7M"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			tackle: ["8L1", "7L1"],
			toxic: ["7M"],
			wideguard: ["8L10", "7L1"],
			zenheadbutt: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 65, moves: ["autotomize", "seedbomb", "skullbash", "irondefense"]},
			{generation: 7, level: 60, shiny: 1, moves: ["autotomize", "seedbomb", "skullbash", "irondefense"]},
			{generation: 8, level: 70, shiny: 1, moves: ["leechseed", "smackdown", "gyroball", "earthquake"]},
		],
		eventOnly: true,
	},
	kartana: {
		learnset: {
			aerialace: ["8L25", "7M", "7L23"],
			aircutter: ["8L20", "8S2", "7L1"],
			airslash: ["8M", "7L59", "7S0", "7S1"],
			brickbreak: ["8M", "7M"],
			calmmind: ["8M", "7M"],
			confide: ["7M"],
			cut: ["8L15", "7L1"],
			defog: ["8L50", "7T", "7L1"],
			detect: ["8L30", "7L53", "7S0", "7S1"],
			doubleteam: ["7M"],
			endure: ["8M"],
			falseswipe: ["8M", "8L10", "7M", "7L7"],
			frustration: ["7M"],
			furycutter: ["8L1", "7L1"],
			gigadrain: ["8M", "7T"],
			gigaimpact: ["8M", "7M"],
			guillotine: ["8L70", "7L73"],
			hiddenpower: ["7M"],
			irondefense: ["8M", "7T"],
			knockoff: ["7T"],
			laserfocus: ["8L45", "7T", "7L29"],
			lastresort: ["7T"],
			leafblade: ["8M", "8L55", "8S2", "7L43", "7S0", "7S1"],
			nightslash: ["8L35", "7L31"],
			protect: ["8M", "7M"],
			psychocut: ["8M", "7L67"],
			razorleaf: ["8L5", "7L13"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			sacredsword: ["8L60", "7L1"],
			screech: ["8M"],
			sleeptalk: ["8M", "7M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarblade: ["8M"],
			steelbeam: ["8T"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			swordsdance: ["8M", "8L65", "8S2", "7M", "7L37"],
			synthesis: ["8L40", "7T", "7L19"],
			tailwind: ["7T"],
			toxic: ["7M"],
			vacuumwave: ["8L1", "8S2", "7L1"],
			xscissor: ["8M", "7M", "7L47", "7S0", "7S1"],
		},
		eventData: [
			{generation: 7, level: 60, moves: ["leafblade", "xscissor", "detect", "airslash"]},
			{generation: 7, level: 60, shiny: 1, moves: ["leafblade", "xscissor", "detect", "airslash"]},
			{generation: 8, level: 70, shiny: 1, moves: ["vacuumwave", "aircutter", "leafblade", "swordsdance"]},
		],
		eventOnly: true,
	},
	guzzlord: {
		learnset: {
			amnesia: ["8M"],
			belch: ["8L60", "7L1"],
			bite: ["8L1", "7L1"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L35"],
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "8S2", "7M", "7L13"],
			bulldoze: ["8M", "7M"],
			corrosivegas: ["8T"],
			crunch: ["8M", "8L30", "7L37"],
			darkpulse: ["8M", "7M"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T"],
			dragonclaw: ["8M", "7M"],
			dragonpulse: ["8M", "7T"],
			dragonrage: ["7L1"],
			dragonrush: ["8L55", "8S2", "7L73"],
			dragontail: ["8L1", "7M", "7L23"],
			drainpunch: ["8M", "7T"],
			dualchop: ["7T"],
			earthquake: ["8M", "7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			flamethrower: ["8M", "7M"],
			fling: ["8M", "7M"],
			frustration: ["7M"],
			gastroacid: ["8L40", "7T", "7L53", "7S0", "7S1"],
			gigaimpact: ["8M", "8L70", "7M"],
			gyroball: ["8M", "7M"],
			hammerarm: ["8L45", "7L43", "7S1"],
			heatcrash: ["8M"],
			heatwave: ["8M", "7T"],
			heavyslam: ["8M", "8L50", "7L59", "7S0", "7S1"],
			hiddenpower: ["7M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T", "7L29"],
			knockoff: ["8L10", "7T"],
			lashout: ["8T"],
			lastresort: ["7T"],
			magnetrise: ["7T"],
			megakick: ["8M"],
			megapunch: ["8M", "8S2"],
			outrage: ["8M", "7T"],
			payback: ["8M", "7M"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			shadowclaw: ["8M", "7M"],
			shockwave: ["7T"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			sludgewave: ["8M", "7M"],
			smackdown: ["7M"],
			snarl: ["8M", "7M"],
			snore: ["8M", "7T"],
			steamroller: ["7L19"],
			steelroller: ["8T"],
			stockpile: ["8L5", "7L1"],
			stomp: ["8L15", "7L7"],
			stompingtantrum: ["8M", "8L20", "8S2", "7T", "7L31"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "7M"],
			swallow: ["8L5", "7L1"],
			thief: ["8M", "7M"],
			thrash: ["8L65", "7L47", "7S0", "7S1"],
			toxic: ["7M"],
			wideguard: ["8L25", "7L1"],
			wringout: ["7L67", "7S0"],
		},
		eventData: [
			{generation: 7, level: 70, moves: ["thrash", "gastroacid", "heavyslam", "wringout"]},
			{generation: 7, level: 60, shiny: 1, moves: ["hammerarm", "thrash", "gastroacid", "heavyslam"]},
			{generation: 8, level: 70, shiny: 1, moves: ["dragonrush", "stompingtantrum", "brutalswing", "megapunch"]},
		],
		eventOnly: true,
	},
	necrozma: {
		learnset: {
			aerialace: ["7M"],
			allyswitch: ["8M", "7T"],
			autotomize: ["8L80", "8S3", "7L47"],
			breakingswipe: ["8M"],
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			calmmind: ["8M", "7M"],
			chargebeam: ["8L1", "8S3", "7M", "7L1"],
			confide: ["7M"],
			confusion: ["8L1", "7L1"],
			cosmicpower: ["8M"],
			darkpulse: ["8M", "7M"],
			doubleteam: ["7M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T"],
			earthpower: ["8M", "7T"],
			earthquake: ["8M", "7M"],
			embargo: ["7M"],
			endure: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M", "7M"],
			flashcannon: ["8M", "7M"],
			fling: ["8M", "7M"],
			frustration: ["7M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M"],
			gravity: ["8L1", "7T", "7L31"],
			gyroball: ["8M", "7M"],
			heatwave: ["8M", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M", "7T"],
			imprison: ["8M"],
			irondefense: ["8M", "8L56", "7T", "7L59", "7S0", "7S1"],
			ironhead: ["8M", "7T"],
			knockoff: ["7T"],
			lightscreen: ["8M", "7M", "7S2"],
			magnetrise: ["7T"],
			metalclaw: ["8L1", "7L1"],
			meteorbeam: ["8T"],
			mirrorshot: ["7L1"],
			moonlight: ["8L1", "7L1", "7S2"],
			morningsun: ["8L1", "7L1"],
			nightslash: ["8L24", "7L23", "7S1"],
			outrage: ["8M", "7T"],
			photongeyser: ["8L72", "7L50", "7S1"],
			powergem: ["8M", "8L64", "8S3", "7L43", "7S1"],
			prismaticlaser: ["8L88", "7L73", "7S0"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psychicfangs: ["8M"],
			psychocut: ["8M", "8L32", "8S3", "7L37"],
			psyshock: ["8M", "7M"],
			recycle: ["7T"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockblast: ["8M", "8L48", "7L19"],
			rockpolish: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			scaryface: ["8M"],
			shadowclaw: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			slash: ["8L16", "7L7"],
			sleeptalk: ["8M", "7M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			stealthrock: ["8M", "8L8", "7T", "7L53", "7S0"],
			stoneedge: ["8M", "7M"],
			storedpower: ["8M", "8L40", "7L13"],
			substitute: ["8M", "7M", "7S2"],
			swagger: ["7M"],
			swordsdance: ["8M", "7M"],
			telekinesis: ["7T"],
			thief: ["8M", "7M"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			trickroom: ["8M", "7M"],
			wringout: ["7L67", "7S0"],
			xscissor: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 75, moves: ["stealthrock", "irondefense", "wringout", "prismaticlaser"]},
			{generation: 7, level: 65, moves: ["photongeyser", "irondefense", "powergem", "nightslash"]},
			{generation: 7, level: 75, shiny: true, moves: ["lightscreen", "substitute", "moonlight"], pokeball: "cherishball"},
			{generation: 8, level: 70, shiny: 1, moves: ["psychocut", "chargebeam", "powergem", "autotomize"]},
		],
		eventOnly: true,
	},
	necrozmaduskmane: {
		learnset: {
			sunsteelstrike: ["8R", "7R"],
		},
		eventOnly: true,
	},
	necrozmadawnwings: {
		learnset: {
			moongeistbeam: ["8R", "7R"],
		},
		eventOnly: true,
	},
	necrozmaultra: {
		learnset: {
			moongeistbeam: ["8R", "7R"],
			sunsteelstrike: ["8R", "7R"],
		},
	},
	magearna: {
		learnset: {
			afteryou: ["7T"],
			agility: ["9M", "8M"],
			aurasphere: ["9M", "9L66", "8M", "8L66", "7L81"],
			aurorabeam: ["9L36", "8L36", "7L17"],
			batonpass: ["9M", "8M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M", "7M"],
			calmmind: ["9M", "8M", "7M"],
			chargebeam: ["9M", "7M"],
			confide: ["7M"],
			confuseray: ["9M"],
			craftyshield: ["8L54", "7L1"],
			dazzlinggleam: ["9M", "8M", "7M"],
			defensecurl: ["9L6", "8L6", "7L1"],
			disarmingvoice: ["9M"],
			doubleteam: ["7M"],
			drainingkiss: ["9M", "8M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["9M", "8M"],
			electroball: ["9M", "8M"],
			electroweb: ["8M"],
			embargo: ["7M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M", "7M"],
			explosion: ["7M"],
			facade: ["9M"],
			falseswipe: ["9M", "8M", "7M"],
			flashcannon: ["9M", "9L72", "8M", "8L72", "7M", "7L41", "7S0"],
			fleurcannon: ["9L90", "8L90", "7L49", "7S0"],
			focusblast: ["9M", "8M", "7M"],
			frustration: ["7M"],
			gearup: ["8L24", "7L1"],
			gigaimpact: ["9M", "8M", "7M"],
			grassknot: ["9M", "8M", "7M"],
			guardswap: ["8M"],
			gyroball: ["9L1", "8M", "8L1", "7M"],
			healbell: ["7T"],
			heartswap: ["7L89"],
			heavyslam: ["9M"],
			helpinghand: ["9M", "9L1", "8M", "8L1", "7T", "7L1", "7S0"],
			hiddenpower: ["7M"],
			hyperbeam: ["9M", "8M", "7M"],
			icebeam: ["9M", "8M", "7M"],
			icespinner: ["9M"],
			imprison: ["9M", "8M"],
			irondefense: ["9M", "9L18", "8M", "8L18", "7T", "7L57"],
			ironhead: ["9M", "9L60", "8M", "8L60", "7T", "7L1"],
			lastresort: ["7T"],
			lightscreen: ["9M", "8M", "7M"],
			lockon: ["9L42"],
			luckychant: ["7L9", "7S0"],
			magneticflux: ["9L24"],
			magnetrise: ["7T"],
			mindreader: ["8L42", "7L33"],
			mirrorshot: ["7L25"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M"],
			painsplit: ["9L78", "8L78", "7T", "7L65"],
			playrough: ["9M"],
			powerswap: ["8M"],
			protect: ["9M", "8M", "7M"],
			psybeam: ["9M", "9L30", "8L30", "7L1"],
			psychic: ["9M"],
			psyshock: ["9M"],
			reflect: ["9M", "8M", "7M"],
			rest: ["9M", "8M"],
			return: ["7M"],
			rollout: ["9L12", "8L12"],
			round: ["8M", "7M"],
			selfdestruct: ["8M"],
			shadowball: ["9M", "8M", "7M"],
			shiftgear: ["9L48", "8L48", "7L1"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			snore: ["8M", "7T"],
			snowscape: ["9M"],
			solarbeam: ["9M", "8M", "7M"],
			sonicboom: ["7L1"],
			speedswap: ["8M"],
			spikes: ["9M"],
			steelbeam: ["9M", "8T"],
			steelroller: ["8T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M", "7M"],
			sunnyday: ["9M"],
			swagger: ["7M"],
			swift: ["9M"],
			synchronoise: ["7L73"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderbolt: ["9M", "8M", "7M"],
			thunderwave: ["9M", "8M", "7M"],
			triattack: ["8M"],
			trick: ["9M", "9L54", "8M"],
			trickroom: ["9M", "8M", "7M"],
			trumpcard: ["7L97"],
			voltswitch: ["9M", "8M", "7M"],
			zapcannon: ["9L84", "8L84"],
			zenheadbutt: ["9M", "8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 50, moves: ["fleurcannon", "flashcannon", "luckychant", "helpinghand"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	magearnaoriginal: {
		learnset: {
			agility: ["9M", "8M"],
			aurasphere: ["9M", "9L66", "8M", "8L66"],
			aurorabeam: ["9L36", "8L36"],
			batonpass: ["9M", "8M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			chargebeam: ["9M"],
			confuseray: ["9M"],
			craftyshield: ["8L54"],
			dazzlinggleam: ["9M", "8M"],
			defensecurl: ["9L6", "8L6", "8S0"],
			disarmingvoice: ["9M"],
			drainingkiss: ["9M", "8M"],
			eerieimpulse: ["9M", "8M"],
			electroball: ["9M", "8M"],
			electroweb: ["8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M"],
			facade: ["9M"],
			falseswipe: ["9M", "8M"],
			flashcannon: ["9M", "9L72", "8M", "8L72", "8S0"],
			fleurcannon: ["9L90", "8L90", "8S0"],
			focusblast: ["9M", "8M"],
			gearup: ["8L24"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			guardswap: ["8M"],
			gyroball: ["9L1", "8M", "8L1"],
			heavyslam: ["9M"],
			helpinghand: ["9M", "9L1", "8M", "8L1"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			icespinner: ["9M"],
			imprison: ["9M", "8M"],
			irondefense: ["9M", "9L18", "8M", "8L18"],
			ironhead: ["9M", "9L60", "8M", "8L60"],
			lightscreen: ["9M", "8M"],
			lockon: ["9L42"],
			magneticflux: ["9L24"],
			mindreader: ["8L42"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M"],
			painsplit: ["9L78", "8L78"],
			playrough: ["9M"],
			powerswap: ["8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M", "9L30", "8L30"],
			psychic: ["9M"],
			psyshock: ["9M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M", "8S0"],
			rollout: ["9L12", "8L12"],
			round: ["8M"],
			selfdestruct: ["8M"],
			shadowball: ["9M", "8M"],
			shiftgear: ["9L48", "8L48"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			snore: ["8M"],
			snowscape: ["9M"],
			solarbeam: ["9M", "8M"],
			speedswap: ["8M"],
			spikes: ["9M"],
			steelbeam: ["9M", "8T"],
			steelroller: ["8T"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderbolt: ["9M", "8M"],
			thunderwave: ["9M", "8M"],
			triattack: ["8M"],
			trick: ["9M", "9L54", "8M"],
			trickroom: ["9M", "8M"],
			voltswitch: ["9M", "8M"],
			zapcannon: ["9L84", "8L84"],
			zenheadbutt: ["9M", "8M"],
		},
		eventData: [
			{generation: 8, level: 50, nature: "Mild", ivs: {hp: 31, atk: 30, def: 30, spa: 31, spd: 31, spe: 0}, moves: ["fleurcannon", "flashcannon", "defensecurl", "rest"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	marshadow: {
		learnset: {
			acrobatics: ["8M", "7M"],
			agility: ["8M"],
			assurance: ["8M", "8L36", "7L1"],
			aurasphere: ["8M"],
			blazekick: ["8M"],
			bounce: ["8M", "7T"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			calmmind: ["8M", "7M"],
			closecombat: ["8M", "8L99", "7L50", "7S0"],
			coaching: ["8T"],
			confide: ["7M"],
			copycat: ["8L1", "7L20"],
			counter: ["8L1", "7L1"],
			doubleteam: ["7M"],
			drainpunch: ["8M", "8L1", "8S1", "7T", "7L1"],
			echoedvoice: ["7M"],
			endeavor: ["8L90", "7T", "7L60"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			falseswipe: ["8M", "7M"],
			feint: ["8L1", "7L11"],
			firepunch: ["8M", "8L1", "7T", "7L1"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["7T"],
			forcepalm: ["8L27", "8S1", "7L5", "7S0"],
			foulplay: ["8M", "7T"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			hex: ["8M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M"],
			icepunch: ["8M", "8L1", "7T", "7L1"],
			ironhead: ["8M", "7T"],
			jumpkick: ["7L35"],
			knockoff: ["7T"],
			laserfocus: ["8L81", "7T", "7L1"],
			lastresort: ["7T"],
			lowkick: ["8M", "7T"],
			lowsweep: ["8M", "7M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			outrage: ["8M", "7T"],
			payback: ["8M", "7M"],
			phantomforce: ["8M"],
			poisonjab: ["8M", "7M"],
			poltergeist: ["8T"],
			protect: ["8M", "7M"],
			psychup: ["8L63", "7M", "7L41"],
			pursuit: ["7L1"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			reversal: ["8M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			roleplay: ["8L9", "7T", "7L30"],
			rollingkick: ["7L15"],
			round: ["8M", "7M"],
			shadowball: ["8M", "7M", "7S0"],
			shadowclaw: ["8M", "7M"],
			shadowpunch: ["8L18", "7L26"],
			shadowsneak: ["8L1", "8S1", "7L1"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			smackdown: ["7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			spectralthief: ["8L72", "8S1", "7L45", "7S0"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "7M"],
			suckerpunch: ["8L45", "7L56"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			swift: ["8M"],
			thief: ["8M", "7M"],
			throatchop: ["8M", "7T"],
			thunderpunch: ["8M", "8L1", "7T", "7L1"],
			toxic: ["7M"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
			zenheadbutt: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 50, moves: ["spectralthief", "closecombat", "forcepalm", "shadowball"], pokeball: "cherishball"},
			{generation: 8, level: 60, moves: ["spectralthief", "drainpunch", "forcepalm", "shadowsneak"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	poipole: {
		learnset: {
			acid: ["8L1", "8S2", "7L1"],
			charm: ["8M", "8L21", "7L19", "7S0"],
			confide: ["7M"],
			covet: ["7T"],
			dragonpulse: ["8M", "8L1", "7T", "7L1", "7S1"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fellstinger: ["8L14", "8S2", "7L47"],
			frustration: ["7M"],
			furyattack: ["8L7", "8S2", "7L7"],
			gastroacid: ["8L56", "7T"],
			growl: ["8L1", "7L1"],
			gunkshot: ["8M", "7T"],
			helpinghand: ["8M", "8L1", "8S2", "7T", "7L1"],
			hiddenpower: ["7M"],
			irontail: ["8M", "7T"],
			nastyplot: ["8M", "8L42", "7L31", "7S0", "7S1"],
			peck: ["8L1", "7L1"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "8L49", "7M", "7L37", "7S0", "7S1"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			signalbeam: ["7T"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			sludgewave: ["8M", "7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M"],
			toxic: ["8L63", "7M", "7L41"],
			toxicspikes: ["8M"],
			uproar: ["8M", "7T"],
			venomdrench: ["8M", "8L35", "7L23", "7S0", "7S1"],
			venoshock: ["8M", "8L28", "7M", "7L13"],
		},
		eventData: [
			{generation: 7, level: 40, shiny: 1, perfectIVs: 3, moves: ["charm", "venomdrench", "nastyplot", "poisonjab"], pokeball: "pokeball"},
			{generation: 7, level: 40, shiny: true, nature: "Modest", perfectIVs: 3, moves: ["venomdrench", "nastyplot", "poisonjab", "dragonpulse"], pokeball: "cherishball"},
			{generation: 8, level: 20, moves: ["helpinghand", "acid", "furyattack", "fellstinger"], pokeball: "beastball"},
		],
		eventOnly: true,
	},
	naganadel: {
		learnset: {
			acid: ["8L1", "7L1"],
			acrobatics: ["8M", "7M"],
			aerialace: ["7M"],
			aircutter: ["8L0", "7L1"],
			airslash: ["8M", "8L1", "7L53"],
			allyswitch: ["8M", "7T"],
			assurance: ["8M"],
			breakingswipe: ["8M"],
			charm: ["8M", "8L21", "7L19"],
			confide: ["7M"],
			crosspoison: ["8M"],
			darkpulse: ["8M", "7M"],
			doubleteam: ["7M"],
			dracometeor: ["8T", "7T"],
			dragonclaw: ["8M", "7M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "8L1", "7T", "7L1"],
			dragonrush: ["8L70"],
			dragontail: ["7M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fellstinger: ["8L14", "7L47"],
			fireblast: ["8M", "7M"],
			flamethrower: ["8M", "7M"],
			fly: ["8M", "7M"],
			frustration: ["7M"],
			furyattack: ["8L7", "7L7"],
			gastroacid: ["8L56", "7T"],
			gigaimpact: ["8M"],
			growl: ["8L1", "7L1"],
			gunkshot: ["8M", "7T"],
			heatwave: ["8M", "7T"],
			helpinghand: ["8M", "8L1", "7T", "7L1"],
			hex: ["8M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			irontail: ["8M", "7T"],
			laserfocus: ["7T"],
			leechlife: ["8M", "7M"],
			nastyplot: ["8M", "8L42", "7L31"],
			outrage: ["8M", "7T"],
			peck: ["8L1", "7L1"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "8L49", "7M", "7L37"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			scaleshot: ["8T"],
			shadowclaw: ["8M", "7M"],
			shockwave: ["7T"],
			signalbeam: ["7T"],
			skyattack: ["7T"],
			skydrop: ["7M"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			sludgewave: ["8M", "7M"],
			smartstrike: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			spikes: ["8M"],
			substitute: ["8M", "7M"],
			swift: ["8M"],
			tailwind: ["7T"],
			thief: ["8M", "7M"],
			throatchop: ["8M", "7T"],
			thunderbolt: ["8M", "7M"],
			toxic: ["8L63", "7M", "7L41"],
			toxicspikes: ["8M"],
			uproar: ["8M", "7T"],
			uturn: ["8M", "7M"],
			venomdrench: ["8M", "8L35", "7L23"],
			venoshock: ["8M", "8L28", "7M", "7L13"],
			xscissor: ["8M", "7M"],
		},
	},
	stakataka: {
		learnset: {
			allyswitch: ["8M", "7T"],
			autotomize: ["8L35", "8S1", "7L31"],
			bide: ["7L17"],
			bind: ["7T"],
			block: ["8L20", "7T"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brutalswing: ["8M", "8S1", "7M"],
			bulldoze: ["8M", "7M"],
			doubleedge: ["8L70", "8S1", "7L61"],
			earthquake: ["8M", "7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			flashcannon: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			gravity: ["7T"],
			gyroball: ["8M", "7M"],
			harden: ["8L1"],
			heatcrash: ["8M"],
			heavyslam: ["8M"],
			hiddenpower: ["7M"],
			highhorsepower: ["8M"],
			infestation: ["7M"],
			irondefense: ["8M", "8L50", "7T", "7L37", "7S0"],
			ironhead: ["8M", "8L55", "7T", "7L43", "7S0"],
			lightscreen: ["8M", "7M"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			magnetrise: ["8L45", "7T"],
			megakick: ["8M"],
			meteorbeam: ["8T"],
			protect: ["8M", "8L10", "7M", "7L1"],
			recycle: ["7T"],
			reflect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockblast: ["8M", "8L40", "7L47", "7S0"],
			rockpolish: ["7M"],
			rockslide: ["8M", "8L25", "8S1", "7M", "7L5"],
			rockthrow: ["8L5", "7L23"],
			rocktomb: ["8M", "7M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			skillswap: ["8M", "7T"],
			sleeptalk: ["8M", "7M"],
			smackdown: ["7M"],
			snore: ["8M", "7T"],
			stealthrock: ["8M", "8L65", "7T", "7L11"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stomp: ["8L15"],
			stompingtantrum: ["8M", "7T"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "7M"],
			superpower: ["8M", "7T"],
			tackle: ["8L1", "7L1"],
			takedown: ["8L60", "7L19"],
			telekinesis: ["7T"],
			toxic: ["7M"],
			trickroom: ["8M", "7M"],
			wideguard: ["8L30", "7L53", "7S0"],
			wonderroom: ["8M", "7T"],
			zenheadbutt: ["8M", "7T"],
		},
		eventData: [
			{generation: 7, level: 60, shiny: 1, moves: ["irondefense", "ironhead", "rockblast", "wideguard"]},
			{generation: 8, level: 70, shiny: 1, moves: ["rockslide", "doubleedge", "brutalswing", "autotomize"]},
		],
		eventOnly: true,
	},
	blacephalon: {
		learnset: {
			afteryou: ["7T"],
			astonish: ["8L1", "7L1"],
			calmmind: ["8M", "8L50", "7M", "7L31"],
			confide: ["7M"],
			confuseray: ["8L20"],
			darkpulse: ["8M", "7M"],
			doubleteam: ["7M"],
			ember: ["8L10", "7L1"],
			encore: ["8M"],
			endure: ["8M"],
			expandingforce: ["8T"],
			explosion: ["7M"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "8L65", "8S1", "7M", "7L37", "7S0"],
			firepunch: ["8M"],
			firespin: ["8M", "8L1"],
			flameburst: ["7L17"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "7M"],
			fling: ["8M", "7M"],
			foulplay: ["8M", "7T"],
			frustration: ["7M"],
			heatwave: ["8M", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			hypnosis: ["8L35"],
			incinerate: ["8L30"],
			knockoff: ["7T"],
			lastresort: ["7T"],
			lightscreen: ["8M", "8L5", "7M", "7L29"],
			magiccoat: ["8L25", "7L7"],
			mindblown: ["8L70", "7L59", "7S0"],
			mysticalfire: ["8M", "8L40"],
			nightshade: ["8L15", "7L23"],
			overheat: ["8M", "7M"],
			painsplit: ["7T"],
			payback: ["8M", "7M"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psyshock: ["8M", "7M"],
			quash: ["7M"],
			recycle: ["7T"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockblast: ["8M"],
			round: ["8M", "7M"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "8L45", "7M", "7L41", "7S0"],
			shadowclaw: ["8M", "8S1", "7M"],
			sleeptalk: ["8M", "7M"],
			smackdown: ["7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M"],
			spite: ["7T"],
			storedpower: ["8M", "7L13"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			taunt: ["8M", "8S1", "7M"],
			thief: ["8M", "7M"],
			torment: ["7M"],
			toxic: ["7M"],
			trick: ["8M", "8L60", "7T", "7L47", "7S0"],
			uproar: ["8M", "7T"],
			willowisp: ["8M", "8L55", "7M"],
			zenheadbutt: ["8M", "8S1"],
		},
		eventData: [
			{generation: 7, level: 60, shiny: 1, moves: ["fireblast", "shadowball", "trick", "mindblown"]},
			{generation: 8, level: 70, shiny: 1, moves: ["shadowclaw", "taunt", "fireblast", "zenheadbutt"]},
		],
		eventOnly: true,
	},
	zeraora: {
		learnset: {
			acrobatics: ["8M", "7M"],
			aerialace: ["7M"],
			agility: ["8M", "8L80"],
			assurance: ["8M"],
			aurasphere: ["8M"],
			blazekick: ["8M", "8S1"],
			bounce: ["8M", "7T"],
			brickbreak: ["8M", "7M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			calmmind: ["8M", "7M"],
			charge: ["8L40", "7L26"],
			closecombat: ["8M", "8L96", "8S1", "7L47", "7S0"],
			coaching: ["8T"],
			confide: ["7M"],
			discharge: ["8L64", "7L50"],
			doubleteam: ["7M"],
			drainpunch: ["8M", "7T"],
			dualchop: ["7T"],
			echoedvoice: ["7M"],
			electricterrain: ["8M"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			endeavor: ["7T"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fakeout: ["8L1", "7L22"],
			falseswipe: ["8M", "7M"],
			firepunch: ["8M", "7T"],
			fling: ["8M", "7M"],
			focusblast: ["8M", "7M"],
			focuspunch: ["7T"],
			frustration: ["7M"],
			furyswipes: ["8L8", "7L12"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			helpinghand: ["8M"],
			hiddenpower: ["7M"],
			honeclaws: ["8L56", "7L5"],
			hyperbeam: ["8M"],
			irontail: ["8M", "7T"],
			knockoff: ["7T"],
			laserfocus: ["7T"],
			lowkick: ["8M", "7T"],
			lowsweep: ["8M", "7M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			outrage: ["8M", "8S1", "7T"],
			payday: ["8M"],
			plasmafists: ["8L88", "8S1", "7L43", "7S0"],
			playrough: ["8M"],
			poweruppunch: ["8L1"],
			protect: ["8M", "7M"],
			quickattack: ["8L1", "7L8"],
			quickguard: ["8L16", "7L40"],
			rest: ["8M", "7M"],
			return: ["7M"],
			revenge: ["8M"],
			reversal: ["8M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			scaryface: ["8M"],
			scratch: ["8L1", "7L1"],
			shockwave: ["7T"],
			slash: ["8L24", "7L33"],
			sleeptalk: ["8M", "7M"],
			snarl: ["8M", "8L1", "7M", "7L19"],
			snatch: ["7T"],
			snore: ["8M", "7T"],
			spark: ["8L1", "7L1"],
			substitute: ["8M", "7M"],
			superpower: ["8M", "7T"],
			swift: ["8M"],
			taunt: ["8M", "7M"],
			throatchop: ["8M", "7T"],
			thunder: ["8M", "7M", "7S0"],
			thunderbolt: ["8M", "7M"],
			thunderpunch: ["8M", "8L48", "7T", "7L29", "7S0"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			voltswitch: ["8M", "8L32", "7M", "7L15"],
			wildcharge: ["8M", "8L72", "7M", "7L36"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 50, moves: ["plasmafists", "thunderpunch", "closecombat", "thunder"], pokeball: "cherishball"},
			{generation: 8, level: 100, shiny: true, nature: "Hasty", ivs: {hp: 31, atk: 31, def: 30, spa: 31, spd: 31, spe: 31}, moves: ["plasmafists", "closecombat", "blazekick", "outrage"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	meltan: {
		learnset: {
			acidarmor: ["8L32", "8V", "7L36"],
			endure: ["8M"],
			facade: ["8M"],
			flashcannon: ["8M", "8L40", "8V", "7M", "7L45"],
			gyroball: ["8M"],
			harden: ["8L1", "8V", "7L1"],
			headbutt: ["8L16", "8V", "7M", "7L1"],
			irondefense: ["8M"],
			protect: ["8M", "8V", "7M"],
			rest: ["8M", "8V", "7M"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			steelbeam: ["8T"],
			substitute: ["8M", "8V", "7M"],
			tailwhip: ["8L8", "8V", "7L9"],
			thunderbolt: ["8M", "8V", "7M"],
			thundershock: ["8L1", "8V", "7L27"],
			thunderwave: ["8M", "8L24", "8V", "7M", "7L18"],
			toxic: ["8V", "7M"],
		},
	},
	melmetal: {
		learnset: {
			acidarmor: ["8L32", "8V", "7L36"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "8V", "7M"],
			brutalswing: ["8M"],
			darkestlariat: ["8M"],
			discharge: ["8L64"],
			doubleironbash: ["8L88", "8V", "8S0", "7L72"],
			dynamicpunch: ["8L72", "8S0"],
			earthquake: ["8M", "8V", "7M"],
			electricterrain: ["8M"],
			endure: ["8M"],
			facade: ["8M", "8V", "7M"],
			flashcannon: ["8M", "8L40", "8V", "7M", "7L45"],
			gigaimpact: ["8M"],
			gyroball: ["8M"],
			harden: ["8L1", "8V", "7L1"],
			headbutt: ["8L1", "8V", "7M", "7L1"],
			heavyslam: ["8M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "8L96", "8V", "8S0", "7M", "7L90"],
			icebeam: ["8M", "8V", "7M"],
			icepunch: ["8M", "8V", "7M"],
			irondefense: ["8M"],
			ironhead: ["8M"],
			megakick: ["8M"],
			megapunch: ["8M", "8L48", "8V", "7L54"],
			protect: ["8M", "8L56", "8V", "7M", "7L63"],
			rest: ["8M", "8V", "7M"],
			rockslide: ["8M", "8V", "7M"],
			rocktomb: ["8M"],
			round: ["8M"],
			selfdestruct: ["8M", "8V", "7M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M", "8V", "7M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "8V", "7M"],
			superpower: ["8M", "8L80", "8V", "7M", "7L81"],
			tailwhip: ["8L1", "8V", "7L1"],
			thunder: ["8M", "8V", "7M"],
			thunderbolt: ["8M", "8V", "7M"],
			thunderpunch: ["8M", "8L0", "8V", "8S0", "7M", "7L0"],
			thundershock: ["8L1", "8V", "7L27"],
			thunderwave: ["8M", "8L24", "8V", "7M", "7L1"],
			toxic: ["8V", "7M"],
		},
		eventData: [
			{generation: 8, level: 100, nature: "Brave", ivs: {hp: 31, atk: 31, def: 31, spa: 31, spd: 31, spe: 0}, moves: ["doubleironbash", "hyperbeam", "dynamicpunch", "thunderpunch"], pokeball: "cherishball"},
		],
	},
	grookey: {
		learnset: {
			acrobatics: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			bodyslam: ["9M"],
			branchpoke: ["9L6", "8L6"],
			bulletseed: ["9M"],
			drainpunch: ["9M", "8M"],
			endeavor: ["9L36", "8L36"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M"],
			facade: ["9M", "8M"],
			fakeout: ["9E", "8E"],
			falseswipe: ["9M", "8M"],
			fling: ["9M", "8M"],
			focusenergy: ["8M"],
			gigadrain: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			grasspledge: ["9M", "8T"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			growl: ["9L1", "8L1"],
			growth: ["9E", "8E"],
			hammerarm: ["9E", "8E"],
			knockoff: ["9L20", "8L20"],
			leafstorm: ["9M"],
			leechseed: ["9E", "8E"],
			lowkick: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			naturepower: ["8E"],
			protect: ["9M", "8M"],
			razorleaf: ["9L12", "8L12"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scratch: ["9L1", "8L1"],
			screech: ["9L17", "8M", "8L17"],
			seedbomb: ["9M"],
			slam: ["9L24", "8L24"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			solarblade: ["8M"],
			strength: ["9E", "8E"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L8", "8M", "8L8"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uproar: ["9L28", "8M", "8L28"],
			uturn: ["9M", "8M"],
			woodhammer: ["9L32", "8L32"],
			workup: ["8M"],
			worryseed: ["9E", "8E"],
		},
	},
	thwackey: {
		learnset: {
			acrobatics: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			bodyslam: ["9M"],
			branchpoke: ["9L1", "8L1"],
			bulletseed: ["9M"],
			doublehit: ["9L0", "8L0"],
			drainpunch: ["9M", "8M"],
			endeavor: ["9L48", "8L48"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M"],
			facade: ["9M", "8M"],
			falseswipe: ["9M", "8M"],
			fling: ["9M", "8M"],
			focusenergy: ["8M"],
			gigadrain: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			grasspledge: ["9M", "8T"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			growl: ["9L1", "8L1"],
			knockoff: ["9L24", "8L24"],
			leafstorm: ["9M"],
			lowkick: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			protect: ["9M", "8M"],
			razorleaf: ["9L12", "8L12"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M"],
			scratch: ["9L1", "8L1"],
			screech: ["9L19", "8M", "8L19"],
			seedbomb: ["9M"],
			slam: ["9L30", "8L30"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			solarblade: ["8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L1", "8M", "8L1"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uproar: ["9L36", "8M", "8L36"],
			uturn: ["9M", "8M"],
			woodhammer: ["9L42", "8L42"],
			workup: ["8M"],
		},
	},
	rillaboom: {
		learnset: {
			acrobatics: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			boomburst: ["9L62", "8L62"],
			branchpoke: ["9L1", "8L1"],
			brickbreak: ["9M", "8M"],
			brutalswing: ["8M"],
			bulkup: ["9M", "8M"],
			bulldoze: ["9M", "8M"],
			bulletseed: ["9M", "8M"],
			darkestlariat: ["8M"],
			doublehit: ["9L1", "8L1"],
			drainpunch: ["9M", "8M"],
			drumbeating: ["9L0", "8L0"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endeavor: ["9L54", "8L54"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M"],
			facade: ["9M", "8M"],
			falseswipe: ["9M", "8M"],
			fling: ["9M", "8M"],
			focusblast: ["9M", "8M"],
			focusenergy: ["8M"],
			frenzyplant: ["9M", "8T"],
			gigadrain: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			grasspledge: ["9M", "8T"],
			grassyglide: ["8T"],
			grassyterrain: ["9M", "9L1", "8M", "8L1"],
			growl: ["9L1", "8L1"],
			highhorsepower: ["8M"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			knockoff: ["9L24", "8L24"],
			leafstorm: ["9M", "8M"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M"],
			magicalleaf: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudshot: ["9M", "8M"],
			nobleroar: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			razorleaf: ["9L12", "8L12"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "8M"],
			scratch: ["9L1", "8L1"],
			screech: ["9L19", "8M", "8L19"],
			seedbomb: ["9M"],
			slam: ["9L30", "8L30"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			solarblade: ["8M"],
			stompingtantrum: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			superpower: ["8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L1", "8M", "8L1"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uproar: ["9L38", "8M", "8L38"],
			uturn: ["9M", "8M"],
			woodhammer: ["9L46", "8L46"],
			workup: ["8M"],
		},
	},
	scorbunny: {
		learnset: {
			acrobatics: ["9M", "8M"],
			agility: ["9M", "9L20", "8M", "8L20"],
			allyswitch: ["8M"],
			assurance: ["8M"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			blazekick: ["8M"],
			bounce: ["9L32", "8M", "8L32"],
			counter: ["9L28", "8L28"],
			doubleedge: ["9L36", "8L36"],
			doublekick: ["9L12", "8L12"],
			electroball: ["9M", "8M"],
			ember: ["9L6", "8L6"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			firefang: ["9M", "8M"],
			firepledge: ["9M", "8T"],
			firespin: ["9M"],
			flamecharge: ["9M", "9L17", "8L17"],
			flamethrower: ["9M", "8M"],
			flareblitz: ["9M"],
			focusenergy: ["8M"],
			growl: ["9L1", "8L1"],
			gunkshot: ["9M", "8M"],
			headbutt: ["9L24", "8L24"],
			heatwave: ["9M", "8M"],
			helpinghand: ["9M"],
			highjumpkick: ["9E", "8E"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			mudshot: ["8M"],
			overheat: ["9M", "8M"],
			protect: ["9M", "8M"],
			quickattack: ["9L8", "8L8"],
			rest: ["9M", "8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			sandattack: ["9E", "8E"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9E", "8E"],
			sunnyday: ["9M", "8M"],
			superfang: ["9E", "8E"],
			swift: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M"],
			workup: ["8M"],
		},
	},
	raboot: {
		learnset: {
			acrobatics: ["9M", "8M"],
			agility: ["9M", "9L24", "8M", "8L24"],
			allyswitch: ["8M"],
			assurance: ["8M"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			blazekick: ["8M"],
			bounce: ["9L42", "8M", "8L42"],
			bulkup: ["9M", "8M"],
			counter: ["9L36", "8L36"],
			doubleedge: ["9L48", "8L48"],
			doublekick: ["9L12", "8L12"],
			electroball: ["9M", "8M"],
			ember: ["9L1", "8L1"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			firefang: ["9M", "8M"],
			firepledge: ["9M", "8T"],
			firespin: ["9M"],
			flamecharge: ["9M", "9L19", "8L19"],
			flamethrower: ["9M", "8M"],
			flareblitz: ["9M", "8M"],
			focusenergy: ["8M"],
			growl: ["9L1", "8L1"],
			gunkshot: ["9M", "8M"],
			headbutt: ["9L30", "8L30"],
			heatwave: ["9M", "8M"],
			helpinghand: ["9M"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			mudshot: ["9M", "8M"],
			overheat: ["9M", "8M"],
			protect: ["9M", "8M"],
			quickattack: ["9L1", "8L1"],
			rest: ["9M", "8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M"],
			workup: ["8M"],
		},
	},
	cinderace: {
		learnset: {
			acrobatics: ["9M", "8M"],
			agility: ["9M", "9L24", "8M", "8L24"],
			allyswitch: ["8M"],
			assurance: ["8M"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			blastburn: ["9M", "8T"],
			blazekick: ["8M"],
			bounce: ["9L46", "8M", "8L46"],
			bulkup: ["9M", "8M"],
			coaching: ["8T"],
			counter: ["9L38", "8L38"],
			courtchange: ["9L62", "8L62"],
			doubleedge: ["9L54", "8L54"],
			doublekick: ["9L12", "8L12"],
			electroball: ["9M", "8M"],
			ember: ["9L1", "8L1"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			feint: ["9L1", "8L1"],
			fireblast: ["9M", "8M"],
			firefang: ["9M", "8M"],
			firepledge: ["9M", "8T"],
			firepunch: ["9M", "8M"],
			firespin: ["9M", "8M"],
			flamecharge: ["9M", "9L19", "8L19"],
			flamethrower: ["9M", "8M"],
			flareblitz: ["9M", "8M"],
			fling: ["9M"],
			focusblast: ["9M", "8M"],
			focusenergy: ["8M"],
			gigaimpact: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			gunkshot: ["9M", "8M"],
			headbutt: ["9L30", "8L30"],
			heatwave: ["9M", "8M"],
			helpinghand: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			ironhead: ["9M", "8M"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M"],
			overheat: ["9M", "8M"],
			protect: ["9M", "8M"],
			pyroball: ["9L0", "8L0"],
			quickattack: ["9L1", "8L1"],
			rest: ["9M", "8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			scorchingsands: ["8T"],
			shadowball: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M"],
			willowisp: ["9M"],
			workup: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	sobble: {
		learnset: {
			aquajet: ["9E", "8E"],
			aquaring: ["9E", "8E"],
			attract: ["8M"],
			batonpass: ["8M"],
			bind: ["9L8", "8L8"],
			bounce: ["8M"],
			chillingwater: ["9M"],
			dive: ["8M"],
			doubleteam: ["9E", "8E"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fellstinger: ["9E", "8E"],
			growl: ["9L1", "8L1"],
			haze: ["9E", "8E"],
			hydropump: ["9M"],
			iceshard: ["9E", "8E"],
			lightscreen: ["9M", "8M"],
			liquidation: ["9M", "9L28", "8M", "8L28"],
			mist: ["9E", "8E"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			pound: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			raindance: ["9M", "9L36", "8M", "8L36"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			soak: ["9L32", "8L32"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L20", "8L20"],
			surf: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			tearfullook: ["9L17", "8L17"],
			terablast: ["9M"],
			uturn: ["9M", "9L24", "8M", "8L24"],
			waterfall: ["9M"],
			watergun: ["9L6", "8L6"],
			waterpledge: ["9M", "8T"],
			waterpulse: ["9M", "9L12", "8L12"],
			weatherball: ["8M"],
			whirlpool: ["8M"],
			workup: ["8M"],
		},
	},
	drizzile: {
		learnset: {
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			bind: ["9L1", "8L1"],
			bounce: ["8M"],
			chillingwater: ["9M"],
			dive: ["8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fling: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			hydropump: ["9M"],
			lightscreen: ["9M", "8M"],
			liquidation: ["9M", "9L36", "8M", "8L36"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			pound: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			raindance: ["9M", "9L48", "8M", "8L48"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			soak: ["9L42", "8L42"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L24", "8L24"],
			surf: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			tearfullook: ["9L19", "8L19"],
			terablast: ["9M"],
			uturn: ["9M", "9L30", "8M", "8L30"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1"],
			waterpledge: ["9M", "8T"],
			waterpulse: ["9M", "9L12", "8L12"],
			weatherball: ["8M"],
			whirlpool: ["8M"],
			workup: ["8M"],
		},
	},
	inteleon: {
		learnset: {
			acrobatics: ["9M", "9L1", "8M", "8L1"],
			agility: ["9M", "8M"],
			aircutter: ["9M"],
			airslash: ["9M", "8M"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			bind: ["9L1", "8L1"],
			blizzard: ["9M", "8M"],
			bounce: ["8M"],
			breakingswipe: ["8M"],
			chillingwater: ["9M"],
			darkpulse: ["9M", "8M"],
			dive: ["8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fling: ["9M", "8M"],
			focusenergy: ["8M"],
			gigaimpact: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			hydrocannon: ["9M", "8T"],
			hydropump: ["9M", "9L62", "8M", "8L62"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			iciclespear: ["8M"],
			icywind: ["9M", "8M"],
			lightscreen: ["9M", "8M"],
			liquidation: ["9M", "9L38", "8M", "8L38"],
			metronome: ["9M", "8M"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			pound: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			raindance: ["9M", "9L54", "8M", "8L54"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			scald: ["8M"],
			scaleshot: ["8T"],
			shadowball: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snipeshot: ["9L0", "8L0"],
			snore: ["8M"],
			snowscape: ["9M"],
			soak: ["9L46", "8L46"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L24", "8L24"],
			surf: ["9M", "8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M"],
			tearfullook: ["9L19", "8L19"],
			terablast: ["9M"],
			uturn: ["9M", "9L30", "8M", "8L30"],
			waterfall: ["9M", "8M"],
			watergun: ["9L1", "8L1"],
			waterpledge: ["9M", "8T"],
			waterpulse: ["9M", "9L12", "8L12"],
			weatherball: ["8M"],
			whirlpool: ["8M"],
			workup: ["8M"],
		},
	},
	skwovet: {
		learnset: {
			amnesia: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			belch: ["9L45", "8L45"],
			bellydrum: ["9E", "8E"],
			bite: ["9L5", "8L5"],
			bodyslam: ["9M", "9L20", "8M", "8L20"],
			brutalswing: ["8M"],
			bulletseed: ["9M", "9L35", "8M", "8L35"],
			counter: ["9L30", "8L30"],
			crunch: ["9M", "8M"],
			defensecurl: ["9E", "8E"],
			dig: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fling: ["9M", "8M"],
			gyroball: ["8M"],
			hypervoice: ["9M", "8M"],
			irontail: ["8M"],
			lastresort: ["9E", "8E"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M"],
			payback: ["8M"],
			protect: ["9M", "8M"],
			rest: ["9M", "9L25", "8M", "8L25"],
			rollout: ["9E", "8E"],
			round: ["8M"],
			seedbomb: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			spitup: ["9L15", "8L15"],
			stockpile: ["9L15", "8L15"],
			stuffcheeks: ["9L10", "8L10"],
			substitute: ["9M", "8M"],
			superfang: ["9L40", "8L40"],
			swallow: ["9L15", "8L15"],
			tackle: ["9L1", "8L1"],
			tailslap: ["8M"],
			tailwhip: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
		},
	},
	greedent: {
		learnset: {
			amnesia: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			belch: ["9L55", "8L55"],
			bite: ["9L1", "8L1"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "9L20", "8M", "8L20"],
			brutalswing: ["8M"],
			bulldoze: ["9M"],
			bulletseed: ["9M", "9L41", "8M", "8L41"],
			counter: ["9L34", "8L34"],
			covet: ["9L0", "8L0"],
			crunch: ["9M", "8M"],
			dig: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			firefang: ["9M", "8M"],
			fling: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			gyroball: ["8M"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			icefang: ["9M", "8M"],
			irontail: ["8M"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M"],
			payback: ["8M"],
			protect: ["9M", "8M"],
			psychicfangs: ["9M", "8M"],
			raindance: ["9M"],
			rest: ["9M", "9L27", "8M", "8L27"],
			round: ["8M"],
			seedbomb: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			spitup: ["9L15", "8L15"],
			stockpile: ["9L15", "8L15"],
			stompingtantrum: ["9M", "8M"],
			stuffcheeks: ["9L1", "8L1"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			superfang: ["9L48", "8L48"],
			superpower: ["8M"],
			swallow: ["9L15", "8L15"],
			swordsdance: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			tailslap: ["8M"],
			tailwhip: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunderfang: ["9M", "8M"],
			uproar: ["8M"],
			wildcharge: ["9M", "8M"],
		},
	},
	rookidee: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M", "8M"],
			aircutter: ["9M"],
			airslash: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			bravebird: ["9M", "9L36", "8M", "8L36"],
			defog: ["9E", "8E"],
			drillpeck: ["9L28", "8L28"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			faketears: ["9M", "8M"],
			fly: ["9M", "8M"],
			focusenergy: ["8M"],
			furyattack: ["9L12", "8L12"],
			honeclaws: ["9L8", "8L8"],
			leer: ["9L1", "8L1"],
			nastyplot: ["9M", "8M"],
			payback: ["8M"],
			peck: ["9L1", "8L1"],
			pluck: ["9L16", "8L16"],
			powertrip: ["9L4", "8L4"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			rocksmash: ["9E", "8E"],
			roost: ["9E", "8E"],
			round: ["8M"],
			sandattack: ["9E", "8E"],
			scaryface: ["9M", "9L24", "8M", "8L24"],
			skyattack: ["9E", "8E"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			spite: ["9E", "8E"],
			substitute: ["9M", "8M"],
			swagger: ["9L32", "8L32"],
			swift: ["9M", "8M"],
			tailwind: ["9M", "9E", "8E"],
			takedown: ["9M"],
			taunt: ["9M", "9L20", "8M", "8L20"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			uturn: ["9M", "8M"],
			workup: ["8M"],
		},
	},
	corvisquire: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M", "8M"],
			aircutter: ["9M"],
			airslash: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			bravebird: ["9M", "9L46", "8M", "8L46"],
			drillpeck: ["9L34", "8L34"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			faketears: ["9M", "8M"],
			fly: ["9M", "8M"],
			focusenergy: ["8M"],
			furyattack: ["9L12", "8L12"],
			honeclaws: ["9L1", "8L1"],
			hurricane: ["9M"],
			leer: ["9L1", "8L1"],
			nastyplot: ["9M", "8M"],
			payback: ["8M"],
			peck: ["9L1", "8L1"],
			pluck: ["9L16", "8L16"],
			powertrip: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "9L28", "8M", "8L28"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			swagger: ["9L40", "8L40"],
			swift: ["9M", "8M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L22", "8M", "8L22"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			uturn: ["9M", "8M"],
			workup: ["8M"],
		},
	},
	corviknight: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M", "8M"],
			aircutter: ["9M"],
			airslash: ["9M", "8M"],
			assurance: ["8M"],
			attract: ["8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			bravebird: ["9M", "9L50", "8M", "8L50"],
			bulkup: ["9M", "8M"],
			drillpeck: ["9L34", "8L34"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			faketears: ["9M", "8M"],
			flashcannon: ["9M", "8M"],
			fly: ["9M", "8M"],
			focusenergy: ["8M"],
			furyattack: ["9L12", "8L12"],
			gigaimpact: ["9M", "8M"],
			heavyslam: ["9M", "8M"],
			honeclaws: ["9L1", "8L1"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			irondefense: ["9M", "9L1", "8M", "8L1"],
			ironhead: ["9M", "8M"],
			leer: ["9L1", "8L1"],
			lightscreen: ["9M", "8M"],
			metalclaw: ["9M"],
			metalsound: ["9L1", "8L1"],
			nastyplot: ["9M", "8M"],
			payback: ["8M"],
			peck: ["9L1", "8L1"],
			pluck: ["9L16", "8L16"],
			powertrip: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			raindance: ["9M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "9L28", "8M", "8L28"],
			screech: ["9L1", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			steelbeam: ["9M", "8T"],
			steelwing: ["9L0", "8M", "8L0"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			swagger: ["9L42", "8L42"],
			swift: ["9M", "8M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L22", "8M", "8L22"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			uturn: ["9M", "8M"],
			workup: ["8M"],
		},
	},
	blipbug: {
		learnset: {
			infestation: ["8E"],
			recover: ["8E"],
			stickyweb: ["8E"],
			strugglebug: ["8L1"],
			supersonic: ["8E"],
		},
	},
	dottler: {
		learnset: {
			allyswitch: ["8M"],
			attract: ["8M"],
			bodypress: ["8M"],
			bugbuzz: ["8M"],
			calmmind: ["8M"],
			confusion: ["8L0"],
			endure: ["8M"],
			energyball: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M"],
			futuresight: ["8M"],
			guardswap: ["8M"],
			helpinghand: ["8M"],
			imprison: ["8M"],
			irondefense: ["8M"],
			leechlife: ["8M"],
			lightscreen: ["8M", "8L0"],
			magicroom: ["8M"],
			payback: ["8M"],
			powerswap: ["8M"],
			protect: ["8M"],
			psychic: ["8M"],
			psychicterrain: ["8M"],
			psyshock: ["8M"],
			reflect: ["8M", "8L0"],
			rest: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			shadowball: ["8M"],
			skillswap: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			storedpower: ["8M"],
			strugglebug: ["8L1"],
			substitute: ["8M"],
			trick: ["8M"],
			trickroom: ["8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["8M"],
		},
	},
	orbeetle: {
		learnset: {
			afteryou: ["8L40"],
			agility: ["8M", "8L12"],
			allyswitch: ["8M", "8L24"],
			attract: ["8M"],
			batonpass: ["8M"],
			bodypress: ["8M"],
			bugbuzz: ["8M", "8L28"],
			calmmind: ["8M", "8L44"],
			confuseray: ["8L4"],
			confusion: ["8L1"],
			endure: ["8M"],
			energyball: ["8M"],
			expandingforce: ["8T"],
			facade: ["8M"],
			futuresight: ["8M"],
			gigadrain: ["8M"],
			gigaimpact: ["8M"],
			guardswap: ["8M"],
			helpinghand: ["8M"],
			hyperbeam: ["8M"],
			hypnosis: ["8L20"],
			imprison: ["8M"],
			irondefense: ["8M"],
			leechlife: ["8M"],
			lightscreen: ["8M", "8L1"],
			magiccoat: ["8L8"],
			magicroom: ["8M"],
			mirrorcoat: ["8L32"],
			payback: ["8M"],
			powerswap: ["8M"],
			protect: ["8M"],
			psybeam: ["8L16"],
			psychic: ["8M", "8L36"],
			psychicterrain: ["8M", "8L48"],
			psychocut: ["8M"],
			psyshock: ["8M"],
			reflect: ["8M", "8L1"],
			rest: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			shadowball: ["8M"],
			skillswap: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			storedpower: ["8M"],
			strugglebug: ["8L1"],
			substitute: ["8M"],
			trick: ["8M"],
			trickroom: ["8M"],
			uturn: ["8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["8M"],
		},
	},
	nickit: {
		learnset: {
			agility: ["8M"],
			assurance: ["8M", "8L16"],
			attract: ["8M"],
			batonpass: ["8M"],
			beatup: ["8M", "8L4"],
			dig: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			faketears: ["8M"],
			foulplay: ["8M", "8L36"],
			honeclaws: ["8L8"],
			howl: ["8E"],
			knockoff: ["8E"],
			lashout: ["8T"],
			mudshot: ["8M"],
			nastyplot: ["8M", "8L20"],
			nightslash: ["8L28"],
			playrough: ["8M"],
			protect: ["8M"],
			quickattack: ["8L1"],
			quickguard: ["8E"],
			rest: ["8M"],
			round: ["8M"],
			screech: ["8M"],
			sleeptalk: ["8M"],
			snarl: ["8M", "8L12"],
			snore: ["8M"],
			substitute: ["8M"],
			suckerpunch: ["8L24"],
			swift: ["8M"],
			tailslap: ["8M", "8L32"],
			tailwhip: ["8L1"],
			taunt: ["8M"],
			thief: ["8M"],
			torment: ["8E"],
		},
	},
	thievul: {
		learnset: {
			acrobatics: ["8M"],
			agility: ["8M"],
			assurance: ["8M", "8L16"],
			attract: ["8M"],
			batonpass: ["8M"],
			beatup: ["8M", "8L1"],
			burningjealousy: ["8T"],
			crunch: ["8M"],
			darkpulse: ["8M"],
			dig: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			faketears: ["8M"],
			firefang: ["8M"],
			foulplay: ["8M", "8L46"],
			gigaimpact: ["8M"],
			grassknot: ["8M"],
			honeclaws: ["8L1"],
			hyperbeam: ["8M"],
			icefang: ["8M"],
			lashout: ["8T"],
			mudshot: ["8M"],
			nastyplot: ["8M", "8L22"],
			nightslash: ["8L34"],
			partingshot: ["8L52"],
			playrough: ["8M"],
			protect: ["8M"],
			psychic: ["8M"],
			quickattack: ["8L1"],
			rest: ["8M"],
			round: ["8M"],
			screech: ["8M"],
			shadowball: ["8M"],
			shadowclaw: ["8M"],
			sleeptalk: ["8M"],
			snarl: ["8M", "8L12"],
			snore: ["8M"],
			substitute: ["8M"],
			suckerpunch: ["8L28"],
			swift: ["8M"],
			tailslap: ["8M", "8L40"],
			tailwhip: ["8L1"],
			taunt: ["8M"],
			thief: ["8M", "8L0"],
			thunderfang: ["8M"],
			uturn: ["8M"],
		},
	},
	gossifleur: {
		learnset: {
			aromatherapy: ["8L32"],
			attract: ["8M"],
			bulletseed: ["8M"],
			charm: ["8M"],
			endure: ["8M"],
			energyball: ["8M"],
			facade: ["8M"],
			gigadrain: ["8M"],
			grassknot: ["8M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growth: ["8E"],
			helpinghand: ["8M"],
			hypervoice: ["8M", "8L28"],
			leafage: ["8L1"],
			leafstorm: ["8M", "8L36"],
			leaftornado: ["8L21"],
			leechseed: ["8E"],
			lightscreen: ["8M"],
			magicalleaf: ["8M"],
			poisonpowder: ["8E"],
			pollenpuff: ["8M"],
			protect: ["8M"],
			rapidspin: ["8L4"],
			razorleaf: ["8L12"],
			rest: ["8M"],
			round: ["8M", "8L16"],
			sing: ["8L1"],
			sleeppowder: ["8E"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			stunspore: ["8E"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			sweetscent: ["8L8"],
			synthesis: ["8L24"],
			worryseed: ["8E"],
		},
	},
	eldegoss: {
		learnset: {
			aromatherapy: ["8L40"],
			attract: ["8M"],
			bulletseed: ["8M"],
			charm: ["8M"],
			cottonguard: ["8L52"],
			cottonspore: ["8L0"],
			endure: ["8M"],
			energyball: ["8M"],
			facade: ["8M"],
			gigadrain: ["8M"],
			gigaimpact: ["8M"],
			grassknot: ["8M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			helpinghand: ["8M"],
			hyperbeam: ["8M"],
			hypervoice: ["8M", "8L34"],
			leafage: ["8L1"],
			leafstorm: ["8M", "8L46"],
			leaftornado: ["8L23"],
			lightscreen: ["8M"],
			magicalleaf: ["8M"],
			pollenpuff: ["8M"],
			protect: ["8M"],
			rapidspin: ["8L1"],
			razorleaf: ["8L12"],
			rest: ["8M"],
			round: ["8M", "8L16"],
			seedbomb: ["8M"],
			sing: ["8L1"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			sweetscent: ["8L1"],
			synthesis: ["8L28"],
			weatherball: ["8M"],
		},
	},
	wooloo: {
		learnset: {
			agility: ["8M"],
			attract: ["8M"],
			copycat: ["8L8"],
			cottonguard: ["8L36"],
			counter: ["8E"],
			defensecurl: ["8L4"],
			doubleedge: ["8L40"],
			doublekick: ["8L16"],
			electroball: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			grassyglide: ["8T"],
			growl: ["8L1"],
			guardsplit: ["8L12"],
			guardswap: ["8M", "8L28"],
			headbutt: ["8L21"],
			payback: ["8M"],
			protect: ["8M"],
			rest: ["8M"],
			reversal: ["8M", "8L32"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stomp: ["8E"],
			substitute: ["8M"],
			swagger: ["8E"],
			tackle: ["8L1"],
			takedown: ["8L25"],
			thunderwave: ["8M"],
			wildcharge: ["8M"],
		},
	},
	dubwool: {
		learnset: {
			agility: ["8M"],
			attract: ["8M"],
			batonpass: ["8M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			bounce: ["8M"],
			copycat: ["8L1"],
			cottonguard: ["8L44"],
			defensecurl: ["8L1"],
			doubleedge: ["8L50"],
			doublekick: ["8L16"],
			electroball: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			gigaimpact: ["8M"],
			grassyglide: ["8T"],
			growl: ["8L1"],
			guardsplit: ["8L12"],
			guardswap: ["8M", "8L32"],
			headbutt: ["8L21"],
			hyperbeam: ["8M"],
			lastresort: ["8L56"],
			megakick: ["8M"],
			payback: ["8M"],
			protect: ["8M"],
			rest: ["8M"],
			retaliate: ["8M"],
			reversal: ["8M", "8L38"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			substitute: ["8M"],
			swordsdance: ["8M"],
			tackle: ["8L1"],
			takedown: ["8L27"],
			thunderwave: ["8M"],
			wildcharge: ["8M"],
			zenheadbutt: ["8M"],
		},
	},
	chewtle: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M"],
			bite: ["9L7", "8L7"],
			bodyslam: ["9M", "9L49", "8M", "8L49"],
			chillingwater: ["9M"],
			counter: ["9L28", "8L28"],
			crunch: ["9M"],
			dive: ["8M"],
			dragontail: ["9M", "9E", "8E"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			falseswipe: ["9M"],
			gastroacid: ["9E", "8E"],
			headbutt: ["9L21", "8L21"],
			hydropump: ["9M", "8M"],
			icefang: ["9M", "8M"],
			jawlock: ["9L35", "8L35"],
			liquidation: ["9M", "9L42", "8M", "8L42"],
			mudshot: ["9M", "8M"],
			payback: ["8M"],
			poisonjab: ["9M"],
			protect: ["9M", "9L14", "8M", "8L14"],
			raindance: ["9M", "8M"],
			rest: ["9M", "8M"],
			revenge: ["8M"],
			round: ["8M"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			shellsmash: ["9E"],
			skittersmack: ["8T"],
			skullbash: ["8E"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			stompingtantrum: ["9M"],
			substitute: ["9M", "8M"],
			surf: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			waterfall: ["9M"],
			watergun: ["9L1", "8L1"],
			waterpulse: ["9M"],
			whirlpool: ["8M"],
		},
	},
	drednaw: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M"],
			bite: ["9L1", "8L1"],
			blizzard: ["9M", "8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "9L57", "8M", "8L57"],
			bulldoze: ["9M", "8M"],
			chillingwater: ["9M"],
			counter: ["9L30", "8L30"],
			crunch: ["9M", "9L1", "8M", "8L1"],
			dig: ["9M", "8M"],
			dive: ["8M"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			falseswipe: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			headbutt: ["9L21", "8L21"],
			headsmash: ["9L66", "8L66"],
			highhorsepower: ["8M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			icefang: ["9M", "8M"],
			icespinner: ["9M"],
			irondefense: ["9M", "8M"],
			irontail: ["8M"],
			jawlock: ["9L39", "8L39"],
			liquidation: ["9M", "9L48", "8M", "8L48"],
			megahorn: ["8M"],
			meteorbeam: ["8T"],
			muddywater: ["8M"],
			mudshot: ["9M", "8M"],
			payback: ["8M"],
			poisonjab: ["9M", "8M"],
			protect: ["9M", "9L1", "8M", "8L1"],
			raindance: ["9M", "8M"],
			razorshell: ["9L1", "8M", "8L1"],
			rest: ["9M", "8M"],
			revenge: ["8M"],
			rockblast: ["9M", "8M"],
			rockpolish: ["9L1", "8L1"],
			rockslide: ["9M", "8M"],
			rocktomb: ["9M", "9L0", "8M", "8L0"],
			round: ["8M"],
			sandstorm: ["9M", "8M"],
			sandtomb: ["8M"],
			scald: ["8M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "8M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M"],
			smartstrike: ["9M", "8M"],
			snore: ["8M"],
			stealthrock: ["9M", "8M"],
			stompingtantrum: ["9M", "8M"],
			stoneedge: ["9M", "8M"],
			substitute: ["9M", "8M"],
			superpower: ["8M"],
			surf: ["9M", "8M"],
			swordsdance: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			throatchop: ["8M"],
			waterfall: ["9M", "8M"],
			watergun: ["9L1", "8L1"],
			waterpulse: ["9M"],
			whirlpool: ["8M"],
		},
	},
	yamper: {
		learnset: {
			attract: ["8M"],
			bite: ["8L10"],
			charge: ["8L35"],
			charm: ["8M", "8L26"],
			crunch: ["8M", "8L30"],
			dig: ["8M"],
			discharge: ["8E"],
			doubleedge: ["8E"],
			electroball: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			firefang: ["8M"],
			flamecharge: ["8E"],
			helpinghand: ["8M"],
			howl: ["8E"],
			nuzzle: ["8L5"],
			playrough: ["8M", "8L45"],
			protect: ["8M"],
			rest: ["8M"],
			risingvoltage: ["8T"],
			roar: ["8L15"],
			round: ["8M"],
			sandattack: ["8E"],
			sleeptalk: ["8M"],
			snarl: ["8M"],
			snore: ["8M"],
			spark: ["8L20"],
			substitute: ["8M"],
			swift: ["8M"],
			tackle: ["8L1"],
			tailwhip: ["8L1"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderfang: ["8M"],
			thunderwave: ["8M"],
			uproar: ["8M"],
			voltswitch: ["8M"],
			wildcharge: ["8M", "8L40"],
		},
	},
	boltund: {
		learnset: {
			agility: ["8M"],
			attract: ["8M"],
			bite: ["8L1"],
			bulkup: ["8M"],
			charge: ["8L41"],
			charm: ["8M", "8L28"],
			crunch: ["8M", "8L34"],
			dig: ["8M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M", "8L62"],
			electrify: ["8L1"],
			electroball: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			firefang: ["8M"],
			focusenergy: ["8M"],
			gigaimpact: ["8M"],
			helpinghand: ["8M"],
			hyperbeam: ["8M"],
			hypervoice: ["8M"],
			nuzzle: ["8L1"],
			playrough: ["8M", "8L55"],
			protect: ["8M"],
			psychicfangs: ["8M"],
			rest: ["8M"],
			risingvoltage: ["8T"],
			roar: ["8L15"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snarl: ["8M"],
			snore: ["8M"],
			spark: ["8L20"],
			substitute: ["8M"],
			swift: ["8M"],
			tackle: ["8L1"],
			tailwhip: ["8L1"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderfang: ["8M"],
			thunderwave: ["8M"],
			uproar: ["8M"],
			voltswitch: ["8M"],
			wildcharge: ["8M", "8L48"],
		},
	},
	rolycoly: {
		learnset: {
			ancientpower: ["9L20", "8L20"],
			attract: ["8M"],
			block: ["9E", "8E"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			dig: ["9M", "8M"],
			endure: ["9M", "8M"],
			explosion: ["9E", "8E"],
			facade: ["9M", "8M"],
			gyroball: ["8M"],
			heatcrash: ["9L35", "8M", "8L35"],
			incinerate: ["9L25", "8L25"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M", "8M"],
			meteorbeam: ["8T"],
			mudslap: ["9M", "9E", "8E"],
			powergem: ["9M"],
			protect: ["9M", "8M"],
			rapidspin: ["9L5", "8L5"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			rockblast: ["9M", "9L40", "8M", "8L40"],
			rockpolish: ["9L15", "8L15"],
			rockslide: ["9M", "8M"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			sandstorm: ["9M", "8M"],
			sandtomb: ["8M"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M"],
			smackdown: ["9L10", "8L10"],
			smokescreen: ["9L1", "8L1"],
			snore: ["8M"],
			spikes: ["9M", "8M"],
			stealthrock: ["9M", "9L30", "8M", "8L30"],
			stoneedge: ["9M", "8M"],
			substitute: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			willowisp: ["9M", "8M"],
		},
	},
	carkol: {
		learnset: {
			ancientpower: ["9L20", "8L20"],
			attract: ["8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			burnup: ["8L55"],
			dig: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			firespin: ["9M", "8M"],
			flamecharge: ["9M", "9L0", "8L0"],
			flamethrower: ["9M", "8M"],
			flareblitz: ["9M", "8M"],
			gyroball: ["8M"],
			heatcrash: ["9L41", "8M", "8L41"],
			heatwave: ["9M", "8M"],
			heavyslam: ["9M", "8M"],
			highhorsepower: ["8M"],
			incinerate: ["9L27", "8L27"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M", "8M"],
			meteorbeam: ["8T"],
			mudslap: ["9M"],
			overheat: ["9M", "8M"],
			powergem: ["9M"],
			protect: ["9M", "8M"],
			rapidspin: ["9L1", "8L1"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			rockblast: ["9M", "9L48", "8M", "8L48"],
			rockpolish: ["9L15", "8L15"],
			rockslide: ["9M", "8M"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			sandstorm: ["9M", "8M"],
			sandtomb: ["8M"],
			scald: ["8M"],
			scorchingsands: ["8T"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M"],
			smackdown: ["9L1", "8L1"],
			smokescreen: ["9L1", "8L1"],
			snore: ["8M"],
			spikes: ["9M", "8M"],
			stealthrock: ["9M", "9L35", "8M", "8L35"],
			stoneedge: ["9M", "9L55", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			willowisp: ["9M", "8M"],
		},
	},
	coalossal: {
		learnset: {
			ancientpower: ["9L20", "8L20"],
			attract: ["8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "8M"],
			burnup: ["8L63"],
			dig: ["9M", "8M"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			firepunch: ["9M", "8M"],
			firespin: ["9M", "8M"],
			flamecharge: ["9M", "9L1", "8L1"],
			flamethrower: ["9M", "8M"],
			flareblitz: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			gyroball: ["8M"],
			heatcrash: ["9L45", "8M", "8L45"],
			heatwave: ["9M", "8M"],
			heavyslam: ["9M", "8M"],
			highhorsepower: ["8M"],
			hyperbeam: ["9M", "8M"],
			incinerate: ["9L27", "8L27"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			meteorbeam: ["8T"],
			overheat: ["9M", "8M"],
			powergem: ["9M"],
			protect: ["9M", "8M"],
			rapidspin: ["9L1", "8L1"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			rockblast: ["9M", "9L54", "8M", "8L54"],
			rockpolish: ["9L15", "8L15"],
			rockslide: ["9M", "8M"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			sandstorm: ["9M", "8M"],
			sandtomb: ["8M"],
			scald: ["8M"],
			scorchingsands: ["8T"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M"],
			smackdown: ["9L1", "8L1"],
			smokescreen: ["9L1", "8L1"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			spikes: ["9M", "8M"],
			stealthrock: ["9M", "9L37", "8M", "8L37"],
			stoneedge: ["9M", "9L63", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			tarshot: ["9L0", "8L0"],
			terablast: ["9M"],
			willowisp: ["9M", "8M"],
		},
	},
	applin: {
		learnset: {
			astonish: ["9L1", "8L1"],
			attract: ["8M"],
			defensecurl: ["9E", "8E"],
			dracometeor: ["8T"],
			grassyglide: ["8T"],
			pounce: ["9M"],
			recycle: ["9E", "8E"],
			rollout: ["9E", "8E"],
			suckerpunch: ["9E", "8E"],
			terablast: ["9M"],
			withdraw: ["9L1", "8L1"],
		},
	},
	flapple: {
		learnset: {
			acidspray: ["9M", "9L4", "8L4"],
			acrobatics: ["9M", "9L8", "8M", "8L8"],
			aerialace: ["9M"],
			airslash: ["9M", "8M"],
			astonish: ["9L1", "8L1"],
			attract: ["8M"],
			bulletseed: ["9M", "8M"],
			dracometeor: ["9M", "8T"],
			dragonbreath: ["9L20", "8L20"],
			dragondance: ["9M", "9L24", "8M", "8L24"],
			dragonpulse: ["9M", "9L28", "8M", "8L28"],
			dragonrush: ["9L44", "8L44"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M"],
			facade: ["9M", "8M"],
			fly: ["9M", "9L40", "8M", "8L40"],
			focusenergy: ["8M"],
			gigadrain: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			gravapple: ["9L32", "8L32"],
			growth: ["9L1", "8L1"],
			heavyslam: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			irondefense: ["9M", "9L36", "8M", "8L36"],
			leafstorm: ["9M"],
			leechseed: ["9L12", "8L12"],
			magicalleaf: ["9M"],
			outrage: ["9M", "8M"],
			pounce: ["9M"],
			protect: ["9M", "9L16", "8M", "8L16"],
			recycle: ["9L1", "8L1"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "8M"],
			seedbomb: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			trailblaze: ["9M"],
			twister: ["9L1", "8L1"],
			uturn: ["9M", "8M"],
			wingattack: ["9L0", "8L0"],
			withdraw: ["9L1", "8L1"],
		},
	},
	appletun: {
		learnset: {
			amnesia: ["9M", "8M"],
			appleacid: ["9L28", "8L28"],
			astonish: ["9L1", "8L1"],
			attract: ["8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "9L32", "8M", "8L32"],
			bulldoze: ["9M", "8M"],
			bulletseed: ["9M", "9L20", "8M", "8L20"],
			curse: ["9L4", "8L4"],
			dracometeor: ["9M", "8T"],
			dragonpulse: ["9M", "9L40", "8M", "8L40"],
			dragontail: ["9M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "9L44", "8M", "8L44"],
			facade: ["9M", "8M"],
			gigadrain: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			grassyglide: ["8T"],
			grassyterrain: ["9M"],
			growth: ["9L1", "8L1"],
			gyroball: ["8M"],
			headbutt: ["9L0", "8L0"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M"],
			highhorsepower: ["8M"],
			hyperbeam: ["9M", "8M"],
			irondefense: ["9M", "9L36", "8M", "8L36"],
			ironhead: ["9M"],
			leafstorm: ["9M"],
			leechseed: ["9L12", "8L12"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M"],
			outrage: ["9M", "8M"],
			payback: ["8M"],
			pounce: ["9M"],
			protect: ["9M", "9L16", "8M", "8L16"],
			raindance: ["9M"],
			recover: ["9L24", "8L24"],
			recycle: ["9L1", "8L1"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			seedbomb: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			stomp: ["9L8", "8L8"],
			stompingtantrum: ["9M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			superpower: ["8M"],
			sweetscent: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			trailblaze: ["9M"],
			withdraw: ["9L1", "8L1"],
			zenheadbutt: ["9M"],
		},
	},
	silicobra: {
		learnset: {
			attract: ["8M"],
			belch: ["9E", "8E"],
			bodyslam: ["9M"],
			brutalswing: ["9L10", "8M", "8L10"],
			bulldoze: ["9M", "9L15", "8M", "8L15"],
			coil: ["9L45", "8L45"],
			dig: ["9M", "9L30", "8M", "8L30"],
			dragonrush: ["9E", "8E"],
			drillrun: ["9M", "8M"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			glare: ["9L25", "8L25"],
			headbutt: ["9L20", "8L20"],
			lastresort: ["9E", "8E"],
			minimize: ["9L5", "8L5"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M", "9E", "8E"],
			poisontail: ["9M", "9E", "8E"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			rockblast: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			round: ["8M"],
			sandattack: ["9L1", "8L1"],
			sandstorm: ["9M", "9L35", "8M", "8L35"],
			sandtomb: ["9L50", "8M", "8L50"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			scorchingsands: ["8T"],
			screech: ["8M"],
			skittersmack: ["8T"],
			slam: ["9L40", "8L40"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			stealthrock: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			wrap: ["9L1", "8L1"],
		},
	},
	sandaconda: {
		learnset: {
			attract: ["8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M"],
			brutalswing: ["9L1", "8M", "8L1"],
			bulldoze: ["9M", "9L15", "8M", "8L15"],
			coil: ["9L49", "8L49"],
			dig: ["9M", "9L30", "8M", "8L30"],
			drillrun: ["9M", "8M"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			firefang: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			glare: ["9L25", "8L25"],
			headbutt: ["9L20", "8L20"],
			highhorsepower: ["8M"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M", "8M"],
			minimize: ["9L1", "8L1"],
			mudshot: ["9M", "8M"],
			mudslap: ["9M"],
			outrage: ["9M", "8M"],
			poisontail: ["9M"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			rockblast: ["9M", "8M"],
			rockslide: ["9M", "8M"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			sandattack: ["9L1", "8L1"],
			sandstorm: ["9M", "9L35", "8M", "8L35"],
			sandtomb: ["9L51", "8M", "8L51"],
			scaleshot: ["8T"],
			scaryface: ["9M"],
			scorchingsands: ["8T"],
			screech: ["8M"],
			skittersmack: ["8T"],
			skullbash: ["8L1"],
			slam: ["9L42", "8L42"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			stealthrock: ["9M", "8M"],
			stoneedge: ["9M", "8M"],
			substitute: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			wrap: ["9L1", "8L1"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	cramorant: {
		learnset: {
			aerialace: ["8E"],
			agility: ["8M"],
			airslash: ["8M"],
			amnesia: ["8M", "8L42"],
			aquaring: ["8E"],
			assurance: ["8M"],
			attract: ["8M"],
			belch: ["8L1"],
			blizzard: ["8M"],
			bravebird: ["8M"],
			defog: ["8E"],
			dive: ["8M", "8L28"],
			drillpeck: ["8L35"],
			dualwingbeat: ["8T"],
			endure: ["8M"],
			facade: ["8M"],
			featherdance: ["8E"],
			fly: ["8M"],
			furyattack: ["8L14"],
			gigaimpact: ["8M"],
			hurricane: ["8M"],
			hydropump: ["8M", "8L56"],
			hyperbeam: ["8M"],
			icebeam: ["8M"],
			icywind: ["8M"],
			liquidation: ["8M"],
			peck: ["8L1"],
			pluck: ["8L21"],
			protect: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			reversal: ["8M"],
			roost: ["8E"],
			round: ["8M"],
			scald: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			spitup: ["8L1"],
			steelwing: ["8M"],
			stockpile: ["8L1"],
			substitute: ["8M"],
			superpower: ["8M"],
			surf: ["8M"],
			swallow: ["8L1"],
			thief: ["8M"],
			thrash: ["8L49"],
			throatchop: ["8M"],
			uproar: ["8M"],
			watergun: ["8L7"],
			weatherball: ["8M"],
			whirlpool: ["8M"],
		},
	},
	arrokuda: {
		learnset: {
			acupressure: ["9E", "8E"],
			agility: ["9M", "9L18", "8M", "8L18"],
			aquajet: ["9L1", "8L1"],
			assurance: ["8M"],
			attract: ["8M"],
			bite: ["9L12", "8L12"],
			bounce: ["8M"],
			brickbreak: ["9M", "8M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "8M"],
			crunch: ["9M", "9L36", "8M", "8L36"],
			dive: ["9L24", "8M", "8L24"],
			doubleedge: ["9L48", "8L48"],
			drillrun: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			focusenergy: ["9L30", "8M"],
			furyattack: ["9L6", "8L6"],
			hydropump: ["9M"],
			icefang: ["9M", "8M"],
			laserfocus: ["8L30"],
			liquidation: ["9M", "9L42", "8M", "8L42"],
			nightslash: ["9E", "8E"],
			peck: ["9L1", "8L1"],
			poisonjab: ["8M"],
			protect: ["9M", "8M"],
			psychicfangs: ["9M", "8M"],
			raindance: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scald: ["8M"],
			scaleshot: ["8T"],
			slash: ["9E", "8E"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			surf: ["9M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9E", "8E"],
			throatchop: ["8M"],
			waterfall: ["9M", "8M"],
			waterpulse: ["9M"],
			whirlpool: ["8M"],
		},
	},
	barraskewda: {
		learnset: {
			agility: ["9M", "9L18", "8M", "8L18"],
			aquajet: ["9L1", "8L1"],
			assurance: ["8M"],
			attract: ["8M"],
			bite: ["9L1", "8L1"],
			blizzard: ["9M"],
			bounce: ["8M"],
			brickbreak: ["9M", "8M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "8M"],
			crunch: ["9M", "9L40", "8M", "8L40"],
			dive: ["9L24", "8M", "8L24"],
			doubleedge: ["9L56", "8L56"],
			drillrun: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			flipturn: ["8T"],
			focusenergy: ["9L32", "8M"],
			furyattack: ["9L1", "8L1"],
			gigaimpact: ["9M", "8M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M"],
			icefang: ["9M", "8M"],
			laserfocus: ["8L32"],
			liquidation: ["9M", "9L48", "8M", "8L48"],
			peck: ["9L1", "8L1"],
			poisonjab: ["9M", "8M"],
			protect: ["9M", "8M"],
			psychicfangs: ["9M", "8M"],
			raindance: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scald: ["8M"],
			scaleshot: ["8T"],
			scaryface: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			surf: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			throatchop: ["9L1", "8M", "8L1"],
			waterfall: ["9M", "8M"],
			waterpulse: ["9M"],
			whirlpool: ["8M"],
		},
	},
	toxel: {
		learnset: {
			acid: ["9L1", "8L1", "8S0"],
			attract: ["8M"],
			belch: ["9L1", "8L1"],
			charm: ["9M"],
			encore: ["9M", "8M"],
			endeavor: ["9E", "8E"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			flail: ["9L1", "8L1", "8S0"],
			growl: ["9L1", "8L1", "8S0"],
			metalsound: ["9E", "8E"],
			nuzzle: ["9L1", "8L1", "8S0"],
			poweruppunch: ["8E"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			tearfullook: ["9L1", "8L1"],
			terablast: ["9M"],
		},
		eventData: [
			{generation: 8, level: 1, isHidden: false, moves: ["nuzzle", "growl", "flail", "acid"], pokeball: "luxuryball"},
		],
	},
	toxtricity: {
		learnset: {
			acid: ["9L1", "8L1"],
			acidspray: ["9M", "9L1", "8L1"],
			attract: ["8M"],
			belch: ["9L1", "8L1"],
			boomburst: ["9L48", "8L48", "8S0"],
			brickbreak: ["9M"],
			charge: ["9L4", "8L4"],
			chargebeam: ["9M"],
			charm: ["9M"],
			discharge: ["9L36", "8L36"],
			drainpunch: ["9M", "8M"],
			eerieimpulse: ["9M", "8M", "8L1"],
			electricterrain: ["9M"],
			electroball: ["9M", "8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			firepunch: ["9M", "8M"],
			flail: ["9L1", "8L1"],
			fling: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			gunkshot: ["9M", "8M"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			leer: ["9L1", "8L1"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M"],
			nobleroar: ["9L1", "8L1"],
			nuzzle: ["9L1", "8L1"],
			overdrive: ["9L44", "8L44", "8S0"],
			payback: ["8M"],
			poisonjab: ["9M", "9L40", "8M", "8L40"],
			poisontail: ["9M"],
			protect: ["9M", "8M"],
			raindance: ["9M"],
			rest: ["9M", "8M"],
			risingvoltage: ["8T", "8S0"],
			round: ["8M"],
			scaryface: ["9M", "9L12", "8M", "8L12"],
			screech: ["9L24", "8M", "8L24"],
			shiftgear: ["9L52", "8L52"],
			shockwave: ["9L8", "8L8"],
			sleeptalk: ["9M", "8M"],
			sludgebomb: ["9M", "8M"],
			sludgewave: ["8M", "8S0"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			spark: ["9L0", "8L0"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			swagger: ["9L28", "8L28"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L16", "8M", "8L16"],
			tearfullook: ["9L1", "8L1"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["8M"],
			thunder: ["9M", "8M"],
			thunderbolt: ["9M", "8M"],
			thunderfang: ["9M"],
			thunderpunch: ["9M", "8M"],
			thundershock: ["9L1", "8L1"],
			thunderwave: ["9M", "8M"],
			toxic: ["9L32", "8L32"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			venoshock: ["9M", "8M", "8L20"],
			voltswitch: ["9M", "8M"],
			wildcharge: ["9M", "8M"],
		},
		eventData: [
			{generation: 8, level: 50, shiny: true, nature: "Rash", abilities: ["punkrock"], moves: ["overdrive", "sludgewave", "boomburst", "risingvoltage"], pokeball: "cherishball"},
		],
	},
	toxtricitylowkey: {
		learnset: {
			acid: ["9L1", "8L1"],
			acidspray: ["9M", "9L1", "8L1"],
			attract: ["8M"],
			belch: ["9L1", "8L1"],
			boomburst: ["9L48", "8L48"],
			brickbreak: ["9M"],
			charge: ["9L4", "8L4"],
			chargebeam: ["9M"],
			charm: ["9M"],
			discharge: ["9L36", "8L36"],
			drainpunch: ["9M", "8M"],
			eerieimpulse: ["9M", "8M", "8L1"],
			electricterrain: ["9M"],
			electroball: ["9M", "8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			firepunch: ["9M", "8M"],
			flail: ["9L1", "8L1"],
			fling: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			gunkshot: ["9M", "8M"],
			helpinghand: ["9M"],
			hex: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			leer: ["9L1", "8L1"],
			magneticflux: ["9L52", "8L52"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M"],
			nobleroar: ["9L1", "8L1"],
			nuzzle: ["9L1", "8L1"],
			overdrive: ["9L44", "8L44"],
			payback: ["8M"],
			poisonjab: ["9M", "9L40", "8M", "8L40"],
			poisontail: ["9M"],
			protect: ["9M", "8M"],
			raindance: ["9M"],
			rest: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M"],
			scaryface: ["9M", "9L12", "8M", "8L12"],
			screech: ["9L24", "8M", "8L24"],
			shockwave: ["9L8", "8L8"],
			sleeptalk: ["9M", "8M"],
			sludgebomb: ["9M", "8M"],
			sludgewave: ["8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			spark: ["9L0", "8L0"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			swagger: ["9L28", "8L28"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			taunt: ["9M", "9L16", "8M", "8L16"],
			tearfullook: ["9L1", "8L1"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["8M"],
			thunder: ["9M", "8M"],
			thunderbolt: ["9M", "8M"],
			thunderfang: ["9M"],
			thunderpunch: ["9M", "8M"],
			thundershock: ["9L1", "8L1"],
			thunderwave: ["9M", "8M"],
			toxic: ["9L32", "8L32"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			venomdrench: ["8M", "8L20"],
			venoshock: ["9M"],
			voltswitch: ["9M", "8M"],
			wildcharge: ["9M", "8M"],
		},
	},
	sizzlipede: {
		learnset: {
			attract: ["8M"],
			bite: ["8L10"],
			brutalswing: ["8M"],
			bugbite: ["8L20"],
			bugbuzz: ["8M"],
			burnup: ["8L55"],
			coil: ["8L25"],
			crunch: ["8M", "8L40"],
			defensecurl: ["8E"],
			ember: ["8L1"],
			endure: ["8M"],
			facade: ["8M"],
			firelash: ["8L45"],
			firespin: ["8M", "8L35"],
			flamewheel: ["8L15"],
			heatcrash: ["8M"],
			heatwave: ["8M"],
			knockoff: ["8E"],
			leechlife: ["8M"],
			lunge: ["8L50"],
			powerwhip: ["8M"],
			protect: ["8M"],
			rest: ["8M"],
			rollout: ["8E"],
			round: ["8M"],
			scald: ["8M"],
			scorchingsands: ["8T"],
			skittersmack: ["8T"],
			slam: ["8L30"],
			sleeptalk: ["8M"],
			smokescreen: ["8L1"],
			snore: ["8M"],
			strugglebug: ["8E"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			venoshock: ["8M"],
			wrap: ["8L5"],
		},
	},
	centiskorch: {
		learnset: {
			attract: ["8M"],
			bite: ["8L1"],
			brutalswing: ["8M"],
			bugbite: ["8L20"],
			bugbuzz: ["8M"],
			burnup: ["8L67"],
			coil: ["8L25"],
			crunch: ["8M", "8L46"],
			ember: ["8L1"],
			endure: ["8M"],
			facade: ["8M"],
			fireblast: ["8M"],
			firefang: ["8M"],
			firelash: ["8L53"],
			firespin: ["8M", "8L39"],
			flamethrower: ["8M"],
			flamewheel: ["8L15"],
			flareblitz: ["8M"],
			gigaimpact: ["8M"],
			heatcrash: ["8M"],
			heatwave: ["8M"],
			hyperbeam: ["8M"],
			inferno: ["8L1"],
			leechlife: ["8M"],
			lunge: ["8L60"],
			mysticalfire: ["8M"],
			overheat: ["8M"],
			powerwhip: ["8M"],
			protect: ["8M"],
			rest: ["8M"],
			round: ["8M"],
			scald: ["8M"],
			scorchingsands: ["8T"],
			skittersmack: ["8T"],
			slam: ["8L32"],
			sleeptalk: ["8M"],
			smokescreen: ["8L1"],
			snore: ["8M"],
			solarbeam: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			thunderfang: ["8M"],
			venoshock: ["8M"],
			willowisp: ["8M"],
			wrap: ["8L1"],
			xscissor: ["8M"],
		},
	},
	clobbopus: {
		learnset: {
			attract: ["8M"],
			bind: ["8L10"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "8L20"],
			brine: ["8M"],
			bulkup: ["8M", "8L25"],
			circlethrow: ["8E"],
			closecombat: ["8M"],
			coaching: ["8T"],
			detect: ["8L15"],
			dive: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			feint: ["8L5"],
			focusblast: ["8M"],
			icepunch: ["8M"],
			leer: ["8L1"],
			liquidation: ["8M"],
			megapunch: ["8M"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			painsplit: ["8E"],
			payback: ["8M"],
			poweruppunch: ["8E"],
			protect: ["8M"],
			rest: ["8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["8M", "8L40"],
			rocksmash: ["8L1"],
			round: ["8M"],
			seismictoss: ["8E"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			soak: ["8E"],
			submission: ["8L30"],
			substitute: ["8M"],
			suckerpunch: ["8E"],
			superpower: ["8M", "8L45"],
			taunt: ["8M", "8L35"],
			waterfall: ["8M"],
			workup: ["8M"],
		},
	},
	grapploct: {
		learnset: {
			attract: ["8M"],
			bind: ["8L1"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "8L20"],
			brine: ["8M"],
			brutalswing: ["8M"],
			bulkup: ["8M", "8L25"],
			closecombat: ["8M"],
			coaching: ["8T"],
			detect: ["8L15"],
			dig: ["8M"],
			dive: ["8M"],
			drainpunch: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			feint: ["8L1"],
			focusblast: ["8M"],
			gigaimpact: ["8M"],
			hydropump: ["8M"],
			hyperbeam: ["8M"],
			icepunch: ["8M"],
			leer: ["8L1"],
			liquidation: ["8M"],
			megapunch: ["8M"],
			muddywater: ["8M"],
			mudshot: ["8M"],
			octazooka: ["8L1"],
			octolock: ["8L0"],
			payback: ["8M"],
			protect: ["8M"],
			rest: ["8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["8M", "8L40"],
			rocksmash: ["8L1"],
			round: ["8M"],
			scaryface: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stompingtantrum: ["8M"],
			submission: ["8L30"],
			substitute: ["8M"],
			superpower: ["8M", "8L45"],
			surf: ["8M"],
			taunt: ["8M", "8L35"],
			topsyturvy: ["8L50"],
			waterfall: ["8M"],
			whirlpool: ["8M"],
			workup: ["8M"],
		},
	},
	sinistea: {
		learnset: {
			allyswitch: ["9E", "8M"],
			aromatherapy: ["8L30"],
			aromaticmist: ["9L6", "8L6"],
			astonish: ["9L1", "8L1"],
			batonpass: ["9M", "8M"],
			calmmind: ["9M"],
			confuseray: ["9M"],
			darkpulse: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			gigadrain: ["9M", "9L36", "8M", "8L36"],
			hex: ["9M", "8M"],
			imprison: ["9M", "8M"],
			magicalleaf: ["9M"],
			megadrain: ["9L12", "8L12"],
			memento: ["9L54", "8L54"],
			metronome: ["9M", "8M"],
			nastyplot: ["9M", "9L42", "8M", "8L42"],
			nightshade: ["9M"],
			payback: ["8M"],
			phantomforce: ["9M", "8M"],
			poltergeist: ["8T"],
			protect: ["9M", "8M", "8L18"],
			psybeam: ["9M"],
			psychic: ["9M", "8M"],
			psyshock: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			shadowball: ["9M", "9L48", "8M", "8L48"],
			shellsmash: ["9L60", "8L60"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L24", "8L24"],
			sweetscent: ["9L30"],
			terablast: ["9M"],
			trick: ["9M", "8M"],
			trickroom: ["9M"],
			willowisp: ["9M", "8M"],
			withdraw: ["9L1", "8L1"],
			wonderroom: ["8M"],
		},
	},
	sinisteaantique: {
		learnset: {
			allyswitch: ["9E"],
			aromatherapy: ["8S0"],
			aromaticmist: ["9L6"],
			astonish: ["9L1"],
			batonpass: ["9M"],
			calmmind: ["9M"],
			celebrate: ["8S0"],
			confuseray: ["9M"],
			darkpulse: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			foulplay: ["9M"],
			gigadrain: ["9M", "9L36"],
			hex: ["9M"],
			imprison: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L12"],
			memento: ["9L54", "8S0"],
			metronome: ["9M", "8S0"],
			nastyplot: ["9M", "9L42"],
			nightshade: ["9M"],
			phantomforce: ["9M"],
			protect: ["9M"],
			psybeam: ["9M"],
			psychic: ["9M"],
			psyshock: ["9M"],
			rest: ["9M"],
			shadowball: ["9M", "9L48"],
			shellsmash: ["9L60"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			suckerpunch: ["9L24"],
			sweetscent: ["9L30"],
			terablast: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			willowisp: ["9M"],
			withdraw: ["9L1"],
		},
		eventData: [
			{generation: 8, level: 50, isHidden: true, moves: ["memento", "metronome", "aromatherapy", "celebrate"], pokeball: "cherishball"},
		],
	},
	polteageist: {
		learnset: {
			allyswitch: ["8M"],
			aromatherapy: ["8L30"],
			aromaticmist: ["9L1", "8L1"],
			astonish: ["9L1", "8L1"],
			batonpass: ["9M", "8M"],
			calmmind: ["9M"],
			confuseray: ["9M"],
			curse: ["9L66", "8L66"],
			darkpulse: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			gigadrain: ["9M", "9L36", "8M", "8L36"],
			gigaimpact: ["9M", "8M"],
			hex: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			imprison: ["9M", "8M"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M"],
			megadrain: ["9L1", "8L1"],
			memento: ["9L54", "8L54"],
			metronome: ["9M", "8M"],
			nastyplot: ["9M", "9L42", "8M", "8L42"],
			nightshade: ["9M"],
			payback: ["8M"],
			phantomforce: ["9M", "8M"],
			poltergeist: ["8T"],
			protect: ["9M", "9L18", "8M", "8L18"],
			psybeam: ["9M"],
			psychic: ["9M", "8M"],
			psyshock: ["9M", "8M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			selfdestruct: ["8M"],
			shadowball: ["9M", "9L48", "8M", "8L48"],
			shellsmash: ["9L60", "8L60"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			storedpower: ["9M", "8M"],
			strengthsap: ["9L1", "8L1"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L24", "8L24"],
			sweetscent: ["9L30"],
			teatime: ["9L0", "8L0"],
			terablast: ["9M"],
			trick: ["9M", "8M"],
			trickroom: ["9M"],
			willowisp: ["9M", "8M"],
			withdraw: ["9L1", "8L1"],
			wonderroom: ["8M"],
		},
	},
	hatenna: {
		learnset: {
			afteryou: ["9E", "8E"],
			aromatherapy: ["8L15"],
			aromaticmist: ["9L15", "8E"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			calmmind: ["9M", "9L35", "8M", "8L35"],
			charm: ["9M", "8M"],
			confusion: ["9L1", "8L1"],
			darkpulse: ["9M", "8M"],
			dazzlinggleam: ["9M", "9L30", "8M", "8L30"],
			disarmingvoice: ["9M", "9L10", "8L10"],
			drainingkiss: ["9M", "8M"],
			endure: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			gigadrain: ["9M", "8M"],
			healingwish: ["9L45", "8L45"],
			healpulse: ["9L25", "8L25"],
			helpinghand: ["9M", "8M"],
			imprison: ["9M", "8M"],
			lifedew: ["9L5", "8L5"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			metronome: ["9M"],
			mistyterrain: ["9M"],
			mysticalfire: ["9E", "8M"],
			nuzzle: ["9E", "8E"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M", "9L20", "8L20"],
			psychic: ["9M", "9L40", "8M", "8L40"],
			psychicterrain: ["9M"],
			psyshock: ["9M", "8M"],
			quash: ["9E", "8E"],
			reflect: ["9M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			skillswap: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			swift: ["9M"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M"],
			trick: ["9M"],
			trickroom: ["9M"],
		},
	},
	hattrem: {
		learnset: {
			aromatherapy: ["8L15"],
			aromaticmist: ["9L15"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			brutalswing: ["9L0", "8M", "8L0"],
			calmmind: ["9M", "9L37", "8M", "8L37"],
			charm: ["9M", "8M"],
			confusion: ["9L1", "8L1"],
			darkpulse: ["9M", "8M"],
			dazzlinggleam: ["9M", "9L30", "8M", "8L30"],
			disarmingvoice: ["9M", "9L1", "8L1"],
			drainingkiss: ["9M", "8M"],
			endure: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			gigadrain: ["9M", "8M"],
			healingwish: ["9L51", "8L51"],
			healpulse: ["9L25", "8L25"],
			helpinghand: ["9M", "8M"],
			imprison: ["9M", "8M"],
			lifedew: ["9L1", "8L1"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			metronome: ["9M"],
			mistyterrain: ["9M"],
			mysticalfire: ["8M"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M", "9L20", "8L20"],
			psychic: ["9M", "9L44", "8M", "8L44"],
			psychicterrain: ["9M"],
			psyshock: ["9M", "8M"],
			reflect: ["9M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			skillswap: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			swift: ["9M"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M"],
			trick: ["9M"],
			trickroom: ["9M"],
		},
	},
	hatterene: {
		learnset: {
			agility: ["9M"],
			aromatherapy: ["8L15"],
			aromaticmist: ["9L15"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			brutalswing: ["9L1", "8M", "8L1"],
			calmmind: ["9M", "9L37", "8M", "8L37"],
			charm: ["9M", "8M"],
			confusion: ["9L1", "8L1"],
			darkpulse: ["9M", "8M"],
			dazzlinggleam: ["9M", "9L30", "8M", "8L30"],
			disarmingvoice: ["9M", "9L1", "8L1"],
			drainingkiss: ["9M", "8M"],
			endure: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			futuresight: ["8M"],
			gigadrain: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			guardswap: ["8M"],
			healingwish: ["9L55", "8L55"],
			healpulse: ["9L25", "8L25"],
			helpinghand: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			imprison: ["9M", "8M"],
			lifedew: ["9L1", "8L1"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			magicpowder: ["9L64", "8L64"],
			magicroom: ["8M"],
			metronome: ["9M"],
			mistyexplosion: ["8T"],
			mistyterrain: ["9M"],
			mysticalfire: ["8M"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			powerswap: ["8M"],
			powerwhip: ["8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M", "9L20", "8L20"],
			psychic: ["9M", "9L46", "8M", "8L46"],
			psychicterrain: ["9M"],
			psychocut: ["9L0", "8M", "8L0"],
			psyshock: ["9M", "8M"],
			reflect: ["9M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			shadowball: ["9M", "8M"],
			shadowclaw: ["9M", "8M"],
			skillswap: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			swift: ["9M"],
			swordsdance: ["9M", "8M"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M"],
			trick: ["9M"],
			trickroom: ["9M", "8M"],
			wonderroom: ["8M"],
		},
	},
	impidimp: {
		learnset: {
			assurance: ["9L16", "8M", "8L16"],
			attract: ["8M"],
			bite: ["9L4", "8L4"],
			burningjealousy: ["8T"],
			chillingwater: ["9M"],
			confide: ["9L1", "8L1"],
			darkpulse: ["9M", "9L33", "8M", "8L33"],
			dazzlinggleam: ["9M", "8M"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fakeout: ["9L1", "8L1"],
			faketears: ["9M", "9L12", "8M", "8L12"],
			flatter: ["9L8", "8L8"],
			fling: ["9M"],
			foulplay: ["9M", "9L44", "8M", "8L44"],
			lashout: ["8T"],
			leechlife: ["9M", "8M"],
			lightscreen: ["9M"],
			lowkick: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M", "8M"],
			mistyterrain: ["9M"],
			nastyplot: ["9M", "9L36", "8M", "8L36"],
			partingshot: ["9E"],
			playrough: ["9M", "9L40", "8M", "8L40"],
			protect: ["9M", "8M"],
			reflect: ["9M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			round: ["8M"],
			scaryface: ["9M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L24", "8L24"],
			swagger: ["9L20", "8L20"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunderwave: ["9M", "8M"],
			torment: ["9L28", "8L28"],
			trailblaze: ["9M"],
			trick: ["9M", "8M"],
			uproar: ["8M"],
		},
	},
	morgrem: {
		learnset: {
			assurance: ["9L16", "8M", "8L16"],
			attract: ["8M"],
			bite: ["9L1", "8L1"],
			burningjealousy: ["8T"],
			chillingwater: ["9M"],
			confide: ["9L1", "8L1"],
			darkpulse: ["9M", "9L35", "8M", "8L35"],
			dazzlinggleam: ["9M", "8M"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fakeout: ["9L1", "8L1"],
			faketears: ["9M", "9L12", "8M", "8L12"],
			falsesurrender: ["9L0", "8L0"],
			flatter: ["9L1", "8L1"],
			fling: ["9M"],
			foulplay: ["9M", "9L52", "8M", "8L52"],
			imprison: ["9M"],
			lashout: ["8T"],
			leechlife: ["9M", "8M"],
			lightscreen: ["9M", "8M"],
			lowkick: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M", "8M"],
			mistyterrain: ["9M"],
			nastyplot: ["9M", "9L40", "8M", "8L40"],
			playrough: ["9M", "9L46", "8M", "8L46"],
			protect: ["9M", "8M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			round: ["8M"],
			scaryface: ["9M"],
			shadowclaw: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L24", "8L24"],
			swagger: ["9L20", "8L20"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			throatchop: ["8M"],
			thunderwave: ["9M", "8M"],
			torment: ["9L28", "8L28"],
			trailblaze: ["9M"],
			trick: ["9M", "8M"],
			uproar: ["8M"],
		},
	},
	grimmsnarl: {
		learnset: {
			assurance: ["9L16", "8M", "8L16"],
			attract: ["8M"],
			bite: ["9L1", "8L1"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M"],
			bulkup: ["9M", "9L1", "8M", "8L1"],
			burningjealousy: ["8T"],
			chillingwater: ["9M"],
			confide: ["9L1", "8L1"],
			crunch: ["9M", "8M"],
			darkestlariat: ["8M"],
			darkpulse: ["9M", "9L35", "8M", "8L35"],
			dazzlinggleam: ["9M", "8M"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fakeout: ["9L1", "8L1"],
			faketears: ["9M", "9L12", "8M", "8L12"],
			falsesurrender: ["9L1", "8L1"],
			firepunch: ["9M", "8M"],
			flatter: ["9L1", "8L1"],
			fling: ["9M"],
			focusblast: ["9M", "8M"],
			focusenergy: ["8M"],
			foulplay: ["9M", "9L56", "8M", "8L56"],
			gigaimpact: ["9M", "8M"],
			hammerarm: ["9L64", "8L64"],
			hyperbeam: ["9M", "8M"],
			icepunch: ["9M", "8M"],
			imprison: ["9M"],
			lashout: ["8T"],
			leechlife: ["9M", "8M"],
			lightscreen: ["9M", "8M"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["9M", "8M"],
			mistyterrain: ["9M"],
			nastyplot: ["9M", "9L40", "8M", "8L40"],
			playrough: ["9M", "9L48", "8M", "8L48"],
			powerswap: ["8M"],
			poweruppunch: ["8L1"],
			powerwhip: ["8M"],
			protect: ["9M", "8M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			round: ["8M"],
			scaryface: ["9M", "8M"],
			shadowclaw: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			spiritbreak: ["9L0", "8L0"],
			stompingtantrum: ["9M", "8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L24", "8L24"],
			superpower: ["8M"],
			swagger: ["9L20", "8L20"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			throatchop: ["8M"],
			thunderpunch: ["9M", "8M"],
			thunderwave: ["9M", "8M"],
			torment: ["9L28", "8L28"],
			trailblaze: ["9M"],
			trick: ["9M", "8M"],
			uproar: ["8M"],
			wonderroom: ["8M"],
		},
	},
	milcery: {
		learnset: {
			acidarmor: ["8L30"],
			aromatherapy: ["8L20"],
			aromaticmist: ["8L1"],
			attract: ["8M", "8L25", "8S0"],
			babydolleyes: ["8E"],
			celebrate: ["8S0"],
			charm: ["8M"],
			dazzlinggleam: ["8M", "8L35"],
			drainingkiss: ["8M", "8L15"],
			endure: ["8M"],
			entrainment: ["8L50", "8S0"],
			facade: ["8M"],
			fling: ["8M"],
			helpinghand: ["8M"],
			lastresort: ["8E", "8S0"],
			mistyterrain: ["8M", "8L45"],
			protect: ["8M"],
			recover: ["8L40"],
			rest: ["8M"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			storedpower: ["8M"],
			substitute: ["8M"],
			sweetkiss: ["8L5"],
			sweetscent: ["8L10"],
			tackle: ["8L1"],
		},
		eventData: [
			{generation: 8, level: 5, nature: "Hardy", isHidden: false, moves: ["celebrate", "lastresort", "entrainment", "attract"], pokeball: "cherishball"},
		],
	},
	alcremie: {
		learnset: {
			acidarmor: ["8L30"],
			aromatherapy: ["8L20"],
			aromaticmist: ["8L1"],
			attract: ["8M", "8L25"],
			calmmind: ["8M"],
			charm: ["8M"],
			dazzlinggleam: ["8M", "8L35"],
			decorate: ["8L0"],
			drainingkiss: ["8M", "8L15"],
			drainpunch: ["8M"],
			encore: ["8M"],
			endure: ["8M"],
			energyball: ["8M"],
			entrainment: ["8L50"],
			facade: ["8M"],
			faketears: ["8M"],
			fling: ["8M"],
			gigadrain: ["8M"],
			gigaimpact: ["8M"],
			helpinghand: ["8M"],
			hyperbeam: ["8M"],
			imprison: ["8M"],
			lightscreen: ["8M"],
			magicalleaf: ["8M"],
			magicroom: ["8M"],
			metronome: ["8M"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M", "8L45"],
			mysticalfire: ["8M"],
			playrough: ["8M"],
			protect: ["8M"],
			psychic: ["8M"],
			psyshock: ["8M"],
			recover: ["8L40"],
			rest: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			storedpower: ["8M"],
			substitute: ["8M"],
			sweetkiss: ["8L1"],
			sweetscent: ["8L1"],
			tackle: ["8L1"],
			triattack: ["8M"],
			wonderroom: ["8M"],
		},
	},
	falinks: {
		learnset: {
			agility: ["9M", "8M"],
			assurance: ["8M"],
			beatup: ["8M"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M"],
			bulkup: ["9M", "9L20", "8M", "8L20"],
			closecombat: ["9M", "9L50", "8M", "8L50"],
			coaching: ["8T"],
			counter: ["9L60", "8L60"],
			endure: ["9M", "9L25", "8M", "8L25"],
			facade: ["9M", "8M"],
			falseswipe: ["9M", "8M"],
			firstimpression: ["9L35", "8L35"],
			focusblast: ["9M", "8M"],
			focusenergy: ["9L10", "8M", "8L10"],
			gigaimpact: ["9M", "8M"],
			headbutt: ["9L15", "8L15"],
			helpinghand: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			irondefense: ["9M", "9L45", "8M", "8L45"],
			ironhead: ["9M", "8M"],
			megahorn: ["9L55", "8M", "8L55"],
			noretreat: ["9L40", "8L40"],
			payback: ["8M"],
			poisonjab: ["9M", "8M"],
			protect: ["9M", "9L1", "8M", "8L1"],
			raindance: ["9M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "9L30", "8M", "8L30"],
			rockslide: ["9M", "8M"],
			rocksmash: ["9L5", "8L5"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			screech: ["8M"],
			sleeptalk: ["9M", "8M"],
			smartstrike: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			superpower: ["8M"],
			swordsdance: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			throatchop: ["8M"],
			trailblaze: ["9M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	pincurchin: {
		learnset: {
			acupressure: ["9L55", "8L55"],
			assurance: ["8M"],
			attract: ["8M"],
			bodyslam: ["9M"],
			brine: ["8M"],
			bubblebeam: ["9L25", "8L25"],
			charge: ["9L10", "8L10"],
			chargebeam: ["9M"],
			chillingwater: ["9M"],
			curse: ["9L35", "8L35"],
			discharge: ["9L60", "8L60"],
			electricterrain: ["9M", "9L40", "8M", "8L40"],
			electroball: ["9M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			furyattack: ["9L15", "8L15"],
			gigaimpact: ["9M"],
			hex: ["9M", "8M"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M"],
			liquidation: ["9M", "8M"],
			memento: ["9E", "8E"],
			muddywater: ["8M"],
			payback: ["8M"],
			peck: ["9L1", "8L1"],
			pinmissile: ["8M"],
			poisonjab: ["9M", "9L45", "8M", "8L45"],
			protect: ["9M", "8M"],
			raindance: ["9M", "8M"],
			recover: ["9L30", "8L30"],
			rest: ["9M", "8M"],
			reversal: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M"],
			scald: ["8M"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			spark: ["9L20", "8L20"],
			spikes: ["9M", "8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9E", "8E"],
			surf: ["9M", "8M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			throatchop: ["8M"],
			thunder: ["9M", "8M"],
			thunderbolt: ["9M", "8M"],
			thundershock: ["9L1", "8L1"],
			thunderwave: ["9M", "8M"],
			toxicspikes: ["9M", "8M"],
			venomdrench: ["8M"],
			venoshock: ["9M", "8M"],
			watergun: ["9L5", "8L5"],
			wildcharge: ["9M"],
			zingzap: ["9L50", "8L50"],
		},
	},
	snom: {
		learnset: {
			attract: ["8M"],
			bugbite: ["9E", "8E"],
			bugbuzz: ["9M", "8M"],
			endure: ["8M"],
			facade: ["9M", "8M"],
			fairywind: ["9E", "8E"],
			iciclespear: ["8M"],
			icywind: ["9M", "8M"],
			mirrorcoat: ["9E", "8E"],
			pounce: ["9M"],
			powdersnow: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			strugglebug: ["9M", "9L1", "8L1"],
			substitute: ["9M", "8M"],
			terablast: ["9M"],
		},
	},
	frosmoth: {
		learnset: {
			acrobatics: ["9M", "8M"],
			airslash: ["9M", "8M"],
			attract: ["9L1", "8M", "8L1"],
			aurorabeam: ["9L24", "8L24"],
			auroraveil: ["9L36", "8L36"],
			avalanche: ["9M", "8M"],
			blizzard: ["9M", "9L40", "8M", "8L40"],
			bugbuzz: ["9M", "9L32", "8M", "8L32"],
			calmmind: ["9M", "8M"],
			dazzlinggleam: ["9M", "8M"],
			defog: ["9L16", "8L16"],
			dualwingbeat: ["8T"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			featherdance: ["9L21", "8L21"],
			gigadrain: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			hail: ["8M", "8L28"],
			helpinghand: ["9M", "9L1", "8M", "8L1"],
			hurricane: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			icespinner: ["9M"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L0", "8M", "8L0"],
			imprison: ["9M", "8M"],
			infestation: ["9L8", "8L8"],
			leechlife: ["9M", "8M"],
			lightscreen: ["9M", "8M"],
			mist: ["9L12", "8L12"],
			playrough: ["9M", "8M"],
			pounce: ["9M"],
			powdersnow: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			quiverdance: ["9L52", "8L52"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			snowscape: ["9M", "9L28"],
			strugglebug: ["9M", "9L1", "8L1"],
			stunspore: ["9L4", "8L4"],
			substitute: ["9M", "8M"],
			swift: ["9M"],
			tailwind: ["9M", "9L44", "8L44"],
			takedown: ["9M"],
			terablast: ["9M"],
			tripleaxel: ["8T"],
			uturn: ["9M", "8M"],
			weatherball: ["8M"],
			wideguard: ["9L48", "8L48"],
		},
	},
	stonjourner: {
		learnset: {
			ancientpower: ["9E", "8E"],
			assurance: ["8M"],
			attract: ["8M"],
			block: ["9L1", "8L1"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "9L42", "8M", "8L42"],
			brutalswing: ["8M"],
			bulldoze: ["9M", "8M"],
			curse: ["9E", "8E"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			gravity: ["9L18", "8L18"],
			heatcrash: ["8M"],
			heavyslam: ["9M", "9L54", "8M", "8L54"],
			hyperbeam: ["9M", "8M"],
			imprison: ["9M", "8M"],
			irondefense: ["9M", "8M"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			megakick: ["9L66", "8M", "8L66"],
			meteorbeam: ["8T"],
			powergem: ["9M"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			rockblast: ["9M", "8M"],
			rockpolish: ["9L6", "8L6"],
			rockslide: ["9M", "9L36", "8M", "8L36"],
			rockthrow: ["9L1", "8L1"],
			rocktomb: ["9M", "9L12", "8M", "8L12"],
			round: ["8M"],
			safeguard: ["8M"],
			sandstorm: ["9M", "8M"],
			sandtomb: ["8M"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			stealthrock: ["9M", "9L30", "8M", "8L30"],
			stomp: ["9L24", "8L24"],
			stompingtantrum: ["9M", "8M"],
			stoneedge: ["9M", "9L60", "8M", "8L60"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			superpower: ["8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			wideguard: ["9L48", "8L48"],
			wonderroom: ["8M"],
		},
	},
	eiscue: {
		learnset: {
			agility: ["9M", "8M"],
			amnesia: ["9M", "9L30", "8M", "8L30"],
			aquaring: ["9E", "8E"],
			attract: ["8M"],
			auroraveil: ["9L48", "8L48"],
			avalanche: ["9M", "8M"],
			bellydrum: ["9E", "8E"],
			blizzard: ["9M", "9L60", "8M", "8L60"],
			bodyslam: ["9M"],
			brine: ["8M"],
			chillingwater: ["9M"],
			dive: ["8M"],
			doubleedge: ["9E", "8E"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			freezedry: ["9L36", "8L36"],
			gigaimpact: ["9M"],
			hail: ["8M", "8L42"],
			headbutt: ["9L24", "8L24"],
			headsmash: ["9E", "8E"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "8M"],
			icepunch: ["9M", "8M"],
			icespinner: ["9M"],
			iciclecrash: ["9E", "8E"],
			iciclespear: ["8M"],
			icywind: ["9M", "9L18", "8M", "8L18"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M", "8M"],
			liquidation: ["9M", "8M"],
			mist: ["9L6", "8L6"],
			powdersnow: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			raindance: ["9M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			snowscape: ["9M", "9L42"],
			soak: ["9E", "8E"],
			substitute: ["9M", "8M"],
			surf: ["9M", "9L54", "8M", "8L54"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			waterfall: ["9M", "8M"],
			waterpulse: ["9M"],
			weatherball: ["9L12", "8M", "8L12"],
			whirlpool: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	indeedee: {
		learnset: {
			afteryou: ["9L25", "8L25"],
			allyswitch: ["8M"],
			aromatherapy: ["8L30"],
			attract: ["8M"],
			bodyslam: ["9M"],
			calmmind: ["9M", "9L40", "8M", "8L40"],
			dazzlinggleam: ["9M", "8M"],
			disarmingvoice: ["9M", "9L10", "8L10"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			encore: ["9M", "9L5", "8M", "8L5"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M"],
			expandingforce: ["8T"],
			extrasensory: ["9E", "8E"],
			facade: ["9M", "8M"],
			fakeout: ["9E", "8E"],
			futuresight: ["8M"],
			healingwish: ["9L30"],
			helpinghand: ["9M", "9L20", "8M", "8L20"],
			hypervoice: ["9M", "8M"],
			imprison: ["9M", "8M"],
			lastresort: ["9L55", "8L55"],
			magicalleaf: ["9M", "8M"],
			magicroom: ["8M"],
			metronome: ["9M", "8M"],
			mysticalfire: ["8M"],
			payday: ["8M"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			powersplit: ["9L45", "8L45"],
			powerswap: ["8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M", "9L15", "8L15"],
			psychic: ["9M", "9L35", "8M", "8L35"],
			psychicterrain: ["9M", "9L50", "8M", "8L50"],
			psychup: ["9E", "8E"],
			psyshock: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			shadowball: ["9M", "8M"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			storedpower: ["9M", "9L1", "8M", "8L1"],
			substitute: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			triattack: ["8M"],
			trick: ["9M", "8M"],
			trickroom: ["9M", "8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	indeedeef: {
		learnset: {
			allyswitch: ["8M"],
			aromatherapy: ["8L30"],
			attract: ["8M"],
			batonpass: ["9M", "9L5", "8M", "8L5"],
			bodyslam: ["9M"],
			calmmind: ["9M", "9L40", "8M", "8L40"],
			charm: ["9M"],
			dazzlinggleam: ["9M", "8M"],
			disarmingvoice: ["9M", "9L10", "8L10"],
			drainingkiss: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "8M"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			fakeout: ["9E", "8E"],
			followme: ["9L25", "8L25"],
			futuresight: ["8M"],
			guardsplit: ["9L45", "8L45"],
			guardswap: ["8M"],
			healingwish: ["9L30", "8L55"],
			healpulse: ["9E", "8E"],
			helpinghand: ["9M", "9L20", "8M", "8L20"],
			hypervoice: ["9M", "9S0", "8M"],
			imprison: ["9M", "8M"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			metronome: ["9M", "8M"],
			mysticalfire: ["8M"],
			payday: ["8M"],
			playnice: ["9L1", "8L1"],
			playrough: ["9M", "8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M", "9L15", "8L15"],
			psychic: ["9M", "9L35", "9S0", "8M", "8L35"],
			psychicterrain: ["9M", "9L50", "8M", "8L50"],
			psychoshift: ["8E"],
			psychup: ["9E", "8E"],
			psyshock: ["9M", "8M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			shadowball: ["9M", "9S0", "8M"],
			skillswap: ["9M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			storedpower: ["9M", "9L1", "8M", "8L1"],
			substitute: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			terrainpulse: ["8T"],
			trick: ["9M", "8M"],
			trickroom: ["9S0"],
			zenheadbutt: ["9M", "8M"],
		},
		eventData: [
			{generation: 9, level: 75, shiny: 1, perfectIVs: 4, moves: ["psychic", "hypervoice", "shadowball", "trickroom"]},
		],
	},
	morpeko: {
		learnset: {
			agility: ["8M", "8L40"],
			assurance: ["8M"],
			attract: ["8M"],
			aurawheel: ["8L55"],
			bite: ["8L25"],
			brickbreak: ["8M"],
			bulletseed: ["8M", "8L45"],
			charge: ["8E"],
			crunch: ["8M", "8L50"],
			darkpulse: ["8M"],
			electricterrain: ["8M"],
			electroball: ["8M"],
			electroweb: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			fakeout: ["8E"],
			faketears: ["8M"],
			firefang: ["8M"],
			flatter: ["8L20"],
			fling: ["8M"],
			foulplay: ["8M"],
			icefang: ["8M"],
			lashout: ["8T"],
			leer: ["8L5"],
			nastyplot: ["8M"],
			outrage: ["8M"],
			partingshot: ["8E"],
			payback: ["8M"],
			powertrip: ["8L10"],
			protect: ["8M"],
			psychicfangs: ["8M"],
			quash: ["8E"],
			quickattack: ["8L15"],
			rapidspin: ["8E"],
			rest: ["8M"],
			revenge: ["8M"],
			risingvoltage: ["8T"],
			round: ["8M"],
			scaryface: ["8M"],
			seedbomb: ["8M"],
			sleeptalk: ["8M"],
			snarl: ["8M"],
			snore: ["8M"],
			spark: ["8L30"],
			stompingtantrum: ["8M"],
			substitute: ["8M"],
			superfang: ["8E"],
			swagger: ["8E"],
			swift: ["8M"],
			tailwhip: ["8L1"],
			taunt: ["8M"],
			thief: ["8M"],
			thrash: ["8L60"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderfang: ["8M"],
			thunderpunch: ["8M"],
			thundershock: ["8L1"],
			thunderwave: ["8M"],
			tickle: ["8E"],
			torment: ["8L35"],
			uproar: ["8M"],
			voltswitch: ["8M"],
			wildcharge: ["8M"],
		},
	},
	cufant: {
		learnset: {
			attract: ["8M"],
			belch: ["9E", "8E"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M"],
			brutalswing: ["8M"],
			bulldoze: ["9M", "9L15", "8M", "8L15"],
			curse: ["9E", "8E"],
			defensecurl: ["9E", "8E"],
			dig: ["9M", "9L30", "8M", "8L30"],
			doubleedge: ["9E", "8E"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fissure: ["9E", "8E"],
			flashcannon: ["9M"],
			fling: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			heavyslam: ["9M"],
			highhorsepower: ["9L50", "8M", "8L50"],
			irondefense: ["9M", "9L25", "8M", "8L25"],
			ironhead: ["9M", "9L40", "8M", "8L40"],
			megakick: ["8M"],
			mudshot: ["9M", "8M"],
			playrough: ["9M", "9L45", "8M", "8L45"],
			powerwhip: ["8M"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			rockblast: ["9M", "8M"],
			rockslide: ["9M", "8M"],
			rocksmash: ["9L10", "8L10"],
			rocktomb: ["9M", "8M"],
			rollout: ["9L5", "8L5"],
			round: ["8M"],
			sandstorm: ["9M"],
			screech: ["8M"],
			slam: ["9E", "8E"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			stealthrock: ["9M", "8M"],
			steelbeam: ["9M", "8T"],
			steelroller: ["8T"],
			stomp: ["9L20", "8L20"],
			stompingtantrum: ["9M", "8M"],
			stoneedge: ["9M"],
			strength: ["9L35", "8L35"],
			substitute: ["9M", "8M"],
			superpower: ["9L55", "8M", "8L55"],
			swagger: ["9E", "8E"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			whirlwind: ["9E", "8E"],
			workup: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	copperajah: {
		learnset: {
			attract: ["8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M"],
			brutalswing: ["8M"],
			bulldoze: ["9M", "9L15", "8M", "8L15"],
			dig: ["9M", "9L30", "8M", "8L30"],
			earthpower: ["9M", "8M"],
			earthquake: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			flashcannon: ["9M", "8M"],
			fling: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			growl: ["9L1", "8L1"],
			heatcrash: ["8M"],
			heavyslam: ["9M", "9L0", "8M", "8L0"],
			highhorsepower: ["9L58", "8M", "8L58"],
			hyperbeam: ["9M", "8M"],
			irondefense: ["9M", "9L25", "8M", "8L25"],
			ironhead: ["9M", "9L44", "8M", "8L44"],
			megakick: ["8M"],
			mudshot: ["9M", "8M"],
			outrage: ["9M", "8M"],
			payback: ["8M"],
			playrough: ["9M", "9L51", "8M", "8L51"],
			powerwhip: ["8M"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			revenge: ["8M"],
			rockblast: ["9M", "8M"],
			rockslide: ["9M", "8M"],
			rocksmash: ["9L1", "8L1"],
			rocktomb: ["9M", "8M"],
			rollout: ["9L1", "8L1"],
			round: ["8M"],
			sandstorm: ["9M"],
			scaryface: ["8M"],
			screech: ["8M"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			stealthrock: ["9M", "8M"],
			steelbeam: ["9M", "8T"],
			steelroller: ["8T"],
			stomp: ["9L20", "8L20"],
			stompingtantrum: ["9M", "8M"],
			stoneedge: ["9M", "8M"],
			strength: ["9L37", "8L37"],
			substitute: ["9M", "8M"],
			superpower: ["9L65", "8M", "8L65"],
			tackle: ["9L1", "8L1"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			workup: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	dracozolt: {
		learnset: {
			aerialace: ["8L14"],
			ancientpower: ["8L21"],
			bodyslam: ["8M"],
			boltbeak: ["8L63"],
			breakingswipe: ["8M"],
			brutalswing: ["8M"],
			bulldoze: ["8M"],
			charge: ["8L7", "8S0"],
			discharge: ["8L56"],
			dracometeor: ["8T"],
			dragonclaw: ["8M"],
			dragonpulse: ["8M", "8L70"],
			dragonrush: ["8L77"],
			dragontail: ["8L35"],
			earthpower: ["8M"],
			earthquake: ["8M"],
			electroball: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			fireblast: ["8M"],
			firefang: ["8M"],
			firespin: ["8M"],
			flamethrower: ["8M"],
			gigaimpact: ["8M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M"],
			irontail: ["8M"],
			lowkick: ["8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			meteorbeam: ["8T"],
			outrage: ["8M"],
			pluck: ["8L28"],
			protect: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			risingvoltage: ["8T"],
			rockblast: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			slam: ["8L49"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stomp: ["8L42"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			tackle: ["8L1", "8S0"],
			taunt: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderfang: ["8M"],
			thunderpunch: ["8M"],
			thundershock: ["8L1", "8S0"],
			thunderwave: ["8M"],
			wildcharge: ["8M"],
		},
		eventData: [
			{generation: 8, level: 10, shiny: 1, perfectIVs: 3, moves: ["tackle", "thundershock", "charge"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	arctozolt: {
		learnset: {
			ancientpower: ["8L21"],
			avalanche: ["8M", "8L35"],
			blizzard: ["8M", "8L77"],
			bodyslam: ["8M"],
			boltbeak: ["8L63"],
			bulldoze: ["8M"],
			charge: ["8L7", "8S0"],
			discharge: ["8L56"],
			echoedvoice: ["8L14"],
			electroball: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			freezedry: ["8L42"],
			gigaimpact: ["8M"],
			hail: ["8M"],
			hydropump: ["8M"],
			hyperbeam: ["8M"],
			hypervoice: ["8M"],
			icebeam: ["8M"],
			icefang: ["8M"],
			iciclecrash: ["8L70"],
			iciclespear: ["8M"],
			icywind: ["8M"],
			irontail: ["8M"],
			lowkick: ["8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			meteorbeam: ["8T"],
			payback: ["8M"],
			pluck: ["8L28"],
			powdersnow: ["8L1", "8S0"],
			protect: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			risingvoltage: ["8T"],
			rockblast: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			slam: ["8L49"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M"],
			substitute: ["8M"],
			surf: ["8M"],
			taunt: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderfang: ["8M"],
			thunderpunch: ["8M"],
			thundershock: ["8L1", "8S0"],
			thunderwave: ["8M"],
			wildcharge: ["8M"],
		},
		eventData: [
			{generation: 8, level: 10, shiny: 1, perfectIVs: 3, moves: ["powdersnow", "thundershock", "charge"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	dracovish: {
		learnset: {
			ancientpower: ["8L21"],
			bite: ["8L28"],
			bodyslam: ["8M"],
			brine: ["8M"],
			brutalswing: ["8M", "8L14"],
			bulldoze: ["8M"],
			crunch: ["8M", "8L56"],
			dive: ["8M"],
			dracometeor: ["8T"],
			dragonbreath: ["8L35"],
			dragonpulse: ["8M", "8L70"],
			dragonrush: ["8L77", "8S1"],
			earthpower: ["8M"],
			earthquake: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			fishiousrend: ["8L63", "8S1"],
			gigaimpact: ["8M"],
			hydropump: ["8M"],
			hyperbeam: ["8M"],
			icefang: ["8M", "8S1"],
			ironhead: ["8M"],
			leechlife: ["8M"],
			liquidation: ["8M"],
			lowkick: ["8M"],
			megakick: ["8M"],
			meteorbeam: ["8T"],
			outrage: ["8M"],
			protect: ["8M", "8L7", "8S0"],
			psychicfangs: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			rockblast: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			scald: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stomp: ["8L42"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M"],
			substitute: ["8M"],
			superfang: ["8L49"],
			surf: ["8M"],
			tackle: ["8L1", "8S0"],
			waterfall: ["8M"],
			watergun: ["8L1", "8S1", "8S0"],
			whirlpool: ["8M"],
			zenheadbutt: ["8M"],
		},
		eventData: [
			{generation: 8, level: 10, shiny: 1, perfectIVs: 3, moves: ["tackle", "watergun", "protect"], pokeball: "pokeball"},
			{generation: 8, level: 80, nature: "Naive", abilities: ["strongjaw"], ivs: {hp: 30, atk: 31, def: 31, spa: 30, spd: 30, spe: 31}, moves: ["fishiousrend", "dragonrush", "icefang", "watergun"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	arctovish: {
		learnset: {
			ancientpower: ["8L21"],
			auroraveil: ["8L35"],
			avalanche: ["8M"],
			bite: ["8L28"],
			blizzard: ["8M", "8L77"],
			bodyslam: ["8M"],
			brine: ["8M"],
			crunch: ["8M", "8L56"],
			dive: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			fishiousrend: ["8L63"],
			freezedry: ["8L42"],
			gigaimpact: ["8M"],
			hail: ["8M"],
			hydropump: ["8M"],
			hyperbeam: ["8M"],
			icebeam: ["8M"],
			icefang: ["8M"],
			iciclecrash: ["8L70"],
			iciclespear: ["8M"],
			icywind: ["8M", "8L14"],
			irondefense: ["8M"],
			ironhead: ["8M"],
			liquidation: ["8M"],
			meteorbeam: ["8T"],
			powdersnow: ["8L1", "8S0"],
			protect: ["8M", "8L7", "8S0"],
			psychicfangs: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			rockblast: ["8M"],
			rockslide: ["8M"],
			rocktomb: ["8M"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stoneedge: ["8M"],
			substitute: ["8M"],
			superfang: ["8L49"],
			surf: ["8M"],
			waterfall: ["8M"],
			watergun: ["8L1", "8S0"],
			whirlpool: ["8M"],
			zenheadbutt: ["8M"],
		},
		eventData: [
			{generation: 8, level: 10, shiny: 1, perfectIVs: 3, moves: ["powdersnow", "watergun", "protect"], pokeball: "pokeball"},
		],
		eventOnly: true,
	},
	duraludon: {
		learnset: {
			attract: ["8M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			breakingswipe: ["8M", "8L24"],
			brickbreak: ["8M"],
			darkpulse: ["8M"],
			dracometeor: ["8T"],
			dragonclaw: ["8M", "8L48"],
			dragonpulse: ["8M"],
			dragontail: ["8L30"],
			endure: ["8M"],
			facade: ["8M"],
			flashcannon: ["8M", "8L54"],
			foulplay: ["8M"],
			gigaimpact: ["8M"],
			gyroball: ["8M"],
			heavyslam: ["8M"],
			honeclaws: ["8L12"],
			hyperbeam: ["8M", "8L66"],
			irondefense: ["8M", "8L36"],
			ironhead: ["8M"],
			laserfocus: ["8L42"],
			leer: ["8L1"],
			lightscreen: ["8M"],
			metalburst: ["8L60"],
			metalclaw: ["8L1"],
			metalsound: ["8L18"],
			mirrorcoat: ["8E"],
			nightslash: ["8E"],
			outrage: ["8M"],
			protect: ["8M"],
			reflect: ["8M"],
			rest: ["8M"],
			rockslide: ["8M"],
			rocksmash: ["8L6"],
			rocktomb: ["8M"],
			round: ["8M"],
			scaryface: ["8M"],
			screech: ["8M"],
			slash: ["8E"],
			sleeptalk: ["8M"],
			snarl: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			stealthrock: ["8M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M"],
			substitute: ["8M"],
			swordsdance: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderwave: ["8M"],
		},
	},
	dreepy: {
		learnset: {
			astonish: ["9L1", "8L1"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			bite: ["9L1", "8L1"],
			confuseray: ["9M", "9E", "8E"],
			curse: ["9E", "8E"],
			disable: ["9E", "8E"],
			doubleteam: ["9E", "8E"],
			dracometeor: ["9M", "8T"],
			dragontail: ["9M", "9E", "8E"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			grudge: ["8E"],
			helpinghand: ["9M", "8M"],
			infestation: ["9L1", "8L1"],
			protect: ["9M", "8M"],
			quickattack: ["9L1", "8L1"],
			rest: ["9M", "8M"],
			round: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9E", "8E"],
			swift: ["9M", "8M"],
			terablast: ["9M"],
			thunderwave: ["9M", "8M"],
		},
	},
	drakloak: {
		learnset: {
			acrobatics: ["9M", "8M"],
			agility: ["9M", "9L24", "8M", "8L24"],
			allyswitch: ["8M"],
			assurance: ["9L12", "8M", "8L12"],
			astonish: ["9L1", "8L1"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			beatup: ["8M"],
			bite: ["9L1", "8L1"],
			breakingswipe: ["8M"],
			brine: ["8M"],
			dive: ["8M"],
			doubleedge: ["9L66", "8L66"],
			doublehit: ["9L30", "8L30"],
			dracometeor: ["9M", "8T"],
			dragondance: ["9M", "9L42", "8M", "8L42"],
			dragonpulse: ["9M", "9L0", "8M", "8L0"],
			dragonrush: ["9L61", "8L61"],
			dragontail: ["9M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			flamethrower: ["9M", "8M"],
			helpinghand: ["9M", "8M"],
			hex: ["9M", "9L18", "8M", "8L18"],
			hydropump: ["9M", "8M"],
			infestation: ["9L1", "8L1"],
			lastresort: ["9L72", "8L72"],
			lightscreen: ["9M"],
			lockon: ["9L6", "8L6"],
			nightshade: ["9M"],
			outrage: ["9M", "8M"],
			phantomforce: ["9M", "9L48", "8M", "8L48"],
			pounce: ["9M"],
			protect: ["9M", "8M"],
			psychicfangs: ["9M", "8M"],
			quickattack: ["9L1", "8L1"],
			reflect: ["9M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scald: ["8M"],
			shadowball: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			steelwing: ["8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			surf: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M", "9L54", "8L54"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "8M"],
			thunderbolt: ["9M", "8M"],
			thunderwave: ["9M", "8M"],
			uturn: ["9M", "9L36", "8M", "8L36"],
			willowisp: ["9M", "8M"],
		},
	},
	dragapult: {
		learnset: {
			acrobatics: ["9M", "8M"],
			agility: ["9M", "9L24", "8M", "8L24"],
			allyswitch: ["8M"],
			assurance: ["9L12", "8M", "8L12"],
			astonish: ["9L1", "8L1"],
			attract: ["8M"],
			batonpass: ["9M", "8M"],
			beatup: ["8M"],
			bite: ["9L1", "8L1"],
			bodyslam: ["9M", "8M"],
			breakingswipe: ["8M"],
			brine: ["8M"],
			dive: ["8M"],
			doubleedge: ["9L70", "8L70"],
			doublehit: ["9L30", "8L30"],
			dracometeor: ["9M", "8T"],
			dragonbreath: ["9L1", "8L1"],
			dragonclaw: ["9M", "8M"],
			dragondance: ["9M", "9L42", "8M", "8L42"],
			dragondarts: ["9L0", "8L0"],
			dragonpulse: ["9M", "8M"],
			dragonrush: ["9L63", "8L63"],
			dragontail: ["9M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			fireblast: ["9M", "8M"],
			flamethrower: ["9M", "8M"],
			fly: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			helpinghand: ["9M", "8M"],
			hex: ["9M", "9L18", "8M", "8L18"],
			hydropump: ["9M", "8M"],
			hyperbeam: ["9M", "8M"],
			infestation: ["9L1", "8L1"],
			lastresort: ["9L78", "8L78"],
			lightscreen: ["9M", "8M"],
			lockon: ["9L6", "8L6"],
			nightshade: ["9M"],
			outrage: ["9M", "8M"],
			phantomforce: ["9M", "9L48", "8M", "8L48"],
			pounce: ["9M"],
			protect: ["9M", "8M"],
			psychicfangs: ["9M", "8M"],
			quickattack: ["9L1", "8L1"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scald: ["8M"],
			shadowball: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			steelwing: ["8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["8L1"],
			sunnyday: ["9M"],
			surf: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M", "9L54", "8L54"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thunder: ["9M", "8M"],
			thunderbolt: ["9M", "8M"],
			thunderwave: ["9M", "8M"],
			triattack: ["8M"],
			uturn: ["9M", "9L36", "8M", "8L36"],
			willowisp: ["9M", "8M"],
		},
	},
	zacian: {
		learnset: {
			agility: ["9M", "8M"],
			airslash: ["9M", "8M"],
			assurance: ["8M"],
			bite: ["9L1", "8L1"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "8M"],
			brutalswing: ["8M"],
			closecombat: ["9M", "9L77", "8M", "8L77"],
			crunch: ["9M", "9L55", "8M", "8L55", "8S0"],
			dazzlinggleam: ["9M"],
			dig: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			falseswipe: ["9M", "8M"],
			firefang: ["9M", "8M"],
			flashcannon: ["9M"],
			focusblast: ["9M", "8M"],
			focusenergy: ["8M"],
			gigaimpact: ["9M", "9L88", "8M", "8L88"],
			helpinghand: ["9M", "8M"],
			howl: ["9L1", "8L1"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			icefang: ["9M", "8M"],
			imprison: ["9M", "8M"],
			irondefense: ["9M"],
			ironhead: ["9M", "9L33", "8M", "8L33", "8S0", "8S1"],
			irontail: ["8M"],
			laserfocus: ["8L44"],
			metalclaw: ["9M", "9L1", "8L1"],
			mistyterrain: ["9M"],
			moonblast: ["9L66", "8L66"],
			nobleroar: ["9L44"],
			playrough: ["9M", "8M", "8S1"],
			poisonjab: ["9M"],
			protect: ["9M", "8M"],
			psychicfangs: ["9M", "8M"],
			psychocut: ["8M"],
			quickattack: ["9L1", "8L1"],
			quickguard: ["9L1", "8L1"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			sacredsword: ["9L1", "8L1", "8S0", "8S1"],
			scaryface: ["9M", "8M"],
			slash: ["9L11", "8L11"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			solarblade: ["8M"],
			steelbeam: ["9M", "8T"],
			substitute: ["9M", "8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "9L22", "8M", "8L22", "8S0", "8S1"],
			tailslap: ["8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M", "8M"],
			trailblaze: ["9M"],
			wildcharge: ["9M", "8M"],
			workup: ["8M"],
		},
		eventData: [
			{generation: 8, level: 70, perfectIVs: 3, moves: ["sacredsword", "swordsdance", "ironhead", "crunch"]},
			{generation: 8, level: 100, shiny: true, nature: "Adamant", ivs: {hp: 31, atk: 31, def: 31, spa: 30, spd: 31, spe: 31}, moves: ["ironhead", "playrough", "swordsdance", "sacredsword"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	zaciancrowned: {
		learnset: {
			behemothblade: ["9R", "8R"],
		},
		eventOnly: true,
	},
	zamazenta: {
		learnset: {
			agility: ["9M", "8M"],
			bite: ["9L1", "8L1"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			closecombat: ["9M", "9L77", "8M", "8L77", "8S1"],
			coaching: ["8T"],
			crunch: ["9M", "9L55", "8M", "8L55", "8S0"],
			dazzlinggleam: ["9M", "8M"],
			dig: ["9M", "8M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			firefang: ["9M", "8M"],
			flashcannon: ["9M", "8M"],
			focusblast: ["9M", "8M"],
			focusenergy: ["8M"],
			gigaimpact: ["9M", "9L88", "8M", "8L88"],
			guardswap: ["8M"],
			heavyslam: ["9M"],
			helpinghand: ["9M", "8M"],
			howl: ["9L1", "8L1"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			icefang: ["9M", "8M"],
			imprison: ["9M", "8M"],
			irondefense: ["9M", "9L22", "8M", "8L22", "8S0", "8S1"],
			ironhead: ["9M", "9L33", "8M", "8L33", "8S0", "8S1"],
			irontail: ["8M"],
			laserfocus: ["8L44"],
			lightscreen: ["9M", "8M"],
			metalburst: ["9L44", "8L1"],
			metalclaw: ["9M", "9L1", "8L1"],
			moonblast: ["9L66", "8L66"],
			payback: ["8M"],
			playrough: ["9M", "8M"],
			powerswap: ["8M"],
			protect: ["9M", "8M"],
			psychicfangs: ["9M", "8M"],
			quickattack: ["9L1", "8L1"],
			raindance: ["9M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sandstorm: ["9M"],
			scaryface: ["9M", "8M"],
			slash: ["9L11", "8L11", "8S0"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			steelbeam: ["9M", "8T"],
			stoneedge: ["9M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			swift: ["9M", "8M"],
			tailslap: ["8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M", "8M"],
			trailblaze: ["9M"],
			wideguard: ["9L1", "8L1", "8S1"],
			wildcharge: ["9M", "8M"],
			workup: ["8M"],
		},
		eventData: [
			{generation: 8, level: 70, perfectIVs: 3, moves: ["slash", "crunch", "ironhead", "irondefense"]},
			{generation: 8, level: 100, shiny: true, nature: "Adamant", ivs: {hp: 31, atk: 31, def: 31, spa: 30, spd: 31, spe: 31}, moves: ["ironhead", "closecombat", "irondefense", "wideguard"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	zamazentacrowned: {
		learnset: {
			behemothbash: ["9R", "8R"],
		},
		eventOnly: true,
	},
	eternatus: {
		learnset: {
			agility: ["9M", "9L1", "8M", "8L1"],
			assurance: ["8M"],
			bodyslam: ["9M"],
			brutalswing: ["8M"],
			confuseray: ["9M", "9L1", "8L1"],
			cosmicpower: ["9L64", "8M", "8L64"],
			crosspoison: ["9L32", "8M", "8L32", "8S0"],
			dracometeor: ["9M", "8T"],
			dragondance: ["9M", "9L24", "8M", "8L24"],
			dragonpulse: ["9M", "9L40", "8M", "8L40", "8S0"],
			dragontail: ["9M", "9L1", "8L1"],
			dynamaxcannon: ["9L56", "8L56", "8S1", "8S0"],
			endure: ["9M", "8M"],
			eternabeam: ["8L88", "8S1"],
			facade: ["9M", "8M"],
			fireblast: ["9M"],
			firespin: ["9M"],
			flamethrower: ["9M", "9L48", "8M", "8L48", "8S1", "8S0"],
			flashcannon: ["9M", "8M"],
			fly: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			gunkshot: ["9M"],
			hyperbeam: ["9M", "9L80", "8M", "8L80"],
			lightscreen: ["9M", "8M"],
			meteorbeam: ["8T"],
			mysticalfire: ["8M"],
			outrage: ["9M", "9L88"],
			payback: ["8M"],
			poisonjab: ["9M", "8M"],
			poisontail: ["9M", "9L1", "8L1"],
			protect: ["9M", "8M"],
			raindance: ["9M"],
			recover: ["9L72", "8L72"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "8M"],
			screech: ["8M"],
			shadowball: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			sludgebomb: ["9M", "8M", "8S1"],
			sludgewave: ["8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			toxic: ["9L8", "8L8"],
			toxicspikes: ["9M", "8M"],
			venomdrench: ["8M"],
			venoshock: ["9M", "9L16", "8M", "8L16"],
		},
		eventData: [
			{generation: 8, level: 60, perfectIVs: 3, moves: ["crosspoison", "dragonpulse", "flamethrower", "dynamaxcannon"]},
			{generation: 8, level: 100, shiny: true, nature: "Timid", perfectIVs: 6, moves: ["eternabeam", "dynamaxcannon", "sludgebomb", "flamethrower"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	kubfu: {
		learnset: {
			acrobatics: ["9M", "8M"],
			aerialace: ["9M", "9L12", "8L12"],
			attract: ["8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "9L24", "8M", "8L24"],
			bulkup: ["9M", "9L32", "8M", "8L32"],
			closecombat: ["9M", "9L48", "8M", "8L48"],
			coaching: ["8T"],
			counter: ["9L44", "8L44"],
			detect: ["9L28", "8L28"],
			dig: ["9M", "8M"],
			dynamicpunch: ["9L40", "8L40"],
			endure: ["9M", "9L4", "8M", "8L4", "8S0"],
			facade: ["9M", "8M"],
			firepunch: ["9M", "8M"],
			fling: ["9M"],
			focusenergy: ["9L8", "8M", "8L8", "8S0"],
			focuspunch: ["9L52", "8L52"],
			headbutt: ["9L20", "8L20"],
			helpinghand: ["9M", "8M"],
			icepunch: ["9M", "8M"],
			ironhead: ["9M", "9L36", "8M", "8L36"],
			leer: ["9L1", "8L1", "8S0"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metalclaw: ["9M"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			rocksmash: ["9L1", "8L1", "8S0"],
			round: ["8M"],
			scaryface: ["9M", "9L16", "8M", "8L16"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			superpower: ["8M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderpunch: ["9M", "8M"],
			uturn: ["9M", "8M"],
			workup: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
		eventData: [
			// TODO get actual event data
			{generation: 8, level: 10, perfectIVs: 3, moves: ["rocksmash", "leer", "endure", "focusenergy"]},
		],
		eventOnly: true,
	},
	urshifu: {
		learnset: {
			acrobatics: ["9M", "8M"],
			aerialace: ["9M", "9L12", "8L12"],
			assurance: ["8M"],
			attract: ["8M"],
			aurasphere: ["9M", "8M"],
			beatup: ["8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "9L24", "8M", "8L24"],
			bulkup: ["9M", "9L32", "8M", "8L32"],
			closecombat: ["9M", "9L48", "8M", "8L48"],
			coaching: ["8T"],
			counter: ["9L44", "8L44"],
			crunch: ["9M", "8M"],
			darkestlariat: ["8M"],
			darkpulse: ["9M", "8M"],
			detect: ["9L28", "8L28"],
			dig: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			dynamicpunch: ["9L40", "8L40"],
			endure: ["9M", "9L1", "8M", "8L1"],
			facade: ["9M", "8M"],
			falseswipe: ["9M", "8M"],
			firepunch: ["9M", "8M"],
			fling: ["9M", "8M"],
			focusblast: ["9M", "8M"],
			focusenergy: ["9L1", "8M", "8L1"],
			focuspunch: ["9L52", "8L52"],
			foulplay: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			headbutt: ["9L20", "8L20"],
			helpinghand: ["9M", "8M"],
			icepunch: ["9M", "8M"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M", "9L36", "8M", "8L36"],
			lashout: ["8T"],
			leer: ["9L1", "8L1"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metalclaw: ["9M"],
			payback: ["8M"],
			poisonjab: ["9M", "8M"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			rockslide: ["9M", "8M"],
			rocksmash: ["9L1", "8L1"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "9L16", "8M", "8L16"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			stoneedge: ["9M", "8M"],
			substitute: ["9M", "8M"],
			suckerpunch: ["9L1", "8L1"],
			superpower: ["8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			throatchop: ["8M"],
			thunderpunch: ["9M", "8M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M"],
			wickedblow: ["9L0", "8L0"],
			workup: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	urshifurapidstrike: {
		learnset: {
			acrobatics: ["9M", "8M"],
			aerialace: ["9M", "9L12", "8L12"],
			aquajet: ["9L1", "8L1"],
			attract: ["8M"],
			aurasphere: ["9M", "8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "9L24", "8M", "8L24"],
			brine: ["8M"],
			bulkup: ["9M", "9L32", "8M", "8L32"],
			chillingwater: ["9M"],
			closecombat: ["9M", "9L48", "8M", "8L48"],
			coaching: ["8T"],
			counter: ["9L44", "8L44"],
			detect: ["9L28", "8L28"],
			dig: ["9M", "8M"],
			dive: ["8M"],
			drainpunch: ["9M", "8M"],
			dynamicpunch: ["9L40", "8L40"],
			endure: ["9M", "9L1", "8M", "8L1"],
			facade: ["9M", "8M"],
			falseswipe: ["9M", "8M"],
			firepunch: ["9M", "8M"],
			fling: ["9M"],
			focusblast: ["9M", "8M"],
			focusenergy: ["9L1", "8M", "8L1"],
			focuspunch: ["9L52", "8L52"],
			gigaimpact: ["9M", "8M"],
			headbutt: ["9L20", "8L20"],
			helpinghand: ["9M", "8M"],
			icepunch: ["9M", "8M"],
			icespinner: ["9M"],
			irondefense: ["9M", "8M"],
			ironhead: ["9M", "9L36", "8M", "8L36"],
			leer: ["9L1", "8L1"],
			liquidation: ["9M", "8M"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			poisonjab: ["9M", "8M"],
			protect: ["9M", "8M"],
			raindance: ["9M", "8M"],
			rest: ["9M", "8M"],
			retaliate: ["8M"],
			revenge: ["8M"],
			reversal: ["9M", "8M"],
			rockslide: ["9M", "8M"],
			rocksmash: ["9L1", "8L1"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			scald: ["8M"],
			scaryface: ["9M", "9L16", "8M", "8L16"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			stoneedge: ["9M", "8M"],
			substitute: ["9M", "8M"],
			superpower: ["8M"],
			surgingstrikes: ["9L0", "8L0"],
			swift: ["9M", "8M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thunderpunch: ["9M", "8M"],
			trailblaze: ["9M"],
			uturn: ["9M", "8M"],
			waterfall: ["9M", "8M"],
			whirlpool: ["8M"],
			workup: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
	},
	zarude: {
		learnset: {
			acrobatics: ["9M", "8M"],
			aerialace: ["9M"],
			assurance: ["8M"],
			bind: ["9L1", "8L1"],
			bite: ["9L42", "8L42"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M"],
			brutalswing: ["8M"],
			bulkup: ["9M", "8M"],
			bulletseed: ["9M", "8M"],
			closecombat: ["9M", "8M", "8S0"],
			crunch: ["9M", "8M"],
			darkestlariat: ["8M"],
			darkpulse: ["9M", "8M"],
			dig: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "9L60", "8M", "8L60"],
			facade: ["9M", "8M"],
			fling: ["9M", "8M"],
			furyswipes: ["9L24", "8L24"],
			gigadrain: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "9L36", "8M", "8L36"],
			grassyglide: ["8T"],
			grassyterrain: ["9M", "8M"],
			growth: ["9L18", "8L18"],
			hammerarm: ["9L72", "8L72"],
			helpinghand: ["9M"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			irontail: ["8M"],
			junglehealing: ["9L90", "8L90"],
			lashout: ["8T"],
			leafstorm: ["9M"],
			leer: ["9L6", "8L6"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M"],
			magicalleaf: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudshot: ["9M", "8M"],
			nastyplot: ["9M", "8M"],
			payback: ["8M"],
			powerwhip: ["9L84", "8M", "8L84", "8S0"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			revenge: ["8M"],
			rockslide: ["9M", "8M"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "9L30", "8M", "8L30"],
			scratch: ["9L1", "8L1"],
			seedbomb: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M", "8S0"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			solarblade: ["8M"],
			stompingtantrum: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			superpower: ["8M"],
			swagger: ["9L54", "8L54", "8S0"],
			swift: ["9M", "8M"],
			swordsdance: ["9M"],
			synthesis: ["9L66", "8L66"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thrash: ["9L78", "8L78"],
			throatchop: ["8M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L48", "8M", "8L48"],
			vinewhip: ["9L12", "8L12"],
		},
		eventData: [
			{generation: 8, level: 60, nature: "Sassy", moves: ["closecombat", "powerwhip", "swagger", "snarl"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	zarudedada: {
		learnset: {
			acrobatics: ["9M", "8M"],
			aerialace: ["9M"],
			assurance: ["8M"],
			bind: ["9L1", "8L1"],
			bite: ["9L42", "8L42"],
			bodyslam: ["9M", "8M"],
			brickbreak: ["9M", "8M"],
			brutalswing: ["8M"],
			bulkup: ["9M", "8M"],
			bulletseed: ["9M", "8M"],
			closecombat: ["9M", "8M"],
			crunch: ["9M", "8M"],
			darkestlariat: ["8M"],
			darkpulse: ["9M", "8M"],
			dig: ["9M", "8M"],
			drainpunch: ["9M", "8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "9L60", "8M", "8L60", "8S0"],
			facade: ["9M", "8M"],
			fling: ["9M", "8M"],
			furyswipes: ["9L24", "8L24"],
			gigadrain: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "9L36", "8M", "8L36"],
			grassyglide: ["8T"],
			grassyterrain: ["9M", "8M"],
			growth: ["9L18", "8L18"],
			hammerarm: ["9L72", "8L72", "8S0"],
			helpinghand: ["9M"],
			hyperbeam: ["9M", "8M"],
			hypervoice: ["9M", "8M"],
			irontail: ["8M"],
			junglehealing: ["9L90", "8L90", "8S0"],
			lashout: ["8T"],
			leafstorm: ["9M"],
			leer: ["9L6", "8L6"],
			lowkick: ["9M", "8M"],
			lowsweep: ["9M"],
			magicalleaf: ["9M", "8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			mudshot: ["9M", "8M"],
			nastyplot: ["9M", "8M"],
			payback: ["8M"],
			powerwhip: ["9L84", "8M", "8L84", "8S0"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			revenge: ["8M"],
			rockslide: ["9M", "8M"],
			rocktomb: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "9L30", "8M", "8L30"],
			scratch: ["9L1", "8L1"],
			seedbomb: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "8M"],
			solarblade: ["8M"],
			stompingtantrum: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			superpower: ["8M"],
			swagger: ["9L54", "8L54"],
			swift: ["9M", "8M"],
			swordsdance: ["9M"],
			synthesis: ["9L66", "8L66"],
			takedown: ["9M"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thief: ["9M", "8M"],
			thrash: ["9L78", "8L78"],
			throatchop: ["8M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L48", "8M", "8L48"],
			vinewhip: ["9L12", "8L12"],
		},
		eventData: [
			{generation: 8, level: 70, nature: "Adamant", moves: ["junglehealing", "hammerarm", "powerwhip", "energyball"], pokeball: "cherishball"},
		],
		eventOnly: true,
	},
	regieleki: {
		learnset: {
			acrobatics: ["9M", "8M"],
			agility: ["9M", "8M"],
			ancientpower: ["9L12", "8L12"],
			assurance: ["8M"],
			bodyslam: ["9M", "8M"],
			bounce: ["8M"],
			chargebeam: ["9M"],
			eerieimpulse: ["9M", "8M"],
			electricterrain: ["9M", "8M"],
			electroball: ["9M", "8M"],
			electroweb: ["9L6", "8M", "8L6"],
			endure: ["9M", "8M"],
			explosion: ["9L78", "8L78"],
			extremespeed: ["9L30", "8L30"],
			facade: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			hyperbeam: ["9M", "9L72", "8M", "8L72"],
			lightscreen: ["9M", "8M"],
			lockon: ["9L60", "8L60", "8S0"],
			magnetrise: ["9L48", "8L48"],
			protect: ["9M", "8M"],
			raindance: ["9M", "8M"],
			rapidspin: ["9L1", "8L1"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			risingvoltage: ["8T"],
			round: ["8M"],
			screech: ["8M"],
			selfdestruct: ["8M"],
			shockwave: ["9L18", "8L18"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L54", "8L54", "8S0"],
			thunder: ["9M", "8M"],
			thunderbolt: ["9M", "9L42", "8M", "8L42"],
			thundercage: ["9L36", "8L36", "8S0"],
			thundershock: ["9L1", "8L1"],
			thunderwave: ["9M", "9L24", "8M", "8L24"],
			voltswitch: ["9M", "8M"],
			wildcharge: ["9M", "8M"],
			zapcannon: ["9L66", "8L66", "8S0"],
		},
		eventData: [
			{generation: 8, level: 70, shiny: 1, moves: ["thundercage", "thrash", "lockon", "zapcannon"]},
		],
		eventOnly: true,
	},
	regidrago: {
		learnset: {
			ancientpower: ["9L12", "8L12"],
			bite: ["9L6", "8L6"],
			bodyslam: ["9M", "8M"],
			breakingswipe: ["8M"],
			crunch: ["9M", "9L30", "8M", "8L30"],
			dracometeor: ["9M", "8T"],
			dragonbreath: ["9L18", "8L18"],
			dragonclaw: ["9M", "9L36", "8M", "8L36", "8S0"],
			dragondance: ["9M", "9L48", "8M", "8L48"],
			dragonenergy: ["9L66", "8L66", "8S0"],
			dragonpulse: ["9M", "8M"],
			earthpower: ["9M"],
			earthquake: ["9M"],
			endure: ["9M", "8M"],
			explosion: ["9L78", "8L78"],
			facade: ["9M", "8M"],
			firefang: ["9M", "8M"],
			focusenergy: ["9L60", "8M", "8L24"],
			gigaimpact: ["9M", "8M"],
			hammerarm: ["9L42", "8L42", "8S0"],
			hyperbeam: ["9M", "9L72", "8M", "8L72"],
			icefang: ["9M"],
			laserfocus: ["8L60", "8S0"],
			lightscreen: ["9M", "8M"],
			outrage: ["9M", "8M"],
			protect: ["9M", "8M"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			reversal: ["9M", "8M"],
			round: ["8M"],
			scaleshot: ["8T"],
			selfdestruct: ["8M"],
			sleeptalk: ["9M", "8M"],
			snore: ["8M"],
			substitute: ["9M", "8M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L54", "8L54"],
			thunderfang: ["9M", "8M"],
			twister: ["9L1", "8L1"],
			visegrip: ["9L1", "8L1"],
		},
		eventData: [
			{generation: 8, level: 70, shiny: 1, moves: ["dragonenergy", "dragonclaw", "hammerarm", "laserfocus"]},
		],
		eventOnly: true,
	},
	glastrier: {
		learnset: {
			assurance: ["8M"],
			avalanche: ["9M", "9L12", "8M", "8L12"],
			blizzard: ["9M", "8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "8M"],
			closecombat: ["9M", "8M"],
			crunch: ["9M", "8M"],
			doubleedge: ["9L66", "8L66", "8S0"],
			doublekick: ["9L6", "8L6"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			hail: ["8M"],
			heavyslam: ["9M", "8M"],
			highhorsepower: ["8M"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			iciclecrash: ["9L36", "8L36", "8S0"],
			iciclespear: ["8M"],
			icywind: ["9M", "8M"],
			irondefense: ["9M", "9L48", "8M", "8L48"],
			lashout: ["8T"],
			megahorn: ["8M"],
			mist: ["9L30", "8L30"],
			mudshot: ["9M", "8M"],
			outrage: ["9M", "8M"],
			payback: ["8M"],
			protect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			smartstrike: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			snowscape: ["9M"],
			stomp: ["9L18", "8L18"],
			stompingtantrum: ["9M", "8M"],
			substitute: ["9M", "8M"],
			superpower: ["8M"],
			swordsdance: ["9M", "9L72", "8M", "8L72", "8S0"],
			tackle: ["9L1", "8L1"],
			tailwhip: ["9L1", "8L1"],
			takedown: ["9M", "9L42", "8L42"],
			taunt: ["9M", "9L60", "8M", "8L60", "8S0"],
			terablast: ["9M"],
			thrash: ["9L54", "8L54"],
			throatchop: ["8M"],
			torment: ["9L24", "8L24"],
			trailblaze: ["9M"],
			uproar: ["8M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 8, level: 75, moves: ["taunt", "doubleedge", "swordsdance", "iciclecrash"]},
		],
		eventOnly: true,
	},
	spectrier: {
		learnset: {
			agility: ["9M", "9L48", "8M", "8L48"],
			assurance: ["8M"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			confuseray: ["9M", "9L24", "8L24"],
			crunch: ["9M", "8M"],
			darkpulse: ["9M", "8M"],
			disable: ["9L60", "8L60", "8S0"],
			doubleedge: ["9L66", "8L66", "8S0"],
			doublekick: ["9L6", "8L6"],
			drainingkiss: ["9M"],
			endure: ["9M", "8M"],
			facade: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			gigaimpact: ["9M", "8M"],
			haze: ["9L30", "8L30"],
			hex: ["9M", "9L12", "8M", "8L12"],
			hyperbeam: ["9M", "8M"],
			lashout: ["8T"],
			mudshot: ["9M", "8M"],
			nastyplot: ["9M", "9L72", "8M", "8L72", "8S0"],
			nightshade: ["9M"],
			payback: ["8M"],
			phantomforce: ["9M", "8M"],
			protect: ["9M", "8M"],
			psychic: ["9M"],
			psychocut: ["8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			scaryface: ["9M", "8M"],
			shadowball: ["9M", "9L36", "8M", "8L36"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			stomp: ["9L18", "8L18"],
			stompingtantrum: ["9M", "8M"],
			substitute: ["9M", "8M"],
			swift: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			tailwhip: ["9L1", "8L1"],
			takedown: ["9M", "9L42", "8L42"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thrash: ["9L54", "8L54", "8S0"],
			uproar: ["8M"],
			willowisp: ["9M", "8M"],
		},
		eventData: [
			{generation: 8, level: 75, moves: ["thrash", "doubleedge", "disable", "nastyplot"]},
		],
		eventOnly: true,
	},
	calyrex: {
		learnset: {
			agility: ["9M", "8M"],
			allyswitch: ["8M"],
			aromatherapy: ["8L40"],
			batonpass: ["9M", "8M"],
			bodypress: ["9M"],
			bulletseed: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			confusion: ["9L1", "8L1"],
			drainingkiss: ["9M", "8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "9L48", "8M", "8L48"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			futuresight: ["9L88", "8M", "8L88"],
			gigadrain: ["9M", "9L16", "8M", "8L16", "8S0"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			grassyterrain: ["9M", "9L40", "8M"],
			growth: ["9L1", "8L1"],
			guardswap: ["8M"],
			healpulse: ["9L72", "8L72"],
			helpinghand: ["9M", "9L32", "8M", "8L32"],
			hyperbeam: ["9M", "8M"],
			imprison: ["9M", "8M"],
			leafstorm: ["9M", "8M"],
			leechseed: ["9L64", "8L64"],
			lifedew: ["9L8", "8L8"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			magicroom: ["8M"],
			megadrain: ["9L1", "8L1"],
			metronome: ["9M", "8M"],
			mudshot: ["9M"],
			payday: ["8M"],
			pollenpuff: ["9M", "8M"],
			pound: ["9L1", "8L1"],
			powerswap: ["8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L56", "8M", "8L56", "8S0"],
			psychicterrain: ["9M", "9L40", "8M"],
			psyshock: ["9M", "9L24", "8M", "8L24"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			scaryface: ["9M"],
			seedbomb: ["9M", "8M"],
			skillswap: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M"],
			snore: ["8M"],
			solarbeam: ["9M", "9L80", "8M", "8L80"],
			solarblade: ["8M"],
			speedswap: ["8M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			swift: ["9M", "8M"],
			takedown: ["9M"],
			terablast: ["9M"],
			triattack: ["8M"],
			trick: ["9M", "8M"],
			trickroom: ["9M", "8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
		eventData: [
			{generation: 8, level: 80, moves: ["psychic", "gigadrain"]},
		],
		eventOnly: true,
	},
	calyrexice: {
		learnset: {
			agility: ["9M", "8M"],
			allyswitch: ["8M"],
			aromatherapy: ["9L40", "8L40"],
			assurance: ["8M"],
			avalanche: ["9M", "9L1", "8M", "8L1"],
			batonpass: ["9M", "8M"],
			blizzard: ["9M", "8M"],
			bodypress: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "8M"],
			bulletseed: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			closecombat: ["9M", "8M"],
			confusion: ["9L1", "8L1"],
			crunch: ["9M", "8M"],
			doubleedge: ["9L1", "8L1"],
			doublekick: ["9L1", "8L1"],
			drainingkiss: ["9M", "8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "9L48", "8M", "8L48"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			futuresight: ["9L88", "8M", "8L88"],
			gigadrain: ["9M", "9L16", "8M", "8L16", "8S0"],
			gigaimpact: ["9M", "8M"],
			glaciallance: ["9L1", "8L1", "8S0"],
			grassknot: ["9M", "8M"],
			grassyterrain: ["9M", "9L40", "8M"],
			growth: ["9L1", "8L1"],
			guardswap: ["8M"],
			hail: ["8M"],
			healpulse: ["9L72", "8L72"],
			heavyslam: ["9M", "8M"],
			helpinghand: ["9M", "9L32", "8M", "8L32"],
			highhorsepower: ["8M"],
			hyperbeam: ["9M", "8M"],
			icebeam: ["9M", "8M"],
			iciclecrash: ["9L1", "8L1"],
			iciclespear: ["8M"],
			icywind: ["9M", "8M"],
			imprison: ["9M", "8M"],
			irondefense: ["9M", "9L1", "8M", "8L1", "8S0"],
			lashout: ["8T"],
			leafstorm: ["9M", "8M"],
			leechseed: ["9L64", "8L64"],
			lifedew: ["9L8", "8L8"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			magicroom: ["8M"],
			megadrain: ["9L1", "8L1"],
			megahorn: ["8M"],
			metronome: ["9M", "8M"],
			mist: ["9L1", "8L1"],
			mudshot: ["9M", "8M"],
			outrage: ["9M", "8M"],
			payback: ["8M"],
			payday: ["8M"],
			pollenpuff: ["9M", "8M"],
			pound: ["9L1", "8L1"],
			powerswap: ["8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L56", "8M", "8L56", "8S0"],
			psychicterrain: ["9M", "8M"],
			psyshock: ["9M", "9L24", "8M", "8L24"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			scaryface: ["9M", "8M"],
			seedbomb: ["9M", "8M"],
			skillswap: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			smartstrike: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			snowscape: ["9M"],
			solarbeam: ["9M", "9L80", "8M", "8L80"],
			solarblade: ["8M"],
			speedswap: ["8M"],
			stomp: ["9L1", "8L1"],
			stompingtantrum: ["9M", "8M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			superpower: ["8M"],
			swift: ["9M", "8M"],
			swordsdance: ["9M", "9L1", "8M", "8L1"],
			tackle: ["9L1", "8L1"],
			tailwhip: ["9L1", "8L1"],
			takedown: ["9M", "9L1", "8L1"],
			taunt: ["9M", "9L1", "8M", "8L1"],
			terablast: ["9M"],
			thrash: ["9L1", "8L1"],
			throatchop: ["8M"],
			torment: ["9L1", "8L1"],
			trailblaze: ["9M"],
			triattack: ["8M"],
			trick: ["9M", "8M"],
			trickroom: ["9M", "8M"],
			uproar: ["8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
		eventData: [
			{generation: 8, level: 80, moves: ["glaciallance", "psychic", "irondefense", "gigadrain"]},
		],
		eventOnly: true,
	},
	calyrexshadow: {
		learnset: {
			agility: ["9M", "9L1", "8M", "8L1", "8S0"],
			allyswitch: ["8M"],
			aromatherapy: ["8L40"],
			assurance: ["8M"],
			astralbarrage: ["9L1", "8L1", "8S0"],
			batonpass: ["9M", "8M"],
			bodyslam: ["9M", "8M"],
			bulldoze: ["9M", "8M"],
			bulletseed: ["9M", "8M"],
			calmmind: ["9M", "8M"],
			confuseray: ["9M", "9L1", "8L1"],
			confusion: ["9L1", "8L1"],
			crunch: ["9M", "8M"],
			darkpulse: ["9M", "8M"],
			disable: ["9L1", "8L1"],
			doubleedge: ["9L1", "8L1"],
			doublekick: ["9L1", "8L1"],
			drainingkiss: ["9M", "8M"],
			encore: ["9M", "8M"],
			endure: ["9M", "8M"],
			energyball: ["9M", "9L48", "8M", "8L48"],
			expandingforce: ["8T"],
			facade: ["9M", "8M"],
			foulplay: ["9M", "8M"],
			futuresight: ["9L88", "8M", "8L88"],
			gigadrain: ["9M", "9L16", "8M", "8L16", "8S0"],
			gigaimpact: ["9M", "8M"],
			grassknot: ["9M", "8M"],
			grassyterrain: ["9M", "9L40", "8M"],
			growth: ["9L1", "8L1"],
			guardswap: ["8M"],
			haze: ["9L1", "8L1"],
			healpulse: ["9L72", "8L72"],
			helpinghand: ["9M", "9L32", "8M", "8L32"],
			hex: ["9M", "9L1", "8M", "8L1"],
			hyperbeam: ["9M", "8M"],
			imprison: ["9M", "8M"],
			lashout: ["8T"],
			leafstorm: ["9M", "8M"],
			leechseed: ["9L64", "8L64"],
			lifedew: ["9L8", "8L8"],
			lightscreen: ["9M", "8M"],
			magicalleaf: ["9M", "8M"],
			magicroom: ["8M"],
			megadrain: ["9L1", "8L1"],
			metronome: ["9M", "8M"],
			mudshot: ["9M", "8M"],
			nastyplot: ["9M", "9L1", "8M", "8L1"],
			nightshade: ["9M"],
			payback: ["8M"],
			payday: ["8M"],
			phantomforce: ["9M", "8M"],
			pollenpuff: ["9M", "8M"],
			pound: ["9L1", "8L1"],
			powerswap: ["8M"],
			protect: ["9M", "8M"],
			psybeam: ["9M"],
			psychic: ["9M", "9L56", "8M", "8L56", "8S0"],
			psychicterrain: ["9M", "9L40", "8M"],
			psychocut: ["8M"],
			psyshock: ["9M", "9L24", "8M", "8L24"],
			reflect: ["9M", "8M"],
			rest: ["9M", "8M"],
			round: ["8M"],
			safeguard: ["8M"],
			scaryface: ["9M", "8M"],
			seedbomb: ["9M", "8M"],
			shadowball: ["9M", "9L1", "8M", "8L1"],
			skillswap: ["9M", "8M"],
			sleeptalk: ["9M", "8M"],
			snarl: ["9M", "8M"],
			snore: ["8M"],
			solarbeam: ["9M", "9L80", "8M", "8L80"],
			solarblade: ["8M"],
			speedswap: ["8M"],
			stomp: ["9L1", "8L1"],
			stompingtantrum: ["9M", "8M"],
			storedpower: ["9M", "8M"],
			substitute: ["9M", "8M"],
			sunnyday: ["9M", "8M"],
			swift: ["9M", "8M"],
			tackle: ["9L1", "8L1"],
			tailwhip: ["9L1", "8L1"],
			takedown: ["9M", "9L1", "8L1"],
			taunt: ["9M", "8M"],
			terablast: ["9M"],
			thrash: ["9L1", "8L1"],
			triattack: ["8M"],
			trick: ["9M", "8M"],
			trickroom: ["9M", "8M"],
			uproar: ["8M"],
			willowisp: ["9M", "8M"],
			wonderroom: ["8M"],
			zenheadbutt: ["9M", "8M"],
		},
		eventData: [
			{generation: 8, level: 80, moves: ["astralbarrage", "psychic", "agility", "gigadrain"]},
		],
		eventOnly: true,
	},
	enamorus: {
		learnset: {
			agility: ["9M"],
			astonish: ["9L1"],
			bodyslam: ["9M"],
			calmmind: ["9M"],
			dazzlinggleam: ["9M", "9L40"],
			disarmingvoice: ["9M"],
			drainingkiss: ["9M", "9L20"],
			earthpower: ["9M"],
			endure: ["9M"],
			extrasensory: ["9L45"],
			facade: ["9M"],
			fairywind: ["9L1"],
			flatter: ["9L10"],
			fly: ["9M"],
			focusblast: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			healingwish: ["9L60"],
			hyperbeam: ["9M"],
			imprison: ["9M", "9L30"],
			irondefense: ["9M", "9L25"],
			ironhead: ["9M"],
			mistyterrain: ["9M"],
			moonblast: ["9L65"],
			mysticalfire: ["9L35"],
			outrage: ["9M", "9L70"],
			playrough: ["9M"],
			protect: ["9M"],
			psychic: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			springtidestorm: ["9L75"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			superpower: ["9L55"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			torment: ["9L5"],
			twister: ["9L15"],
			uproar: ["9L50"],
			zenheadbutt: ["9M"],
		},
	},
	enamorustherian: {
		learnset: {
			agility: ["9M"],
			astonish: ["9L1"],
			bodyslam: ["9M"],
			calmmind: ["9M"],
			dazzlinggleam: ["9M", "9L40"],
			disarmingvoice: ["9M"],
			drainingkiss: ["9M", "9L20"],
			earthpower: ["9M"],
			endure: ["9M"],
			extrasensory: ["9L45"],
			facade: ["9M"],
			fairywind: ["9L1"],
			flatter: ["9L10"],
			fly: ["9M"],
			focusblast: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			healingwish: ["9L60"],
			hyperbeam: ["9M"],
			imprison: ["9M", "9L30"],
			irondefense: ["9M", "9L25"],
			ironhead: ["9M"],
			mistyterrain: ["9M"],
			moonblast: ["9L65"],
			mysticalfire: ["9L35"],
			outrage: ["9M", "9L70"],
			playrough: ["9M"],
			protect: ["9M"],
			psychic: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			springtidestorm: ["9L75"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			superpower: ["9L55"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			torment: ["9L5"],
			twister: ["9L15"],
			uproar: ["9L50"],
			zenheadbutt: ["9M"],
		},
	},
	sprigatito: {
		learnset: {
			acrobatics: ["9M"],
			agility: ["9M"],
			allyswitch: ["9E"],
			bite: ["9L7"],
			bulletseed: ["9M"],
			charm: ["9M"],
			copycat: ["9E"],
			disarmingvoice: ["9M"],
			endure: ["9M"],
			energyball: ["9M", "9L32"],
			facade: ["9M"],
			faketears: ["9M"],
			gigadrain: ["9M"],
			grassknot: ["9M"],
			grasspledge: ["9M"],
			grassyterrain: ["9M"],
			helpinghand: ["9M"],
			honeclaws: ["9L10"],
			leafage: ["9L1"],
			leafstorm: ["9M"],
			leechseed: ["9E"],
			magicalleaf: ["9M", "9L13"],
			mudslap: ["9M"],
			nastyplot: ["9M"],
			petalblizzard: ["9E"],
			playrough: ["9M", "9L36"],
			protect: ["9M"],
			quickattack: ["9L15"],
			rest: ["9M"],
			scratch: ["9L1"],
			seedbomb: ["9M", "9L17"],
			shadowclaw: ["9M"],
			slash: ["9L28"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			substitute: ["9M"],
			suckerpunch: ["9E"],
			swift: ["9M"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L21"],
			worryseed: ["9L25"],
		},
	},
	floragato: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			agility: ["9M"],
			bite: ["9L7"],
			bulletseed: ["9M"],
			charm: ["9M"],
			disarmingvoice: ["9M"],
			endure: ["9M"],
			energyball: ["9M", "9L38"],
			facade: ["9M"],
			faketears: ["9M"],
			fling: ["9M"],
			gigadrain: ["9M"],
			grassknot: ["9M"],
			grasspledge: ["9M"],
			grassyterrain: ["9M"],
			helpinghand: ["9M"],
			honeclaws: ["9L10"],
			leafage: ["9L1"],
			leafstorm: ["9M", "9L46"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			magicalleaf: ["9M", "9L13"],
			mudslap: ["9M"],
			nastyplot: ["9M"],
			playrough: ["9M", "9L42"],
			protect: ["9M"],
			quickattack: ["9L15"],
			rest: ["9M"],
			scratch: ["9L1"],
			seedbomb: ["9M", "9L20"],
			shadowclaw: ["9M"],
			slash: ["9L33"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			substitute: ["9M"],
			swift: ["9M"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thunderpunch: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L24"],
			worryseed: ["9L28"],
		},
	},
	meowscarada: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			agility: ["9M"],
			aurasphere: ["9M"],
			bite: ["9L7"],
			brickbreak: ["9M"],
			bulletseed: ["9M"],
			charm: ["9M"],
			chillingwater: ["9M"],
			darkpulse: ["9M"],
			disarmingvoice: ["9M"],
			doubleteam: ["9M"],
			endure: ["9M"],
			energyball: ["9M", "9L42"],
			facade: ["9M"],
			faketears: ["9M"],
			fling: ["9M"],
			flowertrick: ["9L0"],
			foulplay: ["9M"],
			frenzyplant: ["9M"],
			gigadrain: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grasspledge: ["9M"],
			grassyterrain: ["9M", "9L58"],
			helpinghand: ["9M"],
			honeclaws: ["9L10"],
			hyperbeam: ["9M"],
			knockoff: ["9L52"],
			leafage: ["9L1"],
			leafstorm: ["9M", "9L64"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			magicalleaf: ["9M", "9L13"],
			mudslap: ["9M"],
			nastyplot: ["9M"],
			nightslash: ["9L38"],
			playrough: ["9M", "9L47"],
			pollenpuff: ["9M"],
			powergem: ["9M"],
			protect: ["9M"],
			quickattack: ["9L15"],
			rest: ["9M"],
			scratch: ["9L1"],
			seedbomb: ["9M", "9L20"],
			shadowball: ["9M"],
			shadowclaw: ["9M"],
			skillswap: ["9M"],
			slash: ["9L33"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			spikes: ["9M"],
			substitute: ["9M"],
			swift: ["9M"],
			tailwhip: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderpunch: ["9M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			uturn: ["9M", "9L24"],
			worryseed: ["9L29"],
		},
	},
	fuecoco: {
		learnset: {
			belch: ["9E"],
			bite: ["9L12"],
			bodyslam: ["9M"],
			crunch: ["9M"],
			curse: ["9E"],
			dig: ["9M"],
			disarmingvoice: ["9M"],
			ember: ["9L1"],
			encore: ["9M", "9E"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M", "9L36"],
			firefang: ["9M"],
			firepledge: ["9M"],
			firespin: ["9M"],
			flamecharge: ["9M"],
			flamethrower: ["9M", "9L28"],
			flareblitz: ["9M"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			hypervoice: ["9M", "9L32"],
			incinerate: ["9L15"],
			leer: ["9L1"],
			mudslap: ["9M"],
			outrage: ["9M"],
			overheat: ["9M"],
			protect: ["9M"],
			rest: ["9M"],
			roar: ["9L25"],
			round: ["9L7"],
			seedbomb: ["9M"],
			slackoff: ["9E"],
			sleeptalk: ["9M"],
			snarl: ["9M", "9L21"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			willowisp: ["9M"],
			yawn: ["9L17"],
			zenheadbutt: ["9M"],
		},
	},
	crocalor: {
		learnset: {
			bite: ["9L12"],
			bodyslam: ["9M"],
			crunch: ["9M"],
			dig: ["9M"],
			disarmingvoice: ["9M"],
			ember: ["9L1"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M", "9L47"],
			firefang: ["9M"],
			firepledge: ["9M"],
			firespin: ["9M"],
			flamecharge: ["9M"],
			flamethrower: ["9M", "9L32"],
			flareblitz: ["9M"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			hypervoice: ["9M", "9L38"],
			incinerate: ["9L17"],
			leer: ["9L1"],
			lick: ["9L7"],
			mudslap: ["9M"],
			outrage: ["9M"],
			overheat: ["9M"],
			protect: ["9M"],
			rest: ["9M"],
			roar: ["9L28"],
			round: ["9L10"],
			seedbomb: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M", "9L24"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			willowisp: ["9M", "9L42"],
			yawn: ["9L15"],
			zenheadbutt: ["9M"],
		},
	},
	skeledirge: {
		learnset: {
			bite: ["9L15"],
			blastburn: ["9M"],
			bodyslam: ["9M"],
			crunch: ["9M"],
			dig: ["9M"],
			disarmingvoice: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M"],
			ember: ["9L1"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M", "9L58"],
			firefang: ["9M"],
			firepledge: ["9M"],
			firespin: ["9M"],
			flamecharge: ["9M"],
			flamethrower: ["9M", "9L32"],
			flareblitz: ["9M"],
			gigaimpact: ["9M"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			hex: ["9M", "9L47"],
			hyperbeam: ["9M"],
			hypervoice: ["9M", "9L42"],
			imprison: ["9M"],
			incinerate: ["9L17"],
			leer: ["9L1"],
			lick: ["9L7"],
			mudslap: ["9M"],
			nightshade: ["9M"],
			outrage: ["9M"],
			overheat: ["9M", "9L64"],
			protect: ["9M"],
			rest: ["9M"],
			roar: ["9L28"],
			round: ["9L10"],
			scaryface: ["9M", "9L12"],
			seedbomb: ["9M"],
			shadowball: ["9M", "9L38"],
			shadowclaw: ["9M"],
			sing: ["9L1"],
			sleeptalk: ["9M"],
			snarl: ["9M", "9L24"],
			solarbeam: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			torchsong: ["9L0"],
			willowisp: ["9M", "9L47"],
			zenheadbutt: ["9M"],
		},
	},
	quaxly: {
		learnset: {
			acrobatics: ["9M", "9L31"],
			aerialace: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M", "9L24"],
			aquacutter: ["9L21"],
			aquajet: ["9L13"],
			batonpass: ["9M"],
			bravebird: ["9M"],
			chillingwater: ["9M"],
			detect: ["9E"],
			disarmingvoice: ["9M"],
			doublehit: ["9L17"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			focusenergy: ["9L28"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			hydropump: ["9M"],
			lastresort: ["9E"],
			liquidation: ["9M", "9L35"],
			lowkick: ["9M"],
			mistyterrain: ["9M"],
			pound: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rapidspin: ["9E"],
			rest: ["9M"],
			roost: ["9E"],
			surf: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			watergun: ["9L1"],
			waterpledge: ["9M"],
			wingattack: ["9L10"],
			workup: ["9L7"],
		},
	},
	quaxwell: {
		learnset: {
			acrobatics: ["9M", "9L38"],
			aerialace: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M", "9L27"],
			aquacutter: ["9L23"],
			aquajet: ["9L13"],
			batonpass: ["9M"],
			bravebird: ["9M"],
			chillingwater: ["9M"],
			disarmingvoice: ["9M"],
			doublehit: ["9L1"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			featherdance: ["9L48"],
			focusenergy: ["9L32"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			hydropump: ["9M"],
			liquidation: ["9M", "9L43"],
			lowkick: ["9M"],
			lowsweep: ["9M", "9L19"],
			mistyterrain: ["9M"],
			pound: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			surf: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			watergun: ["9L1"],
			waterpledge: ["9M"],
			waterpulse: ["9M", "9L17"],
			wingattack: ["9L10"],
			workup: ["9L7"],
		},
	},
	quaquaval: {
		learnset: {
			acrobatics: ["9M", "9L43"],
			aerialace: ["9M"],
			agility: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M", "9L27"],
			aquacutter: ["9L21"],
			aquajet: ["9L13"],
			aquastep: ["9L0"],
			batonpass: ["9M"],
			bravebird: ["9M"],
			brickbreak: ["9M"],
			bulkup: ["9M"],
			chillingwater: ["9M"],
			closecombat: ["9M", "9L58"],
			counter: ["9L1"],
			disarmingvoice: ["9M"],
			doublehit: ["9L1"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			featherdance: ["9L52"],
			fling: ["9M"],
			focusenergy: ["9L32"],
			gigaimpact: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			hurricane: ["9M"],
			hydrocannon: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icespinner: ["9M"],
			icywind: ["9M"],
			liquidation: ["9M", "9L47"],
			lowkick: ["9M"],
			lowsweep: ["9M", "9L17"],
			megakick: ["9L38"],
			mistyterrain: ["9M"],
			pound: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			surf: ["9M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			uturn: ["9M"],
			watergun: ["9L1"],
			waterpledge: ["9M"],
			waterpulse: ["9M", "9L17"],
			wavecrash: ["9L64"],
			wingattack: ["9L10"],
			workup: ["9L7"],
		},
	},
	lechonk: {
		learnset: {
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			bulletseed: ["9M"],
			chillingwater: ["9M"],
			covet: ["9L15"],
			dig: ["9M", "9L17"],
			disarmingvoice: ["9M", "9L5"],
			doubleedge: ["9L35"],
			echoedvoice: ["9L8"],
			endeavor: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			headbutt: ["9L21"],
			helpinghand: ["9M"],
			hypervoice: ["9M"],
			ironhead: ["9M"],
			mudshot: ["9M", "9L12"],
			mudslap: ["9M"],
			playrough: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			seedbomb: ["9M"],
			sleeptalk: ["9M"],
			spitup: ["9E"],
			stockpile: ["9E"],
			stuffcheeks: ["9E"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swallow: ["9E"],
			tackle: ["9L1"],
			tailwhip: ["9L1"],
			takedown: ["9M", "9L27"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uproar: ["9L32"],
			workup: ["9L30"],
			yawn: ["9L24"],
			zenheadbutt: ["9M"],
		},
	},
	oinkologne: {
		learnset: {
			belch: ["9L54"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			bulletseed: ["9M"],
			chillingwater: ["9M"],
			covet: ["9L15"],
			dig: ["9M", "9L17"],
			disarmingvoice: ["9M", "9L5"],
			doubleedge: ["9L42"],
			earthpower: ["9M", "9L48"],
			echoedvoice: ["9L8"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			gigaimpact: ["9M"],
			headbutt: ["9L23"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			ironhead: ["9M"],
			mudshot: ["9M", "9L12"],
			mudslap: ["9M"],
			playrough: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			seedbomb: ["9M"],
			sleeptalk: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			tailwhip: ["9L1"],
			takedown: ["9M", "9L26"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uproar: ["9L38"],
			workup: ["9L34"],
			yawn: ["9L27"],
			zenheadbutt: ["9M"],
		},
	},
	oinkolognef: {
		learnset: {
			belch: ["9L51"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			bulletseed: ["9M"],
			chillingwater: ["9M"],
			covet: ["9L12"],
			dig: ["9M", "9L15"],
			disarmingvoice: ["9M", "9L3"],
			doubleedge: ["9L39"],
			earthpower: ["9M", "9L45"],
			echoedvoice: ["9L6"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			gigaimpact: ["9M"],
			headbutt: ["9L17"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			ironhead: ["9M"],
			mudshot: ["9M", "9L9"],
			mudslap: ["9M"],
			playrough: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			seedbomb: ["9M"],
			sleeptalk: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			tailwhip: ["9L1"],
			takedown: ["9M", "9L28"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uproar: ["9L34"],
			workup: ["9L30"],
			yawn: ["9L23"],
			zenheadbutt: ["9M"],
		},
	},
	tarountula: {
		learnset: {
			assurance: ["9L8"],
			block: ["9L18"],
			bodyslam: ["9M"],
			bugbite: ["9L14"],
			bugbuzz: ["9M"],
			bulletseed: ["9M"],
			circlethrow: ["9L36"],
			counter: ["9L22"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			feint: ["9L11"],
			firstimpression: ["9E"],
			gastroacid: ["9L33"],
			gigadrain: ["9M"],
			grassknot: ["9M"],
			headbutt: ["9L25"],
			leechlife: ["9M"],
			lunge: ["9E"],
			memento: ["9E"],
			poisonjab: ["9M"],
			pounce: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			shadowclaw: ["9M"],
			skittersmack: ["9L44"],
			sleeptalk: ["9M"],
			spikes: ["9M"],
			stickyweb: ["9L29"],
			stringshot: ["9L1"],
			strugglebug: ["9M", "9L5"],
			substitute: ["9M"],
			suckerpunch: ["9E"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["9L40"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			xscissor: ["9M"],
		},
	},
	spidops: {
		learnset: {
			aerialace: ["9M"],
			assurance: ["9L8"],
			block: ["9L19"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bugbite: ["9L14"],
			bugbuzz: ["9M"],
			bulletseed: ["9M"],
			circlethrow: ["9L41"],
			counter: ["9L24"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			feint: ["9L11"],
			fling: ["9M"],
			gastroacid: ["9L37"],
			gigadrain: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			headbutt: ["9L28"],
			leechlife: ["9M"],
			lowkick: ["9M"],
			poisonjab: ["9M"],
			pounce: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			rocktomb: ["9M"],
			scaryface: ["9M"],
			shadowclaw: ["9M"],
			silktrap: ["9L0"],
			skittersmack: ["9L49"],
			sleeptalk: ["9M"],
			spikes: ["9M"],
			stickyweb: ["9L33"],
			stringshot: ["9L1"],
			strugglebug: ["9M", "9L5"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["9L45"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M"],
			xscissor: ["9M"],
		},
	},
	nymble: {
		learnset: {
			agility: ["9M", "9L30"],
			assurance: ["9L9"],
			astonish: ["9L6"],
			bugbite: ["9L22"],
			bugbuzz: ["9M"],
			counter: ["9E"],
			doublekick: ["9L11"],
			endure: ["9M", "9L18"],
			facade: ["9M"],
			feint: ["9L26"],
			firstimpression: ["9L41"],
			leechlife: ["9M"],
			leer: ["9L1"],
			pounce: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			screech: ["9L14"],
			skittersmack: ["9E"],
			sleeptalk: ["9M"],
			strugglebug: ["9M", "9L4"],
			substitute: ["9M"],
			suckerpunch: ["9L38"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M"],
			xscissor: ["9M"],
		},
	},
	lokix: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M", "9L32"],
			assurance: ["9L9"],
			astonish: ["9L6"],
			axekick: ["9L53"],
			bounce: ["9L48"],
			brickbreak: ["9M"],
			bugbite: ["9L22"],
			bugbuzz: ["9M"],
			darkpulse: ["9M"],
			detect: ["9L1"],
			doublekick: ["9L11"],
			endure: ["9M", "9L18"],
			facade: ["9M"],
			feint: ["9L28"],
			firstimpression: ["9L44"],
			fling: ["9M"],
			gigaimpact: ["9M"],
			leechlife: ["9M"],
			leer: ["9L1"],
			lowkick: ["9M", "9L1"],
			lowsweep: ["9M"],
			lunge: ["9L0"],
			pounce: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			scaryface: ["9M"],
			screech: ["9L14"],
			sleeptalk: ["9M"],
			strugglebug: ["9M", "9L4"],
			substitute: ["9M"],
			suckerpunch: ["9L40"],
			sunnyday: ["9M"],
			swordsdance: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["9L36"],
			trailblaze: ["9M"],
			uturn: ["9M"],
			xscissor: ["9M"],
		},
	},
	rellor: {
		learnset: {
			bugbite: ["9L20"],
			bugbuzz: ["9M"],
			cosmicpower: ["9E"],
			defensecurl: ["9L1"],
			dig: ["9M", "9L29"],
			endure: ["9M"],
			facade: ["9M"],
			fling: ["9M"],
			gunkshot: ["9M"],
			irondefense: ["9M"],
			leechlife: ["9M"],
			lunge: ["9L35"],
			memento: ["9E"],
			mudshot: ["9M", "9L15"],
			mudslap: ["9M"],
			pounce: ["9M"],
			protect: ["9M"],
			recover: ["9E"],
			rest: ["9M"],
			rocktomb: ["9M"],
			rollout: ["9L11"],
			sandattack: ["9L4"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			strugglebug: ["9M", "9L7"],
			substitute: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M", "9L24"],
			terablast: ["9M"],
			thief: ["9M"],
			weatherball: ["9E"],
			xscissor: ["9M"],
		},
	},
	rabsca: {
		learnset: {
			bugbite: ["9L20"],
			bugbuzz: ["9M", "9L45"],
			calmmind: ["9M"],
			confuseray: ["9M"],
			confusion: ["9L1"],
			dazzlinggleam: ["9M"],
			defensecurl: ["9L1"],
			dig: ["9M"],
			earthpower: ["9M"],
			electroball: ["9M"],
			endure: ["9M"],
			energyball: ["9M"],
			extrasensory: ["9L29"],
			facade: ["9M"],
			fling: ["9M"],
			gigaimpact: ["9M"],
			guardswap: ["9L40"],
			gunkshot: ["9M"],
			hyperbeam: ["9M"],
			imprison: ["9M"],
			irondefense: ["9M"],
			leechlife: ["9M"],
			lightscreen: ["9M"],
			lunge: ["9L35"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			pounce: ["9M"],
			powergem: ["9M"],
			powerswap: ["9L40"],
			protect: ["9M"],
			psybeam: ["9M", "9L15"],
			psychic: ["9M", "9L50"],
			psychicterrain: ["9M"],
			psychup: ["9L1"],
			psyshock: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			revivalblessing: ["9L0"],
			rocktomb: ["9M"],
			rollout: ["9L11"],
			safeguard: ["9L1"],
			sandattack: ["9L4"],
			sandstorm: ["9M"],
			shadowball: ["9M"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			speedswap: ["9L40"],
			storedpower: ["9M"],
			strugglebug: ["9M", "9L7"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M", "9L24"],
			terablast: ["9M"],
			thief: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			xscissor: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	greavard: {
		learnset: {
			allyswitch: ["9E"],
			bite: ["9L6"],
			bulldoze: ["9M"],
			charm: ["9M", "9L46"],
			confuseray: ["9M"],
			crunch: ["9M", "9L28"],
			destinybond: ["9E"],
			dig: ["9M", "9L16"],
			disable: ["9E"],
			doubleedge: ["9L52"],
			endure: ["9M"],
			facade: ["9M"],
			firefang: ["9M"],
			growl: ["9L1"],
			headbutt: ["9L12"],
			helpinghand: ["9M", "9L37"],
			hex: ["9M"],
			howl: ["9E"],
			icefang: ["9M"],
			lick: ["9L3"],
			memento: ["9E"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			nightshade: ["9M"],
			phantomforce: ["9M", "9L41"],
			playrough: ["9M", "9L32"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M", "9L24"],
			roar: ["9L9"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			shadowball: ["9M"],
			shadowsneak: ["9E"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			tailwhip: ["9L6"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderfang: ["9M"],
			trick: ["9M"],
			yawn: ["9E"],
		},
	},
	houndstone: {
		learnset: {
			bite: ["9L6"],
			bodypress: ["9M"],
			bulldoze: ["9M"],
			charm: ["9M", "9L51"],
			confuseray: ["9M"],
			crunch: ["9M", "9L28"],
			dig: ["9M", "9L16"],
			doubleedge: ["9L58"],
			endure: ["9M"],
			facade: ["9M"],
			firefang: ["9M"],
			gigaimpact: ["9M"],
			growl: ["9L1"],
			headbutt: ["9L12"],
			helpinghand: ["9M", "9L41"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			icefang: ["9M"],
			lastrespects: ["9L0"],
			lick: ["9L3"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			nightshade: ["9M"],
			phantomforce: ["9M", "9L46"],
			playrough: ["9M", "9L36"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M", "9L24"],
			roar: ["9L9"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			shadowball: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			tailwhip: ["9L6"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderfang: ["9M"],
			trick: ["9M"],
			willowisp: ["9M"],
		},
	},
	flittle: {
		learnset: {
			agility: ["9M", "9L29"],
			allyswitch: ["9E"],
			babydolleyes: ["9L8"],
			batonpass: ["9M"],
			calmmind: ["9M"],
			confuseray: ["9M"],
			confusion: ["9L5"],
			disarmingvoice: ["9M", "9L11"],
			endure: ["9M"],
			facade: ["9M"],
			foulplay: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			hypnosis: ["9E"],
			lightscreen: ["9M"],
			mudslap: ["9M"],
			peck: ["9L1"],
			pluck: ["9L24"],
			pounce: ["9M"],
			protect: ["9M"],
			psybeam: ["9M", "9L19"],
			psychic: ["9M"],
			psychicterrain: ["9M"],
			psyshock: ["9M"],
			quickattack: ["9L15"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			roost: ["9E"],
			sandstorm: ["9M"],
			seedbomb: ["9M"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			uproar: ["9L34"],
			uturn: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	espathra: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M", "9L29"],
			babydolleyes: ["9L8"],
			batonpass: ["9M"],
			bodyslam: ["9M"],
			bravebird: ["9M"],
			calmmind: ["9M"],
			confuseray: ["9M"],
			confusion: ["9L5"],
			dazzlinggleam: ["9M", "9L43"],
			disarmingvoice: ["9M", "9L11"],
			drillpeck: ["9L1"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			featherdance: ["9L1"],
			flashcannon: ["9M"],
			foulplay: ["9M"],
			gigaimpact: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			lastresort: ["9L54"],
			lightscreen: ["9M"],
			lowkick: ["9M"],
			luminacrash: ["9L0"],
			mudslap: ["9M"],
			nightshade: ["9M"],
			peck: ["9L1"],
			pluck: ["9L24"],
			pounce: ["9M"],
			protect: ["9M"],
			psybeam: ["9M", "9L19"],
			psychic: ["9M", "9L49"],
			psychicterrain: ["9M"],
			psyshock: ["9M"],
			quickattack: ["9L15"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			sandstorm: ["9M"],
			seedbomb: ["9M"],
			shadowball: ["9M"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			uproar: ["9L34"],
			uturn: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	farigiraf: {
		learnset: {
			agility: ["9M", "9L23"],
			amnesia: ["9M"],
			assurance: ["9L10"],
			astonish: ["9L1"],
			batonpass: ["9M", "9L41"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			calmmind: ["9M"],
			chargebeam: ["9M"],
			confuseray: ["9M"],
			confusion: ["9L5"],
			crunch: ["9M", "9L37"],
			dazzlinggleam: ["9M"],
			doublehit: ["9L28"],
			earthquake: ["9M"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			foulplay: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			growl: ["9L1"],
			guardswap: ["9L1"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			imprison: ["9M"],
			ironhead: ["9M"],
			lightscreen: ["9M"],
			lowkick: ["9M"],
			nastyplot: ["9M", "9L46"],
			nightshade: ["9M"],
			powerswap: ["9L1"],
			protect: ["9M"],
			psybeam: ["9M", "9L19"],
			psychic: ["9M", "9L50"],
			psychicfangs: ["9M"],
			psychicterrain: ["9M"],
			psyshock: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			shadowball: ["9M"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			stomp: ["9L14"],
			stompingtantrum: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderwave: ["9M"],
			trailblaze: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			twinbeam: ["9L32"],
			zenheadbutt: ["9M"],
		},
	},
	wiglett: {
		learnset: {
			agility: ["9M"],
			aquajet: ["9L12"],
			blizzard: ["9M"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			dig: ["9M", "9L28"],
			earthpower: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			finalgambit: ["9E"],
			foulplay: ["9M"],
			headbutt: ["9L24"],
			helpinghand: ["9M"],
			hydropump: ["9M"],
			icebeam: ["9M"],
			liquidation: ["9M", "9L40"],
			memento: ["9E"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L4"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			sandattack: ["9L1"],
			sandstorm: ["9M"],
			slam: ["9L20"],
			sleeptalk: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			suckerpunch: ["9L32"],
			surf: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			throatchop: ["9L36"],
			watergun: ["9L1"],
			waterpulse: ["9M", "9L20"],
			wrap: ["9L8"],
		},
	},
	wugtrio: {
		learnset: {
			agility: ["9M"],
			aquajet: ["9L12"],
			blizzard: ["9M"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			dig: ["9M", "9L36"],
			earthpower: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			foulplay: ["9M"],
			gigaimpact: ["9M"],
			headbutt: ["9L24"],
			helpinghand: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M"],
			liquidation: ["9M", "9L54"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			sandattack: ["9L1"],
			sandstorm: ["9M"],
			slam: ["9L16"],
			sleeptalk: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			suckerpunch: ["9L42"],
			surf: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			throatchop: ["9L48"],
			tripledive: ["9L30"],
			watergun: ["9L1"],
			waterpulse: ["9M", "9L20"],
			wrap: ["9L1"],
		},
	},
	dondozo: {
		learnset: {
			aquatail: ["9L40"],
			avalanche: ["9M"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L35"],
			bulldoze: ["9M"],
			chillingwater: ["9M"],
			crunch: ["9M"],
			curse: ["9E"],
			dive: ["9L20"],
			doubleedge: ["9L60"],
			earthquake: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			fissure: ["9E"],
			flail: ["9L10"],
			gigaimpact: ["9M"],
			heavyslam: ["9M", "9L55"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icefang: ["9M"],
			liquidation: ["9M"],
			nobleroar: ["9L25"],
			orderup: ["9L50"],
			outrage: ["9M"],
			protect: ["9M"],
			raindance: ["9M", "9L45"],
			rest: ["9M", "9L15"],
			rockslide: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M", "9L15"],
			soak: ["9L30"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			supersonic: ["9L1"],
			surf: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9E"],
			tickle: ["9L5"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M"],
			wavecrash: ["9L65"],
			yawn: ["9E"],
			zenheadbutt: ["9M"],
		},
	},
	veluza: {
		learnset: {
			agility: ["9M"],
			aquacutter: ["9L25"],
			aquajet: ["9L1"],
			blizzard: ["9M"],
			bodyslam: ["9M"],
			chillingwater: ["9M"],
			crunch: ["9M", "9L50"],
			drillrun: ["9M"],
			endure: ["9M"],
			filletaway: ["9L30"],
			finalgambit: ["9L55"],
			focusenergy: ["9L15"],
			gigaimpact: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M"],
			icefang: ["9M"],
			icywind: ["9M"],
			liquidation: ["9M", "9L45"],
			nightslash: ["9L35"],
			pluck: ["9L7"],
			protect: ["9M"],
			psychic: ["9M"],
			psychicfangs: ["9M"],
			psychicterrain: ["9M"],
			psychocut: ["9L40"],
			raindance: ["9M"],
			recover: ["9E"],
			rest: ["9M"],
			slash: ["9L20"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			surf: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9E"],
			waterfall: ["9M"],
			waterpulse: ["9M", "9L11"],
			zenheadbutt: ["9M"],
		},
	},
	finizen: {
		learnset: {
			acrobatics: ["9M", "9L29"],
			agility: ["9M"],
			aquajet: ["9L13"],
			aquatail: ["9L39"],
			astonish: ["9L7"],
			blizzard: ["9M"],
			bodyslam: ["9M"],
			boomburst: ["9E"],
			bounce: ["9E"],
			charm: ["9M", "9L25"],
			chillingwater: ["9M"],
			counter: ["9E"],
			disarmingvoice: ["9M"],
			dive: ["9L21"],
			doublehit: ["9L17"],
			drainingkiss: ["9M"],
			encore: ["9M", "9L34"],
			endure: ["9M"],
			facade: ["9M"],
			fling: ["9M"],
			focusenergy: ["9L10"],
			haze: ["9E"],
			helpinghand: ["9M"],
			hydropump: ["9M", "9L50"],
			icebeam: ["9M"],
			icywind: ["9M"],
			liquidation: ["9M"],
			mist: ["9L44"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			supersonic: ["9L1"],
			surf: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			tickle: ["9E"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	palafin: {
		learnset: {
			acrobatics: ["9M", "9L29"],
			agility: ["9M"],
			aquajet: ["9L13"],
			aquatail: ["9L39"],
			astonish: ["9L7"],
			aurasphere: ["9M"],
			blizzard: ["9M"],
			bodyslam: ["9M"],
			bulkup: ["9M"],
			charm: ["9M", "9L25"],
			chillingwater: ["9M"],
			closecombat: ["9M"],
			disarmingvoice: ["9M"],
			dive: ["9L21"],
			doublehit: ["9L17"],
			drainingkiss: ["9M"],
			drainpunch: ["9M"],
			encore: ["9M", "9L34"],
			endure: ["9M"],
			facade: ["9M"],
			fling: ["9M"],
			flipturn: ["9L0"],
			focusblast: ["9M"],
			focusenergy: ["9L10"],
			focuspunch: ["9L55"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			helpinghand: ["9M"],
			hydropump: ["9M", "9L50"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icebeam: ["9M"],
			icepunch: ["9M"],
			icywind: ["9M"],
			ironhead: ["9M"],
			jetpunch: ["9L1"],
			liquidation: ["9M"],
			mist: ["9L44"],
			outrage: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			supersonic: ["9L1"],
			surf: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			waterfall: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M"],
			wavecrash: ["9L61"],
			zenheadbutt: ["9M"],
		},
	},
	smoliv: {
		learnset: {
			absorb: ["9L5"],
			bulletseed: ["9M"],
			charm: ["9M"],
			earthpower: ["9M"],
			endure: ["9M"],
			energyball: ["9M", "9L30"],
			facade: ["9M"],
			flail: ["9L16"],
			gigadrain: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M", "9L23"],
			growth: ["9L7"],
			helpinghand: ["9M", "9L13"],
			leafstorm: ["9M"],
			leechseed: ["9L34"],
			magicalleaf: ["9M"],
			megadrain: ["9L20"],
			memento: ["9E"],
			protect: ["9M"],
			razorleaf: ["9L10"],
			rest: ["9M"],
			seedbomb: ["9M", "9L27"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			strengthsap: ["9E"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			sweetscent: ["9L1"],
			swift: ["9M"],
			synthesis: ["9E"],
			tackle: ["9L1"],
			terablast: ["9M"],
			terrainpulse: ["9L38"],
			trailblaze: ["9M"],
			weatherball: ["9E"],
		},
	},
	dolliv: {
		learnset: {
			absorb: ["9L5"],
			bulletseed: ["9M"],
			charm: ["9M"],
			earthpower: ["9M"],
			endure: ["9M"],
			energyball: ["9M", "9L34"],
			facade: ["9M"],
			flail: ["9L16"],
			gigadrain: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M", "9L23"],
			growth: ["9L7"],
			helpinghand: ["9M", "9L13"],
			leafstorm: ["9M"],
			leechseed: ["9L37"],
			magicalleaf: ["9M"],
			megadrain: ["9L20"],
			protect: ["9M"],
			razorleaf: ["9L10"],
			rest: ["9M"],
			seedbomb: ["9M", "9L29"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			sweetscent: ["9L1"],
			swift: ["9M"],
			tackle: ["9L1"],
			terablast: ["9M"],
			terrainpulse: ["9L42"],
			trailblaze: ["9M"],
		},
	},
	arboliva: {
		learnset: {
			absorb: ["9L5"],
			bulletseed: ["9M"],
			charm: ["9M"],
			dazzlinggleam: ["9M"],
			earthpower: ["9M"],
			encore: ["9M"],
			endure: ["9M"],
			energyball: ["9M", "9L34"],
			facade: ["9M"],
			flail: ["9L16"],
			fling: ["9M"],
			gigadrain: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M", "9L23"],
			growth: ["9L7"],
			helpinghand: ["9M", "9L13"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			leafstorm: ["9M"],
			leechseed: ["9L39"],
			lightscreen: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L20"],
			metronome: ["9M"],
			mirrorcoat: ["9L1"],
			petalblizzard: ["9L52"],
			petaldance: ["9L58"],
			pollenpuff: ["9M"],
			protect: ["9M"],
			razorleaf: ["9L10"],
			reflect: ["9M"],
			rest: ["9M"],
			safeguard: ["9L1"],
			seedbomb: ["9M", "9L29"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			sweetscent: ["9L1"],
			swift: ["9M"],
			tackle: ["9L1"],
			terablast: ["9M"],
			terrainpulse: ["9L46"],
			trailblaze: ["9M"],
		},
	},
	capsakid: {
		learnset: {
			bite: ["9L4"],
			bulletseed: ["9M", "9L21"],
			crunch: ["9M", "9L38"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			gigadrain: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			growth: ["9L10"],
			headbutt: ["9L24"],
			helpinghand: ["9M"],
			ingrain: ["9E"],
			leafage: ["9L1"],
			leafstorm: ["9M"],
			leechseed: ["9E"],
			leer: ["9L1"],
			magicalleaf: ["9M"],
			protect: ["9M"],
			ragepowder: ["9E"],
			razorleaf: ["9L13"],
			rest: ["9M"],
			rollout: ["9E"],
			sandstorm: ["9M"],
			seedbomb: ["9M", "9L44"],
			sleeptalk: ["9M"],
			solarbeam: ["9M", "9L48"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L17"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			worryseed: ["9E"],
			zenheadbutt: ["9M", "9L28"],
		},
	},
	scovillain: {
		learnset: {
			bite: ["9L4"],
			bulletseed: ["9M", "9L21"],
			crunch: ["9M", "9L38"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M", "9L1"],
			flamethrower: ["9M", "9L0"],
			gigadrain: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			growth: ["9L10"],
			headbutt: ["9L24"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			leafage: ["9L1"],
			leafstorm: ["9M"],
			leer: ["9L1"],
			magicalleaf: ["9M"],
			overheat: ["9M", "9L48"],
			protect: ["9M"],
			razorleaf: ["9L13"],
			rest: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			seedbomb: ["9M", "9L44"],
			sleeptalk: ["9M"],
			solarbeam: ["9M", "9L48"],
			spicyextract: ["9L0"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L17"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			willowisp: ["9M"],
			worryseed: ["9L33"],
			zenheadbutt: ["9M", "9L28"],
		},
	},
	tadbulb: {
		learnset: {
			acidspray: ["9M"],
			charge: ["9L17"],
			chargebeam: ["9M"],
			chillingwater: ["9M"],
			confuseray: ["9M"],
			discharge: ["9L32"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "9L40"],
			electroball: ["9M"],
			endure: ["9M"],
			flail: ["9L25"],
			hypervoice: ["9M"],
			lightscreen: ["9M"],
			muddywater: ["9E"],
			mudshot: ["9M", "9L24"],
			mudslap: ["9M", "9L1"],
			paraboliccharge: ["9E"],
			protect: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			sleeptalk: ["9M"],
			soak: ["9E"],
			spark: ["9L21"],
			substitute: ["9M"],
			suckerpunch: ["9L45"],
			swift: ["9M"],
			tackle: ["9L1"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thundershock: ["9L7"],
			thunderwave: ["9M"],
			voltswitch: ["9M"],
			watergun: ["9L11"],
			waterpulse: ["9M"],
			weatherball: ["9L36"],
			wildcharge: ["9M"],
			zapcannon: ["9L50"],
		},
	},
	bellibolt: {
		learnset: {
			acidspray: ["9M"],
			charge: ["9L17"],
			chargebeam: ["9M"],
			chillingwater: ["9M"],
			confuseray: ["9M"],
			discharge: ["9L32"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "9L40"],
			electroball: ["9M"],
			endure: ["9M"],
			flail: ["9L25"],
			gigaimpact: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			lightscreen: ["9M"],
			mudshot: ["9M", "9L24"],
			mudslap: ["9M", "9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			slackoff: ["9L1"],
			sleeptalk: ["9M"],
			spark: ["9L21"],
			substitute: ["9M"],
			suckerpunch: ["9L45"],
			swift: ["9M"],
			tackle: ["9L1"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thundershock: ["9L7"],
			thunderwave: ["9M"],
			voltswitch: ["9M"],
			watergun: ["9L11"],
			waterpulse: ["9M"],
			weatherball: ["9L36"],
			wildcharge: ["9M"],
			zapcannon: ["9L50"],
		},
	},
	varoom: {
		learnset: {
			acidspray: ["9M"],
			assurance: ["9L10"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			flashcannon: ["9M"],
			gunkshot: ["9M", "9L50"],
			gyroball: ["9L17"],
			haze: ["9E"],
			headbutt: ["9L21"],
			irondefense: ["9M"],
			ironhead: ["9M", "9L28"],
			lick: ["9L1"],
			partingshot: ["9E"],
			poisongas: ["9L1"],
			poisonjab: ["9M", "9L36"],
			protect: ["9M"],
			raindance: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			screech: ["9L25"],
			selfdestruct: ["9E"],
			sleeptalk: ["9M"],
			sludge: ["9L13"],
			sludgebomb: ["9M"],
			smog: ["9L4"],
			spinout: ["9L46"],
			steelbeam: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swagger: ["9L32"],
			takedown: ["9M"],
			taunt: ["9M", "9L7"],
			terablast: ["9M"],
			thief: ["9M"],
			torment: ["9E"],
			toxic: ["9E"],
			toxicspikes: ["9M"],
			uproar: ["9L41"],
			venoshock: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	revavroom: {
		learnset: {
			acidspray: ["9M"],
			assurance: ["9L10"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M"],
			gunkshot: ["9M", "9L58"],
			gyroball: ["9L17"],
			headbutt: ["9L21"],
			heavyslam: ["9M"],
			hyperbeam: ["9M"],
			irondefense: ["9M"],
			ironhead: ["9M", "9L28"],
			lick: ["9L1"],
			magnetrise: ["9L1"],
			overheat: ["9M"],
			poisongas: ["9L1"],
			poisonjab: ["9M", "9L36"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			screech: ["9L25"],
			shiftgear: ["9L0"],
			sleeptalk: ["9M"],
			sludge: ["9L13"],
			sludgebomb: ["9M"],
			smog: ["9L4"],
			spinout: ["9L52"],
			steelbeam: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swagger: ["9L32"],
			takedown: ["9M"],
			taunt: ["9M", "9L7"],
			terablast: ["9M"],
			thief: ["9M"],
			toxicspikes: ["9M"],
			uproar: ["9L46"],
			venoshock: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	orthworm: {
		learnset: {
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M", "9L16"],
			coil: ["9E"],
			curse: ["9E"],
			dig: ["9M", "9L30"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L47"],
			endure: ["9M"],
			facade: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M"],
			harden: ["9L1"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			irondefense: ["9M", "9L38"],
			ironhead: ["9M", "9L21"],
			irontail: ["9L43"],
			metalburst: ["9E"],
			mudshot: ["9M"],
			mudslap: ["9M", "9L7"],
			protect: ["9M"],
			rest: ["9M"],
			rockblast: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandstorm: ["9M", "9L34"],
			shedtail: ["9L52"],
			sleeptalk: ["9M"],
			smackdown: ["9L12"],
			spikes: ["9M"],
			stealthrock: ["9M"],
			steelbeam: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M", "9L26"],
			terablast: ["9M"],
			wrap: ["9L1"],
		},
	},
	tandemaus: {
		learnset: {
			aerialace: ["9M"],
			afteryou: ["9E"],
			agility: ["9M"],
			babydolleyes: ["9L1"],
			batonpass: ["9M", "9E"],
			beatup: ["9L37"],
			bite: ["9E"],
			bulletseed: ["9M", "9L18"],
			charm: ["9M", "9L33"],
			copycat: ["9L41"],
			crunch: ["9M"],
			dig: ["9M"],
			doublehit: ["9L14"],
			echoedvoice: ["9L5"],
			encore: ["9M", "9L22"],
			endure: ["9M"],
			facade: ["9M"],
			faketears: ["9M"],
			feint: ["9E"],
			grassknot: ["9M"],
			helpinghand: ["9M", "9L8"],
			hypervoice: ["9M", "9L30"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			playrough: ["9M", "9L26"],
			populationbomb: ["9L46"],
			pound: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			seedbomb: ["9M"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			superfang: ["9L11"],
			swift: ["9M"],
			switcheroo: ["9E"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderwave: ["9M"],
			tickle: ["9E"],
			uturn: ["9M"],
			waterpulse: ["9M"],
		},
	},
	maushold: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M"],
			babydolleyes: ["9L1"],
			beatup: ["9L41"],
			bulletseed: ["9M", "9L18"],
			charm: ["9M", "9L37"],
			chillingwater: ["9M"],
			copycat: ["9L46"],
			crunch: ["9M"],
			dig: ["9M"],
			doublehit: ["9L14"],
			echoedvoice: ["9L5"],
			encore: ["9M", "9L22"],
			endure: ["9M"],
			facade: ["9M"],
			faketears: ["9M"],
			followme: ["9L1"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			helpinghand: ["9M", "9L8"],
			hyperbeam: ["9M"],
			hypervoice: ["9M", "9L33"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			playrough: ["9M", "9L29"],
			populationbomb: ["9L53"],
			pound: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			seedbomb: ["9M"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			superfang: ["9L11"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderwave: ["9M"],
			tidyup: ["9L1"],
			trailblaze: ["9M"],
			uturn: ["9M"],
			waterpulse: ["9M"],
		},
	},
	mausholdfour: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M"],
			babydolleyes: ["9L1"],
			beatup: ["9L41"],
			bulletseed: ["9M", "9L18"],
			charm: ["9M", "9L37"],
			chillingwater: ["9M"],
			copycat: ["9L46"],
			crunch: ["9M"],
			dig: ["9M"],
			doublehit: ["9L14"],
			echoedvoice: ["9L5"],
			encore: ["9M", "9L22"],
			endure: ["9M"],
			facade: ["9M"],
			faketears: ["9M"],
			followme: ["9L1"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			helpinghand: ["9M", "9L8"],
			hyperbeam: ["9M"],
			hypervoice: ["9M", "9L33"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			playrough: ["9M", "9L29"],
			populationbomb: ["9L53"],
			pound: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			seedbomb: ["9M"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			superfang: ["9L11"],
			swift: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderwave: ["9M"],
			tidyup: ["9L1"],
			trailblaze: ["9M"],
			uturn: ["9M"],
			waterpulse: ["9M"],
		},
	},
	cetoddle: {
		learnset: {
			amnesia: ["9M", "9L40"],
			avalanche: ["9M", "9L27"],
			bellydrum: ["9E"],
			blizzard: ["9M", "9L53"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L36"],
			bounce: ["9L31"],
			bulldoze: ["9M"],
			charm: ["9M"],
			chillingwater: ["9M"],
			doubleedge: ["9L49"],
			earthquake: ["9M"],
			echoedvoice: ["9L9"],
			endure: ["9M"],
			entrainment: ["9E"],
			facade: ["9M"],
			flail: ["9L25"],
			growl: ["9L6"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icebeam: ["9M"],
			icefang: ["9M"],
			iceshard: ["9L12"],
			icespinner: ["9M", "9L44"],
			iciclecrash: ["9E"],
			icywind: ["9M"],
			liquidation: ["9M"],
			playrough: ["9M"],
			powdersnow: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M", "9L15"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			superpower: ["9E"],
			tackle: ["9L1"],
			takedown: ["9M", "9L19"],
			terablast: ["9M"],
			waterpulse: ["9M"],
			yawn: ["9E"],
		},
	},
	cetitan: {
		learnset: {
			amnesia: ["9M", "9L40"],
			avalanche: ["9M", "9L27"],
			blizzard: ["9M", "9L53"],
			bodypress: ["9M"],
			bodyslam: ["9M", "9L36"],
			bounce: ["9L31"],
			bulldoze: ["9M"],
			charm: ["9M"],
			chillingwater: ["9M"],
			doubleedge: ["9L49"],
			earthquake: ["9M"],
			echoedvoice: ["9L9"],
			endure: ["9M"],
			facade: ["9M"],
			flail: ["9L25"],
			gigaimpact: ["9M"],
			growl: ["9L6"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icebeam: ["9M"],
			icefang: ["9M"],
			icepunch: ["9M"],
			iceshard: ["9L12"],
			icespinner: ["9M", "9L44"],
			icywind: ["9M"],
			liquidation: ["9M"],
			playrough: ["9M"],
			powdersnow: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M", "9L15"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M", "9L19"],
			terablast: ["9M"],
			waterpulse: ["9M"],
		},
	},
	frigibax: {
		learnset: {
			aquatail: ["9E"],
			avalanche: ["9M"],
			bite: ["9L24"],
			blizzard: ["9M"],
			bodyslam: ["9M"],
			crunch: ["9M", "9L44"],
			dig: ["9M"],
			dracometeor: ["9M"],
			dragonbreath: ["9L12"],
			dragonclaw: ["9M", "9L32"],
			dragonpulse: ["9M"],
			dragonrush: ["9E"],
			dragontail: ["9M", "9L1"],
			endure: ["9M"],
			facade: ["9M"],
			focusenergy: ["9L18"],
			freezedry: ["9E"],
			helpinghand: ["9M"],
			icebeam: ["9M", "9L40"],
			icefang: ["9M", "9L29"],
			iciclecrash: ["9L48"],
			iciclespear: ["9E"],
			icywind: ["9M", "9L6"],
			leer: ["9L1"],
			outrage: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			substitute: ["9M"],
			swordsdance: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M", "9L36"],
			terablast: ["9M"],
		},
	},
	arctibax: {
		learnset: {
			aerialace: ["9M"],
			avalanche: ["9M"],
			bite: ["9L24"],
			blizzard: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			crunch: ["9M", "9L50"],
			dig: ["9M"],
			dracometeor: ["9M"],
			dragonbreath: ["9L12"],
			dragonclaw: ["9M"],
			dragonpulse: ["9M"],
			dragontail: ["9M", "9L1"],
			endure: ["9M"],
			facade: ["9M"],
			focusenergy: ["9L18"],
			helpinghand: ["9M"],
			icebeam: ["9M", "9L45"],
			icefang: ["9M", "9L29"],
			iciclecrash: ["9L55"],
			icywind: ["9M", "9L6"],
			ironhead: ["9M"],
			leer: ["9L1"],
			outrage: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			substitute: ["9M"],
			swordsdance: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M", "9L40"],
			terablast: ["9M"],
		},
	},
	baxcalibur: {
		learnset: {
			aerialace: ["9M"],
			avalanche: ["9M"],
			bite: ["9L24"],
			blizzard: ["9M"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			breakingswipe: ["9L1"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			crunch: ["9M", "9L55"],
			dig: ["9M"],
			dracometeor: ["9M"],
			dragonbreath: ["9L12"],
			dragonclaw: ["9M", "9L35"],
			dragondance: ["9M"],
			dragonpulse: ["9M"],
			dragontail: ["9M", "9L1"],
			earthquake: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			focusenergy: ["9L18"],
			gigaimpact: ["9M"],
			glaiverush: ["9L0"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "9L48"],
			icefang: ["9M", "9L29"],
			iceshard: ["9L1"],
			iciclecrash: ["9L62"],
			icywind: ["9M", "9L6"],
			ironhead: ["9M"],
			leer: ["9L1"],
			outrage: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			snowscape: ["9M", "9L1"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			swordsdance: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M", "9L42"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	tatsugiri: {
		learnset: {
			batonpass: ["9M", "9E"],
			chillingwater: ["9M"],
			counter: ["9E"],
			dracometeor: ["9M"],
			dragondance: ["9M"],
			dragonpulse: ["9M", "9L52"],
			endure: ["9M"],
			facade: ["9M"],
			gigaimpact: ["9M"],
			harden: ["9L6"],
			helpinghand: ["9M", "9L12"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			icywind: ["9M"],
			memento: ["9L34"],
			mirrorcoat: ["9L47"],
			muddywater: ["9L39"],
			nastyplot: ["9M", "9L43"],
			outrage: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rapidspin: ["9E"],
			rest: ["9M"],
			sleeptalk: ["9M"],
			soak: ["9L23"],
			splash: ["9L1"],
			substitute: ["9M"],
			surf: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L28"],
			terablast: ["9M"],
			watergun: ["9L1"],
			waterpulse: ["9M", "9L17"],
		},
	},
	cyclizar: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			agility: ["9M"],
			aquatail: ["9E"],
			bite: ["9L23"],
			bodyslam: ["9M"],
			breakingswipe: ["9L14"],
			crunch: ["9M"],
			doubleedge: ["9L51"],
			dracometeor: ["9M"],
			dragonclaw: ["9M", "9L36"],
			dragonpulse: ["9M", "9L45"],
			dragonrush: ["9L57"],
			dragontail: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			firefang: ["9M"],
			gigaimpact: ["9M"],
			growl: ["9L1"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icespinner: ["9M"],
			ironhead: ["9M"],
			irontail: ["9E"],
			knockoff: ["9E"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			outrage: ["9M"],
			overheat: ["9M"],
			powerwhip: ["9E"],
			protect: ["9M"],
			quickattack: ["9L18"],
			raindance: ["9M"],
			rapidspin: ["9L7"],
			rest: ["9M"],
			shedtail: ["9L31"],
			shiftgear: ["9L40"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M", "9L11"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderbolt: ["9M"],
			thunderfang: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L27"],
			wildcharge: ["9M"],
		},
	},
	pawmi: {
		learnset: {
			agility: ["9M", "9L40"],
			batonpass: ["9M"],
			bite: ["9L19"],
			charge: ["9L8"],
			chargebeam: ["9M"],
			charm: ["9M"],
			crunch: ["9M"],
			dig: ["9M", "9L15"],
			discharge: ["9L38"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M"],
			encore: ["9M"],
			endure: ["9M"],
			entrainment: ["9L31"],
			facade: ["9M"],
			fakeout: ["9E"],
			fling: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			machpunch: ["9E"],
			metalclaw: ["9M"],
			nuzzle: ["9L12"],
			playrough: ["9M"],
			protect: ["9M"],
			quickattack: ["9L6"],
			raindance: ["9M"],
			rest: ["9M"],
			scratch: ["9L1"],
			slam: ["9L35"],
			sleeptalk: ["9M"],
			spark: ["9L23"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			sweetkiss: ["9E"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderfang: ["9M"],
			thundershock: ["9L3"],
			thunderwave: ["9M", "9L27"],
			voltswitch: ["9M"],
			wildcharge: ["9M", "9L44"],
			wish: ["9E"],
		},
	},
	pawmo: {
		learnset: {
			agility: ["9M", "9L46"],
			armthrust: ["9L0"],
			batonpass: ["9M"],
			bite: ["9L19"],
			charge: ["9L8"],
			chargebeam: ["9M"],
			charm: ["9M"],
			crunch: ["9M"],
			dig: ["9M", "9L15"],
			discharge: ["9L42"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M"],
			encore: ["9M"],
			endure: ["9M"],
			entrainment: ["9L38"],
			facade: ["9M"],
			fling: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			metalclaw: ["9M"],
			nuzzle: ["9L12"],
			playrough: ["9M"],
			protect: ["9M"],
			quickattack: ["9L6"],
			raindance: ["9M"],
			rest: ["9M"],
			scratch: ["9L1"],
			slam: ["9L32"],
			sleeptalk: ["9M"],
			spark: ["9L23"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderfang: ["9M"],
			thunderpunch: ["9M"],
			thundershock: ["9L3"],
			thunderwave: ["9M", "9L27"],
			voltswitch: ["9M"],
			wildcharge: ["9M", "9L52"],
		},
	},
	pawmot: {
		learnset: {
			agility: ["9M", "9L54"],
			armthrust: ["9L25"],
			batonpass: ["9M"],
			bite: ["9L19"],
			bodypress: ["9M"],
			brickbreak: ["9M"],
			bulkup: ["9M"],
			charge: ["9L8"],
			chargebeam: ["9M"],
			charm: ["9M"],
			closecombat: ["9M", "9L44"],
			crunch: ["9M"],
			dig: ["9M", "9L15"],
			discharge: ["9L49"],
			doubleshock: ["9L60"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M"],
			encore: ["9M"],
			endure: ["9M"],
			entrainment: ["9L39"],
			facade: ["9M"],
			firepunch: ["9M"],
			fling: ["9M"],
			focusblast: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			icepunch: ["9M"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			metalclaw: ["9M"],
			metronome: ["9M"],
			nuzzle: ["9L12"],
			playrough: ["9M"],
			protect: ["9M"],
			quickattack: ["9L6"],
			raindance: ["9M"],
			rest: ["9M"],
			revivalblessing: ["9L0"],
			rocktomb: ["9M"],
			scratch: ["9L1"],
			seedbomb: ["9M"],
			slam: ["9L33"],
			sleeptalk: ["9M"],
			spark: ["9L23"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderfang: ["9M"],
			thunderpunch: ["9M"],
			thundershock: ["9L3"],
			thunderwave: ["9M", "9L29"],
			voltswitch: ["9M"],
			wildcharge: ["9M", "9L1"],
		},
	},
	wattrel: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			agility: ["9M", "9L32"],
			aircutter: ["9M"],
			airslash: ["9M"],
			bravebird: ["9M"],
			chargebeam: ["9M"],
			discharge: ["9L43"],
			dualwingbeat: ["9L27"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M"],
			endeavor: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			featherdance: ["9E"],
			fly: ["9M"],
			growl: ["9L1"],
			hurricane: ["9M"],
			peck: ["9L1"],
			pluck: ["9L11"],
			protect: ["9M"],
			quickattack: ["9L7"],
			rest: ["9M"],
			roost: ["9L23"],
			sleeptalk: ["9M"],
			spark: ["9L15"],
			spitup: ["9E"],
			stockpile: ["9E"],
			substitute: ["9M"],
			swallow: ["9E"],
			swift: ["9M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thundershock: ["9L4"],
			thunderwave: ["9M"],
			uproar: ["9L19"],
			uturn: ["9M"],
			voltswitch: ["9M", "9L37"],
			weatherball: ["9E"],
			wildcharge: ["9M"],
		},
	},
	kilowattrel: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			agility: ["9M", "9L36"],
			aircutter: ["9M"],
			airslash: ["9M"],
			bravebird: ["9M"],
			chargebeam: ["9M"],
			discharge: ["9L48"],
			dualwingbeat: ["9L30"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M"],
			electroball: ["9M", "9L0"],
			endure: ["9M"],
			facade: ["9M"],
			fly: ["9M"],
			gigaimpact: ["9M"],
			growl: ["9L1"],
			hurricane: ["9M", "9L55"],
			hyperbeam: ["9M"],
			peck: ["9L1"],
			pluck: ["9L11"],
			protect: ["9M"],
			quickattack: ["9L7"],
			rest: ["9M"],
			roost: ["9L24"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			spark: ["9L15"],
			substitute: ["9M"],
			swift: ["9M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thundershock: ["9L4"],
			thunderwave: ["9M"],
			uproar: ["9L19"],
			uturn: ["9M"],
			voltswitch: ["9M", "9L43"],
			wildcharge: ["9M"],
		},
	},
	bombirdier: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M"],
			bravebird: ["9M"],
			darkpulse: ["9M"],
			drillrun: ["9M"],
			dualwingbeat: ["9L42"],
			endure: ["9M"],
			facade: ["9M"],
			featherdance: ["9E"],
			fly: ["9M"],
			foulplay: ["9M"],
			gigaimpact: ["9M"],
			heatwave: ["9M"],
			honeclaws: ["9L1"],
			hurricane: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icywind: ["9M"],
			knockoff: ["9L53"],
			leer: ["9L1"],
			memento: ["9L1"],
			nastyplot: ["9M"],
			partingshot: ["9L60"],
			payback: ["9L36"],
			peck: ["9L1"],
			pluck: ["9L20"],
			powergem: ["9M"],
			powertrip: ["9E"],
			protect: ["9M"],
			raindance: ["9M"],
			rockblast: ["9M"],
			rockslide: ["9M", "9L47"],
			rockthrow: ["9L11"],
			rocktomb: ["9M", "9L29"],
			roost: ["9E"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			skyattack: ["9E"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			stealthrock: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			suckerpunch: ["9E"],
			sunnyday: ["9M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M", "9L7"],
			torment: ["9L24"],
			uturn: ["9M"],
			whirlwind: ["9L16"],
			wingattack: ["9L1"],
		},
	},
	squawkabilly: {
		learnset: {
			aerialace: ["9M", "9L13"],
			aircutter: ["9M"],
			airslash: ["9M"],
			bravebird: ["9M", "9L42"],
			copycat: ["9L27"],
			doubleedge: ["9E"],
			endure: ["9M"],
			facade: ["9M", "9L34"],
			faketears: ["9M"],
			finalgambit: ["9E"],
			flatter: ["9E"],
			fly: ["9M", "9L30"],
			foulplay: ["9M"],
			furyattack: ["9L17"],
			gigaimpact: ["9M"],
			growl: ["9L1"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			hurricane: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			mimic: ["9L1"],
			partingshot: ["9E"],
			peck: ["9L1"],
			pounce: ["9M"],
			protect: ["9M"],
			quickattack: ["9L6"],
			rest: ["9M"],
			reversal: ["9M", "9L52"],
			roost: ["9L47"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swagger: ["9L38"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L20"],
			terablast: ["9M"],
			thief: ["9M"],
			torment: ["9L10"],
			uproar: ["9L24"],
			uturn: ["9M"],
		},
	},
	flamigo: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			agility: ["9M"],
			aircutter: ["9M"],
			airslash: ["9M", "9L35"],
			bravebird: ["9M", "9L54"],
			bulkup: ["9M"],
			chillingwater: ["9M"],
			closecombat: ["9M"],
			copycat: ["9L1"],
			detect: ["9L9"],
			doublekick: ["9L5"],
			doubleteam: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			feint: ["9L21"],
			fling: ["9M"],
			fly: ["9M"],
			focusenergy: ["9L15"],
			gigaimpact: ["9M"],
			hurricane: ["9M"],
			hyperbeam: ["9M"],
			liquidation: ["9M"],
			lowkick: ["9M", "9L18"],
			lowsweep: ["9M"],
			megakick: ["9L39"],
			payback: ["9L27"],
			peck: ["9L1"],
			pounce: ["9M"],
			protect: ["9M"],
			quickguard: ["9E"],
			rest: ["9M"],
			reversal: ["9M"],
			roost: ["9L31"],
			skyattack: ["9E"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			swordsdance: ["9M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			throatchop: ["9L48"],
			uturn: ["9M"],
			waterpulse: ["9M"],
			wideguard: ["9L44"],
			wingattack: ["9L12"],
		},
	},
	klawf: {
		learnset: {
			ancientpower: ["9E"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			crabhammer: ["9E"],
			dig: ["9M"],
			earthpower: ["9M"],
			endeavor: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			flail: ["9L37"],
			fling: ["9M"],
			gigaimpact: ["9M"],
			guillotine: ["9L56"],
			harden: ["9L6"],
			helpinghand: ["9M"],
			highhorsepower: ["9L47"],
			hyperbeam: ["9M"],
			irondefense: ["9M", "9L51"],
			knockoff: ["9E"],
			metalclaw: ["9M", "9L17"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			powergem: ["9M"],
			protect: ["9M", "9L21"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			rockblast: ["9M", "9L24"],
			rockslide: ["9M", "9L42"],
			rocksmash: ["9L9"],
			rockthrow: ["9L1"],
			rocktomb: ["9M", "9L13"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swordsdance: ["9M", "9L33"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			visegrip: ["9L1"],
			xscissor: ["9M", "9L29"],
		},
	},
	nacli: {
		learnset: {
			ancientpower: ["9E"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			curse: ["9E"],
			dig: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L40"],
			endure: ["9M"],
			facade: ["9M"],
			fissure: ["9E"],
			flashcannon: ["9M"],
			harden: ["9L1"],
			headbutt: ["9L16"],
			heavyslam: ["9M", "9L35"],
			helpinghand: ["9M"],
			irondefense: ["9M", "9L20"],
			ironhead: ["9M"],
			mudshot: ["9M", "9L7"],
			powergem: ["9M", "9E"],
			protect: ["9M"],
			raindance: ["9M"],
			recover: ["9L25"],
			rest: ["9M"],
			rockpolish: ["9L13"],
			rockslide: ["9M", "9L30"],
			rockthrow: ["9L5"],
			sandstorm: ["9M"],
			sleeptalk: ["9M"],
			smackdown: ["9L10"],
			stealthrock: ["9M", "9L33"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "9L45"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	naclstack: {
		learnset: {
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M"],
			dig: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L45"],
			endure: ["9M"],
			facade: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M"],
			harden: ["9L1"],
			headbutt: ["9L16"],
			heavyslam: ["9M", "9L41"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			irondefense: ["9M", "9L20"],
			ironhead: ["9M"],
			mudshot: ["9M", "9L7"],
			powergem: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			recover: ["9L30"],
			rest: ["9M"],
			rockpolish: ["9L13"],
			rockslide: ["9M", "9L34"],
			rockthrow: ["9L5"],
			saltcure: ["9L0"],
			sandstorm: ["9M"],
			sleeptalk: ["9M"],
			smackdown: ["9L10"],
			stealthrock: ["9M", "9L38"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "9L51"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	garganacl: {
		learnset: {
			avalanche: ["9M"],
			block: ["9L1"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			dig: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L49"],
			endure: ["9M"],
			explosion: ["9L60"],
			facade: ["9M"],
			firepunch: ["9M"],
			flashcannon: ["9M"],
			fling: ["9M"],
			gigaimpact: ["9M"],
			hammerarm: ["9L0"],
			harden: ["9L1"],
			headbutt: ["9L16"],
			heavyslam: ["9M", "9L44"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			icepunch: ["9M"],
			irondefense: ["9M"],
			ironhead: ["9M"],
			mudshot: ["9M", "9L7"],
			powergem: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			recover: ["9L30"],
			rest: ["9M"],
			rockblast: ["9M", "9L1"],
			rockpolish: ["9L13"],
			rockslide: ["9M", "9L34"],
			rockthrow: ["9L5"],
			rocktomb: ["9M", "9L10"],
			saltcure: ["9L24"],
			sandstorm: ["9M"],
			sleeptalk: ["9M"],
			stealthrock: ["9M", "9L40"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "9L54"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderpunch: ["9M"],
			wideguard: ["9L1"],
			zenheadbutt: ["9M"],
		},
	},
	glimmet: {
		learnset: {
			acidarmor: ["9L41"],
			acidspray: ["9M", "9L7"],
			ancientpower: ["9L11"],
			confuseray: ["9M"],
			dazzlinggleam: ["9M"],
			endure: ["9M"],
			explosion: ["9E"],
			facade: ["9M"],
			gunkshot: ["9M"],
			harden: ["9L1"],
			irondefense: ["9M"],
			lightscreen: ["9M"],
			memento: ["9E"],
			mudshot: ["9M"],
			powergem: ["9M", "9L37"],
			protect: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			rockblast: ["9M"],
			rockpolish: ["9L15"],
			rockslide: ["9M", "9L33"],
			rockthrow: ["9L1"],
			rocktomb: ["9M"],
			sandstorm: ["9M", "9L26"],
			selfdestruct: ["9L29"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			sludgewave: ["9L46"],
			smackdown: ["9L1"],
			spikes: ["9M"],
			stealthrock: ["9M", "9L18"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			terablast: ["9M"],
			toxic: ["9E"],
			toxicspikes: ["9M"],
			venoshock: ["9M", "9L22"],
		},
	},
	glimmora: {
		learnset: {
			acidarmor: ["9L44"],
			acidspray: ["9M", "9L7"],
			ancientpower: ["9L11"],
			confuseray: ["9M"],
			dazzlinggleam: ["9M"],
			earthpower: ["9M"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M"],
			gunkshot: ["9M"],
			harden: ["9L1"],
			hyperbeam: ["9M"],
			irondefense: ["9M"],
			lightscreen: ["9M"],
			mortalspin: ["9L0"],
			mudshot: ["9M"],
			powergem: ["9M", "9L39"],
			protect: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			rockblast: ["9M"],
			rockpolish: ["9L15"],
			rockslide: ["9M", "9L33"],
			rockthrow: ["9L1"],
			rocktomb: ["9M"],
			sandstorm: ["9M", "9L26"],
			selfdestruct: ["9L29"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			sludgewave: ["9L50"],
			smackdown: ["9L1"],
			solarbeam: ["9M"],
			spikes: ["9M"],
			spikyshield: ["9L1"],
			stealthrock: ["9M", "9L18"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			terablast: ["9M"],
			toxicspikes: ["9M", "9L1"],
			venoshock: ["9M", "9L22"],
		},
	},
	shroodle: {
		learnset: {
			acidspray: ["9M", "9L5"],
			acrobatics: ["9M"],
			batonpass: ["9M"],
			bite: ["9L8"],
			copycat: ["9E"],
			crosspoison: ["9E"],
			dig: ["9M"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			flatter: ["9L18"],
			fling: ["9M"],
			foulplay: ["9M"],
			furyswipes: ["9L8"],
			gunkshot: ["9M", "9L45"],
			helpinghand: ["9M"],
			knockoff: ["9L40"],
			leer: ["9L1"],
			metronome: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			nastyplot: ["9M"],
			partingshot: ["9E"],
			poisonfang: ["9L14"],
			poisonjab: ["9M", "9L29"],
			pounce: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scratch: ["9L1"],
			slash: ["9L21"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			substitute: ["9M", "9L36"],
			sunnyday: ["9M"],
			superfang: ["9E"],
			swagger: ["9E"],
			switcheroo: ["9L11"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L33"],
			terablast: ["9M"],
			thief: ["9M"],
			toxic: ["9E"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L25"],
			venoshock: ["9M"],
		},
	},
	grafaiai: {
		learnset: {
			acidspray: ["9M", "9L5"],
			acrobatics: ["9M"],
			batonpass: ["9M"],
			dig: ["9M"],
			doodle: ["9L0"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			flatter: ["9L18"],
			fling: ["9M"],
			foulplay: ["9M"],
			furyswipes: ["9L8"],
			gigaimpact: ["9M"],
			gunkshot: ["9M", "9L51"],
			helpinghand: ["9M"],
			knockoff: ["9L45"],
			leer: ["9L1"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			metronome: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			nastyplot: ["9M"],
			poisonfang: ["9L14"],
			poisonjab: ["9M", "9L33"],
			poisontail: ["9M"],
			pounce: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			scaryface: ["9M"],
			scratch: ["9L1"],
			shadowclaw: ["9M"],
			slash: ["9L21"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			substitute: ["9M", "9L40"],
			sunnyday: ["9M"],
			switcheroo: ["9L11"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L37"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
			uturn: ["9M", "9L25"],
			venoshock: ["9M"],
			xscissor: ["9M"],
		},
	},
	fidough: {
		learnset: {
			agility: ["9M"],
			babydolleyes: ["9L15"],
			batonpass: ["9M", "9L26"],
			bite: ["9L11"],
			bodyslam: ["9M"],
			charm: ["9M", "9L36"],
			copycat: ["9E"],
			covet: ["9L8"],
			crunch: ["9M", "9L40"],
			dazzlinggleam: ["9M"],
			dig: ["9M"],
			doubleedge: ["9L33"],
			endure: ["9M"],
			facade: ["9M"],
			firefang: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			howl: ["9E"],
			icefang: ["9M"],
			lastresort: ["9L45"],
			lick: ["9L3"],
			mistyterrain: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			playrough: ["9M", "9L18"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			roar: ["9L30"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			sweetscent: ["9E"],
			tackle: ["9L1"],
			tailwhip: ["9L6"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			trailblaze: ["9M"],
			wish: ["9E"],
			workup: ["9L22"],
			yawn: ["9E"],
		},
	},
	dachsbun: {
		learnset: {
			agility: ["9M"],
			babydolleyes: ["9L15"],
			batonpass: ["9M", "9L29"],
			bite: ["9L11"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			charm: ["9M", "9L42"],
			covet: ["9L8"],
			crunch: ["9M", "9L47"],
			dazzlinggleam: ["9M"],
			dig: ["9M"],
			doubleedge: ["9L38"],
			drainingkiss: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			firefang: ["9M"],
			gigaimpact: ["9M"],
			growl: ["9L1"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			icefang: ["9M"],
			lastresort: ["9L53"],
			lick: ["9L3"],
			mistyterrain: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			playrough: ["9M", "9L18"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			roar: ["9L33"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tackle: ["9L1"],
			tailwhip: ["9L6"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			trailblaze: ["9M"],
			workup: ["9L22"],
		},
	},
	maschiff: {
		learnset: {
			bite: ["9L14"],
			bodyslam: ["9M"],
			charm: ["9M"],
			crunch: ["9M", "9L31"],
			darkpulse: ["9M"],
			destinybond: ["9E"],
			dig: ["9M"],
			doubleedge: ["9L49"],
			endeavor: ["9E"],
			endure: ["9M"],
			facade: ["9M"],
			faketears: ["9M"],
			firefang: ["9M"],
			headbutt: ["9L22"],
			helpinghand: ["9M"],
			honeclaws: ["9L10"],
			icefang: ["9M"],
			jawlock: ["9L43"],
			leer: ["9L1"],
			lick: ["9L4"],
			payback: ["9L26"],
			playrough: ["9M", "9E"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			retaliate: ["9E"],
			reversal: ["9M", "9L39"],
			roar: ["9L18"],
			scaryface: ["9M", "9L1"],
			sleeptalk: ["9M"],
			snarl: ["9M", "9L7"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swagger: ["9L35"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderfang: ["9M"],
			trailblaze: ["9M"],
		},
	},
	mabosstiff: {
		learnset: {
			bite: ["9L14"],
			bodyslam: ["9M"],
			charm: ["9M"],
			comeuppance: ["9L0"],
			crunch: ["9M", "9L34"],
			darkpulse: ["9M"],
			dig: ["9M"],
			doubleedge: ["9L55"],
			endure: ["9M"],
			facade: ["9M"],
			faketears: ["9M"],
			firefang: ["9M"],
			gigaimpact: ["9M"],
			headbutt: ["9L22"],
			helpinghand: ["9M"],
			honeclaws: ["9L10"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icefang: ["9M"],
			jawlock: ["9L48"],
			leer: ["9L1"],
			lick: ["9L4"],
			outrage: ["9M", "9L60"],
			payback: ["9L26"],
			playrough: ["9M"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M", "9L43"],
			roar: ["9L18"],
			scaryface: ["9M", "9L1"],
			sleeptalk: ["9M"],
			snarl: ["9M", "9L7"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swagger: ["9L39"],
			tackle: ["9L1"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderfang: ["9M"],
			trailblaze: ["9M"],
			wildcharge: ["9M"],
		},
	},
	bramblin: {
		learnset: {
			absorb: ["9L5"],
			astonish: ["9L1"],
			beatup: ["9E"],
			block: ["9E"],
			bulletseed: ["9M", "9L13"],
			confuseray: ["9M"],
			curse: ["9L45"],
			defensecurl: ["9L1"],
			disable: ["9L29"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			gigadrain: ["9M", "9L40"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			hex: ["9M", "9L21"],
			infestation: ["9L17"],
			leafstorm: ["9M"],
			leechseed: ["9E"],
			megadrain: ["9L25"],
			nightshade: ["9M"],
			painsplit: ["9L50"],
			phantomforce: ["9M", "9L35"],
			pounce: ["9M"],
			powerwhip: ["9L55"],
			protect: ["9M"],
			rapidspin: ["9L9"],
			rest: ["9M"],
			rollout: ["9L1"],
			scaryface: ["9M"],
			seedbomb: ["9M"],
			shadowball: ["9M"],
			shadowsneak: ["9E"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			spikes: ["9M"],
			strengthsap: ["9E"],
			substitute: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
		},
	},
	brambleghast: {
		learnset: {
			absorb: ["9L5"],
			astonish: ["9L1"],
			bulletseed: ["9M", "9L13"],
			confuseray: ["9M"],
			curse: ["9L45"],
			defensecurl: ["9L1"],
			disable: ["9L29"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			gigadrain: ["9M", "9L40"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			hex: ["9M", "9L21"],
			hyperbeam: ["9M"],
			infestation: ["9L17"],
			leafstorm: ["9M"],
			megadrain: ["9L25"],
			nightshade: ["9M"],
			painsplit: ["9L50"],
			phantomforce: ["9M", "9L35"],
			pounce: ["9M"],
			powerwhip: ["9L55"],
			protect: ["9M"],
			rapidspin: ["9L9"],
			rest: ["9M"],
			rollout: ["9L1"],
			scaryface: ["9M"],
			seedbomb: ["9M"],
			shadowball: ["9M"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			spikes: ["9M"],
			substitute: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			trailblaze: ["9M"],
		},
	},
	gimmighoul: {
		learnset: {
			astonish: ["9L1"],
			confuseray: ["9M"],
			endure: ["9M"],
			hex: ["9M"],
			lightscreen: ["9M"],
			nastyplot: ["9M"],
			nightshade: ["9M"],
			powergem: ["9M"],
			protect: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			shadowball: ["9M"],
			sleeptalk: ["9M"],
			substitute: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
		},
	},
	gholdengo: {
		learnset: {
			astonish: ["9L1"],
			chargebeam: ["9M"],
			confuseray: ["9M", "9L14"],
			dazzlinggleam: ["9M"],
			electroball: ["9M"],
			endure: ["9M"],
			flashcannon: ["9M"],
			fling: ["9M"],
			focusblast: ["9M"],
			gigaimpact: ["9M"],
			heavyslam: ["9M"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			ironhead: ["9M"],
			lightscreen: ["9M"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			makeitrain: ["9L56"],
			memento: ["9L70"],
			metalsound: ["9L28"],
			nastyplot: ["9M", "9L63"],
			nightshade: ["9M", "9L7"],
			powergem: ["9M", "9L49"],
			protect: ["9M"],
			psychic: ["9M"],
			psyshock: ["9M"],
			recover: ["9L42"],
			reflect: ["9M"],
			rest: ["9M"],
			sandstorm: ["9M"],
			shadowball: ["9M", "9L35"],
			sleeptalk: ["9M"],
			steelbeam: ["9M"],
			substitute: ["9M", "9L21"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderpunch: ["9M"],
			thunderwave: ["9M"],
			trick: ["9M"],
		},
	},
	greattusk: {
		learnset: {
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M", "9L28"],
			bulkup: ["9M"],
			bulldoze: ["9M", "9L7"],
			closecombat: ["9M", "9L63"],
			defensecurl: ["9L1"],
			dig: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L49"],
			endeavor: ["9L70"],
			endure: ["9M"],
			facade: ["9M"],
			firefang: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M", "9L56"],
			headlongrush: ["9L91"],
			headsmash: ["9L84"],
			heavyslam: ["9M"],
			hornattack: ["9L1"],
			hyperbeam: ["9M"],
			icefang: ["9M"],
			icespinner: ["9M"],
			ironhead: ["9M"],
			knockoff: ["9L42"],
			megahorn: ["9L77"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			playrough: ["9M"],
			protect: ["9M"],
			psyshock: ["9M"],
			rapidspin: ["9L21"],
			rest: ["9M"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			rollout: ["9L1"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			smartstrike: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M", "9L35"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L1"],
			takedown: ["9M"],
			taunt: ["9M", "9L14"],
			terablast: ["9M"],
			thunderfang: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	brutebonnet: {
		learnset: {
			absorb: ["9L1"],
			astonish: ["9L1"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulletseed: ["9M"],
			clearsmog: ["9L28"],
			closecombat: ["9M"],
			confuseray: ["9M"],
			crunch: ["9M"],
			darkpulse: ["9M"],
			earthpower: ["9M"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			gigadrain: ["9M", "9L49"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			growth: ["9L1"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			ingrain: ["9L70"],
			leafstorm: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L14"],
			outrage: ["9M"],
			payback: ["9L35"],
			pollenpuff: ["9M"],
			protect: ["9M"],
			ragepowder: ["9L77"],
			rest: ["9M"],
			scaryface: ["9M"],
			seedbomb: ["9M"],
			sleeptalk: ["9M"],
			solarbeam: ["9M", "9L91"],
			spore: ["9L63"],
			stompingtantrum: ["9M"],
			stunspore: ["9L7"],
			substitute: ["9M"],
			suckerpunch: ["9L56"],
			sunnyday: ["9M", "9L1"],
			synthesis: ["9L21"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thrash: ["9L42"],
			trailblaze: ["9M"],
			venoshock: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	sandyshocks: {
		learnset: {
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M", "9L14"],
			chargebeam: ["9M", "9L21"],
			discharge: ["9L56"],
			earthpower: ["9M", "9L63"],
			earthquake: ["9M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "9L1"],
			electroball: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M"],
			gravity: ["9L77"],
			heavyslam: ["9M", "9L42"],
			hyperbeam: ["9M"],
			irondefense: ["9M"],
			lightscreen: ["9M"],
			magneticflux: ["9L91"],
			metalsound: ["9L49"],
			mirrorcoat: ["9L70"],
			mudshot: ["9M"],
			powergem: ["9M"],
			protect: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			sandstorm: ["9M"],
			screech: ["9L35"],
			sleeptalk: ["9M"],
			spark: ["9L7"],
			spikes: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L1"],
			supersonic: ["9L1"],
			swift: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thundershock: ["9L1"],
			thunderwave: ["9M", "9L1"],
			triattack: ["9L28"],
			voltswitch: ["9M"],
			wildcharge: ["9M"],
			zapcannon: ["9L84"],
		},
	},
	screamtail: {
		learnset: {
			amnesia: ["9M"],
			batonpass: ["9M"],
			bite: ["9L21"],
			blizzard: ["9M"],
			bodyslam: ["9M", "9L28"],
			boomburst: ["9L91"],
			bulkup: ["9M"],
			calmmind: ["9M"],
			crunch: ["9M", "9L63"],
			dazzlinggleam: ["9M"],
			dig: ["9M"],
			disable: ["9L1"],
			drainpunch: ["9M"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			faketears: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M"],
			firepunch: ["9M"],
			flamethrower: ["9M"],
			fling: ["9M"],
			focusblast: ["9M"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			gyroball: ["9L77"],
			helpinghand: ["9M"],
			howl: ["9L7"],
			hyperbeam: ["9M"],
			hypervoice: ["9M", "9L49"],
			icebeam: ["9M"],
			icefang: ["9M"],
			icepunch: ["9M"],
			imprison: ["9M"],
			lightscreen: ["9M"],
			metronome: ["9M"],
			mistyterrain: ["9M"],
			nobleroar: ["9L14"],
			perishsong: ["9L84"],
			playrough: ["9M", "9L42"],
			pound: ["9L1"],
			protect: ["9M"],
			psybeam: ["9M"],
			psychic: ["9M"],
			psychicfangs: ["9M", "9L56"],
			psychicterrain: ["9M"],
			psyshock: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M", "9L35"],
			rocktomb: ["9M"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			sing: ["9L1"],
			sleeptalk: ["9M"],
			snowscape: ["9M"],
			stealthrock: ["9M"],
			stompingtantrum: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderfang: ["9M"],
			thunderpunch: ["9M"],
			thunderwave: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			waterpulse: ["9M"],
			wish: ["9L70"],
			zenheadbutt: ["9M"],
		},
	},
	fluttermane: {
		learnset: {
			astonish: ["9L1"],
			calmmind: ["9M"],
			chargebeam: ["9M"],
			charm: ["9M"],
			confuseray: ["9M", "9L1"],
			darkpulse: ["9M"],
			dazzlinggleam: ["9M", "9L35"],
			disarmingvoice: ["9M"],
			drainingkiss: ["9M"],
			endure: ["9M"],
			energyball: ["9M"],
			faketears: ["9M"],
			gigaimpact: ["9M"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			icywind: ["9M"],
			imprison: ["9M"],
			magicalleaf: ["9M"],
			meanlook: ["9L14"],
			memento: ["9L21"],
			mistyterrain: ["9M"],
			moonblast: ["9L84"],
			mysticalfire: ["9L49"],
			nightshade: ["9M"],
			painsplit: ["9L77"],
			perishsong: ["9L91"],
			phantomforce: ["9M", "9L70"],
			powergem: ["9M", "9L56"],
			protect: ["9M"],
			psybeam: ["9M", "9L7"],
			psyshock: ["9M", "9L63"],
			rest: ["9M"],
			shadowball: ["9M", "9L42"],
			sleeptalk: ["9M"],
			spite: ["9L1"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L1"],
			swift: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderwave: ["9M"],
			trickroom: ["9M"],
			wish: ["9L28"],
		},
	},
	slitherwing: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bugbite: ["9L1"],
			bugbuzz: ["9M"],
			bulkup: ["9M", "9L56"],
			closecombat: ["9M"],
			dualwingbeat: ["9L63"],
			earthquake: ["9M"],
			ember: ["9L1"],
			endure: ["9M"],
			facade: ["9M"],
			firstimpression: ["9L70"],
			flamecharge: ["9M", "9L14"],
			flareblitz: ["9M"],
			gigadrain: ["9M"],
			gigaimpact: ["9M"],
			gust: ["9L1"],
			heatwave: ["9M"],
			heavyslam: ["9M"],
			hurricane: ["9M"],
			hyperbeam: ["9M"],
			leechlife: ["9M", "9L84"],
			lowkick: ["9M"],
			lowsweep: ["9M", "9L28"],
			lunge: ["9L42"],
			morningsun: ["9L35"],
			poisonpowder: ["9L7"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			sandstorm: ["9M"],
			sleeptalk: ["9M"],
			stomp: ["9L21"],
			stompingtantrum: ["9M"],
			stunspore: ["9L7"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L1"],
			superpower: ["9L49"],
			takedown: ["9M"],
			terablast: ["9M"],
			thrash: ["9L91"],
			trailblaze: ["9M"],
			uturn: ["9M"],
			whirlwind: ["9L77"],
			wildcharge: ["9M"],
			willowisp: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	roaringmoon: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			airslash: ["9M"],
			bite: ["9L1"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			breakingswipe: ["9L1"],
			brickbreak: ["9M"],
			crunch: ["9M"],
			darkpulse: ["9M"],
			dig: ["9M"],
			doubleedge: ["9L91"],
			dracometeor: ["9M"],
			dragonbreath: ["9L1"],
			dragonclaw: ["9M", "9L28"],
			dragondance: ["9M", "9L56"],
			dragonpulse: ["9M"],
			dragonrush: ["9L63"],
			dragontail: ["9M"],
			earthquake: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M"],
			firespin: ["9M"],
			flamethrower: ["9M", "9L42"],
			fly: ["9M", "9L70"],
			focusenergy: ["9L1"],
			gigaimpact: ["9M"],
			headbutt: ["9L14"],
			heatwave: ["9M"],
			hurricane: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			incinerate: ["9L7"],
			ironhead: ["9M"],
			jawlock: ["9L1"],
			leer: ["9L1"],
			metalclaw: ["9M"],
			nightslash: ["9L49"],
			outrage: ["9M"],
			protect: ["9M"],
			rest: ["9M"],
			rockslide: ["9M"],
			roost: ["9L84"],
			scaleshot: ["9L1"],
			scaryface: ["9M", "9L21"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L1"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			throatchop: ["9L77"],
			thunderfang: ["9M"],
			uturn: ["9M"],
			xscissor: ["9M"],
			zenheadbutt: ["9M", "9L35"],
		},
	},
	irontreads: {
		learnset: {
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M", "9L7"],
			defensecurl: ["9L1"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L49"],
			electricterrain: ["9M", "9L1"],
			electroball: ["9M"],
			endeavor: ["9L70"],
			facade: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M", "9L84"],
			heavyslam: ["9M", "9L56"],
			hornattack: ["9L1"],
			hyperbeam: ["9M"],
			icefang: ["9M"],
			icespinner: ["9M"],
			irondefense: ["9M"],
			ironhead: ["9M", "9L28"],
			knockoff: ["9L42"],
			megahorn: ["9L77"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			protect: ["9M"],
			rapidspin: ["9L21"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			rollout: ["9L1"],
			sandstorm: ["9M"],
			scaryface: ["9M"],
			smartstrike: ["9M"],
			stealthrock: ["9M"],
			steelbeam: ["9M"],
			steelroller: ["9L91"],
			stompingtantrum: ["9M", "9L35"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderfang: ["9M"],
			voltswitch: ["9M"],
			wildcharge: ["9M", "9L63"],
			zenheadbutt: ["9M"],
		},
	},
	ironmoth: {
		learnset: {
			acidspray: ["9M", "9L1"],
			acrobatics: ["9M"],
			agility: ["9M"],
			airslash: ["9M"],
			bugbuzz: ["9M", "9L84"],
			chargebeam: ["9M"],
			confuseray: ["9M"],
			dazzlinggleam: ["9M"],
			discharge: ["9L42"],
			electricterrain: ["9M", "9L1"],
			ember: ["9L1"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			fierydance: ["9L56"],
			fireblast: ["9M"],
			firespin: ["9M", "9L14"],
			flamecharge: ["9M"],
			flamethrower: ["9M"],
			flareblitz: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M"],
			gust: ["9L1"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			hurricane: ["9M", "9L77"],
			hyperbeam: ["9M"],
			lightscreen: ["9M"],
			lunge: ["9L28"],
			metalsound: ["9L63"],
			morningsun: ["9L70"],
			overheat: ["9M", "9L91"],
			pounce: ["9M"],
			protect: ["9M"],
			psychic: ["9M"],
			rest: ["9M"],
			screech: ["9L35"],
			sleeptalk: ["9M"],
			sludgewave: ["9L49"],
			solarbeam: ["9M"],
			strugglebug: ["9M", "9L7"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swift: ["9M"],
			takedown: ["9M", "9L21"],
			terablast: ["9M"],
			toxicspikes: ["9M"],
			uturn: ["9M"],
			venoshock: ["9M"],
			whirlwind: ["9L1"],
		},
	},
	ironhands: {
		learnset: {
			armthrust: ["9L1"],
			bellydrum: ["9L84"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			charge: ["9L49"],
			closecombat: ["9M", "9L63"],
			detect: ["9L70"],
			drainpunch: ["9M"],
			earthquake: ["9M"],
			electricterrain: ["9M", "9L1"],
			endure: ["9M"],
			facade: ["9M"],
			fakeout: ["9L7"],
			firepunch: ["9M"],
			fling: ["9M"],
			focusblast: ["9M"],
			focusenergy: ["9L1"],
			focuspunch: ["9L91"],
			forcepalm: ["9L35"],
			gigaimpact: ["9M"],
			heavyslam: ["9M", "9L77"],
			hyperbeam: ["9M"],
			icepunch: ["9M"],
			irondefense: ["9M"],
			ironhead: ["9M"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			metronome: ["9M"],
			playrough: ["9M"],
			protect: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			rockslide: ["9M"],
			rocktomb: ["9M"],
			sandattack: ["9L1"],
			scaryface: ["9M"],
			seismictoss: ["9L42"],
			slam: ["9L28"],
			sleeptalk: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			swordsdance: ["9M"],
			tackle: ["9L1"],
			takedown: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderpunch: ["9M", "9L21"],
			voltswitch: ["9M"],
			whirlwind: ["9L14"],
			wildcharge: ["9M", "9L56"],
		},
	},
	ironjugulis: {
		learnset: {
			acrobatics: ["9M"],
			aircutter: ["9M", "9L1"],
			airslash: ["9M", "9L56"],
			assurance: ["9L14"],
			bodyslam: ["9M"],
			chargebeam: ["9M"],
			crunch: ["9M", "9L35"],
			darkpulse: ["9M", "9L70"],
			dragonbreath: ["9L21"],
			dragonpulse: ["9M", "9L84"],
			dragontail: ["9M"],
			earthpower: ["9M"],
			electricterrain: ["9M", "9L1"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M"],
			flamethrower: ["9M"],
			flashcannon: ["9M"],
			fly: ["9M"],
			focusblast: ["9M"],
			focusenergy: ["9L1"],
			gigaimpact: ["9M"],
			heatwave: ["9M"],
			hurricane: ["9M"],
			hydropump: ["9M"],
			hyperbeam: ["9M", "9L91"],
			hypervoice: ["9M", "9L42"],
			ironhead: ["9M"],
			knockoff: ["9L63"],
			outrage: ["9M", "9L77"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			roar: ["9L7"],
			rocktomb: ["9M"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M", "9L28"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			tailwind: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			triattack: ["9L1"],
			uturn: ["9M"],
			workup: ["9L1"],
			zenheadbutt: ["9M"],
		},
	},
	ironthorns: {
		learnset: {
			bite: ["9L28"],
			blizzard: ["9M"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			brickbreak: ["9M"],
			bulldoze: ["9M"],
			charge: ["9L35"],
			chargebeam: ["9M"],
			crunch: ["9M"],
			dig: ["9M"],
			dragonclaw: ["9M"],
			dragondance: ["9M"],
			dragontail: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L70"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "9L1"],
			electroball: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M", "9L1"],
			firepunch: ["9M"],
			flamethrower: ["9M"],
			fling: ["9M"],
			focusblast: ["9M"],
			gigaimpact: ["9M", "9L91"],
			heavyslam: ["9M"],
			hyperbeam: ["9M"],
			icebeam: ["9M"],
			icefang: ["9M", "9L1"],
			icepunch: ["9M"],
			irondefense: ["9M", "9L1"],
			ironhead: ["9M"],
			lowkick: ["9M"],
			metalclaw: ["9M"],
			pinmissile: ["9L63"],
			powergem: ["9M"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			rockblast: ["9M"],
			rockslide: ["9M", "9L42"],
			rockthrow: ["9L1"],
			rocktomb: ["9M", "9L21"],
			sandstorm: ["9M", "9L49"],
			scaryface: ["9M"],
			screech: ["9L7"],
			snarl: ["9M"],
			spikes: ["9M"],
			stealthrock: ["9M", "9L77"],
			stompingtantrum: ["9M"],
			stoneedge: ["9M", "9L84"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thunder: ["9M"],
			thunderbolt: ["9M"],
			thunderfang: ["9M", "9L1"],
			thunderpunch: ["9M"],
			thunderwave: ["9M"],
			voltswitch: ["9M"],
			wildcharge: ["9M", "9L56"],
		},
	},
	ironbundle: {
		learnset: {
			acrobatics: ["9M"],
			agility: ["9M", "9L63"],
			aircutter: ["9M"],
			auroraveil: ["9L84"],
			avalanche: ["9M"],
			blizzard: ["9M", "9L91"],
			bodyslam: ["9M"],
			chillingwater: ["9M"],
			drillpeck: ["9L28"],
			electricterrain: ["9M", "9L1"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			fling: ["9M"],
			flipturn: ["9L49"],
			freezedry: ["9L42"],
			gigaimpact: ["9M"],
			helpinghand: ["9M", "9L35"],
			hydropump: ["9M", "9L77"],
			hyperbeam: ["9M"],
			icebeam: ["9M", "9L56"],
			icepunch: ["9M"],
			icespinner: ["9M"],
			icywind: ["9M"],
			playrough: ["9M"],
			powdersnow: ["9L7"],
			present: ["9L1"],
			protect: ["9M"],
			raindance: ["9M"],
			rest: ["9M"],
			sleeptalk: ["9M"],
			snowscape: ["9M", "9L70"],
			substitute: ["9M"],
			swift: ["9M"],
			takedown: ["9M", "9L21"],
			taunt: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			uturn: ["9M"],
			waterpulse: ["9M"],
			whirlpool: ["9L14"],
		},
	},
	ironvaliant: {
		learnset: {
			aerialace: ["9M"],
			agility: ["9M"],
			aurasphere: ["9M"],
			brickbreak: ["9M"],
			calmmind: ["9M"],
			chargebeam: ["9M"],
			closecombat: ["9M", "9L63"],
			confuseray: ["9M"],
			dazzlinggleam: ["9M", "9L28"],
			destinybond: ["9L77"],
			disable: ["9L1"],
			doubleteam: ["9L1"],
			drainpunch: ["9M"],
			electricterrain: ["9M", "9L1"],
			encore: ["9M"],
			endure: ["9M"],
			energyball: ["9M"],
			falseswipe: ["9M"],
			feint: ["9L14"],
			firepunch: ["9M"],
			fling: ["9M"],
			focusblast: ["9M"],
			furycutter: ["9L1"],
			futuresight: ["9L21"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			helpinghand: ["9M"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			hypervoice: ["9M"],
			hypnosis: ["9L7"],
			icepunch: ["9M"],
			icywind: ["9M"],
			imprison: ["9M"],
			knockoff: ["9L70"],
			leafblade: ["9L49"],
			lightscreen: ["9M"],
			liquidation: ["9M"],
			lowkick: ["9M"],
			magicalleaf: ["9M"],
			metronome: ["9M"],
			mistyterrain: ["9M"],
			moonblast: ["9L56"],
			nightslash: ["9L42"],
			poisonjab: ["9M"],
			protect: ["9M"],
			psybeam: ["9M"],
			psychic: ["9M"],
			psychicterrain: ["9M"],
			psychocut: ["9L35"],
			psyshock: ["9M"],
			quickguard: ["9L84"],
			reflect: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			shadowball: ["9M"],
			shadowclaw: ["9M"],
			shadowsneak: ["9L1"],
			skillswap: ["9M"],
			sleeptalk: ["9M"],
			spiritbreak: ["9L91"],
			storedpower: ["9M"],
			substitute: ["9M"],
			swift: ["9M"],
			swordsdance: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			thunderbolt: ["9M"],
			thunderpunch: ["9M"],
			thunderwave: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			wideguard: ["9L84"],
			xscissor: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	tinglu: {
		learnset: {
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulldoze: ["9M", "9L20"],
			darkpulse: ["9M", "9L40"],
			dig: ["9M"],
			earthpower: ["9M"],
			earthquake: ["9M", "9L70"],
			endure: ["9M"],
			facade: ["9M"],
			fissure: ["9L75"],
			gigaimpact: ["9M"],
			heavyslam: ["9M"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			meanlook: ["9L1"],
			memento: ["9L65"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			payback: ["9L10"],
			protect: ["9M"],
			rest: ["9M"],
			rockslide: ["9M", "9L60"],
			rocktomb: ["9M"],
			ruination: ["9L50"],
			sandstorm: ["9M"],
			sandtomb: ["9L1"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			spikes: ["9M", "9L5"],
			spite: ["9L1"],
			stealthrock: ["9M"],
			stomp: ["9L15"],
			stompingtantrum: ["9M", "9L45"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			takedown: ["9M"],
			taunt: ["9M", "9L30"],
			terablast: ["9M"],
			thrash: ["9L35"],
			throatchop: ["9L55"],
			whirlwind: ["9L25"],
			zenheadbutt: ["9M"],
		},
	},
	chienpao: {
		learnset: {
			acrobatics: ["9M"],
			aerialace: ["9M"],
			avalanche: ["9M"],
			blizzard: ["9M"],
			brickbreak: ["9M"],
			crunch: ["9M"],
			darkpulse: ["9M", "9L40"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			gigaimpact: ["9M"],
			haze: ["9L15"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			icefang: ["9M"],
			iceshard: ["9L20"],
			icespinner: ["9M"],
			iciclecrash: ["9L45"],
			icywind: ["9M", "9L5"],
			meanlook: ["9L1"],
			mist: ["9L15"],
			nightslash: ["9L35"],
			payback: ["9L10"],
			powdersnow: ["9L1"],
			protect: ["9M"],
			psychicfangs: ["9M"],
			raindance: ["9M"],
			recover: ["9L65"],
			rest: ["9M"],
			ruination: ["9L50"],
			sacredsword: ["9L60"],
			scaryface: ["9M"],
			sheercold: ["9L75"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			snowscape: ["9M", "9L30"],
			spite: ["9L1"],
			substitute: ["9M"],
			suckerpunch: ["9L55"],
			swordsdance: ["9M", "9L25"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			throatchop: ["9L70"],
		},
	},
	wochien: {
		learnset: {
			absorb: ["9L1"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			bulletseed: ["9M"],
			darkpulse: ["9M", "9L40"],
			endure: ["9M"],
			energyball: ["9M"],
			facade: ["9M"],
			foulplay: ["9M", "9L55"],
			gigadrain: ["9M", "9L45"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M", "9L65"],
			growth: ["9L30"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			ingrain: ["9L35"],
			knockoff: ["9L70"],
			leafstorm: ["9M", "9L75"],
			leechseed: ["9L25"],
			lightscreen: ["9M"],
			magicalleaf: ["9M"],
			meanlook: ["9L1"],
			megadrain: ["9L20"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			payback: ["9L10"],
			poisonpowder: ["9L15"],
			pollenpuff: ["9M"],
			powerwhip: ["9L60"],
			protect: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			ruination: ["9L50"],
			scaryface: ["9M"],
			seedbomb: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			solarbeam: ["9M"],
			spite: ["9L1"],
			stunspore: ["9L15"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			tickle: ["9L5"],
			trailblaze: ["9M"],
			zenheadbutt: ["9M"],
		},
	},
	chiyu: {
		learnset: {
			bounce: ["9L55"],
			confuseray: ["9M", "9L30"],
			crunch: ["9M"],
			darkpulse: ["9M", "9L40"],
			ember: ["9L1"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			firespin: ["9M"],
			flamecharge: ["9M", "9L20"],
			flamethrower: ["9M"],
			flamewheel: ["9L5"],
			flareblitz: ["9M"],
			gigaimpact: ["9M"],
			heatwave: ["9M"],
			hex: ["9M"],
			hyperbeam: ["9M"],
			incinerate: ["9L25"],
			inferno: ["9L65"],
			lavaplume: ["9L45"],
			lightscreen: ["9M"],
			meanlook: ["9L1"],
			memento: ["9L70"],
			nastyplot: ["9M", "9L35"],
			overheat: ["9M", "9L75"],
			payback: ["9L10"],
			protect: ["9M"],
			psychic: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			ruination: ["9L50"],
			scaryface: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			spite: ["9L1"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swagger: ["9L60"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			willowisp: ["9M", "9L15"],
			zenheadbutt: ["9M"],
		},
	},
	koraidon: {
		learnset: {
			acrobatics: ["9M"],
			agility: ["9M", "9L35"],
			ancientpower: ["9L14"],
			bodypress: ["9M"],
			bodyslam: ["9M"],
			breakingswipe: ["9L1"],
			brickbreak: ["9M", "9L28"],
			bulkup: ["9M", "9S1"],
			bulldoze: ["9M"],
			closecombat: ["9M", "9L84"],
			collisioncourse: ["9L56", "9S0", "9S1"],
			counter: ["9L70"],
			crunch: ["9M"],
			dig: ["9M"],
			dracometeor: ["9M"],
			dragonclaw: ["9M", "9L42"],
			dragonpulse: ["9M"],
			dragontail: ["9M"],
			drainpunch: ["9M", "9L21"],
			endure: ["9M", "9S0"],
			facade: ["9M"],
			fireblast: ["9M"],
			firefang: ["9M"],
			firespin: ["9M"],
			flamecharge: ["9M"],
			flamethrower: ["9M", "9L49", "9S0", "9S1"],
			flareblitz: ["9M", "9L91"],
			focusblast: ["9M"],
			gigaimpact: ["9M", "9L98", "9S1"],
			heatwave: ["9M"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hyperbeam: ["9M"],
			icefang: ["9M"],
			ironhead: ["9M"],
			lowkick: ["9M"],
			lowsweep: ["9M"],
			mudshot: ["9M"],
			mudslap: ["9M"],
			outrage: ["9M", "9L77"],
			overheat: ["9M"],
			protect: ["9M"],
			rest: ["9M"],
			reversal: ["9M"],
			rocksmash: ["9L7"],
			scaryface: ["9M"],
			screech: ["9L63"],
			shadowclaw: ["9M"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			solarbeam: ["9M"],
			stompingtantrum: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M", "9L1"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M", "9S0"],
			thunderfang: ["9M"],
			uturn: ["9M"],
			wildcharge: ["9M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 9, level: 68, nature: "Quirky", ivs: {hp: 31, atk: 31, def: 28, spa: 31, spd: 28, spe: 31}, moves: ["flamethrower", "collisioncourse", "endure", "terablast"], pokeball: "pokeball"},
			{generation: 9, level: 72, nature: "Adamant", ivs: {hp: 25, atk: 31, def: 25, spa: 31, spd: 25, spe: 31}, moves: ["gigaimpact", "bulkup", "collisioncourse", "flamethrower"]},
		],
		eventOnly: true,
	},
	miraidon: {
		learnset: {
			acrobatics: ["9M"],
			agility: ["9M", "9L35"],
			bodyslam: ["9M"],
			calmmind: ["9M"],
			charge: ["9L14", "9S1"],
			chargebeam: ["9M"],
			confuseray: ["9M"],
			crunch: ["9M"],
			dazzlinggleam: ["9M"],
			discharge: ["9L28"],
			dracometeor: ["9M"],
			dragonbreath: ["9L1"],
			dragonclaw: ["9M"],
			dragonpulse: ["9M", "9L42"],
			dragontail: ["9M"],
			eerieimpulse: ["9M"],
			electricterrain: ["9M", "9L1"],
			electroball: ["9M"],
			electrodrift: ["9L56", "9S0", "9S1"],
			endure: ["9M", "9S0"],
			facade: ["9M"],
			flashcannon: ["9M"],
			gigaimpact: ["9M"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			hyperbeam: ["9M", "9L98", "9S1"],
			lightscreen: ["9M"],
			metalsound: ["9L63"],
			mirrorcoat: ["9L70"],
			outrage: ["9M", "9L77"],
			overheat: ["9M", "9L91"],
			paraboliccharge: ["9L21"],
			powergem: ["9M", "9S0", "9S1"],
			protect: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			scaryface: ["9M"],
			shockwave: ["9L7"],
			sleeptalk: ["9M"],
			snarl: ["9M"],
			solarbeam: ["9M"],
			substitute: ["9M"],
			swordsdance: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M", "9S0"],
			thunder: ["9M", "9L84"],
			thunderbolt: ["9M"],
			thundershock: ["9L1"],
			thunderwave: ["9M"],
			uturn: ["9M"],
			voltswitch: ["9M"],
			wildcharge: ["9M"],
			zenheadbutt: ["9M"],
		},
		eventData: [
			{generation: 9, level: 68, nature: "Quirky", ivs: {hp: 31, atk: 31, def: 28, spa: 31, spd: 28, spe: 31}, moves: ["powergem", "electrodrift", "endure", "terablast"], pokeball: "pokeball"},
			{generation: 9, level: 72, nature: "Modest", ivs: {hp: 25, atk: 31, def: 25, spa: 31, spd: 25, spe: 31}, moves: ["hyperbeam", "charge", "electrodrift", "powergem"]},
		],
		eventOnly: true,
	},
	tinkatink: {
		learnset: {
			astonish: ["9L1"],
			babydolleyes: ["9L5"],
			brutalswing: ["9L24"],
			covet: ["9L11"],
			drainingkiss: ["9M", "9L17"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			fairywind: ["9L1"],
			fakeout: ["9L39"],
			faketears: ["9M"],
			feint: ["9E"],
			flashcannon: ["9M", "9L31"],
			flatter: ["9L43"],
			fling: ["9M"],
			foulplay: ["9M"],
			helpinghand: ["9M"],
			icehammer: ["9E"],
			knockoff: ["9L52"],
			lightscreen: ["9M"],
			metalclaw: ["9M", "9L8"],
			metronome: ["9M"],
			playrough: ["9M", "9L35"],
			pounce: ["9M"],
			protect: ["9M"],
			quash: ["9E"],
			reflect: ["9M"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocksmash: ["9L14"],
			rocktomb: ["9M"],
			skillswap: ["9M"],
			skittersmack: ["9L47"],
			slam: ["9L27"],
			sleeptalk: ["9M"],
			stealthrock: ["9M"],
			steelbeam: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sweetkiss: ["9L21"],
			swordsdance: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderwave: ["9M"],
		},
	},
	tinkatuff: {
		learnset: {
			astonish: ["9L1"],
			babydolleyes: ["9L5"],
			brickbreak: ["9M"],
			brutalswing: ["9L24"],
			covet: ["9L11"],
			drainingkiss: ["9M", "9L17"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			fairywind: ["9L1"],
			fakeout: ["9L39"],
			faketears: ["9M"],
			flashcannon: ["9M", "9L31"],
			flatter: ["9L43"],
			fling: ["9M"],
			foulplay: ["9M"],
			helpinghand: ["9M"],
			knockoff: ["9L52"],
			lightscreen: ["9M"],
			metalclaw: ["9M", "9L8"],
			metronome: ["9M"],
			playrough: ["9M", "9L35"],
			pounce: ["9M"],
			protect: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocksmash: ["9L14"],
			rocktomb: ["9M"],
			skillswap: ["9M"],
			skittersmack: ["9L47"],
			slam: ["9L27"],
			sleeptalk: ["9M"],
			stealthrock: ["9M"],
			steelbeam: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sweetkiss: ["9L21"],
			swordsdance: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderwave: ["9M"],
		},
	},
	tinkaton: {
		learnset: {
			astonish: ["9L1"],
			babydolleyes: ["9L5"],
			brickbreak: ["9M"],
			brutalswing: ["9L24"],
			bulldoze: ["9M"],
			covet: ["9L11"],
			drainingkiss: ["9M", "9L17"],
			encore: ["9M"],
			endure: ["9M"],
			facade: ["9M"],
			fairywind: ["9L1"],
			fakeout: ["9L39"],
			faketears: ["9M"],
			flashcannon: ["9M", "9L31"],
			flatter: ["9L43"],
			fling: ["9M"],
			foulplay: ["9M"],
			gigatonhammer: ["9L0"],
			heavyslam: ["9M"],
			helpinghand: ["9M"],
			knockoff: ["9L52"],
			lightscreen: ["9M"],
			metalclaw: ["9M", "9L8"],
			metronome: ["9M"],
			playrough: ["9M", "9L35"],
			pounce: ["9M"],
			protect: ["9M"],
			reflect: ["9M"],
			rest: ["9M"],
			rockslide: ["9M"],
			rocksmash: ["9L14"],
			rocktomb: ["9M"],
			skillswap: ["9M"],
			skittersmack: ["9L47"],
			slam: ["9L27"],
			sleeptalk: ["9M"],
			stealthrock: ["9M"],
			steelbeam: ["9M"],
			stoneedge: ["9M"],
			substitute: ["9M"],
			sweetkiss: ["9L21"],
			swordsdance: ["9M"],
			terablast: ["9M"],
			thief: ["9M"],
			thunderwave: ["9M"],
		},
	},
	charcadet: {
		learnset: {
			astonish: ["9L1"],
			clearsmog: ["9L8"],
			confuseray: ["9M"],
			destinybond: ["9E"],
			disable: ["9E"],
			ember: ["9L1"],
			endure: ["9M"],
			facade: ["9M"],
			fireblast: ["9M"],
			firespin: ["9M", "9L12"],
			flamecharge: ["9M", "9L24"],
			flamethrower: ["9M"],
			flareblitz: ["9M"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			incinerate: ["9L28"],
			lavaplume: ["9L32"],
			leer: ["9L1"],
			nightshade: ["9M", "9L20"],
			overheat: ["9M"],
			protect: ["9M"],
			sleeptalk: ["9M"],
			spite: ["9E"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			takedown: ["9M"],
			terablast: ["9M"],
			willowisp: ["9M", "9L16"],
		},
	},
	armarouge: {
		learnset: {
			acidspray: ["9M"],
			allyswitch: ["9L42"],
			armorcannon: ["9L62"],
			astonish: ["9L1"],
			aurasphere: ["9M"],
			calmmind: ["9M", "9L37"],
			clearsmog: ["9L8"],
			confuseray: ["9M"],
			darkpulse: ["9M"],
			dragonpulse: ["9M"],
			ember: ["9L1"],
			endure: ["9M"],
			energyball: ["9M"],
			expandingforce: ["9L56"],
			facade: ["9M"],
			fireblast: ["9M"],
			firespin: ["9M", "9L12"],
			flamecharge: ["9M", "9L24"],
			flamethrower: ["9M", "9L48"],
			flareblitz: ["9M"],
			flashcannon: ["9M"],
			fling: ["9M"],
			focusblast: ["9M"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			incinerate: ["9L28"],
			irondefense: ["9M"],
			lavaplume: ["9L32"],
			leer: ["9L1"],
			lightscreen: ["9M"],
			mysticalfire: ["9L1"],
			nightshade: ["9M", "9L20"],
			overheat: ["9M"],
			protect: ["9M"],
			psybeam: ["9M"],
			psychic: ["9M"],
			psychicterrain: ["9M"],
			psyshock: ["9M", "9L0"],
			reflect: ["9M"],
			rest: ["9M"],
			shadowball: ["9M"],
			sleeptalk: ["9M"],
			solarbeam: ["9M"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			trick: ["9M"],
			trickroom: ["9M"],
			wideguard: ["9L1"],
			willowisp: ["9M", "9L16"],
		},
	},
	ceruledge: {
		learnset: {
			allyswitch: ["9L42"],
			astonish: ["9L1"],
			bitterblade: ["9L48"],
			brickbreak: ["9M"],
			bulkup: ["9M"],
			clearsmog: ["9L8"],
			closecombat: ["9M"],
			confuseray: ["9M"],
			dragonclaw: ["9M"],
			ember: ["9L1"],
			endure: ["9M"],
			facade: ["9M"],
			falseswipe: ["9M"],
			fireblast: ["9M"],
			firespin: ["9M", "9L12"],
			flamecharge: ["9M", "9L24"],
			flamethrower: ["9M"],
			flareblitz: ["9M", "9L62"],
			fling: ["9M"],
			heatwave: ["9M"],
			helpinghand: ["9M"],
			hex: ["9M"],
			incinerate: ["9L28"],
			irondefense: ["9M"],
			ironhead: ["9M"],
			lavaplume: ["9L32"],
			leer: ["9L1"],
			lightscreen: ["9M"],
			nightshade: ["9M", "9L20"],
			nightslash: ["9L1"],
			overheat: ["9M"],
			phantomforce: ["9M"],
			poisonjab: ["9M"],
			protect: ["9M"],
			psychocut: ["9L56"],
			quickguard: ["9L1"],
			reflect: ["9M"],
			rest: ["9M"],
			shadowball: ["9M"],
			shadowclaw: ["9M", "9L0"],
			shadowsneak: ["9L1"],
			sleeptalk: ["9M"],
			solarblade: ["9L1"],
			storedpower: ["9M"],
			substitute: ["9M"],
			sunnyday: ["9M"],
			swordsdance: ["9M", "9L37"],
			takedown: ["9M"],
			taunt: ["9M"],
			terablast: ["9M"],
			willowisp: ["9M", "9L16"],
			xscissor: ["9M"],
		},
	},
	toedscool: {
		learnset: {
			absorb: ["9L4"],
			acidspray: ["9M"],
			acupressure: ["9E"],
			bulletseed: ["9M"],
			confuseray: ["9M"],
			dazzlinggleam: ["9M"],
			earthpower: ["9M", "9L48"],
			endure: ["9M"],
			energyball: ["9M"],
			flashcannon: ["9M"],
			foulplay: ["9M"],
			gigadrain: ["9M", "9L44"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			growth: ["9L40"],
			hex: ["9M", "9L28"],
			knockoff: ["9E"],
			leafstorm: ["9M"],
			leechseed: ["9E"],
			lightscreen: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L16"],
			mirrorcoat: ["9E"],
			mudshot: ["9M", "9L24"],
			mudslap: ["9M", "9L1"],
			poisonpowder: ["9L8"],
			powerwhip: ["9L52"],
			protect: ["9M"],
			ragepowder: ["9E"],
			raindance: ["9M"],
			rapidspin: ["9E"],
			reflect: ["9M"],
			rest: ["9M"],
			scaryface: ["9M"],
			screech: ["9L20"],
			seedbomb: ["9M", "9L32"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			solarbeam: ["9M"],
			spikes: ["9M"],
			spore: ["9L36"],
			stunspore: ["9L8"],
			substitute: ["9M"],
			supersonic: ["9L12"],
			swift: ["9M"],
			tackle: ["9L15"],
			taunt: ["9M"],
			terablast: ["9M"],
			tickle: ["9E"],
			toxic: ["9E"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			trickroom: ["9M"],
			venoshock: ["9M"],
			wrap: ["9L1"],
		},
	},
	toedscruel: {
		learnset: {
			absorb: ["9L4"],
			acidspray: ["9M"],
			bulletseed: ["9M"],
			confuseray: ["9M"],
			dazzlinggleam: ["9M"],
			earthpower: ["9M", "9L54"],
			endure: ["9M"],
			energyball: ["9M"],
			flashcannon: ["9M"],
			foulplay: ["9M"],
			gigadrain: ["9M", "9L48"],
			gigaimpact: ["9M"],
			grassknot: ["9M"],
			grassyterrain: ["9M"],
			growth: ["9L44"],
			hex: ["9M", "9L28"],
			hyperbeam: ["9M"],
			leafstorm: ["9M"],
			lightscreen: ["9M"],
			magicalleaf: ["9M"],
			megadrain: ["9L16"],
			mudshot: ["9M", "9L24"],
			mudslap: ["9M", "9L1"],
			poisonpowder: ["9L8"],
			powerwhip: ["9L58"],
			protect: ["9M"],
			raindance: ["9M"],
			reflect: ["9M"],
			reflecttype: ["9L1"],
			rest: ["9M"],
			scaryface: ["9M"],
			screech: ["9L20"],
			seedbomb: ["9M", "9L34"],
			sleeptalk: ["9M"],
			sludgebomb: ["9M"],
			solarbeam: ["9M"],
			spikes: ["9M"],
			spore: ["9L40"],
			stunspore: ["9L8"],
			substitute: ["9M"],
			supersonic: ["9L12"],
			swift: ["9M"],
			tackle: ["9L15"],
			taunt: ["9M"],
			terablast: ["9M"],
			toxicspikes: ["9M"],
			trailblaze: ["9M"],
			trickroom: ["9M"],
			venoshock: ["9M"],
			wrap: ["9L1"],
		},
	},
	tomohawk: {
		learnset: {
			acrobatics: ["8M", "7M", "5M"],
			aerialace: ["8L12", "7M", "7L17", "5M", "5L17"],
			airslash: ["8M", "8L31", "7L33", "5L37"],
			aquatail: ["7T"],
			attract: ["8M", "7M", "5M"],
			aurasphere: ["8M", "8L1", "7L1", "5L1"],
			batonpass: ["8M"],
			bodyslam: ["8M"],
			bravebird: ["8M"],
			brickbreak: ["8M", "5M"],
			bulkup: ["8M", "8L1", "7M", "5M"],
			bulldoze: ["8M", "7M", "5M"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M"],
			confuseray: ["7M"],
			doubleteam: ["7M", "5M"],
			dualwingbeat: ["8T"],
			earthquake: ["8M", "7M", "5M"],
			echoedvoice: ["7M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "5M"],
			falseswipe: ["8M", "7M", "5M"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "5M"],
			fly: ["8M", "7M", "5M"],
			focusblast: ["8M", "7M", "5M"],
			focusenergy: ["8M", "8L1"],
			frustration: ["5M"],
			furyswipes: ["8L9", "7L1", "5L1"],
			gigaimpact: ["8M", "7M", "5M"],
			grassknot: ["8M", "7M", "5M"],
			harden: ["8L1", "7L1", "5L1"],
			healingwish: ["8L46", "7L60", "5L60"],
			heatwave: ["8M", "7L42", "5L45"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M", "5M"],
			hurricane: ["8M", "8L56", "7L51", "5L55"],
			hyperbeam: ["8M", "7M", "5M"],
			hypervoice: ["8M", "8L41", "7L45", "5L49"],
			incinerate: ["6M", "5M"],
			irontail: ["8M", "7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			morningsun: ["8L15", "7L20", "5L20"],
			poweruppunch: ["8L1", "6L99"],
			protect: ["8M", "7M", "5M"],
			quash: ["8E", "7M", "5M"],
			raindance: ["8M", "8L21", "7M", "7L29", "5M", "5L29"],
			rest: ["8M", "8L1", "7M", "7L53", "5M", "5L53"],
			retaliate: ["8M", "8L1", "6M", "5M"],
			return: ["7M", "5M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["8L1", "7M", "5M"],
			rockslide: ["8M", "7M", "5M"],
			rocksmash: ["8L1", "6M", "5M"],
			rocktomb: ["8M", "7M", "5M"],
			roost: ["8L26", "7M"],
			round: ["8M", "7M", "5M"],
			safeguard: ["8M", "7M", "5M"],
			scratch: ["8L1", "7L4"],
			secretpower: ["7M"],
			skyattack: ["8L61", "7L55"],
			skydrop: ["7M", "7L49", "5M", "5L50"],
			sleeptalk: ["8M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M", "5M"],
			stealthrock: ["8M"],
			steelwing: ["8M", "7M"],
			stoneedge: ["8M"],
			strength: ["6M", "5M"],
			submission: ["8L36", "7L37", "5L42"],
			substitute: ["8M", "7M", "5M"],
			sunnyday: ["8M", "8L15", "7M", "7L1", "5M", "5L1"],
			superpower: ["8M", "8L51", "7L50", "5L51"],
			swagger: ["7M", "5M"],
			taunt: ["8M", "8L1", "7M", "5M"],
			thief: ["8M", "7M", "5M"],
			throatchop: ["8M"],
			toxic: ["7M", "5M"],
			whirlwind: ["8L18", "7L23", "5L23"],
			workup: ["8M", "8L1", "7M", "5M"],
		},
	},
	necturna: {
		learnset: {
			attract: ["8M", "7M", "5M"],
			calmmind: ["8M", "7M", "5M"],
			confide: ["7M"],
			crunch: ["8M"],
			cut: ["7M", "5M"],
			darkpulse: ["8M", "7M"],
			doubleteam: ["7M", "5M"],
			dreameater: ["7M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "5M"],
			facade: ["8M", "7M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "5M"],
			futuresight: ["8M"],
			gigadrain: ["8M", "6T"],
			gigaimpact: ["8M", "7M", "5M"],
			grassknot: ["8M", "7M", "5M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L34", "7L34"],
			gravity: ["6T"],
			hex: ["8M", "8L16", "7L28", "5L25"],
			hiddenpower: ["7M", "5M"],
			hornleech: ["8L1", "7L1", "5L31"],
			hyperbeam: ["8M", "7M", "5M"],
			leafblade: ["8M"],
			leafstorm: ["8M"],
			leechlife: ["8M", "7M"],
			leechseed: ["8L1", "7L12"],
			leer: ["8L1", "7L1", "5L1"],
			magicalleaf: ["8M", "8L12", "7L17"],
			naturepower: ["7M"],
			nightshade: ["8L28", "7L39"],
			ominouswind: ["5L7"],
			painsplit: ["8L46", "7L45", "6T", "5L40"],
			payback: ["8M", "7M", "5M"],
			poisonfang: ["8L1", "7L1", "5L1"],
			powerwhip: ["8M", "8L52", "7L56", "5L60"],
			protect: ["8M", "7M", "5M"],
			psychic: ["8M", "7M", "5M"],
			psychup: ["7M", "5M"],
			rest: ["8M", "7M", "5M"],
			return: ["7M", "5M"],
			round: ["8M", "7M", "5M"],
			secretpower: ["7M"],
			seedbomb: ["8M", "8L24", "7T"],
			shadowball: ["8M", "8L40", "7M", "7L50", "5M", "5L50"],
			shadowclaw: ["8M", "7M", "5M"],
			shadowsneak: ["8L1", "7L6", "5L13"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M", "5M"],
			solarblade: ["8M"],
			spite: ["7T"],
			stoneedge: ["8M", "7M", "5M"],
			substitute: ["8M", "7M", "5M"],
			sunnyday: ["8M", "7M", "5M"],
			superfang: ["8L1", "7L1", "6T", "5L1"],
			swagger: ["7M", "5M"],
			telekinesis: ["6M", "5M"],
			thief: ["8M", "7M", "5M"],
			thunderfang: ["8M", "8L1", "7L1", "5L1"],
			torment: ["7M", "5M"],
			toxic: ["7M", "5M"],
			toxicspikes: ["8M", "8L20", "7L23", "5L19"],
			vinewhip: ["8L1", "7L1", "5L1"],
			willowisp: ["8M", "8L20", "7M", "7L23", "5M", "5L19"],
			worryseed: ["7T"],
		},
	},
	mollux: {
		learnset: {
			acid: ["8L4", "7L4", "5L4"],
			acidarmor: ["8L32", "7L33", "5L28"],
			acidspray: ["8L12", "7L12", "5L12"],
			aquaring: ["8E", "7E", "5E"],
			attract: ["8M", "7M", "5M"],
			bide: ["7L1", "5L1"],
			bind: ["7T"],
			calmmind: ["8M", "7M", "5M"],
			charm: ["8M", "7E", "5E"],
			clearsmog: ["8L24", "7L25", "5L20"],
			confide: ["7M"],
			confuseray: ["8L8", "7L17", "5L17"],
			corrosivegas: ["8T"],
			doubleteam: ["7M", "5M"],
			drainingkiss: ["8M", "8L16", "7L20", "7E"],
			ember: ["8L1", "7L1", "5L1"],
			endure: ["8M"],
			eruption: ["8L56", "7L57", "5L52"],
			explosion: ["7M", "5M"],
			facade: ["8M", "7M", "5M"],
			finalgambit: ["8L60", "7L60", "5L57"],
			fireblast: ["8M", "7M", "5M"],
			firespin: ["8M", "7E", "5E"],
			flamecharge: ["7M", "5M"],
			flamethrower: ["8M", "7M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "5M"],
			gastroacid: ["8L52", "7M", "6T", "5E"],
			gigaimpact: ["8M", "7M", "5M"],
			gunkshot: ["8M", "7L52", "6T", "5L49"],
			healbell: ["7E", "6T", "5E"],
			healpulse: ["8E", "7E", "5E"],
			heatwave: ["8M", "7L36", "6T", "5L33"],
			helpinghand: ["8M", "7E", "6T", "5E"],
			hiddenpower: ["7M", "5M"],
			hyperbeam: ["8M", "7M", "5M"],
			inferno: ["8L44", "7L49", "5L44"],
			lavaplume: ["8L28", "7L28", "5L25"],
			leechlife: ["8M", "7M"],
			lifedew: ["8L20"],
			lightscreen: ["8M", "7M", "5M"],
			moonlight: ["8L36", "7L33", "5L28"],
			overheat: ["8M", "7M", "5M"],
			protect: ["8M", "7M", "5M"],
			raindance: ["8M", "7M", "5M"],
			rapidspin: ["8E", "7E", "5E"],
			recover: ["8L48", "7L41", "5L36"],
			rest: ["8M", "7M", "5M"],
			return: ["7M", "5M"],
			round: ["8M", "7M", "5M"],
			secretpower: ["7M"],
			selfdestruct: ["8M"],
			shockwave: ["7T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "7E", "5E"],
			sludgebomb: ["8M", "7M", "5M"],
			sludgewave: ["8M", "7M", "5M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M", "5M"],
			spotlight: ["7L65"],
			stealthrock: ["8M", "7E", "6T", "5E"],
			substitute: ["8M", "7M", "5M"],
			sunnyday: ["8M", "7M", "5M"],
			swagger: ["7M", "5M"],
			swift: ["8M"],
			thief: ["8M", "7M", "5M"],
			thunder: ["8M", "7M", "5M"],
			thunderbolt: ["8M", "7M", "5M"],
			thunderwave: ["8M", "7M", "5M"],
			toxic: ["8L64", "7M", "5M"],
			toxicspikes: ["8M", "8L40", "7L44", "5L41"],
			trick: ["8M", "7E", "6T", "5E"],
			venomdrench: ["8M", "7E"],
			venoshock: ["8M", "7M", "5M"],
			willowisp: ["8M", "7M", "5M"],
			withdraw: ["8L1", "7L9", "5L9"],
		},
	},
	aurumoth: {
		learnset: {
			allyswitch: ["8M", "5M"],
			ancientpower: ["8L21", "7L47", "5L47"],
			attract: ["8M", "7M", "5M"],
			blizzard: ["8M", "7M", "5M"],
			bugbite: ["8L15", "7T", "5T"],
			bugbuzz: ["8M"],
			closecombat: ["8M"],
			confide: ["7M"],
			cut: ["6M", "5M"],
			doubleteam: ["8L9", "7M", "5M"],
			dragondance: ["8M", "8L1", "7L1", "5L1"],
			dreameater: ["7M", "5M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M", "5M"],
			electroweb: ["8M", "7T", "5T"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "5M"],
			finalgambit: ["8L61", "5L41"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "5M"],
			focusblast: ["8M", "7M", "5M"],
			frustration: ["7M", "5M"],
			gigaimpact: ["8M", "7M", "5M"],
			hail: ["8M", "7M", "5M"],
			healingwish: ["8L54", "7L61", "5L61"],
			healpulse: ["8L18", "7L21", "5L21"],
			helpinghand: ["8M", "7T", "5T"],
			hiddenpower: ["7M", "5M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M", "5M"],
			icebeam: ["8M", "7M", "5M"],
			icywind: ["8M", "7T", "5T"],
			imprison: ["8M", "8L12"],
			infestation: ["7M"],
			lightscreen: ["8M", "8L37", "7M", "5M"],
			magiccoat: ["7T", "5T"],
			magicroom: ["8M", "7T", "5T"],
			megahorn: ["8M"],
			nastyplot: ["8M", "8L42"],
			ominouswind: ["7L27", "5L27"],
			overheat: ["8M", "7M", "5M"],
			protect: ["8M", "7M", "5M"],
			psychic: ["8M", "8L47", "7M", "5M"],
			psychicterrain: ["8M", "8L1", "7L1"],
			psychup: ["7M", "5M"],
			psyshock: ["8M", "7M", "5M"],
			raindance: ["8M", "7M", "5M"],
			recycle: ["7T", "5T"],
			reflect: ["8M", "8L37", "7M", "5M"],
			rest: ["8M", "7M", "5M"],
			retaliate: ["8M", "5M"],
			return: ["7M", "5M"],
			roleplay: ["7T", "5T"],
			round: ["8M", "7M", "5M"],
			safeguard: ["8M", "7M", "5M"],
			secretpower: ["7M"],
			shadowball: ["8M", "7M", "5M"],
			shockwave: ["7M"],
			signalbeam: ["7T"],
			silverwind: ["7L1", "5L1"],
			skillswap: ["8M", "7T", "5T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M", "5M"],
			spotlight: ["7L1"],
			steelwing: ["8M", "7M"],
			stringshot: ["8L1", "7L1", "5L1"],
			strugglebug: ["8L1", "5M"],
			substitute: ["8M", "7M", "5M"],
			sunnyday: ["8M", "8L1", "7M", "7L1", "5M", "5L1"],
			surf: ["8M", "7M", "5M"],
			swagger: ["7M", "5M"],
			tackle: ["8L1", "7L1", "5L1"],
			tailglow: ["7L67", "5L67"],
			telekinesis: ["5M"],
			terrainpulse: ["8T"],
			thunder: ["8M", "7M", "5M"],
			thunderbolt: ["8M", "7M", "5M"],
			toxic: ["7M", "5M"],
			trick: ["8M", "7T", "5T"],
			waterpulse: ["7T"],
			willowisp: ["8M", "8L24", "7M", "7L34", "5M", "5L34"],
			wish: ["8L33", "7L54", "5L54"],
			wonderroom: ["8M", "7T", "5T"],
			xscissor: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "8L27", "7T", "5T"],
		},
	},
	malaconda: {
		learnset: {
			attract: ["8M", "7M", "5M"],
			beatup: ["8M"],
			bind: ["7T", "5T"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			confide: ["7M"],
			crunch: ["8M", "8L38", "7L43", "5L42"],
			cut: ["6M", "5M"],
			darkpulse: ["8M", "7M", "5T"],
			doubleteam: ["7M", "5M"],
			dragontail: ["7M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "5M"],
			facade: ["8M", "7M", "5M"],
			followme: ["8L1", "7L1"],
			foulplay: ["8M", "8L50", "7T", "5T"],
			frustration: ["7M", "5M"],
			gigadrain: ["8M", "7T", "5T"],
			gigaimpact: ["8M", "7M", "5M"],
			glare: ["8L32", "7L38"],
			grassknot: ["8M", "8L16", "7M", "7L20", "5M", "5L18"],
			grassyglide: ["8T"],
			healbell: ["7T", "5T"],
			hiddenpower: ["7M", "5M"],
			hyperbeam: ["8M", "7M", "5M"],
			irontail: ["8M", "7T", "5T"],
			knockoff: ["7T", "5T"],
			lashout: ["8T"],
			leafblade: ["8M", "8L28", "7L34", "5L36"],
			leer: ["8L1", "7L1"],
			nastyplot: ["8M"],
			naturepower: ["7M"],
			partingshot: ["8L56"],
			payback: ["8M", "8L1", "7M", "5M"],
			powerwhip: ["8M", "7L48", "5L57"],
			protect: ["8M", "7M", "5M"],
			punishment: ["7L62", "5L66"],
			pursuit: ["7L10", "5L1"],
			rapidspin: ["8L1", "7L1", "5L1"],
			recycle: ["7T"],
			rest: ["8M", "7M", "5M"],
			retaliate: ["8M", "7M", "5M"],
			return: ["7M", "5M"],
			roar: ["7M", "5M"],
			round: ["8M", "7M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L12", "7L15"],
			screech: ["8M"],
			secretpower: ["7M"],
			seedbomb: ["8M", "7T", "5T"],
			skittersmack: ["8T"],
			slam: ["8L24", "7L29", "5L30"],
			sleeptalk: ["8M", "7M", "5T"],
			snarl: ["8M", "7M", "5M"],
			snore: ["8M", "7T", "5T"],
			solarbeam: ["8M", "7M", "5M"],
			solarblade: ["8M", "8L62", "7L66"],
			spikyshield: ["8L44", "7L52"],
			spite: ["7T", "5T"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "5M"],
			suckerpunch: ["8L20", "7L24", "5L25"],
			sunnyday: ["8M", "7M", "5M"],
			swagger: ["7M", "5M"],
			synthesis: ["7T", "5T"],
			taunt: ["8M", "7M", "5M"],
			thief: ["8M", "7M", "5M"],
			throatchop: ["8M"],
			thunderfang: ["8M"],
			toxic: ["7M", "5M"],
			uturn: ["8M", "7M", "5M"],
			vinewhip: ["8L1", "7L6", "5L1"],
			weatherball: ["8M"],
			wildcharge: ["8M", "7M", "5M"],
			worryseed: ["7T", "5T"],
			wrap: ["8L1", "7L1", "5L1"],
			wringout: ["7L57", "5L48"],
		},
	},
	cawmodore: {
		learnset: {
			acrobatics: ["8M", "7M", "5M"],
			aerialace: ["7M", "5M"],
			agility: ["8M"],
			airslash: ["8M"],
			assurance: ["8M"],
			attract: ["8M", "7M", "5M"],
			beatup: ["8M"],
			belch: ["8L54", "7L52"],
			bellydrum: ["8L1", "7L1", "5L35"],
			block: ["7T", "5T"],
			brickbreak: ["8M", "7M", "5M"],
			brine: ["8M", "8L28", "7L44", "5L44"],
			bulletpunch: ["8L1", "7L39", "5L39"],
			confide: ["7M"],
			detect: ["8L32", "7L26", "5L26"],
			doubleteam: ["7M", "5M"],
			drainpunch: ["8M", "7T", "5T"],
			dualwingbeat: ["8T"],
			endeavor: ["7T", "5T"],
			endure: ["8M"],
			facade: ["8M", "7M", "5M"],
			flashcannon: ["8M", "8L48", "7M", "7L58", "5M", "5L52"],
			fly: ["8M", "7M", "5M"],
			frustration: ["7M", "5M"],
			gigaimpact: ["8M", "7M", "5M"],
			growl: ["8L1", "7L5", "5L5"],
			hiddenpower: ["7M", "5M"],
			hurricane: ["8M", "8L60", "7L64", "5L58"],
			hyperbeam: ["8M", "7M", "5M"],
			irondefense: ["8M"],
			ironhead: ["8M", "7T", "5T"],
			knockoff: ["7T", "5T"],
			leer: ["8L1", "7L1", "5L1"],
			megapunch: ["8M"],
			metalclaw: ["8L12", "7L13", "5L13"],
			metronome: ["8M"],
			peck: ["8L1", "7L1", "5L1"],
			pluck: ["7M", "5M"],
			protect: ["8M", "7M", "5M"],
			psychup: ["7M", "5M"],
			raindance: ["8M", "7M", "5M"],
			rest: ["8M", "7M", "5M"],
			retaliate: ["8M", "7M", "5M"],
			return: ["7M", "5M"],
			revenge: ["8M"],
			rocksmash: ["6M", "5M"],
			round: ["8M", "7M", "5M"],
			screech: ["8M", "8L16", "7L18", "5L18"],
			secretpower: ["7M"],
			shockwave: ["7T"],
			skyattack: ["8L66", "7T", "5T"],
			skydrop: ["7M", "5M"],
			sleeptalk: ["8M", "7M", "5T"],
			smackdown: ["7M", "5M"],
			snatch: ["7T", "5T"],
			snore: ["8M", "7T", "5T"],
			steelbeam: ["8T"],
			steelwing: ["8M", "8L36", "7M", "7L31", "5L31"],
			strength: ["6M", "5M"],
			substitute: ["8M", "7M", "5M"],
			surf: ["8M", "7M", "5M"],
			swagger: ["7M", "5M"],
			swift: ["8M", "8L20", "7L9", "5L9"],
			tailwind: ["8L42", "5T", "5L48"],
			throatchop: ["8M"],
			toxic: ["7M", "5M"],
			waterpulse: ["7T"],
			whirlpool: ["8M"],
			wingattack: ["8L24", "7L22", "5L22"],
		},
	},
	volkraken: {
		learnset: {
			absorb: ["8L1", "7L1"],
			aquaring: ["8L32", "7L46", "6L46"],
			assurance: ["8M"],
			attract: ["8M", "7M", "6M"],
			bind: ["8L1", "7T"],
			bite: ["8L24", "7L1", "6L1"],
			bounce: ["8M", "7T"],
			burningjealousy: ["8T"],
			confide: ["7M", "6M"],
			constrict: ["7L1", "6L1"],
			covet: ["7T"],
			destinybond: ["8L56", "7L32", "6L32"],
			dive: ["8M", "8L28", "6L37"],
			doubleteam: ["7M", "6M"],
			facade: ["8M", "7M", "6M"],
			falseswipe: ["8M", "7M", "6M"],
			fireblast: ["8M", "7M", "6M"],
			firelash: ["8L38", "7L35"],
			firespin: ["8M", "8L8", "7L18", "6L18"],
			flameburst: ["7L23", "6L23"],
			flamethrower: ["8M", "7M", "6M"],
			flash: ["7L1", "6M", "6L1"],
			flashcannon: ["8M", "7M", "6M"],
			fling: ["8M", "7M", "6M"],
			flipturn: ["8T"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			heatwave: ["8M", "7L51", "6T", "6L51"],
			hiddenpower: ["7M", "6M"],
			hydropump: ["8M", "8L62", "7L56", "6L56"],
			hyperbeam: ["8M", "7M", "6M"],
			incinerate: ["8L20", "7M"],
			infestation: ["7M"],
			leechlife: ["8M", "7M", "6L1"],
			liquidation: ["8M"],
			memento: ["8L68", "7L66", "6L66"],
			muddywater: ["8M"],
			overheat: ["8M", "7M", "6M"],
			payback: ["8M", "7M", "6M"],
			powergem: ["8M", "8L44", "7L42", "6L42"],
			protect: ["8M", "7M", "6M"],
			quash: ["7M", "6M"],
			raindance: ["8M", "7M", "6M"],
			reflect: ["8M", "8L12", "7M", "7L1", "6M", "6L1"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			round: ["8M", "7M", "6M"],
			scald: ["8M", "8L50", "7M", "7L28", "6M", "6L28"],
			scaryface: ["8M"],
			scorchingsands: ["8T"],
			secretpower: ["7M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "7T"],
			substitute: ["7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			thief: ["8M", "7M", "6M"],
			torment: ["7M", "6M"],
			toxic: ["7M", "6M"],
			uturn: ["8M", "7M", "6M"],
			waterfall: ["8M", "7M", "6M"],
			watergun: ["8L4", "7L1", "6L1"],
			waterpulse: ["6T"],
			whirlpool: ["8M", "8L16", "7L14", "6L14"],
			willowisp: ["8M", "7M", "6M"],
			wringout: ["7L60"],
		},
	},
	plasmanta: {
		learnset: {
			acid: ["8L1", "7L12", "6L12"],
			aquatail: ["8L52", "7L33", "6T", "6L33"],
			attract: ["8M", "7M", "6M"],
			aurasphere: ["8M"],
			block: ["7T"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			clearsmog: ["8L28", "7L22", "6L22"],
			confide: ["7M", "6M"],
			corrosivegas: ["8T"],
			crosspoison: ["8M", "7L51", "6L51"],
			cut: ["6M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			discharge: ["8L40", "7L47", "6L47"],
			doubleteam: ["7M", "6M"],
			eerieimpulse: ["8M", "7L65", "6L65"],
			electricterrain: ["8M", "8L1", "7L1"],
			electrify: ["8L46"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			encore: ["8M", "8L12", "7L16", "6L16"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			flashcannon: ["8M", "7M", "6M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			iondeluge: ["7L26", "6L26"],
			irontail: ["8M", "7T"],
			liquidation: ["8M"],
			magnetrise: ["7T"],
			paraboliccharge: ["8L24", "7L38", "6L38"],
			poisonjab: ["8M", "7M"],
			poisonsting: ["8L1", "7L1", "6L1"],
			poisontail: ["8L20", "7L29", "6L29"],
			protect: ["8M", "7M", "6M"],
			psybeam: ["8L34"],
			psychic: ["8M", "7M", "6M"],
			psyshock: ["8M", "7M", "6M"],
			psywave: ["7L42", "6L42"],
			raindance: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M", "6M"],
			signalbeam: ["6T"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			snore: ["8M", "7T"],
			substitute: ["8M", "7M", "6M"],
			supersonic: ["8L1", "7L1", "6L1"],
			swagger: ["8L58", "7M", "7L56", "6M", "6L56"],
			taunt: ["8M", "7M", "6M"],
			thunder: ["8M", "8L64", "7M", "7L60", "6M", "6L60"],
			thunderbolt: ["8M", "7M", "6M"],
			thundershock: ["8L1", "7L1", "6L1"],
			thunderwave: ["8M", "8L16", "7M", "7L19", "6M", "6L19"],
			toxic: ["8E", "7M", "6M"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M"],
			waterpulse: ["6T"],
			wildcharge: ["8M", "7M", "6M"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	naviathan: {
		learnset: {
			attract: ["8M", "7M", "6M"],
			blizzard: ["8M", "7M", "6M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			breakingswipe: ["8M"],
			brine: ["8M", "8L26", "7L45", "6L45"],
			brutalswing: ["8M", "7M"],
			bubblebeam: ["8L1", "7L25", "6L25"],
			calmmind: ["8M", "7M", "6M"],
			confide: ["7M", "6M"],
			crunch: ["8M"],
			dive: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			dragonbreath: ["8L18", "7L1", "6L1"],
			dragonclaw: ["8M", "7M", "6M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "6T"],
			drillpeck: ["8L36", "7L31", "6L31"],
			echoedvoice: ["7M", "6M"],
			endure: ["8M"],
			facade: ["8M", "7M", "6M"],
			flashcannon: ["8M", "7M", "7L35", "6M", "6L35"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			gust: ["8L1", "7L1", "6L1"],
			hail: ["8M", "7M", "6M"],
			heavyslam: ["8M", "8L66", "7L1"],
			hiddenpower: ["7M", "6M"],
			hurricane: ["8M", "7L64", "6L64"],
			hydropump: ["8M", "8L58", "7L60", "6L60"],
			hyperbeam: ["8M", "7M", "6M"],
			icebeam: ["8M", "7M", "6M"],
			icefang: ["8M"],
			icepunch: ["8M", "6T"],
			iciclecrash: ["8L50", "7L51", "6L51"],
			iciclespear: ["8M"],
			icywind: ["8M", "6T"],
			irondefense: ["8M", "6T"],
			ironhead: ["8M", "8L43", "7L65", "6T", "6L65"],
			irontail: ["8M", "6T"],
			metalclaw: ["8L9", "7L11", "6L11"],
			metalsound: ["8L1", "7L21", "6L21"],
			metronome: ["8M"],
			muddywater: ["8M"],
			peck: ["8L1", "7L1", "6L1"],
			protect: ["8M", "8L1", "7M", "6M"],
			psychicfangs: ["8M"],
			raindance: ["8M", "7M", "6M"],
			rest: ["8M", "7M", "6M"],
			retaliate: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rocksmash: ["7M", "6M"],
			round: ["8M", "7M", "6M"],
			scald: ["8M", "7M", "6M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L15", "7L1", "6L1"],
			screech: ["8M"],
			secretpower: ["7M"],
			selfdestruct: ["8M", "7L74", "6L74"],
			slackoff: ["8L31", "7L39", "6L39"],
			sleeptalk: ["8M", "7M", "6M"],
			snore: ["8M", "6T"],
			splash: ["8L1", "7L1", "6L1"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			strength: ["6M"],
			substitute: ["8M", "7M", "6M"],
			surf: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			taunt: ["8M", "7M", "6M"],
			thunderfang: ["8M"],
			thunderpunch: ["8M", "6T"],
			toxic: ["7M", "6M"],
			waterfall: ["8M", "7M", "6M"],
			watergun: ["8L1", "7L7", "6L7"],
			waterpulse: ["8L12", "6T"],
			whirlpool: ["8M"],
			wideguard: ["8L1", "7L54", "6L54"],
			wildcharge: ["8M", "7M", "6M"],
		},
	},
	crucibelle: {
		learnset: {
			acidarmor: ["8L40", "7L32", "6L32"],
			assurance: ["8M"],
			astonish: ["8L1", "7L1", "6L1"],
			attract: ["8M", "7M", "6M"],
			block: ["8E", "6T"],
			coil: ["8L1", "7E", "6E"],
			confide: ["7M"],
			confuseray: ["8L8", "7L16", "6L16"],
			confusion: ["8L16", "7L14", "6L14"],
			crosspoison: ["8M"],
			defensecurl: ["8E", "7E", "6E"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["8M"],
			embargo: ["7M", "6M"],
			endure: ["8M", "8L1", "7L1", "6L1"],
			explosion: ["8L64", "7M", "7L60", "6M", "6L60"],
			facade: ["8M", "7M", "6M"],
			faketears: ["8M", "8L28", "7L10", "6L10"],
			frustration: ["7M", "6M"],
			gigaimpact: ["8M", "7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			gravity: ["7T", "6T"],
			gunkshot: ["8M", "8L60", "7L56", "6T", "6L56"],
			helpinghand: ["8M", "6T"],
			hex: ["8M", "8L48", "7L44", "6L44"],
			hiddenpower: ["7M", "6M"],
			hyperbeam: ["8M", "7M", "6M"],
			infestation: ["7M", "6M"],
			irondefense: ["8M"],
			ironhead: ["8M", "6T"],
			lightscreen: ["8M"],
			magicroom: ["8M", "6T"],
			meteorbeam: ["8T"],
			metronome: ["8M"],
			payback: ["8M", "7M", "6M"],
			pinmissile: ["8M"],
			poisonjab: ["8M", "7M", "6M"],
			powergem: ["8M"],
			protect: ["8M", "8L1", "7M", "7L1", "6M", "6L1"],
			psybeam: ["8L32", "7L40", "6L40"],
			psychic: ["8M", "7M", "6M"],
			reflect: ["8M", "7M", "7L25", "6M", "6L25"],
			rest: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			rockblast: ["8M", "8L12", "7L52", "6L52"],
			rockpolish: ["7M", "6M"],
			rockslide: ["8M", "8L52", "7M", "7L36", "6M", "6L36"],
			rocksmash: ["7M", "6M"],
			rockthrow: ["8L4", "7L5", "6L5"],
			rocktomb: ["8M", "7M", "6M"],
			rollout: ["8E", "7E", "6E"],
			round: ["8M", "7M", "6M"],
			safeguard: ["8M", "7M", "6M"],
			sandstorm: ["8M", "7M", "6M"],
			secretpower: ["7M"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "7M", "6M"],
			skillswap: ["8M", "6T"],
			sleeptalk: ["8M", "7M", "6M"],
			sludge: ["8L44", "7L28", "6L28"],
			sludgebomb: ["8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			smackdown: ["8L24", "7M", "7L23", "6M", "6L23"],
			snatch: ["6T"],
			snore: ["8M", "6T"],
			stealthrock: ["8M", "6T"],
			steelroller: ["8T"],
			stoneedge: ["8M", "7M", "6M"],
			substitute: ["8M", "7M", "6M"],
			swagger: ["7M", "6M"],
			torment: ["8L20", "7M", "7L48", "6M", "6L48"],
			toxic: ["8L56", "7M", "7L7", "6M", "6L7"],
			toxicspikes: ["8M", "8L36", "7L19", "6L19"],
			trick: ["8M", "6T"],
			uturn: ["8M", "7M", "6M"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M", "6M"],
			withdraw: ["8L1", "7L1", "6L1"],
			wonderroom: ["8M", "6T"],
			woodhammer: ["8E", "7E", "6E"],
			zenheadbutt: ["8M", "6T"],
		},
	},
	kerfluffle: {
		learnset: {
			allyswitch: ["8M"],
			attract: ["8M", "7M", "6M"],
			aurasphere: ["8M", "8L34", "7L45", "6L45"],
			beatup: ["8M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "6M"],
			bulkup: ["8M", "7M", "6M"],
			celebrate: ["6S0"],
			charm: ["8M", "8L1"],
			closecombat: ["8M", "8L54", "7L53", "6L53"],
			coaching: ["8T"],
			confide: ["7L25", "6L25"],
			crushclaw: ["8L1", "7M", "6M"],
			dazzlinggleam: ["8M", "7M", "6M"],
			doubleteam: ["7M", "6M"],
			drainingkiss: ["8M", "8L12", "7L17", "6L17"],
			drainpunch: ["8M", "6T"],
			dreameater: ["8L49", "7M", "7L57", "6M", "6L57"],
			encore: ["8M", "8L1"],
			endure: ["8M", "8L15", "7L21", "6L21"],
			energyball: ["8M", "7M", "6M"],
			facade: ["8M", "7M", "6M"],
			fairywind: ["8L1", "7L1", "6L1"],
			featherdance: ["8L1", "7L1", "6L1"],
			flashcannon: ["8M", "7M", "6M"],
			fly: ["8M", "6S0"],
			focusblast: ["8M", "7M", "6M"],
			focusenergy: ["8M"],
			frustration: ["7M", "6M"],
			gigadrain: ["8M", "6T"],
			gigaimpact: ["8M", "7M", "6M"],
			grassknot: ["8M", "7M", "6M"],
			helpinghand: ["8M", "6T"],
			hiddenpower: ["7M", "6M"],
			holdhands: ["6S0"],
			hyperbeam: ["8M", "7M", "6M"],
			lowkick: ["8M", "6T"],
			lowsweep: ["8M"],
			magicroom: ["8M"],
			megakick: ["8M"],
			megapunch: ["8M"],
			metronome: ["8M", "6S0"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M"],
			moonblast: ["8L39", "7L49", "6L49"],
			nightmare: ["7L57", "6L57"],
			partingshot: ["8L21", "7L33", "6L33"],
			playrough: ["8M", "8L24", "7L37", "6L37"],
			poweruppunch: ["8L9", "7M", "6M"],
			protect: ["8M", "7M", "6M"],
			psychup: ["7M", "6M"],
			rest: ["8M", "8L29", "7M", "7L41", "6M", "6L41"],
			retaliate: ["8M", "7M", "6M"],
			return: ["7M", "6M"],
			revenge: ["8M"],
			reversal: ["8M"],
			round: ["8M", "7M", "6M"],
			scaryface: ["8M", "8L18", "7L29", "6L29"],
			scratch: ["8L1", "7L1", "6L1"],
			secretpower: ["7M"],
			sleeptalk: ["8M", "7M", "6M"],
			sludgebomb: ["8M", "7M", "6M"],
			sludgewave: ["8M", "7M", "6M"],
			snarl: ["8M", "7M", "6M"],
			snore: ["8M", "8L29", "6T"],
			speedswap: ["8M"],
			strength: ["7M", "6M"],
			substitute: ["8M", "7M", "6M"],
			sunnyday: ["8M", "7M", "6M"],
			superpower: ["8M"],
			swagger: ["7M", "6M"],
			swift: ["8M"],
			taunt: ["8M", "7M", "6M"],
			torment: ["8L44", "7M", "6M"],
			toxic: ["7M", "6M"],
			uproar: ["8M"],
			wakeupslap: ["7L13", "6L13"],
			workup: ["8M", "7M"],
			yawn: ["8L1", "7L9", "6L9"],
		},
		eventData: [
			{generation: 6, level: 16, abilities: ["naturalcure"], moves: ["celebrate", "holdhands", "fly", "metronome"], pokeball: "cherishball"},
		],
	},
	pajantom: {
		learnset: {
			aerialace: ["7M"],
			astonish: ["8L10", "7L1"],
			attract: ["8M", "7M"],
			bind: ["8L5", "7T"],
			block: ["7T"],
			bravebird: ["8M", "8L1", "7L1"],
			breakingswipe: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			confide: ["7M"],
			crunch: ["8M"],
			doubleteam: ["8L25", "7M"],
			dracometeor: ["8T", "7T"],
			dragonbreath: ["8L20", "7L19"],
			dragonclaw: ["8M", "8L35", "7M", "7L23"],
			dragonpulse: ["8M", "7T"],
			dragonrage: ["7L12"],
			dragonrush: ["8E", "7L34"],
			dreameater: ["7M"],
			drillrun: ["8M", "7T"],
			dualchop: ["8L30", "7T"],
			earthquake: ["8M", "7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			fairylock: ["8L1", "7L1"],
			fly: ["8M", "7M"],
			frustration: ["7M"],
			gastroacid: ["7T"],
			gigaimpact: ["8M", "7M"],
			gravity: ["7T"],
			growl: ["8L1", "7L1"],
			haze: ["8L40", "7L37"],
			healblock: ["7L30"],
			helpinghand: ["8M", "7T"],
			hex: ["8M"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			icefang: ["8M", "7E"],
			icepunch: ["8M", "7T"],
			icywind: ["8M"],
			imprison: ["8M"],
			infestation: ["7M"],
			irontail: ["8M", "7T", "7E"],
			laserfocus: ["8L15", "7T", "7L10"],
			leechlife: ["8M", "7M"],
			nastyplot: ["8M"],
			outrage: ["8M", "8L60", "7T", "7L53"],
			phantomforce: ["8M", "8L55", "7L45"],
			poisonfang: ["8E", "7E"],
			poisongas: ["8L1", "7L17"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psychicfangs: ["8M", "8L50", "7L32"],
			psychup: ["7M"],
			raindance: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			sandtomb: ["8M", "7E"],
			shadowball: ["8M", "7M"],
			shadowclaw: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "8L1", "7T", "7L1"],
			spiritshackle: ["8L45", "7L1"],
			spite: ["7T", "7E"],
			stoneedge: ["8M", "7M"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			telekinesis: ["7T"],
			throatchop: ["8M", "7T"],
			toxic: ["7M"],
			toxicspikes: ["8M", "7L28"],
			trickroom: ["8M", "7M"],
			venoshock: ["8M", "7M"],
			whirlpool: ["8M", "7E"],
			wrap: ["8L1", "7L1"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	jumbao: {
		learnset: {
			armthrust: ["8L20"],
			attract: ["8M", "7M"],
			block: ["7T"],
			bodyslam: ["8M", "8L45", "7L28"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			bulletseed: ["8M", "7L38"],
			confide: ["7M"],
			dazzlinggleam: ["8M", "7M"],
			detect: ["8L15", "7L1"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			endure: ["8M"],
			energyball: ["8M", "8L55", "7M", "7L45"],
			explosion: ["7M"],
			facade: ["8M", "7M"],
			fakeout: ["8L1", "7L1"],
			flameburst: ["7L35"],
			flowershield: ["8L1", "7L1"],
			focusblast: ["8M", "7M"],
			focusenergy: ["8M", "8L1"],
			frustration: ["7M"],
			gigadrain: ["8M", "8L40", "7T"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			gravity: ["7T"],
			gyroball: ["8M", "7M"],
			heavyslam: ["8M", "8L60", "7L42"],
			helpinghand: ["8M", "8L1", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			ingrain: ["8L1", "7L14"],
			leafage: ["8L1", "7L1"],
			leafstorm: ["8M", "8L70", "7L52"],
			lifedew: ["8L1"],
			lightscreen: ["8M", "7M"],
			luckychant: ["7L1"],
			magicalleaf: ["8M", "8L25", "7L17"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			metronome: ["8M"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M"],
			moonblast: ["8L65", "7L49"],
			naturalgift: ["7L31"],
			playrough: ["8M"],
			protect: ["8M", "7M"],
			psychup: ["8L35", "7M", "7L24"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rototiller: ["7L10"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			seedbomb: ["8M", "7T"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "7M"],
			shoreup: ["7L1"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			solarblade: ["8M"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "8L50", "7M", "7L35"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			synthesis: ["8L50", "7T"],
			tackle: ["8L1", "7L1"],
			toxic: ["7M"],
			wish: ["8L30", "7L21"],
			wonderroom: ["8M", "7T"],
			worryseed: ["7T"],
		},
	},
	caribolt: {
		learnset: {
			attract: ["8M", "7M"],
			bodyslam: ["8M", "8L42", "7L32"],
			boomburst: ["8L1", "7L1"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulletseed: ["8M", "7L1"],
			celebrate: ["7S0"],
			chargebeam: ["8L20", "7M"],
			charm: ["8M"],
			confide: ["7M"],
			confuseray: ["8L15", "7L19"],
			doubleteam: ["7M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M", "8L1", "7L1"],
			electroweb: ["8M", "7T"],
			endeavor: ["7T"],
			endure: ["8M"],
			energyball: ["8M", "8L1", "7M"],
			facade: ["8M", "7M"],
			flash: ["7L47"],
			flashcannon: ["8M", "7M"],
			frenzyplant: ["8T", "7T"],
			frustration: ["7M"],
			gigadrain: ["8M", "7T"],
			gigaimpact: ["8M", "7M"],
			grassknot: ["8M", "7M"],
			grasspledge: ["8T", "7T"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L1", "7L1"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hornleech: ["8L1", "7L1", "7S0"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M", "7T"],
			knockoff: ["7T"],
			leafage: ["8L1", "7L1"],
			leafstorm: ["8M"],
			leechseed: ["8L25", "7L28"],
			leer: ["8L1", "7L1"],
			magnetrise: ["8L63", "7T", "7L62"],
			metronome: ["8M", "7S0"],
			naturepower: ["7M"],
			powerwhip: ["8M"],
			protect: ["8M", "7M"],
			quickattack: ["8L9", "7L12"],
			razorleaf: ["8L12", "7L15"],
			rest: ["8M", "7M"],
			return: ["7M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			seedbomb: ["8M", "8L35", "7T", "7L37"],
			shockwave: ["7T"],
			signalbeam: ["7T", "7L52"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			solarblade: ["8M"],
			spark: ["8L30", "7L24"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "7M"],
			synthesis: ["8L49", "7T"],
			tackle: ["8L1", "7L1"],
			terrainpulse: ["8T"],
			throatchop: ["8M"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "7M"],
			thundershock: ["8L1", "7L1"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			uproar: ["8M", "7T"],
			voltswitch: ["8M", "7M"],
			wildcharge: ["8M", "8L56", "7T", "7L42", "7S0"],
			workup: ["8M", "7M"],
			worryseed: ["7T"],
			zapcannon: ["8L70", "7L57"],
		},
		eventData: [
			{generation: 7, level: 50, moves: ["celebrate", "hornleech", "wildcharge", "metronome"], pokeball: "cherishball"},
		],
	},
	smokomodo: {
		learnset: {
			attract: ["8M", "7M"],
			blastburn: ["8T", "7T"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "8L35", "7M", "7L20"],
			burningjealousy: ["8T"],
			camouflage: ["7L38", "7S0"],
			celebrate: ["7S0"],
			circlethrow: ["8L1", "7L1"],
			clearsmog: ["8L20", "7L29"],
			confide: ["7M"],
			corrosivegas: ["8T"],
			crosspoison: ["8M"],
			defog: ["7T"],
			dig: ["8M", "8L1"],
			doubleteam: ["7M"],
			earthpower: ["8M", "7T"],
			earthquake: ["8M", "8L56", "7M", "7L54"],
			ember: ["8L1", "7L1"],
			endeavor: ["7T"],
			eruption: ["8L1", "7L1", "7S0"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			firefang: ["8M"],
			firepledge: ["8T", "7T"],
			firepunch: ["8M", "7T"],
			firespin: ["8M"],
			fissure: ["8L1", "7L65"],
			flameburst: ["7L24"],
			flamethrower: ["8M", "7M", "7L43"],
			flamewheel: ["8L30", "7L17"],
			flareblitz: ["8M", "8L70", "7L60"],
			focusblast: ["8M", "7M"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			gunkshot: ["8M", "7T"],
			heatcrash: ["8M"],
			heatwave: ["8M", "7T"],
			hiddenpower: ["7M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M"],
			incinerate: ["8L15"],
			irontail: ["8M", "7T"],
			lavaplume: ["8L42", "7L33"],
			lick: ["8L9", "7L7"],
			lowkick: ["8M", "7T"],
			machpunch: ["8L1"],
			magnitude: ["7L1", "7S0"],
			metalclaw: ["8L1", "7L1"],
			morningsun: ["8L1", "7L1"],
			mudshot: ["8M", "8L25", "7L14"],
			mysticalfire: ["8M"],
			overheat: ["8M", "7M"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roar: ["7M"],
			round: ["8M", "7M"],
			sandtomb: ["8M"],
			scaleshot: ["8T"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "7L1"],
			screech: ["8M", "8L49", "7L49"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			smog: ["8L12", "7L10"],
			smokescreen: ["8L1", "7L4"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			stealthrock: ["8M", "7T"],
			stompingtantrum: ["8M", "7T"],
			stormthrow: ["8L1", "7L1"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			tailwhip: ["8L1", "7L1"],
			taunt: ["8M", "7M"],
			toxic: ["8L63", "7M"],
			venomdrench: ["8M"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
		},
		eventData: [
			{generation: 7, level: 50, moves: ["celebrate", "eruption", "magnitude", "camouflage"], pokeball: "cherishball"},
		],
	},
	snaelstrom: {
		learnset: {
			allyswitch: ["8M"],
			aquajet: ["8L12", "7L17"],
			aquaring: ["8L1", "7L1"],
			attract: ["8M", "8L1", "7M", "7L1"],
			blizzard: ["8M", "7M"],
			block: ["7T"],
			bodyslam: ["8M"],
			brine: ["8M", "8L25", "7L27"],
			bugbite: ["7T"],
			bugbuzz: ["8M", "8L56", "7L48"],
			captivate: ["7L56"],
			celebrate: ["7S0"],
			charm: ["8M", "8L9", "7L11"],
			confide: ["7M"],
			confusion: ["8L15", "7L14"],
			dazzlinggleam: ["8M", "7M"],
			dive: ["8M"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			frustration: ["7M"],
			futuresight: ["8M"],
			gigaimpact: ["8M", "7M"],
			growl: ["8L1", "7L1"],
			guardswap: ["8M"],
			hail: ["8M", "7M"],
			healpulse: ["8L30", "7L22"],
			helpinghand: ["8M"],
			hiddenpower: ["7M"],
			hydrocannon: ["8T", "7T"],
			hydropump: ["8M", "8L70", "7L60"],
			hyperbeam: ["8M", "7M"],
			hypervoice: ["8M"],
			icebeam: ["8M", "7M"],
			iciclespear: ["8M"],
			icywind: ["8M", "7T"],
			infestation: ["7M"],
			leechlife: ["8M", "7M", "7S0"],
			liquidation: ["8M", "8L1", "7T", "7L1", "7S0"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			metronome: ["8M", "7S0"],
			muddywater: ["8M"],
			pinmissile: ["8M"],
			pound: ["8L1", "7L1"],
			protect: ["8M", "7M"],
			psychic: ["8M", "8L42", "7M", "7L44"],
			raindance: ["8M", "8L20", "7M", "7L32"],
			rapidspin: ["8L1", "7L1"],
			razorshell: ["8M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scald: ["8M", "7M"],
			signalbeam: ["7T"],
			skillswap: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["8M", "7T"],
			strugglebug: ["8L1", "7L1"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "8L49", "7M", "7L64"],
			toxic: ["8L63", "7M"],
			trick: ["8M", "7T"],
			trickroom: ["8M", "7M"],
			uproar: ["8M", "7T"],
			uturn: ["8M", "8L35", "7M", "7L40"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M"],
			waterfall: ["8M", "7M"],
			watergun: ["8L1"],
			waterpledge: ["8T", "7T"],
			waterpulse: ["7T"],
			whirlpool: ["8M", "8L1", "7L1"],
			wonderroom: ["8M", "7T"],
			workup: ["8M", "7M"],
			xscissor: ["8M"],
		},
		eventData: [
			{generation: 7, level: 50, moves: ["celebrate", "liquidation", "leechlife", "metronome"], pokeball: "cherishball"},
		],
	},
	equilibra: {
		learnset: {
			allyswitch: ["8M", "7T"],
			aurasphere: ["8M", "8L35", "7L71"],
			bodyslam: ["8M"],
			bulldoze: ["8M", "7M"],
			confide: ["7M"],
			destinybond: ["8L52", "7L64"],
			doomdesire: ["8L1", "7L1"],
			doubleteam: ["7M"],
			drillrun: ["8M", "7T"],
			earthpower: ["8M", "8L58", "7T", "7L40"],
			earthquake: ["8M", "7M", "7L57"],
			embargo: ["7M"],
			endure: ["8M"],
			explosion: ["7M"],
			facade: ["8M", "7M"],
			flashcannon: ["8M", "8L28", "7M", "7L45"],
			frustration: ["7M"],
			gigaimpact: ["8M", "7M"],
			gravity: ["7T"],
			guardsplit: ["8L16", "7L25"],
			gyroball: ["8M", "8L12", "7M", "7L20"],
			healingwish: ["8L46", "7L50"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hyperbeam: ["8M", "7M"],
			imprison: ["8M", "8L1", "7L1"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T"],
			magicroom: ["8M", "7T"],
			magnetrise: ["8L1", "7T"],
			memento: ["8L40", "7L50"],
			mindreader: ["8L24", "7L35"],
			mirrorshot: ["7L30"],
			mudshot: ["8M", "7L15"],
			mudslap: ["8L1", "7L1"],
			mudsport: ["7L1"],
			painsplit: ["8L64", "7T", "7L78"],
			perishsong: ["8L1", "7L85"],
			pound: ["8L1", "7L1"],
			powersplit: ["8L16", "7L25"],
			protect: ["8M", "7M"],
			psychup: ["7M"],
			quash: ["8L20", "7M"],
			rapidspin: ["8L1", "7L1"],
			recycle: ["7T"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			trickroom: ["8M", "7M"],
			wonderroom: ["8M", "7T"],
			workup: ["8M", "7M"],
		},
	},
	astrolotl: {
		learnset: {
			acrobatics: ["8M"],
			agility: ["8M"],
			allyswitch: ["8M"],
			attract: ["8M"],
			batonpass: ["8M", "8L1"],
			breakingswipe: ["8M"],
			bulldoze: ["8M"],
			charm: ["8M", "8L20"],
			cosmicpower: ["8M", "8L28"],
			dazzlinggleam: ["8M"],
			dracometeor: ["8T"],
			dragonbreath: ["8L1"],
			dragonclaw: ["8M"],
			dragonpulse: ["8M", "8L44"],
			dragonrush: ["8L56"],
			dragontail: ["8L24"],
			ember: ["8L1"],
			encore: ["8M", "8L12"],
			endure: ["8M"],
			facade: ["8M"],
			fireblast: ["8M"],
			firefang: ["8M"],
			firelash: ["8L38"],
			firepunch: ["8M"],
			firespin: ["8M"],
			flamethrower: ["8M"],
			flamewheel: ["8L16"],
			flareblitz: ["8M", "8L62"],
			healbell: ["8L32"],
			healingwish: ["8L50"],
			heatwave: ["8M"],
			helpinghand: ["8M"],
			hyperbeam: ["8M"],
			imprison: ["8M"],
			lightscreen: ["8M"],
			magicalleaf: ["8M"],
			magiccoat: ["8L1"],
			meteorbeam: ["8T"],
			metronome: ["8M"],
			mysticalfire: ["8M", "8L1"],
			outrage: ["8M"],
			overheat: ["8M"],
			pound: ["8L1"],
			protect: ["8M"],
			reflect: ["8M"],
			rest: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			scorchingsands: ["8T"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			spikes: ["8M"],
			stompingtantrum: ["8M"],
			storedpower: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			swift: ["8M"],
			tailwhip: ["8L1"],
			taunt: ["8M"],
			thunderwave: ["8M"],
			willowisp: ["8M"],
			workup: ["8M"],
		},
	},
	miasmaw: {
		learnset: {
			agility: ["8M"],
			attract: ["8M"],
			bite: ["8L1"],
			bodyslam: ["8M"],
			breakingswipe: ["8M"],
			brutalswing: ["8M"],
			bugbite: ["8L16"],
			bugbuzz: ["8M", "8L24"],
			bulldoze: ["8M"],
			closecombat: ["8M"],
			corrosivegas: ["8T"],
			crunch: ["8M", "8L40"],
			darkpulse: ["8M"],
			dracometeor: ["8T"],
			dragonbreath: ["8L1"],
			dragonclaw: ["8M"],
			dragonhammer: ["8L1"],
			dragonpulse: ["8M", "8L46"],
			dualwingbeat: ["8T"],
			earthpower: ["8M"],
			earthquake: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			firefang: ["8M"],
			flashcannon: ["8M"],
			focusblast: ["8M"],
			gigaimpact: ["8M", "8L58"],
			gunkshot: ["8M"],
			haze: ["8L28"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M"],
			icefang: ["8M"],
			ironhead: ["8M"],
			irontail: ["8M"],
			leechlife: ["8M"],
			lunge: ["8L52"],
			megahorn: ["8M"],
			nastyplot: ["8M"],
			outrage: ["8M"],
			pinmissile: ["8M"],
			poisongas: ["8L20"],
			poisonjab: ["8M"],
			protect: ["8M"],
			raindance: ["8M"],
			rest: ["8M"],
			round: ["8M"],
			scaryface: ["8M"],
			screech: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M"],
			sludgebomb: ["8M"],
			smog: ["8L12"],
			smokescreen: ["8L1"],
			snore: ["8M"],
			strugglebug: ["8L1"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			superfang: ["8L34"],
			superpower: ["8M"],
			swordsdance: ["8M"],
			taunt: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderfang: ["8M"],
			uproar: ["8M"],
			uturn: ["8M"],
			wildcharge: ["8M"],
			workup: ["8M"],
			xscissor: ["8M"],
		},
	},
	chromera: {
		learnset: {
			acidspray: ["8L1"],
			aerialace: ["8L10"],
			aromatherapy: ["8L60"],
			assurance: ["8M"],
			attract: ["8M"],
			beatup: ["8M"],
			belch: ["8S0"],
			blizzard: ["8M"],
			bodyslam: ["8M"],
			boomburst: ["8L75"],
			calmmind: ["8M"],
			charm: ["8M"],
			crunch: ["8M", "8L35"],
			darkpulse: ["8M", "8S0"],
			decorate: ["8L65"],
			endure: ["8M"],
			facade: ["8M"],
			faketears: ["8M"],
			finalgambit: ["8L80"],
			firefang: ["8M", "8L20"],
			firstimpression: ["8L45"],
			foulplay: ["8M"],
			gigaimpact: ["8M"],
			gunkshot: ["8M"],
			hex: ["8M"],
			hyperbeam: ["8M"],
			hypervoice: ["8M"],
			icefang: ["8M", "8L20"],
			imprison: ["8M", "8L25"],
			knockoff: ["8L1"],
			lashout: ["8T"],
			lifedew: ["8L40"],
			lightscreen: ["8M"],
			metalclaw: ["8L5"],
			nobleroar: ["8L1"],
			outrage: ["8M", "8L70"],
			payback: ["8M"],
			payday: ["8M"],
			playrough: ["8M"],
			protect: ["8M"],
			recover: ["8L1", "8S0"],
			reflect: ["8M"],
			rest: ["8M"],
			revenge: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			scald: ["8M"],
			scaryface: ["8M"],
			shadowball: ["8M"],
			sleeptalk: ["8M"],
			sludgebomb: ["8M"],
			sludgewave: ["8M"],
			snarl: ["8M"],
			snore: ["8M"],
			spite: ["8L15"],
			stompingtantrum: ["8M", "8L30"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			switcheroo: ["8S0"],
			taunt: ["8M"],
			thief: ["8M"],
			thunder: ["8M"],
			thunderbolt: ["8M"],
			thunderfang: ["8M", "8L20"],
			toxic: ["8L55"],
			toxicspikes: ["8M"],
			uproar: ["8M"],
			venomdrench: ["8M"],
			wideguard: ["8L50"],
		},
		eventData: [
			{generation: 8, level: 50, moves: ["recover", "switcheroo", "darkpulse", "belch"], pokeball: "cherishball"},
		],
	},
	syclant: {
		learnset: {
			absorb: ["8L1", "7L1"],
			attract: ["8M", "7M", "4M"],
			avalanche: ["8M", "8L33", "7L50", "4M", "4L49"],
			blizzard: ["8M", "7M", "4M"],
			brickbreak: ["8M", "7M", "4M"],
			brutalswing: ["8M", "7M"],
			bugbite: ["4T"],
			bugbuzz: ["8M", "8L60", "7L46", "4L42"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			confide: ["7M"],
			counter: ["4T"],
			cut: ["4M"],
			doubleedge: ["4T"],
			doubleteam: ["7M", "4M"],
			dualwingbeat: ["8T"],
			earthpower: ["8M", "4T"],
			earthquake: ["8M", "7M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			falseswipe: ["8M", "7M", "4M"],
			fling: ["8M", "7M", "4M"],
			focusblast: ["8M", "7M", "4M"],
			focusenergy: ["8M", "8L25", "7L10", "4L8"],
			focuspunch: ["6T", "4M"],
			frostbreath: ["7M"],
			frustration: ["7M", "4M"],
			furyattack: ["8L1", "7L14", "4L1"],
			furycutter: ["8L1", "7L23", "4T"],
			gigaimpact: ["8M", "7M", "4M"],
			hail: ["8M", "7M", "7L37", "4M", "4L35"],
			hiddenpower: ["7M", "4M"],
			honeclaws: ["7M"],
			hyperbeam: ["8M", "7M", "4M"],
			icebeam: ["8M", "7M", "4M"],
			icefang: ["8M"],
			icepunch: ["8M", "8L1", "7L1", "4T", "4L30"],
			iceshard: ["8L15", "7L5", "4L5"],
			iciclecrash: ["8L53", "7L41"],
			iciclespear: ["8M", "8L1", "7L1"],
			icywind: ["8M", "8L20", "7L19", "4T", "4L18"],
			leechlife: ["8M", "7M", "4L1"],
			leer: ["8L1", "7L1", "4L1"],
			megapunch: ["8M"],
			metronome: ["8M"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			pinmissile: ["8M"],
			protect: ["8M", "7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M"],
			screech: ["8M"],
			secretpower: ["7M", "4M"],
			sheercold: ["8L67", "5L59", "4L60"],
			signalbeam: ["7T"],
			silverwind: ["4M"],
			skittersmack: ["8T"],
			slash: ["8L39", "7L28", "4L14"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "4T"],
			spikes: ["8M"],
			stoneedge: ["8M", "7M", "4M"],
			strength: ["6M", "5M", "4M"],
			stringshot: ["4T"],
			strugglebug: ["7M"],
			substitute: ["8M", "7M", "4M"],
			superpower: ["8M", "4T"],
			swagger: ["7M", "4M"],
			swift: ["8M"],
			swordsdance: ["8M", "7M", "4M"],
			taunt: ["8M", "7M", "4M"],
			toxic: ["7M", "4M"],
			tripleaxel: ["8T"],
			uturn: ["8M", "7M", "4M"],
			waterpulse: ["6T", "4M"],
			xscissor: ["8M", "8L46", "7M", "7L32", "4M", "4L27"],
		},
	},
	revenankh: {
		learnset: {
			ancientpower: ["4T"],
			armthrust: ["8L5", "7L13", "4L18"],
			attract: ["8M", "7M", "4M"],
			bide: ["7L1", "4L1"],
			bind: ["7T"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "4M"],
			brutalswing: ["8M", "7M"],
			bulkup: ["8M", "7M", "4M"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			coaching: ["8T"],
			confide: ["7M"],
			counter: ["8E", "7E", "4T"],
			curse: ["8E", "7E", "4E"],
			destinybond: ["8E", "7E", "4E"],
			doubleteam: ["7M", "4M"],
			drainpunch: ["8M", "8L55", "7M", "4M"],
			dreameater: ["7M", "4M"],
			dualchop: ["7T"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			fling: ["8M", "7M", "4M"],
			focusblast: ["8M", "7M", "4M"],
			focuspunch: ["8L70", "7T", "4M"],
			forcepalm: ["8E", "7E", "4E"],
			frustration: ["7M", "4M"],
			gigaimpact: ["8M", "7M", "4M"],
			glare: ["8L35", "7L26", "4L26"],
			hammerarm: ["8L65", "7L57", "4L44"],
			headbutt: ["7T"],
			helpinghand: ["8M", "7T", "4T"],
			hex: ["8M", "7E"],
			hiddenpower: ["7M", "4M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M", "4M"],
			icepunch: ["8M", "7T", "4T"],
			knockoff: ["8L40", "7T", "4T"],
			machpunch: ["8E", "7E", "4E"],
			meanlook: ["8L25", "7L18", "4L23"],
			megapunch: ["8M"],
			memento: ["8E", "7E", "4E"],
			metronome: ["8M", "7E", "4T"],
			mimic: ["7E", "4E"],
			moonlight: ["8L45", "7L62", "4L62"],
			mudslap: ["4T"],
			nastyplot: ["8M", "7E", "4E"],
			naturalgift: ["4M"],
			ominouswind: ["7E", "4T", "4E"],
			painsplit: ["7T", "4T"],
			payback: ["8M", "8L20", "7L38", "4M"],
			poltergeist: ["8T"],
			poweruppunch: ["7M"],
			powerwhip: ["8M", "7L52", "4L38"],
			protect: ["8M", "7M", "4M"],
			psychup: ["7M", "4M"],
			punishment: ["7L71", "4L52"],
			quickguard: ["8L15", "7L48"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			revenge: ["8M", "8L50", "7L33", "4L33"],
			reversal: ["8M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "7L23", "4M", "4L13"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "4M"],
			sandstorm: ["8M", "7M", "4M"],
			sandtomb: ["8M", "8L1", "7L5", "4L5"],
			secretpower: ["7M", "4M"],
			shadowball: ["8M", "7M", "4M"],
			shadowclaw: ["8M", "7M"],
			shadowpunch: ["8L30", "7L29", "4L29"],
			shadowsneak: ["8L10", "7E", "4E"],
			sleeptalk: ["8M", "7M", "4M"],
			smackdown: ["7M"],
			snore: ["8M", "7T"],
			spite: ["7T", "4T"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "4M"],
			suckerpunch: ["8L60", "4T"],
			sunnyday: ["8M", "7M", "4M"],
			superpower: ["8M", "7T", "4T"],
			swagger: ["7M", "4M"],
			taunt: ["8M", "7M", "4M"],
			telekinesis: ["7M"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			trick: ["8M", "7T", "4T"],
			vacuumwave: ["4T"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
			wrap: ["8L1", "7L1", "4L1"],
			wringout: ["7L66", "4L9"],
		},
	},
	pyroak: {
		learnset: {
			amnesia: ["8M", "8L41", "7L37"],
			ancientpower: ["4T"],
			aromaticmist: ["8L1", "7L59"],
			attract: ["8M", "7M", "4M"],
			block: ["8E", "7T", "4T"],
			bulldoze: ["8M", "7M"],
			bulletseed: ["8M", "8L1", "7L1", "4M", "4L1"],
			burningjealousy: ["8T"],
			burnup: ["8L64", "7L68"],
			confide: ["7M"],
			counter: ["4T"],
			doubleedge: ["8L1", "7E", "4T"],
			doubleteam: ["7M", "4M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T"],
			dragontail: ["7M"],
			earthpower: ["8M", "7T", "4T"],
			earthquake: ["8M", "7M", "4M"],
			ember: ["8L1", "7L5", "4L9"],
			endure: ["8M", "4M"],
			energyball: ["8M", "8L1", "7M", "7L1", "4M"],
			facade: ["8M", "7M", "4M"],
			fireblast: ["8M", "7M", "4M"],
			firespin: ["8M", "7E", "4L28"],
			flameburst: ["7L24"],
			flamecharge: ["8L1", "7M"],
			flamethrower: ["8M", "7M", "4M"],
			flamewheel: ["8L16", "7L19", "4L17"],
			flareblitz: ["8M", "8L1", "7L1", "4L1"],
			flash: ["4M"],
			flashcannon: ["8M", "7M", "7L46"],
			frustration: ["7M", "4M"],
			gigadrain: ["8M", "8L20", "7L32", "4M", "4L21"],
			gigaimpact: ["8M", "7M", "4M"],
			grassknot: ["8M", "7M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growth: ["8L12", "7L28", "4L5"],
			headbutt: ["4T"],
			heatcrash: ["8M", "7L41"],
			heatwave: ["8M", "7T", "4T"],
			heavyslam: ["8M"],
			hiddenpower: ["7M", "4M"],
			highhorsepower: ["8M"],
			hyperbeam: ["8M", "7M", "4M"],
			incinerate: ["7M"],
			irondefense: ["8M", "8L41", "7L37", "4T", "4L42"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T", "4M"],
			lavaplume: ["8L48", "7L50", "4L54"],
			leechseed: ["8L1", "7L10", "4L13"],
			lightscreen: ["8M", "7M", "4M"],
			lowkick: ["8M", "7T", "4T"],
			magicalleaf: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M"],
			overheat: ["8M", "7M", "4M"],
			petalblizzard: ["8L1"],
			petaldance: ["8L56", "7L55", "4L36"],
			protect: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			revenge: ["8M"],
			roar: ["7M", "4M"],
			rockclimb: ["4M"],
			rockpolish: ["7M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "4M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "4M"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			secretpower: ["7M", "4M"],
			seedbomb: ["8M", "8L32", "7L23", "4T"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "4T"],
			solarbeam: ["8M", "7M", "4M"],
			solarblade: ["8M"],
			stealthrock: ["8M", "7T", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			sweetscent: ["8L1", "7L1", "4L1"],
			swift: ["8M"],
			swordsdance: ["8M", "7M", "4M"],
			synthesis: ["8L27", "7L14", "4T", "4L48"],
			terrainpulse: ["8T"],
			toxic: ["7M", "4M"],
			wildcharge: ["8M", "7M"],
			willowisp: ["8M", "7M", "4M"],
			woodhammer: ["8L1", "7L1", "4L1"],
			worryseed: ["7T", "4T"],
			zapcannon: ["8L1", "7L64", "4L60"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	fidgit: {
		learnset: {
			acrobatics: ["8M", "8L1", "7M"],
			aerialace: ["8L1", "7M", "4M"],
			afteryou: ["7T"],
			attract: ["8M", "7M", "4M"],
			block: ["7T", "4T"],
			bodyslam: ["8M", "8L28", "7L30", "4T", "4L30"],
			bounce: ["8M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			cometpunch: ["7L1", "4L1"],
			confide: ["7M"],
			copycat: ["7L19", "4L19"],
			dig: ["8M", "7M"],
			doubleedge: ["4T"],
			doubleteam: ["7M", "4M"],
			drillrun: ["8M", "8L1", "7L1"],
			earthpower: ["8M", "8L58", "7L60", "4T", "4L59"],
			earthquake: ["8M", "7M", "4M"],
			encore: ["8M", "8L1", "7L5", "4L5"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			fling: ["8M", "7M", "4M"],
			followme: ["8L1"],
			frustration: ["7M", "4M"],
			gastroacid: ["8L1", "7T", "4T"],
			gigaimpact: ["8M", "7M", "4M"],
			gravity: ["8L52", "7L47", "4T", "4L49"],
			gunkshot: ["8M", "8L64"],
			gust: ["8L1", "7L1", "4L1"],
			healblock: ["7L54", "4L53"],
			helpinghand: ["8M", "8L1", "7L1", "4T", "4L1"],
			hiddenpower: ["7M", "4M"],
			hyperbeam: ["8M", "7M", "4M"],
			icywind: ["8M", "7T", "4T"],
			knockoff: ["8L24", "7L14", "4T", "4L14"],
			lightscreen: ["8M", "7M", "4M"],
			luckychant: ["7L65", "4L67"],
			magicroom: ["8M", "7T"],
			megapunch: ["8M"],
			metronome: ["8M", "4T"],
			mudshot: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			poisonjab: ["8M", "7M", "4M"],
			protect: ["8M", "7M", "4M"],
			psychup: ["7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			rapidspin: ["8L16", "7L9", "4L9"],
			reflect: ["8M", "7M", "4M"],
			rest: ["8M", "8L1", "7M", "4M"],
			return: ["7M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "4M"],
			roleplay: ["7T"],
			rototiller: ["7L1"],
			round: ["8M", "7M"],
			safeguard: ["8M", "8L1", "7M", "4M"],
			sandstorm: ["8M", "7M", "4M"],
			sandtomb: ["8M"],
			scorchingsands: ["8T"],
			secretpower: ["7M", "4M"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "7M", "4M"],
			skillswap: ["8M", "7M", "4M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "4M"],
			sludgebomb: ["8M", "8L38", "7M", "7L35", "4M", "4L35"],
			sludgewave: ["8M", "7M"],
			smartstrike: ["8M"],
			snatch: ["7M", "4M"],
			snore: ["8M", "7T", "4T"],
			speedswap: ["8M"],
			spikes: ["8M"],
			stealthrock: ["8M", "7T", "4M"],
			stompingtantrum: ["8M"],
			stoneedge: ["8M", "7M", "4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			swift: ["8M", "4T"],
			tailwind: ["8L1", "7L1", "4T", "4L1"],
			taunt: ["8M", "7M", "4M"],
			thief: ["8M", "7M", "4M"],
			torment: ["7M", "4M"],
			toxic: ["8L46", "7M", "4M"],
			toxicspikes: ["8M", "8L20", "7L41", "4L41"],
			trickroom: ["8M", "7M", "4M"],
			twister: ["4T"],
			uturn: ["8M", "7M", "4M"],
			venoshock: ["8M", "7M"],
			whirlwind: ["8L32", "7L25", "4L25"],
			wideguard: ["8L12", "7L1"],
			wonderroom: ["8M", "7T"],
		},
	},
	stratagem: {
		learnset: {
			accelerock: ["8L20", "7L21"],
			acupressure: ["8L48", "7L26", "4L20"],
			aerialace: ["7M", "4M"],
			ancientpower: ["8L31", "7L30", "4T"],
			bulldoze: ["8M", "7M"],
			calmmind: ["8M", "7M", "4M"],
			confide: ["7M"],
			cut: ["4M"],
			dazzlinggleam: ["8M", "7M"],
			defensecurl: ["8L1", "7L1", "4L1"],
			disable: ["8L16", "7L17", "4L17"],
			doubleedge: ["8L56", "7L43", "4L32"],
			doubleteam: ["7M", "4M"],
			earthpower: ["8M", "7L47", "4T"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			endure: ["8M", "4M"],
			energyball: ["8M", "7M", "4M"],
			explosion: ["7M", "4M"],
			facade: ["8M", "7M", "4M"],
			falseswipe: ["8M", "7M", "4M"],
			fireblast: ["8M", "7M", "4M"],
			flamethrower: ["8M", "7M", "4M"],
			flash: ["4M"],
			frustration: ["7M", "4M"],
			gigadrain: ["8M", "7T", "4M"],
			gigaimpact: ["8M", "7M", "4M"],
			headbutt: ["4T"],
			headsmash: ["8L70", "7L69", "4L50"],
			heatwave: ["8M", "7T", "4T"],
			hiddenpower: ["7M", "4M"],
			hyperbeam: ["8M", "7M", "4M"],
			incinerate: ["7M"],
			laserfocus: ["8L1", "7L1"],
			lockon: ["8L64", "7L65"],
			metalsound: ["8L36", "7L52", "4L37"],
			meteorbeam: ["8T"],
			mudshot: ["8M", "7L34", "4L25"],
			mudslap: ["8L1", "7L8", "4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			paleowave: ["8L1", "7L1", "4L42"],
			powergem: ["8M", "8L42", "7L39", "4L29"],
			protect: ["8M", "7M", "4M"],
			quickguard: ["8L1", "7L1"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			rockblast: ["8M", "8L12", "7L13", "4L15"],
			rockclimb: ["4M"],
			rockpolish: ["7M", "4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "4M"],
			rollout: ["8L1", "7L4", "4T", "4L1"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M", "4M"],
			secretpower: ["7M", "4M"],
			shadowball: ["8M", "7M", "4M"],
			sleeptalk: ["8M", "7M", "4M"],
			smackdown: ["7M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7T", "4T"],
			speedswap: ["8M", "7L56"],
			stealthrock: ["8M", "7T", "4M"],
			stoneedge: ["8M", "7M", "4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M"],
			swift: ["8M", "4T"],
			tackle: ["8L1", "7L1", "4L1"],
			toxic: ["7M", "4M"],
			trick: ["8M", "7T", "4T"],
			vacuumwave: ["8L24", "7L60", "4T"],
			weatherball: ["8M", "8L1", "7L1", "4L1"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	arghonaut: {
		learnset: {
			aquajet: ["8L16", "7L19", "4L27"],
			armthrust: ["8L1", "7L14", "4L21"],
			attract: ["8M", "7M", "4M"],
			blizzard: ["8M", "7M", "4M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "4M"],
			brine: ["8M", "4M"],
			bubble: ["7L1", "4L1"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			chipaway: ["7L30"],
			circlethrow: ["8L1", "7L1"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M"],
			crosschop: ["7M"],
			crosspoison: ["8M"],
			cut: ["4M"],
			dive: ["8M", "4T"],
			doubleteam: ["7M", "4M"],
			drainpunch: ["8M", "4M"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			falseswipe: ["8M"],
			fling: ["8M", "7M", "4M"],
			focusblast: ["8M", "7M", "4M"],
			focuspunch: ["8L60", "7L73", "4M", "4L67"],
			foulplay: ["8M", "7T"],
			frustration: ["7M", "4M"],
			gigaimpact: ["8M", "7M", "4M"],
			gunkshot: ["8M", "7T", "4T"],
			hail: ["8M", "7M", "4M"],
			headbutt: ["8L36", "4T"],
			hiddenpower: ["7M", "4M"],
			hydropump: ["8M"],
			hyperbeam: ["8M", "7M", "4M"],
			icebeam: ["8M", "7M", "4M"],
			icepunch: ["8M", "7T", "4T"],
			icywind: ["8M", "7T", "4T"],
			knockoff: ["8L24", "7T", "4T"],
			lashout: ["8T"],
			lowkick: ["8M", "7T", "4T"],
			lowsweep: ["8M", "7M"],
			machpunch: ["8L16", "7L35", "4L32"],
			megapunch: ["8M"],
			muddywater: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			poisonjab: ["8M", "7M"],
			poweruppunch: ["7M"],
			protect: ["8M", "7M", "4M"],
			psychup: ["7M"],
			punishment: ["7L67", "4L60"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			revenge: ["8M", "8L28", "7L46", "4L41"],
			roar: ["7M", "4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["4M"],
			rocktomb: ["8M", "7M", "4M"],
			round: ["8M", "7M"],
			safeguard: ["8M"],
			scald: ["8M", "8L42", "7M", "7L40"],
			scaryface: ["8M"],
			secretpower: ["7M", "4M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "4M"],
			sludgebomb: ["8M", "7M", "4M"],
			smokescreen: ["8L12", "7L7", "4L7"],
			snatch: ["7M", "4M"],
			snore: ["8M", "7T", "4T"],
			spikes: ["8M"],
			stoneedge: ["8M", "7M", "4M"],
			strength: ["4M"],
			submission: ["8L48", "7L56", "4L55"],
			substitute: ["8M", "7M", "4M"],
			superpower: ["8M", "7T", "4T"],
			surf: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			taunt: ["8M", "8L32", "7M", "7L24", "4M", "4L36"],
			thief: ["8M", "8L1", "7M", "7L51", "4M", "4L47"],
			throatchop: ["8M"],
			thunderpunch: ["8M", "7T", "4T"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			vacuumwave: ["8L1", "4T"],
			waterfall: ["8M", "7M", "4M"],
			watergun: ["8L1"],
			waterpulse: ["7T", "4M"],
			whirlpool: ["8M", "4M"],
			wideguard: ["8L54", "7L62"],
			workup: ["8M", "7M"],
			wrap: ["8L1", "7L1", "4L1"],
			yawn: ["8L20", "7L10", "4L1"],
		},
	},
	kitsunoh: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M", "7M", "4M"],
			bulldoze: ["8M", "7M"],
			bulletpunch: ["8L1", "7L1"],
			captivate: ["4M"],
			closecombat: ["8M"],
			confide: ["7M"],
			copycat: ["8L20", "7L22", "4L22"],
			curse: ["8E", "7E", "4E"],
			cut: ["7M", "4M"],
			darkpulse: ["8M", "7M", "4M"],
			defog: ["8E", "7E", "4M"],
			dig: ["8M", "7M", "4M"],
			doubleteam: ["7M", "4M"],
			dreameater: ["7M", "4M"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			endeavor: ["7T", "4T"],
			facade: ["8M", "7M", "4M"],
			fakeout: ["8L1", "7L27", "4L35"],
			falseswipe: ["8M", "7M", "4M"],
			featherdance: ["8E", "7E", "4E"],
			feintattack: ["7L14", "4L14"],
			flail: ["8E", "7E", "4E"],
			flash: ["7M", "4M"],
			flashcannon: ["8M", "7M", "4M"],
			fling: ["8M", "7M", "4M"],
			foulplay: ["8M", "7M"],
			frustration: ["7M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "4M"],
			headbutt: ["4T"],
			hex: ["8M", "7E"],
			hiddenpower: ["7M", "4M"],
			honeclaws: ["8L28", "7L31"],
			hyperbeam: ["8M", "7M", "4M"],
			icepunch: ["8M", "7T", "4T"],
			icywind: ["8M", "7T", "4T"],
			irondefense: ["8M", "7T", "4T"],
			ironhead: ["8M", "8L40", "7L43", "4T"],
			irontail: ["8M", "7E", "4M"],
			knockoff: ["7T", "4T"],
			lastresort: ["7T", "4T"],
			lick: ["8L1", "7L6", "4L6"],
			lowkick: ["8M", "7T", "4T"],
			magiccoat: ["7T", "4T"],
			memento: ["8L48", "7L52", "4L31"],
			metalclaw: ["8L1", "7L1", "4L27"],
			metalsound: ["8E", "7E", "4E"],
			meteormash: ["8E", "7E", "4E"],
			metronome: ["8M"],
			naturalgift: ["4M"],
			odorsleuth: ["7L10", "4L10"],
			ominouswind: ["4T"],
			painsplit: ["7T", "4T"],
			payback: ["8M", "7M", "4M"],
			perishsong: ["8L52", "7L56", "4L55"],
			playrough: ["8M", "7E"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "4M"],
			psychoshift: ["8E", "7E", "4E"],
			psychup: ["7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			revenge: ["8M", "8L36", "7L39", "4L44"],
			roar: ["7M", "4M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "4M"],
			scratch: ["8L1", "7L1", "4L1"],
			secretpower: ["7M", "4M"],
			shadowball: ["8M", "7M", "4M"],
			shadowclaw: ["8M", "8L32", "7M", "7L35", "4M", "4L40"],
			shadowsneak: ["8L16", "7L18", "4L18"],
			shadowstrike: ["8L44", "7L48", "4L49"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "4M"],
			snatch: ["7M", "4M"],
			snore: ["8M", "7T", "4T"],
			spite: ["8L12", "7T", "4T"],
			steelbeam: ["8T"],
			substitute: ["8M", "7M", "4M"],
			suckerpunch: ["8E", "7E", "4T"],
			sunnyday: ["8M", "7M", "4M"],
			superfang: ["7T", "4T"],
			superpower: ["8M", "7T", "4T"],
			swagger: ["7M", "4M"],
			tailwhip: ["8L1", "7L1", "4L1"],
			taunt: ["8M", "7M", "4M"],
			telekinesis: ["7M"],
			thief: ["8M", "7M", "4M"],
			thunderpunch: ["8M", "7T", "4T"],
			torment: ["8L24", "7M", "4M"],
			toxic: ["7M", "4M"],
			trick: ["8M", "8L1", "7T", "4T"],
			trickroom: ["8M", "7M", "4M"],
			uturn: ["8M", "7M", "4M"],
			willowisp: ["8M", "7M", "4M"],
			wish: ["8E", "7E"],
			yawn: ["8E", "7E", "4E"],
		},
	},
	cyclohm: {
		learnset: {
			aerialace: ["7M", "4M"],
			aquatail: ["8E", "7T"],
			attract: ["8M", "7M", "4M"],
			bide: ["7L1", "4L1"],
			blizzard: ["8M", "7M", "4M"],
			breakingswipe: ["8M"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			charge: ["8L12", "7L11", "4L11"],
			chargebeam: ["7M", "4M"],
			confide: ["7M"],
			discharge: ["8L35", "7L33", "4L37"],
			doublehit: ["8L1", "7L20", "4L22"],
			doubleteam: ["7M", "4M"],
			dracometeor: ["8T", "7T", "4T"],
			dragonbreath: ["8L20", "7L29", "7E", "4E"],
			dragonclaw: ["8M", "7M", "4M"],
			dragonpulse: ["8M", "7T", "4M"],
			dragonrage: ["7L24", "4L7"],
			dragontail: ["8L28", "7M"],
			earthquake: ["8M", "7M", "4M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M", "8L1", "7L1"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			fireblast: ["8M", "7M", "4M"],
			firefang: ["8M"],
			flamethrower: ["8M", "7M", "4M"],
			flash: ["4M"],
			focusenergy: ["8M"],
			frustration: ["7M", "4M"],
			gigaimpact: ["8M", "7M", "4M"],
			growl: ["8L1", "7L1", "4L1"],
			hail: ["8M", "7M", "4M"],
			headbutt: ["8E", "4T"],
			healbell: ["7E", "4E"],
			hiddenpower: ["7M", "4M"],
			honeclaws: ["7M"],
			hydropump: ["8M", "7E", "4E"],
			hyperbeam: ["8M", "7M", "4M"],
			icebeam: ["8M", "7M", "4M"],
			icefang: ["8M"],
			icywind: ["8M", "7T"],
			incinerate: ["7M"],
			irontail: ["8M", "7T"],
			lightscreen: ["8M", "7M", "4M"],
			lockon: ["8L56"],
			muddywater: ["8M"],
			mudslap: ["8E", "7E", "4T", "4E"],
			naturalgift: ["4M"],
			naturepower: ["7M"],
			outrage: ["8M", "4T"],
			powdersnow: ["8E", "7E"],
			powergem: ["8M", "7E", "4E"],
			protect: ["8M", "7M", "4M"],
			raindance: ["8M", "8L40", "7M", "7L37", "4M", "4L19"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			risingvoltage: ["8T"],
			roar: ["8E", "7M", "4M"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M", "4M"],
			secretpower: ["7M", "4M"],
			shockwave: ["7T", "4M"],
			signalbeam: ["4T"],
			slackoff: ["8L48", "7L48", "4L42"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "4T"],
			sonicboom: ["7L16", "4L29"],
			strength: ["4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			surf: ["8M", "7M", "4M"],
			swagger: ["7M"],
			swift: ["8M", "4T"],
			tackle: ["8L1", "7L1", "4L1"],
			thrash: ["8L64", "7L53", "4L50"],
			thunder: ["8M", "8L72", "7M", "7L65", "4M"],
			thunderbolt: ["8M", "7M", "4M"],
			thunderfang: ["8M"],
			thundershock: ["8L1", "7L11", "4L15"],
			thunderwave: ["8M", "8L24", "7M", "4M"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			triattack: ["8M", "8L1", "7L1", "4L33"],
			trickroom: ["8M", "7M", "4M"],
			twister: ["8L1", "7L7", "4T"],
			voltswitch: ["8M", "7M"],
			waterfall: ["8M", "7M", "4M"],
			waterpulse: ["7T", "4M"],
			weatherball: ["8M", "7L42"],
			whirlwind: ["8L16", "7L1", "4L1"],
			wildcharge: ["8M", "7M"],
			zapcannon: ["8L56", "7L59", "4L59"],
		},
	},
	colossoil: {
		learnset: {
			ancientpower: ["4T"],
			aquatail: ["7T", "4T"],
			attract: ["8M", "7M", "4M"],
			bite: ["8L20", "5L10", "4L11"],
			block: ["7T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L35", "7L42", "4T", "4L22"],
			bounce: ["8M", "7L48", "4T", "4L35"],
			brickbreak: ["8M", "7M", "4M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			chipaway: ["7E"],
			confide: ["7M"],
			crunch: ["8M", "8L49", "7L58", "4L55"],
			darkpulse: ["8M", "7M", "4M"],
			dig: ["8M", "4M"],
			dive: ["8M", "4T"],
			doubleedge: ["8E", "7E", "4T", "4E"],
			doubleteam: ["7M", "4M"],
			drillrun: ["8M", "8L1", "7L1"],
			earthpower: ["8M", "7T", "4T"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			encore: ["8M", "7E", "4E"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			fakeout: ["8E", "7E", "4E"],
			firefang: ["8M", "7E", "4E"],
			fissure: ["8E", "7E", "4E"],
			flail: ["8E", "7E", "4E"],
			foulplay: ["8M", "7T"],
			frustration: ["7M", "4M"],
			furyattack: ["8L1", "7L26"],
			gigaimpact: ["8M", "7M", "4M"],
			hiddenpower: ["7M", "4M"],
			highhorsepower: ["8M", "8L1", "7L1"],
			hornattack: ["8L1", "7L5", "4L6"],
			horndrill: ["8L63", "7L74", "7E", "4L70", "4E"],
			hyperbeam: ["8M", "7M", "4M"],
			irontail: ["8M", "7T", "4M"],
			knockoff: ["8L25", "7T", "4T"],
			lashout: ["8T"],
			leer: ["8L1", "7L1", "4L1"],
			magnitude: ["7L32", "4L42"],
			megahorn: ["8M", "8L56", "7L64", "4L63"],
			mudslap: ["8L1", "7L16", "4T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "4M"],
			peck: ["8L1", "7L1", "4L1"],
			protect: ["8M", "7M", "4M"],
			pursuit: ["7L37", "4L29"],
			raindance: ["8M", "7M", "4M"],
			rapidspin: ["8L15", "7L21", "4L17"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			roar: ["7M", "4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["7M", "4M"],
			rocktomb: ["8M", "7M"],
			rollout: ["4T"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M", "4M"],
			sandtomb: ["8M", "8L1", "7E", "4E"],
			scorchingsands: ["8T"],
			screech: ["8M", "7E", "4E"],
			secretpower: ["7M", "4M"],
			sleeptalk: ["8M", "7M", "4M"],
			smartstrike: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snatch: ["7M", "4M"],
			snore: ["8M", "7T", "4T"],
			spitup: ["8L42", "7L53", "4L48"],
			stockpile: ["8L42", "7L53", "4L48"],
			stoneedge: ["8M", "7M", "4M"],
			strength: ["8L30", "4M"],
			substitute: ["8M", "7M", "4M"],
			suckerpunch: ["8E", "7E", "4T", "4E"],
			sunnyday: ["8M", "7M", "4M"],
			superpower: ["8M", "7T", "4T"],
			swagger: ["7M", "4M"],
			swallow: ["8L42", "7L53", "4L48"],
			taunt: ["8M", "7M", "4M"],
			thunderfang: ["8M", "7E", "4E"],
			torment: ["7M"],
			toxic: ["7M", "4M"],
			uturn: ["8M", "7M", "4M"],
			wideguard: ["7E"],
		},
	},
	krilowatt: {
		learnset: {
			aquatail: ["8L50", "7L1"],
			attract: ["8M", "7M", "4M"],
			blizzard: ["8M", "7M", "4M"],
			bubble: ["7L1", "4L1"],
			bubblebeam: ["8L1", "7L28", "4L28"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			charge: ["8L1", "7L1", "4L1"],
			confide: ["7M"],
			confuseray: ["8L1", "7L11", "4L11"],
			copycat: ["8L25", "7L39", "4L39"],
			counter: ["8E", "7L33", "7E", "4L33", "4E"],
			cut: ["6M", "5M", "4M"],
			discharge: ["8L30", "7M", "7L51", "4L51"],
			dive: ["8M", "6M", "4T"],
			doubleteam: ["7M", "4M"],
			earthpower: ["8M", "7T", "4T"],
			earthquake: ["8M", "7M", "4M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			endure: ["8M", "4M"],
			entrainment: ["8E", "7E"],
			facade: ["8M", "7M", "4M"],
			flash: ["4M"],
			fling: ["8M", "7M", "4M"],
			flipturn: ["8T"],
			followme: ["8E", "7E", "4E"],
			frustration: ["7M", "4M"],
			furycutter: ["4T"],
			gigaimpact: ["8M", "7M", "4M"],
			guillotine: ["8L60", "7L57", "4L57"],
			hail: ["8M", "7M", "4M"],
			heartswap: ["7L53", "4L46"],
			helpinghand: ["8M", "7T", "4T"],
			hiddenpower: ["7M", "4M"],
			hyperbeam: ["8M", "7M", "4M"],
			icebeam: ["8M", "7M", "4M"],
			icepunch: ["8M", "7T", "4T"],
			iceshard: ["8L1", "7L1", "4L1"],
			icywind: ["8M", "7T", "4T"],
			imprison: ["8M", "8L20", "7L17", "4L17"],
			irontail: ["8M", "7T"],
			liquidation: ["8M"],
			lowkick: ["8M", "7T", "4T"],
			lowsweep: ["8M", "7M"],
			magneticflux: ["8L1", "7L1"],
			mefirst: ["7E", "4E"],
			metronome: ["8M", "7E", "4E"],
			mindreader: ["8L35", "7E", "4E"],
			mirrorcoat: ["8E", "7L24", "7E", "4L24", "4E"],
			muddywater: ["8M", "8L40", "7L68", "4L68"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "4M"],
			protect: ["8M", "7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			recycle: ["7T", "4M"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			scald: ["8M", "7M"],
			secretpower: ["7T", "4M"],
			sheercold: ["8E", "7E", "4E"],
			shockwave: ["7T", "4M"],
			signalbeam: ["7T", "4T"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "7T"],
			speedswap: ["8M", "7E"],
			substitute: ["8M", "7M", "4M"],
			surf: ["8M", "7M", "4M"],
			swagger: ["7M"],
			swift: ["8M", "4T"],
			thunder: ["8M", "8L55", "7M", "7L63", "4M", "4L63"],
			thunderbolt: ["8M", "7M", "4M"],
			thunderpunch: ["8M", "7T", "4T"],
			thundershock: ["8L1", "7L5", "4L5"],
			thunderwave: ["8M", "7M", "4M"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			voltswitch: ["8M", "7M"],
			waterfall: ["8M", "7M", "4M"],
			watergun: ["8L1"],
			waterpulse: ["7T", "4M"],
			whirlpool: ["8M", "7M", "4M"],
			wildcharge: ["8M", "8L45", "7M", "7L53"],
		},
	},
	voodoom: {
		learnset: {
			acupressure: ["8L52", "7L40", "4L40"],
			afteryou: ["7T"],
			assurance: ["8M"],
			astonish: ["8L1", "7L1", "4L1"],
			attract: ["8M", "7M", "4M"],
			aurasphere: ["8M", "8L40", "7L45", "4L45"],
			batonpass: ["8M"],
			beatup: ["8M", "7L55", "4L55"],
			brickbreak: ["8M", "7M", "4M"],
			brutalswing: ["8M"],
			bulkup: ["8M", "7M", "4M"],
			bulldoze: ["8M", "7M"],
			burningjealousy: ["8T"],
			captivate: ["4M"],
			charge: ["8L20", "7L19", "4L19"],
			closecombat: ["8M", "8L64", "7L35", "4L35"],
			coaching: ["8T"],
			confide: ["7M"],
			copycat: ["8L1", "7L1", "4L1"],
			counter: ["4T"],
			darkestlariat: ["8M"],
			darkpulse: ["8M", "7M", "4M"],
			doubleteam: ["7M"],
			drainpunch: ["8M", "7T", "4M"],
			dreameater: ["7M", "4M"],
			earthquake: ["8M", "7M", "4M"],
			echoedvoice: ["8L16"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			feintattack: ["7L30", "4L30"],
			flashcannon: ["8M", "7M"],
			fling: ["8M", "7M", "4M"],
			focusblast: ["8M", "7M", "4M"],
			focuspunch: ["7T", "4M"],
			followme: ["8L1", "7L20", "4L20"],
			foulplay: ["8M", "8L58", "7L61"],
			frustration: ["7M", "4M"],
			gigaimpact: ["8M", "7M", "4M"],
			grudge: ["8L35", "7L15", "4L15"],
			hex: ["8M", "8L28", "7L48"],
			hiddenpower: ["7M", "4M"],
			hyperbeam: ["8M", "7M", "4M"],
			hypervoice: ["8M", "7T"],
			icepunch: ["8M", "7T", "4T"],
			imprison: ["8M"],
			knockoff: ["7T", "4T"],
			lashout: ["8T"],
			lowkick: ["8M", "7T", "4T"],
			lowsweep: ["8M", "7M"],
			magiccoat: ["7T", "4T"],
			magicroom: ["8M", "7T"],
			metronome: ["8M", "4T"],
			mudslap: ["4T"],
			nastyplot: ["8M"],
			naturalgift: ["4M"],
			nightmare: ["4T"],
			nightslash: ["8L1", "7M", "7L1", "4L1"],
			painsplit: ["8L46", "7L1", "4T", "4L1"],
			payback: ["8M", "7M", "4M"],
			pinmissile: ["8M", "8L1", "7L25", "4L25"],
			poweruppunch: ["7M"],
			protect: ["8M", "7M", "4M"],
			psychic: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			revenge: ["8M", "8L1", "7L1", "4L1"],
			risingvoltage: ["8T"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["7M", "4M"],
			round: ["8M", "7M"],
			screech: ["8M"],
			secretpower: ["7M", "4M"],
			shockwave: ["7T", "4M"],
			sleeptalk: ["8M", "7M", "4M"],
			smartstrike: ["8M", "7M"],
			snarl: ["8M", "7M"],
			snatch: ["4M"],
			snore: ["8M", "7T", "4T"],
			spite: ["8L12", "7L1", "4T", "4L1"],
			stoneedge: ["8M", "7M", "4M"],
			strength: ["7M", "4M"],
			substitute: ["8M", "7M", "7L50", "4M", "4L50"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			taunt: ["8M", "7M", "4M"],
			tearfullook: ["8L24", "7L22"],
			thief: ["8M", "7M", "4M"],
			throatchop: ["8M"],
			thunderbolt: ["8M", "7M"],
			thunderpunch: ["8M"],
			thunderwave: ["8M", "7M", "4M"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			uproar: ["8M", "7T", "4T"],
			vacuumwave: ["4T"],
			workup: ["8M", "7M"],
			wrap: ["8L1", "7L1", "4L1"],
		},
	},
	syclar: {
		learnset: {
			absorb: ["8L1", "7L1"],
			attract: ["8M", "7M", "4M"],
			avalanche: ["8M", "8L31", "7L48", "4M"],
			blizzard: ["8M", "7M", "4M"],
			bugbite: ["7M", "4T"],
			bugbuzz: ["8M", "8L50", "7L43", "4L42"],
			captivate: ["4M"],
			confide: ["7M"],
			counter: ["8E", "7E", "4T"],
			cut: ["6M", "4M"],
			doubleedge: ["8E", "4T"],
			doubleteam: ["7M", "4M"],
			earthpower: ["8M", "8E", "7E", "4T", "4E"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			falseswipe: ["8M", "7M", "4M"],
			fellstinger: ["8E", "7E"],
			fling: ["8M", "7M", "4M"],
			focusenergy: ["8M", "8L25", "7L10", "4L13"],
			focuspunch: ["7T"],
			frostbreath: ["7M"],
			frustration: ["7M", "4M"],
			furyattack: ["8L5", "7L14", "4L1"],
			furycutter: ["8L10", "7L23", "4T"],
			hail: ["8M", "7M", "7L34", "4M", "4L28"],
			hiddenpower: ["7M", "4M"],
			honeclaws: ["7M"],
			icebeam: ["8M", "7M", "4M"],
			icefang: ["8M"],
			icepunch: ["8M", "7L1"],
			iceshard: ["8L15", "7L5", "4L8"],
			iciclecrash: ["8L45", "7L39"],
			icywind: ["8M", "8L20", "7T", "7L19", "4T", "4L18"],
			leechlife: ["8M", "7M", "4L5"],
			leer: ["8L1", "7L1", "4L1"],
			naturalgift: ["4M"],
			pinmissile: ["8M", "7E", "4E"],
			protect: ["8M", "7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			round: ["8M", "7M"],
			screech: ["8M"],
			secretpower: ["7M", "4M"],
			sheercold: ["8L55", "5L55", "4L49"],
			signalbeam: ["7T", "7E"],
			silverwind: ["4M"],
			skittersmack: ["8T"],
			slash: ["8L35", "7L28"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "7T", "4T"],
			spikes: ["8M", "7E", "4E"],
			strength: ["6M", "5M"],
			stringshot: ["8E", "4T"],
			strugglebug: ["8E", "7M"],
			substitute: ["8M", "7M", "4M"],
			superpower: ["8M", "7T", "7E", "4T", "4E"],
			swagger: ["7M", "4M"],
			swift: ["8M"],
			swordsdance: ["8M", "7M", "4M"],
			tailglow: ["7E", "4E"],
			taunt: ["8M", "7M", "4M"],
			toxic: ["7M", "4M"],
			uturn: ["8M", "7M", "4M"],
			waterpulse: ["6T", "4M"],
			xscissor: ["8M", "8L40", "7M", "7L31", "4M", "4L23"],
		},
	},
	embirch: {
		learnset: {
			amnesia: ["8M", "7L37"],
			aromatherapy: ["7E", "4E"],
			attract: ["8M", "7M", "4M"],
			block: ["8E", "7T", "4T"],
			bulldoze: ["8M"],
			bulletseed: ["8M", "8L1", "7L1", "4M", "4L1"],
			confide: ["7M"],
			counter: ["8E", "7E", "4T", "4E"],
			doubleedge: ["8L40", "7L1", "7E", "4T", "4L33"],
			doubleteam: ["7M", "4M"],
			dragonbreath: ["8E", "7E", "4E"],
			dragondance: ["8M"],
			earthpower: ["8M", "7T"],
			ember: ["8L4", "7L5", "4L9"],
			endure: ["8M", "4M"],
			energyball: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			fireblast: ["8M"],
			firespin: ["8M", "7E", "4L25"],
			flamecharge: ["7M"],
			flamethrower: ["8M", "7M", "4M"],
			flamewheel: ["8L16", "7L19", "4L17"],
			flareblitz: ["8M"],
			flash: ["4M"],
			frustration: ["7M", "4M"],
			gigadrain: ["8M", "8L20", "7T", "7L32", "4M", "4L21"],
			grassknot: ["8M", "7M", "4M"],
			grasswhistle: ["7E", "4E"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7E"],
			growth: ["8L12", "7L28", "4L5"],
			headbutt: ["4T"],
			heatwave: ["8M", "7T", "4T"],
			hiddenpower: ["7M", "4M"],
			incinerate: ["7M"],
			irondefense: ["8M", "7T", "7L37", "4T"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T", "4M"],
			lavaplume: ["8L32", "7L41", "4L40"],
			leechseed: ["8L8", "7L10", "4L13"],
			lightscreen: ["8M", "7M", "4M"],
			magicalleaf: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["8E", "7M"],
			overheat: ["8M", "7M", "4M"],
			petaldance: ["8L36", "7L46", "4L29"],
			protect: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			revenge: ["8M", "7E", "4E"],
			roar: ["7M", "4M"],
			rockclimb: ["4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["4M"],
			rocktomb: ["8M", "7M", "4M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "4M"],
			sandtomb: ["8M", "7L23", "7E", "4E"],
			secretpower: ["4M"],
			seedbomb: ["8M", "8L28", "7T", "4T"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "7T", "4T"],
			solarbeam: ["8M", "7M", "4M"],
			stealthrock: ["8M", "7T", "4M"],
			strength: ["4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			sweetscent: ["8L1", "7L1", "4L1"],
			swift: ["8M"],
			swordsdance: ["8M", "7M", "4M"],
			synthesis: ["8L25", "7T", "7L14", "4T", "4L37"],
			terrainpulse: ["8T"],
			toxic: ["7M", "4M"],
			watersport: ["7E", "4E"],
			wildcharge: ["8M"],
			willowisp: ["8M", "7M", "4M"],
			worryseed: ["7T", "4T"],
			zenheadbutt: ["8M"],
		},
	},
	flarelm: {
		learnset: {
			amnesia: ["8M", "8L39", "7L37"],
			ancientpower: ["4T"],
			attract: ["8M", "7M", "4M"],
			block: ["7T", "4T"],
			bulldoze: ["8M", "7M"],
			bulletseed: ["8M", "8L1", "7L1", "4M", "4L1"],
			burningjealousy: ["8T"],
			confide: ["7M"],
			counter: ["4T"],
			doubleedge: ["8L56", "7L1", "7E", "4T"],
			doubleteam: ["7M", "4M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "7T"],
			dragontail: ["7M"],
			earthpower: ["8M", "7T", "4T"],
			earthquake: ["8M", "7M", "4M"],
			ember: ["8L1", "7L5", "4L9"],
			endure: ["8M", "4M"],
			energyball: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			fireblast: ["8M", "7M", "4M"],
			firespin: ["8M", "7E", "4L28"],
			flameburst: ["7L24"],
			flamecharge: ["8L1", "7M"],
			flamethrower: ["8M", "7M", "4M"],
			flamewheel: ["8L16", "7L19", "4L17"],
			flareblitz: ["8M"],
			flash: ["4M"],
			flashcannon: ["8M", "7M"],
			frustration: ["7M", "4M"],
			gigadrain: ["8M", "8L20", "7L32", "4M", "4L21"],
			grassknot: ["8M", "7M", "4M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			growth: ["8L12", "7L28", "4L5"],
			headbutt: ["4T"],
			heatwave: ["8M", "7T", "4T"],
			hiddenpower: ["7M", "4M"],
			incinerate: ["7M"],
			irondefense: ["8M", "8L39", "7L37", "4T", "4L40"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T", "4M"],
			lavaplume: ["8L44", "7L46", "4L48"],
			leechseed: ["8L1", "7L10", "4L13"],
			lightscreen: ["8M", "7M", "4M"],
			lowkick: ["8M", "7T", "4T"],
			magicalleaf: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			naturepower: ["7M"],
			overheat: ["8M", "7M", "4M"],
			petaldance: ["8L50", "7L50", "4L36"],
			protect: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			revenge: ["8M"],
			roar: ["7M", "4M"],
			rockclimb: ["4M"],
			rockpolish: ["7M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "4M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "4M"],
			sandtomb: ["8M"],
			secretpower: ["7M", "4M"],
			seedbomb: ["8M", "8L32", "7L23", "4T"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "7T", "4T"],
			solarbeam: ["8M", "7M", "4M"],
			stealthrock: ["8M", "7T", "4M"],
			strength: ["6M", "5M", "4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			sweetscent: ["8L1", "7L1", "4L1"],
			swift: ["8M"],
			swordsdance: ["8M", "7M", "4M"],
			synthesis: ["8L20", "7L14", "4T", "4L44"],
			terrainpulse: ["8T"],
			toxic: ["7M", "4M"],
			wildcharge: ["8M", "7M"],
			willowisp: ["8M", "7M", "4M"],
			worryseed: ["7T", "4T"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	breezi: {
		learnset: {
			acrobatics: ["8M", "8L8", "7M", "7L59"],
			aerialace: ["8L16", "7M", "7L30", "4M", "4L55"],
			afteryou: ["7T"],
			attract: ["8M", "7M", "4M"],
			block: ["7T", "4T"],
			bodyslam: ["8M", "4T", "4L30"],
			bounce: ["8M"],
			captivate: ["4M"],
			confide: ["7M"],
			copycat: ["8E", "7L19", "4L19"],
			disable: ["8E", "7E", "4E"],
			doubleedge: ["8E", "7E", "4T"],
			doubleteam: ["7M", "4M"],
			encore: ["8M", "8L12", "7L5", "4L5"],
			endure: ["8M", "4M"],
			entrainment: ["8E", "7E"],
			facade: ["8M", "7M", "4M"],
			fling: ["8M", "7M", "4M"],
			followme: ["8L28", "7E", "4E"],
			frustration: ["7M", "4M"],
			gastroacid: ["7T", "4T"],
			gunkshot: ["8M", "8L52"],
			gust: ["8L1", "7L1", "4L1"],
			healblock: ["7L54", "4L50"],
			helpinghand: ["8M", "8L1", "7T", "7L1", "4T", "4L1"],
			hiddenpower: ["7M", "4M"],
			icywind: ["8M", "7T", "4T"],
			knockoff: ["8L24", "7L14", "4T", "4L14"],
			lightscreen: ["8M", "7M", "4M"],
			luckychant: ["7L55", "4L59"],
			magicroom: ["8M"],
			mefirst: ["7E", "4E"],
			metronome: ["8M", "7E", "4T", "4E"],
			mimic: ["7E", "5E"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			poisonjab: ["8M", "7M", "4M"],
			protect: ["8M", "7M", "4M"],
			psychup: ["7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			reflect: ["8M", "7M", "4M"],
			rest: ["8M", "8L48", "7M", "7L44", "4M", "4L44"],
			return: ["7M", "4M"],
			roleplay: ["7T"],
			round: ["8M", "7M"],
			safeguard: ["8M", "8L44", "7M", "7L9", "4M", "4L9"],
			sandstorm: ["8M", "7M", "4M"],
			sandtomb: ["8M", "7E", "4E"],
			secretpower: ["7M", "4M"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "7M", "4M"],
			skillswap: ["8M", "7M", "4M"],
			sleeptalk: ["8M", "7M", "4M"],
			sludgebomb: ["8M", "8L36", "7M", "7L34", "4M", "4L34"],
			sludgewave: ["8M"],
			snatch: ["7M", "4M"],
			snore: ["8M", "7T", "4T"],
			speedswap: ["8M"],
			spikes: ["8M", "7E", "4E"],
			stealthrock: ["8M", "7T"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			swift: ["8M", "4T"],
			tailwind: ["8L4", "7L1", "4T", "4L1"],
			taunt: ["8M", "7M"],
			thief: ["8M", "7M"],
			toxic: ["8L40", "7M", "4M"],
			toxicspikes: ["8M", "8L20", "7L39", "4L39"],
			trickroom: ["8M", "7M", "4M"],
			twister: ["4T"],
			uturn: ["8M", "7M", "4M"],
			venoshock: ["8M", "7M"],
			whirlwind: ["8L32", "7L25", "4L25"],
			wish: ["8E", "7E", "4E"],
			wonderroom: ["8M"],
		},
	},
	scratchet: {
		learnset: {
			aerialace: ["7M", "5M"],
			attract: ["8M", "7M", "5M"],
			batonpass: ["8M", "7E", "5E"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "7E", "5M"],
			bulkup: ["8M", "8L36", "7M", "7L40", "5M", "5L40"],
			bulldoze: ["8M", "7M", "5M"],
			closecombat: ["8M"],
			coaching: ["8T"],
			confide: ["7M"],
			confuseray: ["8E", "7M", "7E", "5E"],
			doubleteam: ["7M", "5M"],
			echoedvoice: ["7M", "5M"],
			endure: ["8M"],
			facade: ["8M", "7M", "5M"],
			falseswipe: ["8M", "7M", "5M"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "5M"],
			focusblast: ["8M", "7M", "5M"],
			focusenergy: ["8M", "8L6", "7L13", "7E", "5L13", "5E"],
			frustration: ["7M", "7E", "5M"],
			furyswipes: ["8L9", "7L18", "5L1"],
			grassknot: ["8M", "7M", "5M"],
			harden: ["8L1", "7L4", "5L9"],
			haze: ["8E", "7E", "5E"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M", "5M"],
			hypervoice: ["8M", "8L33", "7L36", "5L36"],
			irontail: ["8M", "7T"],
			megakick: ["8M"],
			megapunch: ["8M"],
			memento: ["8E", "7E", "5E"],
			naturepower: ["8E", "7M", "7E", "5E"],
			poweruppunch: ["8L3", "6L99"],
			protect: ["8M", "7M", "5M"],
			quash: ["8E", "7M", "5M"],
			raindance: ["8M", "7M", "5M"],
			rapidspin: ["8E", "7E", "5E"],
			rest: ["8M", "8L24", "7M", "7L53", "5M", "5L53"],
			retaliate: ["8M", "8L21", "7L57", "6M", "5M", "5L57"],
			return: ["7M", "5M"],
			revenge: ["8M"],
			reversal: ["8M"],
			roar: ["8L18", "7M", "7L23", "7E", "5M", "5L23", "5E"],
			rockslide: ["8M", "7M", "5M"],
			rocksmash: ["8L12", "7T", "7L9", "6M", "5M", "5L18"],
			rocktomb: ["8M", "7M", "5M"],
			roost: ["7M", "7E", "5E"],
			round: ["8M", "7M", "5M"],
			safeguard: ["8M", "7M", "5M"],
			scratch: ["8L1", "7L1"],
			secretpower: ["7M"],
			sleeptalk: ["8M"],
			snore: ["8M", "7T"],
			stealthrock: ["8M", "7T", "7E", "5E"],
			strength: ["6M", "5M"],
			submission: ["8L30", "7L32", "5L32"],
			substitute: ["8M", "7M", "5M"],
			sunnyday: ["8M", "7M", "5M"],
			superpower: ["8M", "8L39", "7L45", "5L45"],
			swagger: ["7M", "5M"],
			taunt: ["8M", "8L15", "7M", "7L49", "5M", "5L49"],
			thief: ["8M", "7M", "5M"],
			throatchop: ["8M"],
			toxic: ["7M", "5M"],
			workup: ["8M", "8L27", "7M", "7L27", "5M", "5L27"],
			yawn: ["8E", "7E", "5E"],
		},
	},
	necturine: {
		learnset: {
			attract: ["8M", "7M", "5M"],
			calmmind: ["8M", "7M", "5M"],
			confide: ["7M"],
			curse: ["8E", "7E", "5E"],
			cut: ["7M", "5M"],
			darkpulse: ["8M", "7M"],
			doubleteam: ["7M", "5M"],
			dreameater: ["7M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "5M"],
			facade: ["8M", "7M", "5M"],
			flash: ["6M", "5M"],
			frustration: ["7M", "5M"],
			futuresight: ["8M", "7E", "5E"],
			gigadrain: ["8M", "7E", "6T", "5E"],
			grassknot: ["8M", "7M", "5M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "8L32", "7L22"],
			gravity: ["8E", "7E", "6T", "5E"],
			hex: ["8M", "8L16", "7L18", "5L25"],
			hiddenpower: ["7M", "5M"],
			ingrain: ["8E", "7E", "5E"],
			leafblade: ["8M", "7E", "5E"],
			leafstorm: ["8M", "7E", "5E"],
			leechlife: ["8M", "7M"],
			leechseed: ["8L8", "7L8"],
			leer: ["8L1", "7L1", "5L1"],
			magicalleaf: ["8M", "8L12", "7L11"],
			naturalgift: ["7E", "5L31", "5E"],
			naturepower: ["7M"],
			nightmare: ["7E", "5E"],
			nightshade: ["8L28", "7L26"],
			ominouswind: ["7E", "5L7"],
			painsplit: ["8L40", "7L34", "6T", "5L37"],
			payback: ["8M", "7M", "5M"],
			powerwhip: ["8M", "8L44", "7L50", "5L50"],
			protect: ["8M", "7M", "5M"],
			psychic: ["8M", "7M", "5M"],
			psychup: ["7M", "5M"],
			rest: ["8M", "7M", "5M"],
			return: ["7M", "5M"],
			round: ["8M", "7M", "5M"],
			secretpower: ["7M"],
			seedbomb: ["8M", "8L24", "7T", "7L39"],
			shadowball: ["8M", "8L36", "7M", "7L43", "5M", "5L44"],
			shadowsneak: ["8L4", "7L4", "5L13"],
			sketch: ["7E", "5E"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M", "5M"],
			spite: ["7T"],
			substitute: ["8M", "7M", "5M"],
			sunnyday: ["8M", "7M", "5M"],
			swagger: ["7M", "5M"],
			telekinesis: ["6M", "5M"],
			thief: ["8M", "7M", "5M"],
			torment: ["7M", "5M"],
			toxic: ["7M", "5M"],
			toxicspikes: ["8M", "8L20", "7L23", "5L19"],
			vinewhip: ["8L1", "7L1", "5L1"],
			willowisp: ["8M", "8L20", "7M", "7L15", "5M", "5L19"],
			worryseed: ["7T"],
		},
	},
	cupra: {
		learnset: {
			allyswitch: ["8M", "5M"],
			ancientpower: ["8L21", "7L44", "5L44"],
			attract: ["8M", "7M", "5M"],
			bugbite: ["8L15", "7T", "7L7", "5T", "5L7"],
			bugbuzz: ["8M", "7E", "5E"],
			closecombat: ["8M", "7E", "5E"],
			confide: ["7M"],
			counter: ["8E", "7E", "5E"],
			cut: ["6M", "5M"],
			disable: ["7E", "5E"],
			doubleteam: ["8L9", "7M", "5M"],
			dreameater: ["7M", "5M"],
			echoedvoice: ["7M", "5M"],
			electroweb: ["8M", "7T", "5T"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "5M"],
			feint: ["8E", "7E", "5E"],
			finalgambit: ["8L39", "7L38", "5L38"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "5M"],
			frustration: ["7M", "5M"],
			hail: ["8M", "7M", "5M"],
			healpulse: ["8L18", "7L21", "5L21"],
			helpinghand: ["8M", "7T", "5T"],
			hiddenpower: ["7M", "5M"],
			hydropump: ["8M", "7E", "5E"],
			icywind: ["8M", "7T", "5T"],
			imprison: ["8M", "8L12"],
			infestation: ["7M"],
			lightscreen: ["8M", "7M", "5M"],
			magiccoat: ["7T", "5T"],
			magicroom: ["8M", "7T", "5T"],
			megahorn: ["8M", "7E", "5E"],
			nastyplot: ["8M"],
			protect: ["8M", "7M", "5M"],
			psychic: ["8M", "8L36", "7M", "5M"],
			psychicterrain: ["8M"],
			psychup: ["7M", "5M"],
			psyshock: ["8M", "7M", "5M"],
			raindance: ["8M", "7M", "5M"],
			recycle: ["7T", "5T"],
			reflect: ["8M", "7M", "5M"],
			rest: ["8M", "7M", "5M"],
			retaliate: ["8M", "5M"],
			return: ["7M", "5M"],
			roleplay: ["7T", "5T"],
			round: ["8M", "7M", "5M"],
			safeguard: ["8M", "7M", "7E", "5M", "5E"],
			secretpower: ["7M"],
			shadowball: ["8M", "7M", "5M"],
			shockwave: ["7M"],
			signalbeam: ["7T"],
			skillswap: ["8M", "7T", "5T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			steelwing: ["8M", "7M"],
			stringshot: ["8L1", "7L1", "5L1"],
			strugglebug: ["8L6", "7L27", "5M"],
			substitute: ["8M", "7M", "5M"],
			sunnyday: ["8M", "8L3", "7M", "7L14", "5M", "5L14"],
			swagger: ["7M", "5M"],
			tackle: ["8L1", "7L1", "5L1"],
			tailglow: ["7L60"],
			telekinesis: ["5M"],
			toxic: ["7M", "5M"],
			trick: ["8M", "7T", "5T"],
			waterpulse: ["7T"],
			willowisp: ["8M", "8L24", "7M", "7L32", "5M", "5L32"],
			wingattack: ["7E", "5E"],
			wish: ["8L31", "7L48", "5L48"],
			wonderroom: ["8M", "7T", "5T"],
			xscissor: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "8L27", "7T", "5T", "5L54"],
		},
	},
	argalis: {
		learnset: {
			allyswitch: ["8M", "5M"],
			ancientpower: ["8L21", "7L47", "5L47"],
			attract: ["8M", "7M", "5M"],
			bugbite: ["8L15", "7T", "7L7", "5T", "5L7"],
			bugbuzz: ["8M"],
			closecombat: ["8M"],
			confide: ["7M"],
			cut: ["6M", "5M"],
			doubleteam: ["8L9", "7M", "5M"],
			dreameater: ["7M", "5M"],
			echoedvoice: ["7M", "5M"],
			electroweb: ["8M", "7T", "5T"],
			expandingforce: ["8T"],
			facade: ["8M", "7M", "5M"],
			finalgambit: ["8L52", "7L41", "5L41"],
			flash: ["6M", "5M"],
			fling: ["8M", "7M", "5M"],
			frustration: ["7M", "5M"],
			hail: ["8M", "7M", "5M"],
			healpulse: ["8L18", "7L21", "5L21"],
			helpinghand: ["8M", "7T", "5T"],
			hiddenpower: ["7M", "5M"],
			hydropump: ["8M"],
			icywind: ["8M", "7T", "5T"],
			imprison: ["8M", "8L12"],
			infestation: ["7M"],
			lightscreen: ["8M", "8L37", "7M", "7L57", "5M"],
			magiccoat: ["7T", "5T"],
			magicroom: ["8M", "7T", "5T"],
			megahorn: ["8M"],
			nastyplot: ["8M", "8L42"],
			ominouswind: ["7L27", "5L27"],
			protect: ["8M", "7M", "5M"],
			psychic: ["8M", "8L47", "7M", "7L62", "5M"],
			psychicterrain: ["8M"],
			psychup: ["7M", "5M"],
			psyshock: ["8M", "7M", "5M"],
			raindance: ["8M", "7M", "5M"],
			recycle: ["7T", "5T"],
			reflect: ["8M", "8L37", "7M", "7L57", "5M"],
			rest: ["8M", "7M", "5M"],
			retaliate: ["8M", "5M"],
			return: ["7M", "5M"],
			roleplay: ["7T", "5T"],
			round: ["8M", "7M", "5M"],
			safeguard: ["8M", "7M", "5M"],
			secretpower: ["7M"],
			shadowball: ["8M", "7M", "5M"],
			shockwave: ["7M"],
			signalbeam: ["7T"],
			skillswap: ["8M", "7T", "5T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spotlight: ["7L1"],
			steelwing: ["8M", "7M"],
			stringshot: ["8L1", "7L1", "5L1"],
			strugglebug: ["8L1", "5M"],
			substitute: ["8M", "7M", "5M"],
			sunnyday: ["8M", "8L1", "7M", "7L14", "5M", "5L14"],
			swagger: ["7M", "5M"],
			tackle: ["8L1", "7L1", "5L1"],
			tailglow: ["7L65"],
			telekinesis: ["5M"],
			toxic: ["7M", "5M"],
			trick: ["8M", "7T", "5T"],
			waterpulse: ["7T"],
			willowisp: ["8M", "8L24", "7M", "7L34", "5M", "5L34"],
			wish: ["8L33", "7L54", "5L54"],
			wonderroom: ["8M", "7T", "5T"],
			xscissor: ["8M", "7M", "5M"],
			zenheadbutt: ["8M", "8L27", "7T", "5T"],
		},
	},
	brattler: {
		learnset: {
			aromatherapy: ["8E", "7E", "5E"],
			attract: ["8M", "7M", "5M"],
			beatup: ["8M", "7E", "5E"],
			bind: ["7T", "5T"],
			brutalswing: ["8M", "7M"],
			confide: ["7M"],
			crunch: ["8M", "8L36", "7L43", "5L39"],
			cut: ["6M", "5M"],
			darkpulse: ["8M", "7M", "5T"],
			doubleteam: ["7M", "5M"],
			dragontail: ["7M", "5M"],
			endure: ["8M"],
			energyball: ["8M", "7M", "5M"],
			facade: ["8M", "7M", "5M"],
			feint: ["8E", "7E"],
			foulplay: ["8M", "8L44", "7T", "5T"],
			frustration: ["7M", "5M"],
			gigadrain: ["8M", "7T", "5T"],
			glare: ["8L32", "7L1", "7E", "5E"],
			grassknot: ["8M", "8L16", "7M", "7L15", "5M", "5L18"],
			grassyglide: ["8T"],
			haze: ["8E", "7E", "5E"],
			healbell: ["7T", "5T"],
			hiddenpower: ["7M", "5M"],
			icefang: ["8M", "7E"],
			irontail: ["8M", "7T", "5T"],
			knockoff: ["7T", "5T"],
			lashout: ["8T"],
			leafblade: ["8M", "8L28", "7L26", "5L34"],
			leer: ["8L1", "7L1"],
			nastyplot: ["8M"],
			naturepower: ["7M"],
			nightslash: ["8E", "7E", "5E"],
			partingshot: ["8L48"],
			payback: ["8M", "8L8", "7M", "5M"],
			poisonpowder: ["8E", "7E", "5E"],
			powerwhip: ["8M", "7L44", "5L50"],
			protect: ["8M", "7M", "5M"],
			punishment: ["7L55", "5L55"],
			pursuit: ["7L8", "5L1"],
			recycle: ["7T"],
			rest: ["8M", "7M", "5M"],
			retaliate: ["8M", "7M", "5M"],
			return: ["7M", "5M"],
			roar: ["7M", "5M"],
			round: ["8M", "7M", "5M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L12", "7L11", "7E", "5E"],
			screech: ["8M", "7E", "5E"],
			secretpower: ["7M"],
			seedbomb: ["8M", "7T", "5T"],
			skittersmack: ["8T"],
			slam: ["8L24", "7L22", "5L30"],
			sleeptalk: ["8M", "7M", "5T"],
			snarl: ["8M", "7M", "5M"],
			snore: ["8M", "7T", "5T"],
			solarbeam: ["8M", "7M", "5M"],
			spikyshield: ["8L40", "7L40"],
			spite: ["7T", "5T"],
			strength: ["6M", "5M"],
			stunspore: ["8E", "7E", "5E"],
			substitute: ["8M", "7M", "5M"],
			suckerpunch: ["8L20", "7L18", "5L25"],
			sunnyday: ["8M", "7M", "5M"],
			swagger: ["7M", "5M"],
			sweetscent: ["8E", "7E", "5E"],
			synthesis: ["7T", "5T"],
			taunt: ["8M", "7M", "5M"],
			thief: ["8M", "7M", "5M"],
			thunderfang: ["8M", "7E"],
			toxic: ["7M", "5M"],
			uturn: ["8M", "7M", "5M"],
			vinewhip: ["8L4", "7L5", "5L1"],
			weatherball: ["8M"],
			wildcharge: ["8M", "7M", "5M"],
			worryseed: ["7T", "5T"],
			wrap: ["8L1", "7L1", "5L1"],
			wringout: ["7L49", "5L44"],
		},
	},
	cawdet: {
		learnset: {
			acrobatics: ["8M", "5M"],
			aerialace: ["8E", "5M"],
			airslash: ["8M", "7E", "5E"],
			assurance: ["8M"],
			attract: ["8M", "5M"],
			beatup: ["8M"],
			block: ["5T"],
			brickbreak: ["8M", "5M"],
			brine: ["8M", "8L28", "5L42"],
			bulletpunch: ["8L8", "5L38"],
			confide: ["7M"],
			detect: ["8L32", "5L26"],
			doubleteam: ["5M"],
			drainpunch: ["8M", "5T"],
			drillpeck: ["8E", "7E", "5E"],
			endeavor: ["5T"],
			endure: ["8M"],
			facade: ["8M", "5M"],
			flashcannon: ["8M", "8L44", "5M", "5L49"],
			fly: ["8M", "5M"],
			frustration: ["5M"],
			growl: ["8L4", "5L5"],
			hiddenpower: ["5M"],
			hurricane: ["8M", "8L48", "5L53"],
			irondefense: ["8M"],
			ironhead: ["8M", "5T"],
			knockoff: ["5T"],
			leer: ["8L1", "5L1"],
			metalclaw: ["8L12", "5L13"],
			metalsound: ["8E", "7E", "5E"],
			metronome: ["8M"],
			mirrormove: ["7E", "5E"],
			peck: ["8L1", "5L1"],
			pluck: ["5M"],
			protect: ["8M", "5M"],
			psychup: ["5M"],
			pursuit: ["7E", "5E"],
			quickattack: ["8E", "7E", "5E"],
			quickguard: ["8E", "7E", "5E"],
			raindance: ["8M", "5M"],
			razorwind: ["7E", "5E"],
			rest: ["8M", "5M"],
			retaliate: ["8M", "5M"],
			return: ["5M"],
			rocksmash: ["5M"],
			round: ["8M", "5M"],
			screech: ["8M", "8L16", "5L18"],
			shockwave: ["7T"],
			skyattack: ["5T"],
			skydrop: ["5M"],
			sleeptalk: ["8M", "5T"],
			smackdown: ["5M"],
			snatch: ["5T"],
			snore: ["8M", "5T"],
			steelbeam: ["8T"],
			steelwing: ["8M", "8L36", "5L31"],
			strength: ["5M"],
			substitute: ["8M", "5M"],
			surf: ["8M", "5M"],
			swagger: ["5M"],
			swift: ["8M", "8L20", "5L9"],
			tailwind: ["8L40", "5T", "5L45"],
			toxic: ["5M"],
			waterpulse: ["7T"],
			watersport: ["7E", "5E"],
			whirlpool: ["8M"],
			wingattack: ["8L24", "5L22"],
		},
	},
	volkritter: {
		learnset: {
			absorb: ["8L1"],
			aquajet: ["8E", "7E"],
			aquaring: ["8L32", "6L44"],
			assurance: ["8M", "7E", "6E"],
			attract: ["8M", "6M"],
			bind: ["8L1"],
			bite: ["8L24", "6L1"],
			bounce: ["8M", "7T"],
			captivate: ["7E", "6E"],
			confide: ["6M"],
			constrict: ["6L1"],
			covet: ["7T"],
			destinybond: ["8L44", "6L32"],
			dive: ["8M", "8L28", "6L36"],
			doubleteam: ["6M"],
			facade: ["8M", "6M"],
			falseswipe: ["8M", "6M"],
			fireblast: ["8M", "6M"],
			firespin: ["8M", "8L8", "6L18"],
			flameburst: ["6L23"],
			flamethrower: ["8M", "6M"],
			flash: ["6M", "6L1"],
			flashcannon: ["8M", "6M"],
			fling: ["8M", "6M"],
			flipturn: ["8T"],
			frustration: ["6M"],
			heatwave: ["8M", "6L48"],
			hiddenpower: ["6M"],
			hydropump: ["8M", "8L48", "6L51"],
			incinerate: ["8L20", "6M"],
			infestation: ["7M"],
			leechlife: ["8M", "6L1"],
			memento: ["8L52", "6L59"],
			muddywater: ["8M"],
			overheat: ["8M", "6M"],
			payback: ["8M", "6M"],
			powergem: ["8M", "8L36", "6L39"],
			protect: ["8M", "6M"],
			quash: ["6M"],
			raindance: ["8M", "6M"],
			reflect: ["8M", "8L12", "6M", "6L4"],
			reflecttype: ["8E", "7E"],
			rest: ["8M", "6M"],
			return: ["6M"],
			round: ["8M", "6M"],
			scald: ["8M", "8L40", "6M", "6L28"],
			scaryface: ["8M", "7E", "6E"],
			scorchingsands: ["8T"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "6M"],
			snore: ["8M"],
			substitute: ["8M", "6M"],
			sunnyday: ["8M", "6M"],
			surf: ["8M", "6M"],
			swagger: ["6M"],
			thief: ["8M", "6M"],
			tickle: ["8E", "7E", "6E"],
			torment: ["6M"],
			toxic: ["6M"],
			uturn: ["8M", "6M"],
			waterfall: ["8M", "6M"],
			watergun: ["8L4", "6L1"],
			waterpulse: ["8E", "7E", "6E"],
			whirlpool: ["8M", "8L16", "6L14"],
			willowisp: ["8M", "6M"],
		},
	},
	snugglow: {
		learnset: {
			acid: ["8L8", "6L12"],
			aquatail: ["8L44", "6L32"],
			attract: ["8M", "6M"],
			aurasphere: ["8M", "7E"],
			block: ["7T"],
			clearsmog: ["8L28", "6L22"],
			confide: ["6M"],
			crosspoison: ["8M"],
			cut: ["6M"],
			dazzlinggleam: ["8M", "6M"],
			discharge: ["8L36", "6L42"],
			doubleteam: ["6M"],
			eerieimpulse: ["8M", "6L49"],
			electrify: ["8L40"],
			electroball: ["8M"],
			electroweb: ["8M"],
			encore: ["8M", "8L12", "6L16"],
			endure: ["8M"],
			facade: ["8M", "6M"],
			flashcannon: ["8M", "6M"],
			frustration: ["6M"],
			haze: ["8E", "7E", "6E"],
			hiddenpower: ["6M"],
			iondeluge: ["6L26"],
			irontail: ["8M"],
			magnetrise: ["7T"],
			paraboliccharge: ["8L24", "6L36"],
			poisonjab: ["8M"],
			poisonsting: ["8L1", "6L6"],
			poisontail: ["8L20", "6L29"],
			protect: ["8M", "6M"],
			psybeam: ["8L32"],
			psychic: ["8M", "6M"],
			psyshock: ["8M", "6M"],
			psywave: ["6L39"],
			raindance: ["8M", "6M"],
			rest: ["8M", "6M"],
			return: ["6M"],
			risingvoltage: ["8T"],
			round: ["8M", "6M"],
			shockwave: ["7E", "6E"],
			signalbeam: ["7E", "6E"],
			sleeptalk: ["8M", "6M"],
			sludgebomb: ["8M", "6M"],
			sludgewave: ["8M", "6M"],
			snore: ["8M"],
			splash: ["8E", "7E", "6E"],
			substitute: ["8M", "6M"],
			supersonic: ["8L4", "6L1"],
			swagger: ["6M"],
			taunt: ["8M", "6M"],
			thunder: ["8M", "8L48", "6M", "6L46"],
			thunderbolt: ["8M", "6M"],
			thundershock: ["8L1", "6L1"],
			thunderwave: ["8M", "8L16", "6M", "6L19"],
			toxic: ["8E", "6M"],
			venomdrench: ["8M", "7E"],
			venoshock: ["8M", "6M"],
			waterpulse: ["8E", "7E", "6E"],
			wideguard: ["8E", "7E", "6E"],
			wildcharge: ["8M", "6M"],
			zenheadbutt: ["8M"],
		},
	},
	floatoy: {
		learnset: {
			attract: ["8M", "6M"],
			bite: ["8E", "7E", "6E"],
			blizzard: ["8M", "6M"],
			bodyslam: ["8M"],
			brine: ["8M", "8L24", "6L38"],
			brutalswing: ["8M"],
			bubblebeam: ["8L22", "6L23"],
			calmmind: ["8M", "6M"],
			confide: ["6M"],
			crunch: ["8M"],
			dive: ["8M", "6M"],
			doubleteam: ["6M"],
			dragonbreath: ["8L18", "6L1"],
			dragonclaw: ["8M", "6M"],
			dragondance: ["8M", "7E", "6E"],
			dragonpulse: ["8M", "6T"],
			drillpeck: ["8L30", "6L27"],
			echoedvoice: ["6M"],
			endure: ["8M"],
			facade: ["8M", "6M"],
			featherdance: ["8E", "7E", "6E"],
			feint: ["8E", "7E", "6E"],
			focusenergy: ["8M"],
			frustration: ["6M"],
			gust: ["8L6", "6L1"],
			hail: ["8M", "6M"],
			haze: ["8E", "7E", "6E"],
			hiddenpower: ["6M"],
			hurricane: ["8M"],
			hydropump: ["8M", "8L39", "6L50"],
			icebeam: ["8M", "6M"],
			icefang: ["8M", "7E", "6E"],
			icepunch: ["8M", "6T"],
			iciclecrash: ["8L36", "6L42"],
			iciclespear: ["8M", "7E", "6E"],
			icywind: ["8M", "6T"],
			ironhead: ["8M", "8L33", "6T", "6L53"],
			irontail: ["8M", "7E", "6T", "6E"],
			metalclaw: ["8L9", "6L11"],
			metronome: ["8M", "7E", "6E"],
			muddywater: ["8M"],
			peck: ["8L1", "6L1"],
			protect: ["8M", "8L42", "6M"],
			psychicfangs: ["8M", "7E"],
			raindance: ["8M", "6M"],
			refresh: ["7E", "6E"],
			rest: ["8M", "6M"],
			return: ["6M"],
			rocksmash: ["6M"],
			round: ["8M", "6M"],
			scald: ["8M", "6M"],
			scaryface: ["8M", "8L15", "6L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			slackoff: ["8L27", "6L39"],
			sleeptalk: ["8M", "6M"],
			snore: ["8M", "6T"],
			splash: ["8L1", "6L1"],
			substitute: ["8M", "6M"],
			surf: ["8M", "6M"],
			swagger: ["6M"],
			taunt: ["8M", "6M"],
			thunderfang: ["8M", "7E", "6E"],
			toxic: ["6M"],
			waterfall: ["8M", "6M"],
			watergun: ["8L3", "6L7"],
			waterpulse: ["8L12", "7E", "6T", "6E"],
			whirlpool: ["8M", "7E", "6E"],
		},
	},
	caimanoe: {
		learnset: {
			attract: ["8M", "6M"],
			blizzard: ["8M", "6M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			brine: ["8M", "8L26", "6L43"],
			brutalswing: ["8M"],
			bubblebeam: ["8L1", "6L25"],
			calmmind: ["8M", "6M"],
			confide: ["6M"],
			crunch: ["8M"],
			dive: ["8M", "6M"],
			doubleteam: ["6M"],
			dragonbreath: ["8L18", "6L1"],
			dragonclaw: ["8M", "6M"],
			dragondance: ["8M"],
			dragonpulse: ["8M", "6T"],
			drillpeck: ["8L36", "6L31"],
			echoedvoice: ["6M"],
			endure: ["8M"],
			facade: ["8M", "6M"],
			flashcannon: ["8M", "6M", "6L35"],
			focusenergy: ["8M"],
			frustration: ["6M"],
			gust: ["8L1", "6L1"],
			hail: ["8M", "6M"],
			heavyslam: ["8M"],
			hiddenpower: ["6M"],
			hurricane: ["8M"],
			hydropump: ["8M", "8L51", "6L60"],
			icebeam: ["8M", "6M"],
			icefang: ["8M"],
			icepunch: ["8M", "6T"],
			iciclecrash: ["8L46", "6L47"],
			iciclespear: ["8M"],
			icywind: ["8M", "6T"],
			irondefense: ["8M", "6T"],
			ironhead: ["8M", "8L41", "6T", "6L58"],
			irontail: ["8M", "6T"],
			metalclaw: ["8L9", "6L11"],
			metalsound: ["8L1", "6L21"],
			metronome: ["8M"],
			muddywater: ["8M"],
			peck: ["8L1", "6L1"],
			protect: ["8M", "8L56", "6M"],
			psychicfangs: ["8M"],
			raindance: ["8M", "6M"],
			rest: ["8M", "6M"],
			retaliate: ["8M", "6M"],
			return: ["6M"],
			rocksmash: ["6M"],
			round: ["8M", "6M"],
			scald: ["8M", "6M"],
			scaleshot: ["8T"],
			scaryface: ["8M", "8L15", "6L1"],
			screech: ["8M"],
			secretpower: ["6M"],
			selfdestruct: ["8M", "6L62"],
			slackoff: ["8L31", "6L39"],
			sleeptalk: ["8M", "6M"],
			snore: ["8M", "6T"],
			splash: ["8L1", "6L1"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			strength: ["6M"],
			substitute: ["8M", "6M"],
			surf: ["8M", "6M"],
			swagger: ["6M"],
			taunt: ["8M", "6M"],
			thunderfang: ["8M"],
			thunderpunch: ["8M", "6T"],
			toxic: ["6M"],
			waterfall: ["8M", "6M"],
			watergun: ["8L1", "6L7"],
			waterpulse: ["8L12", "6T"],
			whirlpool: ["8M"],
		},
	},
	pluffle: {
		learnset: {
			allyswitch: ["8M"],
			attract: ["8M", "6M"],
			beatup: ["8M"],
			bodyslam: ["8M"],
			charm: ["8M", "8L9", "7E", "6E"],
			confide: ["7T", "6L25"],
			dazzlinggleam: ["8M", "6M"],
			doubleteam: ["6M"],
			drainingkiss: ["8M", "8L12", "6L13"],
			dreameater: ["8L36", "6M", "6L42"],
			encore: ["8M", "8L15", "7E", "6L16", "6E"],
			endure: ["8M"],
			energyball: ["8M", "6M"],
			facade: ["8M", "6M"],
			fairywind: ["8L3", "6L1"],
			featherdance: ["8L1", "6L1"],
			flashcannon: ["8M", "6M"],
			frustration: ["6M"],
			gigadrain: ["8M", "6T"],
			grassknot: ["8M", "6M"],
			helpinghand: ["8M", "6T"],
			hiddenpower: ["6M"],
			magicroom: ["8M"],
			metronome: ["8M"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M"],
			moonblast: ["8L30", "6L36"],
			nightmare: ["6L42"],
			partingshot: ["8L21", "6L24"],
			playrough: ["8M", "8L24", "6L27"],
			protect: ["8M", "6M"],
			psychup: ["6M"],
			quickguard: ["8E", "7E", "6E"],
			rest: ["8M", "8L27", "6M", "6L31"],
			retaliate: ["8M", "6M"],
			return: ["6M"],
			round: ["8M", "6M"],
			scaryface: ["8M", "8L18", "6L20"],
			scratch: ["8L1", "6L1"],
			sleeptalk: ["8M", "6M"],
			sludgebomb: ["8M", "6M"],
			sludgewave: ["8M", "6M"],
			snarl: ["8M", "6M"],
			snore: ["8M", "8L27", "6T"],
			substitute: ["8M", "6M"],
			sunnyday: ["8M", "6M"],
			swagger: ["6M"],
			taunt: ["8M", "6M"],
			torment: ["8L33", "6M"],
			toxic: ["6M"],
			uproar: ["8M"],
			wakeupslap: ["6L10"],
			wideguard: ["8E", "7E", "6E"],
			wish: ["8E", "7E", "6E"],
			workup: ["8M"],
			yawn: ["8L6", "6L7"],
		},
	},
	rebble: {
		learnset: {
			accelerock: ["8L20", "7L21"],
			acupressure: ["8L40", "7L26", "4L20"],
			aerialace: ["7M", "4M"],
			ancientpower: ["8L29", "7L30", "4T"],
			bulldoze: ["8M", "7M"],
			calmmind: ["8M", "7M", "4M"],
			confide: ["7M"],
			cut: ["4M"],
			dazzlinggleam: ["8M", "7M"],
			defensecurl: ["8L1", "7L1", "4L1"],
			disable: ["8L16", "7L17", "4L17"],
			doubleedge: ["8L44", "7L41", "4L41"],
			doubleteam: ["7M", "4M"],
			earthpower: ["8M", "7T", "7L45", "4T"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			endure: ["8M", "4M"],
			energyball: ["8M", "7M", "4M"],
			explosion: ["7M", "4M"],
			facade: ["8M", "7M", "4M"],
			falseswipe: ["8M", "7M", "4M"],
			fireblast: ["8M", "7M", "4M"],
			flamethrower: ["8M", "7M", "4M"],
			flash: ["4M"],
			frustration: ["7M", "4M"],
			gigadrain: ["8M", "7T", "4M"],
			headbutt: ["4T"],
			headsmash: ["8L52"],
			heatwave: ["8M", "7T", "4T"],
			hiddenpower: ["7M", "4M"],
			hyperbeam: ["8M", "7M", "4M"],
			incinerate: ["7M"],
			lockon: ["8L48", "7L65"],
			metalsound: ["8L32", "7L50", "4L37"],
			meteorbeam: ["8T"],
			mudshot: ["8M", "7L34", "4L25"],
			mudslap: ["8L4", "7L8", "4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			powergem: ["8M", "8L36", "7L38", "4L29"],
			protect: ["8M", "7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			rockblast: ["8M", "8L12", "7L13", "4L15"],
			rockpolish: ["7M", "4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "4M"],
			rollout: ["8L8", "7L4", "4T", "4L1"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M", "4M"],
			secretpower: ["7M", "4M"],
			shadowball: ["8M", "7M", "4M"],
			sleeptalk: ["8M", "7M", "4M"],
			smackdown: ["7M"],
			snore: ["8M", "7T", "4T"],
			speedswap: ["8M"],
			stealthrock: ["8M", "7T", "4M"],
			stoneedge: ["8M", "7M", "4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M"],
			swift: ["8M", "4T"],
			tackle: ["8L1", "7L1", "4L1"],
			toxic: ["7M", "4M"],
			trick: ["8M", "7T", "4T"],
			vacuumwave: ["8L24", "7L60", "4T"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	tactite: {
		learnset: {
			accelerock: ["8L20", "7L21"],
			acupressure: ["8L48", "7L26", "4L20"],
			aerialace: ["7M", "4M"],
			ancientpower: ["8L31", "7L30", "4T"],
			bulldoze: ["8M", "7M"],
			calmmind: ["8M", "7M", "4M"],
			confide: ["7M"],
			cut: ["4M"],
			dazzlinggleam: ["8M", "7M"],
			defensecurl: ["8L1", "7L1", "4L1"],
			disable: ["8L16", "7L17", "4L17"],
			doubleedge: ["8L56", "7L43", "4L43"],
			doubleteam: ["7M", "4M"],
			earthpower: ["8M", "7T", "7L47", "4T"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			endure: ["8M", "4M"],
			energyball: ["8M", "7M", "4M"],
			explosion: ["7M", "4M"],
			facade: ["8M", "7M", "4M"],
			falseswipe: ["8M", "7M", "4M"],
			fireblast: ["8M", "7M", "4M"],
			flamethrower: ["8M", "7M", "4M"],
			flash: ["4M"],
			frustration: ["7M", "4M"],
			gigadrain: ["8M", "7T", "4M"],
			headbutt: ["4T"],
			headsmash: ["8L70", "4L67"],
			heatwave: ["8M", "7T", "4T"],
			hiddenpower: ["7M", "4M"],
			hyperbeam: ["8M", "7M", "4M"],
			incinerate: ["7M"],
			lockon: ["8L64", "7L63"],
			metalsound: ["8L36", "7L51", "4L51"],
			meteorbeam: ["8T"],
			mudshot: ["8M", "7L34", "4L25"],
			mudslap: ["8L1", "7L8", "4T"],
			naturalgift: ["4M"],
			ominouswind: ["4T"],
			powergem: ["8M", "8L42", "7L39", "4L29"],
			protect: ["8M", "7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			rockblast: ["8M", "8L12", "7L13", "4L15"],
			rockpolish: ["7M", "4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["6M", "5M", "4M"],
			rocktomb: ["8M", "7M", "4M"],
			rollout: ["8L1", "7L4", "4T", "4L1"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M", "4M"],
			secretpower: ["7M", "4M"],
			shadowball: ["8M", "7M", "4M"],
			sleeptalk: ["8M", "7M", "4M"],
			smackdown: ["7M"],
			smartstrike: ["8M"],
			snore: ["8M", "7T", "4T"],
			speedswap: ["8M", "7L55"],
			stealthrock: ["8M", "7T", "4M"],
			stoneedge: ["8M", "7M", "4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M"],
			swift: ["8M", "4T"],
			tackle: ["8L1", "7L1", "4L1"],
			toxic: ["7M", "4M"],
			trick: ["8M", "7T", "4T"],
			vacuumwave: ["8L24", "7L60", "4T"],
			zenheadbutt: ["8M", "7T"],
		},
	},
	privatyke: {
		learnset: {
			aquajet: ["8L16", "7L19", "4L27"],
			armthrust: ["8L4", "7L14", "4L21"],
			attract: ["8M", "7M", "4M"],
			blizzard: ["8M", "7M", "4M"],
			bodyslam: ["8M"],
			brickbreak: ["8M", "7M", "4M"],
			brine: ["8M", "4M"],
			bubble: ["7L1", "4L1"],
			bulkup: ["8M", "7M", "7E", "4E"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			chipaway: ["7L30"],
			closecombat: ["8M", "7E"],
			coaching: ["8T"],
			confide: ["7M"],
			crosschop: ["8E", "7M", "4E"],
			cut: ["4M"],
			dive: ["8M", "4T"],
			doubleteam: ["7M", "4M"],
			drainpunch: ["8M", "4M"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			falseswipe: ["8M"],
			fling: ["8M", "7M", "4M"],
			focuspunch: ["8L52", "7L59", "4M", "4L67"],
			foulplay: ["8M", "7T"],
			frustration: ["7M", "4M"],
			hail: ["8M", "7M", "4M"],
			headbutt: ["8L36", "7E", "4T", "4E"],
			hiddenpower: ["7M", "4M"],
			icebeam: ["8M", "7M", "4M"],
			icepunch: ["8M", "7T", "4T"],
			icywind: ["8M", "7T", "4T"],
			knockoff: ["8L24", "7T", "4T"],
			lashout: ["8T"],
			lowkick: ["8M", "7T", "4T"],
			lowsweep: ["8M", "7M"],
			machpunch: ["8L16", "7L35", "4L32"],
			megapunch: ["8M"],
			muddywater: ["8M"],
			mudslap: ["4T"],
			naturalgift: ["4M"],
			octazooka: ["8E", "7E", "4E"],
			poisonjab: ["8M", "7M"],
			poweruppunch: ["8E", "7M"],
			protect: ["8M", "7M", "4M"],
			psychup: ["7M"],
			punishment: ["7L55", "4L60"],
			raindance: ["8M", "7M", "4M"],
			recover: ["8E", "7E", "4E"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			revenge: ["8M", "8L28", "7L42", "4L41"],
			roar: ["7M", "4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["4M"],
			rocktomb: ["8M", "7M", "4M"],
			round: ["8M", "7M"],
			safeguard: ["8M"],
			scald: ["8M", "8L40", "7M", "7L38"],
			scaryface: ["8M", "7E", "4E"],
			secretpower: ["7M", "4M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "4M"],
			sludgebomb: ["8M", "7M", "4M"],
			smokescreen: ["8L12", "7L7", "4L7"],
			snatch: ["7M", "4M"],
			snore: ["8M", "7T", "4T"],
			spikes: ["8M", "7E"],
			strength: ["4M"],
			submission: ["8L44", "7L48", "4L55"],
			substitute: ["8M", "7M", "4M"],
			superpower: ["8M", "7T", "4T"],
			surf: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			taunt: ["8M", "8L32", "7M", "7L24", "4M", "4L36"],
			thief: ["8M", "8L8", "7M", "7L45", "4M", "4L47"],
			throatchop: ["8M"],
			thunderpunch: ["8M", "7T", "4T"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			vacuumwave: ["4T"],
			waterfall: ["8M", "7M", "4M"],
			watergun: ["8L1"],
			waterpulse: ["7T", "4M"],
			whirlpool: ["8M", "4M"],
			wideguard: ["8L48", "7L52"],
			workup: ["8M", "7M"],
			wrap: ["8L1", "7L1", "4L1"],
			yawn: ["8L20", "7L10", "4L1"],
		},
	},
	voodoll: {
		learnset: {
			acupressure: ["8L48", "7L36", "4L40"],
			afteryou: ["7T"],
			assurance: ["8M"],
			astonish: ["8L1", "7L1", "4L1"],
			attract: ["8M", "7M", "4M"],
			aurasphere: ["8M", "8L36", "7L45", "4L45"],
			batonpass: ["8M", "7E", "4E"],
			burningjealousy: ["8T"],
			captivate: ["4M"],
			charge: ["8L20", "7L19", "4L19"],
			confide: ["7M"],
			copycat: ["8L4", "7L1", "4L1"],
			counter: ["8E", "7E", "4T"],
			darkpulse: ["8M", "7M", "4M"],
			doubleteam: ["7M"],
			dreameater: ["7M", "4M"],
			echoedvoice: ["8L16"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			feintattack: ["7L30", "4L30"],
			fling: ["8M", "7M", "4M"],
			followme: ["8L40", "7L20", "4L20"],
			foulplay: ["8M", "8L52", "7L54"],
			frustration: ["7M", "4M"],
			grudge: ["8L33", "7L15", "4L15"],
			hex: ["8M", "8L28", "7L43"],
			hiddenpower: ["7M", "4M"],
			hypervoice: ["8M", "7T"],
			imprison: ["8M", "7E", "4E"],
			knockoff: ["7T", "4T"],
			lashout: ["8T"],
			machpunch: ["8E", "7E", "4E"],
			magiccoat: ["7T", "4T"],
			magicroom: ["8M", "7T"],
			memento: ["8E", "7E", "4E"],
			metronome: ["8M", "7E", "4T"],
			mimic: ["7E", "4E"],
			mudslap: ["4T"],
			nastyplot: ["8M", "7E"],
			naturalgift: ["4M"],
			nightmare: ["7E", "4T"],
			painsplit: ["8L44", "7L7", "4T", "4L7"],
			payback: ["8M", "7M", "4M"],
			perishsong: ["8E", "7E", "4E"],
			pinmissile: ["8M", "8L8", "7L25", "4L25"],
			powertrip: ["8E", "7E"],
			poweruppunch: ["7M"],
			protect: ["8M", "7M", "4M"],
			psychic: ["8M", "7M", "4M"],
			pursuit: ["7E", "4E"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			risingvoltage: ["8T"],
			rocksmash: ["7M", "4M"],
			round: ["8M", "7M"],
			screech: ["8M", "7E", "4E"],
			secretpower: ["7M", "4M"],
			shockwave: ["7T", "4M"],
			sleeptalk: ["8M", "7M", "4M"],
			smellingsalts: ["7E", "4E"],
			snarl: ["8M", "7M"],
			snatch: ["4M"],
			snore: ["8M", "7T", "4T"],
			spite: ["8L12", "7L11", "4T", "4L11"],
			strength: ["7M", "4M"],
			substitute: ["8M", "7M", "7L50", "4M", "4L50"],
			suckerpunch: ["8E"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			taunt: ["8M", "7M", "4M"],
			tearfullook: ["8L24", "7L22"],
			thief: ["8M", "7M", "4M"],
			thunderbolt: ["8M", "7M"],
			thunderwave: ["8M", "7M", "4M"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			uproar: ["8M", "7T", "4T"],
			vacuumwave: ["4T"],
			workup: ["8M", "7M"],
			wrap: ["8L1", "7L1", "4L1"],
		},
	},
	mumbao: {
		learnset: {
			attract: ["8M", "7M"],
			bodyslam: ["8M", "8L45", "7L28"],
			bulletseed: ["8M"],
			confide: ["7M"],
			dazzlinggleam: ["8M", "7M"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			endure: ["8M"],
			energyball: ["8M", "8L55", "7M", "7L37"],
			explosion: ["8E"],
			facade: ["8M", "7M"],
			flowershield: ["8L1", "7L1"],
			focusblast: ["8M", "7M"],
			focusenergy: ["8M", "8L20"],
			frustration: ["7M"],
			gigadrain: ["8M", "8L40", "7T"],
			grassknot: ["8M", "7M"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7E"],
			gravity: ["7T"],
			gyroball: ["8M", "7M"],
			healingwish: ["8E", "7E"],
			heavyslam: ["8M"],
			helpinghand: ["8M", "8L15", "7T", "7L35"],
			hiddenpower: ["7M"],
			ingrain: ["8L10", "7L10"],
			leafage: ["8L5", "7L13"],
			leafstorm: ["8M", "8L65", "7L46"],
			lightscreen: ["8M", "7M"],
			luckychant: ["7L17"],
			magicalleaf: ["8M", "8L25", "7L19"],
			magiccoat: ["7T"],
			mistyexplosion: ["8T"],
			mistyterrain: ["8M", "7E"],
			moonblast: ["8L60", "7L44"],
			naturalgift: ["7L31"],
			playrough: ["8M"],
			protect: ["8M", "7M"],
			psychup: ["8L35", "7M", "7L26"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rototiller: ["7L8"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			seedbomb: ["8M", "7T"],
			selfdestruct: ["8M"],
			shadowball: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			smellingsalts: ["7E"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "8L50", "7M", "7L40"],
			swagger: ["7M"],
			synthesis: ["8L50", "7T"],
			tackle: ["8L1", "7L4"],
			toxic: ["7M"],
			wish: ["8L30", "7L22"],
			woodhammer: ["8E", "7E"],
			worryseed: ["8E", "7T"],
		},
	},
	fawnifer: {
		learnset: {
			attract: ["8M", "7M"],
			bodyslam: ["8M", "8L30", "7L30"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulletseed: ["8M"],
			chargebeam: ["8L18"],
			charm: ["8M"],
			confide: ["7M"],
			confuseray: ["8L15", "7L18"],
			doubleedge: ["8E", "7E"],
			doublekick: ["8E", "7E"],
			doubleteam: ["7M"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			energyball: ["8M", "8L39", "7M"],
			facade: ["8M", "7M"],
			flash: ["7L42"],
			frustration: ["7M"],
			gigadrain: ["8M", "7T"],
			grassknot: ["8M", "7M"],
			grasspledge: ["8T", "7T"],
			grassyglide: ["8T"],
			grassyterrain: ["8M"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hypervoice: ["8M", "7T"],
			knockoff: ["7T"],
			leafage: ["8L3", "7L1"],
			leafstorm: ["8M"],
			leechseed: ["8L21", "7L26"],
			leer: ["8L1", "7L4"],
			naturalgift: ["7E"],
			naturepower: ["7M"],
			powerwhip: ["8M", "7E"],
			present: ["8E", "7E"],
			protect: ["8M", "7M"],
			quickattack: ["8L9", "7L11"],
			rapidspin: ["8E", "7E"],
			razorleaf: ["8L12", "7L14"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			seedbomb: ["8M", "8L27", "7T", "7L34"],
			signalbeam: ["7L46"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			spark: ["8L24", "7L22"],
			spotlight: ["7E"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "7M"],
			synthesis: ["8L33", "7T"],
			tackle: ["8L1", "7L1"],
			thundershock: ["8L6", "7L7"],
			toxic: ["7M"],
			uproar: ["8M", "7T"],
			voltswitch: ["8M", "7M"],
			wildcharge: ["8M", "8L36", "7T", "7L38"],
			workup: ["8M", "7M"],
			worryseed: ["7T"],
		},
	},
	electrelk: {
		learnset: {
			attract: ["8M", "7M"],
			bodyslam: ["8M", "8L40", "7L30"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulletseed: ["8M"],
			chargebeam: ["8L20", "7M"],
			charm: ["8M"],
			confide: ["7M"],
			confuseray: ["8L15", "7L18"],
			doubleteam: ["7M"],
			echoedvoice: ["7M"],
			eerieimpulse: ["8M"],
			electroweb: ["8M", "7T"],
			endeavor: ["7T"],
			endure: ["8M"],
			energyball: ["8M", "8L1", "7M"],
			facade: ["8M", "7M"],
			flash: ["7L45"],
			flashcannon: ["8M", "7M"],
			frustration: ["7M"],
			gigadrain: ["8M", "7T"],
			grassknot: ["8M", "7M"],
			grasspledge: ["8T", "7T"],
			grassyglide: ["8T"],
			grassyterrain: ["8M", "7T"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hypervoice: ["8M", "7T"],
			knockoff: ["7T"],
			leafage: ["8L1", "7L1"],
			leafstorm: ["8M"],
			leechseed: ["8L25", "7L26"],
			leer: ["8L1", "7L4"],
			magnetrise: ["8L55", "7L58"],
			naturepower: ["7M"],
			powerwhip: ["8M"],
			protect: ["8M", "7M"],
			quickattack: ["8L9", "7L11"],
			razorleaf: ["8L12", "7L14"],
			rest: ["8M", "7M"],
			return: ["7M"],
			risingvoltage: ["8T"],
			round: ["8M", "7M"],
			seedbomb: ["8M", "8L35", "7T", "7L35"],
			shockwave: ["7T"],
			signalbeam: ["7T", "7L49"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			solarblade: ["8M"],
			spark: ["8L30", "7L22"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "7M"],
			synthesis: ["8L45", "7T"],
			tackle: ["8L1", "7L1"],
			throatchop: ["8M"],
			thunder: ["8M", "7M"],
			thunderbolt: ["8M", "7M"],
			thundershock: ["8L1", "7L7"],
			thunderwave: ["8M", "7M"],
			toxic: ["7M"],
			uproar: ["8M", "7T"],
			voltswitch: ["8M", "7M"],
			wildcharge: ["8M", "8L50", "7T", "7L40"],
			workup: ["8M", "7M"],
			worryseed: ["7T"],
			zapcannon: ["8L60", "7L53"],
		},
	},
	smogecko: {
		learnset: {
			acidspray: ["8E", "7E"],
			attract: ["8M", "7M"],
			bonerush: ["8E", "7E"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "8L24", "7M", "7L19"],
			bulletpunch: ["8E", "7E"],
			camouflage: ["7L30"],
			confide: ["7M"],
			defog: ["8E", "7T"],
			dig: ["8M", "8L33", "7L47"],
			doubleteam: ["7M"],
			earthpower: ["8M", "7T"],
			ember: ["8L3", "7L1"],
			endeavor: ["7T"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			firefang: ["8M"],
			firepledge: ["8T", "7T"],
			firepunch: ["8M"],
			firespin: ["8M", "7E"],
			flameburst: ["7L22"],
			flamethrower: ["8M", "7M", "7L40"],
			flamewheel: ["8L21", "7L15"],
			flareblitz: ["8L39"],
			forcepalm: ["8E", "7E"],
			frustration: ["7M"],
			gunkshot: ["8M", "7T"],
			heatwave: ["8M", "7T"],
			hiddenpower: ["7M"],
			incinerate: ["8L15", "7L34"],
			irontail: ["8M", "7T"],
			lavaplume: ["8L27", "7L26"],
			lick: ["8L9", "7L7"],
			lowkick: ["8M", "7T"],
			mudshot: ["8M", "8L18", "7L13"],
			overheat: ["8M", "7M"],
			poisonfang: ["8E", "7E"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roar: ["7M"],
			round: ["8M", "7M"],
			sandtomb: ["8M", "7E"],
			scaleshot: ["8T"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "7L1"],
			screech: ["8M", "8L30", "7L44"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			smog: ["8L12", "7L10"],
			smokescreen: ["8L6", "7L4"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			stealthrock: ["8M", "7T"],
			stompingtantrum: ["8M", "7T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			swagger: ["7M"],
			tailwhip: ["8L1", "7L4"],
			taunt: ["8M", "7M"],
			toxic: ["8L36", "7M"],
			venomdrench: ["8M"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
		},
	},
	smoguana: {
		learnset: {
			attract: ["8M", "7M"],
			brickbreak: ["8M", "7M"],
			bulkup: ["8M", "7M"],
			bulldoze: ["8M", "8L35", "7M", "7L19"],
			burningjealousy: ["8T"],
			camouflage: ["7L35"],
			clearsmog: ["8L20", "7L26"],
			confide: ["7M"],
			corrosivegas: ["8T"],
			crosspoison: ["8M"],
			defog: ["7T"],
			dig: ["8M", "8L1"],
			doubleteam: ["7M"],
			earthpower: ["8M", "7T"],
			earthquake: ["8M", "8L50", "7M", "7L50"],
			ember: ["8L1", "7L1"],
			endeavor: ["7T"],
			facade: ["8M", "7M"],
			fireblast: ["8M", "7M"],
			firefang: ["8M"],
			firepledge: ["8T", "7T"],
			firepunch: ["8M", "7T"],
			flameburst: ["7L22"],
			flamethrower: ["8M", "7M", "7L42"],
			flamewheel: ["8L30", "7L15"],
			flareblitz: ["8M", "8L60", "7L55"],
			frustration: ["7M"],
			gunkshot: ["8M", "7T"],
			heatwave: ["8M", "7T"],
			hiddenpower: ["7M"],
			incinerate: ["8L15"],
			irontail: ["8M", "7T"],
			lavaplume: ["8L40", "7L31"],
			lick: ["8L9", "7L7"],
			lowkick: ["8M", "7T"],
			mudshot: ["8M", "8L25", "7L13"],
			overheat: ["8M", "7M"],
			poisonjab: ["8M", "7M"],
			protect: ["8M", "7M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			roar: ["7M"],
			round: ["8M", "7M"],
			sandtomb: ["8M"],
			scaleshot: ["8T"],
			scorchingsands: ["8T"],
			scratch: ["8L1", "7L1"],
			screech: ["8M", "8L45", "7L46"],
			sleeptalk: ["8M", "7M"],
			sludgebomb: ["8M", "7M"],
			smog: ["8L12", "7L10"],
			smokescreen: ["8L1", "7L4"],
			snore: ["8M", "7T"],
			solarbeam: ["8M", "7M"],
			stealthrock: ["8M", "7T"],
			stompingtantrum: ["8M", "7T"],
			substitute: ["8M", "7M"],
			sunnyday: ["8M", "7M"],
			superpower: ["8M", "7T"],
			swagger: ["7M"],
			tailwhip: ["8L1", "7L1"],
			taunt: ["8M", "7M"],
			toxic: ["8L55", "7M"],
			venomdrench: ["8M"],
			willowisp: ["8M", "7M"],
			workup: ["8M", "7M"],
		},
	},
	swirlpool: {
		learnset: {
			acidarmor: ["8E", "7E"],
			allyswitch: ["8M"],
			aquajet: ["8L12", "7L16"],
			attract: ["8M", "7M"],
			blizzard: ["8M", "7M"],
			bodyslam: ["8M"],
			brine: ["8M", "8L21", "7L25"],
			bugbite: ["7T"],
			bugbuzz: ["8M", "8L33", "7L38"],
			captivate: ["7L40"],
			charm: ["8M", "8L9", "7L10"],
			confide: ["7M"],
			confusion: ["8L15", "7L14"],
			dazzlinggleam: ["8M", "7M"],
			dive: ["8M"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			frustration: ["7M"],
			futuresight: ["8M"],
			growl: ["8L1", "7L4"],
			guardswap: ["8M"],
			hail: ["8M", "7M"],
			healpulse: ["8L24", "7L20"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hydropump: ["8M", "8L39", "7L44"],
			hypervoice: ["8M", "7T"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "7T"],
			infestation: ["8E", "7M"],
			leechlife: ["8M", "7M"],
			lifedew: ["8E"],
			magiccoat: ["7T"],
			metronome: ["8M"],
			muddywater: ["8M"],
			pound: ["8L1", "7L1"],
			powder: ["7E"],
			protect: ["8M", "7M"],
			psychic: ["8M", "7M"],
			psychoshift: ["8E", "7E"],
			raindance: ["8M", "8L18", "7M", "7L29"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scald: ["8M", "7M"],
			signalbeam: ["7T"],
			skillswap: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			spikyshield: ["8E", "7E"],
			spotlight: ["7E"],
			stealthrock: ["8M", "7T"],
			stickyweb: ["8E", "7E"],
			strugglebug: ["8L6", "7L7"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "8L30", "7M", "7L48"],
			toxic: ["8L36", "7M"],
			trick: ["8M", "7T"],
			uproar: ["8M", "7T"],
			uturn: ["8M", "8L27", "7M", "7L34"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M"],
			waterfall: ["8M", "7M"],
			watergun: ["8L3", "7L1"],
			waterpledge: ["8T", "7T"],
			waterpulse: ["7T"],
			whirlpool: ["8M"],
			workup: ["8M", "7M"],
		},
	},
	coribalis: {
		learnset: {
			allyswitch: ["8M"],
			aquajet: ["8L12", "7L16"],
			attract: ["8M", "7M"],
			blizzard: ["8M", "7M"],
			block: ["7T"],
			bodyslam: ["8M"],
			brine: ["8M", "8L25", "7L26"],
			bugbite: ["7T"],
			bugbuzz: ["8M", "8L50", "7L42"],
			captivate: ["7L48"],
			charm: ["8M", "8L9", "7L10"],
			confide: ["7M"],
			confusion: ["8L15", "7L14"],
			dazzlinggleam: ["8M", "7M"],
			dive: ["8M"],
			doubleteam: ["7M"],
			drainingkiss: ["8M"],
			dualwingbeat: ["8T"],
			echoedvoice: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			frustration: ["7M"],
			futuresight: ["8M"],
			growl: ["8L1", "7L4"],
			guardswap: ["8M"],
			hail: ["8M", "7M"],
			healpulse: ["8L30", "7L22"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			hydropump: ["8M", "8L60", "7L52"],
			hypervoice: ["8M", "7T"],
			icebeam: ["8M", "7M"],
			icywind: ["8M", "7T"],
			infestation: ["7M"],
			leechlife: ["8M", "7M"],
			magiccoat: ["7T"],
			magicroom: ["8M", "7T"],
			metronome: ["8M"],
			muddywater: ["8M"],
			pound: ["8L1", "7L1"],
			protect: ["8M", "7M"],
			psychic: ["8M", "8L40", "7M", "7L38"],
			raindance: ["8M", "8L20", "7M", "7L30"],
			razorshell: ["8M"],
			rest: ["8M", "7M"],
			return: ["7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			scald: ["8M", "7M"],
			signalbeam: ["7T"],
			skillswap: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M"],
			snore: ["8M", "7T"],
			stealthrock: ["8M", "7T"],
			strugglebug: ["8L1", "7L7"],
			substitute: ["8M", "7M"],
			surf: ["8M", "7M"],
			swagger: ["7M"],
			swift: ["8M"],
			swordsdance: ["8M", "8L45", "7M", "7L58"],
			toxic: ["8L55", "7M"],
			trick: ["8M", "7T"],
			trickroom: ["8M", "7M"],
			uproar: ["8M", "7T"],
			uturn: ["8M", "8L35", "7M", "7L34"],
			venomdrench: ["8M"],
			venoshock: ["8M", "7M"],
			waterfall: ["8M", "7M"],
			watergun: ["8L1"],
			waterpledge: ["8T", "7T"],
			waterpulse: ["7T"],
			whirlpool: ["8M"],
			wonderroom: ["8M", "7T"],
			workup: ["8M", "7M"],
			xscissor: ["8M"],
		},
	},
	justyke: {
		learnset: {
			allyswitch: ["8M", "7T"],
			aurasphere: ["8M", "8L33", "7L64"],
			bodyslam: ["8M"],
			bulldoze: ["8M", "7M"],
			confide: ["7M"],
			destinybond: ["8L44", "7L58"],
			doubleteam: ["7M"],
			drillrun: ["8M", "7T"],
			earthpower: ["8M", "8L48", "7T", "7L36"],
			earthquake: ["8M", "7M", "7L52"],
			embargo: ["7M"],
			endure: ["8M"],
			facade: ["8M", "7M"],
			flashcannon: ["8M", "8L28", "7M", "7L41"],
			frustration: ["7M"],
			guardsplit: ["8L16", "7L21"],
			gyroball: ["8M", "8L12", "7M", "7L17"],
			healingwish: ["8L40", "7L46"],
			helpinghand: ["8M", "7T"],
			hiddenpower: ["7M"],
			imprison: ["8M", "8L8", "7L9"],
			irondefense: ["8M", "7T"],
			ironhead: ["8M", "7T"],
			irontail: ["8M", "7T"],
			magicroom: ["8M", "7T"],
			magnetrise: ["8L1", "7T"],
			memento: ["8L36", "7L46"],
			mindreader: ["8L24", "7L31"],
			mirrorshot: ["7L26"],
			mudshot: ["8M", "7L13"],
			mudslap: ["8L4", "7L5"],
			mudsport: ["7L1"],
			painsplit: ["8L52", "7T", "7L70"],
			pound: ["8L1", "7L1"],
			powersplit: ["8L16", "7L21"],
			protect: ["8M", "7M"],
			psychup: ["7M"],
			quash: ["8L20", "7M"],
			recycle: ["7T"],
			rest: ["8M", "7M"],
			return: ["7M"],
			rockpolish: ["7M"],
			rockslide: ["8M", "7M"],
			rocktomb: ["8M", "7M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M"],
			sandstorm: ["8M", "7M"],
			sleeptalk: ["8M", "7M"],
			smartstrike: ["8M", "7M"],
			snore: ["8M", "7M"],
			steelbeam: ["8T"],
			steelroller: ["8T"],
			substitute: ["8M", "7M"],
			swagger: ["7M"],
			trickroom: ["8M", "7M"],
			wonderroom: ["8M", "7T"],
			workup: ["8M", "7M"],
		},
	},
	solotl: {
		learnset: {
			acrobatics: ["8M"],
			agility: ["8M"],
			allyswitch: ["8M"],
			attract: ["8M"],
			batonpass: ["8M"],
			breakingswipe: ["8M"],
			charm: ["8M", "8L20"],
			cosmicpower: ["8M", "8L28"],
			dazzlinggleam: ["8M"],
			defog: ["8E"],
			dracometeor: ["8T"],
			dragonbreath: ["8L8"],
			dragonpulse: ["8M", "8L40"],
			dragonrush: ["8L48"],
			dragontail: ["8L24"],
			ember: ["8L4"],
			encore: ["8M", "8L12"],
			endure: ["8M"],
			facade: ["8M"],
			fireblast: ["8M"],
			firelash: ["8L36"],
			firespin: ["8M"],
			flamethrower: ["8M"],
			flamewheel: ["8L16"],
			flareblitz: ["8M", "8L52"],
			healbell: ["8L32"],
			healingwish: ["8L44"],
			heatwave: ["8M"],
			helpinghand: ["8M"],
			imprison: ["8M"],
			lifedew: ["8E"],
			lightscreen: ["8M"],
			magicalleaf: ["8M"],
			meteorbeam: ["8T"],
			metronome: ["8M"],
			mysticalfire: ["8M"],
			outrage: ["8M"],
			overheat: ["8M"],
			pound: ["8L1"],
			protect: ["8M"],
			reflect: ["8M"],
			rest: ["8M"],
			round: ["8M"],
			safeguard: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			solarbeam: ["8M"],
			spikes: ["8M"],
			storedpower: ["8M"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			swift: ["8M"],
			tailwhip: ["8L1"],
			taunt: ["8M"],
			thunderwave: ["8M"],
			twister: ["8E"],
			willowisp: ["8M"],
			workup: ["8M"],
			yawn: ["8E"],
		},
	},
	miasmite: {
		learnset: {
			agility: ["8M"],
			aromatherapy: ["8E"],
			attract: ["8M"],
			bite: ["8L8"],
			bodyslam: ["8M"],
			breakingswipe: ["8M"],
			bugbite: ["8L16"],
			bugbuzz: ["8M", "8L24"],
			corrosivegas: ["8T"],
			crunch: ["8M", "8L36"],
			darkpulse: ["8M"],
			dracometeor: ["8T"],
			dragonbreath: ["8L4"],
			dragonclaw: ["8M"],
			dragonpulse: ["8M", "8L40"],
			dragonrush: ["8E"],
			dragontail: ["8E"],
			earthpower: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			firefang: ["8M"],
			firstimpression: ["8E"],
			flashcannon: ["8M"],
			haze: ["8L28"],
			icefang: ["8M"],
			ironhead: ["8M"],
			irontail: ["8M"],
			leechlife: ["8M"],
			lunge: ["8L44"],
			outrage: ["8M"],
			pinmissile: ["8M"],
			poisonfang: ["8E"],
			poisongas: ["8L20"],
			poisonjab: ["8M"],
			protect: ["8M"],
			raindance: ["8M"],
			recover: ["8E"],
			rest: ["8M"],
			round: ["8M"],
			scaryface: ["8M"],
			screech: ["8M"],
			skittersmack: ["8T"],
			sleeptalk: ["8M"],
			sludgebomb: ["8M"],
			smog: ["8L12"],
			smokescreen: ["8L1"],
			snore: ["8M"],
			strugglebug: ["8L1"],
			substitute: ["8M"],
			sunnyday: ["8M"],
			superfang: ["8L32"],
			superpower: ["8M"],
			swordsdance: ["8M"],
			taunt: ["8M"],
			thunderfang: ["8M"],
			uproar: ["8M"],
			workup: ["8M"],
			xscissor: ["8M"],
		},
	},
	nohface: {
		learnset: {
			assurance: ["8M"],
			attract: ["8M", "7M", "4M"],
			captivate: ["4M"],
			closecombat: ["8M"],
			confide: ["7M"],
			copycat: ["8L20", "7L22", "4L22"],
			curse: ["8E", "7E", "4E"],
			cut: ["7M", "4M"],
			darkpulse: ["8M", "7M", "4M"],
			defog: ["8E", "7E", "4M"],
			dig: ["8M", "7M", "4M"],
			doubleteam: ["7M", "4M"],
			dreameater: ["7M", "4M"],
			embargo: ["7M", "4M"],
			endeavor: ["7T", "4T"],
			facade: ["8M", "7M", "4M"],
			fakeout: ["8L8", "7L27", "4L35"],
			falseswipe: ["8M", "7M", "4M"],
			featherdance: ["8E", "7E", "4E"],
			feintattack: ["7L14", "4L14"],
			flail: ["8E", "7E", "4E"],
			flash: ["7M", "4M"],
			fling: ["8M", "7M", "4M"],
			foulplay: ["8M", "7M"],
			frustration: ["7M", "4M"],
			furycutter: ["4T"],
			headbutt: ["4T"],
			hex: ["8M", "7E"],
			hiddenpower: ["7M", "4M"],
			honeclaws: ["8L28", "7L31"],
			icepunch: ["8M", "7T", "4T"],
			icywind: ["8M", "7T", "4T"],
			irontail: ["8M", "7E", "4M"],
			knockoff: ["7T", "4T"],
			lastresort: ["7T", "4T"],
			lick: ["8L4", "7L6", "4L6"],
			magiccoat: ["7T", "4T"],
			memento: ["8L44", "7L52", "4L31"],
			metalsound: ["8E", "7E", "4E"],
			meteormash: ["8E", "7E", "4E"],
			metronome: ["8M"],
			naturalgift: ["4M"],
			odorsleuth: ["7L10", "4L10"],
			ominouswind: ["4T"],
			painsplit: ["7T", "4T"],
			payback: ["8M", "7M", "4M"],
			perishsong: ["8L48", "7L56", "4L55"],
			playrough: ["8M", "7E"],
			poltergeist: ["8T"],
			protect: ["8M", "7M", "4M"],
			psychoshift: ["8E", "7E", "4E"],
			psychup: ["7M", "4M"],
			raindance: ["8M", "7M", "4M"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			revenge: ["8M", "8L36", "7L39", "4L44"],
			roar: ["7M", "4M"],
			round: ["8M", "7M"],
			safeguard: ["8M", "7M", "4M"],
			scratch: ["8L1", "7L1", "4L1"],
			secretpower: ["7M", "4M"],
			shadowball: ["8M", "7M", "4M"],
			shadowclaw: ["8M", "8L32", "7M", "7L35", "4M", "4L40"],
			shadowsneak: ["8L16", "7L18", "4L18"],
			skittersmack: ["8T"],
			sleeptalk: ["8M", "7M", "4M"],
			snatch: ["7M", "4M"],
			snore: ["8M", "7T", "4T"],
			spite: ["8L12", "7T", "4T"],
			substitute: ["8M", "7M", "4M"],
			suckerpunch: ["8E", "7E", "4T"],
			sunnyday: ["8M", "7M", "4M"],
			swagger: ["7M", "4M"],
			tailwhip: ["8L1", "7L1", "4L1"],
			taunt: ["8M", "7M", "4M"],
			telekinesis: ["7M"],
			thief: ["8M", "7M", "4M"],
			thunderpunch: ["8M", "7T", "4T"],
			torment: ["8L24", "7M", "4M"],
			toxic: ["7M", "4M"],
			trick: ["8M", "8L40", "7T", "4T"],
			trickroom: ["8M", "7M", "4M"],
			uturn: ["8M", "7M", "4M"],
			willowisp: ["8M", "7M", "4M"],
			wish: ["8E", "7E"],
			yawn: ["8E", "7E", "4E"],
		},
	},
	monohm: {
		learnset: {
			aerialace: ["7M", "4M"],
			aquatail: ["8E", "7T"],
			attract: ["8M", "7M", "4M"],
			bide: ["7L1", "4L1"],
			blizzard: ["8M", "7M", "4M"],
			captivate: ["4M"],
			charge: ["8L12", "7L11", "4L11"],
			chargebeam: ["7M", "4M"],
			confide: ["7M"],
			discharge: ["8L33", "7L33", "4L37"],
			doubleteam: ["7M", "4M"],
			dragonbreath: ["8L20", "7L29", "7E", "4E"],
			dragonpulse: ["8M", "7T", "4M"],
			dragonrage: ["7L24", "4L7"],
			dragontail: ["8L28", "7M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M", "8L1", "7L1"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			flash: ["4M"],
			focusenergy: ["8M"],
			frustration: ["7M", "4M"],
			growl: ["8L1", "7L1", "4L1"],
			hail: ["8M", "7M", "4M"],
			headbutt: ["8E", "4T"],
			healbell: ["7E", "4E"],
			hiddenpower: ["7M", "4M"],
			hydropump: ["8M", "7E", "4E"],
			icebeam: ["8M", "7M", "4M"],
			icywind: ["8M", "7T"],
			irontail: ["8M", "7T"],
			lightscreen: ["8M", "7M", "4M"],
			lockon: ["8L44"],
			muddywater: ["8M"],
			mudslap: ["8E", "7E", "4T", "4E"],
			naturalgift: ["4M"],
			naturepower: ["7M"],
			outrage: ["8M", "4T"],
			powdersnow: ["8E", "7E"],
			powergem: ["8M", "7E", "4E"],
			protect: ["8M", "7M", "4M"],
			raindance: ["8M", "8L36", "7M", "7L37", "4M", "4L19"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			risingvoltage: ["8T"],
			roar: ["8E", "7M", "4M"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M", "4M"],
			secretpower: ["7M", "4M"],
			shockwave: ["7T", "4M"],
			signalbeam: ["4T"],
			slackoff: ["8L40", "7L48", "4L42"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "4T"],
			sonicboom: ["7L16", "4L29"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			surf: ["8M", "7M", "4M"],
			swagger: ["7M"],
			swift: ["8M", "4T"],
			tackle: ["8L1", "7L1", "4L1"],
			thrash: ["8L48", "7L53", "4L50"],
			thunder: ["8M", "8L52", "7M", "7L65", "4M"],
			thunderbolt: ["8M", "7M", "4M"],
			thunderfang: ["8M"],
			thundershock: ["8L4", "7L11", "4L15"],
			thunderwave: ["8M", "8L24", "7M", "4M"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			trickroom: ["8M", "7M", "4M"],
			twister: ["8L8", "7L7", "4T"],
			voltswitch: ["8M", "7M"],
			waterfall: ["8M", "7M", "4M"],
			waterpulse: ["7T", "4M"],
			weatherball: ["8M"],
			whirlwind: ["8L16", "7L1", "4L1"],
			wildcharge: ["8M", "7M"],
			zapcannon: ["8L44", "7L59", "4L59"],
		},
	},
	duohm: {
		learnset: {
			aerialace: ["7M", "4M"],
			aquatail: ["8E", "7T"],
			attract: ["8M", "7M", "4M"],
			bide: ["7L1", "4L1"],
			blizzard: ["8M", "7M", "4M"],
			captivate: ["4M"],
			charge: ["8L12", "7L11", "4L11"],
			chargebeam: ["7M", "4M"],
			confide: ["7M"],
			discharge: ["8L35", "7L33", "4L37"],
			doublehit: ["8L1", "7L20", "4L22"],
			doubleteam: ["7M", "4M"],
			dracometeor: ["8T", "7T", "4T"],
			dragonbreath: ["8L20", "7L29", "7E", "4E"],
			dragonclaw: ["8M", "7M", "4M"],
			dragonpulse: ["8M", "7T", "4M"],
			dragonrage: ["7L24", "4L7"],
			dragontail: ["8L28", "7M"],
			eerieimpulse: ["8M"],
			electricterrain: ["8M", "8L1", "7L1"],
			electroball: ["8M"],
			electroweb: ["8M", "7T"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			fireblast: ["8M", "7M", "4M"],
			firefang: ["8M"],
			flamethrower: ["8M", "7M", "4M"],
			flash: ["4M"],
			focusenergy: ["8M"],
			frustration: ["7M", "4M"],
			growl: ["8L1", "7L1", "4L1"],
			hail: ["8M", "7M", "4M"],
			headbutt: ["8E", "4T"],
			healbell: ["7E", "4E"],
			hiddenpower: ["7M", "4M"],
			honeclaws: ["7M"],
			hydropump: ["8M", "7E", "4E"],
			icebeam: ["8M", "7M", "4M"],
			icefang: ["8M"],
			icywind: ["8M", "7T"],
			incinerate: ["7M"],
			irontail: ["8M", "7T"],
			lightscreen: ["8M", "7M", "4M"],
			lockon: ["8L52"],
			muddywater: ["8M"],
			mudslap: ["8E", "7E", "4T", "4E"],
			naturalgift: ["4M"],
			naturepower: ["7M"],
			outrage: ["8M", "4T"],
			powdersnow: ["8E", "7E"],
			powergem: ["8M", "7E", "4E"],
			protect: ["8M", "7M", "4M"],
			raindance: ["8M", "8L40", "7M", "7L37", "4M", "4L19"],
			rest: ["8M", "7M", "4M"],
			return: ["7M", "4M"],
			risingvoltage: ["8T"],
			roar: ["8E", "7M", "4M"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M", "4M"],
			secretpower: ["7M", "4M"],
			shockwave: ["7T", "4M"],
			signalbeam: ["4T"],
			slackoff: ["8L46", "7L48", "4L42"],
			sleeptalk: ["8M", "7M", "4M"],
			snore: ["8M", "4T"],
			sonicboom: ["7L16", "4L29"],
			strength: ["4M"],
			substitute: ["8M", "7M", "4M"],
			sunnyday: ["8M", "7M", "4M"],
			surf: ["8M", "7M", "4M"],
			swagger: ["7M"],
			swift: ["8M", "4T"],
			tackle: ["8L1", "7L1", "4L1"],
			thrash: ["8L58", "7L53", "4L50"],
			thunder: ["8M", "8L64", "7M", "7L65", "4M"],
			thunderbolt: ["8M", "7M", "4M"],
			thunderfang: ["8M"],
			thundershock: ["8L1", "7L11", "4L15"],
			thunderwave: ["8M", "8L24", "7M", "4M"],
			torment: ["7M", "4M"],
			toxic: ["7M", "4M"],
			trickroom: ["8M", "7M", "4M"],
			twister: ["8L1", "7L7", "4T"],
			voltswitch: ["8M", "7M"],
			waterfall: ["8M", "7M", "4M"],
			waterpulse: ["7T", "4M"],
			weatherball: ["8M"],
			whirlwind: ["8L16", "7L1", "4L1"],
			wildcharge: ["8M", "7M"],
			zapcannon: ["8L52", "7L59", "4L59"],
		},
	},
	dorsoil: {
		learnset: {
			ancientpower: ["4T"],
			aquatail: ["7T", "4T"],
			attract: ["8M", "7M", "4M"],
			bite: ["8L20", "5L10", "4L11"],
			block: ["7T"],
			bodypress: ["8M"],
			bodyslam: ["8M", "8L35", "7L42", "4T", "4L22"],
			bounce: ["8M", "4T"],
			brickbreak: ["8M", "7M", "4M"],
			brutalswing: ["8M", "7M"],
			bulldoze: ["8M", "7M"],
			captivate: ["4M"],
			chipaway: ["7E"],
			confide: ["7M"],
			crunch: ["8M", "8L45", "7L58", "4L55"],
			darkpulse: ["8M", "7M", "4M"],
			dig: ["8M", "4M"],
			dive: ["8M", "4T"],
			doubleedge: ["8E", "7E", "4T", "4E"],
			doubleteam: ["7M", "4M"],
			drillrun: ["8M"],
			earthpower: ["8M", "7T", "4T"],
			earthquake: ["8M", "7M", "4M"],
			embargo: ["7M", "4M"],
			encore: ["8M", "7E", "4E"],
			endure: ["8M", "4M"],
			facade: ["8M", "7M", "4M"],
			fakeout: ["8E", "7E", "4E"],
			firefang: ["8M", "7E", "4E"],
			fissure: ["8E", "7E", "4E"],
			flail: ["8E", "7E", "4E"],
			foulplay: ["8M", "7T"],
			frustration: ["7M", "4M"],
			hiddenpower: ["7M", "4M"],
			highhorsepower: ["8M"],
			irontail: ["8M", "7T", "4M"],
			knockoff: ["8L25", "7T", "4T"],
			leer: ["8L1", "7L1", "4L1"],
			magnitude: ["7L32", "4L42"],
			mudslap: ["8L5", "7L16", "4T"],
			naturalgift: ["4M"],
			payback: ["8M", "7M", "4M"],
			peck: ["8L1", "7L1", "4L1"],
			protect: ["8M", "7M", "4M"],
			pursuit: ["7L37", "4L29"],
			raindance: ["8M", "7M", "4M"],
			rapidspin: ["8L15", "7L21", "4L17"],
			rest: ["8M", "7M", "4M"],
			retaliate: ["8M", "7M"],
			return: ["7M", "4M"],
			roar: ["7M", "4M"],
			rockslide: ["8M", "7M", "4M"],
			rocksmash: ["7M", "4M"],
			rocktomb: ["8M", "7M"],
			rollout: ["4T"],
			round: ["8M", "7M"],
			sandstorm: ["8M", "7M", "4M"],
			sandtomb: ["8M", "8L10", "7E", "4E"],
			scorchingsands: ["8T"],
			screech: ["8M", "7E", "4E"],
			secretpower: ["7M", "4M"],
			sleeptalk: ["8M", "7M", "4M"],
			snarl: ["8M", "7M"],
			snatch: ["7M", "4M"],
			snore: ["8M", "7T", "4T"],
			spitup: ["8L40", "7L53", "4L48"],
			stockpile: ["8L40", "7L53", "4L48"],
			strength: ["8L30", "4M"],
			substitute: ["8M", "7M", "4M"],
			suckerpunch: ["8E", "7E", "4T", "4E"],
			sunnyday: ["8M", "7M", "4M"],
			superpower: ["8M", "7T", "4T"],
			swagger: ["7M", "4M"],
			swallow: ["8L40", "7L53", "4L48"],
			taunt: ["8M", "7M", "4M"],
			thunderfang: ["8M", "7E", "4E"],
			torment: ["7M"],
			toxic: ["7M", "4M"],
			uturn: ["8M", "7M", "4M"],
			wideguard: ["7E"],
		},
	},
	protowatt: {
		learnset: {
			bubble: ["7L1", "4L1"],
			charge: ["8L1", "7L1", "4L1"],
			confuseray: ["8L10", "7L11", "4L11"],
			counter: ["8E", "7E", "4E"],
			entrainment: ["8E", "7E"],
			followme: ["8E", "7E", "4E"],
			mirrorcoat: ["8E", "7E", "4E"],
			sheercold: ["8E", "7E", "4E"],
			thundershock: ["8L5", "7L5", "4L5"],
			watergun: ["8L1"],
		},
	},
	venomicon: {
		learnset: {
			aircutter: ["8L15"],
			airslash: ["8M"],
			assurance: ["8M"],
			attract: ["8M"],
			bodypress: ["8M"],
			bodyslam: ["8M"],
			bravebird: ["8M"],
			clearsmog: ["8E"],
			coil: ["8L50"],
			confuseray: ["8L10"],
			darkpulse: ["8M"],
			drillpeck: ["8L35"],
			dualwingbeat: ["8T"],
			endure: ["8M"],
			facade: ["8M"],
			fly: ["8M"],
			focusenergy: ["8M"],
			foulplay: ["8M"],
			gigaimpact: ["8M"],
			guardswap: ["8M"],
			gunkshot: ["8M"],
			hex: ["8M", "8L25"],
			hurricane: ["8M", "8L55"],
			hyperbeam: ["8M"],
			imprison: ["8M"],
			irondefense: ["8M"],
			knockoff: ["8E"],
			lashout: ["8T"],
			magicalleaf: ["8M"],
			magicroom: ["8M"],
			meanlook: ["8E"],
			memento: ["8E"],
			nastyplot: ["8M"],
			payback: ["8M"],
			peck: ["8L1"],
			phantomforce: ["8M"],
			poisonjab: ["8M", "8L40"],
			poisonsting: ["8L1"],
			powerswap: ["8M"],
			protect: ["8M"],
			psychic: ["8M"],
			psyshock: ["8M"],
			rest: ["8M"],
			retaliate: ["8M"],
			roost: ["8L45"],
			round: ["8M"],
			safeguard: ["8M"],
			scaryface: ["8M"],
			shadowball: ["8M"],
			skillswap: ["8M"],
			sleeptalk: ["8M"],
			sludgebomb: ["8M"],
			sludgewave: ["8M"],
			snore: ["8M"],
			stealthrock: ["8M"],
			steelwing: ["8M"],
			substitute: ["8M"],
			swift: ["8M"],
			thunderwave: ["8M"],
			toxic: ["8L30"],
			toxicspikes: ["8M"],
			trick: ["8M"],
			uturn: ["8M"],
			venomdrench: ["8M"],
			venoshock: ["8M", "8L20"],
			withdraw: ["8L5"],
		},
	},
	saharaja: {
		learnset: {
			attract: ["8M"],
			bodypress: ["8M"],
			diamondstorm: ["8L1"],
			earthquake: ["8M"],
			endure: ["8M"],
			facade: ["8M"],
			healbell: ["8L1"],
			hornleech: ["8L1"],
			painsplit: ["8L1"],
			protect: ["8M"],
			rapidspin: ["8L1"],
			rest: ["8M"],
			round: ["8M"],
			sleeptalk: ["8M"],
			snore: ["8M"],
			stealthrock: ["8M"],
			substitute: ["8M"],
			swordsdance: ["8M"],
			taunt: ["8M"],
		},
	},
	pokestarsmeargle: {
		eventData: [
			{generation: 5, level: 60, gender: "M", abilities: ["owntempo"], moves: ["mindreader", "guillotine", "tailwhip", "gastroacid"]},
			{generation: 5, level: 30, gender: "M", abilities: ["owntempo"], moves: ["outrage", "magiccoat"]},
			{generation: 5, level: 99, gender: "M", abilities: ["owntempo"], moves: ["nastyplot", "sheercold", "attract", "shadowball"]},
		],
	},
	pokestarufo: {
		eventData: [
			{generation: 5, level: 38, moves: ["bubblebeam", "counter", "recover", "signalbeam"]},
		],
	},
	pokestarufo2: {
		eventData: [
			{generation: 5, level: 47, moves: ["darkpulse", "flamethrower", "hyperbeam", "icebeam"]},
		],
	},
	pokestarbrycenman: {
		eventData: [
			{generation: 5, level: 56, moves: ["icebeam", "nightshade", "psychic", "uturn"]},
		],
	},
	pokestarmt: {
		eventData: [
			{generation: 5, level: 63, moves: ["earthquake", "ironhead", "spark", "surf"]},
		],
	},
	pokestarmt2: {
		eventData: [
			{generation: 5, level: 72, moves: ["dragonpulse", "flamethrower", "metalburst", "thunderbolt"]},
		],
	},
	pokestartransport: {
		eventData: [
			{generation: 5, level: 20, moves: ["clearsmog", "flameburst", "discharge"]},
			{generation: 5, level: 50, moves: ["iciclecrash", "overheat", "signalbeam"]},
		],
	},
	pokestargiant: {
		eventData: [
			{generation: 5, level: 99, moves: ["crushgrip", "focuspunch", "growl", "rage"]},
		],
	},
	pokestargiant2: {
		eventData: [
			{generation: 5, level: 99, moves: ["crushgrip", "doubleslap", "teeterdance", "stomp"]},
		],
	},
	pokestarhumanoid: {
		eventData: [
			{generation: 5, level: 20, gender: "M", moves: ["scratch", "shadowclaw", "acid"]},
			{generation: 5, level: 30, gender: "M", moves: ["darkpulse", "shadowclaw", "slash"]},
			{generation: 5, level: 20, gender: "F", moves: ["acid", "nightslash"]},
			{generation: 5, level: 20, gender: "M", moves: ["acid", "doubleedge"]},
			{generation: 5, level: 20, gender: "F", moves: ["acid", "rockslide"]},
			{generation: 5, level: 20, gender: "M", moves: ["acid", "thunderpunch"]},
			{generation: 5, level: 20, gender: "F", moves: ["acid", "icepunch"]},
			{generation: 5, level: 40, gender: "F", moves: ["explosion", "selfdestruct"]},
			{generation: 5, level: 40, gender: "F", moves: ["shadowclaw", "scratch"]},
			{generation: 5, level: 40, gender: "M", moves: ["nightslash", "scratch"]},
			{generation: 5, level: 40, gender: "M", moves: ["doubleedge", "scratch"]},
			{generation: 5, level: 40, gender: "F", moves: ["rockslide", "scratch"]},
		],
	},
	pokestarmonster: {
		eventData: [
			{generation: 5, level: 50, moves: ["darkpulse", "confusion"]},
		],
	},
	pokestarf00: {
		eventData: [
			{generation: 5, level: 10, moves: ["teeterdance", "growl", "flail", "chatter"]},
			{generation: 5, level: 58, moves: ["needlearm", "headsmash", "headbutt", "defensecurl"]},
			{generation: 5, level: 60, moves: ["hammerarm", "perishsong", "ironhead", "thrash"]},
		],
	},
	pokestarf002: {
		eventData: [
			{generation: 5, level: 52, moves: ["flareblitz", "ironhead", "psychic", "wildcharge"]},
		],
	},
	pokestarspirit: {
		eventData: [
			{generation: 5, level: 99, moves: ["crunch", "dualchop", "slackoff", "swordsdance"]},
		],
	},
	pokestarblackdoor: {
		eventData: [
			{generation: 5, level: 53, moves: ["luckychant", "amnesia", "ingrain", "rest"]},
			{generation: 5, level: 70, moves: ["batonpass", "counter", "flamecharge", "toxic"]},
		],
	},
	pokestarwhitedoor: {
		eventData: [
			{generation: 5, level: 7, moves: ["batonpass", "inferno", "mirrorcoat", "toxic"]},
		],
	},
	pokestarblackbelt: {
		eventData: [
			{generation: 5, level: 30, moves: ["focuspunch", "machpunch", "taunt"]},
			{generation: 5, level: 40, moves: ["machpunch", "hammerarm", "jumpkick"]},
		],
	},
	pokestargiantpropo2: {
		eventData: [
			{generation: 5, level: 99, moves: ["crushgrip", "doubleslap", "teeterdance", "stomp"]},
		],
	},
	pokestarufopropu2: {
		eventData: [
			{generation: 5, level: 47, moves: ["darkpulse", "flamethrower", "hyperbeam", "icebeam"]},
		],
	},
};
