import { ChangeEvent, FocusEvent, MouseEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import BlackOverlay from '../../../../components/BlackOverlay/BlackOverlay';
import { currentEditFieldPropertyState, currentEditFieldState, EditField } from '../../../../state/CustomizationPopupState';
import { currentNavigationState, enqueuedModifierState, NavigationState } from '../../../../state/NavigationState';
import { editingPkmnCustomizationDataState, selectedPkmnCustomizationDataState } from '../../../../state/PkmnState';
import { editPkmnPopupDisplayState, pkmnCustomizeBlackOverlayDisplayState } from '../../../../state/SectionVisibilityStates';
import CustomizePkmnEditPopup from './CustomizePkmnEditPopup';
import './CustomizePkmnPopup.scss';

export default function CustomizePkmnPopup(props: { translateY: string | undefined }): JSX.Element {
	const customizePkmnPopupDisplay = useRecoilValue(editPkmnPopupDisplayState);
	const internalOverlayDisplay = useRecoilValue(pkmnCustomizeBlackOverlayDisplayState);
	const setCurrentNavigation = useSetRecoilState(currentNavigationState);
	const enqueuedModifier = useRecoilValue(enqueuedModifierState);
	
	const setCurrentEditField = useSetRecoilState(currentEditFieldState);
	const setCurrentEditFieldProperty = useSetRecoilState(currentEditFieldPropertyState);
	
	const [level, setLevel] = useState<number>(100);
	const [shiny, setShiny] = useState<boolean>(false);
	const [ivs, setIVs] = useState<string[]>(new Array<string>(6).fill('0'));
	const [evs, setEVs] = useState<string[]>(new Array<string>(6).fill('0'));
	const setSelectedPkmnCustomization = useSetRecoilState(selectedPkmnCustomizationDataState);
	const [editingPkmnCustomization, setEditingPkmnCustomization] = useRecoilState(editingPkmnCustomizationDataState);

	const disabledFieldClickHandler = (e: MouseEvent<HTMLDivElement>) => {
		if (!e.currentTarget.className.includes('disabled'))
			return;

		toast.error(<span>This field is currently disabled.</span>, {
			position: toast.POSITION.TOP_CENTER
		});

		return e.preventDefault();
	};

	const editFieldFocusHandler = (e: FocusEvent<HTMLInputElement>) => {
		setCurrentEditField(e.target?.previousElementSibling?.innerHTML ?? '');
		setCurrentEditFieldProperty((e.currentTarget.getAttribute('customization_property') ?? '') as EditField);
		setCurrentNavigation(NavigationState.editingPkmnField);
	};

	const ivChangeHandler = (e: ChangeEvent<HTMLInputElement>, index: number) => {
		const value: string = e.currentTarget.value;
		const ivsClone = [...ivs];
		ivsClone[index] = value;
		setIVs([...ivsClone]);
	};

	const evChangeHandler = (e: ChangeEvent<HTMLInputElement>, index: number) => {
		const value: string = e.currentTarget.value;
		const evsClone = [...evs];
		evsClone[index] = value;
		setEVs([...evsClone]);
	};

	const onMoveClick = (e: MouseEvent<HTMLDivElement>) => {
		if (e.currentTarget?.className.includes('disabled')) {
			toast.error(<span>This field is currently disabled.</span>, {
				position: toast.POSITION.TOP_CENTER
			});
			return e.preventDefault();
		}

		setCurrentEditField('Move ' + e.currentTarget.getAttribute('id')?.replace('move', ''));
		setCurrentEditFieldProperty((e.currentTarget.getAttribute('customization_property') ?? '') as EditField);
		setCurrentNavigation(NavigationState.editingPkmnField);
	};

	const confirmButtonClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		// Validate local states
		if (isNaN(level) || level <= 0 || level > 100)
			return toast.error(<span>The provided level is invalid.</span>, { position: toast.POSITION.TOP_CENTER });
		
		let invalidIVs: boolean = false;
		ivs.forEach(iv => {
			const ivVal: number = parseInt(iv);

			if (isNaN(ivVal))
				invalidIVs = true;

			if (ivVal < 0 || ivVal > 31)
				invalidIVs = true;
		})
		if (invalidIVs)
			return toast.error(<span>The provided IVs are invalid.</span>, { position: toast.POSITION.TOP_CENTER });

		let invalidEVs: boolean = false;
		evs.forEach(ev => {
			const evVal: number = parseInt(ev);

			if (isNaN(evVal))
				invalidEVs = true;

			if (evVal < 0 || evVal > 255)
				invalidEVs = true;
		})
		if (invalidEVs)
			return toast.error(<span>The provided EVs are invalid.</span>, { position: toast.POSITION.TOP_CENTER });
		
		// Update the PKM currently being edited with local state values untied from Recoil (IVs/EVs, level etc.)
		setEditingPkmnCustomization(current => {
			const clone = current.clone();
			clone.level = level;
			clone.shiny = shiny;
			clone.ivs = ivs.map(iv => isNaN(parseInt(iv)) ? 0 : parseInt(iv));
			clone.evs = evs.map(ev => isNaN(parseInt(ev)) ? 0 : parseInt(ev));
			return clone;
		});

		// Update the selected PKM customization with temp. one.
		setSelectedPkmnCustomization(editingPkmnCustomization.clone());
		
		if (enqueuedModifier)
			setCurrentNavigation(NavigationState.enqueued);
		else
			setCurrentNavigation(NavigationState.mainPage);
	};

	return (
		<div className="section customize-pkmn" style={{ transform: props.translateY, display: customizePkmnPopupDisplay }}>
			<BlackOverlay style={{ display: internalOverlayDisplay }} />

			<CustomizePkmnEditPopup />

			{/* Level, gender etc. */}
			<div className="customize-row">
				<div className="labeled-textbox level" onClick={ disabledFieldClickHandler }>
					<span className="label">Level</span>
					<input type="text" value={ level } maxLength={3} onChange={ e => setLevel(parseInt(e.currentTarget.value)) } />
				</div>

				<div className="labeled-textbox gender clickable button" onClick={ disabledFieldClickHandler }>
					<span className="label">Gender</span>
					<input type="text" value={ editingPkmnCustomization?.gender } maxLength={6} onFocus={ editFieldFocusHandler } {...{customization_property: 'gender'}} readOnly />
				</div>

				<div className="labeled-textbox ability clickable button" onClick={ disabledFieldClickHandler }>
					<span className="label">Ability</span>
					<input type="text" value={ editingPkmnCustomization?.ability } maxLength={16} onFocus={ editFieldFocusHandler } {...{customization_property: 'ability'}} readOnly />
				</div>
			</div>

			{/* Item, shiny, import from showdown */}
			<div className="customize-row">
				<div className="labeled-textbox item clickable button" onClick={ disabledFieldClickHandler }>
					<span className="label">Item</span>
					<input type="text" value={ editingPkmnCustomization?.heldItem } maxLength={3} onFocus={ editFieldFocusHandler } {...{customization_property: 'heldItem'}} readOnly />
				</div>

				<div className="labeled-textbox shiny clickable button" onClick={ disabledFieldClickHandler }>
					<span className="label">Shiny</span>
					<input type='checkbox' className="checkbox-spacer" checked={ shiny } onChange={ e => setShiny(e.currentTarget.checked) } />
				</div>

				<div className="labeled-textbox showdown clickable button" onClick={ disabledFieldClickHandler }>
					<span className="label">Import from Showdown</span>
				</div>
			</div>

			{/* IVs */}
			<div className="customize-row ivs">
				<span>IVs</span>
				<div className="ivs-container">
					<div className="labeled-textbox hp" onClick={ disabledFieldClickHandler }>
						<span className="label">HP</span>
						<input type="text" value={ ivs[0] } maxLength={2} onChange={ e => ivChangeHandler(e, 0) } />
					</div>
					<div className="labeled-textbox attack" onClick={ disabledFieldClickHandler }>
						<span className="label">Attack</span>
						<input type="text" value={ ivs[1] } maxLength={2} onChange={ e => ivChangeHandler(e, 1) } />
					</div>
					<div className="labeled-textbox defense" onClick={ disabledFieldClickHandler }>
						<span className="label">Defense</span>
						<input type="text" value={ ivs[2] } maxLength={2} onChange={ e => ivChangeHandler(e, 2) } />
					</div>
					<div className="labeled-textbox sp-atk" onClick={ disabledFieldClickHandler }>
						<span className="label">Sp. Atk.</span>
						<input type="text" value={ ivs[3] } maxLength={2} onChange={ e => ivChangeHandler(e, 3) } />
					</div>
					<div className="labeled-textbox sp-def" onClick={ disabledFieldClickHandler }>
						<span className="label">Sp. Def.</span>
						<input type="text" value={ ivs[4] } maxLength={2} onChange={ e => ivChangeHandler(e, 4) } />
					</div>
					<div className="labeled-textbox speed" onClick={ disabledFieldClickHandler }>
						<span className="label">Speed</span>
						<input type="text" value={ ivs[5] } maxLength={2} onChange={ e => ivChangeHandler(e, 5) } />
					</div>
				</div>
			</div>

			{/* EVs */}
			<div className="customize-row evs">
				<span>EVs</span>
				<div className="evs-container">
					<div className="labeled-textbox hp" onClick={ disabledFieldClickHandler }>
						<span className="label">HP</span>
						<input type="text" value={ evs[0] } maxLength={3} onChange={ e => evChangeHandler(e, 0) } />
					</div>
					<div className="labeled-textbox attack" onClick={ disabledFieldClickHandler }>
						<span className="label">Attack</span>
						<input type="text" value={ evs[1] } maxLength={3} onChange={ e => evChangeHandler(e, 1) } />
					</div>
					<div className="labeled-textbox defense" onClick={ disabledFieldClickHandler }>
						<span className="label">Defense</span>
						<input type="text" value={ evs[2] } maxLength={3} onChange={ e => evChangeHandler(e, 2) } />
					</div>
					<div className="labeled-textbox sp-atk" onClick={ disabledFieldClickHandler }>
						<span className="label">Sp. Atk.</span>
						<input type="text" value={ evs[3] } maxLength={3} onChange={ e => evChangeHandler(e, 3) } />
					</div>
					<div className="labeled-textbox sp-def" onClick={ disabledFieldClickHandler }>
						<span className="label">Sp. Def.</span>
						<input type="text" value={ evs[4] } maxLength={3} onChange={ e => evChangeHandler(e, 4) } />
					</div>
					<div className="labeled-textbox speed" onClick={ disabledFieldClickHandler }>
						<span className="label">Speed</span>
						<input type="text" value={ evs[5] } maxLength={3} onChange={ e => evChangeHandler(e, 5) } />
					</div>
				</div>
			</div>

			{/* Moves */}
			<div className="customize-row moves">
				<span>Moves</span>
				<div className="moves-grid">
					<div 
						className="labeled-textbox move clickable button" 
						id="move1" 
						onClick={ onMoveClick } 
						{...{customization_property: 'move0'}}
					>
						<span>{ editingPkmnCustomization.moves[0] }</span>
					</div>
					<div 
						className="labeled-textbox move clickable button" 
						id="move2" 
						onClick={ onMoveClick } 
						{...{customization_property: 'move1'}}
					>
						<span>{ editingPkmnCustomization.moves[1] }</span>
					</div>
					<div 
						className="labeled-textbox move clickable button" 
						id="move3" 
						onClick={ onMoveClick } 
						{...{customization_property: 'move2'}}
					>
						<span>{ editingPkmnCustomization.moves[2] }</span>
					</div>
					<div 
						className="labeled-textbox move clickable button" 
						id="move4" 
						onClick={ onMoveClick } 
						{...{customization_property: 'move3'}}
					>
						<span>{ editingPkmnCustomization.moves[3] }</span>
					</div>
				</div>
			</div>

			{/* Confirm button */}
			<div className="customize-row confirm">
				<div className="confirm-button" onClick={ confirmButtonClickHandler }>
					<span>Confirm</span>
				</div>
			</div>
		</div>
	)
}