import { MouseEvent, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { popupModifierState } from '../../state/NavigationState';
import './InteractivePopup.scss';

export enum InteractivePopupType {
	confirm,
	input
}

export default function InteractivePopup(props: {
	popupType: InteractivePopupType,
	name: string,
	title: string,
	message: string,
	inputPlaceholder?: string,
	goHandler?: () => any,
	okayHandler?: () => any,
	cancelHandler?: () => any
}): JSX.Element {
	const [inputValue, setInputValue] = useState<string>('');
	const popupModifier = useRecoilValue(popupModifierState);
	const shouldShow: boolean = popupModifier?.name === props.name;

	const goButtonClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		await popupModifier?.goButtonHandler?.(inputValue).catch(() => undefined);
	};

	const okayButtonClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		await popupModifier?.okButtonHandler?.().catch(() => undefined);
	};

	const cancelButtonClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		await popupModifier?.cancelButtonHandler?.().catch(() => undefined);
	};

	return (
		<div className="interactive-popup" style={{ display: shouldShow ? 'flex' : 'none' }}>
			<div className="title">
				<span>{ props.title }</span>
			</div>
			<div className="message-body">
				<span>{ props.message }</span>

				{ props.popupType === InteractivePopupType.input && <div className="input-wrapper">
					<input type="text" placeholder={ props.inputPlaceholder } onChange={ e => setInputValue(e.currentTarget.value) } value={ inputValue } />
				</div> }
			</div>
			<div className="actions">
				{ props.popupType === InteractivePopupType.confirm && <div className="confirm-actions">
					<div className="button button-cancel" onClick={ cancelButtonClickHandler }><span>Cancel</span></div>
					<div className="button button-okay" onClick={ okayButtonClickHandler }><span>Okay!</span></div>				
				</div> }
				{ props.popupType === InteractivePopupType.input && <div className="input-actions">
					<div className="button button-go" onClick={ goButtonClickHandler }><span>Go!</span></div>
				</div> }
			</div>
		</div>
	);
};