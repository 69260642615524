import { atom, selector } from "recoil";
import EditEntriesFactory from "../services/EditEntriesFactory";
import BilinearSearch from "../services/BinarySearch";
import DexSearch from "../services/DexSearch";
import { currentEditFieldPropertyState, EditField } from "./CustomizationPopupState";
import { selectedPkmnNameState } from "./PkmnState";

//#region PKM SEARCH
export const pkmnSearchStringState = atom<string>({
	key: 'pkmnSearchStringState',
	default: ''
});

export const pkmnSearchResultsState = selector<Array<string>>({
	key: 'pkmnSearchResultsState',
	get: ({get}) => {
		const pkmnSearch: string = get(pkmnSearchStringState);
		return DexSearch.search(pkmnSearch.toLowerCase(), 5);
	}
});

export const pkmSearchLoadingGridDisplayState = selector<string>({
	key: 'loadingGridDisplayState',
	get: ({get}) => {
		const searchResultsLength: number = get(pkmnSearchResultsState).length;
		return searchResultsLength === 0 ? 'block' : 'none';
	}
});
//#endregion PKM SEARCH

export const editFieldSearchValueState = atom<string>({
	key: 'editFieldSearchValueState',
	default: ''
});

export const editFieldSearchResultsState = selector<string[]>({
	key: 'editFieldSearchResultsState',
	get: ({get}) => {
		const editFieldValue: string 					= get(editFieldSearchValueState);
		const selectedEditField: EditField | undefined 	= get(currentEditFieldPropertyState);
		const selectedPkmn: string | undefined 			= get(selectedPkmnNameState);

		if (!selectedPkmn || !selectedEditField)
			return [];

		if (selectedEditField === 'heldItem' || selectedEditField.match(/move/i) !== null)
			return [...new Set(BilinearSearch.search(EditEntriesFactory.generateEditEntries(selectedPkmn, selectedEditField), editFieldValue, 10, false))];
		else
			return EditEntriesFactory.generateEditEntries(selectedPkmn, selectedEditField);
	}
});

export const editFieldSearchLoadingIconDisplayState = selector<string>({
	key: 'editFieldSearchLoadingIconDisplayState',
	get: ({get}) => {
		const searchValue: string = get(editFieldSearchValueState);
		const selectedEditField: EditField | undefined 	= get(currentEditFieldPropertyState);

		if (selectedEditField !== 'heldItem' && selectedEditField?.match(/move/i) === null)
			return 'none';

		return (searchValue !== '' && searchValue !== null && searchValue !== undefined) ? 'none' : 'flex';
	}
});