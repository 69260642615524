import { MouseEvent, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Queue } from '../services/model/Queue';
import PKMManager from '../services/PKMManager';
import QueueAPI from '../services/QueueAPI';
import { currentNavigationState, enqueuedModifierState, NavigationState, popupModifierState } from '../state/NavigationState';
import { legendaryQueueInfoState, normalQueueInfoState } from '../state/NetworkState';
import { selectedPkmnCustomizationDataState } from '../state/PkmnState';
import { enqueuedSectionDisplayState } from '../state/SectionVisibilityStates';
import './EnqueuedSection.scss';

export default function EnqueuedSection(): JSX.Element {
	const enqueuedSectionDisplay: string 			= useRecoilValue(enqueuedSectionDisplayState);
	const [currentNavgation, setCurrentNavigation] 	= useRecoilState(currentNavigationState);
	const setEnqueuedEditingModifier 				= useSetRecoilState(enqueuedModifierState);
	const setPopupModifier							= useSetRecoilState(popupModifierState);
	const [pkmnCustomizationData, setPkmnCustData]	= useRecoilState(selectedPkmnCustomizationDataState);
	
	const normalQ: Queue | undefined				= useRecoilValue(normalQueueInfoState);
	const normalQEstWait: number 					= Math.round(((normalQ?.queuedUsers?.length ?? 0) * 47) / 60 * 100) / 100;

	const legendaryQ: Queue | undefined				= useRecoilValue(legendaryQueueInfoState);
	const legendaryQEstWait: number 				= Math.round(((legendaryQ?.queuedUsers?.length ?? 0) * 41) / 60 * 100) / 100;

	const joinedQueue: string 						= (normalQ?.position ?? -1) !== -1 ? 'normal' : 'legendary';
	const queueSelector: () => Queue | undefined 	= () => joinedQueue === 'normal' ? normalQ : legendaryQ;

	useEffect(() => {
		if (!pkmnCustomizationData)
			setPkmnCustData(PKMManager.fetch());

		const interval = window.setInterval(() => {
			if (currentNavgation !== NavigationState.enqueued)
				return;

			window.location.reload();
		}, 15 * 1000);
		return () => {
			clearInterval(interval);
		}
	});

	const leaveQueueClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		setPopupModifier({
			name: 'leave-popup', 
			okButtonHandler: async () => {
				console.log('clicked #2');
				const res = await QueueAPI.leave(joinedQueue).catch((ex: Error) => ex);
				
				if (res instanceof Error) {
					toast.error(<span>There was an error while joining the {joinedQueue} queue: {res.message}</span>, { position: toast.POSITION.TOP_CENTER });
					return;
				}

				if (res.status === 'error') {
					toast.error(<span>There was an error while joining the {joinedQueue} queue: {res.message}</span>, { position: toast.POSITION.TOP_CENTER });	
					return;
				}
					
				toast(<span>You have left the {joinedQueue} queue!</span>, { position: toast.POSITION.TOP_CENTER });

				console.log('finished #2');
				
				setCurrentNavigation(NavigationState.mainPage);
				setEnqueuedEditingModifier(false);
				setPopupModifier(undefined);
				
				//REVIEW - Definitely needs to be improved.
				window.location.reload();
			},
			cancelButtonHandler: async () => {
				setPopupModifier(undefined);
			}
		});
	};

	const editPkmnClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		setCurrentNavigation(NavigationState.editingPkmn);
		setEnqueuedEditingModifier(true);
	};

	return (
		<div className="section enqueued-section" style={{ display: enqueuedSectionDisplay }}>
			<span className="section-title">3. Wait for your turn!</span>

			<div className="info-edit">
				<span className="est-wait">Est. wait time: { joinedQueue === 'normal' ? normalQEstWait : legendaryQEstWait }m</span>
				<div className="edit-pkmn button" onClick={ editPkmnClickHandler }><span>Edit Pokémon</span></div>
			</div>

			<div className="queue-info">
				<span className="queue-name">{ joinedQueue[0].toUpperCase() + joinedQueue.slice(1) } Queue</span>
				<span className="queue-position">{ queueSelector()?.position ?? -1 }/{ queueSelector()?.maxQueueLength }</span>
			</div>

			<div className="leave-button button" onClick={ leaveQueueClickHandler }>
				<span>Leave Queue</span>
			</div>
		</div>
	);
};