import { atom } from "recoil";

export type EditField = 'gender' | 'ability' | 'heldItem' | 'move0' | 'move1' | 'move2' | 'move3';

export const currentEditFieldState = atom<string | undefined>({
	key: 'currentEditFieldState',
	default: undefined
});

export const currentEditFieldPropertyState = atom<EditField | undefined>({
	key: 'currentEditFieldPropertyState',
	default: undefined
});