import { atom } from "recoil";

/**
 * Defines navigation states. Each navigation state describes the current status of the application,
 * from which, thus, determine which sections or popups to show or not.
 */
export enum NavigationState {
	mainPage,
	searchingPkmn,
	editingPkmn,
	editingPkmnField,
	enqueued,
	trading
};

/**
 * Defines the current navigation state.
 */
export const currentNavigationState = atom<NavigationState>({
	key: 'currentNavigationState',
	default: NavigationState.mainPage
});

/**
 * Determines whether the "editPkmn" popup is shown from an enqueued state or not.
 */
export const enqueuedModifierState = atom<boolean>({
	key: 'enqueuedEditingModifier',
	default: false
});

/**
 * Determines whether an interactive popup is currently shown or not, and which, expressed by the `InteractivePopupType`.
 */
export const popupModifierState = atom<{name: string, okButtonHandler?: () => Promise<void>, cancelButtonHandler?: () => Promise<void>, goButtonHandler?: (inputValue: string | null) => Promise<void>} | undefined>({
	key: 'popupModifierState',
	default: undefined
});