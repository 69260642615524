import { RecoilRoot } from 'recoil';
import './App.scss';
import './App.Desktop.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainPage from './pages/MainPage';
import { Suspense } from 'react';
import AdminPage from './pages/AdminPage';
import SwitchAdminPage from './pages/SwitchAdminPage';

const router = createBrowserRouter([
  {
    path: '*',
    element: <MainPage />,
    errorElement: <></>
  },
  {
    path: '/admin',
    element: (
      <Suspense>
        <AdminPage />
      </Suspense>
    ),
    errorElement: <></>
  },
  {
    path: '/switch-admin',
    element: (
      <Suspense>
        <SwitchAdminPage />
      </Suspense>
    ),
    errorElement: <></>
  }
]);

function App() {
  return (
      <div className="App">
        <RecoilRoot>
          <RouterProvider router={router}/>
        </RecoilRoot>
      </div>
  );
}

export default App;
