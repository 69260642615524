import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentNavigationState, enqueuedModifierState, NavigationState } from "../state/NavigationState";
import { legendaryQueueInfoState, normalQueueInfoState } from "../state/NetworkState";

export default function NavCustomRouter(): JSX.Element {
	const setEnqueuedModifier 	= useSetRecoilState(enqueuedModifierState);
	const setCurrentNavigation 	= useSetRecoilState(currentNavigationState);

	const normalQInfo 			= useRecoilValue(normalQueueInfoState);
	const normalQPosition		= normalQInfo?.position ?? -1;
	const normalQTrading		= normalQPosition !== -1 && normalQPosition <= (normalQInfo?.maxTreasuryLength ?? -1);

	const legendaryQInfo 		= useRecoilValue(legendaryQueueInfoState);
	const legendaryQPosition	= legendaryQInfo?.position ?? -1;
	const legendaryQTrading		= legendaryQPosition !== -1 && legendaryQPosition <= (legendaryQInfo?.maxTreasuryLength ?? -1);

	if (!normalQInfo && !legendaryQInfo) {
		setCurrentNavigation(NavigationState.mainPage);
		setEnqueuedModifier(false);
	}

	const isEnqueued: boolean 	= normalQPosition !== -1 || legendaryQPosition !== -1;
	const isTrading: boolean 	= isEnqueued && (normalQTrading || legendaryQTrading);
	console.log(`enqueued: ${isEnqueued}, trading: ${isTrading}`, normalQInfo, legendaryQInfo);

	useEffect(() => { 
		if (isTrading) {
			setCurrentNavigation(NavigationState.trading);
		} else if (isEnqueued) {
			setCurrentNavigation(NavigationState.enqueued);
			setEnqueuedModifier(true);
		} else {
			setCurrentNavigation(NavigationState.mainPage);
			setEnqueuedModifier(false);
		}
	});
	
	return <></>;
};