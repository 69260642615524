import { PKMCustomizationData } from "./model/PKMCustomizationData";

class PKMManager {
	private readonly customizationDataKey: string = 'andreams:pkmnCustomizationData';

	/**
	 * Given a PKMCustomizationData object, this saves it JSON-encoded in LocalStorage for retrieval across different lifecycles.
	 */
	public save(pkmnCustomizationData: PKMCustomizationData) {
		window.localStorage.setItem(this.customizationDataKey, JSON.stringify(pkmnCustomizationData));
	}

	/**
	 * Fetches the PKMCustomizationData saved in LocalStorage. Returns `undefined` if not present.
	 */
	public fetch(): PKMCustomizationData | undefined {
		try {
			return JSON.parse(window.localStorage.getItem(this.customizationDataKey)!);
		} catch {
			return undefined;
		}
	}

	/**
	 * Clears the PKMCustomizationData that was saved in LocalStorage.
	 */
	public clear(): void {
		window.localStorage.removeItem(this.customizationDataKey);
	}
};

export default new PKMManager();