import moment from "moment";
import { PASSPORT_URI } from "../config";
import { AuthStatus } from "../state/UserState";

class UserManager {
	/**
	 * The LocalStorage `userGroup` cached value.
	 */
	private _lsUserGroup: string | null | undefined;

	/**
	 * The LocalStorage `exp` cached value.
	 */
	private _lsExp: string | null | undefined;

	/**
	 * Fetches the `AuthStatus` JSON-string from LocalStorage.
	 */
	private fetchUserGroup(): string | null | undefined {
		if (!this._lsUserGroup)
			this._lsUserGroup = window.localStorage.getItem('andreams:userGroup');

		return this._lsUserGroup;
	}

	/**
	 * Fetches the exp. date from LocalStorage.
	 */
	private fetchExp(): string | null | undefined {
		if (!this._lsExp)
			this._lsExp = window.localStorage.getItem('andreams:userGroup:exp');

		return this._lsExp;
	}

	/**
	 * Determines whether the saved `AuthStatus` JSON-string is expired or not.
	 */
	private isUserGroupExpired(): boolean {
		if (!this.fetchExp())
			return false;
		
		if (typeof this.fetchExp() !== 'string')
			return false;

		if (isNaN(Date.parse(this.fetchExp()!)))
			return false;

		return moment().isAfter(moment(this._lsExp));
	}

	/**
	 * Fetches the /userGroup. Returns LocalStorage-persisted object if present, calls the Passport API otherwise.
	 * @returns `undefined` if:
	 * - The /userGroup API call fails,
	 * - The persisted `AuthStatus` object is expired.
	 */
	public async getUserGroup(): Promise<AuthStatus | undefined> {
		if (!this.fetchExp() && !this.fetchUserGroup()) {
			const response: Response | undefined = await fetch(`${PASSPORT_URI}/userGroup`, {
				credentials: 'include'
			}).catch(() => undefined);
			const jsonResponse: AuthStatus | undefined = await response?.json().catch(() => undefined) as (AuthStatus | undefined);

			// Resilience to weird Passport bug where `isGuest` can be `true` along other fields.
			if (jsonResponse?.isGuest === true && (jsonResponse.isMod === true || jsonResponse.isSub !== false))
				return;

			if (jsonResponse !== undefined && ('isSub' in jsonResponse)) {
				window.localStorage.setItem('andreams:userGroup', JSON.stringify(jsonResponse));
				window.localStorage.setItem('andreams:userGroup:exp', moment().add(7, 'days').toISOString());
			}
		}

		if (!this.fetchUserGroup())
			return;
		
		if (this.isUserGroupExpired()) {
			window.localStorage.removeItem('andreams:userGroup');
			window.localStorage.removeItem('andreams:userGroup:exp');
			return;
		}

		return JSON.parse(this.fetchUserGroup()!) as AuthStatus;
	}

	/**
	 * Clears all LocalStorage cache entries.
	 */
	public clearCache(): void {
		window.localStorage.removeItem('andreams:userGroup');
		window.localStorage.removeItem('andreams:userGroup:exp');
	}
}

export default new UserManager();