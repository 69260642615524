import moment from 'moment';
import { MouseEvent, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { BACK_END_URI } from '../config';
import { API } from '../services/API';
import { Queue } from '../services/model/Queue';
import QueueAPI from '../services/QueueAPI';
import { currentNavigationState, NavigationState, popupModifierState } from '../state/NavigationState';
import { normalQueueInfoState, legendaryQueueInfoState } from '../state/NetworkState';
import { tradeSectionDisplayState } from '../state/SectionVisibilityStates';
import './TradeSection.scss';

export default function TradeSection(): JSX.Element {
	const tradeSectionDisplay: string = useRecoilValue(tradeSectionDisplayState);
	const [currentNavigation, setCurrentNavigation] = useRecoilState(currentNavigationState);
	const setPopupModifier = useSetRecoilState(popupModifierState);
	const ttkRef = useRef<HTMLSpanElement>(null);

	const normalQ: Queue | undefined				= useRecoilValue(normalQueueInfoState);
	const legendaryQ: Queue | undefined				= useRecoilValue(legendaryQueueInfoState);

	const joinedQueueName: string 					= (normalQ?.position ?? -1) !== -1 ? 'normal' : 'legendary';
	const queueSelector: () => Queue | undefined 	= () => joinedQueueName === 'normal' ? normalQ : legendaryQ;
	const joinedQueue: Queue | undefined 			= queueSelector();

	const insertCodeButtonClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		setPopupModifier({
			name: 'insert-code-popup',
			goButtonHandler: async (inputValue) => {
				if (!inputValue?.match(/^\d{4}[\s-]?\d{4}$/)) {
					toast.error(<span>The trade link code must be made of 8 digits, and can be additionally separated by a space or dash (-).<br/>i.e.: 8888-8888, or 8888 8888, or 88888888.</span>, {
						position: toast.POSITION.TOP_CENTER
					});
					return;
				}

				const result = await API.get<any>(BACK_END_URI + '/queue/setLinkTradeCode', new URLSearchParams({
					queueName: joinedQueueName,
					linkTradeCode: inputValue
				})).catch(_ => undefined);

				console.log(result);

				setPopupModifier(undefined);
			}
		});
	};

	const leaveButtonClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		setPopupModifier({
			name: 'leave-popup',
			okButtonHandler: async () => {
				const res = await QueueAPI.leave(joinedQueueName).catch((ex: Error) => ex);
				
				if (res instanceof Error) {
					toast.error(<span>There was an error while joining the {joinedQueueName} queue: {res.message}</span>, { position: toast.POSITION.TOP_CENTER });
					return;
				}

				if (res.status === 'error') {
					toast.error(<span>There was an error while joining the {joinedQueueName} queue: {res.message}</span>, { position: toast.POSITION.TOP_CENTER });	
					return;
				}
					
				toast(<span>You have left the {joinedQueueName} queue!</span>, { position: toast.POSITION.TOP_CENTER });
				
				setCurrentNavigation(NavigationState.mainPage);
				setPopupModifier(undefined);
				
				//REVIEW - Definitely needs to be improved.
				window.location.reload();
			},
			cancelButtonHandler: async () => {
				setPopupModifier(undefined);
			}
		});
	};
	
	useEffect(() => {
		if (!joinedQueue)
			return;

		if (!joinedQueue.autoKickEnabled)
			return;

		const kickTimeoutTimer = window.setInterval(() => {
			if (currentNavigation !== NavigationState.trading)
				return;

			const ttk: number = joinedQueue.timeToKick;

			if (!ttkRef.current)
				return;

			const timeDifference: number = moment(joinedQueue.timeAccessedRes).add(ttk, 'seconds').diff(moment());
			ttkRef.current.innerHTML = moment.utc(timeDifference).format('mm:ss');
		}, 1000);

		return () => {
			window.clearInterval(kickTimeoutTimer);
		};
	});

	return (
		<div className="section trade-section" style={{ display: tradeSectionDisplay }}>
			<span className="section-title">4. Trade!</span>

			<div className="message-wrapper">
				<span className="queue-name">Legendary Queue</span>
				<span className="message">It's your turn!</span>
			</div>

			<div className="timeout" style={{ display: joinedQueue?.autoKickEnabled ? 'flex' : 'none' }}>
				<span className="section-title">Time left: </span>
				<span className="time-left" ref={ ttkRef }>00:00</span>
			</div>

			<div className="actions">
				<div className="insert-code-button button" onClick={ insertCodeButtonClickHandler }><span>Insert code</span></div>
				<div className="leave-button button" onClick={ leaveButtonClickHandler }><span>Leave queue</span></div>
			</div>
		</div>
	);
};