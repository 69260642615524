import { atom, selector } from "recoil";
import { BACK_END_URI } from "../config";
import { API, StatusResponse } from "../services/API";
import Cache from "../services/Cache";
import { Queue } from "../services/model/Queue";
import QueueAPI from "../services/QueueAPI";

/**
 * Update this value however desired to invalidate the `normalQueueInfoState` and fetch a new value from the back-end.
 */
export const normalQueueInfoInvalidationState = atom<number>({ key: 'normalQueueInfoInvalidationState', default: 0 });
export const normalQueueInfoState = selector<Queue | undefined>({
	key: 'normalQueueInfoState',
	get: async ({get}) => {
		get(legendaryQueueInfoInvalidationState);
		
		if (Cache.normalQueue === undefined)
			Cache.normalQueue = await QueueAPI.getQueueInfo('normal');
		return Cache.normalQueue;
	},
	set: ({get, set}) => {
		set(normalQueueInfoInvalidationState, get(normalQueueInfoInvalidationState) + 1);
		Cache.normalQueue = undefined;
	}
});

/**
 * Update this value however desired to invalidate the `normalQueueInfoState` and fetch a new value from the back-end.
 */
export const legendaryQueueInfoInvalidationState = atom<number>({ key: 'legendaryQueueInfoInvalidationState', default: 0 });
export const legendaryQueueInfoState = selector<Queue | undefined>({
	key: 'legendaryQueueInfoState',
	get: async ({get}) => {
		get(legendaryQueueInfoInvalidationState);

		if (Cache.legendaryQueue === undefined)
			Cache.legendaryQueue = await QueueAPI.getQueueInfo('legendary');
		return Cache.legendaryQueue;
	},
	set: ({get, set}) => {
		set(legendaryQueueInfoInvalidationState, get(legendaryQueueInfoInvalidationState) + 1);
		Cache.normalQueue = undefined;
	}
});

export const currentlyStreamingState = selector<boolean | undefined>({
	key: 'currentlyStreamingState',
	get: async () => {
		const url: string = BACK_END_URI + '/isLive'
		return (await API.get<{isLive: boolean}>(url).catch(_ => undefined))?.isLive;
	}
});

export const switchStatusInvalidationState = atom<number>({ key: 'switchStatusInvalidationState', default: 0 });
export const switchStatusState = selector<{nsName: string, online: boolean}[] | undefined>({
	key: 'switchStatusState',
	get: async ({get}) => {
		get(switchStatusInvalidationState);

		const url: string = BACK_END_URI + '/dispatcher/onlineStatus';
		const response: {nsName: string, online: boolean}[] | StatusResponse = await API.get<any>(url).catch(_ => undefined);

		if ('status' in response && 'message' in response)
			return undefined;

		return response instanceof Error ? undefined : response;
	}
});

export const botsStateStatusInvalidationState = atom<number>({ key: 'botsStateStatusInvalidationState', default: 0 });
export const botsStateState = selector<{nsName: string, state: string}[] | undefined>({
	key: 'botsStateState',
	get: async ({get}) => {
		get(botsStateStatusInvalidationState);

		const url: string = BACK_END_URI + '/dispatcher/botsState';
		const response: {nsName: string, state: string}[] | StatusResponse = await API.get<any>(url).catch(_ => undefined);

		if ('status' in response && 'message' in response)
			return undefined;

		return response instanceof Error ? undefined : response;
	}
});