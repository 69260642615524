import { selector } from "recoil";
import { currentNavigationState, enqueuedModifierState, NavigationState, popupModifierState } from "./NavigationState";

/**
 * Maps the black overlay visibility to its CSS `display` property value.
 */
export const blackOverlayDisplayState = selector<string>({
	key: 'blackOverlayDisplayState',
	get: ({get}) => {
		const currentNavigation: NavigationState = get(currentNavigationState);
		const popupModifier = get(popupModifierState);

		const shouldShow: boolean = [
			NavigationState.editingPkmn, 
			NavigationState.editingPkmnField, 
			NavigationState.searchingPkmn
		].includes(currentNavigation) || popupModifier !== undefined;

		return shouldShow ? 'block' : 'none';
	}
});

/**
 * Maps the black overlay visibility to its CSS `display` property value.
 */
export const pkmnSearchBlackOverlayDisplayState = selector<string>({
	key: 'pkmnSearchBlackOverlayDisplayState',
	get: ({get}) => {
		const currentNavigation: NavigationState = get(currentNavigationState);
		return currentNavigation === NavigationState.editingPkmnField ? 'block' : 'none';
	}
});

/**
 * Maps the black overlay visibility to its CSS `display` property value.
 */
export const pkmnCustomizeBlackOverlayDisplayState = selector<string>({
	key: 'pkmnCustomizeBlackOverlayDisplayState',
	get: ({get}) => {
		const currentNavigation: NavigationState = get(currentNavigationState);
		const shouldShow: boolean = [
			NavigationState.editingPkmnField
		].includes(currentNavigation);
		
		return shouldShow ? 'block' : 'none';
	}
});

/**
 * Maps the black overlay visibility to its CSS `display` property value.
 */
export const searchResultsPopupDisplayState = selector<string>({
	key: 'searchResultsPopupVisibleDisplayState',
	get: ({get}) => {
		const currentNavigation = get(currentNavigationState);
		return currentNavigation === NavigationState.searchingPkmn ? 'flex' : 'none';
	}
});

/**
 * Maps the edit Pokémon visibility to its CSS `display` property value.
 */
export const editPkmnPopupDisplayState = selector<string>({
	key: 'editPkmnPopupDisplayState',
	get: ({get}) => {
		const currentNavigation: NavigationState = get(currentNavigationState);
		const shouldShow: boolean = [
			NavigationState.editingPkmn,
			NavigationState.editingPkmnField
		].includes(currentNavigation);

		return shouldShow ? 'flex' : 'none';
	}
});

/**
 * Maps the field edit Pokémon visibility to its CSS `display` property value.
 */
export const editPkmnFieldPopupDisplayState = selector<string>({
	key: 'editPkmnFieldPopupDisplayState',
	get: ({get}) => {
		const currentNavigation = get(currentNavigationState);
		const shouldShow: boolean = [
			NavigationState.editingPkmnField
		].includes(currentNavigation);

		return shouldShow ? 'flex' : 'none';
	}
});

/**
 * Maps the ChoosePkmnSection visibility to its CSS `display` property value.
 */
 export const choosePkmnSectionDisplayState = selector<string>({
	key: 'choosePkmnSectionDisplayState',
	get: ({get}) => {
		const currentNavigation = get(currentNavigationState);
		const shouldShow: boolean = [
			NavigationState.mainPage, 
			NavigationState.editingPkmn, 
			NavigationState.editingPkmnField,
			NavigationState.searchingPkmn
		].includes(currentNavigation);

		return shouldShow ? 'flex' : 'none';
	}
});

/**
 * Maps the PickQueueSection visibility to its CSS `display` property value.
 */
 export const pickQueueSectionDisplayState = selector<string>({
	key: 'pickQueueSectionDisplayState',
	get: ({get}) => {
		const currentNavigation = get(currentNavigationState);
		const enqueuedModifier = get(enqueuedModifierState);

		const shouldShow: boolean = [
			NavigationState.mainPage, 
			NavigationState.editingPkmn, 
			NavigationState.editingPkmnField, 
			NavigationState.searchingPkmn
		].includes(currentNavigation);

		return (shouldShow && !enqueuedModifier) ? 'flex' : 'none';
	}
});

/**
 * Maps the EnqueuedSection visibility to its CSS `display` property value.
 */
 export const enqueuedSectionDisplayState = selector<string>({
	key: 'enqueuedSectionDisplayState',
	get: ({get}) => {
		const currentNavigation = get(currentNavigationState);
		return currentNavigation === NavigationState.enqueued ? 'flex' : 'none';
	}
});

/**
 * Maps the TradeSection visibility to its CSS `display` property value.
 */
 export const tradeSectionDisplayState = selector<string>({
	key: 'tradeSectionDisplayState',
	get: ({get}) => {
		const currentNavigation = get(currentNavigationState);
		return currentNavigation === NavigationState.trading ? 'flex' : 'none';
	}
});