import ChoosePkmnSection from "./ChoosePkmnSection/ChoosePkmnSection";
import EnqueuedSection from "./EnqueuedSection";
import PickQueueSection from "./PickQueueSection";
import './Sections.scss';
import TradeSection from "./TradeSection";

export default function Sections(): JSX.Element {
	return (
		<div className="sections">
			<ChoosePkmnSection />
			<PickQueueSection />
			<EnqueuedSection />
			<TradeSection />
		</div>
	);
};