import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Footer from '../components/Footer/Footer';
import MenuBar from '../components/MenuBar/MenuBar';
import { AuthStatus, userAuthStatusState } from '../state/UserState';
import { Queue, QueueUser } from '../services/model/Queue.js';
import './AdminPage.scss';
import InteractivePopup, { InteractivePopupType } from '../components/InteractivePopup/InteractivePopup';
import { popupModifierState } from '../state/NavigationState';
import BlackOverlay from '../components/BlackOverlay/BlackOverlay';
import { legendaryQueueInfoState, normalQueueInfoState } from '../state/NetworkState';
import QueueAPI from '../services/QueueAPI';
import { StatusResponse } from '../services/API';
import { ChosenPKMInputType, ChosenPKMWrapper } from '../services/ChosenPKMWrapper';

type InputsSnapshot = {
	[index: string]: string;
	ttk: string;
	maxQLength: string;
	maxTreasuryLength: string;
}

function toastInfo(message: string) {
	toast(<span>{message}</span>, { position: toast.POSITION.TOP_CENTER });
}

function toastError(message: string) {
	toast.error(<span>{message}</span>, { position: toast.POSITION.TOP_CENTER });
}

function makeError(message: string): StatusResponse {
	return {
		status: 'error',
		message: message
	};
}

function EnqueuedUser(props: { queueName: string, user: QueueUser }): JSX.Element {
	const setPopupModifier = useSetRecoilState(popupModifierState);
	const invalidateNormalQueueInfo = useSetRecoilState(normalQueueInfoState);
	const invalidateLegendaryQueueInfo = useSetRecoilState(legendaryQueueInfoState);
	const invalidateQueuesInfo = () => {
		invalidateNormalQueueInfo(undefined);
		invalidateLegendaryQueueInfo(undefined);
	};

	const xBtnClickHandler = (_: MouseEvent<HTMLSpanElement>) => {
		setPopupModifier({
			name: 'kick-popup', 
			okButtonHandler: async () => {
				const response: StatusResponse = await QueueAPI.kick(props.queueName, props.user.uid).catch(ex => makeError(ex.message));
				invalidateQueuesInfo();		
				if (response?.status === 'okay') {
					alert(`The user [${props.user.uid}, ${props.user.username}] was successfully kicked from the queue.`);
				} else
					return alert(`There was an error while kicking the user [${props.user.uid}, ${props.user.username}] from the ${props.queueName} queue: ${response?.message}`);
				setPopupModifier(undefined);
			},
			cancelButtonHandler: async () => {
				setPopupModifier(undefined);
			}
		});
	};

	return (
		<div className="enqueued-user">
			<span className="kick button" onClick={ xBtnClickHandler }>X</span>
			<span 
				className="username" 
				{...{
					uid: props.user.uid, 
					time_joined: props.user.timeJoined, 
					time_accessed_resource: props.user.timeAccessedResource
				}}
			>{ props.user.username }</span>
		</div>
	);
}

function QueueControls(props: { name: string, collapsed: boolean, queue?: Queue }): JSX.Element {
	const queueClassID: string = props.name.toLowerCase().replace(/(queue|\s*)/i, '');

	const [timeToKickValue, setTimeToKickValue] = useState<string>(props.queue?.timeToKick.toString() ?? '');
	const [maxQLengthValue, setMaxQLengthValue] = useState<string>(props.queue?.maxQueueLength.toString() ?? '');
	const [maxTreasuryLengthValue, setMaxTreasuryLengthValue] = useState<string>(props.queue?.maxTreasuryLength.toString() ?? '');
	const [testUsersValue, setTestUsersValue] = useState<string>('5');
	const [testUsersVisible, setTestUsersVisible] = useState<boolean>(false);
	const [updateBtnActive, setUpdateBtnActive] = useState<boolean>(false);
	const [inputsSnapshot, setInputsSnapshot] = useState<InputsSnapshot>({
		ttk: props.queue?.timeToKick.toString() ?? '',
		maxQLength: props.queue?.maxQueueLength.toString() ?? '',
		maxTreasuryLength: props.queue?.maxTreasuryLength.toString() ?? ''
	});
	
	const invalidateNormalQueueInfo = useSetRecoilState(normalQueueInfoState);
	const invalidateLegendaryQueueInfo = useSetRecoilState(legendaryQueueInfoState);
	const invalidateQueuesInfo = () => {
		invalidateNormalQueueInfo(undefined);
		invalidateLegendaryQueueInfo(undefined);
	};
	
	const inputChangedHandler = (value: string, snapshotProperty: string) => {
		if (value !== inputsSnapshot[snapshotProperty])
			setUpdateBtnActive(true);
		else
			setUpdateBtnActive(false);
	};

	const lockBtnClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		const response: StatusResponse = await QueueAPI.toggleLock(props.name).catch(ex => makeError(ex.message));

		if (response.status === 'okay') {
			toastInfo(`The ${props.name} queue was ${props.queue?.locked ? 'unlocked' : 'locked'} successfully!`);
			invalidateQueuesInfo();
		} else
			return toastError(`There was an error while ${props.queue?.locked ? 'un' : ''}locking the queue: ${response.message}`);
	};

	const subBtnClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		const response: StatusResponse = await QueueAPI.toggleSubOnly(props.name).catch(ex => makeError(ex.message));

		if (response.status === 'okay') {
			toastInfo(`The ${props.name} queue sub-only mode was ${props.queue?.subOnly ? 'disabled' : 'enabled'} successfully!`);
			invalidateQueuesInfo();
		} else
			return toastError(`There was an error while ${props.queue?.subOnly ? 'disabling' : 'enabling'} sub-only mode: ${response.message}`);
	};

	const autoKickBtnClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		const response: StatusResponse = await QueueAPI.toggleKickTimeout(props.name).catch(ex => makeError(ex.message));

		if (response.status === 'okay') {
			toastInfo(`The ${props.name} queue auto-kick was ${props.queue?.autoKickEnabled ? 'disabled' : 'enabled'} successfully!`);
			invalidateQueuesInfo();
		} else
			return toastError(`There was an error while ${props.queue?.autoKickEnabled ? 'disabling' : 'enabling'} auto-kick: ${response.message}`);
	};

	const addTestUsersClickHandler = async (e: MouseEvent<HTMLDivElement>) => {
		const queueName: string = e.currentTarget.getAttribute('queue')!;

		if (isNaN(parseInt(testUsersValue)))
			return alert('Test users number needs a valid value!');

		const promises: Promise<StatusResponse | Error>[] = [];
		for (let i = 0; i < parseInt(testUsersValue); i++) {
			console.info(`Adding uid${i}, username${i} to ${queueName} queue.`);
			promises.push(QueueAPI.join(queueName, new ChosenPKMWrapper(ChosenPKMInputType.PREDEFINED, 'abomasnow'), true, `uid${i}`, `username${i}`, Math.random() >= 0.5).catch((ex: Error) => ex));
		}
		const statusResponses = await Promise.all(promises);
		console.log(statusResponses);
	};

	const updateBtnClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		if (!updateBtnActive)
			return toastError(`Nothing changed!`);

		const ttkChanged = inputsSnapshot.ttk !== timeToKickValue;
		const maxQLengthChanged = inputsSnapshot.maxQLength === maxQLengthValue;
		const maxTreasuryLengthChanged = inputsSnapshot.maxTreasuryLength === maxTreasuryLengthValue;

		if (!ttkChanged && !maxQLengthChanged && !maxTreasuryLengthChanged)
			return toastError(`Nothing changed!`);

		const statusResponses: Promise<StatusResponse>[] = [];

		if (ttkChanged)
			statusResponses.push(QueueAPI.setTimeToKick(props.name, parseInt(timeToKickValue)).catch(ex => makeError(ex.message)));

		if (maxQLengthChanged || maxTreasuryLengthChanged)
			statusResponses.push(QueueAPI.resize(props.name, parseInt(maxTreasuryLengthValue), parseInt(maxQLengthValue)).catch(ex => makeError(ex.message)));

		const results = await Promise.all(statusResponses).catch(ex => {
			toastError(`An unexpected error occurred: ${ex.message}`);
		});

		if (!results)
			return;

		const errors: string[] = results.filter(r => r.status === 'error').map(r => r.message);
		
		if (errors.length > 0)
			return toastError(`Could not update the ${props.name} queue: ${errors.join('; ')}`);

		setInputsSnapshot({
			ttk: timeToKickValue,
			maxQLength: maxQLengthValue,
			maxTreasuryLength: maxTreasuryLengthValue
		});

		setUpdateBtnActive(false);

		toastInfo(`The ${props.name} queue was updated successfully!`);
	};

	return (
		<div className={`${queueClassID}-queue queue`} style={{ display: props.collapsed ? 'none' : 'flex' }}>
			<div className="main-buttons">
				<div className="button lock-button" onClick={ lockBtnClickHandler }>
					<span>{ props.queue?.locked ? 'Unlock' : 'Lock' }</span>
				</div>
				<div className="button sub-mode-button" onClick={ subBtnClickHandler }>
					<span>{ props.queue?.subOnly ? 'Disable' : 'Enable' } Sub Mode</span>
				</div>
				<div className="button auto-kick-button" onClick={ autoKickBtnClickHandler }>
					<span>{ props.queue?.autoKickEnabled ? 'Disable' : 'Enable' } Auto-Kick</span>
				</div>
			</div>

			<div className="inputs-wrapper">
				<div className="main-inputs">
					<div className="labeled-textbox">
						<span className="label">Time To Kick (TTK)</span>
						<input 
							type="text" 
							value={ timeToKickValue } 
							onChange={ 
								(e: ChangeEvent<HTMLInputElement>) => {
									inputChangedHandler(e.target.value, 'ttk');
									setTimeToKickValue(e.target.value)
								}
							}
						/>
					</div>
					<div className="labeled-textbox">
						<span className="label">Max Queue Length</span>
						<input 
							type="text" 
							value={ maxQLengthValue } 
							onChange={ 
								(e: ChangeEvent<HTMLInputElement>) => {
									inputChangedHandler(e.target.value, 'maxQLength');
									setMaxQLengthValue(e.target.value);
								}
							}
						/>
					</div>
					<div className="labeled-textbox">
						<span className="label">Max Treasury Length</span>
						<input 
							type="text" 
							value={ maxTreasuryLengthValue } 
							onChange={ 
								(e: ChangeEvent<HTMLInputElement>) => {
									inputChangedHandler(e.target.value, 'maxTreasuryLength');
									setMaxTreasuryLengthValue(e.target.value);
								}
							}
						/>
					</div>
				</div>

				<span className="section-title test-users-title button" onClick={ () => { setTestUsersVisible(!testUsersVisible) } }>Test Users</span>
				<div className="add-test-users" style={{ display: testUsersVisible ? 'flex' : 'none' }}>
					<div className="labeled-textbox">
						<span className="label"># Test Users</span>
						<input
							type="text" 
							value={ testUsersValue } 
							onChange={ 
								(e: ChangeEvent<HTMLInputElement>) => {
									setTestUsersValue(e.target.value);
								}
							}
						/>
					</div>
					<div className="flex-row">
						<div className="button add-to-normal" onClick={ addTestUsersClickHandler } {...{queue: 'normal'}}><span>Normal Test</span></div>
						<div className="button add-to-legendary" onClick={ addTestUsersClickHandler } {...{queue: 'legendary'}}><span>Leg.y Test</span></div>
					</div>
				</div>

				<div className={`button update-button${updateBtnActive ? '' : ' disabled'}`} onClick={ updateBtnClickHandler }>
					<span>Update</span>
				</div>
			</div>

			<div className="queue-users">
				<div className="treasury">
					<span className="section-title">Currently trading users</span>
					<div className="users">
						{
							props.queue?.treasury?.map(user => {
								return <EnqueuedUser user={user} queueName={props.name} key={`${user.uid}#${user.username}`}/>
							})
						}
					</div>
				</div>
				<div className="enqueued">
					<span className="section-title">Enqueued users</span>
					<div className="users">
						{
							props.queue?.queuedUsers?.map(user => {
								return <EnqueuedUser user={user} queueName={props.name} key={`${user.uid}#${user.username}`}/>
							})
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default function AdminPage(): JSX.Element {
	const authStatus: AuthStatus = useRecoilValue(userAuthStatusState);
	const navigate = useNavigate();
	const [normalQCollapsed, setNormalQCollapsed] = useState<boolean>(false);
	const [legendaryQCollapsed, setLegendaryQCollapsed] = useState<boolean>(true);
	const normalQ = useRecoilValue(normalQueueInfoState);
	const legendaryQ = useRecoilValue(legendaryQueueInfoState);
	
	useEffect(() => {
		if (!authStatus.isMod)
			navigate('/');
	});

	if (!authStatus.isMod)
		return <></>;

	return (
		<>
			<MenuBar />
			<BlackOverlay style={undefined} />
			<div className="section admin-page">
				<span className="section-title">Admin Controls</span>

				<div className="normal-queue-wrapper wrapper">
					<div className="queue-title">
						<span className="collapsed-indicator button">{ normalQCollapsed ? '>' : 'V' }</span>
						<h1 className="button" onClick={ () => setNormalQCollapsed(!normalQCollapsed) }>Normal Queue</h1>
					</div>

					<QueueControls name="normal" queue={normalQ} collapsed={normalQCollapsed} />
				</div>

				<div className="legendary-queue-wrapper wrapper">
					<div className="queue-title">
						<span className="collapsed-indicator button">{ legendaryQCollapsed ? '>' : 'V' }</span>
						<h1 className="button" onClick={ () => setLegendaryQCollapsed(!legendaryQCollapsed) }>Legendary Queue</h1>
					</div>

					<QueueControls name="legendary" queue={legendaryQ} collapsed={legendaryQCollapsed} />
				</div>
			</div>
			<div className="spacer" />
			<Footer />
			<ToastContainer />
			<InteractivePopup name="kick-popup" popupType={InteractivePopupType.confirm} title="Are you sure?" message="You're about to kick a user from the queue. Are you sure?" {...{id: 'kick_popup'}} />
		</>
	);
};