import { MouseEvent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useRecoilValue } from 'recoil';
import { CLIENT_ID, PASSPORT_URI, REDIRECT_URI } from '../../config';
import UserManager from '../../services/UserManager';
import { userAuthStatusState } from '../../state/UserState';
import './MenuBar.scss';

export default function MenuBar(): JSX.Element {
	const [logoutOpen, setLogoutOpen] = useState(false);

	const loginClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		UserManager.clearCache();
		window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${window.location.href}`;
	};

	const logoutClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		UserManager.clearCache();
		window.location.href = `${PASSPORT_URI}/logout?redirectURI=${window.location.href}`;
	};

	const menuImageClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		setLogoutOpen(!logoutOpen);
	};

	const menuClickAwayHandler = (e: Event) => {
		if ((e.target as HTMLElement).className.includes('menu-img'))
			return;
			
		if (!logoutOpen)
			return;
		setLogoutOpen(false);
	};

	const userGroup = useRecoilValue(userAuthStatusState);

	return (
		<div className="menu-bar">
			<div className="logo button" onClick={ () => { window.location.href = '/' } }>
				<div className="menu-img"></div>
				<span>PokéReq</span>
			</div>
			<div className="user-profile">
				{ userGroup.isGuest === true && <div className="twitch-login-button" onClick={ loginClickHandler }><span>Login with Twitch</span></div> }
				{ userGroup.isGuest === false && <div className="menu-img" onClick={ menuImageClickHandler } >
					<ClickAwayListener onClickAway={ menuClickAwayHandler }>
						<div className="logout-menu" style={{ display: logoutOpen ? 'block' : 'none' }}>
							{ userGroup.isMod && <div className="admin-button button" onClick={ () => window.location.href = '/admin' }><span>Admin Panel</span></div> }
							{ userGroup.isMod && <div className="switch-admin-button button" onClick={ () => window.location.href = '/switch-admin' }><span>Switch Admin</span></div> }
							<div className="logout-button button" onClick={ logoutClickHandler }><span>Logout</span></div>
						</div>
					</ClickAwayListener>
				</div> }
			</div>
		</div>
	);
};