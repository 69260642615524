import { MouseEvent, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LoadingIcon from '../../../../components/LoadingIcon/LoadingIcon';
import { currentEditFieldPropertyState, currentEditFieldState } from '../../../../state/CustomizationPopupState';
import { currentNavigationState, NavigationState } from '../../../../state/NavigationState';
import { editingPkmnCustomizationDataState } from '../../../../state/PkmnState';
import { editFieldSearchLoadingIconDisplayState, editFieldSearchResultsState, editFieldSearchValueState } from '../../../../state/SearchState';
import { editPkmnFieldPopupDisplayState } from '../../../../state/SectionVisibilityStates';

const editEntry = (value: string, imgPath: string | null, clickHandler: (e: MouseEvent<HTMLDivElement>) => void) => {
	return (
		<div className="entry button" onClick={ clickHandler } key={value+imgPath}>
			<div className="entry-icon" style={{ backgroundImage: imgPath ? `url(${imgPath})` : '', display: imgPath ? 'block' : 'none' }}></div>
			<div className="entry-name"><span>{value}</span></div>
		</div>
	);
};

export default function CustomizePkmnEditPopup(): JSX.Element {
	const customizePkmnEditPopupDisplay 		= useRecoilValue(editPkmnFieldPopupDisplayState);
	const setCurrentNavigation 					= useSetRecoilState(currentNavigationState);
	const setEditingPkmnCustomizationData		= useSetRecoilState(editingPkmnCustomizationDataState);
	const currentEditField						= useRecoilValue(currentEditFieldState);
	const currentEditFieldProperty				= useRecoilValue(currentEditFieldPropertyState);
	const [searchFieldVal, setSearchFieldVal] 	= useRecoilState(editFieldSearchValueState);
	const editFieldSearchLoadingIconDisplay		= useRecoilValue(editFieldSearchLoadingIconDisplayState);
	const editFieldSearchResults				= useRecoilValue(editFieldSearchResultsState);
	const editFieldSearchInputRef				= useRef(null);

	const backButtonClickHandler = (_: MouseEvent<HTMLDivElement>) => {
		setSearchFieldVal('');
		setCurrentNavigation(NavigationState.editingPkmn);
	};

	const saveButtonClickHandler = (_: MouseEvent<HTMLDivElement>) => {};

	const editEntryClickHandler = (e: MouseEvent<HTMLDivElement>) => {
		setSearchFieldVal('');

		const value: string = (e.currentTarget.children[1] as HTMLElement).innerText;
		
		setEditingPkmnCustomizationData(current => {
			const clone = current.clone();

			if (currentEditFieldProperty?.match(/move/i)) {
				const moveIndex: number = parseInt(currentEditFieldProperty!.match(/\d/)?.[0] ?? '-1');
				
				if (moveIndex === -1)
					return clone;

				clone.moves[moveIndex] = value;

				return clone;
			}

			clone[currentEditFieldProperty!] = value;
			return clone;
		});

		setCurrentNavigation(NavigationState.editingPkmn);
	};

	useEffect(() => {
		if (editFieldSearchInputRef.current === null)
			return;

		if (currentEditFieldProperty !== 'heldItem' && currentEditFieldProperty?.match(/move/i) === null)
			(editFieldSearchInputRef.current as HTMLInputElement).setAttribute('disabled', '');
		else
			(editFieldSearchInputRef.current as HTMLInputElement).removeAttribute('disabled');
	});

	const editFieldResultEntries: JSX.Element[] = editFieldSearchResults.map(resultEntry => {
		let iconPath: string | null = null;

		switch(currentEditFieldProperty) {
			case 'move0':
			case 'move1': 
			case 'move2':
			case 'move3':
				iconPath = 'tm';
				break;
			case 'heldItem':
				iconPath = 'item icon';
				break;
		}

		return editEntry(resultEntry, iconPath, editEntryClickHandler);
	});

	return (
		<div className="edit-field-container" style={{ display: customizePkmnEditPopupDisplay }}>
			<div className="edit-header">
				<div className="edit-controls">
					<div className="edit-back-button button" onClick={ backButtonClickHandler }><span>Back</span></div>
					<div className="save-button button" onClick={ saveButtonClickHandler } style={{display: 'none'}}><span>Save</span></div>
				</div>
				<div className="edit-field">
					<span className="label">{ currentEditField }</span>
					<input type="text" onChange={ e => setSearchFieldVal(e.currentTarget.value) } value={ searchFieldVal } ref={editFieldSearchInputRef} />
				</div>
			</div>
			<div className="entries">
				<LoadingIcon style={{ display: editFieldSearchLoadingIconDisplay }}/>
				{ editFieldResultEntries }
			</div>
		</div>
	);
}