import { MouseEvent } from "react";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import BlackOverlay from "../components/BlackOverlay/BlackOverlay";
import Footer from "../components/Footer/Footer";
import InteractivePopup, { InteractivePopupType } from "../components/InteractivePopup/InteractivePopup";
import MenuBar from "../components/MenuBar/MenuBar";
import { BACK_END_URI } from "../config";
import { API, StatusResponse } from "../services/API";
import { botsStateState, switchStatusState } from "../state/NetworkState";
import './SwitchAdminPage.scss';

function SwitchStatusEntry(props: {nsName: string, status: string, state: string | undefined}): JSX.Element {
	return (
		<div className="switch-status">
			<span>{props.nsName[0].toUpperCase() + props.nsName.slice(1)}:</span>
			<span className="status-dot">{props.status === 'online' ? '🟢' : '🔴'} ({props.state ?? ''})</span>
		</div>
	);
}

export default function SwitchAdminPage(): JSX.Element {
	const switchOnlineStatus = useRecoilValue(switchStatusState);
	const botsState = useRecoilValue(botsStateState);
	const joinedSwitchState = switchOnlineStatus?.map(onlineStatus => {
		return {
			nsName: onlineStatus.nsName,
			online: onlineStatus.online,
			state: botsState?.filter(b => b.nsName === onlineStatus.nsName)[0]?.state
		};
	});

	const startAllBotsBtnClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		const result = await API.get<StatusResponse>(BACK_END_URI + '/dispatcher/startAllBots');

		console.log(result);
	};

	const stopAllBotsBtnClickHandler = async (_: MouseEvent<HTMLDivElement>) => {
		const result = await API.get<StatusResponse>(BACK_END_URI + '/dispatcher/stopAllBots');

		console.log(result);
	};

	return (
		<>
			<MenuBar />
			<BlackOverlay style={undefined} />
			<div className="section switch-admin-page">
				<span className="section-title">Dispatcher Admin</span>

				<span className="section-subtitle">Switch Status</span>
				<div className="switch-status-wrapper">
					{
						joinedSwitchState?.map(statusEntry => {
							return <SwitchStatusEntry nsName={statusEntry.nsName} status={statusEntry.online ? 'online' : 'offline'} state={statusEntry.state} key={statusEntry.nsName} />;
						})
					}
				</div>

				<div className="controls">
					<div className="control start-all button" onClick={ startAllBotsBtnClickHandler }><span>Start All Bots</span></div>
					<div className="control start-all button" onClick={ stopAllBotsBtnClickHandler }><span>Stop All Bots</span></div>
				</div>
			</div>
			<div className="spacer" />
			<Footer />
			<ToastContainer />
			<InteractivePopup name="kick-popup" popupType={InteractivePopupType.confirm} title="Are you sure?" message="You're about to kick a user from the queue. Are you sure?" {...{id: 'kick_popup'}} />
		</>
	);
}