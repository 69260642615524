export class PKMCustomizationData {
	[index: string]: any;

	public species!: string;
	public level!: number;

	/**
	 * If set to `undefined`, the genned PKM will have its gender randomly rolled.
	 */
	public gender?: 'M' | 'F' | '-';

	public ability!: string;
	public heldItem!: string;
	public shiny!: boolean;
	public ivs: Array<number> = new Array<number>();
	public evs: Array<number> = new Array<number>();
	public moves: Array<string> = new Array<string>();
	public teraType!: string;

	public constructor(
		species: string, 
		level: number, 
		ability: string, 
		heldItem: string, 
		shiny: boolean, 
		ivs: Array<number>,
		evs: Array<number>,
		moves: Array<string>,
		teraType: string,
		gender?: 'M' | 'F' | '-', 
	) {
		this.species = species;
		this.level = level;
		this.ability = ability;
		this.heldItem = heldItem;
		this.shiny = shiny;
		this.ivs = ivs.map(iv => iv);
		this.evs = evs.map(ev => ev);
		this.moves = moves.map(move => move);
		this.teraType = teraType;
		this.gender = gender;
	}

	public clone(): PKMCustomizationData {
		const clone: PKMCustomizationData = new PKMCustomizationData(
			this.species,
			this.level,
			this.ability,
			this.heldItem,
			this.shiny,
			this.ivs.map(iv => iv),
			this.evs.map(ev => ev),
			this.moves.map(move => move),
			this.teraType,
			this.gender
		);

		return clone;
	}

	public equals(o: PKMCustomizationData): boolean {
		for (const [key, value] of Object.entries(this))
			if (o[key] !== value)
				return false;
		return true;
	}
};