export const gen9Items = [
	"None",
	"Master Ball",
	"Ultra Ball",
	"Great Ball",
	"Poké Ball",
	"Safari Ball",
	"Net Ball",
	"Dive Ball",
	"Nest Ball",
	"Repeat Ball",
	"Timer Ball",
	"Luxury Ball",
	"Premier Ball",
	"Dusk Ball",
	"Heal Ball",
	"Quick Ball",
	"Cherish Ball",
	"Potion",
	"Antidote",
	"Burn Heal",
	"Ice Heal",
	"Awakening",
	"Paralyze Heal",
	"Full Restore",
	"Max Potion",
	"Hyper Potion",
	"Super Potion",
	"Full Heal",
	"Revive",
	"Max Revive",
	"Fresh Water",
	"Soda Pop",
	"Lemonade",
	"Moomoo Milk",
	"Energy Powder",
	"Energy Root",
	"Heal Powder",
	"Revival Herb",
	"Ether",
	"Max Ether",
	"Elixir",
	"Max Elixir",
	"Lava Cookie",
	"Berry Juice",
	"Sacred Ash",
	"HP Up",
	"Protein",
	"Iron",
	"Carbos",
	"Calcium",
	"Rare Candy",
	"PP Up",
	"Zinc",
	"PP Max",
	"Old Gateau",
	"Guard Spec.",
	"Dire Hit",
	"X Attack",
	"X Defense",
	"X Speed",
	"X Accuracy",
	"X Sp. Atk",
	"X Sp. Def",
	"Poké Doll",
	"Fluffy Tail",
	"Blue Flute",
	"Yellow Flute",
	"Red Flute",
	"Black Flute",
	"White Flute",
	"Shoal Salt",
	"Shoal Shell",
	"Red Shard",
	"Blue Shard",
	"Yellow Shard",
	"Green Shard",
	"Super Repel",
	"Max Repel",
	"Escape Rope",
	"Repel",
	"Sun Stone",
	"Moon Stone",
	"Fire Stone",
	"Thunder Stone",
	"Water Stone",
	"Leaf Stone",
	"Tiny Mushroom",
	"Big Mushroom",
	"Pearl",
	"Big Pearl",
	"Stardust",
	"Star Piece",
	"Nugget",
	"Heart Scale",
	"Honey",
	"Growth Mulch",
	"Damp Mulch",
	"Stable Mulch",
	"Gooey Mulch",
	"Root Fossil",
	"Claw Fossil",
	"Helix Fossil",
	"Dome Fossil",
	"Old Amber",
	"Armor Fossil",
	"Skull Fossil",
	"Rare Bone",
	"Shiny Stone",
	"Dusk Stone",
	"Dawn Stone",
	"Oval Stone",
	"Odd Keystone",
	"Griseous Orb",
	"Tea",
	"???",
	"Autograph",
	"Douse Drive",
	"Shock Drive",
	"Burn Drive",
	"Chill Drive",
	"???",
	"Pokémon Box Link",
	"Medicine Pocket",
	"TM Case",
	"Candy Jar",
	"Power-Up Pocket",
	"Clothing Trunk",
	"Catching Pocket",
	"Battle Pocket",
	"???",
	"???",
	"???",
	"???",
	"???",
	"Sweet Heart",
	"Adamant Orb",
	"Lustrous Orb",
	"Greet Mail",
	"Favored Mail",
	"RSVP Mail",
	"Thanks Mail",
	"Inquiry Mail",
	"Like Mail",
	"Reply Mail",
	"Bridge Mail S",
	"Bridge Mail D",
	"Bridge Mail T",
	"Bridge Mail V",
	"Bridge Mail M",
	"Cheri Berry",
	"Chesto Berry",
	"Pecha Berry",
	"Rawst Berry",
	"Aspear Berry",
	"Leppa Berry",
	"Oran Berry",
	"Persim Berry",
	"Lum Berry",
	"Sitrus Berry",
	"Figy Berry",
	"Wiki Berry",
	"Mago Berry",
	"Aguav Berry",
	"Iapapa Berry",
	"Razz Berry",
	"Bluk Berry",
	"Nanab Berry",
	"Wepear Berry",
	"Pinap Berry",
	"Pomeg Berry",
	"Kelpsy Berry",
	"Qualot Berry",
	"Hondew Berry",
	"Grepa Berry",
	"Tamato Berry",
	"Cornn Berry",
	"Magost Berry",
	"Rabuta Berry",
	"Nomel Berry",
	"Spelon Berry",
	"Pamtre Berry",
	"Watmel Berry",
	"Durin Berry",
	"Belue Berry",
	"Occa Berry",
	"Passho Berry",
	"Wacan Berry",
	"Rindo Berry",
	"Yache Berry",
	"Chople Berry",
	"Kebia Berry",
	"Shuca Berry",
	"Coba Berry",
	"Payapa Berry",
	"Tanga Berry",
	"Charti Berry",
	"Kasib Berry",
	"Haban Berry",
	"Colbur Berry",
	"Babiri Berry",
	"Chilan Berry",
	"Liechi Berry",
	"Ganlon Berry",
	"Salac Berry",
	"Petaya Berry",
	"Apicot Berry",
	"Lansat Berry",
	"Starf Berry",
	"Enigma Berry",
	"Micle Berry",
	"Custap Berry",
	"Jaboca Berry",
	"Rowap Berry",
	"Bright Powder",
	"White Herb",
	"Macho Brace",
	"Exp. Share",
	"Quick Claw",
	"Soothe Bell",
	"Mental Herb",
	"Choice Band",
	"King’s Rock",
	"Silver Powder",
	"Amulet Coin",
	"Cleanse Tag",
	"Soul Dew",
	"Deep Sea Tooth",
	"Deep Sea Scale",
	"Smoke Ball",
	"Everstone",
	"Focus Band",
	"Lucky Egg",
	"Scope Lens",
	"Metal Coat",
	"Leftovers",
	"Dragon Scale",
	"Light Ball",
	"Soft Sand",
	"Hard Stone",
	"Miracle Seed",
	"Black Glasses",
	"Black Belt",
	"Magnet",
	"Mystic Water",
	"Sharp Beak",
	"Poison Barb",
	"Never-Melt Ice",
	"Spell Tag",
	"Twisted Spoon",
	"Charcoal",
	"Dragon Fang",
	"Silk Scarf",
	"Upgrade",
	"Shell Bell",
	"Sea Incense",
	"Lax Incense",
	"Lucky Punch",
	"Metal Powder",
	"Thick Club",
	"Leek",
	"Red Scarf",
	"Blue Scarf",
	"Pink Scarf",
	"Green Scarf",
	"Yellow Scarf",
	"Wide Lens",
	"Muscle Band",
	"Wise Glasses",
	"Expert Belt",
	"Light Clay",
	"Life Orb",
	"Power Herb",
	"Toxic Orb",
	"Flame Orb",
	"Quick Powder",
	"Focus Sash",
	"Zoom Lens",
	"Metronome",
	"Iron Ball",
	"Lagging Tail",
	"Destiny Knot",
	"Black Sludge",
	"Icy Rock",
	"Smooth Rock",
	"Heat Rock",
	"Damp Rock",
	"Grip Claw",
	"Choice Scarf",
	"Sticky Barb",
	"Power Bracer",
	"Power Belt",
	"Power Lens",
	"Power Band",
	"Power Anklet",
	"Power Weight",
	"Shed Shell",
	"Big Root",
	"Choice Specs",
	"Flame Plate",
	"Splash Plate",
	"Zap Plate",
	"Meadow Plate",
	"Icicle Plate",
	"Fist Plate",
	"Toxic Plate",
	"Earth Plate",
	"Sky Plate",
	"Mind Plate",
	"Insect Plate",
	"Stone Plate",
	"Spooky Plate",
	"Draco Plate",
	"Dread Plate",
	"Iron Plate",
	"Odd Incense",
	"Rock Incense",
	"Full Incense",
	"Wave Incense",
	"Rose Incense",
	"Luck Incense",
	"Pure Incense",
	"Protector",
	"Electirizer",
	"Magmarizer",
	"Dubious Disc",
	"Reaper Cloth",
	"Razor Claw",
	"Razor Fang",
	"TM01",
	"TM02",
	"TM03",
	"TM04",
	"TM05",
	"TM06",
	"TM07",
	"TM08",
	"TM09",
	"TM10",
	"TM11",
	"TM12",
	"TM13",
	"TM14",
	"TM15",
	"TM16",
	"TM17",
	"TM18",
	"TM19",
	"TM20",
	"TM21",
	"TM22",
	"TM23",
	"TM24",
	"TM25",
	"TM26",
	"TM27",
	"TM28",
	"TM29",
	"TM30",
	"TM31",
	"TM32",
	"TM33",
	"TM34",
	"TM35",
	"TM36",
	"TM37",
	"TM38",
	"TM39",
	"TM40",
	"TM41",
	"TM42",
	"TM43",
	"TM44",
	"TM45",
	"TM46",
	"TM47",
	"TM48",
	"TM49",
	"TM50",
	"TM51",
	"TM52",
	"TM53",
	"TM54",
	"TM55",
	"TM56",
	"TM57",
	"TM58",
	"TM59",
	"TM60",
	"TM61",
	"TM62",
	"TM63",
	"TM64",
	"TM65",
	"TM66",
	"TM67",
	"TM68",
	"TM69",
	"TM70",
	"TM71",
	"TM72",
	"TM73",
	"TM74",
	"TM75",
	"TM76",
	"TM77",
	"TM78",
	"TM79",
	"TM80",
	"TM81",
	"TM82",
	"TM83",
	"TM84",
	"TM85",
	"TM86",
	"TM87",
	"TM88",
	"TM89",
	"TM90",
	"TM91",
	"TM92",
	"HM01",
	"HM02",
	"HM03",
	"HM04",
	"HM05",
	"HM06",
	"???",
	"???",
	"Explorer Kit",
	"Loot Sack",
	"Rule Book",
	"Poké Radar",
	"Point Card",
	"Guidebook",
	"Sticker Case",
	"Fashion Case",
	"Sticker Bag",
	"Pal Pad",
	"Works Key",
	"Old Charm",
	"Galactic Key",
	"Red Chain",
	"Town Map",
	"Vs. Seeker",
	"Coin Case",
	"Old Rod",
	"Good Rod",
	"Super Rod",
	"Sprayduck",
	"Poffin Case",
	"Bike",
	"Suite Key",
	"Oak’s Letter",
	"Lunar Feather",
	"Member Card",
	"Azure Flute",
	"S.S. Ticket",
	"Contest Pass",
	"Magma Stone",
	"Parcel",
	"Coupon 1",
	"Coupon 2",
	"Coupon 3",
	"Storage Key",
	"Secret Medicine",
	"Vs. Recorder",
	"Gracidea",
	"Secret Key",
	"Apricorn Box",
	"Unown Report",
	"Berry Pots",
	"Dowsing Machine",
	"Blue Card",
	"Slowpoke Tail",
	"Clear Bell",
	"Card Key",
	"Basement Key",
	"Squirt Bottle",
	"Red Scale",
	"Lost Item",
	"Pass",
	"Machine Part",
	"Silver Feather",
	"Rainbow Feather",
	"Mystery Egg",
	"Red Apricorn",
	"Blue Apricorn",
	"Yellow Apricorn",
	"Green Apricorn",
	"Pink Apricorn",
	"White Apricorn",
	"Black Apricorn",
	"Fast Ball",
	"Level Ball",
	"Lure Ball",
	"Heavy Ball",
	"Love Ball",
	"Friend Ball",
	"Moon Ball",
	"Sport Ball",
	"Park Ball",
	"Photo Album",
	"GB Sounds",
	"Tidal Bell",
	"Rage Candy Bar",
	"Data Card 01",
	"Data Card 02",
	"Data Card 03",
	"Data Card 04",
	"Data Card 05",
	"Data Card 06",
	"Data Card 07",
	"Data Card 08",
	"Data Card 09",
	"Data Card 10",
	"Data Card 11",
	"Data Card 12",
	"Data Card 13",
	"Data Card 14",
	"Data Card 15",
	"Data Card 16",
	"Data Card 17",
	"Data Card 18",
	"Data Card 19",
	"Data Card 20",
	"Data Card 21",
	"Data Card 22",
	"Data Card 23",
	"Data Card 24",
	"Data Card 25",
	"Data Card 26",
	"Data Card 27",
	"Jade Orb",
	"Lock Capsule",
	"Red Orb",
	"Blue Orb",
	"Enigma Stone",
	"Prism Scale",
	"Eviolite",
	"Float Stone",
	"Rocky Helmet",
	"Air Balloon",
	"Red Card",
	"Ring Target",
	"Binding Band",
	"Absorb Bulb",
	"Cell Battery",
	"Eject Button",
	"Fire Gem",
	"Water Gem",
	"Electric Gem",
	"Grass Gem",
	"Ice Gem",
	"Fighting Gem",
	"Poison Gem",
	"Ground Gem",
	"Flying Gem",
	"Psychic Gem",
	"Bug Gem",
	"Rock Gem",
	"Ghost Gem",
	"Dragon Gem",
	"Dark Gem",
	"Steel Gem",
	"Normal Gem",
	"Health Feather",
	"Muscle Feather",
	"Resist Feather",
	"Genius Feather",
	"Clever Feather",
	"Swift Feather",
	"Pretty Feather",
	"Cover Fossil",
	"Plume Fossil",
	"Liberty Pass",
	"Pass Orb",
	"Dream Ball",
	"Poké Toy",
	"Prop Case",
	"Dragon Skull",
	"Balm Mushroom",
	"Big Nugget",
	"Pearl String",
	"Comet Shard",
	"Relic Copper",
	"Relic Silver",
	"Relic Gold",
	"Relic Vase",
	"Relic Band",
	"Relic Statue",
	"Relic Crown",
	"Casteliacone",
	"Dire Hit 2",
	"X Speed 2",
	"X Sp. Atk 2",
	"X Sp. Def 2",
	"X Defense 2",
	"X Attack 2",
	"X Accuracy 2",
	"X Speed 3",
	"X Sp. Atk 3",
	"X Sp. Def 3",
	"X Defense 3",
	"X Attack 3",
	"X Accuracy 3",
	"X Speed 6",
	"X Sp. Atk 6",
	"X Sp. Def 6",
	"X Defense 6",
	"X Attack 6",
	"X Accuracy 6",
	"Ability Urge",
	"Item Drop",
	"Item Urge",
	"Reset Urge",
	"Dire Hit 3",
	"Light Stone",
	"Dark Stone",
	"TM93",
	"TM94",
	"TM95",
	"Xtransceiver",
	"???",
	"Gram 1",
	"Gram 2",
	"Gram 3",
	"Xtransceiver",
	"Medal Box",
	"DNA Splicers",
	"DNA Splicers",
	"Permit",
	"Oval Charm",
	"Shiny Charm",
	"Plasma Card",
	"Grubby Hanky",
	"Colress Machine",
	"Dropped Item",
	"Dropped Item",
	"Reveal Glass",
	"Weakness Policy",
	"Assault Vest",
	"Holo Caster",
	"Prof’s Letter",
	"Roller Skates",
	"Pixie Plate",
	"Ability Capsule",
	"Whipped Dream",
	"Sachet",
	"Luminous Moss",
	"Snowball",
	"Safety Goggles",
	"Poké Flute",
	"Rich Mulch",
	"Surprise Mulch",
	"Boost Mulch",
	"Amaze Mulch",
	"Gengarite",
	"Gardevoirite",
	"Ampharosite",
	"Venusaurite",
	"Charizardite X",
	"Blastoisinite",
	"Mewtwonite X",
	"Mewtwonite Y",
	"Blazikenite",
	"Medichamite",
	"Houndoominite",
	"Aggronite",
	"Banettite",
	"Tyranitarite",
	"Scizorite",
	"Pinsirite",
	"Aerodactylite",
	"Lucarionite",
	"Abomasite",
	"Kangaskhanite",
	"Gyaradosite",
	"Absolite",
	"Charizardite Y",
	"Alakazite",
	"Heracronite",
	"Mawilite",
	"Manectite",
	"Garchompite",
	"Latiasite",
	"Latiosite",
	"Roseli Berry",
	"Kee Berry",
	"Maranga Berry",
	"Sprinklotad",
	"TM96",
	"TM97",
	"TM98",
	"TM99",
	"TM100",
	"Power Plant Pass",
	"Mega Ring",
	"Intriguing Stone",
	"Common Stone",
	"Discount Coupon",
	"Elevator Key",
	"TMV Pass",
	"Honor of Kalos",
	"Adventure Guide",
	"Strange Souvenir",
	"Lens Case",
	"Makeup Bag",
	"Travel Trunk",
	"Lumiose Galette",
	"Shalour Sable",
	"Jaw Fossil",
	"Sail Fossil",
	"Looker Ticket",
	"Bike",
	"Holo Caster",
	"Fairy Gem",
	"Mega Charm",
	"Mega Glove",
	"Mach Bike",
	"Acro Bike",
	"Wailmer Pail",
	"Devon Parts",
	"Soot Sack",
	"Basement Key",
	"Pokéblock Kit",
	"Letter",
	"Eon Ticket",
	"Scanner",
	"Go-Goggles",
	"Meteorite",
	"Key to Room 1",
	"Key to Room 2",
	"Key to Room 4",
	"Key to Room 6",
	"Storage Key",
	"Devon Scope",
	"S.S. Ticket",
	"HM07",
	"Devon Scuba Gear",
	"Contest Costume",
	"Contest Costume",
	"Magma Suit",
	"Aqua Suit",
	"Pair of Tickets",
	"Mega Bracelet",
	"Mega Pendant",
	"Mega Glasses",
	"Mega Anchor",
	"Mega Stickpin",
	"Mega Tiara",
	"Mega Anklet",
	"Meteorite",
	"Swampertite",
	"Sceptilite",
	"Sablenite",
	"Altarianite",
	"Galladite",
	"Audinite",
	"Metagrossite",
	"Sharpedonite",
	"Slowbronite",
	"Steelixite",
	"Pidgeotite",
	"Glalitite",
	"Diancite",
	"Prison Bottle",
	"Mega Cuff",
	"Cameruptite",
	"Lopunnite",
	"Salamencite",
	"Beedrillite",
	"Meteorite",
	"Meteorite",
	"Key Stone",
	"Meteorite Shard",
	"Eon Flute",
	"Normalium Z",
	"Firium Z",
	"Waterium Z",
	"Electrium Z",
	"Grassium Z",
	"Icium Z",
	"Fightinium Z",
	"Poisonium Z",
	"Groundium Z",
	"Flyinium Z",
	"Psychium Z",
	"Buginium Z",
	"Rockium Z",
	"Ghostium Z",
	"Dragonium Z",
	"Darkinium Z",
	"Steelium Z",
	"Fairium Z",
	"Pikanium Z",
	"Bottle Cap",
	"Gold Bottle Cap",
	"Z-Ring",
	"Decidium Z",
	"Incinium Z",
	"Primarium Z",
	"Tapunium Z",
	"Marshadium Z",
	"Aloraichium Z",
	"Snorlium Z",
	"Eevium Z",
	"Mewnium Z",
	"Normalium Z",
	"Firium Z",
	"Waterium Z",
	"Electrium Z",
	"Grassium Z",
	"Icium Z",
	"Fightinium Z",
	"Poisonium Z",
	"Groundium Z",
	"Flyinium Z",
	"Psychium Z",
	"Buginium Z",
	"Rockium Z",
	"Ghostium Z",
	"Dragonium Z",
	"Darkinium Z",
	"Steelium Z",
	"Fairium Z",
	"Pikanium Z",
	"Decidium Z",
	"Incinium Z",
	"Primarium Z",
	"Tapunium Z",
	"Marshadium Z",
	"Aloraichium Z",
	"Snorlium Z",
	"Eevium Z",
	"Mewnium Z",
	"Pikashunium Z",
	"Pikashunium Z",
	"???",
	"???",
	"???",
	"???",
	"Forage Bag",
	"Fishing Rod",
	"Professor’s Mask",
	"Festival Ticket",
	"Sparkling Stone",
	"Adrenaline Orb",
	"Zygarde Cube",
	"???",
	"Ice Stone",
	"Ride Pager",
	"Beast Ball",
	"Big Malasada",
	"Red Nectar",
	"Yellow Nectar",
	"Pink Nectar",
	"Purple Nectar",
	"Sun Flute",
	"Moon Flute",
	"???",
	"Enigmatic Card",
	"Silver Razz Berry",
	"Golden Razz Berry",
	"Silver Nanab Berry",
	"Golden Nanab Berry",
	"Silver Pinap Berry",
	"Golden Pinap Berry",
	"???",
	"???",
	"???",
	"???",
	"???",
	"Secret Key",
	"S.S. Ticket",
	"Silph Scope",
	"Parcel",
	"Card Key",
	"Gold Teeth",
	"Lift Key",
	"Terrain Extender",
	"Protective Pads",
	"Electric Seed",
	"Psychic Seed",
	"Misty Seed",
	"Grassy Seed",
	"Stretchy Spring",
	"Chalky Stone",
	"Marble",
	"Lone Earring",
	"Beach Glass",
	"Gold Leaf",
	"Silver Leaf",
	"Polished Mud Ball",
	"Tropical Shell",
	"Leaf Letter",
	"Leaf Letter",
	"Small Bouquet",
	"???",
	"???",
	"???",
	"Lure",
	"Super Lure",
	"Max Lure",
	"Pewter Crunchies",
	"Fighting Memory",
	"Flying Memory",
	"Poison Memory",
	"Ground Memory",
	"Rock Memory",
	"Bug Memory",
	"Ghost Memory",
	"Steel Memory",
	"Fire Memory",
	"Water Memory",
	"Grass Memory",
	"Electric Memory",
	"Psychic Memory",
	"Ice Memory",
	"Dragon Memory",
	"Dark Memory",
	"Fairy Memory",
	"Solganium Z",
	"Lunalium Z",
	"Ultranecrozium Z",
	"Mimikium Z",
	"Lycanium Z",
	"Kommonium Z",
	"Solganium Z",
	"Lunalium Z",
	"Ultranecrozium Z",
	"Mimikium Z",
	"Lycanium Z",
	"Kommonium Z",
	"Z-Power Ring",
	"Pink Petal",
	"Orange Petal",
	"Blue Petal",
	"Red Petal",
	"Green Petal",
	"Yellow Petal",
	"Purple Petal",
	"Rainbow Flower",
	"Surge Badge",
	"N-Solarizer",
	"N-Lunarizer",
	"N-Solarizer",
	"N-Lunarizer",
	"Ilima’s Normalium Z",
	"Left Poké Ball",
	"Roto Hatch",
	"Roto Bargain",
	"Roto Prize Money",
	"Roto Exp. Points",
	"Roto Friendship",
	"Roto Encounter",
	"Roto Stealth",
	"Roto HP Restore",
	"Roto PP Restore",
	"Roto Boost",
	"Roto Catch",
	"Health Candy",
	"Mighty Candy",
	"Tough Candy",
	"Smart Candy",
	"Courage Candy",
	"Quick Candy",
	"Health Candy L",
	"Mighty Candy L",
	"Tough Candy L",
	"Smart Candy L",
	"Courage Candy L",
	"Quick Candy L",
	"Health Candy XL",
	"Mighty Candy XL",
	"Tough Candy XL",
	"Smart Candy XL",
	"Courage Candy XL",
	"Quick Candy XL",
	"Bulbasaur Candy",
	"Charmander Candy",
	"Squirtle Candy",
	"Caterpie Candy",
	"Weedle Candy",
	"Pidgey Candy",
	"Rattata Candy",
	"Spearow Candy",
	"Ekans Candy",
	"Pikachu Candy",
	"Sandshrew Candy",
	"Nidoran♀ Candy",
	"Nidoran♂ Candy",
	"Clefairy Candy",
	"Vulpix Candy",
	"Jigglypuff Candy",
	"Zubat Candy",
	"Oddish Candy",
	"Paras Candy",
	"Venonat Candy",
	"Diglett Candy",
	"Meowth Candy",
	"Psyduck Candy",
	"Mankey Candy",
	"Growlithe Candy",
	"Poliwag Candy",
	"Abra Candy",
	"Machop Candy",
	"Bellsprout Candy",
	"Tentacool Candy",
	"Geodude Candy",
	"Ponyta Candy",
	"Slowpoke Candy",
	"Magnemite Candy",
	"Farfetch’d Candy",
	"Doduo Candy",
	"Seel Candy",
	"Grimer Candy",
	"Shellder Candy",
	"Gastly Candy",
	"Onix Candy",
	"Drowzee Candy",
	"Krabby Candy",
	"Voltorb Candy",
	"Exeggcute Candy",
	"Cubone Candy",
	"Hitmonlee Candy",
	"Hitmonchan Candy",
	"Lickitung Candy",
	"Koffing Candy",
	"Rhyhorn Candy",
	"Chansey Candy",
	"Tangela Candy",
	"Kangaskhan Candy",
	"Horsea Candy",
	"Goldeen Candy",
	"Staryu Candy",
	"Mr. Mime Candy",
	"Scyther Candy",
	"Jynx Candy",
	"Electabuzz Candy",
	"Pinsir Candy",
	"Tauros Candy",
	"Magikarp Candy",
	"Lapras Candy",
	"Ditto Candy",
	"Eevee Candy",
	"Porygon Candy",
	"Omanyte Candy",
	"Kabuto Candy",
	"Aerodactyl Candy",
	"Snorlax Candy",
	"Articuno Candy",
	"Zapdos Candy",
	"Moltres Candy",
	"Dratini Candy",
	"Mewtwo Candy",
	"Mew Candy",
	"Meltan Candy",
	"Magmar Candy",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"Endorsement",
	"Pokémon Box Link",
	"Wishing Star",
	"Dynamax Band",
	"???",
	"???",
	"Fishing Rod",
	"Rotom Bike",
	"???",
	"???",
	"Sausages",
	"Bob’s Food Tin",
	"Bach’s Food Tin",
	"Tin of Beans",
	"Bread",
	"Pasta",
	"Mixed Mushrooms",
	"Smoke-Poke Tail",
	"Large Leek",
	"Fancy Apple",
	"Brittle Bones",
	"Pack of Potatoes",
	"Pungent Root",
	"Salad Mix",
	"Fried Food",
	"Boiled Egg",
	"Camping Gear",
	"???",
	"???",
	"Rusted Sword",
	"Rusted Shield",
	"Fossilized Bird",
	"Fossilized Fish",
	"Fossilized Drake",
	"Fossilized Dino",
	"Strawberry Sweet",
	"Love Sweet",
	"Berry Sweet",
	"Clover Sweet",
	"Flower Sweet",
	"Star Sweet",
	"Ribbon Sweet",
	"Sweet Apple",
	"Tart Apple",
	"Throat Spray",
	"Eject Pack",
	"Heavy-Duty Boots",
	"Blunder Policy",
	"Room Service",
	"Utility Umbrella",
	"Exp. Candy XS",
	"Exp. Candy S",
	"Exp. Candy M",
	"Exp. Candy L",
	"Exp. Candy XL",
	"Dynamax Candy",
	"TR00",
	"TR01",
	"TR02",
	"TR03",
	"TR04",
	"TR05",
	"TR06",
	"TR07",
	"TR08",
	"TR09",
	"TR10",
	"TR11",
	"TR12",
	"TR13",
	"TR14",
	"TR15",
	"TR16",
	"TR17",
	"TR18",
	"TR19",
	"TR20",
	"TR21",
	"TR22",
	"TR23",
	"TR24",
	"TR25",
	"TR26",
	"TR27",
	"TR28",
	"TR29",
	"TR30",
	"TR31",
	"TR32",
	"TR33",
	"TR34",
	"TR35",
	"TR36",
	"TR37",
	"TR38",
	"TR39",
	"TR40",
	"TR41",
	"TR42",
	"TR43",
	"TR44",
	"TR45",
	"TR46",
	"TR47",
	"TR48",
	"TR49",
	"TR50",
	"TR51",
	"TR52",
	"TR53",
	"TR54",
	"TR55",
	"TR56",
	"TR57",
	"TR58",
	"TR59",
	"TR60",
	"TR61",
	"TR62",
	"TR63",
	"TR64",
	"TR65",
	"TR66",
	"TR67",
	"TR68",
	"TR69",
	"TR70",
	"TR71",
	"TR72",
	"TR73",
	"TR74",
	"TR75",
	"TR76",
	"TR77",
	"TR78",
	"TR79",
	"TR80",
	"TR81",
	"TR82",
	"TR83",
	"TR84",
	"TR85",
	"TR86",
	"TR87",
	"TR88",
	"TR89",
	"TR90",
	"TR91",
	"TR92",
	"TR93",
	"TR94",
	"TR95",
	"TR96",
	"TR97",
	"TR98",
	"TR99",
	"TM00",
	"Lonely Mint",
	"Adamant Mint",
	"Naughty Mint",
	"Brave Mint",
	"Bold Mint",
	"Impish Mint",
	"Lax Mint",
	"Relaxed Mint",
	"Modest Mint",
	"Mild Mint",
	"Rash Mint",
	"Quiet Mint",
	"Calm Mint",
	"Gentle Mint",
	"Careful Mint",
	"Sassy Mint",
	"Timid Mint",
	"Hasty Mint",
	"Jolly Mint",
	"Naive Mint",
	"Serious Mint",
	"Wishing Piece",
	"Cracked Pot",
	"Chipped Pot",
	"Hi-tech Earbuds",
	"Fruit Bunch",
	"Moomoo Cheese",
	"Spice Mix",
	"Fresh Cream",
	"Packaged Curry",
	"Coconut Milk",
	"Instant Noodles",
	"Precooked Burger",
	"Gigantamix",
	"Wishing Chip",
	"Rotom Bike",
	"Catching Charm",
	"???",
	"Old Letter",
	"Band Autograph",
	"Sonia’s Book",
	"???",
	"???",
	"???",
	"???",
	"???",
	"???",
	"Rotom Catalog",
	"★And458",
	"★And15",
	"★And337",
	"★And603",
	"★And390",
	"★Sgr6879",
	"★Sgr6859",
	"★Sgr6913",
	"★Sgr7348",
	"★Sgr7121",
	"★Sgr6746",
	"★Sgr7194",
	"★Sgr7337",
	"★Sgr7343",
	"★Sgr6812",
	"★Sgr7116",
	"★Sgr7264",
	"★Sgr7597",
	"★Del7882",
	"★Del7906",
	"★Del7852",
	"★Psc596",
	"★Psc361",
	"★Psc510",
	"★Psc437",
	"★Psc8773",
	"★Lep1865",
	"★Lep1829",
	"★Boo5340",
	"★Boo5506",
	"★Boo5435",
	"★Boo5602",
	"★Boo5733",
	"★Boo5235",
	"★Boo5351",
	"★Hya3748",
	"★Hya3903",
	"★Hya3418",
	"★Hya3482",
	"★Hya3845",
	"★Eri1084",
	"★Eri472",
	"★Eri1666",
	"★Eri897",
	"★Eri1231",
	"★Eri874",
	"★Eri1298",
	"★Eri1325",
	"★Eri984",
	"★Eri1464",
	"★Eri1393",
	"★Eri850",
	"★Tau1409",
	"★Tau1457",
	"★Tau1165",
	"★Tau1791",
	"★Tau1910",
	"★Tau1346",
	"★Tau1373",
	"★Tau1412",
	"★CMa2491",
	"★CMa2693",
	"★CMa2294",
	"★CMa2827",
	"★CMa2282",
	"★CMa2618",
	"★CMa2657",
	"★CMa2646",
	"★UMa4905",
	"★UMa4301",
	"★UMa5191",
	"★UMa5054",
	"★UMa4295",
	"★UMa4660",
	"★UMa4554",
	"★UMa4069",
	"★UMa3569",
	"★UMa3323",
	"★UMa4033",
	"★UMa4377",
	"★UMa4375",
	"★UMa4518",
	"★UMa3594",
	"★Vir5056",
	"★Vir4825",
	"★Vir4932",
	"★Vir4540",
	"★Vir4689",
	"★Vir5338",
	"★Vir4910",
	"★Vir5315",
	"★Vir5359",
	"★Vir5409",
	"★Vir5107",
	"★Ari617",
	"★Ari553",
	"★Ari546",
	"★Ari951",
	"★Ori1713",
	"★Ori2061",
	"★Ori1790",
	"★Ori1903",
	"★Ori1948",
	"★Ori2004",
	"★Ori1852",
	"★Ori1879",
	"★Ori1899",
	"★Ori1543",
	"★Cas21",
	"★Cas168",
	"★Cas403",
	"★Cas153",
	"★Cas542",
	"★Cas219",
	"★Cas265",
	"★Cnc3572",
	"★Cnc3208",
	"★Cnc3461",
	"★Cnc3449",
	"★Cnc3429",
	"★Cnc3627",
	"★Cnc3268",
	"★Cnc3249",
	"★Com4968",
	"★Crv4757",
	"★Crv4623",
	"★Crv4662",
	"★Crv4786",
	"★Aur1708",
	"★Aur2088",
	"★Aur1605",
	"★Aur2095",
	"★Aur1577",
	"★Aur1641",
	"★Aur1612",
	"★Pav7790",
	"★Cet911",
	"★Cet681",
	"★Cet188",
	"★Cet539",
	"★Cet804",
	"★Cep8974",
	"★Cep8162",
	"★Cep8238",
	"★Cep8417",
	"★Cen5267",
	"★Cen5288",
	"★Cen551",
	"★Cen5459",
	"★Cen5460",
	"★CMi2943",
	"★CMi2845",
	"★Equ8131",
	"★Vul7405",
	"★UMi424",
	"★UMi5563",
	"★UMi5735",
	"★UMi6789",
	"★Crt4287",
	"★Lyr7001",
	"★Lyr7178",
	"★Lyr7106",
	"★Lyr7298",
	"★Ara6585",
	"★Sco6134",
	"★Sco6527",
	"★Sco6553",
	"★Sco5953",
	"★Sco5984",
	"★Sco6508",
	"★Sco6084",
	"★Sco5944",
	"★Sco6630",
	"★Sco6027",
	"★Sco6247",
	"★Sco6252",
	"★Sco5928",
	"★Sco6241",
	"★Sco6165",
	"★Tri544",
	"★Leo3982",
	"★Leo4534",
	"★Leo4357",
	"★Leo4057",
	"★Leo4359",
	"★Leo4031",
	"★Leo3852",
	"★Leo3905",
	"★Leo3773",
	"★Gru8425",
	"★Gru8636",
	"★Gru8353",
	"★Lib5685",
	"★Lib5531",
	"★Lib5787",
	"★Lib5603",
	"★Pup3165",
	"★Pup3185",
	"★Pup3045",
	"★Cyg7924",
	"★Cyg7417",
	"★Cyg7796",
	"★Cyg8301",
	"★Cyg7949",
	"★Cyg7528",
	"★Oct7228",
	"★Col1956",
	"★Col2040",
	"★Col2177",
	"★Gem2990",
	"★Gem2891",
	"★Gem2421",
	"★Gem2473",
	"★Gem2216",
	"★Gem2777",
	"★Gem2650",
	"★Gem2286",
	"★Gem2484",
	"★Gem2930",
	"★Peg8775",
	"★Peg8781",
	"★Peg39",
	"★Peg8308",
	"★Peg8650",
	"★Peg8634",
	"★Peg8684",
	"★Peg8450",
	"★Peg8880",
	"★Peg8905",
	"★Oph6556",
	"★Oph6378",
	"★Oph6603",
	"★Oph6149",
	"★Oph6056",
	"★Oph6075",
	"★Ser5854",
	"★Ser7141",
	"★Ser5879",
	"★Her6406",
	"★Her6148",
	"★Her6410",
	"★Her6526",
	"★Her6117",
	"★Her6008",
	"★Per936",
	"★Per1017",
	"★Per1131",
	"★Per1228",
	"★Per834",
	"★Per941",
	"★Phe99",
	"★Phe338",
	"★Vel3634",
	"★Vel3485",
	"★Vel3734",
	"★Aqr8232",
	"★Aqr8414",
	"★Aqr8709",
	"★Aqr8518",
	"★Aqr7950",
	"★Aqr8499",
	"★Aqr8610",
	"★Aqr8264",
	"★Cru4853",
	"★Cru4730",
	"★Cru4763",
	"★Cru4700",
	"★Cru4656",
	"★PsA8728",
	"★TrA6217",
	"★Cap7776",
	"★Cap7754",
	"★Cap8278",
	"★Cap8322",
	"★Cap7773",
	"★Sge7479",
	"★Car2326",
	"★Car3685",
	"★Car3307",
	"★Car3699",
	"★Dra5744",
	"★Dra5291",
	"★Dra6705",
	"★Dra6536",
	"★Dra7310",
	"★Dra6688",
	"★Dra4434",
	"★Dra6370",
	"★Dra7462",
	"★Dra6396",
	"★Dra6132",
	"★Dra6636",
	"★CVn4915",
	"★CVn4785",
	"★CVn4846",
	"★Aql7595",
	"★Aql7557",
	"★Aql7525",
	"★Aql7602",
	"★Aql7235",
	"Max Honey",
	"Max Mushrooms",
	"Galarica Twig",
	"Galarica Cuff",
	"Style Card",
	"Armor Pass",
	"Rotom Bike",
	"Rotom Bike",
	"Exp. Charm",
	"Armorite Ore",
	"Mark Charm",
	"Reins of Unity",
	"Reins of Unity",
	"Galarica Wreath",
	"Legendary Clue 1",
	"Legendary Clue 2",
	"Legendary Clue 3",
	"Legendary Clue?",
	"Crown Pass",
	"Wooden Crown",
	"Radiant Petal",
	"White Mane Hair",
	"Black Mane Hair",
	"Iceroot Carrot",
	"Shaderoot Carrot",
	"Dynite Ore",
	"Carrot Seeds",
	"Ability Patch",
	"Reins of Unity",
	"Time Balm",
	"Space Balm",
	"Mysterious Balm",
	"Linking Cord",
	"Hometown Muffin",
	"Apricorn",
	"Jubilife Muffin",
	"Aux Powerguard",
	"Dire Hit",
	"Choice Dumpling",
	"Twice-Spiced Radish",
	"Swap Snack",
	"Caster Fern",
	"Seed of Mastery",
	"Poké Ball",
	"???",
	"Eternal Ice",
	"Uxie’s Claw",
	"Azelf’s Fang",
	"Mesprit’s Plume",
	"Tumblestone",
	"Celestica Flute",
	"Remedy",
	"Fine Remedy",
	"Dazzling Honey",
	"Hearty Grains",
	"Plump Beans",
	"Springy Mushroom",
	"Crunchy Salt",
	"Wood",
	"King’s Leaf",
	"Marsh Balm",
	"Poké Ball",
	"Great Ball",
	"Ultra Ball",
	"Feather Ball",
	"Pokéshi Doll",
	"???",
	"Smoke Bomb",
	"Scatter Bang",
	"Sticky Glob",
	"Star Piece",
	"Mushroom Cake",
	"Bugwort",
	"Honey Cake",
	"Grain Cake",
	"Bean Cake",
	"Salt Cake",
	"Potion",
	"Super Potion",
	"Hyper Potion",
	"Max Potion",
	"Full Restore",
	"Remedy",
	"Fine Remedy",
	"Superb Remedy",
	"Old Gateau",
	"Jubilife Muffin",
	"Full Heal",
	"Revive",
	"Max Revive",
	"Max Ether",
	"Max Elixir",
	"Stealth Spray",
	"???",
	"Aux Power",
	"Aux Guard",
	"Dire Hit",
	"Aux Evasion",
	"Aux Powerguard",
	"Forest Balm",
	"Iron Chunk",
	"???",
	"Black Tumblestone",
	"Sky Tumblestone",
	"???",
	"Ball of Mud",
	"???",
	"Pop Pod",
	"Sootfoot Root",
	"Spoiled Apricorn",
	"Snowball",
	"Sticky Glob",
	"Black Augurite",
	"Peat Block",
	"Stealth Spray",
	"Medicinal Leek",
	"Vivichoke",
	"Pep-Up Plant",
	"???",
	"???",
	"Tempting Charm B",
	"Tempting Charm P",
	"Swordcap",
	"Iron Barktongue",
	"Doppel Bonnets",
	"Direshroom",
	"Sand Radish",
	"Tempting Charm T",
	"Tempting Charm Y",
	"Candy Truffle",
	"Cake-Lure Base",
	"Poké Ball",
	"Great Ball",
	"Ultra Ball",
	"Feather Ball",
	"???",
	"???",
	"Scatter Bang",
	"Smoke Bomb",
	"???",
	"???",
	"Pokéshi Doll",
	"Volcano Balm",
	"Mountain Balm",
	"Snow Balm",
	"Honey Cake",
	"Grain Cake",
	"Bean Cake",
	"Mushroom Cake",
	"Salt Cake",
	"Swap Snack",
	"Choice Dumpling",
	"Twice-Spiced Radish",
	"Survival Charm R",
	"Survival Charm B",
	"Survival Charm P",
	"Survival Charm T",
	"Survival Charm Y",
	"Torn Journal",
	"Warding Charm R",
	"Warding Charm B",
	"Warding Charm P",
	"Warding Charm T",
	"Warding Charm Y",
	"Wall Fragment",
	"Basculegion Food",
	"Old Journal",
	"Wing Ball",
	"Jet Ball",
	"Heavy Ball",
	"Leaden Ball",
	"Gigaton Ball",
	"Wing Ball",
	"Jet Ball",
	"Heavy Ball",
	"Hopo Berry",
	"Superb Remedy",
	"Aux Power",
	"Aux Guard",
	"Aux Evasion",
	"Grit Dust",
	"Grit Gravel",
	"Grit Pebble",
	"Grit Rock",
	"Secret Medicine",
	"Tempting Charm R",
	"Lost Satchel",
	"Lost Satchel",
	"Lost Satchel",
	"Lost Satchel",
	"Lost Satchel",
	"???",
	"Origin Ball",
	"???",
	"???",
	"???",
	"???",
	"Origin Ore",
	"Adamant Crystal",
	"Lustrous Globe",
	"Griseous Core",
	"Blank Plate",
	"???",
	"Crafting Kit",
	"Leaden Ball",
	"Gigaton Ball",
	"Strange Ball",
	"Pokédex",
	"Old Verse 1",
	"Old Verse 2",
	"Old Verse 3",
	"Old Verse 4",
	"???",
	"Old Verse 5",
	"Old Verse 6",
	"Old Verse 7",
	"Old Verse 8",
	"Old Verse 9",
	"Old Verse 10",
	"Old Verse 11",
	"Old Verse 12",
	"Old Verse 13",
	"Old Verse 14",
	"Old Verse 15",
	"Old Verse 16",
	"Old Verse 17",
	"Old Verse 18",
	"Old Verse 19",
	"Old Verse 20",
	"Mysterious Shard S",
	"Mysterious Shard L",
	"Digger Drill",
	"Kanto Slate",
	"Johto Slate",
	"Soul Slate",
	"Rainbow Slate",
	"Squall Slate",
	"Oceanic Slate",
	"Tectonic Slate",
	"Stratospheric Slate",
	"Genome Slate",
	"Discovery Slate",
	"Distortion Slate",
	"DS Sounds",
	"",
	"",
	"",
	"",
	"",
	"Legend Plate",
	"Rotom Phone",
	"Sandwich",
	"Koraidon’s Poké Ball",
	"Miraidon’s Poké Ball",
	"Tera Orb",
	"Scarlet Book",
	"Violet Book",
	"Kofu’s Wallet",
	"",
	"",
	"",
	"",
	"",
	"Tiny Bamboo Shoot",
	"Big Bamboo Shoot",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"Scroll of Darkness",
	"Scroll of Waters",
	"",
	"",
	"Malicious Armor",
	"Normal Tera Shard",
	"Fire Tera Shard",
	"Water Tera Shard",
	"Electric Tera Shard",
	"Grass Tera Shard",
	"Ice Tera Shard",
	"Fighting Tera Shard",
	"Poison Tera Shard",
	"Ground Tera Shard",
	"Flying Tera Shard",
	"Psychic Tera Shard",
	"Bug Tera Shard",
	"Rock Tera Shard",
	"Ghost Tera Shard",
	"Dragon Tera Shard",
	"Dark Tera Shard",
	"Steel Tera Shard",
	"Fairy Tera Shard",
	"Booster Energy",
	"Ability Shield",
	"Clear Amulet",
	"Mirror Herb",
	"Punching Glove",
	"Covert Cloak",
	"Loaded Dice",
	"",
	"Baguette",
	"Mayonnaise",
	"Ketchup",
	"Mustard",
	"Butter",
	"Peanut Butter",
	"Chili Sauce",
	"Salt",
	"Pepper",
	"Yogurt",
	"Whipped Cream",
	"Cream Cheese",
	"Jam",
	"Marmalade",
	"Olive Oil",
	"Vinegar",
	"Sweet Herba Mystica",
	"Salty Herba Mystica",
	"Sour Herba Mystica",
	"Bitter Herba Mystica",
	"Spicy Herba Mystica",
	"Lettuce",
	"Tomato",
	"Cherry Tomatoes",
	"Cucumber",
	"Pickle",
	"Onion",
	"Red Onion",
	"Green Bell Pepper",
	"Red Bell Pepper",
	"Yellow Bell Pepper",
	"Avocado",
	"Bacon",
	"Ham",
	"Prosciutto",
	"Chorizo",
	"Herbed Sausage",
	"Hamburger",
	"Klawf Stick",
	"Smoked Fillet",
	"Fried Fillet",
	"Egg",
	"Potato Tortilla",
	"Tofu",
	"Rice",
	"Noodles",
	"Potato Salad",
	"Cheese",
	"Banana",
	"Strawberry",
	"Apple",
	"Kiwi",
	"Pineapple",
	"Jalapeño",
	"Horseradish",
	"Curry Powder",
	"Wasabi",
	"Watercress",
	"Basil",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"Venonat Fang",
	"Diglett Dirt",
	"Meowth Fur",
	"Psyduck Down",
	"Mankey Fur",
	"Growlithe Fur",
	"Slowpoke Claw",
	"Magnemite Screw",
	"Grimer Toxin",
	"Shellder Pearl",
	"Gastly Gas",
	"Drowzee Fur",
	"Voltorb Sparks",
	"Scyther Claw",
	"Tauros Hair",
	"Magikarp Scales",
	"Ditto Goo",
	"Eevee Fur",
	"Dratini Scales",
	"Pichu Fur",
	"Igglybuff Fluff",
	"Mareep Wool",
	"Hoppip Leaf",
	"Sunkern Leaf",
	"Murkrow Bauble",
	"Misdreavus Tears",
	"Girafarig Fur",
	"Pineco Husk",
	"Dunsparce Scales",
	"Qwilfish Spines",
	"Heracross Claw",
	"Sneasel Claw",
	"Teddiursa Claw",
	"Delibird Parcel",
	"Houndour Fang",
	"Phanpy Nail",
	"Stantler Hair",
	"Larvitar Claw",
	"Wingull Feather",
	"Ralts Dust",
	"Surskit Syrup",
	"Shroomish Spores",
	"Slakoth Fur",
	"Makuhita Sweat",
	"Azurill Fur",
	"Sableye Gem",
	"Meditite Sweat",
	"Gulpin Mucus",
	"Numel Lava",
	"Torkoal Coal",
	"Spoink Pearl",
	"Cacnea Needle",
	"Swablu Fluff",
	"Zangoose Claw",
	"Seviper Fang",
	"Barboach Slime",
	"Shuppet Scrap",
	"Tropius Leaf",
	"Snorunt Fur",
	"Luvdisc Scales",
	"Bagon Scales",
	"Starly Feather",
	"Kricketot Shell",
	"Shinx Fang",
	"Combee Honey",
	"Pachirisu Fur",
	"Buizel Fur",
	"Shellos Mucus",
	"Drifloon Gas",
	"Stunky Fur",
	"Bronzor Fragment",
	"Bonsly Tears",
	"Happiny Dust",
	"Spiritomb Fragment",
	"Gible Scales",
	"Riolu Fur",
	"Hippopotas Sand",
	"Croagunk Poison",
	"Finneon Scales",
	"Snover Berries",
	"Rotom Sparks",
	"Petilil Leaf",
	"Basculin Fang",
	"Sandile Claw",
	"Zorua Fur",
	"Gothita Eyelash",
	"Deerling Hair",
	"Foongus Spores",
	"Alomomola Mucus",
	"Tynamo Slime",
	"Axew Scales",
	"Cubchoo Fur",
	"Cryogonal Ice",
	"Pawniard Blade",
	"Rufflet Feather",
	"Deino Scales",
	"Larvesta Fuzz",
	"Fletchling Feather",
	"Scatterbug Powder",
	"Litleo Tuft",
	"Flabébé Pollen",
	"Skiddo Leaf",
	"Skrelp Kelp",
	"Clauncher Claw",
	"Hawlucha Down",
	"Dedenne Fur",
	"Goomy Goo",
	"Klefki Key",
	"Bergmite Ice",
	"Noibat Fur",
	"Yungoos Fur",
	"Crabrawler Shell",
	"Oricorio Feather",
	"Rockruff Rock",
	"Mareanie Spike",
	"Mudbray Mud",
	"Fomantis Leaf",
	"Salandit Gas",
	"Bounsweet Sweat",
	"Oranguru Fur",
	"Passimian Fur",
	"Sandygast Sand",
	"Komala Claw",
	"Mimikyu Scrap",
	"Bruxish Tooth",
	"Chewtle Claw",
	"Skwovet Fur",
	"Arrokuda Scales",
	"Rookidee Feather",
	"Toxel Sparks",
	"Falinks Sweat",
	"Cufant Tarnish",
	"Rolycoly Coal",
	"Silicobra Sand",
	"Indeedee Fur",
	"Pincurchin Spines",
	"Snom Thread",
	"Impidimp Hair",
	"Applin Juice",
	"Sinistea Chip",
	"Hatenna Dust",
	"Stonjourner Stone",
	"Eiscue Down",
	"Dreepy Powder",
	"",
	"",
	"",
	"Lechonk Hair",
	"Tarountula Thread",
	"Nymble Claw",
	"Rellor Mud",
	"Greavard Wax",
	"Flittle Down",
	"Wiglett Sand",
	"Dondozo Whisker",
	"Veluza Fillet",
	"Finizen Mucus",
	"Smoliv Oil",
	"Capsakid Seed",
	"Tadbulb Mucus",
	"Varoom Fume",
	"Orthworm Tarnish",
	"Tandemaus Fur",
	"Cetoddle Grease",
	"Frigibax Scales",
	"Tatsugiri Scales",
	"Cyclizar Scales",
	"Pawmi Fur",
	"",
	"",
	"Wattrel Feather",
	"Bombirdier Feather",
	"Squawkabilly Feather",
	"Flamigo Down",
	"Klawf Claw",
	"Nacli Salt",
	"Glimmet Crystal",
	"Shroodle Ink",
	"Fidough Fur",
	"Maschiff Fang",
	"Bramblin Twig",
	"Gimmighoul Coin",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"Tinkatink Hair",
	"Charcadet Soot",
	"Toedscool Flaps",
	"Wooper Slime",
	"TM100",
	"TM101",
	"TM102",
	"TM103",
	"TM104",
	"TM105",
	"TM106",
	"TM107",
	"TM108",
	"TM109",
	"TM110",
	"TM111",
	"TM112",
	"TM113",
	"TM114",
	"TM115",
	"TM116",
	"TM117",
	"TM118",
	"TM119",
	"TM120",
	"TM121",
	"TM122",
	"TM123",
	"TM124",
	"TM125",
	"TM126",
	"TM127",
	"TM128",
	"TM129",
	"TM130",
	"TM131",
	"TM132",
	"TM133",
	"TM134",
	"TM135",
	"TM136",
	"TM137",
	"TM138",
	"TM139",
	"TM140",
	"TM141",
	"TM142",
	"TM143",
	"TM144",
	"TM145",
	"TM146",
	"TM147",
	"TM148",
	"TM149",
	"TM150",
	"TM151",
	"TM152",
	"TM153",
	"TM154",
	"TM155",
	"TM156",
	"TM157",
	"TM158",
	"TM159",
	"TM160",
	"TM161",
	"TM162",
	"TM163",
	"TM164",
	"TM165",
	"TM166",
	"TM167",
	"TM168",
	"TM169",
	"TM170",
	"TM171",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"Picnic Set",
	"",
	"Academy Bottle",
	"Academy Bottle",
	"Polka-Dot Bottle",
	"Striped Bottle",
	"Diamond Bottle",
	"Academy Cup",
	"Academy Cup",
	"Striped Cup",
	"Polka-Dot Cup",
	"Flower Pattern Cup",
	"Academy Tablecloth",
	"Academy Tablecloth",
	"Whimsical Tablecloth",
	"Leafy Tablecloth",
	"Spooky Tablecloth",
	"",
	"Academy Ball",
	"Academy Ball",
	"Marill Ball",
	"Yarn Ball",
	"Cyber Ball",
	"Gold Pick",
	"Silver Pick",
	"Red-Flag Pick",
	"Blue-Flag Pick",
	"Pika-Pika Pick",
	"Winking Pika Pick",
	"Vee-Vee Pick",
	"Smiling Vee Pick",
	"Blue Poké Ball Pick",
	"",
	"Auspicious Armor",
	"Leader’s Crest",
	"",
	"",
	"Pink Bottle",
	"Blue Bottle",
	"Yellow Bottle",
	"Steel Bottle (R)",
	"Steel Bottle (Y)",
	"Steel Bottle (B)",
	"Silver Bottle",
	"Barred Cup",
	"Diamond Pattern Cup",
	"Fire Pattern Cup",
	"Pink Cup",
	"Blue Cup",
	"Yellow Cup",
	"Pikachu Cup",
	"Eevee Cup",
	"Slowpoke Cup",
	"Silver Cup",
	"Exercise Ball",
	"Plaid Tablecloth (Y)",
	"Plaid Tablecloth (B)",
	"Plaid Tablecloth (R)",
	"B&W Grass Tablecloth",
	"Battle Tablecloth",
	"Monstrous Tablecloth",
	"Striped Tablecloth",
	"Diamond Tablecloth",
	"Polka-Dot Tablecloth",
	"Lilac Tablecloth",
	"Mint Tablecloth",
	"Peach Tablecloth",
	"Yellow Tablecloth",
	"Blue Tablecloth",
	"Pink Tablecloth",
	"Gold Bottle",
	"Bronze Bottle",
	"Gold Cup",
	"Bronze Cup",
	"Green Poké Ball Pick",
	"Red Poké Ball Pick",
	"Party Sparkler Pick",
	"Heroic Sword Pick",
	"Magical Star Pick",
	"Magical Heart Pick",
	"Parasol Pick",
	"Blue-Sky Flower Pick",
	"Sunset Flower Pick",
	"Sunrise Flower Pick",
	"Blue Dish",
	"Green Dish",
	"Orange Dish",
	"Red Dish",
	"White Dish",
	"Yellow Dish"
];